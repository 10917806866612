import { Injectable } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { BasePlotFormService } from "src/app/plot/services/base-plot-form.service";
import { FormModel } from "src/app/shared/models";
import { UntypedFormArray } from "@angular/forms";
import { BehaviorSubject, take } from "rxjs";
import { RotationService } from "../../services/rotation.service";
import TimeUtilities from "src/app/shared/utilities/time";
import {
  InitialSpecies,
  Regime,
  RegimeForest,
  RotationEvent,
  RotationForest,
  Tree,
} from "../../models";
import XMLUtilities from "../../utils/xml-utiles";
import Utilities from "src/app/shared/utilities/utils";

interface SelectedState {
  rotationExpandedState: { [key: string]: boolean };
  rotationSort: [{ prop: string; dir: "asc" | "desc" }];
  selectedTab: "rotationPropertiesTab" | "eventsTab";
  eventSort: [{ prop: string; dir: "asc" | "desc" }];
  eventExpandedState: { [key: string]: boolean };
}

@Injectable({
  providedIn: "root",
})
export class RmtManagementService extends BasePlotFormService {
  public readonly pageId = "management";

  private readonly defaultSelectedState: SelectedState = {
    rotationExpandedState: {},
    rotationSort: [{ prop: "startDate", dir: "asc" }],
    selectedTab: "rotationPropertiesTab",
    eventSort: [{ prop: "date", dir: "asc" }],
    eventExpandedState: {},
  };

  public selectedState$ = new BehaviorSubject<SelectedState>(
    this.defaultSelectedState
  );

  public regimeList$ = new BehaviorSubject<{ [key: string]: Regime }>({});

  public speciesList$ = new BehaviorSubject<{ [key: string]: InitialSpecies }>(
    {}
  );

  public treeList$ = new BehaviorSubject<{ [key: string]: Tree }>({});

  public modifiers: FormGroupElement[] = [
    {
      label: "Event Error",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              customClassFunction: "getEventErrorMessageClass",
              element: "text",
              method: "getEventErrorMessage",
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Management",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "RotationTableComponent",
                componentInputs: [
                  {
                    inputKey: "startDate",
                    method: "getStartDate",
                  },
                  {
                    inputKey: "endDate",
                    method: "getEndDate",
                  },
                  {
                    inputKey: "simulationService",
                    variable: "simulationService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    rotations: {
      label: "Rotations",
      defaultValue: "formArray",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],

      isShown: true,
    },
    startDate: {
      label: "Start Date",
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],

      isShown: true,
    },
    endDate: {
      label: "End Date",
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],

      isShown: true,
    },
  };

  constructor(public rotationService: RotationService) {
    super();

    //init start and EndDate
    this.initStartAndEndDates();
  }

  public initStartAndEndDates(): void {
    const moment = TimeUtilities.getMoment();
    const today = new Date();
    this.setStartDate(today);
    this.setEndDate(moment(today).add(100, "years").toDate());
  }

  public getEventErrorMessage(): string {
    return this.rotationService.eventErrorMessage$.getValue();
  }

  public getEventErrorMessageClass(): string {
    return "invalid-text";
  }

  public setSimulationService(service: any): void {
    this.simulationService = service;
    this.rotationService.setSimulationService(service);
  }

  public getLocationSpeciesRegimes$(speciesId: string) {
    const projectService = this.simulationService.projectService;
    const projectFormValue = projectService.getFormGroup().value;
    const params = {
      lat: projectFormValue.Latitude,
      lon: projectFormValue.Longitude,
      speciesId: speciesId,
    };
    //for template to subscribe
    return this.simulationService.dbService
      .getRMTSpeciesRegimes(params)
      .pipe(take(1));
  }

  public getRegimeDetails(regimeId: string) {
    const projectService = this.simulationService.projectService;
    const projectFormValue = projectService.getFormGroup().value;
    const params = {
      lat: projectFormValue.Latitude,
      lon: projectFormValue.Longitude,
      regimeId,
    };
    return this.simulationService.dbService.getRMTRegimeDetails(params).pipe(
      take(1)
      //catchError((error) => of(dummyRegimeDetails))
    );
  }

  public getStartDate(): Date {
    return this.getFormGroup().get("startDate").value || new Date();
  }

  public getEndDate(): Date {
    return this.getFormGroup().get("endDate").value || new Date();
  }

  public setStartDate(d: Date): void {
    this.getFormGroup().get("startDate").setValue(d);
  }

  public setEndDate(d: Date): void {
    this.getFormGroup().get("endDate").setValue(d);
  }

  public getRotations() {
    return this.getFormGroup().get("rotations") as UntypedFormArray;
  }

  public getRotationSort(): [{ prop: string; dir: "asc" | "desc" }] {
    return this.selectedState$.getValue().rotationSort;
  }
  public setRotationSort(rotationSort): void {
    this.selectedState$.next({
      ...this.selectedState$.getValue(),
      rotationSort,
    });
  }

  public getEventSort(): [{ prop: string; dir: "asc" | "desc" }] {
    return this.selectedState$.getValue().eventSort;
  }
  public setEventSort(eventSort): void {
    this.selectedState$.next({
      ...this.selectedState$.getValue(),
      eventSort,
    });
  }

  public getSelectedState(): SelectedState {
    return this.selectedState$.getValue();
  }

  public setSelectedState(selectedState): void {
    this.selectedState$.next({
      ...this.selectedState$.getValue(),
      ...selectedState,
    });
  }

  public getRegimeList(): Regime[] {
    const regimeList = this.regimeList$.getValue();
    return Object.values(regimeList) || [];
  }

  public setRegime(regime: RegimeForest) {
    this.regimeList$.next({
      ...this.regimeList$.getValue(),
      [regime.RegimeID]: regime,
    });
  }

  public getSpeciesList(): InitialSpecies[] {
    return Object.values(this.speciesList$.getValue()) || [];
  }

  public setSpecies(species: { [key: string]: InitialSpecies }) {
    this.speciesList$.next({
      ...this.speciesList$.getValue(),
      ...species,
    });
  }

  public getTreeList(): Tree[] {
    return Object.values(this.treeList$.getValue()) || [];
  }

  public setTree(tree: { [key: string]: Tree }) {
    this.treeList$.next({
      ...this.treeList$.getValue(),
      ...tree,
    });
  }

  public writeTreeListXmlObject() {
    let treeList = Utilities.cloneDeep(this.getTreeList());

    treeList.forEach((t) => {
      if (t.SpeciesReference && t.SpeciesReference.Value) {
        delete t.SpeciesReference.Value;
      }
    });

    let template = { Tree: treeList };

    return template;
  }

  public writeSpeciesListXmlObject() {
    const speciesList = Utilities.cloneDeep(this.getSpeciesList());
    let template = {
      Species: speciesList.map((s) => {
        return {
          $: { "xsi:type": "SpecF" },
          ...XMLUtilities.convertRawTSValuesToDouble(s),
        };
      }),
    };
    return template;
  }

  public writeRegimeListXmlObject() {
    const regimeList = Utilities.cloneDeep(this.getRegimeList());
    let template: any = { Regime: [] };
    regimeList.forEach((r) => {
      let convertedRegime = r;
      convertedRegime["$"] = { "xsi:type": "RegimeForest" };

      const defaultRotation = Array.isArray(convertedRegime.DefaultRotation)
        ? convertedRegime.DefaultRotation
        : convertedRegime.DefaultRotation.RotationEvent;
      const convertedDefaultRotation = defaultRotation.map((dr) => {
        let {
          $type,
          xsi: type,
          ...RegimeEventReferenceValueWithoutType
        } = dr.RegimeEventReference.Value || dr.RegimeEventReferenceValue;

        RegimeEventReferenceValueWithoutType =
          XMLUtilities.remapEventGroupArray(
            RegimeEventReferenceValueWithoutType
          );

        const obj = {
          $: {
            "xsi:type": dr.$type,
          },
          ...dr,
          RegimeEventReference: {
            Id: dr.RegimeEventReference["Id"],
            Name: dr.RegimeEventReference["Name"],
          },
          RegimeEventReferenceValue: {
            $: {
              "xsi:type": $type,
            },
            ...RegimeEventReferenceValueWithoutType,
          },
        };

        delete obj.$type;

        return XMLUtilities.removeNullValues(obj);
      });

      convertedRegime.DefaultRotation = {
        RotationEvent: convertedDefaultRotation,
      };

      const avaliableEvents = Array.isArray(r.AvaliableEvents)
        ? r.AvaliableEvents
        : r.AvaliableEvents.RegimeEvent;
      const convertedEvents = avaliableEvents.map((ae) => {
        let { $type, ...aeWithoutType } = ae;

        aeWithoutType = XMLUtilities.remapEventGroupArray(aeWithoutType);
        const obj = {
          $: {
            "xsi:type": $type,
          },
          ...aeWithoutType,
        };
        return XMLUtilities.removeNullValues(obj);
      });

      convertedRegime.AvaliableEvents = { RegimeEvent: convertedEvents };

      delete convertedRegime.SpeciesReference.Value;

      template.Regime.push(convertedRegime);
    });

    return template;
  }

  public readXmlObject(): void {
    const formGroup = this.getFormGroup();
    if (!this.simulationService.importedRMDFileJson) {
      return;
    }
    const ncatStand = this.simulationService.importedRMDFileJson["NCATStand"];

    const template = ncatStand?.Management;

    if (!template) {
      return;
    }

    const startDate = new Date(template.StartDate);
    const endDate = new Date(template.EndDate);

    const trees = Array.isArray(ncatStand?.Trees?.Tree)
      ? ncatStand.Trees.Tree
      : [ncatStand.Trees.Tree];
    const regimeList = Array.isArray(ncatStand?.RegimesList?.Regime)
      ? ncatStand.RegimesList.Regime
      : [ncatStand.RegimesList.Regime];
    const rotations = Array.isArray(template.UserRotations?.Rotation)
      ? template.UserRotations.Rotation
      : [template.UserRotations.Rotation];

    const speciesList = Array.isArray(ncatStand?.SpeciesList?.Species)
      ? ncatStand.SpeciesList.Species
      : [ncatStand.SpeciesList.Species];

    formGroup.get("startDate").setValue(startDate);
    formGroup.get("endDate").setValue(endDate);

    rotations.forEach((r) => {
      const regimeId = r.RegimeReference.Id;
      const foundRegime = regimeList.find((r) => r.RegimeID == regimeId);
      const foundSpecies = speciesList.find(
        (s) => s.Id == r.UserSpeciesReference.Id
      );
      if (foundRegime) {
        const availableEvents = Array.isArray(
          foundRegime?.AvaliableEvents?.RegimeEvent
        )
          ? foundRegime.AvaliableEvents.RegimeEvent
          : [foundRegime.AvaliableEvents.RegimeEvent];
        const rotation: RotationForest = {
          Id: Utilities.uuid(),
          RegimeReference: {
            Id: foundRegime.RegimeID,
            Name: foundRegime.Name,
            Value: foundRegime,
          },
          UserSpeciesReference: {
            Id: r.UserSpeciesReference.Id,
            Name: r.UserSpeciesReference.Name,
            Value: foundSpecies,
          },
          SpeciesTYFInputs: {
            G: +r.SpeciesTYFInputs.G,
            r: +r.SpeciesTYFInputs.r,
            Error: "", // Default or computed value for Error
          },
          RotationEventCollection: [],
          Offset: 0,
          RotationStartDate: null,
          IsValid: true,
        };

        const rotationFormGroup =
          this.rotationService.createRotationFormGroup(rotation);

        const moment = TimeUtilities.getMoment();
        const rotationStartDate = moment(
          this.simulationService.getStartDate()
        ).add(rotationFormGroup.get("Offset").value, "days");
        rotationFormGroup
          .get("RotationStartDate")
          .setValue(rotationStartDate.toDate());

        //set events in the rotation
        const uerEventCollection = Array.isArray(
          r.RotationEventCollection?.RotationEvent
        )
          ? r.RotationEventCollection?.RotationEvent
          : [r.RotationEventCollection?.RotationEvent];
        uerEventCollection.forEach((ue) => {
          let userEvent = ue;

          const eventId = ue.RegimeEventReferenceValue.Id;
          const foundEvent = availableEvents.find((ae) => ae.Id == eventId);

          if (foundEvent) {
            userEvent.RegimeEventReference = {
              Id: foundEvent.Id,
              Name: foundEvent.Name,
              Value: foundEvent,
            };
            this.applyImportedEvent(rotationFormGroup, userEvent);
          }
        });

        this.getRotations().push(rotationFormGroup);
      }
    });

    trees.forEach((tree) => {
      this.setTree({ [tree.SpeciesReference.Id]: tree });
    });

    regimeList.forEach((regime) => {
      const convertedRegime = XMLUtilities.convertXmlType(regime);
      this.setRegime(convertedRegime);
    });

    speciesList.forEach((species) => {
      const convertedSpecies = XMLUtilities.convertXmlType(species);
      this.setSpecies({ [convertedSpecies.Id]: convertedSpecies });
    });
  }

  protected applyImportedEvent(rotationFormGroup, userRegimeEvent) {
    const rotationService = this.rotationService;

    const rotationEvent: RotationEvent = {
      $type: userRegimeEvent["xsi:type"],
      Offset: userRegimeEvent.Offset,
      Year: userRegimeEvent.Year,
      Day: userRegimeEvent.Day,
      OffsetSubsequentOnDateChange: false,
      RegimeEventReference: userRegimeEvent.RegimeEventReference,
      IsClearing: false,
    };

    const newRotationEvent = rotationService.cloneRotationEvent(
      rotationEvent,
      rotationFormGroup
    );
    newRotationEvent.readXmlObject(userRegimeEvent);

    const rotationEventCollection = rotationFormGroup.get(
      "RotationEventCollection"
    );

    rotationEventCollection.setValue([
      ...rotationEventCollection.value,
      newRotationEvent,
    ]);

    rotationService.applyEventSort(rotationFormGroup);
  }

  public writeXmlObject() {
    const moment = TimeUtilities.getMoment();
    let baseTemplate = {
      StartDate: moment(this.getStartDate())
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      EndDate: moment(this.getEndDate())
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      UserRotations: this.rotationService.writeXmlObject(),
    };

    return baseTemplate;
  }

  override isInvalid(): boolean {
    const isFormValid = super.isInvalid();
    const errorMessage = this.rotationService.eventErrorMessage$.getValue();

    const hasErrorMessage = errorMessage !== null && errorMessage !== "";

    return isFormValid || hasErrorMessage;
  }

  override reset() {
    this.selectedState$.next(this.defaultSelectedState);
    this.regimeList$.next({});
    this.speciesList$.next({});
    this.treeList$.next({});
    this.getRotations()?.clear();

    this.rotationService.reset();

    super.reset();

    this.initStartAndEndDates();
  }
}
