<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close regime form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>
      Timing offset for cloned events
      <a
        aria-label="help link button"
        type="button"
        *ngIf="eventsService?.baseHelpPageUrl"
        class="btn"
        [href]="eventsService.baseHelpPageUrl + '/' + cloneRegimeHelpUrl"
        tooltip="'View Clone regime help"
        target="_blank"
      >
        <i class="bi bi-question-circle"></i>
      </a>
    </h1>

    <form [formGroup]="formGroup">
      <div class="input-container">
        <label for="regime-clone-year-input" class="form-label"
          >Calendar years</label
        >
        <div>
          <input
            id="regime-clone-year-input"
            class="form-control form-control-sm"
            form-field
            formControlName="years"
            autocomplete="off"
            type="number"
          />
          <fc-form-error-messages
            *ngIf="
              formGroup.get('years')?.errors &&
              (formGroup.touched || formGroup.dirty)
            "
            [formControlInstance]="formGroup.get('years')"
          ></fc-form-error-messages>
        </div>
      </div>

      <div class="input-container">
        <label for="regime-clone-repeat-times-input" class="form-label"
          >Number of times</label
        >
        <div>
          <input
            id="regime-clone-repeat-times-input"
            class="form-control form-control-sm"
            form-field
            formControlName="repeatTimes"
            autocomplete="off"
            type="number"
          />
          <fc-form-error-messages
            *ngIf="
              formGroup.get('repeatTimes')?.errors &&
              (formGroup.touched || formGroup.dirty)
            "
            [formControlInstance]="formGroup.get('repeatTimes')"
          ></fc-form-error-messages>
        </div>
      </div>

      <div class="form-button-group">
        <button
          type="button"
          (click)="closeForm(null)"
          class="btn btn-outline-primary"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="closeForm(formGroup.getRawValue())"
          class="btn btn-outline-primary"
          [disabled]="formGroup.invalid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
