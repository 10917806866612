import { Injectable, Injector } from "@angular/core";
import {
  BehaviorSubject,
  EMPTY,
  catchError,
  filter,
  switchMap,
  take,
} from "rxjs";
import { Simulation2016Service } from "../core/services/simulation-2016.service";
import { Simulation2020Service } from "../core/services/simulation-2020.service";
import { Simulation2024Service } from "../core/services/simulation-2024.service";
import { SimulationService } from "../shared/models";
import { Title } from "@angular/platform-browser";
import { Constants } from "../shared/constants";
import { RmtSimulationService } from "../rmt/simulation/services/rmt-simulation.service";
import { AuthService } from "../auth/services/auth.service";
import { InteractionStatus } from "@azure/msal-browser";
import { UserRole } from "../user-role/role-access/model";

@Injectable({
  providedIn: "root",
})
export class AppService {
  private currentSimulationService: SimulationService;

  public appVersion$ = new BehaviorSubject<string>(null);

  constructor(private injector: Injector, private titleService: Title) {}

  public initialiseApp(authService: AuthService): Promise<void> {
    return new Promise((resolve, reject) => {
      authService
        .getInteractionStatus()
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          switchMap(() => {
            const accounts = authService.getAllAccounts();
            if (accounts.length > 0) {
              authService.setActiveAccount(accounts[0]);
            }
            const activeAccount = authService.getActiveAccount();
            if (activeAccount) {
              // Directly return the Observable from getUserRoles
              return authService.getUserRoles().pipe(take(1));
            } else {
              // Resolve immediately if there's no active account
              resolve();
              return EMPTY;
            }
          }),
          catchError((error) => {
            console.error("Error during initialization:", error);
            reject(error);
            return EMPTY;
          })
        )
        .subscribe({
          next: (userRole: UserRole) => {
            if (userRole) {
              authService.setUserRoles(userRole);
            }
            resolve(); // Resolve the promise here, after setting user roles
          },
          error: reject, // Reject the promise on error
        });
    });
  }

  public setAppVersion(version: string | null): void {
    if (version == this.getVersion()) {
      return;
    }
    this.appVersion$.next(version);
    this.setCurrentSimulationService(version);
  }

  public setCurrentSimulationService(version) {
    this.destroySimulationService();
    switch (version) {
      case "2016":
        this.currentSimulationService = this.injector.get(
          Simulation2016Service
        );
        break;
      case "2020":
        this.currentSimulationService = this.injector.get(
          Simulation2020Service
        );
        break;
      case "2024":
        this.currentSimulationService = this.injector.get(
          Simulation2024Service
        );
        break;
      case "rmt":
        this.currentSimulationService = this.injector.get(RmtSimulationService);
        break;
    }

    this.currentSimulationService.setUp();
  }

  protected destroySimulationService() {
    if (this.currentSimulationService) {
      this.currentSimulationService.reset();
    }
  }

  public getVersion(): string {
    return this.appVersion$.getValue() || "";
  }

  public getSimulationService() {
    return this.currentSimulationService;
  }

  public getPageTitlePrefix(): string {
    return `${Constants.APP_TITLE} ${this.getVersion()} | `;
  }

  public setPageTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  public getPageTitle(): string {
    return this.titleService.getTitle();
  }
}
