import { Component, OnInit, OnDestroy } from "@angular/core";
import { RMTSimulationResult } from "src/app/rmt/models";
import { RmtSimulationService } from "src/app/rmt/simulation/services/rmt-simulation.service";
import Utilities from "src/app/shared/utilities/utils";
import { RmtResultsService } from "../../services/rmt-results.service";
import { Subject, finalize, take, takeUntil } from "rxjs";

@Component({
  selector: "fc-simulation-result",
  templateUrl: "./simulation-result.component.html",
  styleUrls: ["./simulation-result.component.scss"],
})
export class SimulationResultComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public viewType: "table" | "chart" = "chart";

  public simulationService: RmtSimulationService;

  public resultService: RmtResultsService;

  public chartOption = null;

  public tableRows = null;
  public columns = [];

  public isLoading: boolean = false;

  constructor() {}

  async ngOnInit() {
    this.resultService = this.simulationService.resultsService;

    this.viewType = this.resultService.viewType$.getValue();
    this.chartOption = null;
    this.tableRows = null;
    this.resultService.simulationResult$
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: string) => {
        if (result) {
          this.setData(result);
        }
      });

    this.simulationService.simulate();
  }
  protected setData(csvContent: string): void {
    const { series, tableRows, columns, lastDaysOfMonths } =
      this.processCSVData(csvContent);

    this.chartOption = {
      legend: {
        type: "scroll",
        orient: "horizontal",
        x: "center",
        y: "bottom",
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {},
        },
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        name: "Date",
        nameLocation: "middle",
        data: lastDaysOfMonths,
        nameTextStyle: {
          padding: [20, 0, 0, 0],
        },
      },
      yAxis: {
        name: "Mass [tC]",
        nameLocation: "middle",
        nameTextStyle: {
          padding: [50, 0, 30, 0],
        },
      },
      series: series,
    };

    this.tableRows = tableRows;
    this.columns = columns;
  }

  private processCSVData(csvContent: string): {
    series: any[];
    tableRows: any[];
    columns: any[];
    lastDaysOfMonths: string[];
  } {
    const parseResult = Utilities.convertCsvToArray(csvContent, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transform: (value, column) => {
        if (column === "Date") return value.trim();
        const num = +value;
        return isNaN(num) ? value.trim() : Utilities.simulationRounding(num);
      },
    });

    if (parseResult.errors.length > 0) {
      console.error("CSV parsing errors:", parseResult.errors);
    }

    const headers = parseResult.meta.fields || [];
    const dataRows = parseResult.data as any[];

    const columns = headers.map((header) => ({ prop: header, name: header }));

    const tableRows = dataRows;

    const lastDaysOfMonths = dataRows.map((row) => row[headers[0]]);

    const series = headers.slice(1).map((header) => ({
      name: header,
      data: dataRows.map((row) => {
        const value = row[header];
        return typeof value === "number" ? value : null;
      }),
      type: "line",
      symbol: "none",
    }));

    return { series, tableRows, columns, lastDaysOfMonths };
  }

  public exportTableAsCsv() {
    this.resultService.exportTableAsCsv();
  }

  ngOnDestroy(): void {
    const resultService = this.simulationService.resultsService;
    resultService.setViewType(this.viewType);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
