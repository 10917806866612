import { Injectable, ViewContainerRef } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FieldWatcher, FormModel } from "src/app/shared/models";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { Constants } from "src/app/shared/constants";

@Injectable({
  providedIn: "root",
})
export class ForestThinning2020Service extends BaseEventFormService {
  public version = "2020";

  public componentHost: ViewContainerRef = null;
  public isDynamicComponentLoaded = false;

  public readonly categoryIds = [74, 71];
  public readonly pageHelpLink = "140_Thin.htm";
  public readonly selectedSpeciesId$ = new BehaviorSubject<number>(-1); //for placeholder select

  public logGradesFormGroup: FormGroup;

  protected destinationPercentagesGroups: FormGroupElement[] = [
    {
      id: "stems-to",
      label: "Stems to",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Stems to",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Stems to"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead stem",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToSDdwdThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Deadwood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToDdwdThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Paper and pulp",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToPaprThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Packing wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToPackThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Furniture",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToFurnThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fiberboard",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToFibrThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Construction",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToConsThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Mill residue",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracStemToResiThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "fracStemToSDdwdThin",
                            "fracStemToDdwdThin",
                            "fracStemToFuelThin",
                            "fracStemToPaprThin",
                            "fracStemToPackThin",
                            "fracStemToFurnThin",
                            "fracStemToFibrThin",
                            "fracStemToConsThin",
                            "fracStemToResiThin",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "branches-to",
      label: "Branches to",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Branches to",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Branches to"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead branch",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToSDdwdThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Deadwood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToDdwdThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Paper and pulp",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToPaprThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Packing wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToPackThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Furniture",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToFurnThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fiberboard",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToFibrThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Construction",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToConsThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Mill residue",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBranToResiThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "fracBranToSDdwdThin",
                            "fracBranToDdwdThin",
                            "fracBranToFuelThin",
                            "fracBranToPaprThin",
                            "fracBranToPackThin",
                            "fracBranToFurnThin",
                            "fracBranToFibrThin",
                            "fracBranToConsThin",
                            "fracBranToResiThin",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "bark-to",
      label: "Bark to",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Bark to",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Bark to"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBarkToSBlitThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBarkToBlitThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBarkToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Paper",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBarkToPaprThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Mill residue",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBarkToResiThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "fracBarkToSBlitThin",
                            "fracBarkToBlitThin",
                            "fracBarkToFuelThin",
                            "fracBarkToPaprThin",
                            "fracBarkToResiThin",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "leaves-to",
      label: "Leaves to",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Leaves to",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Leaves to"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead leaf",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracLeafToSLlitThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracLeafToLlitThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracLeafToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "fracLeafToSLlitThin",
                            "fracLeafToLlitThin",
                            "fracLeafToFuelThin",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "coarse-roots-to",
      label: "Coarse roots to",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Coarse roots to",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Coarse roots to"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracCortToSCodrThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracCortToCodrThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracCortToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "fracCortToSCodrThin",
                            "fracCortToCodrThin",
                            "fracCortToFuelThin",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "fine-roots-to",
      label: "Fine roots to",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Fine roots to",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Fine roots to"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracFirtToSFidrThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracFirtToFidrThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "fracFirtToSFidrThin",
                            "fracFirtToFidrThin",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: "biofuel",
      label: "Biofuel",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value:
                    "Destination percentages in the affected portion - Biofuel",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Biofuel"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead stem to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracSDdwdToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead branch to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracSChwdToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead bark to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracSBlitToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Standing dead leaf to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracSLlitToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Deadwood to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracDdwdToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Chopped wood to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracChwdToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark litter to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracBlitToFuelThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf litter to",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fracLlitToFuelThin",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected postThinperiodGroups: FormGroupElement[] = [
    {
      label: "Relative allocation multipliers",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Post-thin period - relative allocation multipliers",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Relative allocation multipliers"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "multStemThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "multBranThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "multBarkThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "multLeafThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "multCortThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "multFirtThin",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      label: "Length of period",
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: "Years [yr]",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "boostYrsThin",
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Thin",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Percentage of forest affected by thin",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctThin",
              },
            ],
          },
        ],
      },
      {
        label: "Age adjustment",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Enable biomass based age adjustment",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "useBioAgeAdjThin",
              },
            ],
          },
        ],
      },
      {
        label: "Destination percentages in the affected portion",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            groups: this.destinationPercentagesGroups,
          },
        ],
      },
      {
        label: "Post-Thin period",
        id: "post-thin-period",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [{ groups: this.postThinperiodGroups }],
      },
      {
        label: "Tree removal and replacement",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "",
            inputs: [
              {
                width: "310px",
                element: "text",
                value: "Trees (stems) removed by this thin:",
              },
              {
                width: "100px",
                element: "text",
                method: "getStemsRemovedByThisThin",
              },
            ],
          },

          {
            id: "remvAvgAgeMultThin",
            label: null,
            inputs: [
              {
                programmingName: "remvAvgAgeMultThin", //only for isShown logic
                element: "component",
                component: "FormulaDisplayComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      lhs: "Average age of removed trees",
                      rhs: [
                        [
                          { element: "icon", type: "operator", value: "=" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvAvgAgeMultThin",
                          },

                          { element: "icon", type: "operator", value: "x" },
                          { element: "text", value: "Average age of trees" },
                        ],

                        [
                          { element: "icon", type: "operator", value: "+" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvMaxAgeMultThin",
                          },

                          { element: "icon", type: "operator", value: "x" },
                          { element: "text", value: "Age of the oldest trees" },
                        ],

                        [
                          { element: "icon", type: "operator", value: "+" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvOffsetThin",
                          },

                          { element: "text", value: "[yr]" },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },

          {
            id: "replaceStemsThin",
            label: "Replace removed trees with new trees (of age 0)",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "replaceStemsThin",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecF",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    useBioAgeAdjThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Use biomass age adjustment (yes/no)",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 0,
      tIn: 314,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracAfctThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "forest affected by thin",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 641,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracStemToDdwdThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 642,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 643,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToPaprThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to pulp and paper",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 644,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToPackThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to packing wood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 645,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToFurnThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to furniture",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 646,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToFibrThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to fiberboard",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 647,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToConsThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to construction timber",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 648,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracStemToResiThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to mill residues",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 649,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToDdwdThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 650,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 651,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToPaprThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to pulp and paper",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 652,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToPackThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to packing wood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 653,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToFurnThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to furniture",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 654,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToFibrThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to fiberboard",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 655,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToConsThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to construction wood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 656,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToResiThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to mill residue",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 657,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBarkToBlitThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark to litter",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 658,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBarkToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 659,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBarkToPaprThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark to pulp and paper",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 660,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBarkToResiThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark to mill residue",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 661,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracLeafToLlitThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves to litter",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 662,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracCortToCodrThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 663,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracFirtToFidrThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 664,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracDdwdToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "deadwood to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 814,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBlitToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark litter to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 815,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracLlitToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 816,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    replaceStemsThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 120,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Replace thinned trees (stems) with new trees (of age 0) (yes/no)",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 0,
      tIn: 993,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    remvAvgAgeMultThin: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Multiplier of average age",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 995,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 105,
    },
    remvMaxAgeMultThin: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Multiplier of age of oldest trees",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 996,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 105,
    },
    remvOffsetThin: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Added constant",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 997,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 105,
    },
    fracChwdToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "chopped wood to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1047,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracLeafToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1115,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracCortToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1116,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    multStemThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 106,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1193,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    multBranThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 106,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1194,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    multBarkThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 106,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1195,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    multLeafThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 106,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1196,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    multFirtThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 106,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1197,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    multCortThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 106,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1198,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    boostYrsThin: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "Length of post-thin period",
      unit: "yr",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1199,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    useLogGrades: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "User's choice of whether use log grades to specify the thin (yes/no)",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 0,
      tIn: 1639,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracGradeThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thin that is of grade 1 logs",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1640,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracGradeThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thin that is of grade 2 logs",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1641,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracGradeThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thin that is of grade 3 logs",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1642,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracGradeThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thin that is of grade 4 logs",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1643,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracGradeThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thin that is of grade 5 logs",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1644,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeNmThin: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of tree species expected to be thinned",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 120,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 1645,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    clearAllRemainsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Clear all remaining tree mass to debris (yes/no)",
      unit: "",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 0,
      tIn: 1646,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fixThin: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thinning event",
      unit: "$",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2054,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    phaThin: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 154,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "thinning event",
      unit: "$/ha",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2055,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracStemToDdwdExtraF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2093,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 173,
    },
    fracBranToDdwdExtraF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2094,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 173,
    },
    fracBarkToBlitExtraF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to bark litter",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2095,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 173,
    },
    fracLeafToLlitExtraF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to leaf litter",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2096,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 173,
    },
    fracCortToCodrExtraF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead coarse roots",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2097,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 173,
    },
    fracFirtToFidrExtraF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead fine roots",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2098,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 173,
    },
    fracStemToSDdwdThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems to standing dead stems",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2176,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBranToSDdwdThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches to standing dead branches",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2177,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracBarkToSBlitThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark to standing dead bark",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2178,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracLeafToSLlitThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves to standing dead leaves",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2179,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracCortToSCodrThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots to standing dead course roots",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2180,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracFirtToSFidrThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots to standing dead fine roots",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2181,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracSDdwdToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "Standing dead stem to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2182,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracSChwdToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "Standing dead branch to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2183,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracSBlitToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "Standing dead bark to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2184,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    fracSLlitToFuelThin: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "Standing dead leaf to biofuel",
      unit: "%",
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 0,
      tIn: 2185,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 121,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "Thin",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userThisThinByLG: {
      defaultValue: false,
      dataType: 2,
      suffixId: null,
    },
  };

  public fieldWatcher: FieldWatcher = {
    fracAfctThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
      // formGroup.get("clearEV").setValue(newValue >= 100);
    },
    fracStemToSDdwdThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToDdwdThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToFuelThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToPaprThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToPackThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToFurnThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToFibrThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToConsThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    fracStemToResiThin: (newValue: any, { formGroup, formModel }) => {
      this.shouldShowTreeRemovalGorup(formGroup, formModel);
    },
    // "configuration:userEcon": (newValue: any, { formGroup, formModel }) => {
    //   const stemsToGroup = this.getLayoutGroup(this.layout.groups, "stems-to");
    //   const branchesToGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "branches-to"
    //   );
    //   const barkToGroup = this.getLayoutGroup(this.layout.groups, "bark-to");
    //   const leavesToGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "leaves-to"
    //   );
    //   const coarseRootsToGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "coarse-roots-to"
    //   );
    //   const fineRootsToGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "fine-roots-to"
    //   );

    //   const biofuelGroup = this.getLayoutGroup(this.layout.groups, "biofuel");

    //   const expectedTreeSpeciesGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "expected-tree-species"
    //   );
    //   const logGradeGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "log-grade-percentages"
    //   );
    //   const extraPercentagesToDebrisGroup = this.getLayoutGroup(
    //     this.layout.groups,
    //     "extra-percentages-to-debris"
    //   );

    //   expectedTreeSpeciesGroup.isShown = newValue;
    //   logGradeGroup.isShown = newValue;
    //   extraPercentagesToDebrisGroup.isShown = newValue;

    //   biofuelGroup.isShown = !newValue;
    //   stemsToGroup.isShown = !newValue;
    //   branchesToGroup.isShown = !newValue;
    //   barkToGroup.isShown = !newValue;
    //   leavesToGroup.isShown = !newValue;
    //   coarseRootsToGroup.isShown = !newValue;
    //   fineRootsToGroup.isShown = !newValue;
    // },
    // clearAllRemainsF: (newValue: any, { formGroup, formModel }) => {
    //   formModel["fracStemToDdwdExtraF"].isShown = !newValue;
    // },
  };

  private shouldShowTreeRemovalGorup(formGroup, formModel) {
    const affectedPortion = +formGroup.get("fracAfctThin")?.value;
    const postThinPeriodGroup = this.getLayoutGroup(
      this.layout.groups,
      "post-thin-period"
    );

    let stemsTotal = 0;
    const stemsToFields = [
      "fracStemToSDdwdThin",
      "fracStemToDdwdThin",
      "fracStemToFuelThin",
      "fracStemToPaprThin",
      "fracStemToPackThin",
      "fracStemToFurnThin",
      "fracStemToFibrThin",
      "fracStemToConsThin",
      "fracStemToResiThin",
    ];

    for (let f of stemsToFields) {
      const value = +formGroup.get(f)?.value;
      if (!isNaN(value.valueOf())) {
        stemsTotal += value;
      }
    }

    const isAffected100 = +affectedPortion == 100;
    const shouldShow = !(isAffected100 && stemsTotal == 100);
    postThinPeriodGroup.isShown = shouldShow;

    formModel["remvAvgAgeMultThin"].isShown = shouldShow;
    formModel["replaceStemsThin"].isShown = shouldShow;

    const toggleFields = [
      "multStemThin",
      "multBranThin",
      "multBarkThin",
      "multLeafThin",
      "multFirtThin",
      "multCortThin",
      "boostYrsThin",
      "remvAvgAgeMultThin",
      "remvMaxAgeMultThin",
      "remvOffsetThin",
      "replaceStemsThin",
    ];

    this[
      shouldShow
        ? "addDefaultValidatorsInBulk"
        : "removeDefaultValidatorsInBulk"
    ](toggleFields, formGroup, formModel);
  }

  public getStemsRemovedByThisThin(): string {
    const configFormGroup =
      this.simulationService.configurationService.getFormGroup();
    const isEcon = configFormGroup.get("userEcon").value;
    const fb = this.getFormGroup();
    const affectedPortion = +fb.get("fracAfctThin").value;
    let totalStemsPercentage = 0;

    affectedPortion;
    if (isNaN(affectedPortion.valueOf())) {
      return "";
    }

    const stemsFields = [
      "fracStemToSDdwdThin",
      "fracStemToDdwdThin",
      "fracStemToFuelThin",
      "fracStemToPaprThin",
      "fracStemToPackThin",
      "fracStemToFurnThin",
      "fracStemToFibrThin",
      "fracStemToConsThin",
      "fracStemToResiThin",
    ];

    for (let f of stemsFields) {
      const value = +fb.get(f).value;
      if (!isNaN(value.valueOf())) {
        totalStemsPercentage += value;
      } else {
        return "";
      }
    }
    return (totalStemsPercentage * (affectedPortion / 100)).toFixed(1) + "%";
  }

  public setComponentHost(comp: ViewContainerRef) {
    this.componentHost = comp;
  }
  public getLogGradesFormGroup(): UntypedFormGroup {
    return this.logGradesFormGroup;
  }

  // public async openLogGradesForm(): Promise<void> {
  //   const treesService = this.simulationService.treesService;

  //   this.logGradesFormGroup = treesService.createFormGroup(
  //     treesService.speciesFormModel
  //   );
  //   this.logGradesFormGroup.patchValue(
  //     this.getSelectedSpeciesFormGroup().getRawValue()
  //   );

  //   const initialState: ModalOptions = {
  //     initialState: {
  //       formGroupInstance: this.logGradesFormGroup,
  //       simulationService: this.simulationService,
  //       forestThinningService: this,
  //     },
  //   };

  //   this.modalService
  //     .openModal(LogGradesFormComponent, {
  //       ...initialState,
  //     })
  //     .pipe(take(1))
  //     .subscribe((result) => {
  //       if (result) {
  //         this.getSelectedSpeciesFormGroup().patchValue(result);
  //         //console.log(result, "LOG GRADE RESULTS");
  //       }
  //     });
  // }

  public selectSpecies({ id, type, name }) {
    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByIdRegimeSP(
      "tree",
      id
    );

    this.selectedSpeciesId$.next(id);
    //Event is using app idSP instead idRegimeSP
    this.formGroup
      .get("idSP")
      .setValue(
        selectedSpeciesFormGroup
          ? selectedSpeciesFormGroup.get("idSP").value
          : null
      );
    this.formGroup.get("treeNmThin").setValue(name);
    this.formGroup
      .get("nmEV")
      .setValue(
        this.simulationService.eventFormsService.getEventDescription(
          this.formGroup.getRawValue()
        )
      );
  }

  public getSpeciesFormModel(): FormModel {
    return this.simulationService.treesService.speciesFormModel;
  }

  // public getSelectedSpeciesFormGroup() {
  //   const id = this.selectedSpeciesId$.getValue();
  //   return this.getSpeciesFormGroupById("tree", id);
  // }

  public getTPRSelectOptions() {
    const selects = [
      { value: null, label: "select one" },
      { value: "PricePerGmt", label: "Price per Gmt" },
      { value: "PricePerDmt", label: "Price per Dmt" },
      { value: "PricePerM3", label: "Price per M3" },
    ];
    return selects;
  }

  public setEventPageLogic(formData, isEventPage) {
    super.setEventPageLogic(formData, isEventPage);

    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByName(
      "tree",
      formData?.treeNmThin
    );

    if (!isEventPage) {
      this.formGroup
        .get("treeNmThin")
        .setValue(selectedSpeciesFormGroup.get("nmSP").value);

      return;
    }

    if (isEventPage) {
      // this.addValidatorsInBulk(
      //   ["treeNmThin"],
      //   [ {  functionName: "required",}],
      //   this.getFormGroup()
      // );
      // this.minimumRequiredFields = [
      //   ...this.baseMinimumRequiredFields,
      //   {
      //     field: "treeNmThin",
      //     label: "Species",
      //   },
      // ];
    }

    const id = selectedSpeciesFormGroup
      ? selectedSpeciesFormGroup.get("idRegimeSP").value
      : -1;

    const name = this.formGroup.get("treeNmThin").value;

    //EventQ in Events page species idSP = app idSP
    //EventQ in its own species idSP= idRegimeSP
    this.formGroup
      .get("idSP")
      .setValue(
        selectedSpeciesFormGroup
          ? selectedSpeciesFormGroup.get("idSP").value
          : null
      );

    this.selectSpecies({
      id: id,
      type: null,
      name: name,
    }); //apply species id so selector will work
  }

  private isClearingThinDest(formValue) {
    const k1Minus = Constants.k1Minus * 100; //convert to percetage

    return (
      +formValue.fracStemToDdwdThin +
        +formValue.fracStemToFuelThin +
        +formValue.fracStemToPaprThin +
        +formValue.fracStemToPackThin +
        +formValue.fracStemToFurnThin +
        +formValue.fracStemToFibrThin +
        +formValue.fracStemToConsThin +
        +formValue.fracStemToResiThin +
        +formValue.fracStemToSDdwdThin >=
        k1Minus &&
      +formValue.fracBranToDdwdThin +
        +formValue.fracBranToFuelThin +
        +formValue.fracBranToPaprThin +
        +formValue.fracBranToPackThin +
        +formValue.fracBranToFurnThin +
        +formValue.fracBranToFibrThin +
        +formValue.fracBranToConsThin +
        +formValue.fracBranToResiThin +
        +formValue.fracBranToSDdwdThin >=
        k1Minus &&
      +formValue.fracBarkToBlitThin +
        +formValue.fracBarkToFuelThin +
        +formValue.fracBarkToPaprThin +
        +formValue.fracBarkToResiThin +
        +formValue.fracBarkToSBlitThin >=
        k1Minus &&
      +formValue.fracLeafToLlitThin +
        +formValue.fracLeafToFuelThin +
        +formValue.fracLeafToSLlitThin >=
        k1Minus &&
      +formValue.fracCortToCodrThin +
        +formValue.fracCortToFuelThin +
        +formValue.fracCortToSCodrThin >=
        k1Minus &&
      +formValue.fracFirtToFidrThin + +formValue.fracFirtToSFidrThin >=
        k1Minus &&
      +formValue.fracAfctThin == 100
    );
  }

  public modifyBeforeSave(formGroup) {
    if (!formGroup) {
      return null;
    }

    let modifiedFormGroup = formGroup;
    if (modifiedFormGroup.get("clearEV")) {
      modifiedFormGroup
        .get("clearEV")
        .setValue(this.isClearingThinDest(modifiedFormGroup.getRawValue()));
    }
    return modifiedFormGroup;
  }

  public insertStandardValues(result: any, formData: any): void {
    this.formGroup.patchValue(formData);

    const selectedSpecies =
      this.simulationService.treesService.getSpeciesByIdRegimeSP(formData.idSP);
    const id = selectedSpecies?.idRegimeSP || -1;

    this.selectSpecies({ id: id, type: null, name: result.speciesName });

    //EventQ in Events page species idSP = app idSP
    //EventQ in its own species idSP= idRegimeSP
    this.formGroup.get("idSP").setValue(selectedSpecies.idSP);

    if (result.isInertEventName) {
      this.formGroup.get("nmEV").setValue(result.eventName);
    }
  }

  public writeXmlObject(formData): object {
    const baseThinFields = [
      "fracAfctThin",
      "userThisThinByLG",
      "clearAllRemainsF",
      "fracStemToDdwdExtraF",
      "fracBranToDdwdExtraF",
      "fracBarkToBlitExtraF",
      "fracLeafToLlitExtraF",
      "fracCortToCodrExtraF",
      "fracFirtToFidrExtraF",
      "replaceStemsThin",
      "useBioAgeAdjThin",
      "remvAvgAgeMultThin",
      "remvMaxAgeMultThin",
      "remvOffsetThin",
      "multStemThin",
      "multBranThin",
      "multBarkThin",
      "multLeafThin",
      "multFirtThin",
      "multCortThin",
      "boostYrsThin",
      "fixThin",
      "phaThin",
      "treeNmThin",
    ];
    const baseThinDestFields = [
      "fracStemToDdwdThin",
      "fracStemToFuelThin",
      "fracStemToPaprThin",
      "fracStemToPackThin",
      "fracStemToFurnThin",
      "fracStemToFibrThin",
      "fracStemToConsThin",
      "fracStemToResiThin",
      "fracStemToSDdwdThin",
      "fracBranToDdwdThin",
      "fracBranToFuelThin",
      "fracBranToPaprThin",
      "fracBranToPackThin",
      "fracBranToFurnThin",
      "fracBranToFibrThin",
      "fracBranToConsThin",
      "fracBranToResiThin",
      "fracBranToSDdwdThin",
      "fracBarkToBlitThin",
      "fracBarkToFuelThin",
      "fracBarkToPaprThin",
      "fracBarkToResiThin",
      "fracBarkToSBlitThin",
      "fracLeafToLlitThin",
      "fracLeafToFuelThin",
      "fracLeafToSLlitThin",
      "fracCortToCodrThin",
      "fracCortToFuelThin",
      "fracCortToSCodrThin",
      "fracFirtToFidrThin",
      "fracFirtToSFidrThin",
      "fracDdwdToFuelThin",
      "fracChwdToFuelThin",
      "fracBlitToFuelThin",
      "fracLlitToFuelThin",
      "fracSDdwdToFuelThin",
      "fracSChwdToFuelThin",
      "fracSBlitToFuelThin",
      "fracSLlitToFuelThin",
    ];

    const xmlObject = {
      $: {},
      notesEV: [],
      Thin: [{ $: {}, fracGradeThin: [",,,,"], ThinDest: [{ $: {} }] }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.Thin[0].$ = this.getFormValueObjectForXmlExport(
      baseThinFields,
      formData
    );

    xmlObject.Thin[0].ThinDest[0].$ = {
      id: "dest",
      ...this.getFormValueObjectForXmlExport(baseThinDestFields, formData),
    };

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
