import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { AddToScenarioPayload } from "src/app/plot/models";
import { BasePlotFormService } from "src/app/plot/services/base-plot-form.service";
import Utilities from "../utilities/utils";

@Injectable({
  providedIn: "root",
})
export class BaseEventFormService extends BasePlotFormService {
  public readonly isEventPage = true;

  public readonly baseMinimumRequiredFields = [
    { field: "dateEV", label: "Event date" },
    { field: "nYrsFromStEV", label: "Calendar years" },
    { field: "nDaysFromStEV", label: "Calendar days" },
    { field: "nmEV", label: "Event name" },
  ];

  //minimum required fields to save the form
  public minimumRequiredFields = [];

  public getFormModel() {
    return this.formModel;
  }

  public getSpeciesFormGroup(type: "tree" | "crop") {
    const service = type == "tree" ? "treesService" : "cropsService";
    return this.simulationService[service].getFormGroup();
  }

  public getSpeciesFormGroupByName(type: "tree" | "crop", name: string) {
    const service = type == "tree" ? "treesService" : "cropsService";
    return this.simulationService[service].getSpeciesFormGroupByName(name);
  }

  public getSpeciesFormGroupByIdRegimeSP(type: "tree" | "crop", id) {
    const service = type == "tree" ? "treesService" : "cropsService";
    return this.simulationService[service].getSpeciesFormGroupByIdRegimeSP(id);
  }

  public getSpeciesFormGroupByIdSP(type: "tree" | "crop", id) {
    const service = type == "tree" ? "treesService" : "cropsService";
    return this.simulationService[service].getSpeciesFormGroupByIdSP(id);
  }

  public getSelectedSpeciesFormGroup(type: "tree" | "crop") {
    const service = type == "tree" ? "treesService" : "cropsService";
    return this.simulationService[service].getSelectedSpeciesFormGroup();
  }

  public getSelectedSpeciesName(type: "tree" | "crop") {
    const formGroup = this.getSelectedSpeciesFormGroup(type);
    return formGroup.get("nmSP").value;
  }

  public getMinimumRequiredFieldTooltip(): string | null {
    const fieldName = this.getMinimumRequiredFieldName();
    if (fieldName) {
      return fieldName + " is required or invalid";
    }
    return "";
  }

  public shouldDisableSave(): boolean {
    if (!this.formGroup) {
      return false;
    }
    if (this.getMinimumRequiredFieldName()) {
      return true;
    }

    return false;
  }

  protected getMinimumRequiredFieldName(): string {
    for (const f of this.minimumRequiredFields) {
      const control = this.formGroup.get(f.field);
      // let hasRequiredValidator = false;
      // if (control && control.validator) {
      //   const validator = control.validator({} as AbstractControl);
      //   hasRequiredValidator = validator?.required;
      // }

      // const hasRequiredField = control?.hasError("required");
      if (control && control.invalid) {
        return f.label;
      }
    }

    return null;
  }

  //some event page might reqiure preset logic before it starts
  public setEventPageLogic(formData, isEventPage: boolean) {
    this.minimumRequiredFields = [...this.baseMinimumRequiredFields];
  }

  public insertStandardValues(result, formData): void {
    this.formGroup.patchValue(formData);

    if (this.formGroup.get("idSP")) {
      const selectedSpecies =
        this.simulationService.treesService.getSpeciesByIdRegimeSP(
          formData.idSP
        );

      //EventQ in Events page species idSP = app idSP
      //EventQ in its own species idSP= idRegimeSP
      this.formGroup.get("idSP").setValue(selectedSpecies.idSP);
    }

    if (result.isInertEventName) {
      this.formGroup.get("nmEV").setValue(result.eventName);
    }
  }

  public getBaseEventFieldsForXmlExport(): string[] {
    return [
      "tEV",
      "clearEV",
      "onEV",
      "dateOriginEV",
      "nYrsFromStEV",
      "nDaysFromStEV",
      "tFaqEV",
      "tAaqEV",
      "aqStYrEV",
      "aqEnYrEV",
      "nmEV",
      "categoryEV",
      "tEvent",
      "idSP",
      "regimeInstance",
      "nmRegime",
    ];
  }

  // public readonly baseFieldWatcher: FieldWatcher = {
  //   dateOriginEV: (newValue: any, { isEventPage }) => {
  //     if (!isEventPage) {
  //       return;
  //     }

  //     const calendarDateFields = ["dateEV"];
  //     const simulationStepFields = ["nYrsFromStEV", "nDaysFromStEV"];

  //     this[
  //       newValue == "Calendar"
  //         ? "addValidatorsInBulk"
  //         : "removeValidatorsInBulk"
  //     ](calendarDateFields, [Validators.required], this.getFormGroup());

  //     this[
  //       newValue == "StartSim"
  //         ? "addValidatorsInBulk"
  //         : "removeValidatorsInBulk"
  //     ](simulationStepFields, [Validators.required], this.getFormGroup());
  //   },
  // };

  public modifyBeforeSave(formGroup) {
    if (!formGroup) {
      return null;
    }

    let modifiedFormGroup = formGroup;
    if (modifiedFormGroup.get("clearEV")) {
      modifiedFormGroup.get("clearEV").setValue(false);
    }
    return modifiedFormGroup;
  }

  public addInputToDigestScenario(data: AddToScenarioPayload) {
    const { service, input, item, addToAllScenarios, itemValue } = data;

    const convertedItem = Utilities.cloneDeep(item);
    convertedItem.inputs = [input];

    const itemModel = this.formModel[input.programmingName];
    const formValue =
      itemValue || this.getFormGroup().get(input.programmingName).value;
    const validations = itemModel.validators;
    const control = new FormControl(formValue, validations);

    const eventId = service.getFormGroup().get("eventId").value;
    const eventFormGroup = this.simulationService.eventsService
      .getEventQ()
      .controls.find((fg) => fg.get("eventId").value == eventId);

    const regimeInstance = eventFormGroup.get("regimeInstance").value;
    const regimes = this.simulationService.eventsService.getRegimesFromEvents(
      this.simulationService.eventsService.getEventQ().controls
    );

    let regimeIndex = regimes.findIndex(
      (r) => r.get("regimeInstance").value == regimeInstance
    );

    if (regimeIndex < 0) {
      this.simulationService.messageService.addAlert({
        type: "danger",
        msg: "Regime not found when adding to Digest.",
        dismissible: true,
      });
      return;
    }
    regimeIndex += 1;

    const regimeName = eventFormGroup.get("nmRegime").value;
    const eventName = eventFormGroup.get("nmEV").value;
    const path = ["Event", `${regimeName} [${regimeIndex}]`, eventName];
    const option = `${regimeIndex},${eventName}`;

    this.simulationService.plotDigestService.setScenarioDetails({
      programmingName: input.programmingName,
      service: this,
      itemLabel: itemModel.label,
      control: control,
      path: path,
      option: option,
      type: "EventQ",
      inputItem: convertedItem,
      addToAllScenarios: addToAllScenarios,
      model: itemModel,
    });
  }
}
