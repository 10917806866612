<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>Select a standard event</h1>

    <ngx-datatable
      #table
      class="custom-table"
      [rows]="speciesEvents"
      [columnMode]="ColumnMode.force"
      [headerHeight]="56"
      [footerHeight]="56"
      rowHeight="auto"
      [limit]="10"
      [selected]="selectedRows"
      [selectionType]="SelectionType.checkbox"
      [selectAllRowsOnPage]="false"
      (select)="onSelect($event)"
    >
      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
        <ng-template ngx-datatable-header-template> </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              isSelected ? "Deselect" : "Select"
            }}</span>
            <input
              type="checkbox"
              [checked]="isSelected"
              (change)="onCheckboxChangeFn($event)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="40"
        [canAutoResize]="false"
        [resizeable]="false"
        [sortable]="false"
        name=""
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <span class="event-type" [class]="getEventClass(row)"></span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Species">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.speciesName }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Event name">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.eventName }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Event description">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.eventDescription }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="form-check">
      <input
        [(ngModel)]="isInertEventName"
        class="form-check-input"
        type="checkbox"
        value=""
        id="insert-event-name"
      />
      <label class="form-check-label" for="insert-event-name">
        Also insert the name of the standard event
      </label>
    </div>

    <div class="form-button-group">
      <button
        type="button"
        (click)="closeForm(null)"
        class="btn btn-outline-primary btn-sm"
      >
        Cancel
      </button>
      <button
        type="button"
        (click)="
          closeForm({
            isInertEventName: isInertEventName,
            eventName: selectedRows[0].eventName,
            speciesName: selectedRows[0].speciesName,
            data: selectedRows[0].data
          })
        "
        class="btn btn-outline-primary btn-sm"
        [disabled]="selectedRows.length !== 1"
      >
        Ok
      </button>
    </div>
  </div>
</div>
