export class Constants {
  public static readonly STRING_INPUT_VALIDATION_PATTERN = /^[a-zA-Z0-9_\- ]*$/;
  public static readonly k0Plus = 0.000000001; // 1.0e-9
  public static readonly k0PlusM = 0.00000000001; // k0Plus minus a bit! Definitely < k0Plus. 1.0e-11
  public static readonly kReadFlo = "%lg";
  public static readonly kfBig = 1e36; // Huge floating point number (assumes flo >= f32)
  public static readonly k0Minus = -Constants.k0Plus;
  public static readonly k1Plus = 1.0 + Constants.k0Plus;
  public static readonly k1Minus = 1.0 - Constants.k0Plus;
  public static readonly kPi = 3.14159265359;

  public static readonly APP_TITLE = "FullCAM";
  public static readonly PLOT_FORMATS = ["plo", "pld", "est"];

  public static readonly PLOT_DOCUMENT_TO_EXTENSION_MAP = {
    DocumentPlot: "plo",
    DocumentPlotDigest: "pld",
    DocumentEstate: "est",
  };
  public static readonly PLOT_EXTENSION_TO_DOCUMENT_MAP = {
    plo: "DocumentPlot",
    pld: "DocumentPlotDigest",
    est: "DocumentEstate",
  };

  public static readonly PLOT_FORMAT_MAP = {
    plo: "plot",
    pld: "plot digest",
    est: "estate",
  };

  public static readonly PLOT_TYPE_MAP = {
    SoilF: "Forest soil",
    CompF: "Forest system",
    SoilA: "Agricultural soil",
    CompA: "Agricultural system",
    CompM: "Mixed (forest and agricultural) system",
  };

  public static readonly ALL_FOREST_PLOT_TYPES = ["SoilF", "CompF", "CompM"];
  public static readonly ALL_AGRICULTURAL_PLOT_TYPES = [
    "SoilA",
    "CompA",
    "CompM",
  ];
  public static readonly ALL_SYSTEM_PLOT_TYPES = ["CompF", "CompA", "CompM"];
  public static readonly AGRICULTURAL_SYSTEM_PLOT_TYPES = ["CompA", "CompM"];
  public static readonly FOREST_SYSTEM_PLOT_TYPES = ["CompF", "CompM"];

  public static readonly TYF_CATEGORY_MAP = {
    Custom: "User defined",
    BeltH: "Belt plantings >1500 sph",
    BeltL: "Belt plantings <1500 sph",
    BlockES: "Block - environmental services",
    BlockLMG: "Block - regeneration systems",
    Water: "In riparian or floodplain zones",
    Short: "Short rotation plantations",
    Long: "Long rotation plantations",
    Block: "Block - Tree plantations established in block configuration",
    Belt: "Belt - Tree plantations established in belt configuration",
    BeltHW: "Belt plantings >1500 sph wide",
    BeltHN: "Belt plantings >1500 sph narrow",
  };
}
