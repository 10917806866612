import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";
import { RotationEvent, RotationForest } from "../../models";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";

@Injectable({
  providedIn: "root",
})
export class RotationTreePlantingService extends BaseRotationEventService {
  public _type = "RotationTreePlanting";

  private intensityDisplayMap = {
    Low: "Low stocking",
    Medium: "Average stocking",
    High: "High stocking",
  };

  public availableOpitions = [];
  public additionalFormControls = [
    {
      label: "Stocking Level",
      key: "stockingLevel",
      element: "select",
      getOptions: () => this.availableOpitions,
    },
  ];

  constructor(
    public rotationEvent: RotationEvent,
    public rotationFormGroup: AbstractControl<RotationForest>,
    public managementService: RmtManagementService
  ) {
    super(rotationEvent, rotationFormGroup, managementService);

    this.eventFormGroup.addControl(
      "stockingLevel",
      new FormControl(null, [Validators.required])
    );

    const stockingLevelGroup = Array.isArray(
      this.regimeEventReference?.Value?.StockingLevelGroups
    )
      ? this.regimeEventReference.Value.StockingLevelGroups
      : [
          this.regimeEventReference.Value.StockingLevelGroups
            .StockingLevelGroup,
        ];

    if (stockingLevelGroup?.length) {
      this.availableOpitions = stockingLevelGroup.map((slg) => {
        return {
          value: slg.StockingLevel,
          label: this.intensityDisplayMap[slg.StockingLevel],
        };
      });

      const defaultLevel = rotationEvent.StockingLevel;

      const defaultValue =
        stockingLevelGroup.find((slg) => slg.StockingLevel == defaultLevel)
          ?.StockingLevel || stockingLevelGroup[0].StockingLevel;

      this.eventFormGroup.get("stockingLevel").setValue(defaultValue);
    }
  }

  public getIntensity() {
    return (
      "Stocking Level: " +
        this.intensityDisplayMap[
          this.eventFormGroup.get("stockingLevel").value
        ] || ""
    );
  }

  override readXmlObject(originalUserRotationEvent) {
    let userRotationEvent = super.readXmlObject(originalUserRotationEvent);
    const stockingLevel = userRotationEvent.StockingLevel;
    this.eventFormGroup.get("stockingLevel").setValue(stockingLevel);
  }

  override writeXmlObject() {
    let template = super.writeXmlObject();
    template["StockingLevel"] = this.eventFormGroup.get("stockingLevel").value;

    if (
      !template.RegimeEventReferenceValue["StockingLevelGroups"]
        ?.StockingLevelGroup
    ) {
      template.RegimeEventReferenceValue["StockingLevelGroups"] = {
        StockingLevelGroup:
          template.RegimeEventReferenceValue["StockingLevelGroups"],
      };
    }

    return template;
  }
}
