<ng-container *ngIf="alerts.length">
  <div id="message-container" *ngFor="let alert of alerts">
    <alert
      [type]="alert.type"
      [dismissOnTimeout]="alert.timeout"
      [dismissible]="alert.dismissible"
      (onClosed)="messageService.onClosed(alert)"
      >{{ alert.msg }}</alert
    >
  </div>
</ng-container>

<div
  id="fullscreen-loading"
  [class]="{ 'fade-in': isFullScreenLoading$ | async }"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
