<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row row-margin">
    <span class="span-access"> {{ status }} User Access</span>
  </div>
  <div class="row row-margin">User Name : {{ name }}</div>
  <br />
  <!-- <div class="row row-margin">
    <label class="form-check-label">
      <input
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="canAccessRMT"
      />
      RMT Access
      <span
        *ngIf="canAccessRMT == true && status == 'Reject'"
        class="reject-role"
        ><i class="bi bi-x-square"></i>&nbsp; RMT Access Revoked</span
      >
      &nbsp;
      <span
        *ngIf="grantedAccessRMT == true"
        class="bi bi-joystick icon-grant"
      ></span>
    </label>
  </div>
  <div class="row row-margin">
    <label class="form-check-label">
      <input
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="canAccessFullCam2016"
      />
      2016 Access
      <span
        *ngIf="canAccessFullCam2016 == true && status == 'Reject'"
        class="reject-role"
        ><i class="bi bi-x-square"></i>&nbsp; 2016 Access Revoked</span
      >&nbsp;
      <span
        *ngIf="grantedAccessFullCam2016 == true"
        class="bi bi-joystick icon-grant"
      ></span>
    </label>
  </div> -->
  <div class="row row-margin">
    <label class="form-check-label">
      <input
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="canAccessFullCam2020"
        disabled
      />
      2020 Access
      <span
        *ngIf="canAccessFullCam2020 == true && status == 'Reject'"
        class="reject-role"
        ><i class="bi bi-x-square"></i>&nbsp; 2020 Access Revoked</span
      >&nbsp;
      <span
        *ngIf="grantedAccessFullCam2020 == true"
        class="bi bi-joystick icon-grant"
      ></span>
    </label>
  </div>
  <div class="row row-margin">
    <label class="form-check-label">
      <input
        type="checkbox"
        class="form-check-input"
        [(ngModel)]="canAccessFullCam2024"
        disabled
      />
      2024 Access
      <span
        *ngIf="canAccessFullCam2024 == true && status == 'Reject'"
        class="reject-role"
        ><i class="bi bi-x-square"></i>&nbsp; 2024 Access Revoked</span
      >&nbsp;
      <span
        *ngIf="grantedAccessFullCam2024 == true"
        class="bi bi-joystick icon-grant"
      ></span>
    </label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="Cancel()">
    Cancel
  </button>
  &nbsp;
  <button type="button" class="btn btn-outline-primary" (click)="Confirm()">
    Confirm
  </button>
</div>
