<ng-container [formGroup]="formGroupInstance">
  <div class="form-controls">
    <div class="left-controls">
      <div class="button-group" *ngIf="selectedRows.length > 1">
        <select
          class="form-control form-select form-select-sm"
          [(ngModel)]="selectedBulkAction"
          [ngModelOptions]="{ standalone: true }"
        >
          <option value="delete">Delete</option>
          <option value="clone" [disabled]="shouldDisableClone()">Clone</option>
        </select>
        <fc-button [inputText]="'Apply'" (click)="bulkAction()"></fc-button>
      </div>
    </div>
    <div class="right-controls">
      <button
        type="button"
        class="btn btn-primary-darken btn-sm"
        (click)="createEvent()"
      >
        New event
      </button>
    </div>
  </div>

  <ngx-datatable
    class="custom-table event-table"
    [rows]="events"
    [rowClass]="getRowClass"
    [columnMode]="ColumnMode.force"
    [headerHeight]="56"
    [footerHeight]="56"
    [rowHeight]="36"
    [limit]="50"
    [selected]="selectedRows"
    [selectionType]="SelectionType.checkbox"
    (select)="onSelect($event)"
    (sort)="onSort($event)"
    [sorts]="currentSort"
  >
    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <label class="check-container">
          <span class="visually-hidden">{{
            allRowsSelected ? "Uncheck all" : "Check all"
          }}</span>
          <input
            type="checkbox"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <label class="check-container">
          <span class="visually-hidden">{{
            isSelected ? "Deselect" : "Select"
          }}</span>
          <input
            type="checkbox"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [width]="40"
      [canAutoResize]="false"
      [resizeable]="false"
      [sortable]="false"
      name=""
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <span
          class="event-type"
          [class]="getEventClass(row)"
          [tooltip]="getEventType(row)"
          [adaptivePosition]="false"
          placement="right"
        ></span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [width]="110"
      [canAutoResize]="false"
      [resizeable]="false"
      name="Date"
    >
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        <span *ngIf="row.get('dateOriginEV').value == 'Calendar'">
          {{ getDateDisplayFormat(row.get("dateEV").value) }}
        </span>

        <span *ngIf="row.get('dateOriginEV').value == 'StartSim'">
          {{
            getSartSimDate(
              row.get("nYrsFromStEV").value,
              row.get("nDaysFromStEV").value
            )
          }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Name">
      <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
        {{ row.get("nmEV")?.value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [width]="150"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      name="Action"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="action-buttons">
          <button
            class="btn btn-sm btn-rounded"
            (click)="editEvent(row)"
            [adaptivePosition]="false"
            tooltip="Edit"
          >
            <span class="visually-hidden">edit</span>
            <i class="bi bi-pencil-fill"></i>
          </button>
          <button
            class="btn btn-sm btn-rounded"
            (click)="cloneEvents([row])"
            [adaptivePosition]="false"
            tooltip="Clone"
          >
            <span class="visually-hidden">clone</span>
            <i class="bi bi-files"></i>
          </button>

          <button
            class="btn btn-sm btn-rounded"
            (click)="deleteEvents([row])"
            [adaptivePosition]="false"
            tooltip="Delete"
          >
            <span class="visually-hidden">delete</span>
            <i class="bi bi-trash"></i>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-container>
