import { Component } from "@angular/core";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-rmt-management",
  templateUrl: "./rmt-management.component.html",
  styleUrls: ["./rmt-management.component.scss"],
})
//extends BaseFlowComponent
export class RmtManagementComponent extends BaseFlowComponent {}
