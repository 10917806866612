<header
  *ngIf="showHeader == true"
  fc-header
  [isPrivacyAccepted]="isPrivacyAccepted"
  [deleteUser]="deleteUser"
  [loginFirstTime]="loginFirstTime"
  [userClaim]="userClaim"
  id="header"
></header>
<nav
  *ngIf="isPrivacyAccepted == true && isUserBlocked == false"
  [userRoles]="userRole$ | async"
  fc-side-bar
  id="side-bar"
></nav>

<main
  *ngIf="isPrivacyAccepted == true && isUserBlocked == false"
  fc-main
  id="main"
  [class]="{ 'is-small-screen': (layoutService.isSmallScreen$ | async) }"
>
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</main>
<footer fc-footer id="footer"></footer>
<fc-messages></fc-messages>

<div *ngIf="showPrivacy" class="div-privacy">
  <div class="logo-container row">
    <img
      src="assets/images/DepartmentImage.png"
      aria-label="Government Crest"
      alt="Full Carbon Accounting Model"
      class="logo"
    />
  </div>
  <br />
  <fc-policy id="fcViewPrivacy"></fc-policy>
  <br />
  <div *ngIf="errCheck == true" class="div-err-privacy row col-12">
    Please Privacy terms and conditions
  </div>
  <br />
  <label class="form-check-label">
    <input
      type="checkbox"
      (change)="chkSelect()"
      class="form-check-input"
      [(ngModel)]="chkPolicyTerms"
    />
    I accept the privacy collection statement and conditions
  </label>

  <br />
  <label class="form-check-label">
    <input
      type="checkbox"
      (change)="chkSelect()"
      class="form-check-input"
      [(ngModel)]="chkPolicyTerms"
    />
    I Accept &nbsp;
    <a class="btn-link" (click)="openPolicyTerms(template)"
      >FullCAM Terms and Conditions</a
    >
  </label>
  <br />
  <br />
  <div class="row">
    <button
      id="btnAccept"
      class="btn btn-outline-primary btn-privacy"
      style="margin-left: 300px"
      (click)="Accept()"
    >
      Accept
    </button>
    &nbsp;
    <button
      id="btnReject"
      class="btn btn-outline-danger btn-privacy"
      (click)="Cancel()"
    >
      Cancel
    </button>
  </div>
  <br />
  <br />
  <br />
</div>

<div *ngIf="isPrivacyRejected" class="div-privacy-reject">
  <div class="row col-12">
    <div class="col-7">
      Thanks for your Time. Application will logoff in next
    </div>
    <div class="count-down col-4">{{ countDownSec }} secs</div>
  </div>
</div>

<div *ngIf="isUserBlocked" class="div-privacy-reject">
  <div class="col-12">
    Your Access is temporarily <span class="span-blocked">blocked</span>. Please
    contact Fullcam Admin
  </div>
  <br />
  <div class="col-12">
    Contact email -
    <a href="mailto: FullCam.Admin@dcceew.gov.au"
      >FullCam.Admin@dcceew.gov.au</a
    >
  </div>
</div>

<ng-template #template>
  <div class="modal-header role-template-header">
    <h4 class="modal-title pull-left">FullCAM Terms and Conditions</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <fc-terms-conditions id="fcTermsConitions"></fc-terms-conditions>
  <button
    type="button"
    class="btn btn-primary"
    aria-label="Accept"
    (click)="modalRef?.hide()"
  >
    Accept
  </button>
</ng-template>
