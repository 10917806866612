<ul class="list-group list-group-flush">
  <ng-container *ngFor="let category of categoryList">
    <li
      class="list-group-item scenario-list"
      *ngIf="shouldShowCateogy(category)"
    >
      <div class="list-content">
        <span>
          <button
            class="btn btn-sm"
            (click)="toggleCategory(category, expandedStateMap[category.label])"
          >
            <span class="visually-hidden">{{
              expandedStateMap[category.label] ? "collapse" : "expand"
            }}</span>
            <i
              class="bi bi-chevron-right"
              [class]="{
                expanded: expandedStateMap[category.label]
              }"
            >
            </i>
          </button>

          <a
            class="category-name"
            href="#"
            (click)="
              showControls(
                'controls',
                category.subcategoryMap,
                $event,
                category
              )
            "
            >{{ category.label }}</a
          >
        </span>
      </div>
      <ul
        class="list-group-flush subitem-list"
        [class]="{
          expanded: expandedStateMap[category.label]
        }"
      >
        <ng-container
          *ngTemplateOutlet="
            subItem;
            context: {
              category: category,
              parentPath: category.label,
              subcategoryMap: category.subcategoryMap
            }
          "
        ></ng-container>
      </ul>
    </li>
  </ng-container>
</ul>

<ng-template
  #subItem
  let-category="category"
  let-parentPath="parentPath"
  let-subcategoryMap="subcategoryMap"
>
  <ng-container
    *ngFor="
      let item of subcategoryMap
        | keyvalue : customSort(parentPath, subcategoryMap)
    "
  >
    <li
      class="list-group-item"
      *ngIf="item.key !== 'controls'"
      [class]="{ 'has-subItems': item.key !== 'controls' }"
    >
      <div class="toggle-button-container">
        <button
          class="btn btn-sm"
          (click)="toggleSubcategory(parentPath + '/' + item.key)"
        >
          <span class="visually-hidden">{{
            expandedStateMap[parentPath + "/" + item.key]
              ? "collapse"
              : "expand"
          }}</span>
          <i
            class="bi bi-chevron-right"
            [class]="{
              expanded: expandedStateMap[parentPath + '/' + item.key]
            }"
          >
          </i>
        </button>
        <a
          href="#"
          (click)="showControls(item.key, subcategoryMap, $event, category)"
          >{{ item.key }}</a
        >
      </div>

      <ul
        class="list-group list-group-flush subitem-list"
        *ngIf="item.key !== 'control' && item.value"
        [class]="{
          expanded: expandedStateMap[parentPath + '/' + item.key]
        }"
      >
        <ng-container
          *ngTemplateOutlet="
            subItem;
            context: {
              category: category,
              parentPath: parentPath + '/' + item.key,
              subcategoryMap: item.value
            }
          "
        ></ng-container>
      </ul>
    </li>

    <ng-container *ngIf="item.key == 'controls'">
      <li class="list-group-item" *ngFor="let control of item.value">
        <a
          href="#"
          class="control"
          (click)="showSingleControl(control, $event, category)"
          >{{ control.label }}
        </a>
        <!-- <fc-input-actions
          *ngIf="category.id !== 'plotDigest'"
          class="input-actions-container"
          [simulationService]="category.service.simulationService"
          [service]="category.service"
          [input]="control.input"
          [item]="control.item"
        ></fc-input-actions> -->
      </li>
    </ng-container>
  </ng-container>
</ng-template>
