import { Component, OnInit } from "@angular/core";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
})
export class ConfigurationComponent
  extends BaseFlowComponent
  implements OnInit
{
  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => {
      this.service.getConfigModels();
    }, 2000);
  }
}
