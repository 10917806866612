import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Map, MapboxOptions, MapMouseEvent, Marker } from "mapbox-gl";

import { environment } from "src/environments/environment";

@Component({
  selector: "fc-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, AfterViewInit {
  private _lat: number;
  private _lng: number;

  @Output() mapClickEvent = new EventEmitter<MapMouseEvent>();
  @Input("lat$") set lat$(value: number) {
    this._lat = value;
    this.validateLatLngInput();
  }
  @Input("lng$") set lng$(value: number) {
    this._lng = value;
    this.validateLatLngInput();
  }

  public map: Map;

  private marker: Marker;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.map = new Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [133, -23],
      zoom: 3,
      // accessToken: environment.mapBoxToken,
    });
    this.registerEvents();
  }

  protected registerEvents() {
    this.map.on("click", (e: MapMouseEvent) => {
      this.mapClickEvent.emit(e);
      this.addMarker([e.lngLat.lng, e.lngLat.lat]);
      this.map.resize();
      //this.map.invalidateSize();
    });

    this.map.on("move", (e) => {
      this.map.resize();
      //this.map.invalidateSize();
    });
  }

  private addMarker(lngLat: [number, number]) {
    if (this.marker) {
      this.marker.remove();
    }
    this.marker = new Marker().setLngLat(lngLat).addTo(this.map);
  }

  private validateLatLngInput() {
    if (this._lat && this._lng && this.map) {
      this.addMarker([this._lng, this._lat]);
    }
  }

  public updateSize() {
    this.map.resize();
  }
}
