import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ModalService } from "src/app/shared/services/modal.service";
import { AddRotationEventFormComponent } from "../add-rotation-event-form/add-rotation-event-form.component";
import { take } from "rxjs";
import TimeUtilities from "src/app/shared/utilities/time";
import { RmtManagementService } from "../../services/rmt-management.service";
import { RotationEventService } from "src/app/rmt/services/events/event.service";
import { TabDirective, TabsetComponent } from "ngx-bootstrap/tabs";
import Utilities from "src/app/shared/utilities/utils";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { RotationService } from "src/app/rmt/services/rotation.service";

@Component({
  selector: "fc-rotation-details",
  templateUrl: "./rotation-details.component.html",
  styleUrls: ["./rotation-details.component.scss"],
})
export class RotationDetailsComponent implements OnInit, AfterViewInit {
  private eventTable: DatatableComponent;

  private rotationService: RotationService;

  @Input() rotationFormGroup: FormGroup;
  @Input() managementService: RmtManagementService;
  @ViewChildren(TabsetComponent) rotationTabs!: QueryList<TabsetComponent>;
  @ViewChildren(DatatableComponent) tables!: QueryList<DatatableComponent>;

  public componentId = Utilities.uuid();

  public selectedRows = [];

  constructor(private modalService: ModalService) {
    this.getRowClass = this.getRowClass.bind(this);
  }
  ngOnInit(): void {
    this.rotationService = this.managementService.rotationService;
  }

  ngAfterViewInit() {
    this.assignEventTable();
    setTimeout(() => {
      //this.eventTable.rowDetail.collapseAllRows();
      const eventExpandedState = this.getEventExpandedState();
      this.eventTable.rows.forEach((r) => {
        if (eventExpandedState[r.id] === true) {
          this.eventTable.rowDetail.toggleExpandRow(r);
        }
      });
      const selectedTab = this.rotationService.getSelectedState().selectedTab;
      this.selectTab(selectedTab);
    }, 0);
  }

  private assignEventTable() {
    const targetId = "eventTable" + this.componentId;
    this.eventTable = this.tables.find(
      (table) => table.element.id === targetId
    );
  }

  public toggleExpandRow(row) {
    const eventExpandedState = this.getEventExpandedState();

    if (eventExpandedState[row.id] !== undefined) {
      eventExpandedState[row.id] = !eventExpandedState[row.id];
    } else {
      eventExpandedState[row.id] = true;
    }

    this.rotationService.setSelectedState({
      eventExpandedState: eventExpandedState,
    });
    console.log(eventExpandedState, "eventExpandedState");
    this.eventTable.rowDetail.toggleExpandRow(row);
  }

  public onSelect({ selected }): void {
    // this.selectedRows.splice(0, this.selectedRows.length);
    // this.selectedRows.push(...selected);
  }

  public getEventCollections(): RotationEventService[] {
    return this.rotationFormGroup.get("RotationEventCollection")?.value || [];
  }

  public getEventFormGroup(row: RotationEventService) {
    return row.eventFormGroup;
  }

  public openEventForm() {
    // MinDate is the start of this rotation
    // MaxDate, if there is a following rotation - it's start - 1 day.
    //          if there is NO following rotation - start of this rotation + (hard coded at 100 years)
    // bool isLastRotation = (_management.UserRotations.Last() == rotation);
    // Rotation nextRotation = _management.UserRotations.FirstOrDefault(r => r.Offset > rotation.Offset);

    // _addEventViewModel.MinDate = _management.StartDate.AddDays(rotation.Offset);
    // _addEventViewModel.MaxDate = (isLastRotation || nextRotation == null)
    //     ? _management.StartDate.AddDays(rotation.Offset).AddYears(100)
    //     : _management.StartDate.AddDays(nextRotation.Offset - rotation.Offset - 1);
    const moment = TimeUtilities.getMoment();
    const rotationService = this.managementService.rotationService;
    const isLastRotation = rotationService.isLastRotation(
      this.rotationFormGroup.get("Id").value
    );
    const offset = +this.rotationFormGroup.get("Offset").value;
    const nextRotation = this.managementService
      .getRotations()
      ?.controls.find((r) => r.get("Offset").value > offset);
    const startDate = this.managementService.getStartDate();
    const minDate = this.rotationFormGroup.get("RotationStartDate").value;

    let maxDate;

    if (isLastRotation || nextRotation == null) {
      maxDate = moment(startDate)
        .add(offset, "days")
        .add(100, "years")
        .toDate();
    } else {
      maxDate = moment(startDate)
        .add(+nextRotation.get("Offset").value - offset - 1, "days")
        .toDate();
    }

    const availableEvents =
      this.rotationFormGroup.get("RegimeReference")?.value?.Value
        .AvaliableEvents;
    const initialState: ModalOptions = {
      initialState: {
        managementService: this.managementService,
        rotationFormGroup: this.rotationFormGroup,
        availableEvents,
        minDate,
        maxDate,
      },
    };

    this.modalService
      .openModal(AddRotationEventFormComponent, {
        ...initialState,
        class: "full-screen",
      })
      .pipe(take(1));
  }

  public moveEvent(row, newDate: Date) {
    if (this.rotationService.isMovingEvent) {
      return;
    }
    const moment = TimeUtilities.getMoment();

    const rotationService = this.managementService.rotationService;
    const startOfEndDate = moment(this.managementService.getEndDate()).startOf(
      "day"
    );
    const oldDate = row.eventFormGroup.get("eventDate").value;
    const startOfNewDate = moment(newDate).startOf("day");
    const startOfOldDate = moment(oldDate).startOf("day");

    if (startOfNewDate.isSame(startOfOldDate, "day")) {
      return;
    }

    if (
      startOfNewDate.isAfter(startOfEndDate, "day") ||
      startOfOldDate.isAfter(startOfEndDate, "day")
    ) {
      return;
    }

    this.rotationService.isMovingEvent = true;

    if (!row.eventFormGroup.get("isOffset").value) {
      const offsetAdjustment = startOfNewDate.diff(startOfOldDate, "days");

      if (offsetAdjustment == 0) {
        this.rotationService.isMovingEvent = false;
        //when component/service created it triggers move event
        //it should take NO action if no date changes
        return;
      }

      row.offset += offsetAdjustment < 0 ? 0 : offsetAdjustment + 1;
      this.rotationService.isMovingEvent = false;
    } else {
      rotationService.moveEventWithOffset(
        this.rotationFormGroup,
        row,
        startOfNewDate.toDate(),
        startOfOldDate.toDate()
      );
    }
    setTimeout(() => {
      this.rotationService.applyEventSort(this.rotationFormGroup);
    }, 0);
  }

  public getEventMinDate(row) {
    if (row.eventFormGroup.get("isOffset").value) {
      return new Date(1900, 0, 1);
    }
    return this.rotationFormGroup.get("RotationStartDate").value;
  }

  public getRowClass(row) {
    if (row.eventFormGroup.invalid) {
      return {
        invalid: true,
      };
    }
    return { invalid: row.id == this.rotationService.errorEventId };
  }

  public getEventTabClass() {
    this.getEventCollections().forEach((e) => {
      if (
        e.eventFormGroup.invalid ||
        e.id == this.rotationService.errorEventId
      ) {
        return "invalid";
      }
    });

    if (
      this.rotationFormGroup.get("Id").value ==
      this.rotationService.erorrRotationId
    ) {
      return "invalid";
    }
    return "";
  }

  public onTabSelect(tab: TabDirective) {
    if (tab.id) {
      this.rotationService.setSelectedState({
        selectedTab: tab.id,
      });
    }
  }

  public selectTab(tabId: string) {
    if (this.rotationTabs) {
      this.rotationTabs.forEach((tabComp) => {
        const tabToSelect = tabComp.tabs.find((t) => t.id === tabId);

        if (tabToSelect) {
          tabToSelect.active = true;
        }
      });
    }
  }

  public getEventExpandedState() {
    return this.rotationService.getSelectedState().eventExpandedState;
  }

  public deleteEvent(row) {
    const rotationService = this.managementService.rotationService;
    rotationService.deleteRotationEvent(row.id, this.rotationFormGroup);
  }

  public onSort(event) {
    const sort = event.sorts;
    this.rotationService.setEventSort(sort);
    this.rotationService.applyEventSort(this.rotationFormGroup);
  }

  public getEventSort() {
    return this.rotationService.getEventSort();
  }
}
