import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "fc-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  @Input() firstName: string = "";

  @Input() lastName: string = "";

  public initials: string;

  constructor() {}

  ngOnInit(): void {
    // this.getInitials();
  }

  // public getInitials() {
  //   if (this.firstName && this.lastName) {
  //     const initials = this.firstName.charAt(0) + this.lastName.charAt(0);
  //     return initials.toUpperCase();
  //   }
  // }
}
