import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import {
  CollectionTableColumns,
  FolderList,
  FolderType,
  ProjectTableColumns,
} from "src/app/my-plots/models";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { Constants } from "../../constants";
import { MyRmdService } from "src/app/rmt/my-rmds/services/my-rmd.service";

@Component({
  selector: "fc-project-form",
  templateUrl: "./project-form.component.html",
  styleUrls: ["./project-form.component.scss"],
})
export class ProjectFormComponent implements OnInit {
  @Output() action = new EventEmitter();

  public isRMT: boolean = false;
  public isLoading: boolean = false;
  public isRequestSucceeded: boolean = false;

  /*** modal inputs ****/
  public lockProject: boolean = false;

  public formData: any;
  public type: FolderType;
  public associatedProject: ProjectTableColumns = null;
  public folders$: Observable<FolderList[]>;

  public formGroup: UntypedFormGroup;

  private responseResult: ProjectTableColumns | CollectionTableColumns = null;

  constructor(
    private myPlotsService: MyPlotsService,
    private myRmdService: MyRmdService
  ) {}

  ngOnInit(): void {
    let fields: any = {
      ...(this.type == "project" && {
        projectId: new UntypedFormControl(this.formData?.projectId || ""),
      }),
      name: new UntypedFormControl(this.formData?.name || "", [
        Validators.required,
        Validators.pattern(Constants.STRING_INPUT_VALIDATION_PATTERN),
        Validators.maxLength(510),
      ]),
      description: new UntypedFormControl(this.formData?.description || "", [
        Validators.required,
        Validators.maxLength(1000),
      ]),
    };
    if (this.type == "collection") {
      fields.collectionId = new UntypedFormControl(
        this.formData?.collectionId || ""
      );
      fields.projectId = new UntypedFormControl(
        this.associatedProject?.projectId || "",
        [Validators.required]
      );
    }

    this.formGroup = new UntypedFormGroup(fields);
  }

  public submitFolder(operation: "create" | "update"): void {
    let formData = this.formGroup.getRawValue();
    this.responseResult = null;
    this.isLoading = true;
    this.isRequestSucceeded = false;
    const targetService = this.isRMT ? this.myRmdService : this.myPlotsService;

    targetService[operation + "Folder"](this.type, formData)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.responseResult = response;
          this.isRequestSucceeded = true;
          this.isLoading = false;

          setTimeout(() => {
            this.closeForm();
          }, 1000);
        },
        (error) => {
          this.responseResult = null;
          this.isRequestSucceeded = false;
          this.isLoading = false;
        }
      );
    this.formGroup.markAsPristine();
    this.formGroup.valueChanges.pipe(take(1)).subscribe(() => {
      this.isRequestSucceeded = false;
    });
  }

  public closeForm() {
    this.action.emit(this.responseResult);
  }

  public isLoading$() {
    return of(this.isLoading);
  }
}
