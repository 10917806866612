import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-clone-scenario-form",
  templateUrl: "./clone-scenario-form.component.html",
  styleUrls: ["./clone-scenario-form.component.scss"],
})
export class CloneScenarioFormComponent extends BaseModalFormComponent {
  public scenarioName: string;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    numberOfClones: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(1),
    ]),
  });
}
