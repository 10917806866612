import { Component, OnInit } from "@angular/core";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-timing",
  templateUrl: "./timing.component.html",
  styleUrls: ["./timing.component.scss"],
})
export class TimingComponent extends BaseFlowComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
    // const isEstate =
    //   this.simulationService?.selectedPlotFormat$?.getValue() == "est";
    // if (isEstate) {
    //   this.service.formModel["dailyTimingTZ"].isShown = false;
    //   this.service.getFormGroup().get("dailyTimingTZ").setValue(false);
    // }
  }
}
