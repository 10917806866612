import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RmtProjectComponent } from "./rmt-project/rmt-project.component";
import { RmtManagementComponent } from "./rmt-management/rmt-management.component";
import { RmtResultsComponent } from "./rmt-results/rmt-results.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { RmtModifiersComponent } from "./rmt-modifiers/rmt-modifiers.component";
import { RmtEntryComponent } from "./simulation/rmt-entry/rmt-entry.component";
import { RotationTableComponent } from "./rmt-management/components/rotation-table/rotation-table.component";
import { AddRotationFormComponent } from "./rmt-management/components/add-rotation-form/add-rotation-form.component";
import { RotationDetailsComponent } from "./rmt-management/components/rotation-details/rotation-details.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { AddRotationEventFormComponent } from "./rmt-management/components/add-rotation-event-form/add-rotation-event-form.component";
import { SimulationResultComponent } from "./rmt-results/components/simulation-result/simulation-result.component";
import { MyRmdsComponent } from './my-rmds/my-rmds/my-rmds.component';

@NgModule({
  declarations: [
    RmtProjectComponent,
    RmtManagementComponent,
    RmtResultsComponent,
    RmtModifiersComponent,
    RmtEntryComponent,
    RotationTableComponent,
    AddRotationFormComponent,
    RotationDetailsComponent,
    AddRotationEventFormComponent,
    SimulationResultComponent,
    MyRmdsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    TabsModule.forRoot(),
  ],
})
export class RmtModule {}
