import { Injectable } from "@angular/core";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { FormLayout, LogEntry } from "../../models";
import { FormModel } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class Log2020Service extends BasePlotFormService {
  public readonly pageId = "log";
  public readonly pageHelpLink = null;

  public readonly logInfoMap = {
    NoMessage: {
      getMessage: (): string => {
        return "";
      },
    },
    FormatStr: {
      getMessage: (arg1, arg2, arg3): string => {
        let message = arg1;
        message = message.replace("%s", arg2);
        message = message.replace("%s", arg3);
        return message;
      },
    },
    Literal: {
      getMessage: (arg1, arg2, arg3): string => {
        return `Client received input '${arg1}' (${arg2}) value '${arg3}'`;
      },
    },
    CliRcvInput: {
      getMessage: (arg1, arg2, arg3): string => {
        return `Client received input '${arg1}' (${arg2}) value '${arg3}'`;
      },
    },
    ConfigChangeInput: {
      getMessage: (arg1, arg2, arg3): string => {
        return `Configuration change: '${arg1}' (${arg2}) set to '${arg3}'`;
      },
    },
    ConfigChangeOldNew: {
      getMessage: (arg1, arg2, arg3, arg4): string => {
        return `Configuration change: '${arg1}' (${arg2}) changed, was '${arg3}', now '${arg4}'`;
      },
    },
    DataBuilderVersAndURL: {
      getMessage: (arg1, arg2): string => {
        return `Databuilder Server version '${arg1}' and URL '${arg2}'`;
      },
    },
    DocConverted: {
      getMessage: (arg1, arg2): string => {
        return `Document converted: was ${arg1}, now ${arg2}`;
      },
    },
    DocCreated: {
      getMessage: (arg1): string => {
        return `${arg1} file created`;
      },
    },
    DocSave: {
      getMessage: (arg1, arg2, arg3): string => {
        return `${arg1} file saved as ${arg2} from ${arg3}`;
      },
    },
    ExplDropAgSpecies: {
      getMessage: (arg1): string => {
        return `Crop Species added via Explorer '${arg1}'`;
      },
    },
    ExplDropEvent: {
      getMessage: (arg1): string => {
        return `Event added via Explorer '${arg1}'`;
      },
    },
    ExplDropFrSpecies: {
      getMessage: (arg1): string => {
        return `Forest Species added via Explorer '${arg1}'`;
      },
    },
    ExplDropInput: {
      getMessage: (arg1, arg2, arg3): string => {
        return `Input '${arg1}' (${arg2}) value '${arg3}' added via Explorer`;
      },
    },
    SpaLocnDecodeErr: {
      getMessage: (arg1, arg2): string => {
        return `Location decode on server failed for lat '${arg1}', lon '${arg2}'`;
      },
    },
    SpaLocnErr: {
      getMessage: (arg1, arg2): string => {
        return `Location not found on server for lat '${arg1}', lon '${arg2}'`;
      },
    },
    SpaReceivesNPI: {
      getMessage: (arg1): string => {
        return `Client received NPI region from server: '${arg1}'`;
      },
    },
    SpaReceivesRegion: {
      getMessage: (arg1): string => {
        return `Client received growth calibration region from server: '${arg1}'`;
      },
    },
    SpaStateIBRAsoilErr: {
      getMessage: (arg1, arg2): string => {
        return `Warning, the state-IBRA-soil combination not found at lat '${arg1}', lon '${arg2}'`;
      },
    },
    SpaUsingLocn: {
      getMessage: (arg1, arg2): string => {
        return `Client using location lat '${arg1}', lon '${arg2}'`;
      },
    },
    SpaUsingRemap: {
      getMessage: (arg1, arg2, arg3): string => {
        return `Client location remapped to state '${arg1}', ibra '${arg2}', soil '${arg3}'`;
      },
    },
    SpaUsingStateIBRAsoil: {
      getMessage: (arg1, arg2, arg3): string => {
        return `Client location set by server: state '${arg1}', ibra '${arg2}', soil '${arg3}'`;
      },
    },
    UserAction: {
      getMessage: (arg1): string => {
        return `User action: ${arg1}`;
      },
    },
  };

  // {NoMessage,				klecInfo,	1,  -1,	""},					// Empty
  // {FormatStr,				klecInfo,	1,	-1,	""},      				// User given format string
  // {Literal,				klecInfo,	1,	 0,	"%s"}, 					// Literal
  // {CliRcvInput,			klecInfo,	1,	 3,	"Client received input '%s' (%s) value '%s'"},
  // {UserAction,			klecInfo,	1,	 1,	"User action: %s"},
  // {SpaLocnErr,			klecInfo,	1,	 2,	"Location not found on server for lat '%s', lon '%s'"},
  // {SpaUsingLocn,			klecInfo,	1,	 2,	"Client using location lat '%s', lon '%s'"},
  // {SpaStateIBRAsoilErr,	klecInfo,	1,	 2,	"Warning, the state-IBRA-soil combination not found at lat '%s', lon '%s'"},
  // {SpaLocnDecodeErr,		klecInfo,	1,	 2,	"Location decode on server failed for lat '%s', lon '%s'"},
  // {SpaUsingStateIBRAsoil,	klecInfo,	1,	 3,	"Client location set by server: state '%s', ibra '%s', soil '%s'"},
  // {SpaUsingRemap,			klecInfo,	1,	 3,	"Client location remapped to state '%s', ibra '%s', soil '%s'"},
  // {SpaReceivesNPI,		klecInfo,	1,	 1,	"Client received NPI region from server: '%s'"},
  // {ConfigChangeOldNew,	klecInfo,	1,	 4,	"Configuration change: '%s' (%s) changed, was '%s', now '%s'"},
  // {ConfigChangeInput,		klecInfo,	1,	 3,	"Configuration change: '%s' (%s) set to '%s'"},
  // {ExplDropFrSpecies,		klecInfo,	1,	 1,	"Forest Species added via Explorer '%s'"},
  // {ExplDropAgSpecies,		klecInfo,	1,	 1,	"Crop Species added via Explorer '%s'"},
  // {ExplDropEvent,			klecInfo,	1,	 1,	"Event added via Explorer '%s'"},
  // {ExplDropInput,			klecInfo,	1,	 3,	"Input '%s' (%s) value '%s' added via Explorer "},
  // {DocSave,				klecInfo,	1,	 3,	"%s file saved as '%s' from '%s'"},	// ie 1st arg Plot|sim|Spa
  // {DocCreated,			klecInfo,	1,	 1,	"%s file created"},		// ie 1st arg Plot|sim|Spa
  // {DocConverted,			klecInfo,	1,	 2,	"Document converted: was %s, now %s"},
  // {SpaReceivesRegion,		klecInfo,	1,	 1,	"Client received growth calibration region from server: '%s'"},
  // {DataBuilderVersAndURL,	klecInfo,	1,	 2,	"Databuilder Server version '%s' and URL '%s'"},
  // { FIDOProject, klecInfo, 1, 2, "FIDO Project: %s" },
  // { FIDOBaseline, klecInfo, 1, 2, "FIDO BaseLine: %s" },
  // { FIDOScenario, klecInfo, 1, 3, "FIDO Scenario: %s - %s" },
  // {Temp26,				klecInfo,	1,	 1,	"Temp26 %s"},
  // {Temp27,				klecInfo,	1,	 1,	"Temp27 %s"},
  // {Temp28,				klecInfo,	1,	 1,	"Temp28 %s"},
  // {Temp29,				klecInfo,	1,	 1,	"Temp29 %s"}

  public readonly layout: FormLayout = {
    label: "Log",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: null,
                element: "component",
                component: "LogListComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    logEntries: {
      defaultValue: [],
      validators: [],
      isShown: true,
    },
  };

  public setLog(logArguments: string[], logType: string): void {
    const timeStamp = Math.floor(new Date().getTime() / 1000);

    const logEntries: LogEntry[] = this.getFormGroup().get("logEntries").value;

    const newLog = {
      classLE: "Info",
      levelLE: "1",
      givenArgs: logArguments,
      tMsgLE: logType,
      timeStampLE: timeStamp,
    };

    this.getFormGroup()
      .get("logEntries")
      .setValue([...logEntries, newLog]);
  }

  public removeLastLog(): void {
    const logEntries: LogEntry[] = this.getFormGroup().get("logEntries").value;

    const removedlastLog = logEntries.pop();

    this.getFormGroup()
      .get("logEntries")
      .setValue([...logEntries]);
  }

  public readXmlObject(): void {
    super.readXmlObject();
    const template = this.simulationService.getPlotFileJson()["LogEntrySet"][0];
    if (!template?.LogEntry?.length) {
      return;
    }
    const logEntries = template.LogEntry.map((le) => {
      return {
        classLE: le.$.classLE,
        levelLE: le.$.levelLE,
        tMsgLE: le.$.tMsgLE,
        timeStampLE: le.$.timeStampLE,
        givenArgs: le.givenArgs[0].s.map((ga) => ga._),
      };
    });

    this.getFormGroup().get("logEntries").setValue(logEntries);
  }

  public writeXmlObject() {
    const logEntries: LogEntry[] = this.getFormGroup().get("logEntries").value;

    const convertedLogEntries = logEntries.map((le) => {
      return {
        $: {
          classLE: le.classLE,
          levelLE: le.levelLE,
          tMsgLE: le.tMsgLE,
          timeStampLE: le.timeStampLE,
        },
        givenArgs: {
          $: { count: le.givenArgs.length },
          s: le.givenArgs.map((arg, index) => {
            return {
              $: { id: index },
              _: arg || "",
            };
          }),
        },
      };
    });

    return {
      LogEntrySet: [
        { $: { count: logEntries.length }, LogEntry: convertedLogEntries },
      ],
    };
  }
}
