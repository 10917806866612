import { Injectable } from "@angular/core";
import { AgriculturalPlowing2020Service } from "./agricultural-plowing-2020.service";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { FormModel } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class AgriculturalPlowing2024Service extends AgriculturalPlowing2020Service {
  protected plowEventModifiersGroup: FormGroupElement = {
    label: "Plow event modifiers",
    isShown: true,
    isRoot: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Plow event modifiers",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", ""],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Plow impact duration (days)",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "durationPlow",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Plow impact multiplier",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "impactMultiPlow",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Soil pool decay multiplier",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "sPDecayMultiPlow",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  };

  protected directTransferOfDebrisToSoilGroup: FormGroupElement = {
    label: "Direct transfer of debris to soil (%)",
    isShown: true,
    isRoot: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "PDirect transfer of debris to soil (%)",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", ""],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds and fruit",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "dTFracGbfrSoilA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dTFracStlkSoilA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dTFracLeafSoilA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dTFracCortSoilA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dTFracFirtSoilA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  };

  protected directTransferOfSoilToAtmosphereGroup: FormGroupElement = {
    label: "Direct transfer of soil to atmosphere (%)",
    isShown: true,
    isRoot: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Destination percentages emitted to Atmosphere",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", ""],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "DPM",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDpmaToAtmsPlowA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "RPM",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRpmaToAtmsPlowA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "BIO-F",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBiofToAtmsPlowA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "BIO-S",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBiosToAtmsPlowA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "HUM",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracHumToAtmsPlowA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  };

  public layout: FormLayout = {
    label: "Plow",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Percentage of agricultural system that is plowed",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctPlow",
              },
            ],
          },
        ],
      },
      this.plowEventModifiersGroup,
      this.directTransferOfDebrisToSoilGroup,
      this.directTransferOfSoilToAtmosphereGroup,
    ],
  };

  public formModel: FormModel = {
    ...this.formModel,
    durationPlow: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Plow duration",
      tIn: 2202,
      systemType: 1,
      std: 2,
      unit: "days",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 1,
      low: 0,
      high: 365,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    impactMultiPlow: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Plow impact multiplier",
      tIn: 2203,
      systemType: 0,
      std: 1,
      unit: "",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    sPDecayMultiPlow: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],

      label: "Soil pool decay multiplier",
      tIn: 2204,
      systemType: 0,
      std: 1,
      unit: "",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    dTFracGbfrSoilA: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],

      label: "direct transfer of grains, buds and fruits to soil",
      tIn: 2205,
      systemType: 0,
      std: 1,
      unit: "%",
      prefixId: 120,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 100,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    dTFracStlkSoilA: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],

      label: "direct transfer of stalk to soil",
      tIn: 2206,
      systemType: 0,
      std: 1,
      unit: "%",
      prefixId: 120,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 100,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    dTFracLeafSoilA: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],

      label: "direct transfer of leaf to soil",
      tIn: 2207,
      systemType: 0,
      std: 1,
      unit: "%",
      prefixId: 120,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 100,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    dTFracCortSoilA: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],

      label: "direct transfer of coarse root to soil",
      tIn: 2208,
      systemType: 0,
      std: 1,
      unit: "%",
      prefixId: 120,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 100,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
    dTFracFirtSoilA: {
      isShown: true,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],

      label: "direct transfer of fine root to soil",
      tIn: 2209,
      systemType: 0,
      std: 1,
      unit: "%",
      prefixId: 120,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 164,
      sortId: 0,
      isSubSame: false,
      dataType: 0,
      low: 0,
      high: 1,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 100,
      isOneMore: false,
      columnWidth: 70,
      categoryLabel: "Forest thinning event",
      categoryName: "EvThin",
      spec: 0,
      eventId: 4,
    },
  };
}
