import { Component, OnInit, HostBinding } from "@angular/core";
import { LayoutService } from "../../layout.service";

@Component({
  selector: "fc-drawer",
  templateUrl: "./drawer.component.html",
  styleUrls: ["./drawer.component.scss"],
})
export class DrawerComponent implements OnInit {
  @HostBinding("class.show") isShown: boolean = false;

  constructor(private layoutService: LayoutService) {}

  ngOnInit(): void {
    this.layoutService.isDrawerOpen$.pipe().subscribe((isOpen) => {
      this.isShown = isOpen;
    });
  }

  public collapesDrawer() {
    this.layoutService.toggleDrawer();
  }

  public toggleDrawer() {
    this.layoutService.toggleDrawer();
  }
}
