import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { RmtManagementService } from "../../services/rmt-management.service";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { Observable, Subject, auditTime, take, takeUntil } from "rxjs";
import {
  AvailableSpecies,
  Rotation,
  RotationForest,
  SiteInfoResponse,
} from "src/app/rmt/models";
import { RmtProjectService } from "src/app/rmt/rmt-project/services/rmt-project.service";
import { SimulationService } from "src/app/shared/models";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ModalService } from "src/app/shared/services/modal.service";
import { AddRotationFormComponent } from "../add-rotation-form/add-rotation-form.component";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import TimeUtilities from "src/app/shared/utilities/time";
import moment from "moment";
import { RotationService } from "src/app/rmt/services/rotation.service";

@Component({
  selector: "fc-rotation-table",
  templateUrl: "./rotation-table.component.html",
  styleUrls: ["./rotation-table.component.scss"],
})
export class RotationTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  simulationService: SimulationService;

  @ViewChild("rotationTable") table: any;

  private readonly destroy$ = new Subject<void>();

  private projectService: RmtProjectService;

  private _moment: typeof moment;

  public readonly minStartDate = new Date(1900, 0, 1);
  public readonly maxEndDate = new Date(2999, 11, 31);

  public managementService: RmtManagementService;
  public rotationService: RotationService;

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  public siteInfoData$: Observable<SiteInfoResponse>;

  public selectedRows: FormControl<Rotation>[] = [];

  public rotations: RotationForest[] = [];

  constructor(private modalService: ModalService) {
    this.getRowClass = this.getRowClass.bind(this);
  }
  ngOnInit(): void {
    this._moment = TimeUtilities.getMoment();

    this.projectService = this.simulationService.projectService;
    this.managementService = this.simulationService.managementService;
    this.rotationService = this.managementService.rotationService;

    this.siteInfoData$ = this.projectService.siteInfoData$;

    this.managementService
      .getFormGroup()
      .get("startDate")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getRotations()?.controls.forEach((fb) => {
          const newStartDate = this.getRotationStartDate(fb.value);
          fb.get("RotationStartDate").setValue(newStartDate);
        });
      });

    this.getRotations()
      .valueChanges.pipe(auditTime(100), takeUntil(this.destroy$))
      .subscribe((newRotations) => {
        setTimeout(() => {
          this.rotations = [...newRotations];
          this.updateExpandedState();
          this.rotationService.checkRotationEvents();
        }, 0);
      });

    this.rotations = this.managementService.getRotations().value;
  }

  ngAfterViewInit(): void {
    this.updateExpandedState();
  }

  private updateExpandedState() {
    const rotationExpandedState = this.getRotationExpandedState();
    //this.table.rowDetail.collapseAllRows();
    setTimeout(() => {
      this.table.rows.forEach((r) => {
        if (rotationExpandedState[r.Id] === true) {
          this.table.rowDetail.toggleExpandRow(r);
        }
      });
    }, 0);
  }

  public getFormGroup(): FormGroup {
    return this.managementService.getFormGroup();
  }

  public addNewRotation(availableSpecies: AvailableSpecies[]) {
    const initialState: ModalOptions = {
      initialState: {
        availableSpecies,
        managementService: this.managementService,
      },
    };

    this.modalService
      .openModal(AddRotationFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe();
  }

  public getRotations() {
    return this.managementService.getRotations();
  }

  public getRowClass(row: RotationForest) {
    return { invalid: row.Id == this.rotationService?.erorrRotationId };
  }

  public getRotationFormGroup(
    row: RotationForest
  ): AbstractControl<RotationForest> {
    return this.getRotations().controls.find(
      (r) => r.get("Id").value == row.Id
    );
  }

  private getRotationStartDate(row) {
    const startDate = this.managementService.getStartDate();
    const offset = this.getRotationFormGroup(row).get("Offset").value;
    if (startDate) {
      return this._moment(startDate).add(offset, "days").toDate();
    }
    return null;
  }

  public onSelect({ selected }): void {
    // this.selectedRows.splice(0, this.selectedRows.length);
    // this.selectedRows.push(...selected);
  }

  public getRotationExpandedState() {
    return this.rotationService.getSelectedState().rotationExpandedState;
  }

  public toggleExpandRow(row: RotationForest) {
    let rotationExpandedState = this.getRotationExpandedState();

    if (rotationExpandedState[row.Id] !== undefined) {
      rotationExpandedState[row.Id] = !rotationExpandedState[row.Id];
    } else {
      rotationExpandedState[row.Id] = true;
    }

    this.rotationService.setSelectedState({
      rotationExpandedState: rotationExpandedState,
    });

    this.table.rowDetail.toggleExpandRow(row);
  }

  public appendRotation(row: Rotation) {}

  public cloneRotation(row: RotationForest) {
    this.rotationService.cloneRotation(row);
  }

  public deleteRotations(rows: RotationForest[]) {
    this.rotationService.deleteRotations(rows);
  }

  public resetRotation(row: RotationForest) {
    this.rotationService.resetRotation(row);
  }

  public onSort(event) {
    const sort = event.sorts;
    this.rotationService.setRotationSort(sort);
    this.rotationService.applyRotationSort();
  }

  public getRotationSort() {
    return this.rotationService.getRotationSort();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
