import {
  Component,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { FormLayout } from "src/app/plot/models";
import { SimulationService, SpeciesEventTypeMap } from "../../models";
import { InsertSpeciesValuesFormComponent } from "../insert-species-values-form/insert-species-values-form.component";
import * as _ from "lodash";

@Component({
  selector: "fc-event-forms",
  templateUrl: "./event-forms.component.html",
  styleUrls: ["./event-forms.component.scss"],
})
export class EventFormsComponent implements OnInit {
  public readonly eventHelpUrl = "137_Event%20Window.htm";

  public destroySubject$ = new Subject<void>();
  @Output() action = new EventEmitter();

  @ViewChild("componentHost", { read: ViewContainerRef })
  componentHost: ViewContainerRef;
  //event page will have some custom logics to perform
  @HostBinding("class.event-form") isEventPage: boolean = false;

  //modal input
  public simulationService: SimulationService;
  public formData: any;
  public speciesType: "tree" | "crop";

  public speciesEventTypeMap: { [key: string]: SpeciesEventTypeMap };
  public speciesEventTypes: SpeciesEventTypeMap[];
  public selectedEventType: SpeciesEventTypeMap;

  public startDate: Date;

  public service: any;
  public formGroup: UntypedFormGroup;
  public layout: FormLayout;

  constructor() {}

  ngOnInit(): void {
    this.speciesEventTypeMap =
      this.simulationService.eventFormsService.getAvailableEventTypes(
        this.speciesType || this.getAvailableEventTypesInput()
      );

    const order = ["tree", "crop", "mixed"];

    this.speciesEventTypes = Object.entries(this.speciesEventTypeMap)
      .map((et) => {
        return { ...et[1], key: et[0] };
      })
      .sort((x, y) => order.indexOf(x.type) - order.indexOf(y.type));

    if (this.formData) {
      const eventType = this.formData.tEV;
      if (eventType) {
        const eventTypeString = eventType;
        this.applyEvent(eventTypeString);
      }
    } else {
      const defaultEvent = this.getDefaultEventType();
      this.applyEvent(defaultEvent);
    }
  }

  protected getPlotType(): string {
    return this.simulationService.configurationService
      .getFormGroup()
      .get("tPlot").value;
  }

  protected getAvailableEventTypesInput(): "tree" | "crop" | null {
    const plotType = this.getPlotType();
    if (plotType == "CompF") {
      return "tree";
    } else if (plotType == "CompA") {
      return "crop";
    } else if (plotType == "CompM") {
      return null;
    }

    return null;
  }

  protected getDefaultEventType(): "PlnF" | "PlnA" {
    const plotType = this.getPlotType();

    if (plotType == "CompF" || plotType == "CompM") {
      return "PlnF";
    }

    return "PlnA";
  }

  protected applyEvent(eventType) {
    this.service =
      this.simulationService.eventFormsService.getEventService(eventType);
    this.service.setSimulationService(this.simulationService);

    //component host is used for popup or dynamic components
    if (this.service.componentHost === null) {
      this.service.setComponentHost(this.componentHost);
    }

    //this.selectedEventType = this._eventTypesMap[eventType];
    this.formGroup = this.service.getFormGroup();
    if (this.formData && this.formData.tEV == eventType) {
      this.formGroup.patchValue(this.formData);
    } else {
      //apply madatroy data for the formGroup
      this.formGroup.get("tEV").setValue(eventType);
    }

    //new event form logic
    if (!this.formData && this.isEventPage) {
      this.formGroup.get("onEV").setValue(true);
      this.formGroup.get("dateOriginEV").setValue("Calendar");
      this.formGroup.get("tEV").setValue(eventType);
    }

    this.layout = this.service.layout;

    this.service.setEventPageLogic(
      this.formGroup?.getRawValue(),
      this.isEventPage
    );

    setTimeout(() => {
      this.destroySubject$.next();
      this.service.watchFields(
        this.destroySubject$,
        {
          formGroup: this.formGroup,
          formModel: this.service.getFormModel(),
          layout: this.layout,
          fieldChangedEmitter: null,
          isEventPage: this.isEventPage, // only available in events services
        },
        this.service.baseFieldWatcher //base fields for all the events
      );
    }, 0);
  }

  public changeEventType(): void {
    this.applyEvent(this.formGroup.get("tEV")?.value);
  }

  public getEventTypeName(eventName: string): "mixed" | "tree" | "crop" {
    if (this.speciesEventTypeMap && this.speciesEventTypeMap[eventName]) {
      return this.speciesEventTypeMap[eventName].type;
    }
    return null;
  }

  public generateName() {
    const eventName =
      this.simulationService.eventFormsService.getEventDescription(
        this.formGroup.getRawValue()
      );
    this.formGroup.get("nmEV").setValue(eventName);
  }

  public insertStandardValues() {
    const speciesService =
      this.formGroup.get("tEvent").value == "SpecF"
        ? this.simulationService.treesService
        : this.simulationService.cropsService;
    const initialState: ModalOptions = {
      initialState: {
        eventFormsService: this.simulationService.eventFormsService,
        eventType: this.formGroup.get("tEV")?.value,
        species: speciesService.getSpecies().value,
      },
    };

    this.simulationService.modalService
      .openModal(InsertSpeciesValuesFormComponent, {
        ...initialState,
        class: "full-screen",
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          //exclue some timing fields
          const excludedFields = [
            "onEV",
            "dateOriginEV",
            "dateEV",
            "nYrsFromStEV",
            "nDaysFromStEV",
            "notesEV",
            "eventId",
          ];
          const formData = _.omit(result.data, excludedFields);

          this.service.insertStandardValues(result, formData);
        }
      });
  }

  public shouldDisableInsertButton(): boolean {
    return this.simulationService.eventsService.shouldDisableInsertStandardValuesButton(
      this.formGroup.get("tEV")
        ? this.formGroup.get("tEV").value
        : this.getDefaultEventType()
    );
  }

  public closeForm(formGroupValue) {
    let modifiedFormGroupValue = formGroupValue;

    if (formGroupValue) {
      modifiedFormGroupValue = this.service.modifyBeforeSave(formGroupValue);
    }

    this.action.emit(modifiedFormGroupValue);
  }
}
