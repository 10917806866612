import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/operators";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'fc-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  public policyHTML: SafeHtml = null;
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.httpClient.get('assets/templates/PrivacyCollectionStatementFullCAMApplication(External).html', { responseType: 'text' }).pipe(take(1)).subscribe(htmlContent => {
      this.policyHTML = this.sanitizeHtml(htmlContent);
    });

  }

  sanitizeHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
