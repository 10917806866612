import { Component, OnInit } from "@angular/core";
import { RegimeEvent, RotationEvent } from "src/app/rmt/models";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";
import TimeUtilities from "src/app/shared/utilities/time";
import { RmtManagementService } from "../../services/rmt-management.service";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "fc-add-rotation-event-form",
  templateUrl: "./add-rotation-event-form.component.html",
  styleUrls: ["./add-rotation-event-form.component.scss"],
})
export class AddRotationEventFormComponent
  extends BaseModalFormComponent
  implements OnInit
{
  private managementService: RmtManagementService;
  private rotationFormGroup: FormGroup;

  private availableEvents: RotationEvent[] = [];
  public sortedEvents: RotationEvent[] = [];
  public selectedRows: RegimeEvent[] = [];
  public eventDate: Date = null;
  public minDate: Date;
  public maxDate: Date;

  public sort = [{ prop: "group", dir: "asc" }];

  ngOnInit(): void {
    this.sortedEvents = [...this.availableEvents];
    this.applySortFilters({ sorts: this.sort });
  }

  public isSelected(row) {
    return this.selectedRows.includes(row);
  }

  public onSelect({ selected }) {
    if (selected && selected.length > 0) {
      // Clear previously selected rows except the current one
      this.selectedRows = selected.slice(-1); // Only keep the last selected item
    }
  }

  public addEvent(): void {
    const regimeEvent = this.selectedRows[0];
    const eventDate = this.eventDate;
    const moment = TimeUtilities.getMoment();
    const rotationService = this.managementService.rotationService;
    const rotationOffset = this.rotationFormGroup.get("Offset").value;
    const simStartDate = this.managementService.getStartDate();

    const rotationStartDateObject = moment(simStartDate).add(
      rotationOffset,
      "days"
    );

    const rotationEvent: RotationEvent = {
      $type: regimeEvent.$type.replace("Regime", "Rotation"),
      Offset: moment(eventDate).diff(rotationStartDateObject, "days") + 1,
      Year: 0,
      Day: 0,
      OffsetSubsequentOnDateChange: false,
      RegimeEventReference: {
        Id: regimeEvent.Id,
        Name: regimeEvent.Name,
        Value: regimeEvent,
      },
      IsClearing: false,
    };

    const newRotationEvent = rotationService.cloneRotationEvent(
      rotationEvent,
      this.rotationFormGroup
    );

    const rotationEventCollection = this.rotationFormGroup.get(
      "RotationEventCollection"
    );

    rotationEventCollection.setValue([
      ...rotationEventCollection.value,
      newRotationEvent,
    ]);

    rotationService.applyEventSort(this.rotationFormGroup);

    this.managementService.simulationService.messageService.addAlert({
      type: "success",
      msg: "New event has been added.",
      timeout: 2000,
    });
  }

  public applySortFilters(event) {
    this.sort = event.sorts;
    const propMap = {
      group: "Group",
      name: "Name",
    };

    const prop = propMap[event.sorts[0].prop];
    const dir = event.sorts[0].dir;

    const sortedEvents = this.availableEvents.sort((a, b) => {
      const valueA = a[prop];
      const valueB = b[prop];

      if (valueA < valueB) return dir === "asc" ? -1 : 1;
      if (valueA > valueB) return dir === "asc" ? 1 : -1;
      return 0;
    });

    this.sortedEvents = [...sortedEvents];
  }
}
