import { Component, Input } from "@angular/core";
import { UserRole } from "../../user-role/role-access/model";

@Component({
  selector: "[fc-side-bar]",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent {
  @Input() userRoles: UserRole;
}
