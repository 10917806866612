import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  private readonly modifierColumnWidthSrouce$ = new BehaviorSubject<number>(
    null
  );

  private readonly screenSizeBreakpoint = 1200;

  public modifierColumnWidth$ = this
    .modifierColumnWidthSrouce$ as Observable<number>;

  public formContainerHeight$ = new BehaviorSubject<number>(null);
  public mainHeight$ = new BehaviorSubject<number>(null);
  public mainWidth$ = new BehaviorSubject<number>(null);
  public isSmallScreen$ = new BehaviorSubject<boolean>(false);

  public isDrawerOpen$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  public toggleDrawer() {
    this.isDrawerOpen$.next(!this.isDrawerOpen$.getValue());
  }

  public watchLayout() {
    window.onresize = (event) => {
      this.getLayoutSizes();
    };
  }

  public forceUpdateLayoutSizes(): void {
    this.getLayoutSizes();
  }

  protected getLayoutSizes(): void {
    this.calculateModifiersColumn();
    this.getFormContainerHeight();
    this.getMainHeightAndWidth();
    this.getScreenType();
  }
  protected getFormContainerHeight(): void {
    const form = document.querySelector(".multisteps-form");
    if (form) {
      this.formContainerHeight$.next(form.clientHeight);
    }
  }

  protected getMainHeightAndWidth(): void {
    const main = document.querySelector("#main");
    if (main) {
      this.mainHeight$.next(main.clientHeight);
      this.mainWidth$.next(main.clientWidth);
    }
  }

  protected calculateModifiersColumn(): void {
    const twoRem = 32;
    const modifiersColumn =
      document.querySelector("fieldset.modifiers-col")?.clientWidth + twoRem ||
      0;
    this.modifierColumnWidthSrouce$.next(modifiersColumn);
  }

  protected getScreenType() {
    const windowWidth = window.innerWidth;
    this.isSmallScreen$.next(windowWidth < this.screenSizeBreakpoint);
  }
}
