<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>Select your management details</h1>
    <form [formGroup]="formGroup">
      <div class="input-container">
        <select
          id="species-select"
          class="form-control form-select form-select-sm"
          formControlName="selectedSpecies"
        >
          <option
            *ngFor="let species of availableSpecies"
            [ngValue]="species"
            [class]="getSpeciesClass(species.Name)"
          >
            {{ species.Name }}
          </option>
        </select>
      </div>

      <div class="input-container" *ngIf="speciesRegimes?.length">
        <select
          id="regime-select"
          class="form-control form-select form-select-sm"
          formControlName="selectedRegime"
        >
          <option
            *ngFor="let regime of speciesRegimes || []"
            [ngValue]="regime"
          >
            {{ regime.Name }}
          </option>
        </select>
      </div>

      <div class="form-button-group inline-form-element">
        <button
          type="button"
          (click)="closeForm(null)"
          class="btn btn-outline-primary btn-sm"
        >
          Close
        </button>
        <button
          type="button"
          (click)="addRotation()"
          class="btn btn-outline-primary btn-sm"
          [disabled]="formGroup.pristine || formGroup.invalid"
        >
          Add Rotation
        </button>
      </div>
    </form>
  </div>
</div>
