<div >
  <fc-policy id="fcViewPrivacy"></fc-policy>
  <br /><br />
  <label class="form-check-label">
    <input type="checkbox" class="form-check-input" checked="checked" disabled="disabled" />
    I accept the privacy collection and conditions
  </label><br />
  <label class="form-check-label">
    <input type="checkbox" class="form-check-input" checked="checked" disabled="disabled" />
    I Accept &nbsp;
    <a class="btn-link" (click)="openPolicyTerms(template)">FullCAM Terms and Conditions</a>
  </label>
</div>

<ng-template #template>
  <div class="modal-header role-template-header">
    <h4 class="modal-title pull-left">FullCAM Terms and Conditions</h4>
    <button type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <fc-terms-conditions id="fcViewTermsConition"></fc-terms-conditions>
  <button type="button"
          class="btn btn-primary"
          aria-label="Accept"
          (click)="modalRef?.hide()">
    Accept
  </button>
</ng-template>
