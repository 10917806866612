import { Component, OnInit } from "@angular/core";
import { User } from "../../models/user-management";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { FormControl, FormGroup } from "@angular/forms";
import { ModalService } from "src/app/shared/services/modal.service";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
})
export class UserProfileComponent
  extends BaseModalFormComponent
  implements OnInit
{
  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  //demo
  public requestHistory = [
    {
      id: "123",
      createdAt: 1710745236,
      // 'pending', 'approved', 'rejected', 'cancelled'
      product: "FullCAM 2024",
      reason: "",
      history: [
        {
          createdBy: "takchun.choi1@dcceew.gov.au",
          createdAt: 1710840000,
          type: "cancelled",
          reason: "Decided to opt for a different product",
        },
        {
          createdBy: "takchun.choi1@dcceew.gov.au",
          createdAt: 1710745236,
          type: "pending",
          reason: "Need for project X",
        },
      ],
    },
  ];

  public userProfile: User;
  public formGroup: FormGroup;

  public isModifyAccess = false;

  public isSaving = false;

  public historyStatusTypeMap = {
    cancelled: "Cancelled",
    pending: "Requested",
  };

  constructor(private modalService: ModalService) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      canAccessFullCam2020: new FormControl<boolean>({
        value: this.userProfile?.canAccessFullCam2020 || false,
        disabled: this.isModifyAccess == false,
      }),
      canAccessFullCam2024: new FormControl<boolean>({
        value: this.userProfile?.canAccessFullCam2024 || false,
        disabled: this.isModifyAccess == false,
      }),
      canAccessRMT: new FormControl<boolean>({
        value: this.userProfile?.canAccessRMT || false,
        disabled: this.isModifyAccess == false,
      }),
    });
  }

  public getRequestStatus(history) {
    if (!history?.length) {
      return "";
    }
    if (!history[0]?.type) {
      return "";
    }
    return this.historyStatusTypeMap[history[0]?.type];
  }

  public modifyAccess() {
    this.confirmModify();
    this.isModifyAccess = true;
    ["canAccessFullCam2020", "canAccessFullCam2024", "canAccessRMT"].forEach(
      (field) => {
        this.formGroup.get(field).enable();
      }
    );
  }

  private confirmModify(): void {
    const message = `<p>The 'Modify Access' feature is designed for special cases only, such as when you're encountering issues with standard user-request processes, need to pause access temporarily, or require access to features not currently available to all users (like beta versions).</p>
    <p>If you're looking to adjust user's access under normal circumstances, please submit a request through the usual user channels.</p>`;
    this.modalService.openConfirmModal(message, true);
  }

  public saveForm() {
    this.isSaving = true;
  }
}
