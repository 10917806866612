import { Observable } from "rxjs";

export interface IOutputWindow {
  title: string;
  content: string;
  isRemovable: boolean;
  isDisabled: boolean;
  isActive?: boolean;
  customClass?: string;
  outputSelection: { [key: string]: boolean };
  outputTree: IOutputListCategory[];
  chartOption: any;
  tableData: {
    headers: Array<{ prop: string; width: string | number }>;
    rows: object[];
  };
}

export interface IOutputListCategory {
  level: number;
  label: string;
  id: string;
  index: number;
  subcategories: IOutputListCategory[];
  items: IOutputListItem[];
  isExpanded: boolean;
  isSelected: boolean;
  isIndeterminate: boolean;
}

export interface IOutputListItem {
  isSelected?: boolean;
  canHaveGainLoss: boolean;
  colWidthOI: string;
  colorOI: string;
  descOI: string;
  hasInitValueOI: boolean;
  nDecPlacesOI: string;
  nmOI: string;
  prefixDOI: string;
  prefixOI: string;
  progNmOI: string;
  sortNOI: string;
  suffixDOI: string;
  suffixOI: string;
  tEconOI: string;
  tOutCat0_OI: string;
  tOutCat1_OI: string;
  tOutCat2_OI: string;
  tOutOI: string;
  tOutputOI: string;
  tScalingOI: string;
  unitNmPloOI: string;
  unitNmSpaOI: string;
}
