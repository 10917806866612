<div class="header-controls">
  <div class="title">
    <button
      aria-label="back button"
      type="button"
      class="btn close-button"
      (click)="closeForm(null)"
    >
      <i class="bi bi-arrow-left-circle-fill"> </i>
    </button>

    <h2>Log grades</h2>
  </div>
  <button
    class="btn btn-primary"
    (click)="closeForm(formGroupInstance.getRawValue())"
  >
    Save and close
  </button>
</div>

<form [formGroup]="formGroupInstance" *ngIf="formGroupInstance">
  <fieldset class="">
    <div class="parent-group">
      <h1 class="page-title">
        {{ layout.label }}
      </h1>
      <div
        fc-form-group-element
        class="sub-groups"
        [formGroupInstance]="formGroupInstance"
        [service]="forestThinningService"
        [simulationService]="simulationService"
        [group]="group"
        *ngFor="let group of layout.groups"
      ></div>
    </div>
  </fieldset>
</form>
