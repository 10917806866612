import * as _ from "lodash";

const outputInfo = [
  {
    progNmOI: "compCM",
    nmOI: "C mass complete",
    tOutOI: "kou_compCM",
    descOI: "<comp>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 0,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "siteCM",
    nmOI: "C mass on-site",
    tOutOI: "kou_siteCM",
    descOI: "<site>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 1,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "abgCM",
    nmOI: "C mass aboveground",
    tOutOI: "kou_abgCM",
    descOI: "<abg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 2,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "blgCM",
    nmOI: "C mass belowground",
    tOutOI: "kou_blgCM",
    descOI: "<blg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 3,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMCompAtms",
    nmOI: "",
    tOutOI: "kou_flowCMCompAtms",
    descOI: "from <comp>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koCMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 4,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMAtmsPlnt",
    nmOI: "to plants",
    tOutOI: "kou_flowCMAtmsPlnt",
    descOI: "due to production, fire, and grazing, to <plnt>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMSeqNet",
    suffixOI: "koNA",
    prefixDOI: "koCMSeqNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 5,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMDebrAtms",
    nmOI: "from debris",
    tOutOI: "kou_flowCMDebrAtms",
    descOI: "from <debr>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmit",
    suffixOI: "koNA",
    prefixDOI: "koCEmitL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 6,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMMulcAtms",
    nmOI: "from mulch",
    tOutOI: "kou_flowCMMulcAtms",
    descOI: "from <mulc>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmit",
    suffixOI: "koNA",
    prefixDOI: "koCEmitL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 7,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowCMSoilAtms",
    nmOI: "from soil",
    tOutOI: "kou_flowCMSoilAtms",
    descOI: "from <soil>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmit",
    suffixOI: "koNA",
    prefixDOI: "koCEmitL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 8,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowCMProdAtmsPdcm",
    nmOI: "product decomposition, from products",
    tOutOI: "kou_flowCMProdAtmsPdcm",
    descOI: "product decomposition, from <prod>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 9,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowCMProdAtmsEner",
    nmOI: "burning for bioenergy, from products",
    tOutOI: "kou_flowCMProdAtmsEner",
    descOI: "burning for bioenergy, from <prod>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 10,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMSiteAtmsFire",
    nmOI: "fire",
    tOutOI: "kou_flowCMSiteAtmsFire",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 11,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowCH4MSiteAtmsFire",
    nmOI: "CH4 emitted",
    tOutOI: "kou_flowCH4MSiteAtmsFire",
    descOI: "CH4 emitted to atmosphere",
    unitNmPloOI: "tCH4/ha",
    unitNmSpaOI: "tCH4",
    prefixOI: "koNA",
    suffixOI: "koDueFire",
    prefixDOI: "koNA",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 12,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "plntCM",
    nmOI: "plants",
    tOutOI: "kou_plntCM",
    descOI: "<plnt>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 13,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "plntAbgCM",
    nmOI: "aboveground plant components",
    tOutOI: "kou_plntAbgCM",
    descOI: "<plntAbg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 14,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "plntBlgCM",
    nmOI: "belowground plant components",
    tOutOI: "kou_plntBlgCM",
    descOI: "<plntBlg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 15,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "debrCM",
    nmOI: "debris",
    tOutOI: "kou_debrCM",
    descOI: "<debr>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 16,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "abgDebrCM",
    nmOI: "aboveground debris",
    tOutOI: "kou_abgDebrCM",
    descOI: "<debrAbg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 17,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "blgDebrCM",
    nmOI: "belowground debris",
    tOutOI: "kou_blgDebrCM",
    descOI: "<debrBlg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 18,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "littCM",
    nmOI: "litter",
    tOutOI: "kou_littCM",
    descOI: "<litt>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 19,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "ddwdCM",
    nmOI: "deadwood",
    tOutOI: "kou_ddwdCM",
    descOI: "<ddwd>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 20,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "mulcCM",
    nmOI: "mulch",
    tOutOI: "kou_mulcCM",
    descOI: "<mulc>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 21,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "soilCM",
    nmOI: "soil",
    tOutOI: "kou_soilCM",
    descOI: "<soil>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 22,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "prodCM",
    nmOI: "products",
    tOutOI: "kou_prodCM",
    descOI: "<prod>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 23,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "dispCM",
    nmOI: "products",
    tOutOI: "kou_dispCM",
    descOI: "<prod>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispBy",
    suffixOI: "koNA",
    prefixDOI: "koCMDispBy",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 24,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "dispEnerCM",
    nmOI: "products",
    tOutOI: "kou_dispEnerCM",
    descOI: "<prod>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 25,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "dispProdCM",
    nmOI: "products",
    tOutOI: "kou_dispProdCM",
    descOI: "<prod>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 26,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "compCMF",
    nmOI: "complete forest",
    tOutOI: "kou_compCMF",
    descOI: "<compF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 27,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "siteCMF",
    nmOI: "on-site forest",
    tOutOI: "kou_siteCMF",
    descOI: "<siteF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 28,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGold",
  },
  {
    progNmOI: "abgCMF",
    nmOI: "aboveground forest",
    tOutOI: "kou_abgCMF",
    descOI: "<abgF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 29,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "blgCMF",
    nmOI: "belowground forest",
    tOutOI: "kou_blgCMF",
    descOI: "<blgF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 30,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowCMCompAtmsF",
    nmOI: "from forest",
    tOutOI: "kou_flowCMCompAtmsF",
    descOI: "from <compF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koCMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 31,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMAtmsTreePlnFF",
    nmOI: "planting, to trees",
    tOutOI: "kou_flowCMAtmsTreePlnFF",
    descOI: "planting, to <tree>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMSeq",
    suffixOI: "koNA",
    prefixDOI: "koCMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 32,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowCMAtmsTreeProdF",
    nmOI: "production, to trees",
    tOutOI: "kou_flowCMAtmsTreeProdF",
    descOI: "production, to <tree>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMSeq",
    suffixOI: "koNA",
    prefixDOI: "koCMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 33,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMDebrAtmsBkdnF",
    nmOI: "breakdown, from forest debris",
    tOutOI: "kou_flowCMDebrAtmsBkdnF",
    descOI: "breakdown, from <debrF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 34,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowCMDebrAtmsTermF",
    nmOI: "termites, from forest debris",
    tOutOI: "kou_flowCMDebrAtmsTermF",
    descOI: "termites, from <debrF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 35,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowCMActvAtmsSdcmF",
    nmOI: "decomposition, from forest soil",
    tOutOI: "kou_flowCMActvAtmsSdcmF",
    descOI: "decomposition, from <soilF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 36,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMProdAtmsPdcmF",
    nmOI: "decomposing while in use, from forest products",
    tOutOI: "kou_flowCMProdAtmsPdcmF",
    descOI: "decomposing while in use, from <prodF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 37,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowCMProdAtmsEnerF",
    nmOI: "burning for bioenergy, from forest products",
    tOutOI: "kou_flowCMProdAtmsEnerF",
    descOI: "burning for bioenergy, from <prodF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 38,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowCMLndfAtmsLdcmF",
    nmOI: "decomposing while in landfill, from forest products",
    tOutOI: "kou_flowCMLndfAtmsLdcmF",
    descOI: "decomposing while in landfill, from <prodF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 39,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMSiteAtmsFireF",
    nmOI: "fire, from forest",
    tOutOI: "kou_flowCMSiteAtmsFireF",
    descOI: "fire, from <siteF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 40,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMTreeAtmsFireF",
    nmOI: "fire, from trees",
    tOutOI: "kou_flowCMTreeAtmsFireF",
    descOI: "fire, from <tree>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 41,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowCMDebrAtmsFireF",
    nmOI: "fire, from forest debris",
    tOutOI: "kou_flowCMDebrAtmsFireF",
    descOI: "fire, from <debrF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 42,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMMulcAtmsFireF",
    nmOI: "fire, from forest mulch",
    tOutOI: "kou_flowCMMulcAtmsFireF",
    descOI: "fire, from <mulcF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 43,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "treeCM",
    nmOI: "trees",
    tOutOI: "kou_treeCM",
    descOI: "<tree>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 44,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "treeAbgCM",
    nmOI: "aboveground tree components",
    tOutOI: "kou_treeAbgCM",
    descOI: "<treeAbg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 45,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 71,
    colorOI: "kclLime",
  },
  {
    progNmOI: "treeBlgCM",
    nmOI: "belowground tree components",
    tOutOI: "kou_treeBlgCM",
    descOI: "<treeBlg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 46,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 71,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "stemCMF",
    nmOI: "tree stems",
    tOutOI: "kou_stemCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 47,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "branCMF",
    nmOI: "tree branches",
    tOutOI: "kou_branCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 48,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "barkCMF",
    nmOI: "tree bark",
    tOutOI: "kou_barkCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 49,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "leafCMF",
    nmOI: "tree leaves",
    tOutOI: "kou_leafCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 50,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortCMF",
    nmOI: "tree coarse roots",
    tOutOI: "kou_cortCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 51,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtCMF",
    nmOI: "tree fine roots",
    tOutOI: "kou_firtCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 52,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowCMTreeDebrTurnF",
    nmOI: "trees to debris",
    tOutOI: "kou_flowCMTreeDebrTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 53,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowCMStemDdwdTurnF",
    nmOI: "tree stems to deadwood",
    tOutOI: "kou_flowCMStemDdwdTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 54,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMBranDdwdTurnF",
    nmOI: "tree branches to deadwood",
    tOutOI: "kou_flowCMBranDdwdTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 55,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMBarkBlitTurnF",
    nmOI: "tree bark to bark litter",
    tOutOI: "kou_flowCMBarkBlitTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 56,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMLeafLlitTurnF",
    nmOI: "tree leaves to leaf litter",
    tOutOI: "kou_flowCMLeafLlitTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 57,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMCortCodrTurnF",
    nmOI: "tree coarse roots to dead roots",
    tOutOI: "kou_flowCMCortCodrTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 58,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMFirtFidrTurnF",
    nmOI: "tree fine roots to dead roots",
    tOutOI: "kou_flowCMFirtFidrTurnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 59,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMTreeDebrThinF",
    nmOI: "trees to debris",
    tOutOI: "kou_flowCMTreeDebrThinF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 60,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMTreeProdThinF",
    nmOI: "trees to products",
    tOutOI: "kou_flowCMTreeProdThinF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 61,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMTreeDebrFireF",
    nmOI: "trees to debris",
    tOutOI: "kou_flowCMTreeDebrFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 62,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPink",
  },
  {
    progNmOI: "debrCMF",
    nmOI: "forest litter and deadwood",
    tOutOI: "kou_debrCMF",
    descOI: "<debrF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 63,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "abgDebrCMF",
    nmOI: "aboveground forest debris",
    tOutOI: "kou_abgDebrCMF",
    descOI: "<debrAbgF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 64,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "blgDebrCMF",
    nmOI: "belowground forest debris",
    tOutOI: "kou_blgDebrCMF",
    descOI: "<debrBlgF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 65,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "littCMF",
    nmOI: "forest litter",
    tOutOI: "kou_littCMF",
    descOI: "<littF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 66,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "ddwdCMF",
    nmOI: "forest deadwood",
    tOutOI: "kou_ddwdCMF",
    descOI: "<ddwdF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 67,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "dDdwdCMF",
    nmOI: "decomposable deadwood",
    tOutOI: "kou_dDdwdCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 68,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rDdwdCMF",
    nmOI: "resistant deadwood",
    tOutOI: "kou_rDdwdCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 69,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "dChwdCMF",
    nmOI: "decomposable chopped wood",
    tOutOI: "kou_dChwdCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 70,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "rChwdCMF",
    nmOI: "resistant chopped wood",
    tOutOI: "kou_rChwdCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 71,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "dBlitCMF",
    nmOI: "decomposable bark litter",
    tOutOI: "kou_dBlitCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 72,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "rBlitCMF",
    nmOI: "resistant bark litter",
    tOutOI: "kou_rBlitCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 73,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dLlitCMF",
    nmOI: "decomposable leaf litter",
    tOutOI: "kou_dLlitCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 74,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rLlitCMF",
    nmOI: "resistant leaf litter",
    tOutOI: "kou_rLlitCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 75,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dCodrCMF",
    nmOI: "decomposable coarse dead roots",
    tOutOI: "kou_dCodrCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 76,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "rCodrCMF",
    nmOI: "resistant coarse dead roots",
    tOutOI: "kou_rCodrCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 77,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dFidrCMF",
    nmOI: "decomposable fine dead roots",
    tOutOI: "kou_dFidrCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 78,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rFidrCMF",
    nmOI: "resistant fine dead roots",
    tOutOI: "kou_rFidrCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 79,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMLittActvBkdnF",
    nmOI: "litter to soil",
    tOutOI: "kou_flowCMLittActvBkdnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 80,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowCMDdrtActvBkdnF",
    nmOI: "dead roots to soil",
    tOutOI: "kou_flowCMDdrtActvBkdnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 81,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMDebrProdThinF",
    nmOI: "debris to products",
    tOutOI: "kou_flowCMDebrProdThinF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 82,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMDebrInrtFireF",
    nmOI: "debris to inert soil",
    tOutOI: "kou_flowCMDebrInrtFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 83,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mulcCMF",
    nmOI: "forest mulch",
    tOutOI: "kou_mulcCMF",
    descOI: "<mulcF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 84,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "sopmCMF",
    nmOI: "soluble plant mulch",
    tOutOI: "kou_sopmCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 85,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "lrpmCMF",
    nmOI: "less-resistant plant mulch",
    tOutOI: "kou_lrpmCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 86,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mrpmCMF",
    nmOI: "more-resistant plant mulch",
    tOutOI: "kou_mrpmCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 87,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sommCMF",
    nmOI: "dead soluble microbial mulch",
    tOutOI: "kou_sommCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 88,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "lrmmCMF",
    nmOI: "dead less -resistant microbial mulch",
    tOutOI: "kou_lrmmCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 89,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "mrmmCMF",
    nmOI: "dead more -resistant microbial mulch",
    tOutOI: "kou_mrmmCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 90,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "micrCMF",
    nmOI: "live microbes in forest mulch",
    tOutOI: "kou_micrCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 91,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMLittSopmBkdnF",
    nmOI: "litter to soluble plant mulch",
    tOutOI: "kou_flowCMLittSopmBkdnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 92,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowCMLittLrpmBkdnF",
    nmOI: "litter to less -resistant plant mulch",
    tOutOI: "kou_flowCMLittLrpmBkdnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 93,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMLittMrpmBkdnF",
    nmOI: "litter to more -resistant plant mulch",
    tOutOI: "kou_flowCMLittMrpmBkdnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 94,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMMulcAtmsMdcmF",
    nmOI: "C mass emitted by forest mulch",
    tOutOI: "kou_flowCMMulcAtmsMdcmF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koDueDcmp",
    prefixDOI: "koNA",
    suffixDOI: "koDueDcmp",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 95,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMMulcActvHumfF",
    nmOI: "mulch to soil",
    tOutOI: "kou_flowCMMulcActvHumfF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueHumf",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 96,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowCMMulcInrtFireF",
    nmOI: "mulch to inert soil",
    tOutOI: "kou_flowCMMulcInrtFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 97,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "soilCMF",
    nmOI: "forest soil",
    tOutOI: "kou_soilCMF",
    descOI: "<soilF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 98,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dpmaCMF",
    nmOI: "DPM",
    tOutOI: "kou_dpmaCMF",
    descOI: "decomposable",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 99,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "rpmaCMF",
    nmOI: "RPM",
    tOutOI: "kou_rpmaCMF",
    descOI: "resistant",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 100,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "biofCMF",
    nmOI: "BIO-F",
    tOutOI: "kou_biofCMF",
    descOI: "fast",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 101,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "biosCMF",
    nmOI: "BIO-S",
    tOutOI: "kou_biosCMF",
    descOI: "slow",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 102,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "biomCMF",
    nmOI: "BIO",
    tOutOI: "kou_biomCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koBioInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 103,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "humsCMF",
    nmOI: "HUM",
    tOutOI: "kou_humsCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koHUMInsoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 104,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "inrtCMF",
    nmOI: "inert soil",
    tOutOI: "kou_inrtCMF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 105,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMActvInrtEncpF",
    nmOI: "active soil to inert soil",
    tOutOI: "kou_flowCMActvInrtEncpF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromFr",
    suffixOI: "koDueEncp",
    prefixDOI: "koCMFromFr",
    suffixDOI: "koDueEncp",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 106,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 73,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMOffsActvMoffF",
    nmOI: "C mass added to forest soil as manure",
    tOutOI: "kou_flowCMOffsActvMoffF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 107,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowCMPresActvExtnF",
    nmOI: "C mass moved to forest soil as plant residue inputs",
    tOutOI: "kou_flowCMPresActvExtnF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 108,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "prodCMF",
    nmOI: "forest products",
    tOutOI: "kou_prodCMF",
    descOI: "<prodF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 109,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "prodUsCMF",
    nmOI: "forest products in use",
    tOutOI: "kou_prodUsCMF",
    descOI: "<prodUsF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 110,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "prodLfCMF",
    nmOI: "forest products in landfill",
    tOutOI: "kou_prodLfCMF",
    descOI: "<prodLfF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 111,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "fuelCM",
    nmOI: "biofuel",
    tOutOI: "kou_fuelCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 112,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "paprCM",
    nmOI: "pulp and paper in use",
    tOutOI: "kou_paprCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 113,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "packCM",
    nmOI: "packing wood in use",
    tOutOI: "kou_packCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 114,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "furnCM",
    nmOI: "furniture and poles in use",
    tOutOI: "kou_furnCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 115,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "fibrCM",
    nmOI: "fiberboard in use",
    tOutOI: "kou_fibrCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 116,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "consCM",
    nmOI: "construction wood in use",
    tOutOI: "kou_consCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 117,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "resiCM",
    nmOI: "mill residue in use",
    tOutOI: "kou_resiCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 118,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "fuelLfCM",
    nmOI: "biofuel in landfill",
    tOutOI: "kou_fuelLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 119,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "paprLfCM",
    nmOI: "pulp and paper in landfill",
    tOutOI: "kou_paprLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 120,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "packLfCM",
    nmOI: "packing wood in landfill",
    tOutOI: "kou_packLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 121,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "furnLfCM",
    nmOI: "furniture and poles in landfill",
    tOutOI: "kou_furnLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 122,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclLime",
  },
  {
    progNmOI: "fibrLfCM",
    nmOI: "fiberboard in landfill",
    tOutOI: "kou_fibrLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 123,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "consLfCM",
    nmOI: "construction wood in landfill",
    tOutOI: "kou_consLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 124,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "resiLfCM",
    nmOI: "mill residue in landfill",
    tOutOI: "kou_resiLfCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 125,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowCMProdLndfFillF",
    nmOI: "C mass in forest products moved to landfill",
    tOutOI: "kou_flowCMProdLndfFillF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 126,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "dispCMF",
    nmOI: "forest products",
    tOutOI: "kou_dispCMF",
    descOI: "<dispF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispBy",
    suffixOI: "koNA",
    prefixDOI: "koCMDispBy",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 127,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "dispEnerCMF",
    nmOI: "forest products",
    tOutOI: "kou_dispEnerCMF",
    descOI: "<dispEnerF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 128,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dispProdCMF",
    nmOI: "forest products",
    tOutOI: "kou_dispProdCMF",
    descOI: "<dispProdF>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 129,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "fuelCMDispEner",
    nmOI: "forest biofuel",
    tOutOI: "kou_fuelCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 130,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "paprCMDispEner",
    nmOI: "pulp and paper",
    tOutOI: "kou_paprCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 131,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "packCMDispEner",
    nmOI: "packing wood",
    tOutOI: "kou_packCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 132,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "furnCMDispEner",
    nmOI: "furniture and poles",
    tOutOI: "kou_furnCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 133,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGray",
  },
  {
    progNmOI: "fibrCMDispEner",
    nmOI: "fiberboard",
    tOutOI: "kou_fibrCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 134,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "consCMDispEner",
    nmOI: "construction wood",
    tOutOI: "kou_consCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 135,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGold",
  },
  {
    progNmOI: "resiCMDispEner",
    nmOI: "mill residues",
    tOutOI: "kou_resiCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 136,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "fossCMCompMgmtF",
    nmOI: "C mass of fossil fuel spent on forest management for bioenergy",
    tOutOI: "kou_fossCMCompMgmtF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 137,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "paprCMDispProd",
    nmOI: "pulp and paper",
    tOutOI: "kou_paprCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 138,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclLime",
  },
  {
    progNmOI: "packCMDispProd",
    nmOI: "packing wood",
    tOutOI: "kou_packCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 139,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "furnCMDispProd",
    nmOI: "furniture and poles",
    tOutOI: "kou_furnCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 140,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "fibrCMDispProd",
    nmOI: "fiberboard",
    tOutOI: "kou_fibrCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 141,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "consCMDispProd",
    nmOI: "construction wood",
    tOutOI: "kou_consCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 142,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "resiCMDispProd",
    nmOI: "mill residues",
    tOutOI: "kou_resiCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 143,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "compCMA",
    nmOI: "complete agricultural system",
    tOutOI: "kou_compCMA",
    descOI: "<compA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 144,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "siteCMA",
    nmOI: "on-site agricultural system",
    tOutOI: "kou_siteCMA",
    descOI: "<siteA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 145,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "abgCMA",
    nmOI: "aboveground agricultural system",
    tOutOI: "kou_abgCMA",
    descOI: "<abgA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 146,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "blgCMA",
    nmOI: "belowground agricultural system",
    tOutOI: "kou_blgCMA",
    descOI: "<blgA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 147,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowCMCompAtmsA",
    nmOI: "from agricultural system",
    tOutOI: "kou_flowCMCompAtmsA",
    descOI: "from <compA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koCMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 148,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMAtmsCropPlnAA",
    nmOI: "planting, to crops",
    tOutOI: "kou_flowCMAtmsCropPlnAA",
    descOI: "planting, to <crop>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMSeq",
    suffixOI: "koNA",
    prefixDOI: "koCMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 149,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMAtmsCropProdA",
    nmOI: "production, to crop",
    tOutOI: "kou_flowCMAtmsCropProdA",
    descOI: "production, to <crop>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMSeq",
    suffixOI: "koNA",
    prefixDOI: "koCMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 150,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMFoddAtmsGrazA",
    nmOI: "grazing",
    tOutOI: "kou_flowCMFoddAtmsGrazA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 151,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowCMDebrAtmsBkdnA",
    nmOI: "breakdown, from agricultural debris",
    tOutOI: "kou_flowCMDebrAtmsBkdnA",
    descOI: "breakdown, from <debrA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 152,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowCMMulcAtmsMdcmA",
    nmOI: "decomposition, from agricultural mulch",
    tOutOI: "kou_flowCMMulcAtmsMdcmA",
    descOI: "decomposition, from <mulcA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 153,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMActvAtmsSdcmA",
    nmOI: "decomposition, from agricultural soil",
    tOutOI: "kou_flowCMActvAtmsSdcmA",
    descOI: "decomposition, from <soilA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 154,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMProdAtmsPdcmA",
    nmOI: "from agricultural products",
    tOutOI: "kou_flowCMProdAtmsPdcmA",
    descOI: "from <prodA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmit",
    suffixOI: "koNA",
    prefixDOI: "koCEmitL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 155,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowCMProdAtmsEnerA",
    nmOI: "burning for bioenergy, from agricultural products",
    tOutOI: "kou_flowCMProdAtmsEnerA",
    descOI: "burning for bioenergy, from <prodA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 156,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMSiteAtmsFireA",
    nmOI: "fire, from agricultural system",
    tOutOI: "kou_flowCMSiteAtmsFireA",
    descOI: "fire, from <siteA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 157,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMCropAtmsFireA",
    nmOI: "fire, from crops",
    tOutOI: "kou_flowCMCropAtmsFireA",
    descOI: "fire, from <crop>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 158,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowCMDebrAtmsFireA",
    nmOI: "fire, from agricultural debris",
    tOutOI: "kou_flowCMDebrAtmsFireA",
    descOI: "fire, from <debrA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 159,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMMulcAtmsFireA",
    nmOI: "fire, from agricultural mulch",
    tOutOI: "kou_flowCMMulcAtmsFireA",
    descOI: "fire, from <mulcA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 160,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 72,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "cropCM",
    nmOI: "crop",
    tOutOI: "kou_cropCM",
    descOI: "<crop>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 161,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGold",
  },
  {
    progNmOI: "cropAbgCM",
    nmOI: "aboveground crop components",
    tOutOI: "kou_cropAbgCM",
    descOI: "<cropAbg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 162,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "cropBlgCM",
    nmOI: "belowground crop components",
    tOutOI: "kou_cropBlgCM",
    descOI: "<cropBlg>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 163,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "gbfrCMA",
    nmOI: "crop grains -buds-fruit",
    tOutOI: "kou_gbfrCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 164,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "stlkCMA",
    nmOI: "crop stalks",
    tOutOI: "kou_stlkCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 165,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leafCMA",
    nmOI: "crop leaves",
    tOutOI: "kou_leafCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 166,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortCMA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_cortCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 167,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtCMA",
    nmOI: "crop fine roots",
    tOutOI: "kou_firtCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 168,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowCMCropDebrTurnA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowCMCropDebrTurnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 169,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowCMGbfrGlitTurnA",
    nmOI: "crop grains -buds-fruit to debris",
    tOutOI: "kou_flowCMGbfrGlitTurnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 170,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMStlkSlitTurnA",
    nmOI: "crop stalks to debris",
    tOutOI: "kou_flowCMStlkSlitTurnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 171,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMLeafLlitTurnA",
    nmOI: "crop leaves to debris",
    tOutOI: "kou_flowCMLeafLlitTurnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 172,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMCortCodrTurnA",
    nmOI: "crop coarse roots to debris",
    tOutOI: "kou_flowCMCortCodrTurnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 173,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMFirtFidrTurnA",
    nmOI: "crop fine roots to debris",
    tOutOI: "kou_flowCMFirtFidrTurnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 174,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMCropFoddUMGrazA",
    nmOI: "crops eaten in unmanaged grazing",
    tOutOI: "kou_flowCMCropFoddUMGrazA",
    descOI: "crops that are eaten by unmanaged grazing animals",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 175,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMSuppFoddGrazA",
    nmOI: "supplemental feed",
    tOutOI: "kou_flowCMSuppFoddGrazA",
    descOI: "supplemental feed introduced in grazing",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 175,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMCropFoddGrazA",
    nmOI: "crops eaten in grazing",
    tOutOI: "kou_flowCMCropFoddGrazA",
    descOI: "crops that is eaten by grazing animals",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 175,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMDebrFoddGrazA",
    nmOI: "debris to fodder",
    tOutOI: "kou_flowCMDebrFoddGrazA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueGraz",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueGraz",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 176,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowCMFoddAnprGrazA",
    nmOI: "crops and debris to animal products due to grazing",
    tOutOI: "kou_flowCMFoddAnprGrazA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koNA",
    prefixDOI: "koCMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 177,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMFoddActvGrazA",
    nmOI: "crops and debris to soil due to grazing",
    tOutOI: "kou_flowCMFoddActvGrazA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koNA",
    prefixDOI: "koCMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 178,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowMCropFualHarvA",
    nmOI: "crops to agricultural biofuel",
    tOutOI: "kou_flowMCropFualHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 179,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowMCropGbfpHarvA",
    nmOI: "crops to grains -buds-fruit products",
    tOutOI: "kou_flowMCropGbfpHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 180,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowMCropCaneHarvA",
    nmOI: "crops to cane products",
    tOutOI: "kou_flowMCropCaneHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 181,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 58,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowMCropLfprHarvA",
    nmOI: "crops to leaf products",
    tOutOI: "kou_flowMCropLfprHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 182,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowMCropRtprHarvA",
    nmOI: "crops to root products",
    tOutOI: "kou_flowMCropRtprHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 183,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowMCropHssiHarvA",
    nmOI: "crops to hay -straw-silage",
    tOutOI: "kou_flowMCropHssiHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 184,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMCropDebrHarvA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowCMCropDebrHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 185,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMCropProdHarvA",
    nmOI: "crops to products",
    tOutOI: "kou_flowCMCropProdHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMCropGrthA",
    nmOI: "crops",
    tOutOI: "kou_flowCMCropGrthA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koGrthInc",
    suffixOI: "koDueGrth",
    prefixDOI: "koGrthInc",
    suffixDOI: "koDueGrth",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMGbfrGrthA",
    nmOI: "crop grains -buds-fruit",
    tOutOI: "kou_flowCMGbfrGrthA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koGrthInc",
    suffixOI: "koDueGrth",
    prefixDOI: "koGrthInc",
    suffixDOI: "koDueGrth",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMStlkGrthA",
    nmOI: "crop stalk",
    tOutOI: "kou_flowCMStlkGrthA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koGrthInc",
    suffixOI: "koDueGrth",
    prefixDOI: "koGrthInc",
    suffixDOI: "koDueGrth",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMLeafGrthA",
    nmOI: "crop leaf",
    tOutOI: "kou_flowCMLeafGrthA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koGrthInc",
    suffixOI: "koDueGrth",
    prefixDOI: "koGrthInc",
    suffixDOI: "koDueGrth",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMCortGrthA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_flowCMCortGrthA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koGrthInc",
    suffixOI: "koDueGrth",
    prefixDOI: "koGrthInc",
    suffixDOI: "koDueGrth",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMFirtGrthA",
    nmOI: "crop fine roots",
    tOutOI: "kou_flowCMFirtGrthA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koGrthInc",
    suffixOI: "koDueGrth",
    prefixDOI: "koGrthInc",
    suffixDOI: "koDueGrth",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMDpmaAtmsPlowA",
    nmOI: "DPM",
    tOutOI: "kou_flowCMDpmaAtmsPlowA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitPlow",
    suffixOI: "koNA",
    prefixDOI: "koCEmitPlow",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMRpmaAtmsPlowA",
    nmOI: "RPM",
    tOutOI: "kou_flowCMRpmaAtmsPlowA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitPlow",
    suffixOI: "koNA",
    prefixDOI: "koCEmitPlow",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMBiofAtmsPlowA",
    nmOI: "BIO-F",
    tOutOI: "kou_flowCMBiofAtmsPlowA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitPlow",
    suffixOI: "koNA",
    prefixDOI: "koCEmitPlow",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMBiosAtmsPlowA",
    nmOI: "BIO-S",
    tOutOI: "kou_flowCMBiosAtmsPlowA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitPlow",
    suffixOI: "koNA",
    prefixDOI: "koCEmitPlow",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMHumAtmsPlowA",
    nmOI: "HUM",
    tOutOI: "kou_flowCMHumAtmsPlowA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitPlow",
    suffixOI: "koNA",
    prefixDOI: "koCEmitPlow",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 186,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMCropDebrDieoA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowCMCropDebrDieoA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueDieO",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueDieO",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMGbfrDebrDieoA",
    nmOI: "crop grains -buds-fruit to debris",
    tOutOI: "kou_flowCMGbfrDebrDieoA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueDieO",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueDieO",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMStlkDebrDieoA",
    nmOI: "crop stalks to debris",
    tOutOI: "kou_flowCMStlkDebrDieoA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueDieO",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueDieO",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMLeafDebrDieoA",
    nmOI: "crop leaves to debris",
    tOutOI: "kou_flowCMLeafDebrDieoA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueDieO",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueDieO",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMCortDebrDieoA",
    nmOI: "crop coarse roots to debris",
    tOutOI: "kou_flowCMCortDebrDieoA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueDieO",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueDieO",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMFirtDebrDieoA",
    nmOI: "crop fine roots to debris",
    tOutOI: "kou_flowCMFirtDebrDieoA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueDieO",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueDieO",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMCropDebrFireA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowCMCropDebrFireA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 187,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMCropDebrHerbA",
    nmOI: "crops to debris due to herbicide",
    tOutOI: "kou_flowCMCropDebrHerbA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koNA",
    prefixDOI: "koCMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 188,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "debrCMA",
    nmOI: "agricultural debris",
    tOutOI: "kou_debrCMA",
    descOI: "<debrA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 189,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "abgDebrCMA",
    nmOI: "aboveground agricultural debris",
    tOutOI: "kou_abgDebrCMA",
    descOI: "<debrAbgA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 190,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "blgDebrCMA",
    nmOI: "belowground agricultural debris",
    tOutOI: "kou_blgDebrCMA",
    descOI: "<debrBlgA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 191,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "littCMA",
    nmOI: "agricultural litter",
    tOutOI: "kou_littCMA",
    descOI: "<littA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 192,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "dGlitCMA",
    nmOI: "decomposable grains -buds-fruit litter",
    tOutOI: "kou_dGlitCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 193,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rGlitCMA",
    nmOI: "resistant grains -buds-fruit litter",
    tOutOI: "kou_rGlitCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 194,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dSlitCMA",
    nmOI: "decomposable stalk litter",
    tOutOI: "kou_dSlitCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 195,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "rSlitCMA",
    nmOI: "resistant stalk litter",
    tOutOI: "kou_rSlitCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 196,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dLlitCMA",
    nmOI: "decomposable leaf litter",
    tOutOI: "kou_dLlitCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 197,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rLlitCMA",
    nmOI: "resistant leaf litter",
    tOutOI: "kou_rLlitCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 198,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dCodrCMA",
    nmOI: "decomposable coarse roots",
    tOutOI: "kou_dCodrCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 199,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "rCodrCMA",
    nmOI: "resistant coarse roots",
    tOutOI: "kou_rCodrCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 200,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dFidrCMA",
    nmOI: "decomposable fine roots",
    tOutOI: "kou_dFidrCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 201,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rFidrCMA",
    nmOI: "resistant fine roots",
    tOutOI: "kou_rFidrCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 202,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMLittActvBkdnA",
    nmOI: "litter to soil",
    tOutOI: "kou_flowCMLittActvBkdnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 203,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowCMDdrtActvBkdnA",
    nmOI: "dead roots to soil",
    tOutOI: "kou_flowCMDdrtActvBkdnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 204,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMDebrProdHarvA",
    nmOI: "debris to products",
    tOutOI: "kou_flowCMDebrProdHarvA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 205,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCMDebrInrtFireA",
    nmOI: "debris to inert soil",
    tOutOI: "kou_flowCMDebrInrtFireA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 206,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mulcCMA",
    nmOI: "agricultural mulch",
    tOutOI: "kou_mulcCMA",
    descOI: "<mulcA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 207,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "sopmCMA",
    nmOI: "soluble plant mulch",
    tOutOI: "kou_sopmCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 208,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "lrpmCMA",
    nmOI: "less-resistant plant mulch",
    tOutOI: "kou_lrpmCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 209,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mrpmCMA",
    nmOI: "more-resistant plant mulch",
    tOutOI: "kou_mrpmCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 210,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sommCMA",
    nmOI: "dead soluble microbial mulch",
    tOutOI: "kou_sommCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 211,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "lrmmCMA",
    nmOI: "dead less -resistant microbial mulch",
    tOutOI: "kou_lrmmCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 212,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "mrmmCMA",
    nmOI: "dead more -resistant microbial mulch",
    tOutOI: "kou_mrmmCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 213,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "micrCMA",
    nmOI: "live microbes in agricultural mulch",
    tOutOI: "kou_micrCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 214,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMLittSopmBkdnA",
    nmOI: "litter to soluble plant mulch",
    tOutOI: "kou_flowCMLittSopmBkdnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 215,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 82,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowCMLittLrpmBkdnA",
    nmOI: "litter to less -resistant plant mulch",
    tOutOI: "kou_flowCMLittLrpmBkdnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 216,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 82,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMLittMrpmBkdnA",
    nmOI: "litter to more -resistant plant mulch",
    tOutOI: "kou_flowCMLittMrpmBkdnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 217,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 82,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowCMMulcActvHumfA",
    nmOI: "mulch to soil",
    tOutOI: "kou_flowCMMulcActvHumfA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueHumf",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 218,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowCMMulcInrtFireA",
    nmOI: "mulch to inert soil",
    tOutOI: "kou_flowCMMulcInrtFireA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 219,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "soilCMA",
    nmOI: "agricultural soil",
    tOutOI: "kou_soilCMA",
    descOI: "<soilA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 220,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dpmaCMA",
    nmOI: "DPM",
    tOutOI: "kou_dpmaCMA",
    descOI: "decomposable",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 221,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "rpmaCMA",
    nmOI: "RPM",
    tOutOI: "kou_rpmaCMA",
    descOI: "resistant",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 222,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "biofCMA",
    nmOI: "BIO-F",
    tOutOI: "kou_biofCMA",
    descOI: "fast",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 223,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "biosCMA",
    nmOI: "BIO-S",
    tOutOI: "kou_biosCMA",
    descOI: "slow",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 224,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "biomCMA",
    nmOI: "BIO",
    tOutOI: "kou_biomCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koBioInSoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 225,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "humsCMA",
    nmOI: "HUM",
    tOutOI: "kou_humsCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koHUMInsoil",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 226,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "inrtCMA",
    nmOI: "inert soil",
    tOutOI: "kou_inrtCMA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 227,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMActvInrtEncpA",
    nmOI: "active soil to inert soil",
    tOutOI: "kou_flowCMActvInrtEncpA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koDueEncp",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koDueEncp",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 228,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowCMOffsActvMoffA",
    nmOI: "C mass added to agricultural soil as manure",
    tOutOI: "kou_flowCMOffsActvMoffA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 229,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowCMPresActvExtnA",
    nmOI: "C mass to agricultural soil as externally computed plant residues",
    tOutOI: "kou_flowCMPresActvExtnA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 230,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowCMCropDpmaPlowA",
    nmOI: "crops to DPM due to plowing",
    tOutOI: "kou_flowCMCropDpmaPlowA",
    descOI: "crops to decomposable",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koNA",
    prefixDOI: "koCMFrom",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 231,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMCropRpmaPlowA",
    nmOI: "crops to RPM due to plowing",
    tOutOI: "kou_flowCMCropRpmaPlowA",
    descOI: "crops to resistant",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koNA",
    prefixDOI: "koCMFrom",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 232,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMDebrDpmaPlowA",
    nmOI: "debris to DPM due to plowing",
    tOutOI: "kou_flowCMDebrDpmaPlowA",
    descOI: "debris to decomposable",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 233,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowCMDebrRpmaPlowA",
    nmOI: "debris to RPM due to plowing",
    tOutOI: "kou_flowCMDebrRpmaPlowA",
    descOI: "debris to resistant",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 234,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowCMMulcDpmaPlowA",
    nmOI: "mulch to DPM due to plowing",
    tOutOI: "kou_flowCMMulcDpmaPlowA",
    descOI: "mulch to decomposable",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 235,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMMulcRpmaPlowA",
    nmOI: "mulch to RPM due to plowing",
    tOutOI: "kou_flowCMMulcRpmaPlowA",
    descOI: "mulch to resistant",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koCMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 236,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "prodCMA",
    nmOI: "agricultural products",
    tOutOI: "kou_prodCMA",
    descOI: "<prodA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 237,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "fualCM",
    nmOI: "biofuel",
    tOutOI: "kou_fualCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koCMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 238,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "gbfpCM",
    nmOI: "grains-buds-fruit products",
    tOutOI: "kou_gbfpCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 239,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "caneCM",
    nmOI: "cane products",
    tOutOI: "kou_caneCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 240,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "lfprCM",
    nmOI: "leaf products",
    tOutOI: "kou_lfprCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 241,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "rtprCM",
    nmOI: "root products",
    tOutOI: "kou_rtprCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 242,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "hssiCM",
    nmOI: "hay-straw-silage",
    tOutOI: "kou_hssiCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 243,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "anprCM",
    nmOI: "animal products",
    tOutOI: "kou_anprCM",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 244,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "dispCMA",
    nmOI: "agricultural products",
    tOutOI: "kou_dispCMA",
    descOI: "<dispA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispBy",
    suffixOI: "koNA",
    prefixDOI: "koCMDispBy",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 245,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "dispEnerCMA",
    nmOI: "agricultural products",
    tOutOI: "kou_dispEnerCMA",
    descOI: "<dispEnerA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 246,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dispProdCMA",
    nmOI: "agricultural products",
    tOutOI: "kou_dispProdCMA",
    descOI: "<dispProdA>",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 247,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "fualCMDispEner",
    nmOI: "agricultural biofuel",
    tOutOI: "kou_fualCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 248,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "gbfpCMDispEner",
    nmOI: "grains-buds-fruit products",
    tOutOI: "kou_gbfpCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 249,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "caneCMDispEner",
    nmOI: "canes",
    tOutOI: "kou_caneCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 250,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "lfprCMDispEner",
    nmOI: "leaf products",
    tOutOI: "kou_lfprCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 251,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGray",
  },
  {
    progNmOI: "rtprCMDispEner",
    nmOI: "root products",
    tOutOI: "kou_rtprCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 252,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "hssiCMDispEner",
    nmOI: "hay-straw-silage",
    tOutOI: "kou_hssiCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 253,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGold",
  },
  {
    progNmOI: "anprCMDispEner",
    nmOI: "animal products",
    tOutOI: "kou_anprCMDispEner",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispEner",
    suffixOI: "koNA",
    prefixDOI: "koCMDispEner",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 254,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "fossCMCompMgmtA",
    nmOI: "fossil fuel spent on agricultural management for bioenergy",
    tOutOI: "kou_fossCMCompMgmtA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 255,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "gbfpCMDispProd",
    nmOI: "grains-buds-fruit products",
    tOutOI: "kou_gbfpCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 256,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "caneCMDispProd",
    nmOI: "canes",
    tOutOI: "kou_caneCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 257,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "lfprCMDispProd",
    nmOI: "leaf products",
    tOutOI: "kou_lfprCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 258,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "rtprCMDispProd",
    nmOI: "root products",
    tOutOI: "kou_rtprCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 259,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "hssiCMDispProd",
    nmOI: "hay and straw",
    tOutOI: "kou_hssiCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 260,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "anprCMDispProd",
    nmOI: "animal products",
    tOutOI: "kou_anprCMDispProd",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMDispUse",
    suffixOI: "koNA",
    prefixDOI: "koCMDispUse",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Disp",
    sortNOI: 261,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 66,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "leakCTree",
    nmOI: "C leak : Trees",
    tOutOI: "kou_leakCTree",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 262,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakCDebrF",
    nmOI: "C leak : forest debris",
    tOutOI: "kou_leakCDebrF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 263,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leakCMulcF",
    nmOI: "C leak : forest mulch",
    tOutOI: "kou_leakCMulcF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 264,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakCSoilF",
    nmOI: "C leak : forest soil",
    tOutOI: "kou_leakCSoilF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 265,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leakCProdF",
    nmOI: "C leak : forest products",
    tOutOI: "kou_leakCProdF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 266,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakCCrop",
    nmOI: "C leak : Crops",
    tOutOI: "kou_leakCCrop",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 267,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leakCDebrA",
    nmOI: "C leak : Agricultural debris",
    tOutOI: "kou_leakCDebrA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 268,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakCMulcA",
    nmOI: "C leak : Agricultural mulch",
    tOutOI: "kou_leakCMulcA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 269,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leakCSoilA",
    nmOI: "C leak : Agricultural soil",
    tOutOI: "kou_leakCSoilA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 270,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakCProdA",
    nmOI: "C leak : Agricultural products",
    tOutOI: "kou_leakCProdA",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 271,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "compNM",
    nmOI: "N mass complete",
    tOutOI: "kou_compNM",
    descOI: "<comp>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 272,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "siteNM",
    nmOI: "N mass on-site",
    tOutOI: "kou_siteNM",
    descOI: "<site>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 273,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "abgNM",
    nmOI: "N mass aboveground",
    tOutOI: "kou_abgNM",
    descOI: "<abg>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 274,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "blgNM",
    nmOI: "N mass belowground",
    tOutOI: "kou_blgNM",
    descOI: "<blg>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 275,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMNetMineraliz",
    nmOI: "Net N mineralization",
    tOutOI: "kou_flowNMNetMineraliz",
    descOI: "Net N mass moved to minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 276,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMGrMineraliz",
    nmOI: "Gross N mineralization",
    tOutOI: "kou_flowNMGrMineraliz",
    descOI: "Gross N mass moved to minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 277,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMGrImmobiliz",
    nmOI: "Gross N immobilization",
    tOutOI: "kou_flowNMGrImmobiliz",
    descOI: "Gross N mass moved from minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 278,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowNMCompAtms",
    nmOI: "",
    tOutOI: "kou_flowNMCompAtms",
    descOI: "from <comp>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koNMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 279,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowNMAtmsPlnt",
    nmOI: "to plants",
    tOutOI: "kou_flowNMAtmsPlnt",
    descOI: "to <plnt>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeqNet",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 280,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowNMMnrlAtms",
    nmOI: "from minerals",
    tOutOI: "kou_flowNMMnrlAtms",
    descOI: "from <mnrl>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koNMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 281,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMProdAtmsPdcm",
    nmOI: "decomposition, from products ",
    tOutOI: "kou_flowNMProdAtmsPdcm",
    descOI: "decomposition, from <prod>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 282,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMProdAtmsEner",
    nmOI: "burning for bioenergy, from products",
    tOutOI: "kou_flowNMProdAtmsEner",
    descOI: "burning for bioenergy, from <prod>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 283,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowN2OMSiteAtmsFire",
    nmOI: "N2O emitted",
    tOutOI: "kou_flowN2OMSiteAtmsFire",
    descOI: "N2O emitted to atmosphere",
    unitNmPloOI: "tN2O/ha",
    unitNmSpaOI: "tN2O",
    prefixOI: "koNA",
    suffixOI: "koDueFire",
    prefixDOI: "koNA",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 284,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "plntNM",
    nmOI: "plants",
    tOutOI: "kou_plntNM",
    descOI: "<plnt>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 285,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "debrNM",
    nmOI: "debris",
    tOutOI: "kou_debrNM",
    descOI: "<debr>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 286,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "mulcNM",
    nmOI: "mulch",
    tOutOI: "kou_mulcNM",
    descOI: "<mulc>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 287,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "soilNM",
    nmOI: "organic soil",
    tOutOI: "kou_soilNM",
    descOI: "<soil>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 288,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "mnrlNM",
    nmOI: "N mass in minerals",
    tOutOI: "kou_mnrlNM",
    descOI: "N mass in <mnrl>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 289,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMMnrlRbal_AToF",
    nmOI: "N mass in minerals",
    tOutOI: "kou_flowNMMnrlRbal_AToF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koBalAgToFr",
    prefixDOI: "koNA",
    suffixDOI: "koBalAgToFrL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 290,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMMnamRbal_AToF",
    nmOI: "N mass available to the mulch",
    tOutOI: "kou_flowNMMnamRbal_AToF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koBalAgToFr",
    prefixDOI: "koNA",
    suffixDOI: "koBalAgToFrL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 291,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMSAmmRbal_AToF",
    nmOI: "N mass in shallow ammonium",
    tOutOI: "kou_flowNMSAmmRbal_AToF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koBalAgToFr",
    prefixDOI: "koNA",
    suffixDOI: "koBalAgToFrL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 292,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMSNtrRbal_AToF",
    nmOI: "N mass in shallow nitrate",
    tOutOI: "kou_flowNMSNtrRbal_AToF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koBalAgToFr",
    prefixDOI: "koNA",
    suffixDOI: "koBalAgToFrL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 293,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMDAmmRbal_AToF",
    nmOI: "N mass in deep ammonium",
    tOutOI: "kou_flowNMDAmmRbal_AToF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koBalAgToFr",
    prefixDOI: "koNA",
    suffixDOI: "koBalAgToFrL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 294,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowNMDNtrRbal_AToF",
    nmOI: "N mass in deep nitrate,",
    tOutOI: "kou_flowNMDNtrRbal_AToF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koBalAgToFr",
    prefixDOI: "koNA",
    suffixDOI: "koBalAgToFrL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 295,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "prodNM",
    nmOI: "products",
    tOutOI: "kou_prodNM",
    descOI: "<prod>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 296,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "compNMF",
    nmOI: "complete forest",
    tOutOI: "kou_compNMF",
    descOI: "<compF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 297,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "siteNMF",
    nmOI: "on-site forest",
    tOutOI: "kou_siteNMF",
    descOI: "<siteF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 298,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "abgNMF",
    nmOI: "aboveground forest",
    tOutOI: "kou_abgNMF",
    descOI: "<abgF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 299,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "blgNMF",
    nmOI: "belowground forest",
    tOutOI: "kou_blgNMF",
    descOI: "<blgF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 300,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowNMNetMineralizF",
    nmOI: "Forest net N mineralization",
    tOutOI: "kou_flowNMNetMineralizF",
    descOI: "Net N mass moved to forest minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 301,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 76,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowNMGrMineralizF",
    nmOI: "Forest gross N mineralization",
    tOutOI: "kou_flowNMGrMineralizF",
    descOI: "Gross N mass moved to forest minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 302,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowNMGrImmobilizF",
    nmOI: "Forest gross N immobilization",
    tOutOI: "kou_flowNMGrImmobilizF",
    descOI: "Gross N mass moved from forest minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 303,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMCompAtmsF",
    nmOI: "from forest",
    tOutOI: "kou_flowNMCompAtmsF",
    descOI: "from <compF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koNMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 304,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMAtmsTreePlnFF",
    nmOI: "planting, to trees",
    tOutOI: "kou_flowNMAtmsTreePlnFF",
    descOI: "planting, to <tree>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 305,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMAtmsTreeProdF",
    nmOI: "production, to trees",
    tOutOI: "kou_flowNMAtmsTreeProdF",
    descOI: "production, to <tree>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 306,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowNMMnrlAtmsF",
    nmOI: "from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsF",
    descOI: "from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koNMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 307,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowNMMnrlAtmsNitrF",
    nmOI: "nitrification, from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsNitrF",
    descOI: "nitrification, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 308,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowNMMnrlAtmsNitaF",
    nmOI: "ammonium-nitrification, from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsNitaF",
    descOI: "nitrification due to the amount of shallow ammonium, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 309,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 110,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMMnrlAtmsNitmF",
    nmOI: "mineralization-nitrification, from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsNitmF",
    descOI: "nitrification due to net mineralization, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 310,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 110,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMMnrlAtmsDeniF",
    nmOI: "denitrification, from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsDeniF",
    descOI: "denitrification, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 311,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "ratioN2ToN2ODeniF",
    nmOI: "forest",
    tOutOI: "kou_ratioN2ToN2ODeniF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNRatio",
    suffixOI: "koNA",
    prefixDOI: "koNRatioL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 312,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 100,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "emitNInN2DeniF",
    nmOI: "denitrification, from forest minerals",
    tOutOI: "kou_emitNInN2DeniF",
    descOI: "denitrification, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNInN2EmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNInN2EmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 313,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "emitNInN2ODeniF",
    nmOI: "denitrification, from forest minerals",
    tOutOI: "kou_emitNInN2ODeniF",
    descOI: "denitrification, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNInN2OEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNInN2OEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 314,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowNMAtmsMnrlDepoF",
    nmOI: "atmospheric deposition, to forest minerals",
    tOutOI: "kou_flowNMAtmsMnrlDepoF",
    descOI: "atmospheric deposition, to <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 315,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMAtmsMnrlNfixF",
    nmOI: "non-symbiotic fixation, to forest minerals",
    tOutOI: "kou_flowNMAtmsMnrlNfixF",
    descOI: "non-symbiotic fixation, to <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 316,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMProdAtmsPdcmF",
    nmOI: "decomposition while in use, from forest products",
    tOutOI: "kou_flowNMProdAtmsPdcmF",
    descOI: "decomposition while in use, from <prodF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 317,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowNMProdAtmsEnerF",
    nmOI: "burning for bioenergy, from forest products",
    tOutOI: "kou_flowNMProdAtmsEnerF",
    descOI: "burning for bioenergy, from <prodF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 318,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMProdLndfFillF",
    nmOI: "moving to landfill, from forest products",
    tOutOI: "kou_flowNMProdLndfFillF",
    descOI: "moving to landfill, from <prodF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 319,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMLndfAtmsLdcmF",
    nmOI: "decomposition in landfill, from forest products",
    tOutOI: "kou_flowNMLndfAtmsLdcmF",
    descOI: "decomposition in landfill, from <prodF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 320,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowNMMnrlAtmsBondF",
    nmOI: "boundary-line emissions, from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsBondF",
    descOI: "boundary-line emissions, from <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 321,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMMnrlAtmsFireF",
    nmOI: "fire, from forest minerals",
    tOutOI: "kou_flowNMMnrlAtmsFireF",
    descOI: "minerals to atmosphere",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 322,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "treeNM",
    nmOI: "trees",
    tOutOI: "kou_treeNM",
    descOI: "<tree>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 323,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "treeAbgNM",
    nmOI: "aboveground tree components",
    tOutOI: "kou_treeAbgNM",
    descOI: "<treeAbg>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 324,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "treeBlgNM",
    nmOI: "belowground tree components",
    tOutOI: "kou_treeBlgNM",
    descOI: "<treeBlg>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 325,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "stemNMF",
    nmOI: "tree stems",
    tOutOI: "kou_stemNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 326,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "branNMF",
    nmOI: "tree branches",
    tOutOI: "kou_branNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 327,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "barkNMF",
    nmOI: "tree bark",
    tOutOI: "kou_barkNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 328,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "leafNMF",
    nmOI: "tree leaves",
    tOutOI: "kou_leafNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 329,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortNMF",
    nmOI: "tree coarse roots",
    tOutOI: "kou_cortNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 330,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtNMF",
    nmOI: "tree fine roots",
    tOutOI: "kou_firtNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 331,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "storNMF",
    nmOI: "tree internal store",
    tOutOI: "kou_storNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 332,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "stemCNRatioF",
    nmOI: "tree stems",
    tOutOI: "kou_stemCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 333,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "branCNRatioF",
    nmOI: "tree branches",
    tOutOI: "kou_branCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 334,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "barkCNRatioF",
    nmOI: "tree bark",
    tOutOI: "kou_barkCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 335,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "leafCNRatioF",
    nmOI: "tree leaves",
    tOutOI: "kou_leafCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 336,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortCNRatioF",
    nmOI: "tree coarse roots",
    tOutOI: "kou_cortCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 337,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtCNRatioF",
    nmOI: "tree fine roots",
    tOutOI: "kou_firtCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 338,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "minStemCNRatioF",
    nmOI: "tree stems",
    tOutOI: "kou_minStemCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 339,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxStemCNRatioF",
    nmOI: "tree stems",
    tOutOI: "kou_maxStemCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 340,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minBranCNRatioF",
    nmOI: "tree branches",
    tOutOI: "kou_minBranCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 341,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxBranCNRatioF",
    nmOI: "tree branches",
    tOutOI: "kou_maxBranCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 342,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minBarkCNRatioF",
    nmOI: "tree bark",
    tOutOI: "kou_minBarkCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 343,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxBarkCNRatioF",
    nmOI: "tree bark",
    tOutOI: "kou_maxBarkCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 344,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minLeafCNRatioF",
    nmOI: "tree leaves",
    tOutOI: "kou_minLeafCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 345,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxLeafCNRatioF",
    nmOI: "tree leaves",
    tOutOI: "kou_maxLeafCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 346,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minCortCNRatioF",
    nmOI: "tree coarse roots",
    tOutOI: "kou_minCortCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 347,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxCortCNRatioF",
    nmOI: "tree coarse roots",
    tOutOI: "kou_maxCortCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 348,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minFirtCNRatioF",
    nmOI: "tree fine roots",
    tOutOI: "kou_minFirtCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 349,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxFirtCNRatioF",
    nmOI: "tree fine roots",
    tOutOI: "kou_maxFirtCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 350,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMTreeDebrTurnF",
    nmOI: "trees to debris",
    tOutOI: "kou_flowNMTreeDebrTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 351,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowNMStemDdwdTurnF",
    nmOI: "tree stems to deadwood",
    tOutOI: "kou_flowNMStemDdwdTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 352,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMBranDdwdTurnF",
    nmOI: "tree branches to deadwood",
    tOutOI: "kou_flowNMBranDdwdTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 353,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMBarkBlitTurnF",
    nmOI: "tree bark to bark litter",
    tOutOI: "kou_flowNMBarkBlitTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 354,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMLeafLlitTurnF",
    nmOI: "tree leaves to leaf litter",
    tOutOI: "kou_flowNMLeafLlitTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 355,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMLeafDLlitTurnF",
    nmOI: "tree leaves to decomposable leaf litter",
    tOutOI: "kou_flowNMLeafDLlitTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 356,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMCortCodrTurnF",
    nmOI: "tree coarse roots to dead roots",
    tOutOI: "kou_flowNMCortCodrTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 357,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowNMFirtFidrTurnF",
    nmOI: "tree fine roots to dead roots",
    tOutOI: "kou_flowNMFirtFidrTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 358,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMStorDdwdTurnF",
    nmOI: "tree N storage to deadwood",
    tOutOI: "kou_flowNMStorDdwdTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 359,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowNMLeafStorTurnF",
    nmOI: "tree leaves to internal N storage",
    tOutOI: "kou_flowNMLeafStorTurnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 360,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowNMTreeDebrThinF",
    nmOI: "trees to debris",
    tOutOI: "kou_flowNMTreeDebrThinF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 361,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMTreeProdThinF",
    nmOI: "trees to products",
    tOutOI: "kou_flowNMTreeProdThinF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 362,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowNMTreeDebrFireF",
    nmOI: "trees to debris",
    tOutOI: "kou_flowNMTreeDebrFireF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 363,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMTreeMnrlFireF",
    nmOI: "trees to minerals",
    tOutOI: "kou_flowNMTreeMnrlFireF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 364,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "debrNMF",
    nmOI: "forest debris",
    tOutOI: "kou_debrNMF",
    descOI: "<debrF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 365,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "dDdwdNMF",
    nmOI: "decomposable deadwood",
    tOutOI: "kou_dDdwdNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 366,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rDdwdNMF",
    nmOI: "resistant deadwood",
    tOutOI: "kou_rDdwdNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 367,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dChwdNMF",
    nmOI: "decomposable chopped wood",
    tOutOI: "kou_dChwdNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 368,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rChwdNMF",
    nmOI: "resistant chopped wood",
    tOutOI: "kou_rChwdNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 369,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "dBlitNMF",
    nmOI: "decomposable bark litter",
    tOutOI: "kou_dBlitNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 370,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "rBlitNMF",
    nmOI: "resistant bark litter",
    tOutOI: "kou_rBlitNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 371,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dLlitNMF",
    nmOI: "decomposable leaf litter",
    tOutOI: "kou_dLlitNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 372,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rLlitNMF",
    nmOI: "resistant leaf litter",
    tOutOI: "kou_rLlitNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 373,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dCodrNMF",
    nmOI: "decomposable coarse dead roots",
    tOutOI: "kou_dCodrNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 374,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "rCodrNMF",
    nmOI: "resistant coarse dead roots",
    tOutOI: "kou_rCodrNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 375,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dFidrNMF",
    nmOI: "decomposable fine dead roots",
    tOutOI: "kou_dFidrNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 376,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rFidrNMF",
    nmOI: "resistant fine dead roots",
    tOutOI: "kou_rFidrNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 377,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "dDdwdCNRatioF",
    nmOI: "decomposable deadwood",
    tOutOI: "kou_dDdwdCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 378,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rDdwdCNRatioF",
    nmOI: "resistant deadwood",
    tOutOI: "kou_rDdwdCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 379,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dChwdCNRatioF",
    nmOI: "decomposable chopped wood",
    tOutOI: "kou_dChwdCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 380,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rChwdCNRatioF",
    nmOI: "resistant chopped wood",
    tOutOI: "kou_rChwdCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 381,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "dBlitCNRatioF",
    nmOI: "decomposable bark litter",
    tOutOI: "kou_dBlitCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 382,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "rBlitCNRatioF",
    nmOI: "resistant bark litter",
    tOutOI: "kou_rBlitCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 383,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dLlitCNRatioF",
    nmOI: "decomposable leaf litter",
    tOutOI: "kou_dLlitCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 384,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rLlitCNRatioF",
    nmOI: "resistant leaf litter",
    tOutOI: "kou_rLlitCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 385,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dCodrCNRatioF",
    nmOI: "decomposable coarse dead roots",
    tOutOI: "kou_dCodrCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 386,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "rCodrCNRatioF",
    nmOI: "resistant coarse dead roots",
    tOutOI: "kou_rCodrCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 387,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dFidrCNRatioF",
    nmOI: "decomposable fine dead roots",
    tOutOI: "kou_dFidrCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 388,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rFidrCNRatioF",
    nmOI: "resistant fine dead roots",
    tOutOI: "kou_rFidrCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 389,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMDebrAtmsTermF",
    nmOI: "termites, from forest debris",
    tOutOI: "kou_flowNMDebrAtmsTermF",
    descOI: "termites, from <debrF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 390,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMDebrProdThinF",
    nmOI: "debris to products",
    tOutOI: "kou_flowNMDebrProdThinF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 391,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "mulcNMF",
    nmOI: "forest organic mulch",
    tOutOI: "kou_mulcNMF",
    descOI: "<mulcF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 392,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "sopmNMF",
    nmOI: "soluble plant mulch",
    tOutOI: "kou_sopmNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 393,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "lrpmNMF",
    nmOI: "less-resistant plant mulch",
    tOutOI: "kou_lrpmNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 394,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mrpmNMF",
    nmOI: "more-resistant plant mulch",
    tOutOI: "kou_mrpmNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 395,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sommNMF",
    nmOI: "dead soluble microbial mulch",
    tOutOI: "kou_sommNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 396,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "lrmmNMF",
    nmOI: "dead less -resistant microbial mulch",
    tOutOI: "kou_lrmmNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 397,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "mrmmNMF",
    nmOI: "dead more -resistant microbial mulch",
    tOutOI: "kou_mrmmNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 398,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "micrNMF",
    nmOI: "live microbes in forest mulch",
    tOutOI: "kou_micrNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 399,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "sopmCNRatioF",
    nmOI: "soluble plant mulch",
    tOutOI: "kou_sopmCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 400,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "lrpmCNRatioF",
    nmOI: "less-resistant plant mulch",
    tOutOI: "kou_lrpmCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 401,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mrpmCNRatioF",
    nmOI: "more-resistant plant mulch",
    tOutOI: "kou_mrpmCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 402,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sommCNRatioF",
    nmOI: "dead soluble microbial mulch",
    tOutOI: "kou_sommCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 403,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "lrmmCNRatioF",
    nmOI: "dead less -resistant microbial mulch",
    tOutOI: "kou_lrmmCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 404,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "mrmmCNRatioF",
    nmOI: "dead more -resistant microbial mulch",
    tOutOI: "kou_mrmmCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 405,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "micrCNRatioF",
    nmOI: "live microbes in forest mulch",
    tOutOI: "kou_micrCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 406,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowNMLittSopmBkdnF",
    nmOI: "litter to soluble plant mulch",
    tOutOI: "kou_flowNMLittSopmBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 407,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMLittLrpmBkdnF",
    nmOI: "litter to less -resistant plant mulch",
    tOutOI: "kou_flowNMLittLrpmBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 408,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMLittMrpmBkdnF",
    nmOI: "litter to more -resistant plant mulch",
    tOutOI: "kou_flowNMLittMrpmBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 409,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "soilNMF",
    nmOI: "forest organic soil",
    tOutOI: "kou_soilNMF",
    descOI: "<soilF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 410,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dpmaNMF",
    nmOI: "DPM",
    tOutOI: "kou_dpmaNMF",
    descOI: "decomposable",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 411,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "rpmaNMF",
    nmOI: "RPM",
    tOutOI: "kou_rpmaNMF",
    descOI: "resistant",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 412,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "biofNMF",
    nmOI: "BIO-F",
    tOutOI: "kou_biofNMF",
    descOI: "fast",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 413,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "biosNMF",
    nmOI: "BIO-S",
    tOutOI: "kou_biosNMF",
    descOI: "slows",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 414,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "biomNMF",
    nmOI: "BIO",
    tOutOI: "kou_biomNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 415,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "humsNMF",
    nmOI: "HUM",
    tOutOI: "kou_humsNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koHUMInsoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 416,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "inrtNMF",
    nmOI: "inert soil",
    tOutOI: "kou_inrtNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 417,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dpmaCNRatioF",
    nmOI: "DPM",
    tOutOI: "kou_dpmaCNRatioF",
    descOI: "decomposable",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 418,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "rpmaCNRatioF",
    nmOI: "RPM",
    tOutOI: "kou_rpmaCNRatioF",
    descOI: "resistant",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 419,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "biofCNRatioF",
    nmOI: "BIO-F",
    tOutOI: "kou_biofCNRatioF",
    descOI: "fast",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 420,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "biosCNRatioF",
    nmOI: "BIO-S",
    tOutOI: "kou_biosCNRatioF",
    descOI: "slow",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 421,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "biomCNRatioF",
    nmOI: "BIO",
    tOutOI: "kou_biomCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 422,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "humsCNRatioF",
    nmOI: "HUM",
    tOutOI: "kou_humsCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koHUMInsoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 423,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "inrtCNRatioF",
    nmOI: "inert soil",
    tOutOI: "kou_inrtCNRatioF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 424,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMLittActvBkdnF",
    nmOI: "litter to soil",
    tOutOI: "kou_flowNMLittActvBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 425,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMDdrtActvBkdnF",
    nmOI: "dead roots to soil",
    tOutOI: "kou_flowNMDdrtActvBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 426,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMMulcActvHumfF",
    nmOI: "mulch to soil",
    tOutOI: "kou_flowNMMulcActvHumfF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueHumf",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 427,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMActvInrtEncpF",
    nmOI: "active soil to inert soil",
    tOutOI: "kou_flowNMActvInrtEncpF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueEncp",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueEncp",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 428,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMOffsActvMoffF",
    nmOI: "N mass added to forest soil as manure",
    tOutOI: "kou_flowNMOffsActvMoffF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 429,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMSoilStorMycoF",
    nmOI: "soil to internal tree storage by mycorrhiza",
    tOutOI: "kou_flowNMSoilStorMycoF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 430,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "mnrlNMF",
    nmOI: "N mass in forest minerals",
    tOutOI: "kou_mnrlNMF",
    descOI: "N mass in <mnrlF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 431,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "mnamNMF",
    nmOI: "N mass as minerals available to the forest mulch",
    tOutOI: "kou_mnamNMF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 432,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "sAmmNMF",
    nmOI: "forest shallow soil",
    tOutOI: "kou_sAmmNMF",
    descOI: "forest",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMAmmIn",
    suffixOI: "koNA",
    prefixDOI: "koNMAmmIn",
    suffixDOI: "koSoilShallowL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 433,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sNtrNMF",
    nmOI: "forest shallow soil",
    tOutOI: "kou_sNtrNMF",
    descOI: "forest",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNtrIn",
    suffixOI: "koNA",
    prefixDOI: "koNMNtrIn",
    suffixDOI: "koSoilShallowL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 434,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "dAmmNMF",
    nmOI: "forest deep soil",
    tOutOI: "kou_dAmmNMF",
    descOI: "forest",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMAmmIn",
    suffixOI: "koNA",
    prefixDOI: "koNMAmmIn",
    suffixDOI: "koSoilDeepL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 435,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dNtrNMF",
    nmOI: "forest deep soil",
    tOutOI: "kou_dNtrNMF",
    descOI: "forest",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNtrIn",
    suffixOI: "koNA",
    prefixDOI: "koNMNtrIn",
    suffixDOI: "koSoilDeepL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 436,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMMnrlTreeProdF",
    nmOI: "minerals to trees due to production",
    tOutOI: "kou_flowNMMnrlTreeProdF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koNA",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 437,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMLittMnrlBkdnF",
    nmOI: "litter to minerals",
    tOutOI: "kou_flowNMLittMnrlBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 438,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMDdrtMnrlBkdnF",
    nmOI: "dead roots to minerals",
    tOutOI: "kou_flowNMDdrtMnrlBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 439,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowNMMnrlSopmBkdnF",
    nmOI: "minerals to soluble plant mulch",
    tOutOI: "kou_flowNMMnrlSopmBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 440,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMMnrlActvBkdnF",
    nmOI: "minerals to soil",
    tOutOI: "kou_flowNMMnrlActvBkdnF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 441,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMMulcMnrlMdcmF",
    nmOI: "mulch to minerals",
    tOutOI: "kou_flowNMMulcMnrlMdcmF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueDcmp",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueDcmp",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 442,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMMulcMnrlHumfF",
    nmOI: "mulch to minerals",
    tOutOI: "kou_flowNMMulcMnrlHumfF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueHumf",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 443,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowNMMnrlActvHumfF",
    nmOI: "minerals to soil",
    tOutOI: "kou_flowNMMnrlActvHumfF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueHumf",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 444,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMActvMnrlSdcmF",
    nmOI: "soil to minerals",
    tOutOI: "kou_flowNMActvMnrlSdcmF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueDcmp",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueDcmp",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 445,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowNMOffsMnrlNoffF",
    nmOI: "N mass added to forest minerals from offsite",
    tOutOI: "kou_flowNMOffsMnrlNoffF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 446,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMDebrMnrlFireF",
    nmOI: "debris to minerals",
    tOutOI: "kou_flowNMDebrMnrlFireF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 447,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMMulcMnrlFireF",
    nmOI: "mulch to minerals",
    tOutOI: "kou_flowNMMulcMnrlFireF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromFr",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFromFr",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 448,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowNMMnrlDeepLeacF",
    nmOI: "N mass leached from shallow nitrate to deep nitrate",
    tOutOI: "kou_flowNMMnrlDeepLeacF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 449,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMSAmmSNtrNitaF",
    nmOI: "ammonium",
    tOutOI: "kou_flowNMSAmmSNtrNitaF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNitrDueTo",
    suffixOI: "koFr",
    prefixDOI: "koNMNitrDueToL",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 450,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMSAmmSNtrNitmF",
    nmOI: "net mineralization",
    tOutOI: "kou_flowNMSAmmSNtrNitmF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNitrDueTo",
    suffixOI: "koFr",
    prefixDOI: "koNMNitrDueToL",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 451,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "nitrTempFnF",
    nmOI: "Nit_T function",
    tOutOI: "kou_nitrTempFnF",
    descOI: "temperature function Nit_T",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInNitrF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 452,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "nitrPhFnF",
    nmOI: "Nit_pH function",
    tOutOI: "kou_nitrPhFnF",
    descOI: "pH function Nit_pH",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInNitrF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 453,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "nitrWaterFnF",
    nmOI: "Nit_TSMD function",
    tOutOI: "kou_nitrWaterFnF",
    descOI: "soil water function Nit_TSMD",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInNitrF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 454,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "nitrFnF",
    nmOI: "Nit_T * Nit_pH * Nit_TSMD",
    tOutOI: "kou_nitrFnF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValProduct",
    suffixDOI: "koInNitrF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 455,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "deniCO2FnF",
    nmOI: "Den_CO2 function",
    tOutOI: "kou_deniCO2FnF",
    descOI: "CO2 function Den_CO2",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 456,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "deniNO3FnF",
    nmOI: "Den_NO3 function",
    tOutOI: "kou_deniNO3FnF",
    descOI: "NO3 function Den_NO3",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 457,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "deniWaterFnF",
    nmOI: "Den_TSMD function",
    tOutOI: "kou_deniWaterFnF",
    descOI: "soil water function Den_TSMD",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 458,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "deniFnF",
    nmOI: "Min(Den_CO2, Den_NO3 ) * Den_TSMD",
    tOutOI: "kou_deniFnF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValProduct",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 459,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "deniRCO2FnF",
    nmOI: "DenR_CO2 function",
    tOutOI: "kou_deniRCO2FnF",
    descOI: "CO2 ratio -function DenR_CO2",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 460,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "deniRNO3FnF",
    nmOI: "DenR_NO3 function",
    tOutOI: "kou_deniRNO3FnF",
    descOI: "NO3 ratio -function DenR_NO3",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 461,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "deniRWaterFnF",
    nmOI: "DenR_TSMD function",
    tOutOI: "kou_deniRWaterFnF",
    descOI: "soil water ratio -function DenR_TSMD",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 462,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "deniRFnF",
    nmOI: "Min(DenR_CO2, DenR_NO3 ) * DenR_TSMD",
    tOutOI: "kou_deniRFnF",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNValProduct",
    suffixDOI: "koInDeniF",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 463,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "prodNMF",
    nmOI: "forest products",
    tOutOI: "kou_prodNMF",
    descOI: "<prodF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 464,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "prodUsNMF",
    nmOI: "forest products in use",
    tOutOI: "kou_prodUsNMF",
    descOI: "<prodUsF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 465,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "prodLfNMF",
    nmOI: "forest products in landfill",
    tOutOI: "kou_prodLfNMF",
    descOI: "<prodLfF>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 466,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "fuelNM",
    nmOI: "biofuel",
    tOutOI: "kou_fuelNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koNMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 467,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "paprNM",
    nmOI: "pulp and paper in use",
    tOutOI: "kou_paprNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 468,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "packNM",
    nmOI: "packing wood in use",
    tOutOI: "kou_packNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 469,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "furnNM",
    nmOI: "furniture and poles in use",
    tOutOI: "kou_furnNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 470,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "fibrNM",
    nmOI: "fiberboard in use",
    tOutOI: "kou_fibrNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 471,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "consNM",
    nmOI: "construction wood in use",
    tOutOI: "kou_consNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 472,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "resiNM",
    nmOI: "mill residue in use",
    tOutOI: "kou_resiNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 473,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "fuelLfNM",
    nmOI: "forest biofuel in landfill",
    tOutOI: "kou_fuelLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 474,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "paprLfNM",
    nmOI: "pulp and paper in landfill",
    tOutOI: "kou_paprLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 475,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "packLfNM",
    nmOI: "packing wood in landfill",
    tOutOI: "kou_packLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 476,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "furnLfNM",
    nmOI: "furniture and poles in landfill",
    tOutOI: "kou_furnLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 477,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "fibrLfNM",
    nmOI: "fiberboard in landfill",
    tOutOI: "kou_fibrLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 478,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "consLfNM",
    nmOI: "construction wood in landfill",
    tOutOI: "kou_consLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 479,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "resiLfNM",
    nmOI: "mill residue in landfill",
    tOutOI: "kou_resiLfNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 480,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "fuelCNRatio",
    nmOI: "biofuel",
    tOutOI: "kou_fuelCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 481,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "paprCNRatio",
    nmOI: "pulp and paper in use",
    tOutOI: "kou_paprCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 482,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "packCNRatio",
    nmOI: "packing wood in use",
    tOutOI: "kou_packCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 483,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "furnCNRatio",
    nmOI: "furniture and poles in use",
    tOutOI: "kou_furnCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 484,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 68,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "fibrCNRatio",
    nmOI: "fiberboard in use",
    tOutOI: "kou_fibrCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 485,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "consCNRatio",
    nmOI: "construction wood in use",
    tOutOI: "kou_consCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 486,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "resiCNRatio",
    nmOI: "mill residue in use",
    tOutOI: "kou_resiCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 487,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "fuelLfCNRatio",
    nmOI: "biofuel in landfill",
    tOutOI: "kou_fuelLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNFr",
    suffixOI: "koNA",
    prefixDOI: "koCNFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 488,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "paprLfCNRatio",
    nmOI: "pulp and paper in landfill",
    tOutOI: "kou_paprLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 489,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "packLfCNRatio",
    nmOI: "packing wood in landfill",
    tOutOI: "kou_packLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 490,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "furnLfCNRatio",
    nmOI: "furniture and poles in landfill",
    tOutOI: "kou_furnLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 491,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 68,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "fibrLfCNRatio",
    nmOI: "fiberboard in landfill",
    tOutOI: "kou_fibrLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 492,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "consLfCNRatio",
    nmOI: "construction wood in landfill",
    tOutOI: "kou_consLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 493,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "resiLfCNRatio",
    nmOI: "mill residue in landfill",
    tOutOI: "kou_resiLfCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 494,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "compNMA",
    nmOI: "complete agricultural system",
    tOutOI: "kou_compNMA",
    descOI: "<compA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 495,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "siteNMA",
    nmOI: "on-site agricultural system",
    tOutOI: "kou_siteNMA",
    descOI: "<siteA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 496,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "abgNMA",
    nmOI: "aboveground agricultural system",
    tOutOI: "kou_abgNMA",
    descOI: "<abgA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 497,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "blgNMA",
    nmOI: "belowground agricultural system",
    tOutOI: "kou_blgNMA",
    descOI: "<blgA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 498,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMNetMineralizA",
    nmOI: "Agricultural net N mineralization",
    tOutOI: "kou_flowNMNetMineralizA",
    descOI: "Net N mass moved to agricultural minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 499,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 78,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMGrMineralizA",
    nmOI: "Agricultural gross N mineralization",
    tOutOI: "kou_flowNMGrMineralizA",
    descOI: "Gross N mass moved to agricultural minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 500,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMGrImmobilizA",
    nmOI: "Agricultural gross N immobilization",
    tOutOI: "kou_flowNMGrImmobilizA",
    descOI: "Gross N mass moved from agricultural minerals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 501,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMCompAtmsA",
    nmOI: "from agricultural system",
    tOutOI: "kou_flowNMCompAtmsA",
    descOI: "from <compA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koNMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 502,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowNMAtmsCropPlnAA",
    nmOI: "planting, to crops",
    tOutOI: "kou_flowNMAtmsCropPlnAA",
    descOI: "planting, to <crop>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 503,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMAtmsCropProdA",
    nmOI: "production, to crops",
    tOutOI: "kou_flowNMAtmsCropProdA",
    descOI: "production, to <crop>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 504,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMMnrlAtmsA",
    nmOI: "from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsA",
    descOI: "from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMEmitNet",
    suffixOI: "koNA",
    prefixDOI: "koNMEmitNetL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 505,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMCropAtmsHarvA",
    nmOI: "harvest, from crops",
    tOutOI: "kou_flowNMCropAtmsHarvA",
    descOI: "harvest, from <crop>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 506,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMFoddAtmsGrazA",
    nmOI: "grazing",
    tOutOI: "kou_flowNMFoddAtmsGrazA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 507,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowNMMnrlAtmsNitrA",
    nmOI: "nitrification, from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsNitrA",
    descOI: "nitrification, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 508,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMMnrlAtmsNitaA",
    nmOI: "ammonium-nitrification, from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsNitaA",
    descOI: "nitrification due to the amount of shallow ammonium, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 509,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 110,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowNMMnrlAtmsNitmA",
    nmOI: "mineralization-nitrification, from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsNitmA",
    descOI: "nitrification due to net mineralization, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 510,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 110,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowNMMnrlAtmsDeniA",
    nmOI: "denitrification, from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsDeniA",
    descOI: "denitrification, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 511,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "ratioN2ToN2ODeniA",
    nmOI: "agricultural",
    tOutOI: "kou_ratioN2ToN2ODeniA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNRatio",
    suffixOI: "koNA",
    prefixDOI: "koNRatioL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 512,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 100,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "emitNInN2DeniA",
    nmOI: "denitrification, from agricultural minerals",
    tOutOI: "kou_emitNInN2DeniA",
    descOI: "denitrification, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNInN2EmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNInN2EmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 513,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "emitNInN2ODeniA",
    nmOI: "denitrification, from agricultural minerals",
    tOutOI: "kou_emitNInN2ODeniA",
    descOI: "denitrification, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNInN2OEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNInN2OEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 514,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMAtmsMnrlDepoA",
    nmOI: "atmospheric deposition, to agricultural minerals",
    tOutOI: "kou_flowNMAtmsMnrlDepoA",
    descOI: "atmospheric deposition, to <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 515,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 84,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowNMAtmsMnrlNfixA",
    nmOI: "non-symbiotic fixation, to agricultural minerals",
    tOutOI: "kou_flowNMAtmsMnrlNfixA",
    descOI: "non-symbiotic fixation, to <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMSeq",
    suffixOI: "koNA",
    prefixDOI: "koNMSeqL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 516,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 84,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMProdAtmsPdcmA",
    nmOI: "decomposition while in use, from agricultural products",
    tOutOI: "kou_flowNMProdAtmsPdcmA",
    descOI: "decomposition while in use, from <prodA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 517,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowNMProdAtmsEnerA",
    nmOI: "burning for bioenergy, from agricultural products",
    tOutOI: "kou_flowNMProdAtmsEnerA",
    descOI: "burning for bioenergy, from <prodA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 518,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMMnrlAtmsBondA",
    nmOI: "boundary-line emissions, from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsBondA",
    descOI: "boundary-line emissions, from <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 519,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowNMMnrlAtmsFireA",
    nmOI: "fire, from agricultural minerals",
    tOutOI: "kou_flowNMMnrlAtmsFireA",
    descOI: "minerals to atmosphere",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 520,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "cropNM",
    nmOI: "crops",
    tOutOI: "kou_cropNM",
    descOI: "<crop>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 521,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "gbfrNMA",
    nmOI: "crop grains, buds, and fruit",
    tOutOI: "kou_gbfrNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 522,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "stlkNMA",
    nmOI: "crop stalks",
    tOutOI: "kou_stlkNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 523,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leafNMA",
    nmOI: "crop leaves",
    tOutOI: "kou_leafNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 524,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortNMA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_cortNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 525,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtNMA",
    nmOI: "crop fine roots",
    tOutOI: "kou_firtNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 526,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "storNMA",
    nmOI: "crop internal store",
    tOutOI: "kou_storNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 527,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "gbfrCNRatioA",
    nmOI: "crop grains -buds-fruit",
    tOutOI: "kou_gbfrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 528,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "stlkCNRatioA",
    nmOI: "crop stalks",
    tOutOI: "kou_stlkCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 529,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leafCNRatioA",
    nmOI: "crop leaves",
    tOutOI: "kou_leafCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 530,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortCNRatioA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_cortCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 531,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtCNRatioA",
    nmOI: "crop fine roots",
    tOutOI: "kou_firtCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 532,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "minGbfrCNRatioA",
    nmOI: "crop grains -buds-fruit",
    tOutOI: "kou_minGbfrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 533,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "maxGbfrCNRatioA",
    nmOI: "crop grains -buds-fruit",
    tOutOI: "kou_maxGbfrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 534,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minStlkCNRatioA",
    nmOI: "crop stalks",
    tOutOI: "kou_minStlkCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 535,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxStlkCNRatioA",
    nmOI: "crop stalks",
    tOutOI: "kou_maxStlkCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 536,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minLeafCNRatioA",
    nmOI: "crop leaves",
    tOutOI: "kou_minLeafCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 537,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxLeafCNRatioA",
    nmOI: "crop leaves",
    tOutOI: "kou_maxLeafCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 538,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minCortCNRatioA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_minCortCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 539,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxCortCNRatioA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_maxCortCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 540,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "minFirtCNRatioA",
    nmOI: "crop fine roots",
    tOutOI: "kou_minFirtCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMin",
    suffixOI: "koNA",
    prefixDOI: "koCNMin",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 541,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "maxFirtCNRatioA",
    nmOI: "crop fine roots",
    tOutOI: "kou_maxFirtCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNMax",
    suffixOI: "koNA",
    prefixDOI: "koCNMax",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 542,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMCropDebrTurnA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowNMCropDebrTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 543,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMGbfrGlitTurnA",
    nmOI: "crop grains -buds-fruit to debris",
    tOutOI: "kou_flowNMGbfrGlitTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 544,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowNMStlkSlitTurnA",
    nmOI: "crop stalks to debris",
    tOutOI: "kou_flowNMStlkSlitTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 545,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMLeafLlitTurnA",
    nmOI: "crop leaves to debris",
    tOutOI: "kou_flowNMLeafLlitTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 546,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMLeafDLlitTurnA",
    nmOI: "crop leaves to decomposable leaf litter",
    tOutOI: "kou_flowNMLeafDLlitTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 547,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMCortCodrTurnA",
    nmOI: "crop coarse roots to debris",
    tOutOI: "kou_flowNMCortCodrTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 548,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMFirtFidrTurnA",
    nmOI: "crop fine roots to debris",
    tOutOI: "kou_flowNMFirtFidrTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 549,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMStorSlitTurnA",
    nmOI: "crop N storage to debris",
    tOutOI: "kou_flowNMStorSlitTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 550,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowNMLeafStorTurnA",
    nmOI: "crop leaves to crop N storage",
    tOutOI: "kou_flowNMLeafStorTurnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueTurn",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueTurn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 551,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "flowNMCropFoddGrazA",
    nmOI: "crops eaten in grazing",
    tOutOI: "kou_flowNMCropFoddGrazA",
    descOI: "crops eaten by grazing animals",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 552,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMDebrFoddGrazA",
    nmOI: "debris to fodder",
    tOutOI: "kou_flowNMDebrFoddGrazA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueGraz",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueGraz",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 553,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowNMFoddActvGrazA",
    nmOI: "crops and debris to soil due to grazing",
    tOutOI: "kou_flowNMFoddActvGrazA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 554,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMFoddMnrlGrazA",
    nmOI: "crops and debris to minerals due to grazing",
    tOutOI: "kou_flowNMFoddMnrlGrazA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 555,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowNMFoddAnprGrazA",
    nmOI: "crops and debris to animal products due to grazing",
    tOutOI: "kou_flowNMFoddAnprGrazA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 556,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowNMCropDebrHarvA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowNMCropDebrHarvA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 557,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMCropProdHarvA",
    nmOI: "crop to products",
    tOutOI: "kou_flowNMCropProdHarvA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 558,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMCropDebrFireA",
    nmOI: "crops to debris",
    tOutOI: "kou_flowNMCropDebrFireA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 559,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMCropDebrHerbA",
    nmOI: "crops to debris due to herbicide",
    tOutOI: "kou_flowNMCropDebrHerbA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 560,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "debrNMA",
    nmOI: "agricultural debris",
    tOutOI: "kou_debrNMA",
    descOI: "<debrA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 561,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "dGlitNMA",
    nmOI: "decomposable grains -buds-fruit litter",
    tOutOI: "kou_dGlitNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 562,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rGlitNMA",
    nmOI: "resistant grains -buds-fruit litter",
    tOutOI: "kou_rGlitNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 563,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dSlitNMA",
    nmOI: "decomposable stalk litter",
    tOutOI: "kou_dSlitNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 564,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "rSlitNMA",
    nmOI: "resistant stalk litter",
    tOutOI: "kou_rSlitNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 565,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dLlitNMA",
    nmOI: "decomposable leaf litter",
    tOutOI: "kou_dLlitNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 566,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rLlitNMA",
    nmOI: "resistant leaf litter",
    tOutOI: "kou_rLlitNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 567,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dCodrNMA",
    nmOI: "decomposable coarse roots",
    tOutOI: "kou_dCodrNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 568,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "rCodrNMA",
    nmOI: "resistant coarse roots",
    tOutOI: "kou_rCodrNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 569,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dFidrNMA",
    nmOI: "decomposable fine roots",
    tOutOI: "kou_dFidrNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 570,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rFidrNMA",
    nmOI: "resistant fine roots",
    tOutOI: "kou_rFidrNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 571,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "dGlitCNRatioA",
    nmOI: "decomposable grains -buds-fruits litter",
    tOutOI: "kou_dGlitCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 572,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rGlitCNRatioA",
    nmOI: "resistant grains -buds-fruits litter",
    tOutOI: "kou_rGlitCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 573,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dSlitCNRatioA",
    nmOI: "decomposable stalk litter",
    tOutOI: "kou_dSlitCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 574,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "rSlitCNRatioA",
    nmOI: "resistant stalk litter",
    tOutOI: "kou_rSlitCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 575,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dLlitCNRatioA",
    nmOI: "decomposable leaf litter",
    tOutOI: "kou_dLlitCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 576,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "rLlitCNRatioA",
    nmOI: "resistant leaf litter",
    tOutOI: "kou_rLlitCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 577,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dCodrCNRatioA",
    nmOI: "decomposable coarse roots",
    tOutOI: "kou_dCodrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 578,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "rCodrCNRatioA",
    nmOI: "resistant coarse roots",
    tOutOI: "kou_rCodrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 579,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dFidrCNRatioA",
    nmOI: "decomposable fine roots",
    tOutOI: "kou_dFidrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 580,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rFidrCNRatioA",
    nmOI: "resistant fine roots",
    tOutOI: "kou_rFidrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 581,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMDebrProdHarvA",
    nmOI: "debris to products",
    tOutOI: "kou_flowNMDebrProdHarvA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueHarv",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueHarv",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 582,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclLime",
  },
  {
    progNmOI: "mulcNMA",
    nmOI: "agricultural organic mulch",
    tOutOI: "kou_mulcNMA",
    descOI: "<mulcA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 583,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "sopmNMA",
    nmOI: "soluble plant mulch",
    tOutOI: "kou_sopmNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 584,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "lrpmNMA",
    nmOI: "less-resistant plant mulch",
    tOutOI: "kou_lrpmNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 585,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mrpmNMA",
    nmOI: "more-resistant plant mulch",
    tOutOI: "kou_mrpmNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 586,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sommNMA",
    nmOI: "dead soluble microbial mulch",
    tOutOI: "kou_sommNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 587,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "lrmmNMA",
    nmOI: "dead less -resistant microbial mulch",
    tOutOI: "kou_lrmmNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 588,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "mrmmNMA",
    nmOI: "dead more -resistant microbial mulch",
    tOutOI: "kou_mrmmNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 589,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "micrNMA",
    nmOI: "live microbes in agricultural mulch",
    tOutOI: "kou_micrNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 590,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "sopmCNRatioA",
    nmOI: "soluble plant mulch",
    tOutOI: "kou_sopmCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 591,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "lrpmCNRatioA",
    nmOI: "less-resistant plant mulch",
    tOutOI: "kou_lrpmCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 592,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mrpmCNRatioA",
    nmOI: "more-resistant plant mulch",
    tOutOI: "kou_mrpmCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 593,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "sommCNRatioA",
    nmOI: "dead soluble microbial mulch",
    tOutOI: "kou_sommCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 594,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "lrmmCNRatioA",
    nmOI: "dead less -resistant microbial mulch",
    tOutOI: "kou_lrmmCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 595,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "mrmmCNRatioA",
    nmOI: "dead more -resistant microbial mulch",
    tOutOI: "kou_mrmmCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 596,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "micrCNRatioA",
    nmOI: "live microbes in agricultural mulch",
    tOutOI: "kou_micrCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 597,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowNMLittSopmBkdnA",
    nmOI: "litter to soluble plant mulch",
    tOutOI: "kou_flowNMLittSopmBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 598,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMLittLrpmBkdnA",
    nmOI: "litter to less -resistant plant mulch",
    tOutOI: "kou_flowNMLittLrpmBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 599,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMLittMrpmBkdnA",
    nmOI: "litter to more -resistant plant mulch",
    tOutOI: "kou_flowNMLittMrpmBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mulc",
    sortNOI: 600,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "soilNMA",
    nmOI: "agricultural organic soil",
    tOutOI: "kou_soilNMA",
    descOI: "<soilA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 601,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "dpmaNMA",
    nmOI: "DPM",
    tOutOI: "kou_dpmaNMA",
    descOI: "decomposable",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 602,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "rpmaNMA",
    nmOI: "RPM",
    tOutOI: "kou_rpmaNMA",
    descOI: "resistant",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 603,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "biofNMA",
    nmOI: "BIO-F",
    tOutOI: "kou_biofNMA",
    descOI: "fast",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 604,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "biosNMA",
    nmOI: "BIO-S",
    tOutOI: "kou_biosNMA",
    descOI: "slow",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 605,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "biomNMA",
    nmOI: "BIO",
    tOutOI: "kou_biomNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 606,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "humsNMA",
    nmOI: "HUM",
    tOutOI: "kou_humsNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koHUMInsoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 607,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "inrtNMA",
    nmOI: "inert soil",
    tOutOI: "kou_inrtNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 608,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "dpmaCNRatioA",
    nmOI: "DPM",
    tOutOI: "kou_dpmaCNRatioA",
    descOI: "decomposable",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 609,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "rpmaCNRatioA",
    nmOI: "RPM",
    tOutOI: "kou_rpmaCNRatioA",
    descOI: "resistant",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koPMInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 610,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "biofCNRatioA",
    nmOI: "BIO-F",
    tOutOI: "kou_biofCNRatioA",
    descOI: "fast",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 611,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "biosCNRatioA",
    nmOI: "BIO-S",
    tOutOI: "kou_biosCNRatioA",
    descOI: "slow",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koXBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 612,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "biomCNRatioA",
    nmOI: "BIO",
    tOutOI: "kou_biomCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koBioInSoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 613,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "humsCNRatioA",
    nmOI: "HUM",
    tOutOI: "kou_humsCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koHUMInsoil",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 614,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 74,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "inrtCNRatioA",
    nmOI: "inert soil",
    tOutOI: "kou_inrtCNRatioA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 615,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMLittActvBkdnA",
    nmOI: "litter to soil",
    tOutOI: "kou_flowNMLittActvBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 616,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMDdrtActvBkdnA",
    nmOI: "dead roots to soil",
    tOutOI: "kou_flowNMDdrtActvBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 617,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMMulcActvHumfA",
    nmOI: "mulch to soil",
    tOutOI: "kou_flowNMMulcActvHumfA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueHumf",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 618,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMActvInrtEncpA",
    nmOI: "active soil to inert soil",
    tOutOI: "kou_flowNMActvInrtEncpA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueEncp",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueEncp",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 619,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMOffsActvMoffA",
    nmOI: "N mass added to agricultural soil as manure",
    tOutOI: "kou_flowNMOffsActvMoffA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 620,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowNMSoilStorMycoA",
    nmOI: "soil to internal crop storage by mycorrhiza",
    tOutOI: "kou_flowNMSoilStorMycoA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 621,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMCropDpmaPlowA",
    nmOI: "crops to DPM due to plowing",
    tOutOI: "kou_flowNMCropDpmaPlowA",
    descOI: "crops to decomposable",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 622,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowNMCropRpmaPlowA",
    nmOI: "crops to RPM due to plowing",
    tOutOI: "kou_flowNMCropRpmaPlowA",
    descOI: "crops to resistant",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koNA",
    prefixDOI: "koNMFrom",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 623,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowNMDebrDpmaPlowA",
    nmOI: "debris to DPM due to plowing",
    tOutOI: "kou_flowNMDebrDpmaPlowA",
    descOI: "debris to decomposable",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 624,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowNMDebrRpmaPlowA",
    nmOI: "debris to RPM due to plowing",
    tOutOI: "kou_flowNMDebrRpmaPlowA",
    descOI: "debris to resistant",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 625,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMMulcDpmaPlowA",
    nmOI: "mulch to DPM due to plowing",
    tOutOI: "kou_flowNMMulcDpmaPlowA",
    descOI: "mulch to decomposable",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 626,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowNMMulcRpmaPlowA",
    nmOI: "mulch to RPM due to plowing",
    tOutOI: "kou_flowNMMulcRpmaPlowA",
    descOI: "mulch to resistant",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koPMInSoilPlow",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 627,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "mnrlNMA",
    nmOI: "N mass in agricultural minerals",
    tOutOI: "kou_mnrlNMA",
    descOI: "N mass in <mnrlA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 628,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "mnamNMA",
    nmOI: "N mass as minerals available to the agricultural mulch",
    tOutOI: "kou_mnamNMA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 629,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "sAmmNMA",
    nmOI: "agricultural shallow soil",
    tOutOI: "kou_sAmmNMA",
    descOI: "agricultural",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMAmmIn",
    suffixOI: "koNA",
    prefixDOI: "koNMAmmIn",
    suffixDOI: "koSoilShallowL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 630,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "sNtrNMA",
    nmOI: "agricultural shallow soil",
    tOutOI: "kou_sNtrNMA",
    descOI: "agricultural",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNtrIn",
    suffixOI: "koNA",
    prefixDOI: "koNMNtrIn",
    suffixDOI: "koSoilShallowL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 631,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "dAmmNMA",
    nmOI: "agricultural deep soil",
    tOutOI: "kou_dAmmNMA",
    descOI: "agricultural",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMAmmIn",
    suffixOI: "koNA",
    prefixDOI: "koNMAmmIn",
    suffixDOI: "koSoilDeepL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 632,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "dNtrNMA",
    nmOI: "agricultural deep soil",
    tOutOI: "kou_dNtrNMA",
    descOI: "agricultural",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNtrIn",
    suffixOI: "koNA",
    prefixDOI: "koNMNtrIn",
    suffixDOI: "koSoilDeepL",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 633,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowNMMnrlCropProdA",
    nmOI: "minerals to crops due to production",
    tOutOI: "kou_flowNMMnrlCropProdA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koNA",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 634,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMLittMnrlBkdnA",
    nmOI: "litter to minerals",
    tOutOI: "kou_flowNMLittMnrlBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 635,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowNMDdrtMnrlBkdnA",
    nmOI: "dead roots to minerals",
    tOutOI: "kou_flowNMDdrtMnrlBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 636,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowNMMnrlSopmBkdnA",
    nmOI: "minerals to soluble plant mulch",
    tOutOI: "kou_flowNMMnrlSopmBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 637,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowNMMnrlActvBkdnA",
    nmOI: "minerals to soil",
    tOutOI: "kou_flowNMMnrlActvBkdnA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueBkdn",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueBkdn",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 638,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowNMMulcMnrlMdcmA",
    nmOI: "mulch to minerals",
    tOutOI: "kou_flowNMMulcMnrlMdcmA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueDcmp",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueDcmp",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 639,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowNMMulcMnrlHumfA",
    nmOI: "mulch to minerals",
    tOutOI: "kou_flowNMMulcMnrlHumfA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueHumf",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 640,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowNMMnrlActvHumfA",
    nmOI: "minerals to soil",
    tOutOI: "kou_flowNMMnrlActvHumfA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueHumf",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueHumf",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 641,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowNMActvMnrlSdcmA",
    nmOI: "soil to minerals",
    tOutOI: "kou_flowNMActvMnrlSdcmA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueDcmp",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueDcmp",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 642,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowNMOffsMnrlNoffA",
    nmOI: "N mass added to agricultural minerals from offsite",
    tOutOI: "kou_flowNMOffsMnrlNoffA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 643,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowNMCropMnrlFireA",
    nmOI: "crops to minerals",
    tOutOI: "kou_flowNMCropMnrlFireA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 644,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowNMMulcMnrlFireA",
    nmOI: "mulch to minerals",
    tOutOI: "kou_flowNMMulcMnrlFireA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 645,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowNMDebrMnrlFireA",
    nmOI: "debris to minerals",
    tOutOI: "kou_flowNMDebrMnrlFireA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMFromAg",
    suffixOI: "koDueFire",
    prefixDOI: "koNMFromAg",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 646,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowNMMnrlDeepLeacA",
    nmOI: "N mass leached from shallow nitrate to deep nitrate",
    tOutOI: "kou_flowNMMnrlDeepLeacA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 647,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowNMSAmmSNtrNitaA",
    nmOI: "ammonium",
    tOutOI: "kou_flowNMSAmmSNtrNitaA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNitrDueTo",
    suffixOI: "koAg",
    prefixDOI: "koNMNitrDueToL",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 648,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowNMSAmmSNtrNitmA",
    nmOI: "net mineralization",
    tOutOI: "kou_flowNMSAmmSNtrNitmA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMNitrDueTo",
    suffixOI: "koAg",
    prefixDOI: "koNMNitrDueToL",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 649,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "nitrTempFnA",
    nmOI: "Nit_T function",
    tOutOI: "kou_nitrTempFnA",
    descOI: "temperature function Nit_T",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInNitrA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 650,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "nitrPhFnA",
    nmOI: "Nit_pH function",
    tOutOI: "kou_nitrPhFnA",
    descOI: "pH function Nit_pH",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInNitrA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 651,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "nitrWaterFnA",
    nmOI: "Nit_TSMD function",
    tOutOI: "kou_nitrWaterFnA",
    descOI: "soil water function Nit_TSMD",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInNitrA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 652,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclRed",
  },
  {
    progNmOI: "nitrFnA",
    nmOI: "Nit_T * Nit_pH * Nit_TSMD",
    tOutOI: "kou_nitrFnA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValProduct",
    suffixDOI: "koInNitrA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 653,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "deniCO2FnA",
    nmOI: "Den_CO2 function",
    tOutOI: "kou_deniCO2FnA",
    descOI: "CO2 function Den_CO2",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 654,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "deniNO3FnA",
    nmOI: "Den_NO3 function",
    tOutOI: "kou_deniNO3FnA",
    descOI: "NO3 function Den_NO3",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 655,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "deniWaterFnA",
    nmOI: "Den_TSMD function",
    tOutOI: "kou_deniWaterFnA",
    descOI: "soil water function Den_TSMD",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 656,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "deniFnA",
    nmOI: "Min(Den_CO2, Den_NO3 ) * Den_TSMD",
    tOutOI: "kou_deniFnA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValProduct",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 657,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "deniRCO2FnA",
    nmOI: "DenR_CO2 function",
    tOutOI: "kou_deniRCO2FnA",
    descOI: "CO2 ratio -function DenR_CO2",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 658,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "deniRNO3FnA",
    nmOI: "DenR_NO3 function",
    tOutOI: "kou_deniRNO3FnA",
    descOI: "NO3 ratio -function DenR_NO3",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 659,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "deniRWaterFnA",
    nmOI: "DenR_TSMD function",
    tOutOI: "kou_deniRWaterFnA",
    descOI: "soil water ratio -function DenR_TSMD",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValOf",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 660,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "deniRFnA",
    nmOI: "Min(DenR_CO2, DenR_NO3 ) * DenR_TSMD",
    tOutOI: "kou_deniRFnA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNValProduct",
    suffixDOI: "koInDeniA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Mnrl",
    sortNOI: 661,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclPink",
  },
  {
    progNmOI: "prodNMA",
    nmOI: "agricultural products",
    tOutOI: "kou_prodNMA",
    descOI: "<prodA>",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 662,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "fualNM",
    nmOI: "biofuel",
    tOutOI: "kou_fualNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOfAg",
    suffixOI: "koNA",
    prefixDOI: "koNMOfAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 663,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "gbfpNM",
    nmOI: "grains-buds-fruit products",
    tOutOI: "kou_gbfpNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 664,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "caneNM",
    nmOI: "cane products",
    tOutOI: "kou_caneNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 665,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "lfprNM",
    nmOI: "leaf products",
    tOutOI: "kou_lfprNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 666,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "rtprNM",
    nmOI: "root products",
    tOutOI: "kou_rtprNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 667,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "hssiNM",
    nmOI: "hay-straw-silage",
    tOutOI: "kou_hssiNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 668,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "anprNM",
    nmOI: "animal products",
    tOutOI: "kou_anprNM",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNMOf",
    suffixOI: "koNA",
    prefixDOI: "koNMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 669,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "fualCNRatio",
    nmOI: "biofuel",
    tOutOI: "kou_fualCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCNAg",
    suffixOI: "koNA",
    prefixDOI: "koCNAg",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 670,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "gbfpCNRatio",
    nmOI: "grains-buds-fruit products",
    tOutOI: "kou_gbfpCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 671,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "caneCNRatio",
    nmOI: "cane products",
    tOutOI: "kou_caneCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 672,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "lfprCNRatio",
    nmOI: "leaf products",
    tOutOI: "kou_lfprCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 673,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "rtprCNRatio",
    nmOI: "root products",
    tOutOI: "kou_rtprCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 674,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "hssiCNRatio",
    nmOI: "hay-straw-silage",
    tOutOI: "kou_hssiCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 675,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "anprCNRatio",
    nmOI: "animal products",
    tOutOI: "kou_anprCNRatio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koCN",
    suffixOI: "koNA",
    prefixDOI: "koCN",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Prod",
    sortNOI: 676,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "leakNTree",
    nmOI: "N leak : Trees",
    tOutOI: "kou_leakNTree",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 677,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakNDebrF",
    nmOI: "N leak : forest debris",
    tOutOI: "kou_leakNDebrF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 678,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "leakNMulcF",
    nmOI: "N leak : forest mulch",
    tOutOI: "kou_leakNMulcF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 679,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "leakNSoilF",
    nmOI: "N leak : forest soil",
    tOutOI: "kou_leakNSoilF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 680,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "leakNMnrlF",
    nmOI: "N leak : forest minerals",
    tOutOI: "kou_leakNMnrlF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 681,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "leakNProdF",
    nmOI: "N leak : forest products",
    tOutOI: "kou_leakNProdF",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 682,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "leakNCrop",
    nmOI: "N leak : Crops",
    tOutOI: "kou_leakNCrop",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 683,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "leakNDebrA",
    nmOI: "N leak : Agricultural debris",
    tOutOI: "kou_leakNDebrA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 684,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "leakNMulcA",
    nmOI: "N leak : Agricultural mulch",
    tOutOI: "kou_leakNMulcA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 685,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "leakNSoilA",
    nmOI: "N leak : Agricultural soil",
    tOutOI: "kou_leakNSoilA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 686,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "leakNMnrlA",
    nmOI: "N leak : Agricultural minerals",
    tOutOI: "kou_leakNMnrlA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 687,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "leakNProdA",
    nmOI: "N leak : Agricultural products",
    tOutOI: "kou_leakNProdA",
    descOI: "",
    unitNmPloOI: "tN/ha",
    unitNmSpaOI: "tN",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_N",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 688,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowCost",
    nmOI: "Costs",
    tOutOI: "kou_flowCost",
    descOI: "All costs",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 689,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowIncm",
    nmOI: "Income",
    tOutOI: "kou_flowIncm",
    descOI: "All income",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 690,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowCash",
    nmOI: "Cashflow",
    tOutOI: "kou_flowCash",
    descOI: "Total cashflow (all income less all costs )",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 691,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCashDiscounted",
    nmOI: "Discounted cashflow",
    tOutOI: "kou_flowCashDiscounted",
    descOI:
      "Total cashflow (all income less all costs ), discounted to the reference year at the discount rate",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 692,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "NPV",
    nmOI: "NPV",
    tOutOI: "kou_NPV",
    descOI:
      "Net present value, the sum of all future discounted cashflows (to the end of the simulation )",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 693,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowCostHarv",
    nmOI: "Harvesting costs",
    tOutOI: "kou_flowCostHarv",
    descOI: "Costs of thins and harvests",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 694,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "flowCostTrns",
    nmOI: "Transport costs",
    tOutOI: "kou_flowCostTrns",
    descOI: "Costs of transporting material from site to mill gate",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 695,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowIncmMill",
    nmOI: "Mill income",
    tOutOI: "kou_flowIncmMill",
    descOI: "Money received for material from the mill",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 696,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "priceCO2",
    nmOI: "Carbon price (CO2-e)",
    tOutOI: "kou_priceCO2",
    descOI: "Dollars per tonne of carbon dioxide or equivalent",
    unitNmPloOI: "$/t CO2 -e",
    unitNmSpaOI: "$/t CO2 -e",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 697,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Econ",
    colWidthOI: 65,
    colorOI: "kclRed",
  },
  {
    progNmOI: "priceC",
    nmOI: "Carbon price (C)",
    tOutOI: "kou_priceC",
    descOI: "Dollars per tonne of carbon mass",
    unitNmPloOI: "$/t of C",
    unitNmSpaOI: "$/t of C",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 698,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Econ",
    colWidthOI: 65,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "cIncm",
    nmOI: "the whole plot",
    tOutOI: "kou_cIncm",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 699,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "cIncmF",
    nmOI: "the forest",
    tOutOI: "kou_cIncmF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 700,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclGold",
  },
  {
    progNmOI: "cIncmA",
    nmOI: "the agricultural system",
    tOutOI: "kou_cIncmA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 701,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cIncmTreeF",
    nmOI: "trees",
    tOutOI: "kou_cIncmTreeF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 702,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclGray",
  },
  {
    progNmOI: "cIncmDebrF",
    nmOI: "forest debris",
    tOutOI: "kou_cIncmDebrF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 703,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "cIncmMulcF",
    nmOI: "forest mulch",
    tOutOI: "kou_cIncmMulcF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 704,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "cIncmSoilF",
    nmOI: "forest soil",
    tOutOI: "kou_cIncmSoilF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 705,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "cIncmProdF",
    nmOI: "forest products",
    tOutOI: "kou_cIncmProdF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 706,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "cIncmCropA",
    nmOI: "crops",
    tOutOI: "kou_cIncmCropA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 707,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "cIncmDebrA",
    nmOI: "agricultural debris",
    tOutOI: "kou_cIncmDebrA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 708,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclPink",
  },
  {
    progNmOI: "cIncmMulcA",
    nmOI: "agricultural mulch",
    tOutOI: "kou_cIncmMulcA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 709,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "cIncmSoilA",
    nmOI: "agricultural soil",
    tOutOI: "kou_cIncmSoilA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 710,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "cIncmProdA",
    nmOI: "agricultural products",
    tOutOI: "kou_cIncmProdA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koNA",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDEachStep",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Ledg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 711,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclRed",
  },
  {
    progNmOI: "cumuCost",
    nmOI: "Costs",
    tOutOI: "kou_cumuCost",
    descOI: "All costs",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koToDate",
    prefixDOI: "koNA",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 712,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "cumuIncm",
    nmOI: "Income",
    tOutOI: "kou_cumuIncm",
    descOI: "All income",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koToDate",
    prefixDOI: "koNA",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 713,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "cumuCash",
    nmOI: "Cashflow",
    tOutOI: "kou_cumuCash",
    descOI: "Total cashflow (all income less all costs )",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koToDate",
    prefixDOI: "koNA",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 714,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "cumuCostHarv",
    nmOI: "Harvesting costs",
    tOutOI: "kou_cumuCostHarv",
    descOI: "Costs of thins and harvests",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koToDate",
    prefixDOI: "koNA",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 715,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "cumuCostTrns",
    nmOI: "Transport costs",
    tOutOI: "kou_cumuCostTrns",
    descOI: "Costs of transporting material from site to mill gate",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koToDate",
    prefixDOI: "koNA",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 716,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "cumuIncmMill",
    nmOI: "Mill income",
    tOutOI: "kou_cumuIncmMill",
    descOI: "Money received for material from the mill",
    unitNmPloOI: "$",
    unitNmSpaOI: "$",
    prefixOI: "koNA",
    suffixOI: "koToDate",
    prefixDOI: "koNA",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 717,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Econ",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "cumuCIncm",
    nmOI: "the whole plot",
    tOutOI: "kou_cumuCIncm",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koToDate",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 718,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "cumuCIncmF",
    nmOI: "the forest",
    tOutOI: "kou_cumuCIncmF",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koToDate",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 719,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "cumuCIncmA",
    nmOI: "the agricultural system",
    tOutOI: "kou_cumuCIncmA",
    descOI: "",
    unitNmPloOI: "$/ha",
    unitNmSpaOI: "$",
    prefixOI: "koCIncmFrom",
    suffixOI: "koToDate",
    prefixDOI: "koCIncmFromChngsIn",
    suffixDOI: "koDFromSt",
    tOutCat0_OI: "kv_Dollars",
    tOutCat1_OI: "kv_Cumu",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 720,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "NA",
    nmOI: "Error (Report a bug if you see this !)",
    tOutOI: "kou_NA",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 721,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "yr",
    nmOI: "Year",
    tOutOI: "kou_yr",
    descOI: "Calendar year",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 722,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 42,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "mo",
    nmOI: "Month",
    tOutOI: "kou_mo",
    descOI: "Month number (Jan = 1, Feb = 2, etc )",
    unitNmPloOI: "mo",
    unitNmSpaOI: "mo",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 723,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 42,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "day",
    nmOI: "Day of month",
    tOutOI: "kou_day",
    descOI: "",
    unitNmPloOI: "day",
    unitNmSpaOI: "day",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 724,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 30,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "dayOfYr",
    nmOI: "Day of year",
    tOutOI: "kou_dayOfYr",
    descOI: "",
    unitNmPloOI: "day",
    unitNmSpaOI: "day",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 725,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 30,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "dateStr",
    nmOI: "Date string",
    tOutOI: "kou_dateStr",
    descOI: "",
    unitNmPloOI: "yyyymmdd",
    unitNmSpaOI: "yyyymmdd",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 726,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "stepEnTimeInYrs",
    nmOI: "Time in decimal years",
    tOutOI: "kou_stepEnTimeInYrs",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 727,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "stepEnTimeInDays",
    nmOI: "Time in decimal days",
    tOutOI: "kou_stepEnTimeInDays",
    descOI: "",
    unitNmPloOI: "day",
    unitNmSpaOI: "day",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koEndOfStep",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 728,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "step",
    nmOI: "Step",
    tOutOI: "kou_step",
    descOI: "Simulation-step number",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 729,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 36,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "treeAge",
    nmOI: "Tree age used in calculations",
    tOutOI: "kou_treeAge",
    descOI: "Tree age for calculations during the step",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 730,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "tyfTreeAge",
    nmOI: "Tree age used in the tree yield formula",
    tOutOI: "kou_tyfTreeAge",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 731,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 50,
    colorOI: "kclLime",
  },
  {
    progNmOI: "maxTreeAge",
    nmOI: "Age of oldest trees",
    tOutOI: "kou_maxTreeAge",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 732,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 50,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "avgTreeAge",
    nmOI: "Average age of trees",
    tOutOI: "kou_avgTreeAge",
    descOI:
      "Average age of trees (may be lowered by tree mortality at end of step )",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 733,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 50,
    colorOI: "kclRed",
  },
  {
    progNmOI: "avgTreeAgeMort",
    nmOI: "Average age of trees that die due to  mortality ",
    tOutOI: "kou_avgTreeAgeMort",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 734,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "cropAge",
    nmOI: "Crop age used in calculations",
    tOutOI: "kou_cropAge",
    descOI: "Crop age for calculations during the step",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 735,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "maxCropAge",
    nmOI: "Age of oldest crop plants",
    tOutOI: "kou_maxCropAge",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 736,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 50,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "avgCropAge",
    nmOI: "Average age of crop plants",
    tOutOI: "kou_avgCropAge",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 737,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 50,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "avgCropAgeMort",
    nmOI: "Average age of crop plants that die due to  mortality ",
    tOutOI: "kou_avgCropAgeMort",
    descOI: "",
    unitNmPloOI: "yr",
    unitNmSpaOI: "yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 738,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "timeSinceLastClr",
    nmOI: "Time since last clearing",
    tOutOI: "kou_timeSinceLastClr",
    descOI: "Number of years since between last clearing and this output step",
    unitNmPloOI: "yrs",
    unitNmSpaOI: "yrs",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 739,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Same",
    colWidthOI: 64,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "tyf_G",
    nmOI: "Tree age of maximum growth (G)",
    tOutOI: "kou_tyf_G",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 740,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 50,
    colorOI: "kclLime",
  },
  {
    progNmOI: "tyf_y",
    nmOI: "Tree yield multiplier",
    tOutOI: "kou_tyf_y",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Time",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 741,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Same",
    colWidthOI: 50,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "eventCategory",
    nmOI: "Event category",
    tOutOI: "kou_eventCategory",
    descOI: "Last Event category found (set by Ag Regime it came from )",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 742,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "mgmtRegime",
    nmOI: "Management regime unique identifier",
    tOutOI: "kou_mgmtRegime",
    descOI: "Last management regime identifier found",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 743,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "mgmtRegimeChanged",
    nmOI: "Management regime changed",
    tOutOI: "kou_mgmtRegimeChanged",
    descOI: "regime changed",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 744,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "mgmtSpecA",
    nmOI: "Ag species planted",
    tOutOI: "kou_mgmtSpecA",
    descOI: "Currently growing Ag species",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 745,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "mgmtSpecF",
    nmOI: "Forest species planted",
    tOutOI: "kou_mgmtSpecF",
    descOI: "Currently growing Forest species",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 746,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "mgmtPersistCount",
    nmOI: "Persistence Count",
    tOutOI: "kou_mgmtPersistCount",
    descOI: "Count of Persistence",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 747,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "unused914",
    nmOI: "Unused 914",
    tOutOI: "kou_unused914",
    descOI: "Unused 914",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_OASys",
    tOutCat1_OI: "kv_DB",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 748,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 0,
    tScalingOI: "Same",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "rainfall",
    nmOI: "Rainfall",
    tOutOI: "kou_rainfall",
    descOI: "Rainfall falling on site",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Water",
    sortNOI: 749,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 48,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "openPanEvap",
    nmOI: "Open-pan evaporation",
    tOutOI: "kou_openPanEvap",
    descOI: "Open-pan evaporation at site",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Water",
    sortNOI: 750,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "VPD",
    nmOI: "Vapor pressure deficit (VPD)",
    tOutOI: "kou_VPD",
    descOI: "Mean daily vapor pressure deficit (VPD) at site",
    unitNmPloOI: "kPa",
    unitNmSpaOI: "kPa",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Water",
    sortNOI: 751,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 48,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "frFrac",
    nmOI: "Forest percentage (remainder is agricultural )",
    tOutOI: "kou_frFrac",
    descOI: "NPP going to trees (remainder goes to crops )",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koPerc",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 752,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclGray",
  },
  {
    progNmOI: "avgAirTemp",
    nmOI: "Average air temperature",
    tOutOI: "kou_avgAirTemp",
    descOI: "Mean daily average air temperature on site",
    unitNmPloOI: "deg C",
    unitNmSpaOI: "deg C",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 753,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "rangeAirTemp",
    nmOI: "Air temperature daily range",
    tOutOI: "kou_rangeAirTemp",
    descOI: "Mean daily air temperature range (max - min ) on site",
    unitNmPloOI: "deg C",
    unitNmSpaOI: "deg C",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 754,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclRed",
  },
  {
    progNmOI: "minAirTemp",
    nmOI: "Minimum air temperature",
    tOutOI: "kou_minAirTemp",
    descOI: "Mean daily minimum air temperature on site",
    unitNmPloOI: "deg C",
    unitNmSpaOI: "deg C",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 755,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "maxAirTemp",
    nmOI: "Maximum air temperature",
    tOutOI: "kou_maxAirTemp",
    descOI: "Mean daily maximum air temperature on site",
    unitNmPloOI: "deg C",
    unitNmSpaOI: "deg C",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 756,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "soilTemp",
    nmOI: "Soil temperature",
    tOutOI: "kou_soilTemp",
    descOI: "Average soil temperature on site",
    unitNmPloOI: "deg C",
    unitNmSpaOI: "deg C",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 757,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "fertility",
    nmOI: "Soil fertility rating",
    tOutOI: "kou_fertility",
    descOI: "Soil fertility rating of site (used by 3PG only )",
    unitNmPloOI: "0..1",
    unitNmSpaOI: "0..1",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 758,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "solarRad",
    nmOI: "Solar radiation",
    tOutOI: "kou_solarRad",
    descOI: "Solar radiation incident on site",
    unitNmPloOI: "MJ/m2",
    unitNmSpaOI: "MJ/m2",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 759,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 52,
    colorOI: "kclGold",
  },
  {
    progNmOI: "frostNights",
    nmOI: "Frost nights",
    tOutOI: "kou_frostNights",
    descOI: "Number of nights of frost experienced on site",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Other",
    sortNOI: 760,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 48,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowMSiteAtmsFire",
    nmOI: "Mass emitted",
    tOutOI: "kou_flowMSiteAtmsFire",
    descOI: "Mass emitted to atmosphere",
    unitNmPloOI: "kg dm /ha",
    unitNmSpaOI: "kg dm",
    prefixOI: "koNA",
    suffixOI: "koDueFire",
    prefixDOI: "koNA",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 761,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "treeM",
    nmOI: "trees",
    tOutOI: "kou_treeM",
    descOI: "<tree>",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 762,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "treeAbgM",
    nmOI: "aboveground tree components",
    tOutOI: "kou_treeAbgM",
    descOI: "<treeAbg>",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 763,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclLime",
  },
  {
    progNmOI: "treeBlgM",
    nmOI: "belowground tree components",
    tOutOI: "kou_treeBlgM",
    descOI: "<treeBlg>",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 764,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "stemVolF",
    nmOI: "Volume of tree stems",
    tOutOI: "kou_stemVolF",
    descOI: "",
    unitNmPloOI: "m3/ha",
    unitNmSpaOI: "m3",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 765,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "stemDensity",
    nmOI: "Stem density",
    tOutOI: "kou_stemDensity",
    descOI: "Basic density of tree stems",
    unitNmPloOI: "dmt/m3",
    unitNmSpaOI: "dmt/m3",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 766,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 50,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "stemMF",
    nmOI: "tree stems",
    tOutOI: "kou_stemMF",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 767,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "branMF",
    nmOI: "tree branches",
    tOutOI: "kou_branMF",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 768,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "barkMF",
    nmOI: "tree bark",
    tOutOI: "kou_barkMF",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 769,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "leafMF",
    nmOI: "tree leaves",
    tOutOI: "kou_leafMF",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 770,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortMF",
    nmOI: "tree coarse roots",
    tOutOI: "kou_cortMF",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 771,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtMF",
    nmOI: "tree fine roots",
    tOutOI: "kou_firtMF",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 772,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "forestProdIx",
    nmOI: "Forest productivity index (annual rate )",
    tOutOI: "kou_forestProdIx",
    descOI:
      "Productivity of site used by tree yield formula, as an annual rate",
    unitNmPloOI: "1...60",
    unitNmSpaOI: "1...60",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 773,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "stemMortF",
    nmOI: "tree stems that died due to  mortality ",
    tOutOI: "kou_stemMortF",
    descOI: "",
    unitNmPloOI: "%",
    unitNmSpaOI: "%",
    prefixOI: "koPerc",
    suffixOI: "koNA",
    prefixDOI: "koPerc",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 774,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "LAI",
    nmOI: "Leaf area index (LAI) of tree canopy",
    tOutOI: "kou_LAI",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 775,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 56,
    colorOI: "kclLime",
  },
  {
    progNmOI: "NPP",
    nmOI: "Net primary production (NPP) of trees",
    tOutOI: "kou_NPP",
    descOI:
      "New tree material created by trees due to combined effect of photosynthesis and respiration",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 776,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "modTemp",
    nmOI: "3PG temperature modifier (modTemp)",
    tOutOI: "kou_modTemp",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 777,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "modNutr",
    nmOI: "3PG soil nutrition modifier (modNutr)",
    tOutOI: "kou_modNutr",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 778,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "modFrost",
    nmOI: "3PG frost modifier (modFrost)",
    tOutOI: "kou_modFrost",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 779,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "modVPD",
    nmOI: "3PG vapor pressure deficit modifier (modVPD)",
    tOutOI: "kou_modVPD",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 780,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "modAge",
    nmOI: "3PG age modifier (modAge)",
    tOutOI: "kou_modAge",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 781,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "modASW",
    nmOI: "3PG soil water modifier (modASW)",
    tOutOI: "kou_modASW",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 782,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "modPhysio",
    nmOI: "3PG physiological modifier (modPhysio)",
    tOutOI: "kou_modPhysio",
    descOI: "",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 783,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "nStems",
    nmOI: "Number of tree stems",
    tOutOI: "kou_nStems",
    descOI: "",
    unitNmPloOI: "#/ha",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 784,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "stemM3PG",
    nmOI: "tree stems, branches, and bark",
    tOutOI: "kou_stemM3PG",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 785,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "foliM3PG",
    nmOI: "tree foliage",
    tOutOI: "kou_foliM3PG",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 786,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "rootM3PG",
    nmOI: "tree roots",
    tOutOI: "kou_rootM3PG",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 787,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "stemVol3PG",
    nmOI: "Volume of tree stems",
    tOutOI: "kou_stemVol3PG",
    descOI: "",
    unitNmPloOI: "m3/ha",
    unitNmSpaOI: "m3",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koExclBB",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 788,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "MAI",
    nmOI: "Mean annual increment (MAI) of tree stems",
    tOutOI: "kou_MAI",
    descOI: "",
    unitNmPloOI: "m3/ha/yr",
    unitNmSpaOI: "m3/yr",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koExclBB",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 789,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 62,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "avgDBH",
    nmOI: "Average diameter of tree stems at breast height (DBH)",
    tOutOI: "kou_avgDBH",
    descOI: "",
    unitNmPloOI: "cm",
    unitNmSpaOI: "cm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 790,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "annualLitterfall",
    nmOI: "Annual cumulative litterfall",
    tOutOI: "kou_annualLitterfall",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 791,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "annualTranspiration",
    nmOI: "Annual cumulative transpiration",
    tOutOI: "kou_annualTranspiration",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 792,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "expansionFactor",
    nmOI: "Expansion factor of trees",
    tOutOI: "kou_expansionFactor",
    descOI:
      "Mass of tree stems, branches, bark and leaves / Mass of tree stems",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 793,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "defnitIrrigF",
    nmOI: "Irigation applied regardless of soil moisture",
    tOutOI: "kou_defnitIrrigF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFrIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 794,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "conditIrrigF",
    nmOI: "max-TSMD guaranteed by conditional irrigation",
    tOutOI: "kou_conditIrrigF",
    descOI: "maximum topsoil moisture deficit that is guaranteed by irrigation",
    unitNmPloOI: "%",
    unitNmSpaOI: "%",
    prefixOI: "koPerc",
    suffixOI: "koFr",
    prefixDOI: "koPerc",
    suffixDOI: "koFrIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 795,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "conditIrrigMMF",
    nmOI: "Irrigation applied due to conditions",
    tOutOI: "kou_conditIrrigMMF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 796,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "rainAndIrrigF",
    nmOI: "Combined rainfall and irrigation",
    tOutOI: "kou_rainAndIrrigF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 797,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "evapF",
    nmOI: "Evaporation from soil",
    tOutOI: "kou_evapF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 798,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "netWaterInputF",
    nmOI: "Net water input (rainfall + irrigation - evaporation )",
    tOutOI: "kou_netWaterInputF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 799,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclGray",
  },
  {
    progNmOI: "maxTSMDF",
    nmOI: "Max TSMD",
    tOutOI: "kou_maxTSMDF",
    descOI: "Maximum topsoil moisture deficit",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 800,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "TSMDF",
    nmOI: "TSMD",
    tOutOI: "kou_TSMDF",
    descOI:
      "Topsoil moisture deficit (TSMD), the lack of non -draining soil water available to trees",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 801,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "excessWaterF",
    nmOI: "Excess water",
    tOutOI: "kou_excessWaterF",
    descOI: "Excess water (= drainage + runoff )",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 802,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "drainF",
    nmOI: "Drainage from topsoil to deeper soil",
    tOutOI: "kou_drainF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 803,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "runoffF",
    nmOI: "Runoff from topsoil to offsite",
    tOutOI: "kou_runoffF",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 804,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "rateTempWaterF",
    nmOI: "Rate multiplier due to temperature and forest topsoil moisture",
    tOutOI: "kou_rateTempWaterF",
    descOI: "Affects rate of debris breakdown and soil decomposition",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koFr",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 805,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 72,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "ASW",
    nmOI: "Available soil water (ASW)",
    tOutOI: "kou_ASW",
    descOI:
      "Used by 3PG. Not coordinated with the topsoil moisture deficit (TSMD)!",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koFr",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 806,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 72,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "transpirationPS",
    nmOI: "Transpiration (3PG)",
    tOutOI: "kou_transpirationPS",
    descOI: "Water removed from soil by trees",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 807,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 68,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "evaporationPS",
    nmOI: "Evaporation (3PG)",
    tOutOI: "kou_evaporationPS",
    descOI: "= transpiration + rain intercepted by the tree canopy",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 808,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "eqmEvap",
    nmOI: "Equilibrium evaporation (3PG lite )",
    tOutOI: "kou_eqmEvap",
    descOI:
      "Used to compute forest productivity index in absence of soil water modifier",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Water",
    sortNOI: 809,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 64,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "prodRateStemMF",
    nmOI: "tree stem mass",
    tOutOI: "kou_prodRateStemMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 810,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "prodRateBranMF",
    nmOI: "tree branch mass",
    tOutOI: "kou_prodRateBranMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 811,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "prodRateBarkMF",
    nmOI: "tree bark mass",
    tOutOI: "kou_prodRateBarkMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 812,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "prodRateLeafMF",
    nmOI: "tree leaf mass",
    tOutOI: "kou_prodRateLeafMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 813,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "prodRateCortMF",
    nmOI: "tree coarse roots mass",
    tOutOI: "kou_prodRateCortMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 814,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "prodRateFirtMF",
    nmOI: "tree fine roots mass",
    tOutOI: "kou_prodRateFirtMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 815,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "prodRateTreeMF",
    nmOI: "tree mass",
    tOutOI: "kou_prodRateTreeMF",
    descOI: "mass of <tree>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 816,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "prodRateAbgFMF",
    nmOI: "aboveground tree mass",
    tOutOI: "kou_prodRateAbgFMF",
    descOI: "mass of <treeAbg>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 817,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "turnRateStemMF",
    nmOI: "tree stem mass",
    tOutOI: "kou_turnRateStemMF",
    descOI: "tree stem mass (due to tree mortality and biomass limit )",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 818,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "turnRateBranMF",
    nmOI: "tree branch mass",
    tOutOI: "kou_turnRateBranMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 819,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "turnRateBarkMF",
    nmOI: "tree bark mass",
    tOutOI: "kou_turnRateBarkMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 820,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "turnRateLeafMF",
    nmOI: "tree leaf mass",
    tOutOI: "kou_turnRateLeafMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 821,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "turnRateCortMF",
    nmOI: "tree coarse roots mass",
    tOutOI: "kou_turnRateCortMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 822,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "turnRateFirtMF",
    nmOI: "tree fine roots mass",
    tOutOI: "kou_turnRateFirtMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 823,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "turnRateTreeMF",
    nmOI: "tree mass",
    tOutOI: "kou_turnRateTreeMF",
    descOI: "mass of <tree>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 824,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "turnRateAbgFMF",
    nmOI: "aboveground tree mass",
    tOutOI: "kou_turnRateAbgFMF",
    descOI: "mass of <treeAbg>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 825,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "yielRateStemMF",
    nmOI: "tree stem mass",
    tOutOI: "kou_yielRateStemMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 826,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "yielRateBranMF",
    nmOI: "tree branch mass",
    tOutOI: "kou_yielRateBranMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 827,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "yielRateBarkMF",
    nmOI: "tree bark mass",
    tOutOI: "kou_yielRateBarkMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 828,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "yielRateLeafMF",
    nmOI: "tree leaf mass",
    tOutOI: "kou_yielRateLeafMF",
    descOI: "tree leaf mass",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 829,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "yielRateCortMF",
    nmOI: "tree coarse roots mass",
    tOutOI: "kou_yielRateCortMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 830,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "yielRateFirtMF",
    nmOI: "tree fine roots mass",
    tOutOI: "kou_yielRateFirtMF",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 831,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "yielRateTreeMF",
    nmOI: "tree mass",
    tOutOI: "kou_yielRateTreeMF",
    descOI: "mass of <tree>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 832,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "yielRateAbgFMF",
    nmOI: "aboveground tree mass",
    tOutOI: "kou_yielRateAbgFMF",
    descOI: "mass of <treeAbg>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 833,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowLogsTot",
    nmOI: "Logs (all types and grades, total )",
    tOutOI: "kou_flowLogsTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 834,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowWoodTot",
    nmOI: "Stems and branches (all log grades, total )",
    tOutOI: "kou_flowWoodTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 835,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowBarkTot",
    nmOI: "Bark (all log grades, total )",
    tOutOI: "kou_flowBarkTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 836,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowFuelTot",
    nmOI: "Biofuel (all log grades, total )",
    tOutOI: "kou_flowFuelTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 837,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowWood1",
    nmOI: "1",
    tOutOI: "kou_flowWood1",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koWood",
    suffixOI: "koNA",
    prefixDOI: "koWood",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 838,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowBark1",
    nmOI: "1",
    tOutOI: "kou_flowBark1",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koBark",
    suffixOI: "koNA",
    prefixDOI: "koBark",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 839,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowFuel1",
    nmOI: "1",
    tOutOI: "kou_flowFuel1",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFuel",
    suffixOI: "koNA",
    prefixDOI: "koFuel",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 840,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowWood2",
    nmOI: "2",
    tOutOI: "kou_flowWood2",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koWood",
    suffixOI: "koNA",
    prefixDOI: "koWood",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 841,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowBark2",
    nmOI: "2",
    tOutOI: "kou_flowBark2",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koBark",
    suffixOI: "koNA",
    prefixDOI: "koBark",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 842,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowFuel2",
    nmOI: "2",
    tOutOI: "kou_flowFuel2",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFuel",
    suffixOI: "koNA",
    prefixDOI: "koFuel",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 843,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowWood3",
    nmOI: "3",
    tOutOI: "kou_flowWood3",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koWood",
    suffixOI: "koNA",
    prefixDOI: "koWood",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 844,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowBark3",
    nmOI: "3",
    tOutOI: "kou_flowBark3",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koBark",
    suffixOI: "koNA",
    prefixDOI: "koBark",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 845,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowFuel3",
    nmOI: "3",
    tOutOI: "kou_flowFuel3",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFuel",
    suffixOI: "koNA",
    prefixDOI: "koFuel",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 846,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowWood4",
    nmOI: "4",
    tOutOI: "kou_flowWood4",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koWood",
    suffixOI: "koNA",
    prefixDOI: "koWood",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 847,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowBark4",
    nmOI: "4",
    tOutOI: "kou_flowBark4",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koBark",
    suffixOI: "koNA",
    prefixDOI: "koBark",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 848,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowFuel4",
    nmOI: "4",
    tOutOI: "kou_flowFuel4",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFuel",
    suffixOI: "koNA",
    prefixDOI: "koFuel",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 849,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "flowWood5",
    nmOI: "5",
    tOutOI: "kou_flowWood5",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koWood",
    suffixOI: "koNA",
    prefixDOI: "koWood",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 850,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "flowBark5",
    nmOI: "5",
    tOutOI: "kou_flowBark5",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koBark",
    suffixOI: "koNA",
    prefixDOI: "koBark",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 851,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowFuel5",
    nmOI: "5",
    tOutOI: "kou_flowFuel5",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFuel",
    suffixOI: "koNA",
    prefixDOI: "koFuel",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Logs",
    sortNOI: 852,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "cropM",
    nmOI: "crop",
    tOutOI: "kou_cropM",
    descOI: "<crop>",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 853,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "cropAbgM",
    nmOI: "aboveground crop components",
    tOutOI: "kou_cropAbgM",
    descOI: "<cropAbg>",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 854,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "cropBlgM",
    nmOI: "belowground crop components",
    tOutOI: "kou_cropBlgM",
    descOI: "<cropBlg>",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 855,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "gbfrMA",
    nmOI: "crop grains -buds-fruit",
    tOutOI: "kou_gbfrMA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 856,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "stlkMA",
    nmOI: "crop stalks",
    tOutOI: "kou_stlkMA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 857,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclGold",
  },
  {
    progNmOI: "leafMA",
    nmOI: "crop leaves",
    tOutOI: "kou_leafMA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 858,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "cortMA",
    nmOI: "crop coarse roots",
    tOutOI: "kou_cortMA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 859,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "firtMA",
    nmOI: "crop fine roots",
    tOutOI: "kou_firtMA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koM",
    suffixOI: "koNA",
    prefixDOI: "koM",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 860,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 50,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "stlkMortA",
    nmOI: "crop stalks that died due to  mortality ",
    tOutOI: "kou_stlkMortA",
    descOI: "",
    unitNmPloOI: "%",
    unitNmSpaOI: "%",
    prefixOI: "koPerc",
    suffixOI: "koNA",
    prefixDOI: "koPerc",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 861,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 60,
    colorOI: "kclGray",
  },
  {
    progNmOI: "soilCoverA",
    nmOI: "Fraction of soil covered by plants",
    tOutOI: "kou_soilCoverA",
    descOI: "Effects evaporation and maximum topsoil moisture",
    unitNmPloOI: "%",
    unitNmSpaOI: "%",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Plnt",
    sortNOI: 862,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 68,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "defnitIrrigA",
    nmOI: "Irrigation applied regardless of soil moisture",
    tOutOI: "kou_defnitIrrigA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAgIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 863,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "conditIrrigA",
    nmOI: "max TSMD guaranteed by conditonal irrigation",
    tOutOI: "kou_conditIrrigA",
    descOI: "maximum topsoil moisture deficit that is guaranteed by irrigation",
    unitNmPloOI: "%",
    unitNmSpaOI: "%",
    prefixOI: "koPerc",
    suffixOI: "koAg",
    prefixDOI: "koPerc",
    suffixDOI: "koAgIn",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 864,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "conditIrrigMMA",
    nmOI: "Irrigation applied due to conditions",
    tOutOI: "kou_conditIrrigMMA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 865,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "rainAndIrrigA",
    nmOI: "Combined rainfall and irrigation",
    tOutOI: "kou_rainAndIrrigA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 866,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclAqua",
  },
  {
    progNmOI: "evapA",
    nmOI: "Evaporation from soil",
    tOutOI: "kou_evapA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 867,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "netWaterInputA",
    nmOI: "Net water input (rainfall + irrigation - evaporation )",
    tOutOI: "kou_netWaterInputA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 868,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "maxTSMDA",
    nmOI: "Max TSMD",
    tOutOI: "kou_maxTSMDA",
    descOI: "Maximum topsoil moisture deficit",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 869,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "TSMDA",
    nmOI: "TSMD",
    tOutOI: "kou_TSMDA",
    descOI:
      "Topsoil moisture deficit (TSMD), the lack of non -draining soil water available to plants",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 870,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Avgs",
    colWidthOI: 75,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "excessWaterA",
    nmOI: "Excess water (= drainage + runoff )",
    tOutOI: "kou_excessWaterA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 871,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "drainA",
    nmOI: "Drainage from topsoil to deeper soil",
    tOutOI: "kou_drainA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 872,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "runoffA",
    nmOI: "Runoff from topsoil to offsite",
    tOutOI: "kou_runoffA",
    descOI: "",
    unitNmPloOI: "mm",
    unitNmSpaOI: "mm",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 873,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 65,
    colorOI: "kclRed",
  },
  {
    progNmOI: "rateTempWaterA",
    nmOI: "Rate multiplier due to temperature and agricultural topsoil moisture",
    tOutOI: "kou_rateTempWaterA",
    descOI: "Affects rate of debris breakdown and soil decomposition",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koAg",
    prefixDOI: "koNA",
    suffixDOI: "koAg",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Water",
    sortNOI: 874,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 1,
    tScalingOI: "Avgs",
    colWidthOI: 86,
    colorOI: "kclGold",
  },
  {
    progNmOI: "prodRateGbfrMA",
    nmOI: "crop grains -buds-fruit mass",
    tOutOI: "kou_prodRateGbfrMA",
    descOI: "",
    unitNmPloOI: "dmt/ha/y",
    unitNmSpaOI: "dmt/y",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 875,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "prodRateStlkMA",
    nmOI: "crop stalk mass",
    tOutOI: "kou_prodRateStlkMA",
    descOI: "",
    unitNmPloOI: "dmt/ha/y",
    unitNmSpaOI: "dmt/y",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 876,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "prodRateLeafMA",
    nmOI: "crop leaf mass",
    tOutOI: "kou_prodRateLeafMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 877,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "prodRateCortMA",
    nmOI: "crop coarse roots mass",
    tOutOI: "kou_prodRateCortMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 878,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "prodRateFirtMA",
    nmOI: "crop fine roots mass",
    tOutOI: "kou_prodRateFirtMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 879,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "prodRateCropMA",
    nmOI: "crop mass",
    tOutOI: "kou_prodRateCropMA",
    descOI: "mass of <crop>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProd",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 880,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "prodRateAbgAMA",
    nmOI: "aboveground crop mass",
    tOutOI: "kou_prodRateAbgAMA",
    descOI: "mass of <cropAbg>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateProd",
    suffixOI: "koNA",
    prefixDOI: "koRateProdL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 881,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "turnRateGbfrMA",
    nmOI: "crop grains -buds-fruit mass",
    tOutOI: "kou_turnRateGbfrMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 882,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "turnRateStlkMA",
    nmOI: "crop stalk mass",
    tOutOI: "kou_turnRateStlkMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 883,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "turnRateLeafMA",
    nmOI: "crop leaf mass",
    tOutOI: "kou_turnRateLeafMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 884,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "turnRateCortMA",
    nmOI: "crop coarse roots mass",
    tOutOI: "kou_turnRateCortMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 885,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "turnRateFirtMA",
    nmOI: "crop fine roots mass",
    tOutOI: "kou_turnRateFirtMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 886,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "turnRateCropMA",
    nmOI: "crop mass",
    tOutOI: "kou_turnRateCropMA",
    descOI: "mass of <crop>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 887,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "turnRateAbgAMA",
    nmOI: "aboveground crop mass",
    tOutOI: "kou_turnRateAbgAMA",
    descOI: "mass of <cropAbg>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateTurn",
    suffixOI: "koNA",
    prefixDOI: "koRateTurnL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 888,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "yielRateGbfrMA",
    nmOI: "crop grains -buds-fruit mass",
    tOutOI: "kou_yielRateGbfrMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 889,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclBrown",
  },
  {
    progNmOI: "yielRateStlkMA",
    nmOI: "crop stalk mass",
    tOutOI: "kou_yielRateStlkMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 890,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGold",
  },
  {
    progNmOI: "yielRateLeafMA",
    nmOI: "crop leaf mass",
    tOutOI: "kou_yielRateLeafMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 891,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "yielRateCortMA",
    nmOI: "crop coarse roots mass",
    tOutOI: "kou_yielRateCortMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 892,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "yielRateFirtMA",
    nmOI: "crop fine roots mass",
    tOutOI: "kou_yielRateFirtMA",
    descOI: "",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 893,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "yielRateCropMA",
    nmOI: "crop mass",
    tOutOI: "kou_yielRateCropMA",
    descOI: "mass of <crop>",
    unitNmPloOI: "dmt/ha per yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 894,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "yielRateAbgAMA",
    nmOI: "aboveground crop mass",
    tOutOI: "kou_yielRateAbgAMA",
    descOI: "mass of <cropAbg>",
    unitNmPloOI: "dmt/ha per /yr",
    unitNmSpaOI: "dmt/yr",
    prefixOI: "koRateYield",
    suffixOI: "koNA",
    prefixDOI: "koRateYieldL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Rate",
    sortNOI: 895,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 3,
    tScalingOI: "Adds",
    colWidthOI: 70,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCropTot",
    nmOI: "Agricultural harvest (all types and grades, total )",
    tOutOI: "kou_flowCropTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 896,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowProdTot",
    nmOI: "Agricultural produce (all crop grades, total )",
    tOutOI: "kou_flowProdTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 897,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowHssiTot",
    nmOI: "Hay, straw, and silage (all crop grades, total )",
    tOutOI: "kou_flowHssiTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 898,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowFualTot",
    nmOI: "Biofuel (all crop grades, total )",
    tOutOI: "kou_flowFualTot",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 899,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowProd1",
    nmOI: "1",
    tOutOI: "kou_flowProd1",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koProd",
    suffixOI: "koNA",
    prefixDOI: "koProd",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 900,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowHssi1",
    nmOI: "1",
    tOutOI: "kou_flowHssi1",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koHssi",
    suffixOI: "koNA",
    prefixDOI: "koHssi",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 901,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclLtBlue",
  },
  {
    progNmOI: "flowFual1",
    nmOI: "1",
    tOutOI: "kou_flowFual1",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFual",
    suffixOI: "koNA",
    prefixDOI: "koFual",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 902,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowProd2",
    nmOI: "2",
    tOutOI: "kou_flowProd2",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koProd",
    suffixOI: "koNA",
    prefixDOI: "koProd",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 903,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclOrange",
  },
  {
    progNmOI: "flowHssi2",
    nmOI: "2",
    tOutOI: "kou_flowHssi2",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koHssi",
    suffixOI: "koNA",
    prefixDOI: "koHssi",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 904,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowFual2",
    nmOI: "2",
    tOutOI: "kou_flowFual2",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFual",
    suffixOI: "koNA",
    prefixDOI: "koFual",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 905,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowProd3",
    nmOI: "3",
    tOutOI: "kou_flowProd3",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koProd",
    suffixOI: "koNA",
    prefixDOI: "koProd",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 906,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclLemon",
  },
  {
    progNmOI: "flowHssi3",
    nmOI: "3",
    tOutOI: "kou_flowHssi3",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koHssi",
    suffixOI: "koNA",
    prefixDOI: "koHssi",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 907,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowFual3",
    nmOI: "3",
    tOutOI: "kou_flowFual3",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFual",
    suffixOI: "koNA",
    prefixDOI: "koFual",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 908,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclGray",
  },
  {
    progNmOI: "flowProd4",
    nmOI: "4",
    tOutOI: "kou_flowProd4",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koProd",
    suffixOI: "koNA",
    prefixDOI: "koProd",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 909,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowHssi4",
    nmOI: "4",
    tOutOI: "kou_flowHssi4",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koHssi",
    suffixOI: "koNA",
    prefixDOI: "koHssi",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 910,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "sDeadCM",
    nmOI: "standing dead",
    tOutOI: "kou_sDeadCM",
    descOI: "standing dead",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 910,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowFual4",
    nmOI: "4",
    tOutOI: "kou_flowFual4",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koFual",
    suffixOI: "koNA",
    prefixDOI: "koFual",
    suffixDOI: "koTransported",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Crop",
    sortNOI: 911,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclMauve",
  },
  {
    progNmOI: "sDeadAbgCM",
    nmOI: "aboveground standing dead",
    tOutOI: "kou_sDeadAbgCM",
    descOI: "aboveground standing dead",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 911,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "sDeadBlgCM",
    nmOI: "belowground standing dead",
    tOutOI: "kou_sDeadBlgCM",
    descOI: "belowground standing dead",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 912,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowMCropFoddGrazA",
    nmOI: "crops to fodder",
    tOutOI: "kou_flowMCropFoddGrazA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koCMFrom",
    suffixOI: "koDueGraz",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueGraz",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 912,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclLime",
  },
  {
    progNmOI: "flowMDebrFoddGrazA",
    nmOI: "debris to fodder",
    tOutOI: "kou_flowMDebrFoddGrazA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koCMFrom",
    suffixOI: "koDueGraz",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueGraz",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 913,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 55,
    colorOI: "kclLime",
  },
  {
    progNmOI: "sLittCM",
    nmOI: "standing dead litter",
    tOutOI: "kou_sLittCM",
    descOI: "standing dead litter",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 913,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "sDdwdCM",
    nmOI: "standing dead deadwood",
    tOutOI: "kou_sDdwdCM",
    descOI: "standing dead deadwood",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 914,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowMFoddAnprGrazA",
    nmOI: "crops and debris to animal products",
    tOutOI: "kou_flowMFoddAnprGrazA",
    descOI: "",
    unitNmPloOI: "dmt/ha",
    unitNmSpaOI: "dmt",
    prefixOI: "koCMFrom",
    suffixOI: "koDueGraz",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueGraz",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Debr",
    sortNOI: 914,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 80,
    colorOI: "kclGray",
  },
  {
    progNmOI: "sDeadCMF",
    nmOI: "standing dead",
    tOutOI: "kou_sDeadCMF",
    descOI: "standing dead",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 915,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "error1",
    nmOI: "Error (Report a bug if you see this !)",
    tOutOI: "kou_error1",
    descOI: "Error (Report a bug if you see this !)",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_FrAg",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 915,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 65,
    colorOI: "kclGray",
  },
  {
    progNmOI: "abgsDeadCMF",
    nmOI: "aboveground standing dead",
    tOutOI: "kou_abgsDeadCMF",
    descOI: "aboveground standing dead",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 916,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "blgsDeadCMF",
    nmOI: "belowground standing dead",
    tOutOI: "kou_blgsDeadCMF",
    descOI: "belowground standing dead",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 917,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "sLittCMF",
    nmOI: "standing dead litter",
    tOutOI: "kou_sLittCMF",
    descOI: "standing dead litter",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 918,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPink",
  },
  {
    progNmOI: "sDdwdCMF",
    nmOI: "standing dead deadwood",
    tOutOI: "kou_sDdwdCMF",
    descOI: "standing dead deadwood",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 919,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "sDStemCMF",
    nmOI: "standing dead stem",
    tOutOI: "kou_sDStemCMF",
    descOI: "standing dead stem",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 920,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "sDBranCMF",
    nmOI: "standing dead branch",
    tOutOI: "kou_sDBranCMF",
    descOI: "standing dead branch",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 921,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "sDBlitCMF",
    nmOI: "standing dead bark",
    tOutOI: "kou_sDBlitCMF",
    descOI: "standing dead bark",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 922,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "sDLlitCMF",
    nmOI: "standing dead leaf",
    tOutOI: "kou_sDLlitCMF",
    descOI: "standing dead leaf",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 923,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "sDCodrCMF",
    nmOI: "standing dead coarse roots",
    tOutOI: "kou_sDCodrCMF",
    descOI: "standing dead coarse roots",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 924,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "sDFidrCMF",
    nmOI: "standing dead fine roots",
    tOutOI: "kou_sDFidrCMF",
    descOI: "standing dead fine roots",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOfFr",
    suffixOI: "koNA",
    prefixDOI: "koCMOfFr",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 925,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 74,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMsDdwdDebrSensF",
    nmOI: "standing dead stem to debris,",
    tOutOI: "kou_flowCMsDdwdDebrSensF",
    descOI: "standing dead stem to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 928,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMsChwdDebrSensF",
    nmOI: "standing dead branch to debris,",
    tOutOI: "kou_flowCMsChwdDebrSensF",
    descOI: "standing dead branch to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 929,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMsBlitDebrSensF",
    nmOI: "standing dead bark to debris,",
    tOutOI: "kou_flowCMsBlitDebrSensF",
    descOI: "standing dead bark to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 930,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMsLlitDebrSensF",
    nmOI: "standing dead leaf to debris,",
    tOutOI: "kou_flowCMsLlitDebrSensF",
    descOI: "standing dead leaf to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 931,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMsCodrDebrSensF",
    nmOI: "standing dead coarse roots to debris,",
    tOutOI: "kou_flowCMsCodrDebrSensF",
    descOI: "standing coarse dead roots to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 932,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMsFidrDebrSensF",
    nmOI: "standing dead fine roots to debris,",
    tOutOI: "kou_flowCMsFidrDebrSensF",
    descOI: "standing dead fine roots to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 933,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMsDdwdAtmsSensF",
    nmOI: "standing dead stem to atmosphere,",
    tOutOI: "kou_flowCMsDdwdAtmsSensF",
    descOI: "standing dead stem to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 934,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMsChwdAtmsSensF",
    nmOI: "standing dead branch to atmosphere,",
    tOutOI: "kou_flowCMsChwdAtmsSensF",
    descOI: "standing dead branch to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 935,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMsBlitAtmsSensF",
    nmOI: "standing dead bark to atmosphere,",
    tOutOI: "kou_flowCMsBlitAtmsSensF",
    descOI: "standing dead bark to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 936,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMsLlitAtmsSensF",
    nmOI: "standing dead leaf to atmosphere,",
    tOutOI: "kou_flowCMsLlitAtmsSensF",
    descOI: "standing dead leaf to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 937,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMsCodrAtmsSensF",
    nmOI: "standing dead coarse roots to atmosphere,",
    tOutOI: "kou_flowCMsCodrAtmsSensF",
    descOI: "standing dead coarse roots to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 938,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlue",
  },
  {
    progNmOI: "flowCMsFidrAtmsSensF",
    nmOI: "standing dead fine roots to atmosphere,",
    tOutOI: "kou_flowCMsFidrAtmsSensF",
    descOI: "standing fine dead roots to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueSens",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueSens",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 939,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMStemsDdwdFireF",
    nmOI: "stem to standing dead stem,",
    tOutOI: "kou_flowCMStemsDdwdFireF",
    descOI: "stem to standing dead stem,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 940,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowCMBransChwdFireF",
    nmOI: "branch to standing dead branch,",
    tOutOI: "kou_flowCMBransChwdFireF",
    descOI: "branch to standing dead branch,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 941,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowCMBarksBlitFireF",
    nmOI: "bark to standing dead bark,",
    tOutOI: "kou_flowCMBarksBlitFireF",
    descOI: "bark to standing dead bark,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 942,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMLeafsLlitFireF",
    nmOI: "leaf to standing dead leaf,",
    tOutOI: "kou_flowCMLeafsLlitFireF",
    descOI: "leaf to standing dead leaf,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 943,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowCMCortsCodrFireF",
    nmOI: "coarse root to standing dead coarse root,",
    tOutOI: "kou_flowCMCortsCodrFireF",
    descOI: "coarse root to standing dead coarse root,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 944,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowCMFirtsFidrFireF",
    nmOI: "fine root to standing dead fine root,",
    tOutOI: "kou_flowCMFirtsFidrFireF",
    descOI: "fine root to standing dead fine root,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 945,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMsDdwdAtmsFireF",
    nmOI: "standing dead stem to atmosphere,",
    tOutOI: "kou_flowCMsDdwdAtmsFireF",
    descOI: "standing dead stem to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCEmitFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 946,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMsChwdAtmsFireF",
    nmOI: "standing dead branch to atmosphere,",
    tOutOI: "kou_flowCMsChwdAtmsFireF",
    descOI: "standing dead branch to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCEmitFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 947,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMsBlitAtmsFireF",
    nmOI: "standing dead bark to atmosphere,",
    tOutOI: "kou_flowCMsBlitAtmsFireF",
    descOI: "standing dead bark to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCEmitFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 948,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMsLlitAtmsFireF",
    nmOI: "standing dead leaf litter to atmosphere,",
    tOutOI: "kou_flowCMsLlitAtmsFireF",
    descOI: "standing dead leaf litter to atmosphere,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCEmitFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 949,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMsDdwdDebrFireF",
    nmOI: "standing dead stem to debris,",
    tOutOI: "kou_flowCMsDdwdDebrFireF",
    descOI: "standing dead stem to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 952,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPeach",
  },
  {
    progNmOI: "flowCMsChwdDebrFireF",
    nmOI: "standing dead branch to debris,",
    tOutOI: "kou_flowCMsChwdDebrFireF",
    descOI: "standing dead branch to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 953,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMsBlitDebrFireF",
    nmOI: "standing dead bark to debris,",
    tOutOI: "kou_flowCMsBlitDebrFireF",
    descOI: "standing dead bark to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 954,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMsLlitDebrFireF",
    nmOI: "standing dead leaf to debris,",
    tOutOI: "kou_flowCMsLlitDebrFireF",
    descOI: "standing dead leaf to debris,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueFire",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueFire",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 955,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMStemsDdwdThinF",
    nmOI: "stem to standing dead stem,",
    tOutOI: "kou_flowCMStemsDdwdThinF",
    descOI: "stem to standing dead stem,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 958,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclRBrown",
  },
  {
    progNmOI: "flowCMBransChwdThinF",
    nmOI: "branch to standing dead branch,",
    tOutOI: "kou_flowCMBransChwdThinF",
    descOI: "branch to standing dead branch,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 959,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowCMBarksBlitThinF",
    nmOI: "bark to standing dead bark,",
    tOutOI: "kou_flowCMBarksBlitThinF",
    descOI: "bark to standing dead bark,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 960,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMLeafsLlitThinF",
    nmOI: "leaf to standing dead leaf,",
    tOutOI: "kou_flowCMLeafsLlitThinF",
    descOI: "leaf to standing dead leaf,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 961,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclTeal",
  },
  {
    progNmOI: "flowCMCortsCodrThinF",
    nmOI: "coarse root to standing dead coarse root,",
    tOutOI: "kou_flowCMCortsCodrThinF",
    descOI: "coarse root to standing dead coarse root,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 962,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowCMFirtsFidrThinF",
    nmOI: "fine root to standing dead fine root,",
    tOutOI: "kou_flowCMFirtsFidrThinF",
    descOI: "fine root to standing dead fine root,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 963,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 64,
    colorOI: "kclRed",
  },
  {
    progNmOI: "OlsonTotalCoarseFuel",
    nmOI: "Olson total coarse fuel",
    tOutOI: "kou_OlsonTotalCoarseFuel",
    descOI: "Olson total coarse fuel",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 964,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowOlTotCoFuelAtmsFireF",
    nmOI: "Olson total coarse fuel to atmosphere, due to fire",
    tOutOI: "kou_flowOlTotCoFuelAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 964,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "flowOlCoHyFuelAtmsFireF",
    nmOI: "Olson coarse heavy fuel to atmosphere, due to fire",
    tOutOI: "kou_flowOlCoHyFuelAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 965,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "OlsonCoarseHeavyFuel",
    nmOI: "Olson coarse heavy fuel",
    tOutOI: "kou_OlsonCoarseHeavyFuel",
    descOI: "Olson coarse heavy fuel",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 965,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "OlsonCoarseLightFuel",
    nmOI: "Olson coarse light fuel",
    tOutOI: "kou_OlsonCoarseLightFuel",
    descOI: "Olson coarse light fuel",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 966,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowOlCoLiFuelAtmsFireF",
    nmOI: "Olson coarse light fuel to atmosphere, due to fire",
    tOutOI: "kou_flowOlCoLiFuelAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 966,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowOlFiWoFuelAtmsFireF",
    nmOI: "Olson fine woody fuel to atmosphere, due to fire",
    tOutOI: "kou_flowOlFiWoFuelAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 967,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "OlsonFineWoodyFuel",
    nmOI: "Olson fine woody fuel",
    tOutOI: "kou_OlsonFineWoodyFuel",
    descOI: "Olson fine woody fuel",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 967,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclRed",
  },
  {
    progNmOI: "OlsonFineGrassFuel",
    nmOI: "Olson fine grass fuel",
    tOutOI: "kou_OlsonFineGrassFuel",
    descOI: "Olson fine grass fuel",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 968,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowOlFiGrFuelAtmsFireF",
    nmOI: "Olson fine grass fuel to atmosphere, due to fire",
    tOutOI: "kou_flowOlFiGrFuelAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Olson",
    sortNOI: 968,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMsDdwdFuelThinF",
    nmOI: "standing dead stem to biofuel,",
    tOutOI: "kou_flowCMsDdwdFuelThinF",
    descOI: "standing dead stem to biofuel,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 969,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMsChwdFuelThinF",
    nmOI: "standing dead branch to biofuel,",
    tOutOI: "kou_flowCMsChwdFuelThinF",
    descOI: "standing dead branch to biofuel,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 970,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMsBlitFuelThinF",
    nmOI: "standing dead bark to biofuel,",
    tOutOI: "kou_flowCMsBlitFuelThinF",
    descOI: "standing dead bark to biofuel,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 971,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "flowCMsLlitFuelThinF",
    nmOI: "standing dead leaf to biofuel,",
    tOutOI: "kou_flowCMsLlitFuelThinF",
    descOI: "standing dead leaf to biofuel,",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMFrom",
    suffixOI: "koDueThin",
    prefixDOI: "koCMFrom",
    suffixDOI: "koDueThin",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_SDwd",
    sortNOI: 972,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 68,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "leakCsDeadF",
    nmOI: "C leak : forest standing dead",
    tOutOI: "kou_leakCsDeadF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Leak",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 977,
    tOutputOI: "ko_leak",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 6,
    tScalingOI: "Adds",
    colWidthOI: 75,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMdDdwdAtmsFireF",
    nmOI: "decomposable deadwood to atmosphere, due to fire",
    tOutOI: "kou_flowCMdDdwdAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 978,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMdChwdAtmsFireF",
    nmOI: "decomposable chopped wood to atmosphere, due to fire",
    tOutOI: "kou_flowCMdChwdAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 979,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMdBlitAtmsFireF",
    nmOI: "decomposable bark litter to atmosphere, due to fire",
    tOutOI: "kou_flowCMdBlitAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 980,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMdLlitAtmsFireF",
    nmOI: "decomposable leaf litter to atmosphere, due to fire",
    tOutOI: "kou_flowCMdLlitAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 981,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMdCodrAtmsFireF",
    nmOI: "decomposable coarse dead root to atmosphere, due to fire",
    tOutOI: "kou_flowCMdCodrAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 982,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMdFidrAtmsFireF",
    nmOI: "decomposable fine dead root to atmosphere, due to fire",
    tOutOI: "kou_flowCMdFidrAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 983,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "flowCMrDdwdAtmsFireF",
    nmOI: "resistant deadwood to atmosphere, due to fire",
    tOutOI: "kou_flowCMrDdwdAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 984,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclRed",
  },
  {
    progNmOI: "flowCMrChwdAtmsFireF",
    nmOI: "resistant chopped wood to atmosphere, due to fire",
    tOutOI: "kou_flowCMrChwdAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 985,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclPink",
  },
  {
    progNmOI: "flowCMrBlitAtmsFireF",
    nmOI: "resistant bark litter to atmosphere, due to fire",
    tOutOI: "kou_flowCMrBlitAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 986,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclBlack",
  },
  {
    progNmOI: "flowCMrLlitAtmsFireF",
    nmOI: "resistant leaf litter to atmosphere, due to fire",
    tOutOI: "kou_flowCMrLlitAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 987,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclWhite",
  },
  {
    progNmOI: "flowCMrCodrAtmsFireF",
    nmOI: "resistant coarse dead root to atmosphere, due to fire",
    tOutOI: "kou_flowCMrCodrAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 988,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclGold",
  },
  {
    progNmOI: "flowCMrFidrAtmsFireF",
    nmOI: "resistant fine dead root to atmosphere, due to fire",
    tOutOI: "kou_flowCMrFidrAtmsFireF",
    descOI: "",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCEmitDue",
    suffixOI: "koNA",
    prefixDOI: "koCEmitDueL",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_C",
    tOutCat1_OI: "kv_Fr",
    tOutCat2_OI: "kv_Emit",
    sortNOI: 989,
    tOutputOI: "ko_baseFlow",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 60,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "noForProdCS",
    nmOI: "without forest products",
    tOutOI: "kou_noForProdCS",
    descOI: "without forest products",
    unitNmPloOI: "tCO2-e/ha",
    unitNmSpaOI: "tCO2-e",
    prefixOI: "koPStock",
    suffixOI: "koNA",
    prefixDOI: "koPStock",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_CProjects",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 990,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPurple",
  },
  {
    progNmOI: "forProdCS",
    nmOI: "with forest products",
    tOutOI: "kou_forProdCS",
    descOI: "with forest products",
    unitNmPloOI: "tCO2-e/ha",
    unitNmSpaOI: "tCO2-e",
    prefixOI: "koPStock",
    suffixOI: "koNA",
    prefixDOI: "koPStock",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_CProjects",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 991,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclGold",
  },
  {
    progNmOI: "treeDebrProdCS",
    nmOI: "(trees, debris, standing dead, and forest products )",
    tOutOI: "kou_treeDebrProdCS",
    descOI: "(trees, debris, standing dead, and forest products )",
    unitNmPloOI: "tCO2-e/ha",
    unitNmSpaOI: "tCO2-e",
    prefixOI: "koCStocks",
    suffixOI: "koNA",
    prefixDOI: "koCStocks",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_CProjects",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 992,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclNavy",
  },
  {
    progNmOI: "treeDebrCS",
    nmOI: "(trees, debris, standing dead )",
    tOutOI: "kou_treeDebrCS",
    descOI: "(trees, debris, standing dead )",
    unitNmPloOI: "tCO2-e/ha",
    unitNmSpaOI: "tCO2-e",
    prefixOI: "koCStocks",
    suffixOI: "koNA",
    prefixDOI: "koCStocks",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_CProjects",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 993,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclKhaki",
  },
  {
    progNmOI: "emissionFromFire",
    nmOI: "(N2O and CH4 )",
    tOutOI: "kou_emissionFromFire",
    descOI: "(N2O and CH4 )",
    unitNmPloOI: "tCO2-e/ha",
    unitNmSpaOI: "tCO2-e",
    prefixOI: "koEFrom",
    suffixOI: "koNA",
    prefixDOI: "koEFrom",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_CProjects",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 994,
    tOutputOI: "ko_flowTotal",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclRed",
  },
  {
    progNmOI: "cpdebrCMF",
    nmOI: "forest debris",
    tOutOI: "kou_cpdebrCMF",
    descOI: "forest debris",
    unitNmPloOI: "tC/ha",
    unitNmSpaOI: "tC",
    prefixOI: "koCMOf",
    suffixOI: "koNA",
    prefixDOI: "koCMOf",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_CProjects",
    tOutCat2_OI: "kv_NA2",
    sortNOI: 995,
    tOutputOI: "ko_total",
    tEconOI: "kecon0",
    hasInitValueOI: true,
    canHaveGainLoss: true,
    nDecPlacesOI: 2,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclPink",
  },
  {
    progNmOI: "decRateMultiRPM",
    nmOI: "Decomposition rate multiplier RPM",
    tOutOI: "kou_decRateMultiRPM",
    descOI: "Decomposition rate multiplier RPM",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 996,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 4,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclGreen",
  },
  {
    progNmOI: "decRateMultiHUM",
    nmOI: "Decomposition rate multiplier HUM",
    tOutOI: "kou_decRateMultiHUM",
    descOI: "Decomposition rate multiplier HUM",
    unitNmPloOI: "",
    unitNmSpaOI: "",
    prefixOI: "koNA",
    suffixOI: "koNA",
    prefixDOI: "koNA",
    suffixDOI: "koNA",
    tOutCat0_OI: "kv_Indept",
    tOutCat1_OI: "kv_Ag",
    tOutCat2_OI: "kv_Soil",
    sortNOI: 997,
    tOutputOI: "ko_0",
    tEconOI: "kecon0",
    hasInitValueOI: false,
    canHaveGainLoss: false,
    nDecPlacesOI: 4,
    tScalingOI: "Adds",
    colWidthOI: 56,
    colorOI: "kclAqua",
  },
];

const outputNameMap = {
  koNA: "",
  koRateProd: "Production rate of",
  koRateProdL: "Rate of production of new plant material >",
  koRateTurn: "Turnover rate of",
  koRateTurnL: "Rate of turnover (shedding) of plant material >",
  koRateYield: "Yield rate of",
  koRateYieldL: "Net rate of production and turnover of plant material >",
  koIn: "(is an input)",
  koM: "Mass of",
  koExclBB: "(excluding branches and bark)",
  koPerc: "Percentage of",
  koFr: " forest",
  koAg: ", agricultural",
  koFrIn: ", forest (is an input)",
  koAgIn: ", agricultural (is an input)",
  koCMOf: "C mass of",
  koCMOfFr: "C mass of forest",
  koCMOfAg: "C mass of agricultural",
  koNMOf: "N mass of",
  koNMOfFr: "N mass of forest",
  koNMOfAg: "N mass of agricultural",
  koCMDispEner: "C mass of fossil fuel displaced by bioenergy from",
  koCMDispUse: "C mass of fossil fuel displaced due to use of",
  koCMDispBy: "C mass of fossil fuel displaced by",
  koCMSeq: "C mass sequestered due to",
  koCMSeqL: "C mass removed from atmosphere due to",
  koCMEmitNet: "Net C mass emitted",
  koCMEmitNetL: "Net C mass emitted to atmosphere",
  koCMSeqNet: "Net C mass sequestered",
  koCMSeqNetL: "Net C mass removed from atmosphere",
  koNMSeq: "N mass sequestered due to",
  koNMSeqL: "N mass removed from atmosphere due to",
  koNMEmitNet: "Net N mass emitted",
  koNMEmitNetL: "Net N mass emitted to atmosphere",
  koNMSeqNet: "Net N mass sequestered",
  koNMSeqNetL: "Net N mass removed from atmosphere",
  koCEmitDue: "C mass emitted due to",
  koCEmitDueL: "C mass emitted to atmosphere due to",
  koCEmit: "C mass emitted",
  koCEmitL: "C mass emitted to atmosphere",
  koNEmitDue: "N mass emitted due to",
  koNEmitDueL: "N mass emitted to atmosphere due to",
  koNInN2EmitDue: "N mass in N2 emitted due to",
  koNInN2EmitDueL: "N mass in N2 emitted to atmosphere due to",
  koNInN2OEmitDue: "N mass in N20 emitted due to",
  koNInN2OEmitDueL: "N mass in N20 emitted to atmosphere due to",
  koCMFrom: "C mass moved from",
  koCMFromFr: "C mass moved from forest",
  koCMFromAg: "C mass moved from agricultural",
  koNMFrom: "N mass moved from",
  koNMFromFr: "N mass moved from forest",
  koNMFromAg: "N mass moved from agricultural",
  koCN: "C:N ratio of",
  koCNAg: "C:N ratio of agricultural",
  koCNFr: "C:N ratio of forest",
  koCNMin: "Minimum possible C:N ratio of",
  koCNMax: "Maximum possible C:N ratio of",
  koPMInSoilPlow: "plant matter in the soil due to plowing",
  koPMInSoil: "plant matter in the soil",
  koXBioInSoil: "decomposing biomass in the soil",
  koBioInSoil: "biomass (fast decomposing + slow decomposing) in the soil",
  koHUMInsoil: "humified organic matter in the soil",
  koDueBkdn: "due to breakdown",
  koDueTurn: "due to turnover",
  koDueFire: "due to fire",
  koDueDcmp: "due to decomposition",
  koDueHumf: "due to humification",
  koDueEncp: "due to encapsulation",
  koDueHarv: "due to harvest",
  koDueThin: "due to thinning",
  koBalAgToFr: "rebalanced from agricultural to forest",
  koBalAgToFrL:
    "moved from agricultural to forest due to those mineral pools effectively being one common pool",
  koNMAmmIn: "N mass in ammonium in",
  koNMNtrIn: "N mass in nitrate in",
  koSoilShallowL: "soil, available to the organic soil",
  koSoilDeepL: "soil, available to the plants but not to the organic soil",
  koNRatio: "N2/N20 N mass ratio in denitrification emissions,",
  koNRatioL:
    "Ratio of N mass in N2 to N mass in N20 in N mass emitted by minerals due to denitrification,",
  koDEachStep: ", during each output step",
  koDFromSt: ", cumulatively from start of simulation",
  koToDate: ", cumulative to date",
  koTransported: ", transported to the mill",
  koWood: "Stems and branches, log grade",
  koBark: "Bark, log grade",
  koFuel: "Biofuel, log grade",
  koProd: "Produce, crop grade",
  koHssi: "Hay, straw, and silage, crop grade",
  koFual: "Biofuel, crop grade",
  koCIncmFrom: "Carbon income from",
  koCIncmFromChngsIn: "Income or expense due to changes in the carbon mass of",
  koNValOf: "Value of the nitrogen",
  koInNitrF: ", in forest nitrification",
  koInDeniF: ", in forest denitrification",
  koInNitrA: ", in agricultural nitrification",
  koInDeniA: ", in agricultural denitrification",
  koNValProduct: "Combined value of the three nitrogen functions:",
  koNMNitrDueTo: "Nitrified N mass due to",
  koNMNitrDueToL:
    "N mass moved from shallow ammonium to shallow nitrate by nitrification due to",
  koEndOfStep: "(at end of step)",
  koDueDieO: "due to die-off",
  koDueGrth: "due to growth",
  koGrthInc: "C mass increment of",
  koDueGraz: "due to grazing",
  koCEmitPlow: "C mass emitted due to ploughing, from",
  koDueSens: "due to senescence",
  koCEmitFrom: "C mass emitted from",
  koPStock: "Project stock",
  koCStocks: "Carbon stocks",
  koEFrom: "Emissions from fire",
};

const inputNameMap = {
  kiNA: { index: 0, description: "" },
  kiEvNotTS: { index: 1, description: "Use events (not time series) for" },
  kiPloConfig: { index: 2, description: "in plot configuration" },
  kiIrrigMin: {
    index: 3,
    description: "irrigation % (guaranteed min % of topsoil water capacity)",
  },
  kiIrrigDef: {
    index: 4,
    description: "irrigation (applied regardless of conditions)",
  },
  kiTreeGrMult: { index: 5, description: "Tree growth multiplier for" },
  kiCropGrMult: { index: 6, description: "Crop growth multiplier for" },
  kiTreAgeMaxGr: {
    index: 7,
    description: "Tree age of max growth in tree yield formula (G)",
  },
  kiNonEndSpecMult: { index: 8, description: "Non-endemic species multiplier" },
  kiRelAllocs: { index: 9, description: "Relative allocations to" },
  kiCPerc: { index: 10, description: "Carbon % of (dry)" },
  kiTurnPerc: { index: 11, description: "Turnover % of" },
  kiLossToStemMort: {
    index: 12,
    description: "loss to stem loss) due to tree mortality",
  },
  kiFormulaAgeTrees: {
    index: 13,
    description: "in formula for age of dying trees",
  },
  kiResistantPerc: { index: 14, description: "Resistant % of" },
  kiBkdnPerc: { index: 15, description: "Breakdown % of" },
  kiAtmosPerc: { index: 16, description: "Atmospheric % of" },
  kiBkdnSensTemp: {
    index: 17,
    description: "Breakdown sensitive to temperature and moisture)",
  },
  kiSensBkdnTo: { index: 18, description: "Sensitivity of breakdown to" },
  kiDecompPerc: { index: 19, description: "Decomposition % of" },
  kiWhileInUse: { index: 20, description: "(while in use)" },
  kiBurntBioenergy: { index: 21, description: "burnt for bioenergy" },
  kiToLandfill: { index: 22, description: "to landfill" },
  kiInLandfill: { index: 23, description: "in landfill" },
  kiMinCN: { index: 24, description: "Minimum C:N ratio of" },
  kiMaxCN: { index: 25, description: "Maximum C:N ratio of" },
  kiAtGermination: { index: 26, description: "at germination" },
  kiDuringMaturity: { index: 27, description: "during maturity" },
  kiStJointTiller: { index: 28, description: "at start of jointing/tillering" },
  kiStJointBoot: { index: 29, description: "at start of flowering/booting" },
  kiDaysEarlyGRForCN: {
    index: 30,
    description: "Days of early growth, for C:N ratio of",
  },
  kiEffcEnergy: { index: 31, description: "Efficiency of using energy from" },
  kiEffcEnergyFossil: {
    index: 32,
    description: "Efficiency of using energy from fossil fuel displaced by",
  },
  kiCEmissionRate: { index: 33, description: "C-emission rate of" },
  kiCEmissionRateDisp: {
    index: 34,
    description: "C-emission rate of fossil fuel displaced by",
  },
  kiCEmissionRateMake: {
    index: 35,
    description: "C-emission rate of fossil fuel used to make",
  },
  kiCEmissionRateMakeD: {
    index: 36,
    description:
      "C-emission rate of fossil fuel used to make product displaced by",
  },
  kiCInFossil: { index: 37, description: "Carbon in fossil fuel" },
  kiEnergyFossil: {
    index: 38,
    description: "Energy in fossil fuel used to make",
  },
  kiEnergyFossilDspl: {
    index: 39,
    description: "Energy in fossil fuel used to make product displaced by",
  },
  kiMDispProdDivMOf: {
    index: 40,
    description: "Mass of displaced product / Mass of",
  },
  kiLifetimeDispProd: {
    index: 41,
    description: "/ Mean lifetime of displaced product",
  },
  kiLifeTimeOf: { index: 42, description: "Mean lifetime of" },
  kiMIncr: { index: 43, description: "mass increments" },
  kiStemLossCropMort: {
    index: 44,
    description: "loss to stem loss, due to crop mortality",
  },
  kiDaysJointTillCN: {
    index: 45,
    description: "Days to start of jointing/tillering, for C:N ratio of",
  },
  kiDaysJointBootCN: {
    index: 46,
    description: "Days to start of flowering/booting, for C:N ratio of",
  },
  kiDaysGrainfillCN: {
    index: 47,
    description: "Days to start of grainfill, for C:N ratio of",
  },
  kiPMCMInputs: {
    index: 48,
    description: "plant mulch carbon mass inputs to the mulch",
  },
  kiHumfPerc: { index: 49, description: "Humification % of" },
  kiMicrMulcF: { index: 50, description: "microbial mulch (forest)" },
  kiPlntMulcF: { index: 51, description: "plant mulch (forest)" },
  kiMicrMulcA: { index: 52, description: "microbial mulch (agricultural)" },
  kiPlntMulcA: { index: 53, description: "plant mulch (agricultural)" },
  kiSensMulcDcmp: {
    index: 54,
    description: "Sensitivity of mulch decomposition to",
  },
  kiCNManure: { index: 55, description: "C:N ratio of the manure going to" },
  kiCN: { index: 56, description: "C:N ratio of" },
  kiPercHumf: { index: 57, description: "% of humified" },
  kiManureCTo: { index: 58, description: "% of manure carbon that goes to" },
  kiToRPMF: { index: 59, description: "to RPM (forest)" },
  kiToDPMF: { index: 60, description: "to DPM (forest)" },
  kiToRPMA: { index: 61, description: "to RPM (agricultural)" },
  kiToDPMA: { index: 62, description: "to DPM (agricultural)" },
  kiPercBroken: { index: 63, description: "% of broken-down" },
  kiF: { index: 64, description: "(forest)" },
  kiA: { index: 65, description: "(agricultural)" },
  kiManureOffsAdd: {
    index: 66,
    description: "Manure from offsite applied to the",
  },
  kiDcmpRateMult: {
    index: 67,
    description: "Decomposition rate multiplier for",
  },
  kiPercDcmpSolids: {
    index: 68,
    description: "% of decomposed DPM, RPM, BIO-F, BIO-S solids ->",
  },
  kiPercDcmpHUMSoilds: {
    index: 69,
    description: "% of decomposed HUM solids ->",
  },
  kiPercN: { index: 70, description: "% of N in" },
  kiToTreeMychF: {
    index: 71,
    description: "that moves to trees by mycorrhizal uptake (forest)",
  },
  kiToTreeMychA: {
    index: 72,
    description: "that moves to crop by mycorrhizal uptake (agricultural)",
  },
  kiAtmosDepo: { index: 73, description: "Atmospheric deposition," },
  kiNSymbioFix: { index: 74, description: "Non-symbiotic fixation," },
  kiNitrEachDayF: { index: 75, description: "nitrified each day (forest)" },
  kiNitrEachDayA: {
    index: 76,
    description: "nitrified each day (agricultural)",
  },
  kiInNitrTempFnF: {
    index: 77,
    description: "in the nitrification temperature function (forest)",
  },
  kiInNitrTempFnA: {
    index: 78,
    description: "in the nitrification temperature function (agricultural)",
  },
  kiInNitrPHFnF: {
    index: 79,
    description: "in the nitrification pH function (forest)",
  },
  kiInNitrPHFnA: {
    index: 80,
    description: "in the nitrification pH function (agricultural)",
  },
  kiInNitrWaterFnF: {
    index: 81,
    description: "}, in the nitrification soil water function (forest)",
  },
  kiInNitrWaterFnA: {
    index: 82,
    description: "}, in the nitrification soil water function (agricultural)",
  },
  ki0NitrWaterFnF: {
    index: 83,
    description:
      "for zero clay, in the nitrification soil water function (forest)",
  },
  ki0NitrWaterFnA: {
    index: 84,
    description:
      "for zero clay, in the nitrification soil water function (agricultural)",
  },
  kiCutoffNitrWaterFnF: {
    index: 85,
    description:
      "at the cutoff clay percentage, in the nitrification soil water function (forest)",
  },
  kiCutoffNitrWaterFnA: {
    index: 86,
    description:
      "at the cutoff clay percentage, in the nitrification soil water function (agricultural)",
  },
  kiInDeniCO2FnF: {
    index: 87,
    description: "in the denitrification CO2 function (forest)",
  },
  kiInDeniCO2FnA: {
    index: 88,
    description: "in the denitrification CO2 function (agricultural)",
  },
  kiInDeniNO3FnF: {
    index: 89,
    description: "in the denitrification NO3 function (forest)",
  },
  kiInDeniNO3FnA: {
    index: 90,
    description: "in the denitrification NO3 function (agricultural)",
  },
  kiInDeniWaterFnF: {
    index: 91,
    description: "in the denitrification soil water function (forest)",
  },
  kiInDeniWaterFnA: {
    index: 92,
    description: "in the denitrification soil water function (agricultural)",
  },
  kiInDeniRCO2FnF: {
    index: 93,
    description: "in the denitrification CO2 ratio function (forest)",
  },
  kiInDeniRCO2FnA: {
    index: 94,
    description: "in the denitrification CO2 ratio function (agricultural)",
  },
  kiInDeniRNO3FnF: {
    index: 95,
    description: "in the denitrification NO3 ratio function (forest)",
  },
  kiInDeniRNO3FnA: {
    index: 96,
    description: "in the denitrification NO3 ratio function (agricultural)",
  },
  kiInDeniRWaterFnF: {
    index: 97,
    description: "in the denitrification soil water ratio function (forest)",
  },
  kiInDeniRWaterFnA: {
    index: 98,
    description:
      "in the denitrification soil water ratio function (agricultural)",
  },
  kiNBondEmitF: {
    index: 99,
    description: "nitrogen boundary-line emissions (forest)",
  },
  kiNBondEmitA: {
    index: 100,
    description: "nitrogen boundary-line emissions (agricultural)",
  },
  kiMPercOfMaxTreeM: {
    index: 101,
    description: "mass as % of maximum tree biomass",
  },
  kiCMOf: { index: 102, description: "Carbon mass of" },
  kiNMAs: { index: 103, description: "Mass of nitrogen as" },
  kiOrganicSoil: { index: 104, description: "organic soil)" },
  kiInFormulaAgeRemvF: {
    index: 105,
    description: "in formula for age of removed trees",
  },
  kiRelAllocMultFor: {
    index: 106,
    description: "Post-thin relative allocation multiplier for",
  },
  kiInFormulaAgeRemvA: {
    index: 107,
    description: "in formula for age of removed plants",
  },
  kiToAtmosFireAffect: {
    index: 108,
    description: "to atmosphere, in the fire-affected portion",
  },
  kiInFiredPortion: {
    index: 109,
    description: "}, in the fire-affected portion",
  },
  kiToInertInFired: {
    index: 110,
    description: "to inert soil, in the fire-affected portion",
  },
  kiGrazPerDay: { index: 111, description: "grazed per day" },
  kiPercFodder: { index: 112, description: "% of fodder" },
  kiLostToTermites: {
    index: 113,
    description: "lost to termites (of what remains after breakdown)",
  },
  kiNOffsAddedTo: {
    index: 114,
    description: "Nitrogen from offsite added to the",
  },
  kiPercSoilCMInit: {
    index: 115,
    description: "% of soil carbon-mass that is initially in",
  },
  kiRegimeAutoqueueF: { index: 116, description: "Forest regime autoqueue:" },
  kiDbaSmt: { index: 117, description: "Species meta data:" },
  kiDbaNmt: { index: 118, description: "NPI region meta data:" },
  kiAvgProdRThresh: {
    index: 119,
    description: "average forest productivity (Pavg) value for r threshold",
  },
  kiPercOf: { index: 120, description: "% of" },
  kiInThinnedPortion: {
    index: 121,
    description: "in thin-affected portion of forest",
  },
  kiInThinnedPortion1: {
    index: 122,
    description: "in thin-affected portion of forest (log grade 1 extraction)",
  },
  kiInThinnedPortion2: {
    index: 123,
    description: "in thin-affected portion of forest (log grade 2 extraction)",
  },
  kiInThinnedPortion3: {
    index: 124,
    description: "in thin-affected portion of forest (log grade 3 extraction)",
  },
  kiInThinnedPortion4: {
    index: 125,
    description: "in thin-affected portion of forest (log grade 4 extraction)",
  },
  kiInThinnedPortion5: {
    index: 126,
    description: "in thin-affected portion of forest (log grade 5 extraction)",
  },
  kiInHarvestPortion: {
    index: 127,
    description: "in harvest-affected portion",
  },
  kiInHarvestPortion1: {
    index: 128,
    description: "in harvest-affected portion (crop grade 1 harvest)",
  },
  kiInHarvestPortion2: {
    index: 129,
    description: "in harvest-affected portion (crop grade 2 harvest)",
  },
  kiInHarvestPortion3: {
    index: 131,
    description: "in harvest-affected portion (crop grade 3 harvest)",
  },
  kiInHarvestPortion4: {
    index: 131,
    description: "in harvest-affected portion (crop grade 4 harvest)",
  },
  kiInThinWood1: {
    index: 132,
    description: "for stems and branches (log grade 1 extraction)",
  },
  kiInThinWood2: {
    index: 133,
    description: "for stems and branches (log grade 2 extraction)",
  },
  kiInThinWood3: {
    index: 134,
    description: "for stems and branches (log grade 3 extraction)",
  },
  kiInThinWood4: {
    index: 135,
    description: "for stems and branches (log grade 4 extraction)",
  },
  kiInThinWood5: {
    index: 136,
    description: "for stems and branches (log grade 5 extraction)",
  },
  kiInThinBark1: {
    index: 137,
    description: "for bark (log grade 1 extraction)",
  },
  kiInThinBark2: {
    index: 138,
    description: "for bark (log grade 2 extraction)",
  },
  kiInThinBark3: {
    index: 139,
    description: "for bark (log grade 3 extraction)",
  },
  kiInThinBark4: {
    index: 140,
    description: "for bark (log grade 4 extraction)",
  },
  kiInThinBark5: {
    index: 141,
    description: "for bark (log grade 5 extraction)",
  },
  kiInThinFuel1: {
    index: 142,
    description: "for biofuel (log grade 1 extraction)",
  },
  kiInThinFuel2: {
    index: 143,
    description: "for biofuel (log grade 2 extraction)",
  },
  kiInThinFuel3: {
    index: 144,
    description: "for biofuel (log grade 3 extraction)",
  },
  kiInThinFuel4: {
    index: 145,
    description: "for biofuel (log grade 4 extraction)",
  },
  kiInThinFuel5: {
    index: 146,
    description: "for biofuel (log grade 5 extraction)",
  },
  kiPriceUnits: { index: 147, description: "Units for prices and costs" },
  kiUseStemDens: {
    index: 148,
    description: "Use stem density if price is in cubic meters",
  },
  kiDensF: {
    index: 149,
    description:
      "Density used if price is in cubic meters and don't use stem density",
  },
  kiDensA: {
    index: 150,
    description: "Density used if price is in cubic meters",
  },
  kiWaterFrac: {
    index: 151,
    description: "Fraction of green mass that is water",
  },
  kiHarvCost: { index: 152, description: "Harvesting cost" },
  kiTrnsCost: { index: 153, description: "Transport cost" },
  kiPriceMillGate: { index: 154, description: "Price at the mill gate" },
  kiYearBasePrices: {
    index: 155,
    description: "Year in which entered prices apply (without escalation)",
  },
  kiAnnIncrInReal: { index: 156, description: "Annual increase in real" },
  kiInHarvProd1: {
    index: 157,
    description: "for agricultural products (crop grade 1 extraction)",
  },
  kiInHarvProd2: {
    index: 158,
    description: "for agricultural products (crop grade 2 extraction)",
  },
  kiInHarvProd3: {
    index: 159,
    description: "for agricultural products (crop grade 3 extraction)",
  },
  kiInHarvProd4: {
    index: 160,
    description: "for agricultural products (crop grade 4 extraction)",
  },
  kiInHarvHssi1: {
    index: 161,
    description: "for hay-straw-silage (crop grade 1 extraction)",
  },
  kiInHarvHssi2: {
    index: 162,
    description: "for hay-straw-silage (crop grade 2 extraction)",
  },
  kiInHarvHssi3: {
    index: 163,
    description: "for hay-straw-silage (crop grade 3 extraction)",
  },
  kiInHarvHssi4: {
    index: 164,
    description: "for hay-straw-silage (crop grade 4 extraction)",
  },
  kiInHarvFual1: {
    index: 165,
    description: "for biofuel (crop grade 1 extraction)",
  },
  kiInHarvFual2: {
    index: 166,
    description: "for biofuel (crop grade 2 extraction)",
  },
  kiInHarvFual3: {
    index: 167,
    description: "for biofuel (crop grade 3 extraction)",
  },
  kiInHarvFual4: {
    index: 168,
    description: "for biofuel (crop grade 4 extraction)",
  },
  kiIncmCFrom: {
    index: 169,
    description: "Carbon income generated from change in carbon mass of",
  },
  kiFixOfCostOf: { index: 170, description: "Fixed portion of cost of" },
  kiPhaOfCostOf: { index: 171, description: "Per-hectare portion of cost of" },
  kiMOf: { index: 172, description: "Mass of" },
  kiThinExtraF: {
    index: 173,
    description: "in thin-affected portion (extra, in addition to log grades)",
  },
  kiThinExtraA: {
    index: 174,
    description:
      "in harvest-affected portion (extra, in addition to crop grades)",
  },
  kiDaily: { index: 175, description: "(with daily timing)" },
  kiYearly: { index: 176, description: "(with yearly timing)" },
  kiToDebrisDieOff: {
    index: 177,
    description: "moved to debris due to Die Off",
  },
  kiToAtmsPlough: {
    index: 178,
    description: "moved to atmosphere due to ploughing",
  },
};

let outputProgNmOIKeyValues = {};
let outputKeyValues = {};

export class OutputInfo {
  static getInputNameMap() {
    return inputNameMap;
  }
  static getOutputNameMap() {
    return outputNameMap;
  }

  static getAllOutputs() {
    return outputInfo;
  }

  static getAllOutputProgNmOIKeyValues() {
    if (Object.keys(outputProgNmOIKeyValues).length) {
      return outputProgNmOIKeyValues;
    }
    outputInfo.forEach((oi) => {
      outputProgNmOIKeyValues[oi.progNmOI] = oi;
    });
    return outputProgNmOIKeyValues;
  }

  static getAllOutputKeyValues() {
    if (Object.keys(outputKeyValues).length) {
      return outputKeyValues;
    }
    outputInfo.forEach((oi) => {
      outputKeyValues[oi.tOutOI] = oi;
    });
    return outputKeyValues;
  }

  static getInputNameMapByIndex() {
    return Object.values(inputNameMap).reduce((acc, item) => {
      acc[item.index] = item;
      return acc;
    }, {});
  }
}
