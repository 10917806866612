import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { delay, takeUntil, tap } from "rxjs/operators";
import { FlyOverPanelService } from "../fly-over-panel/services/fly-over-panel.service";
import { Constants } from "../../constants";

@Component({
  selector: "fc-form-item-table",
  templateUrl: "./form-item-table.component.html",
  styleUrls: ["./form-item-table.component.scss"],
})
export class FormItemTableComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  public caption: string;
  public formGroup: UntypedFormGroup;
  public formGroupPointer: string; //used for form array pointing to a selected formGroup
  public indexPointer: string; //same as above
  public customFormModelPointer: string; //used by logGradesComponents, its using forest thinning service, but formModel is from treesService
  public service: any;
  public data: { headers: any[]; rows: any[] } = null;

  constructor(private flyOverPanelService: FlyOverPanelService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    //dynamic component will not update input values, need to watch changes manually

    if (this.indexPointer) {
      this.service[this.indexPointer]
        .pipe(
          takeUntil(this.destroy$),
          delay(0),
          tap((id) => {
            this.formGroup = this.getFormGroup(id);
          })
        )
        .subscribe();
    } else {
      setTimeout(() => {
        this.formGroup = this.getFormGroup();
      }, 0);
    }
  }

  private getFormGroup(id?): UntypedFormGroup {
    return this.formGroupPointer
      ? this.service[this.formGroupPointer](id)
      : this.service.getFormGroup();
  }

  public getRemaining(payload: string[]): string {
    let total = 0;

    payload.forEach((f) => {
      const value = +this.formGroup.get(f)?.value;
      if (isNaN(value.valueOf())) {
        return "";
      }

      total += !this.isShown(f) ? 0 : value;
    });

    return (100 - total).toFixed(1);
  }

  public getTotal(payload: string[]): string {
    let total = 0;

    payload.forEach((f) => {
      const value = +this.formGroup.get(f)?.value;
      if (isNaN(value.valueOf())) {
        return "";
      }

      total += !this.isShown(f) ? 0 : value;
    });

    return total.toFixed(1);
  }

  public getAverage(payload: string[]): string {
    return this.service.getAverage(payload)
  }

  public isOverHundred(payload: string[]): boolean {
    const isOverHundred = +this.getTotal(payload) > 100;
    payload.forEach((f) => {
      const field = this.formGroup.get(f);
      if (isOverHundred && !field.hasError("overHundred")) {
        setTimeout(() => {
          this.service.addError(field, "overHundred");
        }, 1);
      } else if (!isOverHundred && field.hasError("overHundred")) {
        setTimeout(() => {
          this.service.removeError(field, "overHundred");
        }, 1);
      }
    });

    return isOverHundred;
  }

  public dymamicMethod(data: { method: string; payload: any[] }): any {
    return this[data.method](data.payload);
  }

  public isShown(fieldName: string): boolean {
    if (!fieldName || !this.service || !this.service.getFormModel) {
      return true;
    }

    const formModel = this.customFormModelPointer
      ? this.service[this.customFormModelPointer]()
      : this.service.getFormModel();

    return formModel[fieldName].isShown;
  }

  public linkToComponent(input) {
    this.flyOverPanelService.linkToComponent(
      input,
      this.formGroup,
      this.service
    );
  }
  //this is used for form validation in formGroupElement.ts
  public isInvalid(): boolean {
    if (!this.formGroup) {
      return false;
    }
    let isInvalid = false;
    for (const row of this.data.rows) {
      for (const d of row) {
        if (
          d.programmingName &&
          this.formGroup.get(d.programmingName)?.invalid &&
          this.isShown(d.programmingName)
        ) {
          isInvalid = true;
          break;
        }
      }
      if (isInvalid) {
        break;
      }
    }

    return isInvalid;
  }

  //for input actions
  public getInput(d) {
    return {
      element: "input",
      programmingName: d.programmingName,
      type: d.type,
    };
  }
  public getItem(d) {
    return {
      inputs: [this.getInput(d)],
      label: d.label,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
