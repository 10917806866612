import { Inject, Injectable } from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import {
  InteractionType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  AuthenticationResult,
  AccountInfo,
} from "@azure/msal-browser";

import { BehaviorSubject, Observable } from "rxjs";
import { DbService } from "src/app/shared/services/db.service";
import { UserRole } from "src/app/user-role/role-access/model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userClaimSource$ = new BehaviorSubject<object>(null);
  public readonly userClaim$ = this.userClaimSource$.asObservable();

  private userRoleSource$ = new BehaviorSubject<UserRole>(null);
  public readonly userRole$ = this.userRoleSource$.asObservable();

  public userGuid: string = sessionStorage.getItem("userGuidId");

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private dbService: DbService
  ) {}

  public getInteractionStatus(): Observable<InteractionStatus> {
    return this.msalBroadcastService.inProgress$;
  }

  public getAllAccounts() {
    return this.msalService.instance.getAllAccounts();
  }

  public getActiveAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  public setActiveAccount(acc): void {
    this.msalService.instance.setActiveAccount(acc);
  }

  public setUserClaim(claim): void {
    this.userClaimSource$.next({ ...claim });
  }

  public signIn(userFlowRequest?: RedirectRequest | PopupRequest): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  singOut() {
    this.msalService.logout();
  }

  public setUserRoles(role: UserRole) {
    this.userRoleSource$.next(role);
    this.userGuid = role?.userGuid;
  }

  public getUserRoles(): Observable<any> {
    return this.dbService.getUserRoles();
  }
}
