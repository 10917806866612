<ng-container [formGroup]="formGroupInstance">
  <ng-container>
    <div
      class="form-controls"
      *ngIf="availableRegimesMap$ | async as availableRegimesMap"
    >
      <div class="left-controls">
        <div class="button-group" *ngIf="selectedRows?.length">
          <select
            class="form-control form-select form-select-sm"
            [(ngModel)]="selectedBulkAction"
            [ngModelOptions]="{ standalone: true }"
          >
            <option value="delete">Delete</option>
            <option value="merge">Merge</option>
          </select>
          <fc-button
            [isLoading$]="isBulkActionLoading$"
            [inputText]="'Apply'"
            (click)="bulkAction()"
          ></fc-button>
        </div>
      </div>
      <div class="right-controls">
        <span
          [tooltip]="
            shouldDisableNewRegime(availableRegimesMap)
              ? 'No Species with events available'
              : ''
          "
          container="body"
          [adaptivePosition]="false"
          placement="left"
        >
          <button
            type="button"
            class="btn btn-primary-darken btn-sm"
            (click)="openRegimeForm(availableRegimesMap)"
            [disabled]="shouldDisableNewRegime(availableRegimesMap)"
          >
            New regime
          </button>
        </span>
      </div>
    </div>

    <ngx-datatable
      class="custom-table regime-table"
      [rows]="regimes"
      [columnMode]="ColumnMode.force"
      [headerHeight]="56"
      [footerHeight]="56"
      [rowHeight]="36"
      [limit]="10"
      [selected]="selectedRows"
      [selectionType]="SelectionType.checkbox"
      (select)="onSelect($event)"
      [rowClass]="getRowClass"
    >
      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        [sortable]="false"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              allRowsSelected ? "Uncheck all" : "Check all"
            }}</span>
            <input
              type="checkbox"
              [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              isSelected ? "Deselect" : "Select"
            }}</span>
            <input
              type="checkbox"
              [checked]="isSelected"
              (change)="onCheckboxChangeFn($event)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="40"
        [canAutoResize]="false"
        [resizeable]="false"
        [sortable]="false"
        name=""
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <span
            class="event-type"
            [class]="getEventClass(row)"
            [tooltip]="getEventType(row)"
            [adaptivePosition]="false"
            placement="top"
          ></span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="130"
        [canAutoResize]="false"
        [resizeable]="false"
        [sortable]="false"
        name="event Date"
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ getRegimeDate(row) | date : "MMM y" }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="regime Name"
        [sortable]="false"
        [draggable]="false"
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.get("nmRegime")?.value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="150"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        name="Action"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="action-buttons">
            <button
              class="btn btn-sm btn-rounded"
              (click)="editRegime(row)"
              [adaptivePosition]="false"
              tooltip="Edit"
            >
              <span class="visually-hidden">edit</span>
              <i class="bi bi-pencil-fill"></i>
            </button>
            <button
              class="btn btn-sm btn-rounded"
              (click)="cloneRegime(row)"
              [adaptivePosition]="false"
              tooltip="Clone"
            >
              <span class="visually-hidden">clone</span>
              <i class="bi bi-files"></i>
            </button>

            <button
              class="btn btn-sm btn-rounded"
              (click)="deleteRegimes([row])"
              [adaptivePosition]="false"
              tooltip="Delete"
            >
              <span class="visually-hidden">delete</span>
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </ng-container>
  <div
    *ngIf="regimes.length"
    [class]="{
      'is-outside-viewport': isOutsideViewport,
      'is-expanded': isSlimRegimeExpanded
    }"
    class="slim-regime"
    [style]="{
      width: (modifierColumnWidth$ | async) + 'px',
      left: getSlimRegimeLeftPostion(modifierColumnWidth$ | async)
    }"
  >
    <button
      class="expand-button btn btn-sm"
      (click)="isSlimRegimeExpanded = !isSlimRegimeExpanded"
    >
      <i class="bi bi-chevron-left" *ngIf="isSlimRegimeExpanded"></i>
      <i class="bi bi-chevron-right" *ngIf="!isSlimRegimeExpanded"></i>
      <span class="visually-hidden">{{
        isSlimRegimeExpanded ? "collapse" : "expand"
      }}</span>
    </button>

    <div class="slim-regime-header">
      <h2>Regimes</h2>
      <div class="form-controls">
        <div>
          <div class="button-group" *ngIf="selectedRows?.length">
            <select
              class="form-control form-select form-select-sm"
              [(ngModel)]="selectedBulkAction"
              [ngModelOptions]="{ standalone: true }"
            >
              <option value="delete">Delete</option>
              <option value="merge">Merge</option>
            </select>
            <fc-button
              [isLoading$]="isBulkActionLoading$"
              [inputText]="'Apply'"
              (click)="bulkAction()"
            ></fc-button>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <ngx-datatable
        class="custom-table regime-table"
        [rows]="regimes"
        [columnMode]="ColumnMode.force"
        [headerHeight]="0"
        [footerHeight]="56"
        [rowHeight]="120"
        [selected]="selectedRows"
        [selectionType]="SelectionType.checkbox"
        (select)="onSelect($event)"
        [rowClass]="getRowClass"
      >
        <ngx-datatable-column
          [width]="35"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [sortable]="false"
        >
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <label class="check-container">
              <span class="visually-hidden">{{
                isSelected ? "Deselect" : "Select"
              }}</span>
              <input
                type="checkbox"
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
              />
              <span class="checkmark"></span>
            </label>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [width]="30"
          [canAutoResize]="false"
          [resizeable]="false"
          [sortable]="false"
          name=""
        >
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            <span
              class="event-type"
              [class]="getEventClass(row)"
              [tooltip]="getEventType(row)"
              [adaptivePosition]="false"
              placement="right"
            ></span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="regime Name"
          [sortable]="false"
          [draggable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="regime-content">
              <span class="regime-date">{{
                getRegimeDate(row) | date : "MMM y"
              }}</span>

              <span> {{ row.get("nmRegime")?.value }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          name=""
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="dropstart">
              <button
                class="btn btn-sm"
                id="regime-actions"
                data-bs-toggle="dropdown"
                aria-label="regime dropdown toggle"
              >
                <i class="bi bi-three-dots-vertical"></i>
                <span class="visually-hidden">Regime actions</span>
              </button>

              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    (click)="$event.preventDefault(); editRegime(row)"
                  >
                    <i class="bi bi-pencil-fill"></i> Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    (click)="$event.preventDefault(); cloneRegime(row)"
                    ><i class="bi bi-files"></i> Clone</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    (click)="$event.preventDefault(); deleteRegimes([row])"
                    ><i class="bi bi-trash"></i> Delete</a
                  >
                </li>
              </ul>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</ng-container>
