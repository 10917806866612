import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "fc-help-panel",
  templateUrl: "./help-panel.component.html",
  styleUrls: ["./help-panel.component.scss"],
  host: { id: "help-panel" },
})
export class HelpPanelComponent implements OnInit {
  @HostBinding("className") componentClass: string;

  @Output() collapsed = new EventEmitter<void>();

  @Input() htmlContent: string = "<p>No content</p>";

  @Input() set isExpanded(value: boolean) {
    this._isExpanded = value;
    this.componentClass = value ? "expanded" : "";
  }

  @Input() set anchorId(value: string) {
    this.removeHighlight();
    if (value) {
      this.scrollTo(value);
    }
  }
  public _isExpanded: boolean = false;

  constructor(private hostElement: ElementRef) {}
  ngOnInit(): void {}

  private scrollTo(id) {
    const highlightElement = this.hostElement.nativeElement.querySelector(id);
    if (highlightElement) {
      highlightElement.scrollIntoView({
        behavior: "smooth",
      });

      highlightElement.classList.add("highlight");
    }
  }

  private removeHighlight() {
    const highlightElement =
      this.hostElement.nativeElement.querySelector(".highlight");
    if (highlightElement) {
      highlightElement.classList.remove("highlight");
    }
  }
}
