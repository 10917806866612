import { Component, HostBinding, Input } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { FormGroupElement, ItemInput } from "src/app/plot/models";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "[fc-form-group-element]",
  templateUrl: "./form-group-element.component.html",
  styleUrls: ["./form-group-element.component.scss"],
})
export class FormGroupElementComponent {
  @Input() formGroupInstance: UntypedFormGroup;
  @Input() service: any;
  @Input() simulationService: any;

  @Input() group: FormGroupElement;

  @Input() @HostBinding("class.modifiers") public isModifiersCol = false;

  public baseHelpPageUrl = environment.fullcam2020HelpUrl;

  public id: string = "_" + uuidv4();

  public toggleHelpPanel(linkId) {
    this.service.toggleHelpPanel(linkId);
  }

  public isAllItemsHidden(group) {
    const fieldsWithId = group.items.filter((i) => i.id);

    return !fieldsWithId.some((i) => {
      const field = this.service.getFormModel()[i.id];
      return field?.isShown === true;
    });
  }

  public getErrors(group: FormGroupElement): boolean {
    return this.validateGroup(group);
  }

  protected validateGroup(group: FormGroupElement): boolean {
    //debug
    // if (group.label == "Plants") {
    //   console.log(this.formGroupInstance);
    //   Object.entries(this.formGroupInstance.controls).forEach((c) => {
    //     if (c[1].invalid) {
    //       console.log(c[0]);
    //     }
    //   });
    // }

    if (group.items) {
      return group.items.some((item) => {
        if (item.inputs) {
          return item.inputs.some((input) => {
            //check component errors

            //linkTo is a dynamic component validation will be done in CustomValidator.ts
            if (input.element == "component" && !input.linkTo) {
              return input.isComponentInvalid
                ? input.isComponentInvalid()
                : false;
            }

            if (!input.programmingName) {
              return false;
            }
            //to be improved
            let isInvalid = false;
            if (
              this.service.layout.label == "Trees" ||
              this.service.layout.label == "Crops"
            ) {
              if (this.service.getSelectedSpeciesFormGroup()) {
                isInvalid = this.service
                  .getSelectedSpeciesFormGroup()
                  .get(input.programmingName)?.invalid;
              }
            } else {
              isInvalid = this.service.formGroup.get(
                input.programmingName
              )?.invalid;
            }

            return isInvalid;
          });
        } else if (item.groups) {
          return item.groups.some((g) => this.validateGroup(g));
        } else {
          return false;
        }
      });
    }
    return false;
  }
}
