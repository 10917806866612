import { Injectable } from "@angular/core";
import {
  catchError,
  concatMap,
  delay,
  forkJoin,
  map,
  Observable,
  of,
} from "rxjs";
import { DbService } from "src/app/shared/services/db.service";
import { FolderType, ListType } from "../models";

@Injectable({
  providedIn: "root",
})
export class MyPlotsService {
  constructor(public dbService: DbService) {}

  public readonly plotColumns = [
    {
      name: "File name",
    },
    {
      name: "Longitude",
    },
    {
      name: "Latitude",
    },
    {
      name: "Project",
    },
    { name: "Collection" },
    { name: "Created at" },
    { name: "Updated at" },
  ];

  public readonly projectColumns = [
    {
      name: "Project",
    },
    {
      name: "Description",
    },
    { name: "Created at" },
    { name: "Updated at" },
  ];

  public readonly collectionColumns = [
    {
      name: "Collection",
    },
    {
      name: "Description",
    },
    {
      name: "Project",
    },
    { name: "Created at" },
    { name: "Updated at" },
  ];

  public getList(type: ListType, inputParams?): Observable<any> {
    return this.dbService.getList(type, inputParams);
  }

  public createFolder(type: FolderType, params): Observable<any> {
    return this.dbService.createFolder(type, params);
  }

  public updateFolder(type: FolderType, params): Observable<any> {
    return this.dbService.updateFolder(type, params);
  }

  public deleteFolders(type: FolderType, params: string[]): Observable<any> {
    return this.dbService.deleteFolders(type, params);
  }

  public downloadPlotFiles(
    ids: string[]
  ): Observable<{ id: string; result: string }[]> {
    return forkJoin(
      ids.map((id, index) => {
        return of(null).pipe(
          // Add a 1-second delay for each download
          delay(index * 1000),
          // Use concatMap to continue even if one of them throws an error
          concatMap(() => {
            return this.dbService.downloadPlotFile(id).pipe(
              map((result) => ({ id, result })),
              catchError((error) => {
                // Handle the error here or return a placeholder result
                console.error(`Error downloading file with ID ${id}:`, error);
                return of({ id, result: "Error occurred" });
              })
            );
          })
        );
      })
    );
  }

  public deletePlotFiles(ids: string[]): Observable<any> {
    return this.dbService.deletePlotFiles(ids);
  }
}
