<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>
      {{ isNewForm ? "New Regime" : "Regime editing" }}
      <a
        aria-label="help link button"
        type="button"
        *ngIf="eventsService?.baseHelpPageUrl"
        class="btn"
        [href]="getHelpUrl()"
        [tooltip]="'View ' + isNewForm ? 'New regime' : 'Edit regime' + ' help'"
        target="_blank"
      >
        <i class="bi bi-question-circle"></i>
      </a>
    </h1>
    <form [formGroup]="formGroup">
      <ng-container *ngIf="isNewForm">
        <ng-container *ngTemplateOutlet="newRegimeForm"></ng-container>
      </ng-container>

      <ng-container *ngIf="isNewForm"></ng-container>
      <div class="input-container">
        <label for="regime-name-input" class="form-label">Regime name</label>

        <input
          id="regime-name-input"
          class="form-control form-control-sm"
          form-field
          formControlName="nmRegime"
          autocomplete="off"
        />
      </div>

      <fc-timing-form
        *ngIf="!isNewForm && !isMerge"
        [formGroupInstance]="formGroup"
        [startDate]="startDate"
        [simulationTimingType]="simulationTimingType"
        [eventsService]="eventsService"
      ></fc-timing-form>

      <div class="form-button-group">
        <button
          type="button"
          (click)="closeForm(null)"
          class="btn btn-outline-primary"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="closeForm(formGroup.getRawValue())"
          class="btn btn-outline-primary"
          [disabled]="(formGroup.pristine || formGroup.invalid) && !isMerge"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>

<ng-template #newRegimeForm [formGroup]="formGroup">
  <div class="input-container">
    <label for="regime-select" class="form-label">Available regimes </label>
    <select
      id="regime-select"
      class="form-control form-select form-select-sm"
      formControlName="selectedRegime"
      (change)="setRegimeName()"
      [class]="getEventTypeName()"
    >
      <option
        *ngFor="let regime of regimeList"
        [ngValue]="regime"
        [selected]="
          regime?.$?.idRG == formGroup.get('selectedRegime').value?.id
        "
        [class]="regime.type == 'tree' ? 'forest-event' : 'agricultural-event'"
      >
        {{ regime?.$?.nmRG }}
      </option>
    </select>
    <fc-form-error-messages
      *ngIf="
        formGroup.get('selectedRegime')?.errors &&
        (formGroup.touched || formGroup.dirty)
      "
      [formControlInstance]="formGroup.get('selectedRegime')"
    ></fc-form-error-messages>
  </div>
  <div class="input-container inline-form-element">
    <div>
      <label for="start-date-input" class="form-label">Start date</label>
      <input
        id="start-date-input"
        type="text"
        [dateCustomClasses]="['date-picker']"
        placeholder="DD/MM/YYYY"
        class="form-control form-control-sm"
        formControlName="startDate"
        autocomplete="off"
        fcBeginningDay
        bsDatepicker
      />
    </div>
    <div>
      <label for="repeat-every-input" class="form-label">Repeat every</label>
      <div class="repeatEvery-container">
        <div class="input-group">
          <input
            class="form-control form-control-sm"
            id="repeat-every-input"
            type="number"
            formControlName="repeatEvery"
            autocomplete="off"
          />

          <select
            id="repeat-every-type-select"
            class="form-control form-select form-select-sm"
            formControlName="repeatEveryType"
          >
            <option value="years">years</option>
            <option value="days">days</option>
          </select>
        </div>

        <fc-form-error-messages
          *ngIf="formGroup.get('repeatEvery')?.errors"
          [formControlInstance]="formGroup.get('repeatEvery')"
          [customErrorMessages]="{
            min:
              'The minimum value of this field is ' +
              minRotationLengthInDays +
              ' day(s)'
          }"
        ></fc-form-error-messages>
      </div>
    </div>
    <div>
      <label for="until-date-input" class="form-label">Until date</label>
      <input
        id="until-date-input"
        type="text"
        [dateCustomClasses]="['date-picker']"
        placeholder="DD/MM/YYYY"
        class="form-control form-control-sm"
        formControlName="untilDate"
        autocomplete="off"
        fcBeginningDay
        bsDatepicker
      />
    </div>
  </div>
</ng-template>
