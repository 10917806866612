import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { take, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject, lastValueFrom, finalize, of } from "rxjs";
import { ModalOptions } from "ngx-bootstrap/modal";
import { EsatePlotTableColumns } from "src/app/my-plots/models";
import { ModalService } from "src/app/shared/services/modal.service";
import { MessageService } from "src/app/shared/services/message.service";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { PlotEntryModalComponent } from "src/app/shared/components/plot-entry-modal/plot-entry-modal.component";
import { AppService } from "src/app/services/app.service";
import { PlotFilesService, SimulationService } from "src/app/shared/models";

@Component({
  selector: "fc-polt-file-list",
  templateUrl: "./polt-file-list.component.html",
  styleUrls: ["./polt-file-list.component.scss"],
})
export class PoltFileListComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  public service: PlotFilesService;
  public formGroup: FormGroup;

  public isLoading: boolean = false;
  public isBulkActionLoading$ = new BehaviorSubject<boolean>(false);
  public selectedBulkAction: "createEstatePlot" | "delete" = "createEstatePlot";
  public selectedRows: EsatePlotTableColumns[] = [];
  public plotFiles: EsatePlotTableColumns[] = [];

  public searchString: string = "";
  public tempPlotFiles: EsatePlotTableColumns[] = []; //for plot file search

  public statusFilter: "complete" | "pending" | null = null;

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  @ViewChild("plotTable") plotTable: DatatableComponent;

  public simulationService: SimulationService = null;

  public appVersion: string = "";

  constructor(
    private modalService: ModalService,
    private messageService: MessageService,
    private myPlotsService: MyPlotsService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();
    this.appVersion = this.simulationService.version;

    this.formGroup = this.service.getFormGroup();

    this.plotFiles = this.formGroup.get("plotFiles").value;
    this.tempPlotFiles = this.plotFiles;
    this.formGroup
      .get("plotFiles")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((p) => {
        this.plotFiles = [...p];
        this.resetSearchFilter();
      });
  }

  public getStatusLegend(legendValue): { color: string; label: string } {
    const legend = this.service.legend;
    return legend.find((l) => l.label == legendValue);
  }

  public onSelect({ selected }): void {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.plotTable.selected = [];
  }

  public onActivate(event) {}

  public async editPlotFile(row: EsatePlotTableColumns): Promise<void> {
    this.service.editPlotFile(row, "Back to Plot file list");
  }

  public async deletePlotFiles(rows: EsatePlotTableColumns[]): Promise<void> {
    const ids = rows.map((p) => {
      return { plotId: p.plotId, fileName: p.fileName };
    });
    this.service.deletePlotFiles(ids);
  }

  public async bulkAction() {
    if (this.selectedBulkAction == "delete") {
      await this.deletePlotFiles(this.selectedRows);
      this.isBulkActionLoading$.next(false);
    } else if (this.selectedBulkAction == "createEstatePlot") {
      const { validPlotIds, invalidPlotFileNames } = this.selectedRows.reduce(
        (acc, row) => {
          const isValid = this.canCreateEstatePlotFile(row);

          if (isValid) {
            acc.validPlotIds.push(row.plotId);
          } else {
            acc.invalidPlotFileNames.push(row.fileName);
          }
          return acc;
        },
        { validPlotIds: [], invalidPlotFileNames: [] }
      );

      await this.addPlotFilesToEstate(validPlotIds);

      this.isBulkActionLoading$.next(false);

      if (invalidPlotFileNames.length) {
        this.displayInvalidMessage(invalidPlotFileNames);
      }
    }
    this.deselectAll();
  }

  private displayInvalidMessage(plotFileNames: string[]) {
    let message = "";
    if (plotFileNames.length > 1) {
      message = `Plot files ${plotFileNames.join(", ")} are invalid`;
    } else {
      message = `Plot file ${plotFileNames[0]} is invalid`;
    }
    this.messageService.addAlert({
      type: "warning",
      msg: message,
      dismissible: true,
      timeout: 5000,
    });
  }

  public async addPlotFilesToEstate(ids): Promise<void> {
    this.messageService.setFullScreenLoading(true);
    this.myPlotsService
      .downloadPlotFiles(ids)
      .pipe(
        take(1),
        finalize(() => {
          this.messageService.setFullScreenLoading(false);
        })
      )
      .subscribe({
        next: async (xmlPlotsResults) => {
          this.isBulkActionLoading$.next(false);

          await this.service.addPlotFilesToEstate(xmlPlotsResults);

          this.deselectAll();
        },
        error: (error) => {
          this.isBulkActionLoading$.next(true);
        },
      });
  }

  public selectPlotFiles() {
    this.service.selectPlotFiles();
  }

  public isLoading$() {
    return of(this.isLoading);
  }

  public async validateFiles() {
    const plotFiles = this.formGroup.get("plotFiles").value;

    await this.service.validatePlotFiles(plotFiles);
    this.deselectAll();
  }

  public hasPlotFiles() {
    const plotFiles = this.formGroup.get("plotFiles").value;
    return plotFiles.length > 0;
  }

  public hasBrokenLinkPlotFiles() {
    const plotFiles = this.formGroup.get("plotFiles").value;
    for (const p of plotFiles) {
      if (p.validationStatus == "Not found") {
        return true;
      }
    }
    return false;
  }

  public canCreateEstatePlotFile(row) {
    const invalidStatues = [
      "Not found",
      "Used and not valid",
      "Not yet validated",
      "Not valid and not used",
    ];

    return row.plotId && !invalidStatues.includes(row.validationStatus);
  }

  public async relinkPlotFiles(): Promise<void> {
    const plotFiles = this.formGroup.get("plotFiles").value;

    const missingPlotFiles = plotFiles.filter(
      (p) => p.validationStatus == "Not found"
    );

    if (!missingPlotFiles.length) {
      return;
    }

    this.uploadPlotFilesAndRelink();
  }

  private async uploadPlotFilesAndRelink(): Promise<void> {
    this.service.uploadPlotFilesAndRelink();
  }

  public updateFilter() {
    const val = this.searchString.toLowerCase();

    const filteredPlotFiles = this.plotFiles.filter((d) => {
      return d.fileName.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.tempPlotFiles = filteredPlotFiles;
    this.plotTable.offset = 0;
  }

  private resetSearchFilter() {
    this.searchString = "";
    this.tempPlotFiles = this.plotFiles;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
