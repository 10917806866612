import { Component, OnInit, Input, TemplateRef, Optional } from "@angular/core";
import { FormItemElement, ItemInput } from "src/app/plot/models";
import { FormModel, SimulationService } from "../../models";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { PlotReferenceDataService } from "src/app/core/services/plot-reference-data.service";

@Component({
  selector: "fc-input-actions",
  templateUrl: "./input-actions.component.html",
  styleUrls: ["./input-actions.component.scss"],
})
export class InputActionsComponent implements OnInit {
  @Input() simulationService: SimulationService;
  @Input() service: any;

  @Input() item: FormItemElement;
  @Input() input: ItemInput;

  @Input() value: any;

  @Input() programmingName: string;

  @Input() model: FormModel;

  private modalRef?: BsModalRef;
  private config = {
    backdrop: false,
    ignoreBackdropClick: false,
  };

  public inputPropertiesContent = [];

  constructor(
    private modalService: BsModalService,
    private plotReferenceDataService: PlotReferenceDataService
  ) {}

  ngOnInit(): void {}

  public addInputToDigestScenario(input, item, addToAllScenarios, itemValue) {
    this.service.addInputToDigestScenario({
      service: this.service,
      input: input,
      item: item,
      addToAllScenarios: addToAllScenarios,
      itemValue: itemValue,
    });
  }

  public openModal(template: TemplateRef<any>) {
    this.inputPropertiesContent = [];
    this.getInputProperties();
    this.modalRef = this.modalService.show(template, this.config);
  }

  private getInputProperties() {
    const pageId = this.service.pageId;
    let formModel;
    if (this.model) {
      formModel = this.model;
    } else if (pageId == "trees" || pageId == "crops") {
      formModel = this.service.speciesFormModel;
    } else {
      formModel = this.service.formModel;
    }

    const programmingName = this.programmingName;

    if (programmingName && formModel) {
      const dataTypeMap = this.plotReferenceDataService.dataTypeMap;
      const eventIdMap = this.plotReferenceDataService.eventIdMap;
      const speciesTypeMap = this.plotReferenceDataService.speciesTypeMap;
      const booleanMap = {
        false: "No",
        true: "Yes",
      };
      const inputModel = this.model ? formModel : formModel[programmingName];

      const isNumber = inputModel.dataType == 0 || inputModel.dataType == 1;
      let numberProperties = [];
      let scaleProperties = [];
      if (isNumber) {
        numberProperties = [
          { key: "Min Value", value: inputModel.low },
          { key: "Max Value", value: inputModel.high },
        ];

        scaleProperties = [
          { key: "Scale factor when displayed", value: inputModel.scale },
          {
            key: "Inverted when displayed",
            value: booleanMap[inputModel.isInverse],
          },
        ];
      }

      this.inputPropertiesContent = [
        { key: "Category", value: inputModel.categoryLabel },
        { key: "Name", value: inputModel.label },
        { key: "Units", value: inputModel.unit || "-" },
        { key: "Data type", value: dataTypeMap[inputModel.dataType] },

        ...numberProperties,

        {
          key: "Can have risk properties",
          value: booleanMap[inputModel.isRisk],
        },
        {
          key: "Can have spatial properties",
          value: booleanMap[inputModel.isSpa],
        },
        { key: "Species type", value: speciesTypeMap[inputModel.spec] },
        { key: "Event type", value: eventIdMap[inputModel.eventId] },

        { key: "Programming name", value: programmingName },
        { key: "Help page", value: inputModel.helpId },

        ...scaleProperties,
        {
          key: "Input number",
          value: inputModel.tIn,
        },
      ];
    } else {
      //error handling
    }
  }
}
