<ul>
  <li class="dropend">
    <a
      tooltip="Application List"
      container="body"
      class="dropdown-toggle root-link"
      href="#"
      id="navbarDarkDropdownMenuLink"
      role="button"
      data-bs-toggle="dropdown"
      aria-label="apps dropdown toggle"
    >
      <i class="bi bi-grid-3x3-gap-fill"></i>
    </a>
    <div class="sub-menu dropdown-menu">
      <ng-container
        *ngIf="
          userRoles?.canAccessFullCam2020 && userRoles?.status !== 'Pending'
        "
      >
        <div class="sub-menu-title">FULLCAM 2020</div>
        <ul>
          <li>
            <a
              [routerLink]="['/plots-2020']"
              aria-label="plots link"
              routerLinkActive="router-link-active"
            >
              <i class="bi bi-geo-alt"></i>Plots
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/simulation-2020']"
              aria-label="simulation link"
              routerLinkActive="router-link-active"
            >
              <i class="bi bi-plus-circle"></i>New Simulation
            </a>
          </li>
        </ul>
      </ng-container>

      <ng-container
        *ngIf="
          userRoles?.canAccessFullCam2024 &&
          userRoles?.fullCam2024Status !== 'Pending'
        "
      >
        <div class="sub-menu-title">FULLCAM 2024</div>
        <ul>
          <li>
            <a
              [routerLink]="['/plots-2024']"
              aria-label="plots link"
              routerLinkActive="router-link-active"
            >
              <i class="bi bi-geo-alt"></i>Plots
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/simulation-2024']"
              aria-label="simulation link"
              routerLinkActive="router-link-active"
            >
              <i class="bi bi-plus-circle"></i>New Simulation
            </a>
          </li>
        </ul>
      </ng-container>

      <ng-container *ngIf="true">
        <div class="sub-menu-title">RMT</div>
        <ul>
          <li>
            <a
              [routerLink]="['/rmds']"
              aria-label="rmd link"
              routerLinkActive="router-link-active"
            >
              <i class="bi bi-geo-alt"></i>RMD files
            </a>
          </li>
          <li>
            <a
              [routerLink]="['/rmt/simulation']"
              aria-label="simulation link"
              routerLinkActive="router-link-active"
            >
              <i class="bi bi-plus-circle"></i>New Simulation
            </a>
          </li>
        </ul>
      </ng-container>
    </div>
  </li>

  <!-- <li *ngIf="userRoles?.canAccessUserModule">
    <a
      [routerLink]="['/user-management']"
      routerLinkActive="router-link-active"
      class="root-link"
      aria-label="User Management"
    >
      <i class="bi bi-people-fill"></i>
    </a>
  </li> -->
  <li *ngIf="userRoles?.canAccessUserModule">
    <a
      tooltip="User Access requests"
      container="body"
      [routerLink]="['/portal-admin']"
      routerLinkActive="router-link-active"
      class="root-link"
      aria-label="portal admin link"
    >
      <i class="bi bi-person-plus"></i>
    </a>
  </li>
  <li *ngIf="userRoles?.canAccessUserModule">
    <a
      tooltip="Block and Unblock Users"
      container="body"
      [routerLink]="['/portal-admin-all']"
      routerLinkActive="router-link-active"
      class="root-link"
      aria-label="portal admin revoke access"
    >
      <i class="bi bi-person-dash-fill"></i>
    </a>
  </li>
</ul>
