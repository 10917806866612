import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "fc-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  @Output() action = new EventEmitter();

  public message: string;
  public noActions: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  public confirm(value) {
    this.action.emit(value);
  }
}
