<ng-container *ngIf="(currentFlows$ | async)?.length">
  <div class="floating-button-group">
    <ng-container *ngIf="getPlotFormatName() == 'Estate'">
      <input
        type="file"
        id="plot-import"
        (change)="onGenerateEstateFileSelected($event)"
        [accept]="acceptedFileTypes"
        hidden
      />
      <label
        tooltip="Import a CSV file to generate Estate Plots"
        role="button"
        class="btn btn-outline-primary-darken btn-sm"
        for="plot-import"
        >Generate Estate</label
      >
    </ng-container>
    <fc-button
      [tooltip]="
        shouldDisableSave()
          ? 'Project or Collection in About page is not selected or invalid'
          : ''
      "
      class="download-button"
      [disabled]="shouldDisableSave()"
      [inputText]="
        isFromServer()
          ? 'Update ' + getPlotFormatName() + ' File'
          : 'Save ' + getPlotFormatName() + ' File'
      "
      [isLoading$]="isLoading$()"
      (onClick)="savePlotFile()"
    >
    </fc-button>
    <ng-container *ngIf="selectedPlotFormat$ | async as selectedPlotFormat">
      <fc-button
        [tooltip]="
          !isSimulationReady()
            ? 'Simulation can only be run when all pages are valid.'
            : 'Run ' + getPlotFormatName() + ' simulation'
        "
        class="download-button"
        [disabled]="!isSimulationReady()"
        [inputText]="'Run Simulation'"
        [isLoading$]="isSimulating$()"
        (onClick)="runSimulation()"
      >
      </fc-button>
    </ng-container>
  </div>

  <div class="row">
    <div class="col-12">
      <div
        class="multisteps-form-progress"
        [class]="{ expanded: isFlowStepsExpanded }"
      >
        <fc-simulation-nav
          [selectedItem]="getSelectedFlow()"
          [items]="currentFlows$ | async"
          (itemSelected)="setSelectedFlowPage($event)"
        ></fc-simulation-nav>
      </div>
    </div>
  </div>
  <!--form panels-->
  <div class="row multisteps-form">
    <!-- <div class="col-lg-9 col-md-12"> -->
    <ng-template #flowContentHost></ng-template>
    <!-- </div> -->
  </div>

  <fc-fly-over-panel></fc-fly-over-panel>
</ng-container>
