import { Component } from "@angular/core";
import { lastValueFrom, Subject, takeUntil, take } from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { ProjectTableColumns } from "src/app/my-plots/models";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-rmt-project",
  templateUrl: "./rmt-project.component.html",
  styleUrls: ["./rmt-project.component.scss"],
})
export class RmtProjectComponent extends BaseFlowComponent {
  private readonly roleSub$ = new Subject<void>();

  constructor(private authService: AuthService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.authService.userRole$
      .pipe(takeUntil(this.roleSub$))
      .subscribe(async (role) => {
        if (role) {
          this.roleSub$.next();
          this.roleSub$.complete();
          //check if there are folders loaded already if not load folders
          const existingFolders: ProjectTableColumns[] = await lastValueFrom(
            this.service.getFolders().pipe(take(1))
          );

          if (!existingFolders?.length) {
            await this.service.loadFolders();
          }

          super.ngOnInit();
        }
      });
  }
}
