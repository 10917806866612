import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { take } from 'rxjs';

@Component({
  selector: 'fc-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  public TandSHTML: SafeHtml = null;
  constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.httpClient.get('assets/templates/FullCAMApplication_TermsAndConditions.html', { responseType: 'text' }).pipe(take(1)).subscribe(htmlContent => {
      this.TandSHTML = this.sanitizeHtml(htmlContent);
    });

  }

  sanitizeHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
