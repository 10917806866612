import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "[fc-modifiers]",
  templateUrl: "./modifiers.component.html",
  styleUrls: ["./modifiers.component.scss"],
})
export class ModifiersComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input()
  public data: any[] = [];

  @Input()
  public simulationService: any;

  @Input()
  private service: any;

  @Input()
  public formGroupInstance: UntypedFormGroup;

  public aboutFormGroup: UntypedFormGroup;
  public dataBuilderFormGroup: UntypedFormGroup;
  public currentPageId: string;
  public isEstate: boolean = false;

  constructor() {}

  ngOnInit() {
    const fileExtension = this.simulationService.selectedPlotFormat$.getValue();
    this.isEstate = fileExtension == "est";

    this.aboutFormGroup = this.simulationService.aboutService.getFormGroup();
    this.dataBuilderFormGroup =
      this.simulationService.dataBuilderService.getFormGroup();
    this.currentPageId = this.simulationService.getSelectedFlow()?.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
