<form class="row g-4">
  <div class="col-4">
    <fc-explorer-category-list
      class="category-list"
      [service]="service"
      [categoryList]="categoryList"
      [digestFormGroup]="digestFormGroup"
      (categoryClicked)="showControls($event)"
      [style]="{
        height: (layoutService.formContainerHeight$ | async) - 148 + 'px'
      }"
    ></fc-explorer-category-list>
  </div>

  <div class="col-8">
    <ng-container *ngIf="service.selectedCategoryId$ | async as categoryId">
      <table class="table" *ngIf="categoryId !== 'estate'; else estateTable">
        <thead>
          <tr *ngIf="categoryId !== 'plotDigest'">
            <th scope="col">Name</th>
            <th scope="col">Programming name</th>
            <th scope="col">Value</th>
            <th scope="col"></th>
          </tr>
          <tr *ngIf="categoryId == 'plotDigest'">
            <th scope="col">Input element description</th>
            <th scope="col">Species or Event name</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let control of selectedControls">
            <td>
              {{
                control?.model?.prefixId
                  ? prefixMap[control?.model?.prefixId]?.description
                  : ""
              }}
              {{ control.label }}
              {{
                item?.model?.suffixId
                  ? prefixMap[item.model.suffixId]?.description
                  : ""
              }}
            </td>
            <td>
              {{
                categoryId !== "plotDigest"
                  ? control.programmingName
                  : control.option
              }}
            </td>
            <td>
              <div class="display-value">
                <ng-container *ngIf="control.model?.dataType == 6">
                  <ng-container
                    *ngTemplateOutlet="
                      timeSeries;
                      context: {
                        control: control
                      }
                    "
                  ></ng-container>
                </ng-container>

                <ng-container *ngIf="control.model?.dataType !== 6">
                  <ng-container
                    *ngTemplateOutlet="
                      standardValue;
                      context: {
                        control: control
                      }
                    "
                  ></ng-container>
                </ng-container>
              </div>
            </td>
            <td>
              <fc-input-actions
                *ngIf="categoryId !== 'plotDigest'"
                [simulationService]="service.simulationService"
                [service]="control.service"
                [input]="control.input"
                [item]="control.item"
                [value]="control.value"
                [programmingName]="control.programmingName"
                [model]="control.model"
              ></fc-input-actions>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</form>

<ng-template #standardValue let-control="control">
  <div *ngIf="control?.model?.dataType !== 9" class="standard-value">
    <span>{{ control.displayValue }}</span
    ><span> {{ control.model.unit }}</span>
  </div>
  <div *ngIf="control?.model?.dataType == 9">
    {{ control.displayValue | date : "dd/MM/yyyy" }}
  </div>
</ng-template>

<ng-template #timeSeries let-control="control">
  <button
    class="btn btn-outline-primary btn-sm link-component-button"
    (click)="openTimeSeriesPanel(control)"
  >
    <span> View time series... </span>
  </button>
</ng-template>

<ng-template #estateTable>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Plot</th>
        <th scope="col">Start year</th>
        <th scope="col">Start step</th>
        <th scope="col">Timing</th>
        <th scope="col">Area (ha)</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let control of selectedControls">
        <td>{{ control?.value.fileName }}</td>
        <td>{{ control?.value.startYrEP }}</td>
        <td>{{ control?.value.startStepEP }}</td>
        <td>{{ control?.value.estateTimingEP ? "Estate" : Plot }}</td>
        <td>{{ control?.value.areaEP }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
