import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { View } from "ol";
import { OlMapComponent } from "../ol-map.component";

@Component({
  selector: "fc-map-preview",
  templateUrl: "./map-preview.component.html",
  styleUrls: ["./map-preview.component.scss"],
})
export class MapPreviewComponent implements OnInit {
  @ViewChild(OlMapComponent) mapComponent: OlMapComponent;

  @Input() header: string = "Map preview";

  @Input()
  view: View;

  @Input("previewLayer") set previewLayer(inputLayer) {
    if (inputLayer && this.mapComponent) {
      const hasLayer = this.mapComponent
        .getLayers()
        .getArray()
        .find((layer) => layer?.get("ol_uid") == inputLayer.ol_uid);
      if (!hasLayer) {
        this.mapComponent.addLayer(inputLayer);
      }
    }
  }

  public updateSize() {
    this.mapComponent.updateSize();
  }
  public getView() {
    return this.mapComponent.getView();
  }
  public setView(view: View) {
    this.mapComponent.getMap().setView(view);
  }

  public setCenter(center) {
    this.mapComponent.getView().setCenter(center);
  }

  public addLayer(layer) {
    this.mapComponent.addLayer(layer);
  }

  constructor() {}

  ngOnInit(): void {}
}
