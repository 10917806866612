import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, Subject, take, takeUntil, timeout } from "rxjs";
import { AvailableSpecies, SpeciesRegimesResponse } from "src/app/rmt/models";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";
import { RmtManagementService } from "../../services/rmt-management.service";
import { CustomApiResponse } from "src/app/shared/models";

@Component({
  selector: "fc-add-rotation-form",
  templateUrl: "./add-rotation-form.component.html",
  styleUrls: ["./add-rotation-form.component.scss"],
})
export class AddRotationFormComponent
  extends BaseModalFormComponent
  implements OnInit
{
  private readonly destroy$ = new Subject<void>();

  public managementService: RmtManagementService;
  public availableSpecies: AvailableSpecies;
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    selectedSpecies: new UntypedFormControl(null, [Validators.required]),

    selectedRegime: new UntypedFormControl(null, [Validators.required]),
  });

  public speciesRegimes$: Observable<CustomApiResponse<SpeciesRegimesResponse>>;
  public speciesRegimes: SpeciesRegimesResponse[] = [];

  ngOnInit(): void {
    this.formGroup
      .get("selectedSpecies")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((selectedSpieces: AvailableSpecies) => {
        //reset the regime select
        this.formGroup.get("selectedRegime").reset();
        this.speciesRegimes = [];

        this.speciesRegimes$ = this.managementService
          .getLocationSpeciesRegimes$(selectedSpieces.Id)
          .pipe(take(1))
          .subscribe((speciesRegimesResponse) => {
            let sortedSpeciesRegimes = speciesRegimesResponse.data.sort(
              (a, b) => {
                const aName = a.Name;
                const bName = b.Name;

                try {
                  const getLevelScore = (str) => {
                    if (str.includes("high")) return 3;
                    if (str.includes("low")) return 2;
                    if (str.includes("medium")) return 1;
                    return 3;
                  };

                  const getNumber = (str) => {
                    return parseInt(str.split(")")[1].trim());
                  };

                  const levelDiff = getLevelScore(bName) - getLevelScore(aName);
                  if (levelDiff !== 0) return levelDiff;

                  return getNumber(bName) - getNumber(aName);
                } catch (error) {
                  if (aName === bName) return 0;
                  if (aName < bName) return -1;
                  return 1;
                }
              }
            );
            this.speciesRegimes = sortedSpeciesRegimes;
          });
      });
  }

  public getSpeciesClass(name): "primary-species" | null {
    if (name.trim() === "Mixed species environmental planting") {
      return "primary-species";
    }

    return null;
  }

  public addRotation(): void {
    const regime = this.formGroup.get("selectedRegime").value;
    this.managementService
      .getRegimeDetails(regime.Id)
      .pipe(take(1))
      .subscribe((regimeResponse) => {
        const regime = regimeResponse.data;

        this.managementService.rotationService.addUserRotation(regime, true);

        this.managementService.simulationService.messageService.addAlert({
          type: "success",
          msg: "New rotation has been added.",
          timeout: 2000,
        });
      });
  }
}

// [System.SerializableAttribute()]
// public partial class Regime : object, System.Runtime.Serialization.IExtensibleDataObject, System.ComponentModel.INotifyPropertyChanged {

//     [System.NonSerializedAttribute()]
//     private System.Runtime.Serialization.ExtensionDataObject extensionDataField;

//     [System.Runtime.Serialization.OptionalFieldAttribute()]
//     private System.Collections.Generic.List<NCAT.WebServices.NCATService.RegimeEvent> AvailableEventsField;

//     [System.Runtime.Serialization.OptionalFieldAttribute()]
//     private System.Collections.Generic.List<NCAT.WebServices.NCATService.RotationEvent> DefaultRotationField;

//     [System.Runtime.Serialization.OptionalFieldAttribute()]
//     private int IdField;

//     [System.Runtime.Serialization.OptionalFieldAttribute()]
//     private string NameField;

//     [System.Runtime.Serialization.OptionalFieldAttribute()]
//     private NCAT.WebServices.NCATService.Spec SpeciesField;

//     [System.Runtime.Serialization.OptionalFieldAttribute()]
//     private NCAT.WebServices.NCATService.TreeGrowth TreeGrowthField;

//     [global::System.ComponentModel.BrowsableAttribute(false)]
//     public System.Runtime.Serialization.ExtensionDataObject ExtensionData {
//         get {
//             return this.extensionDataField;
//         }
//         set {
//             this.extensionDataField = value;
//         }
//     }
