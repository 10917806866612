<ng-container *ngIf="formGroupInstance">
  <div [formGroup]="formGroupInstance" [class]="displayDirection">
    <div class="title-group">
      <h2>
        Timing
        <a
          aria-label="help link button"
          type="button"
          *ngIf="baseUrl"
          class="btn"
          [href]="baseUrl + '/' + eventTimingHelpLink"
          tooltip="View Event timing help"
          target="_blank"
        >
          <i class="bi bi-question-circle"></i>
        </a>
      </h2>
    </div>

    <div class="input-container simulate-control">
      <label class="label form-label" for="ev-simulation">Simulate</label>

      <div form-field class="form-check form-switch">
        <input
          id="ev-simulation"
          class="form-check-input switch-input"
          type="checkbox"
          role="switch"
          autocomplete="off"
          formControlName="onEV"
        />
      </div>
      <!-- <fc-input-actions
      [simulationService]="simulationService"
      [service]="service"
      [input]="input"
      [item]="item"
    ></fc-input-actions> -->
    </div>
    <div class="controls">
      <div class="input-container">
        <label class="label form-label" for="ev-simulation">
          Date origin
        </label>
        <select
          id="event-type"
          type="text"
          class="form-control form-select form-select-sm"
          formControlName="dateOriginEV"
        >
          <option
            value="Calendar"
            [selected]="
              formGroupInstance.get('dateOriginEV').value == 'Calendar'
            "
          >
            Calendar date
          </option>

          <option
            value="StartSim"
            [selected]="
              formGroupInstance.get('dateOriginEV').value == 'StartSim'
            "
          >
            Since start of simulation
          </option>
        </select>
      </div>

      <ng-container
        *ngIf="
          formGroupInstance.get('dateOriginEV').value == 'Calendar';
          then calendarDateFields;
          else startOfSimFields
        "
      >
      </ng-container>
    </div>
  </div>

  <ng-template #calendarDateFields [formGroup]="formGroupInstance">
    <div class="input-container">
      <label class="label form-label" for="ev-date"> Event date </label>
      <div>
        <input
          id="ev-date"
          type="text"
          [dateCustomClasses]="['date-picker']"
          placeholder="DD/MM/YYYY"
          class="form-control form-control-sm"
          formControlName="dateEV"
          autocomplete="off"
          [placement]="displayDirection == 'vertical' ? 'top' : 'bottom'"
          bsDatepicker
        />
        <fc-form-error-messages
          *ngIf="
            formGroupInstance.get('dateEV')?.errors &&
            (formGroupInstance.touched || formGroupInstance.dirty)
          "
          [formControlInstance]="formGroupInstance.get('dateEV')"
        >
        </fc-form-error-messages>
      </div>
    </div>

    <div class="input-container">
      <label class="label form-label" for="ev-add-days"> Add days </label>
      <div class="days-control">
        <input
          type="number"
          class="form-control form-control-sm"
          id="ev-add-days"
          [(ngModel)]="numberOfDays"
          [ngModelOptions]="{ standalone: true }"
        />
        <button class="btn btn-outline-primary btn-sm" (click)="addDays()">
          Add
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #startOfSimFields [formGroup]="formGroupInstance">
    <div class="input-container">
      <label class="label form-label" for="ev-calendar-years">
        Calendar years</label
      >
      <div>
        <input
          type="number"
          id="ev-calendar-years"
          formControlName="nYrsFromStEV"
          class="form-control form-control-sm"
        />
        <fc-form-error-messages
          *ngIf="
            formGroupInstance.get('nYrsFromStEV')?.errors &&
            (formGroupInstance.touched || formGroupInstance.dirty)
          "
          [formControlInstance]="formGroupInstance.get('nYrsFromStEV')"
        >
        </fc-form-error-messages>
      </div>
    </div>

    <div class="input-container">
      <label class="label form-label" for="ev-add-days">Calendar days</label>
      <div>
        <input
          type="number"
          id="ev-add-days"
          class="form-control form-control-sm"
          formControlName="nDaysFromStEV"
          class="form-control form-control-sm"
        />
        <fc-form-error-messages
          *ngIf="
            formGroupInstance.get('nDaysFromStEV')?.errors &&
            (formGroupInstance.touched || formGroupInstance.dirty)
          "
          [formControlInstance]="formGroupInstance.get('nDaysFromStEV')"
        >
        </fc-form-error-messages>
        <div
          class="year-conversion-text"
          *ngIf="formGroupInstance.get('nDaysFromStEV').value > 364"
        >
          {{
            getYearConversionText(formGroupInstance.get("nDaysFromStEV").value)
          }}
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
