import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { IOutputWindow } from "../../models";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { OutputWindowsService } from "src/app/shared/models";
import { Subject } from "rxjs";
import { MessageService } from "src/app/shared/services/message.service";
import Utilities from "src/app/shared/utilities/utils";

@Component({
  selector: "fc-simulation-result",
  templateUrl: "./simulation-result.component.html",
  styleUrls: ["./simulation-result.component.scss"],
})
export class SimulationResultComponent implements OnInit {
  private readonly unsubscribe$ = new Subject<void>();

  @ViewChildren("chart") chartComponents: QueryList<ElementRef>;

  @Input() service: OutputWindowsService;

  public readonly ColumnMode = ColumnMode;
  public renamingWindowIndex: number = null;
  public previousValue: string = null;
  public selectedRows: string[][] = [];
  public includeHeadersForClipboard: boolean = true;

  public formGroup: FormGroup;

  @HostListener("document:click", ["$event"])
  handleClick(e: Event) {
    if (this.renamingWindowIndex > -1) {
      const editingId = `#ow-tab${this.renamingWindowIndex}-link`;
      const editingContainer =
        this.hostElement.nativeElement.querySelector(editingId);
      if (editingContainer && !editingContainer.contains(e.target)) {
        this.exitEdit();
      }
    }
  }

  constructor(
    private hostElement: ElementRef,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.service.getFormGroup();
  }
  public enableWindow(ow: AbstractControl<IOutputWindow>): void {
    ow.get("isDisabled").setValue(false);
  }
  public hasDisabledWindows(): boolean {
    return this.getDisabledWindows().length > 0;
  }

  public getDisabledWindows(): AbstractControl<IOutputWindow>[] {
    return this.getOutputWindows().controls.filter(
      (ow) => ow.get("isDisabled").value
    );
  }

  public getOutputWindows(): FormArray {
    return this.formGroup.get("outputWindows") as FormArray<
      AbstractControl<IOutputWindow>
    >;
  }
  public addOutputWindow(): void {
    this.service.addOutputWindow(false);
  }

  public tabSelected(ow: AbstractControl<IOutputWindow>): void {
    this.service.deactiveActiveWindow();
    ow.get("isActive").setValue(true);
    //force to reselect so it does not look deselected
    ow.get("viewType").setValue(ow.get("viewType").value);

    if (ow.get("viewType").value == "Graph") {
      this.refreshCharts();
    }
  }

  public renameWindow(index: number, ow: AbstractControl<IOutputWindow>): void {
    this.renamingWindowIndex = index;
    this.previousValue = ow.get("title").value;

    setTimeout(() => {
      const editingId = `#ow-tab${this.renamingWindowIndex}-link input`;
      const editinInput =
        this.hostElement.nativeElement.querySelector(editingId);
      if (editinInput) {
        editinInput.focus();
        editinInput.setSelectionRange(0, editinInput.value.length);
      }
    }, 1);
  }

  public cloneWindow(ow: AbstractControl<IOutputWindow>): void {
    this.service.cloneOutputWindow(ow);
    window.setTimeout(() => {});
  }

  public disableWindow(ow: AbstractControl<IOutputWindow>): void {
    const isDisabled = ow.get("isDisabled").value;
    this.service.disableOutputWindow(!isDisabled, ow);
  }

  public removeWindow(index: number): void {
    this.service.removeOutputWindow(index);
  }

  public cancelEdit(ow: AbstractControl<IOutputWindow>): void {
    ow.get("title").setValue(this.previousValue);
    this.exitEdit();
  }

  public exitEdit() {
    this.renamingWindowIndex = null;
    this.previousValue = null;
  }

  private refreshCharts(): void {
    if (this.chartComponents?.length) {
      setTimeout(() => {
        this.chartComponents.forEach((c: any) => c.chart.resize());
      }, 10);
    }
  }

  public hasSimulationResult(): boolean {
    const simulationService = this.service.simulationService;

    const simulationResult = simulationService.getSimulationResult();

    if (simulationResult?.length) {
      return true;
    }
    return false;
  }

  public exportTableAsCsv(event) {
    event.preventDefault();
    event.stopPropagation();

    const simulationService = this.service.simulationService;
    let fileName =
      simulationService.helperService.getTodayDateTimeForFileName();

    if (simulationService.plotMetaData?.fileName) {
      fileName = simulationService.plotMetaData.fileName
        .split(".")
        .slice(0, -1)
        .join(".");
    }

    const simulationResult = simulationService.getSimulationResult();

    if (simulationResult?.length) {
      simulationService.helperService.downloadFile(
        "simulation_result_" + fileName + ".csv",
        Utilities.convertArrayToCsv(simulationResult[0].data),
        "text/csv"
      );
    } else {
      this.messageService.addAlert({
        type: "danger",
        msg: `Simulation result not found.`,
        dismissible: true,
      });
    }
  }

  public exportTableAToClipboard(event) {
    event.preventDefault();
    event.stopPropagation();
    const simulationService = this.service.simulationService;

    const simulationResult = simulationService.getSimulationResult();

    const ow = this.service.getActiveWindow();

    const data = ow.get("tableData").value;
    if (data) {
      const headers = data?.headers.map((h) => h["name"]) || [];
      const rows = [headers, ...data?.rows];

      let clipboardData = this.convertToClipboardFormat(rows); // Convert the data to a tab-delimited string
      this.copyToClipboard(clipboardData);
    }
  }

  public onSelect({ selected }) {
    // if (!this.service.multiSelectRowInstructionDisplayed) {
    //   this.service.setMultiSelectRowInstructionDisplayed(true);
    //   this.messageService.addAlert({
    //     type: "info",
    //     msg: `Tip: Hold 'Shift' to select multiple rows or 'Ctrl' (Cmd on Mac) for individual rows.`,
    //     dismissible: true,
    //   });
    // }

    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
  }

  public copyDataToClipboard() {
    const ow = this.service.getActiveWindow();

    const headers =
      ow.get("tableData").value?.headers.map((h) => h["name"]) || [];

    const rows = this.includeHeadersForClipboard
      ? [headers, ...this.selectedRows]
      : this.selectedRows;
    let clipboardData = this.convertToClipboardFormat(rows); // Convert the data to a tab-delimited string
    this.copyToClipboard(clipboardData);
  }

  private copyToClipboard(clipboardData) {
    navigator.clipboard
      .writeText(clipboardData)
      .then(() => {
        this.messageService.addAlert({
          type: "success",
          msg: "Data copied to clipboard!",
          timeout: 3000,
        });
      })
      .catch((err) => {
        this.messageService.addAlert({
          type: "danger",
          msg: "Could not copy data",
        });
      });
  }

  private convertToClipboardFormat(data) {
    return data.map((row) => Object.values(row).join("\t")).join("\n");
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
