<div class="input-action-button">
  <button
    class="btn btn-sm"
    type="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <span class="visually-hidden">actions</span>
    <i class="bi bi-three-dots-vertical"></i>
  </button>
  <ul class="dropdown-menu">
    <li>
      <a class="dropdown-item" href="#"  (click)="openModal(inputPropertyTemplate);$event.preventDefault()">Input properties</a>
    </li>
    <ng-container
      *ngIf="simulationService?.selectedPlotFormat$.getValue() == 'pld'"
    >
      <li><hr class="dropdown-divider" /></li>
      <li>
        <a
          class="dropdown-item"
          href="#"
          (click)="
            addInputToDigestScenario(input, item, null, value);
            $event.preventDefault()
          "
          >Add to Scenario '{{ service.getSelectedScenarioName() }}'</a
        >
      </li>
      <li *ngIf="service.getNumberOfDigestScenarios() > 1">
        <a
          class="dropdown-item"
          href="#"
          (click)="
            addInputToDigestScenario(input, item, true, value);
            $event.preventDefault()
          "
          >Add to all Digest Scenarios</a
        >
      </li>
    </ng-container>
  </ul>
</div>


<ng-template #inputPropertyTemplate>
  
  <div class="modal-header">
    <h3 class="modal-title pull-left">Input properties</h3>
    <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ul class="list-group list-group-flush input-properties">
      <li class="list-group-item" *ngFor="let item of inputPropertiesContent"><span class="key">{{item.key}}:</span> <span>{{item.value}}</span></li>
    </ul>

  </div>
  
</ng-template>
