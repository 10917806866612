import { FormControl, UntypedFormControl } from "@angular/forms";
import { FormFieldModel, InputItem } from "../shared/models";

export type PlotTabs = { [key: string]: PlotTab };

export interface PlotTab {
  id?: string;
  label: string;
  service: any; //need generic service type
  component: any;
}

export interface FormLayout {
  label: string;
  groups: FormGroupElement[];
}

export type PlotTypeOptions =
  | []
  | [
      "Forest soil",
      "Forest system",
      "Agricultural soil",
      "Agricultural system",
      "Mixed (forest and agricultural) system"
    ];

export interface FormGroupElement {
  showErrors?: boolean;
  isAccordion?: boolean;
  isExpanded?: boolean;
  isUnderlineHeader?: boolean;
  isUnderlineItems?: boolean;
  isBorderedContainer?: boolean;
  subLabel?: string;
  isRoot?: boolean;
  label: string;
  id?: string;
  isShown: boolean;
  items?: FormItemElement[];
  helpLink?: string;
}
export interface FormItemElement {
  id?: string;
  label?: string;
  inputs?: ItemInput[];
  shoartLabel?: string;
  groups?: FormGroupElement[];
  preventLabelElement?: boolean; // for accessibility check
  helpLink?: string;
}

export interface ItemInput {
  id?: string;
  programmingName?: string;
  label?: string;
  labelMethod?: string;
  floatingLabel?: string;
  suffixText?: string;
  element:
    | "input"
    | "navigation"
    | "component"
    | "text"
    | "select"
    | "textarea"
    | "button";
  isComponentInvalid?: () => boolean;
  selectOptions?: { label: string; value: any; index?: number }[];
  width?: string;
  value?: string;
  rows?: number;
  type?: string;
  linkTo?: "flyOverPanel";
  component?: string;
  componentInputs?: any[];
  componentOutputs?: any[];
  payload?: any;
  method?: string;
  placeholder?: string;
  buttonLabel?: string;
  showButtonMethod?: string;
  showButtonPayload?: any;
  submitMethod?: string;
  customErrorMessages?: { [key: string]: string };
  customClassFunction?: string;
  hideMethod?: string;
  hideMethodPayload?: string;
}

export interface subGroupsEmitterObject {
  id: string;
  label: string;
  parentGroupHeight: number;
  nav?: subGroupsEmitterObject[];
}

export interface PlotDigestScenario {
  isExpanded?: boolean;
  enabled?: boolean;
  name: string;
  notes?: string;
  option?: string;
  scenarioMap?: ScenarioMap;
  controls?: IScenarioDetails[];
}

export interface ScenarioMap {
  [key: string]: any;
  controls?: ScenarioControl[];
}

export interface ScenarioControl {
  control?: UntypedFormControl;
  label?: string;
  programmingName?: string;
}

export interface IScenarioMap {
  programmingName: string;
  service: any;
  control: FormControl;
  inputItem: InputItem;
  itemLabel: string;
}

export interface IScenarioDetails extends IScenarioMap {
  path: string[];
  option: "EventQ" | "SpeciesForestSet" | "SpeciesAgricultureSet" | "General";
  addToAllScenarios: boolean;
}

export interface ISortedScenarioArrayItem {
  isExpanded: boolean;
  label: string;
  subItems: ISortedScenarioArrayItem[];
}
// export interface PlotDigestTreeViewItem {
//   isExpanded?: boolean;
//   isSearchMatch?: boolean;
//   hasChildNodes?: boolean;
//   nodeType: string;
//   id: string | number;
//   name: string;
//   description?: string;
//   value?: number;
//   subItems?: PlotDigestTreeViewItem[];
//   elements?: PlotDigestTreeViewElement[];
// }

// export interface PlotDigestTreeViewElement {
//   id: string | number;
//   //programmingName: string;
//   name: string;
//   value?: number;
// }

export interface ExplorerTreeViewItem {
  isExpanded?: boolean;
  isSearchMatch?: boolean;
  hasChildNodes?: boolean;
  nodeType?: string;
  id: string | number;
  name: string;
  description?: string;
  value?: number;
  subItems?: ExplorerTreeViewItem[];
  elements?: ExplorerTreeViewElement[];
}

export interface ExplorerTreeViewElement {
  id: string | number;
  //programmingName: string;
  name: string;
  value?: number;
}

export interface AddToScenarioPayload {
  service: any;
  input: ItemInput;
  item: FormItemElement;
  addToAllScenarios?: boolean;
  itemValue?: any;
}

export interface ClonePlotFilesResponse {
  clonedPlotFiles: ClonedPlotFile[];
}
export interface ClonedPlotFile {
  inputFields: ClonedPlotFileInputField[];
  plotId: number[];
}

export interface ClonedPlotFileInputField {
  xPath: string;
  attribute: string;
  value: any;
  inputType: string;
}

export interface Legend {
  color: string;
  label: string;
}

export interface EstatePlot {
  id: string;
  validationStatus: string;
  estateTimingEP: boolean;
  startYrEP: string;
  startStepEP: string;
  hasAreaEP: boolean;
  areaEP: string;
  plotAreaEP: string;
  plotIxEP: number;
  plotId: string;
  fileName: string;
  simulateEP: boolean;
}
interface PlotFileValidationResultSiteAreaData {
  hasArea: boolean;
  siteArea: number;
}
export interface PlotFileValidationResults {
  plotId: string;
  fileName: string;
  fileType: string;
  siteAreaData: PlotFileValidationResultSiteAreaData;
  status: "Valid" | "InValid";
}

export interface LogEntry {
  classLE: string;
  levelLE: string;
  tMsgLE: string;
  timeStampLE: string;
  givenArgs: [string, string, string];
}

export interface LogField {
  logType: string;
  getLogArguments: (
    oldValue: any,
    newValue: any,
    model: FormFieldModel,
    service: any
  ) => string[];
}
