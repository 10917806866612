import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";

@Component({
  selector: "fc-form-error-messages",
  templateUrl: "./form-error-messages.component.html",
  styleUrls: ["./form-error-messages.component.scss"],
})
export class FormErrorMessagesComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  @Input() public formControlInstance: UntypedFormControl;
  @Input() public label: string;
  @Input() public customErrorMessages: { [key: string]: string };

  public containerHeight: string = "0";

  constructor(private hostElement: ElementRef) {}

  ngOnInit(): void {
    // this.containerHeight = this.getHeight();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.containerHeight = this.getHeight();
    });

    this.formControlInstance.valueChanges
      .pipe(takeUntil(this.destroy$), delay(100))
      .subscribe(() => {
        this.containerHeight = this.getHeight();
      });
  }

  public getHeight(): string {
    const messageElement =
      this.hostElement.nativeElement.querySelector(".invalid-feedback");
    const padding = 4;
    return messageElement ? messageElement.clientHeight + padding + "px" : "0";
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
