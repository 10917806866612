<div class="title">
  <button
    aria-label="back button"
    type="button"
    class="btn close-button"
    (click)="flyOverPanelService.closePanel()"
  >
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>

  <h2>Decay rate:</h2>
  {{ title }}
</div>

<div class="main-container">
  <form [formGroup]="formGroup">
    <fieldset>
      <legend class="d-none">Inputs</legend>

      <div class="accordion-header is-expanded">
        <h3>Input variable</h3>
        <div class="button-group">
          <button type="button" class="btn" aria-label="help button">
            <i class="bi bi-question-circle"></i>
          </button>
        </div>
      </div>
      <div class="accordion-body">
        <ul>
          <li class="list-item">
            <span class="label col-form-label" f> Category </span>
            <span>{{ inputVariables?.category }}</span>
          </li>
          <li class="list-item">
            <span class="label col-form-label" f> Name </span>
            <span>{{ title }}</span>
          </li>
          <li class="list-item">
            <span class="label col-form-label" f> Species </span>
            <span>{{ inputVariables?.species }}</span>
          </li>

          <li class="list-item">
            <span class="label col-form-label" f> Event </span>
            <span *ngIf="inputVariables?.event !== 10">{{
              inputVariables?.event
            }}</span>
          </li>
          <li class="list-item">
            <span class="label col-form-label" f> Unit name </span>
            <span [innerHtml]="inputVariables?.unit"></span>
          </li>
        </ul>
      </div>
    </fieldset>
  </form>
  <div class="main-content" [formGroup]="formGroup">
    <div class="form-controls">
      <div class="form-control-header">
        <div class="header-group">
          <div class="header">
            <h3>Rate at which material leaves the pool</h3>
            <button type="button" class="btn" aria-label="help button">
              <i class="bi bi-question-circle"></i>
            </button>
          </div>

          <div class="button-group">
            <button class="btn btn-primary" (click)="save()">
              {{isViewOnly?'Close' : 'Save and close'}}
            </button>
          </div>
        </div>
      </div>
      <div class="control-groups">
        <div class="control-group">
          <h4>Percentage lost after a:</h4>
          <ul>
            <li class="list-item">
              <div class="list-input-group">
                <label
                  class="label col-form-label"
                  for="ts-data-points-per-year-lost"
                >
                  Year
                </label>
                <input
                  autocomplete="off"
                  id="ts-data-points-per-year-lost"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="decayYr"
                  (keyup.enter)="calculate('decayYr')"
                  (blur)="calculate('decayYr', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('decayYr')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('decayYr')"
              >
              </fc-form-error-messages>
            </li>
            <li class="list-item">
              <div class="list-input-group">
                <label class="label col-form-label" for="ts-month-lost">
                  Month
                </label>
                <input
                  autocomplete="off"
                  id="ts-month-lost"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="decayMo"
                  (keyup.enter)="calculate('decayMo')"
                  (blur)="calculate('decayMo', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('decayMo')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('decayMo')"
              >
              </fc-form-error-messages>
            </li>

            <li class="list-item">
              <div class="list-input-group">
                <label class="label col-form-label" for="ts-day-lost">
                  Day
                </label>
                <input
                  autocomplete="off"
                  id="ts-day-lost"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="decayDay"
                  (keyup.enter)="calculate('decayDay')"
                  (blur)="calculate('decayDay', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('decayDay')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('decayDay')"
              >
              </fc-form-error-messages>
            </li>
          </ul>
        </div>

        <div class="control-group">
          <h4>Percentage remaining after a:</h4>
          <ul>
            <li class="list-item">
              <div class="list-input-group">
                <label
                  class="label col-form-label"
                  for="ts-data-points-per-year-pecentage"
                >
                  Year
                </label>
                <input
                  autocomplete="off"
                  id="ts-data-points-per-year-pecentage"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="remainYr"
                  (keyup.enter)="calculate('remainYr')"
                  (blur)="calculate('remainYr', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('remainYr')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('remainYr')"
              >
              </fc-form-error-messages>
            </li>
            <li class="list-item">
              <div class="list-input-group">
                <label class="label col-form-label" for="ts-month-pecentage">
                  Month
                </label>
                <input
                  autocomplete="off"
                  id="ts-month-pecentage"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="remainMo"
                  (keyup.enter)="calculate('remainMo')"
                  (blur)="calculate('remainMo', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('remainMo')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('remainMo')"
              >
              </fc-form-error-messages>
            </li>

            <li class="list-item">
              <div class="list-input-group">
                <label class="label col-form-label" for="ts-day-pecentage">
                  Day
                </label>
                <input
                  autocomplete="off"
                  id="ts-day-pecentage"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="remainDay"
                  (keyup.enter)="calculate('remainDay')"
                  (blur)="calculate('remainDay', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('remainDay')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('remainDay')"
              >
              </fc-form-error-messages>
            </li>
          </ul>
        </div>
        <div class="control-group">
          <h4>Half life of decay, in:</h4>
          <ul>
            <li class="list-item">
              <div class="list-input-group">
                <label
                  class="label col-form-label"
                  for="ts-data-points-per-year-half-life"
                >
                  Year
                </label>
                <input
                  autocomplete="off"
                  id="ts-data-points-per-year-half-life"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="halfYr"
                  (keyup.enter)="calculate('halfYr')"
                  (blur)="calculate('halfYr', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('halfYr')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('halfYr')"
              >
              </fc-form-error-messages>
            </li>
            <li class="list-item">
              <div class="list-input-group">
                <label class="label col-form-label" for="ts-month-half-life">
                  Month
                </label>
                <input
                  autocomplete="off"
                  id="ts-month-half-life"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="halfMo"
                  (keyup.enter)="calculate('halfMo')"
                  (blur)="calculate('halfMo', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('halfMo')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('halfMo')"
              >
              </fc-form-error-messages>
            </li>

            <li class="list-item">
              <div class="list-input-group">
                <label class="label col-form-label" for="ts-day-half-life">
                  Day
                </label>
                <input
                  autocomplete="off"
                  id="ts-day-half-life"
                  class="form-control form-control-sm"
                  type="number"
                  formControlName="halfDay"
                  (keyup.enter)="calculate('halfDay')"
                  (blur)="calculate('halfDay', true)"
                />
              </div>
              <fc-form-error-messages
                *ngIf="
                  formGroup.get('halfDay')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('halfDay')"
              >
              </fc-form-error-messages>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <fc-chart [chartOption]="chartOption" class="chart"></fc-chart>
  </div>
</div>
