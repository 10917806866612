import { Injectable } from "@angular/core";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { take } from "rxjs/operators";
import {
  CollectionTableColumns,
  FolderList,
  ProjectTableColumns,
} from "src/app/my-plots/models";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { FormModel } from "src/app/shared/models";
import { FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { PlotFlow } from "src/app/core/models";

@Injectable({
  providedIn: "root",
})
export class About2020Service extends BasePlotFormService {
  public readonly pageId = "about";
  public readonly pageHelpLink = "11_About.htm";

  public readonly categoryIds = [1];

  public modifiers = [];

  public layout: FormLayout = {
    label: "About",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: "",
                labelMethod: "getFileNameLabel",
                element: "input",
                type: "text",
                programmingName: "nmME",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Project",
                element: "component",
                programmingName: "project",
                component: "FolderSelectComponent",
                componentInputs: [
                  {
                    inputKey: "selectedProjectName",
                    isObservable: true,
                    formKey: "project",
                  },
                  {
                    inputKey: "selectedCollectionName",
                    isObservable: true,
                    formKey: "collection",
                  },
                  {
                    inputKey: "folders$",
                    method: "getFolders",
                  },
                  {
                    inputKey: "type",
                    value: "project",
                  },
                  {
                    inputKey: "isLocked",
                    method: "isEditPlot",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "onRefreshFolders",
                    method: "loadFolders",
                  },
                ],
              },
            ],
          },

          {
            label: null,
            inputs: [
              {
                label: "Collection",
                element: "component",
                programmingName: "collection",
                component: "FolderSelectComponent",
                componentInputs: [
                  {
                    inputKey: "selectedProjectName",
                    isObservable: true,
                    formKey: "project",
                  },
                  {
                    inputKey: "selectedCollectionName",
                    isObservable: true,
                    formKey: "collection",
                  },
                  {
                    inputKey: "folders$",
                    method: "getFolders",
                  },
                  {
                    inputKey: "type",
                    value: "collection",
                  },
                  {
                    inputKey: "isLocked",
                    method: "isEditPlot",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "onRefreshFolders",
                    method: "loadFolders",
                  },
                ],
              },
            ],
          },

          {
            label: null,
            inputs: [
              {
                label: "Note",
                element: "textarea",
                placeholder: "Description here...",
                programmingName: "notesME",
              },
            ],
          },
        ],
      },
    ],
  };

  public folders$ = new BehaviorSubject<FolderList[]>([]);

  public formModel: FormModel = {
    nmME: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 11,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      label: "Name",
      unit: "",
      categoryName: "MetaIn",
      categoryLabel: "About",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 796,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    savedByResearch: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 11,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "Document was saved by research is removed for R21-18",
      isShown: true,
      isExplorer: true,
      suffixId: null,
    },
    savedByVersion: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 11,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Saved by Fullcam version",
      unit: "",
      categoryName: "MetaIn",
      categoryLabel: "About",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2202,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    notesME: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 11,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "maxLength",
          input: 1000,
        },
      ],
      label: "Notes",
      unit: "",
      categoryName: "MetaIn",
      categoryLabel: "About",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 797,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    lockTime: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 11,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Time that document was locked",
      unit: "",
      categoryName: "MetaIn",
      categoryLabel: "About",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1072,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    lockId: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 11,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Security id of locked document",
      unit: "",
      categoryName: "MetaIn",
      categoryLabel: "About",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1123,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    lockOnME: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 11,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Document locked",
      unit: "",
      categoryName: "MetaIn",
      categoryLabel: "About",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1012,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    project: {
      label: "Project",
      defaultValue: "",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      isShown: true,
      isExplorer: false,
      suffixId: null,
    },
    collection: {
      label: "Collection",
      defaultValue: "",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      isShown: true,
      isExplorer: false,
      suffixId: null,
    },
  };

  id = null;
  constructor(public myPlotsService: MyPlotsService) {
    super();
  }

  public isEditPlot(): boolean {
    return this.simulationService.isEditPlot();
  }

  public setFolders(folders: FolderList[]): void {
    this.folders$.next(folders);
  }
  public getFolders(): Observable<FolderList[]> {
    return this.folders$.asObservable();
  }

  public async loadFolders(): Promise<void> {
    const folders = await lastValueFrom(
      this.myPlotsService.getList("folder").pipe(take(1))
    );

    this.folders$.next(folders);
  }

  public getSelectedProject(): ProjectTableColumns {
    const projectName = this.formGroup.get("project").value;
    const projects = this.folders$.getValue();
    if (!projectName) {
      return null;
    }
    if (!projects.length) {
      return null;
    }
    return projects.find((p) => p.name == projectName);
  }

  public getSelectedCollection(): CollectionTableColumns {
    const collectionName = this.formGroup.get("collection").value;
    const selectedProject = this.getSelectedProject();
    if (!selectedProject || !selectedProject.collectionInfo) {
      return null;
    }

    return selectedProject.collectionInfo.find((c) => c.name == collectionName);
  }

  public getFileNameLabel() {
    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();
    if (plotFormat == "plo") {
      return "Name of plot";
    } else if (plotFormat == "pld") {
      return "Name of plot digest";
    } else if (plotFormat == "est") {
      return "Name of estate";
    }
  }
  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    return { xPath: "//Meta", id: null };
  }

  public setProjectCollection(project: string, collection: string): void {
    const selectedFlow: PlotFlow = this.simulationService.getSelectedFlow();
    const projectControl = this.getFormGroup().get("project");
    const collectionControl = this.getFormGroup().get("collection");
    projectControl.setValue(project);
    collectionControl.setValue(collection);

    if (selectedFlow?.id == "about") {
      this.simulationService.currentFlows$
        .pipe(take(1))
        .subscribe((allFlows) => {
          const aboutPage = allFlows.find((f) => f.id == "about");
          this.simulationService.setSelectedFlow(aboutPage);
        });
    }
  }

  public setGenerateEstateFileName(fileName): void {
    this.getFormGroup().get("nmME").setValue(fileName);
  }

  public setGeneratedEstateLog(log) {
    this.getFormGroup().get("notesME").setValue(log);
  }

  public readXmlObject(): void {
    this.getFormGroup();
    if (!this.simulationService.getPlotFileJson()) {
      return;
    }

    const template = this.simulationService.getPlotFileJson()["Meta"][0];
    let { project, collection, ...meta } = template.$;
    const fileName = this.simulationService.plotMetaData?.fileName;

    if (template.notesME) {
      this.formGroup.get("notesME").setValue(template.notesME || "");
    }
    //fill the plot name with file name, otherwise after updating the file, new file will be created in blob storage, that wil lead to wrong validation in ESTATE pages
    if (!meta.nmME && fileName) {
      meta.nmME = fileName.replace(/\.[^/.]+$/, "");
    }

    this.applyXmlValuesToFormGroup(meta, this.formGroup);
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();
    const { notesME, project, collection, ...formValues } = formData;
    let meta = this.getFormValueObjectForXmlExport(null, formValues);

    return { Meta: [{ $: meta, notesME: notesME || "" }] };
  }

  public reset() {
    this.folders$.next([]);
    super.reset();
  }
}
