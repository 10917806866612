<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>Add Event</h1>

    <div class="event-data-picker-container">
      <label for="event-date-input" class="form-label"
        >Select your event date</label
      >
      <span class="small"
        >Valid event dates are between {{ minDate | date : "dd/MM/yyyy" }} and
        {{ maxDate | date : "dd/MM/yyyy" }}
      </span>
      <input
        id="event-date-input"
        type="text"
        [(ngModel)]="eventDate"
        [dateCustomClasses]="['date-picker']"
        placeholder="DD/MM/YYYY"
        class="form-control form-control-sm"
        autocomplete="off"
        [minDate]="minDate"
        [maxDate]="maxDate"
        fcBeginningDay
        bsDatepicker
      />
    </div>
    <ngx-datatable
      #rotationTable
      class="custom-table"
      [rows]="sortedEvents"
      columnMode="force"
      [headerHeight]="56"
      [footerHeight]="56"
      rowHeight="56"
      [selected]="selectedRows"
      selectionType="radio"
      (select)="onSelect($event)"
      [limit]="10"
      (sort)="applySortFilters($event)"
      [sorts]="sort"
    >
      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <label class="check-container" [ngClass]="{ selected: isSelected }">
            <span class="checkmark"></span>
          </label>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Group">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.Group }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Name">
        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.Name }}
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="form-button-group">
      <button
        type="button"
        (click)="closeForm(null)"
        class="btn btn-outline-primary form-control-sm"
      >
        Finished
      </button>
      <button
        type="button "
        (click)="addEvent()"
        class="btn btn-outline-primary form-control-sm"
        [disabled]="!selectedRows.length || !eventDate"
      >
        Add Event
      </button>
    </div>
  </div>
</div>
