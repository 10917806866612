import { Injectable, ViewContainerRef } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FieldWatcher, FormModel } from "src/app/shared/models";
import { Constants } from "src/app/shared/constants";

const destinationPercentagesGroups: FormGroupElement[] = [
  {
    id: "gbf-to",
    label: "GBF to",
    isShown: true,
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - GBF to",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "GBF to"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGbfrToFualHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGbfrToGbfpHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Hay, straw, silage",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGbfrToHssiHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGbfrToGlitHarv",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Total",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracGbfrToFualHarv",
                          "fracGbfrToGbfpHarv",
                          "fracGbfrToHssiHarv",
                          "fracGbfrToGlitHarv",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "stalks-to",
    label: "Stalks to",
    isShown: true,
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - Stalks to",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Stalks to"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStlkToFualHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Cane products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStlkToCaneHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Hay, straw, silage",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStlkToHssiHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStlkToSlitHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Total",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracStlkToFualHarv",
                          "fracStlkToCaneHarv",
                          "fracStlkToHssiHarv",
                          "fracStlkToSlitHarv",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "leaves-to",
    label: "Leaves to",
    isShown: true,
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - Leaves to",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Leaves to"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToFualHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLfprHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Hay, straw, silage",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToHssiHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Total",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracLeafToFualHarv",
                          "fracLeafToLfprHarv",
                          "fracLeafToHssiHarv",
                          "fracLeafToLlitHarv",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "coarse-roots-to",
    label: "Coarse roots to",
    isShown: true,
    isUnderlineHeader: true,
    isUnderlineItems: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - Coarse roots to",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Coarse roots to"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToFualHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Root products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToRtprHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Hay, straw, silage",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToRtprHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Total",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracCortToFualHarv",
                          "fracCortToRtprHarv",
                          "fracCortToHssiHarv",
                          "fracCortToCodrHarv",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "fine-roots-to",
    label: "Fine roots to",
    isShown: true,
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - Fine roots to",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Fine roots to"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrHarv",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "biofuel",
    label: "Biofuel",
    isShown: true,
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - Biofuel",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Biofuel"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter to",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGlitToFualHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter to",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSlitToFualHarv",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter to",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLlitToFualHarv",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class CropHarvest2020Service extends BaseEventFormService {
  public readonly categoryIds = [75, 71];
  public readonly pageHelpLink = "153_Harvest.htm";
  public componentHost: ViewContainerRef = null;
  public isDynamicComponentLoaded = false;

  public layout: FormLayout = {
    label: "Harvest",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Percentage of agricultural system affected by harvesting",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctHarv",
              },
            ],
          },

          {
            label: "Clearing with partial Harvest",
            id: "clearOverride",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "clearOverride",
              },
            ],
          },
        ],
      },

      {
        label: "Destination percentages in the affected portion",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        items: [
          {
            groups: [...destinationPercentagesGroups],
          },
        ],
      },
      {
        label: "Plant removal and replacement",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        items: [
          {
            label: "Plants (stalks) removed by this harvest",
            inputs: [
              {
                element: "text",
                method: "getStalksRemovedByThisHarvest",
              },
            ],
          },

          {
            id: "remvAvgAgeMultHarv",
            label: null,
            inputs: [
              {
                programmingName: "remvAvgAgeMultHarv", //only for isShown logic
                element: "component",
                component: "FormulaDisplayComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      lhs: "Average age of removed trees",
                      rhs: [
                        [
                          { element: "icon", type: "operator", value: "=" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvAvgAgeMultHarv",
                          },

                          { element: "icon", type: "operator", value: "x" },
                          { element: "text", value: "Average age of plants" },
                        ],

                        [
                          { element: "icon", type: "operator", value: "+" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvMaxAgeMultHarv",
                          },

                          { element: "icon", type: "operator", value: "x" },
                          {
                            element: "text",
                            value: "Age of the oldest plants",
                          },
                        ],

                        [
                          { element: "icon", type: "operator", value: "+" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvOffsetHarv",
                          },

                          { element: "text", value: "[yr]" },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },

          {
            id: "replaceStlksHarv",
            label: "Replace removed plants with new plants (of age 0)",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "remvAvgAgeMultHarv",
              },
            ],
          },
        ],
      },
      // {
      //   label: "Post-Thin period",
      //   id: "post-thin-period",
      //   isShown: true,
      //   isAccordion: true,
      //   isRoot: true,
      //   items: [{ groups: postThinperiodGroups }],
      // },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecA",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    clearOverride: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      unit: "",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      suffixId: null,
    },
    fracAfctHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural system affected by harvesting",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 665,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGbfrToGbfpHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit to products",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 666,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracGbfrToHssiHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit to hay-straw-silage",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 667,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracGbfrToGlitHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit to litter",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 668,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracStlkToCaneHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks to canes",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 669,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracStlkToHssiHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks to hay-straw-silage",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 670,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracStlkToSlitHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks to litter",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 671,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracLeafToLfprHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to products",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 672,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracLeafToHssiHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to hay-straw-silage",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 673,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracLeafToLlitHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to litter",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 674,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracCortToRtprHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to products",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 675,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracCortToHssiHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to hay-straw-silage",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 676,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracCortToCodrHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 677,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracFirtToFidrHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 678,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    volFracGbfrNMHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in (grains-buds-fruit to products) to atmosphere",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 679,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    volFracStlkNMHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in (stalks to products) to atmosphere",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 680,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    volFracLeafNMHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in (leaves to products) to atmosphere",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 681,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    volFracCortNMHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in (coarse roots to products) to atmosphere",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 682,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracCortToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 793,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracGbfrToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 811,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracStlkToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 812,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracLeafToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 813,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracGlitToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit litter to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 817,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracSlitToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalk litter to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 818,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    fracLlitToFualHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 819,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 127,
    },
    replaceStlksHarv: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Replace harvested plants (stalks) with new plants (of age 0) (yes/no)",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 3,
      tIn: 994,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    remvAvgAgeMultHarv: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Multiplier of average age",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 998,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 107,
    },
    remvMaxAgeMultHarv: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Multiplier of age of oldest plants",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 999,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 107,
    },
    remvOffsetHarv: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Added constant",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 1000,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 107,
    },
    useCropGrades: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "User's choice of whether use crop grades to specify the harvest (yes/no)",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 3,
      tIn: 1647,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    clearAllRemainsA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Clear all remaining crop mass to debris (yes/no)",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 3,
      tIn: 1648,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cropNmHarv: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of crop species expected to be harvested",
      unit: "",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 120,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 1649,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGradeHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest that is of grade 1",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 1650,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGradeHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest that is of grade 2",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 1651,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGradeHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest that is of grade 3",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 1652,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGradeHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 140,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest that is of grade 4",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 1653,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fixHarv: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest event",
      unit: "$",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2056,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaHarv: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest event",
      unit: "$/ha",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2057,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGbfrToGlitExtraA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to gbf litter",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2099,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 174,
    },
    fracStlkToSlitExtraA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to stalk litter",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2100,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 174,
    },
    fracLeafToLlitExtraA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to leaf litter",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2101,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 174,
    },
    fracCortToCodrExtraA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead coarse roots",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2102,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 174,
    },
    fracFirtToFidrExtraA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 153,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead fine roots",
      unit: "%",
      categoryName: "EvHarv",
      categoryLabel: "Crop harvest event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 3,
      tIn: 2103,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 174,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isFixedExplorerField: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "Harv",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userThisHarvByCG: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
  };
  public getStalksRemovedByThisHarvest(): string {
    const fb = this.getFormGroup();
    const affectedPortion = +fb.get("fracAfctHarv").value;
    if (isNaN(affectedPortion.valueOf())) {
      return "";
    }
    let totalStalkValue = 0;

    [
      "fracStlkToFualHarv",
      "fracStlkToCaneHarv",
      "fracStlkToHssiHarv",
      "fracStlkToSlitHarv",
    ].forEach((stalkValue) => {
      const value = +fb.get(stalkValue).value;
      if (value == null || value == undefined) {
        return "";
      }
      totalStalkValue += value;
    });

    return ((totalStalkValue * affectedPortion) / 100).toFixed(1) + "%";
  }

  public setComponentHost(comp: ViewContainerRef) {
    this.componentHost = comp;
  }

  public async openCropGradesForm(): Promise<void> {
    // const cropGradesComp = await import(
    //   "./log-grades-form/log-grades-form.component"
    // ).then(async (c) => c.CropGradesFormComponent);
    // const inputs = [
    //   {
    //     key: "service",
    //     value: this.simulationService.treesService,
    //   },
    //   {
    //     key: "forestThinningService",
    //     value: this,
    //   },
    // ];
    // const comp = this.simulationService.helperService.dynamicLoadComponent(
    //   this.componentHost,
    //   cropGradesComp,
    //   inputs
    // );
    // this.isDynamicComponentLoaded = true;
  }

  public fieldWatcher: FieldWatcher = {
    fracAfctHarv: (newValue: any, { formGroup, formModel }) => {
      const isHundred = +newValue == 100;
      const isLessThanHundred = +newValue < 100;
      //formGroup.get("clearEV").setValue(isHundred);

      formModel["replaceStlksHarv"].isShown = !isHundred;
      formModel["remvAvgAgeMultHarv"].isShown = !isHundred;

      formModel["clearOverride"].isShown = isLessThanHundred;

      this[
        isLessThanHundred
          ? "addDefaultValidatorsInBulk"
          : "removeDefaultValidatorsInBulk"
      ](
        ["remvAvgAgeMultHarv", "remvMaxAgeMultHarv", "remvOffsetHarv"],
        formGroup,
        formModel
      );
    },
    // fracStlkToFualHarv: (newValue: any, { formGroup, formModel }) => {
    //   this.calcuateStalksRemovedByHarvest(formGroup)
    // },
    // fracStlkToCaneHarv: (newValue: any, { formGroup, formModel }) => {
    //   this.calcuateStalksRemovedByHarvest(formGroup)
    // },
    // fracStlkToHssiHarv: (newValue: any, { formGroup, formModel }) => {
    //   this.calcuateStalksRemovedByHarvest(formGroup)
    // },
    // fracStlkToSlitHarv: (newValue: any, { formGroup, formModel }) => {
    //   this.calcuateStalksRemovedByHarvest(formGroup)
    // }
  };

  private calcuateStalksRemovedByHarvest(formGroup) {
    // const affectedPortion=formGroup.get('fracAfctHarv').value;
    // const totalStalkValue=
  }

  private isClearingHarvDest(formValue) {
    const k1Minus = Constants.k1Minus * 100;

    return (
      +formValue.fracGbfrToFualHarv +
        +formValue.fracGbfrToGbfpHarv +
        +formValue.fracGbfrToHssiHarv +
        +formValue.fracGbfrToGlitHarv >=
        k1Minus &&
      +formValue.fracStlkToFualHarv +
        +formValue.fracStlkToCaneHarv +
        +formValue.fracStlkToHssiHarv +
        +formValue.fracStlkToSlitHarv >=
        k1Minus &&
      +formValue.fracLeafToFualHarv +
        +formValue.fracLeafToLfprHarv +
        +formValue.fracLeafToHssiHarv +
        +formValue.fracLeafToLlitHarv >=
        k1Minus &&
      +formValue.fracCortToFualHarv +
        +formValue.fracCortToRtprHarv +
        +formValue.fracCortToHssiHarv +
        +formValue.fracCortToCodrHarv >=
        k1Minus &&
      +formValue.fracFirtToFidrHarv >= k1Minus
    );
  }

  public modifyBeforeSave(formGroup) {
    if (!formGroup) {
      return null;
    }

    let modifiedFormGroup = formGroup;
    if (modifiedFormGroup.get("clearEV")) {
      modifiedFormGroup
        .get("clearEV")
        .setValue(this.isClearingHarvDest(modifiedFormGroup.getRawValue()));
    }
    return modifiedFormGroup;
  }

  public writeXmlObject(formData): object {
    const baseHarvFields = [
      "fracAfctHarv",
      "userThisHarvByCG",
      "clearAllRemainsA",
      "clearOverride",
      "fracGbfrToGlitExtraA",
      "fracStlkToSlitExtraA",
      "fracLeafToLlitExtraA",
      "fracCortToCodrExtraA",
      "fracFirtToFidrExtraA",
      "replaceStlksHarv",
      "remvAvgAgeMultHarv",
      "remvMaxAgeMultHarv",
      "remvOffsetHarv",
      "volFracGbfrNMHarv",
      "volFracStlkNMHarv",
      "volFracLeafNMHarv",
      "volFracCortNMHarv",
      "fixHarv",
      "phaHarv",
      "cropNmHarv",
    ];

    const harvDestFields = [
      "id",
      "fracGbfrToFualHarv",
      "fracGbfrToGbfpHarv",
      "fracGbfrToHssiHarv",
      "fracGbfrToGlitHarv",
      "fracStlkToFualHarv",
      "fracStlkToCaneHarv",
      "fracStlkToHssiHarv",
      "fracStlkToSlitHarv",
      "fracLeafToFualHarv",
      "fracLeafToLfprHarv",
      "fracLeafToHssiHarv",
      "fracLeafToLlitHarv",
      "fracCortToFualHarv",
      "fracCortToRtprHarv",
      "fracCortToHssiHarv",
      "fracCortToCodrHarv",
      "fracFirtToFidrHarv",
      "fracGlitToFualHarv",
      "fracSlitToFualHarv",
      "fracLlitToFualHarv",
    ];
    const xmlObject = {
      $: {},
      notesEV: [],
      Harv: [{ $: {}, HarvDest: [{ $: {} }], fracGradeHarv: [",,,"] }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.Harv[0].$ = this.getFormValueObjectForXmlExport(
      baseHarvFields,
      formData
    );

    xmlObject.Harv[0].HarvDest[0].$ = {
      id: "dest",
      ...this.getFormValueObjectForXmlExport(harvDestFields, formData),
    };

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
