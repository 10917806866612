import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { PlotReferenceDataService } from "src/app/core/services/plot-reference-data.service";

@Component({
  selector: "fc-location-metadata",
  templateUrl: "./location-metadata.component.html",
  styleUrls: ["./location-metadata.component.scss"],
})
export class LocationMetadataComponent implements OnInit {
  @Input() spatialData$: Observable<any>;
  constructor(private plotReferenceDataService: PlotReferenceDataService) {}

  descriptionMap = {};

  ngOnInit(): void {
    this.descriptionMap = this.plotReferenceDataService.getDescriptionMap();
  }
}
