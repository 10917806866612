import { Injectable } from "@angular/core";
import { FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FieldWatcher, FormModel } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class GrazingChange2020Service extends BaseEventFormService {
  public readonly categoryIds = [80, 71];
  public readonly pageHelpLink = "196_Grazing%20Change.htm";

  public layout: FormLayout = {
    label: "Grazing change",
    groups: [
      {
        label: "New grazing levels",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Grazing on crops",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "cropGrazOn",
              },
            ],
          },

          {
            id: "tGraz",
            label: "",
            inputs: [
              {
                element: "input",
                type: "radioSwitch",
                selectOptions: [
                  {
                    label: "Mass eaten each day by grazers [(dmt/ha)/day]",
                    value: "GrazByM",
                  },
                  {
                    label:
                      "Percentage of NPP eaten each day by grazers [%/day]",
                    value: "GrazByFracNPP",
                  },
                  {
                    label:
                      "Percentage of mass eaten each day by grazers [%/day]",
                    value: "GrazByFrac",
                  },
                ],

                programmingName: "tGraz",
              },
            ],
          },

          {
            label: "Grains, buds, and fruit",
            id: "gbfrMGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "gbfrMGraz",
              },
            ],
          },
          {
            label: "Stalks",
            id: "stlkMGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "stlkMGraz",
              },
            ],
          },
          {
            label: "Leaves",
            id: "leafMGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "leafMGraz",
              },
            ],
          },

          {
            label: "Grains, buds, and fruit",
            id: "fracGbfrNPPGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracGbfrNPPGraz",
              },
            ],
          },
          {
            label: "Stalks",
            id: "fracStlkNPPGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracStlkNPPGraz",
              },
            ],
          },
          {
            label: "Leaves",
            id: "fracLeafNPPGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracLeafNPPGraz",
              },
            ],
          },

          {
            label: "Grains, buds, and fruit",
            id: "fracGbfrGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracGbfrGraz",
              },
            ],
          },
          {
            label: "Stalks",
            id: "fracStlkGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracStlkGraz",
              },
            ],
          },
          {
            label: "Leaves",
            id: "fracLeafGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracLeafGraz",
              },
            ],
          },

          {
            label: "Grazing on debris",
            id: "debrGrazOn",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "debrGrazOn",
              },
            ],
          },

          {
            label: "Grains, buds, and fruit",
            id: "fracGlitGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracGlitGraz",
              },
            ],
          },
          {
            label: "Stalks",
            id: "fracSlitGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracSlitGraz",
              },
            ],
          },
          {
            label: "Leaves",
            id: "fracLlitGraz",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracLlitGraz",
              },
            ],
          },
        ],
      },
      {
        label: "New root slough",
        id: "new-root-slough-group",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "",
            inputs: [
              {
                element: "input",
                type: "radioSwitch",
                selectOptions: [
                  {
                    label: "No root slough",
                    value: "SlouOff",
                  },
                  {
                    label:
                      "Slough roots in proportion to aboveground mass grazed",
                    value: "SlouOn",
                  },
                ],

                programmingName: "tRootSlouGraz",
              },
            ],
          },
          {
            id: "maxRootSlouFrac",
            label: "Maximum percentage of roots sloughed off per step",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "maxRootSlouFrac",
              },
            ],
          },
        ],
      },

      {
        label: "Destination percentages of the new fodder",
        id: "destination-percentages-of-the-new-fodder-group",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",

                component: "FormItemTableComponent",
                componentInputs: [
                  {
                    inputKey: "caption",
                    value: "Destination percentages of the new fodder",
                  },
                  {
                    inputKey: "formGroupInstance",
                    method: "getFormGroup",
                  },
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      headers: ["", "Carbon"],
                      rows: [
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Atmosphere",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracFoddCMToAtmsGraz",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "DPM",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracFoddCMToDpmaGraz",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "RPM",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracFoddCMToRpmaGraz",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Animal products",
                          },
                          {
                            element: "text",
                            method: "getRemaining",
                            payload: [
                              "fracFoddCMToAtmsGraz",
                              "fracFoddCMToDpmaGraz",
                              "fracFoddCMToRpmaGraz",
                            ],
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecA",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    fracFoddNMToSNtrGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to shallow nitrate",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 127,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddNMToDAmmGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to deep ammonium",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 128,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddNMToDNtrGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to deep nitrate",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 129,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tGraz: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 196,
      defaultValue: "GrazByM",
      validators: [],
      validatorConfig: [],
      label: "Type of grazing regime",
      unit: "",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "GrazT",
      eventId: 8,
      tIn: 774,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tRootSlouGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 196,
      defaultValue: "SlouOff",
      validators: [],
      validatorConfig: [],
      label: "Root sloughing (on/off)",
      unit: "",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RootSlouT",
      eventId: 8,
      tIn: 775,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrMGraz: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "grains-buds-fruit",
      unit: "dmt/ha per day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 776,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    stlkMGraz: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "stalks",
      unit: "dmt/ha per day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 777,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    leafMGraz: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "leaves",
      unit: "dmt/ha per day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 778,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracGbfrGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 779,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracStlkGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 780,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracLeafGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 781,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracGbfrNPPGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit NPP",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 782,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracStlkNPPGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalk NPP",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 783,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracLeafNPPGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaf NPP",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 784,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracFoddCMToAtmsGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "to atmosphere",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 785,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddCMToDpmaGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "to DPM",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 786,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddCMToRpmaGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "to RPM",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 787,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddNMToAtmsGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to atmosphere (via manure and urine)",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 789,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddNMToDpmaGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to DPM (via manure)",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 790,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddNMToRpmaGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to RPM (via manure)",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 791,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracFoddNMToSAmmGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen to shallow ammonium",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 112,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 792,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    maxRootSlouFrac: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Maximum % of roots sloughed off in grazing",
      unit: "%",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 971,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fixGraz: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grazing change",
      unit: "$",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 2066,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaGraz: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grazing change",
      unit: "$/ha",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 2067,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGlitGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit litter",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 2088,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracSlitGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalk litter",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 2089,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    fracLlitGraz: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaf litter",
      unit: "%/day",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: null,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 8,
      tIn: 2090,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 111,
    },
    cropGrazOn: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Crop grazing on (on/off)",
      unit: "",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 8,
      tIn: 2091,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    debrGrazOn: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Debris grazing on (on/off)",
      unit: "",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 8,
      tIn: 2092,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    grazerType: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 196,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of Grazer",
      unit: "",
      categoryName: "EvGraz",
      categoryLabel: "Grazing change event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 8,
      tIn: 2118,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "Graz",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
  };

  public fieldWatcher: FieldWatcher = {
    tGraz: (newValue: any, { formGroup, formModel }) => {
      this.checkGrazingOnCropsFields(newValue, { formGroup, formModel });
    },
    tRootSlouGraz: (newValue: any, { formGroup, formModel }) => {
      formModel["maxRootSlouFrac"].isShown = newValue == "SlouOn";
    },
    cropGrazOn: (newValue: any, { formGroup, formModel }) => {
      const toggleFields = [
        "gbfrMGraz",
        "stlkMGraz",
        "leafMGraz",
        "fracGbfrNPPGraz",
        "fracStlkNPPGraz",
        "fracLeafNPPGraz",
        "fracGbfrGraz",
        "fracStlkGraz",
        "fracLeafGraz",
        "tGraz",
      ];

      toggleFields.forEach((f) => {
        formModel[f].isShown = newValue;
      });

      this.checkGrazingOnCropsFields(
        newValue ? formGroup.get("tGraz").value : null,
        {
          formGroup,
          formModel,
        }
      );
    },
    debrGrazOn: (newValue: any, { formGroup, formModel }) => {
      const toggleFields = [
        "fracGlitGraz",
        "fracSlitGraz",
        "fracLlitGraz",
        "maxRootSlouFrac",
        "fracFoddCMToAtmsGraz",
        "fracFoddCMToDpmaGraz",
        "fracFoddCMToRpmaGraz",
      ];

      toggleFields.forEach((f) => {
        formModel[f].isShown = newValue;
      });

      this[
        newValue
          ? "addDefaultValidatorsInBulk"
          : "removeDefaultValidatorsInBulk"
      ](toggleFields, formGroup, formModel);

      //toggole group visibilities

      const newRootSloughGroup = this.getLayoutGroup(
        this.layout.groups,
        "new-root-slough-group"
      );

      const destinationPercentagesGroup = this.getLayoutGroup(
        this.layout.groups,
        "destination-percentages-of-the-new-fodder-group"
      );
      newRootSloughGroup.isShown = newValue;
      destinationPercentagesGroup.isShown = newValue;

      formGroup
        .get("tRootSlouGraz")
        .setValue(formGroup.get("tRootSlouGraz").value);
    },
  };

  protected checkGrazingOnCropsFields(newValue: any, { formGroup, formModel }) {
    const isGrazByM = newValue == "GrazByM";
    const isGrazByFracNPP = newValue == "GrazByFracNPP";
    const isGrazByFrac = newValue == "GrazByFrac";

    const grazByMFields = {
      shouldShowFields: isGrazByM,
      fields: ["gbfrMGraz", "stlkMGraz", "leafMGraz"],
    };

    const grazByFracNPPFields = {
      shouldShowFields: isGrazByFracNPP,
      fields: ["fracGbfrNPPGraz", "fracStlkNPPGraz", "fracLeafNPPGraz"],
    };

    const grazByFracFields = {
      shouldShowFields: isGrazByFrac,
      fields: ["fracGbfrGraz", "fracStlkGraz", "fracLeafGraz"],
    };

    [grazByMFields, grazByFracNPPFields, grazByFracFields].forEach((f) => {
      this[
        f.shouldShowFields
          ? "addDefaultValidatorsInBulk"
          : "removeDefaultValidatorsInBulk"
      ](f.fields, formGroup, formModel);

      f.fields.forEach((gField: string) => {
        formModel[gField].isShown = f.shouldShowFields;
      });
    });
  }

  public writeXmlObject(formData): object {
    const baseGrazFields = [
      "cropGrazOn",
      "debrGrazOn",
      "grazerType",
      "tGraz",
      "tRootSlouGraz",
      "maxRootSlouFrac",
      "gbfrMGraz",
      "stlkMGraz",
      "leafMGraz",
      "fracGbfrGraz",
      "fracStlkGraz",
      "fracLeafGraz",
      "fracGbfrNPPGraz",
      "fracStlkNPPGraz",
      "fracLeafNPPGraz",
      "fracGlitGraz",
      "fracSlitGraz",
      "fracLlitGraz",
      "fracFoddCMToAtmsGraz",
      "fracFoddCMToDpmaGraz",
      "fracFoddCMToRpmaGraz",
      "fracFoddNMToAtmsGraz",
      "fracFoddNMToDpmaGraz",
      "fracFoddNMToRpmaGraz",
      "fracFoddNMToSAmmGraz",
      "fracFoddNMToSNtrGraz",
      "fracFoddNMToDAmmGraz",
      "fracFoddNMToDNtrGraz",
      "fixGraz",
      "phaGraz",
    ];

    const xmlObject = {
      $: {},
      notesEV: [],
      Graz: [{ $: {} }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.Graz[0].$ = this.getFormValueObjectForXmlExport(
      baseGrazFields,
      formData
    );

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
