import {
  HostBinding,
  Directive,
  OnInit,
  ElementRef,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[fcSticky]",
})
export class StickyDirective implements OnInit {
  @HostBinding("class.fixed-div") isFixed: boolean = false;
  private elementOffsetTop: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.elementOffsetTop = this.el.nativeElement.offsetTop;
    this.renderer.listen("window", "scroll", this.handleScroll.bind(this));
  }

  private handleScroll(): void {
    if (window.pageYOffset > this.elementOffsetTop) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }
}
