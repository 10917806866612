<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>Clone Scenario</h1>
    <form [formGroup]="formGroup">
      <label for="scenario-name-input" class="form-label"
        >Cloning Scenario</label
      >
      <input
        id="scenario-name-input"
        class="form-control form-control-sm"
        form-field
        autocomplete="off"
        [value]="scenarioName"
        disabled
      />

      <label for="scenario-notes-input" class="form-label"
        >Number of clones</label
      >
      <input
        id="scenario-clone-input"
        class="form-control form-control-sm"
        form-field
        autocomplete="off"
        formControlName="numberOfClones"
        type="number"
      />

      <div class="form-button-group">
        <button
          type="button"
          (click)="closeForm(null)"
          class="btn btn-outline-primary"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="closeForm(formGroup.getRawValue())"
          class="btn btn-outline-primary"
          [disabled]="formGroup.pristine || formGroup.invalid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
