import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";

import { AuthService } from "src/app/auth/services/auth.service";
import { AppService } from "src/app/services/app.service";
import { environment } from "src/environments/environment";
import { ModalOptions } from "ngx-bootstrap/modal";
import { AboutFullcamComponent } from "src/app/shared/components/about-fullcam/about-fullcam.component";
import { ModalService } from "src/app/shared/services/modal.service";
import { AppInsightsService } from "src/app/shared/services/app-insights.service";
import { Router } from "@angular/router";
@Component({
  selector: "[fc-header]",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  public readonly baseHelpPageUrl = environment.fullcam2020HelpUrl;
  //
  @Input() userClaim: any;
  @Input() isPrivacyAccepted: boolean = true;
  @Input() loginFirstTime: boolean = false;

  public isTestEnv = !environment.production;

  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: ModalService,
    private appInsights: AppInsightsService,
    public appService: AppService
  ) {}

  ngOnInit(): void {}

  public openAboutFullcamModel() {
    const currenntPageTitle = this.appService.getPageTitle();
    const pageTitle = this.appService.getPageTitlePrefix() + "About FullCAM";

    const initialState: ModalOptions = {
      class: "centered-modal",
      initialState: {},
    };

    this.modalService
      .openModal(AboutFullcamComponent, { ...initialState })
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          // This function will be called when the observable emits a value
          const currentUrl = this.router.url;
          this.appService.setPageTitle(currenntPageTitle);
          this.appInsights.logPageView({
            name: currenntPageTitle,
            url: currentUrl,
          });
        },
        error: (error) => {
          console.error(error);
        },
      });

    this.appService.setPageTitle(pageTitle);
    this.appInsights.logPageView({
      name: pageTitle,
      url: "/about-fullcam",
    });
  }

  public signIn(): void {
    this.authService.signIn();
  }

  public signOut(): void {
    sessionStorage.clear();
    this.authService.singOut();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
