<ng-container [formGroup]="formGroupInstance">
  <ng-container formArrayName="digestScenarios">
    <ng-container *ngIf="viewType == 'Category' || viewType == 'Scenario'">
      <ng-container *ngTemplateOutlet="scenarioView"></ng-container>
    </ng-container>

    <ng-container *ngIf="viewType == 'Input'">
      <ng-container *ngTemplateOutlet="inputView"></ng-container>
    </ng-container>
    <!----- Templates ---->

    <ng-template #scenarioView>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item scenario-list"
          *ngFor="let scenario of digestScenarios.controls; let i = index"
          [class]="{
            disabled: !scenario.get('enabled')?.value,
            'is-invalid': scenario.get('digestElementFormGroup').invalid,
            active: scenario.get('name').value == selectedScenarioName
          }"
        >
          <div class="list-content" [formGroupName]="i">
            <span>
              <button
                *ngIf="viewType == 'Category'"
                class="btn btn-sm"
                (click)="
                  scenario
                    .get('isExpanded')
                    .setValue(!scenario.get('isExpanded').value)
                "
              >
                <span class="visually-hidden">{{
                  scenario.get("isExpanded").value.isExpanded
                    ? "collapse"
                    : "expand"
                }}</span>
                <i
                  class="bi bi-chevron-right"
                  [class]="{
                    expanded: scenario.get('isExpanded').value
                  }"
                >
                </i>
              </button>

              <a
                class="scenario-name"
                href="#"
                (click)="
                  setScenarioItem(
                    scenario.get('name').value,
                    scenario,
                    'root',
                    scenario.get('name').value
                  );
                  $event.preventDefault()
                "
                [class]="{
                  'is-invalid': scenario.invalid
                }"
                >{{ scenario.get("name").value }}</a
              >
            </span>
            <div class="action-buttons">
              <div
                form-field
                class="form-check form-switch"
                [adaptivePosition]="false"
                tooltip="Enabled"
              >
                <input
                  class="form-check-input switch-input"
                  type="checkbox"
                  role="switch"
                  autocomplete="off"
                  formControlName="enabled"
                  aria-label="Enabled"
                />
              </div>
              <button
                class="btn btn-sm btn-rounded"
                [adaptivePosition]="false"
                tooltip="Edit"
                (click)="editScenario(scenario)"
              >
                <span class="visually-hidden">edit</span>
                <i class="bi bi-pencil-fill"></i>
              </button>
              <button
                class="btn btn-sm btn-rounded"
                [adaptivePosition]="false"
                tooltip="Clone"
                (click)="cloneScenario(scenario)"
              >
                <span class="visually-hidden">clone</span>
                <i class="bi bi-files"></i>
              </button>

              <button
                class="btn btn-sm btn-rounded"
                [adaptivePosition]="false"
                tooltip="Delete"
                (click)="deleteScenario(scenario)"
              >
                <span class="visually-hidden">delete</span>
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
          <ul
            class="list-group-flush subitem-list"
            [class]="{
              expanded: scenario.get('isExpanded').value
            }"
          >
            <span *ngIf="!scenario.get('sortedScenarioArray').value.length"
              >No inputs, please add inputs from other pages to proceed</span
            >
            <ng-container
              *ngIf="
                scenario.get('sortedScenarioArray').value.length &&
                viewType == 'Category'
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  subItem;
                  context: {
                    parentPath: scenario.get('name').value,
                    scenario: scenario,
                    subItemArray: scenario.get('sortedScenarioArray').value
                  }
                "
              ></ng-container>
            </ng-container>
          </ul>
        </li>
      </ul>
    </ng-template>
    <!------ inputView ------>
    <ng-template #inputView>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let itemLabel of goupByInputList">
          <a
            href="#"
            (click)="
              setScenarioInput(itemLabel, itemLabel); $event.preventDefault()
            "
            >{{ itemLabel }}</a
          >
        </li>
      </ul>
    </ng-template>
    <!------ inputView end------>

    <ng-template
      #subItem
      let-parentPath="parentPath"
      let-subItemArray="subItemArray"
      let-scenario="scenario"
    >
      <li
        class="list-group-item"
        *ngFor="let item of subItemArray"
        [class]="{
          'has-subItems': item.subItems
        }"
      >
        <button
          *ngIf="item.subItems"
          class="btn btn-sm"
          (click)="item.isExpanded = !item.isExpanded"
        >
          <span class="visually-hidden">{{
            item.isExpanded ? "collapse" : "expand"
          }}</span>
          <i
            class="bi bi-chevron-right"
            [class]="{
              expanded: item.isExpanded
            }"
          >
          </i>
        </button>

        <a
          href="#"
          (click)="
            setScenarioItem(
              scenario.get('name').value,
              scenario,
              item.label,
              parentPath + '/' + item.label
            );
            $event.preventDefault()
          "
          [class]="{
            'is-invalid': checkValidation(item, scenario)
          }"
          >{{ item.label }}</a
        >

        <ul
          class="list-group list-group-flush subitem-list"
          *ngIf="item.subItems"
          [class]="{
            expanded: item.isExpanded
          }"
        >
          <ng-container
            *ngTemplateOutlet="
              subItem;
              context: {
                parentPath: parentPath + '/' + item.label,
                scenario: scenario,
                subItemArray: item.subItems
              }
            "
          ></ng-container>
        </ul>
      </li>
    </ng-template>
  </ng-container>
</ng-container>
