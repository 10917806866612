import { Component, OnInit } from "@angular/core";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-models-and-input",
  templateUrl: "./models-and-input.component.html",
  styleUrls: ["./models-and-input.component.scss"],
})
export class ModelsAndInputComponent extends BaseModalFormComponent {
  public inputData: string;
}
