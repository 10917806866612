<div>
  <button
    class="btn btn-outline-primary"
    (click)="previousFlowClicked.emit()"
    [disabled]="!(canNavigatePreviousFlow$ | async)"
  >
    Previous
  </button>
</div>

<div>
  <button
    class="btn btn-outline-primary"
    (click)="nextFlowClicked.emit()"
    [disabled]="!(canNavigateNextFlow$ | async)"
  >
    Next
  </button>
</div>
