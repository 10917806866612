import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  of,
  take,
} from "rxjs";
import { User, UserRequest } from "../models/user-management";
import { DbService } from "src/app/shared/services/db.service";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ModalService } from "src/app/shared/services/modal.service";
import { UserProfileComponent } from "../components/user-profile/user-profile.component";
import { ConfirmWithCommentModalComponent } from "src/app/shared/components/confirm-with-comment-modal/confirm-with-comment-modal.component";

/****************mock data*****************/
const userProfile = {
  roleId: 2,
  userGuid: "4777EC86-AF3B-4858-BE41-48C150BBEDDB",
  isInternalUser: true,
  canAccessRMT: false,
  canAccessUserModule: true,
  canAccessStatistics: true,
  canAccessFullCam2016: false,
  canAccessFullCam2020: true,
  canAccessFullCam2024: false,
  otherReason: "",
  roleName: "Participant in Government Program",
  defaultSimulationQuota: 100,
  quotaDays: 7,
  plotSimulationCount: 0,
  pldSimulationCount: 0,
  estSimulationCount: 0,
  privacyAccepted: true,
  status: "Active",
  userEmailId: "takchun.choi1@dq.com.au",
};

const uq = [
  {
    createdBy: "raminder.singh@dq.com.au",
    id: "1",
    createdAt: 1712291693,
    product: "FullCAM 2024",
    reason: "for UAT",
    roleName: "None",
    userName: null,
    userEmailId: "raminder.singh@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "takchun.choi1@dq.com.au",
    id: "2",
    createdAt: 1712281693,
    product: "FullCAM 2024",
    reason: "for UAT",
    roleName: "Participant in Government Program",
    userName: "Tak Chun Choi",
    userEmailId: "takchun.choi1@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "michelle.whitfield@aff.gov.au",
    id: "3",
    createdAt: 1712271693,
    product: "FullCAM 2024",
    reason: "for UAT",
    roleName: "None",
    userName: null,
    userEmailId: "michelle.whitfield@aff.gov.au",
    status: "Pending",
  },
  {
    createdBy: "Ian.Warren@dq.com.au",
    id: "4",
    createdAt: 1712261693,
    product: "RMT",
    reason: "I need this to access some data for my project ABC.",
    roleName: "None",
    userName: "Ian Warren",
    userEmailId: "Ian.Warren@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "lachie.walmsley1@dq.com.au",
    id: "5",
    createdAt: 1712251693,
    product: "FullCAM 2024",
    reason: "for UAT",
    roleName: "None",
    userName: "Lachie Walmsley",
    userEmailId: "lachie.walmsley1@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "test1@dq.com.au",
    id: "6",
    createdAt: 1712241693,
    product: "RMT",
    reason: "for UAT",
    roleName: "None",
    userName: null,
    userEmailId: "test1@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "",
    id: "7",
    createdAt: 1712231693,
    product: "FullCAM 2024",
    reason: "for UAT",
    roleName: "None",
    userName: "Katey Dugdale",
    userEmailId: "test2@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "test3@aff.gov.au",
    id: "8",
    createdAt: 1712221693,
    product: "RMT",
    reason: "for UAT",
    roleName: "None",
    userName: "First Last",
    userEmailId: "test3@aff.gov.au",
    status: "Pending",
  },
  {
    createdBy: "test4@dq.com.au",
    id: "9",
    createdAt: 1712211693,
    product: "RMT",
    reason: "for UAT",
    roleName: "None",
    userName: "Maxwell Warren",
    userEmailId: "test4@dq.com.au",
    status: "Pending",
  },
  {
    createdBy: "test5@dq.com.au",
    id: "10",
    createdAt: 1712201693,
    product: "FullCAM 2024",
    reason: "for UAT",
    roleName: "None",
    userName: "Sergei Shekh",
    userEmailId: "test5@dq.com.au",
    status: "Pending",
  },
];
/****************end mock data*****************/

@Component({
  selector: "fc-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  public searchString: string = ""; //for user search

  public isLoading: boolean = false;
  public isBulkActionLoading$ = new BehaviorSubject<boolean>(false);

  public selectedUserRows = [];
  public selectedRequestRows = [];

  public selectedRequestBulkAction: "Approve" | "Reject" = "Approve";

  public selectedUserBulkAction: "Block" | "Unblock" = "Block";

  public users: User[] = [];
  public filteredUsers: User[] = [];

  public requests: UserRequest[] = [];
  public filteredRequests: UserRequest[] = [];

  public pageViews = [{ label: "Pending Requests" }, { label: "All Users" }];

  public selectedPageView: "Pending Requests" | "All Users" =
    "Pending Requests";

  public searchFilter: string = "";

  @ViewChild("allUserTable") userTable: DatatableComponent;
  @ViewChild("userRequestsTable") userRequestsTable: DatatableComponent;

  constructor(
    private dbService: DbService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  private getList() {
    if (this.selectedPageView == "Pending Requests") {
      this.dbService
        .getUserRequests()
        .pipe(
          take(1),
          catchError((error) => {
            this.requests = uq;
            this.filteredRequests = uq;
            return of(uq);
          })
        )
        .subscribe((requests) => {
          this.requests = requests;
          this.filteredRequests = requests;
        });
    } else {
      this.dbService
        .getAllUsers()
        .pipe(take(1))
        .subscribe((users) => {
          this.users = users;
          this.filteredUsers = users;
        });
    }
  }

  public setSearchString(): void {
    if (this.searchString.length < 2) {
      return;
    }

    this.searchTable();
  }

  public clearSearch() {
    const selectedEntity = this.getSelectedEntity();
    this.searchString = "";
    selectedEntity.filteredEntity = selectedEntity.entity;
  }

  private searchTable(): void {
    const selectedEntity = this.getSelectedEntity();

    let val = this.searchString.toLowerCase();
    val = val.trim();

    // selectedEntity.filteredEntity = selectedEntity.entity.filter((d) => {
    //   for (const col of Object.values(d)) {
    //     if (col && String(col).toLowerCase().indexOf(val) !== -1) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
    selectedEntity.table.offset = 0;
  }

  private getSelectedEntity() {
    return {
      table:
        this.selectedPageView == "All Users"
          ? this.userTable
          : this.userRequestsTable,
      entity: this.selectedPageView == "All Users" ? this.users : this.requests,
      filteredEntity:
        this.selectedPageView == "All Users"
          ? this.filteredUsers
          : this.filteredRequests,
    };
  }

  public onSelect({ selected }): void {
    const selectedRows =
      this.selectedPageView == "Pending Requests"
        ? this.selectedRequestRows
        : this.selectedUserRows;

    selectedRows.splice(0, selectedRows.length);
    selectedRows.push(...selected);
  }

  public selectPageView(event, label: "Pending Requests" | "All Users"): void {
    event.preventDefault();
    this.clearSearch();
    this.selectedPageView = label;
    this.getList();
  }

  public openUserProfile(user) {
    console.log(user, "USER");
    console.log(userProfile, "userProfile");
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      class: "full-screen",
      initialState: {
        userProfile: userProfile,
      },
    };

    this.modalService
      .openModal(UserProfileComponent, {
        ...initialState,
      })
      .pipe(take(1));
  }

  public async actionRequest(isApproved: boolean, request: UserRequest) {
    const approveIcon = '<i class="approve icon bi bi-check-circle"></i>';
    const rejectIcon = '<i class="reject icon bi bi-x-circle"></i>';

    const message = `<h2>${isApproved ? "Approve" : "Reject"} Request</h2><p>${
      isApproved ? approveIcon : rejectIcon
    }</p><p>User: <b>${request.userEmailId}</b></p><p>Requested product: <b>${
      request.product
    }</b></p>`;
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        isApproved,
        message,
      },
    };

    this.modalService
      .openModal(ConfirmWithCommentModalComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((response) => {
        if (response?.confirm) {
          console.log(response);
          if (isApproved) {
            //approve/request
          } else {
            //reject/Request
          }
        }
      });
  }
  public blockUser(user: User) {
    const blockMessage = `The user will be temporarily restricted from accessing all applications, and an email notification will be sent to them regarding this action. Do you wish to proceed?`;
    this.confirmUserAction(false, user, blockMessage);
  }

  public unblockUser(user: User) {
    const unblockMessage = `Are you sure you want to restore the user's access to all applications? An email notification will be sent to the user upon confirmation.`;
    this.confirmUserAction(true, user, unblockMessage);
  }

  private confirmUserAction(isUnblock: boolean, user: User, message: string) {
    const icon = isUnblock
      ? '<i class="approve icon bi bi-check-circle"></i>'
      : '<i class="reject icon bi bi-x-circle"></i>';
    const modalMessage = `<h2>${
      isUnblock ? "Unblock" : "Block"
    } User</h2><p>${icon}</p><p>User: <b>${
      user.userEmailId
    }</b></p><p>${message}</p>`;

    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        isUnblock,
        message: modalMessage,
      },
    };

    this.modalService
      .openModal(ConfirmWithCommentModalComponent, { ...initialState })
      .pipe(take(1))
      .subscribe((response) => {
        if (response?.confirm) {
          console.log(response);
          if (isUnblock) {
            // Code to unblock the user
          } else {
            // Code to block the user
          }
        }
      });
  }

  public bulkAction() {
    this.isBulkActionLoading$.next(true);
    //const ids = this.selectedRows.map((row) => String(row.plotId));

    // if (this.selectedBulkAction == "delete") {
    //   this.deletePlotFiles(this.selectedRows);
    // } else if (this.selectedBulkAction == "export") {
    //   this.myPlotsService
    //     .downloadPlotFiles(ids)
    //     .pipe(take(1))
    //     .subscribe((xmlPlotsResults) => {
    //       this.isBulkActionLoading$.next(false);

    //       xmlPlotsResults.forEach((xmlPlotResult, index) => {
    //         const plotRow = this.selectedRows[index];
    //         this.simulationService.helperService.downloadFile(
    //           plotRow.fileName,
    //           xmlPlotResult.result,
    //           plotRow.fileType
    //         );
    //       });
    //       this.deselectAll();
    //     });
    // }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
