<div [style]="{ height: containerHeight }" class="message-container">
  <ng-container *ngFor="let error of formControlInstance.errors | keyvalue">
    <span *ngIf="error.key == 'required'" class="invalid-feedback"
      >{{ label ? label : "This field " }} is required.</span
    >
    <span *ngIf="error.key == 'maxlength'" class="invalid-feedback"
      >The maximum length of {{ error?.value?.requiredLength }} characters is
      reached. you typed in {{ error?.value?.actualLength }} characters.</span
    >

    <span *ngIf="error.key == 'max'" class="invalid-feedback"
      >The maximum value of this field is {{ error?.value?.max }}.</span
    >
    <span *ngIf="error.key == 'min'" class="invalid-feedback"
      >The minimum value of this field is {{ error?.value?.min }}.</span
    >

    <span *ngIf="error.key == 'pattern'" class="invalid-feedback"
      >Special characters are not allowed, except spaces, hypens and
      underscores.</span
    >

    <ng-container *ngIf="customErrorMessages">
      <span *ngIf="customErrorMessages[error.key]" class="invalid-feedback">{{
        customErrorMessages[error.key]
      }}</span>
    </ng-container>
  </ng-container>
</div>
