import {
  Component,
  ComponentRef,
  HostBinding,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { RmtSimulationService } from "../services/rmt-simulation.service";
import { PlotFlow } from "src/app/core/models";
import { FlyOverPanelComponent } from "src/app/shared/components/fly-over-panel/fly-over-panel.component";
import { AppService } from "src/app/services/app.service";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  take,
  takeUntil,
} from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "fc-rmt-entry",
  templateUrl: "./rmt-entry.component.html",
  styleUrls: ["./rmt-entry.component.scss"],
})
export class RmtEntryComponent implements OnInit {
  @ViewChild(FlyOverPanelComponent) flyOverPanel: FlyOverPanelComponent;

  @ViewChildren("flowContentHost", { read: ViewContainerRef })
  private flowContentHost: QueryList<ViewContainerRef>;

  @HostBinding("class") className = this.getSelectedFlow()?.id;

  private readonly destroy$ = new Subject<void>();
  private subscription: Subscription[] = [];

  public canNavigateNextFlow$ = new BehaviorSubject<boolean>(true);
  public canNavigatePreviousFlow$ = new BehaviorSubject<boolean>(true);

  public flows: PlotFlow[] = [];
  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private simulationService: RmtSimulationService
  ) {}

  ngOnInit(): void {
    this.activatedroute.params.subscribe((params) => {
      this.initPlotFlow();
    });

    this.flows = this.simulationService.getFlows();
    if (!this.getSelectedFlow()) {
      this.setSelectedFlowPage(this.flows[0]);
    }

    this.simulationService
      .getSelectedFlow$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((flow) => {
        setTimeout(() => {
          this.loadFormComponent(this.getSelectedFlow());
          this.checkNavigation();

          if (flow.service && flow.service.startLogging) {
            flow.service.startLogging();
          }
        }, 0);
      });
  }

  protected isValidSimulationRoute(): boolean {
    const currentRoute = this.router.url.split("/rmt").pop().replace("/", "");

    return currentRoute == "simulation";
  }

  protected initPlotFlow() {
    if (!this.isValidSimulationRoute()) {
      this.router.navigate(["/simulation"]);
      return;
    }
  }

  protected loadFormComponent(flow: PlotFlow) {
    const flowHost = this.flowContentHost?.first;
    if (!flowHost) {
      return;
    }
    flowHost.clear();
    flowHost.element.nativeElement.className = "flow-host";
    const childComp: ComponentRef<any> = flowHost.createComponent(
      flow.component
    );

    //child component inputs
    childComp.instance["service"] = flow.service;
    childComp.instance["simulationService"] = this.simulationService;
    childComp.instance["canNavigatePreviousFlow$"] =
      this.canNavigatePreviousFlow$;
    childComp.instance["canNavigateNextFlow$"] = this.canNavigateNextFlow$;

    const nextFlowSub = childComp.instance["nextFlowClicked"]
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.nextFlow();
      });

    const previousFlowSub = childComp.instance["previousFlowClicked"]
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.previousFlow();
      });

    this.subscription.push(nextFlowSub, previousFlowSub);
  }

  public getSelectedFlow() {
    return this.simulationService.getSelectedFlow();
  }

  public setSelectedFlowPage(flow): void {
    const currentFlow = this.getSelectedFlow();

    if (currentFlow && currentFlow.service.startLogging) {
      currentFlow.service.serviceDestroySubject$.next();
    }
    this.simulationService.setSelectedFlow(flow);
  }

  public previousFlow(): void {
    const selectedFlow = this.getSelectedFlow();
    const index = this.flows.findIndex((f) => f.id == selectedFlow.id);
    this.setSelectedFlowPage(this.flows[index - 1]);
  }

  public nextFlow(): void {
    const selectedFlow = this.getSelectedFlow();
    const index = this.flows.findIndex((f) => f.id == selectedFlow.id);
    this.setSelectedFlowPage(this.flows[index + 1]);
  }

  public onFileSelected(event) {
    const file = event.target.files[0];

    if (file) {
      this.readRMDFile(file);
    }
  }

  private readRMDFile(file: File) {
    this.simulationService.dbService
      .runRMTSimulation(file)
      .pipe(take(1))
      .subscribe((simulationResult) => {
        console.log(simulationResult);
      });
  }

  public saveRMDFile(): void {
    this.simulationService.saveRMDFile();
  }

  public downloadRACalculator(): void {
    const fileName = "Reforestation_Abatement_Calculator_Template_110531.xlsx";
    const filePath = `assets/rmt/${fileName}`;
    this.simulationService.dbService
      .downloadAsset(fileName, filePath)
      .subscribe({
        next: () => {
          this.simulationService.messageService.addAlert({
            type: "success",
            msg: "Reforestation Abatement Calculator downloaded successfully.",
            dismissible: true,
          });
        },
        error: (err) => {
          this.simulationService.messageService.addAlert({
            type: "danger",
            msg: "Something went wrong while downloading Reforestation Abatement Calculator.",
            dismissible: true,
          });
        },
      });
  }

  public shouldDisableSave(): boolean {
    return (
      this.simulationService.projectService.isInvalid() ||
      this.simulationService.managementService.isInvalid()
    );
  }

  public isEditRMD(): boolean {
    return this.simulationService.isEditRMD();
  }

  public isLoading$(): Observable<boolean> {
    return this.simulationService.isUploading$;
  }

  public checkNavigation(): void {
    const selectedFlow = this.getSelectedFlow();
    const selectedFlowIndex = this.flows.findIndex(
      (f) => f.id == selectedFlow.id
    );

    this.canNavigatePreviousFlow$.next(selectedFlowIndex > 0);
  }

  public setCanNavigateNextFlow(canAccess: boolean) {
    this.canNavigateNextFlow$.next(canAccess);
  }

  public canDeactivate() {
    return this.simulationService.isDirtyForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.subscription.forEach((s) => {
      s.unsubscribe();
    });

    this.subscription = [];
    this.simulationService.reset();
  }
}
