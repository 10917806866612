<div class="help-header">
  <h2>HELP</h2>
  <button
    class="btn btn-sm"
    (click)="collapsed.emit()"
    [attr.aria-label]="
      _isExpanded ? 'collapse section button' : 'expand section button'
    "
    [attr.aria-expanded]="_isExpanded"
    aria-controls="help-panel"
  >
    <i class="bi bi-x-lg"></i>
  </button>
</div>
<div class="help-content" [innerHTML]="htmlContent"></div>
