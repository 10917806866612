<div class="fly-over-container">
  <ng-container *ngIf="flyOverPanelService.activeComponent$ | async as comp">
    <ng-container
      *ngComponentOutlet="
        comp;
        injector: flyOverPanelService.flyOverPanelInjector
      "
    ></ng-container>
  </ng-container>
</div>
