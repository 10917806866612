import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import {
  Observable,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
} from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { UserRole } from "src/app/user-role/role-access/model";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    return this.authService.userRole$.pipe(
      filter((role: UserRole) => role !== null),
      map((role) => {
        if (role?.canAccessUserModule) {
          return true;
        } else {
          this.router.navigate([""]);
          return false;
        }
      }),
      catchError((error) => {
        //
        this.router.navigate([""]);
        return of(false);
      })
    );
  }
}
