import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";
import { EstateService } from "src/app/shared/models";

@Component({
  selector: "fc-clone-estate-form",
  templateUrl: "./clone-estate-form.component.html",
  styleUrls: ["./clone-estate-form.component.scss"],
})
export class CloneEstateFormComponent extends BaseModalFormComponent {
  public readonly cloneEstatePlotHelpUrl = "248_Event%20Update.htm";
  /*** modal inputs ****/
  public estateService: EstateService;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    years: new UntypedFormControl(0, [Validators.required]),
    steps: new UntypedFormControl(1, [Validators.required]),
  });
}
