import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { cloneDeep } from "lodash";
import { BehaviorSubject, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { Constants } from "src/app/shared/constants";
import { CustomValidators } from "src/app/shared/custom-validations";
import {
  FieldWatcher,
  FieldWatcherContext,
  FormFieldModel,
  FormModel,
  validatorModel,
} from "src/app/shared/models";
import { ExplorerCatetory } from "src/app/simulation/models";
import { environment } from "src/environments/environment";
import {
  AddToScenarioPayload,
  FormGroupElement,
  FormItemElement,
  ItemInput,
} from "../models";

@Injectable({
  providedIn: "root",
})
export class BasePlotFormService {
  public serviceDestroySubject$ = new Subject<void>();

  public simulationService;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  readonly pageId = null;

  public baseHelpPageUrl = environment.fullcam2020HelpUrl;

  readonly pageHelpLink = null;

  readonly categoryIds = null;

  noItemMessage = "";

  public layout: any = null;

  public formModel: FormModel = null;

  readonly fieldWatcher: FieldWatcher = null;

  public dbInputs: any = null;

  public formGroup: UntypedFormGroup = null;

  public isHelpPanelExpanded = false;

  public helpContent = "";
  public helpAnchorId = "";

  //this is the function for the inputs handled differently between new UI and existing Fullcam
  public readonly specialHandlingInputsForReadWrite = {};

  public toggleHelpPanel(id) {
    if (id !== this.helpAnchorId) {
      this.isHelpPanelExpanded = true;
    } else {
      this.isHelpPanelExpanded = !this.isHelpPanelExpanded;
    }

    if (this.isHelpPanelExpanded) {
      this.helpAnchorId = id;
    }
  }

  public collapseHelpPanel() {
    this.isHelpPanelExpanded = false;
    this.helpAnchorId = "";
  }

  public initForm(): UntypedFormGroup {
    if (!this.formModel) {
      console.error("No form model defined in the service");
    }
    //debug fields
    // console.log(this.dbInputs, "inputs", this.dbInputs !== {});
    //todo

    this.updateFormModelUpateInitialised();

    return this.createFormGroup(this.formModel);
  }

  public getFormModelByCategories(categoryIds: number[]) {
    if (!categoryIds) {
      // console.error("CATEORY ID is mandatory.");
      return [];
    }
    return this.simulationService.plotRefDataService.getInputs(
      categoryIds || this.categoryIds
    );
  }

  public getFormModel(): FormModel {
    return this.formModel;
  }

  public getFormFieldModel(fieldName: string): FormFieldModel {
    return this.getFormModel()[fieldName];
  }

  public getService() {
    return this;
  }

  public createFormGroup(formModel: FormModel): UntypedFormGroup {
    let formGroup = {};
    Object.entries(formModel).forEach((keyValue: [string, FormFieldModel]) => {
      const key = keyValue[0];
      const model = keyValue[1];

      //reconstruct validators
      if (model.validatorConfig) {
        model.validators = model.validatorConfig.map((v) =>
          this.applyValidator(v, key)
        );
      }

      if (model.defaultValue == "formArray") {
        formGroup[key] = new UntypedFormArray([], model.validators);
      } else if (model?.dataType == 6) {
        //"timeSeries"
        formGroup[key] = new UntypedFormControl(
          this.getDefaultTimeSeriesData(key, model.defaultValue),
          model.validators
        );
      } else {
        formGroup[key] = new UntypedFormControl(
          model.defaultValue,
          model.validators
        );
      }
    });
    return new UntypedFormGroup(formGroup);
  }

  public getFormGroup(categoryIds?: number[]): UntypedFormGroup {
    if (!this.formGroup) {
      //below is used when need to alter the form model

      // const categoryDetails = this.plotRefDataService.categories;
      // this.dbInputs = this.getFormModelByCategories(
      //   categoryIds || this.categoryIds
      // ).reduce((output, row) => {
      //   if (row.progNmII) {
      //     output[row.progNmII] = {
      //       high: row.hiII,
      //       low: row.loII,
      //       isOneMore: row.oneMoreII === 1,
      //       dataType: row.tDataII,
      //       helpId: row.helpIdII,
      //       defaultValue: null,
      //       validators: [],
      //       label: row.descII,
      //       unit: row.unitNmII,
      //       categoryName: categoryDetails[row.catIxII].name,
      //       categoryLabel: categoryDetails[row.catIxII].label,
      //       isShown: true,
      //       columnWidth: row.colWidthII,
      //       errorId: row.errNII,
      //       isInverse: row.inverseII === 1,
      //       prefixId: row.prefixII,
      //       isRisk: row.riskII === 1,
      //       scale: row.scaleII,
      //       sortId: row.sortNII,
      //       isSpa: row.spaII === 1,
      //       isSubSame: row.subSameII === 1, // Time series only: Same across subperiods (eg true for temp but false for rainfall)
      //       suffixId: row.suffixII,
      //       enumId: row.tEnumII,
      //       eventId: row.tEventII, // kNotE, kAllE, kPlnF, kPlnA, kThin, kHarv, kFirF, etc,
      //       tIn: row.tInII,
      //       spec: row.tSpecII, // kNotSpec, kAllSpecs, kTree, kCrop
      //       std: row.tStdII, // kNotStd, kStdF, kStdA, kStdFA
      //       isValidTricky: row.validTrickyII === 1, // Validity of input depends on more than whether non-blank and in range (incomplete - only marked for tables, so far)
      //       systemType: row.tSysII, // Required by system: kFr, kAg, kFrAg, kNA
      //     };
      //   } else {
      //     //   console.error("progNmII field is missing from the db.");
      //   }
      //   return output;
      // }, {});

      // let targetModel =
      //   this.pageId == "trees" || this.pageId == "crops"
      //     ? this["speciesFormModel"]
      //     : this.formModel;
      // targetModel = { ...targetModel };

      // Object.keys(targetModel).forEach((mKey) => {
      //   targetModel[mKey]["suffixId"] =
      //     this.dbInputs[mKey]?.suffixId === undefined
      //       ? null
      //       : this.dbInputs[mKey].suffixId;
      // });
      this.formGroup = this.initForm();
    }
    return this.formGroup;
  }

  protected applyValidator(inputValidator: validatorModel, key) {
    //model will be reinitialised after leaving the plot entry page, in this case, validators are constructed

    const validator = inputValidator?.isCustomValidator
      ? CustomValidators[inputValidator.functionName]
      : Validators[inputValidator.functionName];

    if (inputValidator.constantsInput) {
      return validator(Constants[inputValidator.constantsInput]);
    }

    // CustomValidators
    if (
      inputValidator.isCustomValidator &&
      inputValidator.input !== null &&
      inputValidator.input !== undefined
    ) {
      return (control: AbstractControl) =>
        validator(control, inputValidator.input);
    }

    if (inputValidator.input !== null && inputValidator.input !== undefined) {
      return validator(inputValidator.input);
    }

    return validator;
  }

  public cloneAbstractControl<T extends AbstractControl>(control: T): T {
    let newControl: T;

    if (control instanceof UntypedFormGroup) {
      const formGroup = new UntypedFormGroup(
        {},
        control.validator,
        control.asyncValidator
      );
      const controls = control.controls;

      Object.keys(controls).forEach((key) => {
        formGroup.addControl(key, this.cloneAbstractControl(controls[key]));
      });

      newControl = formGroup as any;
    } else if (control instanceof UntypedFormArray) {
      const formArray = new UntypedFormArray(
        [],
        control.validator,
        control.asyncValidator
      );

      control.controls.forEach((formControl) =>
        formArray.push(this.cloneAbstractControl(formControl))
      );

      newControl = formArray as any;
    } else if (control instanceof UntypedFormControl) {
      newControl = new UntypedFormControl(
        control.value,
        control.validator,
        control.asyncValidator
      ) as any;
    } else {
      throw new Error("Error: unexpected control value");
    }

    if (control.disabled) newControl.disable({ emitEvent: false });

    return newControl;
  }

  public manuallyTriggerWatchingFields(
    fieldWatcher: FieldWatcher,
    fieldWatcherContext: FieldWatcherContext
  ) {
    if (!fieldWatcher) {
      return;
    }
    const watchFields = Object.keys(fieldWatcher);

    watchFields.forEach((watchField) => {
      const splitValues = watchField.split(":");
      const isAccessingOtherTab = splitValues.length > 1;
      const watchFieldName = splitValues[isAccessingOtherTab ? 1 : 0];
      const watchTabName = isAccessingOtherTab ? splitValues[0] : null;

      const fieldControl: AbstractControl = isAccessingOtherTab
        ? this.simulationService[watchTabName + "Service"]
            .getFormGroup()
            .get(watchFieldName)
        : fieldWatcherContext.formGroup.get(watchFieldName);

      if (fieldControl) {
        fieldWatcher[watchField](fieldControl.value, fieldWatcherContext);
      }
    });
  }

  public watchFields(
    destroySubject$: Subject<void>,
    context: FieldWatcherContext,
    additionalWatcherFields?: FieldWatcher
  ): void {
    if (this.fieldWatcher) {
      let conbinedFieldWatchers = additionalWatcherFields
        ? { ...this.fieldWatcher, ...additionalWatcherFields }
        : this.fieldWatcher;

      const watchFields = Object.keys(conbinedFieldWatchers);

      watchFields.forEach((watchField) => {
        //to access form group from the other tabs use ":" as divider
        const splitValues = watchField.split(":");
        const isAccessingOtherTab = splitValues.length > 1;
        const watchFieldName = splitValues[isAccessingOtherTab ? 1 : 0];
        const watchTabName = isAccessingOtherTab ? splitValues[0] : null;

        const fieldControl: AbstractControl = isAccessingOtherTab
          ? this.simulationService[watchTabName + "Service"]
              .getFormGroup()
              .get(watchFieldName)
          : this.formGroup.get(watchFieldName);

        fieldControl.valueChanges
          .pipe(takeUntil(destroySubject$), startWith(fieldControl.value))
          .subscribe((newValue) => {
            const fieldWatcherContext: FieldWatcherContext = {
              formGroup: context.formGroup,
              formModel: context.formModel,
              layout: context.layout,
              simulationService: this.simulationService,
              fieldChangedEmitter: context.fieldChangedEmitter,
            };
            conbinedFieldWatchers[watchField](newValue, fieldWatcherContext);
          });
      });
    }
  }

  public isValid(): boolean {
    return this.formGroup?.valid;
  }

  public getErrors(): ValidationErrors | null {
    return this.formGroup.errors;
  }

  // public setFormData(
  //   key: string,
  //   data: any,
  //   dynamicCreate: boolean = false
  // ): void {
  //   const control = this.getFormGroup().get(key);
  //   if (control) {
  //     control.setValue(data);
  //   } else {
  //     console.warn(
  //       `Form key: ${key} not found in ${this.layout?.label} service`,
  //       data
  //     );
  //   }

  //   if (!control && dynamicCreate) {
  //     this.getFormGroup().addControl(key, new FormControl(data, []));
  //     console.warn(
  //       `Form key: ${key} is set dynamically in ${this.layout?.label} service`,
  //       data
  //     );
  //   }
  // }

  public removeError(control: AbstractControl, errorKey: string): void {
    if (control.hasError(errorKey)) {
      const { [errorKey]: removedError, ...restErrors } = control.errors;
      control.setErrors(Object.keys(restErrors).length ? restErrors : null);
    }
  }

  public addError(control: AbstractControl, errorKey: string): void {
    control.setErrors({ ...control.errors, [errorKey]: true });
  }

  public addDefaultValidatorsInBulk(
    fieldNames: string[],
    formGroup: UntypedFormGroup | AbstractControl,
    formModel: FormModel
  ): void {
    fieldNames.forEach((fieldName) => {
      if (formModel[fieldName] && formModel[fieldName].validators)
        formModel[fieldName].validators.forEach((v) => {
          formGroup.get(fieldName).addValidators(v);
          formGroup.get(fieldName).updateValueAndValidity();
        });
    });
  }

  public removeDefaultValidatorsInBulk(
    fieldNames: string[],
    formGroup: UntypedFormGroup | AbstractControl,
    formModel: FormModel
  ): void {
    fieldNames.forEach((fieldName) => {
      if (formModel[fieldName] && formModel[fieldName].validators) {
        formModel[fieldName].validators.forEach((v) => {
          formGroup.get(fieldName).removeValidators(v);
          formGroup.get(fieldName).updateValueAndValidity();
        });
      }
    });
  }

  public addValidatorsInBulk(
    fieldNames: string[],
    validators: ValidatorFn[],
    formGroup: UntypedFormGroup | AbstractControl
  ): void {
    fieldNames.forEach((fieldName) => {
      formGroup.get(fieldName).addValidators(validators);
      formGroup.get(fieldName).updateValueAndValidity();
    });
  }

  public removeValidatorsInBulk(
    fieldNames: string[],
    validators: ValidatorFn[],
    formGroup: UntypedFormGroup | AbstractControl
  ): void {
    fieldNames.forEach((fieldName) => {
      formGroup.get(fieldName).removeValidators(validators);
      formGroup.get(fieldName).updateValueAndValidity();
    });
  }

  public async save(): Promise<any> {}

  public getDefaultTimeSeriesData(fieldName, options?) {
    let defaultOptions = {
      $: {
        tInTS: fieldName,
        tExtrapTS: "AvgYr",
        tOriginTS: "Calendar",
        yr0TS: "2010",
        nYrsTS: "1",
        dataPerYrTS: "12",
        nDecPlacesTS: "1",
        colWidthTS: "50",
        multTS: "1.0",
        showGraphTS: "true",
      },

      WinState: [
        {
          $: {
            L: "10",
            T: "83",
            clientW: "702",
            clientH: "450",
            ws: "Normal",
          },
        },
      ],
      rawTS: [
        {
          $: { count: "12" },
          _: ",,,,,,,,,,,",
        },
      ],
    };

    if (options) {
      defaultOptions = {
        $: { ...defaultOptions.$, ...options.$ },
        WinState: defaultOptions.WinState,
        rawTS: [
          {
            ...defaultOptions.rawTS[0],
            ...options.rawTS[0],
            $: { count: options.rawTS[0]?._.split(",").length },
          },
        ],
      };
    }

    return defaultOptions;
  }

  public setTimeSeriesData(data) {
    this.formGroup.get(data.$.tInTS).setValue(data);
  }

  public setSimulationService(service) {
    this.simulationService = service;
  }

  public getUnit(formField: string) {
    return this.formModel[formField].unit;
  }

  public getConfigurationFormGroup(): UntypedFormGroup {
    const configurationService = this.simulationService.configurationService;
    return configurationService.getFormGroup();
  }

  public getFormModelKeysByCategoryName(category: string): string[] {
    return Object.entries(this.formModel).reduce(function (filtered, model) {
      const key = model[0];
      const value = model[1];
      if (value.categoryName == category) {
        filtered.push(key);
      }
      return filtered;
    }, []);
  }

  public writeXmlObject(formData?): any {
    return {};
  }

  public readXmlObject(): void {
    this.getFormGroup();
    if (!this.simulationService.getPlotFileJson()) {
      return;
    }
  }

  public convertXmlValueToFormValue(
    xmlKey,
    xmlValue,
    inputFormModel?: FormModel
  ) {
    const percentageUnits = ["%/yr", "%/day", "%"];
    const formModel = inputFormModel || this.getFormModel();

    const isPercentage =
      formModel[xmlKey] && percentageUnits.includes(formModel[xmlKey]?.unit);
    const isBooleanType = xmlValue == "true" || xmlValue == "false";
    const isNumberDataType =
      formModel[xmlKey]?.dataType == 0 || formModel[xmlKey]?.dataType == 1;
    let value = xmlValue;
    if (typeof value == "undefined" || value === "") {
      value = null;
    } else if (isBooleanType) {
      value = xmlValue === "true";
    } else if (isPercentage) {
      value = +xmlValue * 100;
    }

    return value;
    //return isNumberDataType ? this.adaptiveRound(value) : value;
  }

  public applyXmlValuesToFormGroup(
    xmlValues: Object,
    formGroup: UntypedFormGroup,
    inputFormModel?: FormModel
  ): void {
    Object.keys(xmlValues).forEach((objectKey) => {
      const formControl = formGroup.get(objectKey);
      if (formControl) {
        //some services might have to process other services' formModel,
        //inutFormMdel has to be included in this case

        let xmlValue = this.convertXmlValueToFormValue(
          objectKey,
          xmlValues[objectKey],
          inputFormModel
        );

        //Handle special inputs
        const foundInput = this.findInputElementById(
          this.layout.groups,
          objectKey
        );

        let value = this.specialHandlingInputsForReadWrite[objectKey]?.read(
          foundInput.input,
          xmlValue
        );
        if (value == undefined) {
          value = xmlValue;
        }

        formControl.setValue(value);
      } else {
        //  console.warn(objectKey + " not found from the form group.");
      }
    });
  }

  public adaptiveRound(number) {
    if (number === null || number === undefined || isNaN(number)) {
      return number;
    }

    let str = number.toString();
    //let str = number.toString();
    let match = str.match(/\.(\d*?)(9999999999999|0000000000000)/);
    if (match) {
      return parseFloat(number).toFixed(match[1].length);
    }
    return number;
  }

  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    //template
    return null;
  }

  public addInputToDigestScenario(data: AddToScenarioPayload) {
    const { service, input, item, addToAllScenarios, itemValue } = data;
    const convertedItem = cloneDeep(item);
    convertedItem.inputs = [input];
    const itemModel = this.getFormModel()[input.programmingName];
    const formValue =
      itemValue !== null && itemValue !== undefined
        ? itemValue
        : this.formGroup.get(input.programmingName).value;
    const validations = itemModel.validators;
    const control = new FormControl(formValue, validations);
    this.simulationService.plotDigestService.setScenarioDetails({
      programmingName: input.programmingName,
      service: this,
      itemLabel: this.getFormModel()[input.programmingName].label,
      control: control,
      path: itemModel.categoryLabel?.split(":").map((c) => c.trim()),
      option: "",
      inputItem: convertedItem,
      addToAllScenarios: addToAllScenarios,
      model: itemModel,
    });
  }

  public getSelectedScenarioName(): string {
    if (!this.simulationService.plotDigestService) {
      return;
    }
    const name =
      this.simulationService.plotDigestService.selectedScenarioName$.getValue();
    if (!name) {
      return this.simulationService.plotDigestService.defaultScenarioName;
    }
    return this.simulationService.plotDigestService.selectedScenarioName$.getValue();
  }

  public getNumberOfDigestScenarios(): number {
    return (
      this.simulationService.plotDigestService
        .getFormGroup()
        .get("digestScenarios").value as FormArray
    ).length;
  }

  public getPathFromInput(programmingName: string): string[] {
    // path.push(this.layout.label)
    let path = [];
    const groups = this.layout.groups;
    let level = 0;
    let foundInput;

    const findItem = (groups) => {
      for (let g of groups) {
        const currentGroupLabel = g.label;
        path[level] = currentGroupLabel;

        if (g.items) {
          for (let i of g.items) {
            if (i.inputs) {
              foundInput = i.inputs.find(
                (iInput) => iInput.programmingName == programmingName
              );
              if (foundInput) {
                break;
              }
            }

            if (i.groups) {
              level++;
              findItem(i.groups);
              if (!foundInput) {
                level--;
              }
            }
          }
        }

        if (foundInput) {
          break;
        }
      }

      return [this.layout.label, ...path];
    };

    path = findItem(groups);

    return path;
  }

  public findInputElementForDigest(
    groups: FormGroupElement[],
    programmingName: string
  ): { item: FormItemElement; input: ItemInput } {
    let foundObject = { item: null, input: null };

    for (let g of groups) {
      if (g.items) {
        for (let i of g.items) {
          if (i.inputs) {
            for (let iInput of i.inputs) {
              if (iInput.programmingName == programmingName) {
                foundObject.item = i;
                foundObject.input = iInput;
                return foundObject;
              }
              if (
                iInput.component &&
                iInput.component == "FormItemTableComponent"
              ) {
                const data = iInput.componentInputs.find(
                  (cIn) => cIn.inputKey == "data"
                );
                if (data) {
                  let foundNestedObject;
                  for (let row of data.value.rows) {
                    foundNestedObject = row.find(
                      (r) => r.programmingName == programmingName
                    );
                    if (foundNestedObject) {
                      foundObject.item = i;
                      foundObject.input = {
                        element: foundNestedObject.element,
                        programmingName: programmingName,
                        type: foundNestedObject.type,
                      };
                      return foundObject;
                    }
                  }
                  if (foundNestedObject) {
                    return foundObject;
                  }
                }
              }

              if (
                iInput.component &&
                iInput.component == "FormulaDisplayComponent"
              ) {
                const data = iInput.componentInputs.find(
                  (cIn) => cIn.inputKey == "data"
                );
                if (data) {
                  let foundNestedObject;
                  const inputs = data.value?.rhs || [];
                  for (let row of inputs) {
                    foundNestedObject = row.find(
                      (r) => r.programmingName == programmingName
                    );
                    if (foundNestedObject) {
                      foundObject.item = i;
                      foundObject.input = {
                        element: foundNestedObject.element,
                        programmingName: programmingName,
                        type: foundNestedObject.type,
                      };
                      return foundObject;
                    }
                  }
                  if (foundNestedObject) {
                    return foundObject;
                  }
                }
              }
            }
          }
        }
      }

      if (foundObject.item) {
        return foundObject;
      }

      const subGroups: FormItemElement[] = g.items.filter((i) => i.groups);

      if (subGroups.length) {
        for (let sg of subGroups) {
          const foundObject = this.findInputElementForDigest(
            sg.groups,
            programmingName
          );
          if (foundObject.item) {
            return foundObject;
          }
        }

        if (foundObject) {
          return foundObject;
        }
      }
    }

    return foundObject;
  }

  public isLayoutGroupShown(groups: FormGroupElement[], id: string): boolean {
    const group = this.getLayoutGroup(groups, id);

    if (group) {
      return group.isShown !== undefined ? group.isShown : true;
    }
    return null;
  }

  public getExplorerSubcategoryMap(data: ExplorerCatetory) {
    let explorerMap = {};
    const rootLabel = this.layout?.label;

    const formModel: FormModel = data.service.formModel;
    const formGroup = data.service.getFormGroup();

    const inputModels = Object.entries(formModel)
      .filter((fm) => fm[1].isExplorer && fm[1].isShown === true)
      .map((fm) => {
        return { ...fm[1], programmingName: fm[0] };
      });

    for (const im of inputModels) {
      //used for digest
      const foundItem = this.findInputElementById(
        [...data.service.layout.groups, ...(data.service.modifiers || [])],
        im.programmingName,
        true,
        false
      );

      if (!foundItem) {
        // console.log(im.programmingName, " not found");
        continue;
      }
      const path = im.categoryLabel?.split(":").map((c) => c.trim());
      const value = {
        label: im.label,
        model: im,
        programmingName: im.programmingName,
        value: formGroup.get(im.programmingName).value,
        dataType: im.dataType,
        service: data.service,
        item: foundItem.item,
        input: foundItem.input,
        formGroup: formGroup,
        displayValue: this.getInputDisplayValue(
          formGroup.get(im.programmingName).value,
          im,
          foundItem.input
        ),
      };

      this.setValueToExplorerMap(explorerMap, path, value);
    }
    return explorerMap[rootLabel];
  }

  public findInputElementById(
    groups: FormGroupElement[],
    programmingName: string,
    skipHiddenGroup?: boolean,
    hasHiddenParent?: boolean
  ): { item: FormItemElement; input: ItemInput } {
    for (let g of groups) {
      const isHiddenGroup = !g.isShown;
      if (g.items) {
        for (let i of g.items) {
          if (i.inputs) {
            for (let iInput of i.inputs) {
              if (iInput.programmingName == programmingName) {
                const foundObject = {
                  item: i,
                  input: iInput,
                };
                if (
                  (skipHiddenGroup && hasHiddenParent) ||
                  (skipHiddenGroup && isHiddenGroup)
                ) {
                  return null;
                }

                return foundObject;
              }
              if (
                iInput.component &&
                iInput.component == "FormItemTableComponent"
              ) {
                const data = iInput.componentInputs.find(
                  (cIn) => cIn.inputKey == "data"
                );
                if (data) {
                  for (let row of data.value.rows) {
                    const foundNestedObject = row.find(
                      (r) => r.programmingName == programmingName
                    );
                    if (foundNestedObject) {
                      const foundObject = {
                        item: i,
                        input: {
                          element: foundNestedObject.element,
                          programmingName: programmingName,
                          type: foundNestedObject.type,
                        },
                      };
                      if (
                        (skipHiddenGroup && hasHiddenParent) ||
                        (skipHiddenGroup && isHiddenGroup)
                      ) {
                        return null;
                      }

                      return foundObject;
                    }
                  }
                }
              }

              if (
                iInput.component &&
                iInput.component == "FormulaDisplayComponent"
              ) {
                const data = iInput.componentInputs.find(
                  (cIn) => cIn.inputKey == "data"
                );
                if (data) {
                  const inputs = data.value?.rhs || [];
                  for (let row of inputs) {
                    const foundNestedObject = row.find(
                      (r) => r.programmingName == programmingName
                    );
                    if (foundNestedObject) {
                      const foundObject = {
                        item: i,
                        input: {
                          element: foundNestedObject.element,
                          programmingName: programmingName,
                          type: foundNestedObject.type,
                        },
                      };
                      if (
                        (skipHiddenGroup && hasHiddenParent) ||
                        (skipHiddenGroup && isHiddenGroup)
                      ) {
                        return null;
                      }

                      return foundObject;
                    }
                  }
                }
              }
            }
          }
        }
      }

      const subGroups: FormItemElement[] = g.items.filter((i) => i.groups);

      if (subGroups.length) {
        for (let sg of subGroups) {
          const foundObject = this.findInputElementById(
            sg.groups,
            programmingName,
            skipHiddenGroup,
            hasHiddenParent ? hasHiddenParent : isHiddenGroup
          );
          if (foundObject?.item) {
            return foundObject;
          }
        }
      }
    }

    return null;
  }

  public getInputDisplayValue(value, inputModel, input?) {
    let displayName = value;
    if (inputModel.enumId == "YesNoT") {
      displayName = displayName == true ? "Yes" : "No";
    } else if (inputModel.enumId == "OnOffT") {
      displayName = displayName == true ? "On" : "Off";
    } else if (inputModel.dataType == 5) {
      //select
      if (input?.selectOptions) {
        const option = input.selectOptions.find((o) => o.value == value);
        if (option) {
          displayName = option.label;
        }
      }
    }

    return displayName;
  }

  protected setValueToExplorerMap(object, path, value) {
    let current = object;

    path.forEach((p, index) => {
      if (!current[p]) {
        current[p] = {};
      }

      if (index == path.length - 1) {
        if (current[p].controls) {
          const hasSameControl = current[p].controls.find(
            (i) => i.programmingName == value.programmingName
          );
          if (!hasSameControl) {
            current[p].controls.push(value);
          }
        } else {
          current[p] = { controls: [value], ...current[p] };
        }
      }

      current = current[p];
    });
  }

  // const { service, input, item, addToAllScenarios, itemValue } = data;

  // const convertedItem = cloneDeep(item);
  // convertedItem.inputs = [input];
  // const itemModel = this.getFormModel()[input.programmingName];
  // const formValue =
  //   itemValue || this.formGroup.get(input.programmingName).value;
  // const validations = itemModel.validators;
  // const control = new FormControl(formValue, validations);

  // this.simulationService.plotDigestService.setScenarioDetails({
  //   programmingName: input.programmingName,
  //   service: this,
  //   itemLabel: this.getFormModel()[input.programmingName].label,
  //   control: control,
  //   path: itemModel.categoryLabel?.split(":").map((c) => c.trim()),
  //   option: "",
  //   inputItem: convertedItem,
  //   addToAllScenarios: addToAllScenarios,
  // });

  /* id has to manually assigned to group element in layout*/
  public getLayoutGroup(
    groups: FormGroupElement[],
    id: string
  ): FormGroupElement {
    let foundGroup: FormGroupElement;

    for (let g of groups) {
      if (g.id == id) {
        foundGroup = g;
        break;
      }

      if (g.items) {
        const subGroups: FormItemElement[] = g.items.filter((i) => i.groups);

        if (subGroups.length) {
          for (let sg of subGroups) {
            foundGroup = this.getLayoutGroup(sg.groups, id);
            if (foundGroup) {
              break;
            }
          }

          if (foundGroup) {
            break;
          }
        }
      }
    }

    return foundGroup;
  }

  /* id has to manually assigned to item element in layout*/
  public getLayoutItem(
    groups: FormGroupElement[],
    id: string
  ): FormItemElement {
    let foundItem: FormItemElement;

    for (let g of groups) {
      if (g.items) {
        foundItem = g.items.find((i) => i.id == id);
        if (foundItem) {
          break;
        }

        const subGroups: FormItemElement[] = g.items.filter((i) => i.groups);

        if (subGroups.length) {
          for (let sg of subGroups) {
            foundItem = this.getLayoutItem(sg.groups, id);
            if (foundItem) {
              break;
            }
          }

          if (foundItem) {
            break;
          }
        }
      }
    }

    return foundItem;
  }

  public isInvalid(): boolean {
    return this.formGroup?.invalid;
  }

  public reset(): void {
    if (!this.formGroup) {
      return;
    }

    Object.values(this.formGroup.controls).forEach((fControl) => {
      if (fControl["controls"]) {
        Object.values(fControl["controls"]).forEach((c: AbstractControl) => {
          c.clearValidators();
        });
      } else {
        fControl.clearValidators();
      }
    });
    this.formGroup = null;
  }

  public convertFormValueToXml(formKey, formValue, inputFormModel?) {
    const percentageUnits = ["%/yr", "%/day", "%"];

    const formModel = inputFormModel || this.formModel;

    const isNumberDataType =
      formModel[formKey]?.dataType == 0 || formModel[formKey]?.dataType == 1;

    //skip excludeFromXML
    if (formModel[formKey] && !formModel[formKey].excludeFromXml) {
      const isTimeSeries = formModel[formKey]?.dataType == 6;

      const isPercentage =
        formModel[formKey] &&
        percentageUnits.includes(formModel[formKey]?.unit);

      const isBooleanType = typeof formValue === "boolean";

      let value = formValue;
      if (typeof value == "undefined" || value === null) {
        value = "";
      } else if (isBooleanType) {
        value = String(formValue);
      } else if (isPercentage && !isTimeSeries) {
        value = +formValue / 100;
      }
      // return value;
      return isNumberDataType ? this.adaptiveRound(value) : value;
    }
    //return formValue;
    return isNumberDataType ? this.adaptiveRound(formValue) : formValue;
  }

  public getFormValueObjectForXmlExport(
    inputFields,
    formData,
    formModel?,
    concats?
  ): object {
    const returnObject = {};
    const fields = inputFields || Object.keys(formData);

    fields.forEach((f) => {
      let key = f;
      if (concats) {
        key =
          concats.position == "append"
            ? key + concats.value
            : concats.value + key.replace(/^\w/, (c) => c.toUpperCase());
      }

      if (typeof formData[key] !== "undefined") {
        returnObject[f] = this.convertFormValueToXml(
          key,
          formData[key],
          formModel
        );
      }
    });

    if (concats) {
      returnObject["id"] = concats.id;
    }

    return returnObject;
  }
  //To trigger flyover panel to open time series (dataVisualiser) component
  public getTimeSeriesInputElement(
    groups: FormGroupElement[],
    timeSeriesId: string
  ): ItemInput {
    let foundInput: ItemInput;
    for (let g of groups) {
      if (g.items) {
        for (let i of g.items) {
          if (i.inputs) {
            foundInput = i.inputs.find(
              (iInput) =>
                iInput.component &&
                iInput.component == "DataVisualiserComponent" &&
                iInput.programmingName == timeSeriesId
            );
            if (foundInput) {
              break;
            }
          }
        }
      }
      if (foundInput) {
        break;
      }
      const subGroups: FormItemElement[] = g.items.filter((i) => i.groups);
      if (subGroups.length) {
        for (let sg of subGroups) {
          foundInput = this.getTimeSeriesInputElement(sg.groups, timeSeriesId);
          if (foundInput) {
            break;
          }
        }
        if (foundInput) {
          break;
        }
      }
    }
    return foundInput;
  }

  protected getInputDisplayValueByProgrammingNameAndValue(
    programmingName,
    value
  ) {
    const model = this.formModel[programmingName];

    const foundItem = this.findInputElementById(
      [...this.layout.groups, ...(this["modifiers"] || [])],
      programmingName,
      true,
      false
    );
    if (foundItem) {
      return this.getInputDisplayValue(value, model, foundItem.input);
    }
    return null;
  }

  public startLogging() {}

  public runFunctionsAfterSimulation(): void {
    //this is created because the fpiAvgLT in Site page changed after simulation
    return;
  }

  //used by EST sim, as form model will be slightly different in some pages
  public updateFormModelUpateInitialised() {
    return;
  }
}
