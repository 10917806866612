import { Component, EventEmitter, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "fc-portal-modal-service",
  templateUrl: "./portal-modal-service.component.html",
  styleUrls: ["./portal-modal-service.component.scss"],
})
export class PortalModalServiceComponent implements OnInit {
  title?: string;
  canAccessFullCam2016: boolean;
  canAccessRMT: boolean;
  canAccessFullCam2020: boolean;
  canAccessFullCam2024: boolean;
  status?: string;
  name: string;
  grantedAccessFullCam2016: boolean;
  grantedAccessRMT: boolean;
  grantedAccessFullCam2020: boolean;
  grantedAccessFullCam2024: boolean;

  constructor(public bsModalRef: BsModalRef) {}
  public event: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  Confirm() {
    this.triggerEvent("Confirm");
    this.bsModalRef.hide();
  }
  Cancel() {
    this.triggerCancelEvent("Cancel");
    this.bsModalRef.hide();
  }
  triggerEvent(item: string) {
    this.event.emit({
      data: item,
      res: 200,
      access2016: this.canAccessFullCam2016,
      access2024: this.canAccessFullCam2024,
      access2020: this.canAccessFullCam2020,
      accessRMT: this.canAccessRMT,
    });
  }
  triggerCancelEvent(item: string) {
    this.event.emit({ data: item, res: 200 });
  }
}
