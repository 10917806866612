import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { FieldWatcher, FormModel } from "src/app/shared/models";
import {
  AddToScenarioPayload,
  ClonedPlotFile,
  ClonePlotFilesResponse,
  FormGroupElement,
  FormLayout,
  IScenarioDetails,
  ItemInput,
  PlotDigestScenario,
  ScenarioControl,
  ScenarioMap,
} from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { BehaviorSubject, take } from "rxjs";
import * as _ from "lodash";
import { ExplorerCatetory } from "src/app/simulation/models";

@Injectable({
  providedIn: "root",
})
export class PlotDigest2020Service extends BasePlotFormService {
  public readonly pageId = "plotDigest";
  public readonly pageHelpLink = "281_Plot%20Digest.htm";

  private readonly selectedScenarioName$ = new BehaviorSubject<string>(null);
  public readonly defaultScenarioName = "Default";

  private readonly selectedScenarioPath$ = new BehaviorSubject<string>(null);

  public digestElements: ScenarioControl[] = [];

  //this is the function for the inputs handled differently between new UI and existing Fullcam FOR DIGEST ONLY
  public readonly specialHandlingDigestInputsForReadWrite = {
    tStepsYTZ: {
      read: (input: ItemInput, inputValue: any) => {
        const option = input.selectOptions.find((o) => o.index == inputValue);

        return option?.value !== undefined ? option?.value : inputValue;
      },
      write: (input: ItemInput, inputValue: any) => {
        const option = input.selectOptions.find((o) => o.index == inputValue);
        return option?.index !== undefined ? option.index : null;
      },
    },
    tAirTemp: {
      read: (input: ItemInput, inputValue: any) => {
        if (inputValue == "TimeSeries") {
          return "Direct";
        } else if (inputValue == "Estimate") {
          return "RangeMin";
        } else {
          return "";
        }
      },
      write: (input: ItemInput, inputValue: any) => {
        if (inputValue == "Direct") {
          return "TimeSeries";
        } else if (inputValue == "RangeMin") {
          return "Estimate";
        } else {
          return "";
        }
      },
    },
    hasArea: {
      read: (input: ItemInput, inputValue: any) => {
        if (inputValue == undefined) {
          return inputValue;
        }
        return !inputValue;
      },
      write: (input: ItemInput, inputValue: any) => {
        if (inputValue == undefined) {
          return inputValue;
        }
        return !inputValue;
      },
    },
  };
  public readonly specialHandlingInputConversion = {
    grthModeF: "grthModeSP",
    grthModeA: "grthModeSP",
  };

  public modifiers: FormGroupElement[] = [
    {
      label: "View by",
      isShown: true,
      isRoot: true,
      items: [
        {
          label: "Choose how you would like to view the data",
          inputs: [
            {
              element: "select",
              selectOptions: [
                { label: "Scenario by Category", value: "Category" },
                { label: "Scenario", value: "Scenario" },
                { label: "Input", value: "Input" },
              ],
              programmingName: "viewType",
            },
          ],
        },
      ],
    },
    {
      label: "Simulation options",
      isShown: true,
      isRoot: true,
      items: [
        {
          label: "Choose options for output windows",
          inputs: [
            {
              element: "select",
              selectOptions: [
                { label: "Combine all Plots", value: "Combined" },
                { label: "Per Scenario", value: "PerScenario" },
                { label: "Per Output", value: "PerOutput" },
              ],
              programmingName: "outWinOption",
            },
          ],
        },

        {
          label: "Average combined plots (not sum)",
          id: "averageCombined",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "averageCombined",
            },
          ],
        },
        {
          label: "Mass outputs converted to unit/ha",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "finalPerHectare",
            },
          ],
        },
        {
          label: "Update spatial data for each scenario",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "updateSpatial",
            },
          ],
        },
      ],
    },
  ];

  public readonly layout: FormLayout = {
    label: "Plot digest",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: false,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: null,
                element: "component",
                component: "PlotDigestContainerComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    viewType: {
      label: "View Type",
      defaultValue: "Category",
      isShown: true,
    },
    outWinOption: {
      label: "Combine all Plots",
      defaultValue: "Combined",
      isShown: true,
    },
    averageCombined: {
      label: "Average combined plots (not sum)",
      defaultValue: true,
      isShown: true,
    },
    finalPerHectare: {
      label: "Mass outputs converted to unit/ha",
      defaultValue: true,
      isShown: true,
    },
    updateSpatial: {
      label: "Update spatial data for each scenario",
      defaultValue: false,
      isShown: true,
    },
    digestScenarios: {
      defaultValue: "formArray",
      isShown: true,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
    },
  };

  public readonly fieldWatcher: FieldWatcher = {
    outWinOption: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const shouldHideAverageOption =
        newValue !== "PerScenario" && newValue !== "PerOutput";
      const averageCombinedControl = formGroup.get("averageCombined");
      formModel["averageCombined"].isShown = shouldHideAverageOption;

      if (
        (newValue == "PerScenario" && averageCombinedControl.value) ||
        (newValue == "PerOutput" && averageCombinedControl.value)
      ) {
        averageCombinedControl.setValue(false);
      }
    },
    viewType: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      let scenarioName = this.selectedScenarioName$.value;

      if (!scenarioName) {
        const scenarioArray = this.getDigestScenariosFormArray();
        if (!scenarioArray.length) {
          return;
        }
        scenarioName = this.getDigestScenariosFormArray().value[0].name;
        this.setSelectedScenarioName(scenarioName);
      }

      const selectedScenario = this.getSelectedScenarioFormGroup();
      if (!selectedScenario) {
        return;
      }
      if (newValue == "Input") {
        this.setDigestElementsByLabel(
          selectedScenario.value?.controls[0]?.itemLabel
        );
      } else {
        this.setScenarioItem({
          name: scenarioName,
          scenario: selectedScenario,
          itemLabel: "root",
          path: "",
        });
      }
    },
  };

  protected createScenarioFormGroup(): FormGroup {
    return new FormGroup({
      isExpanded: new FormControl(true),
      enabled: new FormControl(true),
      name: new FormControl(""),
      notes: new FormControl(""),
      label: new FormControl(""),
      controls: new FormControl([]),
      scenarioMap: new FormControl(null),
      sortedScenarioArray: new FormControl([]),
      digestElementFormGroup: new FormGroup({}),
    });
  }

  public setValueToMap(object, path, value) {
    let current = object;

    path.forEach((p, index) => {
      if (!current[p]) {
        current[p] = {};
      }

      if (index == path.length - 1) {
        if (current[p].controls) {
          const hasSameControl = current[p].controls.find(
            (i) => i.programmingName == value.programmingName
          );
          if (!hasSameControl) {
            current[p].controls.push(value);
          }
        } else {
          current[p] = { controls: [value] };
        }
      }

      current = current[p];
    });
  }

  protected async setScenarioDetails(scenarioDetails: IScenarioDetails) {
    const { addToAllScenarios } = scenarioDetails;

    if (!this.getDigestScenariosFormArray().length) {
      this.addScenario({ name: this.defaultScenarioName });
    }

    if (addToAllScenarios) {
      this.getDigestScenariosFormArray().controls.forEach((c) => {
        this.addControlToScenario(scenarioDetails, c);
      });
    } else {
      this.addControlToScenario(scenarioDetails);
    }
  }

  private addControlToScenario(sd: IScenarioDetails, fg?: AbstractControl) {
    let scenarioDetails = sd;
    scenarioDetails.control = new FormControl(
      scenarioDetails.control.value,
      scenarioDetails.control.validator
    );
    const {
      service,
      control,
      programmingName,
      path,
      option,
      inputItem,
      itemLabel,
    } = scenarioDetails;
    const selectedScenarioFormGroup = fg
      ? fg
      : this.getSelectedScenarioFormGroup();

    const controls = selectedScenarioFormGroup.get("controls");

    const hasSameControlIndex = controls.value.findIndex(
      (c) => c.programmingName == scenarioDetails.programmingName
    );
    if (hasSameControlIndex > -1) {
      controls.value.splice(hasSameControlIndex, scenarioDetails);
    } else {
      controls.setValue([...controls.value, scenarioDetails]);
    }

    const digestElementFormGroup = selectedScenarioFormGroup.get(
      "digestElementFormGroup"
    ) as FormGroup;

    digestElementFormGroup.addControl(programmingName, scenarioDetails.control);

    const scenarioMapControl = selectedScenarioFormGroup.get("scenarioMap");

    let scenarioMap: ScenarioMap = scenarioMapControl.value || {};

    this.setValueToMap(scenarioMap, path, {
      inputItem,
      programmingName,
      itemLabel,
      control,
      service,
    });

    scenarioMapControl.setValue(scenarioMap);

    //for scenario & input editing list
    selectedScenarioFormGroup
      .get("sortedScenarioArray")
      .setValue(this.getSortedSenarioMap(scenarioMap));

    this.simulationService.messageService.addAlert({
      type: "success",
      msg: `Input - ${itemLabel} has been ${
        hasSameControlIndex > -1 ? "replaced in" : "added to"
      } scenario ${selectedScenarioFormGroup.get("name").value}`,
      dismissible: true,
      timeout: 3000,
    });
  }

  //for scenario & input editing list
  public getSortedSenarioMap(sceanrioMap) {
    return Object.keys(sceanrioMap)
      .map((smKey) => {
        return {
          ...(sceanrioMap[smKey].controls && {
            controls: sceanrioMap[smKey].controls,
          }),
          ...(!sceanrioMap[smKey].controls && {
            subItems: this.getSortedSenarioMap(sceanrioMap[smKey]),
          }),
          label: smKey,
          isExpanded: true, //defulat to ture, and will not be cached
        };
      })
      .sort((a, b) => {
        const currentFlow =
          this.simulationService.currentFlowsSource$.getValue();

        const aIndex = currentFlow.findIndex((f) => f.label == a.label);
        const bIndex = currentFlow.findIndex((f) => f.label == b.label);
        return aIndex < bIndex ? -1 : aIndex < bIndex ? 0 : 1;
      });
  }

  public getDigestScenariosFormArray(): UntypedFormArray {
    return this.getFormGroup().get("digestScenarios") as UntypedFormArray;
  }

  public getSelectedScenarioFormGroup(): AbstractControl {
    return this.getDigestScenariosFormArray().controls.find(
      (s) => s.get("name").value == this.selectedScenarioName$.getValue()
    );
  }

  public getSelectedScenarioName(): string {
    if (!this.getSelectedScenarioFormGroup()) {
      return "";
    }
    return this.getSelectedScenarioFormGroup().get("name").value;
  }

  public getSelectedScenarioNotes(): string {
    if (!this.getSelectedScenarioFormGroup()) {
      return "";
    }
    return this.getSelectedScenarioFormGroup().get("notes").value;
  }

  public getSelectedDigestElementFormGroup(): FormGroup {
    if (!this.getSelectedScenarioFormGroup()) {
      return;
    }
    return this.getSelectedScenarioFormGroup().get(
      "digestElementFormGroup"
    ) as FormGroup;
  }

  public setScenarioItem({ name, scenario, itemLabel, path }) {
    const categories = path.split("/");
    //remove the parent
    categories.shift();
    this.setSelectedScenarioName(name);
    this.setSelectedScenarioPath(categories.join(" / "));
    this.setDigestElements(name, scenario, categories);
  }

  public setScenarioInput(itemLabel) {
    this.setDigestElementsByLabel(itemLabel);
  }

  protected setDigestElements(
    name,
    scenario: FormControl,
    categories: string[]
  ): void {
    const controls = scenario.get("controls").value;

    const digestElements = controls.filter((c) => {
      return c.path.toString().indexOf(categories.toString()) > -1;
    });

    this.digestElements = digestElements;
  }

  public setDigestElementsByLabel(itemLabel) {
    const allElements = [];

    this.getDigestScenariosFormArray().controls.forEach((scenario) => {
      const controls = scenario.get("controls").value;
      const digestElements = controls.filter((c) => c.itemLabel == itemLabel);
      digestElements.forEach((de) => {
        allElements.push({ ...de, scenarioName: scenario.get("name").value });
      });
    });
    this.digestElements = allElements;
  }

  public addScenario(inputFformData: PlotDigestScenario) {
    let formData = inputFformData;
    const formGroup = this.createScenarioFormGroup();
    const allScenarios = this.getDigestScenariosFormArray();
    const allScenarioNames = allScenarios.value.map((s) => s.name);
    //checkName
    formData.name = this.checkScenarioName(formData.name, allScenarioNames);

    formGroup.patchValue(formData);
    this.setSelectedScenarioName(formGroup.get("name").value);
    allScenarios.push(formGroup);
  }

  protected checkScenarioName(inputName, allScenarioNames): string {
    let counter = 0;
    let uniqueName = inputName;
    if (!allScenarioNames.includes(uniqueName)) {
      return uniqueName;
    }

    while (allScenarioNames.includes(uniqueName) === true) {
      counter++;
      uniqueName = inputName + counter;
    }
    return uniqueName;
  }

  public setSelectedScenarioName(scenarioName: string) {
    this.selectedScenarioName$.next(scenarioName);
  }

  public setSelectedScenarioPath(path: string) {
    this.selectedScenarioPath$.next(path);
  }

  protected reselectFirstScenario() {
    if (this.getDigestScenariosFormArray().length) {
      const firstScenario = this.getDigestScenariosFormArray().controls[0];
      this.setScenarioItem({
        name: firstScenario.get("name").value,
        scenario: firstScenario,
        itemLabel: "root",
        path: firstScenario.get("name").value,
      });
    }
  }
  public deleteScenario(name: string) {
    const isSelectedScenario = this.selectedScenarioName$.getValue() == name;

    if (isSelectedScenario) {
      this.digestElements = [];
    }
    const foundIndex = this.getDigestScenariosFormArray().controls.findIndex(
      (c) => c.get("name").value == name
    );
    if (foundIndex > -1) {
      if (this.selectedScenarioName$.getValue() == name) {
        this.setSelectedScenarioName(null);
      }
      this.getDigestScenariosFormArray().removeAt(foundIndex);
    }
    //reselect the first scenarion if any
    this.reselectFirstScenario();
  }

  public cloneScenario(numberOfClones: number, fg: FormGroup) {
    [...new Array(numberOfClones)].forEach((_, index) => {
      let formData = fg.getRawValue();
      formData.name = formData.name + " cloned " + (index + 1);
      formData.scenarioMap = {};
      this.addScenario({ name: formData.name });

      formData.controls.forEach((sd: IScenarioDetails) => {
        this.addControlToScenario(sd);
      });
    });
  }

  public cloneScenarioFromPlotFiles(plotFiles) {
    const selectedScenario = this.getSelectedScenarioFormGroup();
    if (!selectedScenario) {
      return;
    }

    const params = {
      plotIds: plotFiles.map((p) => p.plotId),
      inputFields: selectedScenario.value.controls.map((c) => {
        const xPath = c.service.getXPathForCloneDigest(c.programmingName);
        if (!xPath) {
          console.error(
            `Programming name: ${c.programmingName} not found from Digest Xpath`
          );
        }

        return {
          inputType: c?.model?.dataType == 6 ? "TimeSeries" : "General",
          xPath: xPath?.xPath,
          tagId: xPath?.id || null,
          attribute: xPath?.programmingName || c.programmingName,
          value: "",
        };
      }),
    };
    this.simulationService.dbService
      .clonePlotFilesForDigest(params)
      .pipe(take(1))
      .subscribe((result: ClonePlotFilesResponse) => {
        this.applyClonedPlotFiles(
          result.clonedPlotFiles,
          plotFiles,
          selectedScenario
        );
      });
  }

  protected applyClonedPlotFiles(
    clonedPlotFiles: ClonedPlotFile[],
    plotFiles,
    scenario
  ) {
    clonedPlotFiles.forEach((clonedPlotFile) => {
      let formData = scenario.getRawValue();
      const plotFileName = plotFiles.find(
        (p) => p.plotId == clonedPlotFile.plotId
      )?.fileName;
      formData.name = plotFileName;
      formData.scenarioMap = {};
      clonedPlotFile;

      this.addScenario({ name: formData.name });

      formData.controls.forEach((sd) => {
        //reapply the cloned value
        const clonedInputField = clonedPlotFile.inputFields.find(
          (input) => input.attribute == sd.programmingName
        );

        const value = this.convertXmlValueToFormValue(
          sd.programmingName,
          clonedInputField.value,
          sd.model
        );
        const clonedControl = new FormControl(value, sd.control.validator);
        this.addControlToScenario({ ...sd, control: clonedControl });
      });
    });
  }

  public getDigestInputs(scenarioMap) {
    let digestElementArray = [];

    const getControls = (obj) => {
      if (!obj) {
        return;
      }

      if (obj.controls) {
        digestElementArray = [...digestElementArray, ...obj.controls];
        return;
      }

      Object.values(obj).forEach((sd: ScenarioMap) => {
        if (sd.controls) {
          digestElementArray = [...digestElementArray, ...sd.controls];
        } else {
          if (Object.keys(sd).length) {
            Object.keys(sd).forEach((sdKey) => {
              if (sd[sdKey].controls) {
                getControls(sd[sdKey]);
              }
            });
          }
        }
      });
    };
    getControls(scenarioMap);

    return digestElementArray;
  }

  protected getPageByDigestElementOption(
    option: string
  ): "event" | "trees" | "crops" | null {
    if (!option) {
      return null;
    }
    //the only way to tell whether it is event or species is,
    //event is comma separated by "index, event name"
    const splittedOption = option.split(",");
    if (splittedOption[0] && parseInt(splittedOption[0]) > -1) {
      return "event";
    } else {
      //species
      const treeSpecies = this.simulationService.treesService.getSpeciesByName(
        option.trim()
      );
      const cropSpecies = this.simulationService.cropsService.getSpeciesByName(
        option.trim()
      );
      if (treeSpecies) {
        return "trees";
      } else if (cropSpecies) {
        return "crops";
      } else {
        return null;
      }
    }
  }
  protected findEventInputFromXml(input) {
    const showNotFoundMessage = () =>
      this.simulationService.messageService.addAlert({
        type: "warning",
        msg: `Digest input - '${input.tIn}' not found in Events`,
        dismissible: true,
      });
    const eventOption = input.option.split(",");
    const regimeIndex = +eventOption[0] - 1;
    const eventsService = this.simulationService.eventsService;
    const allEvents = eventsService.getFormGroup().get("eventQ")["controls"];
    const regimes = eventsService.getRegimesFromEvents(
      allEvents.sort(
        (a: AbstractControl, b: AbstractControl) =>
          eventsService.getEventDate(a) - eventsService.getEventDate(b)
      )
    );
    const regimeInstance = regimes[regimeIndex].get("regimeInstance")?.value;
    if (!regimeInstance) {
      showNotFoundMessage();
      return;
    }
    const event = allEvents.find(
      (e) =>
        e.get("nmEV").value == eventOption[1].trim() &&
        e.get("regimeInstance").value == regimeInstance
    );
    if (!event) {
      showNotFoundMessage();
      return;
    }
    const evenFromService = eventsService.eventFormsService.getEventService(
      event.get("tEV").value
    );

    //apply eventData back to service's formGroup , so it has context
    evenFromService.getFormGroup().patchValue(event.getRawValue());
    evenFromService.setSimulationService(this.simulationService);

    const foundInput = this.findInputElementById(
      evenFromService.layout.groups,
      input.tIn
    );
    if (foundInput?.item) {
      const payload: AddToScenarioPayload = {
        service: evenFromService,
        input: foundInput.input,
        item: foundInput.item,
        addToAllScenarios: false,
        itemValue: this.convertXmlValueToFormValue(
          input.tIn,
          input.value,
          evenFromService.formModel
        ),
      };

      evenFromService.addInputToDigestScenario(payload);
    } else {
      showNotFoundMessage();
    }
  }

  protected findSpeciesInputFromXml(input, page) {
    const service =
      this.simulationService[page == "trees" ? "treesService" : "cropsService"];

    const layoutGroups = service.layout.groups;
    const modifierGroups = service.modifiers;

    const speciesName = input.option;
    const species = service.getSpeciesByName(speciesName);
    service.selectSpecies({ id: species.idSP });

    for (let groups of [layoutGroups, modifierGroups]) {
      if (!groups || !groups.length) {
        continue;
      }

      const foundInput = this.findInputElementById(groups, input.tIn);
      if (foundInput?.item) {
        const payload: AddToScenarioPayload = {
          service: service,
          input: foundInput.input,
          item: foundInput.item,
          addToAllScenarios: false,
          itemValue: this.convertXmlValueToFormValue(
            input.tIn,
            input.value,
            service.speciesFormModel
          ),
        };

        service.addInputToDigestScenario(payload);
      } else {
        this.simulationService.messageService.addAlert({
          type: "warning",
          msg: `Digest input - '${input.tIn}' not found in species`,
          dismissible: true,
        });
      }
    }
  }
  //all inputs other than event and species
  protected findNonNestedInputFromXml(input) {
    for (let service of this.simulationService.getCurrentFlowServices()) {
      const skippingPages = ["trees", "crops", "events"];

      if (skippingPages.includes(service.pageId) || !service.layout) {
        continue;
      }

      const layoutGroups = service.layout.groups;
      const modifierGroups = service.modifiers;
      for (let groups of [layoutGroups, modifierGroups]) {
        if (!groups || !groups.length) {
          continue;
        }
        const foundInput = this.findInputElementById(groups, input.tIn);
        if (foundInput?.item) {
          //IT SHOULD NOT OVERRIDE FORM VALUE FROM OTHER PAGE
          // let convertedInputObject = { [input.tIn]: input.value };
          // this.applyXmlValuesToFormGroup(
          //   convertedInputObject,
          //   service.getFormGroup(),
          //   service.formModel
          // );

          const inputValue =
            input.TimeSeries ||
            this.specialHandlingDigestInputsForReadWrite[input.tIn]?.read(
              foundInput.input,
              input.value
            ) ||
            input.value;

          const payload: AddToScenarioPayload = {
            service: service,
            input: foundInput.input,
            item: foundInput.item,
            addToAllScenarios: false,
            itemValue: this.convertXmlValueToFormValue(
              input.tIn,
              inputValue,
              service.formModel
            ),
          };
          service.addInputToDigestScenario(payload);
          return;
        }
      }
    }

    this.simulationService.messageService.addAlert({
      type: "warning",
      msg: `Digest input - '${input.tIn}' not found.`,
      dismissible: true,
    });
  }

  public getExplorerSubcategoryMap(data: ExplorerCatetory) {
    let explorerMap = {};

    const scenarios = data.service.getDigestScenariosFormArray().value;
    scenarios.forEach((s) => {
      let scenario = {
        [s.name]: {
          controls: s.controls.map((c) => {
            return {
              label: c.itemLabel,
              programmingName: c.programmingName,
              value: c.control.value,
              option: c.option,
              model: c.model,
              service: c.service,
              formGroup: c.service.getFormGroup(),
              displayValue: this.getInputDisplayValue(
                c.control.value,
                c.model,
                c.input
              ),
            };
          }),
        },
      };
      explorerMap = { ...explorerMap, ...scenario };
    });
    return explorerMap;
  }

  public readXmlObject(): void {
    this.getFormGroup();
    if (!this.simulationService.getPlotFileJson()) {
      return;
    }
    const template = this.simulationService.getPlotFileJson()["Digest"][0];
    const digest = template.$;
    const scenarios = template.DigestElement;

    if (scenarios && scenarios.length) {
      scenarios.forEach((sc) => {
        const formData = {
          enabled: sc.$.enabled == "true",
          name: sc.$.name,
          notes: sc.notes ? sc.notes[0] : "",
        };

        const inputElements = sc.InputElement;
        //add scenario will create form group and patch values
        this.addScenario(formData);

        if (inputElements && inputElements.length) {
          let allInputs = inputElements.map((i) => {
            let input = i.$;
            let tsValue = null;
            if (i.TimeSeries) {
              const { WinState, rawTS, ...restProp } = i.TimeSeries[0];
              if (!WinState || !rawTS) {
                const fieldName = i?.$?.tIn;

                this.simulationService.messageService.addAlert({
                  type: "danger",
                  msg: `Input element - '${fieldName}$' does not have a valid time series value.`,
                  dismissible: true,
                });
                return null;
              }
              tsValue = {
                $: restProp.$,
                WinState: { $: WinState[0].$ },
                rawTS: [{ $: { count: rawTS[0].$.count }, _: rawTS[0]._ }],
              };
            }

            //replace input name
            input.tIn =
              this.specialHandlingInputConversion[input.tIn] || input.tIn;

            return { ...input, ...(tsValue && { TimeSeries: tsValue }) };
          });

          for (const input of allInputs) {
            if (input == null) {
              continue;
            }
            try {
              const page = this.getPageByDigestElementOption(input.option);

              if (page == "event") {
                this.findEventInputFromXml(input);
              } else if (page == "trees" || page == "crops") {
                this.findSpeciesInputFromXml(input, page);
              } else {
                //for all other pages
                this.findNonNestedInputFromXml(input);
              }
            } catch (error) {
              this.simulationService.messageService.addAlert({
                type: "danger",
                msg: `Digest input - '${input.tIn}' not found`,
                dismissible: true,
              });
            }
          }
        }
      });
    }
    this.applyXmlValuesToFormGroup(digest, this.formGroup);
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();
    const { digestScenarios, ...formValues } = formData;
    const digest = this.getFormValueObjectForXmlExport(null, formValues);

    const xmlObject = {
      $: digest,
      DigestElement: [],
    };

    digestScenarios.forEach((ds) => {
      const inputElement = {
        $: { enabled: ds.enabled, name: ds.name },
        InputElement: [],
        notes: [ds.notes],
      };
      inputElement.InputElement = ds.controls.map((e) => {
        const page = this.getPageByDigestElementOption(e.option);
        const formModel =
          page == "trees" || page == "crops"
            ? e.service.speciesFormModel
            : e.service.formModel;
        const isTimeSeries = formModel[e.programmingName]?.dataType == 6;

        let value = this.specialHandlingDigestInputsForReadWrite[
          e.programmingName
        ]?.write(e.inputItem.inputs[0], e.control.value);

        if (value == undefined) {
          value = e.control.value;
        }

        const xmlValue = this.convertFormValueToXml(
          e.programmingName,
          value,
          formModel
        );

        return {
          $: {
            tIn: e.programmingName,
            option: e.option,
            ...(!isTimeSeries && {
              value: xmlValue,
            }),
          },
          ...(isTimeSeries && {
            TimeSeries: [e.control.value],
          }),
        };
      });

      xmlObject.DigestElement.push(inputElement);
    });

    return { Digest: [xmlObject] };
  }

  public reset(): void {
    this.setSelectedScenarioName(null);
    super.reset();
  }
}
