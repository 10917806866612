<div>
  <div class="header-controls">
    <div class="title">
      <button
        aria-label="back button"
        type="button"
        class="btn close-button"
        (click)="closeForm(null)"
      >
        <i class="bi bi-arrow-left-circle-fill"> </i>
      </button>

      <h2>
        Event {{ formGroup.valid ? "" : " (Invalid)" }}
        <a
          aria-label="help link button"
          type="button"
          *ngIf="service?.baseHelpPageUrl"
          class="btn"
          [href]="service.baseHelpPageUrl + '/' + eventHelpUrl"
          tooltip="View Event window help"
          target="_blank"
        >
          <i class="bi bi-question-circle"></i>
        </a>
      </h2>
    </div>
    <div [tooltip]="service?.getMinimumRequiredFieldTooltip()">
      <button
        [disabled]="service?.shouldDisableSave() || false"
        class="btn btn-primary"
        (click)="closeForm(formGroup)"
      >
        Save and close
      </button>
    </div>
  </div>

  <div class="main-container">
    <form [formGroup]="formGroup">
      <fieldset>
        <legend class="d-none">Event controls</legend>
        <ul class="event-controls">
          <li class="list-item">
            <label for="event-name">Name</label>
            <input
              autocomplete="off"
              id="event-name"
              name="event-name"
              class="form-control form-control-sm"
              type="text"
              formControlName="nmEV"
            />

            <fc-form-error-messages
              *ngIf="
                formGroup.get('nmEV')?.errors &&
                (formGroup.touched || formGroup.dirty)
              "
              [formControlInstance]="formGroup.get('nmEV')"
            >
            </fc-form-error-messages>

            <button
              class="btn btn-outline-primary btn-sm"
              id="generate-name-button"
              (click)="generateName()"
            >
              Generate name automatically
            </button>
          </li>
          <li class="list-item">
            <label for="event-type">Type</label>
            <select
              id="event-type"
              type="text"
              class="form-control form-select form-select-sm"
              (change)="changeEventType()"
              formControlName="tEV"
              [class]="
                getEventTypeName(formGroup.get('tEV').value) == 'mixed'
                  ? 'mixed-event'
                  : getEventTypeName(formGroup.get('tEV').value) == 'tree'
                  ? 'forest-event'
                  : 'agricultural-event'
              "
            >
              <option
                *ngFor="let eventType of speciesEventTypes"
                [value]="eventType.key"
                [class]="
                  eventType?.type == 'mixed'
                    ? 'mixed-event'
                    : eventType?.type == 'tree'
                    ? 'forest-event'
                    : 'agricultural-event'
                "
              >
                {{ eventType.label }}
              </option>
            </select>
          </li>
          <li class="list-item">
            <label for="event-notes">Notes</label>
            <textarea
              id="event-notes"
              name="event-notes"
              class="form-control form-control-sm"
              rows="3"
              formControlName="notesEV"
            ></textarea>
          </li>
        </ul>

        <fc-timing-form
          *ngIf="formGroup && isEventPage"
          [formGroupInstance]="formGroup"
          [displayDirection]="'vertical'"
          [startDate]="startDate"
          [simulationTimingType]="simulationTimingType"
          [eventsService]="simulationService.eventsService"
        ></fc-timing-form>
      </fieldset>
    </form>
    <div class="main-content">
      <form *ngIf="layout">
        <fieldset class="main-form-col">
          <legend class="visually-hidden">main form</legend>
          <div class="parent-group">
            <div class="header-controls">
              <h1 class="page-title">
                <div>
                  {{ layout.label }}
                </div>
                <a
                  aria-label="help link button"
                  type="button"
                  *ngIf="service.pageHelpLink"
                  class="btn"
                  [href]="service.baseHelpPageUrl + '/' + service.pageHelpLink"
                  [tooltip]="'View ' + layout.label + ' page help'"
                  target="_blank"
                >
                  <i class="bi bi-question-circle"></i>
                </a>
              </h1>

              <button
                *ngIf="isEventPage"
                [disabled]="shouldDisableInsertButton()"
                class="btn btn-outline-primary btn-sm"
                id="insert-standard-values-button"
                (click)="insertStandardValues()"
              >
                Insert standard values
              </button>
            </div>

            <div
              fc-form-group-element
              class="sub-groups"
              [formGroupInstance]="formGroup"
              [service]="service"
              [simulationService]="simulationService"
              [group]="group"
              *ngFor="let group of layout.groups"
            ></div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>

<ng-template #componentHost></ng-template>
