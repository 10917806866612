<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h2>Models and Inputs</h2>
    <ng-container *ngFor="let d of inputData">{{ d }}<br /></ng-container>
  </div>
</div>
