import { Injectable } from "@angular/core";
import { TreePlanting2020Service } from "./tree-planting-2020.service";
import { FormLayout } from "src/app/plot/models";
import { FieldWatcher, TreeSpecies } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class TreePlanting2024Service extends TreePlanting2020Service {
  public layout: FormLayout = {
    label: "Plant Trees",
    groups: [
      {
        showErrors: true,
        label: "Species",
        id: "species-label-group",
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Species",
            inputs: [
              {
                element: "text",
                method: "getSelectedSpeciesName",
                payload: "tree",
              },
            ],
          },
        ],
      },
      {
        label: null,
        id: "species-selector-group",
        isShown: false,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SpeciesSelectorComponent",
                componentInputs: [
                  {
                    inputKey: "formGroupInstance",
                    method: "getSpeciesFormGroup",
                    payload: "tree",
                  },
                  {
                    inputKey: "selectedId$",
                    variable: "selectedSpeciesId$",
                    isObservable: true,
                  },
                  {
                    inputKey: "selectOnly",
                    value: true,
                  },
                  {
                    inputKey: "type",
                    value: "tree",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "speciesSelected",
                    method: "selectSpecies",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isShown: true,
        label: null,
        showErrors: true,
        items: [
          {
            label: "Specify tree size by",
            inputs: [
              {
                element: "select",
                selectOptions: [
                  {
                    value: "Mass",
                    label: "Masses",
                  },
                  {
                    value: "Vol",
                    label: "Volume for stems, masses for other components",
                  },
                ],
                programmingName: "tStemPlnF",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        id: "tree-planting-tree-mass",
        label: "Masses [dmt/ha]",
        isUnderlineHeader: true,
        isUnderlineItems: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "FormItemTableComponent",
                componentInputs: [
                  {
                    inputKey: "caption",
                    value: "Masses [dmt/ha]",
                  },
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      headers: [],
                      rows: [
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Stems",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "stemMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Branches",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "branMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Bark",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "barkMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Leaves",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "leafMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Coarse roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "cortMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Fine roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "firtMPlnF",
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isShown: true,
        label: "Volumes [m3/ha]",
        id: "tree-planting-tree-vol",
        isUnderlineHeader: true,
        isUnderlineItems: true,
        showErrors: true,
        items: [
          {
            label: "Stems",
            shoartLabel: "",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "stemVolPlnF",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        label: "Ages [yr]",
        isUnderlineHeader: true,
        showErrors: true,
        items: [
          {
            label: "Trees",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "agePlnF",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        id: "tyf-growth-calibration",
        label: "Tree yield formula: Growth calibration",
        showErrors: true,
        items: [
          {
            label: "Growth calibration",
            inputs: [
              {
                element: "select",
                id: "tTYFCat",
                selectOptions: [
                  {
                    value: "Custom",
                    label: "User defined",
                  },
                  {
                    value: "BeltH",
                    label: "Belt plantings >1500 sph",
                  },
                  {
                    value: "BeltL",
                    label: "Belt plantings <1500 sph",
                  },
                  {
                    value: "BlockES",
                    label: "Block - environmental services",
                  },
                  {
                    value: "BlockLMG",
                    label: "Block - regeneration systems",
                  },
                  {
                    value: "Water",
                    label: "In riparian or floodplain zones",
                  },
                  {
                    value: "Short",
                    label: "Short rotation plantations",
                  },
                  {
                    value: "Long",
                    label: "Long rotation plantations",
                  },
                ],
                programmingName: "tTYFCat",
              },
              {
                element: "text",
                method: "getTYFCategory",
              },
            ],
          },
        ],
      },
      // {
      //   isShown: true,
      //   isUnderlineHeader: true,
      //   id: "growthCalibration",
      //   label: "Growth calibration",
      //   items: [
      //     {
      //       label: null,
      //       inputs: [
      //         {
      //           element: "component",
      //           component: "TyfCategoryTableComponent",
      //           componentInputs: [
      //             {
      //               inputKey: "selectedSpeciesId$",
      //               variable: "selectedSpeciesId$",
      //               isObservable: true,
      //             },
      //             {
      //               inputKey: "service",
      //               method: "getService",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  };

  public getTYFCategory(): string {
    const selectedTYF = this.getFormGroup().get("tTYFCat").value;
    const emptyTemplate = "G: --, r: --";
    if (!selectedTYF) {
      return emptyTemplate;
    }

    if (selectedTYF == "Custom") {
      return "G: 10, r: 1";
    }

    const speciesDetails: TreeSpecies = this.getSelectedSpeciesDetails();
    if (
      speciesDetails &&
      speciesDetails.SpeciesForest[0]?.Growth &&
      speciesDetails.SpeciesForest[0]?.Growth[0]["TYFParameters"]
    ) {
      try {
        const tYFCategories =
          speciesDetails.SpeciesForest[0].Growth[0]["TYFParameters"][0][
            "TYFCategory"
          ];

        const foundCategory = tYFCategories.find(
          (tyfC) => tyfC.$.tTYFCat == selectedTYF
        );

        if (foundCategory) {
          return `G: ${foundCategory.$.tyf_G}, r: ${foundCategory.$.tyf_r}`;
        }
      } catch (error) {
        console.error(error);
      }
    }

    return emptyTemplate;
  }

  public getSelectedSpeciesDetails() {
    const id = this.selectedSpeciesId$.getValue();
    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByIdRegimeSP(
      "tree",
      id
    );
    if (!selectedSpeciesFormGroup) {
      return;
    }

    const idRegimeSP = selectedSpeciesFormGroup.get("idRegimeSP").value;

    const species =
      this.simulationService.dataBuilderService.getAllSelectedSpecies("tree");
    //Species details' idSP = tree spieces idRegimeSP
    return species?.find((s) => s["SpeciesForest"][0].$.idSP == idRegimeSP);
  }

  public fieldWatcher: FieldWatcher = {
    ...this.fieldWatcher,
    idSP: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      formModel.tTYFCat.isShown = newValue !== null && newValue !== undefined;
    },
  };
}
