import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { BaseModalFormComponent } from "../../../base-modal-form/base-modal-form.component";
import { ForestThinning2020Service } from "../forest-thinning-2020.service";
import { SimulationService } from "src/app/shared/models";
import { Simulation2020Service } from "src/app/core/services/simulation-2020.service";

const destinationPercentagesGroups: FormGroupElement[] = [
  {
    label: "Stems to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Paper and pulp",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPaprThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPaprThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPaprThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPaprThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPaprThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Packing wood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPackThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPackThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPackThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPackThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToPackThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Furniture",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFurnThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFurnThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFurnThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFurnThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFurnThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fiberboard",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFibrThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFibrThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFibrThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFibrThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFibrThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Construction",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToConsThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToConsThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToConsThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToConsThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToConsThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Mill residue",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToResiThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToResiThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToResiThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToResiThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToResiThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToFuelThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Deadwood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToDdwdThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToDdwdThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToDdwdThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToDdwdThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToDdwdThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        value: "Total",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracStemToPaprThin1",
                          "fracStemToPackThin1",
                          "fracStemToFurnThin1",
                          "fracStemToFibrThin1",
                          "fracStemToConsThin1",
                          "fracStemToResiThin1",
                          "fracStemToFuelThin1",
                          "fracStemToDdwdThin1",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracStemToPaprThin2",
                          "fracStemToPackThin2",
                          "fracStemToFurnThin2",
                          "fracStemToFibrThin2",
                          "fracStemToConsThin2",
                          "fracStemToResiThin2",
                          "fracStemToFuelThin2",
                          "fracStemToDdwdThin2",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracStemToPaprThin3",
                          "fracStemToPackThin3",
                          "fracStemToFurnThin3",
                          "fracStemToFibrThin3",
                          "fracStemToConsThin3",
                          "fracStemToResiThin3",
                          "fracStemToFuelThin3",
                          "fracStemToDdwdThin3",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracStemToPaprThin4",
                          "fracStemToPackThin4",
                          "fracStemToFurnThin4",
                          "fracStemToFibrThin4",
                          "fracStemToConsThin4",
                          "fracStemToResiThin4",
                          "fracStemToFuelThin4",
                          "fracStemToDdwdThin4",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracStemToPaprThin5",
                          "fracStemToPackThin5",
                          "fracStemToFurnThin5",
                          "fracStemToFibrThin5",
                          "fracStemToConsThin5",
                          "fracStemToResiThin5",
                          "fracStemToFuelThin5",
                          "fracStemToDdwdThin5",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Branches to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Paper and pulp",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPaprThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPaprThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPaprThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPaprThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPaprThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Packing wood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPackThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPackThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPackThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPackThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToPackThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Furniture",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFurnThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFurnThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFurnThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFurnThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFurnThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fiberboard",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFibrThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFibrThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFibrThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFibrThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFibrThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Construction",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToConsThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToConsThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToConsThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToConsThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToConsThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Mill residue",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToResiThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToResiThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToResiThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToResiThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToResiThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToFuelThin5",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Deadwood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToDdwdThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToDdwdThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToDdwdThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToDdwdThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToDdwdThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        value: "",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBranToPaprThin1",
                          "fracBranToPackThin1",
                          "fracBranToFurnThin1",
                          "fracBranToFibrThin1",
                          "fracBranToConsThin1",
                          "fracBranToResiThin1",
                          "fracBranToFuelThin1",
                          "fracBranToDdwdThin1",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBranToPaprThin2",
                          "fracBranToPackThin2",
                          "fracBranToFurnThin2",
                          "fracBranToFibrThin2",
                          "fracBranToConsThin2",
                          "fracBranToResiThin2",
                          "fracBranToFuelThin2",
                          "fracBranToDdwdThin2",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBranToPaprThin3",
                          "fracBranToPackThin3",
                          "fracBranToFurnThin3",
                          "fracBranToFibrThin3",
                          "fracBranToConsThin3",
                          "fracBranToResiThin3",
                          "fracBranToFuelThin3",
                          "fracBranToDdwdThin3",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBranToPaprThin4",
                          "fracBranToPackThin4",
                          "fracBranToFurnThin4",
                          "fracBranToFibrThin4",
                          "fracBranToConsThin4",
                          "fracBranToResiThin4",
                          "fracBranToFuelThin4",
                          "fracBranToDdwdThin4",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBranToPaprThin5",
                          "fracBranToPackThin5",
                          "fracBranToFurnThin5",
                          "fracBranToFibrThin5",
                          "fracBranToConsThin5",
                          "fracBranToResiThin5",
                          "fracBranToFuelThin5",
                          "fracBranToDdwdThin5",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Bark to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Paper",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToPaprThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToPaprThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToPaprThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToPaprThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToPaprThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Mill residue",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToResiThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToResiThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToResiThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToResiThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToResiThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToFuelThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToBlitThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToBlitThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToBlitThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToBlitThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToBlitThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        value: "",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBarkToPaprThin1",
                          "fracBarkToResiThin1",
                          "fracBarkToFuelThin1",
                          "fracBarkToBlitThin1",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBarkToPaprThin2",
                          "fracBarkToResiThin2",
                          "fracBarkToFuelThin2",
                          "fracBarkToBlitThin2",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBarkToPaprThin3",
                          "fracBarkToResiThin3",
                          "fracBarkToFuelThin3",
                          "fracBarkToBlitThin3",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBarkToPaprThin4",
                          "fracBarkToResiThin4",
                          "fracBarkToFuelThin4",
                          "fracBarkToBlitThin4",
                        ],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: [
                          "fracBarkToPaprThin5",
                          "fracBarkToResiThin5",
                          "fracBarkToFuelThin5",
                          "fracBarkToBlitThin5",
                        ],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Leaves to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToFuelThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        value: "",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracLeafToFuelThin1", "fracLeafToLlitThin1"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracLeafToFuelThin2", "fracLeafToLlitThin2"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracLeafToFuelThin3", "fracLeafToLlitThin3"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracLeafToFuelThin4", "fracLeafToLlitThin4"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracLeafToFuelThin5", "fracLeafToLlitThin5"],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Coarse roots to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToFuelThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrThin5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        value: "",
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracCortToFuelThin1", "fracCortToCodrThin1"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracCortToFuelThin2", "fracCortToCodrThin2"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracCortToFuelThin3", "fracCortToCodrThin3"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracCortToFuelThin4", "fracCortToCodrThin4"],
                      },
                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["fracCortToFuelThin5", "fracCortToCodrThin5"],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Fine roots to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrThin5",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Deadwood to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDdwdToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDdwdToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDdwdToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDdwdToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDdwdToFuelThin5",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Chopped wood to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracChwdToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracChwdToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracChwdToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracChwdToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracChwdToFuelThin5",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Bark litter to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBlitToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBlitToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBlitToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBlitToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBlitToFuelThin5",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: "Leaf litter to",
    isUnderlineHeader: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Log grade percentages",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Grade 1",
                    "Grade 2",
                    "Grade 3",
                    "Grade 4",
                    "Grade 5",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLlitToFuelThin1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLlitToFuelThin2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLlitToFuelThin3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLlitToFuelThin4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLlitToFuelThin5",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const costGroups: FormGroupElement[] = [
  {
    label: null,
    isUnderlineHeader: true,
    isShown: true,
    id: "cost-group",
    items: [
      {
        label: "Grade 1",
        inputs: [
          {
            element: "input",
            type: "text",
            programmingName: "nmGradeF1",
          },
        ],
      },
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Costs and prices - stems, branches",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Unit",
                    "Water %",
                    "",
                    "Density [dmt/m3]",
                    "Harvesting cost",
                    "Transport cost",
                    "Mill price",
                    "Base year",
                    "Harvesting cost %",
                    "Transport cost %",
                    "Mill price %",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems, Branches",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRWood1",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRWood1",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRWood1",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRBark1",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRBark1",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRBark1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark1",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRFuel1",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRFuel1",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRFuel1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark1",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },

      {
        label: "Grade 2",
        inputs: [
          {
            element: "input",
            type: "text",
            programmingName: "nmGradeF2",
          },
        ],
      },
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Costs and prices - stems, branches",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Unit",
                    "Water %",
                    "",
                    "Density [dmt/m3]",
                    "Harvesting cost",
                    "Transport cost",
                    "Mill price",
                    "Base year",
                    "Harvesting cost %",
                    "Transport cost %",
                    "Mill price %",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems, Branches",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRWood2",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRWood2",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRWood2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRWood2",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRBark2",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRBark2",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRBark2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark2",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRFuel2",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRFuel2",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRFuel2",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark2",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },

      {
        label: "Grade 3",
        inputs: [
          {
            element: "input",
            type: "text",
            programmingName: "nmGradeF3",
          },
        ],
      },
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Costs and prices - grade 3 - stems, branches",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Unit",
                    "Water %",
                    "",
                    "Density [dmt/m3]",
                    "Harvesting cost",
                    "Transport cost",
                    "Mill price",
                    "Base year",
                    "Harvesting cost %",
                    "Transport cost %",
                    "Mill price %",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems, Branches",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],

                        programmingName: "tPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRWood3",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRWood3",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRWood3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRWood3",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRBark3",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRBark3",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRBark3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark3",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRFuel3",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRFuel3",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRFuel3",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark3",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },

      {
        label: "Grade 4",
        inputs: [
          {
            element: "input",
            type: "text",
            programmingName: "nmGradeF4",
          },
        ],
      },
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Costs and prices - stems, branches",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Unit",
                    "Water %",
                    "",
                    "Density [dmt/m3]",
                    "Harvesting cost",
                    "Transport cost",
                    "Mill price",
                    "Base year",
                    "Harvesting cost %",
                    "Transport cost %",
                    "Mill price %",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems, Branches",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRWood1",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRWood4",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRWood4",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRWood4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRWood4",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRBark4",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRBark4",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRBark4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark4",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRFuel4",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRFuel4",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRFuel4",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark4",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },

      {
        label: "Grade 5",
        inputs: [
          {
            element: "input",
            type: "text",
            programmingName: "nmGradeF5",
          },
        ],
      },
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Costs and prices - stems, branches",
              },

              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "formGroupPointer",
                value: "getLogGradesFormGroup",
              },
              {
                inputKey: "customFormModelPointer",
                value: "getSpeciesFormModel",
              },

              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Unit",
                    "Water %",
                    "",
                    "Density [dmt/m3]",
                    "Harvesting cost",
                    "Transport cost",
                    "Mill price",
                    "Base year",
                    "Harvesting cost %",
                    "Transport cost %",
                    "Mill price %",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems, Branches",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRWood5",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRWood5",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRWood5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRWood5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRBark5",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRBark5",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRBark5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark5",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "select",
                        width: "140px",

                        selectOptions: [
                          { value: "PriceBlank", label: "select one" },
                          { value: "PricePerGmt", label: "Price per Gmt" },
                          { value: "PricePerDmt", label: "Price per Dmt" },
                          { value: "PricePerM3", label: "Price per M3" },
                        ],
                        programmingName: "tPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracWaterPRFuel5",
                      },
                      {
                        element: "checkbox",
                        width: "90px",
                        label: "Use stem density",
                        programmingName: "useStemDensityPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "densityPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "harvCostPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "trnsCostPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "millPricePRFuel5",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "stYearPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaHarvPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaTrnsPRFuel5",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "incrPaMillPRBark5",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
@Component({
  selector: "fc-log-grades-form",
  templateUrl: "./log-grades-form.component.html",
  styleUrls: ["./log-grades-form.component.scss"],
})
export class LogGradesFormComponent
  extends BaseModalFormComponent
  implements OnInit
{
  //modal inputs
  public simulationService: Simulation2020Service;
  public forestThinningService: ForestThinning2020Service;
  public formGroupInstance: UntypedFormGroup;

  public layout: FormLayout = {
    label: "",
    groups: [
      {
        label: "Destination percentages",
        isShown: true,
        isRoot: true,
        items: [
          {
            groups: destinationPercentagesGroups,
          },
        ],
      },
      {
        label: "Name ()",
        isShown: true,
        isRoot: true,
        items: [
          {
            groups: destinationPercentagesGroups,
          },
        ],
      },
      // {
      //   label: "Names (optional)",
      //   isShown: true,
      //   isRoot: true,
      //   items: [
      //     {
      //       label: "Grade 1",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "text",
      //           programmingName: "nmGradeF2",
      //         },
      //       ],
      //     },
      //     {
      //       label: "Grade 1",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "text",
      //           programmingName: "nmGradeF2",
      //         },
      //       ],
      //     },
      //     {
      //       label: "Grade 3",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "text",
      //           programmingName: "nmGradeF3",
      //         },
      //       ],
      //     },
      //     {
      //       label: "Grade 4",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "text",
      //           programmingName: "nmGradeF4",
      //         },
      //       ],
      //     },
      //     {
      //       label: "Grade 5",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "text",
      //           programmingName: "nmGradeF5",
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        label: "Cost and prices",
        isShown: true,
        isRoot: true,
        items: [
          {
            groups: costGroups,
          },
        ],
      },
    ],
  };

  async ngOnInit(): Promise<void> {
    const costGroup = this.forestThinningService.getLayoutGroup(
      this.layout.groups,
      "cost-group"
    );
  }

  async ngAfterViewInit() {}

  //for formitem tables
  public getService() {
    return this.forestThinningService;
  }
}
