import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Interactable } from "@interactjs/core/Interactable";
import { DragEvent, ResizeEvent } from "@interactjs/types";
import interact from "interactjs";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "fc-draggable-window",
  templateUrl: "./draggable-window.component.html",
  styleUrls: ["./draggable-window.component.scss"],
})
export class DraggableWindowComponent implements OnInit, OnDestroy {
  @Output() dragend = new EventEmitter<DragEvent>();
  @Output() resizeend = new EventEmitter<ResizeEvent>();

  private interact: Interactable;
  private readonly id = "_" + uuidv4();

  constructor(private hostElement: ElementRef) {}

  ngOnInit(): void {
    this.hostElement.nativeElement.id = this.id;
    this.interact = interact("#" + this.id)
      .resizable({
        // resize from all edges and corners
        edges: { right: true, bottom: true },
        listeners: {
          move(event) {
            const target = event.target;
            let x = parseFloat(target.getAttribute("data-x")) || 0;
            let y = parseFloat(target.getAttribute("data-y")) || 0;

            // update the element's style
            target.style.width = event.rect.width + "px";
            target.style.height = event.rect.height + "px";

            // translate when resizing from top or left edges
            x += event.deltaRect.left;
            y += event.deltaRect.top;

            target.style.transform = "translate(" + x + "px," + y + "px)";

            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
          },
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: "parent",
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 100, height: 50 },
          }),
        ],

        inertia: true,
      })
      .draggable({
        listeners: { move: this.dragMoveListener },
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "parent",
            endOnly: true,
          }),
        ],
      });

    this.interact.on("dragend", (e) => {
      this.dragend.emit(e);
    });
    this.interact.on("resizeend", (e) => {
      this.resizeend.emit(e);
    });
  }

  dragMoveListener(event) {
    const target = event.target,
      // keep the dragged position in the data-x/data-y attributes
      x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx,
      y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.webkitTransform = target.style.transform =
      "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }

  public getId(): string {
    return this.id;
  }

  ngOnDestroy(): void {
    this.interact.unset();
  }
}
