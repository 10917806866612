<ng-container
  [formGroup]="formGroupInstance"
  *ngIf="
    formGroupInstance && formGroupInstance.get('species')['controls']?.length;
    else noSpecies
  "
>
  <ng-container formArrayName="species">
    <ul
      *ngIf="formGroupInstance"
      class="list-group list-group-flush"
      [formGroup]="getSelectedSpeciesFormGroup(selectedId$ | async)"
    >
      <li class="list-item">
        <label class="item-label" for="species-select">Select a species </label>

        <select
          [class]="{ 'is-invalid': isInvalid() }"
          id="species-select"
          type="text"
          class="form-control form-select form-select-sm"
          (change)="selectSpecies($event)"
        >
          <option *ngIf="selectOnly && (selectedId$ | async) == -1">
            Please select one
          </option>
          <option
            *ngFor="let sControl of filteredSpecies"
            [selected]="
              sControl.get('idRegimeSP').value == (selectedId$ | async)
            "
          >
            {{ sControl.get("nmSP")?.value }}
          </option>
        </select>
      </li>

      <ng-container *ngIf="!selectOnly">
        <li class="list-item" [formGroup]="formGroupInstance">
          <label class="item-label" for="species-in-use-switch"
            >Only list species in use
          </label>
          <div form-field class="form-check form-switch">
            <input
              id="species-in-use-switch"
              class="form-check-input switch-input"
              type="checkbox"
              role="switch"
              formControlName="showOnlyInUse"
            />
          </div>
        </li>

        <li class="list-item button-group">
          <div class="usage">
            {{ formGroupInstance.get("species").value.length }} species,
            {{ (speciesInUse$ | async).length }} in use
          </div>

          <button
            class="btn btn-sm btn-outline-primary"
            (click)="buttonClickedEvent('new')"
          >
            New
          </button>
          <button
            class="btn btn-sm btn-outline-primary"
            [disabled]="!formGroupInstance.get('species').controls.length"
            (click)="buttonClickedEvent('clone')"
          >
            Clone
          </button>
          <button
            class="btn btn-sm btn-outline-primary"
            [disabled]="!formGroupInstance.get('species').controls.length"
            (click)="buttonClickedEvent('delete')"
          >
            Delete
          </button>
          <button
            class="btn btn-sm btn-outline-primary"
            [disabled]="!formGroupInstance.get('species').controls.length"
            (click)="buttonClicked.emit({ event: 'usage' })"
          >
            Usage
          </button>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>

<ng-template #noSpecies> No {{ type }} species </ng-template>
