import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { FlyOverPanelService } from "src/app/shared/components/fly-over-panel/services/fly-over-panel.service";
import { SpeciesEvent, SpeciesEventType } from "src/app/shared/models";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"],
})
export class EventsComponent extends BaseFlowComponent implements OnInit {
  // readonly ColumnMode = ColumnMode;
  // readonly SelectionType = SelectionType;
  // // public title: string = "";
  // // public unit: string = "";
  // // public formGroup: FormGroup = new FormGroup({});
  // public events: SpeciesEvent[] = [];
  // public availableEventTypes: SpeciesEventType[] = [];
  // public editingEvent: SpeciesEvent = null;
  // public isEventFormOpen: boolean = false;
  // // constructor(public flyOverPanelService: FlyOverPanelService) {}
  // public createEvent() {
  //   this.isEventFormOpen = true;
  // }
  // public cloneEvent() {}
  // public editEvent(row) {
  //   this.editingEvent = row;
  //   this.isEventFormOpen = true;
  // }
}
