<div class="controls search-container">
  <div class="input-group">
    <input
      id="tree-view-search"
      aria-label="Search projects and collections"
      [(ngModel)]="selectedTypeAheadModel"
      (keyup)="filterItems(_data)"
      [placeholder]="'Search projects and collections'"
      class="form-control form-control-sm autocomplete"
    />
    <button
      id="tree-view-clear-search"
      tooltip="Clear search"
      *ngIf="selectedTypeAheadModel"
      class="btn clear-button btn-sm"
      (click)="clearTypeAheadModel(_data)"
      type="reset"
    >
      <i class="bi bi-x-lg"></i>
    </button>
  </div>
</div>
<div class="top-controls">
  <button
    class="btn btn-primary-darken btn-sm"
    (click)="openProjectForm('project')"
    type="button"
  >
    Create project
  </button>
</div>

<ul class="list-group">
  <li class="list-group-item" *ngFor="let item of _data">
    <div
      class="item-group"
      [class]="{
        'no-subitem': !item[subitemKey]?.length,
        'search-matched': item.isSearchMatch
      }"
    >
      <span class="text-group" [title]="item.name">
        <button
          [attr.aria-controls]="'accordion-content-' + item.projectId"
          [attr.aria-expanded]="item.isExpanded"
          [id]="'accordion-control-' + item.projectId"
          class="btn btn-sm"
          (click)="item.isExpanded = !item.isExpanded"
          *ngIf="item[subitemKey]?.length"
        >
          <span class="visually-hidden">{{
            item.isExpanded ? "collapse" : "expand"
          }}</span>
          <i
            class="bi bi-chevron-right"
            [class]="{
              expanded: item.isExpanded
            }"
          >
          </i>
        </button>
        <i
          class="bi bi-folder-fill"
          [popover]="item.description"
          popoverTitle="Project description"
          triggers="mouseenter:mouseleave"
        ></i>
        <a
          href="#"
          *ngIf="hasLabelAction"
          [tooltip]="itemLabelTooltip"
          container="body"
          (click)="itemLabelClicked.emit(item); $event.preventDefault()"
          >{{ item.name }}</a
        ><ng-container *ngIf="!hasLabelAction">{{ item.name }}</ng-container>
      </span>
      <div class="button-group">
        <span class="badge bg-primary rounded-pill">{{
          item[subitemKey]?.length
        }}</span>
        <button type="button" class="btn btn-sm" data-bs-toggle="dropdown">
          <span class="visually-hidden">Toggle dropdown</span>
          <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu">
          <li class="dropdown-item">
            <a
              href="#"
              (click)="
                openProjectForm('project', null, item); $event.preventDefault()
              "
            >
              <i class="bi bi-pencil-fill"></i> Edit</a
            >
          </li>
          <li class="dropdown-item">
            <a
              href="#"
              (click)="
                openProjectForm('collection', item); $event.preventDefault()
              "
            >
              <i class="bi bi-folder-plus"></i>Add a collection to '{{
                item.name
              }}'
            </a>
          </li>
          <li class="dropdown-item">
            <a
              href="#"
              (click)="deleteItem.emit(item); $event.preventDefault()"
            >
              <i class="bi bi-trash-fill"></i>Delete
            </a>
          </li>
        </ul>
      </div>
    </div>

    <ul
      [id]="'accordion-content-' + item.name"
      class="subitem-list"
      *ngIf="item[subitemKey]"
      [class]="{ expanded: item.isExpanded }"
      [attr.aria-hidden]="!item.isExpanded"
    >
      <li
        class="subitem"
        *ngFor="let subitem of item[subitemKey]"
        [class]="{ 'search-matched': subitem.isSearchMatch }"
      >
        <span class="text-group" [title]="subitem.name"
          ><i
            class="bi bi-folder-fill"
            [popover]="item.description"
            popoverTitle="Collection description"
            triggers="mouseenter:mouseleave"
          ></i>
          <a
            href="#"
            *ngIf="hasLabelAction"
            (click)="itemLabelClicked.emit(subitem)"
            [tooltip]="subitemLabelTooltip"
            container="body"
            >{{ subitem.name }}</a
          ><ng-container *ngIf="!hasLabelAction">{{
            subitem.name
          }}</ng-container></span
        >

        <div class="button-group">
          <button
            class="btn btn-sm"
            (click)="subitemClicked.emit(subitem)"
            [tooltip]="subitemSubmitTooltip"
            container="body"
          >
            <span class="visually-hidden"
              >get plot files under this collection</span
            >
            <i class="bi bi-arrow-right"></i>
          </button>

          <button type="button" class="btn btn-sm" data-bs-toggle="dropdown">
            <span class="visually-hidden">toggle drop down</span>
            <i class="bi bi-three-dots-vertical"></i>
          </button>
          <ul class="dropdown-menu">
            <li class="dropdown-item">
              <a
                href="#"
                (click)="
                  openProjectForm('collection', item, subitem);
                  $event.preventDefault()
                "
              >
                <i class="bi bi-pencil-fill"></i> Edit</a
              >
            </li>
            <li class="dropdown-item">
              <a
                href="#"
                (click)="deleteItem.emit(subitem); $event.preventDefault()"
              >
                <i class="bi bi-trash-fill"></i>Delete</a
              >
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </li>
</ul>
