import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { take, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { ModalOptions } from "ngx-bootstrap/modal";
import { PlotTableColumns } from "src/app/my-plots/models";
import { ModalService } from "src/app/shared/services/modal.service";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { CreateEstateFormComponent } from "../create-estate-form/create-estate-form.component";
import { EstatePlot } from "src/app/plot/models";
import { CloneEstateFormComponent } from "../clone-estate-form/clone-estate-form.component";
import { EstateService, PlotFilesService } from "src/app/shared/models";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "fc-plots-in-estate",
  templateUrl: "./plots-in-estate.component.html",
  styleUrls: ["./plots-in-estate.component.scss"],
})
export class PlotsInEstateComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  public service: EstateService;
  public selectedPlotFiles: PlotTableColumns[] = [];
  public estatePlots: EstatePlot[] = [];

  public searchString: string = ""; //for plot file search
  public tempEstatePlots: EstatePlot[] = []; //for plot file search

  public isLoading: boolean = false;
  public isBulkActionLoading$ = new BehaviorSubject<boolean>(false);
  public selectedBulkAction:
    | "clone"
    | "delete"
    | "export"
    | "simTrue"
    | "simFalse"
    | "estTrue"
    | "estFalse" = "clone";
  public selectedRows: EstatePlot[] = [];
  // public plotFiles: PlotTableColumns[] = [];
  public statusFilter: "complete" | "pending" | null = null;

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  @ViewChild("plotTable") plotTable: DatatableComponent;

  constructor(
    private modalService: ModalService,
    private myPlotsService: MyPlotsService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    const plotFilesService: PlotFilesService =
      this.service.simulationService.plotFilesService;
    const plotFilesControl = plotFilesService.getFormGroup().get("plotFiles");

    this.selectedPlotFiles = plotFilesControl.value;

    this.service
      .getFormGroup()
      .get("estatePlots")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((estatePlots) => {
        setTimeout(() => {
          const plotInfo = this.calaculatePlotInfo(
            this.service.getEstatePlotsFormArray().value
          );
          this.service.setPlotInfomation(plotInfo);
          this.resetSearchFilter();
        }, 1);
      });

    const plotInfo = this.calaculatePlotInfo(
      this.service.getEstatePlotsFormArray().value
    );

    this.tempEstatePlots = this.service.getEstatePlotsFormArray().value;
    this.service.setPlotInfomation(plotInfo);
  }

  private calaculatePlotInfo(estatePlots: EstatePlot[]) {
    return estatePlots.reduce(
      (acc, ePlot) => {
        let area = 0;
        if (ePlot.hasAreaEP) {
          area += isNaN(+ePlot.plotAreaEP) ? 0 : +ePlot.plotAreaEP;
        } else {
          area += isNaN(+ePlot.areaEP) ? 0 : +ePlot.areaEP;
        }

        acc.totalPlots += 1;
        acc.totalArea += area;

        const isNonSimulating = ePlot.simulateEP == false;

        if (isNonSimulating) {
          acc.nonSimulatingPlots += 1;
          acc.nonSimulatingArea += area;
          return acc;
        } else {
          acc.simulatingPlots += 1;
          acc.simulatingArea += area;
        }
        return acc;
      },
      {
        totalPlots: 0,
        simulatingPlots: 0,
        nonSimulatingPlots: 0,
        totalArea: 0,
        simulatingArea: 0,
        nonSimulatingArea: 0,
      }
    );
  }

  public getStatusLegend(legendValue): { color: string; label: string } {
    const legend = this.service.legend;
    return legend.find((l) => l.label == legendValue);
  }

  public onSelect({ selected }): void {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
    const plotInfo = this.calaculatePlotInfo(this.selectedRows);
    this.service.setSelectedPlotInfomation(plotInfo);
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.plotTable.selected = [];
  }

  public onActivate(event) {}

  public async editPlotFile(plotId: string) {
    const plotFilesService = this.service.simulationService.plotFilesService;
    const plotFileRow = plotFilesService.getPlotFileRowById(plotId);

    await plotFilesService.editPlotFile(plotFileRow, "Back to Plots in Estate");
  }

  public async editEstateForm(row: EstatePlot): Promise<void> {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      class: "full-screen",
      initialState: {
        service: this.service,
        estateStartDate: this.service.getStartYear(),
        estateStep: this.service.getStartStep(),
        formData: row,
      },
    };

    this.modalService
      .openModal(CreateEstateFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((estatePlot) => {
        if (estatePlot) {
          this.service.updateEstatePlot(estatePlot);
        }
      });
  }

  public async deletePlotFiles(rows: EstatePlot[]): Promise<void> {
    const ids = rows.map((p) => p.id);
    this.service.deleteEstatePlots(ids);
  }

  public async bulkAction() {
    const ids = this.selectedRows.map((p) => p.id);
    const bulkAction = this.selectedBulkAction;

    this.isBulkActionLoading$.next(true);

    switch (bulkAction) {
      case "delete":
        this.deletePlotFiles(this.selectedRows);
        break;

      case "clone":
        this.cloneEstatePlots(this.selectedRows);
        break;

      case "export":
        //await this.exportAllPlotFiles();
        break;

      case "simTrue":
        this.service.setSimulationFalgForPlots(true, ids);
        break;
      case "simFalse":
        this.service.setSimulationFalgForPlots(false, ids);
        break;

      case "estTrue":
        this.service.setEstateTimingUsedForPlots(true, ids);
        break;
      case "estFalse":
        this.service.setEstateTimingUsedForPlots(false, ids);
        break;
    }

    this.isBulkActionLoading$.next(false);
    this.deselectAll();
  }

  protected setSimulationFalgForPlots(isSimulate: boolean, ids: string) {}
  protected setTimingUsedForPlots(
    timingType: "Estate" | "Plot",
    ids: string[]
  ) {}

  public async exportAllPlotFiles() {
    this.deselectAll();

    // const simulationService = this.appService.getSimulationService();
    // const filteredRows = this.tempEstatePlots.filter(
    //   (r) => r.plotId !== null && r.plotId !== undefined
    // );
    // const ids = filteredRows.map((r) => r.plotId);
    // const numDiff = ids.length !== this.tempEstatePlots.length;

    // if (numDiff) {
    //   simulationService.messageService.addAlert({
    //     type: "warning",
    //     msg: `${
    //       this.tempEstatePlots.length - ids.length
    //     } of Plot files could not be exported due to missing id.`,
    //   });
    // }

    // this.myPlotsService
    //   .downloadPlotFiles(ids)
    //   .pipe(take(1))
    //   .subscribe((xmlPlotsResults) => {
    //     this.isBulkActionLoading$.next(false);

    //     xmlPlotsResults.forEach(async (xmlPlotResult, index) => {
    //       const plotRow = filteredRows[index];
    //       if (plotRow.plotId !== xmlPlotResult.id) {
    //         console.error("Id is not matching for " + plotRow.fileName);
    //       }
    //      await this.updateEstateValuesToPlotBeforeExport(
    //         xmlPlotResult.result,
    //         plotRow
    //       );
    //       // simulationService.helperService.downloadFile(
    //       //   plotRow.fileName,
    //       //   xmlPlotResult.result,
    //       //   "plo"
    //       // );
    //     });
    //   });
  }

  protected async updateEstateValuesToPlotBeforeExport(plotXml, estateInfo) {
    const simulationService = this.appService.getSimulationService();

    const plotFileJson = await simulationService.helperService.convertXmlToJson(
      plotXml
    );
  }

  public cloneEstatePlots(inputRows: EstatePlot[]) {
    const rows = [...inputRows];
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      class: "full-screen",
      initialState: {},
    };

    this.modalService
      .openModal(CloneEstateFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((cloneSettings) => {
        if (cloneSettings) {
          rows.forEach((r) => {
            this.service.cloneEstatePlot({
              ...cloneSettings,
              formValues: r,
            });
          });
        }
      });
  }

  public createEstatePlot() {
    const initialState: ModalOptions = {
      class: "full-screen",
      ignoreBackdropClick: true,
      initialState: {
        service: this.service,

        estateStartDate: this.service.getStartYear(),
        estateStep: this.service.getStartStep(),
      },
    };

    this.modalService
      .openModal(CreateEstateFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((estatePlot) => {
        if (estatePlot) {
          this.service.createEstatePlot(estatePlot);
        }
      });
  }

  public hasPlotFiles(): boolean {
    const estatePlots = this.service.getEstatePlotsFormArray().value;
    return estatePlots.length > 0;
  }

  public validateEstatePlots() {
    this.service.validateEstatePlots();
  }

  public relinkPlotFiles(): void {
    this.service.simulationService.plotFilesService.uploadPlotFilesAndRelink();
  }

  public hasBrokenLinkPlotFiles(): boolean {
    const estateFormArray = this.service.getEstatePlotsFormArray();
    return (
      estateFormArray.controls.filter(
        (ep) =>
          ep.get("plotId").value == null || ep.get("plotId").value == undefined
      ).length > 0
    );
  }

  public updateFilter() {
    const estatePlots = this.service.getEstatePlotsFormArray().value;
    const val = this.searchString.toLowerCase();
    const filteredPlotFiles = estatePlots.filter((d) => {
      return d.fileName.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.tempEstatePlots = filteredPlotFiles;
    this.plotTable.offset = 0;
  }

  private resetSearchFilter() {
    this.searchString = "";
    this.tempEstatePlots = this.service.getEstatePlotsFormArray().value;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
