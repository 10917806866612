import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";
import { RotationEvent, RotationForest } from "../../models";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";
import { AbstractControl, FormControl, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class RotationForestFireService extends BaseRotationEventService {
  public _type = "RotationForestFire";

  public additionalFormControls = [
    {
      label: "% area burnt",
      key: "percentAreaBurnt",
      element: "input",
      type: "number",
    },
  ];

  constructor(
    public rotationEvent: RotationEvent,
    public rotationFormGroup: AbstractControl<RotationForest>,
    public managementService: RmtManagementService
  ) {
    super(rotationEvent, rotationFormGroup, managementService);

    this.eventFormGroup.addControl(
      "percentAreaBurnt",
      new FormControl(0.0, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ])
    );

    if (this.regimeEventReference.Value?.FullCAMFirF.fracAfctFirF) {
      let defaultValue =
        +this.regimeEventReference.Value.FullCAMFirF.fracAfctFirF;
      //convert to percentage
      if (!isNaN(defaultValue)) {
        defaultValue = defaultValue * 100;
      }

      this.eventFormGroup.get("percentAreaBurnt").setValue(defaultValue);
    }
  }

  public getIntensity() {
    const formValue = +this.eventFormGroup.get("percentAreaBurnt").value;
    return `Percentage Area Burnt: ${formValue.toFixed(2)}%`;
  }

  override writeXmlObject() {
    let template = super.writeXmlObject();
    const formValue = +this.eventFormGroup.get("percentAreaBurnt").value;
    template.PercentAreaBurnt = (formValue / 100).toFixed(2);

    return template;
  }
}
