import { Component, OnInit } from "@angular/core";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-diagrams",
  templateUrl: "./diagrams.component.html",
  styleUrls: ["./diagrams.component.scss"],
})
export class DiagramsComponent
  extends BaseModalFormComponent
  implements OnInit
{
  private forestOptions = [
    "Forest System - Tree",
    "Forest System - Debris",
    "Forest System - Soil",
    "Forest System - Products",
  ];

  private agriculturalOptions = [
    "Agricultural System - Crop",
    "Agricultural System - Debris",
    "Agricultural System - Mulch",
    "Agricultural System - Soil",
    "Agricultural System - Products",
  ];

  public diagramOptions = [];

  public seletedOption: string = null;

  //inputs
  public tPlot: "SoilF" | "CompF" | "SoilA" | "CompA" | "CompM";
  public userSoilMnrl: boolean = false;

  public displayMap = {
    SoilF: ["Forest System - Soil"],
    CompF: [...this.forestOptions, "System Overview"],
    SoilA: ["Agricultural System - Mulch", "Agricultural System - Soil"],
    CompA: [...this.agriculturalOptions, "System Overview"],
    CompM: [
      ...this.forestOptions,
      ...this.agriculturalOptions,
      "System Overview",
    ],
  };

  ngOnInit(): void {
    if (this.tPlot) {
      this.diagramOptions = this.displayMap[this.tPlot];
      this.seletedOption = this.diagramOptions[0];
    }
  }

  public shouldShowForest(): boolean {
    const agriculturalPlotTypes = ["SoilA", "CompA"];

    if (agriculturalPlotTypes.includes(this.tPlot)) {
      return false;
    }
    if (this.agriculturalOptions.includes(this.seletedOption)) {
      return false;
    }
    return true;
  }

  public shouldShowAgricultural(): boolean {
    const forestPlotTypes = ["SoilF", "CompF"];

    if (forestPlotTypes.includes(this.tPlot)) {
      return false;
    }

    if (this.forestOptions.includes(this.seletedOption)) {
      return false;
    }

    return true;
  }

  public shouldShowImage(imageName: string): boolean {
    return (
      this.seletedOption == imageName || this.seletedOption == "System Overview"
    );
  }
}
