<ng-container *ngIf="shouldDisplay()">
  <div class="accordion">
    <div
      class="accordion-item"
      fc-output-list-item
      *ngFor="let category of getActiveWindow()"
      [category]="category"
      [service]="service"
      [isParentSelected]="false"
      [parent]="null"
    ></div>
  </div>
  <div class="output-count-details">{{service.totalSelectedOutputs$ |async}} outputs selected, from the {{service.totalOutputsWithinCategories$ | async}} available </div>

</ng-container>
