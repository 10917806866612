<ng-container *ngIf="rotationFormGroup">
  <tabset [id]="'rotationTabs' + componentId">
    <tab
      heading="Rotation Properties"
      id="rotationPropertiesTab"
      (selectTab)="onTabSelect($event)"
    >
      <ng-container *ngTemplateOutlet="rotationProperties"></ng-container>
    </tab>
    <tab
      [customClass]="getEventTabClass()"
      heading="Events"
      id="eventsTab"
      (selectTab)="onTabSelect($event)"
    >
      <ng-container *ngTemplateOutlet="events"></ng-container
    ></tab>
  </tabset>

  <ng-template #rotationProperties>
    <h3>Growth Properties</h3>
    <table class="table">
      <tbody>
        <tr>
          <th>Tree Name</th>
          <td>
            {{ rotationFormGroup.get("UserSpeciesReference")?.value?.Name }}
          </td>
        </tr>

        <tr>
          <th>G - age of maximum biomass increment</th>
          <td>
            {{ rotationFormGroup.get("SpeciesTYFInputs")?.value?.G.toFixed(5) }}
          </td>
        </tr>

        <tr>
          <th>r - age of maximum biomass increment</th>
          <td>
            {{ rotationFormGroup.get("SpeciesTYFInputs")?.value?.r.toFixed(5) }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>

  <ng-template #events>
    <div class="form-controls">
      <div class="left-controls"></div>
      <div class="right-controls">
        <button
          type="button"
          class="btn btn-primary-darken btn-sm"
          (click)="openEventForm()"
        >
          Add Event
        </button>
      </div>
    </div>

    <form [formGroup]="rotationFormGroup">
      <ngx-datatable
        [id]="'eventTable' + componentId"
        class="custom-table"
        [rows]="getEventCollections()"
        [rowClass]="getRowClass"
        columnMode="force"
        [headerHeight]="42"
        [footerHeight]="42"
        rowHeight="42"
        [selected]="selectedRows"
        (select)="onSelect($event)"
        [limit]="10"
        (sort)="onSort($event)"
        [sorts]="getEventSort()"
      >
        <ngx-datatable-row-detail [rowHeight]="'100%'" #eventDetailRow>
          <ng-template
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-row-detail-template
          >
            <div id="event-details" [formGroup]="getEventFormGroup(row)">
              <div class="event-header">
                <h3>
                  {{ row.regimeEventReference.Name }}
                </h3>
              </div>

              <div class="input-container">
                <label
                  [for]="'event-date-input' + row.id + rowIndex"
                  class="form-label"
                  >Event date
                  <span class="small"
                    >Valid event dates are between
                    {{ getEventMinDate(row) | date : "d MMM , y" }}
                    and
                    {{
                      managementService.getEndDate() | date : "d MMM , y"
                    }}</span
                  ></label
                >
                <input
                  [id]="'event-date-input' + row.id + rowIndex"
                  type="text"
                  [dateCustomClasses]="['date-picker']"
                  placeholder="DD/MM/YYYY"
                  class="form-control form-control-sm"
                  autocomplete="off"
                  fcBeginningDay
                  bsDatepicker
                  formControlName="eventDate"
                  [minDate]="getEventMinDate(row)"
                  [maxDate]="managementService.getEndDate()"
                  (bsValueChange)="moveEvent(row, $event)"
                />
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="isOffset"
                  [id]="'checkbox' + row.id + rowIndex"
                />
                <label
                  class="form-check-label"
                  [for]="'checkbox' + row.id + rowIndex"
                >
                  Offset other events on date change
                </label>
              </div>
              <span
                class="small offset-warning error"
                *ngIf="getEventFormGroup(row)?.get('isOffset').value"
                >Warning: changing date to earlier will move the management
                start date</span
              >
              <!--------- additional form controls --------->
              <div
                class="input-container"
                *ngFor="let formContol of row.additionalFormControls"
              >
                <label
                  [for]="formContol.key + row.id + rowIndex"
                  class="form-label"
                  >{{ formContol.label }}
                </label>
                <input
                  *ngIf="formContol.element == 'input'"
                  [id]="formContol.key + row.id + rowIndex"
                  [type]="formContol.type"
                  class="form-control form-control-sm"
                  autocomplete="off"
                  [formControlName]="formContol.key"
                />

                <select
                  *ngIf="formContol.element == 'select'"
                  [id]="formContol.key + row.id + rowIndex"
                  class="form-control form-select form-select-sm"
                  [formControlName]="formContol.key"
                >
                  {{
                    formContol.getOptions()
                  }}
                  <option
                    *ngFor="let option of formContol.getOptions()"
                    [value]="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-row-detail>

        <ngx-datatable-column
          [width]="50"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <a
              href="javascript:void(0)"
              [class.datatable-icon-right]="!getEventExpandedState()[row.id]"
              [class.datatable-icon-down]="getEventExpandedState()[row.id]"
              title="Expand/Collapse Row"
              (click)="toggleExpandRow(row)"
            >
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="Date"
          [width]="120"
          [resizeable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            {{ row?.getEventDate() | date : "dd/MM/yyyy" }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="Group"
          [width]="200"
          [resizeable]="false"
          [canAutoResize]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            {{ row?.regimeEventReference?.Value?.Group }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{ row?.regimeEventReference?.Value?.Name }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Intensity">
          <ng-template ngx-datatable-cell-template let-row="row">
            {{ row?.getIntensity() }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [width]="80"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="action-buttons">
              <button
                class="btn btn-sm btn-rounded"
                (click)="deleteEvent(row)"
                [adaptivePosition]="false"
                tooltip="Delete Event"
                container="body"
              >
                <span class="visually-hidden">Delete Event</span>
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </form>
  </ng-template>
</ng-container>
