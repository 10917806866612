<div class="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">Time Stamp</th>
        <th scope="col">Class</th>
        <th scope="col">Level</th>
        <th scope="col">Message</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of getLogList()">
        <td>{{ log.timeStampLE * 1000 | date : "short" }}</td>
        <td>{{ log.classLE }}</td>
        <td>{{ log.levelLE }}</td>
        <td>{{ getLogMessage(log) }}</td>
      </tr>
    </tbody>
  </table>
</div>
