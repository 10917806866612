import { Injectable } from "@angular/core";
import { EventForms2020Service } from "./event-forms-2020.service";
import { TreePlanting2024Service } from "./tree-planting/tree-planting-2024.service";
import { SpeciesEventTypeMap } from "../../models";
import { ForestFire2024Service } from "./forest-fire/forest-fire-2024.service";
import { ForestTreatment2024Service } from "./forest-treatment/forest-treatment-2024.service";
import { ChopperRoller2024Service } from "./chopper-roller/chopper-roller-2024.service";
import { TermiteChange2024Service } from "./termite-change/termite-change-2024.service";
import { ForestThinning2024Service } from "./forest-thinning/forest-thinning-2024.service";
import { CropPlanting2024Service } from "./crop-planting/crop-planting-2024.service";
import { CropHarvest2024Service } from "./crop-harvest/crop-harvest-2024.service";
import { AgriculturalFire2024Service } from "./agricultural-fire/agricultural-fire-2024.service";
import { AgriculturalPlowing2024Service } from "./agricultural-plowing/agricultural-plowing-2024.service";
import { AgriculturalHerbiciding2024Service } from "./agricultural-herbiciding/agricultural-herbiciding-2024.service";
import { GrazingChange2024Service } from "./grazing-change/grazing-change-2024.service";
import { ForestIrrigationChange2024Service } from "./forest-irrigation-change/forest-irrigation-change-2024.service";
import { ForestManureFromOffsite2024Service } from "./forest-manure-from-offsite/forest-manure-from-offsite-2024.service";
import { AgriculturalIrrigationChange2024Service } from "./agricultural-irrigation-change/agricultural-irrigation-change-2024.service";
import { AgriculturalManureFromOffsite2024Service } from "./agricultural-manure-from-offsite/agricultural-manure-from-offsite-2024.service";
import { ForestPercentageChange2024Service } from "./forest-percentage-change/forest-percentage-change-2024.service";
import { ExternalCost2024Service } from "./external-cost/external-cost-2024.service";
import { ExternalIncome2024Service } from "./external-income/external-income-2024.service";

@Injectable({
  providedIn: "root",
})
export class EventForms2024Service extends EventForms2020Service {
  public version = "2024";

  public forestEventTypeMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    PlnF: {
      label: "Tree planting",
      service: TreePlanting2024Service,
      index: 6,
      type: "tree",
    },
    Thin: {
      label: "Forest thinning",
      service: ForestThinning2024Service,
      index: 0,
      type: "tree",
    },
    FirF: {
      label: "Forest fire",
      service: ForestFire2024Service,
      index: 1,
      type: "tree",
    },
    TmtF: {
      label: "Forest treatment",
      service: ForestTreatment2024Service,
      index: 12,
      type: "tree",
    },
    Chop: {
      label: "Chopper roller",
      service: ChopperRoller2024Service,
      index: 13,
      type: "tree",
    },
    Term: {
      label: "Termite change",
      service: TermiteChange2024Service,
      index: 14,
      type: "tree",
    },

    // NFeF: {
    //   label: "Forest N fertilizer application",
    //   formGroup: this.forestNFertilizerApplication,
    //   categoryIds: [87],
    //index:17
    // },
  };
  public readonly agriculturalEventTypeMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    PlnA: {
      label: "Crop planting",
      service: CropPlanting2024Service,

      index: 7,
      type: "crop",
    },
    Harv: {
      label: "Crop harvest",
      service: CropHarvest2024Service,

      index: 3,
      type: "crop",
    },
    FirA: {
      label: "Agricultural Fire",
      service: AgriculturalFire2024Service,
      index: 2,
      type: "crop",
    },

    Plow: {
      label: "Agricultural plowing",
      service: AgriculturalPlowing2024Service,

      index: 4,
      type: "crop",
    },
    Herb: {
      label: "Agricultural herbiciding",
      service: AgriculturalHerbiciding2024Service,

      index: 5,
      type: "crop",
    },

    Graz: {
      label: "Grazing change",
      service: GrazingChange2024Service,

      index: 8,
      type: "crop",
    },

    // NFeA: {
    //   label: "Agricultural N fertilizer application",
    //   formGroup: null,
    //   categoryIds: [88],
    //index:18
    // },
  };

  public readonly mixedEventTypeMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    // NotE: {
    //   label: "Not Event",
    //   formGroup: null,
    //   categoryIds: [],
    //index:10
    // },
    IrrF: {
      label: "Forest irrigation change",
      service: ForestIrrigationChange2024Service,
      index: 15,
      type: "mixed",
    },
    ManF: {
      label: "Forest manure from offsite",
      service: ForestManureFromOffsite2024Service,
      index: 19,
      type: "mixed",
    },
    IrrA: {
      label: "Agricultural irrigation change",
      service: AgriculturalIrrigationChange2024Service,
      index: 16,
      type: "mixed",
    },
    ManA: {
      label: "Agricultural manure from offsite",
      service: AgriculturalManureFromOffsite2024Service,
      index: 20,
      type: "mixed",
    },
    FFrc: {
      label: "Forest-Percentage change",
      service: ForestPercentageChange2024Service,
      index: 9,
      type: "mixed",
    },
    XCst: {
      label: "External cost",
      service: ExternalCost2024Service,
      index: 21,
      type: "mixed",
    },
    XInc: {
      label: "External income",
      service: ExternalIncome2024Service,
      index: 22,
      type: "mixed",
    },
  };

  public speciesEventMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    ...this.forestEventTypeMap,
    ...this.agriculturalEventTypeMap,
    ...this.mixedEventTypeMap,
  };

  public getEventService(eventType: string): any {
    if (this.speciesEventMap[eventType]) {
      return new this.speciesEventMap[eventType].service();
    }
    return null;
  }

  public getForestEventTypes(): {
    [key: string]: SpeciesEventTypeMap;
  } {
    const configurationFormGroup = this.getConfigurationFormGroup();
    const plotType = configurationFormGroup.get("tPlot")?.value;
    const isEcon = configurationFormGroup.get("userEcon")?.value;
    const useEventIrrigation =
      configurationFormGroup.get("userEventIrrF")?.value;
    const useEventManure = configurationFormGroup.get("userEventManF")?.value;
    let forestEventTypeMap = this.forestEventTypeMap;

    forestEventTypeMap = {
      ...forestEventTypeMap,
      ...(useEventIrrigation && { IrrF: this.mixedEventTypeMap.IrrF }),
      ...(useEventManure && { ManF: this.mixedEventTypeMap.ManF }),
      ...(plotType == "CompM" && { FFrc: this.mixedEventTypeMap.FFrc }),
      ...(isEcon && {
        XCst: this.mixedEventTypeMap.XCst,
        XInc: this.mixedEventTypeMap.XInc,
      }),
    };
    return forestEventTypeMap;
  }
}
