<div class="row">
  <h1>Portal admin- Revoke Access</h1>
  <div class="col-8">
    <input class="form-check-input textfilter"
           type="text"
           placeholder="Enter User Name to filter ..."
           #IdtxtFilter
           [(ngModel)]="txtFilter"
           (change)="FilterData()"
           aria-label="Enter User Name to filter" />
  </div>
  <div class="col-1">
    <button type="submit"
            class="btn btn-outline-primary-darken btn-refresh"
            (click)="Refreshdata()">
      Refresh
    </button>
  </div>
  <div class="col-3">
  </div>
</div>
<br />
<div class="row col-12">
  <table class="table table-striped">
    <thead class="thead-dark">
      <tr>
        <th style="display: none">userGuidId</th>
        <th>User Name</th>
        <th>User Email</th>
        <th>Role Name</th>
        <th>Status</th>
        <th>Blocked Date</th>
        <th>UnBlock</th>
        <th>Block</th>
      </tr>
    </thead>
    <tbody *ngIf="recordsExists == true">
      <tr *ngFor="let item of response">
        <td style="display: none">{{ item.userGuid }}</td>
        <td>{{ item.userName }}</td>
        <td>{{ item.userEmailId }}</td>
        <td>{{ item.roleName }}</td>
        <td><span *ngIf="item.status == 'Blocked'" class="span-blocked"> {{ item.status }}</span>
            <span *ngIf="item.status == 'Unblocked'" class="span-un-blocked"> {{ item.status }}</span></td>
        <td><span *ngIf="item.status == 'Blocked' || item.status == 'Unblocked'"> {{item.modifiedDate | date: 'dd/MM/yyyy' }}</span></td>
        <td>
          <button type="submit" class="btn btn-outline-success" style="color:blue" (click)="OnGrant(item)"
                  [disabled]="item.status == 'Unblocked'">
            Unblock
          </button>
        </td>
        <td>
          <button type="submit" class="btn btn-outline-danger" (click)="OnReject(item)"
                  [disabled]="item.status == 'Blocked'">
            Block
          </button>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="recordsExists == false">
      <tr>
        <td colspan="8">No Records exists</td>
      </tr>
    </tbody>
  </table>
</div>
