import {
  Component,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
} from "@angular/core";
import { PlotFlow } from "../../models";
import { Subject, debounceTime, takeUntil } from "rxjs";

@Component({
  selector: "fc-simulation-nav",
  templateUrl: "./simulation-nav.component.html",
  styleUrls: ["./simulation-nav.component.scss"],
  host: {
    class: "simulation-nav",
  },
})
export class SimulationNavComponent implements OnInit {
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.debounceAdjustNav();
  }

  @Input() items: PlotFlow[] = [];

  @Input() selectedItem: PlotFlow;

  @Output() itemSelected = new EventEmitter<PlotFlow>();
  //for RMT
  @Output() nextFlowAccessAllowed = new EventEmitter<boolean>(false);

  private adjustNavSubject$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  public showMore: boolean = false;

  public mainNav: PlotFlow[] = [];

  public overflowItems: PlotFlow[] = [];

  public nextFlow: PlotFlow = null;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.debounceAdjustNav();
    }

    if (changes.selectedItem) {
      const currentFlow = changes.selectedItem.currentValue;
      const currentFlowIndex = this.items.findIndex(
        (i) => i.id == currentFlow.id
      );
      this.nextFlow = this.items[currentFlowIndex + 1];
    }
  }

  ngAfterViewInit() {
    this.mainNav = this.items;
    // this.laytoutService.mainWidth$.subscribe((mainWidth) => {
    //   this.adjustNav();
    // });

    this.adjustNavSubject$
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => {
        this.adjustNav();
      });
  }

  public setSelectedFlowPage(flow) {
    this.itemSelected.emit(flow);
  }

  public getOverflowItemStatus() {
    let hasInvalid = false;
    let hasActive = false;

    for (const item of this.overflowItems) {
      if (item.isInvalid()) {
        hasInvalid = true;
        break;
      }
      if (item.id == this.selectedItem?.id) {
        hasActive = true;
      }
    }

    if (hasInvalid) {
      return "is-invalid";
    }

    if (hasActive) {
      return "is-active";
    }

    return "";
  }

  private debounceAdjustNav() {
    //reset items
    this.overflowItems = [];
    this.mainNav = this.items;
    setTimeout(() => {
      this.adjustNavSubject$.next();
    }, 0);
  }

  private adjustNav() {
    const padding = 48;
    const sideBarWdith = 77;
    //shuold use window width - sidebar - padding
    let mainContainWidth = document.body.clientWidth - sideBarWdith;
    let container = this.el.nativeElement;
    const navButtons = container.querySelectorAll(".nav-button");
    const navWidth = container.clientWidth;

    if (navWidth + padding < mainContainWidth) {
      if (this.mainNav.length !== this.items.length) {
        this.mainNav = this.items;
      }
      return;
    }
    let buttonIndex;
    for (let i = navButtons.length - 1; i > 0; i--) {
      const buttonOffset = navButtons[i].offsetLeft;
      const buttonWidth = navButtons[i].clientWidth;
      const offsetRight = buttonOffset + buttonWidth;
      if (offsetRight < mainContainWidth - padding) {
        //subtract one more for the More button
        buttonIndex = i - 2;
        break;
      }
    }
    if (buttonIndex) {
      this.overflowItems = this.items.slice(buttonIndex + 1);
      this.mainNav = this.items.slice(0, buttonIndex + 1);
    }
  }

  public getNavClasses(item: PlotFlow) {
    return {
      disabled: this.isItemDisabled(item),
      "is-active": item.id === this.selectedItem?.id,
      "is-invalid": item?.isInvalid(),
      [item.id + "-nav"]: true, // Dynamic class based on item.id
    };
  }

  public isItemDisabled(item: PlotFlow): boolean {
    const isDisabled =
      typeof item.isDisabled === "function" && item.isDisabled();

    if (!this.nextFlow) {
      this.nextFlowAccessAllowed.emit(false);
    } else if (this.nextFlow?.id == item.id) {
      this.nextFlowAccessAllowed.emit(!isDisabled);
    }
    return isDisabled;
  }

  ngOndestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.adjustNavSubject$.next();
    this.destroy$.complete();
  }
}
