import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonComponent } from "./components/form-elements/button/button.component";
import { ModalComponent } from "./components/modal/modal.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { HashIdDirective } from "./directives/hash-id.directive";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BsDatepickerConfig,
  BsDatepickerModule,
} from "ngx-bootstrap/datepicker";
import { MapComponent } from "./components/map/map.component";
import { DataVisualiserComponent } from "./components/data-visualiser/data-visualiser.component";
import { HttpClientModule } from "@angular/common/http";
import { ChartComponent } from "./components/chart/chart.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { OlMapComponent } from "./components/map/ol-map.component";
import { MapPreviewComponent } from "./components/map/map-preview/map-preview.component";
import { DraggableWindowComponent } from "./components/draggable-window/draggable-window.component";
import { FormItemElementComponent } from "./components/form-item-element/form-item-element.component";
import { FormGroupElementComponent } from "./components/form-group-element/form-group-element.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { SpeciesSelectorComponent } from "./components/species-selector/species-selector.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { FlowBottomNavigationComponent } from "./components/flow-bottom-navigation/flow-bottom-navigation.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { SpeciesListComponent } from "./components/species-list/species-list.component";
import { ModifiersComponent } from "./components/modifiers/modifiers.component";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { LocationMetadataComponent } from "./components/location-metadata/location-metadata.component";
import { TransitionGroupItemDirective } from "./directives/transition-group-item.directive";
import { TransitionGroupComponent } from "./components/transition-group/transition-group.component";
import { FolderSelectComponent } from "./components/folder-select/folder-select.component";
import { FlyOverPanelComponent } from "./components/fly-over-panel/fly-over-panel.component";
import { ProjectFormComponent } from "./components/project-form/project-form.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { InputMaskDirective } from "./directives/input-mask.directive";
import { ProjectCollectionListComponent } from "./components/project-collection-list/project-collection-list.component";
import { RouterModule } from "@angular/router";
import { FormErrorMessagesComponent } from "./components/form-error-messages/form-error-messages.component";
import { BeginningDayDirective } from "./directives/beginning-day.directive";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { TableComponent } from "./components/table/table.component";
import { DecayRateComponent } from "./components/decay-rate/decay-rate.component";
import { SpeciesEventsComponent } from "./components/species-events/species-events.component";
import { EventFormsComponent } from "./components/event-forms/event-forms.component";
import { FormItemTableComponent } from "./components/form-item-table/form-item-table.component";
import { LogGradesFormComponent } from "./components/event-forms/forest-thinning/log-grades-form/log-grades-form.component";
import { FormulaDisplayComponent } from "./components/formula-display/formula-display.component";
import { HelpPanelComponent } from "../simulation/components/help-panel/help-panel.component";
import { BaseModalFormComponent } from "./components/base-modal-form/base-modal-form.component";
import { TimingFormComponent } from "../plot/events/components/timing-form/timing-form.component";
import { InsertSpeciesValuesFormComponent } from "./components/insert-species-values-form/insert-species-values-form.component";
import { ObserveVisibilityDirective } from "./directives/observe-visibility.directive";
import { PlotUploadComponent } from "./components/plot-upload/plot-upload.component";
import { InputActionsComponent } from "./components/input-actions/input-actions.component";
import { PlotFilesSelectComponent } from "./components/plot-files-select/plot-files-select.component";
import { MyPlotsComponent } from "../my-plots/my-plots.component";
import { StickyDirective } from "./directives/sticky.directive";
import { LegendComponent } from "./components/legend/legend.component";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PlotEntryModalComponent } from "./components/plot-entry-modal/plot-entry-modal.component";
import { SignInErrorComponent } from "./components/sign-in-error/sign-in-error.component";
import { DrawerComponent } from "../layout/drawer/drawer/drawer.component";
import { AboutFullcamComponent } from "./components/about-fullcam/about-fullcam.component";
import { SimulationNavComponent } from "../simulation/components/simulation-nav/simulation-nav.component";
import { ConfirmWithCommentModalComponent } from './components/confirm-with-comment-modal/confirm-with-comment-modal.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { ResolvingAccessComponent } from './components/resolving-access/resolving-access.component';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: "DD/MM/YYYY",
    initCurrentTime: false,
    containerClass: "theme-blue",
  });
}

// @NgModule({
//   ...
//   providers: [{ provide: BsDatepickerConfig, useFactory: getDatepickerConfig }]
// })

@NgModule({
  declarations: [
    ButtonComponent,
    ModalComponent,
    HashIdDirective,
    MapComponent,
    DataVisualiserComponent,
    ChartComponent,
    OlMapComponent,
    MapPreviewComponent,
    DraggableWindowComponent,
    FormItemElementComponent,
    FormGroupElementComponent,
    BreadcrumbComponent,
    SpeciesSelectorComponent,
    FlowBottomNavigationComponent,
    SafeHtmlPipe,
    SpeciesListComponent,
    ModifiersComponent,
    LocationMetadataComponent,
    TransitionGroupItemDirective,
    TransitionGroupComponent,
    FolderSelectComponent,
    ProjectFormComponent,
    FlyOverPanelComponent,
    ConfirmModalComponent,
    InputMaskDirective,
    ProjectCollectionListComponent,
    OlMapComponent,
    FormErrorMessagesComponent,
    BeginningDayDirective,
    TableComponent,
    DecayRateComponent,
    SpeciesEventsComponent,
    EventFormsComponent,
    FormItemTableComponent,
    LogGradesFormComponent,
    FormulaDisplayComponent,
    HelpPanelComponent,
    BaseModalFormComponent,
    TimingFormComponent,
    InsertSpeciesValuesFormComponent,
    ObserveVisibilityDirective,
    PlotUploadComponent,
    InputActionsComponent,
    PlotFilesSelectComponent,
    MyPlotsComponent,
    StickyDirective,
    LegendComponent,
    PlotEntryModalComponent,
    SignInErrorComponent,
    DrawerComponent,
    AboutFullcamComponent,
    SimulationNavComponent,
    ConfirmWithCommentModalComponent,
    NoAccessComponent,
    ResolvingAccessComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: "No data to display", // Message to show when array is presented, but contains no values
        totalMessage: "total", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxMaskModule.forRoot(),
    PopoverModule.forRoot(),
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    ButtonComponent,
    DataVisualiserComponent,
    MapComponent,
    OlMapComponent,
    DraggableWindowComponent,
    MapPreviewComponent,
    ChartComponent,
    FormItemElementComponent,
    FormGroupElementComponent,
    BreadcrumbComponent,
    SpeciesSelectorComponent,
    HashIdDirective,
    TransitionGroupItemDirective,
    TransitionGroupComponent,
    BsDatepickerModule,
    FlowBottomNavigationComponent,
    ModifiersComponent,
    LocationMetadataComponent,
    FolderSelectComponent,
    ProjectFormComponent,
    SafeHtmlPipe,
    FlyOverPanelComponent,
    ProjectCollectionListComponent,
    NgxDatatableModule,
    OlMapComponent,
    FormErrorMessagesComponent,
    AccordionModule,
    BeginningDayDirective,
    TooltipModule,
    NgxMaskModule,
    PopoverModule,
    HelpPanelComponent,
    TimingFormComponent,
    PlotFilesSelectComponent,
    MyPlotsComponent,
    InputActionsComponent,
    StickyDirective,
    LegendComponent,
    PlotEntryModalComponent,
    SimulationNavComponent,
    DrawerComponent,
  ],
  providers: [{ provide: BsDatepickerConfig, useFactory: getDatepickerConfig }],
})
export class SharedModule {}
