<div>
  <div class="lhs">{{ data.lhs }}</div>
</div>

<div class="rhs" [formGroup]="formGroup" *ngIf="formGroup">
  <div class="line" *ngFor="let line of data.rhs">
    <span *ngFor="let d of line" class="input-control">
      <ng-container *ngIf="d.element == 'icon'">
        <i class="bi bi-x-lg operator" *ngIf="d.value == 'x'"></i>
        <i class="bi bi-plus-lg operator" *ngIf="d.value == '+'"></i>
        <span class="operator" *ngIf="d.value == '='">=</span>
      </ng-container>

      <ng-container *ngIf="d.element == 'text'">{{ d.value }}</ng-container>
      <input
        *ngIf="d.element == 'input'"
        [type]="d.type"
        class="form-control form-control-sm"
        [formControlName]="d.programmingName"
      />
      <fc-input-actions
        *ngIf="
          d.element == 'input' &&
          service?.simulationService?.selectedPlotFormat$.getValue() == 'pld'
        "
        [simulationService]="service?.simulationService"
        [service]="service"
        [input]="getInput(d)"
        [item]="getItem(d)"
        [programmingName]="d.programmingName"
      ></fc-input-actions>
    </span>
  </div>
</div>
