import { Component, OnInit } from "@angular/core";
import { DbService } from "../../shared/services/db.service";
import { UnApprovedAllUsersData } from "../models/UnApprovedUsers copy";
import { AuthService } from "src/app/auth/services/auth.service";

@Component({
  selector: "fc-portal-admin-all-users",
  templateUrl: "./portal-admin-all-users.component.html",
  styleUrls: ["./portal-admin-all-users.component.scss"],
})
export class PortalAdminAllUsersComponent implements OnInit {
  constructor(private dbService: DbService, private authService: AuthService) {}
  response: UnApprovedAllUsersData[] = [];
  recordsExists: boolean = false;
  txtFilter: string = "";
  saveResponse: UnApprovedAllUsersData[] = [];

  ngOnInit(): void {
    this.GetUnApprovedUsers();
  }
  GetUnApprovedUsers() {
    this.dbService
      .getUnApprovedAllUsers()
      .pipe()
      .subscribe((users: any) => {
        this.response = users;
        this.saveResponse = this.response;
        this.recordsExists = this.response.length > 0;
      });
  }
  Refreshdata() {
    if (this.txtFilter.trim().length > 2) {
      this.FilterData();
    } else {
      this.GetUnApprovedUsers();
    }
  }
  FilterData() {
    this.response = this.saveResponse;
    if (this.txtFilter.trim().length > 2) {
      let result: UnApprovedAllUsersData[] = [];
      result.push(
        this.response.find((obj) => {
          return obj.userName
            .toLowerCase()
            .includes(this.txtFilter.toLowerCase());
        })
      );
      this.response = result;
    }
    this.recordsExists =
      this.response[0] != undefined && this.response.length > 0;
  }

  OnReject(request: UnApprovedAllUsersData) {
    request.status = "Blocked";
    this.dbService
      .ManageUserAccess(request)
      .pipe()
      .subscribe((users: any) => {
        this.response = users.body;
        this.saveResponse = this.response;
        this.recordsExists = this.response.length > 0;
      });
  }
  OnGrant(request: UnApprovedAllUsersData) {
    request.status = "Unblocked";
    this.dbService
      .ManageUserAccess(request)
      .pipe()
      .subscribe((users: any) => {
        this.response = users.body;
        this.saveResponse = this.response;
        this.recordsExists = this.response.length > 0;
      });
  }
}
