<div class="row">
  <div class="col-lg-8">
    <h1 class="page-title">User profile</h1>

    <div class="accordion-header">
      <h2>User info</h2>
    </div>
    <div class="accordion-body user-info">
      <ng-container *ngIf="userClaim$ | async as userClaim">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <span class="property-name">First name:</span>
            <span>{{ userClaim?.given_name }}</span>
          </li>
          <li class="list-group-item">
            <span class="property-name">Last name:</span>
            <span>{{ userClaim?.family_name }}</span>
          </li>
          <li class="list-group-item">
            <span class="property-name">Email:</span>
            <span>{{ userClaim?.emails[0] }}</span>
          </li>
        </ul>
      </ng-container>
    </div>

    <div class="accordion-header">
      <h2>Request access to FullCAM applications</h2>
    </div>

    <div class="accordion-body">
      <h3>Which version(s) of FullCAM do you require access to, and why</h3>
      <form [formGroup]="formGroup">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let version of versions">
            <div class="first-level">
              <ng-container
                *ngIf="!isPending(version.statusProp); else pendingIcon"
              >
                <div
                  form-field
                  class="form-check form-switch"
                  *ngIf="!shouldDisable(version); else checkIcon"
                >
                  <input
                    [id]="version.value"
                    class="form-check-input switch-input"
                    type="checkbox"
                    role="switch"
                    [value]="version.value"
                    (change)="onVersionChange($event, version)"
                  />
                </div>
              </ng-container>
              <label [for]="version.value" class="form-check-label">{{
                version.label
              }}</label>
            </div>

            <ng-container *ngIf="getRoleFormGroup(version.value)">
              <ng-container
                *ngTemplateOutlet="
                  requestRole;
                  context: {
                    version: version.value,
                    warning: version.warning,
                    roleFormGroup: getRoleFormGroup(version.value)
                  }
                "
              ></ng-container
            ></ng-container>
          </li>
        </ul>

        <fc-button
          class="submit-button"
          [disabled]="
            formGroup.pristine || formGroup.invalid || !selectedVersions.length
          "
          [inputText]="'Submit'"
          [isLoading$]="isLoading$()"
          [successText]="'Done'"
          (onClick)="submitForm()"
        >
        </fc-button>
      </form>
    </div>
  </div>
</div>
<ng-template
  #requestRole
  let-version="version"
  let-warning="warning"
  let-roleFormGroup="roleFormGroup"
>
  <ul class="list-group sub-menu" [formGroup]="roleFormGroup">
    <li class="list-group-item" *ngFor="let role of roles">
      <input
        [id]="version + role.value"
        class="form-check-input"
        type="radio"
        autocomplete="off"
        [value]="role.value"
        formControlName="role"
        (change)="onRoleChange($event, role.value, warning)"
      />
      <label [for]="version + role.value" class="form-check-label"
        >{{ role.label }}
      </label>
    </li>
    <li class="list-group-item" *ngIf="roleFormGroup?.get('role').value">
      <div class="reason-container">
        <textarea
          class="form-control form-control-sm"
          rows="5"
          maxlength="500"
          formControlName="reason"
          placeholder="Please provide details"
        ></textarea>
        <small
          >{{ 500 - roleFormGroup?.get("reason").value.length }} characters
          remaining</small
        >
      </div>
    </li>
  </ul>
</ng-template>
<ng-template #checkIcon
  ><i tooltip="Current Access" class="bi bi-check-lg check-icon"></i
></ng-template>

<ng-template #pendingIcon
  ><i tooltip="Pending Access" class="bi bi-hourglass-split pending-icon"></i
></ng-template>
