import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SimulationComponent } from "./simulation.component";
import { PlotEntryComponent } from "./plot-entry/plot-entry.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [SimulationComponent, PlotEntryComponent],
  imports: [CommonModule, SharedModule],
})
export class SimulationModule {}
