<ng-container
  *ngFor="let input of item.inputs ? item.inputs : []; let i = index"
>
  <ng-container
    *ngIf="input.id ? service.getFormModel()[input.id].isShown : true"
  >
    <ng-container [ngSwitch]="true">
      <div
        class="input-container"
        [formGroup]="formGroupInstance"
        [class]="
          input.customClassFunction
            ? service[input.customClassFunction]()
            : null
        "
        *ngIf="
          input.hideMethod
            ? !service[input.hideMethod](input.hideMethodPayload)
            : true
        "
      >
        <label
          *ngIf="hasLabelMethod(input.labelMethod) || input.label"
          class="input-label"
          [for]="input.programmingName || ''"
          >{{ hasLabelMethod(input.labelMethod) || input.label }}
        </label>

        <ng-container *ngSwitchCase="input.element == 'input'">
          <ng-container [ngSwitch]="true">
            <div
              form-field
              class="form-check form-switch"
              *ngSwitchCase="input.type == 'switch'"
            >
              <input
                [id]="input.programmingName"
                class="form-check-input switch-input"
                type="checkbox"
                role="switch"
                autocomplete="off"
                [formControlName]="input.programmingName"
                [attr.disabled]="
                  service.getFormModel()[input.programmingName]?.isDisabled
                    ? ''
                    : null
                "
              />
            </div>
            <div
              *ngSwitchCase="input.type == 'radioSwitch'"
              class="btn-group btn-group-sm"
              role="group"
              aria-label="radio toggle button group"
            >
              <ng-container
                *ngFor="let option of input.selectOptions; let j = index"
              >
                <input
                  type="radio"
                  class="btn-check"
                  [name]="input.programmingName"
                  [id]="input.programmingName + j"
                  [value]="option.value"
                  autocomplete="off"
                  [formControlName]="input.programmingName"
                  [attr.disabled]="
                    service.getFormModel()[input.programmingName]?.isDisabled
                      ? ''
                      : null
                  "
                />
                <label
                  class="btn btn-outline-primary"
                  [for]="input.programmingName + j"
                  >{{ option.label }}</label
                >
              </ng-container>
            </div>

            <div *ngSwitchCase="input.type == 'datePicker'">
              <input
                [id]="input.programmingName"
                [style]="input.width && { width: input.width }"
                type="text"
                [dateCustomClasses]="['date-picker']"
                [placeholder]="input.placeholder"
                class="form-control form-control-sm"
                [formControlName]="input.programmingName"
                autocomplete="off"
                fcBeginningDay
                bsDatepicker
                [attr.disabled]="
                  service.getFormModel()[input.programmingName]?.isDisabled
                    ? ''
                    : null
                "
              />
            </div>

            <ng-container *ngIf="input.floatingLabel; else standardInput">
              <div class="form-floating">
                <ng-container *ngTemplateOutlet="standardInput"></ng-container>
                <label [for]="input.programmingName || ''"
                  ><span class="visually-hidden">{{ input.label }}</span>
                  {{ input.floatingLabel }}</label
                >
              </div>
            </ng-container>

            <ng-template #standardInput>
              <input
                [id]="input.programmingName"
                autocomplete="off"
                *ngSwitchCase="standardInputs.indexOf(input.type) > -1"
                [style]="input.width && { width: input.width }"
                class="form-control form-control-sm"
                [type]="input.type"
                [placeholder]="input.placeholder || ''"
                [formControlName]="input.programmingName"
                [attr.disabled]="
                  service.getFormModel()[input.programmingName]?.isDisabled
                    ? ''
                    : null
                "
              />
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="input.element == 'select'">
          <select
            [id]="input.programmingName"
            type="text"
            [style]="input.width && { width: input.width }"
            class="form-control form-select form-select-sm"
            [formControlName]="input.programmingName"
            [attr.disabled]="
              service.getFormModel()[input.programmingName]?.isDisabled
                ? ''
                : null
            "
          >
            <option
              *ngFor="let option of input.selectOptions"
              [selected]="
                formGroupInstance.get(input.programmingName)?.value ==
                option.value
              "
              [value]="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </ng-container>

        <ng-container *ngSwitchCase="input.element == 'textarea'">
          <textarea
            [id]="input.programmingName"
            class="form-control"
            [style]="input.width && { width: input.width }"
            [formControlName]="input.programmingName"
            [rows]="input.rows || 8"
            [placeholder]="input.placeholder || ''"
            [attr.disabled]="
              service.getFormModel()[input.programmingName]?.isDisabled
                ? ''
                : null
            "
          ></textarea>
        </ng-container>

        <ng-container *ngSwitchCase="input.element == 'navigation'">
          <button
            [attr.disabled]="
              service.getFormModel()[input.programmingName]?.isDisabled
                ? ''
                : null
            "
            [attr.aria-label]="'link to component ' + input.component"
            [id]="'link-to-component-' + input.programmingName"
            class="btn btn-outline-primary btn-sm link-component-button"
            [class]="{
              'link-component-data-visualiser-button':
                input.component == 'DataVisualiserComponent',
              'link-component-data-line-chart-button':
                input.component == 'DecayRateComponent',
              'is-invalid': formGroupInstance.get(input.programmingName)
                ?.invalid
            }"
            (click)="linkToComponent(input)"
          >
            <span
              *ngIf="
                input.component == 'DataVisualiserComponent' ||
                input.component == 'SpeciesEventsComponent'
              "
            >
              View...
            </span>
            <span *ngIf="input.component == 'DecayRateComponent'">
              <i class="bi bi-graph-up"></i>
            </span>
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="input.element == 'button'">
          <button
            *ngIf="
              input.showButtonMethod
                ? service[input.showButtonMethod](input.showButtonPayload)
                : true
            "
            [attr.aria-label]="input?.buttonLabel"
            [class]="input.buttonClass"
            class="btn btn-sm btn-primary"
            (click)="service[input.submitMethod](input.payload)"
            [attr.disabled]="
              service.getFormModel()[input.programmingName]?.isDisabled
                ? ''
                : null
            "
          >
            {{ input?.buttonLabel }}
          </button>
        </ng-container>

        <ng-container *ngSwitchCase="input.element == 'custom'">
          <button
            class="btn btn-sm"
            [attr.disabled]="
              service.getFormModel()[input.programmingName]?.isDisabled
                ? ''
                : null
            "
          >
            <i
              class="bi bi-chevron-right"
              (click)="
                navigateToSubGroups(
                  input.linkTo == 'component' ? id + '-component-' + i : id,
                  input
                )
              "
            ></i>
          </button>
        </ng-container>
        <div
          [style]="input.width && { width: input.width }"
          class="custom-text"
          *ngSwitchCase="input.element == 'text'"
        >
          <span *ngIf="input.value">{{ input.value }}</span>
          <span *ngIf="input.method">{{
            service[input.method](input.payload || undefined)
          }}</span>
        </div>
        <!-- to be imporved, hide show logic is based on the input id and programming name of the first item inputs -->
        <ng-container
          *ngIf="input.id ? service.getFormModel()[input.id].isShown : true"
        >
          <ng-template
            *ngIf="input.element == 'component'"
            [hashId]="id + '-component-' + i"
          ></ng-template>
        </ng-container>

        <!-- error messages-->
        <fc-form-error-messages
          *ngIf="
            formGroupInstance?.get(input.programmingName)?.errors &&
            (formGroupInstance.get(input.programmingName)?.touched ||
              formGroupInstance.get(input.programmingName).dirty)
          "
          [formControlInstance]="formGroupInstance.get(input.programmingName)"
          [label]="
            hasLabelMethod(input.labelMethod) || input.label || item.label
          "
          [customErrorMessages]="input.customErrorMessages"
        ></fc-form-error-messages>
      </div>
    </ng-container>
  </ng-container>

  <fc-input-actions
    [class]="{ 'has-label': hasLabelMethod(input.labelMethod) || input.label }"
    *ngIf="shouldShowDigestActions(input)"
    [simulationService]="simulationService"
    [service]="service"
    [input]="input"
    [item]="item"
    [programmingName]="input.programmingName"
  ></fc-input-actions>
</ng-container>

<!-- <ng-container
    *ngFor="let input of item.inputs ? item.inputs : []; let i = index"
  >
    <div
      *ngIf="input.linkTo == 'component'"
      class="sub-groups sub-groups-component"
      [id]="id + '-component-' + i"
      [class]="{ 'is-active': selectedSubGroups?.id == id + '-component-' + i }"
    >
      <ng-template [hashId]="id + '-component-' + i"> </ng-template>
    </div>

    <ng-template
      *ngIf="input.element == 'component'"
      [hashId]="id + '-component-' + i"
    ></ng-template>
  </ng-container> -->

<ng-container *ngIf="item.groups?.length">
  <div
    class="sub-groups"
    fc-form-group-element
    *ngFor="let group of item.groups"
    [group]="group"
    [formGroupInstance]="formGroupInstance"
    [service]="service"
    [simulationService]="simulationService"
    [id]="id"
  ></div>
</ng-container>
