import { Injectable } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FieldWatcher, FormModel } from "src/app/shared/models";
import { Constants } from "src/app/shared/constants";

const percentageofForestTreeGroups: FormGroupElement[] = [
  {
    label: "Tree",
    isShown: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            programmingName: "project",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Destination percentages in the affected portion - tree",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To Standing dead", "To Debris"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToSDdwdFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStemToDdwdFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Branches",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToSChwdFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBranToDdwdFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToSBlitFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracBarkToBlitFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToSLlitFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToSCodrFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToSFidrFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrFirF",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const percentageofForestStandingDeadGroups: FormGroupElement[] = [
  {
    label: "Standing dead",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            programmingName: "project",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value:
                  "Destination percentages in the affected portion - standing dead",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To Debris"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Standing dead stem",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSDdwdToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSDdwdToDebrFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Standing dead branches",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSChwdToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSChwdToDebrFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Standing dead bark",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSBlitToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSBlitToDebrFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Standing dead leaf",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSLlitToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracSLlitToDebrFirF",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const decomposableDebrisGroups: FormGroupElement[] = [
  {
    label: "Decomposable debris",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            programmingName: "project",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Decomposable debris",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To inert soil"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Deadwood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDDdwdToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDDdwdToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Chopped wood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDChwdToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDChwdToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDBlitToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDBlitToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDLlitToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDLlitToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRCodrToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRCodrToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDFidrToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDFidrToInrtFirF",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const resistantDebrisGroups: FormGroupElement[] = [
  {
    label: "Resistant debris",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            programmingName: "project",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Decomposable debris",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To inert soil"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Deadwood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRDdwdToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRDdwdToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Chopped wood",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRChwdToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRChwdToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRBlitToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRBlitToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRLlitToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRLlitToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRCodrToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRCodrToInrtFirF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRFidrToAtmsFirF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRFidrToInrtFirF",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class ForestFire2020Service extends BaseEventFormService {
  public readonly categoryIds = [76, 71];
  public readonly pageHelpLink = "144_Forest%20Fire.htm";

  public layout: FormLayout = {
    label: "Forest fire",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Percentage of forest affected by fire",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctFirF",
              },
            ],
          },
        ],
      },
      {
        label: "Age adjustment",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Enable biomass based age adjustment",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "useBioAgeAdjFirF",
              },
            ],
          },
        ],
      },

      {
        label: "Destination percentages in the affected portion",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            groups: percentageofForestTreeGroups,
          },

          {
            groups: percentageofForestStandingDeadGroups,
          },
        ],
      },
      {
        label: "Years to regrow post-fire",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "FormItemTableComponent",
                componentInputs: [
                  {
                    inputKey: "caption",
                    value: "Years to regrow post-fire - years",
                  },

                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      headers: ["", "(years)"],
                      rows: [
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Stem",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "yrsStemRegrowFirF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Branches",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "yrsBranRegrowFirF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Bark",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "yrsBarkRegrowFirF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Leaves",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "yrsLeafRegrowFirF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Coarse roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "yrsCortRegrowFirF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Fine roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "yrsFirtRegrowFirF",
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
          { groups: decomposableDebrisGroups },
          { groups: resistantDebrisGroups },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecF",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    fracSAmmNToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in ammonium in the shallow soil",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 229,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSNtrNToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in nitrate in the shallow soil",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 230,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDAmmNToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in ammonium in the deep soil",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 231,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDNtrNToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in nitrate in the deep soil",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 232,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracAfctFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "forest affected by fire",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 683,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracStemToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stems",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 684,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracBranToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "branches",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 685,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracBarkToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "bark",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 686,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracLeafToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 687,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracStemToDdwdFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 688,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracBranToDdwdFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 689,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracBarkToBlitFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "bark to litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 690,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracLeafToLlitFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 691,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracCortToCodrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 692,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracFirtToFidrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 693,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracDDdwdToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable deadwood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 694,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracRDdwdToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant deadwood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 695,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDBlitToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable bark litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 696,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracRBlitToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant bark litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 697,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDLlitToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable leaf litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 698,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracRLlitToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant leaf litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 699,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDCodrToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 700,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracRCodrToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 701,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDFidrToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable fine roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 702,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracRFidrToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant fine roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 703,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDDdwdToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable deadwood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 704,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracRDdwdToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant deadwood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 705,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracDBlitToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable bark litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 706,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracRBlitToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant bark litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 707,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracDLlitToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable leaf litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 708,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracRLlitToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant leaf litter",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 709,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracDCodrToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 710,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracRCodrToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 711,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracDFidrToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable fine roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 712,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracRFidrToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant fine roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 713,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracSopmToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 714,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracLrpmToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 715,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracMrpmToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 716,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSommToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 717,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracLrmmToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 718,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracMrmmToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 719,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracMicrToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 720,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracMnamNToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mineral nitrogen",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 721,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSopmToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 722,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracLrpmToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 723,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracMrpmToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 724,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracSommToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 725,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracLrmmToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 726,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracMrmmToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 727,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracMicrToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 728,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracLeafRegrowFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "burnt leaves that regrow (in addition to other growth)",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 729,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracDChwdToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable chopped wood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 1048,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracRChwdToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant chopped wood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 1049,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracDChwdToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable chopped wood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 1050,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracRChwdToInrtFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant chopped wood",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 1051,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracCortToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 1158,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracFirtToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 1159,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    yrsStemRegrowFirF: {
      high: 100,
      low: 0.083,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.083,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Years to fully regrow stems",
      unit: "yr",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 1,
      tIn: 1160,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    yrsBarkRegrowFirF: {
      high: 100,
      low: 0.083,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.083,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Years to fully regrow bark",
      unit: "yr",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 1,
      tIn: 1161,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    yrsBranRegrowFirF: {
      high: 100,
      low: 0.083,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.083,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Years to fully regrow branches",
      unit: "yr",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 1,
      tIn: 1162,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    yrsLeafRegrowFirF: {
      high: 100,
      low: 0.083,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.083,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Years to fully regrow leaves",
      unit: "yr",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 1,
      tIn: 1163,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    yrsCortRegrowFirF: {
      high: 100,
      low: 0.083,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.083,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Years to fully regrow coarse roots",
      unit: "yr",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 1,
      tIn: 1164,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    yrsFirtRegrowFirF: {
      high: 100,
      low: 0.083,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.083,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Years to fully regrow fine roots",
      unit: "yr",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 1,
      tIn: 1165,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    useBioAgeAdjFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Use biomass age adjustment (yes/no)",
      unit: "",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 1,
      tIn: 1470,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fixFirF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "forest fire",
      unit: "$",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2058,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    phaFirF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 120,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "forest fire",
      unit: "$/ha",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2059,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracStemToSDdwdFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stems to standing dead stems",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracBranToSChwdFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "branches to standing dead branches",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2187,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracBarkToSBlitFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "bark to standing dead bark",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2188,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracLeafToSLlitFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to standing dead leaves",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2189,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracCortToSCodrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to standing dead course roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2190,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracFirtToSFidrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots to standing dead fine roots",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2191,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 109,
    },
    fracSDdwdToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead stem",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2192,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSChwdToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead branch",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2193,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSBlitToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead bark",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2194,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSLlitToAtmsFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead leaf",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2195,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 108,
    },
    fracSDdwdToDebrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead stem to debris",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2196,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracSChwdToDebrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead branch to debris",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2197,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracSBlitToDebrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead bark to debris",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2198,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    fracSLlitToDebrFirF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 144,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Standing dead leaf to debris",
      unit: "%",
      categoryName: "EvFirF",
      categoryLabel: "Forest fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 1,
      tIn: 2199,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 110,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isFixedExplorerField: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "FirF",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
  };

  public fieldWatcher: FieldWatcher = {
    fracStemToAtmsFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracStemToAtmsFirF", "fracStemToSDdwdFirF", "fracStemToDdwdFirF"],
        "yrsStemRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracStemToSDdwdFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracStemToAtmsFirF", "fracStemToSDdwdFirF", "fracStemToDdwdFirF"],
        "yrsStemRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracStemToDdwdFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracStemToAtmsFirF", "fracStemToSDdwdFirF", "fracStemToDdwdFirF"],
        "yrsStemRegrowFirF",
        formGroup,
        formModel
      );
    },

    fracBranToAtmsFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracBranToAtmsFirF", "fracBranToSChwdFirF", "fracBranToSChwdFirF"],
        "yrsBranRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracBranToSChwdFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracBranToAtmsFirF", "fracBranToSChwdFirF", "fracBranToSChwdFirF"],
        "yrsBranRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracBranToDdwdFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracBranToAtmsFirF", "fracBranToSChwdFirF", "fracBranToSChwdFirF"],
        "yrsBranRegrowFirF",
        formGroup,
        formModel
      );
    },

    fracBarkToAtmsFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracBarkToAtmsFirF", "fracBarkToSBlitFirF", "fracBarkToBlitFirF"],
        "yrsBarkRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracBarkToSBlitFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracBarkToAtmsFirF", "fracBarkToSBlitFirF", "fracBarkToBlitFirF"],
        "yrsBarkRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracBarkToBlitFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracBarkToAtmsFirF", "fracBarkToSBlitFirF", "fracBarkToBlitFirF"],
        "yrsBarkRegrowFirF",
        formGroup,
        formModel
      );
    },

    fracLeafToAtmsFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracLeafToAtmsFirF", "fracLeafToSLlitFirF", "fracLeafToLlitFirF"],
        "yrsLeafRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracLeafToSLlitFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracLeafToAtmsFirF", "fracLeafToSLlitFirF", "fracLeafToLlitFirF"],
        "yrsLeafRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracLeafToLlitFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracLeafToAtmsFirF", "fracLeafToSLlitFirF", "fracLeafToLlitFirF"],
        "yrsLeafRegrowFirF",
        formGroup,
        formModel
      );
    },

    fracCortToAtmsFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracCortToAtmsFirF", "fracCortToSCodrFirF", "fracCortToCodrFirF"],
        "yrsCortRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracCortToSCodrFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracCortToAtmsFirF", "fracCortToSCodrFirF", "fracCortToCodrFirF"],
        "yrsCortRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracCortToCodrFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracCortToAtmsFirF", "fracCortToSCodrFirF", "fracCortToCodrFirF"],
        "yrsCortRegrowFirF",
        formGroup,
        formModel
      );
    },

    fracFirtToAtmsFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracFirtToAtmsFirF", "fracFirtToSFidrFirF", "fracFirtToFidrFirF"],
        "yrsFirtRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracFirtToSFidrFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracFirtToAtmsFirF", "fracFirtToSFidrFirF", "fracFirtToFidrFirF"],
        "yrsFirtRegrowFirF",
        formGroup,
        formModel
      );
    },
    fracFirtToFidrFirF: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        ["fracFirtToAtmsFirF", "fracFirtToSFidrFirF", "fracFirtToFidrFirF"],
        "yrsFirtRegrowFirF",
        formGroup,
        formModel
      );
    },
    // fracAfctFirF: (newValue: any, { formGroup, formModel }) => {
    //   formGroup.get("clearEV").setValue(newValue >= 100);
    // },
  };

  private isClearingEventFirF(formValue) {
    const k1Minus = Constants.k1Minus * 100;

    return (
      +formValue.fracAfctFirF >= k1Minus &&
      +formValue.fracStemToAtmsFirF +
        +formValue.fracStemToDdwdFirF +
        +formValue.fracStemToSDdwdFirF >=
        k1Minus &&
      +formValue.fracBranToAtmsFirF +
        +formValue.fracBranToDdwdFirF +
        +formValue.fracBranToSChwdFirF >=
        k1Minus &&
      +formValue.fracBarkToAtmsFirF +
        +formValue.fracBarkToBlitFirF +
        +formValue.fracBarkToSBlitFirF >=
        k1Minus &&
      +formValue.fracLeafToAtmsFirF +
        +formValue.fracLeafToLlitFirF +
        +formValue.fracLeafToSLlitFirF >=
        k1Minus &&
      +formValue.fracCortToAtmsFirF +
        +formValue.fracCortToCodrFirF +
        +formValue.fracCortToSCodrFirF >=
        k1Minus &&
      +formValue.fracFirtToAtmsFirF +
        +formValue.fracFirtToFidrFirF +
        +formValue.fracFirtToSFidrFirF >=
        k1Minus
    );
  }

  public modifyBeforeSave(formGroup) {
    if (!formGroup) {
      return null;
    }

    let modifiedFormGroup = formGroup;
    if (modifiedFormGroup.get("clearEV")) {
      modifiedFormGroup
        .get("clearEV")
        .setValue(this.isClearingEventFirF(modifiedFormGroup.getRawValue()));
    }
    return modifiedFormGroup;
  }

  protected applyYearToRegrowFieldLogic(
    watchFields: string[],
    targetField: string,
    formGroup,
    formModel
  ): void {
    let total = 0;
    for (let f of watchFields) {
      const value = formGroup.get(f).value;

      if (value == null) {
        formModel[targetField].isShown = false;
        this.removeDefaultValidatorsInBulk([targetField], formGroup, formModel);
        return;
      }
      total += +value;
    }

    formModel[targetField].isShown = total > 0;

    this[
      total > 0 ? "addDefaultValidatorsInBulk" : "removeDefaultValidatorsInBulk"
    ]([targetField], formGroup, formModel);
  }

  public writeXmlObject(formData): object {
    const baseFirFFields = [
      "fracAfctFirF",
      "fracStemToAtmsFirF",
      "fracBranToAtmsFirF",
      "fracBarkToAtmsFirF",
      "fracLeafToAtmsFirF",
      "fracCortToAtmsFirF",
      "fracFirtToAtmsFirF",
      "fracStemToDdwdFirF",
      "fracBranToDdwdFirF",
      "fracBarkToBlitFirF",
      "fracLeafToLlitFirF",
      "fracCortToCodrFirF",
      "fracFirtToFidrFirF",
      "fracStemToSDdwdFirF",
      "fracBranToSChwdFirF",
      "fracBarkToSBlitFirF",
      "fracLeafToSLlitFirF",
      "fracCortToSCodrFirF",
      "fracFirtToSFidrFirF",
      "fracDDdwdToAtmsFirF",
      "fracRDdwdToAtmsFirF",
      "fracDChwdToAtmsFirF",
      "fracRChwdToAtmsFirF",
      "fracDBlitToAtmsFirF",
      "fracRBlitToAtmsFirF",
      "fracDLlitToAtmsFirF",
      "fracRLlitToAtmsFirF",
      "fracDCodrToAtmsFirF",
      "fracRCodrToAtmsFirF",
      "fracDFidrToAtmsFirF",
      "fracRFidrToAtmsFirF",
      "fracDDdwdToInrtFirF",
      "fracRDdwdToInrtFirF",
      "fracDChwdToInrtFirF",
      "fracRChwdToInrtFirF",
      "fracDBlitToInrtFirF",
      "fracRBlitToInrtFirF",
      "fracDLlitToInrtFirF",
      "fracRLlitToInrtFirF",
      "fracDCodrToInrtFirF",
      "fracRCodrToInrtFirF",
      "fracDFidrToInrtFirF",
      "fracRFidrToInrtFirF",
      "fracSopmToAtmsFirF",
      "fracLrpmToAtmsFirF",
      "fracMrpmToAtmsFirF",
      "fracSommToAtmsFirF",
      "fracLrmmToAtmsFirF",
      "fracMrmmToAtmsFirF",
      "fracMicrToAtmsFirF",
      "fracSopmToInrtFirF",
      "fracLrpmToInrtFirF",
      "fracMrpmToInrtFirF",
      "fracSommToInrtFirF",
      "fracLrmmToInrtFirF",
      "fracMrmmToInrtFirF",
      "fracMicrToInrtFirF",
      "fracMnamNToAtmsFirF",
      "fracSAmmNToAtmsFirF",
      "fracSNtrNToAtmsFirF",
      "fracDAmmNToAtmsFirF",
      "fracDNtrNToAtmsFirF",
      "yrsStemRegrowFirF",
      "yrsBranRegrowFirF",
      "yrsBarkRegrowFirF",
      "yrsLeafRegrowFirF",
      "yrsCortRegrowFirF",
      "yrsFirtRegrowFirF",
      "fracSDdwdToAtmsFirF",
      "fracSChwdToAtmsFirF",
      "fracSBlitToAtmsFirF",
      "fracSLlitToAtmsFirF",
      "fracSDdwdToDebrFirF",
      "fracSChwdToDebrFirF",
      "fracSBlitToDebrFirF",
      "fracSLlitToDebrFirF",
      "useBioAgeAdjFirF",
      "fixFirF",
      "phaFirF",
    ];
    const xmlObject = {
      $: {},
      notesEV: [],
      FirF: [{ $: {} }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.FirF[0].$ = this.getFormValueObjectForXmlExport(
      baseFirFFields,
      formData
    );

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
