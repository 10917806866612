import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { IOutputListCategory, IOutputListItem } from "../../models";
import { OutputWindowsService } from "src/app/shared/models";

@Component({
  selector: "[fc-output-list-item]",
  templateUrl: "./output-list-item.component.html",
  styleUrls: ["./output-list-item.component.scss"],
})
export class OutputListItemComponent implements OnInit {
  public outputNameMap: { [key: string]: string };

  @Output() selectedItemUpdated = new EventEmitter<void>();
  @Input() category: IOutputListCategory;
  @Input() service: OutputWindowsService;
  @Input() isParentSelected: boolean;
  @Input() parent: IOutputListCategory;
  //to prevent changes to be triggered
  private initialised = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isParentSelected && this.initialised) {
      const isSelected = changes.isParentSelected.currentValue;
      this.toggleSelectedItems(isSelected);
    }
  }

  ngOnInit(): void {
    if (!this.isScenarioOW()) {
      this.outputNameMap = this.service.getOutputNameMap();
      this.initialised = true;

      //reapply isSelected values
      if (this.category.items?.length) {
        setTimeout(() => {
          const outputSelection = this.service
            .getActiveWindow()
            .get("outputSelection").value;
          this.category.items.forEach((i) => {
            if (outputSelection && outputSelection[i.progNmOI] && !i.isSelected) {
              this.toggleSelectedItem(i);
            }
          });
        }, 0);
      }
    }
  }

  public isScenarioOW(): boolean {
    return this.service.getActiveWindow().get("isScenarioView").value;
  }
  public toggleTreeExpansion(category: IOutputListCategory): void {
    category.isExpanded = !category.isExpanded;
  }

  public isItemSelected(): boolean {
    return false;
  }

  public toggleSelectedCategories(): void {
    this.category.isSelected = !this.category.isSelected;
    this.toggleSelectedItems(this.category.isSelected);
    this.selectedItemUpdated.emit();
  }

  public toggleSelectedItems(isSelected): void {
    this.category.isSelected = isSelected;
    //last category
    this.category.isIndeterminate = false;

    this.category.items.forEach((i) => {
      i.isSelected = isSelected;
      if (i.isSelected) {
        this.service.addOutput(i.progNmOI);
      } else {

        this.service.removeOutput(i.progNmOI);
      }
    });

    this.service.updateTotalOutputCount()
  }

  public toggleSelectedItem(item: IOutputListItem): void {
    item.isSelected = !item.isSelected;
    //check if it is the root category

    const level = this.category.level;
    const selectedItems = this.category.items.filter((i) => i.isSelected);

    if (level == 2) {
      if (!selectedItems.length) {
        this.category.isSelected = false;
        this.category.isIndeterminate = false;
      } else if (selectedItems.length == this.category.items.length) {
        this.category.isSelected = true;
        this.category.isIndeterminate = false;
      } else {
        this.category.isIndeterminate = true;
      }
    } else {
      this.setSelectedCategory();
    }

    if (item.isSelected) {
      this.service.addOutput(item.progNmOI);
    } else {
      this.service.removeOutput(item.progNmOI);
    }
    this.service.updateTotalOutputCount();
    this.selectedItemUpdated.emit();
  }

  public setSelectedCategory(): void {
    //Not root categories will have both subcategories and items
    const selectedItems = this.category.items.filter((i) => i.isSelected);
    const selectedSubCategories = this.category.subcategories.filter(
      (sc) => sc.isSelected
    );

    const hasIndeterminateSubCategory = this.category.subcategories.some(
      (sc) => sc.isIndeterminate
    );

    if (hasIndeterminateSubCategory) {
      this.category.isIndeterminate = true;
      this.selectedItemUpdated.emit();
      return;
    }

    if (
      selectedSubCategories.length == this.category.subcategories.length &&
      selectedItems.length == this.category.items.length
    ) {
      this.category.isSelected = true;
      this.category.isIndeterminate = false;
    } else if (selectedSubCategories.length == 0 && selectedItems.length == 0) {
      this.category.isSelected = false;
      this.category.isIndeterminate = false;
    } else {
      this.category.isIndeterminate = true;
    }
    this.selectedItemUpdated.emit();
  }
}
