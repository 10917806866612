import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-validation-report",
  templateUrl: "./validation-report.component.html",
  styleUrls: ["./validation-report.component.scss"],
})
export class ValidationReportComponent
  extends BaseModalFormComponent
  implements OnInit
{
  @Output() action = new EventEmitter();

  public plotFiles = [];

  ngOnInit(): void {}
}
