import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

export class CustomValidators {
  public static timeSeries(
    control?: AbstractControl,
    isAtleastOneRequired?: boolean
  ): ValidationErrors | null {
    if (control && !control.hasValidator(Validators.required)) {
      return null;
    }
    if (control?.value?.rawTS) {
      if (!control?.value.rawTS[0]._) {
        return { timeSeries: true };
      }
      let isInvalid = false;
      const allValues: string[] = control?.value.rawTS[0]._.split(",");

      const dataPointsPerYear = control.value?.$?.dataPerYrTS;

      if (dataPointsPerYear && allValues) {
        isInvalid = true;

        // const method = isAtleastOneRequired ? "some" : "every";
        // const isInvalid = !control?.value.rawTS[0]._.split(",")[method](
        //   (v) => v !== "" && !isNaN(v.valueOf())
        // );

        // return isInvalid ? { timeSeries: isInvalid } : null;

        for (let i = 0; i < allValues.length; i += dataPointsPerYear) {
          const eachYear = allValues.slice(i, i + dataPointsPerYear);
          let isThisYearInvalid = false;

          isThisYearInvalid = !eachYear.every(
            (v) => v !== "" && !isNaN(+v.valueOf())
          );

          // Only require one year of data to be valid
          if (!isThisYearInvalid) {
            isInvalid = false;
            break;
          }
        }
      }

      return isInvalid ? { timeSeries: isInvalid } : null;
    }
    return null;
  }

  public static eventQ(control: FormArray): ValidationErrors | null {
    if (control) {
      for (const event of control.controls)
        if (!event.valid) {
          return { eventQ: true };
        }
    }
    return null;
  }
}
