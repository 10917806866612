import { Component, OnInit, Input } from "@angular/core";
import { Legend } from "src/app/plot/models";

@Component({
  selector: "fc-legend",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
})
export class LegendComponent implements OnInit {
  @Input()
  legend: Legend[] = [];

  constructor() {}

  ngOnInit(): void {}
}
