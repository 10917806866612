<ng-container *ngIf="group && group.isShown">
  <div
    *ngIf="group.label"
    class="accordion-header"
    [class]="{
      'underline-header': group.isUnderlineHeader,
      'sub-header': !group.isRoot,
      'is-expanded': group.isAccordion && group.isExpanded,
      'is-invalid':
        group.showErrors && getErrors(group) && service.getFormGroup().touched
    }"
  >
    <h2 *ngIf="group.isRoot">{{ group.label }}</h2>
    <h3 *ngIf="!group.isRoot && !group.isUnderlineHeader">{{ group.label }}</h3>
    <h4 *ngIf="group.isUnderlineHeader">{{ group.label }}</h4>

    <div class="button-group">
      <a
        aria-label="help link button"
        type="button"
        *ngIf="group.helpLink"
        class="btn"
        [href]="baseHelpPageUrl + '/' + group.helpLink"
        target="_blank"
        [tooltip]="'View ' + group.label + ' help'"
      >
        <i class="bi bi-question-circle"></i>
      </a>
      <button
        [attr.aria-label]="
          group.isExpanded ? 'collapse section button' : 'expand section button'
        "
        [attr.aria-expanded]="group.isExpanded"
        [attr.aria-controls]="'accordion-body-' + id"
        *ngIf="group.isAccordion"
        type="button"
        class="btn"
        (click)="group.isExpanded = !group.isExpanded"
      >
        <i class="bi bi-chevron-down arrow"></i>
      </button>
    </div>
  </div>

  <div
    [id]="'accordion-body-' + id"
    [attr.aria-hidden]="
      group.isExpanded !== undefined &&
      group.isExpanded !== null &&
      !group.isExpanded
    "
    class="accordion-body"
    [class]="{
      fixed: !group.isAccordion,
      'bordered-container': group.isBorderedContainer,
      'underline-items': group.isUnderlineItems
    }"
  >
    <ul>
      <li class="list-item" *ngIf="group.subLabel">
        <div class="font-italic">{{ group.subLabel }}</div>
      </li>
      <ng-container *ngFor="let item of group.items">
        <li
          class="list-item"
          *ngIf="item.id ? service.getFormModel()[item.id].isShown : true"
          [class]="{ 'full-width': item.label === null }"
        >
          <!---hide method only works for the first input-->
          <ng-container
            *ngIf="
              !(item.label === null) &&
              item.inputs &&
              (item.inputs?.length && item.inputs[0].hideMethod
                ? !service[item.inputs[0]?.hideMethod](
                    item.inputs[0]?.hideMethodPayload
                  )
                : true)
            "
          >
            <span *ngIf="item.label === ''"></span>
            <label
              class="item-label"
              *ngIf="
                !(item.label == '') && !item.preventLabelElement;
                else noLabelElement
              "
              [for]="
                item.inputs?.length == 1 ? item.inputs[0]?.programmingName : ''
              "
            >
              {{ item.label }}
              <a
                aria-label="help link button"
                type="button"
                *ngIf="item.helpLink"
                class="btn"
                [href]="baseHelpPageUrl + '/' + item.helpLink"
                target="_blank"
                [tooltip]="'View ' + item.label + ' help'"
              >
                <i class="bi bi-question-circle"></i>
              </a>
            </label>

            <ng-template #noLabelElement
              ><span class="input-label">{{ item.label }}</span></ng-template
            >
          </ng-container>

          <div
            [class]="{ 'full-width': item.groups }"
            fc-form-item-element
            [formGroupInstance]="formGroupInstance"
            [simulationService]="simulationService"
            [service]="service"
            [item]="item"
            [isModifiersCol]="isModifiersCol"
          ></div>
        </li>
      </ng-container>
    </ul>
    <div
      class="no-item"
      *ngIf="isAllItemsHidden(group) && !group.isRoot && service.noItemMessage"
    >
      {{ service.noItemMessage || "No items" }}
    </div>
  </div>
</ng-container>
