import { Component, OnInit, Input } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { startWith, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import TimeUtilities from "src/app/shared/utilities/time";
import { environment } from "src/environments/environment";
import { EventsService } from "src/app/shared/models";

@Component({
  selector: "fc-timing-form",
  templateUrl: "./timing-form.component.html",
  styleUrls: ["./timing-form.component.scss"],
})
export class TimingFormComponent implements OnInit {
  @Input()
  eventsService: EventsService;

  @Input()
  formGroupInstance: UntypedFormGroup;

  @Input()
  startDate: Date;

  @Input()
  displayDirection: "horizontal" | "vertical" = "horizontal";

  @Input()
  simulationTimingType: "calendar" | "step";

  public readonly baseUrl = environment.fullcam2020HelpUrl;

  public readonly eventTimingHelpLink = "143_Event%20Timing.htm";

  private readonly destroy$ = new Subject<void>();

  public numberOfDays: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.setValidation();

    this.formGroupInstance
      .get("dateOriginEV")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((dateOriginType) => {
        if (dateOriginType == "Calendar") {
          this.setEventDate(true);
        } else if (dateOriginType == "StartSim") {
          this.setStartSimYearsAndDays();
        }

        this.setValidation();
      });
  }

  protected setValidation() {
    const dateOriginType = this.formGroupInstance.get("dateOriginEV").value;
    if (dateOriginType == "Calendar") {
      this.formGroupInstance.get("dateEV").addValidators(Validators.required);
      this.formGroupInstance
        .get("nYrsFromStEV")
        .removeValidators(Validators.required);
      this.formGroupInstance
        .get("nDaysFromStEV")
        .removeValidators(Validators.required);
    } else if (dateOriginType == "StartSim") {
      this.formGroupInstance
        .get("dateEV")
        .removeValidators(Validators.required);
      this.formGroupInstance
        .get("nYrsFromStEV")
        .addValidators(Validators.required);
      this.formGroupInstance
        .get("nDaysFromStEV")
        .addValidators(Validators.required);
    }

    this.formGroupInstance.get("dateEV").updateValueAndValidity();
    this.formGroupInstance.get("nYrsFromStEV").updateValueAndValidity();
    this.formGroupInstance.get("nDaysFromStEV").updateValueAndValidity();
  }

  public setEventDate(removeStartSimFields?) {
    const moment = TimeUtilities.getMoment();
    let numYear = +this.formGroupInstance.get("nYrsFromStEV").value || 0;
    let numDay = +this.formGroupInstance.get("nDaysFromStEV").value || 0;

    if (numDay > 365) {
      const yearsAdding = Math.floor(numDay / 365);
      numYear = numYear + yearsAdding;
      numDay = numDay - yearsAdding * 365;
    }
    const startDateObject = moment(this.startDate);
    const startYear = +startDateObject.year();
    const endYear = +startYear + numYear;
    const isLastYearLeapYear = moment([]).isLeapYear();
    const isAfterFeb = moment(endYear)
      .add(+numDay, "days")
      .isAfter(moment(endYear + "-02-29").toDate());

    if (isLastYearLeapYear && isAfterFeb) {
      numDay += 1;
    }

    startDateObject.add(+numYear, "years");
    startDateObject.add(+numDay, "days");

    this.formGroupInstance.get("dateEV").setValue(startDateObject.toDate());
    if (removeStartSimFields) {
      this.formGroupInstance.get("nYrsFromStEV").setValue(null);
      this.formGroupInstance.get("nDaysFromStEV").setValue(null);
    }
  }

  public setStartSimYearsAndDays() {
    const moment = TimeUtilities.getMoment();
    const eventDate = this.formGroupInstance.get("dateEV").value;

    if (eventDate && this.startDate) {
      //check if day and month are the same
      const eventDateObject = moment(eventDate);
      const startDateOBject = moment(this.startDate);

      if (
        eventDateObject.date() == startDateOBject.date() &&
        eventDateObject.month() == startDateOBject.month()
      ) {
        const diffYear = eventDateObject.year() - startDateOBject.year();
        this.formGroupInstance.get("nYrsFromStEV").setValue(diffYear);
        this.formGroupInstance.get("nDaysFromStEV").setValue(0);
        return;
      }

      let diffDays = moment(eventDate).diff(this.startDate, "days");

      //if (this.simulationTimingType == "step") {
      diffDays =
        diffDays -
        TimeUtilities.getNumbersOfLeapYearDaysBetweenDates(
          this.startDate,
          eventDate
        );
      //}

      if (diffDays < 365) {
        this.formGroupInstance.get("nYrsFromStEV").setValue(0);
        this.formGroupInstance.get("nDaysFromStEV").setValue(diffDays);
      } else {
        const years = Math.floor(diffDays / 365);
        const days = diffDays - 365 * years;
        this.formGroupInstance.get("nYrsFromStEV").setValue(years);
        this.formGroupInstance.get("nDaysFromStEV").setValue(days);
      }
    }
  }

  public addDays(): void {
    const moment = TimeUtilities.getMoment();
    const eventDateControl = this.formGroupInstance.get("dateEV");
    const eventDate = eventDateControl.value;

    if (moment(eventDate).isValid && this.numberOfDays) {
      eventDateControl.setValue(
        moment(eventDate).add(this.numberOfDays, "d").toDate()
      );
      this.formGroupInstance.markAsDirty();
    }
  }

  public getYearConversionText(days): string {
    const years = Math.floor(days / 365);
    return `${days} days = ${
      this.simulationTimingType == "calendar" ? "approx" : ""
    } ${years} year(s) and ${days - years * 365} day(s)`;
  }

  ngOnDestroy(): void {
    if (this.formGroupInstance.get("dateOriginEV").value == "StartSim") {
      this.setEventDate();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }
}
