import { Injectable } from "@angular/core";
import {
  EsatePlotTableColumns,
  FolderList,
  PlotTableColumns,
  ProjectTableColumns,
} from "src/app/my-plots/models";
import { FormModel } from "src/app/shared/models";
import { MessageService } from "src/app/shared/services/message.service";
import {
  EstatePlot,
  FormGroupElement,
  FormLayout,
  PlotFileValidationResults,
} from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { catchError, map, take } from "rxjs/operators";
import { BehaviorSubject, Subject, lastValueFrom, of } from "rxjs";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ValidationReportComponent } from "../components/validation-report/validation-report.component";
import { ModalService } from "src/app/shared/services/modal.service";
import { ProjectCollectionSelectorComponent } from "src/app/shared/components/project-collection-selector/project-collection-selector.component";
import { PlotFilesSelectComponent } from "src/app/shared/components/plot-files-select/plot-files-select.component";
import { PlotUploadComponent } from "src/app/shared/components/plot-upload/plot-upload.component";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { PlotEntryModalComponent } from "src/app/shared/components/plot-entry-modal/plot-entry-modal.component";

@Injectable({
  providedIn: "root",
})
export class PlotFiles2020Service extends BasePlotFormService {
  readonly unsubscribe$ = new Subject<void>();

  private generatedPLotFileLog: { succeeded: string[]; failed: string[] } = {
    succeeded: [],
    failed: [],
  };

  public isReadingXml$ = new BehaviorSubject<boolean>(false);

  public readonly pageId = "plotFiles";
  public readonly pageHelpLink = "166_Plot%20Files.htm";

  public projectCollectionFilter: {
    collection: string;
    collectionId: string;
    project: string;
    projectId: string;
  } = null;

  public readonly legend = [
    { color: "#a6caf0", label: "Valid but not used" },
    { color: "#c0c0c0", label: "Not valid and not used" },
    { color: "#000000", label: "Not found" },
    { color: "#00ff00", label: "Used and valid" },
    { color: "#ff0000", label: "Used and not valid" },
    { color: "#ffffff", label: "Not yet validated" },
  ];

  public validationReportTime: string = "Validation has not been run";

  public modifiers: FormGroupElement[] = [
    {
      label: "Folders with all the plot files",
      isShown: true,
      isRoot: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotFileFolder",
            },
            {
              buttonLabel: "Choose folder",
              element: "button",
              submitMethod: "selectPlotFiles",
            },
          ],
        },
      ],
    },
    {
      label: "Validation report",
      isShown: true,
      isRoot: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getValidationReportTime",
            },
            {
              buttonLabel: "View report",
              showButtonMethod: "shouldShowReportButton",
              element: "button",
              submitMethod: "openValidationReport",
            },
          ],
        },
      ],
    },
    {
      label: "Legend",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "LegendComponent",
              componentInputs: [
                {
                  inputKey: "legend",
                  variable: "legend",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Plot files",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: false,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: null,
                element: "component",
                component: "PlotFileListComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    plotFiles: {
      defaultValue: [], //not using formArray as theres no input needed to interact
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      isShown: true,
    },
  };

  constructor(
    private modalService: ModalService,
    private messageService: MessageService,
    private myPlotsService: MyPlotsService
  ) {
    super();
  }

  public getValidationReportTime(): string {
    return this.validationReportTime;
  }

  public getPlotFileFolder(): string {
    if (!this.projectCollectionFilter) {
      return "No folder selected.";
    }

    return `Project: ${this.projectCollectionFilter?.project} / Collection: ${this.projectCollectionFilter?.collection}`;
  }

  public async addPlotFiles(plotFiles: PlotTableColumns[]) {
    const plotFileControl = this.getFormGroup().get("plotFiles");
    const currentPlotFiles = plotFileControl.value;
    const currentPlotIds = currentPlotFiles.map((p) => p.plotId);
    const newPlotFiles = plotFiles.filter(
      (p) => !currentPlotIds.includes(p.plotId)
    );
    let plotFileWithStatus: EsatePlotTableColumns[] = newPlotFiles.map((p) => {
      return {
        ...p,
        validationStatus: "Not yet validated",
        validationMessage: "",
        isValid: null,
        isUsedByEstate: false,
      };
    });
    if (plotFileWithStatus.length) {
      await this.validatePlotFiles(plotFileWithStatus);
      plotFileControl.setValue([...currentPlotFiles, ...plotFileWithStatus]);
    } else {
      this.simulationService.messageService.addAlert({
        type: "success",
        msg: `No plot files added.`,
        timeout: 3000,
      });
    }
  }

  public clearPlotFileList(): void {
    this.getFormGroup().get("plotFiles").setValue([]);
  }

  public getPlotFileRowById(plotId: string) {
    const plotFileControl = this.getFormGroup().get("plotFiles");
    const plotFiles = plotFileControl.value;
    return plotFiles.find((p) => p.plotId == plotId);
  }

  public async addPlotFilesToEstate(xmlPlotsResults) {
    let plotFileIdsAddedToEstate = [];
    this.messageService.setFullScreenLoading(true);

    for (const xmlPlotsResult of xmlPlotsResults) {
      try {
        const plotFileJson =
          await this.simulationService.helperService.convertXmlToJson(
            xmlPlotsResult.result
          );

        const isJsonValid = this.plotFileJsonCheck(plotFileJson);

        if (!isJsonValid) {
          console.error("Invalid plot file, skipped");
          continue;
        }

        const timingFields = plotFileJson["DocumentPlot"]?.Timing[0].$;
        const stepType = timingFields.tStepsYTZ;
        const customStep = timingFields.stepsPerYrYTZ;
        const plotStartStep =
          stepType == "Yearly" || (stepType == "Other" && customStep == 1)
            ? 1
            : timingFields.stStepInStYrYTZ;
        const plotSiteArea = this.getSiteAreaFromPlotJson(plotFileJson);

        let formData: EstatePlot =
          this.simulationService.estateService.getEstatePlotTemplate();
        formData.startYrEP = timingFields.stYrYTZ;
        formData.startStepEP = plotStartStep;
        formData.plotIxEP = -1; //index will be reassigned when writting xml
        formData.plotId = xmlPlotsResult.id;
        formData.plotAreaEP = plotSiteArea;
        formData.fileName = this.getFileNameByPlotId(xmlPlotsResult.id);
        formData.hasAreaEP = plotSiteArea !== null;
        this.simulationService.estateService.createEstatePlot(formData);

        plotFileIdsAddedToEstate.push(String(xmlPlotsResult.id));
        +this.simulationService.messageService.addAlert({
          type: "success",
          msg: `Estate Plot has been created in 'Plots in Estate' page!`,
          timeout: 3000,
        });
      } catch (error) {
        this.simulationService.messageService.addAlert({
          type: "danger",
          msg: `Something went wrong, Estate Plot cannot be created.`,
        });
      }
    }

    this.setPlotStatusByPlotIds(plotFileIdsAddedToEstate, "Used and valid");
    this.messageService.setFullScreenLoading(false);
  }

  public setProjectCollectionFilter(projectCollectionFilter): void {
    this.projectCollectionFilter = projectCollectionFilter;
  }

  public async validatePlotFiles(plotFiles: EsatePlotTableColumns[]) {
    this.messageService.setFullScreenLoading(true);

    const currentdate = new Date();
    const datetime =
      "Last Validated: " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    this.validationReportTime = datetime;

    try {
      const plotFilesWithId = plotFiles.filter(
        (p) => p.plotId !== null && p.plotId !== undefined
      );
      const verifyResults = await this.verifyPlotFiles(
        plotFilesWithId.map((p) => p.plotId)
      );

      if (verifyResults?.length) {
        this.applyStatusToVerifiedPlotFiles(plotFiles, verifyResults);
      }
    } catch (error) {
    } finally {
      this.messageService.setFullScreenLoading(false);
    }
  }

  public async verifyPlotFiles(plotIds): Promise<PlotFileValidationResults[]> {
    const params = {
      plotIds: plotIds,
      siteAreaData: true,
      fileType: "plo",
    };
    const verifyResults: PlotFileValidationResults[] = await lastValueFrom(
      this.simulationService.dbService.verifyPlotFiles(params).pipe(take(1))
    );
    return verifyResults;
  }

  public applyStatusToVerifiedPlotFiles(
    plotFiles: EsatePlotTableColumns[],
    verifyResults: PlotFileValidationResults[]
  ) {
    for (let p of plotFiles) {
      if (p.plotId == null || p.plotId == undefined) {
        p.isValid = false;
      }

      const foundResult = verifyResults.find(
        (r) => String(r.plotId) == String(p.plotId)
      );

      const isUsedByEstate = this.isUsedByEstate(p.plotId, "plotId");

      if (!foundResult) {
        p.validationStatus = "Not valid and not used";
        p.validationMessage = "Plot file cannot be located";
        p.isValid = false;
        continue;
      }

      if (foundResult && foundResult.status) {
        const isValid = foundResult.status == "Valid";
        p.isValid = isValid;
        if (isValid) {
          p.validationStatus = isUsedByEstate
            ? "Used and valid"
            : "Valid but not used";
          p.validationMessage = isUsedByEstate
            ? "Used and valid"
            : "Valid but not used";
        } else {
          p.validationStatus = isUsedByEstate
            ? "Used and not valid"
            : "Not valid and not used";
          p.validationMessage = "Not ready - no other checks performed";
        }

        //update name if name changed
        if (p.fileName !== foundResult.fileName) {
          p.fileName = foundResult.fileName;
          if (isUsedByEstate) {
            this.handePlotFileNameChange(p);
          }
        }
        continue;
      }
    }
  }

  private handePlotFileNameChange(plotFile: EsatePlotTableColumns) {
    this.simulationService.estateService.updatePlotFileName(plotFile);
  }

  public setPlotStatusByPlotIds(plotIds: string[], status) {
    const plotFileControl = this.getFormGroup().get("plotFiles");
    let currentPlotFiles = plotFileControl.value;

    for (const plotId of plotIds) {
      let foundPlotFile = currentPlotFiles.find((p) => p.plotId == plotId);
      if (foundPlotFile) {
        foundPlotFile.validationStatus = status;
      } else {
        console.error(
          "Plot file with Id:" + plotId + " not found when adding status."
        );
      }
    }

    plotFileControl.setValue(currentPlotFiles);
  }

  public getSiteAreaFromPlotJson(plotFileJson) {
    const doc = plotFileJson["DocumentPlot"];
    if (!plotFileJson) {
      console.error("Invalid file content.");
      return null;
    }

    if (!doc) {
      console.error("Invalid plot type.");
      return null;
    }

    const site = doc.Site[0].$;
    const hasArea = site.hasArea == "true";
    const siteArea = site.siteArea;
    if (!hasArea) {
      return null;
    }
    return siteArea;
  }

  public async deletePlotFiles(
    idObjects: { plotId: string; fileName: string }[]
  ): Promise<void> {
    let userConfirmedDeleteBefore = false;
    //if user  imported estate file with borken link, fileName will be used as identifier
    let remainingPlotFiles = [];
    const plotFiles = this.getFormGroup().get("plotFiles").value;

    for (const plotFile of plotFiles) {
      const identifier =
        plotFile.plotId == -1 ||
        plotFile.plotId == null ||
        plotFile.plotId == undefined
          ? "fileName"
          : "plotId";
      const deletingPlotFile = idObjects.find(
        (idObject) => idObject[identifier] == plotFile[identifier]
      );
      if (deletingPlotFile) {
        if (this.isUsedByEstate(plotFile[identifier], identifier)) {
          const message =
            "Deleting this plot file will delete the corresponding plots in the estate, Continute?";
          const confirm = userConfirmedDeleteBefore
            ? true
            : await this.simulationService.modalService.openConfirmModal(
                message
              );

          if (confirm) {
            userConfirmedDeleteBefore = true;
            const estateIds = this.getEstatePlotIds(
              plotFile[identifier],
              identifier
            );
            this.simulationService.estateService.deleteEstatePlots(estateIds);
            continue;
          } else {
            remainingPlotFiles.push(plotFile);
            continue;
          }
        }
        continue;
      }

      remainingPlotFiles.push(plotFile);
    }
    this.getFormGroup().get("plotFiles").setValue(remainingPlotFiles);
  }
  protected getFileNameByPlotId(plotId): string {
    const plotFiles = this.getFormGroup().get("plotFiles").value;
    if (!plotFiles || !plotFiles.length) {
      return;
    }

    const foundPlotFile = plotFiles.find((p) => p.plotId == plotId);
    return foundPlotFile?.fileName;
  }

  protected getEstatePlotIds(
    value,
    identifier: "plotId" | "fileName"
  ): string[] {
    const estatePlots =
      this.simulationService.estateService.getFormGroup().get("estatePlots")
        .value || [];

    const foundEstatePlots: EstatePlot[] = estatePlots.filter(
      (ep) => ep[identifier] == value
    );
    if (foundEstatePlots.length) {
      return foundEstatePlots.map((ep) => ep.plotId);
    }

    return null;
  }

  protected isUsedByEstate(value, identifier: "plotId" | "fileName"): boolean {
    const estatePlotIds = this.getEstatePlotIds(value, identifier);
    return estatePlotIds?.length > 0;
  }

  protected plotFileJsonCheck(plotFileJson): boolean {
    if (!plotFileJson) {
      console.error("Invalid file content.");
      return false;
    }
    const doc = plotFileJson["DocumentPlot"];
    if (!doc) {
      console.error("Invalid plot type.");
      return false;
    }

    //call validate API

    // const siteModel = this.simulationService.siteService.formModel;
    // const siteAreaMin = siteModel.low;
    // const site = doc.Site[0].$;
    // const hasArea = site.hasArea == "true";
    // const siteArea = site.siteArea;
    // if (!hasArea) {
    //   console.error("Site area not turned on");
    //   return false;
    // }

    // if (!siteArea || +siteArea < siteAreaMin) {
    //   console.error("Invalid site area value");
    //   return false;
    // }

    return true;
  }

  public shouldShowReportButton() {
    return this.validationReportTime !== "Validation has not been run";
  }

  public openValidationReport() {
    const plotFiles = this.formGroup.get("plotFiles").value || [];
    const invalidPlotFiles = plotFiles.filter((p) => p.isValid == false);
    const initialState: ModalOptions = {
      class: "full-screen",
      ignoreBackdropClick: true,
      initialState: {
        plotFiles: invalidPlotFiles,
      },
    };

    this.simulationService.modalService.openModal(ValidationReportComponent, {
      ...initialState,
    });
  }

  public selectPlotFiles() {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        forcePlotTypeFilter: "plo",
        projectCollectionFilter: this.projectCollectionFilter,
        selectedPlotFiles: this.getFormGroup().get("plotFiles").value || [],
      },
    };

    this.modalService
      .openModal(PlotFilesSelectComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result) => {
        const selectedProjectCollection = result?.projectCollectionFilter;
        if (result?.plotFiles) {
          if (
            this.projectCollectionFilter &&
            JSON.stringify(selectedProjectCollection) !==
              JSON.stringify(this.projectCollectionFilter)
          ) {
            this.simulationService.messageService.addAlert({
              type: "warning",
              msg: "Project or collection has been changed, the existing plot files list is cleared.",
              dismissible: true,
            });
            this.clearPlotFileList();
          }

          this.addPlotFiles(result.plotFiles);
        }
        if (result) {
          this.setProjectCollectionFilter(result?.projectCollectionFilter);
        }
      });
  }

  public async uploadPlotFilesAndRelink() {
    const projectCollectionFilter = this.projectCollectionFilter;

    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        projectCollectionFilter: projectCollectionFilter,
      },
    };

    this.modalService
      .openModal(PlotUploadComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe(() => {
        if (!this.simulationService.isUploading$.getValue()) {
          this.simulationService.resetFileUpload();
        }

        this.relinkPlotFiles();
        // const plotFiles = this.formGroup.get("plotFiles").value;

        // let missingPlotFiles: EsatePlotTableColumns[] = plotFiles.filter(
        //   (p) => p.validationStatus == "Not found"
        // );

        // const missingPlotFileNames = missingPlotFiles.map((p) => p.fileName);

        // try {
        //   this.simulationService.dbService
        //     .filterPlotFileList({
        //       fileNames: missingPlotFileNames,
        //       projectId,
        //       collectionId,
        //       size: 9999,
        //     })
        //     .pipe(take(1))
        //     .subscribe(async (response: any) => {
        //       const plotFileList: EsatePlotTableColumns[] = response.data || [];
        //       if (plotFileList.length) {
        //         //replace missingPlotFiles with this data
        //         for (let i = 0; i < missingPlotFiles.length; i++) {
        //           const foundMatchingFile = plotFileList.find(
        //             (p) => p.fileName == missingPlotFiles[i].fileName
        //           );
        //           if (foundMatchingFile) {
        //             missingPlotFiles[i] = foundMatchingFile;
        //           }
        //         }

        //         const foundMissingPlotFiles = missingPlotFiles.filter(
        //           (p) => p.plotId !== null && p.plotId !== undefined
        //         );
        //         const ids = foundMissingPlotFiles.map((p) => p.plotId);

        //         if (!ids.length) {
        //           return;
        //         }

        //         const verifyResults: PlotFileValidationResults[] =
        //           await this.verifyPlotFiles(ids);

        //         if (verifyResults.length) {
        //           this.applyStatusToVerifiedPlotFiles(
        //             foundMissingPlotFiles,
        //             verifyResults
        //           );

        //           this.replacePlotFiles(foundMissingPlotFiles, true);
        //         }
        //       }
        //     });
        // } catch (error) {
        //   console.error(error);
        // }
      });
  }

  public relinkPlotFiles() {
    const projectCollectionFilter = this.projectCollectionFilter;
    const projectId = projectCollectionFilter.projectId;
    const collectionId = projectCollectionFilter.collectionId;

    const plotFiles = this.formGroup.get("plotFiles").value;

    let missingPlotFiles: EsatePlotTableColumns[] = plotFiles.filter(
      (p) => p.validationStatus == "Not found"
    );

    const missingPlotFileNames = missingPlotFiles.map((p) => p.fileName);

    try {
      this.simulationService.dbService
        .filterPlotFileList({
          fileNames: missingPlotFileNames,
          projectId,
          collectionId,
          size: 9999,
        })
        .pipe(take(1))
        .subscribe(async (response: any) => {
          const plotFileList: EsatePlotTableColumns[] = response.data || [];
          if (plotFileList.length) {
            //replace missingPlotFiles with this data
            for (let i = 0; i < missingPlotFiles.length; i++) {
              const foundMatchingFile = plotFileList.find(
                (p) => p.fileName == missingPlotFiles[i].fileName
              );
              if (foundMatchingFile) {
                missingPlotFiles[i] = foundMatchingFile;
              }
            }

            const foundMissingPlotFiles = missingPlotFiles.filter(
              (p) => p.plotId !== null && p.plotId !== undefined
            );
            const ids = foundMissingPlotFiles.map((p) => p.plotId);

            if (!ids.length) {
              return;
            }

            const verifyResults: PlotFileValidationResults[] =
              await this.verifyPlotFiles(ids);

            if (verifyResults.length) {
              this.applyStatusToVerifiedPlotFiles(
                foundMissingPlotFiles,
                verifyResults
              );

              this.replacePlotFiles(foundMissingPlotFiles, true);
            }
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  public async readXmlObject() {
    this.isReadingXml$.next(true);
    try {
      super.readXmlObject();
      const fileIdSet = this.simulationService.getPlotFileJson()?.FileIdSet;
      const template = fileIdSet?.[0];
      const fileIds = template?.FileId || [];
      const fileIdPath = template?.$.fileIdPath;

      const projectId = this.extractIdFromPath(fileIdPath, "projectId");
      const collectionId = this.extractIdFromPath(fileIdPath, "collectionId");
      const hasProjectAndCollection = projectId && collectionId;

      const folders: FolderList[] = await this.fetchFolders();

      const fileObjects = this.mapFileObjects(fileIds);

      let selectedProjectCollection = hasProjectAndCollection
        ? this.findProjectCollection(folders, projectId, collectionId)
        : await this.selectProjectAndCollection();

      if (selectedProjectCollection) {
        this.setProjectCollectionFilter(selectedProjectCollection);
        await this.readImportingPlotFiles(fileObjects);
      } else {
        this.isReadingXml$.next(false);
      }
    } catch (error) {
      this.isReadingXml$.next(false);
      // Handle the error appropriately
    } finally {
      if (this.projectCollectionFilter) {
        this.setSelectedProjectAndCollectionInAboutPage(
          this.projectCollectionFilter
        );
      }
    }
  }

  public setSelectedProjectAndCollectionInAboutPage(selectedProjectCollection) {
    if (!this.simulationService.isEditPlot()) {
      const aboutService = this.simulationService.aboutService;
      aboutService.setProjectCollection(
        selectedProjectCollection.project,
        selectedProjectCollection.collection
      );
    }
  }

  public mapFileObjects(
    fileIds: any[]
  ): { fileName: string; plotId: string }[] {
    return fileIds.map((obj) => ({
      fileName: obj?.$?.plotFNm || null,
      plotId: obj?.$?.plotId || null,
    }));
  }

  protected extractIdFromPath(path: string, key: string): string | null {
    const match = path?.match(new RegExp(`${key}:(\\d+)`));
    return match ? match[1] : null;
  }

  protected async fetchFolders(): Promise<FolderList[]> {
    return (
      lastValueFrom(
        this.simulationService.aboutService.getFolders().pipe(take(1))
      ) || []
    );
  }
  //used by generate estate function
  public selectProjectAndCollection(): Promise<any> {
    return new Promise((resolve, reject) => {
      const initialState: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {},
      };

      this.modalService
        .openModal(ProjectCollectionSelectorComponent, {
          ...initialState,
        })
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            const selectedProjectCollection = result;
            if (selectedProjectCollection) {
              resolve(selectedProjectCollection);
            } else {
              reject("No selection made");
            }
          },
          error: (error) => reject(error),
        });
    });
  }

  protected findProjectCollection(
    folders: FolderList[],
    projectId: string,
    collectionId: string
  ): {
    project: string;
    collection: string;
    projectId: string | number;
    collectionId: string | number;
  } | null {
    const foundProject = folders.find(
      (pc) =>
        pc.projectId == projectId &&
        pc.collectionInfo[0]?.collectionId == collectionId
    );
    if (foundProject) {
      return {
        project: foundProject.name,
        collection: foundProject.collectionInfo[0].name,
        projectId: foundProject.projectId,
        collectionId: foundProject.collectionInfo[0].collectionId,
      };
    }

    return null;
  }

  public async readImportingPlotFiles(fileObjects, includeLog?: boolean) {
    return new Promise<void>(async (resolve, reject) => {
      const fileNames = fileObjects.map((obj) => obj.fileName);
      const projectId = this.projectCollectionFilter.projectId;
      const collectionId = this.projectCollectionFilter.collectionId;

      this.simulationService.dbService
        .filterPlotFileList({ fileNames, projectId, collectionId, size: 99999 })
        .pipe(
          take(1),
          map((response: { data: ProjectTableColumns[] }) => {
            const plotFileList = response.data || [];
            return this.categorisePlotFiles(fileNames, plotFileList);
          }),
          catchError((error) => {
            // Handle error, log it or show to the user if needed
            this.addAllImportFailedPlotFiles(fileNames);
            return of({ foundItems: [], missingItems: fileNames });
          })
        )
        .subscribe({
          next: async ({ foundItems, missingItems }) => {
            if (includeLog) {
              this.generatedPLotFileLog.succeeded = foundItems.map(
                (i) => `Plot file added: ${i.fileName}`
              );
              this.generatedPLotFileLog.failed = missingItems.map(
                (i) => `Import failed: ${i}`
              );
            }

            if (foundItems.length) {
              await this.addPlotFiles(foundItems);
              this.isReadingXml$.next(false);
            }
            if (missingItems.length) {
              this.addAllImportFailedPlotFiles(missingItems);
              this.isReadingXml$.next(false);
            }

            // Follow the index from imported XML
            const unsortedPlotFiles =
              this.getFormGroup().get("plotFiles").value;
            const sortedPlotFiles = await this.sortPlotFiles(
              unsortedPlotFiles,
              fileObjects
            );
            this.getFormGroup().get("plotFiles").setValue(sortedPlotFiles);

            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  protected async sortPlotFiles(
    unsortedPlotFiles: ProjectTableColumns[],
    fileObjects
  ): Promise<ProjectTableColumns[]> {
    // Create a mapping of fileName to fileObject index
    const fileIndexMap = new Map<string, number>();
    fileObjects.forEach((obj, index) => fileIndexMap.set(obj.fileName, index));

    // Sort unsortedPlotFiles based on the order of fileObjects
    const sortedPlotFiles = unsortedPlotFiles.sort((a, b) => {
      const indexA = fileIndexMap.get(a["fileName"]) || 0;
      const indexB = fileIndexMap.get(b["fileName"]) || 0;
      return indexA - indexB;
    });

    return sortedPlotFiles;
  }

  private categorisePlotFiles(fileNames, plotFileList) {
    return fileNames.reduce(
      (acc, item) => {
        const foundFromList = plotFileList.find((l) => l.fileName == item);
        foundFromList
          ? acc.foundItems.push(foundFromList)
          : acc.missingItems.push(item);
        return acc;
      },
      { foundItems: [], missingItems: [] }
    );
  }

  protected addAllImportFailedPlotFiles(fileNames: string[]): void {
    const plotFileControl = this.getFormGroup().get("plotFiles");

    const plotListTemplate: EsatePlotTableColumns = {
      fileName: null,
      plotId: null,
      projectId: null,
      collectionId: null,
      fileType: null,
      project: null,
      collection: null,
      latitude: null,
      longitude: null,
      validationStatus: "Not found",
      validationMessage: "Import failed or file not found from the collection",
      isValid: false,
      isUsedByEstate: false,
    };
    const rows = fileNames.map((fileName) => {
      return { ...plotListTemplate, fileName };
    });
    plotFileControl.setValue([...plotFileControl.value, ...rows]);
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();

    const fileIds = formData.plotFiles.map((f) => {
      return { $: { plotFNm: f.fileName } };
    });

    //get file path
    let fileIdPath = "";
    if (this.projectCollectionFilter) {
      fileIdPath = `projectId:${this.projectCollectionFilter.projectId}, collectionId:${this.projectCollectionFilter.collectionId}`;
    }
    const fileSet = {
      count: String(fileIds.length),
      fileIdPath: fileIdPath,
    };
    return { FileIdSet: [{ $: fileSet, FileId: fileIds }] };
  }

  public replacePlotFiles(plotFilesToBeReplaced, replaceByName = false) {
    const plotFileControl = this.getFormGroup().get("plotFiles");
    const checkingField = replaceByName ? "fileName" : "plotId";
    let currentPlotFiles = plotFileControl.value;
    let plotFilesToBeRelinked = [];

    for (let i = 0; i < plotFilesToBeReplaced.length; i++) {
      let foundMatchingFileIndex = currentPlotFiles.findIndex(
        (cp) => cp[checkingField] == plotFilesToBeReplaced[i]?.[checkingField]
      );
      if (foundMatchingFileIndex > -1) {
        currentPlotFiles[foundMatchingFileIndex] = plotFilesToBeReplaced[i];
        plotFilesToBeRelinked.push(plotFilesToBeReplaced[i]);
      }
    }

    plotFileControl.setValue(currentPlotFiles);
    this.simulationService.estateService.updatedBrokenLinks(
      plotFilesToBeRelinked
    );
  }

  public async editPlotFile(
    row: EsatePlotTableColumns,
    title?: string
  ): Promise<void> {
    const simulationService = this.simulationService.getNewInstance();

    this.messageService.setFullScreenLoading(true);

    const plotFileResults = await lastValueFrom(
      this.myPlotsService.downloadPlotFiles([row.plotId]).pipe(take(1))
    );
    const plotFile = plotFileResults[0].result;

    const plotFileJson = await simulationService.helperService.convertXmlToJson(
      plotFile
    );

    if (!simulationService.aboutService.formGroup) {
      simulationService.aboutService.getFormGroup();
    }

    simulationService.aboutService.formGroup
      .get("project")
      .setValue(row.project);
    simulationService.aboutService.formGroup
      .get("collection")
      .setValue(row.collection);

    simulationService.setPlotMetaData(row);
    simulationService.setImportedPlotFileJson(plotFileJson);
    simulationService.readPlotFileJson();

    this.messageService.setFullScreenLoading(false);

    const initialState: ModalOptions = {
      keyboard: false,
      ignoreBackdropClick: true,
      class: "full-screen",
      initialState: {
        backToPageText: title || "Back to previous page",
        simulationService: simulationService,
      },
    };

    this.modalService
      .openModal(PlotEntryModalComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.validatePlotFiles([row]);
      });
  }

  public reset(): void {
    this.generatedPLotFileLog = {
      succeeded: [],
      failed: [],
    };
    this.getFormGroup().get("plotFiles")?.setValue([]);
    this.projectCollectionFilter = null;
    this.validationReportTime = "Validation has not been run";
    this.unsubscribe$.next();
    this.isReadingXml$.next(false);
    super.reset();
  }
}
