import { Injectable, Injector } from "@angular/core";

import {
  BehaviorSubject,
  Observable,
  Subject,
  from,
  lastValueFrom,
  of,
  throwError,
} from "rxjs";
import { Flows, PlotFlow, PlotTemplate, SimulationResult } from "../models";
import * as xml2js from "xml2js";
import {
  catchError,
  concatMap,
  delay,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
} from "rxjs/operators";
import { DbService } from "src/app/shared/services/db.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { TimingComponent } from "src/app/plot/timing/timing.component";
import { DataBuilderComponent } from "src/app/plot/data-builder/data-builder.component";
import { SiteComponent } from "src/app/plot/site/site.component";
import { SoilComponent } from "src/app/plot/soil/soil.component";
import { TreesComponent } from "src/app/plot/trees/trees.component";
import { CropsComponent } from "src/app/plot/crops/crops.component";
import { InitialConditionsComponent } from "src/app/plot/initial-conditions/initial-conditions.component";
import { ConfigurationComponent } from "src/app/plot/configuration/configuration.component";
import { AboutComponent } from "src/app/plot/about/about.component";
import { EventsComponent } from "src/app/plot/events/events.component";
import { EstateComponent } from "src/app/plot/estate/estate.component";
import { PlotFilesComponent } from "src/app/plot/plot-files/plot-files.component";
import { PlotDigestComponent } from "src/app/plot/plot-digest/plot-digest.component";
import {
  IFileUploadForm,
  IFileUploadRequest,
  IPoltFileType,
  PlotDocumentType,
  PlotFormat,
  UploadProgressCount,
} from "src/app/shared/models";
import { FlyOverPanelService } from "src/app/shared/components/fly-over-panel/services/fly-over-panel.service";
import { Constants } from "src/app/shared/constants";
import { Router } from "@angular/router";
import { PlotTableColumns } from "src/app/my-plots/models";
import { ExplorerComponent } from "src/app/plot/explorer/explorer.component";
import { OutputWindowsComponent } from "src/app/plot/output-windows/output-windows.component";
import Utilities from "src/app/shared/utilities/utils";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  HttpEventType,
  HttpHeaderResponse,
  HttpResponse,
} from "@angular/common/http";
import { MessageService } from "src/app/shared/services/message.service";
import JSZip from "jszip";
import { PlotReferenceDataService } from "./plot-reference-data.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { About2020Service } from "src/app/plot/about/services/about-2020.service";
import { Configuration2020Service } from "src/app/plot/configuration/services/configuration-2020.service";
import { Timing2020Service } from "src/app/plot/timing/services/timing-2020.service";
import { DataBuilder2020Service } from "src/app/plot/data-builder/services/data-builder-2020.service";
import { Site2020Service } from "src/app/plot/site/services/site-2020.service";
import { Trees2020Service } from "src/app/plot/trees/services/trees-2020.service";
import { Crops2020Service } from "src/app/plot/crops/services/crops-2020.service";
import { InitialConditions2020Service } from "src/app/plot/initial-conditions/services/initial-conditions-2020.service";
import { Events2020Service } from "src/app/plot/events/services/events-2020.service";
import { OutputWindows2020Service } from "src/app/plot/output-windows/service/output-windows-2020.service";
import { Explorer2020Service } from "src/app/plot/explorer/services/explorer-2020.service";
import { Soil2020Service } from "src/app/plot/soil/services/soil-2020.service";
import { PlotDigest2020Service } from "src/app/plot/plot-digest/services/plot-digest-2020.service";
import { Estate2020Service } from "src/app/plot/estate/services/estate-2020.service";
import { EventForms2020Service } from "src/app/shared/components/event-forms/event-forms-2020.service";
import { PlotFiles2020Service } from "src/app/plot/plot-files/services/plot-files-2020.service";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { GoogleAnalyticsService } from "src/app/shared/services/google-analytics.service";
import { AppService } from "src/app/services/app.service";
import { AppInsightsService } from "src/app/shared/services/app-insights.service";
import { Log2020Service } from "src/app/plot/log/services/log2020.service";
import { LogComponent } from "src/app/plot/log/log.component";
import packageJson from "../../../../package.json";
import FullcamVersionUtilities from "src/app/shared/utilities/fullcam-version";

const defaultMnrl = [
  {
    MnrlZap: [
      {
        $: {
          id: "forest",
          depoSlope: "",
          depoOffset: "",
          nfixSlope: "",
          nfixOffset: "",
          nitrK1: "0.2",
          nitrK2: "0.02",
          nitrKMax: "0.1",
          nitrTemp_a: "1.0",
          nitrTemp_b: "25.0",
          nitrTemp_c: "5.5",
          nitrPH_a: "1.0",
          nitrPH_b: "4.749",
          nitrPH_c: "0.756",
          nitrWater_aL: "2.0",
          nitrWater_aH: "5.5",
          nitrWater_aX: "0.7",
          nitrWater_bL: "4.2",
          nitrWater_bH: "9.3",
          nitrWater_bX: "0.7",
          nitrWater_cL: "6.0",
          nitrWater_cH: "11.0",
          nitrWater_cX: "0.7",
          deniMax: "0.024",
          deniNRMax: "25.0",
          deniCO2_a: "1.0",
          deniCO2_b: "0.015",
          deniCO2_c: "0.003",
          deniNO3_a: "1.0",
          deniNO3_b: "0.085",
          deniNO3_c: "0.022",
          deniWater_a: "1.0",
          deniWater_b: "0.8",
          deniWater_c: "0.065",
          deniRCO2_a: "1.0",
          deniRCO2_b: "0.0125",
          deniRCO2_c: "0.0038",
          deniRNO3_a: "1.0",
          deniRNO3_b: "0.0763",
          deniRNO3_c: "0.0133",
          deniRWater_a: "1.0",
          deniRWater_b: "0.65",
          deniRWater_c: "0.075",
          useTSDeni: "false",
          fracMnrlNToDeepLeac: "",
          tLeachingN: "LeachN_NCyc",
          proRataNRationing: "true",
          bondOn: "false",
          bondEmitLo: "",
          bondEmitMed: "",
          bondEmitHi: "",
          bondMnrlNM: "",
          bondSoilTemp: "",
          bondSpread: "",
        },
        rationNArr: [
          {
            _: "RationDeni,RationSdcm,RationNita,RationMdcm,RationProd,RationAbsn,RationSoen,RationBond",
            $: {
              type: "RationT",
            },
          },
        ],
        fracBiteArr: ["0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5"],
      },
      {
        $: {
          id: "ag",
          depoSlope: "",
          depoOffset: "",
          nfixSlope: "",
          nfixOffset: "",
          nitrK1: "0.2",
          nitrK2: "0.02",
          nitrKMax: "0.1",
          nitrTemp_a: "1.0",
          nitrTemp_b: "25.0",
          nitrTemp_c: "5.5",
          nitrPH_a: "1.0",
          nitrPH_b: "4.749",
          nitrPH_c: "0.756",
          nitrWater_aL: "2.0",
          nitrWater_aH: "5.5",
          nitrWater_aX: "0.7",
          nitrWater_bL: "4.2",
          nitrWater_bH: "9.3",
          nitrWater_bX: "0.7",
          nitrWater_cL: "6.0",
          nitrWater_cH: "11.0",
          nitrWater_cX: "0.7",
          deniMax: "0.024",
          deniNRMax: "25.0",
          deniCO2_a: "1.0",
          deniCO2_b: "0.015",
          deniCO2_c: "0.003",
          deniNO3_a: "1.0",
          deniNO3_b: "0.085",
          deniNO3_c: "0.022",
          deniWater_a: "1.0",
          deniWater_b: "0.8",
          deniWater_c: "0.065",
          deniRCO2_a: "1.0",
          deniRCO2_b: "0.0125",
          deniRCO2_c: "0.0038",
          deniRNO3_a: "1.0",
          deniRNO3_b: "0.0763",
          deniRNO3_c: "0.0133",
          deniRWater_a: "1.0",
          deniRWater_b: "0.65",
          deniRWater_c: "0.075",
          useTSDeni: "false",
          fracMnrlNToDeepLeac: "",
          tLeachingN: "LeachN_NCyc",
          proRataNRationing: "true",
          bondOn: "false",
          bondEmitLo: "",
          bondEmitMed: "",
          bondEmitHi: "",
          bondMnrlNM: "",
          bondSoilTemp: "",
          bondSpread: "",
        },
        rationNArr: [
          {
            _: "RationDeni,RationSdcm,RationNita,RationMdcm,RationProd,RationAbsn,RationSoen,RationBond",
            $: {
              type: "RationT",
            },
          },
        ],
        fracBiteArr: ["0.5,0.5,0.5,0.5,0.5,0.5,0.5,0.5"],
      },
    ],
    TimeSeries: [
      {
        $: {
          tInTS: "mnrlNMFromOffsF",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "mnrlNMFromOffsA",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "mnrlNMToAtmsDeniF",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "mnrlNMToAtmsDeniA",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "mnrlNMToAtmsLeacF",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "mnrlNMToAtmsLeacA",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
    ],
  },
];

const defaultMulch = [
  {
    MulchZap: [
      {
        $: {
          id: "forest",
          fracMdcmToMicr: "",
          fracMicrCMIsWall: "",
          fracWallCMIsLr: "",
          turnFracMicr: "",
          fracMdcmMicrExcr: "",
          micrNCRatio: "",
          sommNCRatio: "",
          lrmmNCRatio: "",
          mrmmNCRatio: "",
          mrpmNCRatio: "",
          lrpmNCRatio: "",
          maxMicrNCRatio: "",
          minMicrNCRatio: "",
          mdcmFracSopm: "",
          mdcmFracLrpm: "",
          mdcmFracMrpm: "",
          mdcmFracSomm: "",
          mdcmFracLrmm: "",
          mdcmFracMrmm: "",
          mdcmSensTemp: "",
          mdcmSensRain: "",
          humfFracSopm: "",
          humfFracLrpm: "",
          humfFracMrpm: "",
          humfFracSomm: "",
          humfFracLrmm: "",
          humfFracMrmm: "",
          humfFracMicr: "",
        },
      },
      {
        $: {
          id: "ag",
          fracMdcmToMicr: "",
          fracMicrCMIsWall: "",
          fracWallCMIsLr: "",
          turnFracMicr: "",
          fracMdcmMicrExcr: "",
          micrNCRatio: "",
          sommNCRatio: "",
          lrmmNCRatio: "",
          mrmmNCRatio: "",
          mrpmNCRatio: "",
          lrpmNCRatio: "",
          maxMicrNCRatio: "",
          minMicrNCRatio: "",
          mdcmFracSopm: "",
          mdcmFracLrpm: "",
          mdcmFracMrpm: "",
          mdcmFracSomm: "",
          mdcmFracLrmm: "",
          mdcmFracMrmm: "",
          mdcmSensTemp: "",
          mdcmSensRain: "",
          humfFracSopm: "",
          humfFracLrpm: "",
          humfFracMrpm: "",
          humfFracSomm: "",
          humfFracLrmm: "",
          humfFracMrmm: "",
          humfFracMicr: "",
        },
      },
    ],
    TimeSeries: [
      {
        $: {
          tInTS: "sopmCMInput",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "lrpmCMInput",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
      {
        $: {
          tInTS: "mrpmCMInput",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: ",,,,,,,,,,,",
            $: {
              count: "12",
            },
          },
        ],
      },
    ],
  },
];

const defaultEconInfo = [
  {
    $: {
      incmStYr: "2010",
      incmEnYr: "2050",
      incmTreeF: "true",
      incmDebrF: "true",
      incmMulcF: "true",
      incmSoilF: "true",
      incmProdF: "false",
      incmCropA: "true",
      incmDebrA: "true",
      incmMulcA: "true",
      incmSoilA: "true",
      incmProdA: "false",
      cPriceUseTS: "false",
      cPriceBaseYr: "2010",
      cPriceBase: "20.0",
      cPriceIncr: "0.0",
      discountRateEC: "0.09",
      refDiscountYr: "2010",
      lastDiscountYr: "2050",
    },
    TimeSeries: [
      {
        $: {
          tInTS: "cPriceTS",
          tExtrapTS: "AvgYr",
          tOriginTS: "Calendar",
          yr0TS: "2010",
          nYrsTS: "30",
          dataPerYrTS: "1",
          nDecPlacesTS: "1",
          colWidthTS: "50",
          multTS: "1.0",
          showGraphTS: "true",
        },
        WinState: [
          {
            $: {
              L: "10",
              T: "83",
              clientW: "702",
              clientH: "450",
              ws: "Normal",
            },
          },
        ],
        rawTS: [
          {
            _: "20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0,20.0",
            $: {
              count: "30",
            },
          },
        ],
      },
    ],
  },
];

const defaultNonEstateTags = {
  RINSet: [
    {
      $: {
        count: "0",
      },
      rInCorr: [
        {
          $: {
            count: "0",
          },
        },
      ],
      HeaderState: [
        {
          $: {
            sortIx: "0",
            sortUp: "true",
            sortBy1: "false",
            sortBy2: "false",
            showOnlyHS: "false",
          },
          headSectW: ["182,340,124,0,0,0"],
        },
      ],
    },
  ],
  SensPkg: [
    {
      $: {
        tSimSteps: "LastStep",
        maxNIterations: "1000",
        useCnvg: "true",
        cnvgPercentChng: "1.5",
        cnvgCheckNSteps: "100",
        tSample: "LatinHyperCube",
      },
      tOutArrSE: [
        {
          $: {
            type: "OutT",
            count: "0",
          },
        },
      ],
      expandSE: [
        "f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f",
      ],
    },
  ],
  OptiPkg: [
    {
      $: {
        dummy: "0.0",
      },
    },
  ],
};

@Injectable({
  providedIn: "root",
})
export class Simulation2020Service {
  public version: string = "2020";

  public readonly plotFlows = [
    "about",
    "configuration",
    "timing",
    "dataBuilder",
    "site",
    "trees",
    "crops",
    "soil",
    "initialConditions",
    "economic",
    "events",
    "outputWindows",
    "explorer",
    "log",
  ];

  public readonly estateFlows = [
    "about",
    "timing",
    "plotFiles",
    "estate",
    "outputWindows",
    "explorer",
    "log",
  ];

  public readonly plotDigestFlows = [
    "about",
    "configuration",
    "timing",
    "dataBuilder",
    "site",
    "trees",
    "crops",
    "soil",
    "initialConditions",
    "economic",
    "events",
    "outputWindows",
    "explorer",
    "plotDigest",
    "log",
  ];

  public readonly allFlows: Flows = {
    about: {
      label: "About",
      service: this.aboutService,
      component: AboutComponent,
      isInvalid: () => this.aboutService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    configuration: {
      label: "Configuration",
      service: this.configurationService,
      component: ConfigurationComponent,
      isInvalid: () => this.configurationService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    timing: {
      label: "Timing",
      service: this.timingService,
      component: TimingComponent,
      isInvalid: () => this.timingService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    plotFiles: {
      label: "Plot files",
      service: this.plotFilesService,
      component: PlotFilesComponent,
      isInvalid: () => this.plotFilesService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    estate: {
      label: "Plots in estate",
      service: this.estateService,
      component: EstateComponent,
      isInvalid: () => this.estateService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    dataBuilder: {
      label: "Location info",
      service: this.dataBuilderService,
      component: DataBuilderComponent,
      isInvalid: () => this.dataBuilderService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompA" },
        { flow: "configuration", field: "tPlot", value: "CompF" },
        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
    },
    site: {
      label: "Site",
      service: this.siteService,
      component: SiteComponent,
      isInvalid: () => this.siteService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
    },

    trees: {
      label: "Trees",
      service: this.treesService,
      component: TreesComponent,
      isInvalid: () => this.treesService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompF" },
        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
    },
    crops: {
      label: "Crops",
      service: this.cropsService,
      component: CropsComponent,
      isShown: false,
      isInvalid: () => this.cropsService.isInvalid(),
      type: "mainFlow",
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompA" },
        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
    },
    soil: {
      label: "Soil",
      service: this.soilService,
      component: SoilComponent,
      isInvalid: () => this.soilService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
      showConditions: [
        [
          { flow: "configuration", field: "tPlot", value: "CompA" },
          { flow: "configuration", field: "userSoilMnrl", value: true },
        ],
        [
          { flow: "configuration", field: "tPlot", value: "CompF" },
          { flow: "configuration", field: "userSoilMnrl", value: true },
        ],
        [
          { flow: "configuration", field: "tPlot", value: "CompM" },
          { flow: "configuration", field: "userSoilMnrl", value: true },
        ],
        { flow: "configuration", field: "tPlot", value: "SoilF" },
        { flow: "configuration", field: "tPlot", value: "SoilA" },
      ],
    },
    // economic: {
    //   label: "Economic",
    //   service: this.economicService,
    //   component: EconomicComponent,
    //   isShown: false,
    //   isInvalid: () => this.economicService.isInvalid(),
    //   type: "mainFlow",
    //   isInMoreOptions: true,
    //   showConditions: [
    //     { flow: "configuration", field: "userEcon", value: true },
    //   ],
    // },

    initialConditions: {
      label: "Initial conditions",
      service: this.initialConditionsService,
      component: InitialConditionsComponent,
      isInvalid: () => this.initialConditionsService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
    },
    events: {
      label: "Events",
      service: this.eventsService,
      component: EventsComponent,
      isInvalid: () => this.eventsService.isInvalid(),
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompA" },

        { flow: "configuration", field: "tPlot", value: "CompF" },

        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
      isShown: false,
      type: "mainFlow",
    },
    outputWindows: {
      label: "Output windows",
      service: this.outputWindowsService,
      component: OutputWindowsComponent,
      isInvalid: () => false,
      isShown: true,
      type: "mainFlow",
    },
    explorer: {
      label: "Explorer",
      service: this.explorerService,
      component: ExplorerComponent,
      isInvalid: () => false,
      isShown: true,
      type: "mainFlow",
      isInMoreOptions: true,
    },
    plotDigest: {
      label: "Plot digest",
      service: this.plotDigestService,
      component: PlotDigestComponent,
      isInvalid: () => this.plotDigestService.isInvalid(),
      isShown: true,
      type: "mainFlow",
    },
    log: {
      label: "Log",
      service: this.logService,
      component: LogComponent,
      isInvalid: () => false,
      isShown: true,
      type: "mainFlow",
    },
  };

  private readonly simulationResultSource$ = new BehaviorSubject<
    SimulationResult[]
  >([]);
  public readonly simulationResult$: Observable<SimulationResult[]> =
    this.simulationResultSource$.asObservable();

  private readonly selectedFlow$ = new BehaviorSubject<PlotFlow>(null);

  private readonly currentFlowsSource$ = new BehaviorSubject<PlotFlow[]>(null);

  public currentFlows$: Observable<Array<PlotFlow>> =
    this.currentFlowsSource$.asObservable();

  public importedPlotFileJson: Object = null;

  public isClonedPlotFile: boolean = false;

  public plotMetaData: PlotTableColumns = null;

  public selectedPlotFormat$ = new BehaviorSubject<PlotFormat>(null);

  private readonly plotTemplatesSource$ = new BehaviorSubject<PlotTemplate[]>(
    []
  );
  public readonly plotTemplates$ = this.plotTemplatesSource$.asObservable();

  public readonly templateHeaderMap = {
    ERF: "Emission Reduction Fund",
    ShapeToDigest: "Shape To Digest",
  };

  constructor(
    public injector: Injector,
    public aboutService: About2020Service,
    public configurationService: Configuration2020Service,
    public timingService: Timing2020Service,
    public dataBuilderService: DataBuilder2020Service,
    public siteService: Site2020Service,
    public soilService: Soil2020Service,
    public treesService: Trees2020Service,
    public cropsService: Crops2020Service,
    public initialConditionsService: InitialConditions2020Service,
    public eventsService: Events2020Service,
    public outputWindowsService: OutputWindows2020Service,
    public explorerService: Explorer2020Service,
    public logService: Log2020Service,
    public plotDigestService: PlotDigest2020Service,
    public plotFilesService: PlotFiles2020Service,
    public estateService: Estate2020Service,
    public eventFormsService: EventForms2020Service,
    public router: Router,
    public dbService: DbService,
    public myPlotsService: MyPlotsService,
    public flyOverPanelService: FlyOverPanelService,
    public helperService: HelperService,
    public messageService: MessageService,
    public modalService: ModalService,
    public plotRefDataService: PlotReferenceDataService,
    public gaService: GoogleAnalyticsService,
    public appInsights: AppInsightsService,
    public appService: AppService
  ) {}

  //for file upload
  public readonly uploadingFiles$ = new BehaviorSubject<
    FormArray<AbstractControl<IFileUploadForm>>
  >(new FormArray([]));
  private cancelUploadsSubject = new Subject<void>();

  public isUploading$ = new BehaviorSubject<boolean>(false);
  public numUploadedFiles$ = new BehaviorSubject<number>(0);
  public uploadProgressCount$ = new BehaviorSubject<UploadProgressCount>({
    success: 0,
    fail: 0,
  });

  public setUp() {
    this.eventFormsService.setSimulationService(this);
    Object.values(this.allFlows).forEach((f) => {
      f.service.setSimulationService(this);
    });
  }

  public isEditPlot(): boolean {
    return this.importedPlotFileJson !== null && this.isClonedPlotFile == false;
  }

  public isEstate(): boolean {
    const fileExtension = this.selectedPlotFormat$.getValue();
    return fileExtension == "est";
  }

  private getFlowSourceArray(plotFlows): PlotFlow[] {
    return Object.entries(this.allFlows)
      .filter((f) => plotFlows.includes(f[0]))
      .map((f) => {
        return { id: f[0], ...f[1] };
      });
  }

  public getCurrentFlowServices() {
    return this.currentFlowsSource$.getValue()?.map((f) => f.service);
  }

  public setCurrentFlowSource(plotFormat): void {
    let plotFlows;
    switch (plotFormat) {
      case "plo":
        plotFlows = this.plotFlows;
        break;
      case "pld":
        plotFlows = this.plotDigestFlows;
        break;
      case "est":
        plotFlows = this.estateFlows;
        break;
    }
    const allFlows = this.getFlowSourceArray(plotFlows);
    this.currentFlowsSource$.next([...allFlows]);
  }

  public setImportedPlotFileJson(plotFile: Object) {
    if (!plotFile) {
      this.messageService.addAlert({
        type: "danger",
        msg: "Invalid plot file, unable to read the plot file.",
        dismissible: true,
      });
      return;
    }

    const plotFormat: PlotFormat =
      Constants.PLOT_DOCUMENT_TO_EXTENSION_MAP[Object.keys(plotFile)[0]];

    if (this.hasResearchOptions(plotFile, plotFormat)) {
      this.messageService.addAlert({
        type: "danger",
        msg: "File cannot be opened because it has research-only options set.",
        dismissible: true,
      });
      return;
    }

    this.importedPlotFileJson = plotFile;

    if (plotFormat) {
      this.setSelectedPlotFormat(plotFormat);
    }
  }

  public setPlotMetaData(data: PlotTableColumns): void {
    this.plotMetaData = data;
  }

  public setSelectedPlotFormat(format: PlotFormat): void {
    this.selectedPlotFormat$.next(format);
    //plot format changes in plot select will determine numbers of available flows;
    this.setCurrentFlowSource(format);
  }

  public calculateFlowVisibility(): void {
    const plotFormat = this.selectedPlotFormat$.getValue();
    if (!plotFormat) {
      console.error("plotFormat is null in calculateFlowVisibility");
      return;
    }

    // const shouldShowBaseFlows = plotFormat !== null && plotFormat !== undefined;
    const allFlows = this.currentFlowsSource$.getValue();
    allFlows.forEach((f) => {
      //main flow
      //check conditions

      const isTimingPageValid = this.timingService.getFormGroup().valid;
      const isConfigPageValid = this.configurationService.getFormGroup().valid;
      const isAboutPageValid = this.aboutService.getFormGroup().valid;
      //remove in test evn
      // const isTimingPageValid = true;
      // const isConfigPageValid = true;
      // const isAboutPageValid = true;
      //remove in test evn

      //este will show all pages
      const shouldshowMainFlows =
        plotFormat == "est"
          ? true
          : isAboutPageValid && isTimingPageValid && isConfigPageValid;
      //baseflows isShown are set to ture in the object, no need to proccess again
      if (f.type !== "baseFlow") {
        const shouldShowConditionalFlow = !f.showConditions
          ? true
          : f.showConditions.some((c) => {
              return Array.isArray(c)
                ? c.every((subCondition) =>
                    this.checkFlowCondition(subCondition)
                  )
                : this.checkFlowCondition(c);
            });

        f.isShown = shouldshowMainFlows && shouldShowConditionalFlow;
      }
    });
  }

  protected checkFlowCondition(condition): boolean {
    const fieldValue = this[condition.flow + "Service"].formGroup?.get(
      condition.field
    )?.value;

    if (condition.value) {
      return fieldValue === condition.value;
    } else if (condition.hasValue) {
      return fieldValue !== undefined && fieldValue !== null;
    }
  }

  public addFlows(flows: string[]) {
    flows.forEach((f) => {
      let newFlows = [];
      const currentFlowIds = this.currentFlowsSource$
        .getValue()
        .map((f) => f.id);
      if (!currentFlowIds.includes(f)) {
        newFlows.push(this.getTab(f));
      }
      this.currentFlowsSource$.next([
        ...this.currentFlowsSource$.getValue(),
        ...newFlows,
      ]);
    });
  }
  public getTab(tabName: string) {
    return { id: tabName, ...this.allFlows[tabName] };
  }

  public hideFlows(hideFlows: string[]) {
    let newFlows = this.currentFlowsSource$.getValue();
    newFlows.forEach((f) => {
      if (hideFlows.includes(f.id)) {
        f.isShown = false;
      }
    });

    this.currentFlowsSource$.next({
      ...this.currentFlowsSource$.getValue(),
      ...newFlows,
    });
  }

  public updateFlowsVislbility(flows: string[], isShown) {
    let newFlows = this.currentFlowsSource$.getValue();
    newFlows.forEach((f) => {
      if (flows.includes(f.id)) {
        f.isShown = isShown;
      }
    });

    this.currentFlowsSource$.next([...newFlows]);
  }

  // public removeFlows(flows: string[]) {
  //   let currentFlows = this.currentFlowsSource$.getValue();
  //   flows.forEach((f) => {
  //     const foundIndex = currentFlows.findIndex((cf) => cf.id == f);
  //     if (foundIndex > -1) {
  //       currentFlows.splice(foundIndex, 1);
  //     }
  //   });
  //   this.currentFlowsSource$.next([...currentFlows]);
  // }

  public setSelectedFlow(flow: PlotFlow) {
    this.selectedFlow$.next(flow);
    try {
      this.appService.setPageTitle(
        this.appService.getPageTitlePrefix() + flow.label
      );
      const pageTitle = this.appService.getPageTitlePrefix() + flow.label;

      this.gaService.sendEvent({
        name: "page_view",
        category: "fcm-page-title",
        label: pageTitle,
      });

      this.appInsights.logPageView({
        name: pageTitle,
        url: "/" + flow.id,
      });
    } catch (error) {
      console.error("Unable to track sub-pages with Google Analytics.");
    }
  }
  public getSelectedFlow(): PlotFlow {
    return this.selectedFlow$.getValue();
  }

  public getSelectedFlow$(): Observable<PlotFlow> {
    return this.selectedFlow$;
  }

  public getFlowById(id: string): PlotFlow {
    return this.getCurrentWorkFlow().find((f) => f.id == id);
  }

  public getCurrentWorkFlow(): PlotFlow[] {
    return this.currentFlowsSource$.getValue();
  }

  public previousFlow() {}

  public getPlotFileJson(withDocType?: boolean) {
    if (withDocType) {
      return this.importedPlotFileJson;
    } else {
      return this.importedPlotFileJson[
        Object.keys(this.importedPlotFileJson)[0]
      ];
    }
  }

  public readPlotFileJson() {
    if (this.importedPlotFileJson) {
      //activate all formGroup first, as some pages will need to read values from other pages
      this.getCurrentFlowServices().forEach((service) => {
        service.getFormGroup();
      });

      this.getCurrentFlowServices().forEach((service: any) => {
        const pageName = service.layout?.label;

        try {
          service.readXmlObject();
        } catch (error) {
          console.error(error);
          this.messageService.addAlert({
            type: "danger",
            msg: "Something went wrong while reading " + pageName,
            dismissible: true,
          });
        }
      });
    }
  }

  protected convertInputsToXmlObject(): object {
    let xmlObject = {};
    const isEstate = this.isEstate();
    const defaultObjects = {
      ...(!isEstate && { Mnrl: defaultMnrl }),
      ...(!isEstate && { Mulch: defaultMulch }),
      ...(!isEstate && { EconInfo: defaultEconInfo }),
      ...(!isEstate ? defaultNonEstateTags : {}),
      WinState: [
        {
          $: {
            id: "winStateDO",
            L: "-7",
            T: "84",
            clientW: "1350",
            clientH: "613",
            ws: "Maximized",
          },
        },
        {
          $: {
            id: "winStateDI",
            L: "-8",
            T: "-8",
            clientW: "1366",
            clientH: "713",
            ws: "Maximized",
          },
        },
      ],
      custColorsDO: {
        _: "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0",
        $: {
          count: "16",
        },
      },
    };

    const plotFormat = this.selectedPlotFormat$.getValue();
    const docTag =
      plotFormat == "plo"
        ? "DocumentPlot"
        : plotFormat == "pld"
        ? "DocumentPlotDigest"
        : "DocumentEstate";

    const fileType =
      plotFormat == "plo"
        ? "FullCAM Plot "
        : plotFormat == "pld"
        ? "FullCAM Plot Digest"
        : "FullCAM Plot Estate";

    this.getCurrentWorkFlow().forEach((f) => {
      xmlObject = {
        ...xmlObject,
        ...f.service.writeXmlObject(),
      };
    });
    const year = this.appService.getVersion();

    const docVersion = FullcamVersionUtilities.getDocVersionByFullcamYear(year);
    return {
      [docTag]: {
        $: {
          FileType: fileType,
          Version: docVersion,
          pageIxDO: "10",
          tDiagram: "-1",
          "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
          "xsi:noNamespaceSchemaLocation": `http://www.fullcam.au/XML/${docTag}${docVersion}.xsd`,
        },
        ...xmlObject,
        ...defaultObjects,
      },
    };
  }

  public getBasePageTitle(): string {
    return `${
      this.isEditPlot() ? "Update" : "Create a new"
    } ${this.getPlotFormatName()}
    `;
  }

  public getPlotFileAsXml(): string {
    //user template?
    let plotFile = this.convertInputsToXmlObject();
    const builder = new xml2js.Builder();

    const xml = builder.buildObject(plotFile);
    return xml;
  }

  public async savePlotFile() {
    const latitude =
      this.dataBuilderService.getFormGroup().get("latBL").value || null;
    const longitude =
      this.dataBuilderService.getFormGroup().get("lonBL").value || null;

    const project = this.aboutService.getFormGroup().get("project").value;
    const collection = this.aboutService.getFormGroup().get("collection").value;
    let status: "Valid" | "Invalid" = this.isSimulationReady()
      ? "Valid"
      : "Invalid";

    let fileName = this.aboutService.getFormGroup().get("nmME")?.value;
    if (!fileName) {
      fileName = "Plot " + this.helperService.getTodayDateTimeForFileName();
    }
    const fileExtension = this.selectedPlotFormat$.getValue();

    const logArguments = [
      this.getPlotFormatName(),
      `${project}/${collection}/${fileName}.${fileExtension}`,
      `FullCAM UI Version: ${packageJson.version}`,
    ];
    this.logService.setLog(logArguments, "DocSave");

    const plotFile = this.getPlotFileAsXml();
    const file = this.helperService.convertXmlStringToFileObject(
      plotFile,
      fileName //+ "." + fileExtension
    );

    try {
      const verifiedPlotFile: any = await lastValueFrom(
        this.dbService
          .verifyPlotFiles({
            plotFiles: [file],
            fileType: fileExtension,
          })
          .pipe(take(1))
      );

      if (verifiedPlotFile) {
        status = verifiedPlotFile[0].status;
      }
    } catch (error) {
      this.logService.removeLastLog();
    }

    let data: IFileUploadRequest = {
      fileType: fileExtension,
      project,
      collection,
      file,
      latitude,
      longitude,
      status,
    };

    this.isUploading$.next(true);
    //check if the plot file is downloaded from server
    if (this.plotMetaData && !this.isClonedPlotFile) {
      data.plotId = this.plotMetaData.plotId;

      return this.dbService
        .updatePlotFile(data)
        .pipe(
          take(1),
          catchError((error) => {
            this.isUploading$.next(false);
            this.logService.removeLastLog();
            return throwError(() => error);
          })
        )
        .subscribe(async (response) => {
          this.isUploading$.next(false);
          if (!response) {
            this.messageService.addAlert({
              type: "warning",
              msg: "Something went wrong with the reponse result.",
              dismissible: true,
            });

            return;
          }
          this.processPlotfileAfterSaved(plotFile, response);

          this.messageService.addAlert({
            type: "success",
            msg: `${this.getPlotFormatName(
              true
            )} file has been updated successfully.`,
            timeout: 5000,
            dismissible: true,
          });
        });
    } else {
      this.dbService
        .uploadPlotFile(data, false)
        .pipe(
          take(1),
          catchError((error) => {
            this.isUploading$.next(false);
            this.logService.removeLastLog();
            return throwError(() => error);
          })
        )
        .subscribe(async (response) => {
          this.isUploading$.next(false);
          if (!response) {
            this.messageService.addAlert({
              type: "warning",
              msg: "Something went wrong with the reponse result.",
              dismissible: true,
            });

            return;
          }

          this.processPlotfileAfterSaved(plotFile, response);
          this.messageService.addAlert({
            type: "success",
            msg: `${this.getPlotFormatName(true)} has been saved successfully.`,
            timeout: 5000,
            dismissible: true,
          });
        });
    }
  }

  public getPlotFormatName(isCapitalised: boolean = true) {
    const currentPlotFormat = this.selectedPlotFormat$.value;
    const plotFormatMap = Constants.PLOT_FORMAT_MAP;
    const plotFormatName = plotFormatMap[currentPlotFormat];
    if (!plotFormatName) {
      return;
    }

    const fileName = isCapitalised
      ? Utilities.capitaliseFirstLetter(plotFormatName)
      : plotFormatName;
    return fileName;
  }

  protected async processPlotfileAfterSaved(plotFile, response) {
    const plotFileJson = await this.helperService.convertXmlToJson(plotFile);

    this.setPlotMetaData(response);
    this.setImportedPlotFileJson(plotFileJson);
    //reset isClone file after saved
    this.isClonedPlotFile = false;

    this.calculateFlowVisibility();
    this.resetFormStatusToPristineAfterSaved();
  }

  public resetFormStatusToPristineAfterSaved(): void {
    if (!this.getCurrentFlowServices()) {
      return;
    }

    this.getCurrentFlowServices().forEach((service) => {
      if (service?.formGroup) {
        const formGroup: FormGroup = service.formGroup;
        formGroup.markAsPristine();
      }
    });
  }

  public downloadPlotFileLocally() {
    const plotFile = this.getPlotFileAsXml();

    let fileName =
      this.plotMetaData?.fileName ||
      this.aboutService.formGroup.get("nmME")?.value ||
      "Plot export " + this.helperService.getTodayDateTimeForFileName();
    const fileExtension = this.selectedPlotFormat$.getValue();

    const url = window.URL.createObjectURL(new Blob([plotFile]));
    const link = document.createElement("a");
    let downloadFileName = (fileName =
      fileName.indexOf("." + fileExtension) == -1
        ? fileName + "." + fileExtension
        : fileName);
    link.href = url;
    link.setAttribute("download", downloadFileName);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      link.remove();
    }, 1);
  }

  public runSimulation(): void {
    this.messageService.setIsSimulationRunning(true);
    let plotFile;
    let simOption; //for plot digest
    let plotIds; //for estate
    try {
      plotFile = this.getPlotFileAsXml();
    } catch (error) {
      this.messageService.setIsSimulationRunning(false);
    }

    let fileName =
      this.plotMetaData?.fileName ||
      this.aboutService.formGroup.get("nmME")?.value ||
      "Plot_export_" + this.helperService.getTodayDateTimeForFileName();

    const fileExtension = this.selectedPlotFormat$.getValue();
    fileName =
      fileName.indexOf("." + fileExtension) == -1
        ? fileName + "." + fileExtension
        : fileName;
    //remove
    this.downloadPlotFileLocally();
    //remove

    if (fileExtension == "pld") {
      let digestOutputOption = this.plotDigestService
        .getFormGroup()
        .get("outWinOption").value;
      //param defined by backend
      if (digestOutputOption == "PerScenario") {
        digestOutputOption = "PerDoc";
      }
      simOption = digestOutputOption;
    } else if (fileExtension == "est") {
      plotIds = this.plotFilesService
        .getFormGroup()
        .get("plotFiles")
        .value.map((p) => p.plotId);
    }
    try {
      this.dbService
        .runSimulation(plotFile, fileExtension, fileName, simOption, plotIds)
        .pipe(take(1))
        .subscribe((response: any) => {
          if (!response) {
            //error is handled in db service
            return;
          }

          if (fileExtension == "plo") {
            this.handleCsvResponse(response);
          } else if (fileExtension == "pld") {
            this.handleZipResponse(response);
          } else if (fileExtension == "est") {
            this.handleCsvResponse(response);
          }

          this.messageService.addAlert({
            type: "success",
            msg: "Your simulation has been executed successfully! CSV results have been downloaded.",
            timeout: 5000,
            dismissible: true,
          });
          this.messageService.setIsSimulationRunning(false);
        });

      this.gaService.sendEvent({
        name: "click",
        category: "simulation",
        label: `${fileExtension}-${this.version}`,
      });
    } catch (error) {
      console.error(error);
    }

    this.runFunctionsAfterSimulation();
  }

  protected runFunctionsAfterSimulation(): void {
    this.getCurrentWorkFlow().forEach((f) => {
      if (f.service?.runFunctionsAfterSimulation) {
        f.service.runFunctionsAfterSimulation();
      }
    });
  }

  protected handleCsvResponse(csv) {
    let fileName = this.helperService.getTodayDateTimeForFileName();

    if (this.plotMetaData?.fileName) {
      fileName = this.plotMetaData.fileName.split(".").slice(0, -1).join(".");
    }

    this.helperService.downloadFile(
      "simulation_result_" + fileName + ".csv",
      csv,
      "text/csv"
    );

    const csvArray = Utilities.convertCsvToArray(csv, {
      header: true,
      skipEmptyLines: true,
    });

    this.setSimulationResult([csvArray]);

    //redirect to output windows page
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const outputWindowsPage = allFlows.find((f) => f.id == "outputWindows");
      this.setSelectedFlow(outputWindowsPage);
      setTimeout(() => {
        this.outputWindowsService.convertSimulationResultToChartOption(
          csvArray
        );
      }, 0);
    });
  }

  protected async handleZipResponse(response) {
    const jszip = new JSZip();
    const zip = await jszip.loadAsync(response);
    const files = Object.values(zip.files);
    const scenarioView: "Combined" | "PerScenario" | "PerOutput" =
      this.plotDigestService.getFormGroup().get("outWinOption").value;
    const csvArrayResults = [];

    this.helperService.downloadFile(
      "simulation_result_" +
        (this.plotMetaData?.fileName.split(".").pop() ||
          this.helperService.getTodayDateTimeForFileName()) +
        ".zip",
      response,
      "application/zip"
    );

    for (const file of files) {
      if (!file.dir && file.name.toLocaleLowerCase().endsWith(".csv")) {
        const csvData = await zip.file(file.name).async("text");

        const csvArray = Utilities.convertCsvToArray(csvData, {
          header: true,
          skipEmptyLines: true,
        });
        csvArrayResults.push({ ...csvArray, fileName: file.name });
      }
    }

    this.setSimulationResult(csvArrayResults);
    //redirect to output windows page
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const outputWindowsPage = allFlows.find((f) => f.id == "outputWindows");
      this.setSelectedFlow(outputWindowsPage);
      setTimeout(() => {
        this.outputWindowsService.convertDigestSimulationResultToChartOptions(
          csvArrayResults,
          scenarioView
        );
      }, 0);
    });
  }

  protected setSimulationResult(result) {
    this.simulationResultSource$.next([...result]);
  }

  public getSimulationResult() {
    return this.simulationResultSource$.getValue();
  }

  public isDirtyForm(): boolean {
    if (!this.getCurrentFlowServices()) {
      return false;
    }

    return this.getCurrentFlowServices().some((service) => {
      if (service?.formGroup?.dirty) {
        return true;
      }
    });
  }

  public getPlotTemplateList() {
    this.dbService
      .getPlotTemplateList()
      .pipe(
        mergeMap(async (response) => {
          let convertedResponse: any =
            await this.helperService.convertXmlToJson(response);
          convertedResponse?.DocFragment?.ItemList[0]?.ItemInfo.forEach(
            (item) => {
              item.$.header =
                this.templateHeaderMap[item?.$?.value.split("\\")[0]];
            }
          );
          return convertedResponse?.DocFragment?.ItemList[0]?.ItemInfo;
        }),
        take(1)
      )
      .subscribe((templates: PlotTemplate[]) => {
        this.plotTemplatesSource$.next(templates);
      });
  }

  public getPlotTemplate(name: string) {
    return this.dbService.getPlotTemplate(name);
  }

  public redirectToPlotEntry() {
    const plotFormat = this.selectedPlotFormat$.getValue();
    if (this.importedPlotFileJson && this.selectedPlotFormat$.getValue()) {
      this.router.navigate(["/simulation-" + this.version + "/" + plotFormat]);
    }
  }

  public processImportedPlotFile(plotFileJson: any): void {
    try {
      const documentType = Object.keys(plotFileJson)[0] as PlotDocumentType;
      const docVersion = plotFileJson[documentType]?.$?.Version;
      const year = this.appService.getVersion();
      let updatedPlotFileJson = plotFileJson;

      const checkVersionResult = FullcamVersionUtilities.checkVersion(
        docVersion,
        year
      );

      if (checkVersionResult !== null) {
        updatedPlotFileJson = FullcamVersionUtilities.updateVersion(
          checkVersionResult,
          plotFileJson,
          documentType
        );

        this.messageService.addAlert({
          type: "info",
          msg: `Plot file version ${
            checkVersionResult.type == "update" ? "updated" : "reverted"
          } to ${year} `,
          timeout: 3000,
        });
      }

      this.setImportedPlotFileJson(updatedPlotFileJson);
      this.readPlotFileJson();
      this.redirectToPlotEntry();
    } catch (error) {
      this.messageService.addAlert({
        type: "danger",
        msg: "Unable to read plot file",
        dismissible: true,
      });
    }
  }

  public setUploadingFiles(filesFormArray: FormArray): void {
    this.uploadingFiles$.next(filesFormArray);
  }

  public loadPlotFiles(files: FileList) {
    if (files.length) {
      const context = this;
      (async function () {
        const existingFiles = context.uploadingFiles$.getValue();
        const filesFormArray = existingFiles.length
          ? existingFiles
          : new FormArray([]);

        for await (const file of Array.from(files) as File[]) {
          const plotFile = await context.helperService
            .readFile(file)
            .catch((alertMessage) => {
              context.messageService.addAlert(alertMessage);
            });

          if (!plotFile) {
            context.messageService.addAlert({
              type: "danger",
              msg: "Invalid plot file, unable to read the plot file.",
              dismissible: true,
            });
            continue;
          }

          let latitude, longitude;

          const plotFileJson = await context.helperService.convertXmlToJson(
            plotFile
          );

          if (!plotFileJson) {
            context.messageService.addAlert({
              type: "danger",
              msg: "Invalid plot file, unable to read the plot file.",
              dismissible: true,
            });
            continue;
          }

          const extension: IPoltFileType = file.name
            .split(".")
            .pop() as IPoltFileType;

          const documentType =
            Constants.PLOT_EXTENSION_TO_DOCUMENT_MAP[extension];

          if (!documentType) {
            continue;
          }

          const json = plotFileJson[documentType];

          latitude = json?.Build && json.Build[0]?.$?.latBL;
          longitude = json?.Build && json.Build[0]?.$?.lonBL;

          const fileData: IFileUploadRequest = {
            fileType: extension,
            file: file,
            project: null,
            collection: null,
            latitude: latitude || null,
            longitude: longitude || null,
          };

          filesFormArray.push(context.getFileFormGroup(fileData));
        }
        context.setUploadingFiles(filesFormArray);
      })();
    }
  }

  public getFileFormGroup(formData: IFileUploadRequest): AbstractControl {
    return new FormGroup({
      fileType: new FormControl(formData.fileType, [Validators.required]),
      file: new FormControl(formData.file, [Validators.required]),
      project: new FormControl(formData.project, [Validators.required]),
      collection: new FormControl(formData.collection, [Validators.required]),
      latitude: new FormControl(formData.latitude, [Validators.required]),
      longitude: new FormControl(formData.longitude, [Validators.required]),
      progress: new FormControl("", []),
      subscription: new FormControl(null, []),
    });
  }

  public uploadPlotFiles(filesFormArray: FormArray) {
    this.resetFileUpload();
    this.uploadingFiles$.next(filesFormArray);
    this.isUploading$.next(true);

    try {
      this.gaService.sendEvent({
        name: "click",
        category: "file-upload",
        label: `number of files: ${filesFormArray.controls?.length}, version: ${this.version}`,
      });
    } catch (error) {
      console.error(error);
    }

    from(filesFormArray.controls)
      .pipe(
        delay(1000), // Delay of 1 second
        concatMap((fileForm: AbstractControl) =>
          this.checkAndUpdatePlotFileVersion(
            fileForm.get("file").value,
            fileForm.get("fileType").value
          ).pipe(
            switchMap((updatedPlotJson) => {
              if (updatedPlotJson) {
                const xml =
                  this.helperService.convertJsonToXmlString(updatedPlotJson);
                const updatedFile =
                  this.helperService.convertXmlStringToFileObject(
                    xml,
                    fileForm.get("file").value.name
                  );

                fileForm.get("file").setValue(updatedFile);
              }

              return this.dbService.uploadPlotFile(fileForm.getRawValue()).pipe(
                map((event) => ({ event, fileForm })),
                catchError((error) => of({ error, fileForm }))
              );
              // this.setProgress("skipped", fileForm);
              // // If the version is not correct, skip the upload by returning an Observable that completes without emitting
              // return of(null).pipe(map(() => ({ skipped: true, fileForm })));
            })
          )
        ),

        takeUntil(this.cancelUploadsSubject)
      )
      .subscribe({
        next: (data) => {
          const fileForm = data.fileForm;

          if ("event" in data) {
            // Handle the case where the 'event' property exists
            const event = data.event;
            if (event.type === HttpEventType.UploadProgress) {
              const percentageLoaded = Math.round(
                (event.loaded / event.total) * 100
              );
              fileForm.get("progress").setValue(String(percentageLoaded));
            } else if (event instanceof HttpResponse) {
              this.setProgress("completed", fileForm);
            }
          } else if ("error" in data) {
            // Handle the error case
            console.error("Error during file upload:", data.error);
            this.setProgress("fail", fileForm);
          }
        },
        error: (error) => {
          // This block will now only be used for non-recoverable errors
          console.error("Unrecoverable error during file upload:", error);
        },
      });
  }

  private checkAndUpdatePlotFileVersion(file: File, fileType: PlotFormat) {
    return from(this.helperService.readFile(file)).pipe(
      catchError((error) => {
        // Handle readFile error
        this.messageService.addAlert({
          type: "danger",
          msg: "Error reading file",
        });
        return error;
      }),
      switchMap((plotFile) => {
        return from(this.helperService.convertXmlToJson(plotFile));
      }),
      switchMap((plotFileJson: any) => {
        let updatedPlotFileJson = null;
        const documentType = Constants.PLOT_EXTENSION_TO_DOCUMENT_MAP[
          fileType
        ] as PlotDocumentType;
        const docVersion = plotFileJson[documentType]?.$?.Version;
        const year = this.appService.getVersion();

        const checkVersionResult = FullcamVersionUtilities.checkVersion(
          docVersion,
          year
        );
        if (checkVersionResult !== null) {
          updatedPlotFileJson = FullcamVersionUtilities.updateVersion(
            checkVersionResult,
            plotFileJson,
            documentType
          );

          this.messageService.addAlert({
            type: "info",
            msg:
              `Plot file version ${
                checkVersionResult.type == "update" ? "updated" : "reverted"
              } to ${year}- ` + file.name,
            timeout: 3000,
          });
          // return of(false);
          // return this.dbService.updatePlotFileVersion(file).pipe(
          //   switchMap((updateResponse) => {
          //     console.log(updateResponse, "updateResponse");
          //     // Process the update response if necessary
          //     this.messageService.addAlert({
          //       type: "info",
          //       msg: "Version updated successfully",
          //     });
          //     // Assuming the update makes the file valid, or further validation is needed
          //     return of(true);
          //   })
          // );
        }

        return of(updatedPlotFileJson);
      })
    );
  }

  public cancelAllUploads(): void {
    this.cancelUploadsSubject.next();
    this.uploadingFiles$.getValue().controls.forEach((fg) => {
      const progress = fg.get("progress").value;

      if (!Number.isNaN(+progress)) {
        fg.get("progress").setValue("cancelled");
      }
    });
    this.isUploading$.next(false);
    this.checkUploadProgress();
  }

  public deleteFile(row): void {
    const file = row.file;
    const index = this.uploadingFiles$
      .getValue()
      .controls.findIndex((formGroup) => {
        const f = formGroup.get("file").value;
        return file === f;
      });

    if (row.subscription) {
      row.subscription.unsubscribe();
    }
    this.uploadingFiles$.getValue().removeAt(index);
    if (this.isUploading$.getValue()) {
      this.numUploadedFiles$.next(this.numUploadedFiles$.getValue() + 1);
      this.checkUploadProgress();
    }
  }

  protected setProgress(status: "completed" | "fail" | "skipped", fileForm) {
    fileForm.get("progress").setValue(status);
    this.uploadProgressCount$.next({
      ...this.uploadProgressCount$.getValue(),
      ...(["fail", "skipped"].includes(status) && {
        fail: (this.uploadProgressCount$.getValue().fail += 1),
      }),
      ...(status == "completed" && {
        success: (this.uploadProgressCount$.getValue().success += 1),
      }),
    });
    this.numUploadedFiles$.next(this.numUploadedFiles$.getValue() + 1);
    this.checkUploadProgress();
  }
  protected checkUploadProgress() {
    if (
      this.numUploadedFiles$.getValue() >=
      this.uploadingFiles$.getValue().length
    ) {
      this.isUploading$.next(false);
      this.numUploadedFiles$.next(0);
    }
  }

  public resetFileUpload() {
    this.isUploading$.next(false);
    this.cancelUploadsSubject.next();
    this.numUploadedFiles$.next(0);
    this.uploadingFiles$.next(new FormArray([]));
    this.uploadProgressCount$.next({
      success: 0,
      fail: 0,
    });
  }

  public hasResearchOptions(plotFileJson, plotFormat: PlotFormat): boolean {
    // research (for grepping here!)
    // this only applies to plo (others have no config obj or are research only)
    //Doc.cpp line 1314

    const documentType = Constants.PLOT_EXTENSION_TO_DOCUMENT_MAP[plotFormat];

    if (!documentType) {
      return false;
    }

    const configNode = plotFileJson[documentType]?.Config;
    const timingNode = plotFileJson[documentType]?.Timing;

    if (!configNode || !timingNode) {
      return false;
    }
    const configObject = configNode[0].$;
    const timingObject = timingNode[0].$;
    const researchOutputFreq = ["MoWeek", "YrWeek", "Other"];

    if (plotFormat == "plo") {
      return (
        configObject.tPlot == "Tree" ||
        configObject.tTreeProd == "3PG" ||
        configObject.userCalcFPI == "true" ||
        configObject.userN == "true" ||
        configObject.userOpti == "true" ||
        configObject.userEcon == "true" ||
        configObject.userLogGrade == "true" ||
        configObject.rothCVers == "Vers265" ||
        (timingObject.dailyTimingTZ == "true" &&
          (timingObject.useDaysPerStepDTZ == "false" ||
            timingObject.daysPerStepDTZ !== "1" ||
            researchOutputFreq.includes(timingObject.outputFreqDTZ)))
      );
    } else if (plotFormat == "est") {
      return timingObject.dailyTimingTZ == "true";
    }

    return false;
  }
  public canThisPageAddedToDigest(pageId: string, service: any): boolean {
    const excludedPages = ["explorer", "plotDigest", "logs", "outputWindows"];

    if (service.isEventPage) {
      return true;
    }

    if (excludedPages.includes(pageId)) {
      return false;
    }

    return this.plotDigestFlows.includes(pageId);
  }

  public isSimulationReady(): boolean {
    for (const f of Object.values(this.getCurrentWorkFlow())) {
      const excludedPages = ["Explorer", "Logs"];

      if (!excludedPages.includes(f.label) && f.isInvalid() && f.isShown) {
        return false;
      }
    }
    return true;
  }

  public getNewInstance() {
    const childInjector = Injector.create({
      providers: [
        {
          provide: About2020Service,
          useClass: About2020Service,
          deps: [MyPlotsService],
        },
        {
          provide: Configuration2020Service,
          useClass: Configuration2020Service,
          deps: [],
        },
        { provide: Timing2020Service, useClass: Timing2020Service, deps: [] },
        {
          provide: DataBuilder2020Service,
          useClass: DataBuilder2020Service,
          deps: [],
        },
        { provide: Site2020Service, useClass: Site2020Service, deps: [] },
        { provide: Soil2020Service, useClass: Soil2020Service, deps: [] },
        { provide: Trees2020Service, useClass: Trees2020Service, deps: [] },
        { provide: Crops2020Service, useClass: Crops2020Service, deps: [] },
        {
          provide: InitialConditions2020Service,
          useClass: InitialConditions2020Service,
          deps: [],
        },
        { provide: Events2020Service, useClass: Events2020Service, deps: [] },
        {
          provide: Explorer2020Service,
          useClass: Explorer2020Service,
          deps: [],
        },
        {
          provide: OutputWindows2020Service,
          useClass: OutputWindows2020Service,
          deps: [],
        },
        {
          provide: PlotDigest2020Service,
          useClass: PlotDigest2020Service,
          deps: [],
        },
        {
          provide: PlotFiles2020Service,
          useClass: PlotFiles2020Service,
          deps: [],
        },
        { provide: Estate2020Service, useClass: Estate2020Service, deps: [] },
        {
          provide: EventForms2020Service,
          useClass: EventForms2020Service,
          deps: [],
        },
        // { provide: MyPlotsService, useClass: MyPlotsService, deps: [DbService] },
        // { provide: DbService, useClass: DbService, deps: [HttpClient, AppService] },
        { provide: HelperService, useClass: HelperService, deps: [] },
        { provide: MessageService, useClass: MessageService, deps: [] },
        {
          provide: PlotReferenceDataService,
          useClass: PlotReferenceDataService,
          deps: [],
        },
      ],
      parent: this.injector,
    });
    const injector = childInjector.get(Injector);
    const aboutService = childInjector.get(About2020Service);
    const configurationService = childInjector.get(Configuration2020Service);
    const timingService = childInjector.get(Timing2020Service);
    const dataBuilderService = childInjector.get(DataBuilder2020Service);
    const siteService = childInjector.get(Site2020Service);
    const soilService = childInjector.get(Soil2020Service);
    const treesService = childInjector.get(Trees2020Service);
    const cropsService = childInjector.get(Crops2020Service);
    const initialConditionsService = childInjector.get(
      InitialConditions2020Service
    );
    const eventsService = childInjector.get(Events2020Service);
    const explorerService = childInjector.get(Explorer2020Service);
    const outputWindowsService = childInjector.get(OutputWindows2020Service);
    const plotDigestService = childInjector.get(PlotDigest2020Service);
    const plotFilesService = childInjector.get(PlotFiles2020Service);
    const estateService = childInjector.get(Estate2020Service);
    const eventFormsService = childInjector.get(EventForms2020Service);
    const logService = childInjector.get(Log2020Service);

    const newInstance = new Simulation2020Service(
      injector,
      aboutService,
      configurationService,
      timingService,
      dataBuilderService,
      siteService,
      soilService,
      treesService,
      cropsService,
      initialConditionsService,
      eventsService,
      outputWindowsService,
      explorerService,
      logService,
      plotDigestService,
      plotFilesService,
      estateService,
      eventFormsService,
      this.router,
      this.dbService,
      this.myPlotsService,
      this.flyOverPanelService,
      this.helperService,
      this.messageService,
      this.modalService,
      this.plotRefDataService,
      this.gaService,
      this.appInsights,
      this.appService
    );
    newInstance.setUp();
    return newInstance;
  }

  public reset(): void {
    Object.values(this.allFlows).forEach((f) => {
      f.service.reset();
    });

    this.importedPlotFileJson = null;

    this.isClonedPlotFile = false;
    this.plotMetaData = null;

    this.selectedFlow$.next(null);
    this.currentFlowsSource$.next(null);
    this.plotTemplatesSource$.next([]);
    this.selectedPlotFormat$.next(null);
    this.simulationResultSource$.next(null);
    this.resetFileUpload();
  }
}
