import { Component } from "@angular/core";

import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";
import { EventsService } from "src/app/shared/models";

export interface CloneFormData {
  years: number;
  repeatTimes: number;
  days?: number;
}

@Component({
  selector: "fc-clone-regime-form",
  templateUrl: "./clone-regime-form.component.html",
  styleUrls: ["./clone-regime-form.component.scss"],
})
export class CloneRegimeFormComponent extends BaseModalFormComponent {
  /*** modal inputs ****/
  public eventsService: EventsService;
  public readonly cloneRegimeHelpUrl = "278_Regime%20Update.htm";

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    years: new UntypedFormControl(0, [
      Validators.required,
      Validators.min(-1000),
      Validators.max(1000),
    ]),
    repeatTimes: new UntypedFormControl(1, [
      Validators.required,
      Validators.min(0),
      Validators.max(10000),
    ]),
    days: new UntypedFormControl(0, [Validators.min(0), Validators.max(1000)]),
  });
}
