import {
  Component,
  HostBinding,
  HostListener,
  InjectionToken,
  OnInit,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FlyOverPanelService } from "./services/fly-over-panel.service";

export const token = new InjectionToken<string>("");

@Component({
  selector: "fc-fly-over-panel",
  templateUrl: "./fly-over-panel.component.html",
  styleUrls: ["./fly-over-panel.component.scss"],
})
export class FlyOverPanelComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  @HostBinding("class.expand") isExpanded: boolean = false;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (this.isExpanded) {
      this.flyOverPanelService.closePanel();
    }
  }

  constructor(public flyOverPanelService: FlyOverPanelService) { }

  ngOnInit(): void {
    this.flyOverPanelService.isExpanded$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isExpanded) => {
        this.isExpanded = isExpanded;
      });
  }

  ngonDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
