import { Injectable } from "@angular/core";
import { Trees2020Service } from "./trees-2020.service";
import { FormGroupElement, FormLayout } from "../../models";

@Injectable({
  providedIn: "root",
})
export class Trees2024Service extends Trees2020Service {
  protected plantsGroups: FormGroupElement[] = [
    {
      isShown: true,
      showErrors: true,
      label: "Carbon percentages",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Carbon Percentages",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracStemF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracBranF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracBarkF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracLeafF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracCortF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracFirtF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      label: "Turnover - half life in years ",
      showErrors: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      subLabel: "[No turnover of stems]",
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Turnover percentages - half life in years",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [
                      "",
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                      "Year",
                    ],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF01",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF02",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF03",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF04",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF05",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF06",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF07",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF08",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF09",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF10",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF11",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF12",
                          width: "60px",
                        },
                        {
                          element: "text",
                          method: "getAverage",
                          payload: [
                            "turnFracBranF01",
                            "turnFracBranF02",
                            "turnFracBranF03",
                            "turnFracBranF04",
                            "turnFracBranF05",
                            "turnFracBranF06",
                            "turnFracBranF07",
                            "turnFracBranF08",
                            "turnFracBranF09",
                            "turnFracBranF10",
                            "turnFracBranF11",
                            "turnFracBranF12",
                          ],

                          width: "60px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "viewOnlyValue",
                                method: "getAverage",
                                payload: [
                                  "turnFracBranF01",
                                  "turnFracBranF02",
                                  "turnFracBranF03",
                                  "turnFracBranF04",
                                  "turnFracBranF05",
                                  "turnFracBranF06",
                                  "turnFracBranF07",
                                  "turnFracBranF08",
                                  "turnFracBranF09",
                                  "turnFracBranF10",
                                  "turnFracBranF11",
                                  "turnFracBranF12",
                                ],
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Branches",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracBranF01",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF01",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF02",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF03",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF04",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF05",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF06",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF07",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF08",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF09",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF10",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF11",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF12",
                          width: "60px",
                        },
                        {
                          element: "text",
                          method: "getAverage",
                          payload: [
                            "turnFracBarkF01",
                            "turnFracBarkF02",
                            "turnFracBarkF03",
                            "turnFracBarkF04",
                            "turnFracBarkF05",
                            "turnFracBarkF06",
                            "turnFracBarkF07",
                            "turnFracBarkF08",
                            "turnFracBarkF09",
                            "turnFracBarkF10",
                            "turnFracBarkF11",
                            "turnFracBarkF12",
                          ],

                          width: "60px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "viewOnlyValue",
                                method: "getAverage",
                                payload: [
                                  "turnFracBarkF01",
                                  "turnFracBarkF02",
                                  "turnFracBarkF03",
                                  "turnFracBarkF04",
                                  "turnFracBarkF05",
                                  "turnFracBarkF06",
                                  "turnFracBarkF07",
                                  "turnFracBarkF08",
                                  "turnFracBarkF09",
                                  "turnFracBarkF10",
                                  "turnFracBarkF11",
                                  "turnFracBarkF12",
                                ],
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Bark",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracBarkF01",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF01",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF02",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF03",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF04",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF05",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF06",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF07",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF08",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF09",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF10",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF11",
                          width: "60px",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF12",
                          width: "60px",
                        },
                        {
                          element: "text",
                          method: "getAverage",
                          payload: [
                            "turnFracLeafF01",
                            "turnFracLeafF02",
                            "turnFracLeafF03",
                            "turnFracLeafF04",
                            "turnFracLeafF05",
                            "turnFracLeafF06",
                            "turnFracLeafF07",
                            "turnFracLeafF08",
                            "turnFracLeafF09",
                            "turnFracLeafF10",
                            "turnFracLeafF11",
                            "turnFracLeafF12",
                          ],

                          width: "60px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "viewOnlyValue",
                                method: "getAverage",
                                payload: [
                                  "turnFracLeafF01",
                                  "turnFracLeafF02",
                                  "turnFracLeafF03",
                                  "turnFracLeafF04",
                                  "turnFracLeafF05",
                                  "turnFracLeafF06",
                                  "turnFracLeafF07",
                                  "turnFracLeafF08",
                                  "turnFracLeafF09",
                                  "turnFracLeafF10",
                                  "turnFracLeafF11",
                                  "turnFracLeafF12",
                                ],
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Leaves",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracLeafF01",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Coarse roots",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracCortF",
                          width: "60px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracCortF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Coarse roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracCortF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: true,
                          value: "Fine roots",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "text",
                          value: "",
                        },
                        {
                          element: "input",
                          type: "number",
                          width: "60px",
                          programmingName: "turnFracFirtF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracFirtF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Fine roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracFirtF",
                              },
                            ],
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      showErrors: true,
      label: "Density",
      items: [
        {
          label: "Stem density",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "stemDensity",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "stemDensity",
                },
                {
                  inputKey: "title",
                  value: "Stem Density",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "stemDensity",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      showErrors: true,
      label: "Mortality",
      items: [
        {
          label: "Tree mortality on",
          shoartLabel: "",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "treeMortOn",
            },
          ],
        },
        {
          id: "stemMortF",
          label: "Stem loss",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "stemMortF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "stemMortF",
                },
                {
                  inputKey: "title",
                  value: "Stem Loss",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "stemMortF",
                },
              ],
            },
          ],
        },
        {
          groups: [
            {
              id: "stem-loss-group",
              isShown: true,
              showErrors: true,
              isUnderlineHeader: true,
              isUnderlineItems: true,
              label: "Ratios of component losses to stem loss:",
              items: [
                {
                  label: null,
                  inputs: [
                    {
                      element: "component",
                      component: "FormItemTableComponent",
                      componentInputs: [
                        {
                          inputKey: "caption",
                          value: "Ratios of component losses to stem loss",
                        },
                        {
                          inputKey: "formGroupPointer",
                          value: "getSelectedSpeciesFormGroup",
                        },
                        {
                          inputKey: "indexPointer",
                          value: "selectedSpeciesId$",
                        },
                        {
                          inputKey: "service",
                          method: "getService",
                        },
                        {
                          inputKey: "data",
                          value: {
                            headers: [],
                            rows: [
                              [
                                {
                                  element: "text",
                                  isHeader: true,
                                  value: "Branches",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioBranMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: true,
                                  value: "Bark",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioBarkMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: true,
                                  value: "Leaves",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioLeafMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: true,
                                  value: "Coarse roots",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioCortMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: true,
                                  value: "Fine roots",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioFirtMortF",
                                },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  label: null,
                  inputs: [
                    {
                      element: "component",
                      component: "FormulaDisplayComponent",
                      componentInputs: [
                        {
                          inputKey: "formGroupPointer",
                          value: "getSelectedSpeciesFormGroup",
                        },
                        {
                          inputKey: "indexPointer",
                          value: "selectedSpeciesId$",
                        },
                        {
                          inputKey: "service",
                          method: "getService",
                        },
                        {
                          inputKey: "data",
                          value: {
                            lhs: "Average age of dying trees",
                            rhs: [
                              [
                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "=",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "remvAvgAgeMultMortF",
                                },

                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "x",
                                },
                                {
                                  element: "text",
                                  value: "Average age of trees",
                                },
                              ],

                              [
                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "+",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "remvMaxAgeMultMortF",
                                },

                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "x",
                                },
                                {
                                  element: "text",
                                  value: "Age of the oldest trees",
                                },
                              ],

                              [
                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "+",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "remvOffsetMortF",
                                },

                                { element: "text", value: "[yr]" },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  label: "Replace dying trees with new trees (of age 0)",
                  shoartLabel: "",
                  inputs: [
                    {
                      element: "input",
                      type: "switch",
                      programmingName: "replaceStemsMortF",
                    },
                  ],
                },
                {
                  label: "Enable biomass-based age adjustment",
                  shoartLabel: "",
                  inputs: [
                    {
                      element: "input",
                      type: "switch",
                      programmingName: "useBioAgeAdjMortF",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  public readonly layout: FormLayout = {
    label: "Trees",
    groups: [
      {
        label: "Species",
        isShown: true,
        isRoot: true,
        showErrors: true,
        helpLink: "56_Select%20a%20Species.htm",
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SpeciesSelectorComponent",
                componentInputs: [
                  {
                    inputKey: "selectedId$",
                    variable: "selectedSpeciesId$",
                    isObservable: true,
                  },
                  {
                    inputKey: "formGroupInstance",
                    method: "getFormGroup",
                  },
                  {
                    inputKey: "speciesInUse$",
                    variable: "speciesInUse$",
                    isObservable: true,
                  },
                  {
                    inputKey: "type",
                    value: "tree",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "buttonClicked",
                    method: "speciesButtonClicked",
                  },
                  {
                    outputKey: "speciesSelected",
                    method: "selectSpecies",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Properties of the species",
        isShown: true,
        isAccordion: true,
        helpLink: "145_Properties%20of%20the%20Species.htm",
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: "Name",
                element: "input",
                type: "text",
                programmingName: "nmSP",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Notes",
                element: "textarea",
                rows: 2,
                programmingName: "notesSP",
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Growth",
                helpLink: "42_Growth%20Properties.htm",
                items: [{ groups: this.growthGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Plants",
                helpLink: "43_Plant%20Properties.htm",
                items: [{ groups: this.plantsGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Standing dead",
                helpLink: "285_StandingDead.htm",
                items: [{ groups: this.standingDeadGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Debris",
                helpLink: "45_Debris%20Properties.htm",
                items: [{ groups: this.debrisGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Products",
                items: [{ groups: this.productsGroups }],
              },
            ],
          },
        ],
      },
      {
        label: "Standard information for the species",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        helpLink: "146_Standard%20Information%20for%20the%20Species.htm",
        items: [
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Trees",
                showErrors: true,
                helpLink: "185_Initial%20Trees.htm",
                items: [{ groups: this.initialTreesGroup }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Standing Dead",
                showErrors: true,
                helpLink: "284_Initial%20StandingDead.htm",
                items: [{ groups: this.initialStandingDeadGroup }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Debris",
                showErrors: true,
                helpLink: "284_Initial%20StandingDead.htm",
                items: [{ groups: this.initialDebrisGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Products",
                showErrors: true,
                helpLink: "33_Initial%20Products.htm",
                items: [{ groups: this.initialProductGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Events",
                showErrors: true,
                helpLink: "142_Standard%20Events%20of%20a%20Species.htm",
                items: [
                  {
                    label: "Events",
                    shoartLabel: "",
                    inputs: [
                      {
                        element: "navigation",
                        linkTo: "flyOverPanel",
                        programmingName: "eventQ",
                        component: "SpeciesEventsComponent",
                        componentInputs: [
                          {
                            inputKey: "speciesFormGroup",
                            method: "getSelectedSpeciesFormGroup",
                          },
                          {
                            inputKey: "formGroup",
                            variable: "formGroup",
                          },
                          {
                            inputKey: "service",
                            method: "getService",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  constructor() {
    super();
    //in 2024 some fields are removed
    const { turnFracBranF, turnFracBarkF, turnFracLeafF, ...restModel } =
      this.speciesFormModel;

    this.speciesFormModel = {
      ...restModel,
      turnFracBranF01: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2211,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF02: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2212,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF03: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2213,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF04: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2214,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF05: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2215,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF06: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2216,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF07: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2217,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF08: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2218,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF09: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2219,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF10: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2220,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF11: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2221,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBranF12: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "branches",
        tIn: 2222,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF01: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2223,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF02: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2224,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF03: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2225,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF04: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2226,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF05: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2227,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF06: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2228,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF07: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2229,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF08: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2230,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF09: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2231,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF10: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2232,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF11: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2233,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracBarkF12: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "bark",
        tIn: 2234,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF01: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2235,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF02: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2236,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF03: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2237,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF04: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2238,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF05: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2239,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF06: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2240,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF07: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2241,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF08: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2242,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF09: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2243,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF10: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2244,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF11: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2245,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
      turnFracLeafF12: {
        defaultValue: null,
        validators: [],
        validatorConfig: [
          {
            functionName: "min",
            input: 0,
          },
          {
            functionName: "max",
            input: 800000,
          },
          {
            functionName: "required",
          },
        ],
        isShown: true,
        isDigest: true,
        isExplorer: true,
        label: "leaves",
        tIn: 2246,
        systemType: 0,
        std: 0,
        unit: "½ life yrs",
        prefixId: 11,
        suffixId: 0,
        isRisk: true,
        isSpa: false,
        helpId: 43,
        sortId: 0,
        subSame: false,
        dataType: 0,
        low: 0,
        high: 800000,
        enumId: null,
        isValidTricky: false,
        errorId: 0,
        isInverse: false,
        scale: 1,
        isOneMore: false,
        columnWidth: 70,
        categoryName: "TreePl",
        categoryLabel: "Tree species : Plants",
        spec: 1,
        eventId: 10,
      },
    };

    const removingFields = ["turnFracBranF", "turnFracBarkF", "turnFracLeafF"];
    const oldBaseFields = this.writeXmlBaseFields.filter(
      (f) => !removingFields.includes(f)
    );
    //modify write xml fields
    const newBranchFields = [
      "turnFracBranF01",
      "turnFracBranF02",
      "turnFracBranF03",
      "turnFracBranF04",
      "turnFracBranF05",
      "turnFracBranF06",
      "turnFracBranF07",
      "turnFracBranF08",
      "turnFracBranF09",
      "turnFracBranF10",
      "turnFracBranF11",
      "turnFracBranF12",
    ];
    const newBarkFields = [
      "turnFracBarkF01",
      "turnFracBarkF02",
      "turnFracBarkF03",
      "turnFracBarkF04",
      "turnFracBarkF05",
      "turnFracBarkF06",
      "turnFracBarkF07",
      "turnFracBarkF08",
      "turnFracBarkF09",
      "turnFracBarkF10",
      "turnFracBarkF11",
      "turnFracBarkF12",
    ];
    const newLeafFields = [
      "turnFracLeafF01",
      "turnFracLeafF02",
      "turnFracLeafF03",
      "turnFracLeafF04",
      "turnFracLeafF05",
      "turnFracLeafF06",
      "turnFracLeafF07",
      "turnFracLeafF08",
      "turnFracLeafF09",
      "turnFracLeafF10",
      "turnFracLeafF11",
      "turnFracLeafF12",
    ];
    this.writeXmlBaseFields = [
      ...oldBaseFields,
      ...newBranchFields,
      ...newBarkFields,
      ...newLeafFields,
    ];
  }
}
