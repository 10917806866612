import {
  Component,
  ComponentRef,
  HostBinding,
  Injector,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { BehaviorSubject, Observable, Subject, Subscription, of } from "rxjs";
import { take, takeUntil, delay } from "rxjs/operators";
import { Constants } from "src/app/shared/constants";
import { FlyOverPanelComponent } from "../../shared/components/fly-over-panel/fly-over-panel.component";
import { PlotFormat, SimulationService } from "../../shared/models";
import { PlotFlow } from "./../models";
import { AppService } from "src/app/services/app.service";
import { MessageService } from "src/app/shared/services/message.service";
import Utilities from "src/app/shared/utilities/utils";
import { ModalService } from "src/app/shared/services/modal.service";

@Component({
  selector: "fc-plot-entry",
  templateUrl: "./plot-entry.component.html",
  styleUrls: ["./plot-entry.component.scss"],
})
export class PlotEntryComponent implements OnInit {
  @ViewChild(FlyOverPanelComponent) flyOverPanel: FlyOverPanelComponent;

  @ViewChildren("flowContentHost", { read: ViewContainerRef })
  private flowContentHost: QueryList<ViewContainerRef>;

  @HostBinding("class") className = this.getSelectedFlow()?.id;
  public readonly plotFormatMap = Constants.PLOT_FORMAT_MAP;

  private subscription: Subscription[] = [];

  private acceptedRoutes = Constants.PLOT_FORMATS;

  private readonly destroy$ = new Subject<void>();

  public masterFormGroup: UntypedFormGroup;
  public currentFlows$: Observable<PlotFlow[]>;
  public selectedPlotFormat$: Subject<PlotFormat>;

  public canNavigateNextFlow$ = new BehaviorSubject<boolean>(true);
  public canNavigatePreviousFlow$ = new BehaviorSubject<boolean>(true);

  public isFlowStepsExpanded: boolean = false;

  public flyOverPanelInjector: Injector;

  public isLoading: boolean = false;

  public simulationService: SimulationService = null;

  public appVersion: string = "";

  constructor(
    private router: Router,
    private activatedroute: ActivatedRoute,
    private appService: AppService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();
    this.appVersion = this.simulationService.version;

    this.selectedPlotFormat$ = this.simulationService.selectedPlotFormat$;

    this.activatedroute.params.subscribe((params) => {
      this.initPlotFlow();
    });

    if (!this.getSelectedFlow()) {
      this.currentFlows$.pipe(take(1)).subscribe((flows) => {
        this.setSelectedFlowPage(flows[0]);
      });
    }

    this.simulationService
      .getSelectedFlow$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((flow) => {
        setTimeout(() => {
          this.loadFormComponent(this.getSelectedFlow());
          this.checkNavigation();

          if (flow.service && flow.service.startLogging) {
            flow.service.startLogging();
          }
        }, 0);
      });
  }

  ngAfterViewInit() {}

  protected isValidSimulationRoute(): boolean {
    const currentRoute = this.router.url
      .split("/simulation-" + this.appVersion)
      .pop()
      .replace("/", "");

    return this.acceptedRoutes.includes(currentRoute);
  }

  protected initPlotFlow() {
    if (!this.isValidSimulationRoute()) {
      this.router.navigate(["/simulation-" + this.appVersion]);
      return;
    }

    const currentRoute = this.router.url
      .split("/simulation-" + this.appVersion)
      .pop()
      .replace("/", "");

    if (
      currentRoute == "plo" ||
      currentRoute == "est" ||
      currentRoute == "pld"
    ) {
      this.simulationService.setSelectedPlotFormat(currentRoute);

      this.currentFlows$ = this.simulationService.currentFlows$;

      this.simulationService.calculateFlowVisibility();

      this.currentFlows$.pipe(take(1)).subscribe((flows) => {
        flows
          .filter((f) => f.type == "baseFlow")
          .forEach((f) =>
            f.service
              .getFormGroup()
              .valueChanges.pipe(takeUntil(this.destroy$), delay(500))
              .subscribe(() => {
                this.simulationService.calculateFlowVisibility();
                this.checkNavigation();
              })
          );
      });

      //subscribe to baseflow changes
    }
  }

  protected loadFormComponent(flow: PlotFlow) {
    //  this.className = this.getSelectedFlow()?.id + "-page";

    const flowHost = this.flowContentHost?.first;
    if (!flowHost) {
      return;
    }
    flowHost.clear();
    flowHost.element.nativeElement.className = "flow-host";
    const childComp: ComponentRef<any> = flowHost.createComponent(
      flow.component
    );

    //child component inputs
    childComp.instance["service"] = flow.service;
    childComp.instance["simulationService"] = this.simulationService;
    childComp.instance["canNavigatePreviousFlow$"] =
      this.canNavigatePreviousFlow$;
    childComp.instance["canNavigateNextFlow$"] = this.canNavigateNextFlow$;

    //child component outputs
    // const fieldChangedSub = childComp.instance["fieldChanged"]
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((val: FieldChangedEmitter) => {
    //     console.log("subscribe", val);
    //     this.calculateFlowVisibility(val);
    //   });
    const nextFlowSub = childComp.instance["nextFlowClicked"]
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.nextFlow();
      });

    const previousFlowSub = childComp.instance["previousFlowClicked"]
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.previousFlow();
      });

    this.subscription.push(nextFlowSub, previousFlowSub);
  }

  public getSelectedFlow() {
    //this function gets loaded before ng init
    const simulationService = this.appService.getSimulationService();
    if (simulationService) {
      return simulationService.getSelectedFlow();
    }
    return null;
  }

  public setSelectedFlowPage(flow): void {
    const currentFlow = this.getSelectedFlow();

    if (currentFlow && currentFlow.service.startLogging) {
      currentFlow.service.serviceDestroySubject$.next();
    }
    this.simulationService.setSelectedFlow(flow);
  }

  public checkNavigation(): void {
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const visibleFlows = allFlows.filter((f) => f.isShown);
      const selectedFlow = this.getSelectedFlow();
      const selectedFlowIndex = visibleFlows.findIndex(
        (f) => f.id == selectedFlow.id
      );

      this.canNavigatePreviousFlow$.next(selectedFlowIndex > 0);

      this.canNavigateNextFlow$.next(
        selectedFlowIndex < visibleFlows.length - 1
      );
    });
  }

  public previousFlow(): void {
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const visibleFlows = allFlows.filter((f) => f.isShown);
      const selectedFlow = this.getSelectedFlow();
      const index = visibleFlows.findIndex((f) => f.id == selectedFlow.id);
      this.setSelectedFlowPage(visibleFlows[index - 1]);
    });
  }

  public nextFlow(): void {
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const visibleFlows = allFlows.filter((f) => f.isShown);
      const selectedFlow = this.getSelectedFlow();
      const index = visibleFlows.findIndex((f) => f.id == selectedFlow.id);
      this.setSelectedFlowPage(visibleFlows[index + 1]);
    });
  }

  public downloadPlotFileLocally() {
    this.simulationService.downloadPlotFileLocally();
  }

  public savePlotFile() {
    //this.isLoading = true;
    this.simulationService.savePlotFile();
  }

  public runSimulation() {
    this.simulationService.runSimulation();
  }

  public collapseHelpPanel() {
    this.getSelectedFlow().service.collapseHelpPanel();
  }

  public isLoading$(): Observable<boolean> {
    return this.simulationService.isUploading$;
  }

  public isSimulating$(): Observable<boolean> {
    return this.simulationService.messageService.isSimulationRunning$;
  }

  public getPlotFormatName(): string {
    return this.simulationService.getPlotFormatName();
  }

  public shouldDisableSave(): boolean {
    const aboutFormGroup = this.simulationService.aboutService.getFormGroup();
    const project = aboutFormGroup.get("project").value;
    const collection = aboutFormGroup.get("collection").value;
    if (!project || !collection) {
      return true;
    }

    return false;
  }

  public isSimulationReady() {
    return this.simulationService.isSimulationReady();
  }

  public isFromServer(): boolean {
    return (
      this.simulationService.plotMetaData !== null &&
      this.simulationService.plotMetaData !== undefined &&
      !this.simulationService.isClonedPlotFile
    );
  }

  canDeactivate() {
    return this.simulationService.isDirtyForm();
  }

  public async onGenerateEstateFileSelected(event) {
    const file = event.target.files[0];

    if (file) {
      const self = this;
      this.messageService.setFullScreenLoading(true);

      Utilities.convertCsvToArray(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim().toLowerCase(),
        complete: (results) => {
          try {
            const rawData = results.data;

            // Normalize headers to ensure case-insensitive matching
            const expectedHeaders = [
              "Start Year",
              "Start Step",
              "Area",
              "Plot File",
            ];
            const normalizedHeaders = Object.keys(rawData[0]).map(
              (header: string) =>
                expectedHeaders.find(
                  (expected) => expected.toLowerCase() === header.toLowerCase()
                )
            );

            // Validate headers
            if (normalizedHeaders.some((header) => header === undefined)) {
              throw null;
            }

            self.simulationService.estateService.generateEstatePlots(rawData);
          } catch (error) {
            self.messageService.addAlert({
              type: "danger",
              msg: `Invalid CSV content, columns required are 'Start Year', 'Start Step', 'Area' and 'Plot File'`,
              dismissible: true,
            });
          } finally {
            this.messageService.setFullScreenLoading(false);
          }
        },
        error: (error) => {
          throw error;
        },
      });
    }
    //reset input
    event.target.value = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.subscription.forEach((s) => {
      s.unsubscribe();
    });

    this.subscription = [];
    this.simulationService.reset();
  }
}
