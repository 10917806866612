import { Injectable } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Constants } from "src/app/shared/constants";
import {
  FieldWatcher,
  FormModel,
  SimulationService,
} from "src/app/shared/models";
import { FormGroupElement, FormLayout, ItemInput } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";

const waterGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    label: "Natural",
    showErrors: true,
    items: [
      {
        label: "Rainfall",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "rainfall",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "rainfall",
              },
              {
                inputKey: "title",
                value: "Rainfall",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "rainfall",
              },
            ],
          },
        ],
      },
      {
        label: "Open-pan Evaporation",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "openPanEvap",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "openPanEvap",
              },
              {
                inputKey: "title",
                value: "Open-pan Evaporation",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "openPanEvap",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "forest-irrigation",
    isShown: true,
    label: "Forest Irrigation",
    isAccordion: true,
    showErrors: true,
    items: [
      {
        label: "Definite Irrigation",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "defnitIrrigF",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "defnitIrrigF",
              },
              {
                inputKey: "title",
                value: "Definite Irrigation (Forest)",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "defnitIrrigF",
              },
            ],
          },
        ],
      },
      {
        label: "Conditional irrigation on",
        id: "conditIrrigOnF",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "conditIrrigOnF",
          },
        ],
      },
      {
        label: "Conditional Irrigation",
        preventLabelElement: true,
        id: "conditIrrigF",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "conditIrrigF",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "conditIrrigF",
              },
              {
                inputKey: "title",
                value: "Conditional Irrigation (Forest)",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "conditIrrigF",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: "agricultural-irrigation",
    isShown: true,
    label: "Agricultural Irrigation",
    isAccordion: true,
    showErrors: true,
    items: [
      {
        label: "Definite Irrigation",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "defnitIrrigA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "defnitIrrigA",
              },
              {
                inputKey: "title",
                value: "Definite Irrigation (Agricultural)",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "defnitIrrigA",
              },
            ],
          },
        ],
      },
      {
        label: "Conditional irrigation on",
        id: "conditIrrigOnA",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "conditIrrigOnA",
          },
        ],
      },
      {
        label: "Conditional Irrigation",
        preventLabelElement: true,
        id: "conditIrrigA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "conditIrrigA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "conditIrrigA",
              },
              {
                inputKey: "title",
                value: "Conditional Irrigation (Agricultural)",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "conditIrrigA",
              },
            ],
          },
        ],
      },
    ],
  },
];

const temperatureGroups: FormGroupElement[] = [
  {
    isShown: true,
    label: "Air Temperature",
    showErrors: true,
    isAccordion: true,
    isExpanded: true,
    items: [
      {
        label: "Average Air Temperature Source",
        inputs: [
          {
            element: "select",
            selectOptions: [
              {
                label: "Average Air Temperature Source time series",
                value: "Direct",
              },
              {
                label: "Estimate from min. and range of air temperatures",
                value: "RangeMin",
              },
            ],
            programmingName: "tAirTemp",
          },
        ],
      },
      {
        label: "Average Air Temperature",
        preventLabelElement: true,
        id: "avgAirTemp",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "avgAirTemp",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "avgAirTemp",
              },
              {
                inputKey: "title",
                value: "Average Air Temperature",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "avgAirTemp",
              },
            ],
          },
        ],
      },
      {
        label: "Minimum Air Temperature",
        preventLabelElement: true,
        id: "minAirTemp",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "minAirTemp",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "minAirTemp",
              },
              {
                inputKey: "title",
                value: "Minimum Air Temperature",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "minAirTemp",
              },
            ],
          },
        ],
      },
      {
        label: "Air Temperature Daily Range",
        preventLabelElement: true,
        id: "rangeAirTemp",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "rangeAirTemp",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "rangeAirTemp",
              },
              {
                inputKey: "title",
                value: "Air Temperature Daily Range",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "rangeAirTemp",
              },
            ],
          },
        ],
      },
    ],
  },
];

const growthMultipliersGroups: FormGroupElement[] = [
  {
    id: "tree-growth-multipliers",
    isShown: true,
    label: "Tree Growth Component Multipliers",
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Tree Growth Component Multipliers",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stems",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultStemF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Branches",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultBranF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Bark",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultBarkF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultLeafF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultCortF",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultFirtF",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    isShown: true,
    id: "crop-growth-multipliers",
    label: "Crop Growth Component Multipliers",
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Tree Growth Component Multipliers",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultGbfrA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultStlkA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultLeafA",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultCortA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "siteMultFirtA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
@Injectable({
  providedIn: "root",
})
export class Site2020Service extends BasePlotFormService {
  public readonly pageId = "site";
  public readonly pageHelpLink = "200_Site.htm";

  public readonly categoryIds = [5, 6, 7, 8, 9, 10, 11, 12];

  //this is the function for the inputs handled differently between new UI and existing Fullcam
  public readonly specialHandlingInputsForReadWrite = {
    hasArea: {
      read: (input: ItemInput, inputValue: any) => {
        if (inputValue == undefined) {
          return inputValue;
        }
        return !inputValue;
      },
      write: (input: ItemInput, inputValue: any) => {
        if (inputValue == undefined) {
          return inputValue;
        }
        return !inputValue;
      },
    },
  };

  public readonly layout: FormLayout = {
    label: "Site",
    groups: [
      {
        label: "Water",
        id: "water-group",
        isShown: true,
        isRoot: true,
        isAccordion: true,
        showErrors: true,
        helpLink: "12_Site_Water.htm",
        items: [
          {
            groups: waterGroups,
          },
        ],
      },
      {
        label: "Temperature",
        id: "temperature-group",
        isShown: true,
        isRoot: true,
        isAccordion: true,
        showErrors: true,
        helpLink: "13_Site_Temperature.htm",
        items: [{ groups: temperatureGroups }],
      },
      {
        label: "Productivity",
        id: "productivity",
        isShown: false,
        isRoot: true,
        showErrors: true,
        isAccordion: true,
        items: [
          {
            label: "Forest Productivity Index",
            preventLabelElement: true,
            inputs: [
              {
                element: "navigation",
                linkTo: "flyOverPanel",
                component: "DataVisualiserComponent",
                programmingName: "forestProdIx",
                componentInputs: [
                  {
                    inputKey: "timeSeries$",
                    isObservable: true,
                    formKey: "forestProdIx",
                  },
                  {
                    inputKey: "title",
                    value: "Forest Productivity Index",
                  },
                  {
                    inputKey: "formFieldModel",
                    method: "getFormFieldModel",
                    payload: "forestProdIx",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "growth-multipliers",
        label: "Growth Multipliers",
        isShown: true,
        isRoot: true,
        isAccordion: true,
        showErrors: true,
        helpLink: "39_Site_Growth%20Multipliers.htm",
        items: [
          {
            groups: growthMultipliersGroups,
          },
        ],
      },
      {
        label: "Area",
        showErrors: true,
        isShown: true,
        isRoot: true,
        helpLink: "157_Site_Area.htm",
        items: [
          {
            id: "hasArea",
            label: "Plot has no area (point model, mass outputs in t/ha)",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "hasArea",
              },
            ],
          },
          {
            id: "siteArea",
            label: "Area (mass outputs in t)",
            preventLabelElement: true,
            inputs: [
              {
                element: "input",
                type: "number",
                placeholder: "ha",
                floatingLabel: "ha",
                programmingName: "siteArea",
              },
            ],
          },
        ],
      },
      {
        label: "Maximum aboveground biomass [tdm/ha]",
        id: "max-aboveground-biomasses",
        showErrors: true,
        isShown: true,
        isRoot: true,
        helpLink: "36_Site_Maximum%20Aboveground%20Biomass.htm",
        items: [
          {
            id: "maxAbgMF",
            label: "Trees",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "maxAbgMF",
              },
            ],
          },
          {
            id: "maxAbgMA",
            label: "Crops",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "maxAbgMA",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    tAirTemp: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 13,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Source of air temperature data",
      unit: "",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AvgAirTempT",
      eventId: 10,
      tIn: 597,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tVPD: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 12,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Source of VPD data",
      unit: "",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "VPDTempT",
      eventId: 10,
      tIn: 598,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tSoilTemp: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 13,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Source of soil temperature data",
      unit: "",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "SoilTempT",
      eventId: 10,
      tIn: 1056,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    hasArea: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 157,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "Site has area (yes/no)",
      unit: "",
      categoryName: "SiteAr",
      categoryLabel: "Site : Area",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1213,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userHasArea: {
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      suffixId: null,
    },
    siteArea: {
      high: 1e36,
      low: 0.01,
      isOneMore: false,
      dataType: 0,
      helpId: 157,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.01,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Site area",
      unit: "ha",
      categoryName: "SiteAr",
      categoryLabel: "Site : Area",
      isShown: true,
      isDisabled: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1214,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    conditIrrigOnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 12,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Forest conditional irrigation (on/off)",
      unit: "",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1011,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    conditIrrigOnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 12,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Agricultural conditional irrigation (on/off)",
      unit: "",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1013,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    defnitIrrigA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 92,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "12",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Definite agricultural",
      unit: "mm",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1014,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 4,
    },
    conditIrrigA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 91,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "12",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Conditional agricultural",
      unit: "%",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1015,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 3,
    },
    soilTemp: {
      high: 100,
      low: -270,
      isOneMore: false,
      dataType: 6,
      helpId: 66,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil temperature",
      unit: "deg C",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1057,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    APAR: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 38,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "APAR (absorbed photosynthetically active radiation)",
      unit: "MJ/m2",
      categoryName: "SiteLi",
      categoryLabel: "Site : Light",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1205,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    modNutrTS: {
      high: 2,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 181,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil nutrition modifier (modNutr) in 3PG-lite",
      unit: "0...2",
      categoryName: "SitePr",
      categoryLabel: "Site : Productivity",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1209,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    modASWTS: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 183,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil water modifier (modASW) in 3PG-lite",
      unit: "0...1",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1210,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    modVPDTS: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 194,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Vapor pressure deficit (VPD) modifier in 3PG-lite",
      unit: "0...1",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1211,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    eqmEvap: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 156,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Equilibrium evaporation",
      unit: "mm",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1212,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultStemF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 5,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 184,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultBranF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 5,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 185,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultBarkF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 5,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 186,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultLeafF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 5,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 187,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultCortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 5,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 188,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultFirtF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 5,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 189,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    siteMultGbfrA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 6,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 190,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    siteMultStlkA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 6,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 191,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    siteMultLeafA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 6,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 192,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    siteMultCortA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 6,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 193,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    siteMultFirtA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 39,
      defaultValue: "1.0",
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots",
      unit: "",
      categoryName: "SiteGM",
      categoryLabel: "Site : Growth adjustments",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 6,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 194,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    maxAbgMF: {
      high: 10000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 36,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 10000,
        },
        {
          functionName: "required",
        },
      ],
      label: "Maximum aboveground forest biomass",
      unit: "dmt/ha",
      categoryName: "SiteMB",
      categoryLabel: "Site : Maximum biomass",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 1000,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 420,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 131,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxAbgMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 36,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
        {
          functionName: "required",
        },
      ],
      label: "Maximum aboveground agricultural biomass",
      unit: "dmt/ha",
      categoryName: "SiteMB",
      categoryLabel: "Site : Maximum biomass",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 132,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    latitude3PG: {
      high: 180,
      low: -180,
      isOneMore: false,
      dataType: 0,
      helpId: 35,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Latitude",
      unit: "Deg N",
      categoryName: "SiteLi",
      categoryLabel: "Site : Light",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 195,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    molPARPerMJRad: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 35,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Photosynthetically active photons per megajoule of radiation",
      unit: "mol/MJ",
      categoryName: "SiteLi",
      categoryLabel: "Site : Light",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 196,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    plantMPerMolC: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 35,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Plant mass produced per mole of GPP carbon",
      unit: "g",
      categoryName: "SiteLi",
      categoryLabel: "Site : Light",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 197,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    upstreamCRatio: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 17,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Upstream C emissions in making fossil fuel / C in that fossil fuel",
      unit: "",
      categoryName: "SiteDp",
      categoryLabel: "Site : Displacement",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 968,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    fpiAvgLT: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 36,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "FPI long term average",
      unit: "",
      categoryName: "SitePr",
      categoryLabel: "Site : Productivity",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 420,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2201,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    avgAirTemp: {
      high: 100,
      low: -270,
      isOneMore: false,
      dataType: 6,
      helpId: 89,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Average air temperature",
      unit: "deg C",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: false,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 415,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    fertility: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 90,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil fertility rating (0 to 1)",
      unit: "0...1",
      categoryName: "SitePr",
      categoryLabel: "Site : Productivity",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 2,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    conditIrrigF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 91,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "12",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Conditional forest",
      unit: "%",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 3,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 3,
    },
    defnitIrrigF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 92,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "12",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Definite forest",
      unit: "mm",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 4,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    solarRad: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 93,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Solar radiation",
      unit: "MJ/m2",
      categoryName: "SiteLi",
      categoryLabel: "Site : Light",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 5,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    frostNights: {
      high: 365.25,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 94,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Frost nights",
      unit: "days",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 6,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rangeAirTemp: {
      high: 120,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 95,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Air temperature daily range",
      unit: "deg C",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 7,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    minAirTemp: {
      high: 60,
      low: -60,
      isOneMore: false,
      dataType: 6,
      helpId: 96,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Minimum air temperature",
      unit: "deg C",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 8,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    VPD: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 6,
      helpId: 97,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Vapor pressure deficit (VPD)",
      unit: "kPa",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 9,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    openPanEvap: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 98,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Open-pan evaporation",
      unit: "mm",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 416,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 10,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    forestProdIx: {
      high: 100,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 188,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Forest productivity index (annual rate)",
      unit: "1...60",
      categoryName: "SitePr",
      categoryLabel: "Site : Productivity",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 418,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 48,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rainfall: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 88,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Rainfall",
      unit: "mm",
      categoryName: "SiteWa",
      categoryLabel: "Site : Water",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 419,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 575,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    modFrostTS: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 178,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Frost modifier (modFrost) in 3PG-lite",
      unit: "0...1",
      categoryName: "SiteTe",
      categoryLabel: "Site : Temperature",
      isShown: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 589,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
  };

  public setTimeSeriesData(data) {
    this.formGroup.get(data.$.tInTS).setValue(data);
  }

  public applyDefaultTimeSeriesData() {
    if (!this.simulationService.getPlotFileJson()) {
      return;
    }
    if (this.formGroup) {
      let template = this.simulationService.getPlotFileJson()["Site"];
      template[0].TimeSeries.forEach((ts) => {
        const fieldName = ts.$.tInTS;
        if (fieldName && this.formGroup.get(fieldName)) {
          this.formGroup.get(fieldName).setValue(ts);
        } else {
          console.warn(`Key Site -> ${fieldName} not found`);
        }
      });
    }
  }

  public updateFormDataFromSpatialData(inputElement) {
    let hasAvgAirTempInput = false;
    let hasRangeAirTemp = false;

    inputElement.forEach((ie) => {
      let data;
      if (ie.TimeSeries) {
        const { WinState, rawTS, ...restProp } = ie.TimeSeries[0];
        data = {
          $: restProp,
          WinState: { $: WinState[0] },
          rawTS: [{ $: { count: rawTS[0].count }, _: rawTS[0]._ }],
        };
      } else {
        data = ie.value;
      }
      this.applyXmlValuesToFormGroup({ [ie.tIn]: data }, this.getFormGroup());
      if (ie.tIn == "avgAirTemp") {
        hasAvgAirTempInput = true;
      }
      if (ie.tIn == "rangeAirTemp") {
        hasRangeAirTemp = true;
      }
    });

    //update tAirTemp dropdown value in site
    if (hasAvgAirTempInput) {
      this.formGroup.get("tAirTemp").setValue("Direct");
    }
    if (hasRangeAirTemp) {
      this.formGroup.get("tAirTemp").setValue("RangeMin");
    }

    //classic fullcam behaviour sets fpiAvgLT to 0,
    //after running the simulation it sets back to the value using the calculateFPIAvgLT function
    this.getFormGroup().get("fpiAvgLT").setValue(0);
    // this.calculateFPIAvgLT();
  }

  public calculateFPIAvgLT(): void {
    // if (!at(kin_forestProdIx))
    // 	return;
    //   Fot& fpi = forestProdIx.rawTS;
    //   fpiAvgLT = 0.0;
    //   int num = 0;
    //   int len = fpi.len;
    //   if (len > 48)//48th year from 1970 is 2017
    //       len = 48;//48th year from 1970 is 2017
    //   for (int i = 0; i < len; i += 1)
    //   {
    //       if (fpi.f[i] != kfBlank)
    //       {
    //           fpiAvgLT += fpi.f[i];
    //           num += 1;
    //       }
    //   }
    //   fpiAvgLT *= forestProdIx.z.multTS;
    //   fpiAvgLT /= num;

    const forestProdIx = this.getFormGroup().get("forestProdIx").value;

    if (!forestProdIx) {
      return;
    }

    const timeSeriesData = forestProdIx.rawTS[0]?._.split(",");

    let fpiAvgLT = 0.0;
    let num = 0;
    let len = timeSeriesData.length;

    if (len > 48) {
      len = 48; // limit to 48 entries
    }

    for (let i = 0; i < len; i++) {
      const value = parseFloat(timeSeriesData[i]);
      if (!isNaN(value)) {
        fpiAvgLT += value;
        num++;
      }
    }

    if (num > 0) {
      fpiAvgLT *= +forestProdIx.$.multTS;
      fpiAvgLT /= num;
    } else {
      fpiAvgLT = 0; // or some other default/error value
    }

    this.getFormGroup().get("fpiAvgLT").setValue(fpiAvgLT);
  }

  public readonly fieldWatcher: FieldWatcher = {
    conditIrrigOnF: (newValue: any) => {
      this.formModel["conditIrrigF"].isShown = newValue;
    },
    conditIrrigOnA: (newValue: any) => {
      this.formModel["conditIrrigA"].isShown = newValue;
    },
    tAirTemp: (newValue: any, { formGroup, formModel }) => {
      if (newValue == "RangeMin") {
        this.formModel["minAirTemp"].isShown = true;
        this.formModel["rangeAirTemp"].isShown = true;
        this.formModel["avgAirTemp"].isShown = false;
      } else if (newValue == "Direct") {
        this.formModel["minAirTemp"].isShown = false;
        this.formModel["rangeAirTemp"].isShown = false;
        this.formModel["avgAirTemp"].isShown = true;
      } else {
        this.formModel["minAirTemp"].isShown = false;
        this.formModel["rangeAirTemp"].isShown = false;
        this.formModel["avgAirTemp"].isShown = false;
      }

      if (this.formModel["minAirTemp"].isShown) {
        this.addDefaultValidatorsInBulk(["minAirTemp"], formGroup, formModel);
        // this.addValidatorsInBulk(
        //   ["minAirTemp"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          ["minAirTemp"],
          formGroup,
          formModel
        );
        // this.removeValidatorsInBulk(
        //   ["minAirTemp"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (this.formModel["rangeAirTemp"].isShown) {
        this.addDefaultValidatorsInBulk(["rangeAirTemp"], formGroup, formModel);
        // this.addValidatorsInBulk(
        //   ["rangeAirTemp"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          ["rangeAirTemp"],
          formGroup,
          formModel
        );

        // this.removeValidatorsInBulk(
        //   ["rangeAirTemp"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (this.formModel["avgAirTemp"].isShown) {
        this.addDefaultValidatorsInBulk(["avgAirTemp"], formGroup, formModel);
        // this.addValidatorsInBulk(
        //   ["avgAirTemp"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          ["avgAirTemp"],
          formGroup,
          formModel
        );

        // this.removeValidatorsInBulk(
        //   ["avgAirTemp"],
        //   [Validators.required],
        //   formGroup
        // );
      }
    },
    hasArea: (newValue: any, { formGroup, formModel }) => {
      const siteAreaModel = formModel["siteArea"];
      siteAreaModel.isDisabled = newValue;
      if (!newValue) {
        this.addDefaultValidatorsInBulk(["siteArea"], formGroup, formModel);

        // this.addValidatorsInBulk(
        //   ["siteArea"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(["siteArea"], formGroup, formModel);

        // this.removeValidatorsInBulk(
        //   ["siteArea"],
        //   [Validators.required],
        //   formGroup
        // );
      }
    },
    "configuration:tPlot": (
      newValue: any,
      { formGroup, formModel, simulationService }
    ) => {
      const plotType = newValue;
      const configurationFormGroup =
        simulationService.configurationService.getFormGroup();
      const treeProduction = configurationFormGroup.get("tTreeProd").value;
      const soilMnrl = configurationFormGroup.get("userSoilMnrl").value;
      const userEcon = configurationFormGroup.get("userEcon").value;

      const growthMultipliersGroup = this.getLayoutGroup(
        this.layout.groups,
        "growth-multipliers"
      );
      const treeGrowthMultipliersGroup = this.getLayoutGroup(
        this.layout.groups,
        "tree-growth-multipliers"
      );
      const cropGrowthMultipliersGroup = this.getLayoutGroup(
        this.layout.groups,
        "crop-growth-multipliers"
      );

      const agriculturalIrrigationGroup = this.getLayoutGroup(
        this.layout.groups,
        "agricultural-irrigation"
      );
      const forestIrrigationGroup = this.getLayoutGroup(
        this.layout.groups,
        "forest-irrigation"
      );

      const maxAboveGroundBiomassesGroup = this.getLayoutGroup(
        this.layout.groups,
        "max-aboveground-biomasses"
      );

      growthMultipliersGroup.isShown =
        Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType);
      treeGrowthMultipliersGroup.isShown =
        Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType);
      cropGrowthMultipliersGroup.isShown =
        Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType);

      agriculturalIrrigationGroup.isShown =
        Constants.ALL_AGRICULTURAL_PLOT_TYPES.includes(plotType);
      forestIrrigationGroup.isShown =
        Constants.ALL_FOREST_PLOT_TYPES.includes(plotType);

      maxAboveGroundBiomassesGroup.isShown =
        Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType);

      formModel["maxAbgMF"].isShown =
        Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType);
      if (formModel["maxAbgMF"].isShown) {
        this.addDefaultValidatorsInBulk(["maxAbgMF"], formGroup, formModel);

        // this.addValidatorsInBulk(
        //   ["maxAbgMF"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(["maxAbgMF"], formGroup, formModel);
        // this.removeValidatorsInBulk(
        //   ["maxAbgMF"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      formModel["maxAbgMA"].isShown =
        Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType);
      if (formModel["maxAbgMA"].isShown) {
        this.addDefaultValidatorsInBulk(["maxAbgMA"], formGroup, formModel);

        // this.addValidatorsInBulk(
        //   ["maxAbgMA"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(["maxAbgMA"], formGroup, formModel);
        // this.removeValidatorsInBulk(
        //   ["maxAbgMA"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      //Plot area
      this.applyEconomicWatcher(
        userEcon,
        formModel,
        formGroup,
        simulationService
      );

      //productivities group
      this.applyTreeProdWatcher(
        treeProduction,
        formModel,
        formGroup,
        simulationService
      );

      this.applySoilMnrlWatcher(
        soilMnrl,
        formModel,
        formGroup,
        simulationService
      );
    },

    "configuration:tTreeProd": (
      newValue: any,
      { formGroup, formModel, simulationService }
    ) => {
      this.applyTreeProdWatcher(
        newValue,
        formModel,
        formGroup,
        simulationService
      );
    },
    "configuration:userSoilMnrl": (
      newValue: any,
      { formGroup, formModel, simulationService }
    ) => {
      this.applySoilMnrlWatcher(
        newValue,
        formModel,
        formGroup,
        simulationService
      );
    },
    "configuration:userEcon": (
      newValue: any,
      { formGroup, formModel, simulationService }
    ) => {
      this.applyEconomicWatcher(
        newValue,
        formModel,
        formGroup,
        simulationService
      );
    },
  };

  applySoilMnrlWatcher(
    newValue: any,
    formModel: FormModel,
    formGroup: UntypedFormGroup,
    simulationService: SimulationService
  ) {
    const configurationFormGroup =
      simulationService.configurationService.getFormGroup();
    const plotType = configurationFormGroup.get("tPlot").value;

    const temperatureGroup = this.getLayoutGroup(
      this.layout.groups,
      "temperature-group"
    );
    const waterGroup = this.getLayoutGroup(this.layout.groups, "water-group");

    temperatureGroup.isShown = !(
      Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType) && !newValue
    );
    waterGroup.isShown = !(
      Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType) && !newValue
    );

    if (Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType) && !newValue) {
      formModel["conditIrrigOnF"].isShown = false;
      formModel["conditIrrigOnA"].isShown = false;
      formModel["conditIrrigF"].isShown = false;
      formModel["conditIrrigA"].isShown = false;
      if (Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType)) {
        this.removeDefaultValidatorsInBulk(
          ["conditIrrigF"],
          formGroup,
          formModel
        );
        // this.removeValidatorsInBulk(
        //   ["conditIrrigF"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType)) {
        this.removeDefaultValidatorsInBulk(
          ["conditIrrigA"],
          formGroup,
          formModel
        );
        // this.removeValidatorsInBulk(
        //   ["conditIrrigA"],
        //   [Validators.required],
        //   formGroup
        // );
      }
    } else {
      formModel["conditIrrigOnF"].isShown = true;
      formModel["conditIrrigOnA"].isShown = true;

      if (Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType)) {
        this.addDefaultValidatorsInBulk(["conditIrrigF"], formGroup, formModel);

        // this.addValidatorsInBulk(
        //   ["conditIrrigF"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType)) {
        this.addDefaultValidatorsInBulk(["conditIrrigA"], formGroup, formModel);
        // this.addValidatorsInBulk(
        //   ["conditIrrigA"],
        //   [Validators.required],
        //   formGroup
        // );
      }
    }
  }

  applyTreeProdWatcher(
    newValue: any,
    formModel: FormModel,
    formGroup: UntypedFormGroup,
    simulationService: SimulationService
  ) {
    const configurationFormGroup =
      simulationService.configurationService.getFormGroup();
    const plotType = configurationFormGroup.get("tPlot").value;

    const isTYF = newValue == "TYF";

    const productivityGroup = this.getLayoutGroup(
      this.layout.groups,
      "productivity"
    );

    const showProductivityGroup =
      isTYF && Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType);

    productivityGroup.isShown = showProductivityGroup;

    formModel["maxAbgMF"].isShown = showProductivityGroup;

    if (isTYF && showProductivityGroup) {
      this.addDefaultValidatorsInBulk(
        ["forestProdIx", "maxAbgMF"],
        formGroup,
        formModel
      );
      // this.addValidatorsInBulk(
      //   ["forestProdIx", "maxAbgMF"],
      //   [Validators.required],
      //   formGroup
      // );
    } else {
      this.removeDefaultValidatorsInBulk(
        ["forestProdIx", "maxAbgMF"],
        formGroup,
        formModel
      );
      // this.removeValidatorsInBulk(
      //   ["forestProdIx", "maxAbgMF"],
      //   [Validators.required],
      //   formGroup
      // );
    }
  }

  applyEconomicWatcher(
    newValue: any,
    formModel: FormModel,
    formGroup: UntypedFormGroup,
    simulationService: SimulationService
  ) {
    const configurationFormGroup =
      simulationService.configurationService.getFormGroup();
    const plotType = configurationFormGroup.get("tPlot").value;
    const econOn = newValue;
    const hasAreaControl = formGroup.get("hasArea");
    const hasAreaValue = hasAreaControl.value;
    const shouldHideHasAreaControl =
      econOn && Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType);

    if (shouldHideHasAreaControl && hasAreaValue)
      hasAreaControl.setValue(false);
    formModel["hasArea"].isShown = !shouldHideHasAreaControl;
  }

  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    if (this.formModel[programmingName].dataType == 6) {
      return { xPath: "//TimeSeries", id: null };
    } else {
      return { xPath: "//Site", id: null };
    }
  }

  public readXmlObject(): void {
    super.readXmlObject();

    const template = this.simulationService.getPlotFileJson()["Site"][0];
    const timeSeries = template.TimeSeries;
    const site = template.$;

    //special handling inputs will executed in baseplotFormService
    this.applyXmlValuesToFormGroup(site, this.formGroup);
    if (timeSeries) {
      timeSeries.forEach((ts) => {
        const objectKey = ts?.$?.tInTS;
        const formControl = this.formGroup.get(objectKey);
        if (formControl) {
          formControl.setValue(ts);
        } else {
          console.warn(`Site -> ${objectKey} not found`);
        }
      });
    }

    //manually trigger watchers to reapply validations
    this.manuallyTriggerWatchingFields(this.fieldWatcher, {
      formGroup: this.formGroup,
      formModel: this.formModel,
      layout: this.layout,
      simulationService: this.simulationService,
    });
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();
    //convertFormValueToXml
    let site: any = {};
    let timeSeries = [];
    let nonTimeseriesData = {};

    Object.entries(formData).forEach((data) => {
      const key = data[0];
      const foundInput = this.findInputElementById(this.layout.groups, key);

      let value = this.specialHandlingInputsForReadWrite[key]?.write(
        foundInput.input,
        data[1]
      );
      if (value == undefined) {
        value = data[1];
      }

      if (value !== undefined) {
        if (this.formModel[key].dataType == 6) {
          timeSeries.push(value);
        } else {
          nonTimeseriesData[key] = value;
        }
      }
    });

    site = {
      count: timeSeries.length,
      ...this.getFormValueObjectForXmlExport(null, nonTimeseriesData),
    };

    return { Site: [{ $: site, TimeSeries: timeSeries }] };
  }

  public runFunctionsAfterSimulation(): void {
    this.calculateFPIAvgLT();
    return;
  }
}
