import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SimulationService, TreeViewItem } from "../../models";
import * as _ from "lodash";
import { ModalOptions } from "ngx-bootstrap/modal";
import { of, take } from "rxjs";
import { ProjectFormComponent } from "../project-form/project-form.component";
import {
  CollectionTableColumns,
  FolderType,
  ProjectTableColumns,
} from "src/app/my-plots/models";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "fc-project-collection-list",
  templateUrl: "./project-collection-list.component.html",
  styleUrls: ["./project-collection-list.component.scss"],
})
export class ProjectCollectionListComponent implements OnInit {
  @Input() isRMT: boolean = false;
  @Input() data: TreeViewItem[];
  @Input() subitemKey: string = "subitems";
  @Input() parentIdKey: string = "id";
  @Input() subitemSubmitTooltip: string = "";
  @Input() itemLabelTooltip: string = "";
  @Input() subitemLabelTooltip: string = "";

  @Input() hasLabelAction: boolean = false;

  @Output() itemAdded = new EventEmitter<{ type: string; projects: [] }>();

  @Output() itemLabelClicked = new EventEmitter<any>();

  @Output() editItem = new EventEmitter<any>();
  @Output() deleteItem = new EventEmitter<any>();

  @Output() subitemClicked = new EventEmitter<any>();

  public _data: TreeViewItem[];

  public searchData: TreeViewItem[] = [];

  public selectedTypeAheadModel: any;

  public simulationService: SimulationService = null;

  constructor(public appService: AppService) {}

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      const currentValue: any = changes.data.currentValue || [];
      const applyExpandFlag = (d, parent) => {
        const hasSubItem = d[this.subitemKey];
        let subitems;
        if (hasSubItem) {
          subitems = d[this.subitemKey].map((si) => applyExpandFlag(si, d));
        }
        return {
          ...d,
          ...(parent && { parent }),
          ...(hasSubItem && { [this.subitemKey]: subitems }),
          isExpanded: false,
          isSearchMatch: false,
        };
      };

      this._data = currentValue.map((item) => applyExpandFlag(item, null));
    }
  }

  public clearTypeAheadModel(data) {
    this.selectedTypeAheadModel = "";

    data.forEach((d) => {
      if (d[this.subitemKey]) {
        d[this.subitemKey].forEach((si) => {
          si.isSearchMatch = false;
        });
      }
      d.isSearchMatch = false;
    });
  }

  public filterItems(data) {
    if (this.selectedTypeAheadModel?.length > 0) {
      data.forEach((d) => {
        let hasMatchSubitems = false;
        if (d[this.subitemKey]) {
          d[this.subitemKey].forEach((si) => {
            si.isSearchMatch = this.validateSearchString(si);
            if (si.isSearchMatch) {
              hasMatchSubitems = true;
            }
          });
        }

        d.isExpanded = hasMatchSubitems;
        d.isSearchMatch = this.validateSearchString(d);
      });
    } else if (this.selectedTypeAheadModel?.length < 1) {
      this.clearTypeAheadModel(data);
    }
  }

  protected validateSearchString(d): boolean {
    const matchName =
      String(d.name)
        .toLowerCase()
        .indexOf(this.selectedTypeAheadModel.toLowerCase()) > -1;
    const matchDiscription =
      String(d.description)
        .toLowerCase()
        .indexOf(this.selectedTypeAheadModel.toLowerCase()) > -1;
    return matchName || matchDiscription;
  }

  public async openProjectForm(
    type: FolderType,
    associatedProject?: ProjectTableColumns,
    formData?: ProjectTableColumns | CollectionTableColumns
  ) {
    const initialState: ModalOptions = {
      initialState: {
        isRMT: this.isRMT,
        type: type,
        formData: formData,
        folders$: of(this.data),
        ...(type == "collection" && {
          associatedProject: associatedProject,
          lockProject: true,
        }),
      },
    };

    this.simulationService.modalService
      .openModal(ProjectFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe(() => {
        this.loadFolders();
      });
  }

  private loadFolders() {
    if (!this.isRMT) {
      this.simulationService.aboutService.loadFolders();
    } else {
      this.simulationService.projectService.loadFolders();
    }
  }
}
