import { Component, OnInit } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";
import { Observable } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { Constants } from "src/app/shared/constants";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-crops",
  templateUrl: "./crops.component.html",
  styleUrls: ["./crops.component.scss"],
})
export class CropsComponent extends BaseFlowComponent implements OnInit {
  public selectedSpeciesIndex: Observable<number> = null;
  ngOnInit(): void {
    this.formGroup = this.service.getFormGroup();

    this.layout = this.service.layout;
    this.modifiers = this.service.modifiers;

    this.service.selectedSpeciesId$
      .pipe(
        takeUntil(this.destroySubject$),
        tap((id) => {
          const index = this.service.getSelectedSpeciesIndex();

          if (id == null) {
            //this.service.speciesButtonClicked({ event: "new" });
          } else if (index > -1) {
            //reset
            //this.destroySubject$.next();
            this.selectedSpeciesIndex = index;
            setTimeout(() => {
              this.service.watchFields(this.destroySubject$, {
                formGroup: this.service.getSelectedSpeciesFormGroup(),
                formModel: this.service.speciesFormModel,
                layout: this.layout,
                fieldChangedEmitter: this.fieldChanged,
              });
            }, 1);
          }
        })
      )
      .subscribe();
    this.service.setSpeciesInUse();
  }

  get species() {
    return this.formGroup.get("species") as UntypedFormArray;
  }

  public addSpecies(): void {
    this.service.speciesButtonClicked({ event: "new" });
  }
}
