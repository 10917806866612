<ng-container *ngIf="tableData; else noItem">
  <table>
    <thead>
      <th *ngFor="let header of tableData.headers">{{ header }}</th>
    </thead>
    <tbody>
      <tr *ngFor="let row of tableData.rows">
        <td *ngFor="let d of row">{{ d }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template #noItem>
  <div class="no-item">No data</div>
</ng-template>
