import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseModalFormComponent } from "../base-modal-form/base-modal-form.component";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { EventStyleClass } from "src/app/plot/events/models";
import { EventFormsService } from "../../models";

@Component({
  selector: "fc-insert-species-values-form",
  templateUrl: "./insert-species-values-form.component.html",
  styleUrls: ["./insert-species-values-form.component.scss"],
})
export class InsertSpeciesValuesFormComponent
  extends BaseModalFormComponent
  implements OnInit
{
  @ViewChild("table") table: DatatableComponent;
  public speciesEvents = [];

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;
  public selectedRows = [];
  public isInertEventName = false;

  //modal input
  public eventFormsService: EventFormsService;
  public eventType = null;
  public species = [];

  ngOnInit(): void {
    this.createSpeciesList();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.table.recalculate();
    }, 400);
  }

  protected createSpeciesList() {
    this.speciesEvents = this.species
      .map((s) => {
        const filteredEventQ = s.eventQ.filter(
          (eq) => eq.tEV == this.eventType
        );

        if (!filteredEventQ.length) {
          return [];
        }

        return filteredEventQ.map((eq) => {
          return {
            speciesName: s.nmSP,
            eventName: eq.nmEV,
            eventDescription: this.eventFormsService.getEventDescription(eq),
            data: eq,
          };
        });
      })
      .flat()
      .sort(
        (a, b) =>
          a.speciesName.localeCompare(b.speciesName) ||
          a.eventName.localeCompare(b.eventName)
      );
  }

  public onSelect({ selected }): void {
    this.selectedRows = [];
    if (selected.length) {
      this.selectedRows = [selected[selected.length - 1]];
    }
  }

  public getEventClass(row): EventStyleClass {
    const eventName = row.data.tEV;

    const eventType =
      this.eventFormsService.getAvailableEventTypes()[eventName]?.type;
    return eventType == "tree"
      ? "forest-event"
      : eventType == "crop"
      ? "agricultural-event"
      : "mixed-event";
  }
}
