import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";
import { WeedControlIntensityEnum } from "../models";
import { RotationEvent, RotationForest } from "../../models";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";

@Injectable({
  providedIn: "root",
})
export class RotationWeedControlService extends BaseRotationEventService {
  public _type = "RotationWeedControl";

  private intensityDisplayMap = {
    Light: "Light",
    Normal: "Normal",
    Heavy: "Heavy",
  };

  public availableOpitions = [];
  public additionalFormControls = [
    {
      label: "Weed Control Intensity",
      key: "weedControlIntensity",
      element: "select",
      getOptions: () => this.availableOpitions,
    },
  ];

  constructor(
    public rotationEvent: RotationEvent,
    public rotationFormGroup: AbstractControl<RotationForest>,
    public managementService: RmtManagementService
  ) {
    super(rotationEvent, rotationFormGroup, managementService);

    this.eventFormGroup.addControl(
      "weedControlIntensity",
      new FormControl(null, [Validators.required])
    );

    const weedControlIntensityGroups = Array.isArray(
      this.regimeEventReference?.Value?.WeedControlIntensityGroups
    )
      ? this.regimeEventReference.Value.WeedControlIntensityGroups
      : [
          this.regimeEventReference.Value.WeedControlIntensityGroups
            .WeedControlIntensityGroup,
        ];

    if (weedControlIntensityGroups.length) {
      this.availableOpitions = weedControlIntensityGroups.map((g) => {
        return {
          value: g.WeedControlIntensity,
          label: this.intensityDisplayMap[g.WeedControlIntensity],
        };
      });
      const defaultValue = weedControlIntensityGroups[0].WeedControlIntensity;
      this.eventFormGroup.get("weedControlIntensity").setValue(defaultValue);
    }
  }

  public getIntensity() {
    return (
      "Weed Control Intensity: " +
      this.eventFormGroup.get("weedControlIntensity").value
    );
  }

  override readXmlObject(originalUserRotationEvent) {
    let userRotationEvent = super.readXmlObject(originalUserRotationEvent);
    const weedControlIntensity = userRotationEvent.WeedControlIntensity;
    this.eventFormGroup
      .get("weedControlIntensity")
      .setValue(weedControlIntensity);
  }

  override writeXmlObject() {
    let template = super.writeXmlObject();

    if (
      !template.RegimeEventReferenceValue["WeedControlIntensityGroups"]
        ?.WeedControlIntensityGroup
    ) {
      template.RegimeEventReferenceValue["WeedControlIntensityGroups"] = {
        WeedControlIntensityGroup:
          template.RegimeEventReferenceValue["WeedControlIntensityGroups"],
      };
    }
    template["WeedControlIntensity"] = this.eventFormGroup.get(
      "weedControlIntensity"
    ).value;

    return template;
  }
}
