<form (keydown.enter)="$event.preventDefault()" class="row g-0" *ngIf="layout">
  <fieldset class="main-form-col col-12">
    <legend class="visually-hidden">main form</legend>
    <div class="parent-group">
      <h1 class="page-title">
        <div>
          <span>{{ getBasePageTitle() }}</span> > {{ layout.label }}
        </div>
        <a
          aria-label="help link button"
          type="button"
          *ngIf="service.pageHelpLink"
          class="btn"
          [href]="service.baseHelpPageUrl + '/' + service.pageHelpLink"
          [tooltip]="'View ' + layout.label + ' page help'"
          target="_blank"
        >
          <i class="bi bi-question-circle"></i>
        </a>
      </h1>
      <div
        fc-form-group-element
        class="sub-groups"
        [formGroupInstance]="formGroup"
        [service]="service"
        [simulationService]="simulationService"
        [group]="group"
        *ngFor="let group of layout.groups"
      ></div>
    </div>
    <fc-flow-bottom-navigation
      [canNavigatePreviousFlow$]="canNavigatePreviousFlow$"
      [canNavigateNextFlow$]="canNavigateNextFlow$"
      (nextFlowClicked)="nextFlowClicked.emit()"
      (previousFlowClicked)="previousFlowClicked.emit()"
    >
    </fc-flow-bottom-navigation>
  </fieldset>
</form>
