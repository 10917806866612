import { Injectable } from "@angular/core";
import {
  catchError,
  concatMap,
  delay,
  forkJoin,
  map,
  Observable,
  of,
} from "rxjs";
import { FolderType, ListType } from "src/app/my-plots/models";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";

@Injectable({
  providedIn: "root",
})
export class MyRmdService extends MyPlotsService {
  override getList(type: ListType, inputParams?): Observable<any> {
    return this.dbService.getList(type, inputParams, true);
  }

  override createFolder(type: FolderType, params): Observable<any> {
    return this.dbService.createFolder(type, params, true);
  }

  override updateFolder(type: FolderType, params): Observable<any> {
    return this.dbService.updateFolder(type, params, true);
  }

  override deleteFolders(type: FolderType, params: string[]): Observable<any> {
    return this.dbService.deleteFolders(type, params, true);
  }

  override downloadPlotFiles(
    ids: string[]
  ): Observable<{ id: string; result: string }[]> {
    return forkJoin(
      ids.map((id, index) => {
        return of(null).pipe(
          // Add a 1-second delay for each download
          delay(index * 1000),
          // Use concatMap to continue even if one of them throws an error
          concatMap(() => {
            return this.dbService.downloadPlotFile(id, true).pipe(
              map((result) => ({ id, result })),
              catchError((error) => {
                // Handle the error here or return a placeholder result
                console.error(`Error downloading file with ID ${id}:`, error);
                return of({ id, result: "Error occurred" });
              })
            );
          })
        );
      })
    );
  }

  override deletePlotFiles(ids: string[]): Observable<any> {
    return this.dbService.deletePlotFiles(ids, true);
  }
}
