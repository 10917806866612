import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FolderList } from "src/app/my-plots/models";
import { Observable } from "rxjs";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { AppService } from "src/app/services/app.service";
import { SimulationService, UploadProgressCount } from "../../models";

@Component({
  selector: "fc-plot-upload",
  templateUrl: "./plot-upload.component.html",
  styleUrls: ["./plot-upload.component.scss"],
})
export class PlotUploadComponent implements OnInit {
  @Output() action = new EventEmitter();

  public projectCollectionFilter: {
    collection: string;
    collectionId: string;
    project: string;
    projectId: string;
  } = null;

  public folders$: Observable<FolderList[]>;

  public formGroup: FormGroup = new FormGroup({
    project: new FormControl(null, [Validators.required]),
    collection: new FormControl(null, [Validators.required]),
  });

  public simulationService: SimulationService = null;

  public uploadProgressCount$: Observable<UploadProgressCount>;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();

    this.formGroup
      .get("project")
      .setValue(this.projectCollectionFilter?.project);
    this.formGroup
      .get("collection")
      .setValue(this.projectCollectionFilter?.collection);

    this.uploadProgressCount$ = this.simulationService.uploadProgressCount$;
  }

  public getFilesFormArray(): FormArray {
    return this.simulationService.uploadingFiles$.getValue() as FormArray;
  }

  public onFileChange(event) {
    if (event.target?.files.length) {
      this.simulationService.loadPlotFiles(event.target.files);
    }
  }

  public areProjectAndCollectionValid(): boolean {
    return (
      this.formGroup.get("project").valid &&
      this.formGroup.get("collection").valid
    );
  }

  public shouldDisableUpload(): boolean {
    console.log(!this.areProjectAndCollectionValid());
    console.log(!this.getFilesFormArray().length);
    console.log(this.simulationService.isUploading$.getValue());

    return (
      !this.areProjectAndCollectionValid() ||
      !this.getFilesFormArray().length ||
      this.simulationService.isUploading$.getValue()
    );
  }

  public deleteFile(row) {
    this.simulationService.deleteFile(row);
  }

  public closeForm(): void {
    this.simulationService.resetFileUpload();
    this.action.emit(null);
  }

  public onSubmit(): void {
    const fileFormArray = this.getFilesFormArray();

    fileFormArray.controls.forEach((fileForm) => {
      fileForm.get("project").setValue(this.formGroup.get("project").value);
      fileForm
        .get("collection")
        .setValue(this.formGroup.get("collection").value);
    });

    this.simulationService.uploadPlotFiles(fileFormArray);
  }

  public cancelUpload(): void {
    this.simulationService.cancelAllUploads();
  }
  public resetUploadForm(): void {
    this.simulationService.resetFileUpload();
  }

  public hasCompletedFile(): boolean {
    return this.getFilesFormArray().value.some(
      (f) => f.progress == "completed"
    );
  }

  public isNumber(progress: string): boolean {
    return !Number.isNaN(+progress);
  }

  public async loadFolders() {
    await this.simulationService.aboutService.loadFolders();
  }
}
