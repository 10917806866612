export type ListType = "plot" | "project" | "collection" | "folder" | "rmd";

export type FolderType = "project" | "collection";

export interface FolderList extends ProjectTableColumns {
  collectionInfo: CollectionTableColumns[];
  collectionId: string;
}

export interface ProjectTableColumns {
  projectId: string;
  name: string;
  label: string;
  description: string;
  createdAt?: Date;
  updatedAt?: Date;
  collectionInfo: CollectionTableColumns[];
}

export interface CollectionTableColumns extends ProjectTableColumns {
  collectionId: string;
}

export interface PlotTableColumns {
  plotId: string;
  projectId: string;
  collectionId: string;
  fileName: string;
  fileType: "plo" | "pld" | "est";
  project: string;
  collection: string;
  latitude: string;
  longitude: string;
  status?: "complete" | "pending";
  createdAt?: Date;
  updatedAt?: Date;
}
export interface EsatePlotTableColumns extends PlotTableColumns {
  validationStatus: string;
  validationMessage: string;
  isValid: boolean;
  isUsedByEstate: boolean;
}

export class DataTablePage {
  // The number of elements in the page
  pageSize: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
  offset: number = 0;
}

export interface GenerateEstateColumns {
  "start year": string;
  "start step": string;
  area: string;
  "plot file": string;
}
