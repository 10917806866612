<button
  *ngFor="let item of mainNav"
  [hidden]="!item.isShown"
  (click)="setSelectedFlowPage(item)"
  [class]="getNavClasses(item)"
  class="btn nav-button"
  [disabled]="isItemDisabled(item)"
>
  {{ item.label }}
</button>

<button
  *ngIf="overflowItems?.length"
  class="btn btn-sm dropdown-toggle more-items show-nav"
  [class]="getOverflowItemStatus()"
  type="button"
  data-bs-toggle="dropdown"
  aria-expanded="false"
>
  More <span class="caret"></span>
</button>
<ul class="dropdown-menu">
  <li *ngFor="let item of overflowItems">
    <a
      class="dropdown-item"
      [class]="{
        active: item.id == selectedItem?.id,
        'is-invalid': item?.isInvalid(),
        disabled: isItemDisabled(item)
      }"
      href="#"
      (click)="$event.preventDefault(); setSelectedFlowPage(item)"
      [attr.aria-disabled]="isItemDisabled(item)"
    >
      {{ item.label }}</a
    >
  </li>
</ul>
