import { Component, OnInit } from "@angular/core";
import { MapMouseEvent } from "mapbox-gl";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-data-builder",
  templateUrl: "./data-builder.component.html",
  styleUrls: ["./data-builder.component.scss"],
})
export class DataBuilderComponent extends BaseFlowComponent implements OnInit {
  public mapClicked(event: MapMouseEvent) {
    this.service.setLatlng(event.lngLat);
  }
}
