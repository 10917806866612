import moment from "moment";
import { Constants } from "../constants";

const millisecondsPerSecond = 1000;
const secondsPerMinute = 60;
const minutesPerHour = 60;
const hoursPerDay = 24;
const daysPerWeek = 7;

//offset before 1970
const epochOffset = 621355968000000000;
const daysFrom0000To1970 = 719527;
const ticksPerMillisecond = 10000;
const ticksPerSecond = ticksPerMillisecond * millisecondsPerSecond;
const ticksPerMinute = ticksPerSecond * secondsPerMinute;
const ticksPerHour = ticksPerMinute * minutesPerHour;
const ticksPerDay = ticksPerHour * hoursPerDay;
const ticksPerWeek = ticksPerDay * daysPerWeek;

const nmberOfDaysIn400Years = ((365 * 4 + 1) * 25 - 1) * 4 + 1;

const minYear = -27258;
const maxYear = 31196;
const maxMonth = 12;

export default class TimeUtilities {
  static ticksPerDay = ticksPerDay;

  static getMoment() {
    return moment;
  }

  static isLeapYear(year: number): boolean {
    return (year & 3) == 0 && (year % 100 != 0 || year % 400 == 0);
  }

  static calculateStartOfYear(year: number) {
    // Initial value is just temporary.
    let leapYears = year / 100;
    if (year < 0) {
      // Add 3 before shifting right since /4 and >>2 behave differently
      // on negative numbers. When the expression is written as
      // (year / 4) - (year / 100) + (year / 400),
      // it works for both positive and negative values, except this optimization
      // eliminates two divisions.
      leapYears = ((year + 3) >> 2) - leapYears + ((leapYears + 3) >> 2) - 1;
    } else {
      leapYears = (year >> 2) - leapYears + (leapYears >> 2);
      if (this.isLeapYear(year)) {
        leapYears--;
      }
    }

    return (
      (year * 365 + (Math.round(leapYears) - daysFrom0000To1970)) * ticksPerDay
    );
  }

  static dateToTicks(date: Date): number {
    return date.getTime() * ticksPerMillisecond + epochOffset;
  }

  static convertDayStringToDate(dateString): Date {
    return moment(dateString).toDate();
  }

  static getDaysInYear(year: string) {
    let days = 0;
    [...new Array(12)].forEach((_, i) => {
      days += moment(
        `${year}-${i < 9 ? "0" + (i + 1) : i + 1}`,
        "YYYY-MM"
      ).daysInMonth();
    });

    return days;
  }

  static getYearTicks(year: string): number {
    return this.calculateStartOfYear(+year);
  }

  static getYearTicksByYear(year: string): number {
    return this.getDaysInYear(year) * ticksPerDay;
  }

  static convertUnixToTimeInstant(unixTimeStamp: number) {
    return unixTimeStamp * 1000 * ticksPerMillisecond - ticksPerDay;
  }

  static getTimeInstant(dateString: string): number {
    return (
      moment(dateString + "T00:00:00Z").unix() * 1000 * ticksPerMillisecond -
      ticksPerDay
    );
  }

  static getYearFromTimeInstant(timeInstant): string {
    const unixTimeStamp = timeInstant / 1000 / ticksPerMillisecond;
    return moment.unix(unixTimeStamp).add(1, "year").format("YYYY");
  }

  static getTimeInYears(instant: number) {
    const year = this.getYearFromTimeInstant(instant);

    const yearTicks = this.getYearTicks(year);
    const ticksInYear = this.getYearTicksByYear(year);

    const f = (instant - yearTicks) / ticksInYear;
    return year + f;
  }

  static getValueOfTimeSeries(
    timeSeries,
    year?: number,
    yrFrac?: number,
    scaled: boolean = false
  ) {
    const rawTS = timeSeries.rawTS[0]._.split(",");
    const dataPerYrTS = +timeSeries.dataPerYrTS;
    const yr0TS = +timeSeries.yr0TS;
    const multTS = +timeSeries.multTS;
    const nYrsTS = +timeSeries.nYrsTS;

    //Find index during year
    let ixDuringYr: number;
    if (dataPerYrTS == 1) {
      ixDuringYr = 0;
    } else {
      ixDuringYr = Math.floor(yrFrac * dataPerYrTS + Constants.k0Plus);
      if (ixDuringYr >= dataPerYrTS) {
        ixDuringYr = dataPerYrTS - 1;
      }
    }
    // Move year into raw data range
    let yrRaw: number = year - yr0TS;
    switch (timeSeries.tExtrapTS) {
      case "NearestYr":
        if (yrRaw < 0) yrRaw = 0;
        else if (yrRaw >= nYrsTS) yrRaw = nYrsTS - 1;
        break;
      case "CycleYrs":
        if (yrRaw < 0) {
          const mod: number = -yrRaw % nYrsTS;
          yrRaw = mod == 0 ? 0 : nYrsTS - mod;
        } else if (yrRaw >= nYrsTS) yrRaw = yrRaw % nYrsTS;
        break;
      case "AvgYr":
        if (yrRaw < 0 || yrRaw >= nYrsTS) {
          let sum = 0.0;
          let k = ixDuringYr;
          for (let i = nYrsTS - 1; i >= 0; --i) {
            if (rawTS[k]) {
              sum += +rawTS[k];
            }

            k += dataPerYrTS;
          }
          return (sum / nYrsTS) * (scaled ? multTS : 1);
        }
        break;
    }
    // Finish - Other extrapolations
    const ix = yrRaw * dataPerYrTS + ixDuringYr;
    return +rawTS[ix] * (scaled ? +multTS : 1);
  }

  static getFirstDayOfYear(year): Date {
    return new Date(year, 0, 1);
  }

  static getNumbersOfLeapYearDaysBetweenDates(
    startDate: Date,
    endDate: Date
  ): number {
    let numDays = 0;
    const startYear = moment(startDate).year();
    const endYear = moment(endDate).year();
    const numYears = endYear - startYear;

    if (endYear < startYear) {
      return;
    }

    [...new Array(numYears)].forEach((_, index) => {
      let year = startYear + index;
      if (this.isLeapYear(year)) {
        numDays++;
      }
    });

    return numDays;
  }

  static getNumberDateDaysToStartOfYear(year: number): number {
    let n = 0;
    // Shift years for negative days up to positive area
    if (year < 1900) {
      n = 0;
      while (year < 1900) {
        year += 400;
        n -= nmberOfDaysIn400Years;
      }
      return n + TimeUtilities.getNumberDateDaysToStartOfYear(year);
    }

    n = (year - 1900) * 365 + 1; // If all years had 365 days
    n += (year - 1900 + 3) >> 2; // Every   4th year is  leap
    n -= (year - 1900 + 99) / 100; // Every 100th year not leap
    n += (year - 1900 + 299) / 400; // Every 400th year is  leap
    return n;
  }

  //time span

  //   bool TimeSpan::FromStr(const char * str, TimeSpan& result )
  // {
  //     int num = 0;
  //     char type[30];
  //     int fnd = sscanf(str, "%d %s", &num, &type);
  //     if (fnd <= 1)
  //     {
  //         result = TimeSpan(TicksPerDay * kiDaysPerYr * num);
  //         result.type = TimeSpanType::Years;
  //         return true;
  //     }
  //     strlwr(type);
  //     i64 span;
  //     switch (*type)
  //     {
  //     case 'y':
  //     {
  //         span = TicksPerDay * kiDaysPerYr * num;
  //         result.type = TimeSpanType::Years;
  //     }   break;
  //     case 'd':
  //     {
  //         span = TicksPerDay * num;
  //         result.type = TimeSpanType::Days;
  //     }   break;
  //     default:
  //         return false;
  //     }
  //     result = TimeSpan(span);
  //     return true;
  // }

  // static geTimeSpan( start,  end)
  //     // :_value((start-end).GetTicks())
  // {

  //   const _value = (this.dateToTicks(start) - this.dateToTicks(end))

  //     if (_value != 0)
  //     {
  //          let startyear, endyear;

  //         if (start > end)
  //         {
  //             endyear = start;
  //             startyear = end;
  //         }
  //         else
  //         {
  //             endyear = end;
  //             startyear = start;
  //         }
  //         let leapDaysCount = 0;
  //         bool startLeap = IsLeapYear(startyear.GetYear());
  //         bool endleap  = IsLeapYear(endyear.GetYear());

  //         int istartyear = startyear.GetYear();
  //         int iendYear = endyear.GetYear();

  //         if (startLeap && startyear.GetDayOfYear() <= 59)
  //             istartyear--;

  //         if (endleap && endyear.GetDayOfYear() > 59)
  //             iendYear++;

  //         for (int i = (istartyear+1); i < iendYear; i++)
  //         {
  //             if (IsLeapYear(i))
  //                 leapDaysCount++;
  //         }

  //         if (_value < 0)
  //             _value += (leapDaysCount*TicksPerDay);
  //         else
  //             _value -= (leapDaysCount*TicksPerDay);
  //     }

  // }

  // const char * TimeSpan::ToString()
  // {
  //     static char buf[30];
  //     flo yrs = GetYearsFrac();
  //     if (yrs < 1.0)
  //     {
  //         if (GetDays() == 0)
  //             buf[0] = 0;
  //         else
  //             sprintf(buf, "%d days", GetDays());
  //     }
  //     else
  //         sprintf(buf, "%d years", (int)ceil(yrs));
  //     return buf;
  // }

  static convertYYYYMMDDToDateFormat(dateStr) {
    // Check if the year is negative (BCE)
    const isBCE = dateStr.startsWith("-");

    if (!isBCE) {
      return moment(dateStr, "YYYYMMDD").toDate();
    }

    const year = dateStr.substring(0, 4);

    const restOfDate = dateStr.substring(4);

    const momentDate = moment(restOfDate, "MMDD");

    const month = momentDate.format("MM");
    const day = momentDate.format("DD");

    const baseMomentDate = moment(`0000${month}${day}`, "YYYYMMDD");

    baseMomentDate.add(+year, "year");

    return baseMomentDate.toDate();
  }

  static convertDateToYYYYMMDDFormat(d: Date) {
    const momentDate = moment(d);

    let year = momentDate.year();
    const month = momentDate.format("MM");
    const day = momentDate.format("DD");

    let formattedDate;

    if (year < 0) {
      year = Math.abs(year);
      const yearStr = year.toString().padStart(3, "0");
      formattedDate = `-${yearStr}${month}${day}`;
    } else {
      formattedDate = momentDate.format("YYYYMMDD");
    }

    return formattedDate;
  }
}
