import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class AppInsightsService {
  private appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsightsKey,
      },
    });
    this.appInsights.loadAppInsights();
  }

  public logPageView(data: { name: string; url: string }): void {
    this.appInsights.trackPageView(data);
  }

  public logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }
}
