import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "[fc-rmt-modifiers]",
  templateUrl: "./rmt-modifiers.component.html",
  styleUrls: ["./rmt-modifiers.component.scss"],
})
export class RmtModifiersComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  @Input()
  public data: any[] = [];

  @Input()
  public simulationService: any;

  @Input()
  public service: any;

  @Input()
  public formGroupInstance: UntypedFormGroup;

  constructor() {}
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
