import { Injectable } from "@angular/core";
import { Flows, PlotFlow } from "src/app/core/models";
import { RmtProjectService } from "../../rmt-project/services/rmt-project.service";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";
import { RmtResultsService } from "../../rmt-results/services/rmt-results.service";
import { RmtProjectComponent } from "../../rmt-project/rmt-project.component";
import { RmtManagementComponent } from "../../rmt-management/rmt-management.component";
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  concatMap,
  delay,
  finalize,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  throwError,
} from "rxjs";
import { AppService } from "src/app/services/app.service";
import { GoogleAnalyticsService } from "src/app/shared/services/google-analytics.service";
import { AppInsightsService } from "src/app/shared/services/app-insights.service";
import { DbService } from "src/app/shared/services/db.service";
import { RmtResultsComponent } from "../../rmt-results/rmt-results.component";
import { MessageService } from "src/app/shared/services/message.service";
import { HttpEventType, HttpResponseBase } from "@angular/common/http";
import Utilities from "src/app/shared/utilities/utils";
import { HelperService } from "src/app/shared/services/helper.service";
import { ModalService } from "src/app/shared/services/modal.service";
import {
  CustomApiResponse,
  IFileUploadForm,
  IFileUploadRequest,
  UploadProgressCount,
} from "src/app/shared/models";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import XMLUtilities from "../../utils/xml-utiles";
import { Router } from "@angular/router";
import { PlotTableColumns } from "src/app/my-plots/models";

const testXML = ``;
const xmlTemplate = {
  ReforestationModelDocument: {
    $: {
      "xmlns:xsd": "http://www.w3.org/2001/XMLSchema",
      "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
    },
    Version: "1.0.16.664",
    SaveDate: "",
    ServerVersion: "1.0.16.813",
    CatalogHash: "E16B364CEF5F99CE43A20C59A793854B833402B1",
    Encrypted: "false",
    NCATStand: {
      ProjectInformation: {},
      Management: {},
      Trees: {},
      RegimesList: {},
      SpeciesList: {},
      SiteInfo: {},
    },
  },
};

@Injectable({
  providedIn: "root",
})
export class RmtSimulationService {
  public readonly version = "rmt";
  public readonly docType = "ReforestationModelDocument";

  //used by shared component
  public selectedPlotFormat$ = new BehaviorSubject<string>("rmt");

  public isLoading: boolean = false;

  public readonly allFlows: Flows = {
    project: {
      id: "project",
      label: "Project",
      service: this.projectService,
      component: RmtProjectComponent,
      isInvalid: () => this.projectService.isInvalid(),
      isShown: true,
      type: "mainFlow",
    },
    management: {
      id: "management",
      label: "Management",
      service: this.managementService,
      component: RmtManagementComponent,
      isInvalid: () => this.managementService.isInvalid(),
      isDisabled: () => this.projectService.isInvalid(),
      isShown: true,
      type: "mainFlow",
    },
    results: {
      id: "results",
      label: "Results",
      service: this.resultsService,
      component: RmtResultsComponent,
      isInvalid: () => this.resultsService.isInvalid(),
      isDisabled: () =>
        this.projectService.isInvalid() || this.managementService.isInvalid(),
      isShown: true,
      type: "mainFlow",
    },
  };

  private readonly selectedFlow$ = new BehaviorSubject<PlotFlow>(null);

  private readonly currentFlowsSource$ = new BehaviorSubject<PlotFlow[]>(null);

  // public currentFlows$: Observable<Array<PlotFlow>> =
  //   this.currentFlowsSource$.asObservable();

  public importedRMDFileJson: Object = null;

  public isClonedRMDFile: boolean = false;

  public rmdMetaData: PlotTableColumns = null;

  //for file upload
  public readonly uploadingFiles$ = new BehaviorSubject<
    FormArray<AbstractControl<IFileUploadForm>>
  >(new FormArray([]));
  private cancelUploadsSubject = new Subject<void>();

  public isUploading$ = new BehaviorSubject<boolean>(false);
  public numUploadedFiles$ = new BehaviorSubject<number>(0);
  public uploadProgressCount$ = new BehaviorSubject<UploadProgressCount>({
    success: 0,
    fail: 0,
  });

  constructor(
    private appService: AppService,
    private gaService: GoogleAnalyticsService,
    private appInsights: AppInsightsService,
    public projectService: RmtProjectService,
    public managementService: RmtManagementService,
    public resultsService: RmtResultsService,
    public dbService: DbService,
    public messageService: MessageService,
    public modalService: ModalService,
    public helperService: HelperService,
    public router: Router
  ) {}

  public setUp(): void {
    Object.values(this.allFlows).forEach((f) => {
      f.service.setSimulationService(this);
    });
    //remove beflow when deploying to TEST
    //  this.projectService.getSiteInfo();
    //  this.projectService.getSiteInfo();
    // this.resultsService.simulate();
    // this.http
    //   .get("assets/Fake Stand.rmd", { responseType: "text" })
    //   .subscribe((xmlString) => {
    //     Utilities.convertXmlToJson(xmlString).then((standJson) => {
    //       console.log(standJson);
    //     });
    //   });
    // // this.writeXmlFile();
  }

  public isEditRMD(): boolean {
    return this.importedRMDFileJson !== null && this.isClonedRMDFile == false;
  }

  public setRMDMetaData(data: PlotTableColumns): void {
    this.rmdMetaData = data;
  }

  public getFlows(): PlotFlow[] {
    return Object.values(this.allFlows);
  }

  public setSelectedFlow(flow: PlotFlow) {
    this.selectedFlow$.next(flow);
    try {
      this.appService.setPageTitle(
        this.appService.getPageTitlePrefix() + flow.label
      );
      const pageTitle = this.appService.getPageTitlePrefix() + flow.label;

      this.gaService.sendEvent({
        name: "page_view",
        category: "fcm-page-title",
        label: pageTitle,
      });

      this.appInsights.logPageView({
        name: pageTitle,
        url: "/" + flow.id,
      });
    } catch (error) {
      console.error("Unable to track sub-pages with Google Analytics.");
    }
  }
  public getSelectedFlow(): PlotFlow {
    return this.selectedFlow$.getValue();
  }

  public getSelectedFlow$(): Observable<PlotFlow> {
    return this.selectedFlow$;
  }

  //base functions
  public getBasePageTitle() {
    return `${this.isEditRMD() ? "Update" : "Create a new"} RMD
    `;
  }

  public getStartDate(): Date {
    return this.managementService.getStartDate();
  }

  public getEndDate(): Date {
    return this.managementService.getEndDate();
  }

  public getFileName(): string {
    return this.projectService.getFormGroup().get("StandName").value;
  }

  public async writeXmlFile() {
    const rmdJson = this.getRMDJson();
    const rmdXml = await Utilities.convertJsonToXmlString(rmdJson);
    const fileName = this.projectService.getFileName() || "New RMT";
    this.helperService.downloadFile(fileName + ".rmd", rmdXml);
    return this.helperService.createFile(
      fileName + ".rmd",
      rmdXml,
      "application/xml"
    );
  }

  public async simulate() {
    this.isLoading = true;
    try {
      const flle = await this.writeXmlFile();

      this.dbService
        .runRMTSimulation(flle)
        .pipe(
          take(1),
          finalize(() => (this.isLoading = false))
        )
        .subscribe((response) => {
          this.resultsService.setResult(response.data);
        });
    } catch (error) {
      this.isLoading = false;
    }
  }
  private readXmlObject() {
    this.projectService.readXmlObject();
    this.managementService.readXmlObject();

    this.managementService.rotationService.checkRotationEvents();
  }

  private getRMDJson() {
    let baseTemplate = xmlTemplate;

    let ncatStand = baseTemplate.ReforestationModelDocument.NCATStand;

    ncatStand.Management = this.managementService.writeXmlObject();

    ncatStand.ProjectInformation = this.projectService.writeXmlObject();
    ncatStand.RegimesList = this.managementService.writeRegimeListXmlObject();

    ncatStand.SiteInfo = this.projectService.writeSiteInfoXmlObject();
    ncatStand.SpeciesList = this.managementService.writeSpeciesListXmlObject();
    ncatStand.Trees = this.managementService.writeTreeListXmlObject();
    baseTemplate.ReforestationModelDocument.SaveDate = new Date().toISOString();

    return baseTemplate;
  }

  public async saveRMDFile() {
    const latitude =
      this.projectService.getFormGroup().get("Latitude").value || null;
    const longitude =
      this.projectService.getFormGroup().get("Longitude").value || null;

    const project = this.projectService.getFormGroup().get("project").value;
    const collection = this.projectService
      .getFormGroup()
      .get("collection").value;
    let status: "Valid" | "Invalid" = "Valid"; //save only allowed when project and management is valid

    let fileName = this.projectService.getFileName() || "New RMT";
    const fileExtension = "rmd";

    const rmdFileJson = this.getRMDJson();
    const rmdXml = await Utilities.convertJsonToXmlString(rmdFileJson);
    const file = this.helperService.convertXmlStringToFileObject(
      rmdXml,
      fileName + "." + fileExtension
    );

    let data: IFileUploadRequest = {
      fileType: fileExtension,
      project,
      collection,
      file,
      latitude,
      longitude,
      status,
    };

    this.isUploading$.next(true);
    //check if the plot file is downloaded from server
    if (this.rmdMetaData && !this.isClonedRMDFile) {
      data.plotId = this.rmdMetaData.plotId;

      return this.dbService
        .updatePlotFile(data, true)
        .pipe(
          take(1),
          catchError((error) => {
            this.isUploading$.next(false);
            return throwError(() => error);
          })
        )
        .subscribe(async (response: CustomApiResponse<any>) => {
          this.isUploading$.next(false);
          if (!response) {
            this.messageService.addAlert({
              type: "warning",
              msg: "Something went wrong with the reponse result.",
              dismissible: true,
            });

            return;
          }
          this.processRMDfileAfterSaved(rmdFileJson, response.data);

          this.messageService.addAlert({
            type: "success",
            msg: `RMD file has been updated successfully.`,
            timeout: 5000,
            dismissible: true,
          });
        });
    } else {
      this.dbService
        .uploadPlotFile(data, false, true)
        .pipe(
          take(1),
          catchError((error) => {
            this.isUploading$.next(false);
            return throwError(() => error);
          })
        )
        .subscribe(async (response: any) => {
          this.isUploading$.next(false);
          if (!response) {
            this.messageService.addAlert({
              type: "warning",
              msg: "Something went wrong with the reponse result.",
              dismissible: true,
            });

            return;
          }

          this.processRMDfileAfterSaved(rmdFileJson, response.data);
          this.messageService.addAlert({
            type: "success",
            msg: `RMD has been saved successfully.`,
            timeout: 5000,
            dismissible: true,
          });
        });
    }
  }

  protected async processRMDfileAfterSaved(rmdFileJson, response) {
    this.setRMDMetaData(response);
    this.importedRMDFileJson = rmdFileJson;
    //reset isClone file after saved
    this.isClonedRMDFile = false;

    this.resetFormStatusToPristineAfterSaved();
  }

  public resetFormStatusToPristineAfterSaved(): void {
    this.getFlows().forEach((flow) => {
      if (flow.service?.formGroup) {
        const formGroup: FormGroup = flow.service?.formGroup;
        formGroup.markAsPristine();
      }
    });
  }

  /*******************rmd file upload********************/
  //keep the same name from the plot upload component
  public uploadPlotFiles(filesFormArray: FormArray) {
    this.resetFileUpload();
    this.uploadingFiles$.next(filesFormArray);
    this.isUploading$.next(true);

    try {
      this.gaService.sendEvent({
        name: "click",
        category: "file-upload",
        label: `number of files: ${filesFormArray.controls?.length}, version: ${this.version}`,
      });
    } catch (error) {
      console.error(error);
    }

    from(filesFormArray.controls)
      .pipe(
        delay(1000), // Delay of 1 second
        concatMap((fileForm: AbstractControl) =>
          this.checkRMDEncryption(fileForm.get("file").value).pipe(
            switchMap((updatedPlotJson) => {
              if (updatedPlotJson) {
                //update lat lng after decrypted
                if (
                  fileForm.get("latitude").value == 0 &&
                  fileForm.get("longitude").value == 0
                ) {
                  if (
                    updatedPlotJson[this.docType].NCATStand?.ProjectInformation
                      ?.Latitude
                  ) {
                    fileForm
                      .get("latitude")
                      .setValue(
                        updatedPlotJson[this.docType].NCATStand
                          .ProjectInformation.Latitude
                      );
                    fileForm
                      .get("longitude")
                      .setValue(
                        updatedPlotJson[this.docType].NCATStand
                          .ProjectInformation.Longitude
                      );
                  }
                }

                const xml =
                  this.helperService.convertJsonToXmlString(updatedPlotJson);
                const updatedFile =
                  this.helperService.convertXmlStringToFileObject(
                    xml,
                    fileForm.get("file").value.name
                  );

                fileForm.get("file").setValue(updatedFile);
              }

              return this.dbService
                .uploadPlotFile(fileForm.getRawValue(), true, true)
                .pipe(
                  map((event) => ({ event, fileForm })),
                  catchError((error) => of({ error, fileForm }))
                );
              // this.setProgress("skipped", fileForm);
              // // If the version is not correct, skip the upload by returning an Observable that completes without emitting
              // return of(null).pipe(map(() => ({ skipped: true, fileForm })));
            })
          )
        ),

        takeUntil(this.cancelUploadsSubject)
      )
      .subscribe({
        next: (data) => {
          const fileForm = data.fileForm;

          if ("event" in data) {
            // Handle the case where the 'event' property exists
            const event = data.event;
            if (event.type === HttpEventType.UploadProgress) {
              const percentageLoaded = Math.round(
                (event.loaded / event.total) * 100
              );
              fileForm.get("progress").setValue(String(percentageLoaded));
            } else if (event instanceof HttpResponseBase) {
              this.setProgress("completed", fileForm);
            }
          } else if ("error" in data) {
            // Handle the error case
            console.error("Error during file upload:", data.error);
            this.setProgress("fail", fileForm);
          }
        },
        error: (error) => {
          // This block will now only be used for non-recoverable errors
          console.error("Unrecoverable error during file upload:", error);
        },
      });
  }

  public processImportedRMDFile(plotFileJson: any): void {
    try {
      this.importedRMDFileJson = plotFileJson[this.docType];
      this.readXmlObject();
      this.redirectToRMDEntry();
    } catch (error) {
      console.error(error);
      this.messageService.addAlert({
        type: "danger",
        msg: "Unable to read plot file",
        dismissible: true,
      });
    }
  }

  public redirectToRMDEntry() {
    if (this.importedRMDFileJson) {
      this.router.navigate(["/rmt/simulation"]);
    }
  }

  public checkRMDEncryption(inputFile: File): Observable<any> {
    return from(XMLUtilities.readRMDFile(inputFile)).pipe(
      catchError((error) => {
        this.messageService.addAlert({
          type: "danger",
          msg: "Error reading file",
        });
        return of(null);
      }),
      switchMap((rmdFile) => {
        if (rmdFile === null) return of(null);
        return from(XMLUtilities.convertXmlToJson(rmdFile));
      }),
      switchMap((rmdFileJson: any) => {
        if (rmdFileJson === null) {
          return of(null);
        }
        if (rmdFileJson[this.docType]?.Encrypted == "true") {
          return from(this.dbService.decryptRMDFile(inputFile)).pipe(
            catchError((error) => {
              this.messageService.addAlert({
                type: "danger",
                msg: "Error decrypting file.",
              });
              return of(null);
            }),
            mergeMap((decryptResponse) => {
              if (decryptResponse && decryptResponse.data) {
                return from(
                  XMLUtilities.convertXmlToJson(decryptResponse.data)
                );
              }
              return of(null);
            })
          );
        }
        return of(rmdFileJson);
      })
    );
  }

  //keep the same name from the plot upload component
  public loadPlotFiles(files: FileList) {
    if (files.length) {
      const context = this;
      (async function () {
        const existingFiles = context.uploadingFiles$.getValue();
        const filesFormArray = existingFiles.length
          ? existingFiles
          : new FormArray([]);

        for await (const file of Array.from(files) as File[]) {
          const rmdFile = await XMLUtilities.readRMDFile(file).catch(
            (alertMessage) => {
              context.messageService.addAlert(alertMessage);
            }
          );

          if (!rmdFile) {
            context.messageService.addAlert({
              type: "danger",
              msg: "Invalid rmd file, unable to read the rmd file.",
              dismissible: true,
            });
            continue;
          }

          let latitude, longitude;

          const rmdFileJson = await XMLUtilities.convertXmlToJson(rmdFile);
          console.log(rmdFileJson, "RMD FILE JSON");
          if (!rmdFileJson) {
            context.messageService.addAlert({
              type: "danger",
              msg: "Invalid plot file, unable to read the rmd file.",
              dismissible: true,
            });
            continue;
          }

          const json = rmdFileJson[context.docType];

          latitude =
            json?.NCATStand && json.NCATStand?.ProjectInformation?.Latitude;
          longitude =
            json?.NCATStand && json.NCATStand?.ProjectInformation?.Longitude;

          const fileData: IFileUploadRequest = {
            fileType: "rmd",
            file: file,
            project: null,
            collection: null,
            latitude: latitude || 0,
            longitude: longitude || 0,
          };

          filesFormArray.push(context.getFileFormGroup(fileData));
        }
        context.setUploadingFiles(filesFormArray);
      })();
    }
  }

  public getFileFormGroup(formData: IFileUploadRequest): AbstractControl {
    return new FormGroup({
      fileType: new FormControl(formData.fileType, [Validators.required]),
      file: new FormControl(formData.file, [Validators.required]),
      project: new FormControl(formData.project, [Validators.required]),
      collection: new FormControl(formData.collection, [Validators.required]),
      latitude: new FormControl(formData.latitude),
      longitude: new FormControl(formData.longitude),
      progress: new FormControl("", []),
      subscription: new FormControl(null, []),
    });
  }

  public setUploadingFiles(filesFormArray: FormArray): void {
    this.uploadingFiles$.next(filesFormArray);
  }

  public cancelAllUploads(): void {
    this.cancelUploadsSubject.next();
    this.uploadingFiles$.getValue().controls.forEach((fg) => {
      const progress = fg.get("progress").value;

      if (!Number.isNaN(+progress)) {
        fg.get("progress").setValue("cancelled");
      }
    });
    this.isUploading$.next(false);
    this.checkUploadProgress();
  }

  public deleteFile(row): void {
    const file = row.file;
    const index = this.uploadingFiles$
      .getValue()
      .controls.findIndex((formGroup) => {
        const f = formGroup.get("file").value;
        return file === f;
      });

    if (row.subscription) {
      row.subscription.unsubscribe();
    }
    this.uploadingFiles$.getValue().removeAt(index);
    if (this.isUploading$.getValue()) {
      this.numUploadedFiles$.next(this.numUploadedFiles$.getValue() + 1);
      this.checkUploadProgress();
    }
  }

  protected setProgress(status: "completed" | "fail" | "skipped", fileForm) {
    fileForm.get("progress").setValue(status);
    this.uploadProgressCount$.next({
      ...this.uploadProgressCount$.getValue(),
      ...(["fail", "skipped"].includes(status) && {
        fail: (this.uploadProgressCount$.getValue().fail += 1),
      }),
      ...(status == "completed" && {
        success: (this.uploadProgressCount$.getValue().success += 1),
      }),
    });
    this.numUploadedFiles$.next(this.numUploadedFiles$.getValue() + 1);
    this.checkUploadProgress();
  }
  protected checkUploadProgress() {
    if (
      this.numUploadedFiles$.getValue() >=
      this.uploadingFiles$.getValue().length
    ) {
      this.isUploading$.next(false);
      this.numUploadedFiles$.next(0);
    }
  }

  public isDirtyForm(): boolean {
    return this.getFlows().some((f) => {
      if (f.service?.formGroup?.dirty) {
        return true;
      }
    });
  }

  public resetFileUpload() {
    this.isUploading$.next(false);
    this.cancelUploadsSubject.next();
    this.numUploadedFiles$.next(0);
    this.uploadingFiles$.next(new FormArray([]));
    this.uploadProgressCount$.next({
      success: 0,
      fail: 0,
    });
  }

  /******************************************************/

  public reset() {
    Object.values(this.allFlows).forEach((f) => {
      f.service.reset();
    });

    this.importedRMDFileJson = null;

    this.isClonedRMDFile = false;
    this.rmdMetaData = null;

    this.selectedFlow$.next(null);
    this.currentFlowsSource$.next(null);
    this.resetFileUpload();
  }
}
