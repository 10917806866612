import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ExplorerService } from "src/app/shared/models";
import {
  ExplorerCatetory,
  ExplorerControl,
  SubcategoryMap,
} from "src/app/simulation/models";

@Component({
  selector: "fc-explorer-category-list",
  templateUrl: "./explorer-category-list.component.html",
  styleUrls: ["./explorer-category-list.component.scss"],
})
export class ExplorerCategoryListComponent implements OnInit {
  @Input() service: ExplorerService;
  @Input() digestFormGroup: FormGroup;

  @Input() public categoryList: ExplorerCatetory[];

  @Output() public categoryClicked = new EventEmitter<{
    categoryKey?: string;
    controlLabel?: string;
    subcategoryMap: SubcategoryMap;
    category: string;
  }>();

  private readonly destroy$ = new Subject<void>();

  public expandedStateMap: {
    [key: string]: boolean | { [key: string]: boolean };
  };

  constructor() {}

  ngOnInit(): void {
    this.expandedStateMap = this.service.expandedStateMap;
    if (this.digestFormGroup) {
      this.digestFormGroup
        .get("digestScenarios")
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          const digestCategory = this.categoryList.find(
            (c) => c.id == "plotDigest"
          );
          digestCategory.subcategoryMap =
            digestCategory.service.getExplorerSubcategoryMap(digestCategory);
        });
    }

    //set expaned state
    this.categoryList.forEach((category) => {
      if (this.service.expandedStateMap[category.label]) {
        const subcategoryMap =
          category.service.getExplorerSubcategoryMap(category);
        setTimeout(() => {
          category.subcategoryMap = subcategoryMap;
        }, 0);
      }
    });

    setTimeout(() => {
      this.setSelectedControls();
    }, 0);
  }

  protected setSelectedControls() {
    //set updated controls
    const categoryId = this.service.selectedCategoryId$.value;
    const selectedCateogyKey = this.service.selectedCategoryKey$.value;
    if (categoryId && selectedCateogyKey) {
      const selectedCategory = this.categoryList.find(
        (c) => c.id == categoryId
      );
      if (selectedCategory) {
        if (selectedCateogyKey.controlLabel) {
          const foundControl = this.getCategoryControl(
            selectedCategory.subcategoryMap,
            selectedCateogyKey.controlLabel
          );
          if (foundControl) {
            this.showSingleControl(foundControl, null, selectedCategory);
          }
          return;
        } else if (selectedCateogyKey.categoryKey) {
          if (!Object.keys(selectedCategory?.subcategoryMap || {}).length) {
            const subcategoryMap =
              selectedCategory.service.getExplorerSubcategoryMap(
                selectedCategory
              );
            selectedCategory.subcategoryMap = subcategoryMap;
          }
          const foundObject = this.getCategoryMapByKey(
            selectedCategory.subcategoryMap,
            selectedCateogyKey.categoryKey
          );
          if (foundObject) {
            this.categoryClicked.emit({
              subcategoryMap: foundObject,
              category: selectedCategory.id,
              categoryKey: selectedCateogyKey.categoryKey,
            });
          }
          return;
        }
      }
    }
  }

  protected getCategoryControl(
    categoryMap: SubcategoryMap,
    controlLabel: string
  ): ExplorerControl {
    for (const key in categoryMap) {
      if (categoryMap.hasOwnProperty(key)) {
        const value = categoryMap[key];
        if (Array.isArray(value) && key == "controls") {
          for (const control of value) {
            if (control.label == controlLabel) {
              return control;
            }
          }
        }

        if (typeof value == "object" && value !== null) {
          const control = this.getCategoryControl(value, controlLabel);
          if (control) {
            return control;
          }
        }
      }
    }
    return null;
  }

  protected getCategoryMapByKey(
    categoryMap: SubcategoryMap,
    targetKey: string
  ): SubcategoryMap {
    for (const key in categoryMap) {
      if (targetKey == "controls") {
        return categoryMap;
      }
      if (categoryMap[key]) {
        const value: any = categoryMap[key];
        if (value.controls && key == targetKey) {
          return value;
        }

        if (!value.controls) {
          const foundObject = this.getCategoryMapByKey(value, targetKey);
          if (foundObject) {
            return foundObject;
          }
        }
      }
    }
    return null;
  }

  public toggleCategory(category: ExplorerCatetory, isExpanded: boolean): void {
    this.expandedStateMap[category.label] = !isExpanded;
    // if (category.id == "plotDigest") {
    //   this.setDigestCategoryMap(category);
    //   return;
    // }

    if (!isExpanded && !Object.keys(category.subcategoryMap || {})?.length) {
      const subcategoryMap =
        category.service.getExplorerSubcategoryMap(category);
      category.subcategoryMap = subcategoryMap;
    }
  }

  public toggleSubcategory(path: string): void {
    const isExpanded = this.expandedStateMap[path];
    this.expandedStateMap[path] = isExpanded === undefined ? true : !isExpanded;
  }

  public showControls(
    categoryKey: string,
    subcategoryMap: SubcategoryMap,
    event,
    category?
  ) {
    event?.preventDefault();

    //Cateogry children will be loaded when expand button click
    //it's possible user will click on the link to show controls without clicking expand button first
    if (!Object.keys(subcategoryMap || {}).length) {
      const newSubcategoryMap =
        category.service.getExplorerSubcategoryMap(category);

      if (!newSubcategoryMap) {
        return;
      }
      category.subcategoryMap = newSubcategoryMap;
      this.categoryClicked.emit({
        categoryKey: categoryKey,
        subcategoryMap: category.subcategoryMap,
        category: category.id,
      });
      return;
    }

    this.categoryClicked.emit({
      subcategoryMap:
        categoryKey == "controls"
          ? subcategoryMap
          : subcategoryMap[categoryKey],
      category: category.id,
      categoryKey: categoryKey,
    });
  }

  public showSingleControl(control: ExplorerControl, event, category) {
    event?.preventDefault();

    this.categoryClicked.emit({
      controlLabel: control.label,
      subcategoryMap: { controls: [control] },
      category: category.id,
    });
  }

  public shouldShowCateogy(category: ExplorerCatetory): boolean {
    if (category.id == "trees" || category.id == "crops") {
      const speciesService =
        this.service.simulationService[
          category.id == "trees" ? "treesService" : "cropsService"
        ];
      const species = speciesService.getFormGroup().get("species")?.value;
      return species?.length;
    }

    if (category.id == "events") {
      const eventsService = this.service.simulationService.eventsService;
      return eventsService.getFormGroup()?.get("eventQ").value?.length;
    }

    if (category.id == "plotDigest") {
      return this.digestFormGroup?.get("digestScenarios").value?.length;
    }

    return true;
  }

  public customSort(parentPath, subcategoryMap) {
    return (a, b) => {
      //event Events/Acacia Forest and Woodlands [1]
      const path = parentPath.split("/");
      if (path[0] !== "Events") {
        return;
      }

      //second level
      if (a?.values?.contorls) {
        const eventDateA = a.values.contorls.find(
          (c) => c.programmingName == "dateEV"
        )?.value;
        const eventDateB = b.values.contorls.find(
          (c) => c.programmingName == "dateEV"
        )?.value;
        return eventDateA - eventDateB;
      }
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
