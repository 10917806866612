import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormArray, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import {
  FieldWatcher,
  FieldWatcherContext,
  FormModel,
  TreeSpecies,
} from "src/app/shared/models";
import { FormGroupElement, FormLayout } from "../../models";
import { TableComponentData } from "src/app/shared/components/table/table.component";
import { BaseSpeciesService } from "src/app/shared/services/base-species.service";
import { Constants } from "src/app/shared/constants";

@Injectable({
  providedIn: "root",
})
export class Trees2020Service extends BaseSpeciesService {
  protected writeXmlBaseFields = [
    "idSP",
    "nmSP",
    "grthModeSP",
    "tAgeIxSP",
    "curEdit",
    "idRegimeSP",
    "mvgTreeId",
    "pltnType",
    "tSpecFrCat",
    "CFracStemF",
    "CFracBranF",
    "CFracBarkF",
    "CFracLeafF",
    "CFracCortF",
    "CFracFirtF",
    "turnFracBranF",
    "turnFracBarkF",
    "turnFracLeafF",
    "turnFracCortF",
    "turnFracFirtF",
    "ratioBranMortF",
    "ratioBarkMortF",
    "ratioLeafMortF",
    "ratioCortMortF",
    "ratioFirtMortF",
    "rFracStemF",
    "rFracBranF",
    "rFracBarkF",
    "rFracLeafF",
    "rFracCortF",
    "rFracFirtF",
    "bkdnFracSDdwdF",
    "bkdnFracSChwdF",
    "bkdnFracSBlitF",
    "bkdnFracSLlitF",
    "bkdnFracSCodrF",
    "bkdnFracSFidrF",
    "atmsFracSDdwdBkdnF",
    "atmsFracSChwdBkdnF",
    "atmsFracSBlitBkdnF",
    "atmsFracSLlitBkdnF",
    "atmsFracSCodrBkdnF",
    "atmsFracSFidrBkdnF",
    "bkdnFracDDdwdF",
    "bkdnFracRDdwdF",
    "bkdnFracDChwdF",
    "bkdnFracRChwdF",
    "bkdnFracDBlitF",
    "bkdnFracRBlitF",
    "bkdnFracDLlitF",
    "bkdnFracRLlitF",
    "bkdnFracDCodrF",
    "bkdnFracRCodrF",
    "bkdnFracDFidrF",
    "bkdnFracRFidrF",
    "atmsFracDDdwdBkdnF",
    "atmsFracRDdwdBkdnF",
    "atmsFracDChwdBkdnF",
    "atmsFracRChwdBkdnF",
    "atmsFracDBlitBkdnF",
    "atmsFracRBlitBkdnF",
    "atmsFracDLlitBkdnF",
    "atmsFracRLlitBkdnF",
    "atmsFracDCodrBkdnF",
    "atmsFracRCodrBkdnF",
    "atmsFracDFidrBkdnF",
    "atmsFracRFidrBkdnF",
    "bkdnSensMulcF",
    "bkdnSensSoilF",
    "bkdnSensTempF",
    "bkdnSensRainF",
  ];

  protected writeXmlProductsFields = [
    "pdcmFrac",
    "enerFrac",
    "fillFrac",
    "ldcmFrac",
    "effcEner",
    "effcFoss",
    "cemrEner",
    "cemrFoss",
    "makeProd",
    "cemrProd",
    "makeDisp",
    "cemrDisp",
    "MDispPerProd",
    "LProdPerDisp",
    "CFracProd",
  ];
  protected writeXmlProductsFieldConcats = [
    {
      position: "append",
      value: "FuelF",
      id: "fuel",
    },
    {
      position: "append",
      value: "PaprF",
      id: "papr",
    },
    {
      position: "append",
      value: "PackF",
      id: "pack",
    },
    {
      position: "append",
      value: "FurnF",
      id: "furn",
    },
    {
      position: "append",
      value: "FibrF",
      id: "fibr",
    },
    {
      position: "append",
      value: "ConsF",
      id: "cons",
    },
    {
      position: "append",
      value: "ResiF",
      id: "resi",
    },
  ];

  protected writeXmlForestDetailsBaseFields = [
    "cultivationCF",
    "thinCF",
    "biofuelBurnCF",
    "wasteBurnCF",
    "tIncrTblF",
    "treeMortOn",
    "replaceStemsMortF",
    "useBioAgeAdjMortF",
    "remvAvgAgeMultMortF",
    "remvMaxAgeMultMortF",
    "remvOffsetMortF",
    "maxSFixNCRatioF",
    "storFracLeafTurnNF",
    "userRDebrNCRatioF",
    "absnNCRatioF",
    "maxBkdnNCRatioF",
    "ratioNPPToGPP",
    "nDaysLostPerFrost",
    "maxStemMAt1000tph",
    "ratioFoliStem2cm",
    "ratioFoliStem20cm",
    "stemMDBHMult",
    "stemMDBHPower",
    "maxFracNPPToRoot",
    "minFracNPPToRoot",
    "minTempForGrth",
    "optTempForGrth",
    "maxTempForGrth",
    "maxLitterfall",
    "age0Litterfall",
    "ageMedianLitterfall",
    "turnFracRoot3PG",
    "maxCanopyCond",
    "maxStomatalCond",
    "condCoefft",
    "canopyBoundaryCond",
    "fertFracNPPToRoot",
    "modNutrFor0Fert",
    "maxAge",
    "agePower",
    "ageMult",
    "age0SLA",
    "matureAgeSLA",
    "ageOfHalfwaySLA",
    "PARAbsorbCoefft",
    "ageMatureCanopy",
    "quantumEfficiency",
    "age0BBFrac",
    "matureAgeBBFrac",
    "ageOfHalfwayBBFrac",
    "mrFracOfRLittF",
  ];
  protected writeXmlThinDestFields = [
    "fracStemToDdwdThin",
    "fracStemToFuelThin",
    "fracStemToPaprThin",
    "fracStemToPackThin",
    "fracStemToFurnThin",
    "fracStemToFibrThin",
    "fracStemToConsThin",
    "fracStemToResiThin",
    "fracStemToSDdwdThin",
    "fracBranToDdwdThin",
    "fracBranToFuelThin",
    "fracBranToPaprThin",
    "fracBranToPackThin",
    "fracBranToFurnThin",
    "fracBranToFibrThin",
    "fracBranToConsThin",
    "fracBranToResiThin",
    "fracBranToSDdwdThin",
    "fracBarkToBlitThin",
    "fracBarkToFuelThin",
    "fracBarkToPaprThin",
    "fracBarkToResiThin",
    "fracBarkToSBlitThin",
    "fracLeafToLlitThin",
    "fracLeafToFuelThin",
    "fracLeafToSLlitThin",
    "fracCortToCodrThin",
    "fracCortToFuelThin",
    "fracCortToSCodrThin",
    "fracFirtToFidrThin",
    "fracFirtToSFidrThin",
    "fracDdwdToFuelThin",
    "fracChwdToFuelThin",
    "fracBlitToFuelThin",
    "fracLlitToFuelThin",
    "fracSDdwdToFuelThin",
    "fracSChwdToFuelThin",
    "fracSBlitToFuelThin",
    "fracSLlitToFuelThin",
  ];

  protected writeXmlVarNCDataFFields = [
    "days2F",
    "maxNC1F",
    "maxNC2F",
    "minNC1F",
  ];
  protected writeXmlVarNCDataFFieldConcats = [
    {
      position: "prepend",
      value: "stem",
      id: "stemVF",
    },
    {
      position: "prepend",
      value: "bran",
      id: "branVF",
    },
    {
      position: "prepend",
      value: "bark",
      id: "barkVF",
    },
    {
      position: "prepend",
      value: "leaf",
      id: "leafVF",
    },
    {
      position: "prepend",
      value: "cort",
      id: "cortVF",
    },
    {
      position: "prepend",
      value: "firt",
      id: "firtVF",
    },
  ];

  protected writeXmlThinInfoFields = [
    "tPR",
    "useStemDensityPR",
    "densityPR",
    "fracWaterPR",
    "harvCostPR",
    "trnsCostPR",
    "millPricePR",
    "baseYearPR",
    "incrPaHarvPR",
    "incrPaTrnsPR",
    "incrPaMillPR",
  ];
  protected writeXmlThinInfoFieldConcats = [
    {
      position: "append",
      value: "Wood",
      id: "wood",
    },
    {
      position: "append",
      value: "Bark",
      id: "bark",
    },
    {
      position: "append",
      value: "Fuel",
      id: "fuel",
    },
  ];

  protected writeXmlTyfCategoryFields = ["tTYFCat", "tyf_G", "tyf_r"];

  protected writeXmlGrowthTimeseries = [
    "stemDensity",
    "incrStemVolF",
    "incrStemMF",
    "incrAbgMF",
  ];

  protected writeXmlAllocationsTimeseriesFields = [
    "allocStemF",
    "allocBranF",
    "allocBarkF",
    "allocLeafF",
    "allocCortF",
    "allocFirtF",
  ];

  protected writeXmlBaseTimeseriesFields = [
    "stemMortF",
    "modTempTS",
    "fracRainIntercept",
  ];

  protected writeXmlInitTreeFields = [
    "treeExistsInit",
    "maxTreeAgeInit",
    "avgTreeAgeInit",
    "tInitStem",
    "stemMInitF",
    "branMInitF",
    "barkMInitF",
    "leafMInitF",
    "cortMInitF",
    "firtMInitF",
    "stemVolInitF",
    "stemFracInitF",
    "branFracInitF",
    "barkFracInitF",
    "leafFracInitF",
    "cortFracInitF",
    "firtFracInitF",
    "stemNCRatioInitF",
    "branNCRatioInitF",
    "barkNCRatioInitF",
    "leafNCRatioInitF",
    "cortNCRatioInitF",
    "firtNCRatioInitF",
    "storNMInitF",
    "stemM3PGInit",
    "foliM3PGInit",
    "rootM3PGInit",
    "nStemsInit",
    "tTYFCatInitF",
    "frFracInit",
    "tFrFracInit",
    "treeNmInit",
    "agRegimeId",
  ];

  protected writeXmlInitStandingDeadFields = [
    "sDStemCMInitF",
    "sDBranCMInitF",
    "sDBlitCMInitF",
    "sDLlitCMInitF",
    "sDCodrCMInitF",
    "sDFidrCMInitF",
  ];

  protected writeXmlInitDebrisFields = [
    "dDdwdCMInitF",
    "rDdwdCMInitF",
    "dChwdCMInitF",
    "rChwdCMInitF",
    "dBlitCMInitF",
    "rBlitCMInitF",
    "dLlitCMInitF",
    "rLlitCMInitF",
    "dCodrCMInitF",
    "rCodrCMInitF",
    "dFidrCMInitF",
    "rFidrCMInitF",
    "dDdwdNCRatioInitF",
    "dChwdNCRatioInitF",
    "dBlitNCRatioInitF",
    "dLlitNCRatioInitF",
    "dCodrNCRatioInitF",
    "dFidrNCRatioInitF",
  ];

  protected writeXmlInitProductFields = [
    "fuelCMInitF",
    "paprCMInitF",
    "packCMInitF",
    "furnCMInitF",
    "fibrCMInitF",
    "consCMInitF",
    "resiCMInitF",
    "fuelLfCMInitF",
    "paprLfCMInitF",
    "packLfCMInitF",
    "furnLfCMInitF",
    "fibrLfCMInitF",
    "consLfCMInitF",
    "resiLfCMInitF",
    "fuelNCRatioInitF",
    "paprNCRatioInitF",
    "packNCRatioInitF",
    "furnNCRatioInitF",
    "fibrNCRatioInitF",
    "consNCRatioInitF",
    "resiNCRatioInitF",
  ];

  protected writeXmlNameGradeFields = [
    "nmGradeF5",
    "nmGradeF4",
    "nmGradeF3",
    "nmGradeF2",
    "nmGradeF1",
  ];

  //*************write xml fields end**************//

  public readonly pageId = "trees";
  public readonly pageHelpLink = "201_Plants.htm";

  public readonly categoryIds = [
    13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, /*init*/ 56, 57,
    61, 99, 100, 72,
  ];

  protected growthGroups: FormGroupElement[] = [
    {
      isShown: true,
      showErrors: true,
      isUnderlineHeader: true,
      helpLink: "42_Growth%20Properties.htm",
      label:
        "Properties of the Allocations, Increments, and Other Species Time Series",
      items: [
        {
          label:
            "Type of tree age to use in tables that are expressed in years since the plants sprouted, and also in the tree yield formula",
          shoartLabel: "",
          inputs: [
            {
              element: "select",
              selectOptions: [
                {
                  value: "MaxAge",
                  label: "Maximum age of any tree in the forest",
                },
                {
                  value: "AvgAge",
                  label: "Average age of the trees in the forest",
                },
              ],
              programmingName: "tAgeIxSP",
            },
          ],
        },
        {
          id: "grthModeSP",
          label: "Allocations and increments are in terms of",
          shoartLabel: "",
          inputs: [
            {
              element: "select",
              selectOptions: [], //options are handled in form watcher
              programmingName: "grthModeSP",
            },
          ],
        },
      ],
    },

    {
      isShown: true,
      isUnderlineHeader: true,
      label: "Allocations",
      showErrors: true,
      items: [
        {
          label: "Stems",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "allocStemF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "allocStemF",
                },
                {
                  inputKey: "title",
                  value: "Stems",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "allocStemF",
                },
              ],
            },
          ],
        },
        {
          label: "Branches",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "allocBranF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "allocBranF",
                },
                {
                  inputKey: "title",
                  value: "Branches",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "allocBranF",
                },
              ],
            },
          ],
        },
        {
          label: "Bark",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "allocBarkF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "allocBarkF",
                },
                {
                  inputKey: "title",
                  value: "Bark",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "allocBarkF",
                },
              ],
            },
          ],
        },
        {
          label: "Leaves",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "allocLeafF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "allocLeafF",
                },
                {
                  inputKey: "title",
                  value: "Leaves",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "allocLeafF",
                },
              ],
            },
          ],
        },
        {
          label: "Coarse roots",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "allocCortF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "allocCortF",
                },
                {
                  inputKey: "title",
                  value: "Coarse Roots",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "allocCortF",
                },
              ],
            },
          ],
        },
        {
          label: "Fine roots",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "allocFirtF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "allocFirtF",
                },
                {
                  inputKey: "title",
                  value: "Fine Roots",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "allocFirtF",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      isShown: true,
      isUnderlineHeader: true,
      id: "increments",
      label: "Increments",
      items: [
        {
          label: "",
          inputs: [
            {
              element: "input",
              type: "radioSwitch",
              selectOptions: [
                {
                  value: "IncrStemVolF",
                  label: "Stem volumes",
                },
                {
                  value: "IncrStemMF",
                  label: "Stem masses",
                },
                {
                  value: "IncrAbgMF",
                  label: "Aboveground masses",
                },
              ],
              programmingName: "tIncrTblF",
            },
          ],
        },
        {
          label: "",
          id: "incrStemVolF",
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "incrStemVolF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "incrStemVolF",
                },
                {
                  inputKey: "title",
                  value: "Stem volumes",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "incrStemVolF",
                },
              ],
            },
          ],
        },
        {
          label: "",
          id: "incrStemMF",
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "incrStemMF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "incrStemMF",
                },
                {
                  inputKey: "title",
                  value: "Stem masses",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "incrStemMF",
                },
              ],
            },
          ],
        },
        {
          label: "",
          id: "incrAbgMF",
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "incrAbgMF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "incrAbgMF",
                },
                {
                  inputKey: "title",
                  value: "Aboveground masses",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "incrAbgMF",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      isUnderlineHeader: true,
      id: "userDefinedGrowth",
      label:
        "User Defined Growth Calibration Parameters for the Tree Yield Formula",
      items: [
        {
          label: "Tree age of max growth in tree yield formula (G) [yr]",
          shoartLabel: "",
          inputs: [
            { element: "input", type: "number", programmingName: "tyf_G" },
          ],
        },
        {
          label:
            "Non-endemic species multiplier of max aboveground biomass in tyf (r)",
          shoartLabel: "",
          inputs: [
            { element: "input", type: "number", programmingName: "tyf_r" },
          ],
        },
      ],
    },

    {
      isShown: true,
      isUnderlineHeader: true,
      id: "growthCalibration",
      label: "Growth calibration",
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "TyfCategoryTableComponent",
              componentInputs: [
                {
                  inputKey: "selectedSpeciesId$",
                  variable: "selectedSpeciesId$",
                  isObservable: true,
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected plantsGroups: FormGroupElement[] = [
    {
      isShown: true,
      showErrors: true,
      label: "Carbon percentages",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Carbon Percentages",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracStemF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracBranF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracBarkF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracLeafF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracCortF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "CFracFirtF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      label: "Turnover percentages - half life in years",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Turnover percentages - half life in years",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "[No turnover of stems]"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBranF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracBranF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Branches",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracBranF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracBarkF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracBarkF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Bark",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracBarkF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracLeafF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracLeafF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Leaves",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracLeafF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "turnFracCortF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracCortF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Coarse roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracCortF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          width: "120px",
                          programmingName: "turnFracFirtF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "turnFracFirtF",
                              },
                              {
                                inputKey: "title",
                                value: "Turnover percentages - Fine roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "turnFracFirtF",
                              },
                            ],
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      showErrors: true,
      label: "Density",
      items: [
        {
          label: "Stem density",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "stemDensity",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "stemDensity",
                },
                {
                  inputKey: "title",
                  value: "Stem Density",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "stemDensity",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      showErrors: true,
      label: "Mortality",
      items: [
        {
          label: "Tree mortality on",
          shoartLabel: "",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "treeMortOn",
            },
          ],
        },
        {
          id: "stemMortF",
          label: "Stem loss",
          shoartLabel: "",
          preventLabelElement: true,
          inputs: [
            {
              element: "navigation",
              linkTo: "flyOverPanel",
              component: "DataVisualiserComponent",
              programmingName: "stemMortF",
              componentInputs: [
                {
                  inputKey: "timeSeries$",
                  isObservable: true,
                  formKey: "stemMortF",
                },
                {
                  inputKey: "title",
                  value: "Stem Loss",
                },
                {
                  inputKey: "formFieldModel",
                  method: "getFormFieldModel",
                  payload: "stemMortF",
                },
              ],
            },
          ],
        },
        {
          groups: [
            {
              id: "stem-loss-group",
              isShown: true,
              showErrors: true,
              isUnderlineHeader: true,
              isUnderlineItems: true,
              label: "Ratios of component losses to stem loss:",
              items: [
                {
                  label: null,
                  inputs: [
                    {
                      element: "component",
                      component: "FormItemTableComponent",
                      componentInputs: [
                        {
                          inputKey: "caption",
                          value: "Ratios of component losses to stem loss",
                        },
                        {
                          inputKey: "formGroupPointer",
                          value: "getSelectedSpeciesFormGroup",
                        },
                        {
                          inputKey: "indexPointer",
                          value: "selectedSpeciesId$",
                        },
                        {
                          inputKey: "service",
                          method: "getService",
                        },
                        {
                          inputKey: "data",
                          value: {
                            headers: [],
                            rows: [
                              [
                                {
                                  element: "text",
                                  isHeader: "true",
                                  value: "Branches",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioBranMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: "true",
                                  value: "Bark",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioBarkMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: "true",
                                  value: "Leaves",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioLeafMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: "true",
                                  value: "Coarse roots",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioCortMortF",
                                },
                              ],
                              [
                                {
                                  element: "text",
                                  isHeader: "true",
                                  value: "Fine roots",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "ratioFirtMortF",
                                },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  label: null,
                  inputs: [
                    {
                      element: "component",
                      component: "FormulaDisplayComponent",
                      componentInputs: [
                        {
                          inputKey: "formGroupPointer",
                          value: "getSelectedSpeciesFormGroup",
                        },
                        {
                          inputKey: "indexPointer",
                          value: "selectedSpeciesId$",
                        },
                        {
                          inputKey: "service",
                          method: "getService",
                        },
                        {
                          inputKey: "data",
                          value: {
                            lhs: "Average age of dying trees",
                            rhs: [
                              [
                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "=",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "remvAvgAgeMultMortF",
                                },

                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "x",
                                },
                                {
                                  element: "text",
                                  value: "Average age of trees",
                                },
                              ],

                              [
                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "+",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "remvMaxAgeMultMortF",
                                },

                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "x",
                                },
                                {
                                  element: "text",
                                  value: "Age of the oldest trees",
                                },
                              ],

                              [
                                {
                                  element: "icon",
                                  type: "operator",
                                  value: "+",
                                },
                                {
                                  element: "input",
                                  type: "number",
                                  programmingName: "remvOffsetMortF",
                                },

                                { element: "text", value: "[yr]" },
                              ],
                            ],
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  label: "Replace dying trees with new trees (of age 0)",
                  shoartLabel: "",
                  inputs: [
                    {
                      element: "input",
                      type: "switch",
                      programmingName: "replaceStemsMortF",
                    },
                  ],
                },
                {
                  label: "Enable biomass-based age adjustment",
                  shoartLabel: "",
                  inputs: [
                    {
                      element: "input",
                      type: "switch",
                      programmingName: "useBioAgeAdjMortF",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected standingDeadGroups: FormGroupElement[] = [
    {
      isShown: true,
      showErrors: true,
      label: "Breakdown percentages [½ life yrs]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Breakdown percentages [½ life yrs]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stem",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracSDdwdF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracSDdwdF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Stem",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracSDdwdF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branch",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracSChwdF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracSChwdF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Branch",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracSChwdF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracSBlitF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracSBlitF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Bark",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracSBlitF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracSLlitF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracSLlitF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Leaf",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracSLlitF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracSCodrF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracSCodrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Breakdown Percentages - Coarse dead roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracSCodrF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          width: "120px",
                          programmingName: "bkdnFracSFidrF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracSFidrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Breakdown Percentages - Fine dead roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracSFidrF",
                              },
                            ],
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      showErrors: true,
      label: "Atmospheric percentages of breakdown products",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Atmospheric percentages of breakdown products",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stem",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracSDdwdBkdnF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branch",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracSChwdBkdnF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracSBlitBkdnF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracSLlitBkdnF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracSCodrBkdnF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracSFidrBkdnF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected debrisGroups: FormGroupElement[] = [
    {
      isShown: true,
      showErrors: true,
      label: "Resistant percentages",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Resistant percentages",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFracStemF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFracBranF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFracBarkF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFracLeafF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFracCortF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFracFirtF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      label: "Breakdown percentages [½ life yrs]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Breakdown Percentages [½ life yrs]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },

                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Decomposable", "Resistant"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Deadwood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracDDdwdF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracDDdwdF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Deadwood",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracDDdwdF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracRDdwdF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracRDdwdF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Deadwood",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracRDdwdF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Chopped wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracDChwdF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracDChwdF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Chopped wood",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracDChwdF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracRChwdF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracRChwdF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Chopped wood",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracRChwdF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracDBlitF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracDBlitF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Bark litter",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracDBlitF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracRBlitF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracRBlitF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Bark litter",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracRBlitF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracDLlitF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracDLlitF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Leaf litter",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracDLlitF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracRLlitF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracRLlitF",
                              },
                              {
                                inputKey: "title",
                                value: "Breakdown Percentages - Leaf litter",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracRLlitF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracDCodrF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracDCodrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Breakdown Percentages - Coarse dead roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracDCodrF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "bkdnFracRCodrF",
                          width: "120px",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracRCodrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Breakdown Percentages - Coarse dead roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracRCodrF",
                              },
                            ],
                          },
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          width: "120px",
                          programmingName: "bkdnFracDFidrF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracDFidrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Breakdown Percentages - Fine dead roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracDFidrF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          width: "120px",
                          programmingName: "bkdnFracRFidrF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "bkdnFracRFidrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Breakdown Percentages - Fine dead roots",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "bkdnFracRFidrF",
                              },
                            ],
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      label: "Sensitivity of breakdown to temperature and water",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      helpLink:
        "122_Sensitivity%20of%20Debris%20Breakdown%20to%20Temperature%20and%20Water.htm",
      items: [
        {
          label: "Mulch-style sensitivity",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "bkdnSensMulcF",
            },
          ],
        },
        {
          label: "Sensitivity to temperature [1/(deg C)]",
          id: "bkdnSensTempF",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "bkdnSensTempF",
            },
          ],
        },
        {
          label: "Sensitivity to rainfall and irrigation  [1/mm]",
          id: "bkdnSensRainF",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "bkdnSensRainF",
            },
          ],
        },
        {
          label: "Soil-style sensitivity",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "bkdnSensSoilF",
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      label: "Atmospheric percentages of breakdown products",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Atmospheric Percentages of Breakdown Products",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },

                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Decomposable", "Resistant"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Deadwood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracDDdwdBkdnF",
                          width: "120px",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracRDdwdBkdnF",
                          width: "120px",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Chopped wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracDChwdBkdnF",
                          width: "120px",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracRChwdBkdnF",
                          width: "120px",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracDBlitBkdnF",
                          width: "120px",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracRBlitBkdnF",
                          width: "120px",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracDLlitBkdnF",
                          width: "120px",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracRLlitBkdnF",
                          width: "120px",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracDCodrBkdnF",
                          width: "120px",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "atmsFracRCodrBkdnF",
                          width: "120px",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          width: "120px",
                          programmingName: "atmsFracDFidrBkdnF",
                        },

                        {
                          element: "input",
                          type: "number",
                          width: "120px",
                          programmingName: "atmsFracRFidrBkdnF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected productsGroups: FormGroupElement[] = [
    {
      isShown: true,
      label: "Product processing percentages [%/yr]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Product processing percentages [%/yr]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [
                      "",
                      "Percentage that decomposes each year while used as products",
                      "Percentage burnt for bio-energy each year",
                      "Percentage moved to landfill each year",
                      "Total percentage leaving the in-use pools each year",
                      "Percentage that decompose each year while in landfill",
                    ],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracFuelF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracFuelF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Biofuel - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracFuelF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracFuelF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracFuelF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracFuelF",
                            "enerFracFuelF",
                            "fillFracFuelF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracFuelF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracFuelF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Biofuel - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracFuelF",
                              },
                            ],
                          },
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Pulp and paper",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracPaprF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracPaprF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Pulp and paper - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracPaprF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracPaprF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracPaprF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracPaprF",
                            "enerFracPaprF",
                            "fillFracPaprF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracPaprF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracPaprF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Pulp and paper - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracPaprF",
                              },
                            ],
                          },
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Packing wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracPackF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracPackF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Packing wood - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracPackF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracPackF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracPackF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracPackF",
                            "enerFracPackF",
                            "fillFracPackF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracPackF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracPackF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Packing wood - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracPackF",
                              },
                            ],
                          },
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Furniture, poles",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracFurnF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracFurnF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Furniture, poles - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracFurnF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracFurnF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracFurnF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracFurnF",
                            "enerFracFurnF",
                            "fillFracFurnF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracFurnF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracFurnF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Furniture, poles - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracFurnF",
                              },
                            ],
                          },
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fiberboard",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracFibrF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracFibrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Fiberboard - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracFibrF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracFibrF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracFibrF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracFibrF",
                            "enerFracFibrF",
                            "fillFracFibrF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracFibrF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracFibrF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Fiberboard - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracFibrF",
                              },
                            ],
                          },
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Construction wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracConsF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracConsF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Construction wood - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracConsF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracConsF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracConsF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracConsF",
                            "enerFracConsF",
                            "fillFracConsF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracConsF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracConsF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Construction wood - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracConsF",
                              },
                            ],
                          },
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Mill residue",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "pdcmFracResiF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "pdcmFracResiF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Mill residue - Percentage that decomposes each year while used as products",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "pdcmFracResiF",
                              },
                            ],
                          },
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "enerFracResiF",
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "fillFracResiF",
                        },

                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "pdcmFracResiF",
                            "enerFracResiF",
                            "fillFracResiF",
                          ],
                        },

                        {
                          element: "input",
                          type: "number",
                          programmingName: "ldcmFracResiF",
                          linkToComponent: {
                            element: "navigation",
                            linkTo: "flyOverPanel",
                            component: "DecayRateComponent",
                            componentInputs: [
                              {
                                inputKey: "incomingFieldControl",
                                isObservable: true,
                                formKey: "ldcmFracResiF",
                              },
                              {
                                inputKey: "title",
                                value:
                                  "Product processing percentages [%/yr] - Mill residue - Percentage that decompose each year while in landfill",
                              },
                              {
                                inputKey: "inputVariables",
                                method: "getDecayRateInputVariables",
                                payload: "ldcmFracResiF",
                              },
                            ],
                          },
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected initialTreesGroup: FormGroupElement[] = [
    {
      isShown: false,
      id: "init-specify-tree-size",
      label: null,
      showErrors: true,
      isUnderlineHeader: true,
      items: [
        {
          label: "Specify tree size by",
          inputs: [
            {
              element: "select",
              selectOptions: [
                {
                  value: "Mass",
                  label: "Masses",
                },
                {
                  value: "Vol",
                  label: "Volume for stems, masses for other components",
                },
                {
                  value: "Frac",
                  label: "Percentage of maximum tree biomass",
                },
                {
                  value: "FracAge",
                  label: "Percentage of tree age biomass",
                },
              ],
              programmingName: "tInitStem",
            },
          ],
        },
      ],
    },
    {
      isShown: false,
      id: "init-tree-mass",
      label: "Masses [dmt/ha]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Masses [dmt/ha]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "stemMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "branMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "barkMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "leafMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "cortMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "firtMInitF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      isShown: false,
      id: "init-tree-percentage-tree-age-biomass",
      label: "% of tree age biomass",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Masses [dmt/ha]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "stemFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "branFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "barkFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "leafFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "cortFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "firtFracInitF",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "stemFracInitF",
                            "branFracInitF",
                            "barkFracInitF",
                            "leafFracInitF",
                            "cortFracInitF",
                            "firtFracInitF",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      isShown: false,
      id: "init-tree-percentage-maximum-biomass",
      label: "% of maximum tree biomass",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Masses [dmt/ha]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: [],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "stemFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "branFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "barkFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "leafFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "cortFracInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "firtFracInitF",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Total",
                        },
                        {
                          element: "text",
                          method: "getTotal",
                          payload: [
                            "stemFracInitF",
                            "branFracInitF",
                            "barkFracInitF",
                            "leafFracInitF",
                            "cortFracInitF",
                            "firtFracInitF",
                          ],
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },

    {
      isShown: false,
      label: "Volumes [m3/ha]",
      id: "init-tree-vol",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: "Stems",
          shoartLabel: "",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "stemVolInitF",
            },
          ],
        },
      ],
    },
    {
      isShown: true,
      label: "Ages [yr]",
      isUnderlineHeader: true,
      showErrors: true,
      items: [
        {
          label: "Ages of oldest trees",
          shoartLabel: "",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "maxTreeAgeInit",
            },
          ],
        },
        {
          label: "Average age of trees",
          shoartLabel: "",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "avgTreeAgeInit",
            },
          ],
        },
      ],
    },
    {
      isShown: false,
      id: "tyf-growth-calibration",
      label: "Tree yield formula: Growth calibration",
      showErrors: true,
      items: [
        {
          label: "Growth calibration",
          inputs: [
            {
              element: "select",
              selectOptions: [
                {
                  value: "Custom",
                  label: "User defined",
                },
                {
                  value: "BlockES",
                  label: "Block - environmental services",
                },
                {
                  value: "BlockLMG",
                  label: "Block - regeneration systems",
                },

                {
                  value: "Water",
                  label: "In riparian or floodplain zones",
                },
              ],
              programmingName: "tTYFCatInitF",
            },
          ],
        },
      ],
    },
  ];

  protected initialStandingDeadGroup: FormGroupElement[] = [
    {
      isShown: true,
      label: "Carbon masses [tC/ha]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Carbon masses [tC/ha]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Standing dead"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "sDStemCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branch",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "sDBranCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "sDBlitCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "sDLlitCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "sDCodrCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "sDFidrCMInitF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected initialDebrisGroups: FormGroupElement[] = [
    {
      isShown: true,
      label: "Carbon masses [tC/ha]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Carbon masses [tC/ha]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Decomposable", "Resistant"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Deadwood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "dDdwdCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rDdwdCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Chopped wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "dChwdCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rChwdCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "dBlitCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rBlitCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaf litter",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "dLlitCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rLlitCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "dCodrCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rCodrCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine dead roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "dFidrCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "rFidrCMInitF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  protected initialProductGroups: FormGroupElement[] = [
    {
      isShown: true,
      label: "Carbon masses [tC/ha]",
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Carbon masses [tC/ha]",
                },
                {
                  inputKey: "formGroupPointer",
                  value: "getSelectedSpeciesFormGroup",
                },
                {
                  inputKey: "indexPointer",
                  value: "selectedSpeciesId$",
                },
                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "Used As Products", "In Landfill"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Biofuel",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fuelCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fuelLfCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Pulp and paper",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "paprCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "paprLfCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Packing wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "packCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "packLfCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Furniture, poles",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "furnCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "furnLfCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fiberboard",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fibrCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "fibrLfCMInitF",
                        },
                      ],
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Construction wood",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "consCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "consLfCMInitF",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Mill residue",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "resiCMInitF",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "resiLfCMInitF",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  public readonly layout: FormLayout = {
    label: "Trees",
    groups: [
      {
        label: "Species",
        isShown: true,
        isRoot: true,
        showErrors: true,
        helpLink: "56_Select%20a%20Species.htm",
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SpeciesSelectorComponent",
                componentInputs: [
                  {
                    inputKey: "selectedId$",
                    variable: "selectedSpeciesId$",
                    isObservable: true,
                  },
                  {
                    inputKey: "formGroupInstance",
                    method: "getFormGroup",
                  },
                  {
                    inputKey: "speciesInUse$",
                    variable: "speciesInUse$",
                    isObservable: true,
                  },
                  {
                    inputKey: "type",
                    value: "tree",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "buttonClicked",
                    method: "speciesButtonClicked",
                  },
                  {
                    outputKey: "speciesSelected",
                    method: "selectSpecies",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Properties of the species",
        isShown: true,
        isAccordion: true,
        helpLink: "145_Properties%20of%20the%20Species.htm",
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: "Name",
                element: "input",
                type: "text",
                programmingName: "nmSP",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Notes",
                element: "textarea",
                rows: 2,
                programmingName: "notesSP",
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Growth",
                helpLink: "42_Growth%20Properties.htm",
                items: [{ groups: this.growthGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Plants",
                helpLink: "43_Plant%20Properties.htm",
                items: [{ groups: this.plantsGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Standing dead",
                helpLink: "285_StandingDead.htm",
                items: [{ groups: this.standingDeadGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Debris",
                helpLink: "45_Debris%20Properties.htm",
                items: [{ groups: this.debrisGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Products",
                items: [{ groups: this.productsGroups }],
              },
            ],
          },
        ],
      },
      {
        label: "Standard information for the species",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        helpLink: "146_Standard%20Information%20for%20the%20Species.htm",
        items: [
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Trees",
                showErrors: true,
                helpLink: "185_Initial%20Trees.htm",
                items: [{ groups: this.initialTreesGroup }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Standing Dead",
                showErrors: true,
                helpLink: "284_Initial%20StandingDead.htm",
                items: [{ groups: this.initialStandingDeadGroup }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Debris",
                showErrors: true,
                helpLink: "284_Initial%20StandingDead.htm",
                items: [{ groups: this.initialDebrisGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Initial Products",
                showErrors: true,
                helpLink: "33_Initial%20Products.htm",
                items: [{ groups: this.initialProductGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Events",
                showErrors: true,
                helpLink: "142_Standard%20Events%20of%20a%20Species.htm",
                items: [
                  {
                    label: "Events",
                    shoartLabel: "",
                    inputs: [
                      {
                        element: "navigation",
                        linkTo: "flyOverPanel",
                        programmingName: "eventQ",
                        component: "SpeciesEventsComponent",
                        componentInputs: [
                          {
                            inputKey: "speciesFormGroup",
                            method: "getSelectedSpeciesFormGroup",
                          },
                          {
                            inputKey: "formGroup",
                            variable: "formGroup",
                          },
                          {
                            inputKey: "service",
                            method: "getService",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    showOnlyInUse: {
      defaultValue: false,
      isShown: true,
    },
    headerState: {
      label: "Event Q header state",
      defaultValue: {
        $: {
          sortIx: "0",
          sortUp: "true",
          sortBy1: "false",
          sortBy2: "false",
          showOnlyHS: "false",
        },
        headSectW: ["80,270,90,214,90,270"],
      },
      isShown: false,
      excludeFromXml: true,
    },
    showEvT: {
      label: "Event Q Show Event",
      defaultValue: "t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t",
      isShown: false,
      excludeFromXml: true,
    },
    species: {
      label: "Trees",
      defaultValue: "formArray",
      isShown: true,
    },
  };

  public speciesFormModel: FormModel = {
    tyfCategories: {
      label: "TYF Categories",
      defaultValue: [],
      isShown: false,
      excludeFromXml: true,
      suffixId: null,
    },
    eventQ: {
      label: "Species Events",
      defaultValue: "formArray",
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "eventQ",
        },
      ],
      isShown: true,
      suffixId: null,
    },
    curEdit: {
      defaultValue: false,
      isShown: true,
      high: 1,
      low: 0,
      dataType: 2,
      suffixId: null,
    },
    // dbId: {
    //   defaultValue: null,
    //   isShown: false,
    //   excludeFromXml: true,
    //   suffixId: null,
    // },
    idSP: {
      defaultValue: null,
      isShown: true,
      suffixId: null,
    },
    grthModeSP: {
      defaultValue: "Yield",
      isShown: true,
      validators: [],
      validatorConfig: [],
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species : Growth",
      isDigest: true,
      isExplorer: true,
      suffixId: null,
    },
    nSpecsF: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 1,
      helpId: 148,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Number of tree species",
      unit: "",
      categoryName: "SpecFr",
      categoryLabel: "Forest species set",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 604,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 56,
      defaultValue: "Tree",
      validators: [],
      validatorConfig: [],
      label: "Type of plants (trees or crop)",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "SpecT",
      eventId: 10,
      tIn: 599,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tAgeIxSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 42,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Type of plant age to use in growth tables (average or maximum)",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species : Growth",
      isShown: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "AgeIxT",
      eventId: 10,
      tIn: 601,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 145,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Species name",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 798,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 145,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "maxLength",
          input: 999,
        },
      ],
      label: "Species notes",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 799,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    inUseSP: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 148,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Species in use",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1269,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    statusSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 148,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Species status",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "StatusT",
      eventId: 10,
      tIn: 1284,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    incrStemVolF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 110,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Stem volume increments",
      unit: "m3/ha",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 22,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    incrAbgMF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 110,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Aboveground",
      unit: "dmt/ha",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 23,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 43,
    },
    allocStemF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 112,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "stems",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 24,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    allocBranF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 112,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "branches",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 25,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    allocBarkF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 112,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "bark",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 26,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    allocLeafF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 112,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "leaves",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 27,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    allocCortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 112,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "coarse roots",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 28,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    allocFirtF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 112,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "fine roots",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 29,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    pltnType: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 145,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Plantation Type (identifier for spatial sim.s)",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PlantationTypeT",
      eventId: 10,
      tIn: 312,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    grthModeF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 42,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Types of growth tables (yields or NPP)",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "GrthModeFT",
      eventId: 10,
      tIn: 313,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tIncrTblF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 110,
      defaultValue: "IncrStemVolF",
      validators: [],
      validatorConfig: [],
      label: "Type of increments to use for tree growth",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "IncrTblFT",
      eventId: 10,
      tIn: 602,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    mvgTreeId: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 1,
      helpId: 145,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "MVG number (native vegetation identifier for spatial sim.s)",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 607,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tyf_r: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 42,
      defaultValue: 1,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "of max aboveground biomass in tyf (r)",
      unit: "",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 8,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1147,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    incrStemMF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 110,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Stem",
      unit: "dmt/ha",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1204,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 43,
    },
    tyf_G: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 42,
      defaultValue: 10,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "",
      unit: "yr",
      categoryName: "TreeGr",
      categoryLabel: "Tree species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 7,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1257,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemDensity: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 6,
      helpId: 9,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Stem density",
      unit: "kgdm/m3",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 74,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracStemF: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems",
      unit: "%",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 75,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracBranF: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches",
      unit: "%",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 76,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracBarkF: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark",
      unit: "%",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 77,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracLeafF: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves",
      unit: "%",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 78,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracCortF: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots",
      unit: "%",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 79,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracFirtF: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots",
      unit: "%",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 80,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    turnFracBranF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 1,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches",
      unit: "½ life yrs",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 81,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    turnFracBarkF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark",
      unit: "½ life yrs",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 82,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    turnFracLeafF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves",
      unit: "½ life yrs",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 83,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    turnFracCortF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots",
      unit: "½ life yrs",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 84,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    turnFracFirtF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots",
      unit: "½ life yrs",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 85,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    useBioAgeAdjMortF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 121,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Use biomass age adjustment (yes/no)",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 498,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ratioBranMortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of branch",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 977,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 12,
    },
    ratioBarkMortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of bark",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 978,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 12,
    },
    ratioLeafMortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of leaf",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 979,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 12,
    },
    ratioCortMortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of coarse root",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 980,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 12,
    },
    ratioFirtMortF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of fine root",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 981,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 12,
    },
    stemMortF: {
      high: 0.999999999,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 19,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "1",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: "0.0",
            count: "1",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Annual % of stems lost due to mortality",
      unit: "%/yr",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 986,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeMortOn: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 121,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Tree mortality (on/off)",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 988,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    replaceStemsMortF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 121,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "Replace dying trees with new trees (of age 0) (yes/no)",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1001,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    remvAvgAgeMultMortF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: -1e36,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Multiplier of average age",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1002,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 13,
    },
    remvMaxAgeMultMortF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: -1e36,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Multiplier of age of oldest trees",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1003,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 13,
    },
    remvOffsetMortF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: -1e36,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Added constant",
      unit: "",
      categoryName: "TreePl",
      categoryLabel: "Tree species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1004,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 13,
    },
    rFracStemF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "stems",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 86,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rFracBranF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "branches",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 87,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rFracBarkF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "bark",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 88,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rFracLeafF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "leaves",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 89,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rFracCortF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "coarse roots",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 90,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rFracFirtF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fine roots",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 91,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    mrFracOfRLittF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant litter that is 'more-resistant' (not 'less-resistant')",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 93,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracDDdwdF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable deadwood",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 200,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracRDdwdF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant deadwood",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 201,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracDBlitF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable bark litter",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 202,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracRBlitF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant bark litter",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 203,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracDLlitF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable leaf litter",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 204,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracRLlitF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant leaf litter",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 205,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracDCodrF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 206,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracRCodrF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant coarse dead roots",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 207,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracDFidrF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable fine dead roots",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 208,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracRFidrF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant fine dead roots",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 209,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracDDdwdBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable deadwood breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 210,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracRDdwdBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant deadwood breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 211,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracDBlitBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable bark litter breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 212,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracRBlitBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant bark litter breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 213,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracDLlitBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable leaf litter breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 214,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracRLlitBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant leaf litter breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 215,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracDCodrBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable coarse dead root breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 216,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracRCodrBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant coarse dead root breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 217,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracDFidrBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable fine dead root breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 218,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracRFidrBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant fine dead root breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 219,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnSensSoilF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 122,
      defaultValue: false,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "soil-style (on/off)",
      unit: "",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 17,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1016,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnSensTempF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 122,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "temperature",
      unit: "1/(deg C)",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 18,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1017,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnSensRainF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 122,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "rainfall",
      unit: "1/mm",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 18,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1018,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracDChwdF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable chopped wood",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1040,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracRChwdF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant chopped wood",
      unit: "½ life yrs",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1041,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracDChwdBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "decomposable chopped wood breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1042,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracRChwdBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "resistant chopped wood breakdown",
      unit: "%",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1043,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnSensMulcF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 122,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "mulch-style (on/off)",
      unit: "",
      categoryName: "TreeDe",
      categoryLabel: "Tree species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 17,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1117,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    pdcmFracFuelF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "forest biofuel",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 220,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    pdcmFracPaprF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "pulp and paper",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 221,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    pdcmFracPackF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "packing wood",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 222,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    pdcmFracFurnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "furniture,poles,preservatives",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 223,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    pdcmFracFibrF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fiberboard",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 224,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    pdcmFracConsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "construction timber",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 225,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    pdcmFracResiF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "mill residue",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 226,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    enerFracPaprF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "pulp and paper",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 391,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 21,
    },
    enerFracPackF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "packing wood",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 393,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 21,
    },
    enerFracFurnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "furniture,poles,preservatives",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 395,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 21,
    },
    enerFracFibrF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fiberboard",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 397,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 21,
    },
    enerFracConsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "construction timber",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 399,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 21,
    },
    enerFracResiF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "mill residue",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 401,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 21,
    },
    fillFracFuelF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "forest biofuel",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 403,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    fillFracPaprF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "pulp and paper",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 405,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    fillFracPackF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "packing wood",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 407,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    fillFracFurnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "furniture,poles,preservatives",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 409,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    fillFracFibrF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fiberboard",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 411,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    fillFracConsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "construction timber",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 413,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    fillFracResiF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "mill residue",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 415,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 22,
    },
    ldcmFracFuelF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "forest biofuel",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 468,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    ldcmFracFibrF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "fiberboard",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 471,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    ldcmFracPackF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "packing wood",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 473,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    ldcmFracResiF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "mill residue",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 476,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    ldcmFracPaprF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "pulp and paper",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 478,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    ldcmFracConsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "construction timber",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 481,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    ldcmFracFurnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "furniture,poles,preservatives",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 483,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    enerFracFuelF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "forest biofuel burnt for bioenergy",
      unit: "%/yr",
      categoryName: "TreePr",
      categoryLabel: "Tree species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 486,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxSFixNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum ratio of symbiotic N fixation (kgN) to C production (tC)",
      unit: "/1000",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 239,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    storFracLeafTurnNF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Stored % of leaf nitrogen during turnover",
      unit: "%",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 240,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userRDebrNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "resistant debris pools",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 241,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    absnNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of absorbed N to the C in the decomposable fine breakdown",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 242,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxBkdnNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "the decomposable fine breakdown",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 243,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMaxNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree stems",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 101,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1294,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 26,
    },
    stemMaxNC2F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree stems",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 101,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1295,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 27,
    },
    branMaxNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree branches",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 102,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1296,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 26,
    },
    branMaxNC2F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree branches",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 102,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1297,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 27,
    },
    barkMaxNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree bark",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 103,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1298,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 26,
    },
    barkMaxNC2F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree bark",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 103,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1299,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 27,
    },
    leafMaxNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree leaves",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 104,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1300,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 26,
    },
    leafMaxNC2F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree leaves",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 104,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1301,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 27,
    },
    cortMaxNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree coarse roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 105,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1302,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 26,
    },
    cortMaxNC2F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree coarse roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 105,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1303,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 27,
    },
    firtMaxNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree fine roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 106,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1304,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 26,
    },
    firtMaxNC2F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree fine roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 106,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1305,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 27,
    },
    stemMinNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree stems",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 101,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1306,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branMinNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree branches",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 102,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1307,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkMinNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree bark",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 103,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1308,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafMinNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree leaves",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 104,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1309,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cortMinNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree coarse roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 105,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1310,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    firtMinNC1F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree fine roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 106,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1311,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemDays2F: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 30,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1312,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branDays2F: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 30,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1313,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkDays2F: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 30,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1314,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafDays2F: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 30,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1315,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cortDays2F: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 30,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1316,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    firtDays2F: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "TreeNi",
      categoryLabel: "Tree species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 30,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1317,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracStemToDdwdThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1494,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1495,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToPaprThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1496,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToPackThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1497,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToFurnThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1498,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToFibrThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1499,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToConsThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to construction timber",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1500,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToResiThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to mill residues",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1501,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToDdwdThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1502,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1503,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToPaprThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1504,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToPackThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1505,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToFurnThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1506,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToFibrThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1507,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToConsThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to construction wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1508,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBranToResiThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1509,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBarkToBlitThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1510,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBarkToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1511,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBarkToPaprThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1512,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBarkToResiThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1513,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracLeafToLlitThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1514,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracLeafToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1515,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracCortToCodrThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1516,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracCortToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1517,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracFirtToFidrThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1518,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracDdwdToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deadwood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1519,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracChwdToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "chopped wood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1520,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracBlitToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1521,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracLlitToFuelThin1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1522,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 122,
    },
    fracStemToDdwdThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1523,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1524,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToPaprThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1525,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToPackThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1526,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToFurnThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1527,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToFibrThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1528,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToConsThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to construction timber",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1529,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToResiThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to mill residues",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1530,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToDdwdThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1531,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1532,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToPaprThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1533,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToPackThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1534,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToFurnThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1535,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToFibrThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1536,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToConsThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to construction wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1537,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBranToResiThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1538,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBarkToBlitThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1539,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBarkToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1540,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBarkToPaprThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1541,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBarkToResiThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1542,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracLeafToLlitThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1543,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracLeafToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1544,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracCortToCodrThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1545,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracCortToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1546,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracFirtToFidrThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1547,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracDdwdToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deadwood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1548,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracChwdToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "chopped wood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1549,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracBlitToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1550,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracLlitToFuelThin2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1551,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 123,
    },
    fracStemToDdwdThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1552,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1553,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToPaprThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1554,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToPackThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1555,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToFurnThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1556,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToFibrThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1557,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToConsThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to construction timber",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1558,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToResiThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to mill residues",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1559,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToDdwdThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1560,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1561,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToPaprThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1562,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToPackThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1563,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToFurnThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1564,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToFibrThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1565,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToConsThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to construction wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1566,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBranToResiThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1567,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBarkToBlitThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1568,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBarkToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1569,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBarkToPaprThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1570,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBarkToResiThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1571,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracLeafToLlitThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1572,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracLeafToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1573,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracCortToCodrThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1574,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracCortToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1575,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracFirtToFidrThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1576,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracDdwdToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deadwood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1577,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracChwdToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "chopped wood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1578,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracBlitToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1579,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracLlitToFuelThin3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1580,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 124,
    },
    fracStemToDdwdThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1581,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1582,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToPaprThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1583,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToPackThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1584,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToFurnThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1585,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToFibrThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1586,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToConsThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to construction timber",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1587,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToResiThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to mill residues",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1588,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToDdwdThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1589,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1590,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToPaprThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1591,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToPackThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1592,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToFurnThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1593,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToFibrThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1594,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToConsThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to construction wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1595,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBranToResiThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1596,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBarkToBlitThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1597,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBarkToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1598,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBarkToPaprThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1599,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBarkToResiThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1600,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracLeafToLlitThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1601,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracLeafToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1602,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracCortToCodrThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1603,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracCortToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1604,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracFirtToFidrThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1605,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracDdwdToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deadwood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1606,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracChwdToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "chopped wood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1607,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracBlitToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1608,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracLlitToFuelThin4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1609,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 125,
    },
    fracStemToDdwdThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1610,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1611,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToPaprThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1612,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToPackThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1613,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToFurnThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1614,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToFibrThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1615,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToConsThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to construction timber",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1616,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracStemToResiThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems to mill residues",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1617,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToDdwdThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to deadwood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1618,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1619,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToPaprThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1620,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToPackThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to packing wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1621,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToFurnThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to furniture",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1622,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToFibrThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to fiberboard",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1623,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToConsThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to construction wood",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1624,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBranToResiThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1625,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBarkToBlitThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1626,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBarkToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1627,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBarkToPaprThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to pulp and paper",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1628,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBarkToResiThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark to mill residue",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1629,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracLeafToLlitThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1630,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracLeafToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1631,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracCortToCodrThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1632,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracCortToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1633,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracFirtToFidrThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1634,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracDdwdToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deadwood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1635,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracChwdToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "chopped wood to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1636,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracBlitToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1637,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    fracLlitToFuelThin5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1638,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 126,
    },
    nmGradeF1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: "nmGradeF1",
      validators: [],
      validatorConfig: [],
      label: "Name of log grade 1",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1735,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nmGradeF2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: "nmGradeF2",
      validators: [],
      validatorConfig: [],
      label: "Name of log grade 2",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1736,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nmGradeF3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: "nmGradeF3",
      validators: [],
      validatorConfig: [],
      label: "Name of log grade 3",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1737,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nmGradeF4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: "nmGradeF4",
      validators: [],
      validatorConfig: [],
      label: "Name of log grade 4",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1738,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nmGradeF5: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: "nmGradeF5",
      validators: [],
      validatorConfig: [],
      label: "Name of log grade 5",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1739,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tPRWood1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1745,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    useStemDensityPRWood1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1746,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    densityPRWood1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1747,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    fracWaterPRWood1: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1748,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    harvCostPRWood1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1749,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    trnsCostPRWood1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1750,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    millPricePRWood1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Price at the mill gate",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1751,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    stYearPRWood1: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1752,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    incrPaHarvPRWood1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1753,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    incrPaTrnsPRWood1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1754,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    incrPaMillPRWood1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1755,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 132,
    },
    tPRBark1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1756,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    useStemDensityPRBark1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1757,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    densityPRBark1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1758,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    fracWaterPRBark1: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1759,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    harvCostPRBark1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1760,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    trnsCostPRBark1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1761,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    millPricePRBark1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1762,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    stYearPRBark1: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1763,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    incrPaHarvPRBark1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1764,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    incrPaTrnsPRBark1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1765,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    incrPaMillPRBark1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1766,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 137,
    },
    tPRFuel1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1767,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    useStemDensityPRFuel1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1768,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    densityPRFuel1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1769,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    fracWaterPRFuel1: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1770,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    harvCostPRFuel1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1771,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    trnsCostPRFuel1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1772,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    millPricePRFuel1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1773,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    stYearPRFuel1: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1774,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    incrPaHarvPRFuel1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1775,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    incrPaTrnsPRFuel1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1776,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    incrPaMillPRFuel1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1777,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 142,
    },
    tPRWood2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1778,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    useStemDensityPRWood2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1779,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    densityPRWood2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1780,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    fracWaterPRWood2: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1781,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    harvCostPRWood2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1782,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    trnsCostPRWood2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1783,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    millPricePRWood2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1784,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    stYearPRWood2: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1785,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    incrPaHarvPRWood2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1786,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    incrPaTrnsPRWood2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1787,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    incrPaMillPRWood2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1788,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 133,
    },
    tPRBark2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1789,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    useStemDensityPRBark2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1790,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    densityPRBark2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1791,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    fracWaterPRBark2: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1792,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    harvCostPRBark2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1793,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    trnsCostPRBark2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1794,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    millPricePRBark2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1795,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    stYearPRBark2: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1796,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    incrPaHarvPRBark2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1797,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    incrPaTrnsPRBark2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1798,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    incrPaMillPRBark2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1799,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 138,
    },
    tPRFuel2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1800,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    useStemDensityPRFuel2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1801,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    densityPRFuel2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1802,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    fracWaterPRFuel2: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1803,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    harvCostPRFuel2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1804,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    trnsCostPRFuel2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1805,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    millPricePRFuel2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1806,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    stYearPRFuel2: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1807,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    incrPaHarvPRFuel2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1808,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    incrPaTrnsPRFuel2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1809,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    incrPaMillPRFuel2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1810,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 143,
    },
    tPRWood3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1811,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    useStemDensityPRWood3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1812,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    densityPRWood3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1813,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    fracWaterPRWood3: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1814,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    harvCostPRWood3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1815,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    trnsCostPRWood3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1816,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    millPricePRWood3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1817,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    stYearPRWood3: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1818,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    incrPaHarvPRWood3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1819,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    incrPaTrnsPRWood3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1820,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    incrPaMillPRWood3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1821,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 134,
    },
    tPRBark3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1822,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    useStemDensityPRBark3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1823,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    densityPRBark3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1824,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    fracWaterPRBark3: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1825,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    harvCostPRBark3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1826,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    trnsCostPRBark3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1827,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    millPricePRBark3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1828,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    stYearPRBark3: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1829,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    incrPaHarvPRBark3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1830,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    incrPaTrnsPRBark3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1831,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    incrPaMillPRBark3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1832,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 139,
    },
    tPRFuel3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1833,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    useStemDensityPRFuel3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1834,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    densityPRFuel3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1835,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    fracWaterPRFuel3: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1836,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    harvCostPRFuel3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1837,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    trnsCostPRFuel3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1838,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    millPricePRFuel3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1839,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    stYearPRFuel3: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1840,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    incrPaHarvPRFuel3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1841,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    incrPaTrnsPRFuel3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1842,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    incrPaMillPRFuel3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1843,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 144,
    },
    tPRWood4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1844,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    useStemDensityPRWood4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1845,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    densityPRWood4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1846,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    fracWaterPRWood4: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1847,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    harvCostPRWood4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1848,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    trnsCostPRWood4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1849,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    millPricePRWood4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1850,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    stYearPRWood4: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1851,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    incrPaHarvPRWood4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1852,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    incrPaTrnsPRWood4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1853,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    incrPaMillPRWood4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1854,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 135,
    },
    tPRBark4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1855,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    useStemDensityPRBark4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1856,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    densityPRBark4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1857,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    fracWaterPRBark4: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1858,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    harvCostPRBark4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1859,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    trnsCostPRBark4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1860,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    millPricePRBark4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1861,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    stYearPRBark4: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1862,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    incrPaHarvPRBark4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1863,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    incrPaTrnsPRBark4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1864,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    incrPaMillPRBark4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1865,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 140,
    },
    tPRFuel4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1866,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    useStemDensityPRFuel4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1867,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    densityPRFuel4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1868,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    fracWaterPRFuel4: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1869,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    harvCostPRFuel4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1870,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    trnsCostPRFuel4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1871,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    millPricePRFuel4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1872,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    stYearPRFuel4: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1873,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    incrPaHarvPRFuel4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1874,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    incrPaTrnsPRFuel4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1875,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    incrPaMillPRFuel4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1876,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 145,
    },
    tPRWood5: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1877,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    useStemDensityPRWood5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1878,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    densityPRWood5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1879,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    fracWaterPRWood5: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1880,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    harvCostPRWood5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1881,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    trnsCostPRWood5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1882,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    millPricePRWood5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1883,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    stYearPRWood5: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1884,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    incrPaHarvPRWood5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1885,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    incrPaTrnsPRWood5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1886,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    incrPaMillPRWood5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1887,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 136,
    },
    tPRBark5: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1888,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    useStemDensityPRBark5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1889,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    densityPRBark5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1890,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    fracWaterPRBark5: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1891,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    harvCostPRBark5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1892,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    trnsCostPRBark5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1893,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    millPricePRBark5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1894,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    stYearPRBark5: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1895,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    incrPaHarvPRBark5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1896,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    incrPaTrnsPRBark5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1897,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    incrPaMillPRBark5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1898,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 141,
    },
    tPRFuel5: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: "PriceBlank",
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1899,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    useStemDensityPRFuel5: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 148,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1900,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    densityPRFuel5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 149,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1901,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    fracWaterPRFuel5: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1902,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    harvCostPRFuel5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1903,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    trnsCostPRFuel5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1904,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    millPricePRFuel5: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1905,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    stYearPRFuel5: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1906,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    incrPaHarvPRFuel5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1907,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    incrPaTrnsPRFuel5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1908,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    incrPaMillPRFuel5: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "TreeLg",
      categoryLabel: "Tree species : Thinning",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1909,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 146,
    },
    effcEnerFuelF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "forest biofuel",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 820,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcEnerPaprF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 821,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcEnerPackF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 822,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcEnerFurnF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 823,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcEnerFibrF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 824,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcEnerConsF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 825,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcEnerResiF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 826,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossFuelF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "forest biofuel",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 827,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossPaprF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 828,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossPackF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 829,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossFurnF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 830,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossFibrF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 831,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossConsF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 832,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    effcFossResiF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "%",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 833,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerFuelF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "forest biofuel",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 834,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerPaprF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 835,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerPackF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 836,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerFurnF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 837,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerFibrF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 838,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerConsF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 839,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrEnerResiF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 840,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossFuelF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "forest biofuel",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 841,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossPaprF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 842,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossPackF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 843,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossFurnF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 844,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossFibrF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 845,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossConsF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 846,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrFossResiF: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "t/MJ",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 847,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cultivationCF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "spent cultivating tree species on plot",
      unit: "tC/ha/yr",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 960,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    thinCF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "used in thinning / C in thinned material",
      unit: "",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 961,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    biofuelBurnCF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "used to burn forest biofuel / C in forest biofuel",
      unit: "",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 962,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    wasteBurnCF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "used in burning non-biofuel forest products / C in those products",
      unit: "",
      categoryName: "TreeDisE",
      categoryLabel: "Tree species : Displacement due to bioenergy",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 963,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeProdPaprF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 848,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeProdPackF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 849,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeProdFurnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 850,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeProdFibrF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 851,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeProdConsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 852,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeProdResiF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 853,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrProdPaprF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 854,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrProdPackF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 855,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrProdFurnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 856,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrProdFibrF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 857,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrProdConsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 858,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrProdResiF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 859,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeDispPaprF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "product displaced by pulp and paper",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 860,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeDispPackF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 861,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeDispFurnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 862,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeDispFibrF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 863,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeDispConsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 864,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    makeDispResiF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "MJ/t",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 865,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrDispPaprF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 866,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrDispPackF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 867,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrDispFurnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 868,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrDispFibrF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 869,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrDispConsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 870,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cemrDispResiF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "t/MJ",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 871,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    MDispPerProdPaprF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 872,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    MDispPerProdPackF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 873,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    MDispPerProdFurnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 874,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    MDispPerProdFibrF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 875,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    MDispPerProdConsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 876,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    MDispPerProdResiF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 877,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    LProdPerDispPaprF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 878,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 41,
    },
    LProdPerDispPackF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 879,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 41,
    },
    LProdPerDispFurnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 880,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 41,
    },
    LProdPerDispFibrF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 881,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 41,
    },
    LProdPerDispConsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 882,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 41,
    },
    LProdPerDispResiF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 883,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 41,
    },
    CFracProdPaprF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "%",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 885,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracProdPackF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "%",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 886,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracProdFurnF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture and poles",
      unit: "%",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 887,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracProdFibrF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "%",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 888,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracProdConsF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction wood",
      unit: "%",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 889,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    CFracProdResiF: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "%",
      categoryName: "TreeDisP",
      categoryLabel: "Tree species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 890,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ratioNPPToGPP: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of NPP to GPP",
      unit: "",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 244,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nDaysLostPerFrost: {
      high: 365.25,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Days of growth lost for each frost night",
      unit: "day",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 245,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxStemMAt1000tph: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum stem mass per tree in mature forests of 1000 trees/ha",
      unit: "kg/tree",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 246,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    minTempForGrth: {
      high: 100,
      low: -273,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Minimum temperature for growth",
      unit: "deg C",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 253,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    optTempForGrth: {
      high: 100,
      low: -273,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Optimal temperature for growth",
      unit: "deg C",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 254,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxTempForGrth: {
      high: 100,
      low: -273,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum temperature for growth",
      unit: "deg C",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 255,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxAge: {
      high: 1e36,
      low: 0.01,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum tree age",
      unit: "yr",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 266,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    agePower: {
      high: 1e36,
      low: 0.01,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "The power in the age modifier formula",
      unit: "",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 267,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ageMult: {
      high: 1e36,
      low: 0.01,
      isOneMore: false,
      dataType: 0,
      helpId: 4,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "The multiplier in the age modifier formula",
      unit: "",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 268,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    modTempTS: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 61,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Temperature modifier in 3PG-lite",
      unit: "0...1",
      categoryName: "Tree3G",
      categoryLabel: "Tree species : 3PG : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 1208,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ratioFoliStem2cm: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of foliage NPP to stem NPP,at 2 cm stem diameter",
      unit: "",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 247,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ratioFoliStem20cm: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of foliage NPP to stem NPP,at 20 cm stem diameter",
      unit: "",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 248,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMDBHMult: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "The power in the stem-mass-to-diameter formula",
      unit: "",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 249,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMDBHPower: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "The multiplier in the stem-mass-to-diameter formula",
      unit: "",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 250,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxFracNPPToRoot: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Minimum % of NPP that goes to the roots",
      unit: "%",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 251,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    minFracNPPToRoot: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum % of NPP that goes to the roots",
      unit: "%",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 252,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    age0BBFrac: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stem that is branch and bark,for seedlings",
      unit: "%",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 276,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    matureAgeBBFrac: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stem that is branch and bark,for mature-age trees",
      unit: "%",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 277,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ageOfHalfwayBBFrac: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 22,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Age at which bark-and-bark frac. halfway between seedling and mature",
      unit: "yr",
      categoryName: "Tree3A",
      categoryLabel: "Tree species : 3PG : Allometry",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 278,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxLitterfall: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum monthly leaf turnover fraction",
      unit: "1/month",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 256,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    age0Litterfall: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Monthly leaf turnover fraction for a seedling",
      unit: "1/month",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 257,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ageMedianLitterfall: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Age at which leaf turnover fraction has median value",
      unit: "yr",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 258,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxCanopyCond: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum canopy conductance",
      unit: "m/s",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 260,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxStomatalCond: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum stomatal conductance",
      unit: "m/s",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 261,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    condCoefft: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "VPD response coefficient for canopy conductance",
      unit: "kPa",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 262,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    canopyBoundaryCond: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Canopy boundary layer conductance",
      unit: "m/s",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 263,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    age0SLA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Specific leaf area (SLA) for a seedling",
      unit: "m2/kg",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 269,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    matureAgeSLA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Specific leaf area (SLA) for a mature-age forest",
      unit: "m2/kg",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 270,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ageOfHalfwaySLA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Age at which SLA is halfway between seedling and mature SLA's",
      unit: "yr",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 271,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    PARAbsorbCoefft: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Radiation extinction coefft for absorption of photosyn. active rad.",
      unit: "",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 272,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    ageMatureCanopy: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Age at which the tree canopy reaches its mature level of cover",
      unit: "yr",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 273,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracRainIntercept: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 155,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Percentage of precipitation intercepted by the tree canopy",
      unit: "%",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: true,
      enumId: null,
      eventId: 10,
      tIn: 274,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    quantumEfficiency: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 21,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Quantum efficiency",
      unit: "mol C / mol PAR",
      categoryName: "Tree3L",
      categoryLabel: "Tree species : 3PG : Leaves",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 275,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    turnFracRoot3PG: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 41,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "roots that turnover",
      unit: "%/yr",
      categoryName: "Tree3O",
      categoryLabel: "Tree species : 3PG : Other",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 259,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fertFracNPPToRoot: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 41,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Fertility factor for the fraction of NPP that goes to the roots",
      unit: "",
      categoryName: "Tree3O",
      categoryLabel: "Tree species : 3PG : Other",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 264,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    modNutrFor0Fert: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 41,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil nutrition modifier when the soil fertility rating is zero",
      unit: "",
      categoryName: "Tree3O",
      categoryLabel: "Tree species : 3PG : Other",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 265,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    avgTreeAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Average age of trees",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 142,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxTreeAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Age of oldest trees",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 143,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemVolInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stem volume",
      unit: "m3/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 144,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Branch mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 145,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Bark mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 146,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Leaf mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 147,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    cortMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Coarse root mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 148,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    firtMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Fine root mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 149,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    stemM3PGInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stem mass (3PG)",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 151,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    foliM3PGInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Foliage mass (3PG)",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 152,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rootM3PGInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Root mass (3PG)",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 153,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nStemsInit: {
      high: 2100200300,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 1e-9,
        },
        {
          functionName: "max",
          input: 2100200300,
        },
      ],
      label: "Stems per hectare",
      unit: "1/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 154,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "stems",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 1,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 506,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "branches",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 2,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 507,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "bark",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 3,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 508,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "leaves",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 4,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 509,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    cortNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "coarse roots",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 5,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 510,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    firtNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fine roots",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 6,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 511,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    storNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Nitrogen mass in tree store",
      unit: "kgN/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 512,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tInitStem: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 185,
      defaultValue: "Vol",
      validators: [],
      validatorConfig: [],
      label: "Method of specifying initial sizes of tree components",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "InitStemT",
      eventId: 10,
      tIn: 628,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeExistsInit: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 185,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Trees initially growing in the forest (yes/no)",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1079,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeNmInit: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 2100200300,
        },
      ],
      label: "Name of initial tree species",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1131,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stem mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1137,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Stem",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1138,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    branFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Branch",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1139,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    barkFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Bark",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1140,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    leafFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Leaf",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1141,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    cortFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Coarse root",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1142,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    firtFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Fine root",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1143,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    dFidrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 21,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dDdwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable deadwood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 155,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rDdwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant deadwood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 156,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dBlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable bark litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 157,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rBlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant bark litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 158,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dLlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable leaf litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 159,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rLlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant leaf litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 160,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dCodrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 161,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rCodrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 162,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rFidrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant fine dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 163,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dDdwdNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable deadwood",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 513,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dBlitNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable bark litter",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 514,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dLlitNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable leaf litter",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 515,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dCodrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 516,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dFidrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 517,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dChwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable chopped wood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1044,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rChwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant chopped wood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1045,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dChwdNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable chopped wood",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial : Forest : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1046,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fuelCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "biofuel stocks",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 529,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    paprCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "pulp and paper",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 530,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    packCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "packing wood",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 531,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    furnCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "furniture,poles,and preservatives",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 532,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    fibrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fiberboard",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 533,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    consCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "construction timber",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 534,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    resiCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "mill residue",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 535,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    fuelNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "biofuel",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 536,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    paprNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "pulp and paper",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 537,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    packNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "packing wood",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 538,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    furnNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "furniture,poles,and preservatives",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 539,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fibrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fiberboard",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 540,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    consNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "construction timber",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 541,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    resiNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "mill residue",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 542,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fuelLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "forest biofuel",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 802,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    paprLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "pulp and paper",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 803,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    packLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "packing wood",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 804,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    furnLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "furniture,poles,and preservatives",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 805,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    fibrLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fiberboard",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 806,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    consLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "construction timber",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 807,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    resiLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "mill residue",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 808,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    sDStemCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead stem ",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2158,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDBranCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead branch",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2159,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDBlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead bark",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2160,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDLlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead leaf",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2161,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDCodrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead coarse roots",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2162,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDFidrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead fine roots",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2163,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracSDdwdF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead stem",
      unit: "½ life yrs",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2164,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracSChwdF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead branch",
      unit: "½ life yrs",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2165,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracSBlitF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead bark",
      unit: "½ life yrs",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2166,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracSLlitF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead leaf",
      unit: "½ life yrs",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2167,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracSCodrF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead coarse roots",
      unit: "½ life yrs",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2168,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    bkdnFracSFidrF: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead fine roots",
      unit: "½ life yrs",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2169,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracSDdwdBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead stem breakdown",
      unit: "%",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2170,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracSChwdBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead branch breakdown",
      unit: "%",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2171,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracSBlitBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead bark breakdown",
      unit: "%",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2172,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracSLlitBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead leaf breakdown",
      unit: "%",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2173,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracSCodrBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead coarse roots breakdown",
      unit: "%",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2174,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    atmsFracSFidrBkdnF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      label: "standing dead fine roots breakdown",
      unit: "%",
      categoryName: "TreeSd",
      categoryLabel: "Tree species : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      subSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2175,
      spec: 1,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sopmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 164,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    lrpmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 165,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    mrpmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 166,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    sommCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 167,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    lrmmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 168,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    mrmmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 169,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    micrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 170,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    sopmNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 518,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    sommNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 519,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    micrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "InitFM",
      categoryLabel: "Initial : Forest : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 18,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 520,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    ASWInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Available soil water (ASW)",
      unit: "mm",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 150,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    dpmaCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 172,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    rpmaCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 173,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    biofCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 174,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    biosCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 175,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    humsCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 176,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    TSMDInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Forest topsoil moisture deficit (TSMD)",
      unit: "mm",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 177,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    inrtCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "inert soil",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 178,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    dpmaNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 523,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    rpmaNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 524,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    biofNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 12,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 525,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    biosNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 13,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 526,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    humsNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 14,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 527,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    inrtNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "inert soil",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial : Forest : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 528,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    mulcNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "available nitrogen",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial : Forest : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 171,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    sAmmNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow ammonium (with forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial : Forest : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 237,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    sNtrNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow nitrate (with forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial : Forest : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 238,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    dAmmNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep ammonium (below forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial : Forest : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1083,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    dNtrNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep nitrate (below forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial : Forest : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1378,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: null,
    },
    frFracInit: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 197,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Forest % (manually entered)",
      unit: "%",
      categoryName: "InitWP",
      categoryLabel: "Initial : Whole plot",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 133,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    tFrFracInit: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 197,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of initial forest %",
      unit: "",
      categoryName: "InitWP",
      categoryLabel: "Initial : Whole plot",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrFracT",
      eventId: 10,
      tIn: 606,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: null,
    },
    agePlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Age of trees when planted",
      unit: "yr",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 614,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemVolPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Volume of stems",
      unit: "m3/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 615,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 616,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 617,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 618,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cortMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 619,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    firtMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 620,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 1,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 621,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 2,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 622,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 3,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 623,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 4,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 624,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cortNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 5,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 625,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    firtNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 6,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 626,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    storNMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in plant store",
      unit: "kgN/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 627,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeNmPlnF: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of tree species",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 1133,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tStemPlnF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Method of specifying sizes of components of planted trees",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "InitStemT",
      eventId: 6,
      tIn: 1144,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 1145,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fixPlnF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree planting event",
      unit: "$",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 2050,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    phaPlnF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree planting event",
      unit: "$/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 2051,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    unused2119: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "unused 2119",
      unit: "yr",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 7,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 2119,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tTYFCatInitF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Initial Growth Calibration ",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TYFCatT",
      eventId: 10,
      tIn: 2120,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tTYFCat: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Growth Calibration",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TYFCatT",
      eventId: 6,
      tIn: 2121,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracStemToSDdwdThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracStemToSDdwdThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracStemToSDdwdThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracStemToSDdwdThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracStemToSDdwdThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBranToSDdwdThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBranToSDdwdThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBranToSDdwdThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBranToSDdwdThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBranToSDdwdThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBarkToSBlitThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBarkToSBlitThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBarkToSBlitThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBarkToSBlitThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracBarkToSBlitThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracLeafToSLlitThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracLeafToSLlitThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracLeafToSLlitThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracLeafToSLlitThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracLeafToSLlitThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracCortToSCodrThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracCortToSCodrThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracCortToSCodrThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracCortToSCodrThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracCortToSCodrThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracFirtToSFidrThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracFirtToSFidrThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracFirtToSFidrThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracFirtToSFidrThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracFirtToSFidrThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSDdwdToFuelThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSDdwdToFuelThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSDdwdToFuelThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSDdwdToFuelThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSDdwdToFuelThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSChwdToFuelThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSChwdToFuelThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSChwdToFuelThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSChwdToFuelThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSChwdToFuelThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSBlitToFuelThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSBlitToFuelThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSBlitToFuelThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSBlitToFuelThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSBlitToFuelThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSLlitToFuelThin1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSLlitToFuelThin2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSLlitToFuelThin3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSLlitToFuelThin4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracSLlitToFuelThin5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRWood1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRWood2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRWood3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRWood4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRWood5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRBark1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRBark2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRBark3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRBark4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRBark5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFuel1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFuel2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFuel3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFuel4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFuel5: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    agRegimeId: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    makeProdFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    cemrProdFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    makeDispFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    cemrDispFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    MDispPerProdFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    LProdPerDispFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    CFracProdFuelF: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    idRegimeSP: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    tSpecFrCat: {
      defaultValue: null,
      suffixId: null,
    },
  };

  public tYFSelectOptions = [];

  public readonly speciesType = "tree";

  public onSpeciesIdChanged(id): void {
    const tyfCategoriesControl =
      this.getSelectedSpeciesFormGroup().get("tyfCategories");
    const tYFgrowthCalibrationGroup = this.getLayoutGroup(
      this.layout.groups,
      "tyf-growth-calibration"
    );
    const selectOptions = tyfCategoriesControl.value;
    if (selectOptions.length) {
      tYFgrowthCalibrationGroup.items[0].inputs[0].selectOptions =
        selectOptions;
    }
  }

  public fieldWatcher: FieldWatcher = {
    "configuration:tTreeProd": (newValue: any, { formGroup }) => {
      const isTYF = newValue == "TYF";
      const isIncrements = newValue == "Incr";
      const isExist = formGroup.get("treeExistsInit").value;

      let options = [
        {
          value: "Yield",
          label: "Yields",
        },
      ];

      const npp = {
        value: "NPP",
        label: "Net Primary Production (NPP)",
      };

      if (isIncrements) {
        options.push(npp);
      }

      const growthMetodItem = this.getLayoutItem(
        this.layout.groups,
        "grthModeSP"
      );

      if (growthMetodItem.inputs[0].selectOptions) {
        growthMetodItem.inputs[0].selectOptions = options;
      }

      const IncrementsGroup = this.getLayoutGroup(
        this.layout.groups,
        "increments"
      );

      if (IncrementsGroup) {
        IncrementsGroup.isShown = isIncrements;
      }

      const userDefinedGrowthGroup = this.getLayoutGroup(
        this.layout.groups,
        "userDefinedGrowth"
      );

      if (userDefinedGrowthGroup) {
        userDefinedGrowthGroup.isShown = isTYF;
      }

      const growthCalibrationGroup = this.getLayoutGroup(
        this.layout.groups,
        "growthCalibration"
      );

      const tYFgrowthCalibrationGroup = this.getLayoutGroup(
        this.layout.groups,
        "tyf-growth-calibration"
      );

      if (growthCalibrationGroup) {
        growthCalibrationGroup.isShown = isTYF;
      }

      if (tYFgrowthCalibrationGroup) {
        tYFgrowthCalibrationGroup.isShown = isTYF && isExist;
      }
    },
    tIncrTblF: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const fields = ["incrStemVolF", "incrStemMF", "incrAbgMF"];
      const configurationFormGroup =
        simulationService.configurationService.getFormGroup();
      const isIncrements =
        configurationFormGroup.get("tTreeProd").value == "Incr";

      fields.forEach((f) => {
        const showButton =
          newValue.charAt(0).toLowerCase() + newValue.slice(1) == f;
        formModel[f].isShown = showButton;

        if (showButton && isIncrements) {
          this.addValidatorsInBulk([f], [Validators.required], formGroup);
        } else {
          this.removeValidatorsInBulk([f], [Validators.required], formGroup);
        }
      });
    },
    treeMortOn: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const stemLossGroup = this.getLayoutGroup(
        this.layout.groups,
        "stem-loss-group"
      );

      //stemloss timeseries button
      formModel["stemMortF"].isShown = newValue;

      if (stemLossGroup) {
        stemLossGroup.isShown = newValue;
        if (newValue) {
          this.addValidatorsInBulk(
            [
              "ratioBranMortF",
              "ratioBarkMortF",
              "ratioLeafMortF",
              "ratioCortMortF",
              "ratioFirtMortF",
              "remvAvgAgeMultMortF",
              "remvMaxAgeMultMortF",
              "remvOffsetMortF",
              "stemMortF",
            ],
            [Validators.required],
            this.getSelectedSpeciesFormGroup()
          );
        } else {
          this.removeValidatorsInBulk(
            [
              "ratioBranMortF",
              "ratioBarkMortF",
              "ratioLeafMortF",
              "ratioCortMortF",
              "ratioFirtMortF",
              "remvAvgAgeMultMortF",
              "remvMaxAgeMultMortF",
              "remvOffsetMortF",
              "stemMortF",
            ],
            [Validators.required],
            this.getSelectedSpeciesFormGroup()
          );
        }
      }
    },
    bkdnSensMulcF: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      formModel["bkdnSensTempF"].isShown = newValue;
      formModel["bkdnSensRainF"].isShown = newValue;

      if (newValue) {
        this.addValidatorsInBulk(
          ["bkdnSensTempF", "bkdnSensRainF"],
          [Validators.required],
          this.getSelectedSpeciesFormGroup()
        );
      } else {
        this.removeValidatorsInBulk(
          ["bkdnSensTempF", "bkdnSensRainF"],
          [Validators.required],
          this.getSelectedSpeciesFormGroup()
        );
      }
    },
    tInitStem: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const isExist = formGroup.get("treeExistsInit").value;
      if (!isExist) {
        return;
      }

      const initSpecifyTreeSizeGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-specify-tree-size"
      );

      const massGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-tree-mass"
      );
      const percentTreeAgeBioMassGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-tree-percentage-tree-age-biomass"
      );

      const percentMaxBioMassGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-tree-percentage-maximum-biomass"
      );

      const volGroup = this.getLayoutGroup(this.layout.groups, "init-tree-vol");

      const isNotPercentage = newValue == "Mass" || newValue == "Vol";

      const isVol = newValue == "Vol";

      percentTreeAgeBioMassGroup.isShown = newValue == "FracAge";
      percentMaxBioMassGroup.isShown = newValue == "Frac";
      massGroup.isShown = isNotPercentage;

      volGroup.isShown = isVol;

      formModel["stemMInitF"].isShown = !isVol;

      initSpecifyTreeSizeGroup.isShown = isExist;
    },
  };

  public async watchFields(
    destroySubject$: Subject<void>,
    context: FieldWatcherContext
  ): Promise<void> {
    if (this.fieldWatcher) {
      const watchFields = Object.keys(this.fieldWatcher);
      watchFields.forEach(async (watchField) => {
        //to access form group from the other tabs use ":" as divider
        const splitValues = watchField.split(":");
        const isAccessingOtherTab = splitValues.length > 1;
        const watchFieldName = splitValues[isAccessingOtherTab ? 1 : 0];
        const watchTabName = isAccessingOtherTab ? splitValues[0] : null;

        //TO DO TREE and CROP TABS ARE NOT ACCESSILBE WITH GETFORMGROUP
        const fieldControl: AbstractControl = isAccessingOtherTab
          ? this.simulationService[watchTabName + "Service"]
              .getFormGroup()
              .get(watchFieldName)
          : this.getSelectedSpeciesFormGroup().get(watchFieldName);

        fieldControl.valueChanges
          .pipe(takeUntil(destroySubject$), startWith(fieldControl.value))
          .subscribe((newValue) => {
            const fieldWatcherContext: FieldWatcherContext = {
              formGroup: context.formGroup,
              formModel: context.formModel,
              layout: context.layout,
              simulationService: this.simulationService,
              fieldChangedEmitter: context.fieldChangedEmitter,
            };
            this.fieldWatcher[watchField](newValue, fieldWatcherContext);
          });
      });
    }
  }

  public async applySpeciesFormData(speciesForest, isFromServer?: boolean) {
    if (!speciesForest) {
      return;
    }
    const speciesFormGroup = this.createFormGroup(this.speciesFormModel);
    //const speciesForestObject=speciesForest.$;
    const allocationDetails = speciesForest.Allocations;
    const eventQ = speciesForest.EventQ;
    const forestDetails = speciesForest.ForestDetails;
    const forestInit = speciesForest.ForestInit;
    const timeSeries = [{ TimeSeries: speciesForest.TimeSeries }];
    const growthDetails = speciesForest.Growth;
    const products = speciesForest.Products;
    const nameGrade = speciesForest.nmGradeF;
    const notes = speciesForest.notesSP;

    if (forestDetails) {
      const frOjb = forestDetails[0].$;
      const varNCDataF = forestDetails[0].VarNCDataF;
      const thinDest = forestDetails[0].ThinDest;
      const thinInfo = forestDetails[0].ThinInfo;

      this.applyXmlValuesToFormGroup(frOjb, speciesFormGroup);
      if (varNCDataF) {
        varNCDataF.forEach((varNCData) => {
          const varNCDataObj = {};
          const fieldPrefix = varNCData.$.id.slice(0, -2);
          //convert keys before applying data
          Object.entries(varNCData.$).forEach((keyValue) => {
            const key = keyValue[0];
            const value = keyValue[1];
            if (key !== "id") {
              varNCDataObj[
                fieldPrefix + key.charAt(0).toUpperCase() + key.slice(1)
              ] = value;
            }
          });

          this.applyXmlValuesToFormGroup(varNCDataObj, speciesFormGroup);
        });
      }

      if (thinDest) {
        thinDest.forEach((hd) => {
          const hdObj = {};
          const fieldSuffix = hd.$.id.slice(-1);

          //convert keys before applying data
          Object.entries(hd.$).forEach((keyValue) => {
            const key = keyValue[0];
            const value = keyValue[1];
            if (key !== "id") {
              hdObj[key + fieldSuffix] = value;
            }
          });

          this.applyXmlValuesToFormGroup(hdObj, speciesFormGroup);
        });
      }

      if (thinInfo) {
        thinInfo.forEach((ti) => {
          const tiObj = {};

          const fieldNumberSuffix = ti.$.id.slice(-1);

          ti.Price.forEach((p) => {
            //convert keys before applying data
            const fieldSuffix =
              p.$.id.charAt(0).toUpperCase() + p.$.id.slice(1);

            Object.entries(p.$).forEach((keyValue) => {
              const key = keyValue[0];
              const value = keyValue[1];
              if (key !== "id" && key !== "baseYearPR") {
                tiObj[key + fieldSuffix + fieldNumberSuffix] = value;
              }

              if (key == "baseYearPR") {
                tiObj[key + fieldNumberSuffix] = value;
              }
            });

            this.applyXmlValuesToFormGroup(tiObj, speciesFormGroup);
          });
        });
      }
    }

    if (forestInit) {
      const tree = forestInit[0].InitTreeF;
      const debris = forestInit[0].InitDebrF;
      const products = forestInit[0].InitProdF;
      const standingDeads = forestInit[0].InitSDwdF;
      [tree, debris, products, standingDeads].forEach((init) => {
        if (init) {
          this.applyXmlValuesToFormGroup(init[0].$, speciesFormGroup);
        }
      });
    }

    //time series fields
    [allocationDetails, growthDetails, timeSeries].forEach((tsItem) => {
      if (tsItem) {
        if (tsItem[0].TimeSeries) {
          tsItem[0].TimeSeries.forEach((ts) => {
            const formField = speciesFormGroup.get(ts.$.tInTS);
            if (formField) {
              formField.setValue(ts);
            } else {
              console.warn("Allocation Field " + ts.$.tInTS + " not found");
            }
          });
        }
      }
    });

    if (products) {
      products[0].Product.forEach((p) => {
        const obj = {};
        const fieldSuffix =
          p.$.id.charAt(0).toUpperCase() + p.$.id.slice(1) + "F";
        //convert keys before applying data
        Object.entries(p.$).forEach((keyValue) => {
          const key = keyValue[0];
          const value = keyValue[1];
          if (key !== "id") {
            obj[key + fieldSuffix] = value;
          }
        });

        this.applyXmlValuesToFormGroup(obj, speciesFormGroup);
      });
    }

    if (nameGrade) {
      nameGrade[0].s.forEach((ng) => {
        const formField = speciesFormGroup.get(ng._);
        if (formField) {
          formField.setValue(ng.$.id);
        } else {
          console.warn("Name Grade Field " + ng._ + " not found");
        }
      });
    }

    this.applyXmlValuesToFormGroup(speciesForest.$, speciesFormGroup);

    //eventQ
    if (eventQ[0]) {
      const events = eventQ[0].Event;
      if (events) {
        const eventQFormArray = speciesFormGroup.get(
          "eventQ"
        ) as UntypedFormArray;

        events.forEach((event) => {
          const eventType = event.$.tEV;
          const eventFormsService = this.simulationService.eventFormsService;
          const service = eventFormsService.getEventService(eventType);
          const eventFormGroup = this.setEventQFormGroup(event, service);

          //manually trigger watchers to reapply validations
          this.manuallyTriggerWatchingFields(service.fieldWatcher, {
            formGroup: eventFormGroup,
            formModel: service.formModel,
            layout: service.layout,
            simulationService: this.simulationService,
          });

          eventQFormArray.push(eventFormGroup);
        });
      }
      this.getFormGroup().get("headerState").setValue(eventQ[0].HeaderState[0]);
      this.getFormGroup().get("showEvT").setValue(eventQ[0].showEvT[0]);
    }

    if (notes) {
      speciesFormGroup.get("notesSP").setValue(notes[0]);
    }

    const speciesIds = this.getSpecies().value.map((s) => s.idSP);
    const id = this.generateSpeciesId(speciesIds);
    speciesFormGroup.get("idSP").setValue(id);
    speciesFormGroup.get("tSP").setValue("Tree");

    //idSP from downloaded species will be assigned to idRegimeSP in the from
    speciesFormGroup
      .get("idRegimeSP")
      .setValue(
        isFromServer ? speciesForest.$.idSP : speciesForest.$.idRegimeSP
      );

    this.addSpecies(speciesFormGroup);
    //for replacing in the future
    // speciesFormGroup.get("dbId").setValue(speciesForest.$.idSP);
    // speciesFormGroup.get("tSP").setValue("Tree");
    // //if theres existing dbId in formArray, it will update the current formGroup
    // //this happens when importing a plotfile
    // const existingSpecies = this.getSpecies().controls.find(
    //   (s) => s.get("idRegimeSP").value == speciesForest.$.idRegimeSP
    // );
    // if (existingSpecies) {
    //   existingSpecies.patchValue(speciesFormGroup.getRawValue());
    //   return;
    // }
    // const speciesIds = this.getSpecies().value.map((s) => s.idSP);
    // const id = this.generateSpeciesId(speciesIds);
    // speciesFormGroup.get("idSP").setValue(id);

    // this.addSpecies(speciesFormGroup);
  }

  public replaceIdRegimeSPFromDownloadedSpecies(response) {
    if (!response?.SpeciesForest[0]) {
      return;
    }
    const speciesDetails = response?.SpeciesForest[0].$;

    const speciesFormGroup = this.getSpecies().controls.find(
      (s) => s.get("nmSP").value == speciesDetails.nmSP
    );

    if (speciesFormGroup) {
      //check if current selected species id = original idRegimeSP
      if (
        speciesFormGroup.get("idRegimeSP").value ==
        this.selectedSpeciesId$.getValue()
      ) {
        this.selectedSpeciesId$.next(speciesDetails.idSP);
      }
      speciesFormGroup.get("idRegimeSP").setValue(speciesDetails.idSP);
    }
  }

  //public getTYFCategoryBySpeciesIdRegimeSP(idRegimeSP) {
  //   //const species = this.getSpeciesByIdRegimeSP(dbId);
  //   const speciesDetails = this.getSpeciesDetailsByIdRegimeSP(idRegimeSP);
  //   if (
  //     speciesDetails &&
  //     speciesDetails.SpeciesForest[0]?.Growth &&
  //     speciesDetails.SpeciesForest[0]?.Growth[0]["TYFParameters"]
  //   ) {
  //     const tYFCategories = speciesDetails.SpeciesForest[0].Growth[0][
  //       "TYFParameters"
  //     ][0]["TYFCategory"].map((tyfC) => tyfC.$.tTYFCat);

  //     const tYFSelectOptions = Object.entries(Constants.TYF_CATEGORY_MAP).map(
  //       (tyf) => {
  //         return {
  //           value: tyf[0],
  //           label: tyf[1],
  //         };
  //       }
  //     );
  //     //always keep Custom option
  //     return tYFSelectOptions.filter(
  //       (o) => tYFCategories.includes(o.value) || o.value == "Custom"
  //     );
  //   }
  // }

  public getTYFCategoryBySpeciesNmSP(nmSP) {
    try {
      const speciesDetails = this.getSpeciesDetailsByNmSP(nmSP);
      if (
        speciesDetails &&
        speciesDetails.SpeciesForest[0]?.Growth &&
        speciesDetails.SpeciesForest[0]?.Growth[0]["TYFParameters"]
      ) {
        const tYFCategories = speciesDetails.SpeciesForest[0].Growth[0][
          "TYFParameters"
        ][0]["TYFCategory"].map((tyfC) => tyfC.$.tTYFCat);

        const tYFSelectOptions = Object.entries(Constants.TYF_CATEGORY_MAP).map(
          (tyf) => {
            return {
              value: tyf[0],
              label: tyf[1],
            };
          }
        );
        //always keep Custom option
        return tYFSelectOptions.filter(
          (o) => tYFCategories.includes(o.value) || o.value == "Custom"
        );
      }
    } catch (error) {
      this.simulationService.messageService.addAlert({
        type: "danger",
        msg: `TYF Category not found from species: ${nmSP}.`,
      });
      return null;
    }
  }

  public getTYFCategory(): TableComponentData {
    try {
      const speciesDetails: TreeSpecies = this.getSelectedSpeciesDetails();
      if (
        speciesDetails &&
        speciesDetails.SpeciesForest[0]?.Growth &&
        speciesDetails.SpeciesForest[0]?.Growth[0]["TYFParameters"]
      ) {
        const tYFCategories =
          speciesDetails.SpeciesForest[0].Growth[0]["TYFParameters"][0][
            "TYFCategory"
          ];
        const rows = tYFCategories
          .map((tyfC) => {
            return [
              Constants.TYF_CATEGORY_MAP[tyfC.$.tTYFCat],
              tyfC.$.tyf_G,
              tyfC.$.tyf_r,
            ];
          })
          .sort((a, b) => a[0] - b[0]);

        const data = {
          headers: ["Calibration", "G", "r or y"],
          rows: rows,
        };

        this.setTYFSelectOPtions(tYFCategories.map((tyfC) => tyfC.$.tTYFCat));

        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  protected setTYFSelectOPtions(tYFCategories: string[]) {
    this.tYFSelectOptions = Object.entries(Constants.TYF_CATEGORY_MAP).map(
      (tyf) => {
        return {
          value: tyf[0],
          label: tyf[1],
        };
      }
    );
    //always keep Custom option
    const currentOptions = this.tYFSelectOptions.filter(
      (o) => tYFCategories.includes(o.value) || o.value == "Custom"
    );

    if (
      !this.getSelectedSpeciesFormGroup().get("tyfCategories").value?.length
    ) {
      this.getSelectedSpeciesFormGroup()
        .get("tyfCategories")
        .setValue(currentOptions);

      const tYFgrowthCalibrationGroup = this.getLayoutGroup(
        this.layout.groups,
        "tyf-growth-calibration"
      );
      tYFgrowthCalibrationGroup.items[0].inputs[0].selectOptions =
        currentOptions;
    }
  }

  public getFormModel() {
    return this.speciesFormModel;
  }

  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    // const xmlObject = {
    //   SpeciesForest: writeXmlInitTreeFields,
    //   Prodcut: writeXmlProductsFieldConcats,
    //   ForestDetails: writeXmlInitSDwdFFields,
    //   InitDebrF: writeXmlInitDebrFFields,
    //   InitDebrA: writeXmlInitDebrAFields,
    //   InitMulcF: writeXmlInitMulcFFields,
    //   InitMulcA: writeXmlInitMulcAFields,
    //   InitSoilF: writeXmlInitSoilFFields,
    //   InitSoilA: writeXmlInitSoilAFields,
    //   InitMnrlF: writeXmlInitMnrlFFields,
    //   InitMnrlA: writeXmlInitMnrlAFields,
    //   InitProdF: writeXmlInitProdFFields,
    //   InitProdA: writeXmlInitProdAFields,
    //   InitWholP: writeXmlInitWholPFields,
    // };

    // for (const xmlKey in xmlObject) {
    //   if (xmlObject[xmlKey].includes(programmingName)) {
    //     return { xPath: "//" + xmlKey, id: null };
    //   }
    // }
    return null;

    // if (this.formModel[programmingName].dataType == 6) {
    //   return { xPath: "//TimeSeries", id: null };
    // } else {
    //   return { xPath: "//Site", id: null };
    // }
  }

  public readXmlObject(): void {
    //all species will be assigned by data builder's tab
  }

  public writeXmlObject() {
    const formData = this.getFormGroup().getRawValue();

    const SpeciesForest = formData.species.map((spFormData) => {
      const speciesObject = {
        $: {},
        notesSP: [],
        Products: [{ Product: [] }],
        ForestDetails: [
          {
            $: {},
            VarNCDataF: [],
            ThinDest: [],
            ThinInfo: [],
          },
        ],
        Growth: [
          { TYFParameters: [{ $: {}, TYFCategory: [] }], TimeSeries: [] },
        ],
        Allocations: [{ TimeSeries: [] }],
        TimeSeries: [],
        ForestInit: [
          {
            InitTreeF: [],
            InitSDwdF: [],
            InitDebrF: [],
            InitProdF: [],
          },
        ],
        EventQ: [
          {
            $: {},
            Event: [],
            HeaderState: [],
            showEvT: "",
          },
        ],
        nmGradeF: [{ s: [] }],
      };

      speciesObject.$ = this.getFormValueObjectForXmlExport(
        this.writeXmlBaseFields,
        spFormData,
        this.speciesFormModel
      );

      speciesObject.notesSP[0] = spFormData.notesSP;

      //Products
      this.writeXmlProductsFieldConcats.forEach((concat) => {
        const fieldValues = this.getFormValueObjectForXmlExport(
          this.writeXmlProductsFields,
          spFormData,
          this.speciesFormModel,
          concat
        );

        speciesObject.Products[0].Product.push({ $: fieldValues });
      });

      //ForestDetails
      speciesObject.ForestDetails[0].$ = this.getFormValueObjectForXmlExport(
        this.writeXmlForestDetailsBaseFields,
        spFormData,
        this.speciesFormModel
      );

      //nameGrade will change the value of the following Objects
      this.writeXmlNameGradeFields.forEach((nameGrade, index) => {
        const ng = nameGrade.slice(0, nameGrade.length - 1);
        const ngId = nameGrade.charAt(nameGrade.length - 1);

        //ThinDest
        const thinDestFieldValues = this.getFormValueObjectForXmlExport(
          this.writeXmlThinDestFields,
          spFormData,
          this.speciesFormModel,
          {
            position: "append",
            value: ngId,
            id: nameGrade,
          }
        );

        speciesObject.ForestDetails[0].ThinDest.push({
          $: thinDestFieldValues,
        });

        //ThinInfo
        speciesObject.ForestDetails[0].ThinInfo;
        const thinInfoObject = {
          $: { id: nameGrade },
          Price: [],
        };

        this.writeXmlThinInfoFieldConcats.forEach((concat) => {
          let combinedConcat = { ...concat };
          combinedConcat.value = combinedConcat.value + ngId;

          const fieldValues = this.getFormValueObjectForXmlExport(
            this.writeXmlThinInfoFields,
            spFormData,
            this.speciesFormModel,
            combinedConcat
          );

          thinInfoObject.Price.push({ $: fieldValues });
        });

        speciesObject.ForestDetails[0].ThinInfo.push(thinInfoObject);
        speciesObject.nmGradeF[0].s.push({
          $: { id: index + 1 }, //id is not from the name
          _: nameGrade,
        });
      });

      //VarNCDataF
      this.writeXmlVarNCDataFFieldConcats.forEach((concat) => {
        const fieldValues = this.getFormValueObjectForXmlExport(
          this.writeXmlVarNCDataFFields,
          spFormData,
          this.speciesFormModel,
          concat
        );

        speciesObject.ForestDetails[0].VarNCDataF.push({ $: fieldValues });
      });

      //Growth
      this.writeXmlGrowthTimeseries.forEach((ts) => {
        speciesObject.Growth[0].TimeSeries.push(spFormData[ts]);
      });

      //TYFParameters
      const species = this.getSpeciesByIdRegimeSP(spFormData.idRegimeSP);
      const speciesDetails = this.getSpeciesDetailsByNmSP(species?.nmSP);

      if (speciesDetails && speciesDetails.SpeciesForest[0]?.Growth) {
        const tYFCategories =
          speciesDetails.SpeciesForest[0].Growth[0]["TYFParameters"];
        speciesObject.Growth[0].TYFParameters = tYFCategories;
      } else {
        //default for custom trees
        speciesObject.Growth[0].TYFParameters = [
          {
            $: { count: "1", idSP: spFormData.idSP },
            TYFCategory: [
              { $: { tTYFCat: "Custom", tyf_G: "10.0", tyf_r: "1.0" } },
            ],
          },
        ];
      }

      //Allocations
      this.writeXmlAllocationsTimeseriesFields.forEach((ts) => {
        speciesObject.Allocations[0].TimeSeries.push(spFormData[ts]);
      });

      this.writeXmlBaseTimeseriesFields.forEach((ts) => {
        speciesObject.TimeSeries.push(spFormData[ts]);
      });

      //ForestInit
      speciesObject.ForestInit[0].InitTreeF.push({
        $: this.getFormValueObjectForXmlExport(
          this.writeXmlInitTreeFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      speciesObject.ForestInit[0].InitSDwdF.push({
        $: this.getFormValueObjectForXmlExport(
          this.writeXmlInitStandingDeadFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      speciesObject.ForestInit[0].InitDebrF.push({
        $: this.getFormValueObjectForXmlExport(
          this.writeXmlInitDebrisFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      speciesObject.ForestInit[0].InitProdF.push({
        $: this.getFormValueObjectForXmlExport(
          this.writeXmlInitProductFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      //EventQ
      speciesObject.EventQ[0].$["count"] = spFormData.eventQ.length;
      speciesObject.EventQ[0].HeaderState[0] = formData.headerState;
      speciesObject.EventQ[0].showEvT = formData.showEvT;
      speciesObject.EventQ[0].Event = spFormData.eventQ.map((evFormData) => {
        const eventType = evFormData.tEV;
        // if (eventType == "FirF") {
        //   console.log("WRITE: before convert clearEV=", evFormData.clearEV);
        //// }
        const eventFormsService = this.simulationService.eventFormsService;

        const eventTypeService = eventFormsService.getEventService(eventType);
        return eventTypeService.writeXmlObject(evFormData);
      });
      // console.log("WRITE:" + speciesObject.EventQ[0].Event);
      return speciesObject;
    });

    const xmlObject = {
      $: {
        count: SpeciesForest.length,
        showOnlyInUse: String(formData.showOnlyInUse),
      },
      SpeciesForest,
    };

    return {
      SpeciesForestSet: [xmlObject],
    };
  }
}
