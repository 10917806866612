import { Component } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";
import { delay, takeUntil, tap } from "rxjs/operators";
import { Constants } from "src/app/shared/constants";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-trees",
  templateUrl: "./trees.component.html",
  styleUrls: ["./trees.component.scss"],
})
export class TreesComponent extends BaseFlowComponent {
  public selectedSpeciesIndex: number = null;

  ngOnInit(): void {
    this.formGroup = this.service.getFormGroup();
    this.layout = this.service.layout;
    this.modifiers = this.service.modifiers;

    this.service.selectedSpeciesId$
      .pipe(
        takeUntil(this.destroySubject$),
        delay(0),
        tap(() => {
          const index = this.service.getSelectedSpeciesIndex();
          this.selectedSpeciesIndex = index;

          if (this.species.controls[index]) {
            this.service.watchFields(this.destroySubject$, {
              formGroup: this.service.getSelectedSpeciesFormGroup(),
              formModel: this.service.speciesFormModel,
              layout: this.layout,
              fieldChangedEmitter: this.fieldChanged,
            });
          }
        })
      )
      .subscribe();
    this.service.setSpeciesInUse();
  }

  get species() {
    return this.formGroup.get("species") as UntypedFormArray;
  }

  public addSpecies(): void {
    this.service.speciesButtonClicked({ event: "new" });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
