import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SideBarComponent } from "./side-bar/side-bar.component";
import { RouterModule } from "@angular/router";
import { AvatarComponent } from "../shared/components/avatar/avatar.component";
import { DrawerComponent } from "./drawer/drawer/drawer.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    AvatarComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    AvatarComponent,
  ],
})
export class LayoutModule {}
