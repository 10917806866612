import { Injectable, Injector } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ModalService } from "src/app/shared/services/modal.service";

@Injectable({
  providedIn: "root",
})
export class FlyOverPanelService {
  private readonly dynamicComponentMap = {
    DataVisualiserComponent: import(
      "../../data-visualiser/data-visualiser.component"
    ).then(({ DataVisualiserComponent }) => DataVisualiserComponent),
    DecayRateComponent: import("../../decay-rate/decay-rate.component").then(
      ({ DecayRateComponent }) => DecayRateComponent
    ),
    SpeciesEventsComponent: import(
      "../../species-events/species-events.component"
    ).then(({ SpeciesEventsComponent }) => SpeciesEventsComponent),
  };

  public isExpanded$ = new BehaviorSubject<boolean>(false);

  public activeComponent$: Promise<any>;
  public flyOverPanelInjector: Injector;

  public currentLoadedComponent = null;

  constructor(private injector: Injector, private modalService: ModalService) { }

  public expand({ comp$, data }) {
    this.activeComponent$ = comp$;
    this.createInjector(data);
    this.isExpanded$.next(true);
  }

  public closePanel() {
    this.isExpanded$.next(false);
    this.activeComponent$ = null;
    this.currentLoadedComponent = null;
  }

  private createInjector(data) {
    this.flyOverPanelInjector = Injector.create({
      providers: [{ provide: "componentData", useValue: data }],
      parent: this.injector,
    });
  }

  public async linkToComponent(
    input,
    formGroupInstance,
    service
  ): Promise<void> {
    const componentInputs = {};
    if (
      input.component == "DataVisualiserComponent" &&
      !this.canActivateTimeSeries(input, service)
    ) {
      const message = `  <p>Enter this data as a series of events rather than as a time series. See the 'Events' page.</p>
      <p>Select the style of data entry via the 'Events or Time Series' button on the 'Configuration' page.</p>`;

      await this.modalService.openConfirmModal(message, true);

      return;
    }

    if (input.componentInputs) {
      input.componentInputs.forEach(async (ci) => {
        if (ci.formKey) {
          const formControl = formGroupInstance.get(ci.formKey);

          componentInputs[ci.inputKey] = ci.isObservable
            ? formControl
            : formControl.value;
        } else if (ci.variable) {
          componentInputs[ci.inputKey] = ci.payload
            ? service[ci.variable](ci.payload)
            : service[ci.variable];
        } else if (ci.value) {
          componentInputs[ci.inputKey] = ci.value;
        } else if (ci.method) {
          componentInputs[ci.inputKey] = await service[ci.method](
            ci.payload || undefined
          );
        }
      });
    }

    this.currentLoadedComponent = input.component;

    this.expand({
      comp$: this.dynamicComponentMap[input.component],
      data: componentInputs,
    });
  }

  protected canActivateTimeSeries(input, service): boolean {
    //DocModalEdit.cpp line 163
    const programmingName = input.programmingName;

    const configFormGroup = service.getConfigurationFormGroup();
    const eventIrrF = configFormGroup.get("userEventIrrF").value;
    const eventIrrA = configFormGroup.get("userEventIrrA").value;
    const eventManA = configFormGroup.get("userEventManA").value;
    const eventManF = configFormGroup.get("userEventManF").value;

    // (cL->eventNFeF && tIn == kin_mnrlNMFromOffsF)
    //          || (cL->eventNFeA && tIn == kin_mnrlNMFromOffsA)

    if (
      (eventIrrA && programmingName == "defnitIrrigA") ||
      (eventIrrA && programmingName == "conditIrrigA") ||
      (eventIrrF && programmingName == "defnitIrrigF") ||
      (eventIrrF && programmingName == "conditIrrigF") ||
      (eventManF && programmingName == "manuCMFromOffsF") ||
      (eventManA && programmingName == "manuCMFromOffsA")
    ) {
      return false;
    }
    return true;
  }
}
