import { Component, OnInit } from "@angular/core";
import { LogEntry } from "src/app/plot/models";

@Component({
  selector: "fc-log-list",
  templateUrl: "./log-list.component.html",
  styleUrls: ["./log-list.component.scss"],
})
export class LogListComponent implements OnInit {
  private service;

  constructor() {}

  ngOnInit(): void {}

  public getLogList() {
    return this.service.getFormGroup().get("logEntries").value;
  }

  public convertTime(timeStamp: string): string {
    const moment = this.service.simulationService.helperService.getMoment();
    return moment.unix(timeStamp).format();
  }

  public getLogMessage(log: LogEntry) {
    const logType = this.service.logInfoMap[log.tMsgLE];
    if (logType && logType.getMessage) {
      return logType.getMessage.apply(logType, log.givenArgs);
    }
    return "Message function not found";
  }
}
