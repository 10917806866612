import { Injectable } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";

import { FormModel } from "src/app/shared/models";
import { BehaviorSubject } from "rxjs";
import { Validators } from "@angular/forms";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

@Injectable({
  providedIn: "root",
})
export class CropPlanting2020Service extends BaseEventFormService {
  public readonly categoryIds = [73, 71];
  public readonly selectedSpeciesId$ = new BehaviorSubject<number>(-1); //for placeholder select
  public readonly pageHelpLink = "161_Plant%20Crop.htm";
  public layout: FormLayout = {
    label: "Plant crop",
    groups: [
      {
        showErrors: true,
        label: "Species",
        id: "species-label-group",
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Species",
            inputs: [
              {
                element: "text",
                method: "getSelectedSpeciesName",
                payload: "crop",
              },
            ],
          },
        ],
      },
      {
        label: null,
        id: "species-selector-group",
        isShown: false,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SpeciesSelectorComponent",
                componentInputs: [
                  {
                    inputKey: "formGroupInstance",
                    method: "getSpeciesFormGroup",
                    payload: "crop",
                  },
                  {
                    inputKey: "selectedId$",
                    variable: "selectedSpeciesId$",
                    isObservable: true,
                  },
                  {
                    inputKey: "selectOnly",
                    value: true,
                  },
                  {
                    inputKey: "type",
                    value: "crop",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "speciesSelected",
                    method: "selectSpecies",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isShown: true,
        id: "crop-planting-crop-masses",
        label: "Masses [dmt/ha]",
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "FormItemTableComponent",
                componentInputs: [
                  {
                    inputKey: "caption",
                    value: "Masses [dmt/ha]",
                  },

                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      headers: [],
                      rows: [
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Grains, buds, fruit (GBF)",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "gbfrMPlnA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Stalks",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "stlkMPlnA",
                          },
                        ],

                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Leaves",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "leafMPlnA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Coarse roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "cortMPlnA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Fine roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "firtMPlnA",
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isShown: true,
        label: "Ages [yr]",
        id: "agePlnA-group",
        items: [
          {
            label: "",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "agePlnA",
              },
              {
                element: "text",
                value: "Crop",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        label: "Growth period",
        id: "growthPeriod-group",
        items: [
          {
            label: "",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "growthPeriod",
              },
              {
                element: "text",
                value: "Days",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    runtimeGrowthPeriod: {
      defaultValue: null,
      suffixId: null,
    },
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecA",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    agePlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      isShown: true,
      isDigest: true,
      isExplorer: true,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Age of crops when planted",
      unit: "yr",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 629,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "grains-buds-fruit",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 630,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "stalks",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 631,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "leaves",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 632,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "coarse roots",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 633,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fine roots",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 634,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 7,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 635,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 8,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 636,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 9,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 637,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 10,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 638,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 11,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 639,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    storNMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in plant store",
      unit: "kgN/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 640,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cropNmPlnA: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Name of crop species",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 1134,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fixPlnA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "crop planting event",
      unit: "$",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 2052,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaPlnA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "crop planting event",
      unit: "$/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 2053,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    growthPeriod: {
      high: 999,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "required",
        },
      ],
      label: "Age of crop maturity",
      unit: "yr",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 2144,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: null,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "PlnA",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
  };

  // public fieldWatcher: FieldWatcher = {
  //   growthPeriod: (newValue: any, { formGroup, formModel }) => {
  //     const toggleFields = ["growthPeriod", "agePlnA"];
  //     const shouldShowFields = newValue && newValue > 0;

  //     toggleFields.forEach((f: string) => {
  //       formModel[f].isShown = shouldShowFields;
  //     });
  //   },
  // };

  public selectSpecies({ id, type, name }) {
    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByIdRegimeSP(
      "crop",
      id
    );

    this.selectedSpeciesId$.next(id);

    //Event is using app idSP instead idRegimeSP
    this.formGroup
      .get("idSP")
      .setValue(
        selectedSpeciesFormGroup
          ? selectedSpeciesFormGroup.get("idSP").value
          : null
      );
    this.formGroup.get("cropNmPlnA").setValue(name);

    this.formGroup
      .get("nmEV")
      .setValue(
        this.simulationService.eventFormsService.getEventDescription(
          this.formGroup.getRawValue()
        )
      );

    //  growthPeriod
    let shouldShowFields = false;

    //const speciesFormGroup = this.getSpeciesFormGroupByIdRegimeSP("crop", id);
    if (id !== -1 && selectedSpeciesFormGroup) {
      const growthMethod = selectedSpeciesFormGroup.get("grthModeSP").value;

      shouldShowFields = growthMethod !== "Perennial";
    }

    const toggleGroups = [
      this.getLayoutGroup(this.layout.groups, "growthPeriod-group"),
      this.getLayoutGroup(this.layout.groups, "agePlnA-group"),
    ];

    toggleGroups.forEach((g: FormGroupElement) => {
      g.isShown = shouldShowFields;
    });

    this[
      shouldShowFields
        ? "addDefaultValidatorsInBulk"
        : "removeDefaultValidatorsInBulk"
    ](["growthPeriod", "agePlnA"], this.formGroup, this.formModel);
  }

  public setEventPageLogic(formData, isEventPage) {
    super.setEventPageLogic(formData, isEventPage);

    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByName(
      "crop",
      formData?.cropNmPlnA
    );

    if (!isEventPage) {
      this.formGroup
        .get("cropNmPlnA")
        .setValue(selectedSpeciesFormGroup.get("nmSP").value);

      return;
    }

    if (isEventPage) {
      this.addDefaultValidatorsInBulk(
        ["cropNmPlnA"],
        this.getFormGroup(),
        this.formModel
      );

      this.minimumRequiredFields = [
        ...this.baseMinimumRequiredFields,
        {
          field: "cropNmPlnA",
          label: "Species",
        },
      ];
    }

    const speciesLabelGroup = this.getLayoutGroup(
      this.layout.groups,
      "species-label-group"
    );
    const speciesSelectorGroup = this.getLayoutGroup(
      this.layout.groups,
      "species-selector-group"
    );

    speciesLabelGroup.isShown = false;
    speciesSelectorGroup.isShown = true;
    const name = this.formGroup.get("cropNmPlnA").value;
    if (formData.idSP) {
      this.selectSpecies({
        id: selectedSpeciesFormGroup
          ? selectedSpeciesFormGroup.get("idRegimeSP").value
          : -1,
        type: null,
        name: name,
      }); //apply species id so selector will work
    }

    if (formData.idSP == null || this.selectedSpeciesId$.getValue() == -1) {
      const toggleGroups = [
        this.getLayoutGroup(this.layout.groups, "growthPeriod-group"),
        this.getLayoutGroup(this.layout.groups, "agePlnA-group"),
      ];

      toggleGroups.forEach((g: FormGroupElement) => {
        g.isShown = false;
      });
    }
  }

  public insertStandardValues(result: any, formData: any): void {
    this.formGroup.patchValue(formData);

    const id = this.simulationService.cropsService.getSpeciesByIdRegimeSP(
      formData.idSP
    )?.idRegimeSP;
    this.selectSpecies({ id: id, type: null, name: result.speciesName });

    if (result.isInertEventName) {
      this.formGroup.get("nmEV").setValue(result.eventName);
    }
  }

  public writeXmlObject(formData): object {
    const basePlnAFields = [
      "agePlnA",
      "gbfrMPlnA",
      "stlkMPlnA",
      "leafMPlnA",
      "cortMPlnA",
      "firtMPlnA",
      "gbfrNCRatioPlnA",
      "stlkNCRatioPlnA",
      "leafNCRatioPlnA",
      "cortNCRatioPlnA",
      "firtNCRatioPlnA",
      "storNMPlnA",
      "fixPlnA",
      "phaPlnA",
      "growthPeriod",
      "cropNmPlnA",
    ];

    const xmlObject = {
      $: {},
      notesEV: [],
      PlnA: [{ $: {} }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.PlnA[0].$ = this.getFormValueObjectForXmlExport(
      basePlnAFields,
      formData
    );

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
