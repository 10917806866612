import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-add-scenario-form",
  templateUrl: "./add-scenario-form.component.html",
  styleUrls: ["./add-scenario-form.component.scss"],
})
export class AddScenarioFormComponent
  extends BaseModalFormComponent
  implements OnInit
{
  public scenarioName: string;
  public notes: string;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    notes: new UntypedFormControl(null),
  });

  ngOnInit(): void {
    if (this.scenarioName) {
      this.formGroup.get("name").setValue(this.scenarioName);
    }

    if (this.notes) {
      this.formGroup.get("notes").setValue(this.notes);
    }
  }
}
