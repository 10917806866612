<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1 class="h1">User Profile ({{ userProfile?.status }})</h1>

    <form [formGroup]="formGroup">
      <div class="input-container">
        <label for="user-name" class="form-label">User Name</label>

        <input
          id="user-name"
          form-field
          type="text"
          class="form-control form-control-sm"
          autocomplete="off"
          [value]="userProfile?.userName || ''"
          disabled
        />
      </div>
      <div class="input-container">
        <label for="user-email" class="form-label">User email</label>

        <input
          id="user-email"
          form-field
          type="text"
          class="form-control form-control-sm"
          autocomplete="off"
          [value]="userProfile?.userEmailId"
          disabled
        />
      </div>
      <!-- <div class="form-button-group">
      <button
        type="button"
        (click)="closeForm()"
        class="btn btn-outline-primary btn-sm"
      >
        Cancel
      </button>
      <fc-button
        [disabled]="formGroup.pristine || formGroup.invalid"
        [inputText]="formData ? 'Update ' + type : 'Create ' + type"
        [isLoading$]="isLoading$()"
        [isSuccess]="isRequestSucceeded"
        [successText]="'Done'"
        (onClick)="submitFolder(formData ? 'update' : 'create')"
      >
      </fc-button>
    </div> -->

      <div class="title-group">
        <h2 class="h2">Current access</h2>
        <button
          *ngIf="isModifyAccess == false"
          type="button"
          (click)="modifyAccess()"
          class="btn btn-outline-primary btn-sm"
        >
          Modify access
        </button>
      </div>

      <ul class="list-group list-group-flush request-list">
        <li class="list-group-item list-group-item-action">
          <div><label for="fullcam2020">FullCAM 2020</label></div>
          <span class="controls-group">
            <div form-field class="form-check form-switch">
              <input
                id="fullcam2020"
                class="form-check-input switch-input"
                type="checkbox"
                role="switch"
                autocomplete="off"
                formControlName="canAccessFullCam2020"
              />
            </div>

            <div
              class="form-floating"
              *ngIf="
                userProfile?.canAccessFullCam2020 !==
                formGroup.get('canAccessFullCam2020').value
              "
            >
              <textarea
                class="form-control"
                id="fullcam2020-reason"
                style="height: 100px"
              ></textarea>
              <label for="fullcam2020-reason">Reason</label>
            </div>
          </span>
        </li>
        <li class="list-group-item list-group-item-action">
          <div><label for="fullcam2024">FullCAM 2024</label></div>
          <span class="controls-group">
            <div form-field class="form-check form-switch">
              <input
                id="fullcam2024"
                class="form-check-input switch-input"
                type="checkbox"
                role="switch"
                autocomplete="off"
                formControlName="canAccessFullCam2024"
              />
            </div>
            <div
              class="form-floating"
              *ngIf="
                userProfile?.canAccessFullCam2024 !==
                formGroup.get('canAccessFullCam2024').value
              "
            >
              <textarea
                class="form-control"
                id="fullcam2024-reason"
                style="height: 100px"
              ></textarea>
              <label for="fullcam2024-reason">Reason</label>
            </div>
          </span>
        </li>
        <li class="list-group-item list-group-item-action">
          <div><label for="rmt">RMT</label></div>
          <span class="controls-group">
            <div form-field class="form-check form-switch">
              <input
                id="rmt"
                class="form-check-input switch-input"
                type="checkbox"
                role="switch"
                autocomplete="off"
                formControlName="canAccessRMT"
              />
            </div>

            <div
              class="form-floating"
              *ngIf="
                userProfile?.canAccessRMT !==
                formGroup.get('canAccessRMT').value
              "
            >
              <textarea
                class="form-control"
                id="rmt-reason"
                style="height: 100px"
              ></textarea>
              <label for="rmt-reason">Reason</label>
            </div>
          </span>
        </li>
      </ul>

      <ul class="list-group list-group-flush request-list privacy-statement">
        <li class="list-group-item list-group-item-action">
          <div><label for="fullcam2020">Privacy Statement</label></div>
          <span class="controls-group">{{
            userProfile?.privacyAccepted ? "Current" : "Superceded"
          }}</span>
        </li>
      </ul>
    </form>

    <h2 class="h2">Request History</h2>
    <ngx-datatable
      #userRequestsTable
      class="custom-table"
      [rows]="requestHistory"
      [columnMode]="ColumnMode.force"
      [headerHeight]="56"
      [footerHeight]="56"
      rowHeight="56"
      [limit]="10"
    >
      <ngx-datatable-column prop="product" name="Product" [draggable]="false">
        <ng-template ngx-datatable-cell-template let-value="value">
          {{ value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Status" [draggable]="false">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <span class="status">{{ getRequestStatus(row.history) }}</span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="createdAt"
        name="Request Date"
        [draggable]="false"
      >
        <ng-template ngx-datatable-cell-template let-value="value">
          {{ value * 1000 | date : "dd/MM/yyyy" }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="History"
        prop="history"
        with="100"
        [sortable]="false"
        [draggable]="false"
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <button
            type="button"
            class="btn btn-sm btn-primary"
            [popover]="requestHistoryTemplate"
            [popoverContext]="{ history: row.history }"
            [outsideClick]="true"
            container="body"
            [popoverTitle]="row.product + ' - ' + getRequestStatus(row.history)"
          >
            Details
          </button>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>

    <div class="sticky-bottom-group">
      <button
        type="button"
        (click)="closeForm(null)"
        class="btn btn-outline-primary"
      >
        Cancel
      </button>
      <button
        type="button"
        (click)="saveForm()"
        class="btn btn-primary"
        [disabled]="isSaving"
      >
        Save
      </button>
    </div>
  </div>

  <ng-template #requestHistoryTemplate let-history="history">
    <div class="list-group list-group-flush request-history">
      <div
        class="list-group-item list-group-item-action"
        *ngFor="let h of history"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ historyStatusTypeMap[h.type] }}</h5>
          <small>1710840000</small>
        </div>
        <p class="mb-0">
          Created by: <b>{{ h.createdBy }}</b>
        </p>
        <small> {{ h.createdAt * 1000 | date : "dd/MM/yyyy h:mm:ss a" }}</small>
        <p class="mt-2">
          Reason: <b>{{ h.reason }}</b>
        </p>
      </div>
    </div>
  </ng-template>
</div>
