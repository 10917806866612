import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "fc-base-modal-form",
  templateUrl: "./base-modal-form.component.html",
  styleUrls: ["./base-modal-form.component.scss"],
})
export class BaseModalFormComponent {
  @Output()
  action = new EventEmitter<any>();

  public closeForm(result: any): void {
    this.action.emit(result);
  }
}
