import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";
import { RotationEvent, RotationForest } from "../../models";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";

@Injectable({
  providedIn: "root",
})
export class RotationHarvestingThinService extends BaseRotationEventService {
  public _type = "RotationHarvestingThin";

  public additionalFormControls = [
    {
      label: "% biomass removed",
      key: "percentBiomassRemoved",
      element: "input",
      type: "number",
    },
  ];

  constructor(
    public rotationEvent: RotationEvent,
    public rotationFormGroup: AbstractControl<RotationForest>,
    public managementService: RmtManagementService
  ) {
    super(rotationEvent, rotationFormGroup, managementService);

    this.eventFormGroup.addControl(
      "percentBiomassRemoved",
      new FormControl(0.0, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
      ])
    );

    if (this.regimeEventReference.Value?.FullCAMThin?.fracAfctThin) {
      let defaultValue =
        +this.regimeEventReference.Value.FullCAMThin.fracAfctThin;
      //convert to percentage
      if (!isNaN(defaultValue)) {
        defaultValue = defaultValue * 100;
        this.eventFormGroup.get("percentBiomassRemoved").setValue(defaultValue);
      }
    }

    if (this.regimeEventReference.Name == "Firewood collection") {
      this.eventFormGroup.get("percentBiomassRemoved").setValue(0.1 * 100);
    }
  }

  public getIntensity() {
    const formValue = +this.eventFormGroup.get("percentBiomassRemoved").value;
    const isFirewood = this.regimeEventReference.Name == "Firewood collection";

    return `Percentage ${
      isFirewood ? "Debris" : "Biomass"
    } removed:  ${formValue.toFixed(2)}%`;
  }

  override writeXmlObject() {
    let template = super.writeXmlObject();
    const formValue = +this.eventFormGroup.get("percentBiomassRemoved").value;
    template.PercentBiomassRemoved = (formValue / 100).toFixed(2);

    return template;
  }
}
