<ng-template #noData class="no-data">
  No {{ type }} species available, please query spatial data on the left panel.
</ng-template>

<ng-container *ngIf="availableSpecies$ | async as availableSpecies">
  <ng-container
    *ngIf="
      availableSpecies.length || selectedSpecies?.controls.length;
      else noData
    "
  >
    <div class="species-selection-info">
      <span>{{ selectedSpecies?.controls.length }} selected</span
      ><span>{{ availableSpecies.length }} {{ type }} species available</span
      ><span></span>
    </div>
    <ng-container *ngIf="selectedSpecies?.controls.length">
      <ul
        class="transition-list species-list selected-species-list"
        fc-transition-group
        [transition-group-class]="'transition-list'"
      >
        <li
          transition-group-item
          *ngFor="let species of selectedSpecies.controls; let i = index"
        >
          <div>
            <button
              class="btn"
              (click)="removeSpecies(species)"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Remove"
              [disabled]="loadingSpeciesName"
            >
              <span class="visually-hidden">Remove {{ type }} species</span>
              <i class="bi bi-check2"></i>
            </button>

            <span>{{ species?.get("nmSP")?.value || "N/A" }}</span>
          </div>

          <ng-container
            *ngIf="
              initialSpeciesId$.getValue() == species.get('idRegimeSP')?.value;
              else initialSpeciesButton
            "
          >
            <span class="badge rounded-pill bg-success"
              >Initial {{ type }}</span
            ></ng-container
          >

          <ng-template #initialSpeciesButton
            ><a
              *ngIf="species.get(type + 'ExistsInit')?.value"
              href="#"
              (click)="
                $event.preventDefault();
                setInitialSpecies(
                  species.get('idRegimeSP')?.value,
                  species?.get('nmSP')?.value
                )
              "
              >Set as initial {{ type }}</a
            ></ng-template
          >
        </li>
      </ul>
    </ng-container>
    <input
      [attr.aria-label]="'Search ' + loadingSpeciesName + ' species'"
      [(ngModel)]="selectedTypeAheadModel"
      [typeahead]="filteredSpecies$ | async"
      typeaheadOptionField="value"
      (typeaheadOnSelect)="onAutoCompleteSelect($event)"
      [isAnimated]="true"
      [placeholder]="'Search ' + type + ' species'"
      [disabled]="loadingSpeciesName"
      class="form-control form-control-sm autocomplete"
    />

    <ul class="species-list">
      <ng-container *ngFor="let species of filteredSpecies$ | async">
        <li>
          <button
            class="btn"
            (click)="addSpecies(species)"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Add"
            [disabled]="loadingSpeciesName"
          >
            <span class="visually-hidden">Add {{ type }} species</span>
            <i
              [hidden]="loadingSpeciesName == species.value"
              class="bi bi-plus"
            ></i>

            <span
              [hidden]="loadingSpeciesName !== species.value"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>

          <span>{{ species.value }}</span>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</ng-container>
