import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { merge } from "rxjs";
import TimeUtilities from "src/app/shared/utilities/time";
import { RegimeFormData } from "../../models";
import { EventsService } from "src/app/shared/models";

@Component({
  selector: "fc-regime-form",
  templateUrl: "./regime-form.component.html",
  styleUrls: ["./regime-form.component.scss"],
})
export class RegimeFormComponent implements OnInit {
  @Output() action = new EventEmitter();

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    selectedRegime: new UntypedFormControl(null, [Validators.required]),
    startDate: new UntypedFormControl(null),
    untilDate: new UntypedFormControl(null),
    repeatEvery: new UntypedFormControl(null),
    repeatEveryType: new UntypedFormControl("days"),
    nmRegime: new UntypedFormControl(null),
    speciesType: new UntypedFormControl(null),
    onEV: new UntypedFormControl(null),
    dateOriginEV: new UntypedFormControl("Calendar"),
    dateEV: new UntypedFormControl(null),
    nYrsFromStEV: new UntypedFormControl(null),
    nDaysFromStEV: new UntypedFormControl(null),
    numberOfDays: new UntypedFormControl(0),
  });

  public readonly newFormHelpLink = "274_New%20Regime.htm";
  public readonly editFormHelpLink = "280_Edit%20Regime.htm";

  public selectedRegime = null;
  public minRotationLengthInDays = null; //assuming 1 year = 365

  public isNewForm: boolean = true;

  /*** modal inputs ****/
  public regimeList = [];
  public startDate: Date = null;
  public endDate: Date = null;
  public formData: RegimeFormData;
  public eventsService: EventsService;
  public isMerge: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.isNewForm = !this.formData || !Object.keys(this.formData).length;

    if (this.formData) {
      //edit form
      this.formGroup.patchValue(this.formData);
      this.formGroup.get("selectedRegime").clearValidators();
      this.formGroup.get("selectedRegime").updateValueAndValidity();
    } else {
      this.formGroup
        .get("startDate")
        .setValue(
          String(this.startDate) !== "Invalid Date" ? this.startDate : null
        );
      this.formGroup
        .get("untilDate")
        .setValue(
          String(this.endDate) !== "Invalid Date" ? this.endDate : null
        );
    }

    // merge(
    //   this.formGroup.get("repeatEvery").valueChanges,
    //   this.formGroup.get("repeatEveryType").valueChanges
    // ).subscribe(() => {
    //   this.minRotationValidation();
    // });
  }

  public getHelpUrl() {
    const url =
      this.eventsService.baseHelpPageUrl +
      "/" +
      (this.isNewForm ? this.newFormHelpLink : this.editFormHelpLink);
    return url;
  }

  public getEventTypeName() {
    if (!this.formGroup.get("selectedRegime").value) {
      return "";
    }
    return this.formGroup.get("selectedRegime").value.type == "mixed"
      ? "mixed-event"
      : this.formGroup.get("selectedRegime").value.type == "tree"
      ? "forest-event"
      : "agricultural-event";
  }

  public setRegimeName() {
    const regime = this.formGroup.get("selectedRegime").value;
    const regimeName = regime?.$?.nmRG || null;
    this.formGroup.get("nmRegime").setValue(regimeName);
    this.setRepeatEvery(regime);
  }

  public setRepeatEvery(regime): void {
    const moment = TimeUtilities.getMoment();

    const eventDatesMap = this.eventsService.getEventDatesByRegimeRules(
      regime,
      this.startDate,
      this.endDate
    );

    const eventDatesWithSequence = Object.entries(eventDatesMap).sort(
      (a: any, b: any) => a[0] - b[0]
    );

    const firstEventDate = eventDatesWithSequence[0][1];
    const lastEventDate =
      eventDatesWithSequence[eventDatesWithSequence.length - 1][1];
    const daysDiff = moment(lastEventDate).diff(firstEventDate, "days");

    let repeatEveryValue =
      daysDiff > 365
        ? moment(lastEventDate).diff(firstEventDate, "years") + 1
        : daysDiff - Math.floor(daysDiff / 365);

    const repeatEveryType = daysDiff > 365 ? "years" : "days";

    //set to null if 1 day
    if (repeatEveryValue <= 1 && repeatEveryType == "days") {
      repeatEveryValue = null;
    }

    this.minRotationLengthInDays =
      repeatEveryType == "days" ? repeatEveryValue : repeatEveryValue * 365;

    this.formGroup.get("repeatEvery").setValue(repeatEveryValue);
    this.formGroup.get("repeatEveryType").setValue(repeatEveryType);
    this.formGroup.get("repeatEvery").updateValueAndValidity();
  }

  // protected minRotationValidation(): void {
  //   const repeatEveryType = this.formGroup.get("repeatEveryType").value;
  //   const rotationLengthControl = this.formGroup.get("repeatEvery");
  //   const rotationLength = rotationLengthControl.value;

  //   const days: number =
  //     repeatEveryType == "years" ? 365 * rotationLength || 0 : rotationLength;

  //   if (!rotationLengthControl.value) {
  //     if (rotationLengthControl.hasError("min")) {
  //       rotationLengthControl.setErrors({});
  //     }

  //     return;
  //   }

  //   if (this.minRotationLengthInDays && this.minRotationLengthInDays > days) {
  //     if (!rotationLengthControl.hasError("min")) {
  //       rotationLengthControl.setErrors({
  //         min:
  //           this.minRotationLengthInDays +
  //           " " +
  //           this.formGroup.get("repeatEveryType").value,
  //       });
  //     }
  //     return;
  //   }

  //   if (rotationLengthControl.hasError("min")) {
  //     rotationLengthControl.setErrors(null);
  //   }
  // }

  public closeForm(result: RegimeFormData): void {
    this.action.emit(result);
  }
}
