import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { Collection, Map, MapEvent, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { TileArcGISRest } from "ol/source";
import { v4 as uuidv4 } from "uuid";
import { defaults } from "ol/interaction";
import BaseLayer from "ol/layer/Base";

@Component({
  selector: "fc-ol-map",
  templateUrl: "./ol-map.component.html",
  styleUrls: ["./ol-map.component.scss"],
})
export class OlMapComponent implements OnInit, AfterViewInit {
  @Input() disableInteraction: boolean = false;

  @Input("view") set view(newView: View) {
    this._view = newView;
    if (this.map) {
      this.map.setView(newView);
    }
  }

  private map: Map;

  private _view = new View({
    center: [15096574.738836141, -3149482.2768923095],
    zoom: 4.5,
  });
  public readonly mapId = "_" + uuidv4();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const basemapUrl =
      "https://services.ga.gov.au/gis/rest/services/World_Bathymetry_Imagery/MapServer";
    const basemapLayer = new TileLayer({
      source: new TileArcGISRest({
        url: basemapUrl,
      }),
    });

    this.map = new Map({
      interactions: this.disableInteraction ? [] : defaults(),
      target: this.mapId,
      layers: [],
      view: this._view,
    });

    this.map.on("moveend", (e: MapEvent) => {});
  }

  public getId(): string {
    return this.mapId;
  }
  public getMap(): Map {
    return this.map;
  }
  public getView(): View {
    return this.map.getView();
  }
  public getLayers(): Collection<BaseLayer> {
    return this.map.getLayers();
  }
  public addLayer(layer): void {
    this.map.addLayer(layer);
  }

  public updateSize(): void {
    this.map.updateSize();
  }
}
