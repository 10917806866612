import { Injectable } from "@angular/core";
import { FormModel } from "src/app/shared/models";
import { SimulationResult } from "src/app/simulation/models";
import { FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { OutputInfo } from "../outputInfo";
import { IOutputWindow } from "../models";
import { AbstractControl, FormArray } from "@angular/forms";
import * as _ from "lodash";
import Utilities from "src/app/shared/utilities/utils";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OutputWindows2020Service extends BasePlotFormService {
  public readonly pageId = "outputWindows";
  public readonly pageHelpLink = "25_Output%20Windows.htm";

  public readonly baseHeaders = [
    "Year",
    "Month",
    "Day",
    "Step In Year",
    "Dec. Year",
  ];

  public readonly defaultOutputSelections = {
    siteCM: true,
    soilCM: true,
    debrCM: true,
    treeCM: true,
    cropCM: true,
  };

  public defaultEstateOutputSections = {
    ...this.defaultOutputSelections,
    mulcCM: true,
  };

  public readonly numberOfCategoryNodes = 940; //c0+c1+c2
  public readonly maxNumberWindows = 20;

  private outputCounter = 1;
  //used by  expandOW
  private readonly cat0Length = 55;
  private readonly cat1Length = 176;
  private readonly cat2Length = 880;

  private readonly outputCategoryNameMap = {
    kv_C: "Carbon",
    kv_N: "Nitrogen",
    kv_Dollars: "Dollars", //<- to be confirmed
    kv_Indept: "Other",
    kv_OASys: "Spatial",
    kv_Time: "Timing",
    kv_FrAg: "Whole",
    kv_Fr: "Forest",
    kv_Ag: "Agricultural",
    kv_Leak: "Leaks",
    kv_Ledg: "Ledger",
    kv_Cumu: "Cumulative",
    kv_DB: "OASys",
    kv_CStock: "Carbon Stock",
    kv_NA1: "-",
    kv_CProjects: "Carbon Projects",
    kv_NA2: "-",
    kv_Emit: "Emissions",
    kv_Plnt: "Plants",
    kv_SDwd: "Standing dead",
    kv_Debr: "Debris",
    kv_Mulc: "Mulch",
    kv_Soil: "Soil",
    kv_Mnrl: "Minerals",
    kv_Prod: "Products",
    kv_Disp: "Displacements",
    kv_Water: "Water",
    kv_Rate: "Rates",
    kv_Logs: "Log Production",
    kv_Crop: "Crop Production",
    kv_Other: "Other",
    kv_Olson: "Olson Fuel Loads",
  };

  private readonly cat0 = [
    { id: "kv_C", index: 0 },
    { id: "kv_N", index: 1 },
    { id: "kv_Dollars", index: 2 },
    { id: "kv_Indept", index: 3 },
  ];

  private readonly cat1 = [
    { id: "kv_Time", index: 0 },
    { id: "kv_FrAg", index: 1 },
    { id: "kv_Fr", index: 2 },
    { id: "kv_Ag", index: 3 },
    { id: "kv_Leak", index: 4 },
    { id: "kv_Ledg", index: 5 },
    { id: "kv_Cumu", index: 6 },
    { id: "kv_CStock", index: 8 },
    { id: "kv_NA1", index: 9 },
    { id: "kv_CProjects", index: 10 },
  ];

  private readonly cat2 = [
    { index: 0, id: "kv_NA2" },
    { index: 1, id: "kv_Emit" },
    { index: 2, id: "kv_Plnt" },
    { index: 3, id: "kv_SDwd" },
    { index: 4, id: "kv_Debr" },
    { index: 5, id: "kv_Mulc" },
    { index: 6, id: "kv_Soil" },
    { index: 7, id: "kv_Mnrl" },
    { index: 8, id: "kv_Prod" },
    { index: 9, id: "kv_Disp" },
    { index: 10, id: "kv_Water" },
    { index: 11, id: "kv_Rate" },
    { index: 12, id: "kv_Logs" },
    { index: 13, id: "kv_Crop" },
    { index: 14, id: "kv_Other" },
    { index: 15, id: "kv_Olson" },
  ];

  public totalOutputsWithinCategories$ = new BehaviorSubject<number>(0);
  public totalSelectedOutputs$ = new BehaviorSubject<number>(0);
  public multiSelectRowInstructionDisplayed: boolean = false;

  public layout: FormLayout = {
    label: "Output windows",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "OutputWindowsContainerComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
  public formModel: FormModel = {
    outputWindows: {
      label: "Output windows",
      defaultValue: "formArray",
      isShown: true,
    },
  };

  public outputWindowFormModel: FormModel = {
    title: { defaultValue: null, isShown: false },
    // content: { defaultValue: null, isShown: false },
    isRemovable: { defaultValue: true, isShown: true },
    isDisabled: { defaultValue: false, isShown: false },
    isActive: { defaultValue: true, isShown: false },
    viewType: { defaultValue: "Graph", isShown: false },
    outputSelection: { defaultValue: null, isShown: false },
    outputTree: { defaultValue: [], isShown: false },
    tableData: { defaultValue: null, isShown: false },
    chartOption: { defaultValue: null, isShown: false },
    isScenarioView: { defaultValue: false, isShown: false }, //distinguish between ows and digest ows
  };

  public setMultiSelectRowInstructionDisplayed(isDisplayed: boolean) {
    this.multiSelectRowInstructionDisplayed = isDisplayed;
  }

  protected getOutputName(): string {
    let name = "Output " + this.outputCounter;

    const outputWindowsNames = this.getOutputWindowsFormArray().value.map(
      (ow) => ow.title
    );

    if (outputWindowsNames.includes(name)) {
      this.outputCounter++;
      name = "Output " + this.outputCounter;
      while (outputWindowsNames.includes(name) === true) {
        this.outputCounter++;
        name = "Output " + this.outputCounter;
      }
      return name;
    }

    this.outputCounter++;
    return name;
  }

  public addOutputWindow(includesOutputSelection?: boolean) {
    const fg = this.getFormGroup();
    const outputWindows = fg.get("outputWindows") as FormArray;
    this.deactiveActiveWindow();

    const oWformGroup = this.createFormGroup(this.outputWindowFormModel);

    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();
    const defaultOutputSelections =
      plotFormat == "est"
        ? this.defaultEstateOutputSections
        : this.defaultOutputSelections;
    oWformGroup.patchValue({
      title: this.getOutputName(),
      outputSelection: includesOutputSelection ? defaultOutputSelections : {},
    });
    outputWindows.push(oWformGroup);
    this.buildTree(oWformGroup);
    //this.buildTreeToActiveWindow();
  }

  public deactiveActiveWindow() {
    const activeWindow = this.getActiveWindow();
    if (activeWindow) {
      activeWindow.get("isActive").setValue(false);
    }
  }

  public convertSimulationResultToChartOption(csvData: SimulationResult): void {
    const allOutputKeyValues = OutputInfo.getAllOutputProgNmOIKeyValues();

    const outputWindows = this.getOutputWindowsFormArray();
    const allOutputHeaderFields = csvData.meta.fields.filter(
      (h) => !this.baseHeaders.includes(h)
    );

    outputWindows.controls.forEach((owForm: AbstractControl<IOutputWindow>) => {
      if (!owForm.get("isDisabled").value) {
        let headerFields = [];
        allOutputHeaderFields.forEach((header, index) => {
          const outputs = Object.keys(owForm.get("outputSelection").value);

          for (let i = 0; i < outputs.length; i++) {
            const prefixKey = allOutputKeyValues[outputs[i]].prefixOI;
            let prefix = this.getOutputNameMap()[prefixKey];
            if (!prefix || prefixKey == "koNA") {
              prefix = "";
            }

            const itemName =
              prefix.trim() + " " + allOutputKeyValues[outputs[i]].nmOI?.trim();

            if (
              header
                .toLocaleLowerCase()
                .indexOf(itemName.toLocaleLowerCase().trim()) > -1
            ) {
              headerFields.push(header);
              break;
            }
          }
        });

        const data = this.getChartOptionAndTableData({
          headerFields,
          inputCsvObject: csvData,
        });

        owForm.get("chartOption").setValue(data.chartOption);
        owForm.get("tableData").setValue(data.tableData);
      }
    });
  }

  public convertDigestSimulationResultToChartOptions(
    csvData: SimulationResult[],
    scenarioView
  ): void {
    const allOutputKeyValues = OutputInfo.getAllOutputProgNmOIKeyValues();
    const allOutputWindows = this.getOutputWindowsFormArray();
    let newOutputWindows = [];
    // //clear all scenario view
    const filteredOWs = allOutputWindows.controls.filter(
      (ow) => !ow.get("isScenarioView").value
    );

    newOutputWindows = [...filteredOWs];

    for (const csvObject of csvData) {
      let owName = "";
      let baseOW;

      const headerFields = csvObject.meta.fields.filter(
        (h) => !this.baseHeaders.includes(h)
      );

      if (scenarioView == "Combined") {
        //csv file name returned from server will be {fileName}+ISO time string
        //in the case no changes to window name
        baseOW = newOutputWindows[0];
      }

      if (scenarioView == "PerScenario" || scenarioView == "PerOutput") {
        //backend is returning the file names with last scenario name
        //if ow length is greater than user ow title to split otherwise use scenario name

        //scenario name used when output windows  more than 1
        const scenarios =
          this.simulationService.plotDigestService.getDigestScenariosFormArray()
            ?.value;
        let scenarioNameUsed = "";
        if (scenarios?.length) {
          const scenarioNames = scenarios.map((s) => s.name);
          scenarioNameUsed = scenarioNames.find((sName) => {
            const owTitleWithCsv = " - " + sName + ".csv";
            const owTitle = " - " + sName + " - ";
            return (
              csvObject.fileName.indexOf(owTitle) > -1 ||
              csvObject.fileName.indexOf(owTitleWithCsv) > -1
            );
          });
        }
        const cowName = newOutputWindows
          .find((cow) => {
            const cowTitle = " - " + cow.get("title").value + " - ";
            return csvObject.fileName.indexOf(cowTitle) > -1;
          })
          ?.get("title").value;

        if (scenarioView == "PerScenario") {
          //file names from backend will only contain scenario names
          owName =
            (filteredOWs.length > 1
              ? filteredOWs[0].get("title").value + " - "
              : "") + scenarioNameUsed?.trim();
        } else if (scenarioView == "PerOutput") {
          if (filteredOWs.length > 1) {
            owName =
              cowName +
              " - " +
              csvObject.fileName
                .split(`- ${cowName} - `)
                .pop()
                .replace(".csv", "");
          } else {
            owName = csvObject.fileName
              .split(`- ${scenarioNameUsed.trim()} - `)
              .pop()
              .replace(".csv", "");
          }
        }

        owName = owName.trim();
        if (owName) {
          //get the output window used for the
          baseOW = newOutputWindows.find(
            (cow) => cow.get("title").value == owName
          );
        }
      }

      //sceanrio results
      if (baseOW) {
        baseOW.get("isActive").setValue(true);

        const data = this.getChartOptionAndTableData({
          headerFields,
          inputCsvObject: csvObject,
        });
        baseOW.get("chartOption").setValue(data.chartOption);
        baseOW.get("tableData").setValue(data.tableData);
        continue;
      }

      //create new OW for scenarios
      const scenarioOWformGroup = this.createFormGroup(
        this.outputWindowFormModel
      );
      const scenarioOWData = this.getChartOptionAndTableData({
        headerFields,
        inputCsvObject: csvObject,
      });

      scenarioOWformGroup
        .get("chartOption")
        .setValue(scenarioOWData.chartOption);
      scenarioOWformGroup.get("tableData").setValue(scenarioOWData.tableData);
      scenarioOWformGroup.get("title").setValue(owName);
      scenarioOWformGroup.get("isScenarioView").setValue(true);
      scenarioOWformGroup.get("isActive").setValue(false);
      newOutputWindows.push(scenarioOWformGroup);

      continue;
    }
    //when ui hits ow page, a new ow will be created if no ow exist
    this.getOutputWindowsFormArray().clear();
    newOutputWindows.forEach((ow) => {
      this.getOutputWindowsFormArray().push(ow);
    }); //
  }

  protected getChartOptionAndTableData({ headerFields, inputCsvObject }): {
    chartOption: any;
    tableData: any;
  } {
    let returnObj = { chartOption: null, tableData: null };

    let csvObject: SimulationResult = { ...inputCsvObject };

    csvObject.data = csvObject.data.map((d) => {
      const obj = {};
      Object.keys(d).forEach((key) => {
        if (this.baseHeaders.includes(key)) {
          obj[key] = d[key];
        } else if (headerFields.includes(key)) {
          obj[key] = Utilities.formatNumberToFourDecimalPlaces(+d[key]);
        }
      });
      return obj;
    });

    const series = headerFields.map((header) => {
      return {
        name: header,
        data: csvObject.data.map((d) => +d[header]),
        type: "line",
        symbol: "none",
      };
    });

    returnObj.chartOption = {
      legend: {
        type: "scroll",
        orient: "horizontal", // 'vertical'
        x: "center", // 'center' | 'left' | {number},
        y: "bottom", // 'center' | 'bottom' | {number}
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {},
        },
      },
      tooltip: {
        trigger: "axis",
      },
      xAxis: {
        type: "category",
        name: "year",
        nameLocation: "middle",
        data: csvObject.data.map((d) => d["Year"]),
        nameTextStyle: {
          /* t-l-b-r */
          padding: [20, 0, 0, 0],
        },
      },
      yAxis: {
        name: "Value",
        nameLocation: "middle",
        nameTextStyle: {
          /* t-l-b-r */
          padding: [50, 0, 30, 0],
        },
      },

      series: series,
    };

    returnObj.tableData = {
      headers: [
        csvObject.meta.fields[0],
        csvObject.meta.fields[1],
        ...headerFields,
      ].map((h, i) => {
        return { prop: h, width: i < 2 ? 50 : "auto" };
      }),

      rows: csvObject.data,
    };

    return returnObj;
  }

  public buildTree(
    ow: AbstractControl<IOutputWindow>,
    expandIndexs?: "t" | "f"[]
  ) {
    this.totalOutputsWithinCategories$.next(0);
    let totalOutputsWithinCategories = 0;

    const previousTree = ow.get("outputTree").value;
    const allOutputs = OutputInfo.getAllOutputs();
    const allOutputKeyValues = OutputInfo.getAllOutputKeyValues();

    const availableOutputs = allOutputs.filter((o) =>
      this.isOutputAvailable(o.tOutOI, allOutputKeyValues)
    );

    let outputTreeArray = [];

    for (let c0 of this.cat0) {
      const previousCat0Object = previousTree
        ? previousTree.find((pt) => pt.level == 0 && pt.id == c0.id)
        : null;

      let cat0Object = {
        level: 0,
        label: this.outputCategoryNameMap[c0.id],
        id: c0.id,
        index: c0.index,
        isExpanded: previousCat0Object?.isExpanded || false,
        isSelected: previousCat0Object?.isSelected || false,
        indeterminate: previousCat0Object?.isIndeterminate || false,
        items: [],
        subcategories: [],
      };
      //for Xml read
      if (expandIndexs) {
        // index = kNOutCat012 + kNOutCat01 + tCat0 				on level 0
        const index = this.cat2Length + this.cat0Length + c0.index;

        // if (expandIndexs[index] == "t") {
        //   console.log("level 0 expanded index", index);
        // }
      }

      for (let c1 of this.cat1) {
        const previousCat1Object = previousCat0Object
          ? previousCat0Object.subcategories.find(
              (pt) => pt.level == 1 && pt.id == c1.id
            )
          : null;

        let cat1Object = {
          level: 1,
          label: this.outputCategoryNameMap[c1.id],
          id: c1.id,
          index: c1.index,
          isExpanded: previousCat1Object?.isExpanded || false,
          isSelected: previousCat1Object?.isSelected || false,
          indeterminate: previousCat1Object?.isIndeterminate || false,
          items: [],
          subcategories: [],
        };

        //for Xml read
        if (expandIndexs) {
          //index		 = kNOutCat012 + tCat0 * kNOutCat1 + tCat1			on level 1
          const index =
            this.cat2Length + c0.index * this.cat1.length + c1.index;
          // if (expandIndexs[index] == "t") {
          //   console.log("level 1 expanded index", index);
          // }
          cat1Object.isExpanded = expandIndexs[index] == "t";
        }

        for (let c2 of this.cat2) {
          const previousCat2Object = previousCat1Object
            ? previousCat1Object.subcategories.find(
                (pt) => pt.level == 2 && pt.id == c2.id
              )
            : null;
          let cat2Object = {
            level: 2,
            label: this.outputCategoryNameMap[c2.id],
            id: c2.id,
            index: c2.index,
            isExpanded: previousCat2Object?.isExpanded || false,
            isSelected: previousCat2Object?.isSelected || false,
            indeterminate: previousCat2Object?.isIndeterminate || false,
            items: [],
          };

          //for Xml read
          if (expandIndexs) {
            //	index	 = tCat0 * kNOutCat12 + tCat1 * kNOutCat2 + tCat2 	on level 2
            const index =
              c0.index * this.cat1Length +
              c1.index * this.cat2.length +
              c2.index;
            // if (expandIndexs[index] == "t") {
            //   console.log("level 2 expanded index", index);
            // }

            cat2Object.isExpanded = expandIndexs[index] == "t";
          }

          const filteredOuputs = availableOutputs
            .filter(
              (ao) =>
                ao["tOutCat0_OI"] == c0.id &&
                ao["tOutCat1_OI"] == c1.id &&
                ao["tOutCat2_OI"] == c2.id
            )
            .map((ao) => {
              return {
                ...ao,
                isSelected: false,
              };
            });

          if (filteredOuputs.length) {
            if (cat2Object.label !== "-") {
              cat2Object.items = filteredOuputs;
              //add items
              totalOutputsWithinCategories += filteredOuputs.length;
              cat1Object.subcategories.push(cat2Object);
            } else {
              //add items
              totalOutputsWithinCategories += filteredOuputs.length;
              cat1Object.items = filteredOuputs;
            }
          }
        }
        if (cat1Object.subcategories.length || cat1Object.items.length) {
          cat0Object.subcategories.push(cat1Object);
        }
      }
      if (cat0Object.subcategories.length || cat0Object.items.length) {
        outputTreeArray.push(cat0Object);
      }
    }

    this.totalOutputsWithinCategories$.next(totalOutputsWithinCategories);

    ow.get("outputTree").setValue(outputTreeArray);
  }

  public getOutputNameMap(): { [key: string]: string } {
    return OutputInfo.getOutputNameMap();
  }

  public getOutputWindowsFormArray() {
    return this.getFormGroup().get("outputWindows") as FormArray;
  }

  public getActiveWindow(): AbstractControl<IOutputWindow> {
    const outputWindows = this.getOutputWindowsFormArray();
    if (outputWindows) {
      return outputWindows.controls.find((ow) => ow.get("isActive").value);
    }
    return null;
  }

  public cloneOutputWindow(ow: AbstractControl<IOutputWindow>) {
    const fg = this.getFormGroup();
    const value = ow.getRawValue();
    const outputWindows = fg.get("outputWindows") as FormArray;
    this.deactiveActiveWindow();
    const oWformGroup = this.createFormGroup(this.outputWindowFormModel);
    oWformGroup.patchValue({
      ...value,
      title: value.title + " (copy)",
      isActive: false,
    });
    outputWindows.push(oWformGroup);
    setTimeout(() => {
      oWformGroup.get("isActive").setValue(true);
      this.buildTree(oWformGroup);
    }, 1);
  }

  public removeOutputWindow(index: number): void {
    this.getOutputWindowsFormArray().removeAt(index);
    this.getOutputWindowsFormArray().controls[0].get("isActive").setValue(true);
  }
  public disableOutputWindow(
    isDisabled: boolean,
    ow: AbstractControl<IOutputWindow>
  ): void {
    ow.get("isDisabled").setValue(isDisabled);
  }

  public addOutput(progNmOI: string): void {
    this.getActiveWindow()
      .get("outputSelection")
      .setValue({
        ...this.getActiveWindow().get("outputSelection").value,
        [progNmOI]: true,
      });
  }

  public updateTotalOutputCount(): void {
    const allOutputs = this.getActiveWindow().get("outputSelection").value;
    //output is filtered when plot type changes, however the selected outputs are hidden but not removed
    const allAvailableOutputKeyValues = OutputInfo.getAllOutputKeyValues();

    const filteredSelectedOutput = Object.keys(allOutputs).filter((o) =>
      this.isOutputAvailable("kou_" + o, allAvailableOutputKeyValues)
    );

    this.totalSelectedOutputs$.next(filteredSelectedOutput.length);
  }

  public removeOutput(progNmOI: string): void {
    const { [progNmOI]: _, ...rest } =
      this.getActiveWindow().get("outputSelection").value;

    this.getActiveWindow()
      .get("outputSelection")
      .setValue({
        ...rest,
      });
  }

  public isOutputAvailable(tOut, allOutputKeyValues): boolean {
    // Is the output available?
    // Inputs that affect OutputIsAvailable cannot be RINs or SINs -- see StartInputsList
    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();

    if (allOutputKeyValues[tOut]?.tOutCat0_OI == "kv_OASys") {
      return false;
    }
    if (plotFormat == "est") {
      switch (tOut) {
        case "kou_NA":
        case "kou_yr":
        case "kou_mo":
        case "kou_day":
        case "kou_dayOfYr":
        case "kou_stepEnTimeInYrs":
        case "kou_stepEnTimeInDays":
        case "kou_step":
        case "kou_dateStr":
          return false;
        case "kou_decRateMultiRPM": {
          /********* this part might have to move to the right location, to reduce the code complex level***********/
          const version = this.simulationService.version;
          return version == "2024";
        }

        case "kou_decRateMultiHUM": {
          /********* this part might have to move to the right location, to reduce the code complex level***********/
          const version = this.simulationService.version;
          return version == "2024";
        }
      }

      if (allOutputKeyValues[tOut]?.nmOI == "kOutErrNm") {
        return false;
      }
      return true;
    }

    switch (tOut) {
      // CFrAg
      // 3PG has no carbon fractions --> cannot do C masses by itself
      case "kou_compCM":
      /// SUBRULE SOIL return this.hasConfig('CAMX');
      case "kou_siteCM":

      case "kou_blgCM":
        return this.hasConfig("CAMX") && this.hasConfig("RothC");
      case "kou_abgCM":
        return this.hasConfig("CAMX");
      // CFrAgEmit
      case "kou_flowCMCompAtms":
        return this.hasConfig("CAMX") && this.hasConfig("RothC");
      case "kou_flowCMAtmsPlnt":

      case "kou_flowCMDebrAtms":
        return this.hasConfig("CAMX");
      case "kou_flowCMMulcAtms":
        return this.hasConfig("Gendec");
      case "kou_flowCMSoilAtms":
        return this.hasConfig("RothC");
      case "kou_flowCMProdAtmsPdcm":

      case "kou_flowCMProdAtmsEner":
        return this.hasConfig("CAMX");
      case "kou_flowCMSiteAtmsFire":

      case "kou_flowCH4MSiteAtmsFire":
        return this.hasConfig("CAMX") && this.hasConfig("RothC");
      // CFrAgPlnt
      case "kou_plntCM":

      case "kou_plntAbgCM":

      case "kou_plntBlgCM":
        return this.hasConfig("CAMX");
      // CFrAgDebr

      case "kou_sDeadCM":

      case "kou_sDeadAbgCM":

      case "kou_sDeadBlgCM":

      case "kou_sLittCM":

      case "kou_sDdwdCM":

      case "kou_abgDebrCM":

      case "kou_blgDebrCM":
        return this.hasConfig("CAMX");
      case "kou_littCM":

      case "kou_debrCM":

      case "kou_ddwdCM":
        return this.hasConfig("CAMX");
      // CFrAgMulc
      case "kou_mulcCM":
        return this.hasConfig("Gendec");
      // CFrAgSoil
      case "kou_soilCM":
        return this.hasConfig("RothC");
      // CFrAgProd
      case "kou_prodCM":
        return this.hasConfig("CAMX");
      // CFrAgDisp
      case "kou_dispCM":
        return this.hasConfig("dispEner") || this.hasConfig("dispProd");
      case "kou_dispEnerCM":
        return this.hasConfig("dispEner");
      case "kou_dispProdCM":
        return this.hasConfig("dispProd");
      // CFr
      case "kou_compCMF":

      case "kou_siteCMF":

      case "kou_blgCMF":
        return this.hasConfig("CAMFor") && this.hasConfig("RothCF");
      case "kou_abgCMF":
        return this.hasConfig("CAMFor");
      // CFrEmit
      case "kou_flowCMCompAtmsF":
        return this.hasConfig("CAMFor") && this.hasConfig("RothCF");
      case "kou_flowCMAtmsTreePlnFF":

      case "kou_flowCMAtmsTreeProdF":

      case "kou_flowCMDebrAtmsBkdnF":

      case "kou_flowCMDebrAtmsTermF":
        return this.hasConfig("CAMFor");
      case "kou_flowCMActvAtmsSdcmF":
        return this.hasConfig("RothCF");
      case "kou_flowCMProdAtmsPdcmF":

      case "kou_flowCMProdAtmsEnerF":

      case "kou_flowCMLndfAtmsLdcmF":
        return this.hasConfig("CAMFor");
      case "kou_flowCMSiteAtmsFireF":
        return this.hasConfig("CAMFor") && this.hasConfig("RothCF");
      case "kou_flowCMTreeAtmsFireF":

      case "kou_flowCMdDdwdAtmsFireF":

      case "kou_flowCMdChwdAtmsFireF":

      case "kou_flowCMdBlitAtmsFireF":

      case "kou_flowCMdLlitAtmsFireF":

      case "kou_flowCMdCodrAtmsFireF":

      case "kou_flowCMdFidrAtmsFireF":

      case "kou_flowCMrDdwdAtmsFireF":

      case "kou_flowCMrChwdAtmsFireF":

      case "kou_flowCMrBlitAtmsFireF":

      case "kou_flowCMrLlitAtmsFireF":

      case "kou_flowCMrCodrAtmsFireF":

      case "kou_flowCMrFidrAtmsFireF":

      case "kou_flowCMDebrAtmsFireF":
        return this.hasConfig("CAMFor");
      case "kou_flowCMMulcAtmsFireF":
        return this.hasConfig("CAMFor") && this.hasConfig("GendecF");

      // CFrPlnt
      case "kou_treeCM":

      case "kou_treeAbgCM":

      case "kou_treeBlgCM":

      case "kou_stemCMF":

      case "kou_branCMF":

      case "kou_barkCMF":

      case "kou_leafCMF":

      case "kou_cortCMF":

      case "kou_firtCMF":

      case "kou_flowCMTreeDebrTurnF":

      case "kou_flowCMStemDdwdTurnF":

      case "kou_flowCMBranDdwdTurnF":

      case "kou_flowCMBarkBlitTurnF":

      case "kou_flowCMLeafLlitTurnF":

      case "kou_flowCMCortCodrTurnF":

      case "kou_flowCMFirtFidrTurnF":

      case "kou_flowCMTreeDebrThinF":

      case "kou_flowCMTreeProdThinF":

      case "kou_flowCMTreeDebrFireF":
        return this.hasConfig("CAMFor");

      case "kou_flowCMsDdwdDebrSensF":

      case "kou_flowCMsChwdDebrSensF":

      case "kou_flowCMsBlitDebrSensF":

      case "kou_flowCMsLlitDebrSensF":

      case "kou_flowCMsCodrDebrSensF":

      case "kou_flowCMsFidrDebrSensF":

      case "kou_flowCMsDdwdAtmsSensF":

      case "kou_flowCMsChwdAtmsSensF":

      case "kou_flowCMsBlitAtmsSensF":

      case "kou_flowCMsLlitAtmsSensF":

      case "kou_flowCMsCodrAtmsSensF":

      case "kou_flowCMsFidrAtmsSensF":
        return this.hasConfig("CAMFor");

      case "kou_flowCMStemsDdwdFireF":

      case "kou_flowCMBransChwdFireF":

      case "kou_flowCMBarksBlitFireF":

      case "kou_flowCMLeafsLlitFireF":

      case "kou_flowCMCortsCodrFireF":

      case "kou_flowCMFirtsFidrFireF":

      case "kou_flowCMsDdwdDebrFireF":

      case "kou_flowCMsChwdDebrFireF":

      case "kou_flowCMsBlitDebrFireF":

      case "kou_flowCMsLlitDebrFireF":

      case "kou_flowCMsDdwdAtmsFireF":

      case "kou_flowCMsChwdAtmsFireF":

      case "kou_flowCMsBlitAtmsFireF":

      case "kou_flowCMsLlitAtmsFireF":
        return this.hasConfig("CAMFor");

      case "kou_flowCMStemsDdwdThinF":

      case "kou_flowCMBransChwdThinF":

      case "kou_flowCMBarksBlitThinF":

      case "kou_flowCMLeafsLlitThinF":

      case "kou_flowCMCortsCodrThinF":

      case "kou_flowCMFirtsFidrThinF":

      case "kou_flowCMsDdwdFuelThinF":

      case "kou_flowCMsChwdFuelThinF":

      case "kou_flowCMsBlitFuelThinF":

      case "kou_flowCMsLlitFuelThinF":
        return this.hasConfig("CAMFor");

      case "kou_OlsonTotalCoarseFuel":

      case "kou_OlsonCoarseHeavyFuel":

      case "kou_OlsonCoarseLightFuel":

      case "kou_OlsonFineWoodyFuel":

      case "kou_OlsonFineGrassFuel":

      case "kou_flowOlTotCoFuelAtmsFireF":

      case "kou_flowOlCoHyFuelAtmsFireF":

      case "kou_flowOlCoLiFuelAtmsFireF":

      case "kou_flowOlFiWoFuelAtmsFireF":

      case "kou_flowOlFiGrFuelAtmsFireF":
        return this.hasConfig("CAMX");

      // CFrDebr
      case "kou_debrCMF":

      case "kou_abgDebrCMF":

      case "kou_blgDebrCMF":

      case "kou_littCMF":

      case "kou_ddwdCMF":

      case "kou_dDdwdCMF":

      case "kou_rDdwdCMF":

      case "kou_dChwdCMF":

      case "kou_rChwdCMF":

      case "kou_dBlitCMF":

      case "kou_rBlitCMF":

      case "kou_dLlitCMF":

      case "kou_rLlitCMF":

      case "kou_dCodrCMF":

      case "kou_rCodrCMF":

      case "kou_dFidrCMF":

      case "kou_rFidrCMF":

      case "kou_sDeadCMF":

      case "kou_sDStemCMF":

      case "kou_sDBranCMF":

      case "kou_sDBlitCMF":

      case "kou_sDLlitCMF":

      case "kou_sDCodrCMF":

      case "kou_sDFidrCMF":

      case "kou_sLittCMF":

      case "kou_sDdwdCMF":

      case "kou_abgsDeadCMF":

      case "kou_blgsDeadCMF":
        return this.hasConfig("CAMFor");
      case "kou_flowCMLittActvBkdnF":
        return this.hasConfig("CAMFor") && !this.hasConfig("GendecF");
      case "kou_flowCMDdrtActvBkdnF":

      case "kou_flowCMDebrProdThinF":

      case "kou_flowCMDebrInrtFireF":
        return this.hasConfig("CAMFor");
      // CFrMulc
      case "kou_mulcCMF":

      case "kou_sopmCMF":

      case "kou_lrpmCMF":

      case "kou_mrpmCMF":

      case "kou_sommCMF":

      case "kou_lrmmCMF":

      case "kou_mrmmCMF":

      case "kou_micrCMF":

      case "kou_flowCMLittSopmBkdnF":

      case "kou_flowCMLittLrpmBkdnF":

      case "kou_flowCMLittMrpmBkdnF":

      case "kou_flowCMMulcAtmsMdcmF":

      case "kou_flowCMMulcActvHumfF":
        return this.hasConfig("GendecF");
      case "kou_flowCMMulcInrtFireF":
        return this.hasConfig("GendecF") && this.hasConfig("CAMFor");
      // CFrSoil
      case "kou_soilCMF":

      case "kou_dpmaCMF":

      case "kou_rpmaCMF":

      case "kou_biofCMF":

      case "kou_biosCMF":

      case "kou_biomCMF":

      case "kou_humsCMF":
        return this.hasConfig("RothCF");
      case "kou_inrtCMF":

      case "kou_flowCMActvInrtEncpF":
        return this.hasConfig("RothCF") && this.hasConfig("CAMFor");
      case "kou_flowCMOffsActvMoffF":
        return this.hasConfig("RothCF");
      case "kou_flowCMPresActvExtnF":
        return this.hasConfig("RothCF") && !this.hasConfig("CAMFor");
      // CFrProd
      case "kou_prodCMF":

      case "kou_prodUsCMF":

      case "kou_prodLfCMF":

      case "kou_fuelCM":

      case "kou_paprCM":

      case "kou_packCM":

      case "kou_furnCM":

      case "kou_fibrCM":

      case "kou_consCM":

      case "kou_resiCM":

      case "kou_fuelLfCM":

      case "kou_paprLfCM":

      case "kou_packLfCM":

      case "kou_furnLfCM":

      case "kou_fibrLfCM":

      case "kou_consLfCM":

      case "kou_resiLfCM":

      case "kou_flowCMProdLndfFillF":
        return this.hasConfig("CAMFor");
      // CFrDisp
      case "kou_dispCMF":
        return (
          this.hasConfig("CAMFor") &&
          (this.hasConfig("dispEner") || this.hasConfig("dispProd"))
        );
      case "kou_dispEnerCMF":
        return this.hasConfig("CAMFor") && this.hasConfig("dispEner");
      case "kou_dispProdCMF":
        return this.hasConfig("CAMFor") && this.hasConfig("dispProd");
      case "kou_fuelCMDispEner":

      case "kou_paprCMDispEner":

      case "kou_packCMDispEner":

      case "kou_furnCMDispEner":

      case "kou_fibrCMDispEner":

      case "kou_consCMDispEner":

      case "kou_resiCMDispEner":

      case "kou_fossCMCompMgmtF":
        return this.hasConfig("CAMFor") && this.hasConfig("dispEner");
      case "kou_paprCMDispProd":

      case "kou_packCMDispProd":

      case "kou_furnCMDispProd":

      case "kou_fibrCMDispProd":

      case "kou_consCMDispProd":

      case "kou_resiCMDispProd":
        return this.hasConfig("CAMFor") && this.hasConfig("dispProd");
      // CAg
      case "kou_compCMA":

      case "kou_siteCMA":

      case "kou_blgCMA":
        return this.hasConfig("CAMAg") && this.hasConfig("RothCA");
      case "kou_abgCMA":
        return this.hasConfig("CAMAg");
      // CAgEmit
      case "kou_flowCMCompAtmsA":
        return this.hasConfig("CAMAg") && this.hasConfig("RothCA");
      case "kou_flowCMAtmsCropPlnAA":

      case "kou_flowCMAtmsCropProdA":

      case "kou_flowCMFoddAtmsGrazA":

      case "kou_flowCMDebrAtmsBkdnA":
        return this.hasConfig("CAMAg");
      case "kou_flowCMMulcAtmsMdcmA":
        return this.hasConfig("GendecA");
      case "kou_flowCMActvAtmsSdcmA":
        return this.hasConfig("RothCA");
      case "kou_flowCMProdAtmsPdcmA":

      case "kou_flowCMProdAtmsEnerA":
        return this.hasConfig("CAMAg");
      case "kou_flowCMSiteAtmsFireA":
        return this.hasConfig("CAMAg") && this.hasConfig("RothCA");
      case "kou_flowCMCropAtmsFireA":

      case "kou_flowCMDebrAtmsFireA":
        return this.hasConfig("CAMAg");
      case "kou_flowCMMulcAtmsFireA":
        return this.hasConfig("GendecA") && this.hasConfig("CAMAg");
      // CAgPlnt
      case "kou_cropCM":

      case "kou_cropAbgCM":

      case "kou_cropBlgCM":

      case "kou_gbfrCMA":

      case "kou_stlkCMA":

      case "kou_leafCMA":

      case "kou_cortCMA":

      case "kou_firtCMA":

      case "kou_flowCMSuppFoddGrazA":

      case "kou_flowCMCropDebrTurnA":

      case "kou_flowCMGbfrGlitTurnA":

      case "kou_flowCMStlkSlitTurnA":

      case "kou_flowCMLeafLlitTurnA":

      case "kou_flowCMCortCodrTurnA":

      case "kou_flowCMFirtFidrTurnA":

      case "kou_flowCMCropFoddGrazA":

      case "kou_flowCMCropFoddUMGrazA":

      case "kou_flowCMDebrFoddGrazA":

      case "kou_flowCMFoddAnprGrazA":

      case "kou_flowMFoddAnprGrazA":

      case "kou_flowCMFoddActvGrazA":

      case "kou_flowMCropFualHarvA":

      case "kou_flowMCropGbfpHarvA":

      case "kou_flowMCropCaneHarvA":

      case "kou_flowMCropLfprHarvA":

      case "kou_flowMCropRtprHarvA":

      case "kou_flowMCropHssiHarvA":

      case "kou_flowMCropFoddGrazA":

      case "kou_flowMDebrFoddGrazA":

      case "kou_flowCMCropDebrHarvA":

      case "kou_flowCMCropProdHarvA":

      case "kou_flowCMCropDebrFireA":

      case "kou_flowCMCropDebrHerbA":

      case "kou_flowCMCropDebrDieoA":

      case "kou_flowCMGbfrDebrDieoA":

      case "kou_flowCMStlkDebrDieoA":

      case "kou_flowCMLeafDebrDieoA":

      case "kou_flowCMCortDebrDieoA":

      case "kou_flowCMFirtDebrDieoA":

      case "kou_flowCMCropGrthA":

      case "kou_flowCMGbfrGrthA":

      case "kou_flowCMStlkGrthA":

      case "kou_flowCMLeafGrthA":

      case "kou_flowCMCortGrthA":

      case "kou_flowCMFirtGrthA":
        return this.hasConfig("CAMAg");
      // CAgDebr
      case "kou_debrCMA":

      case "kou_abgDebrCMA":

      case "kou_blgDebrCMA":

      case "kou_littCMA":

      case "kou_dGlitCMA":

      case "kou_rGlitCMA":

      case "kou_dSlitCMA":

      case "kou_rSlitCMA":

      case "kou_dLlitCMA":

      case "kou_rLlitCMA":

      case "kou_dCodrCMA":

      case "kou_rCodrCMA":

      case "kou_dFidrCMA":

      case "kou_rFidrCMA":
        return this.hasConfig("CAMAg");
      case "kou_flowCMLittActvBkdnA":
        return this.hasConfig("CAMAg") && !this.hasConfig("GendecA");
      case "kou_flowCMDdrtActvBkdnA":

      case "kou_flowCMDebrProdHarvA":

      case "kou_flowCMDebrInrtFireA":
        return this.hasConfig("CAMAg");
      // CAgMulc
      case "kou_mulcCMA":

      case "kou_sopmCMA":

      case "kou_lrpmCMA":

      case "kou_mrpmCMA":

      case "kou_sommCMA":

      case "kou_lrmmCMA":

      case "kou_mrmmCMA":

      case "kou_micrCMA":

      case "kou_flowCMLittSopmBkdnA":

      case "kou_flowCMLittLrpmBkdnA":

      case "kou_flowCMLittMrpmBkdnA":

      case "kou_flowCMMulcActvHumfA":
        return this.hasConfig("GendecA");
      case "kou_flowCMMulcInrtFireA":
        return this.hasConfig("GendecA") && this.hasConfig("CAMAg");
      // CAgSoil
      case "kou_soilCMA":

      case "kou_dpmaCMA":

      case "kou_rpmaCMA":

      case "kou_biofCMA":

      case "kou_biosCMA":

      case "kou_biomCMA":

      case "kou_humsCMA":
        return this.hasConfig("RothCA");
      case "kou_inrtCMA":

      case "kou_flowCMActvInrtEncpA":
        return this.hasConfig("RothCA") && this.hasConfig("CAMAg");
      case "kou_flowCMOffsActvMoffA":
        return this.hasConfig("RothCA");
      case "kou_flowCMPresActvExtnA":
        return this.hasConfig("RothCA") && !this.hasConfig("CAMAg");
      case "kou_flowCMCropDpmaPlowA":

      case "kou_flowCMCropRpmaPlowA":

      case "kou_flowCMDebrDpmaPlowA":

      case "kou_flowCMDebrRpmaPlowA":
        return this.hasConfig("RothCA") && this.hasConfig("CAMAg");
      case "kou_flowCMMulcDpmaPlowA":

      case "kou_flowCMMulcRpmaPlowA":
        return this.hasConfig("RothCA") && this.hasConfig("GendecA");
      // CAgProd
      case "kou_prodCMA":

      case "kou_fualCM":

      case "kou_gbfpCM":

      case "kou_caneCM":

      case "kou_lfprCM":

      case "kou_rtprCM":

      case "kou_hssiCM":

      case "kou_anprCM":
        return this.hasConfig("CAMAg");
      // CAgDisp
      case "kou_dispCMA":
        return (
          this.hasConfig("CAMAg") &&
          (this.hasConfig("dispEner") || this.hasConfig("dispProd"))
        );
      case "kou_dispEnerCMA":
        return this.hasConfig("CAMAg") && this.hasConfig("dispEner");
      case "kou_dispProdCMA":
        return this.hasConfig("CAMAg") && this.hasConfig("dispProd");
      case "kou_fualCMDispEner":

      case "kou_gbfpCMDispEner":

      case "kou_caneCMDispEner":

      case "kou_lfprCMDispEner":

      case "kou_rtprCMDispEner":

      case "kou_hssiCMDispEner":

      case "kou_anprCMDispEner":

      case "kou_fossCMCompMgmtA":
        return this.hasConfig("CAMAg") && this.hasConfig("dispEner");
      case "kou_gbfpCMDispProd":

      case "kou_caneCMDispProd":

      case "kou_lfprCMDispProd":

      case "kou_rtprCMDispProd":

      case "kou_hssiCMDispProd":

      case "kou_anprCMDispProd":
        return this.hasConfig("CAMAg") && this.hasConfig("dispProd");
      // CLeak
      case "kou_leakCTree":

      case "kou_leakCsDeadF":

      case "kou_leakCDebrF":
        return this.hasConfig("CAMFor");
      case "kou_leakCMulcF":
        return this.hasConfig("GendecF");
      case "kou_leakCSoilF":
        return this.hasConfig("RothCF");
      case "kou_leakCProdF":
        return this.hasConfig("CAMFor");
      case "kou_leakCCrop":

      case "kou_leakCDebrA":
        return this.hasConfig("CAMAg");
      case "kou_leakCMulcA":
        return this.hasConfig("GendecA");
      case "kou_leakCSoilA":
        return this.hasConfig("RothCA");
      case "kou_leakCProdA":
        return this.hasConfig("CAMAg");
      // NFrAg
      case "kou_compNM":

      case "kou_siteNM":

      case "kou_abgNM":

      case "kou_blgNM":

      case "kou_flowNMNetMineraliz":

      case "kou_flowNMGrMineraliz":

      case "kou_flowNMGrImmobiliz":
        return this.hasConfig("simN");
      // NFrAgEmit
      case "kou_flowNMCompAtms":

      case "kou_flowNMAtmsPlnt":

      case "kou_flowNMMnrlAtms":

      case "kou_flowNMProdAtmsPdcm":

      case "kou_flowNMProdAtmsEner":
        return this.hasConfig("simN");
      case "kou_flowN2OMSiteAtmsFire":
        return this.hasConfig("CAMX") && this.hasConfig("RothC");
      // NFrAgPlnt
      case "kou_plntNM":
        return this.hasConfig("simN");
      // NFrAgDebr
      case "kou_debrNM":
        return this.hasConfig("simN");
      // NFrAgMulc
      case "kou_mulcNM":
        return this.hasConfig("Gendec");
      // NFrAgSoil
      case "kou_soilNM":
        return this.hasConfig("simN");
      // NFrAgMnrl
      case "kou_mnrlNM":
        return this.hasConfig("simMnrlN");
      case "kou_flowNMMnrlRbal_AToF":
        return this.hasConfig("simMnrlNF") && this.hasConfig("simMnrlNA");
      case "kou_flowNMMnamRbal_AToF":
        return (
          this.hasConfig("GendecF") &&
          this.hasConfig("GendecA") &&
          !this.hasConfig("simN")
        );
      case "kou_flowNMSAmmRbal_AToF":

      case "kou_flowNMSNtrRbal_AToF":

      case "kou_flowNMDAmmRbal_AToF":

      case "kou_flowNMDNtrRbal_AToF":
        return this.hasConfig("simNF") && this.hasConfig("simNA");
      // NFrAgProd
      case "kou_prodNM":
        return this.hasConfig("simN");
      // NFr
      case "kou_compNMF":

      case "kou_siteNMF":

      case "kou_abgNMF":

      case "kou_blgNMF":

      case "kou_flowNMNetMineralizF":

      case "kou_flowNMGrMineralizF":

      case "kou_flowNMGrImmobilizF":
        return this.hasConfig("simNF");
      // NFrEmit
      case "kou_flowNMCompAtmsF":

      case "kou_flowNMAtmsTreePlnFF":

      case "kou_flowNMAtmsTreeProdF":

      case "kou_flowNMMnrlAtmsF":

      case "kou_flowNMMnrlAtmsNitrF":

      case "kou_flowNMMnrlAtmsNitaF":

      case "kou_flowNMMnrlAtmsNitmF":

      case "kou_flowNMMnrlAtmsDeniF":
        return this.hasConfig("simNF");
      case "kou_ratioN2ToN2ODeniF":

      case "kou_emitNInN2DeniF":

      case "kou_emitNInN2ODeniF":
        return this.hasConfig("simNF"); // !mnrl->f.useTSDeni <-default value in research edition is false
      case "kou_flowNMAtmsMnrlDepoF":

      case "kou_flowNMAtmsMnrlNfixF":

      case "kou_flowNMProdAtmsPdcmF":

      case "kou_flowNMProdAtmsEnerF":

      case "kou_flowNMProdLndfFillF":

      case "kou_flowNMLndfAtmsLdcmF":

      case "kou_flowNMMnrlAtmsBondF":

      case "kou_flowNMMnrlAtmsFireF":
        return this.hasConfig("simNF");
      // NFrPlnt
      case "kou_treeNM":

      case "kou_treeAbgNM":

      case "kou_treeBlgNM":

      case "kou_stemNMF":

      case "kou_branNMF":

      case "kou_barkNMF":

      case "kou_leafNMF":

      case "kou_cortNMF":

      case "kou_firtNMF":

      case "kou_storNMF":

      case "kou_stemCNRatioF":

      case "kou_branCNRatioF":

      case "kou_barkCNRatioF":

      case "kou_leafCNRatioF":

      case "kou_cortCNRatioF":

      case "kou_firtCNRatioF":

      case "kou_minStemCNRatioF":

      case "kou_maxStemCNRatioF":

      case "kou_minBranCNRatioF":

      case "kou_maxBranCNRatioF":

      case "kou_minBarkCNRatioF":

      case "kou_maxBarkCNRatioF":

      case "kou_minLeafCNRatioF":

      case "kou_maxLeafCNRatioF":

      case "kou_minCortCNRatioF":

      case "kou_maxCortCNRatioF":

      case "kou_minFirtCNRatioF":

      case "kou_maxFirtCNRatioF":

      case "kou_flowNMTreeDebrTurnF":

      case "kou_flowNMStemDdwdTurnF":

      case "kou_flowNMBranDdwdTurnF":

      case "kou_flowNMBarkBlitTurnF":

      case "kou_flowNMLeafLlitTurnF":

      case "kou_flowNMLeafDLlitTurnF":

      case "kou_flowNMCortCodrTurnF":

      case "kou_flowNMFirtFidrTurnF":

      case "kou_flowNMStorDdwdTurnF":

      case "kou_flowNMLeafStorTurnF":

      case "kou_flowNMTreeDebrThinF":

      case "kou_flowNMTreeProdThinF":

      case "kou_flowNMTreeDebrFireF":

      case "kou_flowNMTreeMnrlFireF":
        return this.hasConfig("simNF");
      // NFrDebr
      case "kou_debrNMF":

      case "kou_dDdwdNMF":

      case "kou_rDdwdNMF":

      case "kou_dChwdNMF":

      case "kou_rChwdNMF":

      case "kou_dBlitNMF":

      case "kou_rBlitNMF":

      case "kou_dLlitNMF":

      case "kou_rLlitNMF":

      case "kou_dCodrNMF":

      case "kou_rCodrNMF":

      case "kou_dFidrNMF":

      case "kou_rFidrNMF":

      case "kou_dDdwdCNRatioF":

      case "kou_rDdwdCNRatioF":

      case "kou_dChwdCNRatioF":

      case "kou_rChwdCNRatioF":

      case "kou_dBlitCNRatioF":

      case "kou_rBlitCNRatioF":

      case "kou_dLlitCNRatioF":

      case "kou_rLlitCNRatioF":

      case "kou_dCodrCNRatioF":

      case "kou_rCodrCNRatioF":

      case "kou_dFidrCNRatioF":

      case "kou_rFidrCNRatioF":

      case "kou_flowNMDebrAtmsTermF":

      case "kou_flowNMDebrProdThinF":
        return this.hasConfig("simNF");
      // NFrMulc
      case "kou_mulcNMF":

      case "kou_sopmNMF":

      case "kou_lrpmNMF":

      case "kou_mrpmNMF":

      case "kou_sommNMF":

      case "kou_lrmmNMF":

      case "kou_mrmmNMF":

      case "kou_micrNMF":

      case "kou_sopmCNRatioF":

      case "kou_lrpmCNRatioF":

      case "kou_mrpmCNRatioF":

      case "kou_sommCNRatioF":

      case "kou_lrmmCNRatioF":

      case "kou_mrmmCNRatioF":

      case "kou_micrCNRatioF":
        return this.hasConfig("GendecF");
      case "kou_flowNMLittSopmBkdnF":

      case "kou_flowNMLittLrpmBkdnF":

      case "kou_flowNMLittMrpmBkdnF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      // NFrSoil
      case "kou_soilNMF":

      case "kou_dpmaNMF":

      case "kou_rpmaNMF":

      case "kou_biofNMF":

      case "kou_biosNMF":

      case "kou_biomNMF":

      case "kou_humsNMF":

      case "kou_inrtNMF":

      case "kou_dpmaCNRatioF":

      case "kou_rpmaCNRatioF":

      case "kou_biofCNRatioF":

      case "kou_biosCNRatioF":

      case "kou_biomCNRatioF":

      case "kou_humsCNRatioF":

      case "kou_inrtCNRatioF":
        return this.hasConfig("simNF");
      case "kou_flowNMLittActvBkdnF":
        return this.hasConfig("simNF") && !this.hasConfig("GendecF");
      case "kou_flowNMDdrtActvBkdnF":
        return this.hasConfig("simNF");
      case "kou_flowNMMulcActvHumfF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      case "kou_flowNMActvInrtEncpF":

      case "kou_flowNMOffsActvMoffF":

      case "kou_flowNMSoilStorMycoF":
        return this.hasConfig("simNF");
      // NFrMnrl
      case "kou_mnrlNMF":
        return this.hasConfig("simMnrlNF");
      case "kou_mnamNMF":
        return this.hasConfig("GendecF") && !this.hasConfig("simNF");
      case "kou_sAmmNMF":

      case "kou_sNtrNMF":

      case "kou_dAmmNMF":

      case "kou_dNtrNMF":

      case "kou_flowNMMnrlTreeProdF":

      case "kou_flowNMLittMnrlBkdnF":

      case "kou_flowNMDdrtMnrlBkdnF":
        return this.hasConfig("simNF");
      case "kou_flowNMMnrlSopmBkdnF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      case "kou_flowNMMnrlActvBkdnF":
        return this.hasConfig("simNF");
      case "kou_flowNMMulcMnrlMdcmF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      case "kou_flowNMMulcMnrlHumfF":

      case "kou_flowNMMnrlActvHumfF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      case "kou_flowNMActvMnrlSdcmF":
        return this.hasConfig("simNF");
      case "kou_flowNMOffsMnrlNoffF":
        return this.hasConfig("simMnrlNF");
      case "kou_flowNMDebrMnrlFireF":
        return this.hasConfig("simNF");
      case "kou_flowNMMulcMnrlFireF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      case "kou_flowNMMnrlDeepLeacF":

      case "kou_flowNMSAmmSNtrNitaF":

      case "kou_flowNMSAmmSNtrNitmF":

      case "kou_nitrTempFnF":

      case "kou_nitrPhFnF":

      case "kou_nitrWaterFnF":

      case "kou_nitrFnF":

      case "kou_deniCO2FnF":

      case "kou_deniNO3FnF":

      case "kou_deniWaterFnF":

      case "kou_deniFnF":

      case "kou_deniRCO2FnF":

      case "kou_deniRNO3FnF":

      case "kou_deniRWaterFnF":

      case "kou_deniRFnF":
        return this.hasConfig("simNF");
      // NFrProd
      case "kou_prodNMF":

      case "kou_prodUsNMF":

      case "kou_prodLfNMF":

      case "kou_fuelNM":

      case "kou_paprNM":

      case "kou_packNM":

      case "kou_furnNM":

      case "kou_fibrNM":

      case "kou_consNM":

      case "kou_resiNM":

      case "kou_fuelLfNM":

      case "kou_paprLfNM":

      case "kou_packLfNM":

      case "kou_furnLfNM":

      case "kou_fibrLfNM":

      case "kou_consLfNM":

      case "kou_resiLfNM":

      case "kou_fuelCNRatio":

      case "kou_paprCNRatio":

      case "kou_packCNRatio":

      case "kou_furnCNRatio":

      case "kou_fibrCNRatio":

      case "kou_consCNRatio":

      case "kou_resiCNRatio":

      case "kou_fuelLfCNRatio":

      case "kou_paprLfCNRatio":

      case "kou_packLfCNRatio":

      case "kou_furnLfCNRatio":

      case "kou_fibrLfCNRatio":

      case "kou_consLfCNRatio":

      case "kou_resiLfCNRatio":
        return this.hasConfig("simNF");
      // NAg
      case "kou_compNMA":

      case "kou_siteNMA":

      case "kou_abgNMA":

      case "kou_blgNMA":

      case "kou_flowNMNetMineralizA":

      case "kou_flowNMGrMineralizA":

      case "kou_flowNMGrImmobilizA":
        return this.hasConfig("simNA");
      // NAgEmit
      case "kou_flowNMCompAtmsA":

      case "kou_flowNMAtmsCropPlnAA":

      case "kou_flowNMAtmsCropProdA":

      case "kou_flowNMMnrlAtmsA":

      case "kou_flowNMCropAtmsHarvA":

      case "kou_flowNMFoddAtmsGrazA":

      case "kou_flowNMMnrlAtmsNitrA":

      case "kou_flowNMMnrlAtmsNitaA":

      case "kou_flowNMMnrlAtmsNitmA":

      case "kou_flowNMMnrlAtmsDeniA":
        return this.hasConfig("simNA");
      case "kou_ratioN2ToN2ODeniA":

      case "kou_emitNInN2DeniA":

      case "kou_emitNInN2ODeniA":
        return this.hasConfig("simNA"); // !mnrl->f.useTSDeni <-default value in research edition is false
      case "kou_flowNMAtmsMnrlDepoA":

      case "kou_flowNMAtmsMnrlNfixA":

      case "kou_flowNMProdAtmsPdcmA":

      case "kou_flowNMProdAtmsEnerA":

      case "kou_flowNMMnrlAtmsBondA":

      case "kou_flowNMMnrlAtmsFireA":
        return this.hasConfig("simNA");
      // NAgPlnt
      case "kou_cropNM":

      case "kou_gbfrNMA":

      case "kou_stlkNMA":

      case "kou_leafNMA":

      case "kou_cortNMA":

      case "kou_firtNMA":

      case "kou_storNMA":

      case "kou_gbfrCNRatioA":

      case "kou_stlkCNRatioA":

      case "kou_leafCNRatioA":

      case "kou_cortCNRatioA":

      case "kou_firtCNRatioA":

      case "kou_minGbfrCNRatioA":

      case "kou_maxGbfrCNRatioA":

      case "kou_minStlkCNRatioA":

      case "kou_maxStlkCNRatioA":

      case "kou_minLeafCNRatioA":

      case "kou_maxLeafCNRatioA":

      case "kou_minCortCNRatioA":

      case "kou_maxCortCNRatioA":

      case "kou_minFirtCNRatioA":

      case "kou_maxFirtCNRatioA":

      case "kou_flowNMCropDebrTurnA":

      case "kou_flowNMGbfrGlitTurnA":

      case "kou_flowNMStlkSlitTurnA":

      case "kou_flowNMLeafLlitTurnA":

      case "kou_flowNMLeafDLlitTurnA":

      case "kou_flowNMCortCodrTurnA":

      case "kou_flowNMFirtFidrTurnA":

      case "kou_flowNMStorSlitTurnA":

      case "kou_flowNMLeafStorTurnA":

      case "kou_flowNMCropFoddGrazA":

      case "kou_flowNMDebrFoddGrazA":

      case "kou_flowNMFoddActvGrazA":

      case "kou_flowNMFoddMnrlGrazA":

      case "kou_flowNMFoddAnprGrazA":

      case "kou_flowNMCropDebrHarvA":

      case "kou_flowNMCropProdHarvA":

      case "kou_flowNMCropDebrFireA":

      case "kou_flowNMCropDebrHerbA":
        return this.hasConfig("simNA");
      // NAgDebr
      case "kou_debrNMA":

      case "kou_dGlitNMA":

      case "kou_rGlitNMA":

      case "kou_dSlitNMA":

      case "kou_rSlitNMA":

      case "kou_dLlitNMA":

      case "kou_rLlitNMA":

      case "kou_dCodrNMA":

      case "kou_rCodrNMA":

      case "kou_dFidrNMA":

      case "kou_rFidrNMA":

      case "kou_dGlitCNRatioA":

      case "kou_rGlitCNRatioA":

      case "kou_dSlitCNRatioA":

      case "kou_rSlitCNRatioA":

      case "kou_dLlitCNRatioA":

      case "kou_rLlitCNRatioA":

      case "kou_dCodrCNRatioA":

      case "kou_rCodrCNRatioA":

      case "kou_dFidrCNRatioA":

      case "kou_rFidrCNRatioA":

      case "kou_flowNMDebrProdHarvA":
        return this.hasConfig("simNA");
      // NAgMulc
      case "kou_mulcNMA":

      case "kou_sopmNMA":

      case "kou_lrpmNMA":

      case "kou_mrpmNMA":

      case "kou_sommNMA":

      case "kou_lrmmNMA":

      case "kou_mrmmNMA":

      case "kou_micrNMA":

      case "kou_sopmCNRatioA":

      case "kou_lrpmCNRatioA":

      case "kou_mrpmCNRatioA":

      case "kou_sommCNRatioA":

      case "kou_lrmmCNRatioA":

      case "kou_mrmmCNRatioA":

      case "kou_micrCNRatioA":
        return this.hasConfig("GendecA");
      case "kou_flowNMLittSopmBkdnA":

      case "kou_flowNMLittLrpmBkdnA":

      case "kou_flowNMLittMrpmBkdnA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      // NAgSoil
      case "kou_soilNMA":
        return this.hasConfig("simNA");
      case "kou_dpmaNMA":

      case "kou_rpmaNMA":

      case "kou_biofNMA":

      case "kou_biosNMA":

      case "kou_biomNMA":

      case "kou_humsNMA":
        return this.hasConfig("simNA") && this.hasConfig("RothCA");
      case "kou_inrtNMA":
        return this.hasConfig("simNA");
      case "kou_dpmaCNRatioA":

      case "kou_rpmaCNRatioA":

      case "kou_biofCNRatioA":

      case "kou_biosCNRatioA":

      case "kou_biomCNRatioA":

      case "kou_humsCNRatioA":
        return this.hasConfig("simNA") && this.hasConfig("RothCA");
      case "kou_inrtCNRatioA":
        return this.hasConfig("simNA");
      case "kou_flowNMLittActvBkdnA":
        return this.hasConfig("simNA") && !this.hasConfig("GendecA");
      case "kou_flowNMDdrtActvBkdnA":
        return this.hasConfig("simNA");
      case "kou_flowNMMulcActvHumfA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      case "kou_flowNMActvInrtEncpA":

      case "kou_flowNMOffsActvMoffA":

      case "kou_flowNMSoilStorMycoA":

      case "kou_flowNMCropDpmaPlowA":

      case "kou_flowNMCropRpmaPlowA":

      case "kou_flowNMDebrDpmaPlowA":

      case "kou_flowNMDebrRpmaPlowA":
        return this.hasConfig("simNA");
      case "kou_flowNMMulcDpmaPlowA":

      case "kou_flowNMMulcRpmaPlowA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      // NAgMnrl
      case "kou_mnrlNMA":
        return this.hasConfig("simMnrlNA");
      case "kou_mnamNMA":
        return this.hasConfig("GendecA") && !this.hasConfig("simNA");
      case "kou_sAmmNMA":

      case "kou_sNtrNMA":

      case "kou_dAmmNMA":

      case "kou_dNtrNMA":

      case "kou_flowNMMnrlCropProdA":

      case "kou_flowNMLittMnrlBkdnA":

      case "kou_flowNMDdrtMnrlBkdnA":
        return this.hasConfig("simNA");
      case "kou_flowNMMnrlSopmBkdnA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      case "kou_flowNMMnrlActvBkdnA":
        return this.hasConfig("simNA");
      case "kou_flowNMMulcMnrlMdcmA":

      case "kou_flowNMMulcMnrlHumfA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      case "kou_flowNMMnrlActvHumfA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      case "kou_flowNMActvMnrlSdcmA":
        return this.hasConfig("simNA");
      case "kou_flowNMOffsMnrlNoffA":
        return this.hasConfig("simMnrlNA");
      case "kou_flowNMCropMnrlFireA":
        return this.hasConfig("simNA");
      case "kou_flowNMMulcMnrlFireA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      case "kou_flowNMDebrMnrlFireA":

      case "kou_flowNMMnrlDeepLeacA":

      case "kou_flowNMSAmmSNtrNitaA":

      case "kou_flowNMSAmmSNtrNitmA":

      case "kou_nitrTempFnA":

      case "kou_nitrPhFnA":

      case "kou_nitrWaterFnA":

      case "kou_nitrFnA":

      case "kou_deniCO2FnA":

      case "kou_deniNO3FnA":

      case "kou_deniWaterFnA":

      case "kou_deniFnA":

      case "kou_deniRCO2FnA":

      case "kou_deniRNO3FnA":

      case "kou_deniRWaterFnA":

      case "kou_deniRFnA":
        return this.hasConfig("simNA");
      // NAgProd
      case "kou_prodNMA":

      case "kou_fualNM":

      case "kou_gbfpNM":

      case "kou_caneNM":

      case "kou_lfprNM":

      case "kou_rtprNM":

      case "kou_hssiNM":

      case "kou_anprNM":

      case "kou_fualCNRatio":

      case "kou_gbfpCNRatio":

      case "kou_caneCNRatio":

      case "kou_lfprCNRatio":

      case "kou_rtprCNRatio":

      case "kou_hssiCNRatio":

      case "kou_anprCNRatio":
        return this.hasConfig("simNA");
      // NLeak
      case "kou_leakNTree":

      case "kou_leakNDebrF":
        return this.hasConfig("simNF");
      case "kou_leakNMulcF":
        return this.hasConfig("simNF") && this.hasConfig("GendecF");
      case "kou_leakNSoilF":

      case "kou_leakNMnrlF":

      case "kou_leakNProdF":
        return this.hasConfig("simNF");
      case "kou_leakNCrop":

      case "kou_leakNDebrA":
        return this.hasConfig("simNA");
      case "kou_leakNMulcA":
        return this.hasConfig("simNA") && this.hasConfig("GendecA");
      case "kou_leakNSoilA":

      case "kou_leakNMnrlA":

      case "kou_leakNProdA":
        return this.hasConfig("simNA");
      // DLedg, DCumu
      case "kou_flowCost":

      case "kou_flowCostHarv":

      case "kou_flowCostTrns":

      case "kou_flowIncmMill":

      case "kou_cumuCost":

      case "kou_cumuCostHarv":

      case "kou_cumuCostTrns":

      case "kou_cumuIncmMill":

      case "kou_priceCO2":

      case "kou_priceC":
        return this.hasConfig("econ");

      case "kou_flowIncm":

      case "kou_flowCash":

      case "kou_flowCashDiscounted":

      case "kou_NPV":

      case "kou_cIncm":

      case "kou_cumuIncm":

      case "kou_cumuCash":

      case "kou_cumuCIncm":
        return (
          this.hasConfig("econ") &&
          this.hasConfig("CAMX") &&
          this.hasConfig("RothC")
        );
      case "kou_cIncmF":

      case "kou_cumuCIncmF":
        return (
          this.hasConfig("econ") &&
          this.hasConfig("CAMFor") &&
          this.hasConfig("RothCF")
        );
      case "kou_cIncmA":

      case "kou_cumuCIncmA":
        return (
          this.hasConfig("econ") &&
          this.hasConfig("CAMAg") &&
          this.hasConfig("RothCA")
        );

      case "kou_cIncmTreeF":

      case "kou_cIncmDebrF":
        return this.hasConfig("econ") && this.hasConfig("CAMFor");
      case "kou_cIncmMulcF":
        return this.hasConfig("econ") && this.hasConfig("GendecF");
      case "kou_cIncmSoilF":
        return this.hasConfig("econ") && this.hasConfig("RothCF");
      case "kou_cIncmProdF":
        return this.hasConfig("econ") && this.hasConfig("CAMFor");
      case "kou_cIncmCropA":

      case "kou_cIncmDebrA":
        return this.hasConfig("econ") && this.hasConfig("CAMAg");
      case "kou_cIncmMulcA":
        return this.hasConfig("econ") && this.hasConfig("GendecA");
      case "kou_cIncmSoilA":
        return this.hasConfig("econ") && this.hasConfig("RothCA");
      case "kou_cIncmProdA":
        return this.hasConfig("econ") && this.hasConfig("CAMAg");
      // IndTiming
      case "kou_NA":
        return false;
      case "kou_yr":

      case "kou_mo":

      case "kou_day":

      case "kou_dayOfYr":

      case "kou_stepEnTimeInYrs":

      case "kou_stepEnTimeInDays":

      case "kou_step":

      case "kou_dateStr":
        return true;
      case "kou_treeAge":
        return this.hasConfig("CAMFor") || this.hasConfig("PPPG");
      case "kou_tyfTreeAge":

      case "kou_tyf_G":

      case "kou_tyf_y":
        return this.hasConfig("tyfOn");
      case "kou_maxTreeAge":

      case "kou_avgTreeAge":
        return this.hasConfig("CAMFor") || this.hasConfig("PPPG");
      case "kou_avgTreeAgeMort":
        return this.hasConfig("CAMFor");
      case "kou_cropAge":

      case "kou_maxCropAge":

      case "kou_avgCropAge":

      case "kou_avgCropAgeMort":
        return this.hasConfig("CAMAg");
      // IndDB
      case "kou_eventCategory":

      case "kou_mgmtRegime":

      case "kou_mgmtSpecA":

      case "kou_mgmtSpecF":

      case "kou_mgmtRegimeChanged":

      case "kou_mgmtPersistCount":
      // (spa || sim);
      case "kou_rainfall":
        return this.waterUsed(false);
      case "kou_openPanEvap":
        return this.hasConfig("RothC");
      case "kou_VPD":
        return this.hasConfig("PPPG") && this.getSiteValue("tVPD") == "Direct";
      // IndFrOther
      case "kou_timeSinceLastClr":
        return this.hasConfig("CAMFor") || this.hasConfig("PPPG");
      // IndFrAgOther
      case "kou_frFrac":
        return this.hasConfig("CAMFor") && this.hasConfig("CAMAg");
      case "kou_avgAirTemp":
        return this.airTempUsed(false);
      case "kou_rangeAirTemp":

      case "kou_minAirTemp":

      case "kou_maxAirTemp":
        return (
          this.airTempUsed(false) &&
          (this.getSiteValue("tAirTemp") == "RangeMin" ||
            (this.hasConfig("PPPG") && this.getSiteValue("tVPD") == "RangeMin"))
        );
      case "kou_soilTemp":
        return this.soilTempUsed();
      case "kou_fertility":

      case "kou_solarRad":

      case "kou_frostNights":
        return this.hasConfig("PPPG");

      case "kou_flowMSiteAtmsFire":
        return this.hasConfig("CAMX") && this.hasConfig("RothC");
      // IndFrPlnt
      case "kou_treeM":

      case "kou_treeAbgM":

      case "kou_treeBlgM":

      case "kou_stemVolF":

      case "kou_stemDensity":

      case "kou_stemMF":

      case "kou_branMF":

      case "kou_barkMF":

      case "kou_leafMF":

      case "kou_cortMF":

      case "kou_firtMF":
        return this.hasConfig("CAMFor");
      case "kou_forestProdIx":
        return this.hasConfig("tyfOn");
      case "kou_stemMortF":
        return this.hasConfig("CAMFor");
      case "kou_LAI":

      case "kou_NPP":
        return this.hasConfig("PPPG");
      case "kou_modTemp":

      case "kou_modNutr":

      case "kou_modFrost":
        return this.hasConfig("PPPG") || this.hasConfig("pppgL");
      case "kou_modVPD":
        return this.hasConfig("PPPG") || this.hasConfig("calcModASW");
      case "kou_modAge":
        return this.hasConfig("PPPG");
      case "kou_modASW":
        return this.hasConfig("PPPG") || this.hasConfig("pppgL");
      case "kou_modPhysio":
        return this.hasConfig("PPPG");
      case "kou_nStems":

      case "kou_stemM3PG":

      case "kou_foliM3PG":

      case "kou_rootM3PG":

      case "kou_stemVol3PG":

      case "kou_MAI":

      case "kou_avgDBH":

      case "kou_annualLitterfall":

      case "kou_annualTranspiration":
        return !this.hasConfig("CAMFor") && this.hasConfig("PPPG");
      case "kou_expansionFactor":
        return this.hasConfig("CAMFor");
      // IndFrWater
      case "kou_TSMDF":

      case "kou_maxTSMDF":
        return this.hasConfig("RothCF");
      case "kou_defnitIrrigF":
        return this.waterUsedF(false);
      case "kou_conditIrrigF":

      case "kou_conditIrrigMMF":
        return this.hasConfig("RothCF") && this.getSiteValue("conditIrrigOnF");
      case "kou_rainAndIrrigF":
        return this.waterUsedF(false);
      case "kou_evapF":

      case "kou_netWaterInputF":
        return this.hasConfig("RothCF");
      case "kou_excessWaterF":

      case "kou_drainF":

      case "kou_runoffF":
        return this.hasConfig("RothCF") && this.hasConfig("simNF");
      case "kou_rateTempWaterF":
        return this.waterUsedF(false);
      case "kou_ASW":
        return this.hasConfig("PPPG") || this.hasConfig("calcModASW");
      case "kou_transpirationPS":

      case "kou_evaporationPS":
        return this.hasConfig("PPPG") || this.hasConfig("calcModASW");
      case "kou_eqmEvap":
        return this.hasConfig("tyfOn") && this.hasConfig("calcModASW");
      // IndFrRate
      case "kou_prodRateStemMF":

      case "kou_prodRateBranMF":

      case "kou_prodRateBarkMF":

      case "kou_prodRateLeafMF":

      case "kou_prodRateCortMF":

      case "kou_prodRateFirtMF":

      case "kou_prodRateTreeMF":

      case "kou_prodRateAbgFMF":

      case "kou_turnRateStemMF":

      case "kou_turnRateBranMF":

      case "kou_turnRateBarkMF":

      case "kou_turnRateLeafMF":

      case "kou_turnRateCortMF":

      case "kou_turnRateFirtMF":

      case "kou_turnRateTreeMF":

      case "kou_turnRateAbgFMF":

      case "kou_yielRateStemMF":

      case "kou_yielRateBranMF":

      case "kou_yielRateBarkMF":

      case "kou_yielRateLeafMF":

      case "kou_yielRateCortMF":

      case "kou_yielRateFirtMF":

      case "kou_yielRateTreeMF":

      case "kou_yielRateAbgFMF":
        return this.hasConfig("CAMFor");
      // IndFrLog
      case "kou_flowLogsTot":

      case "kou_flowWoodTot":

      case "kou_flowBarkTot":

      case "kou_flowFuelTot":

      case "kou_flowWood1":

      case "kou_flowBark1":

      case "kou_flowFuel1":

      case "kou_flowWood2":

      case "kou_flowBark2":

      case "kou_flowFuel2":

      case "kou_flowWood3":

      case "kou_flowBark3":

      case "kou_flowFuel3":

      case "kou_flowWood4":

      case "kou_flowBark4":

      case "kou_flowFuel4":

      case "kou_flowWood5":

      case "kou_flowBark5":

      case "kou_flowFuel5":
        return this.hasConfig("CAMFor") && this.hasConfig("econ");
      // IndAgPlnt
      case "kou_cropM":

      case "kou_cropAbgM":

      case "kou_cropBlgM":

      case "kou_gbfrMA":

      case "kou_stlkMA":

      case "kou_leafMA":

      case "kou_cortMA":

      case "kou_firtMA":

      case "kou_stlkMortA":
        return this.hasConfig("CAMAg");
      case "kou_soilCoverA":
        return this.hasConfig("RothCA");
      // IndAgWater
      case "kou_TSMDA":

      case "kou_maxTSMDA":
        return this.hasConfig("RothCA");
      case "kou_defnitIrrigA":
        return this.waterUsedA(false);
      case "kou_conditIrrigA":

      case "kou_conditIrrigMMA":
        return this.hasConfig("RothCA") && this.getSiteValue("conditIrrigOnA");
      case "kou_rainAndIrrigA":
        return this.waterUsedA(false);
      case "kou_evapA":

      case "kou_netWaterInputA":
        return this.hasConfig("RothCA");
      case "kou_excessWaterA":

      case "kou_drainA":

      case "kou_runoffA":
        return this.hasConfig("RothCA") && this.hasConfig("simNA");
      case "kou_rateTempWaterA":
        return this.waterUsedA(false);
      // IndAgRate
      case "kou_prodRateGbfrMA":

      case "kou_prodRateStlkMA":

      case "kou_prodRateLeafMA":

      case "kou_prodRateCortMA":

      case "kou_prodRateFirtMA":

      case "kou_prodRateCropMA":

      case "kou_prodRateAbgAMA":

      case "kou_turnRateGbfrMA":

      case "kou_turnRateStlkMA":

      case "kou_turnRateLeafMA":

      case "kou_turnRateCortMA":

      case "kou_turnRateFirtMA":

      case "kou_turnRateCropMA":

      case "kou_turnRateAbgAMA":

      case "kou_yielRateGbfrMA":

      case "kou_yielRateStlkMA":

      case "kou_yielRateLeafMA":

      case "kou_yielRateCortMA":

      case "kou_yielRateFirtMA":

      case "kou_yielRateCropMA":

      case "kou_yielRateAbgAMA":
        return this.hasConfig("CAMAg");
      case "kou_decRateMultiRPM": {
        /********* this part might have to move to the right location, to reduce the code complex level***********/
        const version = this.simulationService.version;
        if (version == "2024") {
          return this.hasConfig("CAMAg");
        } else {
          //this is not available in 2020
          return false;
        }
      }

      case "kou_decRateMultiHUM": {
        /********* this part might have to move to the right location, to reduce the code complex level***********/

        const version = this.simulationService.version;
        if (version == "2024") {
          return this.hasConfig("CAMAg");
        } else {
          //this is not available in 2020
          return false;
        }
      }

      // IndAgCrop
      case "kou_flowCropTot":

      case "kou_flowProdTot":

      case "kou_flowHssiTot":

      case "kou_flowFualTot":

      case "kou_flowProd1":

      case "kou_flowHssi1":

      case "kou_flowFual1":

      case "kou_flowProd2":

      case "kou_flowHssi2":

      case "kou_flowFual2":

      case "kou_flowProd3":

      case "kou_flowHssi3":

      case "kou_flowFual3":

      case "kou_flowProd4":

      case "kou_flowHssi4":

      case "kou_flowFual4":
        return this.hasConfig("CAMAg") && this.hasConfig("econ");
      case "kou_flowCMDpmaAtmsPlowA":

      case "kou_flowCMRpmaAtmsPlowA":

      case "kou_flowCMBiofAtmsPlowA":

      case "kou_flowCMBiosAtmsPlowA":

      case "kou_flowCMHumAtmsPlowA":
        return this.hasConfig("CAMAg");
      case "kou_treeDebrProdCS":

      case "kou_treeDebrCS":

      case "kou_emissionFromFire":

      case "kou_noForProdCS":

      case "kou_forProdCS":
        return this.hasConfig("CAMFor") && this.hasConfig("RothCF");
      case "kou_cpdebrCMF":
        return this.hasConfig("CAMFor");
      // Reusable
      case "kou_error1":
        return false;
      default:
        return false;
    }
  }

  protected hasConfig(arg): boolean {
    const configService = this.simulationService.configurationService;
    //check variable
    if (configService[arg] !== undefined) {
      return configService[arg];
    } else if (configService.getFormGroup().get(arg)?.value !== undefined) {
      return configService.getFormGroup().get(arg)?.value;
    }

    return false;
  }

  protected getSiteValue(arg) {
    return this.simulationService.siteService.getFormGroup().get(arg)?.value;
  }

  protected waterUsedF(considerConfigOnly: boolean): boolean {
    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();
    return (
      ["pld", "plo", "sim"].includes(plotFormat) &&
      (this.hasConfig("RothCF") ||
        this.hasConfig("GendecF") ||
        this.hasConfig("PPPG") ||
        this.hasConfig("calcModASW") ||
        (this.hasConfig("CAMFor") && considerConfigOnly))
    );
    //research edition fields
    //|| specSetF->SensitiveToRainAndTempF(config)))
  }

  protected waterUsedA(considerConfigOnly: boolean): boolean {
    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();
    return (
      ["pld", "plo", "sim"].includes(plotFormat) &&
      (this.hasConfig("RothCA") ||
        this.hasConfig("GendecA") ||
        (this.hasConfig("CAMAg") && considerConfigOnly))
    );

    //research edition fields
    //|| specSetF->SensitiveToRainAndTempF(config)))
  }

  protected waterUsed(considerConfigOnly: boolean): boolean {
    return (
      this.waterUsedF(considerConfigOnly) || this.waterUsedA(considerConfigOnly)
    );
  }

  protected airTempUsed(considerConfigOnly: boolean) {
    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();
    if (!["pld", "plo", "sim"].includes(plotFormat)) {
      return false;
    }

    let b: boolean;

    if (considerConfigOnly)
      b =
        this.hasConfig("RothC") ||
        this.hasConfig("Gendec") ||
        this.hasConfig("PPPG") ||
        this.hasConfig("pppgL") ||
        this.soilTempUsed();
    else
      b =
        this.hasConfig("RothC") ||
        this.hasConfig("Gendec") ||
        this.hasConfig("PPPG") ||
        this.hasConfig("calcModTemp");
    //|| (this.soilTempUsed() && this.getSiteValue('tSoilTemp')  == kspAirTemp)

    return b;

    //research edition fields
    //tSoilTemp')  == kspAirTemp
    //|| specSetF->SensitiveToRainAndTempF(config)))
  }

  protected soilTempUsed() {
    // SoilTemp is only used for:
    //  - Boundary-line emissions {simN}
    //  - NCycle nitrification temperature function {simN}
    const plotFormat = this.simulationService.selectedPlotFormat$.getValue();
    if (!["pld", "plo", "sim"].includes(plotFormat)) {
      return false;
    }

    return this.hasConfig("simN");
  }

  public readXmlObject(): void {
    super.readXmlObject();

    const allOutputKeyValues = OutputInfo.getAllOutputKeyValues();

    const template = this.simulationService.getPlotFileJson()["OutWinSet"][0];

    const outputWindows = template.OutWin;

    if (outputWindows?.length) {
      const owFormArray = this.getOutputWindowsFormArray();

      outputWindows.forEach((owXmlObj, index) => {
        const oWformGroup = this.createFormGroup(this.outputWindowFormModel);
        const expandIndexs = owXmlObj.expandOW
          ? owXmlObj.expandOW[0]._.split(",")
          : null;

        let formValue = {
          title: owXmlObj.$.winNmOW,
          isDisabled: owXmlObj.$.activeWinOW == "false",
          isActive: index == 0 ? true : false,
          viewType: owXmlObj.$.tViewOW,
          outputSelection: {},
          outputTree: [],
        };

        if (owXmlObj.OutLook?.length) {
          owXmlObj.OutLook.forEach((ol) => {
            const programmingName = ol.$.tOutOL;
            if (
              this.isOutputAvailable(
                "kou_" + programmingName,
                allOutputKeyValues
              )
            ) {
              formValue.outputSelection[programmingName] = true;
            }
          });
        }

        oWformGroup.patchValue(formValue);
        owFormArray.push(oWformGroup);
        this.buildTree(oWformGroup, expandIndexs);
      });
    }
    // const config = template.$;

    // this.applyXmlValuesToFormGroup(config, this.formGroup);
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();
    const nonScenarioOutWins = formData.outputWindows.filter(
      (ow) => !ow.isScenarioView
    );
    const outWin = nonScenarioOutWins.map((ow) => {
      return {
        $: {
          count: Object.keys(ow.outputSelection).length,
          tViewOW: ow.viewType,
          activeWinOW: !ow.isDisabled,
          winNmOW: ow.title.trim(),
          tblTopRow: "2",
          tblLeftCol: "2",
        },
        OutLook: Object.keys(ow.outputSelection).map((programmingName) => {
          return {
            $: {
              tOutOL: programmingName,
              nDecPlacesOL: "0",
              lineWidthOL: "8",
              lineStyleOL: "0",
              colWidthOL: "42",
              logOfMultOL: "0",
              colorOL: "16744576",
              reportGainLossOL: "false",
            },
          };
        }),
        GraphLook: [
          {
            $: {
              outColorGL: "16777215",
              inColorGL: "14143696",
            },
            Rbox: [
              {
                $: {
                  L: "60",
                  T: "62",
                  R: "580",
                  B: "324",
                },
              },
            ],
            Legend: [
              {
                $: {
                  id: "legendGL",
                  legendOn: "true",
                  clearBackLG: "false",
                  autoSizeLG: "true",
                  backColorLG: "-16777192",
                  borderColorLG: "0",
                  borderWidthLG: "0",
                },
                Rbox: [
                  {
                    $: {
                      L: "50",
                      T: "372",
                      R: "590",
                      B: "540",
                    },
                  },
                ],
                FontX: [
                  {
                    $: {
                      fontNm: "Arial",
                      colorFO: "0",
                      sizeFO: "8",
                      charsetFO: "1",
                      pitchFO: "Default",
                      styleFO: "Plain",
                    },
                  },
                ],
              },
            ],
            Title: [
              {
                $: {
                  id: "graphTitleGL",
                  titleTI: "",
                  autoStrTI: "true",
                  vertTextTI: "false",
                  clearBackTI: "true",
                  backColorTI: "16777215",
                  borderColorTI: "0",
                  borderWidthTI: "0",
                  xAlignTI: "CenterH",
                  yAlignTI: "Top",
                },
                Rbox: [
                  {
                    $: {
                      L: "100",
                      T: "42",
                      R: "540",
                      B: "63",
                    },
                  },
                ],
                FontX: [
                  {
                    $: {
                      fontNm: "Arial",
                      colorFO: "-16777203",
                      sizeFO: "12",
                      charsetFO: "1",
                      pitchFO: "Default",
                      styleFO: "Bold",
                    },
                  },
                ],
              },
              {
                $: {
                  id: "xAxisTitleGL",
                  titleTI: "",
                  autoStrTI: "true",
                  vertTextTI: "false",
                  clearBackTI: "true",
                  backColorTI: "16777215",
                  borderColorTI: "0",
                  borderWidthTI: "0",
                  xAlignTI: "CenterH",
                  yAlignTI: "Top",
                },
                Rbox: [
                  {
                    $: {
                      L: "100",
                      T: "349",
                      R: "540",
                      B: "379",
                    },
                  },
                ],
                FontX: [
                  {
                    $: {
                      fontNm: "Arial",
                      colorFO: "-16777208",
                      sizeFO: "9",
                      charsetFO: "1",
                      pitchFO: "Default",
                      styleFO: "Bold",
                    },
                  },
                ],
              },
              {
                $: {
                  id: "yAxisTitleGL",
                  titleTI: "",
                  autoStrTI: "true",
                  vertTextTI: "true",
                  clearBackTI: "true",
                  backColorTI: "16777215",
                  borderColorTI: "0",
                  borderWidthTI: "0",
                  xAlignTI: "Left",
                  yAlignTI: "CenterV",
                },
                Rbox: [
                  {
                    $: {
                      L: "1",
                      T: "62",
                      R: "29",
                      B: "324",
                    },
                  },
                ],
                FontX: [
                  {
                    $: {
                      fontNm: "Arial",
                      colorFO: "-16777208",
                      sizeFO: "9",
                      charsetFO: "1",
                      pitchFO: "Default",
                      styleFO: "Bold",
                    },
                  },
                ],
              },
            ],
            Axis: [
              {
                $: {
                  id: "xAxis",
                  minUser: "1990.0",
                  maxUser: "2020.0",
                  majorUnitUser: "5.0",
                  minorUnitUser: "1.0",
                  tickStartUser: "1990.0",
                  autoScale: "true",
                  labelledAX: "true",
                  majorTicksFarSide: "false",
                  minorTicksFarSide: "false",
                  nDecPlacesAX: "0",
                  gridlineWidthAX: "2",
                  widthAX: "1",
                  colorAX: "0",
                  tMajorTick: "TicksOutside",
                  tMinorTick: "TicksOutside",
                },
                FontX: [
                  {
                    $: {
                      fontNm: "Arial",
                      colorFO: "0",
                      sizeFO: "9",
                      charsetFO: "1",
                      pitchFO: "Default",
                      styleFO: "Plain",
                    },
                  },
                ],
                Rbox: [
                  {
                    $: {
                      L: "60",
                      T: "319",
                      R: "580",
                      B: "354",
                    },
                  },
                ],
              },
              {
                $: {
                  id: "yAxis",
                  minUser: "0.0",
                  maxUser: "1000.0",
                  majorUnitUser: "200.0",
                  minorUnitUser: "50.0",
                  tickStartUser: "0.0",
                  autoScale: "true",
                  labelledAX: "true",
                  majorTicksFarSide: "false",
                  minorTicksFarSide: "false",
                  nDecPlacesAX: "0",
                  gridlineWidthAX: "0",
                  widthAX: "1",
                  colorAX: "0",
                  tMajorTick: "TicksOutside",
                  tMinorTick: "TicksOutside",
                },
                FontX: [
                  {
                    $: {
                      fontNm: "Arial",
                      colorFO: "0",
                      sizeFO: "9",
                      charsetFO: "1",
                      pitchFO: "Default",
                      styleFO: "Plain",
                    },
                  },
                ],
                Rbox: [
                  {
                    $: {
                      L: "30",
                      T: "62",
                      R: "65",
                      B: "324",
                    },
                  },
                ],
              },
            ],
          },
        ],

        WinState: [
          {
            $: {
              L: "10",
              T: "103",
              clientW: "477",
              clientH: "223",
              ws: "Normal",
            },
          },
        ],
        expandOW: [
          {
            _: "f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,f,t,t,t,t,f,f,f,f,f,f,t,f,f,f,f,f,f,f,f,f,f,f,t,t,f,t,f",
            $: {
              count: "940",
            },
          },
        ],
      };
    });

    return { OutWinSet: [{ OutWin: outWin }] };
  }

  public reset(): void {
    this.totalOutputsWithinCategories$.next(0);
    this.totalSelectedOutputs$.next(0);
    this.multiSelectRowInstructionDisplayed = false;
    super.reset();
  }
}
