<legend>OPTIONS & MODIFIERS</legend>
<ul>
  <li>
    <div class="label">FILE NAME</div>
    <div
      class="modifier-value"
      [title]="
        simulationService.plotMetaData
          ? simulationService.plotMetaData.fileName
          : aboutFormGroup?.get('nmME').value
      "
    >
      {{
        simulationService.plotMetaData
          ? simulationService.plotMetaData.fileName
          : aboutFormGroup?.get("nmME").value
      }}
    </div>
  </li>

  <li>
    <div class="label">
      {{ simulationService.aboutService.getFileNameLabel() }}
    </div>
    <div class="modifier-value" [title]="aboutFormGroup?.get('nmME').value">
      {{ aboutFormGroup?.get("nmME").value }}
    </div>
  </li>
  <li>
    <div class="label">PROJECT</div>
    <div
      class="modifier-value"
      [title]="simulationService.aboutService.getSelectedProject()?.name"
    >
      {{ simulationService.aboutService.getSelectedProject()?.name }}
    </div>
  </li>
  <li>
    <div class="label">COLLECTION</div>
    <div
      class="modifier-value"
      [title]="simulationService.aboutService.getSelectedCollection()?.name"
    >
      {{ simulationService.aboutService.getSelectedCollection()?.name }}
    </div>
  </li>

  <li *ngIf="currentPageId !== 'configuration' && !isEstate">
    <div class="label">PLOT TYPE</div>
    <div
      class="modifier-value"
      [title]="simulationService.configurationService.getSelectedPlotTypeName()"
    >
      {{ simulationService.configurationService.getSelectedPlotTypeName() }}
    </div>
  </li>

  <li *ngIf="currentPageId !== 'dataBuilder' && !isEstate">
    <div class="label">LOCATION</div>
    <div
      class="modifier-value nested-value"
      [title]="
        'Long: ' +
        dataBuilderFormGroup?.get('lonBL')?.value +
        ' , Lat: ' +
        dataBuilderFormGroup?.get('latBL')?.value
      "
    >
      <div *ngIf="dataBuilderFormGroup?.get('lonBL')?.value">
        <span class="label">Long:</span>

        <span class="modifier-value">
          {{ dataBuilderFormGroup?.get("lonBL")?.value + ", " }}
        </span>
      </div>
      <div *ngIf="dataBuilderFormGroup?.get('latBL')?.value">
        <span class="label">Lat:</span>

        <span class="modifier-value">
          {{ dataBuilderFormGroup?.get("latBL")?.value }}
        </span>
      </div>
    </div>
  </li>
</ul>
<div
  fc-form-group-element
  class="sub-groups"
  [formGroupInstance]="formGroupInstance"
  [service]="service"
  [simulationService]="simulationService"
  [group]="group"
  [isModifiersCol]="true"
  *ngFor="let group of data"
></div>

<!-- <div class="accordion-header"><h3></h3></div>

<li *ngFor="let item of data">
  <div class="label">{{ item.label }}</div>
  <div class="modifier-value">{{ item.value }}</div>
</li> -->
