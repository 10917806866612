import { Injectable } from "@angular/core";
import { UntypedFormArray, Validators } from "@angular/forms";
import { FieldWatcher, FormModel } from "src/app/shared/models";
import { BaseSpeciesService } from "src/app/shared/services/base-species.service";
import { FormGroupElement, FormLayout } from "../../models";

const growthGroups: FormGroupElement[] = [
  {
    isShown: true,
    showErrors: true,
    isUnderlineHeader: true,
    helpLink: "42_Growth%20Properties.htm",
    label:
      "Properties of the Allocations, Increments, and Other Species Time Series",
    items: [
      {
        label:
          "Plant age to use in time series that are expressed in years since the plant sprouted",
        shoartLabel: "",
        inputs: [
          {
            element: "select",
            selectOptions: [
              {
                value: "MaxAge",
                label: "Maximum age of any plant in the crop",
              },
              {
                value: "AvgAge",
                label: "Average age of the plants in the crop",
              },
            ],
            programmingName: "tAgeIxSP",
          },
        ],
      },
      {
        id: "grthModeSP",
        label: "Allocations and increments are in terms of",
        shoartLabel: "",
        inputs: [
          {
            element: "select",
            selectOptions: [
              {
                value: "Yield",
                label: "Yields (Annual)",
              },
              {
                value: "NPP",
                label: "Net Primary Production (NPP)",
              },
              {
                value: "Perennial",
                label: "Yields (Perennial)",
              },
              {
                value: "Linear",
                label: "Yields (Linear)",
              },
            ],
            programmingName: "grthModeSP",
          },
        ],
      },
    ],
  },

  {
    isShown: true,
    showErrors: true,
    id: "allocations",
    label: "Allocations",
    items: [
      {
        label: "Grains, buds, fruit",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "allocGbfrA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "allocGbfrA",
              },
              {
                inputKey: "title",
                value: "Grains, buds, fruit",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "allocGbfrA",
              },
            ],
          },
        ],
      },
      {
        label: "Stalks",
        shoartLabel: "",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "allocStlkA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "allocStlkA",
              },
              {
                inputKey: "title",
                value: "Stalks",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "allocStlkA",
              },
            ],
          },
        ],
      },
      {
        label: "Leaves",
        shoartLabel: "",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "allocLeafA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "allocLeafA",
              },
              {
                inputKey: "title",
                value: "Leaves",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "allocLeafA",
              },
            ],
          },
        ],
      },

      {
        label: "Coarse roots",
        shoartLabel: "",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "allocCortA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "allocCortA",
              },
              {
                inputKey: "title",
                value: "Coarse Roots",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "allocCortA",
              },
            ],
          },
        ],
      },
      {
        label: "Fine roots",
        shoartLabel: "",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "allocFirtA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "allocFirtA",
              },
              {
                inputKey: "title",
                value: "Fine Roots",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "allocFirtA",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    isShown: true,
    id: "increments",
    label: "Increments",
    items: [
      {
        label: "",
        inputs: [
          {
            element: "input",
            type: "radioSwitch",
            selectOptions: [
              {
                value: "IncrGbfrMA",
                label: "Grains, buds, fruit masses",
              },
              {
                value: "IncrStlkMA",
                label: "Stalk masses",
              },
              {
                value: "IncrLeafMA",
                label: "Leaf masses",
              },
              {
                value: "IncrCortMA",
                label: "Coarse root masses",
              },
              {
                value: "IncrStLfMA",
                label: "Stalk-and-Leaf masses",
              },
              {
                value: "IncrAbgMA",
                label: "Aboveground masses",
              },
            ],
            programmingName: "tIncrTblA",
          },
        ],
      },
      {
        label: "",
        id: "incrGbfrMA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "incrGbfrMA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "incrGbfrMA",
              },
              {
                inputKey: "title",
                value: "Grains, buds, fruit masses",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "incrGbfrMA",
              },
            ],
          },
        ],
      },
      {
        label: "",
        id: "incrStlkMA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "incrStlkMA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "incrStlkMA",
              },
              {
                inputKey: "title",
                value: "Stalk masses",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "incrStlkMA",
              },
            ],
          },
        ],
      },
      {
        label: "",
        id: "incrLeafMA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "incrLeafMA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "incrLeafMA",
              },
              {
                inputKey: "title",
                value: "Leaf masses",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "incrLeafMA",
              },
            ],
          },
        ],
      },
      {
        label: "",
        id: "incrCortMA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "incrCortMA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "incrCortMA",
              },
              {
                inputKey: "title",
                value: "Coarse root masses",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "incrCortMA",
              },
            ],
          },
        ],
      },

      {
        label: "",
        id: "incrStLfMA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "incrStLfMA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "incrStLfMA",
              },
              {
                inputKey: "title",
                value: "Stalk-and-Leaf masses",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "incrStLfMA",
              },
            ],
          },
        ],
      },

      {
        label: "",
        id: "incrAbgMA",
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "incrAbgMA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "incrAbgMA",
              },
              {
                inputKey: "title",
                value: "Aboveground masses",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "incrAbgMA",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    isShown: true,
    id: "sigmoidal-group",
    label: "Sigmoidal growth factors",
    items: [
      {
        label: "Growth factor A",
        shoartLabel: "",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "sigmoidalGrowthA",
          },
        ],
      },
      {
        label: "Growth factor B",
        shoartLabel: "",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "sigmoidalGrowthB",
          },
        ],
      },
    ],
  },

  {
    isShown: true,
    id: "perennial-recovery-group",
    label: "Perennial recovery",
    items: [
      {
        label: "SDM threshold %",
        shoartLabel: "",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "recoveryFracA",
          },
        ],
      },
      {
        label: "Recovery days",
        shoartLabel: "",
        inputs: [
          { element: "input", type: "number", programmingName: "grthPeriod" },
        ],
      },
    ],
  },

  {
    isShown: true,
    id: "die-off-group",
    label: "Die off",
    items: [
      {
        label: "Die off",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "turnFracDieOA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "turnFracDieOA",
              },
              {
                inputKey: "title",
                value: "Mass of crop moved to debris due to Die Off",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "turnFracDieOA",
              },
            ],
          },
        ],
      },
    ],
  },
];

const plantsGroups: FormGroupElement[] = [
  {
    isShown: true,
    showErrors: true,
    label: "Carbon percentages",
    isUnderlineHeader: true,
    isUnderlineItems: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Carbon Percentages",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, fruit",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "CFracGbfrA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "CFracStlkA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "CFracLeafA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "CFracCortA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "CFracFirtA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    isShown: true,
    label: "Turnover percentages - half life in years",
    showErrors: true,
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Turnover percentages - half life in years",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "[No turnover of stems]"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, fruit",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "turnFracGbfrA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "turnFracGbfrA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Turnover percentages - Grains, buds, fruit",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "turnFracGbfrA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "turnFracStlkA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "turnFracStlkA",
                            },
                            {
                              inputKey: "title",
                              value: "Turnover percentages - Stalks",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "turnFracStlkA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "turnFracLeafA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "turnFracLeafA",
                            },
                            {
                              inputKey: "title",
                              value: "Turnover percentages - Leaves",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "turnFracLeafA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "turnFracCortA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "turnFracCortA",
                            },
                            {
                              inputKey: "title",
                              value: "Turnover percentages - Coarse roots",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "turnFracCortA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        width: "120px",
                        programmingName: "turnFracFirtA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "turnFracFirtA",
                            },
                            {
                              inputKey: "title",
                              value: "Turnover percentages - Fine roots",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "turnFracFirtA",
                            },
                          ],
                        },
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    isShown: true,
    label: "Mortality",
    showErrors: true,
    helpLink: "121_Mortality.htm",
    items: [
      {
        label: "Crop mortality on",
        shoartLabel: "",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "cropMortOn",
          },
        ],
      },
    ],
  },
  {
    isShown: true,
    showErrors: true,
    isUnderlineHeader: true,
    isUnderlineItems: true,
    label: "Stalk loss",
    id: "stalk-loss",
    items: [
      {
        label: "Stalk loss",
        shoartLabel: "",
        preventLabelElement: true,
        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "stlkMortA",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "stlkMortA",
              },
              {
                inputKey: "title",
                value: "Stem Loss",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "stlkMortA",
              },
            ],
          },
        ],
      },
      {
        label: "Ratios of component losses to stalk loss:",
        inputs: [],
      },

      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Ratios of component losses to stalk loss",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, fruit",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "ratioGbfrMortA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "ratioLeafMortA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "ratioCortMortA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "ratioFirtMortA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },

      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormulaDisplayComponent",
            componentInputs: [
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  lhs: "Average age of dying plants",
                  rhs: [
                    [
                      { element: "icon", type: "operator", value: "=" },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "remvAvgAgeMultMortA",
                      },

                      { element: "icon", type: "operator", value: "x" },
                      { element: "text", value: "Average age of plants" },
                    ],

                    [
                      { element: "icon", type: "operator", value: "+" },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "remvMaxAgeMultMortA",
                      },

                      { element: "icon", type: "operator", value: "x" },
                      { element: "text", value: "Age of the oldest plants" },
                    ],

                    [
                      { element: "icon", type: "operator", value: "+" },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "remvOffsetMortA",
                      },

                      { element: "text", value: "[yr]" },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },

      {
        label: "Replace dying plants with new plants (of age 0)",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "replaceStlksMortA",
          },
        ],
      },
    ],
  },
];

const debrisGroups: FormGroupElement[] = [
  {
    isShown: true,
    showErrors: true,
    label: "Resistant percentages",
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Resistant percentages",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, fruit",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rFracGbfrA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rFracStlkA",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rFracLeafA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rFracCortA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rFracFirtA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    isShown: true,
    label: "Breakdown percentages [½ life yrs]",
    isUnderlineHeader: true,
    isUnderlineItems: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Breakdown Percentages [½ life yrs]",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },

              {
                inputKey: "data",
                value: {
                  headers: ["", "Decomposable", "Resistant"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracDGlitA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracDGlitA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - GBF litter",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracDGlitA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracRGlitA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracRGlitA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - GBF litter",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracRGlitA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracDSlitA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracDSlitA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - Stalk litter",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracDSlitA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracRSlitA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracRSlitA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - Stalk litter",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracRSlitA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracDLlitA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracDLlitA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - Leaf litter",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracDLlitA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracRLlitA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracRLlitA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - Leaf litter",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracRLlitA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracDCodrA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracDCodrA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Breakdown Percentages - Coarse dead roots",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracDCodrA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "bkdnFracRCodrA",
                        width: "120px",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracRCodrA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Breakdown Percentages - Coarse dead roots",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracRCodrA",
                            },
                          ],
                        },
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        width: "120px",
                        programmingName: "bkdnFracDFidrA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracDFidrA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - Fine dead roots",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracDFidrA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        width: "120px",
                        programmingName: "bkdnFracRFidrA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "bkdnFracRFidrA",
                            },
                            {
                              inputKey: "title",
                              value: "Breakdown Percentages - Fine dead roots",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "bkdnFracRFidrA",
                            },
                          ],
                        },
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    isShown: true,
    label: "Sensitivity of breakdown to temperature and water",
    isUnderlineHeader: true,
    isUnderlineItems: true,
    helpLink:
      "122_Sensitivity%20of%20Debris%20Breakdown%20to%20Temperature%20and%20Water.htm",
    items: [
      {
        label: "Mulch-style sensitivity",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "bkdnSensMulcA",
          },
        ],
      },
      {
        label: "Sensitivity to temperature [1/(deg C)]",
        id: "bkdnSensTempA",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "bkdnSensTempA",
          },
        ],
      },
      {
        label: "Sensitivity to rainfall and irrigation  [1/mm]",
        id: "bkdnSensRainA",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "bkdnSensRainA",
          },
        ],
      },
      {
        id: "bkdnSensSoilA",
        label: "Soil-style sensitivity",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "bkdnSensSoilA",
          },
        ],
      },
    ],
  },
  {
    isShown: true,
    label: "Atmospheric percentages of breakdown products",
    isUnderlineHeader: true,
    isUnderlineItems: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Atmospheric Percentages of Breakdown Products",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },

              {
                inputKey: "data",
                value: {
                  headers: ["", "Decomposable", "Resistant"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracDGlitBkdnA",
                        width: "120px",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracRGlitBkdnA",
                        width: "120px",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracDSlitBkdnA",
                        width: "120px",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracRSlitBkdnA",
                        width: "120px",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracDLlitBkdnA",
                        width: "120px",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracRLlitBkdnA",
                        width: "120px",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracDCodrBkdnA",
                        width: "120px",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "atmsFracRCodrBkdnA",
                        width: "120px",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        width: "120px",
                        programmingName: "atmsFracDFidrBkdnA",
                      },

                      {
                        element: "input",
                        type: "number",
                        width: "120px",
                        programmingName: "atmsFracRFidrBkdnA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const productsGroups: FormGroupElement[] = [
  {
    isShown: true,
    showErrors: true,
    label: "Product processing percentages [%/yr]",
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Product processing percentages [%/yr]",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [
                    "",
                    "Percentage that decomposes each year while used as products",
                    "Percentage burnt for bio-energy each year",
                    "Total percentage leaving the in-use pools each year",
                  ],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracFualA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracFualA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Biofuel",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracFualA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracFualA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracFualA", "enerFracFualA"],
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, fruit",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracGbfpA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracGbfpA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Grains, buds, fruit",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracGbfpA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracGbfpA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracGbfpA", "enerFracGbfpA"],
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Canes",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracCaneA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracCaneA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Canes",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracCaneA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracCaneA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracCaneA", "enerFracCaneA"],
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracLfprA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracLfprA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Leaf products",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracLfprA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracLfprA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracLfprA", "enerFracLfprA"],
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Root products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracRtprA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracRtprA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Root products ",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracRtprA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracRtprA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracRtprA", "enerFracRtprA"],
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Hay, straw, silage",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracHssiA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracHssiA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Hay, straw, silage",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracHssiA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracHssiA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracHssiA", "enerFracHssiA"],
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Animal products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "pdcmFracAnprA",
                        linkToComponent: {
                          element: "navigation",
                          linkTo: "flyOverPanel",
                          component: "DecayRateComponent",
                          componentInputs: [
                            {
                              inputKey: "incomingFieldControl",
                              isObservable: true,
                              formKey: "pdcmFracAnprA",
                            },
                            {
                              inputKey: "title",
                              value:
                                "Product processing percentages [%/yr] - Animal products",
                            },
                            {
                              inputKey: "inputVariables",
                              method: "getDecayRateInputVariables",
                              payload: "pdcmFracAnprA",
                            },
                          ],
                        },
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "enerFracAnprA",
                      },

                      {
                        element: "text",
                        method: "getTotal",
                        payload: ["pdcmFracAnprA", "enerFracAnprA"],
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const initialCropGroup: FormGroupElement[] = [
  {
    isShown: true,
    id: "init-crop-masses",
    label: "Masses [dmt/ha]",
    isUnderlineHeader: true,
    showErrors: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Masses [dmt/ha]",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: [],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, and fruit (GBF)",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "gbfrMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "stlkMInitA",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "leafMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "cortMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "firtMInitA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },

  {
    isShown: true,
    label: "Ages [yr]",
    showErrors: true,
    isUnderlineHeader: true,
    items: [
      {
        label: "Ages of oldest plants",
        shoartLabel: "",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "maxCropAgeInit",
          },
        ],
      },
      {
        label: "Average age of plants",
        shoartLabel: "",
        inputs: [
          {
            element: "input",
            type: "number",
            programmingName: "avgCropAgeInit",
          },
        ],
      },
    ],
  },
];

const initialDebrisGroups: FormGroupElement[] = [
  {
    isShown: true,
    showErrors: true,
    label: "Carbon masses [tC/ha]",
    isUnderlineHeader: true,
    isUnderlineItems: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Carbon masses [tC/ha]",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Decomposable", "Resistant"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dGlitCMInitA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rGlitCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dSlitCMInitA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rSlitCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dLlitCMInitA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rLlitCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dCodrCMInitA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rCodrCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "dFidrCMInitA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rFidrCMInitA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const initialProductGroups: FormGroupElement[] = [
  {
    isShown: true,
    showErrors: true,
    label: "Carbon masses [tC/ha]",
    isUnderlineHeader: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Carbon masses [tC/ha]",
              },
              {
                inputKey: "formGroupPointer",
                value: "getSelectedSpeciesFormGroup",
              },
              {
                inputKey: "indexPointer",
                value: "selectedSpeciesId$",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "Used As Products"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Biofuel",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fualCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Grains, buds, fruit",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "gbfpCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Canes",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "caneCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "lfprCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Root products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "rtprCMInitA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Hay, straw, silage",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "hssiCMInitA",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Animal products",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "anprCMInitA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class Crops2020Service extends BaseSpeciesService {
  public readonly pageId = "crops";

  public readonly pageHelpLink = "201_Plants.htm";

  public readonly categoryIds = [
    14, 15, 29, 30, 31, 32, 33, 34, 35, 36, 39, 41, 43, 45, 47, 49, 53, 55, 62,
    63, 64, 65, 66, 67, 73, 78,
  ];

  public readonly layout: FormLayout = {
    label: "Crops",
    groups: [
      {
        label: "Species",
        showErrors: true,
        isShown: true,
        isRoot: true,
        helpLink: "56_Select%20a%20Species.htm",
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SpeciesSelectorComponent",
                componentInputs: [
                  {
                    inputKey: "selectedId$",
                    variable: "selectedSpeciesId$",
                    isObservable: true,
                  },
                  {
                    inputKey: "formGroupInstance",
                    method: "getFormGroup",
                  },
                  {
                    inputKey: "speciesInUse$",
                    variable: "speciesInUse$",
                    isObservable: true,
                  },
                  {
                    inputKey: "type",
                    value: "crop",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "buttonClicked",
                    method: "speciesButtonClicked",
                  },
                  {
                    outputKey: "speciesSelected",
                    method: "selectSpecies",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Properties of the Species",
        showErrors: true,
        isShown: true,
        isAccordion: true,
        helpLink: "145_Properties%20of%20the%20Species.htm",
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: "Name",
                element: "input",
                type: "text",
                programmingName: "nmSP",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Notes",
                element: "textarea",
                rows: 2,
                programmingName: "notesSP",
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Growth",
                helpLink: "42_Growth%20Properties.htm",
                items: [{ groups: growthGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Plants",
                helpLink: "43_Plant%20Properties.htm",
                items: [{ groups: plantsGroups }],
              },
            ],
          },

          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Debris",
                helpLink: "45_Debris%20Properties.htm",
                items: [{ groups: debrisGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                showErrors: true,
                isAccordion: true,
                label: "Products",
                helpLink: "47_Product%20Properties.htm",
                items: [{ groups: productsGroups }],
              },
            ],
          },
        ],
      },
      {
        label: "Standard information for the species",
        isShown: true,
        showErrors: true,
        isAccordion: true,
        isRoot: true,
        helpLink: "146_Standard%20Information%20for%20the%20Species.htm",
        items: [
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Initial Crop",
                helpLink: "184_Initial%20Crops.htm",
                items: [{ groups: initialCropGroup }],
              },
            ],
          },

          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Initial Debris",
                helpLink: "31_Initial%20Debris.htm",
                items: [{ groups: initialDebrisGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                showErrors: true,
                label: "Initial Products",
                helpLink: "33_Initial%20Products.htm",
                items: [{ groups: initialProductGroups }],
              },
            ],
          },
          {
            groups: [
              {
                isShown: true,
                isAccordion: true,
                label: "Events",
                showErrors: true,
                helpLink: "142_Standard%20Events%20of%20a%20Species.htm",
                items: [
                  {
                    label: "Events",
                    shoartLabel: "",
                    preventLabelElement: true,
                    inputs: [
                      {
                        element: "navigation",
                        linkTo: "flyOverPanel",
                        programmingName: "eventQ",
                        component: "SpeciesEventsComponent",
                        componentInputs: [
                          {
                            inputKey: "speciesFormGroup",
                            method: "getSelectedSpeciesFormGroup",
                          },
                          {
                            inputKey: "formGroup",
                            variable: "formGroup",
                          },
                          {
                            inputKey: "service",
                            method: "getService",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    showOnlyInUse: {
      defaultValue: false,
      isShown: true,
    },
    headerState: {
      label: "Event Q header state",
      defaultValue: {
        $: {
          sortIx: "0",
          sortUp: "true",
          sortBy1: "false",
          sortBy2: "false",
          showOnlyHS: "false",
        },
        headSectW: ["80,270,90,214,90,270"],
      },
      isShown: false,
    },
    showEvT: {
      label: "Event Q Show Event",
      defaultValue: "t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t",
      isShown: false,
      excludeFromXml: true,
    },
    species: {
      label: "Crops",
      defaultValue: "formArray",
      isShown: true,
    },
  };

  public speciesFormModel: FormModel = {
    eventQ: {
      label: "Species Events",
      defaultValue: "formArray",
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "eventQ",
        },
      ],
      isShown: true,
      suffixId: null,
    },
    // dbId: {
    //   defaultValue: null,
    //   isShown: false,
    //   excludeFromXml: true,
    //   suffixId: null,
    // },
    curEdit: {
      defaultValue: false,
      isShown: true,
      high: 1,
      low: 0,
      dataType: 2,
      suffixId: null,
    },
    idSP: {
      defaultValue: null,
      isShown: true,
      suffixId: null,
    },
    nSpecsA: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 1,
      helpId: 148,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Number of crop species",
      unit: "",
      categoryName: "SpecAg",
      categoryLabel: "Agricultural species set",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1130,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    categoryA: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 148,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Crop Category",
      unit: "",
      categoryName: "SpecAg",
      categoryLabel: "Agricultural species set",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "SpecAgCatT",
      eventId: 10,
      tIn: 1286,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 56,
      defaultValue: "Crop",
      validators: [],
      validatorConfig: [],
      label: "Type of plants (trees or crop)",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "SpecT",
      eventId: 10,
      tIn: 599,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tAgeIxSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 42,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Type of plant age to use in growth tables (average or maximum)",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgeIxT",
      eventId: 10,
      tIn: 601,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 145,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "maxLength",
          input: 999,
        },
      ],
      label: "Species name",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 798,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 145,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "maxLength",
          input: 999,
        },
      ],
      label: "Species notes",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 799,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    inUseSP: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 148,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Species in use",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1269,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    statusSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 148,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Species status",
      unit: "",
      categoryName: "SpecFA",
      categoryLabel: "Species",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "StatusT",
      eventId: 10,
      tIn: 1284,
      spec: 3,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    incrGbfrMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Grains,buds,and fruit",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 30,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 43,
    },
    incrStlkMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Stalk",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 31,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 43,
    },
    incrLeafMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Leaf",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 32,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 43,
    },
    incrCortMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Coarse root",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 33,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 43,
    },
    incrStLfMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Stalk and leaf",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 34,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 43,
    },
    incrAbgMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            count: "10",
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "Aboveground",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 35,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 43,
    },
    allocGbfrA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 124,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 36,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    allocStlkA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 124,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "stalks",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 37,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    allocLeafA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 124,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "leaves",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 38,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    allocCortA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 124,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "coarse roots",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 39,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    allocFirtA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 124,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "10",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "fine roots",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 9,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 40,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    grthModeSP: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 42,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Types of growth tables (yields or NPP)",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "GrthModeAT",
      eventId: 10,
      tIn: 600,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: null,
    },
    tIncrTblA: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 42,
      defaultValue: "IncrGbfrMA",
      validators: [],
      validatorConfig: [],
      label: "Type of increments to use for crop growth",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "IncrTblAT",
      eventId: 10,
      tIn: 603,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sigmoidalGrowthA: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 42,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
      ],
      label: "Sigmoidal Growth Factor A",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2137,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sigmoidalGrowthB: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 42,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
      ],
      label: "Sigmoidal Growth Factor B",
      unit: "",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2138,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    turnFracDieOA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "1",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: "0.0",
            $: {
              count: "1",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
          input: true,
        },
      ],
      label: "crop",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2143,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 177,
    },
    perennialDieOffTS: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 118,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Die Off biomass",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2146,
      spec: 2,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 177,
    },
    perennialSDMTS: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 113,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Perennial SDM",
      unit: "dmt/ha",
      categoryName: "CropGr",
      categoryLabel: "Crop species : Growth",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2147,
      spec: 2,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracGbfrA: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit",
      unit: "%",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 94,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracStlkA: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks",
      unit: "%",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 95,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracLeafA: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves",
      unit: "%",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 96,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracCortA: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots",
      unit: "%",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 97,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracFirtA: {
      high: 1,
      low: 0.001,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.1,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots",
      unit: "%",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 98,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    turnFracGbfrA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "grains-buds-fruit",
      unit: "half life yrs",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 99,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    turnFracStlkA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "stalks",
      unit: "half life yrs",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 100,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    turnFracLeafA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "leaves",
      unit: "half life yrs",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 101,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    turnFracCortA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "coarse roots",
      unit: "half life yrs",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 102,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    turnFracFirtA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 43,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "fine roots",
      unit: "half life yrs",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 11,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 103,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    ratioGbfrMortA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of grains-buds-fruit",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 982,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 44,
    },
    ratioLeafMortA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of leaf",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 983,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 44,
    },
    ratioCortMortA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of coarse root",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 984,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 44,
    },
    ratioFirtMortA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of fine root",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 985,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 44,
    },
    stlkMortA: {
      high: 0.999999999,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 19,
      defaultValue: {
        $: {
          yr0TS: "0",
          nYrsTS: "1",
          dataPerYrTS: "1",
        },
        rawTS: [
          {
            _: "0.0",
            $: {
              count: "1",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Annual % of stalks lost due to mortality",
      unit: "%/yr",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 987,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cropMortOn: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Crop mortality (on/off)",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 989,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    replaceStlksMortA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Replace dying plants with new plants (of age 0) (yes/no)",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1005,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    remvAvgAgeMultMortA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Multiplier of average age in formula for age of dying plants",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1006,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    remvMaxAgeMultMortA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Multiplier of age of oldest plants in formula for age of dying plants",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1007,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    remvOffsetMortA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 121,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Added constant in formula for age of dying plants",
      unit: "",
      categoryName: "CropPl",
      categoryLabel: "Crop species : Plants",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1008,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rFracGbfrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 104,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rFracStlkA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 105,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rFracLeafA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 106,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rFracCortA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 107,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rFracFirtA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 14,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 108,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    mrFracOfRLittA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "resistant litter that is 'more-resistant' (not 'less-resistant')",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 109,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracDGlitA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "decomposable grains-buds-fruit",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 279,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracRGlitA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "resistant grains-buds-fruit",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 280,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracDSlitA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "decomposable stalk litter",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 281,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracRSlitA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "resistant stalk litter",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 282,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracDLlitA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "decomposable leaf litter",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 283,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracRLlitA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "resistant leaf litter",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 284,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracDCodrA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 285,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracRCodrA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 286,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracDFidrA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 287,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnFracRFidrA: {
      high: 800000,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 800000,
        },
      ],
      label: "resistant fine dead roots",
      unit: "half life yrs",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 15,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 288,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracDGlitBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable grains-buds-fruit",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 289,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracRGlitBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant grains-buds-fruit",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 290,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracDSlitBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable stalk litter breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 291,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracRSlitBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant stalk litter breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 292,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracDLlitBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable leaf litter breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 293,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracRLlitBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant leaf litter breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 294,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracDCodrBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable coarse dead root breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 295,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracRCodrBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant coarse dead root breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 296,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracDFidrBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable fine dead root breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 297,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    atmsFracRFidrBkdnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 45,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant fine dead root breakdown",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 16,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 298,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnSensMulcA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 122,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mulch-style (yes/no)",
      unit: "",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 17,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1019,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnSensTempA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 122,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "temperature",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 18,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1020,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnSensRainA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 122,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "rainfall",
      unit: "%",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 18,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1021,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bkdnSensSoilA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 122,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soil-style (yes/no)",
      unit: "",
      categoryName: "CropDe",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 17,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1118,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    pdcmFracGbfpA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 299,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 20,
    },
    pdcmFracCaneA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 300,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 20,
    },
    pdcmFracLfprA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 301,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 20,
    },
    pdcmFracRtprA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 302,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 20,
    },
    pdcmFracHssiA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 303,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 20,
    },
    pdcmFracAnprA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 304,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 20,
    },
    pdcmFracFualA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "agricultural biofuel",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 417,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    enerFracFualA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "agricultural biofuel",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 419,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    enerFracGbfpA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 421,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    enerFracCaneA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "canes",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 423,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    enerFracLfprA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaf products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 425,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    enerFracRtprA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "root products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 432,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    enerFracHssiA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "hay-straw-silage",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 437,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    enerFracAnprA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 47,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "animal products",
      unit: "%/yr",
      categoryName: "CropPr",
      categoryLabel: "Crop species : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 788,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 21,
    },
    maxSFixNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum ratio of symbiotic N fixation (kgN) to C production (tC)",
      unit: "/1000",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 315,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    storFracLeafTurnNA: {
      high: 0.99,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Stored % of leaf nitrogen during turnover",
      unit: "%",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 316,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    userRDebrNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "resistant debris pools",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 317,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    absnNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of absorbed N to the C in the decomposable fine breakdown",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 318,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    maxBkdnNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "the decomposable fine breakdown",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 319,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrMaxNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1318,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    gbfrMaxNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1319,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    gbfrMaxNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1320,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    gbfrMaxNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1321,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkMaxNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1322,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    stlkMaxNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1323,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    stlkMaxNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1324,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    stlkMaxNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1325,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafMaxNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1326,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    leafMaxNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1327,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    leafMaxNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1328,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    leafMaxNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1329,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortMaxNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1330,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    cortMaxNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1331,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    cortMaxNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1332,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    cortMaxNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1333,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtMaxNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1334,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    firtMaxNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1335,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    firtMaxNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1336,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    firtMaxNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1337,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrMinNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1338,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    gbfrMinNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1339,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    gbfrMinNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1340,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    gbfrMinNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 107,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1341,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkMinNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1342,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    stlkMinNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1343,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    stlkMinNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1344,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    stlkMinNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 108,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1345,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafMinNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1346,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    leafMinNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1347,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    leafMinNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1348,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    leafMinNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 109,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1349,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortMinNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1350,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    cortMinNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1351,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    cortMinNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1352,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    cortMinNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 110,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1353,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtMinNC1A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1354,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 26,
    },
    firtMinNC2A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1355,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 28,
    },
    firtMinNC3A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1356,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 29,
    },
    firtMinNC4A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots during grainfill",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 111,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1357,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrDays2A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 45,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1358,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrDays3A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 46,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1359,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrDays4A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 47,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1360,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkDays2A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 45,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1361,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkDays3A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 46,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1362,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkDays4A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 47,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1363,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafDays2A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 45,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1364,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafDays3A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 46,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1365,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafDays4A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 47,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1366,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortDays2A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 45,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1367,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortDays3A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 46,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1368,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortDays4A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 47,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1369,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtDays2A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 45,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1370,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtDays3A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 46,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1371,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtDays4A: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 74,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "CropNi",
      categoryLabel: "Crop species : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 47,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1372,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGbfrToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1654,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracGbfrToGbfpHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1655,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracGbfrToHssiHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1656,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracGbfrToGlitHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1657,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracStlkToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1658,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracStlkToCaneHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to canes",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1659,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracStlkToHssiHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1660,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracStlkToSlitHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1661,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracLeafToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1662,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracLeafToLfprHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1663,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracLeafToHssiHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1664,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracLeafToLlitHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1665,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracCortToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1666,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracCortToRtprHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1667,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracCortToHssiHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1668,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracCortToCodrHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1669,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracFirtToFidrHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1670,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracGlitToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1671,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracSlitToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalk litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1672,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracLlitToFualHarv1: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1673,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 128,
    },
    fracGbfrToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1674,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracGbfrToGbfpHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1675,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracGbfrToHssiHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1676,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracGbfrToGlitHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1677,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracStlkToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1678,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracStlkToCaneHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to canes",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1679,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracStlkToHssiHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1680,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracStlkToSlitHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1681,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracLeafToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1682,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracLeafToLfprHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1683,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracLeafToHssiHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1684,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracLeafToLlitHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1685,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracCortToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1686,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracCortToRtprHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1687,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracCortToHssiHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1688,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracCortToCodrHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1689,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracFirtToFidrHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1690,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracGlitToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1691,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracSlitToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalk litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1692,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracLlitToFualHarv2: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1693,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 129,
    },
    fracGbfrToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1694,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracGbfrToGbfpHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1695,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracGbfrToHssiHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1696,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracGbfrToGlitHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1697,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracStlkToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1698,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracStlkToCaneHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to canes",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1699,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracStlkToHssiHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1700,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracStlkToSlitHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1701,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracLeafToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1702,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracLeafToLfprHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1703,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracLeafToHssiHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1704,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracLeafToLlitHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1705,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracCortToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1706,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracCortToRtprHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1707,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracCortToHssiHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1708,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracCortToCodrHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1709,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracFirtToFidrHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1710,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracGlitToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1711,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracSlitToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalk litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1712,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracLlitToFualHarv3: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1713,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 130,
    },
    fracGbfrToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1714,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracGbfrToGbfpHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1715,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracGbfrToHssiHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1716,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracGbfrToGlitHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1717,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracStlkToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1718,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracStlkToCaneHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to canes",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1719,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracStlkToHssiHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1720,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracStlkToSlitHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1721,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracLeafToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1722,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracLeafToLfprHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1723,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracLeafToHssiHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1724,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracLeafToLlitHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves to litter",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1725,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracCortToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1726,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracCortToRtprHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to products",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1727,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracCortToHssiHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to hay-straw-silage",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1728,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracCortToCodrHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1729,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracFirtToFidrHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1730,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracGlitToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1731,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracSlitToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalk litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1732,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    fracLlitToFualHarv4: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf litter to biofuel",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1733,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 131,
    },
    nmGradeA1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of crop grade 1",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1740,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    nmGradeA2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of crop grade 2",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1741,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    nmGradeA3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of crop grade 3",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1742,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    nmGradeA4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of crop grade 4",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 200,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1743,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tPRProd1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1910,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    densityPRProd1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1911,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    fracWaterPRProd1: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1912,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    harvCostPRProd1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1913,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    trnsCostPRProd1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1914,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    millPricePRProd1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1915,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    stYearPRProd1: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1916,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    incrPaHarvPRProd1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1917,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    incrPaTrnsPRProd1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1918,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    incrPaMillPRProd1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1919,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 157,
    },
    tPRHssi1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1920,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    densityPRHssi1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1921,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    fracWaterPRHssi1: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1922,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    harvCostPRHssi1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1923,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    trnsCostPRHssi1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1924,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    millPricePRHssi1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1925,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    stYearPRHssi1: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1926,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    incrPaHarvPRHssi1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1927,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    incrPaTrnsPRHssi1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1928,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    incrPaMillPRHssi1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1929,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 161,
    },
    tPRFual1: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1930,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    densityPRFual1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1931,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    fracWaterPRFual1: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1932,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    harvCostPRFual1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1933,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    trnsCostPRFual1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1934,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    millPricePRFual1: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1935,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    stYearPRFual1: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1936,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    incrPaHarvPRFual1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1937,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    incrPaTrnsPRFual1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1938,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    incrPaMillPRFual1: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1939,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 165,
    },
    tPRProd2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1940,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    densityPRProd2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1941,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    fracWaterPRProd2: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1942,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    harvCostPRProd2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1943,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    trnsCostPRProd2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1944,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    millPricePRProd2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1945,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    stYearPRProd2: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1946,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    incrPaHarvPRProd2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1947,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    incrPaTrnsPRProd2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1948,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    incrPaMillPRProd2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1949,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 158,
    },
    tPRHssi2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1950,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    densityPRHssi2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1951,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    fracWaterPRHssi2: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1952,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    harvCostPRHssi2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1953,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    trnsCostPRHssi2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1954,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    millPricePRHssi2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1955,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    stYearPRHssi2: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1956,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    incrPaHarvPRHssi2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1957,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    incrPaTrnsPRHssi2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1958,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    incrPaMillPRHssi2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1959,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 162,
    },
    tPRFual2: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1960,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    densityPRFual2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1961,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    fracWaterPRFual2: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1962,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    harvCostPRFual2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1963,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    trnsCostPRFual2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1964,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    millPricePRFual2: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1965,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    stYearPRFual2: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1966,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    incrPaHarvPRFual2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1967,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    incrPaTrnsPRFual2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1968,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    incrPaMillPRFual2: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1969,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 166,
    },
    tPRProd3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1970,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    densityPRProd3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1971,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    fracWaterPRProd3: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1972,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    harvCostPRProd3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1973,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    trnsCostPRProd3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1974,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    millPricePRProd3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1975,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    stYearPRProd3: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1976,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    incrPaHarvPRProd3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1977,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    incrPaTrnsPRProd3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1978,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    incrPaMillPRProd3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1979,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 159,
    },
    tPRHssi3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1980,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    densityPRHssi3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1981,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    fracWaterPRHssi3: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1982,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    harvCostPRHssi3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1983,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    trnsCostPRHssi3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1984,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    millPricePRHssi3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1985,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    stYearPRHssi3: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1986,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    incrPaHarvPRHssi3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1987,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    incrPaTrnsPRHssi3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1988,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    incrPaMillPRHssi3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1989,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 163,
    },
    tPRFual3: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 1990,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    densityPRFual3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1991,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    fracWaterPRFual3: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1992,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    harvCostPRFual3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1993,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    trnsCostPRFual3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1994,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    millPricePRFual3: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1995,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    stYearPRFual3: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1996,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    incrPaHarvPRFual3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1997,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    incrPaTrnsPRFual3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1998,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    incrPaMillPRFual3: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1999,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 167,
    },
    tPRProd4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 2000,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    densityPRProd4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2001,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    fracWaterPRProd4: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2002,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    harvCostPRProd4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2003,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    trnsCostPRProd4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2004,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    millPricePRProd4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2005,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    stYearPRProd4: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2006,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    incrPaHarvPRProd4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2007,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    incrPaTrnsPRProd4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2008,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    incrPaMillPRProd4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2009,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 160,
    },
    tPRHssi4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 2010,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    densityPRHssi4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2011,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    fracWaterPRHssi4: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2012,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    harvCostPRHssi4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2013,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    trnsCostPRHssi4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2014,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    millPricePRHssi4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2015,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    stYearPRHssi4: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2016,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    incrPaHarvPRHssi4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2017,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    incrPaTrnsPRHssi4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2018,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    incrPaMillPRHssi4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2019,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 164,
    },
    tPRFual4: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 147,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PriceT",
      eventId: 10,
      tIn: 2020,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    densityPRFual4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "dmt/m3",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 150,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2021,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    fracWaterPRFual4: {
      high: 0.95,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "%",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 151,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2022,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    harvCostPRFual4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 152,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2024,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    trnsCostPRFual4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 153,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2024,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    millPricePRFual4: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "$/dmt-e",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 154,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2025,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    stYearPRFual4: {
      high: 2200,
      low: 1800,
      isOneMore: false,
      dataType: 1,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "",
      unit: "",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 155,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2026,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    incrPaHarvPRFual4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "harvest costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2027,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    incrPaTrnsPRFual4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "transport costs",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2028,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    incrPaMillPRFual4: {
      high: 1,
      low: -1,
      isOneMore: false,
      dataType: 0,
      helpId: 263,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill-gate prices",
      unit: "% pa",
      categoryName: "CropCg",
      categoryLabel: "Crop species : Harvest",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 156,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2029,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 168,
    },
    effcEnerFualA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural biofuel",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 891,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcEnerGbfpA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 892,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcEnerCaneA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 893,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcEnerLfprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 894,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcEnerRtprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 895,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcEnerHssiA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 896,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcEnerAnprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 31,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 897,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossFualA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural biofuel",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 898,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossGbfpA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 899,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossCaneA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 900,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossLfprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 901,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossRtprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 902,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossHssiA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 903,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    effcFossAnprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "%",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 32,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 904,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerFualA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural biofuel",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 905,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerGbfpA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 906,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerCaneA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 907,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerLfprA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 908,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerRtprA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 909,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerHssiA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 910,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrEnerAnprA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 911,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossFualA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural biofuel",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 912,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossGbfpA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 913,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossCaneA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 914,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossLfprA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 915,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossRtprA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 916,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossHssiA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 917,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrFossAnprA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "t/MJ",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 34,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 918,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cultivationCA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "spent cultivating crop species on plot",
      unit: "tC/ha/yr",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 964,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    harvCA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "used in harvesting / C in harvested material",
      unit: "",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 965,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    biofuelBurnCA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "used in burning agricultural biofuel / C in agricultural biofuel",
      unit: "",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 966,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    wasteBurnCA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 16,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "used in burning non-biofuel agricultural products / C in those products",
      unit: "",
      categoryName: "CropDisE",
      categoryLabel: "Crop species : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 37,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 967,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracProdAnprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "%",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 884,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeProdGbfpA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 919,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeProdCaneA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 920,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeProdLfprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 921,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeProdRtprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 922,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeProdHssiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 923,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeProdAnprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 38,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 924,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrProdGbfpA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 925,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrProdCaneA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fossil fuel used to canes",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 33,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 926,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrProdLfprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 927,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrProdRtprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 928,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrProdHssiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 929,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrProdAnprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 35,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 930,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeDispGbfpA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 931,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeDispCaneA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 932,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeDispLfprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 933,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeDispRtprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 934,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeDispHssiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 935,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    makeDispAnprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "MJ/t",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 39,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 936,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispGbfpA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 937,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispCaneA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 938,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispLfprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 939,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispRtprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 940,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispHssiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 941,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispAnprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "t/MJ",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 36,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 942,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    MDispPerProdGbfpA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 943,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    MDispPerProdCaneA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 944,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    MDispPerProdLfprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 945,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    MDispPerProdRtprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 946,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    MDispPerProdHssiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 947,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    MDispPerProdAnprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 40,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 948,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    LProdPerDispGbfpA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 949,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 41,
    },
    LProdPerDispCaneA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 950,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 41,
    },
    LProdPerDispLfprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 951,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 41,
    },
    LProdPerDispRtprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 952,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 41,
    },
    LProdPerDispHssiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 953,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 41,
    },
    LProdPerDispAnprA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 42,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 954,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 41,
    },
    CFracProdGbfpA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "%",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 955,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracProdCaneA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "%",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 956,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracProdLfprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "%",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 957,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracProdRtprA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "%",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 958,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    CFracProdHssiA: {
      high: 1,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 18,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "%",
      categoryName: "CropDisP",
      categoryLabel: "Crop species : Displacement due to product use",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 10,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 959,
      spec: 2,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracMdcmToMicrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "eaten carbon that is assimilated",
      unit: "%",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 321,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracMicrCMIsWallA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "microbial carbon in cell walls",
      unit: "%",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 323,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracWallCMIsLrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "cell wall carbon in less-resistant material",
      unit: "%",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 325,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    turnFracMicrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live mulch microbes that die each year",
      unit: "%",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 327,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracMdcmMicrExcrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "assimilated carbon that is excreted",
      unit: "%",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 329,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    mdcmFracSopmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 352,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    mdcmFracLrpmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 353,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    mdcmFracMrpmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 354,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    mdcmFracSommA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 355,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mdcmFracLrmmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 356,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mdcmFracMrmmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 19,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 357,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mdcmSensTempA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "temperature",
      unit: "1/(deg C)",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 54,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 359,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    mdcmSensRainA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "rainfall",
      unit: "1/mm",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 54,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 361,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    humfFracSopmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 369,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    humfFracLrpmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 370,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    humfFracMrpmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 371,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    humfFracSommA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 372,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    humfFracLrmmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 373,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    humfFracMrmmA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 374,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    humfFracMicrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 29,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "%/yr",
      categoryName: "MulcAP",
      categoryLabel: "Mulch : Agricultural : Properties",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 49,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 375,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    micrNCRatioA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 332,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sommNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 333,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    lrmmNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 336,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mrmmNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 337,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mrpmNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 342,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    lrpmNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 343,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    maxMicrNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 344,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minMicrNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 76,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "MulcAN",
      categoryLabel: "Mulch : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 345,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumfSopmToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 404,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 62,
    },
    fracHumfLrpmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 406,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfMrpmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 408,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfSommToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 410,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 62,
    },
    fracHumfLrmmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 412,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfMrmmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 414,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfMicrToXpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes to DPM and RPM",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 416,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracDLitBkdnToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable litter",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 63,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 422,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 62,
    },
    fracRLitBkdnToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "resistant litter",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 89,
      errorId: 0,
      isInverse: false,
      prefixId: 63,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 424,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    dToRRatioInPresA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of DPM to RPM in plant residues",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 72,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 427,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 433,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 434,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToBiofA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 435,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToBiosA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 436,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuDpmaNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 443,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuRpmaNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 444,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuBiofNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 15,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 445,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuBiosNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 16,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 446,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuHumsNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 17,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 447,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    doManuFromOffsA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural soil (yes/no)",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 66,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 801,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    pHF: {
      high: 14,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pH of forest soil",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 134,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    pHA: {
      high: 14,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pH of agricultural soil",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 138,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    encpFracHumsA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Agricultural HUM encapsulation %",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 87,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 450,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sampleDepthA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Depth of agricultural soil sampled",
      unit: "cm",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 452,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sdcmRateMultDpmaA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 456,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultRpmaA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 458,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultBiofV263A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 460,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultBiosV263A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 462,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultHumsA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 464,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultBiomCovA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "BIO-F and BIO-S decomposition rate modifier for covered agricultural soil",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 466,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracPbioToBiofV263A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 98,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 472,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracPbioToBiofV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 104,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 474,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracPbioToBiosV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 104,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 475,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumsToBiosV263A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 482,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumsToBiofV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 484,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumsToBiosV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 485,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    evapoOpenRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of evapotranspiration to open-pan evaporation",
      unit: "",
      categoryName: "SoilAW",
      categoryLabel: "Soil : Agricultural : Water",
      isShown: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 73,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    bToCMaxTSMDRatioA: {
      high: 10,
      low: 0.0001,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ratio of bare-to-covered maximum TSMD",
      unit: "",
      categoryName: "SoilAW",
      categoryLabel: "Soil : Agricultural : Water",
      isShown: true,
      columnWidth: 79,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 454,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxBiofNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 381,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxBiosNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 382,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxHumsNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 383,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minBiofNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 387,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minBiosNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 388,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minHumsNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 389,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    richNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "C:N ratio above which the agricultural mineral pool is 'depleted'",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 503,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    poorNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "C:N ratio below which the agricultural mineral pool is 'healthy'",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 504,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracDpmaToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1124,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    fracRpmaToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1125,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    fracBiofToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1126,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    fracBiosToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1127,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    mnrlNMFromOffsA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 103,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen from offsite",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 16,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    depoSlopeA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "slope",
      unit: "kgN/ha per mm of rain",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 73,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 489,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    depoOffsetA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "offset",
      unit: "kgN/ha per year",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 73,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 490,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    nfixSlopeA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "slope",
      unit: "kgN/ha per mm of rain",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 74,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 493,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    nfixOffsetA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "offset",
      unit: "kgN/ha per year",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 74,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 494,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracMnrlNToDeepLeacA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mineral N lost to the depths due to leaching",
      unit: "%/yr",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 500,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    proRataNRationingA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 87,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ration N between processes on a pro-rata basis (yes/no)",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 973,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    useTSDeniA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Specify agricultural denitrification by time series (yes/no)",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1108,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tLeachingNA: {
      high: 2,
      low: 0,
      isOneMore: false,
      dataType: 1,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Ag. leaching specified by N cycling,fraction,or time series",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1110,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    mnrlNMToAtmsDeniA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 117,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Denitrification",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1112,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    mnrlNMToAtmsLeacA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 119,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Leaching",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1114,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    nitrK1A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Percentage of net mineralized N that is",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1428,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 76,
    },
    nitrK2A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Percentage of nitrified N that is lost as N2O",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1429,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    nitrKMaxA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum percentage of shallow ammonium that can be",
      unit: "per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1430,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 76,
    },
    nitrTemp_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1431,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 78,
    },
    nitrTemp_bA: {
      high: 50,
      low: -20,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "deg C",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1432,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 78,
    },
    nitrTemp_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1433,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 78,
    },
    nitrPH_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1434,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 80,
    },
    nitrPH_bA: {
      high: 14,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1435,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 80,
    },
    nitrPH_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1436,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 80,
    },
    nitrWater_aLA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1437,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 84,
    },
    nitrWater_aHA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1438,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 86,
    },
    nitrWater_aXA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Cutoff clay percentage for 'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1439,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 82,
    },
    nitrWater_bLA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1440,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 84,
    },
    nitrWater_bHA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1441,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 86,
    },
    nitrWater_bXA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Cutoff clay percentage for 'b'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1442,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 82,
    },
    nitrWater_cLA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1443,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 84,
    },
    nitrWater_cHA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1444,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 86,
    },
    nitrWater_cXA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Cutoff clay percentage for 'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1445,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 82,
    },
    deniMaxA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum denitrification rate",
      unit: "kgN/ha per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1446,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    deniNRMaxA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum N2:N2O ratio during denitrification",
      unit: "kgN/ha per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1447,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    deniCO2_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1448,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 88,
    },
    deniCO2_bA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "kgC/ha per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1449,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 88,
    },
    deniCO2_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "kgC/ha per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1450,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 88,
    },
    deniNO3_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1451,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 90,
    },
    deniNO3_bA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1452,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 90,
    },
    deniNO3_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1453,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 90,
    },
    deniWater_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1454,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 92,
    },
    deniWater_bA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1455,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 92,
    },
    deniWater_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1456,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 92,
    },
    deniRCO2_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1457,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 94,
    },
    deniRCO2_bA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "kgC/ha per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1458,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 94,
    },
    deniRCO2_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "kgC/ha per day",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1459,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 94,
    },
    deniRNO3_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1460,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 96,
    },
    deniRNO3_bA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1461,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 96,
    },
    deniRNO3_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "kgN/ha",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1462,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 96,
    },
    deniRWater_aA: {
      high: 9.9,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'a'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1463,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 98,
    },
    deniRWater_bA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'b'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1464,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 98,
    },
    deniRWater_cA: {
      high: 1e36,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "'c'",
      unit: "",
      categoryName: "MnrlAN",
      categoryLabel: "Minerals: Agricultural nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1465,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 98,
    },
    bondOnA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Agricultural nitrogen boundary-line emissions (on/off)",
      unit: "",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 1058,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    bondEmitLoA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Low level of",
      unit: "kgN/ha",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1059,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 100,
    },
    bondEmitMedA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Medium level of",
      unit: "kgN/ha",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1060,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 100,
    },
    bondEmitHiA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "High level of",
      unit: "kgN/ha",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1061,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 100,
    },
    bondMnrlNMA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Mineral nitrogen threshold for",
      unit: "kgN/ha",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1062,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 100,
    },
    bondSoilTempA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil temperature threshold for",
      unit: "deg C",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1063,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 100,
    },
    bondSpreadA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 67,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Agricultural boundary-line spread (for time steps longer than 1 day)",
      unit: "",
      categoryName: "MnrlAB",
      categoryLabel: "Minerals: Agricultural : Boundary line emissions",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1064,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Leaf mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 41,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    cortMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Coarse root mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 42,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    firtMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Fine root mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 43,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxCropAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Age of oldest plants",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 137,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    avgCropAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Average age of plants",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 179,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    gbfrMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Grains,buds,and fruit mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 180,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stalk mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 181,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 7,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 543,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 8,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 544,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 9,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 545,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    cortNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 10,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 546,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    firtNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 11,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 547,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    storNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in plant store",
      unit: "kgN/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 548,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    cropExistsInit: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Plants initially growing in the agricultural system (yes/no)",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1080,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cropNmInit: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of initial crop species",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial : Agricultural : Crops",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1132,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dGlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable grains-buds-fruit litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 44,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rGlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant grains-buds-fruit litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 45,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dSlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable stalk litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 46,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rLlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant leaf litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 49,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dCodrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 50,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rCodrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 51,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dFidrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 52,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rFidrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant fine dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 53,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rSlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant stalk litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 182,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dLlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable leaf litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 183,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dGlitNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable grains-buds-fruit litter",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 549,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dSlitNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable stalk litter",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 550,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dLlitNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable leaf litter",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 551,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dCodrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable coarse dead roots",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 552,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dFidrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable fine dead roots",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial : Agricultural : Debris",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 553,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sopmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 54,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    lrpmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 55,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    mrpmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 56,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    sommCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 57,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    lrmmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 58,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mrmmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 59,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    micrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 60,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sopmNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 554,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    sommNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 555,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    micrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "InitAM",
      categoryLabel: "Initial : Agricultural : Mulch",
      isShown: true,
      columnWidth: 70,
      errorId: 19,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 556,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dpmaCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 62,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rpmaCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 63,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biofCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 64,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biosCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 65,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    humsCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 66,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    TSMDInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Agricultural topsoil moisture deficit (TSMD)",
      unit: "mm",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 67,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    inrtCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "inert soil",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 68,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dpmaNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 557,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rpmaNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 558,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biofNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 15,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 559,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biosNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 16,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 560,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    humsNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 17,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 561,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    inrtNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "inert soil",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial : Agricultural : Soil",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 562,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    mulcNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural mineralized nitrogen",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial : Agricultural : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 61,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sAmmNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow ammonium (with agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial : Agricultural : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1379,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    sNtrNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow nitrate (with agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial : Agricultural : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1380,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    dAmmNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep ammonium (below agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial : Agricultural : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1381,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    dNtrNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep nitrate (below agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial : Agricultural : Minerals",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1382,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    gbfpCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "grains-buds-fruit products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 563,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    caneCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "canes",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 564,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    lfprCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "leaf products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 565,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rtprCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "root products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 566,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    hssiCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "hay-straw-silage",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 567,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    anprCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "animal products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 568,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfpNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 569,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    caneNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 570,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    lfprNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 571,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rtprNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 572,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    hssiNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 573,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    anprNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 574,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fualCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "biofuel stocks",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 809,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fualNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "biofuel stocks",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial : Agricultural : Products",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 810,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    agePlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Age of crops when planted",
      unit: "yr",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 629,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 630,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 631,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 632,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 633,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "dmt/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 634,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 7,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 635,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 8,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 636,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 9,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 637,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cortNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 10,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 638,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    firtNCRatioPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 11,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 639,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    storNMPlnA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in plant store",
      unit: "kgN/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 640,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cropNmPlnA: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Name of crop species",
      unit: "",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 1134,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fixPlnA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "crop planting event",
      unit: "$",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 2052,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaPlnA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "crop planting event",
      unit: "$/ha",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 2053,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    grthPeriod: {
      high: 999,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 161,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 999,
        },
      ],
      label: "Age of crop maturity",
      unit: "yr",
      categoryName: "EvPlnA",
      categoryLabel: "Crop planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 7,
      tIn: 2144,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracAfctPlow: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 164,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural system that is plowed",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 1191,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fixPlow: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 164,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "plowing event",
      unit: "$",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2062,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaPlow: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 164,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "plowing event",
      unit: "$/ha",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2063,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracDpmaToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2148,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracRpmaToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2149,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracBiofToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2150,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracBiosToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2151,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracHumToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2152,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    recoveryFracA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Perennial Recovery Fraction",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2153,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cemrDispFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRProd1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRProd2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRProd3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRProd4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRHssi1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRHssi2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRHssi3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRHssi4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFual1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFual2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFual3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    baseYearPRFual4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    agRegimeId: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    cemrProdFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    makeProdFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    makeDispFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    MDispPerProdFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    LProdPerDispFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    CFracProdFualA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracGbfpA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracGbfpA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracCaneA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracCaneA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracLfprA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracLfprA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracRtprA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracRtprA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracHssiA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracHssiA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fillFracAnprA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    ldcmFracAnprA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    idRegimeSP: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    catA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    recoveryPeriodA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRFual4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRFual3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRFual2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRFual1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRProd4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    fracGradeHarv: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRProd3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRProd2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRProd1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRHssi4: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRHssi3: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRHssi2: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    useStemDensityPRHssi1: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
  };

  public readonly speciesType = "crop";

  public applySpeciesFormData(speciesAgriculture, isFromServer?: boolean) {
    if (!speciesAgriculture) {
      return;
    }

    const speciesFormGroup = this.createFormGroup(this.speciesFormModel);
    const eventQ = speciesAgriculture.EventQ;
    const agDetails = speciesAgriculture.AgDetails;
    const agInit = speciesAgriculture.AgInit;

    const allocations = speciesAgriculture.Allocations;

    const growth = speciesAgriculture.Growth;

    const nmGradeA = speciesAgriculture.nmGradeA;
    const products = speciesAgriculture.Products;
    const notes = speciesAgriculture.notesSP;

    if (agDetails) {
      const agOjb = agDetails[0].$;
      const varNCDataA = agDetails[0].VarNCDataA;
      const harvDest = agDetails[0].HarvDest;
      const harvInfo = agDetails[0].HarvInfo;

      this.applyXmlValuesToFormGroup(agOjb, speciesFormGroup);
      if (varNCDataA) {
        varNCDataA.forEach((varNCData) => {
          const varNCDataObj = {};
          const fieldPrefix = varNCData.$.id.slice(0, -2);
          //convert keys before applying data
          Object.entries(varNCData.$).forEach((keyValue) => {
            const key = keyValue[0];
            const value = keyValue[1];
            if (key !== "id") {
              varNCDataObj[
                fieldPrefix + key.charAt(0).toUpperCase() + key.slice(1)
              ] = value;
            }
          });

          this.applyXmlValuesToFormGroup(varNCDataObj, speciesFormGroup);
        });
      }

      if (harvDest) {
        harvDest.forEach((hd) => {
          const hdObj = {};
          const fieldSuffix = hd.$.id.slice(-1);

          //convert keys before applying data
          Object.entries(hd.$).forEach((keyValue) => {
            const key = keyValue[0];
            const value = keyValue[1];
            if (key !== "id") {
              hdObj[key + fieldSuffix] = value;
            }
          });

          this.applyXmlValuesToFormGroup(hdObj, speciesFormGroup);
        });
      }

      if (harvInfo) {
        harvInfo.forEach((hi) => {
          const hiObj = {};

          const fieldNumberSuffix = hi.$.id.slice(-1);

          hi.Price.forEach((p) => {
            //convert keys before applying data
            const fieldSuffix =
              p.$.id.charAt(0).toUpperCase() + p.$.id.slice(1);

            Object.entries(p.$).forEach((keyValue) => {
              const key = keyValue[0];
              const value = keyValue[1];
              if (key !== "id" && key !== "baseYearPR") {
                hiObj[key + fieldSuffix + fieldNumberSuffix] = value;
              }

              if (key == "baseYearPR") {
                hiObj[key + fieldNumberSuffix] = value;
              }
            });

            this.applyXmlValuesToFormGroup(hiObj, speciesFormGroup);
          });
        });
      }
    }

    if (agInit) {
      const crop = agInit[0].InitCropA;
      const debris = agInit[0].InitDebrA;
      const products = agInit[0].InitProdA;
      [crop, debris, products].forEach((init) => {
        if (init) {
          const initObj = {};
          //convert keys before applying data
          Object.entries(init[0].$).forEach((keyValue) => {
            const key = keyValue[0];
            const value = keyValue[1];
            initObj[key] = value;
          });

          this.applyXmlValuesToFormGroup(initObj, speciesFormGroup);
        }
      });
    }

    //time series fields
    [allocations, growth, speciesAgriculture].forEach((tsItem) => {
      if (tsItem?.length) {
        if (tsItem[0].TimeSeries) {
          tsItem[0].TimeSeries.forEach((ts) => {
            const formField = speciesFormGroup.get(ts.$.tInTS);
            if (formField) {
              formField.setValue(ts);
            } else {
              console.warn("Allocation Field " + ts.$.tInTS + " not found");
            }
          });
        }
      }
    });

    if (products) {
      products[0].Product.forEach((p) => {
        const obj = {};
        const fieldSuffix =
          p.$.id.charAt(0).toUpperCase() + p.$.id.slice(1) + "A";
        //convert keys before applying data
        Object.entries(p.$).forEach((keyValue) => {
          const key = keyValue[0];
          const value = keyValue[1];
          if (key !== "id") {
            obj[key + fieldSuffix] = value;
          }
        });

        this.applyXmlValuesToFormGroup(obj, speciesFormGroup);
      });
    }

    if (nmGradeA) {
      nmGradeA[0].s.forEach((ng) => {
        const formField = speciesFormGroup.get(ng._);
        if (formField) {
          formField.setValue(ng.$.id);
        } else {
          console.warn("Name Grade Field " + ng._ + " not found");
        }
      });
    }

    this.applyXmlValuesToFormGroup(speciesAgriculture.$, speciesFormGroup);

    //eventQ
    if (eventQ[0]) {
      const events = eventQ[0].Event;
      if (events) {
        const eventQFormArray = speciesFormGroup.get(
          "eventQ"
        ) as UntypedFormArray;

        events.forEach((event) => {
          const eventType = event.$.tEV;
          const eventFormsService = this.simulationService.eventFormsService;
          const service = eventFormsService.getEventService(eventType);
          const eventFormGroup = this.setEventQFormGroup(event, service);

          //manually trigger watchers to reapply validations
          this.manuallyTriggerWatchingFields(service.fieldWatcher, {
            formGroup: eventFormGroup,
            formModel: service.formModel,
            layout: service.layout,
            simulationService: this.simulationService,
          });

          eventQFormArray.push(eventFormGroup);
        });
      }
      this.getFormGroup().get("headerState").setValue(eventQ[0].HeaderState[0]);
      this.getFormGroup().get("showEvT").setValue(eventQ[0].showEvT[0]);
    }

    if (notes) {
      speciesFormGroup.get("notesSP").setValue(notes[0]);
    }

    const speciesIds = this.getSpecies().value.map((s) => s.idSP);
    const id = this.generateSpeciesId(speciesIds);
    speciesFormGroup.get("idSP").setValue(id);
    speciesFormGroup.get("tSP").setValue("Crop");
    // speciesFormGroup.get("dbId").setValue(speciesAgriculture.$.idSP);
    speciesFormGroup
      .get("idRegimeSP")
      .setValue(
        isFromServer
          ? speciesAgriculture.$.idSP
          : speciesAgriculture.$.idRegimeSP
      );

    this.addSpecies(speciesFormGroup);
  }

  public fieldWatcher: FieldWatcher = {
    grthModeSP: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const allocationsGroup = this.getLayoutGroup(
        this.layout.groups,
        "allocations"
      );
      const increamentGroup = this.getLayoutGroup(
        this.layout.groups,
        "increments"
      );
      const sigmoidalGroup = this.getLayoutGroup(
        this.layout.groups,
        "sigmoidal-group"
      );
      const perennialGroup = this.getLayoutGroup(
        this.layout.groups,
        "perennial-recovery-group"
      );
      const dieOffGroup = this.getLayoutGroup(
        this.layout.groups,
        "die-off-group"
      );

      allocationsGroup.isShown = newValue !== null;
      increamentGroup.isShown = newValue !== null;

      sigmoidalGroup.isShown = newValue == "Yield";
      perennialGroup.isShown = newValue == "Perennial";
      dieOffGroup.isShown = newValue == "Perennial";
    },
    tIncrTblA: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const fields = [
        "incrGbfrMA",
        "incrStlkMA",
        "incrLeafMA",
        "incrCortMA",
        "incrStLfMA",
        "incrAbgMA",
      ];

      fields.forEach((f) => {
        const isShown = f.toLowerCase() == String(newValue).toLowerCase();
        formModel[f].isShown = isShown;
        if (isShown) {
          this.addValidatorsInBulk([f], [Validators.required], formGroup);
        } else {
          this.removeValidatorsInBulk([f], [Validators.required], formGroup);
        }
      });
    },
    cropMortOn: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const stalkLossGroup = this.getLayoutGroup(
        this.layout.groups,
        "stalk-loss"
      );
      const fields = [
        "stlkMortA",
        "ratioGbfrMortA",
        "ratioLeafMortA",
        "ratioCortMortA",
        "ratioFirtMortA",
        "remvAvgAgeMultMortA",
        "remvMaxAgeMultMortA",
        "remvOffsetMortA",
      ];
      stalkLossGroup.isShown = newValue == true;

      if (stalkLossGroup.isShown) {
        this.addValidatorsInBulk(fields, [Validators.required], formGroup);
      } else {
        this.removeValidatorsInBulk(fields, [Validators.required], formGroup);
      }
    },
    bkdnSensMulcA: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      formModel["bkdnSensTempA"].isShown = newValue == true;
      formModel["bkdnSensRainA"].isShown = newValue == true;

      if (newValue == true) {
        this.addValidatorsInBulk(
          ["bkdnSensTempA", "bkdnSensRainA"],
          [Validators.required],
          formGroup
        );
      } else {
        this.removeValidatorsInBulk(
          ["bkdnSensTempA", "bkdnSensRainA"],
          [Validators.required],
          formGroup
        );
      }
    },
  };

  public replaceIdRegimeSPFromDownloadedSpecies(response) {
    if (!response?.SpeciesAgriculture[0]) {
      return;
    }
    const speciesDetails = response?.SpeciesAgriculture[0].$;

    const speciesFormGroup = this.getSpecies().controls.find(
      (s) => s.get("nmSP").value == speciesDetails.nmSP
    );

    if (speciesFormGroup) {
      //check if current selected species id = original idRegimeSP
      if (
        speciesFormGroup.get("idRegimeSP").value ==
        this.selectedSpeciesId$.getValue()
      ) {
        this.selectedSpeciesId$.next(speciesDetails.idSP);
      }
      speciesFormGroup.get("idRegimeSP").setValue(speciesDetails.idSP);
    }
  }

  public readXmlObject(): void {
    //all species will be assigned by data builder's tab
  }

  public writeXmlObject() {
    const baseFields = [
      "idSP",
      "nmSP",
      "grthModeSP",
      "tAgeIxSP",
      "curEdit",
      "idRegimeSP",
      "CFracGbfrA",
      "CFracStlkA",
      "CFracLeafA",
      "CFracCortA",
      "CFracFirtA",
      "turnFracGbfrA",
      "turnFracStlkA",
      "turnFracLeafA",
      "turnFracCortA",
      "turnFracFirtA",
      "ratioGbfrMortA",
      "ratioLeafMortA",
      "ratioCortMortA",
      "ratioFirtMortA",
      "rFracGbfrA",
      "rFracStlkA",
      "rFracLeafA",
      "rFracCortA",
      "rFracFirtA",
      "bkdnFracDGlitA",
      "bkdnFracRGlitA",
      "bkdnFracDSlitA",
      "bkdnFracRSlitA",
      "bkdnFracDLlitA",
      "bkdnFracRLlitA",
      "bkdnFracDCodrA",
      "bkdnFracRCodrA",
      "bkdnFracDFidrA",
      "bkdnFracRFidrA",
      "atmsFracDGlitBkdnA",
      "atmsFracRGlitBkdnA",
      "atmsFracDSlitBkdnA",
      "atmsFracRSlitBkdnA",
      "atmsFracDLlitBkdnA",
      "atmsFracRLlitBkdnA",
      "atmsFracDCodrBkdnA",
      "atmsFracRCodrBkdnA",
      "atmsFracDFidrBkdnA",
      "atmsFracRFidrBkdnA",
      "bkdnSensMulcA",
      "bkdnSensSoilA",
      "bkdnSensTempA",
      "bkdnSensRainA",
      "catA",
      "sigmoidalGrowthA",
      "sigmoidalGrowthB",
      "recoveryFracA",
      "recoveryPeriodA",
    ];
    const productsFields = [
      "pdcmFrac",
      "enerFrac",
      "fillFrac",
      "ldcmFrac",
      "effcEner",
      "effcFoss",
      "cemrEner",
      "cemrFoss",
      "makeProd",
      "cemrProd",
      "makeDisp",
      "cemrDisp",
      "MDispPerProd",
      "LProdPerDisp",
      "CFracProd",
    ];
    const productsFieldConcats = [
      {
        position: "append",
        value: "FualA",
        id: "fual",
      },
      {
        position: "append",
        value: "GbfpA",
        id: "gbfp",
      },
      {
        position: "append",
        value: "CaneA",
        id: "cane",
      },
      {
        position: "append",
        value: "LfprA",
        id: "lfpr",
      },
      {
        position: "append",
        value: "RtprA",
        id: "rtpr",
      },
      {
        position: "append",
        value: "HssiA",
        id: "hssi",
      },
      {
        position: "append",
        value: "AnprA",
        id: "anpr",
      },
    ];

    const agDetailsBaseFields = [
      "cultivationCA",
      "harvCA",
      "biofuelBurnCA",
      "wasteBurnCA",
      "tIncrTblA",
      "cropMortOn",
      "replaceStlksMortA",
      "remvAvgAgeMultMortA",
      "remvMaxAgeMultMortA",
      "remvOffsetMortA",
      "maxSFixNCRatioA",
      "storFracLeafTurnNA",
      "userRDebrNCRatioA",
      "absnNCRatioA",
      "maxBkdnNCRatioA",
      "mrFracOfRLittA",
    ];
    const harvDestFields = [
      "fracGbfrToFualHarv",
      "fracGbfrToGbfpHarv",
      "fracGbfrToHssiHarv",
      "fracGbfrToGlitHarv",
      "fracStlkToFualHarv",
      "fracStlkToCaneHarv",
      "fracStlkToHssiHarv",
      "fracStlkToSlitHarv",
      "fracLeafToFualHarv",
      "fracLeafToLfprHarv",
      "fracLeafToHssiHarv",
      "fracLeafToLlitHarv",
      "fracCortToFualHarv",
      "fracCortToRtprHarv",
      "fracCortToHssiHarv",
      "fracCortToCodrHarv",
      "fracFirtToFidrHarv",
      "fracGlitToFualHarv",
      "fracSlitToFualHarv",
      "fracLlitToFualHarv",
    ];
    const varNCDataFFields = [
      "maxNC1A",
      "maxNC2A",
      "maxNC3A",
      "maxNC4A",
      "minNC1A",
      "minNC2A",
      "minNC3A",
      "minNC4A",
      "days2A",
      "days3A",
      "days4A",
    ];
    const varNCDataFFieldConcats = [
      {
        position: "prepend",
        value: "gbfr",
        id: "gbfrVA",
      },
      {
        position: "prepend",
        value: "stlk",
        id: "stlkVA",
      },
      {
        position: "prepend",
        value: "leaf",
        id: "leafVA",
      },
      {
        position: "prepend",
        value: "cort",
        id: "cortVA",
      },
      {
        position: "prepend",
        value: "firt",
        id: "firtVA",
      },
    ];

    const harvInfoFields = [
      "baseYearPR",
      "densityPR",
      "fracWaterPR",
      "harvCostPR",
      "incrPaHarvPR",
      "incrPaTrnsPR",
      "incrPaMillPR",
      "millPricePR",
      "tPR",
      "trnsCostPR",
      "useStemDensityPR",
    ];
    const harvInfoFieldConcats = [
      {
        position: "append",
        value: "Prod",
        id: "prod",
      },
      {
        position: "append",
        value: "Hssi",
        id: "hssi",
      },
      {
        position: "append",
        value: "Fual",
        id: "fual",
      },
    ];

    const growthTimeseries = [
      "incrGbfrMA",
      "incrStlkMA",
      "incrLeafMA",
      "incrCortMA",
      "incrStLfMA",
      "incrAbgMA",
      "turnFracDieOA",
    ];

    const allocationsTimeseriesFields = [
      "allocGbfrA",
      "allocStlkA",
      "allocLeafA",
      "allocCortA",
      "allocFirtA",
      "stlkMortA",
    ];

    const initCropFields = [
      "cropExistsInit",
      "maxCropAgeInit",
      "avgCropAgeInit",
      "gbfrMInitA",
      "stlkMInitA",
      "leafMInitA",
      "cortMInitA",
      "firtMInitA",
      "gbfrNCRatioInitA",
      "stlkNCRatioInitA",
      "leafNCRatioInitA",
      "cortNCRatioInitA",
      "firtNCRatioInitA",
      "storNMInitA",
      "cropNmInit",
    ];

    const initDebrisFields = [
      "dGlitCMInitA",
      "rGlitCMInitA",
      "dSlitCMInitA",
      "rSlitCMInitA",
      "dLlitCMInitA",
      "rLlitCMInitA",
      "dCodrCMInitA",
      "rCodrCMInitA",
      "dFidrCMInitA",
      "rFidrCMInitA",
      "dGlitNCRatioInitA",
      "dSlitNCRatioInitA",
      "dLlitNCRatioInitA",
      "dCodrNCRatioInitA",
      "dFidrNCRatioInitA",
    ];

    const initProductFields = [
      "fualCMInitA",
      "gbfpCMInitA",
      "caneCMInitA",
      "lfprCMInitA",
      "rtprCMInitA",
      "hssiCMInitA",
      "anprCMInitA",
      "fualNCRatioInitA",
      "gbfpNCRatioInitA",
      "caneNCRatioInitA",
      "lfprNCRatioInitA",
      "rtprNCRatioInitA",
      "hssiNCRatioInitA",
      "anprNCRatioInitA",
    ];

    const nameGradeFields = [
      "nmGradeA4",
      "nmGradeA3",
      "nmGradeA2",
      "nmGradeA1",
    ];

    const formData = this.getFormGroup().getRawValue();

    const SpeciesAgriculture = formData.species.map((spFormData) => {
      const speciesObject = {
        $: {},
        notesSP: [],
        Products: [{ Product: [] }],
        AgDetails: [
          {
            $: {},
            VarNCDataA: [],
            HarvDest: [],
            HarvInfo: [],
          },
        ],
        Growth: [{ TimeSeries: [] }],
        Allocations: [{ TimeSeries: [] }],
        AgInit: [
          {
            InitCropA: [],
            InitDebrA: [],
            InitProdA: [],
          },
        ],
        EventQ: [
          {
            $: {},
            Event: [],
            HeaderState: [],
            showEvT: [],
          },
        ],
        nmGradeA: [{ s: [] }],
      };

      speciesObject.$ = this.getFormValueObjectForXmlExport(
        baseFields,
        spFormData,
        this.speciesFormModel
      );

      speciesObject.notesSP[0] = spFormData.notesSP;

      //Products
      productsFieldConcats.forEach((concat) => {
        const fieldValues = this.getFormValueObjectForXmlExport(
          productsFields,
          spFormData,
          this.speciesFormModel,
          concat
        );

        speciesObject.Products[0].Product.push({ $: fieldValues });
      });

      //ForestDetails
      speciesObject.AgDetails[0].$ = this.getFormValueObjectForXmlExport(
        agDetailsBaseFields,
        spFormData,
        this.speciesFormModel
      );

      //nameGrade will change the value of the following Objects
      nameGradeFields.forEach((nameGrade) => {
        const ng = nameGrade.slice(0, nameGrade.length - 1);
        const ngId = nameGrade.charAt(nameGrade.length - 1);

        //HarvDest
        const thinDestFieldValues = this.getFormValueObjectForXmlExport(
          harvDestFields,
          spFormData,
          this.speciesFormModel,
          {
            position: "append",
            value: ngId,
            id: nameGrade,
          }
        );

        speciesObject.AgDetails[0].HarvDest.push({
          $: thinDestFieldValues,
        });

        //ThinInfo
        speciesObject.AgDetails[0].HarvInfo;
        const harvInfoObject = {
          $: { id: nameGrade },
          Price: [],
        };

        harvInfoFieldConcats.forEach((concat) => {
          let combinedConcat = { ...concat };
          combinedConcat.value = combinedConcat.value + ngId;

          const fieldValues = this.getFormValueObjectForXmlExport(
            harvInfoFields,
            spFormData,
            this.speciesFormModel,
            combinedConcat
          );

          harvInfoObject.Price.push({ $: fieldValues });
        });

        speciesObject.AgDetails[0].HarvInfo.push(harvInfoObject);

        speciesObject.nmGradeA[0].s.push({
          $: { id: ngId },
          _: nameGrade,
        });
      });

      //VarNCDataF
      varNCDataFFieldConcats.forEach((concat) => {
        const fieldValues = this.getFormValueObjectForXmlExport(
          varNCDataFFields,
          spFormData,
          this.speciesFormModel,
          concat
        );

        speciesObject.AgDetails[0].VarNCDataA.push({ $: fieldValues });
      });

      //Growth
      growthTimeseries.forEach((ts) => {
        speciesObject.Growth[0].TimeSeries.push(spFormData[ts]);
      });

      //Allocations
      allocationsTimeseriesFields.forEach((ts) => {
        speciesObject.Allocations[0].TimeSeries.push(spFormData[ts]);
      });

      //ForestInit
      speciesObject.AgInit[0].InitCropA.push({
        $: this.getFormValueObjectForXmlExport(
          initCropFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      speciesObject.AgInit[0].InitDebrA.push({
        $: this.getFormValueObjectForXmlExport(
          initDebrisFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      speciesObject.AgInit[0].InitProdA.push({
        $: this.getFormValueObjectForXmlExport(
          initProductFields,
          spFormData,
          this.speciesFormModel
        ),
      });

      //EventQ
      speciesObject.EventQ[0].$["count"] = spFormData.eventQ.length;
      speciesObject.EventQ[0].HeaderState[0] = formData.headerState;
      speciesObject.EventQ[0].showEvT = [formData.showEvT];
      speciesObject.EventQ[0].Event = spFormData.eventQ.map((evFormData) => {
        const eventType = evFormData.tEV;
        const eventFormsService = this.simulationService.eventFormsService;
        const eventTypeService = eventFormsService.getEventService(eventType);
        return eventTypeService.writeXmlObject(evFormData);
      });

      return speciesObject;
    });

    const xmlObject = {
      $: {
        count: SpeciesAgriculture.length,
        showOnlyInUse: String(formData.showOnlyInUse),
      },
      SpeciesAgriculture,
    };

    return {
      SpeciesAgricultureSet: [xmlObject],
    };
  }
}
