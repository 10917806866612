<div class="form-controls">
  <div class="left-controls">
    <div class="btn-group" *ngIf="hasSimulationResult()">
      <button
        type="button"
        class="btn btn-primary-darken btn-sm"
        data-bs-toggle="dropdown"
      >
        Export table
      </button>

      <ul class="dropdown-menu">
        <li class="dropdown-item">
          <a href="#" (click)="exportTableAsCsv($event)">Export as CSV</a>
        </li>
        <li class="dropdown-item">
          <a href="#" (click)="exportTableAToClipboard($event)"
            >Copy to Clipboard</a
          >
        </li>
      </ul>
    </div>
    <button
      *ngIf="selectedRows.length"
      type="button"
      class="btn btn-primary-darken btn-sm"
      (click)="copyDataToClipboard()"
    >
      Copy to clipboard
    </button>

    <div class="form-check" *ngIf="selectedRows.length">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="include-headers"
        [(ngModel)]="includeHeadersForClipboard"
      />
      <label class="form-check-label" for="include-headers">
        Include headers
      </label>
    </div>
  </div>
  <div class="right-controls">
    <div
      [tooltip]="
        service.maxNumberWindows == getOutputWindows().controls.length
          ? 'Maximum number of windows reached'
          : ''
      "
    >
      <button
        type="button"
        class="btn btn-primary-darken btn-sm"
        (click)="addOutputWindow()"
        [disabled]="
          service.maxNumberWindows == getOutputWindows().controls?.length
        "
      >
        New output window
      </button>
    </div>
  </div>
</div>

<form [formGroup]="formGroup">
  <tabset [formArrayName]="'outputWindows'" role="tablist">
    <tab
      *ngFor="let ow of getOutputWindows().controls; index as i"
      (selectTab)="tabSelected(ow)"
      [active]="ow.get('isActive').value"
      [formGroupName]="i"
      customClass="ow-tab"
      [id]="'ow-tab' + i"
    >
      <ng-template tabHeading>
        <div
          class="ow-title"
          [class]="{
            'is-editing': renamingWindowIndex == i,
            'is-disabled': ow.get('isDisabled').value
          }"
        >
          <span>{{ ow.get("title").value }}</span>
          <input
            *ngIf="renamingWindowIndex == i"
            type="text"
            formControlName="title"
            (keyup.enter)="exitEdit()"
            (keyup.esc)="cancelEdit(ow)"
            (keydown)="$event.stopPropagation()"
          />
        </div>

        <button
          class="btn btn-sm dropdown-button"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="visually-hidden">actions</span>
          <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a
              (click)="renameWindow(i, ow); $event.preventDefault()"
              class="dropdown-item"
              href="#"
              >Rename</a
            >
          </li>
          <li>
            <a
              (click)="cloneWindow(ow); $event.preventDefault()"
              class="dropdown-item"
              href="#"
              >Clone</a
            >
          </li>
          <li *ngIf="getOutputWindows().length > 1">
            <a
              (click)="removeWindow(i); $event.preventDefault()"
              class="dropdown-item"
              href="#"
              >Delete</a
            >
          </li>
          <li>
            <a
              (click)="disableWindow(ow); $event.preventDefault()"
              class="dropdown-item"
              href="#"
              >{{ ow.get("isDisabled").value ? "Enable" : "Disable" }}</a
            >
          </li>
        </ul>
      </ng-template>

      <div class="inner-tab-content">
        <ng-container
          *ngTemplateOutlet="
            tabContentControls;
            context: {
              formGroup: formGroup,
              formArrayName: 'outputWindows',
              formGroupName: i,
              ow: ow
            }
          "
        ></ng-container>

        <fc-chart
          #chart
          *ngIf="
            ow.get('viewType').value == 'Graph' && ow.get('chartOption').value
          "
          [id]="'chart-' + i"
          [chartOption]="ow.get('chartOption').value"
          class="chart"
        ></fc-chart>
        <div *ngIf="ow.get('viewType').value == 'Table'">
          <ng-container
            *ngTemplateOutlet="
              dataTable;
              context: {
                data: ow.get('tableData').value
              }
            "
          ></ng-container>
        </div>
        <div class="no-item" *ngIf="!ow.get('chartOption').value">No data</div>
      </div>
    </tab>
  </tabset>
</form>

<ng-template
  #tabContentControls
  let-formGroup="formGroup"
  let-formArrayName="formArrayName"
  let-formGroupName="formGroupName"
  let-ow="ow"
>
  <div class="tab-content-controls" [formGroup]="formGroup">
    <ng-container [formArrayName]="formArrayName">
      <ng-container [formGroupName]="formGroupName">
        <div
          class="btn-group btn-group-sm"
          role="group"
          aria-label="radio toggle between graph and table view"
        >
          <input
            formControlName="viewType"
            type="radio"
            class="btn-check"
            name="viewType"
            [id]="'graph-view-button' + formGroupName"
            value="Graph"
            autocomplete="off"
            [checked]="ow.get('viewType').value == 'Graph'"
          />

          <label
            class="btn btn-outline-primary"
            [for]="'graph-view-button' + formGroupName"
            >Graph</label
          >

          <input
            formControlName="viewType"
            type="radio"
            class="btn-check"
            name="viewType"
            [id]="'table-view-button' + formGroupName"
            value="Table"
            autocomplete="off"
            [checked]="ow.get('viewType').value == 'Table'"
          />
          <label
            class="btn btn-outline-primary"
            [for]="'table-view-button' + formGroupName"
            >Table</label
          >
        </div>
        <a
          aria-label="help link button"
          type="button"
          *ngIf="service.baseHelpPageUrl"
          class="btn help-button"
          [href]="service.baseHelpPageUrl + '/' + '168_Output%20Window.htm'"
          tooltip="View Output window help"
          target="_blank"
        >
          <i class="bi bi-question-circle"></i>
        </a>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #dataTable let-data="data">
  <ngx-datatable
    *ngIf="data?.rows"
    class="custom-table"
    style="height: 400px"
    [columns]="data?.headers"
    [rows]="data?.rows"
    [columnMode]="ColumnMode.force"
    [forceColumnWidth]="true"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="35"
    [scrollbarV]="true"
    [selectionType]="'multi'"
    (select)="onSelect($event)"
    [limit]="999"
  >
  </ngx-datatable>
  <p class="small" *ngIf="data?.rows?.length > 1">
    Tip: Hold 'Shift' to select multiple rows or 'Ctrl' (Cmd on Mac) for
    individual rows.
  </p>
</ng-template>
