<h1 class="page-title">Users & Access Management - {{ selectedPageView }}</h1>
<div class="row">
  <div class="header">
    <div class="btn-group">
      <a
        href="#"
        *ngFor="let pv of pageViews"
        (click)="selectPageView($event, pv.label)"
        [class]="{ active: selectedPageView == pv.label }"
        class="btn btn-primary"
        [attr.aria-current]="selectedPageView == pv.label ? pv.label : null"
        >{{ pv.label }}</a
      >
    </div>
  </div>

  <div class="content">
    <div class="table-controls">
      <div class="button-group-container">
        <div
          class="button-group"
          *ngIf="selectedRequestRows.length || selectedUserRows.length"
        >
          <select
            *ngIf="selectedPageView == 'Pending Requests'; else userBulkSelect"
            class="form-control form-select form-select-sm"
            [(ngModel)]="selectedRequestBulkAction"
          >
            <option value="Approve">Approve</option>
            <option value="Reject">Reject</option>
          </select>
          <fc-button
            [isLoading$]="isBulkActionLoading$"
            [inputText]="'Apply'"
            (click)="bulkAction()"
          ></fc-button>
        </div>
      </div>

      <div class="button-group-container">
        <div class="button-group">
          <input
            id="table-search"
            aria-label="Search table"
            class="form-control form-control-sm"
            placeholder="Search table"
            (keyup.enter)="setSearchString()"
            [(ngModel)]="searchString"
          />
          <button
            *ngIf="searchString?.length"
            aria-label="clear search button"
            type="button"
            class="btn clear-search-button btn-sm"
            (click)="clearSearch()"
          >
            <i class="bi bi-x-lg"> </i>
          </button>

          <button
            id="table-search"
            [disabled]="searchString?.length < 2"
            class="btn btn-primary-darken btn-sm"
            (click)="setSearchString()"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedPageView == 'Pending Requests'; else allUsers">
      <ngx-datatable
        #userRequestsTable
        class="custom-table"
        [rows]="getSelectedEntity()?.filteredEntity || []"
        [columnMode]="ColumnMode.force"
        [headerHeight]="56"
        [footerHeight]="56"
        rowHeight="56"
        [selected]="selectedRequestRows"
        [selectionType]="SelectionType.checkbox"
        (select)="onSelect($event)"
        [limit]="10"
      >
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn"
          >
            <label class="check-container">
              <span class="visually-hidden">{{
                allRowsSelected ? "Uncheck all" : "Check all"
              }}</span>
              <input
                type="checkbox"
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
              />
              <span class="checkmark"></span>
            </label>
          </ng-template>
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <label class="check-container">
              <span class="visually-hidden">{{
                isSelected ? "Deselect" : "Select"
              }}</span>
              <input
                type="checkbox"
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
              />
              <span class="checkmark"></span>
            </label>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="userName" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="userEmailId" [draggable]="false">
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            {{ value }}

            <button
              (click)="openUserProfile(row)"
              type="button"
              class="btn"
              tooltip="View profile"
            >
              <i class="bi bi-file-earmark-person"></i>
            </button>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="roleName" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          prop="product"
          name="Requested product"
          [draggable]="false"
        >
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column prop="reason" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Actions"
          *ngIf="!selectPlotFilesOnly"
          [width]="180"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="user-actions">
              <button
                type="submit"
                class="btn btn-sm btn-primary"
                (click)="actionRequest(true, row)"
              >
                Approve
              </button>
              <button
                type="submit"
                class="btn btn-sm btn-danger"
                (click)="actionRequest(false, row)"
              >
                Reject
              </button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ng-container>
  </div>
</div>

<ng-template #allUsers>
  <ngx-datatable
    #allUserTable
    class="custom-table"
    [rows]="getSelectedEntity()?.filteredEntity || []"
    [columnMode]="ColumnMode.force"
    [headerHeight]="56"
    [footerHeight]="56"
    rowHeight="56"
    [selected]="selectedUserRows"
    [selectionType]="SelectionType.checkbox"
    (select)="onSelect($event)"
    [limit]="10"
  >
    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <label class="check-container">
          <span class="visually-hidden">{{
            allRowsSelected ? "Uncheck all" : "Check all"
          }}</span>
          <input
            type="checkbox"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <label class="check-container">
          <span class="visually-hidden">{{
            isSelected ? "Deselect" : "Select"
          }}</span>
          <input
            type="checkbox"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="userName" [draggable]="false">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="userEmailId" [draggable]="false">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}

        <button
          (click)="openUserProfile(row)"
          type="button"
          class="btn"
          tooltip="View profile"
        >
          <i class="bi bi-file-earmark-person"></i>
        </button>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="roleName" [draggable]="false">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="status" [draggable]="false">
      <ng-template ngx-datatable-cell-template let-value="value">
        {{ value }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Actions"
      [width]="180"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="user-actions">
          <button
            (click)="unblockUser(row)"
            type="button"
            [disabled]="user?.status !== !'Blocked'"
            class="btn btn-sm btn-primary"
          >
            Unblock
          </button>
          <button
            (click)="blockUser(row)"
            type="button"
            [disabled]="user?.status == 'Blocked'"
            class="btn btn-sm btn-danger"
          >
            Block
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-template>

<ng-template #userBulkSelect>
  <select
    class="form-control form-select form-select-sm"
    [(ngModel)]="selectedUserBulkAction"
  >
    <option value="Block">Block</option>
    <option value="Unblock">Unblock</option>
  </select>
</ng-template>
