import { Injectable, ViewContainerRef } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";

import { FieldWatcher, FormModel } from "src/app/shared/models";
import { FormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ForestThinning2020Service } from "./forest-thinning-2020.service";

@Injectable({
  providedIn: "root",
})
export class ForestThinning2024Service extends ForestThinning2020Service {
  public version = "2024";

  public componentHost: ViewContainerRef = null;
  public isDynamicComponentLoaded = false;

  public readonly categoryIds = [74, 71];
  public readonly pageHelpLink = "140_Thin.htm";
  public readonly selectedSpeciesId$ = new BehaviorSubject<number>(-1); //for placeholder select

  public logGradesFormGroup: FormGroup;

  protected maximumYearsToRegrowPostThin: FormGroupElement[] = [
    {
      label: null,
      isShown: true,
      isUnderlineHeader: true,
      isUnderlineItems: true,
      showErrors: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "FormItemTableComponent",
              componentInputs: [
                {
                  inputKey: "caption",
                  value: "Maximum years to regrow post thin",
                },

                {
                  inputKey: "service",
                  method: "getService",
                },
                {
                  inputKey: "data",
                  value: {
                    headers: ["", "(years)"],
                    rows: [
                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Stems",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "yrsStemRegrowThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Branches",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "yrsBranRegrowThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Bark",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "yrsBarkRegrowThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Leaves",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "yrsLeafRegrowThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Coarse roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "yrsCortRegrowThin",
                        },
                      ],

                      [
                        {
                          element: "text",
                          isHeader: "true",
                          value: "Fine roots",
                        },
                        {
                          element: "input",
                          type: "number",
                          programmingName: "yrsFirtRegrowThin",
                        },
                      ],
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Thin",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Percentage of forest affected by thin",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctThin",
              },
            ],
          },
        ],
      },
      {
        label: "Age adjustment",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Enable biomass based age adjustment",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "useBioAgeAdjThin",
              },
            ],
          },
        ],
      },
      {
        label: "Destination percentages in the affected portion",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            groups: this.destinationPercentagesGroups,
          },
        ],
      },
      {
        label: "Post-Thin period",
        id: "post-thin-period",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [{ groups: this.postThinperiodGroups }],
      },
      {
        label: "Maximum years to regrow post-thin",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [{ groups: this.maximumYearsToRegrowPostThin }],
      },
      {
        label: "Tree removal and replacement",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "",
            inputs: [
              {
                width: "310px",
                element: "text",
                value: "Trees (stems) removed by this thin:",
              },
              {
                width: "100px",
                element: "text",
                method: "getStemsRemovedByThisThin",
              },
            ],
          },

          {
            id: "remvAvgAgeMultThin",
            label: null,
            inputs: [
              {
                programmingName: "remvAvgAgeMultThin", //only for isShown logic
                element: "component",
                component: "FormulaDisplayComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      lhs: "Average age of removed trees",
                      rhs: [
                        [
                          { element: "icon", type: "operator", value: "=" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvAvgAgeMultThin",
                          },

                          { element: "icon", type: "operator", value: "x" },
                          { element: "text", value: "Average age of trees" },
                        ],

                        [
                          { element: "icon", type: "operator", value: "+" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvMaxAgeMultThin",
                          },

                          { element: "icon", type: "operator", value: "x" },
                          { element: "text", value: "Age of the oldest trees" },
                        ],

                        [
                          { element: "icon", type: "operator", value: "+" },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "remvOffsetThin",
                          },

                          { element: "text", value: "[yr]" },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },

          {
            id: "replaceStemsThin",
            label: "Replace removed trees with new trees (of age 0)",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "replaceStemsThin",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    ...this.formModel,
    yrsStemRegrowThin: {
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      isShown: true,
      isDigest: true,
      isExplorer: true,
      label: "Years to fully regrow stems",
      tIn: 2247,
      systemType: 2,
      std: 0,
      unit: "yr",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 140,
      sortId: 0,
      isSubSame: true,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      spec: 0,
      eventId: 1,
    },
    yrsBarkRegrowThin: {
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      isShown: true,
      isDigest: true,
      isExplorer: true,
      label: "Years to fully regrow bark",
      tIn: 2248,
      systemType: 2,
      std: 0,
      unit: "yr",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 140,
      sortId: 0,
      isSubSame: true,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      spec: 0,
      eventId: 1,
    },
    yrsBranRegrowThin: {
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      isShown: true,
      isDigest: true,
      isExplorer: true,
      label: "Years to fully regrow branches",
      tIn: 2249,
      systemType: 2,
      std: 0,
      unit: "yr",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 140,
      sortId: 0,
      isSubSame: true,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      spec: 0,
      eventId: 1,
    },
    yrsLeafRegrowThin: {
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
        {
          functionName: "required",
        },
      ],
      isShown: true,
      isDigest: true,
      isExplorer: true,
      label: "Years to fully regrow leaves",
      tIn: 2250,
      systemType: 2,
      std: 0,
      unit: "yr",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 140,
      sortId: 0,
      isSubSame: true,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      spec: 0,
      eventId: 1,
    },
    yrsCortRegrowThin: {
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      isShown: true,
      isDigest: true,
      isExplorer: true,
      label: "Years to fully regrow coarse roots",
      tIn: 2251,
      systemType: 2,
      std: 0,
      unit: "yr",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 140,
      sortId: 0,
      isSubSame: true,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      spec: 0,
      eventId: 1,
    },
    yrsFirtRegrowThin: {
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      isShown: true,
      isDigest: true,
      isExplorer: true,
      label: "Years to fully regrow fine roots",
      tIn: 2252,
      systemType: 2,
      std: 0,
      unit: "yr",
      prefixId: 0,
      suffixId: 0,
      isRisk: true,
      isSpa: true,
      helpId: 140,
      sortId: 0,
      isSubSame: true,
      dataType: 0,
      low: 0,
      high: 100,
      enumId: null,
      isValidTricky: false,
      errorId: 0,
      isInverse: false,
      scale: 1,
      isOneMore: false,
      columnWidth: 70,
      categoryName: "EvThin",
      categoryLabel: "Forest thinning event",
      spec: 0,
      eventId: 1,
    },
  };

  public fieldWatcher: FieldWatcher = {
    ...this.fieldWatcher,
    fracStemToSDdwdThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToDdwdThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToFuelThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToPaprThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToPackThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToFurnThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToFibrThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToConsThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracStemToResiThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getStemFieldsForYearToRegrowFieldLogic(),
        "yrsStemRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToSDdwdThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToDdwdThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToFuelThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToPaprThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToPackThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToFurnThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToFibrThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToConsThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBranToResiThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBranchFieldsForYearToRegrowFieldLogic(),
        "yrsBranRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBarkToSBlitThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBarkFieldsForYearToRegrowFieldLogic(),
        "yrsBarkRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBarkToBlitThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBarkFieldsForYearToRegrowFieldLogic(),
        "yrsBarkRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBarkToFuelThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBarkFieldsForYearToRegrowFieldLogic(),
        "yrsBarkRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBarkToPaprThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBarkFieldsForYearToRegrowFieldLogic(),
        "yrsBarkRegrowThin",
        formGroup,
        formModel
      );
    },
    fracBarkToResiThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getBarkFieldsForYearToRegrowFieldLogic(),
        "yrsBarkRegrowThin",
        formGroup,
        formModel
      );
    },
    fracLeafToSLlitThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getLeafFieldsForYearToRegrowFieldLogic(),
        "yrsLeafRegrowThin",
        formGroup,
        formModel
      );
    },
    fracLeafToLlitThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getLeafFieldsForYearToRegrowFieldLogic(),
        "yrsLeafRegrowThin",
        formGroup,
        formModel
      );
    },
    fracLeafToFuelThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getLeafFieldsForYearToRegrowFieldLogic(),
        "yrsLeafRegrowThin",
        formGroup,
        formModel
      );
    },
    fracCortToSCodrThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getCoarseRootFieldsForYearToRegrowFieldLogic(),
        "yrsCortRegrowThin",
        formGroup,
        formModel
      );
    },
    fracCortToCodrThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getCoarseRootFieldsForYearToRegrowFieldLogic(),
        "yrsCortRegrowThin",
        formGroup,
        formModel
      );
    },
    fracCortToFuelThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getCoarseRootFieldsForYearToRegrowFieldLogic(),
        "yrsCortRegrowThin",
        formGroup,
        formModel
      );
    },
    fracFirtToSFidrThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getFineRootFieldsForYearToRegrowFieldLogic(),
        "yrsFirtRegrowThin",
        formGroup,
        formModel
      );
    },
    fracFirtToFidrThin: (newValue: any, { formGroup, formModel }) => {
      this.applyYearToRegrowFieldLogic(
        this.getFineRootFieldsForYearToRegrowFieldLogic(),
        "yrsFirtRegrowThin",
        formGroup,
        formModel
      );
    },
  };

  protected getStemFieldsForYearToRegrowFieldLogic() {
    return [
      "fracStemToSDdwdThin",
      "fracStemToDdwdThin",
      "fracStemToFuelThin",
      "fracStemToPaprThin",
      "fracStemToPackThin",
      "fracStemToFurnThin",
      "fracStemToFibrThin",
      "fracStemToConsThin",
      "fracStemToResiThin",
    ];
  }

  protected getBranchFieldsForYearToRegrowFieldLogic() {
    return [
      "fracBranToSDdwdThin",
      "fracBranToDdwdThin",
      "fracBranToFuelThin",
      "fracBranToPaprThin",
      "fracBranToPackThin",
      "fracBranToFurnThin",
      "fracBranToFibrThin",
      "fracBranToConsThin",
      "fracBranToResiThin",
    ];
  }

  protected getBarkFieldsForYearToRegrowFieldLogic() {
    return [
      "fracBarkToSBlitThin",
      "fracBarkToBlitThin",
      "fracBarkToFuelThin",
      "fracBarkToPaprThin",
      "fracBarkToResiThin",
    ];
  }

  protected getLeafFieldsForYearToRegrowFieldLogic() {
    return ["fracLeafToSLlitThin", "fracLeafToLlitThin", "fracLeafToFuelThin"];
  }

  protected getCoarseRootFieldsForYearToRegrowFieldLogic() {
    return ["fracCortToSCodrThin", "fracCortToCodrThin", "fracCortToFuelThin"];
  }

  protected getFineRootFieldsForYearToRegrowFieldLogic() {
    return ["fracFirtToSFidrThin", "fracFirtToFidrThin"];
  }

  protected applyYearToRegrowFieldLogic(
    watchFields: string[],
    targetField: string,
    formGroup,
    formModel
  ): void {
    let total = 0;

    for (let f of watchFields) {
      const value = formGroup.get(f).value;
      if (value === null || value === "" || value === undefined) {
        formModel[targetField].isShown = false;
        this.removeDefaultValidatorsInBulk([targetField], formGroup, formModel);
        return;
      }
      total += +value;
    }

    formModel[targetField].isShown = total > 0;

    this[
      total > 0 ? "addDefaultValidatorsInBulk" : "removeDefaultValidatorsInBulk"
    ]([targetField], formGroup, formModel);
  }

  public writeXmlObject(formData): object {
    const baseThinFields = [
      "fracAfctThin",
      "userThisThinByLG",
      "clearAllRemainsF",
      "fracStemToDdwdExtraF",
      "fracBranToDdwdExtraF",
      "fracBarkToBlitExtraF",
      "fracLeafToLlitExtraF",
      "fracCortToCodrExtraF",
      "fracFirtToFidrExtraF",
      "replaceStemsThin",
      "useBioAgeAdjThin",
      "remvAvgAgeMultThin",
      "remvMaxAgeMultThin",
      "remvOffsetThin",
      "multStemThin",
      "multBranThin",
      "multBarkThin",
      "multLeafThin",
      "multFirtThin",
      "multCortThin",
      "boostYrsThin",
      "fixThin",
      "phaThin",
      "treeNmThin",
      "yrsBarkRegrowThin",
      "yrsBranRegrowThin",
      "yrsLeafRegrowThin",
      "yrsCortRegrowThin",
      "yrsFirtRegrowThin",
    ];
    const baseThinDestFields = [
      "fracStemToDdwdThin",
      "fracStemToFuelThin",
      "fracStemToPaprThin",
      "fracStemToPackThin",
      "fracStemToFurnThin",
      "fracStemToFibrThin",
      "fracStemToConsThin",
      "fracStemToResiThin",
      "fracStemToSDdwdThin",
      "fracBranToDdwdThin",
      "fracBranToFuelThin",
      "fracBranToPaprThin",
      "fracBranToPackThin",
      "fracBranToFurnThin",
      "fracBranToFibrThin",
      "fracBranToConsThin",
      "fracBranToResiThin",
      "fracBranToSDdwdThin",
      "fracBarkToBlitThin",
      "fracBarkToFuelThin",
      "fracBarkToPaprThin",
      "fracBarkToResiThin",
      "fracBarkToSBlitThin",
      "fracLeafToLlitThin",
      "fracLeafToFuelThin",
      "fracLeafToSLlitThin",
      "fracCortToCodrThin",
      "fracCortToFuelThin",
      "fracCortToSCodrThin",
      "fracFirtToFidrThin",
      "fracFirtToSFidrThin",
      "fracDdwdToFuelThin",
      "fracChwdToFuelThin",
      "fracBlitToFuelThin",
      "fracLlitToFuelThin",
      "fracSDdwdToFuelThin",
      "fracSChwdToFuelThin",
      "fracSBlitToFuelThin",
      "fracSLlitToFuelThin",
    ];

    const xmlObject = {
      $: {},
      notesEV: [],
      Thin: [{ $: {}, fracGradeThin: [",,,,"], ThinDest: [{ $: {} }] }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.Thin[0].$ = this.getFormValueObjectForXmlExport(
      baseThinFields,
      formData
    );

    xmlObject.Thin[0].ThinDest[0].$ = {
      id: "dest",
      ...this.getFormValueObjectForXmlExport(baseThinDestFields, formData),
    };

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
