import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import moment, { Moment } from "moment";
import { BehaviorSubject } from "rxjs";
import { take, skip } from "rxjs/operators";

import * as _ from "lodash";

import { CalendarSystemT, FormModel } from "src/app/shared/models";
import TimeUtilities from "src/app/shared/utilities/time";
import { FormGroupElement, FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { RegimeFormData } from "../models";
import Utilities from "src/app/shared/utilities/utils";
import { ExplorerCatetory } from "src/app/simulation/models";

@Injectable({
  providedIn: "root",
})
export class Events2020Service extends BasePlotFormService {
  readonly availableRegimesMapSource$ = new BehaviorSubject<{
    [key: string]: { type: string; regime: any[] };
  }>({});

  public availableRegimesMap$ = this.availableRegimesMapSource$.asObservable();

  readonly selectedRegimeAndEventStateSource$ = new BehaviorSubject<{
    from: "event" | "regime";
    regimeInstances: string[];
  }>(null);
  public selectedRegimeAndEventState$ =
    this.selectedRegimeAndEventStateSource$.asObservable();

  public readonly eventStatus$ = new BehaviorSubject<String>("Ready");

  public numbersOfRegimesInQueue$ = new BehaviorSubject<number>(0);
  public numbersOfUniqueRegimes$ = new BehaviorSubject<number>(0);

  public readonly errorStatusMap = {
    0: "Thin invalid because there are no trees",
    1: "Harvest invalid because there is no crop",
    2: "Herbicide invalid because there is no crop",
    3: "Planting of trees invalid because there are already trees",
    4: "Planting of crop invalid because there is already a crop",
    5: "Event not ready\n(blank or out-of-range parameters)",
    6: "Turning both crop and debris grazing off invalid because neither was on",
    7: "Cannot use 3PG's fraction-canopy because 3PG is not on",
    8: "Crop grazing invalid because there is no crop",
    9: "Timing not ready\n(see the 'Timing' page)",
    10: "Configuration not ready\n(see the 'Configuration' page)",
    11: "Treatment invalid because there are no trees",
    12: "Treatment invalid because the tree yield formula not in use",
    13: "Chopper-roller invalid because there are trees standing",
    14: "Thinning invalid because expecting a different species",
    15: "Harvest invalid because expecting a different species",
    16: "Multiple events on same day are invalid",
  };

  public regimeIdCounter: number = 1;

  public readonly pageId = "events";
  public readonly pageHelpLink = "136_Events.htm";

  public readonly categoryIds = [71];

  public modifiers: FormGroupElement[] = [
    {
      label: "Regimes",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      helpLink: "278_Regime%20Update.htm",
      items: [
        {
          label: "Regimes in queue",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getNumberOfRegimes",
            },
          ],
        },
        {
          label: "Unique",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getNumberOfUniqueRegimes",
            },
          ],
        },
      ],
    },
    {
      label: "Events",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      helpLink: "136_Events.htm",
      items: [
        {
          label: "Sort by system",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "sortBy1",
            },
          ],
        },
        {
          label: "Only show simulating events",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "showOnlyHS",
            },
          ],
        },
        {
          label: "Sort by whether simulating",
          inputs: [
            {
              hideMethod: "shouldShowWhetherSimulatingButton",
              element: "input",
              type: "switch",
              programmingName: "sortBy2",
            },
          ],
        },
        {
          label: "Events in queue",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getNumberOfEvents",
            },
          ],
        },
        {
          label: "Simulating",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getNumberOfSimulatingEvents",
            },
          ],
        },

        {
          label: "Non-simulating",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getNumberOfNonSimulatingEvents",
            },
          ],
        },
      ],
    },

    {
      label: "Status",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              customClassFunction: "getEventStatusClass",
              element: "text",
              method: "getEventStatus",
            },
          ],
        },
      ],
    },

    {
      isAccordion: true,
      label: "Initial conditions",
      isShown: true,
      isRoot: true,
      isExpanded: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getInitialTreeName",
            },
          ],
        },
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getInitialCropName",
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Events",
    groups: [
      {
        label: "Regime editing",
        isShown: true,
        isRoot: true,
        showErrors: true,
        helpLink: "276_Editing%20Regimes.htm",
        isAccordion: true,
        isExpanded: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "RegimeEditorComponent",
                componentInputs: [
                  {
                    inputKey: "availableRegimesMap$",
                    variable: "availableRegimesMap$",
                    isObservable: true,
                  },
                  {
                    inputKey: "startDate",
                    method: "getStartDate",
                  },
                  {
                    inputKey: "endDate",
                    method: "getEndDate",
                  },
                  {
                    inputKey: "formGroupInstance",
                    method: "getFormGroup",
                  },
                  {
                    inputKey: "eventsService",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Event editing",
        isShown: true,
        isRoot: true,
        showErrors: true,
        helpLink: "136_Events.htm",
        isAccordion: true,
        isExpanded: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "EventEditorComponent",
                componentInputs: [
                  {
                    inputKey: "formGroupInstance",
                    method: "getFormGroup",
                  },
                  {
                    inputKey: "eventsService",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    headerState: {
      label: "Event header state",
      defaultValue: {
        $: {
          sortIx: "0",
          sortUp: "true",
          sortBy1: "false",
          sortBy2: "false",
          showOnlyHS: "false",
        },
        headSectW: ["80,270,90,214,90,270"],
      },
      isShown: false,
    },
    sortBy1: {
      label: "Sort by system",
      defaultValue: false,
    },
    sortBy2: {
      label: "Sort by whether simulating",
      defaultValue: false,
    },
    showOnlyHS: {
      label: "Only show simulating events",
      defaultValue: false,
    },
    sortIx: {
      label: "Only show simulating events",
      defaultValue: 0,
    },
    sortUp: {
      label: "Only show simulating events",
      defaultValue: true,
    },

    eventQ: {
      label: "Events",
      defaultValue: "formArray",
      isShown: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
    },
  };

  public speciesDeleted({ id, idRegimeSP, speciesType }) {
    if (!idRegimeSP) {
      //manually created species
      return;
    }
    const field = speciesType == "tree" ? "PlnF" : "PlnA";
    this.getEventQ().controls.forEach((e) => {
      if (e.get("tEV").value == field && e.get("idSP").value == idRegimeSP) {
        e.get("idSP").setValue(null);
        e.get(speciesType == "tree" ? "treeNmPlnF" : "cropNmPlnA").setValue(
          null
        );
      }
    });

    this.removeAvailableRegime(idRegimeSP);
  }

  public getCalendarSystem(): CalendarSystemT {
    return this.simulationService.timingService.getCalendarSystem();
  }

  public getStartDate(): Date {
    return this.simulationService.timingService.getStartDate();
  }

  public getEndDate(): Date {
    return this.simulationService.timingService.getEndDate();
  }

  public getSimulationTimingType(): "step" | "calendar" {
    return this.simulationService.timingService.getSimulationTimingType();
  }

  public isBetweenSimuationTime({
    eventDate,
    dateOriginEV,
    nYrsFromStEV,
    nDaysFromStEV,
  }): boolean {
    const moment = TimeUtilities.getMoment();
    const startDate = this.getStartDate();
    const endDate = this.getEndDate();
    let d = eventDate;

    if (dateOriginEV !== "Calendar") {
      d = this.convertStepsToEventDate(startDate, nYrsFromStEV, nDaysFromStEV);
    }

    return (
      moment(d).isSameOrAfter(startDate) && moment(d).isSameOrBefore(endDate)
    );
  }

  public convertStepsToEventDate(startDate, nYrsFromStEV, nDaysFromStEV): Date {
    const startDateObject = moment(startDate);
    startDateObject.add(nYrsFromStEV, "years");
    startDateObject.add(nDaysFromStEV, "days");

    return startDateObject.toDate();
  }

  public isFixedCalendarDate(): boolean {
    return this.simulationService.timingService.isFixedCalendarDate();
  }

  public addAvailableRegime(data) {
    const regimes = this.availableRegimesMapSource$.getValue();
    const type = data.RegimeF ? "tree" : "crop";
    if (regimes[data.$.idSP]) {
      console.error(`Id ${data.$.idSP} already exists.`);
    }
    let newRegime = {
      [data.$.idSP]:
        type == "tree"
          ? { regime: data.RegimeF, type }
          : { regime: data.RegimeA, type },
    };

    //add species for creating events
    newRegime[data.$.idSP].regime
      .map((r) => {
        return { ...r, speciesType: type };
      })
      .sort((a, b) => a?.$?.nmRG.localeCompare(b?.$?.nmRG));

    this.availableRegimesMapSource$.next({
      ...regimes,
      ...newRegime,
    });
  }

  public getRegimesFromEvents(formGroups: AbstractControl[]) {
    let regimeInstances: number[] = [];
    const regimes = formGroups.reduce((previousValue, fb) => {
      if (!regimeInstances.includes(fb.get("regimeInstance").value)) {
        previousValue.push(fb);
        regimeInstances.push(fb.get("regimeInstance").value);
        return previousValue;
      }
      return previousValue;
    }, []);
    return regimes.sort((a, b) => this.getEventDate(a) - this.getEventDate(b));
  }

  protected removeAvailableRegime(idRegimeSP: string) {
    const availableRegimesMap = this.availableRegimesMapSource$.getValue();
    const { [idRegimeSP]: removingRegime, ...restOfRegimesMap } =
      availableRegimesMap;

    this.availableRegimesMapSource$.next(restOfRegimesMap);
  }

  public updateRegime(regimeFormData: RegimeFormData, daysAdded: number) {
    const updatingRegimeInstance = regimeFormData.regimeInstance;
    const updatingEventDate = regimeFormData.dateEV;
    const moment = TimeUtilities.getMoment();

    const eventFormArray = this.getEventQ();

    const updatingRegime = eventFormArray.controls.filter(
      (c) => c.get("regimeInstance").value == updatingRegimeInstance
    );

    let lastUpdatingEventDate: Date = null;

    updatingRegime.forEach((fb) => {
      let eventDate: Date = null;
      //first event of Regime
      if (!lastUpdatingEventDate) {
        eventDate = updatingEventDate;
      } else {
        eventDate = moment(fb.get("dateEV").value).add(daysAdded, "d").toDate();
        eventDate = this.checkAndConvertLeapYearDate(eventDate);
      }

      fb.get("dateEV").setValue(eventDate);
      fb.get("nmRegime").setValue(regimeFormData.nmRegime);
      // fb.get("onEV").setValue(
      //   moment(eventDate).isSameOrBefore(this.getEndDate()) &&
      //     moment(eventDate).isSameOrAfter(this.getStartDate())
      //     ? regimeFormData.onEV
      //     : false
      // );

      fb.get("onEV").setValue(regimeFormData.onEV);

      fb.get("dateOriginEV").setValue(regimeFormData.dateOriginEV);
      //also startsim fields

      lastUpdatingEventDate = eventDate;
    });

    if (daysAdded == 0) {
      return;
    }

    this.rearrangeFormGroupEventDates(this.getEventQ().getRawValue(), [
      updatingRegimeInstance,
    ]);
  }

  public mergeRegimes(
    mergingToRegime: AbstractControl,
    selectedRegimes: AbstractControl[],
    mergingToRegimeName: string
  ) {
    const regimeId = mergingToRegime.get("regimeId").value;
    const mergingToRegimeInstance = mergingToRegime.get("regimeInstance").value;

    selectedRegimes.forEach((regime: AbstractControl) => {
      const targetRegimeFormGroups = this.getEventQ().controls.filter(
        (e) =>
          e.get("regimeInstance").value == regime.get("regimeInstance").value
      );

      targetRegimeFormGroups.forEach((regimeFormGroup) => {
        regimeFormGroup.get("nmRegime").setValue(mergingToRegimeName);
        regimeFormGroup.get("regimeInstance").setValue(mergingToRegimeInstance);
        regimeFormGroup.get("regimeId").setValue(regimeId);
      });
    });
  }

  public cloneRegime(
    regimeInstance: number,
    data: { years: number; repeatTimes: number }
  ) {
    const moment = TimeUtilities.getMoment();
    const allEvents = this.getEventQ().getRawValue();

    let regimeBeingCloned = allEvents
      .filter((e) => e.regimeInstance == regimeInstance)
      .map((cr) => _.cloneDeep(cr))
      .sort((a: any, b: any) => a.dateEV - b.dateEV);

    let yearsAdding = data.years;

    const isRegimeCycleLengthLessThanYearAdding =
      this.isRegimeCycleLengthLessThanYearAdding(
        regimeBeingCloned[0].dateEV,
        regimeBeingCloned[regimeBeingCloned.length - 1].dateEV,
        yearsAdding
      );

    //check if clone year is less than regime cycle,
    //add the years to the original regime

    if (isRegimeCycleLengthLessThanYearAdding) {
      const updatingOriginalRegimeFromGroups = this.getEventQ().controls.filter(
        (eFormGroup) => eFormGroup.get("regimeInstance").value == regimeInstance
      );
      updatingOriginalRegimeFromGroups.forEach((ur) => {
        //check date originType type option
        // const dateOriginType = ur.get("dateOriginEV").value;
        // if (dateOriginType == "StartSim") {
        //   const startSimDate = ur.get("dateEV").setValue;
        // }

        const dateSplitted = moment(ur.get("dateEV").value)
          .format("DD-MM-YYYY")
          .split("-");

        ur.get("dateEV").setValue(
          moment(
            `${dateSplitted[0]}-${dateSplitted[1]}-${
              +dateSplitted[2] + yearsAdding
            }`,
            "DD-MM-YYYY"
          ).toDate()
        );
      });

      regimeBeingCloned = updatingOriginalRegimeFromGroups.map((ur) =>
        _.cloneDeep(ur.getRawValue())
      );
    }
    //end checking

    //init the last event date here from the oringinal regime
    let previousRegimeFirstEventDate = regimeBeingCloned[0].dateEV;
    let previousRegimeLastEventDate =
      regimeBeingCloned[regimeBeingCloned.length - 1].dateEV;

    //to track event year in a regime
    let previousEventYear = +moment(previousRegimeLastEventDate).year();

    //to tack years between last first event to current first event
    let offsetYear = null;

    [...new Array(data.repeatTimes)].forEach((_, rIndex) => {
      const newRegimeInstance = this.getNewRegimeId();

      regimeBeingCloned.forEach((cr, index, arr) => {
        let eventDate;

        const splittedOriginalEventDate = moment(cr.dateEV)
          .format("DD-MM-YYYY")
          .split("-");

        //only the first cloned regime will add the yearsAdding to its first event,
        //other regimes will follow the previous ones

        //first regime and first event
        const lastEventYear = +moment(previousRegimeLastEventDate).year();
        const firstEventYear = +moment(previousRegimeFirstEventDate).year();
        if (rIndex == 0 && index == 0) {
          //special handling for the first event date in the first event
          if (!isRegimeCycleLengthLessThanYearAdding) {
            eventDate = moment(
              `${splittedOriginalEventDate[0]}-${
                splittedOriginalEventDate[1]
              }-${+splittedOriginalEventDate[2] + yearsAdding}`,
              "DD-MM-YYYY"
            );
            offsetYear = yearsAdding;
          } else {
            const updatedEventDateAndYear =
              this.compareAndAdjustYearForFirstEvent(
                splittedOriginalEventDate,
                lastEventYear,
                previousRegimeLastEventDate
              );

            eventDate = updatedEventDateAndYear.updatedEventDate;
            offsetYear =
              +updatedEventDateAndYear.updatedLastEventYear - firstEventYear;
          }
        } else if (index == 0) {
          //first event of a regime
          //fist if first event date is greater than last year last event date, if not add 1 year
          const updatedEventDateAndYear =
            this.compareAndAdjustYearForFirstEvent(
              splittedOriginalEventDate,
              lastEventYear,
              previousRegimeLastEventDate
            );

          eventDate = updatedEventDateAndYear.updatedEventDate;
          offsetYear =
            +updatedEventDateAndYear.updatedLastEventYear - firstEventYear;
        } else {
          //not the first event

          eventDate = moment(
            `${splittedOriginalEventDate[0]}-${splittedOriginalEventDate[1]}-${
              +splittedOriginalEventDate[2] + +offsetYear
            }`,
            "DD-MM-YYYY"
          ).toDate();
        }

        // eventDate = this.checkAndConvertLeapYearDate(eventDate);
        arr[index].regimeInstance = newRegimeInstance;
        arr[index].dateEV = eventDate;
        arr[index].eventId = Utilities.uuid();

        //update form group
        const eventType = cr.tEV;
        const eventService =
          this.simulationService.eventFormsService.getEventService(eventType);

        let eventFormGroup = eventService.getFormGroup();
        eventFormGroup = this.getBaseEventFormControls(eventFormGroup);
        eventFormGroup.patchValue(arr[index]);

        this.manuallyTriggerWatchingFields(eventService.fieldWatcher, {
          formGroup: eventFormGroup,
          formModel: eventService.formModel,
          layout: eventService.layout,
          simulationService: this.simulationService,
        });

        this.getEventQ().push(eventFormGroup);

        //update tracking variables

        //first event of a regime
        if (index == 0) {
          previousRegimeFirstEventDate = eventDate;
        }
        //last event of a regime
        if (index + 1 == arr.length) {
          previousRegimeLastEventDate = eventDate;
        }
        previousEventYear = +moment(eventDate).year();
      });

      this.rearrangeFormGroupEventDates(
        [...allEvents, ...regimeBeingCloned],
        [newRegimeInstance]
      );
      this.validateEvents();
    });
  }

  protected compareAndAdjustYearForFirstEvent(
    splittedOriginalEventDate,
    lastEventYear,
    previousRegimeLastEventDate
  ) {
    let updatedEventDate;
    let updatedLastEventYear;
    const newFirsteventDate = moment(
      `${splittedOriginalEventDate[0]}-${
        splittedOriginalEventDate[1]
      }-${+lastEventYear}`,
      "DD-MM-YYYY"
    ).toDate();

    if (moment(newFirsteventDate).isAfter(previousRegimeLastEventDate)) {
      updatedEventDate = newFirsteventDate;
      updatedLastEventYear = +lastEventYear;
    } else {
      updatedEventDate = moment(
        `${splittedOriginalEventDate[0]}-${splittedOriginalEventDate[1]}-${
          +lastEventYear + 1
        }`,
        "DD-MM-YYYY"
      ).toDate();
      updatedLastEventYear = +lastEventYear + 1;
    }

    return { updatedEventDate, updatedLastEventYear };
  }

  protected isRegimeCycleLengthLessThanYearAdding(
    originalStartDate,
    originalEndDate,
    yearsAdding
  ): boolean {
    const originalDateSplitted = moment(originalStartDate)
      .format("DD-MM-YYYY")
      .split("-");

    const testRegimeEndDate = moment(
      `${originalDateSplitted[0]}-${originalDateSplitted[1]}-${
        +originalDateSplitted[2] + yearsAdding
      }`,
      "DD-MM-YYYY"
    );

    return testRegimeEndDate.isSameOrBefore(moment(originalEndDate));
  }

  protected convertStartSimYearsAndDaysToEventDate(formGroup) {
    const moment = TimeUtilities.getMoment();
    let numYear = +formGroup.get("nYrsFromStEV").value || 0;
    let numDay = +formGroup.get("nDaysFromStEV").value || 0;

    if (numDay > 365) {
      const yearsAdding = Math.floor(numDay / 365);
      numYear = numYear + yearsAdding;
      numDay = numDay - yearsAdding * 365;
    }
    const startDateObject = moment(this.getStartDate());
    const startYear = +startDateObject.year();
    const endYear = +startYear + numYear;
    const isLastYearLeapYear = moment([]).isLeapYear();
    const isAfterFeb = moment(endYear)
      .add(+numDay, "days")
      .isAfter(moment(endYear + "-02-29").toDate());

    if (isLastYearLeapYear && isAfterFeb) {
      numDay += 1;
    }

    startDateObject.add(+numYear, "years");
    startDateObject.add(+numDay, "days");
  }

  protected rearrangeFormGroupEventDates(
    allEvents,
    updatingRegimeInstances: number[]
  ): void {
    //this is for sorting the updated regime,
    //updatingRegimeInstance is the updated regime instance which its event dates will be preserved
    const updatingRegimeInstance = updatingRegimeInstances[0];

    const moment = TimeUtilities.getMoment();

    const regimesObject: any = _.chain(allEvents)
      .groupBy("regimeInstance")
      .value();

    //const regimes: any[] = Object.values(regimesObject);

    const regimes: any[] = Object.values(regimesObject).sort(
      (a: any, b: any) => a[0].dateEV - b[b.length - 1].dateEV
    );

    const updatingRegime = allEvents.filter(
      (e) => e.regimeInstance == updatingRegimeInstance
    );

    const startingIndex = regimes.findIndex(
      (r) => r[0].regimeInstance == updatingRegimeInstance
    );

    for (let i = startingIndex + 1; i < regimes.length; i++) {
      const lastRegime = regimes[i - 1];
      const previousRegimeLastEventDate =
        i == startingIndex + 1
          ? updatingRegime[updatingRegime.length - 1].dateEV
          : lastRegime[lastRegime.length - 1]?.dateEV;

      const currentRegime = regimes[i];
      const currentRegimeFirstEventDate = currentRegime[0].dateEV;
      let yearsToAdd = 0;
      if (
        lastRegime &&
        moment(previousRegimeLastEventDate).isSameOrAfter(
          currentRegimeFirstEventDate
        )
      ) {
        const lastRegimeEventDate = lastRegime[lastRegime.length - 1].dateEV;
        yearsToAdd =
          Math.abs(
            moment(currentRegimeFirstEventDate).diff(
              lastRegimeEventDate,
              "years"
            )
          ) + 1;
      }
      //if it's still same date after added offset years, add one more year

      if (
        moment(previousRegimeLastEventDate).isSame(
          moment(currentRegimeFirstEventDate).add(yearsToAdd).toDate()
        )
      ) {
        yearsToAdd += 1;
      }

      if (yearsToAdd == 0) {
        break;
      }

      currentRegime.forEach((cr, index, arr) => {
        const eventId = cr.eventId;
        //const newEventDateObject = moment(cr.dateEV).add(yearsToAdd, "years");
        // const eventDate = this.checkAndConvertLeapYearDate(
        //   newEventDateObject.toDate()
        // );

        const oldEventDateArray = moment(cr.dateEV)
          .format("DD-MM-YYYY")
          .split("-");
        const newEventDateObject = moment(
          oldEventDateArray[0] +
            "-" +
            oldEventDateArray[1] +
            "-" +
            (+oldEventDateArray[2] + yearsToAdd),
          "DD-MM-YYYY"
        );
        const eventDate = newEventDateObject.toDate();

        const fb = this.getEventQ().controls.find(
          (e) => e.get("eventId").value == eventId
        );

        fb.get("dateEV").setValue(eventDate);
        cr.dateEV = eventDate;
        arr[index].dateEV = eventDate;
      });
    }
  }

  // protected rearrangeFormGroupEventDates(
  //   allEvents,
  //   updatingRegimeInstances:number[]
  // ): void {
  //   //this is for sorting the updated regime,
  //   //updatingRegimeInstance is the updated regime instance which its event dates will be preserved

  //   const moment = TimeUtilities.getMoment();

  //   const regimesObject: any = _.chain(allEvents)
  //     .groupBy("regimeInstance")
  //     .value();

  //   //const regimes: any[] = Object.values(regimesObject);

  //   const regimes: any[] = Object.values(regimesObject).sort(
  //     (a: any, b: any) => a[0].dateEV - b[b.length - 1].dateEV
  //   );

  //   const updatingRegime = allEvents.filter(
  //     (e) => e.regimeInstance == updatingRegimeInstance
  //   );

  //   const startingIndex = regimes.findIndex(
  //     (r) => r[0].regimeInstance == updatingRegimeInstance
  //   );

  //   for (let i = startingIndex + 1; i < regimes.length; i++) {
  //     const lastRegime = regimes[i - 1];
  //     const previousRegimeLastEventDate =
  //       i == startingIndex + 1
  //         ? updatingRegime[updatingRegime.length - 1].dateEV
  //         : lastRegime[lastRegime.length - 1]?.dateEV;

  //     const currentRegime = regimes[i];
  //     const currentRegimeFirstEventDate = currentRegime[0].dateEV;
  //     let yearsToAdd = 0;
  //     if (
  //       lastRegime &&
  //       moment(previousRegimeLastEventDate).isSameOrAfter(
  //         currentRegimeFirstEventDate
  //       )
  //     ) {
  //       const lastRegimeEventDate = lastRegime[lastRegime.length - 1].dateEV;
  //       yearsToAdd =
  //         Math.abs(
  //           moment(currentRegimeFirstEventDate).diff(
  //             lastRegimeEventDate,
  //             "years"
  //           )
  //         ) + 1;
  //     }
  //     //if it's still same date after added offset years, add one more year

  //     if (
  //       moment(previousRegimeLastEventDate).isSame(
  //         moment(currentRegimeFirstEventDate).add(yearsToAdd).toDate()
  //       )
  //     ) {
  //       yearsToAdd += 1;
  //     }

  //     if (yearsToAdd == 0) {
  //       break;
  //     }

  //     currentRegime.forEach((cr, index, arr) => {
  //       const eventId = cr.eventId;
  //       //const newEventDateObject = moment(cr.dateEV).add(yearsToAdd, "years");
  //       // const eventDate = this.checkAndConvertLeapYearDate(
  //       //   newEventDateObject.toDate()
  //       // );

  //       const oldEventDateArray = moment(cr.dateEV)
  //         .format("DD-MM-YYYY")
  //         .split("-");
  //       const newEventDateObject = moment(
  //         oldEventDateArray[0] +
  //           "-" +
  //           oldEventDateArray[1] +
  //           "-" +
  //           (+oldEventDateArray[2] + yearsToAdd),
  //         "DD-MM-YYYY"
  //       );
  //       const eventDate = newEventDateObject.toDate();

  //       const fb = this.getEventQ().controls.find(
  //         (e) => e.get("eventId").value == eventId
  //       );

  //       fb.get("dateEV").setValue(eventDate);
  //       cr.dateEV = eventDate;
  //       arr[index].dateEV = eventDate;
  //     });
  //   }
  // }

  public deleteRegimes(regimeInstances: number[]) {
    regimeInstances.forEach((ri) => {
      const allEvents = this.getEventQ().getRawValue();
      const eventLength = allEvents.filter(
        (e) => e.regimeInstance == ri
      ).length;
      for (let i = 0; i < eventLength; i++) {
        //only removeAt available in form array
        const removeIndex = this.getEventQ()
          .getRawValue()
          .findIndex((e) => e.regimeInstance == ri);
        this.getEventQ().removeAt(removeIndex);
      }
    });
  }
  //this function return date based on dateOriginEV selection
  public getEventDate(fb: AbstractControl) {
    const dateOriginEV = fb.get("dateOriginEV").value;
    const nYrsFromStEV = fb.get("nYrsFromStEV").value;
    const nDaysFromStEV = fb.get("nDaysFromStEV").value;
    let d = fb.get("dateEV").value;

    if (dateOriginEV !== "Calendar") {
      const startDate = this.getStartDate();
      d = this.convertStepsToEventDate(startDate, nYrsFromStEV, nDaysFromStEV);
    }
    return d;
  }

  public getEventDatesByRegimeRules(
    regime,
    startDate,
    endDate,
    repeartEvery? // this is used to populate events Not  in create regime form
  ): { [key: string]: Date } {
    const moment = TimeUtilities.getMoment();
    let startDateObject = moment(startDate);
    const endDateObject = moment(endDate);
    const eventQueue = regime.RegimeEvent;

    // const ignoreItems = ["AN+182GP", "APGP", "ANGP"];
    let lastTransitionRule = null;
    let lastEventOffsetDay: number = null;
    // let years = 0;
    // let days = 0;
    let returnObject = {};
    let counter = 0;

    for (const eq of eventQueue) {
      if (eq.$.eventNmREV.indexOf("Grazing Non Managed") > -1) {
        continue;
      }

      const ruleSets = eq.$.ruleSet.split(",");

      const valueFromRule = ruleSets[0].match(/[\d\.]+/);
      const value = valueFromRule === null ? 0 : valueFromRule[0];
      const hasDecimal = String(value).indexOf(".") > -1;
      let yearDayValue = hasDecimal ? String(value).split(".") : [0, +value];
      let transitionRule = null;

      if (ruleSets[1]) {
        transitionRule = ruleSets[1].substring(
          ruleSets[1].indexOf("(") + 1,
          ruleSets[1].indexOf(")")
        );
      }

      if (ruleSets[0].indexOf("+") > -1) {
        // does  have =
        if (ruleSets[0].indexOf("=") > -1) {
          startDateObject.startOf("year").subtract(1, "day");
        }

        if (+yearDayValue[1] > 365) {
          const y = Math.floor(+yearDayValue[1] / 365);
          const d = +yearDayValue[1] - +yearDayValue[1] / 365;

          startDateObject.add(d, "days");
          startDateObject.add(y, "years");
        } else {
          startDateObject.add(yearDayValue[0], "years");
          startDateObject.add(yearDayValue[1], "days");
        }
      } else if (ruleSets[0].indexOf("-") > -1) {
        // has =
        if (ruleSets[0].indexOf("=") > -1) {
          //if repeat every is provided during process Events NOT in create regime Form

          if (repeartEvery) {
            //startDate is the startDate of every regime
            const modifiedendStartDate = moment(startDate).add(
              repeartEvery,
              "years"
            );
            startDateObject = modifiedendStartDate.subtract(
              yearDayValue[0],
              "years"
            );
          } else {
            startDateObject = moment(endDateObject.toDate()).subtract(
              yearDayValue[0],
              "years"
            );
          }

          startDateObject.startOf("year");
          //  startDateObject.subtract(+yearDayValue[0], "years");
        } else {
          startDateObject.subtract(+yearDayValue[0], "years");
          startDateObject.subtract(+yearDayValue[1], "days");
        }
      } else if (ruleSets[0].indexOf("=") > -1) {
        //if offset day less than the start date on the FIRST sequence, start date will need to add 1 year
        const isLessThanOffSetDate =
          counter == 0 &&
          moment(startDateObject.toDate())
            .startOf("year")
            .subtract(1, "day")
            .add(yearDayValue[1], "days")
            .isBefore(startDateObject.toDate());
        if (isLessThanOffSetDate) {
          startDateObject.add(1, "year");
        }
        startDateObject.startOf("year").subtract(1, "day");

        if (!hasDecimal && +yearDayValue[1] < lastEventOffsetDay) {
          startDateObject.add(1, "years");
        }
        startDateObject.add(+yearDayValue[0], "years");
        startDateObject.add(+yearDayValue[1], "days");
      } else if (value) {
        //value only without operater

        startDateObject.subtract(+yearDayValue[0], "years");
        startDateObject.subtract(+yearDayValue[1], "days");
      }

      //exceptions
      if (!valueFromRule) {
        const eventName = eq.$.eventNmREV;
        if (
          lastTransitionRule == "CWSW" &&
          eventName.indexOf("Thin (clearing)") > -1
        ) {
          startDateObject.add(182, "days");
        }
      }

      lastTransitionRule = transitionRule;

      lastEventOffsetDay = +yearDayValue[1];

      returnObject[eq.$.seq] = this.checkAndConvertLeapYearDate(
        startDateObject.toDate()
      );
      counter++;
    }

    return returnObject;
  }

  public async createEventsByRegime(regimeFormData: RegimeFormData) {
    const moment = TimeUtilities.getMoment();

    const repeatEveryType = regimeFormData.repeatEveryType;

    const startDate = regimeFormData.startDate;

    const untilDate = regimeFormData.untilDate;
    const rotationLength = regimeFormData.repeatEvery || 0;

    let counter = 0;
    let lastRegime;

    let startDateObject = moment(startDate);

    let untilDateObject: Moment = moment(untilDate);

    const isInvalidDate =
      String(startDateObject.toDate()) == "Invalid Date" ||
      !startDateObject.isValid() ||
      String(untilDate) == "Invalid Date" ||
      !untilDate;

    while (
      counter == 0 ||
      (this.getEventQ().getRawValue().length &&
        untilDateObject.isAfter(
          lastRegime[lastRegime.length - 1].get("dateEV").value
        ) &&
        !isInvalidDate)
    ) {
      let dateObject = counter == 0 ? startDateObject : moment();

      if (counter > 0) {
        //only loop once if no rotation
        if (rotationLength == 0) {
          break;
        }

        const lastRegimeFirstEventYeaObject = moment(
          lastRegime[0].get("dateEV").value
        ).startOf("year");

        dateObject = lastRegimeFirstEventYeaObject.add(
          rotationLength,
          repeatEveryType
        );

        //sometimes first event date + rotation length might be less than the last event
        if (
          lastRegimeFirstEventYeaObject.isBefore(
            lastRegime[lastRegime.length - 1].get("dateEV").value
          )
        ) {
          dateObject = moment(
            lastRegime[lastRegime.length - 1].get("dateEV").value
          );
        }

        //break if the next rotation date (after added rotation) is greater than until date
        if (untilDateObject.isBefore(dateObject.toDate())) {
          break;
        }
      }

      const regimeId = this.getNewRegimeId();

      const events = await this.processEvents(
        regimeFormData,
        dateObject.toDate(),
        untilDate,
        regimeId,
        isInvalidDate
      );

      events.forEach((e) => {
        this.getEventQ().push(e);
      });
      lastRegime = events;
      counter++;
    }
  }

  protected getLastEventDate(events) {
    return new Date(events[events.length - 1].dateEV);
  }

  protected async processEvents(
    regimeFormData: RegimeFormData,
    startDate: Date,
    untilDate: Date,
    id: number,
    isInvalidDate: boolean
  ) {
    const moment = TimeUtilities.getMoment();

    const selectedSpeciesType = regimeFormData.selectedRegime.$["tSpecFrCat"]
      ? "tree"
      : "crop";
    const selectedSpeciesDbId = regimeFormData.selectedRegime.$.idSpecies;

    const regimeEvents = regimeFormData.selectedRegime.RegimeEvent;
    const regimeName = regimeFormData.nmRegime;
    const regimeId = regimeFormData.selectedRegime.$.idRG; //regime id is used to filter unique regimes
    const treesService = this.simulationService.treesService;
    const cropsService = this.simulationService.cropsService;

    const selectedspeciesService =
      selectedSpeciesType == "tree" ? treesService : cropsService;

    const selectedSpeciesFormGroup =
      selectedspeciesService.getSpeciesFormGroupByIdRegimeSP(
        selectedSpeciesDbId
      );

    const eventDatesMap = this.getEventDatesByRegimeRules(
      regimeFormData.selectedRegime,
      startDate,
      untilDate,
      regimeFormData.repeatEvery
    );
    let events = [];

    const loopRegimeEvents = async () => {
      for await (const re of regimeEvents) {
        const regimeSeq = re.$.seq;
        const eventDate = eventDatesMap[regimeSeq];

        //some of regime events might be skipped
        if (!eventDate) {
          continue;
        }

        const reName = re.$.eventNmREV;

        const eventType = re.$.tevREV;
        const speciesDbId = re.$.idSP;

        let selectedSpeciesEventQ =
          selectedspeciesService.getSpeciesEventQByIdRegimeSP(
            selectedSpeciesDbId
          );

        if (!selectedSpeciesEventQ) {
          continue;
        }

        if (speciesDbId !== selectedSpeciesDbId) {
          selectedSpeciesEventQ = await this.getSpeciesDetailsOnTheFly(
            eventType,
            speciesDbId,
            treesService,
            cropsService
          );
        }

        const eventFormData = selectedSpeciesEventQ.find(
          (sEQ) => sEQ.nmEV == reName
        );

        const eventService =
          this.simulationService.eventFormsService.getEventService(eventType);
        let eventFormGroup = eventService.getFormGroup();

        const customEventNameRequiredArray = ["PlnF", "PlnA"];
        const eventName = customEventNameRequiredArray.includes(eventType)
          ? this.simulationService.eventFormsService.getEventDescription(
              eventFormData
            )
          : reName;

        //eventFormData is from species and have been coverted from xml to form.
        //this.applyXmlValuesToFormGroup(eventFormData, eventFormGroup);
        eventFormGroup = this.getBaseEventFormControls(eventFormGroup);
        eventFormGroup.patchValue(eventFormData);
        eventFormGroup.get("regimeInstance").setValue(id);
        eventFormGroup.get("nmRegime").setValue(regimeName);
        eventFormGroup.get("regimeId").setValue(regimeId);
        // eventFormGroup.get("regimeSeq").setValue(parseInt(regimeSeq));

        //fields own by regime event
        eventFormGroup.get("nmEV").setValue(eventName);

        //create id for internal use
        eventFormGroup.get("eventId").setValue(Utilities.uuid());

        //if until date is invalid fall back to start date and onEv set to false
        eventFormGroup
          .get("dateEV")
          .setValue(isInvalidDate ? this.getStartDate() : eventDate);

        eventFormGroup
          .get("onEV")
          .setValue(
            isInvalidDate
              ? false
              : moment(eventDate).isSameOrBefore(this.getEndDate()) &&
                  moment(eventDate).isSameOrAfter(this.getStartDate())
          );

        // EventQ in Events page species idSP = app idSP
        //EventQ in its own species idSP= idRegimeSP
        eventFormGroup
          .get("idSP")
          .setValue(selectedSpeciesFormGroup?.get("idSP").value);

        if (!eventFormGroup.get("tEV").value) {
          console.error(`Event Type ${eventType} not found`);
        }

        //manually trigger watchers to reapply validations
        this.manuallyTriggerWatchingFields(eventService.fieldWatcher, {
          formGroup: eventFormGroup,
          formModel: eventService.formModel,
          layout: eventService.layout,
          simulationService: this.simulationService,
        });

        //recalculate clearEV
        eventFormGroup = eventService.modifyBeforeSave(eventFormGroup);

        events.push(eventFormGroup);
      }
    };
    await loopRegimeEvents();

    return events;
  }

  // protected getSpeciesIdByDbId(dbId, eventType, treesService, cropsService) {
  //   const speciesType =
  //     this.simulationService.eventFormsService.getSpeciesTypeByEventType(
  //       eventType
  //     );
  //   const speciesService = speciesType == "tree" ? treesService : cropsService;
  //   return speciesService.getSpeciesIdByDbId(dbId);
  // }

  protected getSpeciesDetailsOnTheFly(
    eventType,
    idRegimeSP,
    treesService,
    cropsService
  ) {
    return new Promise((resolve, reject) => {
      const speciesType =
        this.simulationService.eventFormsService.getSpeciesTypeByEventType(
          eventType
        );
      const speciesService =
        speciesType == "tree" ? treesService : cropsService;
      const dataBuilderService = this.simulationService.dataBuilderService;
      const speciesSource$ =
        speciesType == "tree"
          ? dataBuilderService.selectedTreeSpeciesSource$
          : dataBuilderService.selectedCropSpeciesSource$;

      if (speciesService.getSpeciesEventQByIdRegimeSP(idRegimeSP)) {
        resolve(speciesService.getSpeciesEventQByIdRegimeSP(idRegimeSP));
        return;
      }

      dataBuilderService.getSpeciesDetails({
        species: { id: idRegimeSP, type: speciesType },
      });

      speciesSource$.pipe(skip(1), take(1)).subscribe(() => {
        resolve(speciesService.getSpeciesEventQByIdRegimeSP(idRegimeSP));
        return;
      });
    });
  }

  protected checkAndConvertLeapYearDate(d: Date): Date {
    if (this.getSimulationTimingType() == "calendar") {
      return d;
    }

    const moment = TimeUtilities.getMoment();
    const dateObject = moment(d);
    const year = dateObject.year();

    if (!dateObject.isLeapYear()) {
      return d;
    } else {
      return dateObject.isBefore(moment(year + "-02-29").toDate())
        ? d
        : dateObject.add(1, "day").toDate();
    }
  }

  protected getNewRegimeId(): number {
    const id = this.regimeIdCounter;
    this.regimeIdCounter++;
    return id;
  }

  public validateEvents() {
    const moment = TimeUtilities.getMoment();
    let shouldExitLoop = false; //nested break

    //start with initial tree, then based on each event's thinning % value
    let plantedTree = this.simulationService.initialConditionsService
      .getFormGroup()
      .get("treeExistsInit").value;
    let plantedCrop = this.simulationService.initialConditionsService
      .getFormGroup()
      .get("cropExistsInit").value;

    let lastPlantedCropFormGroup: AbstractControl;
    let lastPlantedTreeSpeciesName = "";
    let lastPlantedCropSpeciesName = "";

    let lastPlantedTree;
    const grazingCrop = [false, false];
    const grazingDebr = [false, false];

    const allEvents = this.getFilteredEventQByPlotType().controls.sort(
      (a: any, b: any) => this.getEventDate(a) - this.getEventDate(b)
    );
    this.eventStatus$.next("Ready");

    for (const eventFormGroup of allEvents) {
      //only set problemEV when needed, this will reduce changes that triggers rendering
      const isProblemEV = eventFormGroup.get("problemEV").value;
      if (eventFormGroup.invalid) {
        this.eventStatus$.next(this.errorStatusMap[5]);
        break;
      }
      if (shouldExitLoop) {
        break;
      }
      //skip non simulating events
      if (!eventFormGroup.get("onEV").value) {
        continue;
      }

      //check if its outside the start end date range in Timing page
      const isBetweenSimuationTime = this.isBetweenSimuationTime({
        eventDate: eventFormGroup.get("dateEV").value,
        dateOriginEV: eventFormGroup.get("dateOriginEV").value,
        nYrsFromStEV: eventFormGroup.get("nYrsFromStEV").value,
        nDaysFromStEV: eventFormGroup.get("nDaysFromStEV").value,
      });

      const eventType = eventFormGroup.get("tEV").value;
      switch (eventType) {
        case "PlnF":
          if (plantedTree) {
            shouldExitLoop = true;
            this.eventStatus$.next(this.errorStatusMap[3]);
            if (!isProblemEV) {
              eventFormGroup.get("problemEV").setValue(true);
            }
          } else {
            if (isProblemEV) {
              eventFormGroup.get("problemEV").setValue(false);
            }
          }

          plantedTree = true;
          lastPlantedTreeSpeciesName = eventFormGroup.get("treeNmPlnF").value;

          break;
        case "PlnA":
          //  eventFormGroup.get("problemEV").setValue(plantedCrop);
          if (plantedCrop || lastPlantedCropFormGroup) {
            if (!isProblemEV) {
              eventFormGroup.get("problemEV").setValue(true);
            }
            this.eventStatus$.next(this.errorStatusMap[4]);
            shouldExitLoop = true;
          } else {
            if (isProblemEV) {
              eventFormGroup.get("problemEV").setValue(false);
            }
          }

          plantedCrop = true;
          lastPlantedCropSpeciesName = eventFormGroup.get("cropNmPlnA").value;
          //idSP in event forms are idRegimeSP
          const speciesId = eventFormGroup.get("idSP").value;
          if (!speciesId) {
            break;
          }

          if (
            eventFormGroup.get("growthPeriod").value !== 0 &&
            this.simulationService.cropsService.getSpeciesByIdRegimeSP(
              speciesId
            )?.grthModeSP !== "Perennial"
          ) {
            eventFormGroup
              .get("runtimeGrowthPeriod")
              .setValue(eventFormGroup.get("growthPeriod").value);
          } else {
            lastPlantedCropFormGroup = eventFormGroup;
          }

          break;

        case "Thin":
          if (!plantedTree) {
            if (!isProblemEV) {
              eventFormGroup.get("problemEV").setValue(true);
            }
            this.eventStatus$.next(this.errorStatusMap[0]);
            //14: "Thinning invalid because expecting a different species",
            shouldExitLoop = true;
          } else {
            plantedTree = +eventFormGroup.get("fracAfctThin").value < 100;
            if (isProblemEV) {
              eventFormGroup.get("problemEV").setValue(false);
            }
          }
        // NOTE: Deliberate fall thru
        case "FirF":
          if (eventFormGroup.get("clearEV").value) {
            //it doesnt seem to affact anything in Existing fullcam
            plantedTree = false;
          }
          break;

        case "Harv":
          if (!plantedCrop) {
            this.eventStatus$.next(this.errorStatusMap[1]);
            //15: "Harvest invalid because expecting a different species",
            if (!isProblemEV) {
              eventFormGroup.get("problemEV").setValue(true);
            }
            shouldExitLoop = true;
            break;
          } else {
            if (isProblemEV) {
              eventFormGroup.get("problemEV").setValue(false);
            }
          }

        // NOTE: Deliberate fall thru
        case "Herb":
        case "FirA":
        case "Plow":
          if (eventFormGroup.get("clearEV").value) {
            if (lastPlantedCropFormGroup) {
              const dayDiff = moment(eventFormGroup.get("dateEV").value).diff(
                lastPlantedCropFormGroup.get("dateEV").value,
                "days"
              );
              lastPlantedCropFormGroup
                .get("runtimeGrowthPeriod")
                .setValue(dayDiff);
              lastPlantedCropFormGroup = null;
            }

            plantedCrop = false;
          }
          break;
        case "Graz":
          const isCropGrazOn = eventFormGroup.get("cropGrazOn").value;
          const isDebrisGrazOn = eventFormGroup.get("debrGrazOn").value;
          const isInvalid =
            !isDebrisGrazOn &&
            !isDebrisGrazOn &&
            (grazingCrop[isCropGrazOn ? 1 : 0] ||
              grazingDebr[isDebrisGrazOn ? 1 : 0]);
          //   eventFormGroup.get("problemEV").setValue(isInvalid);
          if (isInvalid) {
            this.eventStatus$.next(this.errorStatusMap[6]);
            if (!isProblemEV) {
              eventFormGroup.get("problemEV").setValue(true);
            }
            //6: "Turning both crop and debris grazing off invalid because neither was on",
          } else {
            if (isProblemEV) {
              eventFormGroup.get("problemEV").setValue(false);
            }
          }

          if (isCropGrazOn && !plantedCrop) {
            if (!isProblemEV) {
              eventFormGroup.get("problemEV").setValue(true);
            }
            this.eventStatus$.next(this.errorStatusMap[8]);

            shouldExitLoop = true;

            break;
            //8: "Crop grazing invalid because there is no crop",
          } else {
            if (isProblemEV) {
              eventFormGroup.get("problemEV").setValue(false);
            }
          }
          //TO BE COMFIRMED - NON MANAGED SEQUNCES WILL BE FILTERED OUT
          // const grazerTypeMap = {
          //   Managed: 0,
          //   NonManaged: 1,
          // };
          // grazingCrop[grazerTypeMap[eventFormGroup.get("grazerType").value]] =
          //   isCropGrazOn;
          // grazingDebr[grazerTypeMap[eventFormGroup.get("grazerType").value]] =
          //   isDebrisGrazOn;

          break;
        case "FFrc":
          // if (!co->PPPG && ev->AsType<FFrc>()->z.tFFrc == kFrFrac3PG)
          // ERR_RET(7)
          break;
        case "TmtF":
          const isTyfOn =
            this.getConfigurationFormGroup().get("tTreeProd").value == "TYF";

          // eventFormGroup
          //   .get("problemEV")
          //   .setValue((plantedTree && !isTyfOn) || !plantedTree);

          if (plantedTree && !isTyfOn) {
            this.eventStatus$.next(this.errorStatusMap[12]);
            shouldExitLoop = true;
            //12: "Treatment invalid because the tree yield formula not in use",
          } else if (!plantedTree) {
            shouldExitLoop = true;
            this.eventStatus$.next(this.errorStatusMap[11]);
            //11: "Treatment invalid because there are no trees",
          }
          break;

        case "Chop":
          //eventFormGroup.get("problemEV").setValue(plantedTree);
          if (plantedTree) {
            this.eventStatus$.next(this.errorStatusMap[13]);
            shouldExitLoop = true;
          }
          // ERR_RET(13);
          break;
      }

      if (shouldExitLoop) {
        return;
      }

      if (!isBetweenSimuationTime) {
        if (eventFormGroup.get("problemEV").value) {
          eventFormGroup.get("problemEV").setValue(false);
        }
        continue;
      }
    }
  }

  public getEventQ(): UntypedFormArray {
    return this.getFormGroup().get("eventQ") as UntypedFormArray;
  }

  public getFilteredEventQByPlotType(): UntypedFormArray {
    const availableEventTypes =
      this.simulationService.eventFormsService.getEventTypesByPlotType();

    const allEvents = this.getEventQ().controls.filter((eventFormGroup) => {
      const eventType = eventFormGroup.get("tEV").value;
      return availableEventTypes[eventType] !== undefined;
    });
    return new FormArray(allEvents);
  }

  /*******event CRUD******/

  public addEvent(fg: UntypedFormGroup): void {
    const moment = TimeUtilities.getMoment();
    const eventDate = fg.get("dateEV").value;
    const eventFormArray = this.getEventQ();
    const eventWithSameDate = eventFormArray.value.find(
      (e) => e.dateEV == eventDate
    );

    fg = this.getBaseEventFormControls(fg);

    fg.get("eventId").setValue(Utilities.uuid());
    fg.get("labelEV")?.setValue(
      this.simulationService.eventFormsService.getEventDescription(
        fg.getRawValue()
      )
    );

    fg.get("regimeInstance").setValue(
      eventWithSameDate ? eventWithSameDate.regimeInstance : Utilities.uuid()
    );

    fg.get("nmRegime").setValue(
      eventWithSameDate ? eventWithSameDate.nmRegime : "New Regime"
    );
    fg.get("regimeId").setValue(
      eventWithSameDate ? eventWithSameDate.regimeId : Utilities.uuid()
    );

    fg.get("onEV").setValue(
      moment(eventDate).isSameOrBefore(this.getEndDate()) &&
        moment(eventDate).isSameOrAfter(this.getStartDate())
    );

    //fullcam behaviour
    if (moment(eventDate).isBefore(this.getStartDate())) {
      fg.get("dateEV").setValue(this.getStartDate());
    }

    this.getEventQ().push(fg);
    this.validateEvents();
  }

  public editEvent(
    existingFormGroup: UntypedFormGroup,
    updatedFormGroup: UntypedFormGroup
  ): void {
    Object.entries(updatedFormGroup.controls).forEach((keyValueArray) => {
      const key = keyValueArray[0];
      const control = keyValueArray[1];
      existingFormGroup.setControl(key, this.cloneAbstractControl(control));
    });
  }

  public deleteEvent(fg: UntypedFormGroup): void {
    const removeIndex = this.getEventQ()
      .getRawValue()
      .findIndex((e) => e.eventId == fg.get("eventId").value);
    this.getEventQ().removeAt(removeIndex);
  }

  public cloneEvent(
    eventFormGroups: UntypedFormGroup[],
    data: { years: number; days: number; repeatTimes: number }
  ): void {
    const moment = TimeUtilities.getMoment();

    //events being cloned repeatly, dates will be based on the previous cloned events
    let clonningEventFormGroups = eventFormGroups;

    [...new Array(data.repeatTimes)].forEach(() => {
      let previousFormGroups = [];

      clonningEventFormGroups.forEach((eventFormGroup) => {
        let cloningEvent = eventFormGroup.getRawValue();

        const startDateObject = moment(cloningEvent.dateEV);
        startDateObject.add(data.years, "years");
        startDateObject.add(data.days, "days");

        let eventDate = startDateObject.toDate();
        eventDate = this.checkAndConvertLeapYearDate(eventDate);

        const isOnEv =
          moment(eventDate).isSameOrBefore(this.getEndDate()) &&
          moment(eventDate).isSameOrAfter(this.getStartDate());

        const eventService =
          this.simulationService.eventFormsService.getEventService(
            cloningEvent.tEV
          );

        const formGroup = eventService.getFormGroup();

        formGroup.patchValue({
          ...cloningEvent,
          onEV: isOnEv,
          dateEV: eventDate,
        });

        const formGroupWithBaseFormData =
          this.getBaseEventFormControls(formGroup);

        formGroupWithBaseFormData
          .get("regimeInstance")
          .setValue(cloningEvent.regimeInstance);
        formGroupWithBaseFormData
          .get("regimeId")
          .setValue(cloningEvent.regimeId);

        this.manuallyTriggerWatchingFields(eventService.fieldWatcher, {
          formGroup: formGroupWithBaseFormData,
          formModel: eventService.formModel,
          layout: eventService.layout,
          simulationService: this.simulationService,
        });

        this.getEventQ().push(formGroupWithBaseFormData);

        previousFormGroups.push(formGroupWithBaseFormData);
      });

      clonningEventFormGroups = [...previousFormGroups];
    });

    this.rearrangeFormGroupEventDates(this.getEventQ().getRawValue(), [
      clonningEventFormGroups[0].get("regimeInstance").value,
    ]);

    this.validateEvents();
  }

  /***** modifiers *****/

  public getEventStatusClass(): { [key: string]: boolean } {
    const status = this.eventStatus$.getValue();
    return { "invalid-text": status !== "Ready" };
  }

  public setNumberofRegimes(num: number): void {
    this.numbersOfRegimesInQueue$.next(num);
  }

  public setNunmberOfUniqueRegimes(num: number): void {
    this.numbersOfUniqueRegimes$.next(num);
  }

  public getNumberOfRegimes(): number {
    return this.numbersOfRegimesInQueue$.getValue();
  }

  public getNumberOfUniqueRegimes() {
    return this.numbersOfUniqueRegimes$.getValue();
  }

  public getNumberOfEvents() {
    return this.getEventQ().getRawValue().length;
  }

  public getNumberOfSimulatingEvents() {
    return this.getEventQ().controls.filter((e) => {
      const eventDate = e.get("dateEV")?.value;
      const onEV = e.get("onEV")?.value;

      const eventType = e.get("tEV").value;
      const eventTypeObject =
        this.simulationService.eventFormsService.getEventTypesByPlotType()[
          eventType
        ];
      //any events don't belong to plot type will be filtered out,
      //e.g. agricultural and mixed events will be filtered out if plot type is forest system
      if (!eventTypeObject) {
        return false;
      }

      if (!eventDate || !onEV) {
        return false;
      }

      const isOnEv =
        moment(eventDate).isSameOrBefore(this.getEndDate()) &&
        moment(eventDate).isSameOrAfter(this.getStartDate());

      return isOnEv;
    }).length;
  }

  public getNumberOfNonSimulatingEvents() {
    return (
      this.getEventQ().getRawValue().length - this.getNumberOfSimulatingEvents()
    );
  }

  public getInitialTreeName() {
    const initTreeId =
      this.simulationService.initialConditionsService.initTreeId$.getValue();
    const treeExists = this.simulationService.initialConditionsService
      .getFormGroup()
      .get("treeExistsInit").value;
    if (initTreeId == "0" || !initTreeId || !treeExists) {
      return "No tree";
    }

    return (
      "Tree: " +
      this.simulationService.treesService.getSpeciesByIdRegimeSP(initTreeId)
        ?.nmSP
    );
  }

  public getInitialCropName() {
    const initCropId =
      this.simulationService.initialConditionsService.initCropId$.getValue();
    const cropExists = this.simulationService.initialConditionsService
      .getFormGroup()
      .get("cropExistsInit").value;
    if (initCropId == "0" || !initCropId || !cropExists) {
      return "No crop";
    }

    return (
      "Crop: " +
      this.simulationService.cropsService.getSpeciesByIdRegimeSP(initCropId)
        ?.nmSP
    );
  }

  public getEventStatus() {
    return this.eventStatus$.getValue() || "Ready";
  }

  /***** end modifiers *****/

  public shouldDisableInsertStandardValuesButton(eventType) {
    const speciesType =
      this.simulationService.eventFormsService.getSpeciesTypeByEventType(
        eventType
      );
    const speciesService =
      speciesType == "tree"
        ? this.simulationService.treesService
        : this.simulationService.cropsService;

    const species = speciesService.getSpecies().value;

    if (!species.length) {
      return true;
    }

    const eventQs = species.filter((s) => {
      const filteredEventQ = s.eventQ.filter((eq) => eq.tEV == eventType);

      if (!filteredEventQ.length) {
        return false;
      }
      return true;
    });
    if (!eventQs.length) {
      return true;
    }

    return false;
  }

  protected getBaseEventFormControls(fg: UntypedFormGroup): UntypedFormGroup {
    const newEventId = Utilities.uuid();
    if (!fg.get("eventId")) {
      fg.addControl("eventId", new UntypedFormControl(newEventId));
    } else {
      fg.get("eventId").setValue(newEventId);
    }

    if (!fg.get("regimeInstance")) {
      fg.addControl("regimeInstance", new UntypedFormControl(null));
    } else {
      fg.get("regimeInstance").setValue(null);
    }

    if (!fg.get("problemEV")) {
      fg.addControl("problemEV", new UntypedFormControl(false));
    } else {
      fg.get("problemEV").setValue(false);
    }

    if (!fg.get("nmRegime")) {
      fg.addControl("nmRegime", new UntypedFormControl(""));
    } else {
      fg.get("nmRegime").setValue("");
    }

    if (!fg.get("regimeId")) {
      fg.addControl("regimeId", new UntypedFormControl(""));
    } else {
      fg.get("regimeId").setValue("");
    }

    return fg;
  }

  public setSelectedRegimeAndEventState(state: {
    from: "event" | "regime";
    regimeInstances: string[];
  }): void {
    this.selectedRegimeAndEventStateSource$.next(state);
  }

  public shouldShowWhetherSimulatingButton(): boolean {
    return this.formGroup.get("showOnlyHS").value ? true : false;
  }

  public getExplorerSubcategoryMap(data: ExplorerCatetory) {
    let explorerMap = {};

    const events = this.getEventQ().controls;
    let currentRegimeInstance: string = null;
    let indexCounter = 0;
    if (!events?.length) {
      return;
    }

    events
      .sort((a, b) => this.getEventDate(a) - this.getEventDate(b))
      .forEach((eventFormGroup) => {
        const eventFormsService =
          this.simulationService.eventFormsService.getEventService(
            eventFormGroup.get("tEV").value
          );
        const regimeInstance = eventFormGroup.get("regimeInstance").value;
        if (currentRegimeInstance !== regimeInstance) {
          currentRegimeInstance = regimeInstance;
          indexCounter++;
        }
        const formModel: FormModel = eventFormsService.formModel;

        const regimeName = eventFormGroup.get("nmRegime").value;
        const eventName = eventFormGroup.get("nmEV").value;

        const inputModels = Object.entries(formModel)
          .filter(
            (fm) =>
              fm[1].isFixedExplorerField || (fm[1].isExplorer && fm[1].isShown)
          )
          .map((fm) => {
            return { ...fm[1], programmingName: fm[0] };
          });

        for (const im of inputModels) {
          //used for digest
          const foundItem = this.findInputElementById(
            eventFormsService?.layout?.groups,
            im.programmingName,
            true,
            false
          );

          if (!foundItem && !im.isFixedExplorerField) {
            //   console.log(im.programmingName, " not found");
            continue;
          }

          const path = [`${regimeName} [${indexCounter}]`, eventName];
          const value = {
            label: im.label,
            model: im,
            programmingName: im.programmingName,
            value: eventFormGroup.get(im.programmingName).value,
            dataType: im.dataType,
            service: data.service,
            item: foundItem?.item,
            input: foundItem?.input,
            formGroup: eventFormGroup,
            displayValue: this.getInputDisplayValue(
              eventFormGroup.get(im.programmingName).value,
              im,
              foundItem?.input
            ),
          };

          this.setValueToExplorerMap(explorerMap, path, value);
        }
      });

    return explorerMap;
  }

  public getInputDisplayValue(value, inputModel, input?) {
    const eventTypes =
      this.simulationService.eventFormsService.getAvailableEventTypes();

    if (
      eventTypes &&
      inputModel?.programmingName == "tEV" &&
      eventTypes[value]
    ) {
      return eventTypes[value].label;
    }
    return super.getInputDisplayValue(value, inputModel, input);
  }

  public convertStartSimYearsAndDaysToDate(nYrsFromStEV, nDaysFromStEV) {
    const moment = TimeUtilities.getMoment();
    let numYear = +nYrsFromStEV || 0;
    let numDay = +nDaysFromStEV || 0;

    if (numDay > 365) {
      const yearsAdding = Math.floor(numDay / 365);
      numYear = numYear + yearsAdding;
      numDay = numDay - yearsAdding * 365;
    }
    const startDate = this.simulationService.timingService.getDate("start");
    const startDateObject = moment(startDate);
    const startYear = +startDateObject.year();
    const endYear = +startYear + numYear;

    startDateObject.add(+numYear, "years");
    startDateObject.add(+numDay, "days");

    return startDateObject.toDate();
  }

  public readXmlObject(): void {
    super.readXmlObject();
    let regimeIdCounter = 0;

    const moment = TimeUtilities.getMoment();
    const template = this.simulationService.getPlotFileJson()["EventQ"][0];
    const eventQ = template.Event;
    const headerState = template.HeaderState[0];

    if (headerState) {
      this.applyXmlValuesToFormGroup(headerState.$, this.formGroup);
    }

    if (eventQ?.length) {
      const flatMap = (obj) => {
        let formValues = {};
        Object.keys(obj).forEach((key) => {
          if (key == "$") {
            formValues = { ...formValues, ...obj[key] };
          }

          if (obj[key][0] !== null && obj[key][0] !== undefined) {
            if (typeof obj[key][0] === "object") {
              formValues = { ...formValues, ...flatMap(obj[key][0]) };
            } else {
              formValues = { ...formValues, ...{ [key]: obj[key][0] } };
            }
          }
        });
        return formValues;
      };

      eventQ.forEach((eq) => {
        let baseEventFields = { ...eq.$ };
        const eventType = baseEventFields.tEV;
        const eventFields = flatMap(eq[eventType][0]);
        const notesEV = { notesEV: eq.notesEV[0] };
        let eventDate;

        //reapply event Date
        if (baseEventFields?.dateOriginEV == "StartSim") {
          eventDate = this.convertStartSimYearsAndDaysToDate(
            baseEventFields?.nYrsFromStEV,
            baseEventFields?.nDaysFromStEV
          );
        } else {
          eventDate = eq.dateEV
            ? TimeUtilities.convertYYYYMMDDToDateFormat(eq.dateEV[0]._)
            : "";
        }

        // const eventTypeObject =
        //   this.simulationService.eventFormsService.getAvailableEventTypes()[eventType];

        //some plotfiles might contain events that don't exist in the plot type, it will be omitted
        // if (eventTypeObject) {
        baseEventFields.eventId = Utilities.uuid();
        //not sure why Doc is assigned
        if (baseEventFields.tEvent == "Doc") {
          delete baseEventFields.tEvent;
        }

        const eventService =
          this.simulationService.eventFormsService.getEventService(eventType);
        let eventFormGroup = eventService.getFormGroup();
        eventFormGroup = this.getBaseEventFormControls(eventFormGroup);
        //need to supply formModel for each eventType

        this.applyXmlValuesToFormGroup(
          {
            ...baseEventFields,
            ...eventFields,
            ...notesEV,
            ...{ dateEV: eventDate },
          },
          eventFormGroup,
          eventService.formModel
        );

        //custom fields that aren't included in the form model
        eventFormGroup.get("regimeInstance").setValue(eq.$.regimeInstance);
        eventFormGroup.get("nmRegime").setValue(eq.$.nmRegime);

        if (+eq.$.regimeInstance > regimeIdCounter) {
          regimeIdCounter = +eq.$.regimeInstance + 1;
        }

        //manually trigger watchers to reapply validations
        this.manuallyTriggerWatchingFields(eventService.fieldWatcher, {
          formGroup: eventFormGroup,
          formModel: eventService.formModel,
          layout: eventService.layout,
          simulationService: this.simulationService,
        });

        //assign eventDate for sorting if event
        // if (eventFormGroup.get("dateOriginEV").value == "StartSim") {
        //   const convertedEventDate =
        //     this.convertStartSimYearsAndDaysToEventDate(eventFormGroup);
        //   eventFormGroup.get("dateEV").setValue(convertedEventDate);
        // }
        this.getEventQ().push(eventFormGroup);
        // }
      });

      this.setRegimeIdCounter(regimeIdCounter);
    }
  }

  protected setRegimeIdCounter(counter) {
    this.regimeIdCounter = counter;
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();

    const xmlObject = {
      $: { count: 0 },
      Event: [],
      HeaderState: [{ $: {}, headSectW: ["80,270,785,270,270,0"] }],
      showEvT: ["t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,t,f"],
    };

    const headerStateFields = [
      "sortIx",
      "sortUp",
      "sortBy1",
      "sortBy2",
      "showOnlyHS",
    ];
    xmlObject.$["count"] = formData.eventQ.length;
    xmlObject.HeaderState[0].$ = this.getFormValueObjectForXmlExport(
      headerStateFields,
      formData
    );
    xmlObject.Event = formData.eventQ
      .map((evFormData) => {
        const eventType = evFormData.tEV;
        const eventTypeService =
          this.simulationService.eventFormsService.getEventService(eventType);

        const eventXmlObject = eventTypeService.writeXmlObject(evFormData);

        if (evFormData.dateEV) {
          eventXmlObject.dateEV = [
            {
              $: { CalendarSystemT: this.getCalendarSystem() },
              _: TimeUtilities.convertDateToYYYYMMDDFormat(evFormData.dateEV),
            },
          ];
        }
        //existing Fullcam behaviour
        eventXmlObject.$.tEvent = "Doc";

        return eventXmlObject;
      })
      .sort((a, b) => {
        if (a.$.onEV === b.$.onEV) {
          return 0;
        } else if (a.$.onEV == "true") {
          return 1;
        } else {
          return -1;
        }
      });
    //move disalbed events to top

    return { EventQ: [xmlObject] };
  }

  public reset(): void {
    this.availableRegimesMapSource$.next({});
    this.selectedRegimeAndEventStateSource$.next(null);
    this.eventStatus$.next("Ready");
    this.numbersOfRegimesInQueue$.next(0);
    this.numbersOfUniqueRegimes$.next(0);
    this.regimeIdCounter = 1;

    super.reset();
  }

  public isInvalid(): boolean {
    if (this.eventStatus$.getValue() !== "Ready") {
      return true;
    }

    this.validateEvents();
    return this.eventStatus$.getValue() !== "Ready";
  }
}
