<div class="row">
  <div class="drawer-container col-xl-3">
    <fc-drawer>
      <fc-output-list [service]="service"></fc-output-list>
    </fc-drawer>
  </div>

  <fc-simulation-result
    [service]="service"
    class="col-12 col-xl-9"
  ></fc-simulation-result>
</div>
