import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "fc-clone-event-form",
  templateUrl: "./clone-event-form.component.html",
  styleUrls: ["./clone-event-form.component.scss"],
})
export class CloneEventFormComponent extends BaseModalFormComponent {
  public readonly baseHelpPageUrl = environment.fullcam2020HelpUrl;
  public cloneEventHelpUrl =
    this.baseHelpPageUrl + "/" + "248_Event%20Update.htm";
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    years: new UntypedFormControl(0, [
      Validators.required,
      Validators.min(-1000),
      Validators.max(1000),
    ]),
    days: new UntypedFormControl(0, [
      Validators.required,
      Validators.min(-1000),
      Validators.max(1000),
    ]),
    repeatTimes: new UntypedFormControl(1, [
      Validators.required,
      Validators.min(0),
      Validators.max(10000),
    ]),
  });
}
