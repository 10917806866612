<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm()">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h2>
      Upload {{ simulationService.version == "rmt" ? "RMT" : "plot" }} files
    </h2>
    <form [formGroup]="formGroup">
      <div class="input-container">
        <label for="project-select" class="form-label">Project</label>
        <p *ngIf="projectCollectionFilter">
          <input
            disabled
            class="form-control form-control-sm"
            id="project-select"
            [value]="projectCollectionFilter.project"
          />
        </p>
        <fc-folder-select
          *ngIf="!projectCollectionFilter"
          id="project-select"
          [type]="'project'"
          [folders$]="folders$"
          [selectedProjectName]="formGroup.get('project')"
          [isLocked]="simulationService.isUploading$ | async"
          (onRefreshFolders)="loadFolders()"
        ></fc-folder-select>
      </div>

      <div class="input-container">
        <label for="collection-select" class="form-label">Collection</label>
        <p *ngIf="projectCollectionFilter">
          <input
            disabled
            class="form-control form-control-sm"
            id="associated-project"
            [value]="projectCollectionFilter.collection"
          />
        </p>
        <fc-folder-select
          *ngIf="!projectCollectionFilter"
          id="collection-select"
          [type]="'collection'"
          [folders$]="folders$"
          [selectedProjectName]="formGroup.get('project')"
          [selectedCollectionName]="formGroup.get('collection')"
          [isLocked]="simulationService.isUploading$ | async"
          (onRefreshFolders)="loadFolders()"
        ></fc-folder-select>
      </div>

      <div class="input-container">
        <ng-container *ngIf="!(simulationService.isUploading$ | async)">
          <label for="file-select" class="form-label">Selected files</label>

          <input
            id="file-select"
            type="file"
            multiple
            class="form-control form-control-sm"
            (change)="onFileChange($event)"
          />
        </ng-container>
        <ngx-datatable
          class="custom-table"
          [rows]="(simulationService.uploadingFiles$ | async)?.value"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="35"
          [limit]="10"
        >
          <ngx-datatable-column
            name="Name"
            [width]="350"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
          >
            <ng-template
              ngx-datatable-cell-template
              let-row="row"
              let-value="value"
            >
              {{ row.file.name }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="Size (Mb)"
            [width]="100"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
          >
            <ng-template
              ngx-datatable-cell-template
              let-row="row"
              let-value="value"
              >{{ row.file.size / 1000000 }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Progress"
            [width]="130"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
          >
            <ng-template
              ngx-datatable-cell-template
              let-row="row"
              let-value="value"
            >
              <div class="progress" *ngIf="isNumber(row.progress)">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [attr.aria-valuenow]="row.progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [style]="{ width: row.progress + '%' }"
                ></div>
              </div>
              <span *ngIf="!isNumber(row.progress)"> {{ row.progress }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name=""
            [width]="50"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="action-buttons">
                <button
                  class="btn btn-sm btn-rounded"
                  (click)="deleteFile(row)"
                  [adaptivePosition]="false"
                  tooltip="Delete"
                >
                  <span class="visually-hidden">delete</span>
                  <i class="bi bi-trash"></i>
                </button>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <ng-container *ngIf="(simulationService.uploadingFiles$ | async)?.length">
        <div
          class="upload-counter"
          *ngIf="uploadProgressCount$ | async as uploadProgressCount"
        >
          <span>Success: {{ uploadProgressCount.success }}</span
          ><span>Fail: {{ uploadProgressCount.fail }}</span>
        </div>
      </ng-container>
      <div class="form-button-group">
        <div>
          <button
            *ngIf="!(simulationService.isUploading$ | async)"
            class="btn btn-sm btn-outline-primary"
            type="button"
            (click)="resetUploadForm()"
          >
            Reset form
          </button>
        </div>
        <div>
          <button
            *ngIf="simulationService.isUploading$ | async"
            class="btn btn-sm btn-danger"
            type="button"
            (click)="cancelUpload()"
          >
            Cancel upload
          </button>

          <button
            [disabled]="shouldDisableUpload()"
            class="btn btn-sm btn-primary"
            type="submit"
            (click)="onSubmit()"
          >
            Upload file(s)
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
