<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm()">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h2>{{ formData ? "Edit " + type : "Create new " + type }}</h2>
    <form [formGroup]="formGroup">
      <div class="input-container">
        <label for="folder-name" class="form-label">Name of {{ type }}</label>

        <input
          id="folder-name"
          form-field
          type="text"
          class="form-control form-control-sm"
          formControlName="name"
          autocomplete="off"
          fcInputMask
        />
        <fc-form-error-messages
          *ngIf="
            formGroup.get('name')?.errors &&
            (formGroup.touched || formGroup.dirty)
          "
          [formControlInstance]="formGroup.get('name')"
          [label]="'Name'"
        ></fc-form-error-messages>
      </div>
      <div class="input-container">
        <label for="folder-description" class="form-label">Description</label>

        <textarea
          id="folder-description"
          class="form-control form-control-sm"
          form-field
          formControlName="description"
          rows="5"
          autocomplete="off"
          fcInputMask
        ></textarea>
        <fc-form-error-messages
          *ngIf="
            formGroup.get('description')?.errors &&
            (formGroup.touched || formGroup.dirty)
          "
          [formControlInstance]="formGroup.get('description')"
          [label]="'Description'"
        ></fc-form-error-messages>
      </div>
      <div class="input-container">
        <ng-container for="associated-project" *ngIf="type == 'collection'">
          <label class="form-label">Associated Project</label>
          <p *ngIf="lockProject; else projectSelect">
            {{ associatedProject?.name }}
          </p>
          <ng-template #projectSelect>
            <fc-folder-select
              *ngIf="!lockProject"
              id="associated-project"
              [type]="'project'"
              [folders$]="folders$"
              [selectedProjectName]="formGroup.get('projectId')"
            ></fc-folder-select>
          </ng-template>
        </ng-container>
      </div>
      <div class="form-button-group">
        <button
          type="button"
          (click)="closeForm()"
          class="btn btn-outline-primary btn-sm"
        >
          Cancel
        </button>
        <fc-button
          [disabled]="formGroup.pristine || formGroup.invalid"
          [inputText]="formData ? 'Update ' + type : 'Create ' + type"
          [isLoading$]="isLoading$()"
          [isSuccess]="isRequestSucceeded"
          [successText]="'Done'"
          (onClick)="submitFolder(formData ? 'update' : 'create')"
        >
        </fc-button>
      </div>
    </form>
  </div>
</div>
