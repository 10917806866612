import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import Utilities from "src/app/shared/utilities/utils";
import { ExplorerCatetory } from "src/app/simulation/models";
import { FormModel } from "../../../shared/models";
import { FormGroupElement, FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";

@Injectable({
  providedIn: "root",
})
export class Explorer2020Service extends BasePlotFormService {
  public pageId = "explorer";
  public readonly pageHelpLink = "234_Explorer.htm";

  public selectedCategoryId$ = new BehaviorSubject<string>(null);
  public selectedCategoryKey$ = new BehaviorSubject<{
    categoryKey: string;
    controlLabel: string;
  }>(null);

  public modifiers: FormGroupElement[] = [];

  public readonly layout: FormLayout = {
    label: "Explorer",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: null,
                element: "component",
                component: "ExplorerContainerComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {};

  //used to record expanded state of the category
  public expandedStateMap: {
    [key: string]: boolean | { [key: string]: boolean };
  } = {};

  public getExplorerLayoutMap(): ExplorerCatetory[] {
    const excludedPages = ["plotFiles", "outputWindows", "log", "explorer"];
    const explorerCategoryList = this.simulationService.currentFlowsSource$
      .getValue()
      ?.filter((f) => f.isShown && !excludedPages.includes(f.id))
      .map((f) => {
        return {
          ...f,
          //shouldDisplay: true,
          isExpanded: false,
          subcategoryMap: {},
        };
      });
    // console.log(this.explorerCategoryList);
    return explorerCategoryList;

    //filterout pages not related to explorer
  }
  public setSelectedCategoryId(id: string) {
    this.selectedCategoryId$.next(id);
  }
  public setSelectedCategoryKey(data) {
    this.selectedCategoryKey$.next(data);
  }

  public setExpandedState(path, isExpanded) {
    Utilities.setValueToMap(this.expandedStateMap, path, isExpanded);
  }

  public getExpandedState(path): boolean {
    return Utilities.getValueFromMap(this.expandedStateMap, path);
  }

  public reset(): void {
    this.selectedCategoryId$.next(null);
    this.selectedCategoryKey$.next(null);
    this.expandedStateMap = {};
    super.reset();
  }
}
