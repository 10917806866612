import { FullcamVersionCheckResult, PlotDocumentType } from "../models";

const DOC_VERSION_TO_YEAR_MAP = {
  5007: "2020",
  5009: "2024",
};

const TREE_2020_FIELDS = ["turnFracBranF", "turnFracBarkF", "turnFracLeafF"];

const TREE_2024_BRANCH_FIELDS = [
  "turnFracBranF01",
  "turnFracBranF02",
  "turnFracBranF03",
  "turnFracBranF04",
  "turnFracBranF05",
  "turnFracBranF06",
  "turnFracBranF07",
  "turnFracBranF08",
  "turnFracBranF09",
  "turnFracBranF10",
  "turnFracBranF11",
  "turnFracBranF12",
];
const TREE_2024_BARK_FIELDS = [
  "turnFracBarkF01",
  "turnFracBarkF02",
  "turnFracBarkF03",
  "turnFracBarkF04",
  "turnFracBarkF05",
  "turnFracBarkF06",
  "turnFracBarkF07",
  "turnFracBarkF08",
  "turnFracBarkF09",
  "turnFracBarkF10",
  "turnFracBarkF11",
  "turnFracBarkF12",
];

const TREE_2024_LEAF_FIELDS = [
  "turnFracLeafF01",
  "turnFracLeafF02",
  "turnFracLeafF03",
  "turnFracLeafF04",
  "turnFracLeafF05",
  "turnFracLeafF06",
  "turnFracLeafF07",
  "turnFracLeafF08",
  "turnFracLeafF09",
  "turnFracLeafF10",
  "turnFracLeafF11",
  "turnFracLeafF12",
];

const TREE_2024_FIELDS = [
  ...TREE_2024_BRANCH_FIELDS,
  ...TREE_2024_BARK_FIELDS,
  ...TREE_2024_LEAF_FIELDS,
];

const THIN_2024_FIELDS = [
  "yrsStemRegrowThin",
  "yrsBarkRegrowThin",
  "yrsBranRegrowThin",
  "yrsLeafRegrowThin",
  "yrsCortRegrowThin",
  "yrsFirtRegrowThin",
]; //0.0

const PLOW_2024_FIELDS = [
  "impactMultiPlow",
  "sPDecayMultiPlow",
  "durationPlow",
  "dTFracGbfrSoilA",
  "dTFracStlkSoilA",
  "dTFracLeafSoilA",
  "dTFracCortSoilA",
  "dTFracFirtSoilA",
];
//1.0

export default class FullcamVersionUtilities {
  static getFullcamYearByDocVersion(docVersion: string): string {
    return DOC_VERSION_TO_YEAR_MAP[docVersion];
  }

  static getDocVersionByFullcamYear(year: string): number | undefined {
    for (const [docVersion, docYear] of Object.entries(
      DOC_VERSION_TO_YEAR_MAP
    )) {
      if (docYear === year) {
        return parseInt(docVersion);
      }
    }
    return undefined; // Explicitly return undefined if no matching year is found
  }

  static checkVersion(
    docVersion: number,
    currentYear: string
  ): FullcamVersionCheckResult | null {
    const docYear = this.getFullcamYearByDocVersion(String(docVersion));
    if (docYear === currentYear) {
      return null; // No action needed if versions match
    }

    const requiredDocVersion = this.getDocVersionByFullcamYear(currentYear);
    if (!requiredDocVersion) {
      return null; // Return null if no required doc version is found (could also consider throwing an error)
    }

    return {
      type: docVersion > requiredDocVersion ? "revert" : "update",
      docVersion: requiredDocVersion,
      previousDocVersion: docVersion,
      previousVersion: docYear,
      version: currentYear,
    };
  }

  //int Doc::RdDO(Doc* d, bool showMsg)
  //Doc.cpp 2186
  //	fpiAvgLT = (sqrt(z.maxAbgMF) + 5.2912) / 6.0109;
  //fpiAvgLT *= fpiAvgLT;
  //

  static updateVersion(
    data: FullcamVersionCheckResult,
    plotFileJson: Object,
    docType: PlotDocumentType
  ) {
    let updatedPlotFileJson = plotFileJson;

    if (docType !== "DocumentEstate") {
      updatedPlotFileJson = this.proccessPloPld(data, plotFileJson, docType);
    }

    updatedPlotFileJson = this.alterDocTag({
      docVersion: data.docVersion,
      docType: docType,
      plotFileJson: plotFileJson,
    });

    return updatedPlotFileJson;
  }

  private static proccessPloPld(
    data: FullcamVersionCheckResult,
    plotFileJson: Object,
    docType: PlotDocumentType
  ) {
    let updatedPlotFileJson = plotFileJson;

    if (data.version == "2024") {
      if (data.previousVersion == "2020") {
        //remove old tree fields
        updatedPlotFileJson = this.alterSpeciesFields({
          operation: "remove",
          fields: TREE_2020_FIELDS,
          value: null,
          docType: docType,
          plotFileJson: plotFileJson,
        });
        //add new tree fields
        updatedPlotFileJson = this.alterSpeciesFields({
          operation: "add",
          fields: TREE_2024_FIELDS,
          value: "0.0",
          docType: docType,
          plotFileJson: plotFileJson,
        });
        //add new event fields
        updatedPlotFileJson = this.alterEventFields({
          operation: "add",
          thinFields: THIN_2024_FIELDS,
          thinValue: "0.0",
          plowFields: PLOW_2024_FIELDS,
          plowValue: "1.0",
          docType: docType,
          plotFileJson: plotFileJson,
        });
      }
    } else if (data.version == "2022") {
      if (data.previousVersion == "2024") {
        //remove old tree fields
        updatedPlotFileJson = this.alterSpeciesFields({
          operation: "remove",
          fields: TREE_2024_FIELDS,
          value: null,
          docType: docType,
          plotFileJson: plotFileJson,
        });
        //add new tree fields
        updatedPlotFileJson = this.alterSpeciesFields({
          operation: "add",
          fields: TREE_2020_FIELDS,
          value: null,
          docType: docType,
          plotFileJson: plotFileJson,
        });
        //add new event fields
        updatedPlotFileJson = this.alterEventFields({
          operation: "remove",
          thinFields: THIN_2024_FIELDS,
          thinValue: null,
          plowFields: PLOW_2024_FIELDS,
          plowValue: null,
          docType: docType,
          plotFileJson: plotFileJson,
        });
      }
    }

    const forestProdIx = this.getForestProdIx(updatedPlotFileJson, docType);
    if (forestProdIx) {
      updatedPlotFileJson = this.calcFPIAvgLT(
        updatedPlotFileJson,
        forestProdIx,
        docType
      );
    }

    return updatedPlotFileJson;
  }

  private static alterDocTag(data: {
    docVersion: number;
    docType: "DocumentPlot" | "DocumentPlotDigest" | "DocumentEstate";
    plotFileJson: any;
  }) {
    let alteredJson = data.plotFileJson;
    alteredJson[data.docType].$.Version = data.docVersion;
    alteredJson[data.docType].$[
      "xsi:noNamespaceSchemaLocation"
    ] = `http://www.fullcam.com/XML/${data.docType}${data.docVersion}.xsd`;

    return alteredJson;
  }

  private static alterSpeciesFields(data: {
    operation: "add" | "remove";
    fields: string[];
    value?: any;
    docType: "DocumentPlot" | "DocumentPlotDigest" | "DocumentEstate";
    plotFileJson: any;
  }) {
    let alteredJson = data.plotFileJson;
    const speciesForestSet = alteredJson[data.docType]?.SpeciesForestSet;
    if (speciesForestSet && speciesForestSet.length > 0) {
      const species = speciesForestSet[0].SpeciesForest || [];
      species.forEach((specie: any) => {
        if (data.operation == "add") {
          data.fields.forEach((field) => {
            specie.$[field] = data.value;
          });
        } else if (data.operation == "remove") {
          data.fields.forEach((field) => {
            specie.$[field] = delete specie.$[field];
          });
        }
      });
    }

    return alteredJson;
  }

  private static alterEventFields(data: {
    operation: "add" | "remove";
    thinFields: string[];
    thinValue?: any;
    plowFields: string[];
    plowValue?: any;
    docType: "DocumentPlot" | "DocumentPlotDigest" | "DocumentEstate";
    plotFileJson: any;
  }) {
    let alteredJson = data.plotFileJson;
    let allEvents = [];

    const eventQ = alteredJson[data.docType]?.EventQ;

    if (eventQ && eventQ.length > 0) {
      const events = eventQ[0].Event;
      if (events?.length) {
        allEvents.push(...events);
      }
    }

    const speciesForestSet = alteredJson[data.docType]?.SpeciesForestSet;
    if (speciesForestSet && speciesForestSet.length > 0) {
      const species = speciesForestSet[0].SpeciesForest || [];
      species.forEach((specie: any) => {
        const eventQ = specie.EventQ;
        if (eventQ && eventQ[0].length > 0) {
          const events = eventQ[0].Event;
          if (events?.length) {
            allEvents.push(...events);
          }
        }
      });
    }

    const speciesAgricultureSet =
      alteredJson[data.docType]?.SpeciesAgricultureSet;
    if (speciesAgricultureSet && speciesAgricultureSet.length > 0) {
      const species = speciesAgricultureSet[0].SpeciesAgriculture || [];

      species.forEach((specie: any) => {
        const eventQ = specie.EventQ;
        if (eventQ && eventQ[0].length > 0) {
          const events = eventQ[0].Event;
          if (events?.length) {
            allEvents.push(...events);
          }
        }
      });
    }

    allEvents.forEach((event: any) => {
      if (event.Thin && event.Thin.length > 0) {
        const thinObject = event.Thin[0].$;
        if (data.operation == "add") {
          data.thinFields.forEach((field) => {
            thinObject[field] = data.thinValue;
          });
        } else if (data.operation == "remove") {
          data.thinFields.forEach((field) => {
            thinObject[field] = delete thinObject[field];
          });
        }
      }

      if (event.Plow && event.Plow.length > 0) {
        const plowObject = event.Plow[0].$;
        if (data.operation == "add") {
          data.plowFields.forEach((field) => {
            plowObject[field] = data.plowValue;
          });
        } else if (data.operation == "remove") {
          data.plowFields.forEach((field) => {
            plowObject[field] = delete plowObject[field];
          });
        }
      }
    });

    return alteredJson;
  }

  private static getForestProdIx(plotFileJson: any, docType: PlotDocumentType) {
    const site = plotFileJson[docType]?.Site;
    if (!site) {
      return null;
    }
    const timeseries = site[0].TimeSeries;

    const forestProdIx = timeseries.find(
      (ts) => ts?.$?.tInTS == "forestProdIx"
    );

    return forestProdIx;
  }

  private static calcFPIAvgLT(plotFileJson, forestProdIx, docType) {
    let updatedPlotFileJson = plotFileJson;

    if (!updatedPlotFileJson[docType]?.Site[0]?.$?.fpiAvgLT) {
      return plotFileJson;
    }

    const fpi = forestProdIx.rawTS[0]._?.split(",");
    const multTS = +forestProdIx.$.multTS;
    let fpiAvgLT = 0;
    let num = 0;
    let len = fpi.length;
    if (len > 48) {
      //48th year from 1970 is 2017
      len = 48; //48th year from 1970 is 2017
    }
    for (let i = 0; i < len; i += 1) {
      if (+fpi[i]) {
        fpiAvgLT += +fpi[i];
        num += 1;
      }
    }
    fpiAvgLT *= multTS;
    fpiAvgLT /= num;

    updatedPlotFileJson[docType].Site[0].$.fpiAvgLT = fpiAvgLT;
    return updatedPlotFileJson;
  }
}
