<ng-container *ngIf="flows?.length">
  <div class="floating-button-group">
    <button
      tooltip="Download Reforestation Abatement Calculator"
      (click)="downloadRACalculator()"
      type="button"
      class="btn btn-primary-darken btn-sm"
    >
      Reforestation Abatement Calculator
    </button>

    <fc-button
      [tooltip]="
        shouldDisableSave() ? 'The Project or Management page is not valid' : ''
      "
      class="download-button"
      [disabled]="shouldDisableSave()"
      [inputText]="isEditRMD() ? 'Update RMD File' : 'Save RMD File'"
      [isLoading$]="isLoading$()"
      (onClick)="saveRMDFile()"
    >
    </fc-button>
  </div>
  <div class="row">
    <div class="col-12">
      <div
        class="multisteps-form-progress"
        [class]="{ expanded: isFlowStepsExpanded }"
      >
        <fc-simulation-nav
          [selectedItem]="getSelectedFlow()"
          [items]="flows"
          (itemSelected)="setSelectedFlowPage($event)"
          (nextFlowAccessAllowed)="setCanNavigateNextFlow($event)"
        ></fc-simulation-nav>
      </div>
    </div>
  </div>
  <!--form panels-->
  <div class="row multisteps-form">
    <!-- <div class="col-lg-9 col-md-12"> -->
    <ng-template #flowContentHost></ng-template>
    <!-- </div> -->
  </div>

  <fc-fly-over-panel></fc-fly-over-panel>
</ng-container>
