import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { FormLayout } from "src/app/plot/models";

import { FieldWatcher, FormModel } from "src/app/shared/models";
import { BehaviorSubject } from "rxjs";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

@Injectable({
  providedIn: "root",
})
export class TreePlanting2020Service extends BaseEventFormService {
  public readonly categoryIds = [72, 71];
  public readonly pageHelpLink = "158_Plant%20Trees.htm";

  public readonly selectedSpeciesId$ = new BehaviorSubject<number>(-1); //for placeholder select

  public layout: FormLayout = {
    label: "Plant Trees",
    groups: [
      {
        showErrors: true,
        label: "Species",
        id: "species-label-group",
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Species",
            inputs: [
              {
                element: "text",
                method: "getSelectedSpeciesName",
                payload: "tree",
              },
            ],
          },
        ],
      },
      {
        label: null,
        id: "species-selector-group",
        isShown: false,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SpeciesSelectorComponent",
                componentInputs: [
                  {
                    inputKey: "formGroupInstance",
                    method: "getSpeciesFormGroup",
                    payload: "tree",
                  },
                  {
                    inputKey: "selectedId$",
                    variable: "selectedSpeciesId$",
                    isObservable: true,
                  },
                  {
                    inputKey: "selectOnly",
                    value: true,
                  },
                  {
                    inputKey: "type",
                    value: "tree",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "speciesSelected",
                    method: "selectSpecies",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isShown: true,
        label: null,
        showErrors: true,
        items: [
          {
            label: "Specify tree size by",
            inputs: [
              {
                element: "select",
                selectOptions: [
                  {
                    value: "Mass",
                    label: "Masses",
                  },
                  {
                    value: "Vol",
                    label: "Volume for stems, masses for other components",
                  },
                ],
                programmingName: "tStemPlnF",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        id: "tree-planting-tree-mass",
        label: "Masses [dmt/ha]",
        isUnderlineHeader: true,
        isUnderlineItems: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "FormItemTableComponent",
                componentInputs: [
                  {
                    inputKey: "caption",
                    value: "Masses [dmt/ha]",
                  },
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      headers: [],
                      rows: [
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Stems",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "stemMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Branches",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "branMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Bark",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "barkMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Leaves",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "leafMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Coarse roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "cortMPlnF",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "Fine roots",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "firtMPlnF",
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isShown: true,
        label: "Volumes [m3/ha]",
        id: "tree-planting-tree-vol",
        isUnderlineHeader: true,
        isUnderlineItems: true,
        showErrors: true,
        items: [
          {
            label: "Stems",
            shoartLabel: "",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "stemVolPlnF",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        label: "Ages [yr]",
        isUnderlineHeader: true,
        showErrors: true,
        items: [
          {
            label: "Trees",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "agePlnF",
              },
            ],
          },
        ],
      },
      {
        isShown: true,
        id: "tyf-growth-calibration",
        label: "Tree yield formula: Growth calibration",
        showErrors: true,
        items: [
          {
            label: "Growth calibration",
            inputs: [
              {
                element: "select",
                selectOptions: [
                  {
                    value: "Custom",
                    label: "User defined",
                  },
                  {
                    value: "BeltH",
                    label: "Belt plantings >1500 sph",
                  },
                  {
                    value: "BeltL",
                    label: "Belt plantings <1500 sph",
                  },
                  {
                    value: "BlockES",
                    label: "Block - environmental services",
                  },
                  {
                    value: "BlockLMG",
                    label: "Block - regeneration systems",
                  },
                  {
                    value: "Water",
                    label: "In riparian or floodplain zones",
                  },
                  {
                    value: "Short",
                    label: "Short rotation plantations",
                  },
                  {
                    value: "Long",
                    label: "Long rotation plantations",
                  },
                ],
                programmingName: "tTYFCat",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecF",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    agePlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Age of trees when planted",
      unit: "yr",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 614,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemVolPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Volume of stems",
      unit: "m3/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 615,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "branches",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 616,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "bark",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 617,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "leaves",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 618,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cortMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "coarse roots",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 619,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    firtMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fine roots",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 620,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 1,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 621,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 2,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 622,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 3,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 623,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 4,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 624,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    cortNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 5,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 625,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    firtNCRatioPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 6,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 626,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    storNMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in plant store",
      unit: "kgN/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 627,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeNmPlnF: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Name of tree species",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 1133,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tStemPlnF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: "Mass",
      validators: [],
      validatorConfig: [],
      label: "Method of specifying sizes of components of planted trees",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "InitStemT",
      eventId: 6,
      tIn: 1144,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMPlnF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems",
      unit: "dmt/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 1145,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fixPlnF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree planting event",
      unit: "$",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 2050,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    phaPlnF: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "tree planting event",
      unit: "$/ha",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 2051,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    unused2119: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "unused 2119",
      unit: "yr",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 7,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 6,
      tIn: 2119,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tTYFCatInitF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Initial Growth Calibration ",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TYFCatT",
      eventId: 10,
      tIn: 2120,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tTYFCat: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Growth Calibration",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TYFCatT",
      eventId: 6,
      tIn: 2121,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "PlnF",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
  };

  public fieldWatcher: FieldWatcher = {
    tStemPlnF: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const volGroup = this.getLayoutGroup(
        this.layout.groups,
        "tree-planting-tree-vol"
      );

      const isVol = newValue == "Vol";
      volGroup.isShown = isVol;

      this[
        isVol ? "addDefaultValidatorsInBulk" : "removeDefaultValidatorsInBulk"
      ](["stemVolPlnF"], formGroup, formModel);

      formModel["stemMPlnF"].isShown = !isVol;

      this[
        isVol ? "removeDefaultValidatorsInBulk" : "addDefaultValidatorsInBulk"
      ](["stemMPlnF"], formGroup, formModel);
    },
  };

  public selectSpecies({ id, type, name }) {
    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByIdRegimeSP(
      "tree",
      id
    );

    if (!selectedSpeciesFormGroup) {
      return;
    }

    const tyfGroup = this.getLayoutGroup(
      this.layout.groups,
      "tyf-growth-calibration"
    );

    //trees downloaded from db will have their own tyf categories
    if (name !== null) {
      const tyfCategories =
        this.simulationService.treesService.getTYFCategoryBySpeciesNmSP(name);

      if (tyfCategories) {
        tyfGroup.items[0].inputs[0].selectOptions = tyfCategories;
      }
    }

    this.selectedSpeciesId$.next(id);
    //Event is using app idSP instead idRegimeSP
    //EventQ in Events page species idSP = app idSP
    //EventQ in its own species idSP= idRegimeSP
    this.formGroup
      .get("idSP")
      .setValue(
        selectedSpeciesFormGroup
          ? selectedSpeciesFormGroup.get("idSP").value
          : null
      );
    this.formGroup.get("treeNmPlnF").setValue(name);

    this.formGroup
      .get("nmEV")
      .setValue(
        this.simulationService.eventFormsService.getEventDescription(
          this.formGroup.getRawValue()
        )
      );

    //reset selection
    setTimeout(() => {
      this.formGroup
        .get("tTYFCat")
        .setValue(this.formGroup.get("tTYFCat").value);
    }, 0);
  }

  public setEventPageLogic(formData, isEventPage) {
    super.setEventPageLogic(formData, isEventPage);

    const selectedSpeciesFormGroup = this.getSpeciesFormGroupByName(
      "tree",
      formData?.treeNmPlnF
    );

    if (!isEventPage) {
      this.formGroup
        .get("treeNmPlnF")
        .setValue(selectedSpeciesFormGroup.get("nmSP").value);

      //manually created tree
      if (selectedSpeciesFormGroup.get("idRegimeSP").value === null) {
        const tyfGroup = this.getLayoutGroup(
          this.layout.groups,
          "tyf-growth-calibration"
        );
        tyfGroup.items[0].inputs[0].selectOptions =
          tyfGroup.items[0].inputs[0].selectOptions.filter(
            (o) => o.value == "Custom"
          );
      }
      return;
    }

    if (isEventPage) {
      this.addValidatorsInBulk(
        ["treeNmPlnF"],
        [Validators.required],
        this.getFormGroup()
      );
      this.minimumRequiredFields = [
        ...this.baseMinimumRequiredFields,
        {
          field: "treeNmPlnF",
          label: "Species",
        },
      ];
    }

    const speciesLabelGroup = this.getLayoutGroup(
      this.layout.groups,
      "species-label-group"
    );
    const speciesSelectorGroup = this.getLayoutGroup(
      this.layout.groups,
      "species-selector-group"
    );

    speciesLabelGroup.isShown = false;
    speciesSelectorGroup.isShown = true;
    //skip selecting a species if its a new form
    if (!formData.eventId) {
      return;
    }
    const name = this.formGroup.get("treeNmPlnF").value;

    this.selectSpecies({
      id: selectedSpeciesFormGroup
        ? selectedSpeciesFormGroup.get("idRegimeSP").value
        : -1,
      type: null,
      name: name,
    }); //apply species id so selector will work
  }

  public insertStandardValues(result: any, formData: any): void {
    //EventQ in Events page species idSP = app idSP
    //EventQ in its own species idSP= idRegimeSP

    //some templates dont have correct idRegimeSP
    const id = this.simulationService.treesService.getSpeciesByName(
      result.speciesName
    )?.idRegimeSP;

    this.selectSpecies({ id: id, type: "tree", name: result.speciesName });
    //TYF menu will be regenerated after species selected, form data has to apply after that
    this.formGroup.patchValue(formData);
    if (result.isInertEventName) {
      this.formGroup.get("nmEV").setValue(result.eventName);
    }
  }

  public writeXmlObject(formData): object {
    const basePlnFFields = [
      "tStemPlnF",
      "agePlnF",
      "stemVolPlnF",
      "stemMPlnF",
      "branMPlnF",
      "barkMPlnF",
      "leafMPlnF",
      "cortMPlnF",
      "firtMPlnF",
      "stemNCRatioPlnF",
      "branNCRatioPlnF",
      "barkNCRatioPlnF",
      "leafNCRatioPlnF",
      "cortNCRatioPlnF",
      "firtNCRatioPlnF",
      "storNMPlnF",
      "fixPlnF",
      "phaPlnF",
      "tTYFCat",
      "treeNmPlnF",
    ];

    const xmlObject = {
      $: {},
      notesEV: [],
      PlnF: [{ $: {} }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.PlnF[0].$ = this.getFormValueObjectForXmlExport(
      basePlnFFields,
      formData
    );

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
