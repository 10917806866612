import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "fc-confirm-with-comment-modal",
  templateUrl: "./confirm-with-comment-modal.component.html",
  styleUrls: ["./confirm-with-comment-modal.component.scss"],
})
export class ConfirmWithCommentModalComponent implements OnInit {
  @Output() action = new EventEmitter();
  public comment: string = "";
  public message: string;

  constructor() {}

  ngOnInit(): void {}

  public confirm(value: boolean, comment: string) {
    this.action.emit({ confirm: value, comment: comment });
  }
}
