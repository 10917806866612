import { Injectable } from "@angular/core";
import { AlertComponent } from "ngx-bootstrap/alert";
import { BehaviorSubject } from "rxjs";

export interface AlertMessage {
  type: "success" | "danger" | "warning" | "info";
  msg: string;
  timeout?: number;
  dismissible?: boolean;
}
@Injectable({
  providedIn: "root",
})
export class MessageService {
  public alerts: AlertMessage[] = [];
  public isFullScreenLoading$ = new BehaviorSubject<boolean>(false);
  public isSimulationRunning$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  addAlert(alert: AlertMessage): void {
    this.alerts.push({ dismissible: true, ...alert });
  }

  onClosed(dismissedAlert: AlertComponent): void {
    //this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  public setFullScreenLoading(isLoading: boolean) {
    this.isFullScreenLoading$.next(isLoading);
  }

  public setIsSimulationRunning(isLoading: boolean) {
    this.isSimulationRunning$.next(isLoading);
  }

  public removeAllLoading() {
    this.setFullScreenLoading(false);
    this.setIsSimulationRunning(false);
  }
}
