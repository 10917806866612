import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { take, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject, lastValueFrom, Observable } from "rxjs";
import { ModalOptions } from "ngx-bootstrap/modal";
import {
  DataTablePage,
  FolderList,
  PlotTableColumns,
} from "src/app/my-plots/models";
import { Constants } from "src/app/shared/constants";
import { ModalService } from "src/app/shared/services/modal.service";
import { MessageService } from "src/app/shared/services/message.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { LayoutService } from "src/app/layout/layout.service";
import { AppService } from "src/app/services/app.service";
import { PlotUploadComponent } from "src/app/shared/components/plot-upload/plot-upload.component";
import { RmtSimulationService } from "../../simulation/services/rmt-simulation.service";
import { MyRmdService } from "../services/my-rmd.service";

@Component({
  selector: "fc-my-rmds",
  templateUrl: "./my-rmds.component.html",
  styleUrls: ["./my-rmds.component.scss"],
})
export class MyRmdsComponent implements OnInit, AfterViewInit {
  private readonly roleSub$ = new Subject<void>();

  @Input()
  selectedPlotFiles = [];

  @Output()
  public plotIdSeleceted = new EventEmitter<string>();

  //for multiple files select
  @Output()
  public plotFilesSeleceted = new EventEmitter<PlotTableColumns[]>();

  @ViewChild("plotTable") plotTable: DatatableComponent;

  public readonly defaultPage: DataTablePage = {
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    offset: 0,
  };
  public forceFilter = false;

  public isLoading: boolean = false;
  public isBulkActionLoading$ = new BehaviorSubject<boolean>(false);
  public selectedBulkAction: "delete" | "export" = "export";
  public selectedPlotTypeFilter: "all" | "plo" | "pld" | "est" = "all";

  public page = new DataTablePage();
  public selectedRows: PlotTableColumns[] = [];
  public rows: PlotTableColumns[] = [];

  public folders$: Observable<FolderList[]>;

  public statusFilter: "complete" | "pending" | null = null;

  public projectCollectionFilter: {
    collection: string;
    collectionId: string;
    project: string;
    projectId: string;
  } = null;

  public searchString: string = "";
  public searchFilter: string = "";

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  public readonly acceptedFileTypes: string = Constants.PLOT_FORMATS.join(",");

  constructor(
    private modalService: ModalService,
    private messageService: MessageService,
    private authService: AuthService,
    public layoutService: LayoutService,
    public appService: AppService,
    public simulationService: RmtSimulationService,
    public myRmdService: MyRmdService
  ) {
    this.page = { ...this.defaultPage };
  }

  async ngOnInit(): Promise<void> {
    this.folders$ = this.simulationService.projectService.getFolders();

    this.authService.userRole$
      .pipe(takeUntil(this.roleSub$))
      .subscribe(async (role) => {
        if (role) {
          await this.getFolders();
          this.roleSub$.next();
          this.roleSub$.complete();
        }
      });
  }

  ngAfterViewInit(): void {
    this.layoutService.forceUpdateLayoutSizes();
  }

  private async getFolders(): Promise<void> {
    await this.simulationService.projectService.loadFolders();
    this.filterPlotFiles();
    // this.myPlotsService
    //   .getList("folder")
    //   .pipe(take(1))
    //   .subscribe((f) => {
    //     if (f) {
    //       this.simulationService.aboutService.setFolders(f);
    //       this.folders = f;
    //       this.filterPlotFiles();
    //     }
    //   });
    //
  }

  public setProjectCollectionFilter(event): void {
    if (
      event.parent?.projectId == this.projectCollectionFilter?.projectId &&
      event.collectionId == this.projectCollectionFilter?.collectionId
    ) {
      return;
    }

    this.projectCollectionFilter = {
      project: event.parent.name,
      projectId: event.parent.projectId,
      collection: event.name,
      collectionId: event.collectionId,
    };

    this.filterPlotFiles();
  }

  public removeProjectCollectionFilter(): void {
    this.projectCollectionFilter = null;
    this.filterPlotFiles();
  }

  public setSearchString(): void {
    if (this.searchString.length < 2) {
      return;
    }

    this.searchFilter = this.searchString;
    this.searchString = "";
    this.filterPlotFiles();
  }

  public removeSearchFilter(): void {
    this.searchFilter = "";
    this.filterPlotFiles();
  }

  public filterPlotFiles(pageInfo?) {
    //reset page
    this.page = { ...this.defaultPage, ...pageInfo };

    this.setPage(null, {
      ...(this.projectCollectionFilter && {
        projectId: this.projectCollectionFilter.projectId,
      }),
      ...(this.projectCollectionFilter && {
        collectionId: this.projectCollectionFilter.collectionId,
      }),

      ...(this.searchFilter?.length > 1 && { search: this.searchFilter }),
      ...(this.statusFilter && {
        status: this.statusFilter,
      }),
      ...(this.selectedPlotTypeFilter !== "all" && {
        plotType: this.selectedPlotTypeFilter,
      }),
    });
  }

  public async deleteFolder(row): Promise<void> {
    const type = row.collectionId ? "collection" : "project";
    const folderMessage =
      type == "collection"
        ? "collection and ALL the plot files under this folder"
        : "project and ALL the collections and plot files under this folder";
    const message = `Are you sure, you want to delete '${row.name}' ${folderMessage}?`;
    const confirm = await this.modalService.openConfirmModal(message);

    if (confirm) {
      this.myRmdService
        .deleteFolders(type, [
          String(row[type == "collection" ? "collectionId" : "projectId"]),
        ])
        .pipe(take(1))
        .subscribe(async () => {
          await this.getFolders();
        });
    }
  }

  public setPage(pageInfo?, filters?) {
    if (pageInfo) {
      this.page = pageInfo;
    }

    this.myRmdService
      .getList("rmd", {
        pageSize: this.page.pageSize,
        pageNumber: this.page.offset + 1,
        ...filters,
      })
      .pipe(take(1))
      .subscribe((response) => {
        if (!response) {
          this.messageService.addAlert({
            type: "danger",
            msg: "Something went wrong while getting the plot files.",
            dismissible: true,
          });
          return;
        }
        this.rows = response.data;
        this.page = response.page;
      });
  }

  public onSelect({ selected }): void {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.plotTable.selected = [];
  }

  public onActivate(event) {}

  public async editPlotFile(row: PlotTableColumns): Promise<void> {
    this.messageService.setFullScreenLoading(true);
    const rmdFileResults = await lastValueFrom(
      this.myRmdService.downloadPlotFiles([row.plotId]).pipe(take(1))
    );
    const rmdFileString = rmdFileResults[0].result;

    if (rmdFileString) {
      const blob = new Blob([rmdFileString], { type: "text/plain" });
      let rmdFile = new File([blob], row.fileName, {
        type: "text/plain",
      });

      setTimeout(async () => {
        const rmdFileJson = await lastValueFrom(
          this.simulationService.checkRMDEncryption(rmdFile).pipe(take(1))
        );
        this.simulationService.projectService
          .getFormGroup()
          .get("project")
          .setValue(row.project);
        this.simulationService.projectService
          .getFormGroup()
          .get("collection")
          .setValue(row.collection);

        this.simulationService.setRMDMetaData(row);

        this.simulationService.processImportedRMDFile(rmdFileJson);
        this.messageService.setFullScreenLoading(false);
      }, 100);
    }
  }

  public async clonePlotFile(row: PlotTableColumns): Promise<void> {
    this.messageService.setFullScreenLoading(true);
    const rmdFileResults = await lastValueFrom(
      this.myRmdService.downloadPlotFiles([row.plotId]).pipe(take(1))
    );
    const rmdFileString = rmdFileResults[0].result;

    if (rmdFileString) {
      const blob = new Blob([rmdFileString], { type: "text/plain" });
      let rmdFile = new File([blob], row.fileName, {
        type: "text/plain",
      });

      setTimeout(async () => {
        const rmdFileJson = await lastValueFrom(
          this.simulationService.checkRMDEncryption(rmdFile).pipe(take(1))
        );
        //modify the plot file name after cloned
        const standName =
          rmdFileJson[this.simulationService.docType]?.NCATStand
            ?.ProjectInformation?.StandName;

        if (standName) {
          const createTime =
            this.simulationService.helperService.getTodayDateTimeForFileName();
          rmdFileJson[
            this.simulationService.docType
          ].NCATStand.ProjectInformation.StandName =
            standName + " cloned at " + createTime;
        }

        this.simulationService.projectService
          .getFormGroup()
          .get("project")
          .setValue(row.project);
        this.simulationService.projectService
          .getFormGroup()
          .get("collection")
          .setValue(row.collection);

        this.simulationService.isClonedRMDFile = true;
        this.simulationService.setRMDMetaData(row);
        this.simulationService.processImportedRMDFile(rmdFileJson);
        this.messageService.setFullScreenLoading(false);
      }, 100);
    }
  }

  public async deletePlotFiles(rows: PlotTableColumns[]): Promise<void> {
    const fileNames =
      rows.length == 1 ? rows[0].fileName : rows.length + " plot files";
    const ids = rows.map((r) => String(r.plotId));
    const message = `Are you sure, you want to delete ${fileNames}?`;
    const confirm = await this.modalService.openConfirmModal(message);

    if (confirm) {
      this.myRmdService
        .deletePlotFiles(ids)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isBulkActionLoading$.next(false);
            this.filterPlotFiles();
            this.deselectAll();
          },
          error: (error) => {
            this.messageService.addAlert({
              type: "danger",
              msg: "Something went wrong while deleting files, please try again later.",
              dismissible: true,
            });
            this.isBulkActionLoading$.next(false);
          },
        });
    }
  }

  public bulkAction() {
    this.isBulkActionLoading$.next(true);
    const ids = this.selectedRows.map((row) => String(row.plotId));
    if (this.selectedBulkAction == "delete") {
      this.deletePlotFiles(this.selectedRows);
    } else if (this.selectedBulkAction == "export") {
      this.myRmdService
        .downloadPlotFiles(ids)
        .pipe(take(1))
        .subscribe((xmlPlotsResults) => {
          this.isBulkActionLoading$.next(false);
          xmlPlotsResults.forEach((xmlPlotResult, index) => {
            const plotRow = this.selectedRows[index];
            this.simulationService.helperService.downloadFile(
              plotRow.fileName,
              xmlPlotResult.result,
              plotRow.fileType
            );
          });
          this.deselectAll();
        });
    }
  }

  public uploadRMDFile() {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        projectCollectionFilter: this.projectCollectionFilter,
        folders$: this.folders$,
      },
    };

    this.modalService
      .openModal(PlotUploadComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe(() => {
        // if (!this.simulationService.isUploading$.getValue()) {
        //   this.simulationService.resetFileUpload();
        // }
        this.filterPlotFiles();
      });
  }

  public async onFileSelected(event) {
    const file = event.target.files[0];

    if (file) {
      this.messageService.setFullScreenLoading(true);

      setTimeout(async () => {
        const plotFileJson = await lastValueFrom(
          this.simulationService.checkRMDEncryption(file).pipe(take(1))
        );
        this.simulationService.isClonedRMDFile = true;
        this.simulationService.processImportedRMDFile(plotFileJson);
        this.messageService.setFullScreenLoading(false);
      }, 100);
    }
  }

  //override selectFn
  public selectPlotFilesOnlySelectFn(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const selectedPlotIds = this.selectedPlotFiles.map((p) => p.plotId);
      const rows = this.plotTable.rows;
      const filteredSelectedRows = rows.filter(
        (r) => !selectedPlotIds.includes(r.plotId)
      );
      this.selectedRows = [...filteredSelectedRows];
      this.plotTable.selected = [...filteredSelectedRows];
    } else {
      this.selectedRows = [];
      this.plotTable.selected = [];
    }
  }

  public selectPlotFilesOnlyAllRowsSelected(): boolean {
    //view is not ready
    if (!this.plotTable) {
      return false;
    }
    const rows = this.plotTable.rows;
    const selectedPlotIds = this.selectedPlotFiles.map((p) => p.plotId);
    const filteredSelectedRows = rows.filter(
      (r) => !selectedPlotIds.includes(r.plotId)
    );
    if (!filteredSelectedRows.length) {
      return false;
    }

    return filteredSelectedRows.length == this.selectedRows.length;
  }
}
