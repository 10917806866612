<ng-container *ngIf="siteInfoData$ | async as siteInfo">
  <div class="form-controls">
    <div class="left-controls">
      <form [formGroup]="getFormGroup()" class="management-form">
        <div>
          <label for="start-date-input" class="form-label">Start Date</label>
          <input
            id="start-date-input"
            type="text"
            [dateCustomClasses]="['date-picker']"
            placeholder="DD/MM/YYYY"
            class="form-control form-control-sm"
            autocomplete="off"
            formControlName="startDate"
            [minDate]="minStartDate"
            [maxDate]="maxEndDate"
            fcBeginningDay
            bsDatepicker
          />
        </div>

        <div>
          <label for="end-date-input" class="form-label">End Date</label>
          <input
            id="end-date-input"
            type="text"
            [dateCustomClasses]="['date-picker']"
            placeholder="DD/MM/YYYY"
            class="form-control form-control-sm"
            autocomplete="off"
            formControlName="endDate"
            fcBeginningDay
            bsDatepicker
          />
        </div>
      </form>
    </div>
    <div class="right-controls">
      <button
        type="button"
        class="btn btn-primary-darken btn-sm"
        (click)="addNewRotation(siteInfo?.AvailableSpecies)"
      >
        Add Rotation
      </button>
    </div>
  </div>
  <ngx-datatable
    #rotationTable
    class="custom-table"
    [rows]="rotations"
    [rowClass]="getRowClass"
    [columnMode]="ColumnMode.force"
    [headerHeight]="56"
    [footerHeight]="56"
    rowHeight="56"
    [selected]="selectedRows"
    [selectionType]="SelectionType.checkbox"
    (select)="onSelect($event)"
    [limit]="10"
    (sort)="onSort($event)"
    [sorts]="getRotationSort()"
  >
    <ngx-datatable-column
      [width]="50"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn"
      >
        <label class="check-container">
          <span class="visually-hidden">{{
            allRowsSelected ? "Uncheck all" : "Check all"
          }}</span>
          <input
            type="checkbox"
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <label class="check-container">
          <span class="visually-hidden">{{
            isSelected ? "Deselect" : "Select"
          }}</span>
          <input
            type="checkbox"
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-row-detail [rowHeight]="'100%'" #rotationDetailRow>
      <ng-template let-row="row" ngx-datatable-row-detail-template>
        <fc-rotation-details
          [managementService]="managementService"
          [rotationFormGroup]="getRotationFormGroup(row)"
        ></fc-rotation-details>
      </ng-template>
    </ngx-datatable-row-detail>

    <ngx-datatable-column
      [width]="50"
      [resizeable]="false"
      [sortable]="false"
      [draggable]="false"
      [canAutoResize]="false"
    >
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a
          href="javascript:void(0)"
          [class.datatable-icon-right]="!getRotationExpandedState()[row.Id]"
          [class.datatable-icon-down]="getRotationExpandedState()[row.Id]"
          title="Expand/Collapse Row"
          (click)="toggleExpandRow(row)"
        >
        </a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="Start Date"
      [width]="150"
      [canAutoResize]="false"
      [resizeable]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.RotationStartDate | date : "dd/MM/yyyy" }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Rotation Name">
      <ng-template ngx-datatable-cell-template let-row="row">
        {{ row.RegimeReference?.Name }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="Actions"
      [width]="180"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
    >
      <ng-template ngx-datatable-cell-template let-row="row">
        <div class="action-buttons">
          <!-- <button
            class="btn btn-sm btn-rounded"
            (click)="appendRotation(row)"
            [adaptivePosition]="false"
            tooltip="Append Rotation"
          >
            <span class="visually-hidden">Append Rotation</span>
            <i class="bi bi-pencil-fill"></i>
          </button> -->
          <button
            class="btn btn-sm btn-rounded"
            (click)="cloneRotation(row)"
            [adaptivePosition]="false"
            tooltip="Clone"
          >
            <span class="visually-hidden">Clone Rotation</span>
            <i class="bi bi-files"></i>
          </button>

          <button
            class="btn btn-sm btn-rounded"
            (click)="deleteRotations([row])"
            [adaptivePosition]="false"
            tooltip="Delete Rotation"
          >
            <span class="visually-hidden">Delete Rotation</span>
            <i class="bi bi-trash"></i>
          </button>

          <button
            class="btn btn-sm btn-rounded"
            (click)="resetRotation(row)"
            [adaptivePosition]="false"
            tooltip="Reset Rotation"
          >
            <span class="visually-hidden">Reset Rotation</span>
            <i class="bi bi-arrow-counterclockwise"></i>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</ng-container>
