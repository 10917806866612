import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { subGroupsEmitterObject } from "src/app/plot/models";

@Component({
  selector: "fc-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Output()
  private navigationIndexSelected = new EventEmitter<number>();

  @Input()
  public nav: subGroupsEmitterObject[] = [];

  constructor() {}

  ngOnInit(): void {}

  public navigateToHome(): void {
    this.navigationIndexSelected.emit(null);
  }

  public navigateTo(index: number): void {
    this.navigationIndexSelected.emit(index);
  }
}
