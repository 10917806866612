<div class="tab-content-controls">
  <button
    type="button"
    class="btn btn-primary-darken btn-sm"
    data-bs-toggle="dropdown"
    (click)="exportTableAsCsv()"
  >
    Export table
  </button>

  <div
    class="btn-group btn-group-sm"
    role="group"
    aria-label="radio toggle between graph and table view"
  >
    <input
      type="radio"
      class="btn-check"
      name="viewType"
      id="graph-view-button"
      value="chart"
      autocomplete="off"
      [(ngModel)]="viewType"
    />

    <label class="btn btn-outline-primary" for="graph-view-button">Chart</label>

    <input
      type="radio"
      class="btn-check"
      name="viewType"
      id="table-view-button"
      value="table"
      autocomplete="off"
      [(ngModel)]="viewType"
    />
    <label class="btn btn-outline-primary" for="table-view-button">Table</label>
  </div>
</div>
<div class="content">
  <div class="loading-container" *ngIf="isLoading">Loading {{ isLoading }}</div>
  <fc-chart
    *ngIf="viewType == 'chart' && chartOption; else dataTable"
    #chart
    id="chart"
    [chartOption]="chartOption"
    class="chart"
  ></fc-chart>
</div>

<ng-template #dataTable let-data="data">
  <ngx-datatable
    class="custom-table"
    style="height: 480px"
    [columns]="columns"
    [rows]="tableRows || []"
    columnMode="force"
    [forceColumnWidth]="true"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="35"
    [scrollbarV]="true"
    [limit]="999"
  >
  </ngx-datatable>
</ng-template>
