import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";
import { BasePlotFormService } from "src/app/plot/services/base-plot-form.service";
import {
  FieldWatcher,
  FieldWatcherContext,
  FormModel,
  TreeSpecies,
} from "../models";
import { v4 as uuidv4 } from "uuid";
import { AddToScenarioPayload } from "src/app/plot/models";
import { cloneDeep } from "lodash";
import { ExplorerCatetory } from "src/app/simulation/models";
import { Constants } from "../constants";

@Injectable({
  providedIn: "root",
})
export class BaseSpeciesService extends BasePlotFormService {
  public readonly selectedSpeciesId$ = new BehaviorSubject<number>(-1);
  public speciesInUse$ = new BehaviorSubject<string[]>([]);
  private speciesIdCounter = 0;

  public speciesType = "";

  public speciesFormModel: FormModel = null;

  constructor() {
    super();

    this.selectedSpeciesId$.subscribe((id) => {
      if (this.formGroup && this.getSelectedSpeciesFormGroup()) {
        this.onSpeciesIdChanged(id);
      }
    });
  }

  //this is to be used if there is setup required when species changes
  public onSpeciesIdChanged(id): void {}

  public async watchFields(
    destroySubject$: Subject<void>,
    context: FieldWatcherContext,
    additionalWatcherFields?: FieldWatcher
  ): Promise<void> {
    if (this.fieldWatcher) {
      const watchFields = Object.keys(
        additionalWatcherFields
          ? { ...this.fieldWatcher, ...additionalWatcherFields }
          : this.fieldWatcher
      );

      watchFields.forEach(async (watchField) => {
        //to access form group from the other tabs use ":" as divider
        const splitValues = watchField.split(":");
        const isAccessingOtherTab = splitValues.length > 1;
        const watchFieldName = splitValues[isAccessingOtherTab ? 1 : 0];
        const watchTabName = isAccessingOtherTab ? splitValues[0] : null;

        //TO DO TREE and CROP TABS ARE NOT ACCESSILBE WITH GETFORMGROUP
        const fieldControl: AbstractControl = isAccessingOtherTab
          ? this.simulationService[watchTabName + "Service"]
              .getFormGroup()
              .get(watchFieldName)
          : this.getSelectedSpeciesFormGroup()?.get(watchFieldName);

        if (fieldControl) {
          fieldControl.valueChanges
            .pipe(takeUntil(destroySubject$), startWith(fieldControl.value))
            .subscribe((newValue) => {
              const fieldWatcherContext: FieldWatcherContext = {
                formGroup: context.formGroup,
                formModel: context.formModel,
                layout: context.layout,
                simulationService: this.simulationService,
                fieldChangedEmitter: context.fieldChangedEmitter,
              };
              this.fieldWatcher[watchField](newValue, fieldWatcherContext);
            });
        } else {
          console.warn(watchFieldName + "field not found.");
        }
      });
    }
  }

  public setSpeciesInUse(): void {
    this.speciesInUse$.next(this.getSpeciesInUse());
  }

  public getSpeciesInUse(): string[] {
    let speciesInUse = [];
    const plantTreeEvent = "PlnF";
    const plantCropEvent = "PlnA";
    const initialSpecies$ =
      this.simulationService.initialConditionsService[
        this.speciesType == "tree" ? "initTreeId$" : "initCropId$"
      ];

    //it could be a generated uuid or number
    if (
      initialSpecies$.getValue() !== -1 &&
      initialSpecies$.getValue() !== null &&
      initialSpecies$.getValue() !== undefined
    ) {
      speciesInUse.push(initialSpecies$.getValue());
    }

    this.simulationService.eventsService
      .getEventQ()
      .value.filter(
        (e) =>
          e.tEV ==
          (this.speciesType == "tree" ? plantTreeEvent : plantCropEvent)
      )
      .forEach((e) => {
        //idSP from Event is actually dbID
        const speciesId = e.idSP;

        if (
          speciesId !== null &&
          speciesId > -1 &&
          !speciesInUse.includes(speciesId)
        ) {
          speciesInUse.push(speciesId);
        }
      });

    return speciesInUse;
  }

  public applySpeciesFormData(data) {}

  public selectSpecies({ id }) {
    this.selectedSpeciesId$.next(id);
  }

  // public getSpeciesIdByDbId(dbId) {
  //   return this.getSpecies()
  //     .getRawValue()
  //     .find((s) => s.dbId == dbId)?.idSP;
  // }

  public getSpeciesByIdRegimeSP(idRegimeSP) {
    return this.getSpecies()
      .getRawValue()
      .find((s) => s.idRegimeSP == idRegimeSP);
  }

  public getSpeciesByName(name) {
    return this.getSpecies()
      .getRawValue()
      .find((s) => s.nmSP == name);
  }

  public getSpeciesFormGroupByIdRegimeSP(id) {
    return this.getSpecies()?.controls.find(
      (s) => s.get("idRegimeSP").value == id
    );
  }

  public getSpeciesFormGroupByName(name: string) {
    return this.getSpecies()?.controls.find((s) => s.get("nmSP").value == name);
  }

  public getSpeciesFormGroupByIdSP(id) {
    return this.getSpecies()?.controls.find((s) => s.get("idSP").value == id);
  }

  public getSpecies(): UntypedFormArray {
    return this.getFormGroup().get("species") as UntypedFormArray;
  }

  public getSelectedSpeciesIndex(): number {
    const id = this.selectedSpeciesId$.getValue();

    return this.getSpecies().controls.findIndex(
      (s) => s.get("idRegimeSP").value == id
    );
  }

  public addSpecies(speciesFormGroup): void {
    const species = this.getSpecies();
    species.push(speciesFormGroup);
    this.selectSpecies({ id: speciesFormGroup.get("idRegimeSP").value });
    this.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.getSpecies().controls.sort((a, b) =>
      a.get("nmSP").value.localeCompare(b.get("nmSP").value)
    );
  }

  protected generateSpeciesName(speciesNames: string[]): string {
    const type = this.speciesType == "tree" ? "Tree" : "Crop";

    if (!speciesNames.length) {
      return `${type} Species (1)`;
    }
    for (let i = 0; i < speciesNames.length + 1; i++) {
      if (!speciesNames.includes(`${type} Species (${i + 1})`)) {
        return `${type} Species (${i + 1})`;
      }
    }
  }

  protected generateSpeciesId(speciesIds: number[]): number {
    this.speciesIdCounter++;
    if (speciesIds.includes(this.speciesIdCounter)) {
      this.speciesIdCounter++;
      while (speciesIds.includes(this.speciesIdCounter) === true) {
        this.speciesIdCounter++;
      }
      return this.speciesIdCounter;
    }

    return this.speciesIdCounter;
  }

  public speciesButtonClicked(event) {
    if (event.event == "new") {
      const speciesIds = this.getSpecies().value.map((s) => s.idSP);
      const id = this.generateSpeciesId(speciesIds);
      const idRegimeSP = uuidv4();
      const speciesFormGroup = this.createFormGroup(this.speciesFormModel);
      const speciesNames = this.getSpecies().value.map((s) => s.nmSP);

      speciesFormGroup
        .get("nmSP")
        .setValue(this.generateSpeciesName(speciesNames));
      speciesFormGroup.get("idSP").setValue(id);
      speciesFormGroup.get("idRegimeSP").setValue(idRegimeSP);

      this.addSpecies(speciesFormGroup);

      // this.applySpeciesFormData("");
    } else if (event.event == "clone") {
      const speciesIds = this.getSpecies().value.map((s) => s.idSP);
      const id = this.generateSpeciesId(speciesIds);
      let data = event.data;
      data.nmSP = data.nmSP + " copy";
      if (this.hasSpeciesName(data.nmSP)) {
        let uniqueName = data.nmSP + " copy";
        while (this.hasSpeciesName(uniqueName) === true) {
          uniqueName += " copy";
        }
        data.nmSP = uniqueName;
      }

      data.idSP = id;
      data.idRegimeSP = uuidv4();
      const speciesFormGroup = this.createFormGroup(this.speciesFormModel);
      speciesFormGroup.patchValue(data);
      this.addSpecies(speciesFormGroup);
    } else if (event.event == "delete") {
      const index = this.getSpecies().value.findIndex(
        (sp) => sp.idRegimeSP == event.idRegimeSP
      );

      this.getSpecies().removeAt(index);

      this.removeDeletedSpeciesLinkages({
        id: event.id,
        idRegimeSP: event.idRegimeSP,
      });

      if (this.getSpecies().value.length > 0) {
        this.selectSpecies({
          id: this.getSpecies().value[0].idRegimeSP,
        });
      } else {
        this.selectSpecies({ id: null });
      }
    } else if (event.event == "usage") {
      this.showSpeciesUsage();
    }

    this.setSpeciesInUse();
  }

  protected removeDeletedSpeciesLinkages({ id, idRegimeSP }) {
    this.simulationService.initialConditionsService.speciesDeleted({
      id,
      idRegimeSP,
      speciesType: this.speciesType,
    });

    if (idRegimeSP) {
      this.simulationService.eventsService.speciesDeleted({
        id,
        idRegimeSP,
        speciesType: this.speciesType,
      });
    }
  }

  protected hasSpeciesName(name) {
    const speciesNames = this.getSpecies().value.map((s) => s.nmSP);
    return speciesNames.includes(name);
  }

  public getFormModel() {
    return this.speciesFormModel;
  }

  public getSelectedSpeciesFormGroup(): AbstractControl {
    const id = this.selectedSpeciesId$.getValue();
    return this.getSpecies()?.controls.find(
      (s) => s.get("idRegimeSP").value == id
    );
  }

  public getSpeciesDetailsByIdRegimeSP(idRegimeSP): TreeSpecies {
    //SpeciesDetails' idSP assigned to idRegimeSP
    const species =
      this.simulationService.dataBuilderService.getAllSelectedSpecies(
        this.speciesType
      );

    return species?.find((s) => {
      s[this.speciesType == "tree" ? "SpeciesForest" : "SpeciesAgriculture"][0]
        .$.idSP == idRegimeSP;
    });
  }

  public getSpeciesDetailsByNmSP(nmSP): TreeSpecies {
    //SpeciesDetails' idSP assigned to idRegimeSP
    const species =
      this.simulationService.dataBuilderService.getAllSelectedSpecies(
        this.speciesType
      );

    return species?.find((s) => {
      return (
        s[
          this.speciesType == "tree" ? "SpeciesForest" : "SpeciesAgriculture"
        ][0].$.nmSP == nmSP
      );
    });
  }

  public getSelectedSpeciesDetails(): TreeSpecies {
    if (!this.getSelectedSpeciesFormGroup()) {
      return null;
    }

    const idRegimeSP =
      this.getSelectedSpeciesFormGroup().get("idRegimeSP").value;

    const species =
      this.simulationService.dataBuilderService.getAllSelectedSpecies(
        this.speciesType
      );
    //Species details' idSP = tree spieces idRegimeSP
    return species?.find(
      (s) =>
        s[
          this.speciesType == "tree" ? "SpeciesForest" : "SpeciesAgriculture"
        ][0].$.idSP == idRegimeSP
    );
  }

  public getUnit(fieldName: string): string {
    return this.speciesFormModel[fieldName].unit;
  }

  public getDecayRateInputVariables(fieldName: string) {
    const field = this.speciesFormModel[fieldName];
    const species = this.getSelectedSpeciesFormGroup().get("nmSP").value;
    const event = field.eventId;

    return {
      category: field.categoryLabel,
      unit: field.unit,
      species,
      event,
    };
  }

  public getAverage(payload: string[]) {
    const inputsWithValue = payload
      .map((f) => +this.getSelectedSpeciesFormGroup().get(f)?.value || 0)
      .filter((v) => v > 0 && v > Constants.k0Plus);

    const numberOfInputs = payload.length;
    let aveHalfLife = 0.0;
    inputsWithValue.forEach((v) => {
      aveHalfLife += 1 / v;
    });

    if (aveHalfLife < Constants.k0Plus) {
      return "0";
    }

    aveHalfLife = numberOfInputs / aveHalfLife;

    return aveHalfLife.toFixed(3);
  }

  public showSpeciesUsage(): void {
    const species = this.getSelectedSpeciesFormGroup().value;
    const speciesName = species.nmSP;
    const initConditionId =
      this.simulationService.initialConditionsService[
        this.speciesType == "tree" ? "initTreeId$" : "initCropId$"
      ].getValue();
    const isInitSpecies = species.idRegimeSP == initConditionId;

    const numberOfPlantingEvents = this.simulationService.eventsService
      .getEventQ()
      .value.filter(
        (e) =>
          e.tEV == (this.speciesType == "tree" ? "PlnF" : "PlnA") &&
          e.idSP == species.idRegimeSP //idSP in events = dbId
      );

    const numberOfThinningOrHarvestEvents = this.simulationService.eventsService
      .getEventQ()
      .value.filter(
        (e) =>
          e.tEV == (this.speciesType == "tree" ? "Thin" : "Harv") &&
          e.idSP == species.idRegimeSP
      );

    const message = `
    <p>The ${this.speciesType} species "${speciesName}" is referred to by:</p>
    <ul>
    <li>${isInitSpecies ? "The" : "Not the"} initial ${
      this.speciesType
    } species</li>
    <li>${numberOfPlantingEvents.length} ${
      this.speciesType
    } planting events</li>
    <li>${numberOfThinningOrHarvestEvents.length} ${
      this.speciesType == "tree" ? "tree thinning" : "crop harvest"
    } events</li>
    <li>0 risk inputs</li>
    </ul>`;
    this.simulationService.modalService.openConfirmModal(message, true);

    //     bool Doc::MsgBufWhereSpecUsed(const Cot* specNm, SpecT t, bool pastTense) const
    // {
    //     // Is an individual species used? Only to be called for making messages.
    //     ASSERT(!EMPTY_COT(specNm));
    //     ASSERT(t == kTree || t == kCrop);
    //     bool fr = (t == kTree);
    //     bool used = false;
    //     MsgBufClear();
    //     // Intro
    //     MsgBuf("The %s species '%s' %s referred to by:\n",
    //         fr ? "tree" : "crop", specNm->s, pastTense ? "was" : "is");
    //     // Initial species
    //     if (pldPloSim && (config->CAMX || config->PPPG))
    //     {
    //         bool b = (t == kTree && init->treeF.treeNmInit.IsEqualCT(specNm))
    //             || (t == kCrop && init->cropA.cropNmInit.IsEqualCT(specNm));
    //         MsgBuf("   - %s initial %s species%s\n",
    //             b ? "The" : "(Not the", fr ? "tree" : "crop", b ? "" : ")");
    //         used = used || b;
    //     }
    //     // Events (mainQ)
    //     if (pldPloSim && config->CAMX)
    //     {
    //         int n;
    //         if (fr)
    //         {
    //             n = eventQ->NSpeciesSpecificEvents(specNm, kPlnF);
    //             MsgBuf("   - %d tree planting event%s\n", n, (n == 1) ? "" : "s");
    //             used = used || (n > 0);
    //             n = eventQ->NSpeciesSpecificEvents(specNm, kThin);
    //             MsgBuf("   - %d tree thinning event%s\n", n, (n == 1) ? "" : "s");
    //             used = used || (n > 0);
    //         }
    //         else
    //         {
    //             n = eventQ->NSpeciesSpecificEvents(specNm, kPlnA);
    //             MsgBuf("   - %d crop planting event%s\n", n, (n == 1) ? "" : "s");
    //             used = used || (n > 0);
    //             n = eventQ->NSpeciesSpecificEvents(specNm, kHarv);
    //             MsgBuf("   - %d crop harvest event%s\n", n, (n == 1) ? "" : "s");
    //             used = used || (n > 0);
    //         }
    //     }
    //     // RIn's
    //     if (pldPloSim)
    //     {
    //         int n = rInSet->NRIns(specNm);
    //         MsgBuf("   - %d risk input%s\n", n, (n == 1) ? "" : "s");
    //         used = used || (n > 0);
    //     }
    //     // SIn's
    //     // Db tables
    //     // Finish
    //     return used;
    // }
  }

  public getSelectedSpeciesName() {
    return this.getSelectedSpeciesFormGroup().get("nmSP").value;
  }

  public getSpeciesEventQByIdRegimeSP(idRegimeSP): any[] {
    const species = this.getSpecies().value.find(
      (s) => s.idRegimeSP == idRegimeSP
    );

    if (species) {
      return species.eventQ;
    }
    return null;
  }

  public setEventQFormGroup(event, service) {
    //service is Event services
    const flatMap = (obj) => {
      let formValues = {};
      Object.keys(obj).forEach((key) => {
        if (key == "$") {
          formValues = { ...formValues, ...obj[key] };
        }

        if (obj[key][0] !== null && obj[key][0] !== undefined) {
          if (typeof obj[key][0] === "object") {
            formValues = { ...formValues, ...flatMap(obj[key][0]) };
          } else {
            formValues = { ...formValues, ...{ [key]: obj[key][0] } };
          }
        }
      });
      return formValues;
    };

    const eventFormsService = this.simulationService.eventFormsService;

    const eventFormGroup = service.getFormGroup();

    const formValues = flatMap(event);

    this.applyXmlValuesToFormGroup(
      formValues,
      eventFormGroup,
      service.getFormModel()
    );

    //add id to the form for updating
    eventFormGroup.addControl("eventId", new UntypedFormControl(uuidv4()));

    eventFormGroup
      .get("labelEV")
      .setValue(
        eventFormsService.getEventDescription(eventFormGroup.getRawValue())
      );

    return eventFormGroup;
  }
  //for imported file and redownloading species from data builder
  // public updateIdRegimeSPToCurrentSpecies(data) {
  //   const name = data.species.value;
  //   const species = this.getSpecies().controls.find(
  //     (sp) => sp.get("nmSP").value == name
  //   );
  //   if (species) {
  //     console.log(data.species.id, "updateIdRegimeSPToCurrentSpecies");
  //     species.get("idRegimeSP").setValue(data.species.id);
  //   } else {
  //     console.error(
  //       `Species - ${name} not found, idRegimeSP cannot be inserted.`
  //     );
  //   }
  // }

  public addInputToDigestScenario(data: AddToScenarioPayload) {
    const { service, input, item, addToAllScenarios, itemValue } = data;
    const convertedItem = cloneDeep(item);
    convertedItem.inputs = [input];
    const itemModel = this.speciesFormModel[input.programmingName];
    const formValue =
      itemValue ||
      this.getSelectedSpeciesFormGroup().get(input.programmingName).value;
    const validations = itemModel.validators;
    const control = new FormControl(formValue, validations);
    const speciesName = this.getSelectedSpeciesFormGroup().get("nmSP").value;

    let categoryLabel = this.reformatSpeciesCategoryLabel(
      speciesName,
      itemModel
    );

    const option = speciesName;

    this.simulationService.plotDigestService.setScenarioDetails({
      programmingName: input.programmingName,
      service: this,
      itemLabel: itemModel.label,
      control: control,
      path: categoryLabel.split(":").map((c) => c.trim()),
      option: option,
      type:
        this.speciesType == "tree"
          ? "SpeciesForestSet"
          : "SpeciesAgricultureSet",
      inputItem: convertedItem,
      addToAllScenarios: addToAllScenarios,
      model: itemModel,
      input: input,
    });
  }

  protected reformatSpeciesCategoryLabel(speciesName, itemModel): string {
    const replacingKeywords = [
      "Tree species :",
      "Initial : Forest :",
      "Crop species :",
      "Initial : Agricultural :",
      "Initial :",
      "Species :",
      "Species",
    ];
    const replacingKeyword = replacingKeywords.find(
      (kw) => itemModel.categoryLabel.indexOf(kw) == 0
    );

    if (!replacingKeyword) {
      return itemModel.categoryLabel;
    }
    const presetCategory =
      (this.speciesType == "tree" ? "Trees:" : "Crops:") +
      speciesName +
      ":" +
      (replacingKeyword.indexOf("Initial") > -1 ? "Standard initial" : "");
    let categoryLabel = itemModel.categoryLabel.replace(
      replacingKeyword,
      presetCategory
    );
    return categoryLabel;
  }

  public getExplorerSubcategoryMap(data: ExplorerCatetory) {
    let explorerMap = {};

    const rootLabel = this.layout?.label;

    const formModel: FormModel = data.service.speciesFormModel;
    const formGroup = data.service.getFormGroup();
    const species = formGroup.get("species") as FormArray;

    species.controls.forEach((spFormGroup) => {
      const speciesName = spFormGroup.get("nmSP").value;

      const inputModels = Object.entries(formModel)
        .filter((fm) => fm[1].isDigest && fm[1].isShown)
        .map((fm) => {
          return { ...fm[1], programmingName: fm[0] };
        });

      for (const im of inputModels) {
        const categoryLabel = this.reformatSpeciesCategoryLabel(
          speciesName,
          im
        );

        const path = categoryLabel?.split(":").map((c) => c.trim());
        //used for digest
        const foundItem = this.findInputElementById(
          data.service.layout.groups,
          im.programmingName
        );

        if (!foundItem) {
          // console.log(im.programmingName, " not found");
          continue;
        }

        const value = {
          label: im.label,
          model: im,
          programmingName: im.programmingName,
          value: spFormGroup.get(im.programmingName).value,
          dataType: im.dataType,
          service: data.service,
          item: foundItem.item,
          input: foundItem.input,
          formGroup: spFormGroup,
          displayValue: this.getInputDisplayValue(
            spFormGroup.get(im.programmingName).value,
            im,
            foundItem.input
          ),
        };
        this.setValueToExplorerMap(explorerMap, path, value);
      }

      const requiredInputs = ["tSP", "tAgeIxSP", "nmSP", "notesSP", "inUseSP"];
      requiredInputs.forEach((programmingName) => {
        const model = Object.keys(formModel).find((m) => m == programmingName);
        const im = { ...formModel[programmingName], programmingName };
        //  const value = spFormGroup.get(programmingName).value;
        const value = {
          label: im.label,
          model: im,
          programmingName: im.programmingName,
          value: spFormGroup.get(im.programmingName).value,
          dataType: im.dataType,
          service: data.service,
          item: null,
          input: null,
          formGroup: spFormGroup,
          displayValue: this.getInputDisplayValue(
            spFormGroup.get(im.programmingName).value,
            im
          ),
        };

        this.setValueToExplorerMap(
          explorerMap,
          [data.service.pageId == "trees" ? "Trees" : "Crops", speciesName],
          value
        );
      });
    });

    return explorerMap[rootLabel];
  }

  public isInvalid(): boolean {
    //IGORE eventQ validation
    const selectedSpeciesFormGroup =
      this.getSelectedSpeciesFormGroup() as FormGroup;

    if (selectedSpeciesFormGroup) {
      if (
        selectedSpeciesFormGroup.invalid &&
        selectedSpeciesFormGroup.get("eventQ").valid
      ) {
        return true;
      }

      let invalidFields = [];
      Object.entries(selectedSpeciesFormGroup.controls).forEach((fg) => {
        if (fg[0] !== "eventQ" && fg[1].invalid) {
          return true;
        } else {
          invalidFields.push(fg[0]);
        }
      });

      if (invalidFields.length == 1 && invalidFields[0] == "eventQ") {
        return false;
      }
    }
    return false;
  }

  public reset(): void {
    this.selectedSpeciesId$.next(-1);
    this.speciesInUse$.next([]);
    this.speciesIdCounter = 0;
    super.reset();
  }
}
