import { Component, Input, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";

export interface TableComponentData {
  headers: string[];
  rows: Array<any[]>;
}

@Component({
  selector: "fc-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit {
  public readonly destroy$ = new Subject<void>();

  @Input() data$: Observable<{ headers: string[]; rows: number[][] }>;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
