import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { AuthService } from "../auth/services/auth.service";
import { FlyOverPanelComponent } from "../shared/components/fly-over-panel/fly-over-panel.component";
import { Constants } from "../shared/constants";
import { MessageService } from "../shared/services/message.service";
import { PlotTemplate } from "./models";
import { AppService } from "../services/app.service";
import { SimulationService } from "../shared/models";

@Component({
  selector: "fc-simulation",
  templateUrl: "./simulation.component.html",
  styleUrls: ["./simulation.component.scss"],
})
export class SimulationComponent implements OnInit, OnDestroy {
  @ViewChild(FlyOverPanelComponent) flyOverPanel: FlyOverPanelComponent;

  private readonly roleSub$ = new Subject<void>();

  public readonly acceptedFileTypes: string = Constants.PLOT_FORMATS.join(",");
  public plotTemplates$: Observable<PlotTemplate[]>;

  public simulationService: SimulationService = null;

  public appVersion: string = "";
  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();
    this.appVersion = this.simulationService.version;

    this.plotTemplates$ = this.simulationService.plotTemplates$;

    this.authService.userRole$
      .pipe(takeUntil(this.roleSub$))
      .subscribe((role) => {
        if (role) {
          this.simulationService.getPlotTemplateList();

          this.roleSub$.next();
          this.roleSub$.complete();
        }
      });
  }

  public async onFileSelected(event) {
    const file: File = event.target.files[0];

    if (file) {
      const plotFile = await this.simulationService.helperService
        .readFile(file)
        .catch((alertMessage) => {
          this.messageService.addAlert(alertMessage);
        });

      if (!plotFile) {
        return;
      }
      const plotFileJson =
        await this.simulationService.helperService.convertXmlToJson(plotFile);

      //to do validate plot type
      // const plotFormat: PlotFormat =
      //   Constants.PLOT_DOCUMENT_TO_EXTENSION_MAP[Object.keys(plotFile)[0]];

      this.simulationService.isClonedPlotFile = true;
      this.simulationService.processImportedPlotFile(plotFileJson);
    }
  }

  public setPlotTemplate(name) {
    this.simulationService
      .getPlotTemplate(name)
      .pipe(take(1))
      .subscribe(async (template) => {
        let plotFileJson: any =
          await this.simulationService.helperService.convertXmlToJson(template);
        //specially converted for plot template
        const docKey = Object.keys(plotFileJson.DocFragment)[1];
        const convertedPlotFileJosn: Object = {
          [docKey]: plotFileJson.DocFragment[docKey][0],
        };
        this.simulationService.isClonedPlotFile = true;
        this.simulationService.processImportedPlotFile(convertedPlotFileJosn);
      });
  }

  ngOnDestroy(): void {}
}
