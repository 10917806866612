import { Component, OnInit } from "@angular/core";
import { ModalOptions } from "ngx-bootstrap/modal";
import { take } from "rxjs/operators";
import { ModalService } from "../../services/modal.service";
import { PlotUploadComponent } from "../plot-upload/plot-upload.component";
import { Simulation2020Service } from "src/app/core/services/simulation-2020.service";

@Component({
  selector: "fc-plot-upload-drop-zone",
  templateUrl: "./plot-upload-drop-zone.component.html",
  styleUrls: ["./plot-upload-drop-zone.component.scss"],
})
export class PlotUploadDropZoneComponent implements OnInit {
  public filesOver = false;
  public isModalOpened = false;
  constructor(
    // private simulationService: Simulation2020Service, //todo
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    window.addEventListener("dragenter", (e) => {
      if (this.isFile(e)) {
        if (!this.filesOver) {
          this.filesOver = true;
        }
      }

      e.preventDefault();
    });
  }

  protected isFile(evt) {
    var dt = evt.dataTransfer;

    for (let i = 0; i < dt.types.length; i++) {
      if (dt.types[i] === "Files") {
        return true;
      }
    }
    return false;
  }
  public dragEnterHandler(e) {
    if (this.isFile(e)) {
      if (!this.filesOver) {
        this.filesOver = true;
      }
    }
    e.preventDefault();
  }

  public dragleaveHandler(e) {
    const dropZone = document.querySelector("#fc-drop-zone") as HTMLElement;
    const rect = dropZone.getBoundingClientRect();
    if (
      e.clientX < rect.left ||
      e.clientX >= rect.right ||
      e.clientY < rect.top ||
      e.clientY >= rect.bottom
    ) {
      this.filesOver = false;
    }

    e.preventDefault();
  }
  public dragoverHandler(e: any) {
    e.stopPropagation();
    e.preventDefault();
  }

  public async dropHandler(ev) {
    ev.preventDefault();
    // this.filesOver = false;
    // try {
    //   this.simulationService.loadPlotFiles(ev.dataTransfer?.files);
    // } catch {
    //   this.simulationService.messageService.addAlert({
    //     type: "danger",
    //     msg: "Unable to read the plot file content.",
    //     dismissible: true,
    //   });
    // }

    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        projectCollectionFilter: null,
        //   folders$: this.simulationService.aboutService.folders$,
      },
    };

    if (this.isModalOpened) {
      return;
    }

    this.modalService
      .openModal(PlotUploadComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          // if (!this.simulationService.isUploading$.getValue()) {
          //   this.simulationService.resetFileUpload();
          // }
          this.isModalOpened = false;
        },
        error: (err) => {
          // this.simulationService.messageService.addAlert({
          //   type: "danger",
          //   msg: err,
          //   dismissible: true,
          // });
          // this.isModalOpened = false;
          // this.simulationService.resetFileUpload();
        },
      });

    this.isModalOpened = true;
  }
}
