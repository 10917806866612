import { Injectable } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";

import { FormModel } from "src/app/shared/models";
import { BehaviorSubject } from "rxjs";
import { Validators } from "@angular/forms";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";
import { CropPlanting2020Service } from "./crop-planting-2020.service";

@Injectable({
  providedIn: "root",
})
export class CropPlanting2024Service extends CropPlanting2020Service {}
