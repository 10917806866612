import { Component, Inject, Injector, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ModalOptions } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { EventStyleClass } from "src/app/plot/events/models";
import {
  SpeciesEventTypeMap,
  SpeciesEventType,
  EventFormsService,
} from "../../models";
import { ModalService } from "../../services/modal.service";
import { EventFormsComponent } from "../event-forms/event-forms.component";
import { FlyOverPanelService } from "../fly-over-panel/services/fly-over-panel.service";
import Utilities from "../../utilities/utils";

@Component({
  selector: "fc-species-events",
  templateUrl: "./species-events.component.html",
  styleUrls: ["./species-events.component.scss"],
})
export class SpeciesEventsComponent implements OnInit {
  private eventFormsService: EventFormsService;
  private service: any;

  private readonly destroy$ = new Subject<void>();
  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;
  readonly defaultEventFilterState: Array<"t" | "f"> = [
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
    "t",
  ];

  //public species: any; //fix agriculture model
  public speciesType: "tree" | "crop" = null;
  public namespace: "SpeciesForest" | "SpeciesAgriculture" = null;

  public title: string = "";
  public unit: string = "";

  public eventTypeFilterformGroup: UntypedFormGroup = new UntypedFormGroup({});

  public events: UntypedFormArray;

  public filteredEvents: AbstractControl[] = [];

  public eventTypesMap: {
    [key: SpeciesEventType | string]: SpeciesEventTypeMap;
  };

  public selectedRows: AbstractControl[] = [];

  public selectedBulkAction: "delete" | "clone" = "delete";

  public currentSort: { prop: string; dir: "desc" | "asc" }[] = [
    { prop: null, dir: "asc" },
  ];
  private readonly sortColumns = ["nmEV", "labelEV"];

  constructor(
    public flyOverPanelService: FlyOverPanelService,
    private modalService: ModalService,
    private injector: Injector,
    @Inject("componentData") public componentData: any
  ) {}

  ngOnInit(): void {
    const speciesFormGroup = this.componentData.speciesFormGroup;
    this.service = this.componentData.service;
    this.eventFormsService = this.service.simulationService.eventFormsService;

    this.speciesType = String(
      speciesFormGroup.get("tSP").value
    ).toLowerCase() as "tree" | "crop";

    this.eventTypesMap = this.eventFormsService.getEventTypes(this.speciesType);
    const headerState = this.componentData.formGroup.get("headerState").value;
    let eventFilterState = this.componentData.formGroup.get("showEvT").value;
    if (eventFilterState) {
      eventFilterState = eventFilterState.split(",");
    }

    if (headerState) {
      this.currentSort = [
        {
          prop: this.sortColumns[headerState.$.sortIx],
          dir: headerState.$.sortUp == "true" ? "asc" : "desc",
        },
      ];
    }

    this.events = speciesFormGroup.get("eventQ");

    // this.events.controls.forEach((fg: any) => {
    //   Object.entries(fg.controls).forEach((c: any) => {
    //     if (c[1].status == "INVALID") {
    //       console.log(c[0], c[1].value);
    //     }
    //   });
    // });

    this.setEventfilter(eventFilterState || this.defaultEventFilterState);

    if (this.events.controls.length) {
      this.filterEvents();
    }

    //for event filter check boxes
  }

  ngAfterViewInit(): void {
    this.onSort({ sorts: this.currentSort });
  }

  protected setEventfilter(filterState: Array<"t" | "f">): void {
    const eventTypeArray = Object.entries(this.eventTypesMap);
    filterState.forEach((f, index) => {
      const fieldName = eventTypeArray.find((e) => e[1].index == index);
      if (fieldName) {
        this.eventTypeFilterformGroup.addControl(
          fieldName[0],
          new UntypedFormControl(f == "t")
        );
      }
    });
  }

  protected filterEvents() {
    const eventTypes = this.eventTypeFilterformGroup.getRawValue();
    this.filteredEvents = this.events.controls.filter(
      (e) => eventTypes[e.get("tEV").value]
    );
  }

  public createEvent() {
    const initialState: ModalOptions = {
      initialState: {
        simulationService: this.service.simulationService,
        speciesType: this.speciesType,
        formData: null,
        isEventPage: false,
      },
    };

    this.modalService
      .openModal(EventFormsComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((resultFormGroup: UntypedFormGroup) => {
        if (resultFormGroup) {
          resultFormGroup.addControl(
            "eventId",
            new UntypedFormControl(Utilities.uuid())
          );
          resultFormGroup
            .get("labelEV")
            ?.setValue(
              this.eventFormsService.getEventDescription(resultFormGroup)
            );
          this.events.push(resultFormGroup);
          this.filterEvents();
        }
      });
  }

  public cloneEvent(fg: AbstractControl) {
    let formData = fg.getRawValue();

    const index = this.events.controls.findIndex(
      (e) => e.get("eventId").value == formData.eventId
    );

    const eventType = formData.tEV;
    const eventFormGroup = this.eventFormsService.getEventFormGroup(eventType);
    eventFormGroup.patchValue(formData);
    eventFormGroup.addControl(
      "eventId",
      new UntypedFormControl(Utilities.uuid())
    );
    eventFormGroup
      .get("nmEV")
      .setValue(eventFormGroup.get("nmEV").value + " (copy)");
    this.events.insert(index + 1, eventFormGroup);
    this.filterEvents();
  }

  public editEvent(fg: UntypedFormGroup) {
    const initialState: ModalOptions = {
      initialState: {
        simulationService: this.service.simulationService,
        speciesType: this.speciesType,
        formData: fg.getRawValue(),
        isEventPage: false,
      },
    };

    this.modalService
      .openModal(EventFormsComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((resultFormGroup: UntypedFormGroup) => {
        if (resultFormGroup) {
          Object.entries(resultFormGroup.controls).forEach((keyValueArray) => {
            const key = keyValueArray[0];
            const control = keyValueArray[1];
            fg.setControl(key, this.service.cloneAbstractControl(control));
          });
        }
      });
  }
  public getEventType(fg: UntypedFormGroup): string {
    const eventName = fg.value.tEV;
    if (!eventName) {
      return;
    }
    return this.eventFormsService.getAvailableEventTypes()[eventName]?.label;
  }

  public getEventClass(fg: UntypedFormGroup): EventStyleClass {
    const eventName = fg.value.tEV;
    const eventType =
      this.eventFormsService.getAvailableEventTypes()[eventName]?.type;
    return eventType == "tree"
      ? "forest-event"
      : eventType == "crop"
      ? "agricultural-event"
      : "mixed-event";
  }

  public getRowClass(fg) {
    //for debugging
    // if (fg.invalid) {
    //   Object.entries(fg.controls).forEach((f: any) => {
    //     if (f[1].invalid) {
    //       console.log("INVALID", f[0], f[1]);
    //     }
    //   });
    // }
    return { invalid: fg.invalid };
  }

  public deleteEvents(fgs: AbstractControl[]) {
    fgs.forEach((fg) => {
      const foundIndex = this.events.value.findIndex(
        (e) => e.eventId == fg.value.eventId
      );
      if (foundIndex > -1) {
        this.events.removeAt(foundIndex);
        this.filterEvents();
      }
    });
    this.deselectAll();
  }

  public bulkAction() {
    if (this.selectedBulkAction == "delete") {
      this.deleteEvents(this.selectedRows);
    } else if (this.selectedBulkAction == "clone") {
      this.selectedRows.forEach((r) => this.cloneEvent(r));
    }

    this.deselectAll();
  }

  public onSelect({ selected }) {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.selectedRows = [];
  }

  public onSort(event): void {
    const sort = event.sorts[0];
    this.currentSort = [sort];

    this.events.controls.sort((a, b) => {
      if (a.get(sort.prop)?.value > b.get(sort.prop)?.value) {
        return sort.dir == "asc" ? 1 : -1;
      }

      if (b.get(sort.prop)?.value > a.get(sort.prop)?.value) {
        return sort.dir == "asc" ? -1 : 1;
      }

      return 0;
    });
    this.filterEvents();
  }

  ngOnDestroy(): void {
    const speciesFormGroup = this.componentData.speciesFormGroup;

    const eventFilterStateControl = this.componentData.formGroup.get("showEvT");
    let eventFilterStateValue = eventFilterStateControl?.value
      ? eventFilterStateControl.value.split(",")
      : this.defaultEventFilterState;

    if (this.currentSort[0]) {
      const headerStateControl =
        this.componentData.formGroup.get("headerState");
      let headerStateValue = { ...headerStateControl.value };

      headerStateValue.$.sortIx = this.sortColumns.findIndex(
        (sc) => sc == this.currentSort[0].prop
      );
      headerStateValue.$.sortUp =
        this.currentSort[0].dir == "desc" ? "false" : "true";

      headerStateControl.setValue(headerStateValue);
    }

    const eventTypeArray = Object.entries(this.eventTypesMap);

    eventTypeArray.forEach((et) => {
      const key = et[0];
      const value = et[1];
      const index = value.index;
      const isOn = this.eventTypeFilterformGroup.get(key).value ? "t" : "f";
      eventFilterStateValue[index] = isOn;
    });

    eventFilterStateControl.setValue(eventFilterStateValue.join(","));
  }

  //for formgroup validator
  public isInvalid(): boolean {
    if (this.componentData.speciesFormGroup) {
      return this.componentData.speciesFormGroup.get("eventQ").invalid;
    }
    return false;
  }
}
