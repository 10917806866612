import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "fc-flow-bottom-navigation",
  templateUrl: "./flow-bottom-navigation.component.html",
  styleUrls: ["./flow-bottom-navigation.component.scss"],
})
export class FlowBottomNavigationComponent implements OnInit {
  @Output() nextFlowClicked = new EventEmitter<void>();

  @Output() previousFlowClicked = new EventEmitter<void>();

  @Input()
  public canNavigatePreviousFlow$: Observable<boolean>;

  @Input()
  public canNavigateNextFlow$: Observable<boolean>;

  constructor() {}

  ngOnInit(): void {}
}
