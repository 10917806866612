import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FolderList } from "src/app/my-plots/models";
import { Observable } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { BaseModalFormComponent } from "../base-modal-form/base-modal-form.component";

@Component({
  selector: "fc-project-collection-selector",
  templateUrl: "./project-collection-selector.component.html",
  styleUrls: ["./project-collection-selector.component.scss"],
})
export class ProjectCollectionSelectorComponent
  extends BaseModalFormComponent
  implements OnInit
{
  @Output() action = new EventEmitter();

  public folders$: Observable<FolderList[]>;

  public projectCollectionFilter: {
    collection: string;
    collectionId: string;
    project: string;
    projectId: string;
  } = null;
  public showPlotFileList = false;

  public selectedPlotFiles = [];

  public simulationService = null;

  constructor(private appService: AppService) {
    super();
  }

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();
    this.folders$ = this.simulationService.aboutService.getFolders();
  }

  public selectFolder(collection) {
    this.projectCollectionFilter = {
      collection: collection.name,
      collectionId: collection.collectionId,
      project: collection.parent.name,
      projectId: collection.parent.projectId,
    };
  }
}
