import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";

@Injectable({
  providedIn: "root",
})
export class RotationTermiteChangeService extends BaseRotationEventService {
  public _type = "RotationTermiteChange";

  public getIntensity() {
    //     TermiteChangeState =
    //         ((RegimeTermiteChange)regimeEvent).TermiteChangeStateGroups[0].
    //         TermiteChangeState
    // };
  }
}
