import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { BaseModalFormComponent } from "src/app/shared/components/base-modal-form/base-modal-form.component";
import { PlotTableColumns } from "src/app/my-plots/models";
import { EstatePlot } from "src/app/plot/models";
import { EstateService, PlotFilesService } from "src/app/shared/models";

@Component({
  selector: "fc-create-estate-form",
  templateUrl: "./create-estate-form.component.html",
  styleUrls: ["./create-estate-form.component.scss"],
})
export class CreateEstateFormComponent
  extends BaseModalFormComponent
  implements OnInit
{
  private readonly destroy$ = new Subject<void>();

  @Input()
  service: EstateService;

  @Output() action = new EventEmitter();

  readonly helpUrl = "186_One%20Plot%20in%20the%20Estate.htm";

  public formGroup: UntypedFormGroup;

  public formData: EstatePlot;

  public readonly estateHelpLink = "186_One%20Plot%20in%20the%20Estate.htm";

  public selectedRegime = null;
  public minRotationLengthInDays = null; //assuming 1 year = 365

  public isNewForm: boolean = true;

  public estateStartDate: number;
  public estateStep: number;

  public selectedPlotFiles: PlotTableColumns[] = [];

  ngOnInit(): void {
    this.formGroup = this.service.getEstatePlotFormGroupTemplate();
    const plotFilesService: PlotFilesService =
      this.service.simulationService.plotFilesService;

    if (plotFilesService) {
      const plotFilesControl = plotFilesService.getFormGroup().get("plotFiles");

      this.selectedPlotFiles = plotFilesControl.value;
    }

    this.formGroup
      .get("estateTimingEP")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.togglePlotStepValidation(newValue);
      });

    this.formGroup
      .get("hasAreaEP")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.toggleAreaValidation(newValue);
      });

    if (this.formData) {
      this.formGroup.patchValue(this.formData);
    }
  }

  private toggleAreaValidation(hasAreaEP: boolean): void {
    this.service[!hasAreaEP ? "addValidatorsInBulk" : "removeValidatorsInBulk"](
      ["areaEP"],
      [Validators.required, Validators.min(0)],
      this.formGroup
    );
  }

  private togglePlotStepValidation(estateTimingEP: boolean): void {
    const timingService = this.service.simulationService.timingService;
    const timingSteps = timingService.getSteps();

    this.service[
      !estateTimingEP ? "addValidatorsInBulk" : "removeValidatorsInBulk"
    ](
      ["startStepEP"],
      [Validators.required, Validators.min(1), Validators.max(timingSteps)],
      this.formGroup
    );

    this.service[
      !estateTimingEP ? "addValidatorsInBulk" : "removeValidatorsInBulk"
    ](
      ["startYrEP"],
      [Validators.required, Validators.min(-999), Validators.max(9999)],
      this.formGroup
    );
  }
}
