import { Injectable } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { Constants } from "src/app/shared/constants";
import {
  FieldWatcher,
  FormModel,
  SimulationService,
} from "src/app/shared/models";
import TimeUtilities from "src/app/shared/utilities/time";

import { FormGroupElement, FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
/******************all xml fields********************/
const writeXmlInitTreeFields = [
  "treeExistsInit",
  "maxTreeAgeInit",
  "avgTreeAgeInit",
  "tInitStem",
  "stemMInitF",
  "branMInitF",
  "barkMInitF",
  "leafMInitF",
  "cortMInitF",
  "firtMInitF",
  "stemVolInitF",
  "stemFracInitF",
  "branFracInitF",
  "barkFracInitF",
  "leafFracInitF",
  "cortFracInitF",
  "firtFracInitF",
  "stemNCRatioInitF",
  "branNCRatioInitF",
  "barkNCRatioInitF",
  "leafNCRatioInitF",
  "cortNCRatioInitF",
  "firtNCRatioInitF",
  "storNMInitF",
  "stemM3PGInit",
  "foliM3PGInit",
  "rootM3PGInit",
  "nStemsInit",
  "tTYFCatInitF",
  "frFracInit",
  "tFrFracInit",
  "treeNmInit",
  "agRegimeId",
];
const writeXmlInitCropFields = [
  "cropExistsInit",
  "maxCropAgeInit",
  "avgCropAgeInit",
  "gbfrMInitA",
  "stlkMInitA",
  "leafMInitA",
  "cortMInitA",
  "firtMInitA",
  "gbfrNCRatioInitA",
  "stlkNCRatioInitA",
  "leafNCRatioInitA",
  "cortNCRatioInitA",
  "firtNCRatioInitA",
  "storNMInitA",
  "cropNmInit",
];

const writeXmlInitSDwdFFields = [
  "sDStemCMInitF",
  "sDBranCMInitF",
  "sDBlitCMInitF",
  "sDLlitCMInitF",
  "sDCodrCMInitF",
  "sDFidrCMInitF",
];

const writeXmlInitDebrFFields = [
  "dDdwdCMInitF",
  "rDdwdCMInitF",
  "dChwdCMInitF",
  "rChwdCMInitF",
  "dBlitCMInitF",
  "rBlitCMInitF",
  "dLlitCMInitF",
  "rLlitCMInitF",
  "dCodrCMInitF",
  "rCodrCMInitF",
  "dFidrCMInitF",
  "rFidrCMInitF",
  "dDdwdNCRatioInitF",
  "dChwdNCRatioInitF",
  "dBlitNCRatioInitF",
  "dLlitNCRatioInitF",
  "dCodrNCRatioInitF",
  "dFidrNCRatioInitF",
];

const writeXmlInitDebrAFields = [
  "dGlitCMInitA",
  "rGlitCMInitA",
  "dSlitCMInitA",
  "rSlitCMInitA",
  "dLlitCMInitA",
  "rLlitCMInitA",
  "dCodrCMInitA",
  "rCodrCMInitA",
  "dFidrCMInitA",
  "rFidrCMInitA",
  "dGlitNCRatioInitA",
  "dSlitNCRatioInitA",
  "dLlitNCRatioInitA",
  "dCodrNCRatioInitA",
  "dFidrNCRatioInitA",
];

const writeXmlInitMulcFFields = [
  "sopmCMInitF",
  "lrpmCMInitF",
  "mrpmCMInitF",
  "sommCMInitF",
  "lrmmCMInitF",
  "mrmmCMInitF",
  "micrCMInitF",
  "sopmNCRatioInitF",
  "sommNCRatioInitF",
  "micrNCRatioInitF",
];
const writeXmlInitMulcAFields = [
  "sopmCMInitA",
  "lrpmCMInitA",
  "mrpmCMInitA",
  "sommCMInitA",
  "lrmmCMInitA",
  "mrmmCMInitA",
  "micrCMInitA",
  "sopmNCRatioInitA",
  "sommNCRatioInitA",
  "micrNCRatioInitA",
];
const writeXmlInitSoilFFields = [
  "dpmaCMInitF",
  "rpmaCMInitF",
  "biofCMInitF",
  "biosCMInitF",
  "humsCMInitF",
  "dpmaNCRatioInitF",
  "rpmaNCRatioInitF",
  "biofNCRatioInitF",
  "biosNCRatioInitF",
  "humsNCRatioInitF",
  "inrtCMInitF",
  "inrtNCRatioInitF",
  "ASWInitF",
  "TSMDInitF",
];
const writeXmlInitSoilAFields = [
  "dpmaCMInitA",
  "rpmaCMInitA",
  "biofCMInitA",
  "biosCMInitA",
  "humsCMInitA",
  "dpmaNCRatioInitA",
  "rpmaNCRatioInitA",
  "biofNCRatioInitA",
  "biosNCRatioInitA",
  "humsNCRatioInitA",
  "inrtCMInitA",
  "inrtNCRatioInitA",
  "TSMDInitA",
];
const writeXmlInitMnrlFFields = [
  "mulcNMInitF",
  "sAmmNMInitF",
  "sNtrNMInitF",
  "dAmmNMInitF",
  "dNtrNMInitF",
];
const writeXmlInitMnrlAFields = [
  "mulcNMInitA",
  "sAmmNMInitA",
  "sNtrNMInitA",
  "dAmmNMInitA",
  "dNtrNMInitA",
];
const writeXmlInitProdFFields = [
  "fuelCMInitF",
  "paprCMInitF",
  "packCMInitF",
  "furnCMInitF",
  "fibrCMInitF",
  "consCMInitF",
  "resiCMInitF",
  "fuelLfCMInitF",
  "paprLfCMInitF",
  "packLfCMInitF",
  "furnLfCMInitF",
  "fibrLfCMInitF",
  "consLfCMInitF",
  "resiLfCMInitF",
  "fuelNCRatioInitF",
  "paprNCRatioInitF",
  "packNCRatioInitF",
  "furnNCRatioInitF",
  "fibrNCRatioInitF",
  "consNCRatioInitF",
  "resiNCRatioInitF",
];
const writeXmlInitProdAFields = [
  "fualCMInitA",
  "gbfpCMInitA",
  "caneCMInitA",
  "lfprCMInitA",
  "rtprCMInitA",
  "hssiCMInitA",
  "anprCMInitA",
  "fualNCRatioInitA",
  "gbfpNCRatioInitA",
  "caneNCRatioInitA",
  "lfprNCRatioInitA",
  "rtprNCRatioInitA",
  "hssiNCRatioInitA",
  "anprNCRatioInitA",
];
const writeXmlInitWholPFields = ["tFrFracInit", "frFracInit"];
/******************all xml fields********************/

const initTreeFields = [
  "avgTreeAgeInit",
  "maxTreeAgeInit",
  "stemVolInitF",
  "branMInitF",
  "barkMInitF",
  "leafMInitF",
  "cortMInitF",
  "firtMInitF",
  "tInitStem",
  "stemMInitF",
  "stemFracInitF",
  "branFracInitF",
  "barkFracInitF",
  "leafFracInitF",
  "cortFracInitF",
  "firtFracInitF",
];

const treeStandingDeadFields = [
  "sDStemCMInitF",
  "sDBranCMInitF",
  "sDBlitCMInitF",
  "sDLlitCMInitF",
  "sDCodrCMInitF",
  "sDFidrCMInitF",
];

const treeDebrisFields = [
  "dFidrCMInitF",
  "dDdwdCMInitF",
  "rDdwdCMInitF",
  "dBlitCMInitF",
  "rBlitCMInitF",
  "dLlitCMInitF",
  "rLlitCMInitF",
  "dCodrCMInitF",
  "rCodrCMInitF",
  "rFidrCMInitF",
  "dChwdCMInitF",
  "rChwdCMInitF",
];

const treeProductFields = [
  "fuelCMInitF",
  "paprCMInitF",
  "packCMInitF",
  "furnCMInitF",
  "fibrCMInitF",
  "consCMInitF",
  "resiCMInitF",
  "fuelLfCMInitF",
  "paprLfCMInitF",
  "packLfCMInitF",
  "furnLfCMInitF",
  "fibrLfCMInitF",
  "consLfCMInitF",
  "consLfCMInitF",
  "resiLfCMInitF",
];
const initCropFields = [
  "leafMInitA",
  "cortMInitA",
  "firtMInitA",
  "maxCropAgeInit",
  "avgCropAgeInit",
  "gbfrMInitA",
  "stlkMInitA",
];

const cropDebrisFields = [
  "dGlitCMInitA",
  "rGlitCMInitA",
  "dSlitCMInitA",
  "rLlitCMInitA",
  "dCodrCMInitA",
  "rCodrCMInitA",
  "dFidrCMInitA",
  "rFidrCMInitA",
  "rSlitCMInitA",
  "dLlitCMInitA",
];

const cropProductFields = [
  "gbfpCMInitA",
  "caneCMInitA",
  "lfprCMInitA",
  "rtprCMInitA",
  "hssiCMInitA",
  "anprCMInitA",
  "fualCMInitA",
];

const soilFields = [
  "dpmaCMInitF",
  "rpmaCMInitF",
  "biofCMInitF",
  "biosCMInitF",
  "humsCMInitF",
  "inrtCMInitF",
  "TSMDInitF",
];

const cropSoilFields = [
  "dpmaCMInitA",
  "rpmaCMInitA",
  "biofCMInitA",
  "biosCMInitA",
  "humsCMInitA",
  "inrtCMInitA",
  "TSMDInitA",
];

const treesGroups: FormGroupElement[] = [
  {
    label: "Trees",
    id: "trees-group",
    isShown: true,
    showErrors: true,
    isAccordion: true,
    helpLink: "185_Initial%20Trees.htm",
    items: [
      {
        groups: [
          {
            label: null, //placeholder group for displaying error in the init tree group
            isShown: false,
            showErrors: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "treeNmInit",
                  },
                ],
              },
            ],
          },

          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "SpeciesSelectorComponent",
                    componentInputs: [
                      {
                        inputKey: "formGroupInstance",
                        method: "getSpeciesFormGroup",
                        payload: "tree",
                      },
                      {
                        inputKey: "selectedId$",
                        variable: "initTreeId$",
                        isObservable: true,
                      },
                      {
                        inputKey: "selectOnly",
                        value: true,
                      },
                      {
                        inputKey: "type",
                        value: "tree",
                      },
                    ],
                    componentOutputs: [
                      {
                        outputKey: "speciesSelected",
                        method: "selectSpecies",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: "Existence",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label:
                  "The forest has trees growing in it at the start of the simulation",
                inputs: [
                  {
                    element: "input",
                    type: "switch",
                    programmingName: "treeExistsInit",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            isBorderedContainer: true,
            id: "init-tree-group",
            showErrors: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: { type: "tree", categoryName: "InitFT" },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "tree", categoryName: "InitFT" },
                  },
                ],
              },
              {
                groups: [
                  {
                    isShown: true,
                    id: "init-specify-tree-size",
                    label: null,
                    showErrors: true,
                    isUnderlineHeader: true,
                    items: [
                      {
                        label: "Specify tree size by",
                        inputs: [
                          {
                            element: "select",
                            selectOptions: [
                              {
                                value: "Mass",
                                label: "Masses",
                              },
                              {
                                value: "Vol",
                                label:
                                  "Volume for stems, masses for other components",
                              },
                              {
                                value: "Frac",
                                label: "Percentage of maximum tree biomass",
                              },
                              {
                                value: "FracAge",
                                label: "Percentage of tree age biomass",
                              },
                            ],
                            programmingName: "tInitStem",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    isShown: true,
                    id: "init-tree-mass",
                    label: "Masses [dmt/ha]",
                    isUnderlineHeader: true,
                    isUnderlineItems: true,
                    showErrors: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Masses [dmt/ha]",
                              },

                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: [],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Stems",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "stemMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Branches",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "branMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Bark",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "barkMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaves",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "leafMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Coarse roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "cortMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fine roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "firtMInitF",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    isShown: true,
                    id: "init-tree-percentage-biomass",
                    label: "% of Maximum Tree Biomass",
                    isUnderlineHeader: true,
                    isUnderlineItems: true,
                    showErrors: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Masses [dmt/ha]",
                              },
                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: [],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Stems",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "stemFracInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Branches",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "branFracInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Bark",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "barkFracInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaves",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "leafFracInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Coarse roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "cortFracInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fine roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "firtFracInitF",
                                      },
                                    ],

                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Total",
                                      },
                                      {
                                        element: "text",
                                        method: "getTotal",
                                        payload: [
                                          "stemFracInitF",
                                          "branFracInitF",
                                          "barkFracInitF",
                                          "leafFracInitF",
                                          "cortFracInitF",
                                          "firtFracInitF",
                                        ],
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  {
                    isShown: true,
                    label: "Volumes [m3/ha]",
                    id: "init-tree-vol",
                    isUnderlineHeader: true,
                    isUnderlineItems: true,
                    showErrors: true,
                    items: [
                      {
                        label: "Stems",
                        shoartLabel: "",
                        inputs: [
                          {
                            element: "input",
                            type: "number",
                            programmingName: "stemVolInitF",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    isShown: true,
                    label: "Ages [yr]",
                    isUnderlineHeader: true,
                    showErrors: true,
                    items: [
                      {
                        label: "Ages of oldest trees",
                        shoartLabel: "",
                        inputs: [
                          {
                            element: "input",
                            type: "number",
                            programmingName: "maxTreeAgeInit",
                          },
                        ],
                      },
                      {
                        label: "Average age of trees",
                        shoartLabel: "",
                        inputs: [
                          {
                            element: "input",
                            type: "number",
                            programmingName: "avgTreeAgeInit",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    isShown: true,
                    id: "tyf-growth-calibration",
                    label: "Tree yield formula: Growth calibration",
                    showErrors: true,
                    items: [
                      {
                        label: "Growth calibration",
                        inputs: [
                          {
                            element: "select",
                            selectOptions: [
                              {
                                value: "Custom",
                                label: "User defined",
                              },
                              {
                                value: "BeltH",
                                label: "Belt plantings >1500 sph",
                              },
                              {
                                value: "BeltL",
                                label: "Belt plantings <1500 sph",
                              },
                              {
                                value: "BlockES",
                                label: "Block - environmental services",
                              },
                              {
                                value: "BlockLMG",
                                label: "Block - regeneration systems",
                              },
                              {
                                value: "Water",
                                label: "In riparian or floodplain zones",
                              },
                              {
                                value: "Short",
                                label: "Short rotation plantations",
                              },
                              {
                                value: "Long",
                                label: "Long rotation plantations",
                              },
                            ],
                            programmingName: "tTYFCatInitF",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const standingDeadGroups: FormGroupElement[] = [
  {
    label: "Standing dead",
    id: "standing-dead-group",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    helpLink: "284_Initial%20StandingDead.htm",
    items: [
      {
        groups: [
          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label:
                  "All standing dead is of the initial tree species, namely:",
                inputs: [
                  {
                    element: "text",
                    method: "getInitSpeciesName",
                    payload: "tree",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            isBorderedContainer: true,
            showErrors: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: {
                      type: "tree",
                      categoryName: "InitFSd",
                    },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "tree", categoryName: "InitFSd" },
                  },
                ],
              },
              {
                groups: [
                  {
                    label: "Carbon masses [tC/ha]",
                    isShown: true,
                    isUnderlineHeader: true,
                    showErrors: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Carbon masses [tC/ha]",
                              },
                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: ["", "Standing dead"],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Stems",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "sDStemCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Branch",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "sDBranCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Bark",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "sDBlitCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaf",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "sDLlitCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Coarse dead roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "sDCodrCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fine dead roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "sDFidrCMInitF",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const debrisGroups: FormGroupElement[] = [
  {
    label: "Debris",
    id: "debris-group",
    isAccordion: true,
    showErrors: true,
    isShown: true,
    helpLink: "31_Initial%20Debris.htm",
    items: [
      {
        groups: [
          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "All debris are of the initial tree species, namely:",
                inputs: [
                  {
                    element: "text",
                    method: "getInitSpeciesName",
                    payload: "tree",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            isBorderedContainer: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: { type: "tree", categoryName: "InitFD" },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "tree", categoryName: "InitFD" },
                  },
                ],
              },
              {
                groups: [
                  {
                    label: "Carbon masses [tC/ha]",
                    isShown: true,
                    showErrors: true,
                    isUnderlineHeader: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Carbon masses [tC/ha]",
                              },
                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: ["", "Decomposable", "Resistant"],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Deadwood",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dDdwdCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rDdwdCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Chopped wood",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dChwdCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rChwdCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Bark litter",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dBlitCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rBlitCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaf litter",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dLlitCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rLlitCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Coarse dead roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dCodrCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rCodrCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fine dead roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dFidrCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rFidrCMInitF",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const soilGroups: FormGroupElement[] = [
  {
    label: "Soil",
    id: "soil-group",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    helpLink: "32_Initial%20Soil.htm",
    items: [
      {
        groups: [
          {
            label: "Carbon Masses [tC/ha]",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Carbon Masses [tC/ha]",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "DPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "dpmaCMInitF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "RPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "rpmaCMInitF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "biofCMInitF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "biosCMInitF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "humsCMInitF",
                                width: "120px",
                              },
                            ],

                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "Inert soil",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "inrtCMInitF",
                                width: "120px",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: "Water [mm]",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "Topsoil moisture deficit (TSMD)",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "TSMDInitF",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const productGroups: FormGroupElement[] = [
  {
    label: "Products",
    id: "products-group",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    helpLink: "33_Initial%20Products.htm",
    items: [
      {
        groups: [
          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "All products are of the initial tree species, namely:",
                inputs: [
                  {
                    element: "text",
                    method: "getInitSpeciesName",
                    payload: "tree",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            isBorderedContainer: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: { type: "tree", categoryName: "InitFP" },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "tree", categoryName: "InitFP" },
                  },
                ],
              },
              {
                groups: [
                  {
                    label: "Carbon masses [tC/ha]",
                    isShown: true,
                    isUnderlineHeader: true,
                    showErrors: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Carbon masses [tC/ha]",
                              },
                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: [
                                    "",
                                    "Used As Products",
                                    "In Landfill",
                                  ],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Biofuel",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "fuelCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "fuelLfCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Pulp and paper",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "paprCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "paprLfCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Packing wood",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "packCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "packLfCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Furniture, poles",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "furnCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "furnLfCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fiberboard",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "fibrCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "fibrLfCMInitF",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Construction wood",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "consCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "consLfCMInitF",
                                      },
                                    ],

                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Mill residue",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "resiCMInitF",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "resiLfCMInitF",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const cropsGroups: FormGroupElement[] = [
  {
    label: "Crops",
    id: "crops-group",
    isShown: true,
    showErrors: true,
    isAccordion: true,
    helpLink: "184_Initial%20Crops.htm",
    items: [
      {
        groups: [
          {
            label: null, //placeholder group for displaying error in the init crop group
            isShown: false,
            showErrors: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "cropNmInit",
                  },
                ],
              },
            ],
          },

          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "SpeciesSelectorComponent",
                    componentInputs: [
                      {
                        inputKey: "formGroupInstance",
                        method: "getSpeciesFormGroup",
                        payload: "crop",
                      },
                      {
                        inputKey: "selectedId$",
                        variable: "initCropId$",
                        isObservable: true,
                      },
                      {
                        inputKey: "selectOnly",
                        value: true,
                      },
                      {
                        inputKey: "type",
                        value: "crop",
                      },
                    ],
                    componentOutputs: [
                      {
                        outputKey: "speciesSelected",
                        method: "selectSpecies",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: "Existence",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label:
                  "The agricultural system has a crop growing in it at the start of the simulation",
                inputs: [
                  {
                    element: "input",
                    type: "switch",
                    programmingName: "cropExistsInit",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            id: "init-crop-group",
            showErrors: true,
            isBorderedContainer: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: { type: "crop", categoryName: "InitAC" },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "crop", categoryName: "InitAC" },
                  },
                ],
              },

              {
                groups: [
                  {
                    label: "Masses [dmt/ha]",
                    id: "init-crop-masses-group",
                    isShown: true,
                    isUnderlineHeader: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Masses [dmt/ha]",
                              },
                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: [],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Grains, buds, and fruit (GBF)",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "gbfrMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Stalks",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "stlkMInitA",
                                      },
                                    ],

                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaves",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "leafMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Coarse roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "cortMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fine roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "firtMInitA",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  {
                    isShown: true,
                    label: "Ages [yr]",
                    id: "init-crop-ages-group",
                    showErrors: true,
                    isUnderlineHeader: true,
                    items: [
                      {
                        label: "Ages of oldest plants",
                        shoartLabel: "",
                        inputs: [
                          {
                            element: "input",
                            type: "number",
                            programmingName: "maxCropAgeInit",
                          },
                        ],
                      },
                      {
                        label: "Average age of plants",
                        shoartLabel: "",
                        inputs: [
                          {
                            element: "input",
                            type: "number",
                            programmingName: "avgCropAgeInit",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const cropDebrisGroups: FormGroupElement[] = [
  {
    label: "Debris",
    id: "crops-debris-group",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    helpLink: "31_Initial%20Debris.htm",
    items: [
      {
        groups: [
          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "All debris are of the initial crop species, namely:",
                inputs: [
                  {
                    element: "text",
                    method: "getInitSpeciesName",
                    payload: "crop",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            isBorderedContainer: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: { type: "crop", categoryName: "InitAD" },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "crop", categoryName: "InitAD" },
                  },
                ],
              },
              {
                groups: [
                  {
                    label: "Carbon masses [tC/ha]",
                    isShown: true,
                    isUnderlineHeader: true,
                    showErrors: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Carbon masses [tC/ha]",
                              },

                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: ["", "Decomposable", "Resistant"],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "GBF litter",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dGlitCMInitA",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rGlitCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Stalk litter",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dSlitCMInitA",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rSlitCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaf litter",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dLlitCMInitA",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rLlitCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Coarse dead roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dCodrCMInitA",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rCodrCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Fine dead roots",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "dFidrCMInitA",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rFidrCMInitA",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const cropSoilGroups: FormGroupElement[] = [
  {
    label: "Soil",
    id: "crop-soil-group",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    helpLink: "32_Initial%20Soil.htm",
    items: [
      {
        groups: [
          {
            label: "Carbon Masses [tC/ha]",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Carbon Masses [tC/ha]",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "DPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "dpmaCMInitA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "RPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "rpmaCMInitA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "biofCMInitA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "biosCMInitA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "humsCMInitA",
                                width: "120px",
                              },
                            ],

                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "Inert soil",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "inrtCMInitA",
                                width: "120px",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: "Water [mm]",
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            items: [
              {
                label: "Topsoil moisture deficit (TSMD)",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "TSMDInitA",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const cropProductGroups: FormGroupElement[] = [
  {
    label: "Products",
    id: "crops-products-group",
    isAccordion: true,
    isShown: true,
    showErrors: true,
    helpLink: "33_Initial%20Products.htm",
    items: [
      {
        groups: [
          {
            label: "Species",
            isShown: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "All products are of the initial crop species, namely:",
                inputs: [
                  {
                    element: "text",
                    method: "getInitSpeciesName",
                    payload: "crop",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            isShown: true,
            isBorderedContainer: true,
            items: [
              {
                label: "",
                inputs: [
                  {
                    buttonLabel: "Insert standard values",
                    showButtonMethod: "showButtonMethod",
                    showButtonPayload: { type: "crop", categoryName: "InitAP" },
                    element: "button",
                    submitMethod: "insertStandardValues",
                    payload: { type: "crop", categoryName: "InitAP" },
                  },
                ],
              },
              {
                groups: [
                  {
                    label: "Carbon masses [tC/ha]",
                    isShown: true,
                    isUnderlineHeader: true,
                    showErrors: true,
                    items: [
                      {
                        label: null,
                        inputs: [
                          {
                            element: "component",
                            component: "FormItemTableComponent",
                            componentInputs: [
                              {
                                inputKey: "caption",
                                value: "Carbon masses [tC/ha]",
                              },
                              {
                                inputKey: "service",
                                method: "getService",
                              },
                              {
                                inputKey: "data",
                                value: {
                                  headers: ["", "Used As Products"],
                                  rows: [
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Biofuel",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "fualCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Grains, buds, fruit",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "gbfpCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Canes",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "caneCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Leaf products",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "lfprCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Root products",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "rtprCMInitA",
                                      },
                                    ],
                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Hay, straw, silage",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "hssiCMInitA",
                                      },
                                    ],

                                    [
                                      {
                                        element: "text",
                                        isHeader: "true",
                                        value: "Animal products",
                                      },
                                      {
                                        element: "input",
                                        type: "number",
                                        programmingName: "anprCMInitA",
                                      },
                                    ],
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class InitialConditions2020Service extends BasePlotFormService {
  public readonly pageId = "initialConditions";
  public readonly pageHelpLink = "205_Initial%20Conditions.htm";

  public readonly categoryIds = [
    56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 72, 72, 99,
  ];
  public readonly initTreeId$ = new BehaviorSubject<number>(-1); //for placeholder select idRegimeSP
  public readonly initCropId$ = new BehaviorSubject<number>(-1); //for placeholder select idRegimeSP

  public readonly layout: FormLayout = {
    label: "Initial conditions",
    groups: [
      {
        label: "Forest",
        id: "forest-group",
        isShown: true,
        isRoot: true,
        isAccordion: true,
        showErrors: true,
        items: [
          { groups: treesGroups },
          { groups: standingDeadGroups },
          { groups: debrisGroups },
          { groups: soilGroups },
          { groups: productGroups },
        ],
      },
      {
        label: "Agricultural",
        id: "agricultural-group",
        showErrors: true,
        isAccordion: true,
        isShown: true,
        isRoot: true,
        items: [
          { groups: cropsGroups },
          { groups: cropDebrisGroups },
          { groups: cropSoilGroups },
          { groups: cropProductGroups },
        ],
      },
      {
        label: "Whole Plot",
        id: "whole-Plot-group",
        showErrors: true,
        isShown: true,
        isRoot: true,
        helpLink: "197_Initial%20Conditions%20For%20the%20Whole%20Plot.htm",
        items: [
          {
            label: "Percentage of the plot that is forest, by area",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "frFracInit",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    avgTreeAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Average age of trees",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 142,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxTreeAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Age of oldest trees",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 143,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemVolInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stem volume",
      unit: "m3/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 144,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Branch mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 145,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Bark mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 146,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Leaf mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 147,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    cortMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Coarse root mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 148,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    firtMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Fine root mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 149,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    stemM3PGInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Stem mass (3PG)",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 151,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    foliM3PGInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Foliage mass (3PG)",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 152,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rootM3PGInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Root mass (3PG)",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 153,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    nStemsInit: {
      high: 2100200300,
      low: 1e-9,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Stems per hectare",
      unit: "1/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 154,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stems",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 1,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 506,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    branNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "branches",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 2,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 507,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    barkNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "bark",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 3,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 508,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    leafNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 4,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 509,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    cortNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 5,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 510,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    firtNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 6,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 511,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    storNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in tree store",
      unit: "kgN/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 512,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    tInitStem: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 185,
      defaultValue: "Vol",
      validators: [],
      validatorConfig: [],
      label: "Method of specifying initial sizes of tree components",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "InitStemT",
      eventId: 10,
      tIn: 628,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeExistsInit: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 185,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Trees initially growing in the forest (yes/no)",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1079,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    treeNmInit: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "maxLength",
          input: 999,
        },
      ],
      label: "Name of initial tree species",
      unit: "",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1131,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stem mass",
      unit: "dmt/ha",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1137,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    stemFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Stem",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1138,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    branFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Branch",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1139,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    barkFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Bark",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1140,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    leafFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Leaf",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1141,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    cortFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Coarse root",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1142,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    firtFracInitF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 185,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Fine root",
      unit: "%",
      categoryName: "InitFT",
      categoryLabel: "Initial conditions : Forest : Trees",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1143,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 101,
    },
    dFidrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 21,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dDdwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable deadwood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 155,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rDdwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant deadwood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 156,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dBlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable bark litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 157,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rBlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant bark litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 158,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dLlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable leaf litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 159,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rLlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant leaf litter",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 160,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dCodrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 161,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rCodrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 162,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rFidrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant fine dead roots",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 163,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dDdwdNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable deadwood",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 513,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dBlitNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable bark litter",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 514,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dLlitNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable leaf litter",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 515,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dCodrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable coarse dead roots",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 516,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dFidrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable fine dead roots",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 517,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dChwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable chopped wood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1044,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    rChwdCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant chopped wood",
      unit: "tC/ha",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1045,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dChwdNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable chopped wood",
      unit: "",
      categoryName: "InitFD",
      categoryLabel: "Initial conditions : Forest : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1046,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sopmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 164,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 51,
    },
    lrpmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 165,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 51,
    },
    mrpmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 166,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 51,
    },
    sommCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 167,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 50,
    },
    lrmmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 168,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 50,
    },
    mrmmCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 169,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 50,
    },
    micrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "tC/ha",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 170,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sopmNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 518,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 51,
    },
    sommNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 519,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 50,
    },
    micrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "InitFM",
      categoryLabel: "Initial conditions : Forest : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 18,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 520,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    ASWInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Available soil water (ASW)",
      unit: "mm",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 150,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dpmaCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "DPM",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 172,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rpmaCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "RPM",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 173,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    biofCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-F",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 174,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    biosCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-S",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 175,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    humsCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "HUM",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 176,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    TSMDInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Forest topsoil moisture deficit (TSMD)",
      unit: "mm",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 177,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    inrtCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "inert soil",
      unit: "tC/ha",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 178,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    dpmaNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 523,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    rpmaNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 524,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    biofNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 12,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 525,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    biosNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 13,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 526,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    humsNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 14,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 527,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    inrtNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "inert soil",
      unit: "",
      categoryName: "InitFS",
      categoryLabel: "Initial conditions : Forest : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 528,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    mulcNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "available nitrogen",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial conditions : Forest : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 171,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sAmmNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow ammonium (with forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial conditions : Forest : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 237,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 104,
    },
    sNtrNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow nitrate (with forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial conditions : Forest : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 238,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 104,
    },
    dAmmNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep ammonium (below forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial conditions : Forest : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1083,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 104,
    },
    dNtrNMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep nitrate (below forest",
      unit: "kgN/ha",
      categoryName: "InitFN",
      categoryLabel: "Initial conditions : Forest : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1378,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 104,
    },
    fuelCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "biofuel stocks",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 529,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    paprCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "pulp and paper",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 530,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    packCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "packing wood",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 531,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    furnCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "furniture,poles,and preservatives",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 532,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    fibrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fiberboard",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 533,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    consCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "construction timber",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 534,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    resiCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "mill residue",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 535,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 20,
    },
    fuelNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "biofuel",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 536,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    paprNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pulp and paper",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 537,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    packNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "packing wood",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 538,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    furnNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "furniture,poles,and preservatives",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 539,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fibrNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fiberboard",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 540,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    consNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "construction timber",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 541,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    resiNCRatioInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mill residue",
      unit: "",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 542,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fuelLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "forest biofuel",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 802,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    paprLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "pulp and paper",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 803,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    packLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "packing wood",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 804,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    furnLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "furniture,poles,and preservatives",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 805,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    fibrLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "fiberboard",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 806,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    consLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "construction timber",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 807,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    resiLfCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "mill residue",
      unit: "tC/ha",
      categoryName: "InitFP",
      categoryLabel: "Initial conditions : Forest : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 808,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 23,
    },
    leafMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Leaf mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 41,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    cortMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Coarse root mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 42,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    firtMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Fine root mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 43,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxCropAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Age of oldest plants",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 137,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    avgCropAgeInit: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Average age of plants",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 179,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    gbfrMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Grains,buds,and fruit mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 180,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Stalk mass",
      unit: "dmt/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 181,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 7,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 543,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    stlkNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "stalks",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 8,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 544,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    leafNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaves",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 9,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 545,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    cortNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "coarse roots",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 10,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 546,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    firtNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "fine roots",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 11,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 547,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    storNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen mass in plant store",
      unit: "kgN/ha",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 548,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    cropExistsInit: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Plants initially growing in the agricultural system (yes/no)",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1080,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    cropNmInit: {
      high: 2100200300,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 184,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "maxLength",
          input: 999,
        },
      ],
      label: "Name of initial crop species",
      unit: "",
      categoryName: "InitAC",
      categoryLabel: "Initial conditions : Agricultural : Crops",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1132,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dGlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable grains-buds-fruit litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 44,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rGlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant grains-buds-fruit litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 45,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dSlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable stalk litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 46,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rLlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant leaf litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 49,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dCodrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 50,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rCodrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant coarse dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 51,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dFidrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 52,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rFidrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant fine dead roots",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 53,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rSlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "resistant stalk litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 182,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dLlitCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "decomposable leaf litter",
      unit: "tC/ha",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 183,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dGlitNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable grains-buds-fruit litter",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 549,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dSlitNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable stalk litter",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 550,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    dLlitNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable leaf litter",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 551,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dCodrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable coarse dead roots",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 552,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dFidrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable fine dead roots",
      unit: "",
      categoryName: "InitAD",
      categoryLabel: "Initial conditions : Agricultural : Debris",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 553,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sopmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 54,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    lrpmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 55,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    mrpmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 56,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    sommCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 57,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    lrmmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 58,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    mrmmCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 59,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    micrCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "tC/ha",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 60,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sopmNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 554,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 53,
    },
    sommNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble",
      unit: "",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 555,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 52,
    },
    micrNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 37,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "",
      categoryName: "InitAM",
      categoryLabel: "Initial conditions : Agricultural : Mulch",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 19,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 556,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dpmaCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "DPM",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 62,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rpmaCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "RPM",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 63,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biofCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-F",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 64,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biosCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-S",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 65,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    humsCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "HUM",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 66,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    TSMDInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Agricultural topsoil moisture deficit (TSMD)",
      unit: "mm",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 67,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    inrtCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "inert soil",
      unit: "tC/ha",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 68,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    dpmaNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 557,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    rpmaNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 558,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biofNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 15,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 559,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    biosNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 16,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 560,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    humsNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 17,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 561,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    inrtNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 32,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "inert soil",
      unit: "",
      categoryName: "InitAS",
      categoryLabel: "Initial conditions : Agricultural : Soil",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 562,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    mulcNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural mineralized nitrogen",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial conditions : Agricultural : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 61,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sAmmNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow ammonium (with agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial conditions : Agricultural : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1379,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    sNtrNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "shallow nitrate (with agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial conditions : Agricultural : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1380,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    dAmmNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep ammonium (below agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial conditions : Agricultural : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1381,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    dNtrNMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 100,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "deep nitrate (below agricultural",
      unit: "kgN/ha",
      categoryName: "InitAN",
      categoryLabel: "Initial conditions : Agricultural : Minerals",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 103,
      isRisk: true,
      scale: 1000,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1382,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 104,
    },
    gbfpCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "grains-buds-fruit products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 563,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    caneCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "canes",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 564,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    lfprCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "leaf products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 565,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rtprCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "root products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 566,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    hssiCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "hay-straw-silage",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 567,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    anprCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "animal products",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 568,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    gbfpNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "grains-buds-fruit products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 569,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    caneNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "canes",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 570,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    lfprNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "leaf products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 571,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rtprNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "root products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 572,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    hssiNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "hay-straw-silage",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 573,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    anprNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "animal products",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 574,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fualCMInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "biofuel stocks",
      unit: "tC/ha",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 172,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 809,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fualNCRatioInitA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 33,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "biofuel stocks",
      unit: "",
      categoryName: "InitAP",
      categoryLabel: "Initial conditions : Agricultural : Products",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 56,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 810,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    frFracInit: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 197,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Forest % (manually entered)",
      unit: "%",
      categoryName: "InitWP",
      categoryLabel: "Initial conditions : Whole plot",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 133,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFrFracInit: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 197,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of initial forest %",
      unit: "",
      categoryName: "InitWP",
      categoryLabel: "Initial conditions : Whole plot",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrFracT",
      eventId: 10,
      tIn: 606,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tTYFCatInitF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 158,
      defaultValue: "Custom",
      validators: [],
      validatorConfig: [],
      label: "Initial Growth Calibration ",
      unit: "",
      categoryName: "EvPlnF",
      categoryLabel: "Tree planting event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TYFCatT",
      eventId: 10,
      tIn: 2120,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDStemCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead stem ",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial conditions : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2158,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDBranCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead branch",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial conditions : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2159,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDBlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead bark",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial conditions : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2160,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDLlitCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead leaf",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial conditions : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2161,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDCodrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead coarse roots",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial conditions : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2162,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sDFidrCMInitF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 31,
      defaultValue: 0,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "initial standing dead fine roots",
      unit: "tC/ha",
      categoryName: "InitFSd",
      categoryLabel: "Initial conditions : Forest : Standing Dead",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 102,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2163,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
  };

  public speciesDeleted({ id, idRegimeSP, speciesType }) {
    const idSource =
      speciesType == "tree" ? this.initTreeId$ : this.initCropId$;
    if (idSource.getValue() == idRegimeSP) {
      this.selectSpecies({ id: -1, type: speciesType, name: null });
      //idSource.next(-1);
    }
  }

  public getSpeciesFormGroup(type: "tree" | "crop") {
    const service = type == "tree" ? "treesService" : "cropsService";
    return this.simulationService[service].getFormGroup();
  }

  public selectSpecies({ id, type, name }): void {
    const idSource = type == "tree" ? this.initTreeId$ : this.initCropId$;
    idSource.next(id);

    this.setInitSpeciesName({ type, name });
    const species = this.getSpeciesFormGroup(type).get("species").value;
    const speciesFormData = species.find((s) => s.idRegimeSP == id);
    // const idRegimeSP = speciesFormData?.idRegimeSP;

    // if (!idRegimeSP) {
    //   return;
    // }

    if (type == "tree") {
      const tyfCategories =
        this.simulationService.treesService.getTYFCategoryBySpeciesNmSP(name);
      const tyfGroup = this.getLayoutGroup(
        this.layout.groups,
        "tyf-growth-calibration"
      );

      if (tyfCategories) {
        tyfGroup.items[0].inputs[0].selectOptions = tyfCategories;
      } else {
        const customCatetories = [
          {
            value: null,
            label: "",
          },
          {
            value: "Custom",
            label: "User defined",
          },
        ];
        tyfGroup.items[0].inputs[0].selectOptions = customCatetories;
      }

      //wait for the view to update first
      setTimeout(() => {
        this.formGroup
          .get("tTYFCatInitF")
          .setValue(this.formGroup.get("tTYFCatInitF").value);
      }, 0);
    }

    if (type == "crop") {
      const growthMethod = speciesFormData.grthModeSP;
      const initCropFields = [
        "gbfrMInitA",
        "stlkMInitA",
        "leafMInitA",
        "cortMInitA",
        "firtMInitA",
        "maxCropAgeInit",
        "avgCropAgeInit",
      ];
      const cropMassesGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-crop-masses-group"
      );
      const cropAgesGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-crop-ages-group"
      );
      cropAgesGroup.isShown = growthMethod !== "Perennial";
      cropMassesGroup.isShown = growthMethod !== "Perennial";
      cropAgesGroup.isShown
        ? this.addDefaultValidatorsInBulk(
            initCropFields,
            this.formGroup,
            this.formModel
          )
        : this.removeDefaultValidatorsInBulk(
            initCropFields,
            this.formGroup,
            this.formModel
          );
    }
  }

  public setInitSpeciesName({ type, name }) {
    const speciesName = type == "tree" ? "treeNmInit" : "cropNmInit";
    this.getFormGroup().get(speciesName).setValue(name);
  }

  public getInitSpeciesName(type) {
    const speciesName = type == "tree" ? "treeNmInit" : "cropNmInit";
    return this.getFormGroup().get(speciesName).value;
  }

  public readonly fieldWatcher: FieldWatcher = {
    "configuration:tPlot": (
      newValue: any,
      { formGroup, formModel, simulationService }
    ) => {
      const plotType = newValue;

      const wholePlotGroup = this.getLayoutGroup(
        this.layout.groups,
        "whole-Plot-group"
      );

      wholePlotGroup.isShown = plotType == "CompM";
      if (wholePlotGroup.isShown) {
        this.addDefaultValidatorsInBulk(["frFracInit"], formGroup, formModel);
      } else {
        this.removeDefaultValidatorsInBulk(
          ["frFracInit"],
          formGroup,
          formModel
        );
      }

      //forest
      const forestGroup = this.getLayoutGroup(
        this.layout.groups,
        "forest-group"
      );
      const treesGroup = this.getLayoutGroup(this.layout.groups, "trees-group");
      const standingDeadGroup = this.getLayoutGroup(
        this.layout.groups,
        "standing-dead-group"
      );
      const debrisGroup = this.getLayoutGroup(
        this.layout.groups,
        "debris-group"
      );
      const productsGroup = this.getLayoutGroup(
        this.layout.groups,
        "products-group"
      );

      //agricultural
      const agriculturalGroup = this.getLayoutGroup(
        this.layout.groups,
        "agricultural-group"
      );
      const cropsGroup = this.getLayoutGroup(this.layout.groups, "crops-group");
      const cropsDebrisGroup = this.getLayoutGroup(
        this.layout.groups,
        "crops-debris-group"
      );
      const cropsProductsGroup = this.getLayoutGroup(
        this.layout.groups,
        "crops-products-group"
      );

      // //forest logic
      forestGroup.isShown = Constants.ALL_FOREST_PLOT_TYPES.includes(plotType);

      treesGroup.isShown =
        plotType !== "SoilF" && !["SoilA", "CompA"].includes(plotType);
      standingDeadGroup.isShown =
        plotType !== "SoilF" && !["SoilA", "CompA"].includes(plotType);
      debrisGroup.isShown =
        plotType !== "SoilF" && !["SoilA", "CompA"].includes(plotType);
      productsGroup.isShown =
        plotType !== "SoilF" && !["SoilA", "CompA"].includes(plotType);

      [
        { group: standingDeadGroup, fields: treeStandingDeadFields },
        { group: debrisGroup, fields: treeDebrisFields },
        { group: productsGroup, fields: treeProductFields },
      ].forEach((g) => {
        if (g.group.isShown) {
          //this.addValidatorsInBulk(g.fields, [Validators.required], formGroup);

          this.addDefaultValidatorsInBulk(g.fields, formGroup, formModel);
        } else {
          // this.removeValidatorsInBulk(
          //   g.fields,
          //   [Validators.required],
          //   formGroup
          // );
          this.removeDefaultValidatorsInBulk(g.fields, formGroup, formModel);
        }
      });

      //place holder validation for initial tree name
      if (Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType)) {
        // this.addValidatorsInBulk(
        //   ["treeNmInit"],
        //   [Validators.required],
        //   formGroup
        // );

        this.addDefaultValidatorsInBulk(["treeNmInit"], formGroup, formModel);
      } else {
        // this.removeValidatorsInBulk(
        //   ["treeNmInit"],
        //   [Validators.required],
        //   formGroup
        // );

        this.removeDefaultValidatorsInBulk(
          ["treeNmInit"],
          formGroup,
          formModel
        );
      }

      // //agricultural logic
      agriculturalGroup.isShown =
        Constants.ALL_AGRICULTURAL_PLOT_TYPES.includes(plotType);

      cropsGroup.isShown =
        plotType !== "SoilA" && !["SoilF", "CompF"].includes(plotType);
      cropsDebrisGroup.isShown =
        plotType !== "SoilA" && !["SoilF", "CompF"].includes(plotType);
      cropsProductsGroup.isShown =
        plotType !== "SoilA" && !["SoilF", "CompF"].includes(plotType);

      [
        { group: cropsGroup, fields: initCropFields },
        { group: cropsDebrisGroup, fields: cropDebrisFields },
        { group: cropsProductsGroup, fields: cropProductFields },
      ].forEach((g) => {
        if (g.group.isShown) {
          // this.addValidatorsInBulk(g.fields, [Validators.required], formGroup);
          this.addDefaultValidatorsInBulk(g.fields, formGroup, formModel);
        } else {
          // this.removeValidatorsInBulk(
          //   g.fields,
          //   [Validators.required],
          //   formGroup
          // );
          this.removeDefaultValidatorsInBulk(g.fields, formGroup, formModel);
        }
      });

      //place holder validation for initial tree name
      if (Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType)) {
        // this.addValidatorsInBulk(
        //   ["cropNmInit"],
        //   [Validators.required],
        //   formGroup
        // );
        this.addDefaultValidatorsInBulk(["cropNmInit"], formGroup, formModel);
      } else {
        // this.removeValidatorsInBulk(
        //   ["cropNmInit"],
        //   [Validators.required],
        //   formGroup
        // );
        this.removeDefaultValidatorsInBulk(
          ["cropNmInit"],
          formGroup,
          formModel
        );
      }

      this.applySoilMnrlWatcher(
        this.getConfigurationFormGroup().get("userSoilMnrl").value,
        formModel,
        formGroup,
        simulationService
      );
    },
    treeExistsInit: (newValue: any, { formModel, formGroup }) => {
      const initTreeGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-tree-group"
      );
      initTreeGroup.isShown = newValue;
      if (!initTreeGroup.isShown) {
        // this.removeValidatorsInBulk(
        //   initTreeFields,
        //   [Validators.required],
        //   formGroup
        // );
        this.removeDefaultValidatorsInBulk(
          initTreeFields,
          formGroup,
          formModel
        );
      } else {
        this.initTreeWatcher(
          formGroup.get("tInitStem").value,
          formModel,
          formGroup
        );
        // this.addValidatorsInBulk(
        //   ["maxTreeAgeInit", "avgTreeAgeInit"],
        //   [Validators.required],
        //   formGroup
        // );

        this.addDefaultValidatorsInBulk(
          ["maxTreeAgeInit", "avgTreeAgeInit"],
          formGroup,
          formModel
        );
      }
    },
    "configuration:userSoilMnrl": (
      newValue: any,
      { formGroup, formModel, simulationService }
    ) => {
      this.applySoilMnrlWatcher(
        newValue,
        formModel,
        formGroup,
        simulationService
      );
    },
    cropExistsInit: (newValue: any, { formModel, formGroup }) => {
      const initCropGroup = this.getLayoutGroup(
        this.layout.groups,
        "init-crop-group"
      );

      initCropGroup.isShown = newValue;
      if (initCropGroup.isShown) {
        // this.addValidatorsInBulk(
        //   initCropFields,
        //   [Validators.required],
        //   formGroup
        // );

        this.addDefaultValidatorsInBulk(initCropFields, formGroup, formModel);
      } else {
        // this.removeValidatorsInBulk(
        //   initCropFields,
        //   [Validators.required],
        //   formGroup
        // );
        this.removeDefaultValidatorsInBulk(
          initCropFields,
          formGroup,
          formModel
        );
      }
    },

    tInitStem: (newValue: any, { formModel, formGroup }) => {
      this.initTreeWatcher(newValue, formModel, formGroup);
    },
  };

  protected applySoilMnrlWatcher(
    userSoilMnrl: any,
    formModel: FormModel,
    formGroup: UntypedFormGroup,
    simulationService: SimulationService
  ) {
    const configurationFormGroup =
      simulationService.configurationService.getFormGroup();
    const plotType = configurationFormGroup.get("tPlot").value;

    const soilGroup = this.getLayoutGroup(this.layout.groups, "soil-group");
    const cropSoilGroup = this.getLayoutGroup(
      this.layout.groups,
      "crop-soil-group"
    );
    soilGroup.isShown =
      (userSoilMnrl && Constants.ALL_FOREST_PLOT_TYPES.includes(plotType)) ||
      plotType == "SoilF";

    cropSoilGroup.isShown =
      (userSoilMnrl &&
        Constants.ALL_AGRICULTURAL_PLOT_TYPES.includes(plotType)) ||
      plotType == "SoilA";

    if (soilGroup.isShown) {
      // this.addValidatorsInBulk(soilFields, [Validators.required], formGroup);
      this.addDefaultValidatorsInBulk(soilFields, formGroup, formModel);
    } else {
      // this.removeValidatorsInBulk(soilFields, [Validators.required], formGroup);
      this.removeDefaultValidatorsInBulk(soilFields, formGroup, formModel);
    }

    if (cropSoilGroup.isShown) {
      // this.addValidatorsInBulk(
      //   cropSoilFields,
      //   [Validators.required],
      //   formGroup
      // );
      this.addDefaultValidatorsInBulk(cropSoilFields, formGroup, formModel);
    } else {
      // this.removeValidatorsInBulk(
      //   cropSoilFields,
      //   [Validators.required],
      //   formGroup
      // );
      this.removeDefaultValidatorsInBulk(cropSoilFields, formGroup, formModel);
    }
    //inert soil
    formModel["inrtCMInitF"].isShown = !["SoilA", "CompA", "SoilF"].includes(
      plotType
    );

    if (formModel["inrtCMInitF"].isShown) {
      // this.addValidatorsInBulk(
      //   ["inrtCMInitF"],
      //   [Validators.required],
      //   formGroup
      // );

      this.addDefaultValidatorsInBulk(["inrtCMInitF"], formGroup, formModel);
    } else {
      // this.removeValidatorsInBulk(
      //   ["inrtCMInitF"],
      //   [Validators.required],
      //   formGroup
      // );
      this.removeDefaultValidatorsInBulk(["inrtCMInitF"], formGroup, formModel);
    }

    formModel["inrtCMInitA"].isShown = !["SoilF", "CompF", "SoilA"].includes(
      plotType
    );

    if (formModel["inrtCMInitA"].isShown) {
      // this.addValidatorsInBulk(
      //   ["inrtCMInitA"],
      //   [Validators.required],
      //   formGroup
      // );
      this.addDefaultValidatorsInBulk(["inrtCMInitA"], formGroup, formModel);
    } else {
      // this.removeValidatorsInBulk(
      //   ["inrtCMInitA"],
      //   [Validators.required],
      //   formGroup
      // );

      this.removeDefaultValidatorsInBulk(["inrtCMInitA"], formGroup, formModel);
    }
  }

  protected initTreeWatcher(newValue, formModel, formGroup) {
    const isExist = formGroup.get("treeExistsInit").value;
    if (!isExist) {
      return;
    }

    const initSpecifyTreeSizeGroup = this.getLayoutGroup(
      this.layout.groups,
      "init-specify-tree-size"
    );

    const massGroup = this.getLayoutGroup(this.layout.groups, "init-tree-mass");

    const massFields = [
      "branMInitF",
      "barkMInitF",
      "leafMInitF",
      "cortMInitF",
      "firtMInitF",
    ];

    const percentageBioMassGroup = this.getLayoutGroup(
      this.layout.groups,
      "init-tree-percentage-biomass"
    );

    const volGroup = this.getLayoutGroup(this.layout.groups, "init-tree-vol");

    const isPercentage = newValue == "Frac" || newValue == "FracAge";
    const isNotPercentage = newValue == "Mass" || newValue == "Vol";

    const isVol = newValue == "Vol";

    percentageBioMassGroup.isShown = isPercentage;
    const percentageFields = [
      "stemFracInitF",
      "branFracInitF",
      "barkFracInitF",
      "leafFracInitF",
      "cortFracInitF",
      "firtFracInitF",
    ];

    if (isPercentage) {
      // this.addValidatorsInBulk(
      //   percentageFields,
      //   [Validators.required],
      //   formGroup
      // );

      this.addDefaultValidatorsInBulk(percentageFields, formGroup, formModel);
    } else {
      // this.removeValidatorsInBulk(
      //   percentageFields,
      //   [Validators.required],
      //   formGroup
      // );

      this.removeDefaultValidatorsInBulk(
        percentageFields,
        formGroup,
        formModel
      );
    }

    massGroup.isShown = isNotPercentage;

    volGroup.isShown = isVol;

    if (isNotPercentage) {
      if (newValue == "Mass") {
        // this.addValidatorsInBulk(
        //   ["stemMInitF"],
        //   [Validators.required],
        //   formGroup
        // );
        // this.removeValidatorsInBulk(
        //   ["stemVolInitF"],
        //   [Validators.required],
        //   formGroup
        // );

        this.addDefaultValidatorsInBulk(["stemMInitF"], formGroup, formModel);

        this.removeDefaultValidatorsInBulk(
          ["stemVolInitF"],
          formGroup,
          formModel
        );
      }

      if (newValue == "Vol") {
        // this.addValidatorsInBulk(
        //   ["stemVolInitF"],
        //   [Validators.required],
        //   formGroup
        // );
        // this.removeValidatorsInBulk(
        //   ["stemMInitF"],
        //   [Validators.required],
        //   formGroup
        // );

        this.addDefaultValidatorsInBulk(["stemVolInitF"], formGroup, formModel);

        this.removeDefaultValidatorsInBulk(
          ["stemMInitF"],
          formGroup,
          formModel
        );
      }

      //this.addValidatorsInBulk(massFields, [Validators.required], formGroup);
      this.addDefaultValidatorsInBulk(massFields, formGroup, formModel);
    } else {
      // this.removeValidatorsInBulk(
      //   ["stemMInitF", "stemVolInitF", ...massFields],
      //   [Validators.required],
      //   formGroup
      // );
      this.removeDefaultValidatorsInBulk(
        ["stemMInitF", "stemVolInitF", ...massFields],
        formGroup,
        formModel
      );
      massFields;
    }

    initSpecifyTreeSizeGroup.isShown = isExist;
    formModel["stemMInitF"].isShown = newValue == "Mass";
  }

  public removeInitialSpecies(type) {
    this.selectSpecies({
      type,
      id: null,
      name: null,
    });
  }

  public disableTreeInsertButton(): boolean {
    return !this.initTreeId$.getValue();
  }

  public disableCropInsertButton(): boolean {
    return !this.initCropId$.getValue();
  }

  public showButtonMethod(payload): boolean {
    const { type, categoryName } = payload;

    const species = this.getSpeciesFormGroup(type).get("species");
    const initId = type == "tree" ? this.initTreeId$ : this.initCropId$;

    if (+initId.getValue() <= 0) {
      return false;
    }

    const index = species.controls.findIndex(
      (sp) => sp.get("idRegimeSP")?.value == initId.getValue()
    );

    if (index < 0) {
      return false;
    }

    const speciesFormGroup = species.controls[index];

    const fields = this.getFormModelKeysByCategoryName(categoryName);
    return !fields.some((f) => {
      return speciesFormGroup.get(f).invalid;
    });
  }

  public insertStandardValues(payload) {
    const { type, categoryName } = payload;

    // const treeCategories = ["InitFT", "InitFD", "InitFP", "InitFSd", "EvPlnF"];
    // const cropCategories = ["InitAC", "InitAD", "InitAM", "InitAN", "InitAP"];

    const species = this.getSpeciesFormGroup(type).get("species");
    const initId = type == "tree" ? this.initTreeId$ : this.initCropId$;

    const speciesFormGroup = species.controls.find(
      (sp) => sp.get("idRegimeSP")?.value == initId.getValue()
    );

    //const speciesFormGroup = species.controls[index];

    const fields = this.getFormModelKeysByCategoryName(categoryName);
    fields.forEach((f) => {
      const value = speciesFormGroup.get(f).value;
      this.formGroup.get(f).setValue(value);
    });
  }

  public setInitialSoilData(soilData) {
    //DocDB.cpp: 1254

    const dataBuilderFormGroup =
      this.simulationService.dataBuilderService.getFormGroup();
    const totSOC = +soilData.initTotalC;
    //this change is for R20-24 New initial soil fraction spatial layers
    let newCalc = false;
    if (totSOC <= 1.0) {
      newCalc = true;
    }

    //this change is for R20-24 New initial soil fraction spatial layers
    let totFrac = 1.0;
    if (!newCalc) {
      // Source data does NOT necessarily add up to 100%, thus we need to scale by totFrac
      totFrac =
        +soilData.initFracRpma +
        +soilData.initFracHums +
        +soilData.initFracInrt +
        +soilData.initFracBiof +
        +soilData.initFracBios;
    }

    //FullCAM Code\OtherCode\TimeSeries.cpp :238
    const initTSMDTimeSeris = soilData.TimeSeries.find(
      (ts) => ts.tInTS == "initTSMD"
    );

    //todo
    const timingService = this.simulationService.timingService;
    const startTimeInstant = timingService.getStartTime();
    const startTime = +TimeUtilities.getTimeInYears(startTimeInstant);
    const startYear = +TimeUtilities.getYearFromTimeInstant(startTimeInstant);
    const initTSMDValue = TimeUtilities.getValueOfTimeSeries(
      initTSMDTimeSeris,
      startYear,
      startYear - startTime
    );

    // Forest initial soil
    const plotType = this.getConfigurationFormGroup().get("tPlot").value;

    let frFrac = +dataBuilderFormGroup.get("frFracBL").value / 100;
    const haNoFrFracValue = frFrac == null || frFrac < 0;

    if (haNoFrFracValue) {
      //has value already at this point?
      frFrac = +this.formGroup.get("frFracInit").value;
    }

    if (plotType == "CompF") {
      frFrac = 1.0;
    }

    if (plotType == "CompA") {
      frFrac = 0.0;
    } else if (haNoFrFracValue) {
      frFrac = 1.0;
    }

    //this change is for R20-24 New initial soil fraction spatial layers

    let initSoilForestValues: any = {};

    if (Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType) && !newCalc) {
      const x = frFrac * totSOC;
      initSoilForestValues = {
        rpmaCMInitF: (x * +soilData.initFracRpma) / totFrac,
        humsCMInitF: (x * +soilData.initFracHums) / totFrac,
        inrtCMInitF: (x * +soilData.initFracInrt) / totFrac,
        biofCMInitF: (x * +soilData.initFracBiof) / totFrac,
        biosCMInitF: (x * +soilData.initFracBios) / totFrac,
      };
    }
    //this change is for R20-24 New initial soil fraction spatial layers
    if (Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType) && newCalc) {
      initSoilForestValues = {
        rpmaCMInitF: frFrac * +soilData.initFracRpma,
        humsCMInitF: frFrac * +soilData.initFracHums,
        inrtCMInitF: frFrac * +soilData.initFracInrt,
        biofCMInitF: frFrac * +soilData.initFracBiof,
        biosCMInitF: frFrac * +soilData.initFracBios,
      };
    }
    initSoilForestValues.dpmaCMInitF = 0.0;

    initSoilForestValues.TSMDInitF = initTSMDValue;

    let initSoilAgriculturalValues: any = {};

    if (
      Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType) &&
      !newCalc
    ) {
      // Ag initial soil
      const x = (1.0 - frFrac) * totSOC;
      initSoilAgriculturalValues.rpmaCMInitA =
        (x * +soilData.initFracRpma) / totFrac;
      initSoilAgriculturalValues.humsCMInitA =
        (x * +soilData.initFracHums) / totFrac;
      initSoilAgriculturalValues.inrtCMInitA =
        (x * +soilData.initFracInrt) / totFrac;
      initSoilAgriculturalValues.biofCMInitA =
        (x * +soilData.initFracBiof) / totFrac;
      initSoilAgriculturalValues.biosCMInitA =
        (x * +soilData.initFracBios) / totFrac;
    }
    //this change is for R20-24 New initial soil fraction spatial layers
    if (
      Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType) &&
      newCalc
    ) {
      // Ag initial soil
      const x = 1.0 - frFrac;
      initSoilAgriculturalValues.rpmaCMInitA = x * soilData.initFracRpma;
      initSoilAgriculturalValues.humsCMInitA = x * soilData.initFracHums;
      initSoilAgriculturalValues.inrtCMInitA = x * soilData.initFracInrt;
      initSoilAgriculturalValues.biofCMInitA = x * soilData.initFracBiof;
      initSoilAgriculturalValues.biosCMInitA = x * soilData.initFracBios;
    }
    initSoilAgriculturalValues.dpmaCMInitA = 0.0;
    initSoilAgriculturalValues.TSMDInitA = initTSMDValue;

    this.applyXmlValuesToFormGroup(
      { ...initSoilForestValues, ...initSoilAgriculturalValues },
      this.getFormGroup()
    );
  }

  public setInitialConditionsData(type, idRegimeSP): void {
    const configurationFormGroup = this.getConfigurationFormGroup();
    const forestPercentage = this.simulationService.dataBuilderService
      .getFormGroup()
      .get("frFracBL").value;
    const plotType = configurationFormGroup.get("tPlot").value;

    const treeCategories = ["InitFT", "InitFD", "InitFP", "InitFSd", "EvPlnF"];
    const cropCategories = ["InitAC", "InitAD", "InitAM", "InitAN", "InitAP"];
    const categories = type == "tree" ? treeCategories : cropCategories;

    const species = this.getSpeciesFormGroup(type).get("species");
    let index;

    const selectedSpecies = species.controls.find((sp, i) => {
      if (sp.get("idRegimeSP").value == idRegimeSP) {
        index = i;
        return true;
      }
    });

    this.selectSpecies({
      type,
      id: idRegimeSP,
      name: selectedSpecies?.get("nmSP").value,
    });

    const speciesFormGroup = species.controls[index];

    categories.forEach((c) => {
      const fields = this.getFormModelKeysByCategoryName(c);
      fields.forEach((f) => {
        const value = speciesFormGroup.get(f).value;
        this.formGroup.get(f).setValue(value);
      });
    });

    if (plotType !== "CompM") {
      return;
    }
    //apply forest percentage
    this.setInitSpeciesPercentageValues(
      type,
      forestPercentage ? forestPercentage / 100 : 1.0,
      speciesFormGroup
    );
  }

  public getXPathForCloneDigest(programmingName: string): {
    xPath: string;
    id: string;
  } {
    const xmlObject = {
      InitTreeF: writeXmlInitTreeFields,
      InitCropA: writeXmlInitCropFields,
      InitSDwdF: writeXmlInitSDwdFFields,
      InitDebrF: writeXmlInitDebrFFields,
      InitDebrA: writeXmlInitDebrAFields,
      InitMulcF: writeXmlInitMulcFFields,
      InitMulcA: writeXmlInitMulcAFields,
      InitSoilF: writeXmlInitSoilFFields,
      InitSoilA: writeXmlInitSoilAFields,
      InitMnrlF: writeXmlInitMnrlFFields,
      InitMnrlA: writeXmlInitMnrlAFields,
      InitProdF: writeXmlInitProdFFields,
      InitProdA: writeXmlInitProdAFields,
      InitWholP: writeXmlInitWholPFields,
    };

    for (const xmlKey in xmlObject) {
      if (xmlObject[xmlKey].includes(programmingName)) {
        return { xPath: "//" + xmlKey, id: null };
      }
    }
    return null;
  }

  setInitSpeciesPercentageValues(type, frFrac, speciesFormGroup) {
    const treeFields = [
      "branMInitF",
      "barkMInitF",
      "leafMInitF",
      "cortMInitF",
      "firtMInitF",
      "stemVolInitF",
      "stemFracInitF",
      "branFracInitF",
      "barkFracInitF",
      "leafFracInitF",
      "cortFracInitF",
      "firtFracInitF",
      "storNMInitF",
      "stemM3PGInit",
      "foliM3PGInit",
      "rootM3PGInit",
      "nStemsInit",
      "dDdwdCMInitF",
      "rDdwdCMInitF",
      "dChwdCMInitF",
      "rChwdCMInitF",
      "dBlitCMInitF",
      "rBlitCMInitF",
      "dLlitCMInitF",
      "rLlitCMInitF",
      "dCodrCMInitF",
      "rCodrCMInitF",
      "dFidrCMInitF",
      "rFidrCMInitF",
    ];

    const cropFields = [
      "gbfrMInitA",
      "stlkMInitA",
      "leafMInitA",
      "cortMInitA",
      "firtMInitA",
      "storNMInitA",
      "dGlitCMInitA",
      "rGlitCMInitA",
      "dSlitCMInitA",
      "rSlitCMInitA",
      "dLlitCMInitA",
      "rLlitCMInitA",
      "dCodrCMInitA",
      "rCodrCMInitA",
      "dFidrCMInitA",
      "rFidrCMInitA",
    ];
    const fields = type == "tree" ? treeFields : cropFields;

    fields.forEach((f) => {
      const fieldValue = speciesFormGroup.get(f)?.value;
      if (fieldValue) {
        this.formGroup.get(f).setValue(fieldValue * frFrac);
      } else {
        console.warn(`${f} can not be found in initial conditions form group`);
      }
    });
  }

  public getInitialSpeciesId(type): BehaviorSubject<number> {
    return type == "tree" ? this.initTreeId$ : this.initCropId$;
  }

  public readXmlObject(): void {
    super.readXmlObject();

    const template = this.simulationService.getPlotFileJson()["Init"][0];

    [
      "InitCropA",
      "InitDebrA",
      "InitDebrF",
      "InitMnrlA",
      "InitMnrlF",
      "InitMulcA",
      "InitMulcF",
      "InitProdA",
      "InitProdF",
      "InitSDwdF",
      "InitSoilA",
      "InitSoilF",
      "InitTreeF",
      "InitWholP",
    ].forEach((initGroup) => {
      if (template[initGroup] && template[initGroup][0]?.$) {
        this.applyXmlValuesToFormGroup(
          template[initGroup][0]?.$,
          this.formGroup
        );
      }
    });

    const initTree =
      template["InitTreeF"] && template["InitTreeF"][0].$?.treeNmInit;
    const initCrop =
      template["InitCropA"] && template["InitCropA"][0].$?.cropNmInit;
    if (initTree) {
      const initTreeObject =
        this.simulationService.treesService.getSpeciesByName(initTree);
      if (initTreeObject) {
        this.selectSpecies({
          id: initTreeObject.idRegimeSP,
          type: "tree",
          name: initTreeObject.nmSP,
        });
      } else {
        this.simulationService.messageService.addAlert({
          type: "danger",
          msg: `Tree species - ${initTree} does not exist in Initial conditions.`,
          dismissible: true,
        });
      }
    }

    if (initCrop) {
      const initCropbject =
        this.simulationService.cropsService.getSpeciesByName(initCrop);
      if (!initCropbject) {
        this.simulationService.messageService.addAlert({
          type: "danger",
          msg: `Crop species - ${initCrop} does not exist in Initial conditions.`,
          dismissible: true,
        });
        return;
      }
      this.selectSpecies({
        id: initCropbject.idRegimeSP,
        type: "crop",
        name: initCropbject.nmSP,
      });
    }

    //manually trigger watchers to reapply validations
    this.manuallyTriggerWatchingFields(this.fieldWatcher, {
      formGroup: this.formGroup,
      formModel: this.formModel,
      layout: this.layout,
      simulationService: this.simulationService,
    });
  }

  public writeXmlObject() {
    let formData = this.formGroup.getRawValue();

    const xmlObject = {
      InitTreeF: [{ $: {} }],
      InitCropA: [{ $: {} }],
      InitSDwdF: [{ $: {} }],
      InitDebrF: [{ $: {} }],
      InitDebrA: [{ $: {} }],
      InitMulcF: [{ $: {} }],
      InitMulcA: [{ $: {} }],
      InitSoilF: [{ $: {} }],
      InitSoilA: [{ $: {} }],
      InitMnrlF: [{ $: {} }],
      InitMnrlA: [{ $: {} }],
      InitProdF: [{ $: {} }],
      InitProdA: [{ $: {} }],
      InitWholP: [{ $: {} }],
    };

    xmlObject.InitTreeF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitTreeFields,
      formData
    );

    xmlObject.InitCropA[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitCropFields,
      formData
    );

    xmlObject.InitSDwdF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitSDwdFFields,
      formData
    );
    xmlObject.InitDebrF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitDebrFFields,
      formData
    );
    xmlObject.InitDebrA[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitDebrAFields,
      formData
    );
    xmlObject.InitMulcF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitMulcFFields,
      formData
    );
    xmlObject.InitMulcA[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitMulcAFields,
      formData
    );
    xmlObject.InitSoilF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitSoilFFields,
      formData
    );
    xmlObject.InitSoilA[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitSoilAFields,
      formData
    );
    xmlObject.InitMnrlF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitMnrlFFields,
      formData
    );
    xmlObject.InitMnrlA[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitMnrlAFields,
      formData
    );
    xmlObject.InitProdF[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitProdFFields,
      formData
    );
    xmlObject.InitProdA[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitProdAFields,
      formData
    );
    xmlObject.InitWholP[0].$ = this.getFormValueObjectForXmlExport(
      writeXmlInitWholPFields,
      formData
    );

    return { Init: [xmlObject] };
  }

  public reset() {
    this.initTreeId$.next(-1);
    this.initCropId$.next(-1);
    super.reset();
  }
}
