<div class="main-container">
  <button
    aria-label="back button"
    type="button"
    class="btn close-button"
    (click)="closeForm(null)"
  >
    <i class="bi bi-x-lg"> </i>
  </button>
  <div class="logo-container">
    <img
      src="assets/images/CCEEW-stacked-white.png"
      aria-label="Department of Climate Change, Energy, the Environment and Water Government Crest"
      alt="Department of Climate Change, Energy, the Environment and Water Government Crest"
      class="logo"
    />
  </div>

  <ng-container *ngIf="version == 'rmt'; then rmt; else fullcam"></ng-container>

  <p>© Commonwealth of Australia</p>
</div>

<ng-template #fullcam>
  <div class="pre-header">
    <i class="bi bi-info-circle role-a"></i> About FullCAM
  </div>
  <h1>FullCAM {{ version ? version + " PR" : "" }}</h1>
  <p>
    Simulation Engine Version:
    <b>Standard {{ getSimulationEngineVersion() }}</b>
  </p>
  <p>
    FullCAM UI Version: <b>v{{ uiVersion }}</b>
  </p>
</ng-template>

<ng-template #rmt>
  <div class="pre-header">
    <i class="bi bi-info-circle role-a"></i> About RMT
  </div>
  <h1>Reforestation Modelling Tool</h1>

  <p>
    RMT UI Version: <b>v{{ rmtUiVersion }}</b>
  </p>
</ng-template>
