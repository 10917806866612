import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AboutComponent } from "./about/about.component";
import { ConfigurationComponent } from "./configuration/configuration.component";
import { TimingComponent } from "./timing/timing.component";
import { DataBuilderComponent } from "./data-builder/data-builder.component";
import { SiteComponent } from "./site/site.component";
import { SoilComponent } from "./soil/soil.component";
import { InitialConditionsComponent } from "./initial-conditions/initial-conditions.component";
import { TabsModule } from "ngx-bootstrap/tabs";
import { SharedModule } from "../shared/shared.module";
import { TreesComponent } from "./trees/trees.component";
import { PlotDigestComponent } from "./plot-digest/plot-digest.component";
import { CropsComponent } from "./crops/crops.component";
import { PlotFilesComponent } from "./plot-files/plot-files.component";
import { EstateComponent } from "./estate/estate.component";
import { EventsComponent } from "./events/events.component";
import { EconomicComponent } from "./economic/economic.component";
import { RegimeEditorComponent } from "./events/components/regime-editor/regime-editor.component";
import { RegimeFormComponent } from "./events/components/regime-form/regime-form.component";
import { EventEditorComponent } from "./events/components/event-editor/event-editor.component";
import { TyfCategoryTableComponent } from "./trees/components/tyf-category-table/tyf-category-table.component";
import { CloneRegimeFormComponent } from "./events/components/clone-regime-form/clone-regime-form.component";
import { CloneEventFormComponent } from "./events/components/clone-event-form/clone-event-form.component";
import { ExplorerComponent } from "./explorer/explorer.component";
import { BrowserModule } from "@angular/platform-browser";
import { HashLocationStrategy } from "@angular/common";
import { LocationStrategy } from "@angular/common";
import { Location } from "@angular/common";
import { OutputWindowsComponent } from "./output-windows/output-windows.component";
import { OutputWindowsContainerComponent } from "./output-windows/components/output-container/output-windows-container.component";
import { OutputListComponent } from "./output-windows/components/output-list/output-list.component";
import { SimulationResultComponent } from "./output-windows/components/simulation-result/simulation-result.component";
import { PlotDigestContainerComponent } from "./plot-digest/components/plot-digest-container/plot-digest-container.component";
import { AddScenarioFormComponent } from "./plot-digest/components/add-scenario-form/add-scenario-form.component";
import { OutputListItemComponent } from "./output-windows/components/output-list-item/output-list-item.component";
import { CloneScenarioFormComponent } from "./plot-digest/components/clone-scenario-form/clone-scenario-form.component";
import { ScenarioViewComponent } from "./plot-digest/components/scenario-view/scenario-view.component";
import { ExplorerContainerComponent } from "./explorer/components/explorer-container/explorer-container.component";
import { ExplorerCategoryListComponent } from "./explorer/components/explorer-category-list/explorer-category-list.component";
import { PoltFileListComponent } from "./plot-files/components/polt-file-list/polt-file-list.component";
import { PlotsInEstateComponent } from "./estate/components/plots-in-estate/plots-in-estate.component";
import { CreateEstateFormComponent } from "./estate/components/create-estate-form/create-estate-form.component";
import { CloneEstateFormComponent } from "./estate/components/clone-estate-form/clone-estate-form.component";
import { ValidationReportComponent } from "./plot-files/components/validation-report/validation-report.component";
import { ProjectCollectionSelectorComponent } from "../shared/components/project-collection-selector/project-collection-selector.component";
import { LogComponent } from "./log/log.component";
import { LogListComponent } from "./log/components/log-list/log-list.component";
import { ModelsAndInputComponent } from "./configuration/components/models-and-input/models-and-input.component";
import { DiagramsComponent } from "./configuration/components/diagrans/diagrams.component";

@NgModule({
  declarations: [
    AboutComponent,
    ConfigurationComponent,
    TimingComponent,
    DataBuilderComponent,
    SiteComponent,
    SoilComponent,
    InitialConditionsComponent,
    TreesComponent,
    PlotDigestComponent,
    CropsComponent,
    PlotFilesComponent,
    EstateComponent,
    EventsComponent,
    EconomicComponent,
    RegimeEditorComponent,
    RegimeFormComponent,
    EventEditorComponent,
    TyfCategoryTableComponent,
    CloneRegimeFormComponent,
    CloneEventFormComponent,
    ExplorerComponent,
    OutputWindowsComponent,
    OutputWindowsContainerComponent,
    OutputListComponent,
    SimulationResultComponent,
    PlotDigestContainerComponent,
    AddScenarioFormComponent,
    OutputListItemComponent,
    CloneScenarioFormComponent,
    ScenarioViewComponent,
    ExplorerContainerComponent,
    ExplorerCategoryListComponent,
    PoltFileListComponent,
    PlotsInEstateComponent,
    CreateEstateFormComponent,
    CloneEstateFormComponent,
    ValidationReportComponent,
    ProjectCollectionSelectorComponent,
    LogComponent,
    LogListComponent,
    ModelsAndInputComponent,
    DiagramsComponent,
  ],
  imports: [CommonModule, SharedModule, BrowserModule, TabsModule.forRoot()],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    // {
    //   provide: About2024Service,
    //   useClass: About2024Service,
    // },
    // {
    //   provide: Configuration2024Service,
    //   useClass: Configuration2024Service,
    // },
    // {
    //   provide: Timing2024Service,
    //   useClass: Timing2024Service,
    // },
    // {
    //   provide: DataBuilder2024Service,
    //   useClass: DataBuilder2024Service,
    // },
    // {
    //   provide: Site2024Service,
    //   useClass: Site2024Service,
    // },
    // {
    //   provide: Trees2024Service,
    //   useClass: Trees2024Service,
    // },
    // {
    //   provide: Crops2024Service,
    //   useClass: Crops2024Service,
    // },
    // {
    //   provide: Soil2024Service,
    //   useClass: Soil2024Service,
    // },
    // {
    //   provide: InitialConditions2024Service,
    //   useClass: InitialConditions2024Service,
    // },
    // {
    //   provide: Events2024Service,
    //   useClass: Events2024Service,
    // },
    // {
    //   provide: OutputWindows2024Service,
    //   useClass: OutputWindows2024Service,
    // },
  ],
})
export class PlotModule {}
