<div class="row">
  <div class="header">
    <div></div>
    <div class="header-button-group">
      <button
        *ngIf="hasBrokenLinkPlotFiles()"
        (click)="relinkPlotFiles()"
        type="button"
        class="btn btn-outline-primary-darken btn-sm"
      >
        Relink plot files
      </button>
      <button
        *ngIf="hasPlotFiles()"
        (click)="validateFiles()"
        type="button"
        class="btn btn-outline-primary-darken btn-sm"
      >
        Validate plot files
      </button>

      <button
        (click)="selectPlotFiles()"
        type="button"
        class="btn btn-primary-darken btn-sm"
      >
        Add plot files for estate
      </button>
    </div>
  </div>

  <div class="content">
    <div class="table-controls">
      <div class="button-group-container">
        <div class="button-group">
          <ng-container *ngIf="selectedRows?.length">
            <select
              class="form-control form-select form-select-sm"
              [(ngModel)]="selectedBulkAction"
            >
              <option value="createEstatePlot">Create Estate Plot</option>
              <option value="delete">Delete</option>
            </select>
            <fc-button
              [isLoading$]="isBulkActionLoading$"
              [inputText]="'Apply'"
              (click)="bulkAction()"
            ></fc-button>
          </ng-container>
        </div>
      </div>
      <div class="button-group-container">
        <div class="button-group">
          <input
            id="plot-file-search"
            aria-label="Search plot file name"
            class="form-control form-control-sm"
            placeholder="Search plot file name"
            [(ngModel)]="searchString"
            (keyup.enter)="updateFilter()"
          />
          <button
            id="clear-plot-file-search"
            class="btn btn-primary-darken btn-sm"
            (keyup.enter)="updateFilter()"
            (click)="updateFilter()"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <ngx-datatable
      #plotTable
      class="custom-table"
      [rows]="tempPlotFiles"
      [columnMode]="ColumnMode.force"
      [headerHeight]="56"
      [footerHeight]="56"
      rowHeight="56"
      [selected]="selectedRows"
      [selectionType]="SelectionType.checkbox"
      (select)="onSelect($event)"
      [limit]="10"
    >
      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              allRowsSelected ? "Uncheck all" : "Check all"
            }}</span>
            <input
              type="checkbox"
              [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>

        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              isSelected ? "Deselect" : "Select"
            }}</span>

            <input
              type="checkbox"
              [checked]="isSelected"
              (change)="onCheckboxChangeFn($event)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        prop="validationStatus"
        [width]="50"
        [canAutoResize]="false"
        [draggable]="false"
        [sortable]="false"
        [resizeable]="false"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
        </ng-template>

        <ng-template ngx-datatable-cell-template let-value="value">
          <span
            class="status"
            [style]="{ 'background-color': getStatusLegend(value)?.color }"
            [tooltip]="getStatusLegend(value)?.label"
          ></span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="File name">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ value }}
          <i
            *ngIf="!row.plotId"
            class="broken-link"
            container="body"
            [tooltip]="
              value +
              '  was not located in the imported file. To correct this please upload and relink a new plot file ensuring it carries the same name.'
            "
            class="bi bi-link-45deg"
          ></i>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Modified date"
        [width]="155"
        [canAutoResize]="false"
        [draggable]="false"
      >
        <ng-template ngx-datatable-cell-template let-value="value">
          {{ value ? (value | date : "dd/MM/yyyy") : "Invalid Date" }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Action"
        [width]="150"
        [canAutoResize]="false"
        [draggable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="action-buttons">
            <button
              [disabled]="!canCreateEstatePlotFile(row)"
              class="btn btn-sm btn-rounded"
              (click)="addPlotFilesToEstate([row.plotId])"
              container="body"
              tooltip="Creaet Estate Plot in 'Plots in Estate' page"
            >
              <span class="visually-hidden"
                >Creaet Estate Plot in "Plots in Estate" page</span
              >
              <i class="bi bi-arrow-up-right"></i>
            </button>

            <button
              [disabled]="!row.plotId"
              class="btn btn-sm btn-rounded"
              (click)="editPlotFile(row)"
              container="body"
              tooltip="Edit"
            >
              <span class="visually-hidden">edit</span>
              <i class="bi bi-pencil-fill"></i>
            </button>

            <button
              class="btn btn-sm btn-rounded"
              (click)="deletePlotFiles([row])"
              container="body"
              tooltip="Delete"
            >
              <span class="visually-hidden">delete</span>
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
