import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { ModalService } from "./modal.service";

export interface HasUnsavedData {
  hasUnsavedData(): boolean;
}
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: "root",
})
export class CanDeactivateGuardService
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(private modalService: ModalService) {}

  async canDeactivate(component: CanComponentDeactivate): Promise<boolean> {
    if (component.canDeactivate && component.canDeactivate()) {
      const message =
        "Are you sure, you want to leave this page with unsaved data?";
      const confirm = await this.modalService.openConfirmModal(message);

      return confirm;
    }

    return true;
  }
}
