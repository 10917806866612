<div class="title">
  <button
    aria-label="back button"
    type="button"
    class="btn close-button"
    (click)="flyOverPanelService.closePanel()"
  >
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>

  <h2>Standard events:</h2>
  {{ title }}
</div>

<div class="main-container">
  <div class="side-controls">
    <form>
      <fieldset>
        <legend class="visually-hidden">Event Filters</legend>
        <div class="accordion-header is-expanded">
          <h3>Filter</h3>
          <div class="button-group">
            <button type="button" class="btn" aria-label="help button">
              <i class="bi bi-question-circle"></i>
            </button>
          </div>
        </div>
        <div class="accordion-body">
          <ul [formGroup]="eventTypeFilterformGroup">
            <li
              class="list-item form-check form-switch"
              *ngFor="let eventType of eventTypesMap | keyvalue"
            >
              <label
                class="label form-check-label"
                [for]="eventType.key + '-filter'"
              >
                {{ eventType.value.label }}
              </label>

              <input
                [id]="eventType.key + '-filter'"
                [name]="eventType.key"
                class="form-check-input switch-input"
                type="checkbox"
                role="switch"
                [formControlName]="eventType.key"
                (change)="filterEvents()"
              />
            </li>
          </ul>
        </div>
      </fieldset>
    </form>
    <div class="filter-form-footer">
      <span>{{ events.value.length }} events present</span>
      <span>{{ filteredEvents.length }} events showing</span>
    </div>
  </div>

  <div class="main-content">
    <div class="form-controls">
      <div class="left-controls">
        <div class="button-group" *ngIf="selectedRows?.length">
          <select
            class="form-control form-select form-select-sm"
            [(ngModel)]="selectedBulkAction"
            [ngModelOptions]="{ standalone: true }"
          >
            <option value="delete">Delete</option>
            <option value="clone">Clone</option>
          </select>
          <fc-button
            [isLoading$]="isBulkActionLoading$"
            [inputText]="'Apply'"
            (click)="bulkAction()"
          ></fc-button>
        </div>
      </div>
      <div class="right-controls">
        <button
          type="button"
          class="btn btn-primary-darken"
          (click)="createEvent()"
        >
          New event
        </button>
      </div>
    </div>

    <ngx-datatable
      class="custom-table"
      [rows]="filteredEvents"
      [columnMode]="ColumnMode.force"
      [headerHeight]="56"
      [footerHeight]="56"
      rowHeight="auto"
      [limit]="10"
      [selected]="selectedRows"
      [selectionType]="SelectionType.checkbox"
      [rowClass]="getRowClass"
      (select)="onSelect($event)"
      (sort)="onSort($event)"
      [sorts]="currentSort"
    >
      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              allRowsSelected ? "Uncheck all" : "Check all"
            }}</span>
            <input
              type="checkbox"
              [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              isSelected ? "Deselect" : "Select"
            }}</span>
            <input
              type="checkbox"
              [checked]="isSelected"
              (change)="onCheckboxChangeFn($event)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [width]="40"
        [canAutoResize]="false"
        [resizeable]="false"
        [draggable]="false"
        [sortable]="false"
        name=""
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          <span
            class="event-type"
            [class]="getEventClass(row)"
            [tooltip]="getEventType(row)"
            [adaptivePosition]="false"
            placement="top"
          ></span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Name" [draggable]="false" [prop]="'nmEV'">
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.value.nmEV }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Description"
        [draggable]="false"
        [prop]="'labelEV'"
      >
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.value.labelEV }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Action" [sortable]="false">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="action-buttons">
            <button
              class="btn btn-sm btn-rounded"
              (click)="editEvent(row)"
              [adaptivePosition]="false"
              tooltip="Edit"
            >
              <span class="visually-hidden">edit</span>
              <i class="bi bi-pencil-fill"></i>
            </button>
            <button
              class="btn btn-sm btn-rounded"
              (click)="cloneEvent(row)"
              [adaptivePosition]="false"
              tooltip="Clone"
            >
              <span class="visually-hidden">clone</span>
              <i class="bi bi-files"></i>
            </button>

            <button
              class="btn btn-sm btn-rounded"
              (click)="deleteEvents([row])"
              [adaptivePosition]="false"
              tooltip="Delete"
            >
              <span class="visually-hidden">delete</span>
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
