import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { FieldWatcher, FormModel } from "src/app/shared/models";
import Utilities from "src/app/shared/utilities/utils";
import {
  EstatePlot,
  FormGroupElement,
  FormLayout,
  PlotFileValidationResults,
} from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import { Subject, takeUntil, take, lastValueFrom } from "rxjs";
import {
  EsatePlotTableColumns,
  GenerateEstateColumns,
  PlotTableColumns,
} from "src/app/my-plots/models";
import { ValidationReportComponent } from "../../plot-files/components/validation-report/validation-report.component";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ExplorerCatetory } from "src/app/core/models";
import { PlotUploadComponent } from "src/app/shared/components/plot-upload/plot-upload.component";

@Injectable({
  providedIn: "root",
})
export class Estate2020Service extends BasePlotFormService {
  readonly unsubscribe$ = new Subject<void>();

  private generatedEstateLog: { succeeded: string[]; failed: string[] } = {
    succeeded: [],
    failed: [],
  };

  public readonly pageId = "estate";
  public readonly pageHelpLink = "167_Plots%20in%20the%20Estate.htm";

  public estatePlotInformation = {
    totalPlots: 0,
    simulatingPlots: 0,
    nonSimulatingPlots: 0,
    totalArea: 0,
    simulatingArea: 0,
    nonSimulatingArea: 0,
    numberOfSelectedPlots: 0,
    areaOfSelectedPlots: 0,
  };

  public selectedEstatePlotInformation = {
    totalPlots: 0,
    totalArea: 0,
  };

  public readonly legend = [
    { color: "#00ff00", label: "Valid" },
    { color: "#ff0000", label: "Not valid" },
    { color: "#ddddff", label: "Before simulation start" },
    { color: "#ffc9ae", label: "After simulation end" },
    { color: "#e1e1e1", label: "Not simulated" },
  ];

  public validationReportTime: string = "Validation has not been run";

  public modifiers: FormGroupElement[] = [
    {
      label: "Validation report",
      isShown: true,
      isRoot: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getValidationReportTime",
            },
            {
              buttonLabel: "View report",
              showButtonMethod: "shouldShowReportButton",
              element: "button",
              submitMethod: "openValidationReport",
            },
          ],
        },
      ],
    },
    {
      label: "All Plot",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: "Total plots",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotInfomation",
              payload: "totalPlots",
            },
          ],
        },
        {
          label: "Simulating",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotInfomation",
              payload: "simulatingPlots",
            },
          ],
        },
        {
          label: "Non-simulating",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotInfomation",
              payload: "nonSimulatingPlots",
            },
          ],
        },
        {
          label: "Total area [ha]",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotInfomation",
              payload: "totalArea",
            },
          ],
        },
        {
          label: "Simulating",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotInfomation",
              payload: "simulatingArea",
            },
          ],
        },
        {
          label: "Non-simulating",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getPlotInfomation",
              payload: "nonSimulatingArea",
            },
          ],
        },
      ],
    },
    {
      label: "Selected Plots",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: "Number of plots",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getSelectedPlotInfomation",
              payload: "totalPlots",
            },
          ],
        },
        {
          preventLabelElement: true,
          label: "Area of plots[ha]",
          inputs: [
            {
              element: "text",
              method: "getSelectedPlotInfomation",
              payload: "totalArea",
            },
          ],
        },
      ],
    },
    {
      label: "Legend",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "LegendComponent",
              componentInputs: [
                {
                  inputKey: "legend",
                  variable: "legend",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Plots in estate",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: false,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: null,
                element: "component",
                component: "PlotInEstateComponent",
                componentInputs: [
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    estatePlots: {
      defaultValue: "formArray",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      isExplorer: true,
      isShown: true,
    },
  };

  //this model is only for xml read
  public estatePlotModel: FormModel = {
    id: {
      dataType: 4,
      defaultValue: null,
    },
    estateTimingEP: {
      dataType: 2,
      defaultValue: null,
    },
    startYrEP: {
      dataType: 4,
      defaultValue: null,
    },
    startStepEP: {
      dataType: 4,
      defaultValue: null,
    },
    hasAreaEP: {
      dataType: 2,
      defaultValue: null,
    },
    areaEP: {
      dataType: 4,
      defaultValue: null,
    },
    plotIxEP: {
      dataType: 4,
      defaultValue: null,
    },
    plotId: {
      dataType: 4,
      defaultValue: null,
    },
    fileName: {
      dataType: 4,
      defaultValue: null,
    },
    simulateEP: {
      dataType: 2,
      defaultValue: null,
    },
  };

  public getValidationReportTime(): string {
    return this.validationReportTime;
  }

  public shouldShowReportButton() {
    return this.validationReportTime !== "Validation has not been run";
  }

  public setPlotInfomation(info): void {
    this.estatePlotInformation = { ...this.estatePlotInformation, ...info };
  }
  public getPlotInfomation(key) {
    return this.estatePlotInformation[key];
  }

  public setSelectedPlotInfomation(info) {
    this.selectedEstatePlotInformation = {
      ...this.selectedEstatePlotInformation,
      totalPlots: info.totalPlots,
      totalArea: info.totalArea,
    };
  }

  public getSelectedPlotInfomation(key) {
    return this.selectedEstatePlotInformation[key];
  }

  public getEstatePlotTemplate(): EstatePlot {
    return this.getEstatePlotFormGroupTemplate().getRawValue();
  }

  public getEstatePlotFormGroupTemplate(): FormGroup {
    const maxValue = this.getStartYearMaxSteps();
    return new FormGroup({
      id: new UntypedFormControl(Utilities.uuid()),
      isValid: new UntypedFormControl(false),
      validationStatus: new UntypedFormControl(null),
      validationMessage: new UntypedFormControl(null),
      estateTimingEP: new UntypedFormControl(false),
      startYrEP: new UntypedFormControl(null, [
        Validators.min(-999),
        Validators.max(9999),
      ]),
      startStepEP: new UntypedFormControl(null, [
        Validators.min(1),
        Validators.max(maxValue),
      ]),
      // timing: new UntypedFormControl("Plot", []),
      hasAreaEP: new UntypedFormControl(false),
      areaEP: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(0),
      ]),
      plotAreaEP: new UntypedFormControl(null, []),
      plotIxEP: new UntypedFormControl(null, []),
      fileName: new UntypedFormControl(null, []),
      plotId: new UntypedFormControl(null, [Validators.required]),
      simulateEP: new UntypedFormControl(true),
    });
  }

  // public fieldWatcher: FieldWatcher = {
  //   "timing:stYrYTZ": (newValue: any, { formGroup }) => {
  //     this.validatePlotAfterTimingUpdated();
  //   },
  //   "timing:stStepInStYrYTZ": (newValue: any, { formGroup }) => {
  //     this.validatePlotAfterTimingUpdated();
  //   },
  //   "timing:enYrYTZ": (newValue: any, { formGroup }) => {
  //     this.validatePlotAfterTimingUpdated();
  //   },
  //   "timing:enStepInEnYrYTZ": (newValue: any, { formGroup }) => {
  //     this.validatePlotAfterTimingUpdated();
  //   },
  // };

  // private validatePlotAfterTimingUpdated() {
  //   const estaePlotFormArray = this.getEstatePlotsFormArray();
  //   estaePlotFormArray.controls.forEach((ef) => {
  //     this.validateEstatePlot(ef);
  //   });
  // }

  public getEstatePlotsFormArray(): FormArray {
    return this.getFormGroup().get("estatePlots") as FormArray;
  }

  public createEstatePlot(estaePlot: EstatePlot) {
    const formGroup = this.getEstatePlotFormGroupTemplate();
    formGroup.patchValue(estaePlot);
    this.validateEstatePlot(formGroup);
    this.getEstatePlotsFormArray().push(formGroup);
  }

  public getStartYearMaxSteps(): number {
    const timingService = this.simulationService.timingService;
    const stepType = timingService.getFormGroup().get("tStepsYTZ").value;
    const customMaxStep = timingService
      .getFormGroup()
      .get("stepsPerYrYTZ").value;
    const maxStepValueMap = timingService.maxStepValueMap;

    const maxSteps =
      stepType == "Other" ? +customMaxStep : +maxStepValueMap[stepType];
    return maxSteps;
  }

  public async validateEstatePlots(): Promise<void> {
    this.simulationService.messageService.setFullScreenLoading(true);
    try {
      const currentdate = new Date();
      const datetime =
        "Last Validated: " +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      this.validationReportTime = datetime;

      const plotFilesService = this.simulationService.plotFilesService;
      const estaePlotFormArray = this.getEstatePlotsFormArray();
      const uniquePlotIdsSet = new Set<string>(
        estaePlotFormArray.value.map((p) => p.plotId)
      );
      const uniquePlotIds = Array.from(uniquePlotIdsSet).filter(
        (id) => id !== undefined && id !== null
      );

      if (!uniquePlotIds.length) {
        return;
      }

      const verifyResults: PlotFileValidationResults[] =
        await plotFilesService.verifyPlotFiles(uniquePlotIds);
      if (verifyResults.length) {
        //also update the plotfile Status
        const plotFileControl = plotFilesService
          .getFormGroup()
          .get("plotFiles");
        const plotFiles = plotFileControl.value;
        await plotFilesService.applyStatusToVerifiedPlotFiles(
          plotFiles,
          verifyResults
        );

        this.applyStatusToVerifiedPlotFiles(verifyResults);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.simulationService.messageService.setFullScreenLoading(false);
    }
  }

  protected applyStatusToVerifiedPlotFiles(
    verifyResults: PlotFileValidationResults[]
  ) {
    const estaePlotFormArray = this.getEstatePlotsFormArray();
    for (let ep of estaePlotFormArray.controls) {
      const plotId = ep.get("plotId").value;
      const foundResult = verifyResults.find((r) => r.plotId == plotId);

      if (!foundResult) {
        ep.get("validationStatus").setValue("Not valid");
        ep.get("validationMessage").setValue(`Plot file cannot be located`);
        ep.get("isValid").setValue(false);
        continue;
      }
      if (foundResult.status == "InValid") {
        ep.get("validationStatus").setValue("Not valid");
        ep.get("validationMessage").setValue(`Plot File was not ready.`);
        ep.get("isValid").setValue(false);
        continue;
      }

      if (foundResult && foundResult.status) {
        const isPlotValid = foundResult.status == "Valid";
        const hasSiteArea = foundResult.siteAreaData.hasArea;
        const useSiteArea = ep.get("hasAreaEP").value;

        if (isPlotValid && !hasSiteArea && useSiteArea) {
          ep.get("validationStatus").setValue("Not valid");
          ep.get("validationMessage").setValue(
            `Estate Plot has 'use plot area' defined. However, the Plot file data for Site:Area is not valid. Please check the Plot file.`
          );
          ep.get("isValid").setValue(false);
          continue;
        }

        this.validateEstatePlot(ep);
      }
    }
  }

  public openValidationReport() {
    const estatePlots = this.getEstatePlotsFormArray().value || [];
    const invalidEstatePlots = estatePlots.filter((p) => p.isValid == false);
    const initialState: ModalOptions = {
      class: "full-screen",
      ignoreBackdropClick: true,
      initialState: {
        plotFiles: invalidEstatePlots,
      },
    };
    this.simulationService.modalService.openModal(ValidationReportComponent, {
      ...initialState,
    });
  }

  public validateEstatePlot(estatePlotFormGroup: AbstractControl) {
    const isValidControl = estatePlotFormGroup.get("isValid");
    const validationMessageControl =
      estatePlotFormGroup.get("validationMessage");
    const statusControl = estatePlotFormGroup.get("validationStatus");
    const useEstateTiming = estatePlotFormGroup.get("estateTimingEP").value;
    const useEstateArea = estatePlotFormGroup.get("hasAreaEP").value;
    const isSimulate = estatePlotFormGroup.get("simulateEP").value;

    if (!isSimulate) {
      statusControl.setValue("Not simulated");
      isValidControl.setValue(true);
      validationMessageControl.setValue("Not simulated");
      return;
    }

    if (!useEstateTiming) {
      estatePlotFormGroup.get("startYrEP").setValidators(Validators.required);
      estatePlotFormGroup.get("startStepEP").setValidators(Validators.required);
    } else {
      estatePlotFormGroup
        .get("startYrEP")
        .removeValidators(Validators.required);
      estatePlotFormGroup
        .get("startStepEP")
        .removeValidators(Validators.required);
    }
    if (!useEstateArea) {
      estatePlotFormGroup.get("areaEP").setValidators(Validators.required);
    } else {
      estatePlotFormGroup.get("areaEP").removeValidators(Validators.required);
    }
    estatePlotFormGroup.updateValueAndValidity();

    if (estatePlotFormGroup.invalid) {
      statusControl.setValue("Not valid");
      isValidControl.setValue(false);
      validationMessageControl.setValue("Estate Plot is invalid");
      return;
    }

    if (this.isBeforeSimulation(estatePlotFormGroup.value)) {
      statusControl.setValue("Before simulation start");
      isValidControl.setValue(true);
      validationMessageControl.setValue("Not simulated");

      return;
    }

    if (this.isAfterSimulation(estatePlotFormGroup.value)) {
      statusControl.setValue("After simulation end");
      isValidControl.setValue(true);
      validationMessageControl.setValue("After simulation end");
      return;
    }

    statusControl.setValue("Valid");
    isValidControl.setValue(true);
    validationMessageControl.setValue("Valid");
    return;
  }

  public setSimulationFalgForPlots(isSimulate: boolean, ids: string[]) {
    let failCounter = 0;
    const estatePlots = this.getEstatePlotsFormArray();

    ids.forEach((id) => {
      const foundPlot = estatePlots.controls.find(
        (ep) => ep.get("id").value == id
      );

      if (foundPlot) {
        foundPlot.get("simulateEP").setValue(isSimulate);
        this.validateEstatePlot(foundPlot);
      } else {
        failCounter += 1;
      }
    });

    if (failCounter > 0) {
      this.simulationService.messageService.addAlert({
        type: "danger",
        msg: `${failCounter} of Estate Plots not found, value could not apply.`,
        dismissible: true,
      });
    }
  }

  public setEstateTimingUsedForPlots(isEstateTiming: boolean, ids: string[]) {
    let failCounter = 0;
    const estatePlots = this.getEstatePlotsFormArray();

    ids.forEach((id) => {
      const foundPlot = estatePlots.controls.find(
        (ep) => ep.get("id").value == id
      );

      if (foundPlot) {
        foundPlot.get("estateTimingEP").setValue(isEstateTiming);
        this.validateEstatePlot(foundPlot);
      } else {
        failCounter += 1;
      }
    });

    if (failCounter > 0) {
      this.simulationService.messageService.addAlert({
        type: "danger",
        msg: `${failCounter} of Estate Plots not found, value could not apply.`,
        dismissible: true,
      });
    }
  }

  public cloneEstatePlot(cloneSettings: {
    years: number;
    steps: number;
    formValues: EstatePlot;
  }) {
    let { id, ...formValues } = cloneSettings?.formValues;
    const maxSteps = this.getStartYearMaxSteps();

    const currentSteps = +cloneSettings.formValues.startStepEP;
    let yearsAdding = cloneSettings.years;
    const isNegativeSteps = cloneSettings.steps < 0;
    let stepsAdding = Math.abs(cloneSettings.steps);

    if (stepsAdding + currentSteps > maxSteps) {
      const value = (stepsAdding + currentSteps) / maxSteps;
      const years = Math.floor(value);
      const remainder = Math.round((value - years) * maxSteps);

      yearsAdding += isNegativeSteps ? -Math.abs(years) : years;
      stepsAdding = remainder;
    } else {
      stepsAdding = stepsAdding + currentSteps;
    }

    formValues.startYrEP = String(
      Math.floor(+formValues.startYrEP + yearsAdding)
    );
    formValues.startStepEP = String(Math.floor(stepsAdding));

    const formGroup = this.getEstatePlotFormGroupTemplate();

    formGroup.patchValue(formValues);
    this.getEstatePlotsFormArray().push(formGroup);
  }

  public updateEstatePlot(estaePlot: EstatePlot) {
    const estatePlots = this.getEstatePlotsFormArray();
    const updatingPlot = estatePlots.controls.find(
      (ep) => ep.value.id == estaePlot.id
    );
    if (updatingPlot) {
      updatingPlot.patchValue(estaePlot);
      this.validateEstatePlot(updatingPlot);
    }
  }

  public updatePlotFileName(plotFile: EsatePlotTableColumns) {
    this.getEstatePlotsFormArray()
      .controls.filter((estateForm) => {
        const plotId = estateForm.get("plotId").value;
        return plotId == plotFile.plotId;
      })
      .forEach((estateForm) => {
        estateForm.get("fileName").setValue(plotFile.fileName);
      });
  }

  public deleteEstatePlots(ids: string[]) {
    ids.forEach((id) => {
      const estatePlots = this.getEstatePlotsFormArray();
      const index = estatePlots.value.findIndex((p) => p.id == id);
      if (index > -1) {
        estatePlots.removeAt(index);
      } else {
        console.error(
          "Estate plot not found, unable to remove the Estate plot."
        );
      }
    });
  }

  public getStartYear(): number {
    return this.simulationService.timingService.getFormGroup().get("stYrYTZ")
      .value;
  }

  public getStartStep(): number {
    const formGroup = this.simulationService.timingService.getFormGroup();
    const timingStepType = formGroup.get("tStepsYTZ").value;

    return timingStepType == "Yearly"
      ? 1
      : formGroup.get("stStepInStYrYTZ").value;
  }

  getTimingYearAndStep() {
    const timingService = this.simulationService.timingService;

    const timingStepType = timingService.getFormGroup().get("tStepsYTZ").value;
    const timingStartYear = timingService.getFormGroup().get("stYrYTZ").value;
    const timingEndYear = timingService.getFormGroup().get("enYrYTZ").value;

    const timingStartStep =
      timingStepType == "Yearly"
        ? 1
        : timingService.getFormGroup().get("stStepInStYrYTZ").value;
    const timingEndStep =
      timingStepType == "Yearly"
        ? 1
        : timingService.getFormGroup().get("enStepInEnYrYTZ").value;

    return {
      timingStartYear,
      timingEndYear,
      timingStartStep,
      timingEndStep,
    };
  }

  public isBeforeSimulation(estatePlot: EstatePlot) {
    const plotYear = estatePlot.startYrEP;
    const plotStep = estatePlot.startStepEP;

    const { timingStartYear, timingStartStep } = this.getTimingYearAndStep();

    if (!isNaN(timingStartYear) && timingStartYear > plotYear) {
      return true;
    }

    if (!isNaN(timingStartStep) && timingStartStep > plotStep) {
      return true;
    }

    return false;
  }

  public isAfterSimulation(estatePlot: EstatePlot) {
    const plotYear = estatePlot.startYrEP;
    const plotStep = estatePlot.startStepEP;

    const { timingEndYear, timingEndStep } = this.getTimingYearAndStep();

    if (!isNaN(timingEndYear) && +plotYear > +timingEndYear) {
      return true;
    }

    if (!isNaN(timingEndStep) && +plotStep > +timingEndStep) {
      return true;
    }

    return false;
  }

  public isOutSideSimulationRange(estatePlot: EstatePlot) {
    return (
      this.isBeforeSimulation(estatePlot) || this.isAfterSimulation(estatePlot)
    );
  }

  public updatedBrokenLinks(plotFiles: PlotTableColumns[]): void {
    const estatePlots = this.getEstatePlotsFormArray();
    const estatePlotsWithNoId = estatePlots.controls.filter(
      (ep: FormControl<EstatePlot>) => {
        return (
          ep.get("plotId").value == null || ep.get("plotId").value == undefined
        );
      }
    );

    for (const plotFile of plotFiles) {
      const foundMatchings = estatePlotsWithNoId.filter(
        (ep) => ep.get("fileName").value == plotFile.fileName
      );
      if (foundMatchings.length) {
        foundMatchings.forEach((fm) => {
          fm.get("plotId").setValue(plotFile.plotId);
          this.validateEstatePlot(fm);
        });
      }
    }
  }

  public async generateEstatePlots(rows: GenerateEstateColumns[]) {
    const plotFilesService = this.simulationService.plotFilesService;

    const selectedProjectCollection =
      await plotFilesService.selectProjectAndCollection();
    plotFilesService.setProjectCollectionFilter(selectedProjectCollection);

    const message = `Are all plot files listed in the CSV uploaded to the designated project and collection? `;
    const confirm = await this.simulationService.modalService.openConfirmModal(
      message
    );

    if (!confirm) {
      await this.uploadPlotFile(selectedProjectCollection);
    }

    const fileObjects: { data: PlotTableColumns[] } = await lastValueFrom(
      this.simulationService.myPlotsService
        .getList("plot", {
          pageNumber: 1,
          size: 99999,
          projectId: selectedProjectCollection.projectId,
          collectionId: selectedProjectCollection.collectionId,
          plotType: "plo",
        })
        .pipe(take(1))
    );

    await plotFilesService.readImportingPlotFiles(
      fileObjects?.data || [],
      true
    );

    this.createEsatePlotFromCsv(rows);

    plotFilesService.relinkPlotFiles();

    this.applyGenerateEstateSummaryToAboutPage(selectedProjectCollection);

    this.simulationService.messageService.setFullScreenLoading(false);
  }

  private applyGenerateEstateSummaryToAboutPage(selectedProjectCollection) {
    const today =
      this.simulationService.helperService.getTodayDateTimeForFileName();
    const fileName = "Generated_Estate_" + today;

    const aboutService = this.simulationService.aboutService;

    const log = this.geerateEstateLog(fileName, selectedProjectCollection);

    aboutService.setProjectCollection(
      selectedProjectCollection.project,
      selectedProjectCollection.collection
    );
    aboutService.setGenerateEstateFileName(fileName);
    aboutService.setGeneratedEstateLog(log);
    this.clearLogs();
  }
  private clearLogs() {
    const plotFileService = this.simulationService.plotFilesService;
    plotFileService.generatedPLotFileLog.succeeded = [];
    plotFileService.generatedPLotFileLog.faliled = [];

    this.generatedEstateLog.succeeded = [];
    this.generatedEstateLog.failed = [];
  }

  private geerateEstateLog(fileName, selectedProjectCollection) {
    const plotFileService = this.simulationService.plotFilesService;
    const plotFileLog = plotFileService.generatedPLotFileLog;
    const baseMessage = [
      "Generated Estate",
      "",
      "Project: " + selectedProjectCollection.project,
      "Collection: " + selectedProjectCollection.collection,
      "",
      "Run time: " + this.getCurrentTimeFormatted(),
    ];
    let logForDownload = [];
    let log = [...baseMessage];
    log.push("", "Plot files:");
    log.push("Succeeded: " + plotFileLog.succeeded.length);
    log.push("Failed: " + plotFileLog.failed.length);
    log.push("", "Estate Plots:");
    log.push("Succeeded: " + this.generatedEstateLog.succeeded.length);
    log.push("Failed: " + this.generatedEstateLog.failed.length);

    logForDownload.push(...log);
    logForDownload.push(
      "",
      "===============================================================",
      ""
    );
    logForDownload.push("", "Plot files:");
    logForDownload.push(...plotFileLog.succeeded);
    logForDownload.push(...plotFileLog.failed);
    logForDownload.push("", "Estate Plots:");
    logForDownload.push(...this.generatedEstateLog.succeeded);
    logForDownload.push(...this.generatedEstateLog.failed);

    this.simulationService.helperService.downloadFile(
      fileName + "_log",
      logForDownload.join("\n"),
      "txt"
    );

    return log.join("\n");
  }

  private getCurrentTimeFormatted(): string {
    const now = new Date();

    // Options for formatting the time part
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
    };

    // Options for formatting the date part
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: "long", // Full name of the day of the week
      day: "2-digit",
      month: "long", // Full name of the month
      year: "numeric",
    };

    // Create Intl.DateTimeFormat instances with desired locales and options
    const timeFormatter = new Intl.DateTimeFormat("en-US", timeOptions);
    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);

    // Format the current date and time
    const formattedTime = timeFormatter.format(now);
    const formattedDate = dateFormatter.format(now);

    // Combine both parts
    return `${formattedTime} on ${formattedDate}`;
  }

  private async uploadPlotFile(projectCollectionFilter) {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        projectCollectionFilter: projectCollectionFilter,
        folders$: null,
      },
    };

    await lastValueFrom(
      this.simulationService.modalService
        .openModal(PlotUploadComponent, {
          ...initialState,
        })
        .pipe(take(1))
    );

    if (!this.simulationService.isUploading$.getValue()) {
      this.simulationService.resetFileUpload();
    }

    return;
  }

  private createEsatePlotFromCsv(rows: GenerateEstateColumns[]) {
    const selectedPlotFiles = this.simulationService.plotFilesService
      .getFormGroup()
      ?.get("plotFiles").value;

    for (const row of rows) {
      const isValid = this.validateGenerateEstateCsvRow(row);
      const fileName = row["plot file"];

      if (!isValid) {
        this.generatedEstateLog.failed.push(
          "Import failed: " + [...Object.values(row)].toString()
        );
        continue;
      }

      let formData: any = {
        startYrEP: row["start year"],
        startStepEP: row["start step"],
        areaEP: row["area"],
        estateTimingEP: true,
        fileName,
      };

      formData = { ...this.getEstatePlotTemplate(), ...formData };
      formData.hasAreaEP = false;

      formData.plotIxEP = selectedPlotFiles[formData.plotIxEP]?.plotId || null;
      formData.fileName = fileName;
      formData.plotId = this.getPlotIdFromPlotFilesPage(fileName);

      this.createEstatePlot(formData);

      this.generatedEstateLog.succeeded.push(
        "Estate Plot added: " +
          [...Object.entries(row)].map((r) => r.join(" ")).toString()
      );
    }
  }

  private validateGenerateEstateCsvRow(row: GenerateEstateColumns) {
    if (
      isNaN(+row["start year"]) ||
      isNaN(+row["start step"]) ||
      isNaN(+row["area"])
    ) {
      return false;
    }

    if (!row["plot file"]) {
      return false;
    }

    return true;
  }

  public readXmlObject() {
    this.getFormGroup();
    const plotFilesService = this.simulationService.plotFilesService;
    //wait for plot file page done first
    const isReadingXml$ = plotFilesService.isReadingXml$;

    isReadingXml$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isReadingXml) => {
        if (isReadingXml == false) {
          this.unsubscribe$.next();

          const fileIdSet = this.simulationService.getPlotFileJson()?.FileIdSet;

          const plotFilesTemplate = fileIdSet?.[0];
          const fileIds = plotFilesTemplate?.FileId;
          const fileObjects = plotFilesService.mapFileObjects(fileIds);

          const template = this.simulationService.getPlotFileJson()["EPlotSet"];

          const ePlots = template[0].EPlot;

          if (ePlots?.length) {
            const selectedPlotFiles = this.simulationService.plotFilesService
              .getFormGroup()
              ?.get("plotFiles").value;
            ePlots.forEach((ep) => {
              let xmlData = ep.$;
              let formData: any = {};
              Object.keys(xmlData).forEach((xmlKey) => {
                formData[xmlKey] = this.convertXmlValueToFormValue(
                  xmlKey,
                  xmlData[xmlKey],
                  this.estatePlotModel
                );
              });

              const fileName = fileObjects[formData.plotIxEP]?.fileName;
              formData = { ...this.getEstatePlotTemplate(), ...formData };
              formData.hasAreaEP = !formData.hasAreaEP; //oppsite value coz of the toogle design

              formData.plotIxEP =
                selectedPlotFiles[formData.plotIxEP]?.plotId || null;
              formData.fileName = fileName;
              formData.plotId =
                fileObjects[formData.plotIxEP]?.plotId ||
                this.getPlotIdFromPlotFilesPage(fileName);

              this.createEstatePlot(formData);
            });

            plotFilesService.validatePlotFiles(
              plotFilesService.getFormGroup().get("plotFiles").value
            );
          }
        }
      });
  }

  protected getPlotIdFromPlotFilesPage(fileName: string) {
    const selectedPlotFiles = this.simulationService.plotFilesService
      .getFormGroup()
      ?.get("plotFiles").value;

    if (!selectedPlotFiles.length) {
      return;
    }

    return selectedPlotFiles.find((p) => p.fileName == fileName)?.plotId;
  }
  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();

    let ePlotSet = {
      $: {
        count: formData.estatePlots.length,
      },
      EPlot: [],
      HeaderState: [
        {
          $: {
            sortIx: "0",
            sortUp: "false",
            sortBy1: "false",
            sortBy2: "false",
            showOnlyHS: "false",
          },
          headSectW: ["55,55,40,65,279,0"],
        },
      ],
    };

    //this.getFormValueObjectForXmlExport(null, formData);
    //convert the id to index for plotIxEP
    const selectedPlotFiles = this.simulationService.plotFilesService
      .getFormGroup()
      ?.get("plotFiles").value;
    ePlotSet.EPlot = formData.estatePlots.map((ep) => {
      const epData: any = this.getFormValueObjectForXmlExport(null, ep);

      const plotIndex = selectedPlotFiles.findIndex(
        (p) => p.plotId == epData.plotId
      );

      return {
        $: {
          hasAreaEP: String(!epData.hasAreaEP), //oppsite value coz of the toogle design
          simulateEP: epData.simulateEP,
          estateTimingEP: epData.estateTimingEP,
          areaEP: epData.areaEP == null ? "" : epData.areaEP,
          startYrEP: epData.startYrEP,
          startStepEP: epData.startStepEP,
          plotIxEP: plotIndex,
        },
      };
    });

    return { EPlotSet: [ePlotSet] };
  }

  public getExplorerSubcategoryMap(data: ExplorerCatetory) {
    return {
      controls: this.getEstatePlotsFormArray().value.map((ep) => {
        return {
          label: ep.fileName,
          model: null,
          programmingName: ep.id,
          value: ep,
          dataType: null,
          service: this,
          item: null,
          input: null,
          formGroup: null,
          displayValue: null,
        };
      }),
    };
  }

  public reset(): void {
    this.generatedEstateLog = {
      succeeded: [],
      failed: [],
    };

    this.estatePlotInformation = {
      totalPlots: 0,
      simulatingPlots: 0,
      nonSimulatingPlots: 0,
      totalArea: 0,
      simulatingArea: 0,
      nonSimulatingArea: 0,
      numberOfSelectedPlots: 0,
      areaOfSelectedPlots: 0,
    };

    this.selectedEstatePlotInformation = {
      totalPlots: 0,
      totalArea: 0,
    };

    this.validationReportTime = "Validation has not been run";

    const estatePlots = this.getFormGroup().get("estatePlots") as FormArray;
    estatePlots.clear();
    this.unsubscribe$.next();
    super.reset();
  }
}
