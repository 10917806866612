<div class="row">
  <h1>Portal admin</h1>
  <div class="col-8">
    <input
      class="form-check-input textfilter"
      type="text"
      placeholder="Enter User Name to filter ..."
      #IdtxtFilter
      [(ngModel)]="txtFilter"
      (change)="FilterData()"
      aria-label="Enter User Name to filter"
    />
  </div>
  <div class="col-1">
    <button
      type="submit"
      class="btn btn-outline-primary-darken btn-refresh"
      (click)="Refreshdata()"
    >
      Refresh
    </button>
  </div>
  <div class="col-3">
    <i class="bi bi-bag-check help-text pull-right"> User Requested</i> <br />
    <i class="bi bi-bag-check status-pending pull-right"> Pending Approval</i>
    <br />
    <i class="bi bi-joystick icon-grant help-text pull-right">
      Access Granted</i
    >
    <br />
    <i class="uncheked pull-right">Red Color - User NOT Requested</i> <br />
  </div>
</div>
<br />
<div class="row col-12">
  <table class="table table-striped">
    <thead class="thead-dark">
      <tr>
        <th style="display: none">userGuidId</th>
        <th>UserName</th>
        <th>User Email</th>
        <th>RoleName</th>
        <!-- <th>Rmt Access</th>
        <th>2016 Access</th> -->
        <th>2020 Access</th>
        <th>2024 Access</th>
        <th>Grant</th>
        <!-- <th>Reject</th> -->
      </tr>
    </thead>
    <tbody *ngIf="recordsExists == true">
      <tr *ngFor="let item of response">
        <td style="display: none">{{ item.userGuid }}</td>
        <td>{{ item.userName }}</td>
        <td>{{ item.userEmailId }}</td>
        <td>{{ item.roleName }}</td>
        <!-- <td>
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="item.canAccessRMT"
              (change)="openModal(template, item.oldRMT, item.canAccessRMT)"
            />
            <span *ngIf="item.oldRMT == false" class="uncheked">
              RMT Access</span
            >
            <span *ngIf="item.oldRMT == true"> RMT Access</span>
          </label>
          &nbsp;
          <i
            *ngIf="item.oldRMT == true && item.canAccessRMT == true"
            class="bi bi-bag-check help-text"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.oldRMT == true && item.canAccessRMT == false"
            class="bi bi-bag-check status-pending"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.accessRMT"
            class="bi bi-joystick icon-grant"
            (click)="helpTextModal(helptemplate)"
          ></i>
        </td> -->
        <!-- <td>
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="item.canAccessFullCam2016"
              (change)="
                openModal(template, item.old2016, item.canAccessFullCam2016)
              "
            />
            <span *ngIf="item.old2016 == false" class="uncheked">
              2016 Access</span
            >
            <span *ngIf="item.old2016 == true"> 2016 Access</span>
          </label>
          &nbsp;
          <i
            *ngIf="item.old2016 == true && item.canAccessFullCam2016 == true"
            class="bi bi-bag-check help-text"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.old2016 == true && item.canAccessFullCam2016 == false"
            class="bi bi-bag-check status-pending"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.accessFullCam2016"
            class="bi bi-joystick icon-grant"
            (click)="helpTextModal(helptemplate)"
          ></i>
        </td> -->
        <td>
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="item.canAccessFullCam2020"
              (change)="
                openModal(template, item.old2020, item.canAccessFullCam2020)
              "
            />
            <span *ngIf="item.old2020 == false" class="uncheked">
              2020 Access</span
            >
            <span *ngIf="item.old2020 == true"> 2020 Access</span>
          </label>
          &nbsp;
          <i
            *ngIf="item.old2020 == true && item.canAccessFullCam2020 == true"
            class="bi bi-bag-check help-text"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.old2020 == true && item.canAccessFullCam2020 == false"
            class="bi bi-bag-check status-pending"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.accessFullCam2020"
            class="bi bi-joystick icon-grant"
            (click)="helpTextModal(helptemplate)"
          ></i>
        </td>
        <td>
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              [(ngModel)]="item.canAccessFullCam2024"
              (change)="
                openModal(template, item.old2024, item.canAccessFullCam2024)
              "
            />
            <span *ngIf="item.old2024 == false" class="uncheked">
              2024 Access</span
            >
            <span *ngIf="item.old2024 == true"> 2024 Access</span>
          </label>
          &nbsp;
          <i
            *ngIf="item.old2024 == true && item.canAccessFullCam2024 == true"
            class="bi bi-bag-check help-text"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.old2024 == true && item.canAccessFullCam2024 == false"
            class="bi bi-bag-check status-pending"
            (click)="helpTextModal(helptemplate)"
          ></i>
          <i
            *ngIf="item.accessFullCam2024"
            class="bi bi-joystick icon-grant"
            (click)="helpTextModal(helptemplate)"
          ></i>
        </td>
        <td>
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="shouldDisableGrant(item)"
            (click)="OnGrant(item)"
          >
            Grant
          </button>
        </td>
        <!-- <td>
          <button type="submit" class="btn btn-danger" (click)="OnReject(item)">
            Reject
          </button>
        </td> -->
      </tr>
    </tbody>
    <tbody *ngIf="recordsExists == false">
      <tr>
        <td colspan="8">No Records exists</td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #template>
  <div class="modal-header role-template-header">
    <h4 class="modal-title pull-left">User Request Access</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">You are trying to Override User Access</div>
</ng-template>
<ng-template #helptemplate>
  <div class="modal-header help-template-header">
    <h4 class="modal-title pull-left">Help Text</h4>
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalHelpRef?.hide()"
    >
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">User requested access</div>
</ng-template>
