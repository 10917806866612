import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";
import { RotationEvent, RotationForest } from "../../models";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";

@Injectable({
  providedIn: "root",
})
export class RotationFertilizationService extends BaseRotationEventService {
  public _type = "RotationFertilization";

  private intensityDisplayMap = {
    Low: "Low fertilization",
    Medium: "Average fertilization",
    High: "High fertilization",
  };

  public availableOpitions = [];
  public additionalFormControls = [
    {
      label: "Fertilization Level",
      key: "fertilizationLevel",
      element: "select",
      getOptions: () => this.availableOpitions,
    },
  ];

  constructor(
    public rotationEvent: RotationEvent,
    public rotationFormGroup: AbstractControl<RotationForest>,
    public managementService: RmtManagementService
  ) {
    super(rotationEvent, rotationFormGroup, managementService);

    this.eventFormGroup.addControl(
      "fertilizationLevel",
      new FormControl(null, [Validators.required])
    );

    const fertilizationLevelGroups = Array.isArray(
      this.regimeEventReference?.Value?.FertilizationLevelGroups
    )
      ? this.regimeEventReference.Value.FertilizationLevelGroups
      : [
          this.regimeEventReference.Value.FertilizationLevelGroups
            .FertilizationLevelGroup,
        ];

    if (fertilizationLevelGroups?.length) {
      this.availableOpitions = fertilizationLevelGroups.map((fg) => {
        return {
          value: fg.FertilizationLevel,
          label: this.intensityDisplayMap[fg.FertilizationLevel],
        };
      });

      const defaultValue = fertilizationLevelGroups[0].FertilizationLevel;
      this.eventFormGroup.get("fertilizationLevel").setValue(defaultValue);
    }
  }

  public getIntensity() {
    const fertilizationLevelGroups = Array.isArray(
      this.regimeEventReference?.Value?.FertilizationLevelGroups
    )
      ? this.regimeEventReference.Value.FertilizationLevelGroups
      : [
          this.regimeEventReference.Value.FertilizationLevelGroups
            .FertilizationLevelGroup,
        ];

    if (fertilizationLevelGroups?.length) {
      return (
        "Fertilization Level: " +
          this.intensityDisplayMap[
            this.eventFormGroup.get("fertilizationLevel").value
          ] || ""
      );
    }
  }

  override writeXmlObject() {
    let template = super.writeXmlObject();

    template["FertilizationLevel"] =
      this.eventFormGroup.get("fertilizationLevel").value;

    if (
      !template.RegimeEventReferenceValue["FertilizationLevelGroups"]
        ?.FertilizationLevelGroup
    ) {
      template.RegimeEventReferenceValue["FertilizationLevelGroups"] = {
        FertilizationLevelGroup:
          template.RegimeEventReferenceValue["FertilizationLevelGroups"],
      };
    }
    return template;
  }
}
