import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "src/app/auth/services/auth.service";
import { MessageService } from "./message.service";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CustomApiResponse } from "../models";

@Injectable()
export class FcHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  protected readonly defaultMessage = "Something went wrong";
  protected readonly messageMap = {
    "user-guid": `${this.defaultMessage} while adding user.`,
    "soft-delete-user": `${this.defaultMessage} while deleting user.`,
    "save-role-access": `${this.defaultMessage} while saving user roles.`,
    "user-roles": `${this.defaultMessage} while getting user roles.`,
    "unapproved-users": `${this.defaultMessage} while getting unapproved users.`,
    "grant-access": `${this.defaultMessage} while getting user's role granted.`,
    "reject-access": `${this.defaultMessage} while getting rejecting user's access.`,
    "privacy-accept-notification": `${this.defaultMessage} while getting sending privacy statement notification.`,
    template: `${this.defaultMessage} while getting templates.`,
    "run-plotsimulation": `${this.defaultMessage} while running simulation.`,
    "run-pld-filesimulation": `${this.defaultMessage} while running simulation.`,
    "run-est-filesimulation": `${this.defaultMessage} while running simulation.`,
    siteinfo: `${this.defaultMessage} while getting spatial data.`,
    regimes: `${this.defaultMessage} while getting regimes.`,
    "download-plotfile": `${this.defaultMessage} while downloading plo file.`,
    "upload-plotfile": `${this.defaultMessage} while saving plot file.`,
    "upload-est-plotfiles": `${this.defaultMessage} while saving estate file.`,
    "save-project": `${this.defaultMessage} while saving project folder.`,
    "save-collection": `${this.defaultMessage} while saving collection folder.`,
    "project-collections": `${this.defaultMessage} while getting project and collection folders.`,
    plotfiles: `${this.defaultMessage} while getting plo files.`,
    "update-plotfile": `${this.defaultMessage} while updating plo files.`,
  };

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const adId = this.authService.getActiveAccount()?.localAccountId;
    let headers = req.headers.set(
      "userGuidId",
      this.authService.userGuid || ""
    );

    if (adId) {
      headers = headers.set("userId", adId);
    }

    const requestClone = req.clone({ headers });
    return next.handle(requestClone).pipe(
      catchError((error) => {
        /******* 
         * this is NOT a good error handing example,
         if possible backend should standardise the error output from api side*******/
        const apiName: string = req.url.split("/").pop();
        let responseData;

        if (
          error?.error &&
          typeof error.error === "object" &&
          "status" in error.error &&
          "message" in error.error
        ) {
          const customError = error.error as CustomApiResponse<any>;
          this.displayCustomMessage(customError.message);
        }

        if (error?.error instanceof Blob) {
          //check if blob, from digest sim
          this.readBlobError(error.error).then((result) => {
            this.displayMessage(error, result?.errors || result, apiName);
          });
          return throwError(() => error);
        }

        if (error?.errors) {
          this.displayMessage(error, error.errors, apiName);
          return throwError(() => error);
        }

        if (error?.error?.errors) {
          this.displayMessage(error, error.error.errors, apiName);
          return throwError(() => error);
        }

        if (error?.error && typeof error.error === "object") {
          //check if the reponse detail is real object or not
          this.displayMessage(error, error.error, apiName);
          return throwError(() => error);
        } else {
          try {
            responseData = JSON.parse(error.error);
          } catch (err) {
            this.displayMessage(error, null, apiName);
            return throwError(() => error);
          }
        }

        if (!responseData) {
          this.displayMessage(error, null, apiName);
          return throwError(() => error);
        }

        this.displayMessage(error, responseData, apiName);

        return throwError(() => error);
      })
    );
  }

  private displayMessage(error, responseData, apiName) {
    let message;
    if (responseData) {
      if (error.status == 500) {
        message = responseData.detail;
      } else if (error.status == 400) {
        if (responseData.errors) {
          message = this.errorsToString(responseData.errors);
        } else {
          message = Object.entries(responseData)
            .map(
              (errArray) =>
                `${errArray[0]}: ${(errArray[1] as string[])
                  .map((e) => e)
                  .join(", ")}`
            )
            .join(", ");
        }
      } else {
        message = this.messageMap[apiName] || this.defaultMessage;
      }
    }

    this.messageService.addAlert({
      type: "danger",
      msg: message,
      dismissible: true,
    });
    this.messageService.removeAllLoading();
  }
  private errorsToString(errors): string {
    let errorMessage = "";
    Object.keys(errors).forEach((key) => {
      const messages = errors[key].join(", ");
      errorMessage += `${key}: ${messages}. `;
    });
    return errorMessage.trim();
  }

  protected async readBlobError(responseData): Promise<any> {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        let errorMessage = null;
        try {
          errorMessage = JSON.parse(String(reader.result));
          resolve(errorMessage);
        } catch (err) {
          reject(null);
        }
      };

      reader.onerror = () => {
        reject(null);
      };
      reader.readAsText(responseData);
    });
  }

  protected displayCustomMessage(message) {
    this.messageService.addAlert({
      type: "danger",
      msg: message,
      dismissible: true,
    });
    this.messageService.removeAllLoading();
  }
}
