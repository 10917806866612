import { Component, OnInit, TemplateRef } from "@angular/core";
import { DbService } from "../../shared/services/db.service";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { PortalModalServiceComponent } from "./portal-modal-service/portal-modal-service.component";
import { forEach } from "lodash";
import {
  UnApprovedUsers,
  UnApprovedUsersData,
} from "../models/UnApprovedUsers copy";
import { AuthService } from "src/app/auth/services/auth.service";

@Component({
  selector: "fc-portal-admin",
  templateUrl: "./portal-admin.component.html",
  styleUrls: ["./portal-admin.component.scss"],
})
export class PortalAdminComponent implements OnInit {
  bsModalRef?: BsModalRef;
  modalRef?: BsModalRef;
  modalHelpRef?: BsModalRef;
  constructor(
    private authService: AuthService,
    private dbService: DbService,
    private modalService: BsModalService
  ) {}
  response: UnApprovedUsersData[] = [];
  recordsExists: boolean = false;
  txtFilter: string = "";
  saveResponse: UnApprovedUsersData[] = [];

  ngOnInit(): void {
    this.GetUnApprovedUsers();
  }
  GetUnApprovedUsers() {
    this.dbService
      .getUnApprovedUsers()
      .pipe()
      .subscribe((users: any) => {
        this.response = this.BindResponseData(users);
        this.saveResponse = this.response;
        this.recordsExists = this.response.length > 0;
      });
  }
  Refreshdata() {
    if (this.txtFilter.trim().length > 2) {
      this.FilterData();
    } else {
      this.GetUnApprovedUsers();
    }
  }
  FilterData() {
    this.response = this.saveResponse;
    if (this.txtFilter.trim().length > 2) {
      let result: UnApprovedUsersData[] = [];
      result.push(
        this.response.find((obj) => {
          return obj.userName
            .toLowerCase()
            .includes(this.txtFilter.toLowerCase());
        })
      );
      this.response = result;
    }
    this.recordsExists =
      this.response[0] != undefined && this.response.length > 0;
  }

  OnGrant(roleData: UnApprovedUsersData) {
    const initialState: ModalOptions = {
      initialState: {
        canAccessFullCam2016: roleData.canAccessFullCam2016,
        canAccessRMT: roleData.canAccessRMT,
        canAccessFullCam2020: roleData.canAccessFullCam2020,
        canAccessFullCam2024: roleData.canAccessFullCam2024,
        title: "Grant User Role Access",
        name: roleData.userName,
        status: "Grant",
        grantedAccessFullCam2016: roleData.accessFullCam2016,
        grantedAccessRMT: roleData.accessRMT,
        grantedAccessFullCam2020: roleData.accessFullCam2020,
        grantedAccessFullCam2024: roleData.accessFullCam2024,
      },
    };
    this.bsModalRef = this.modalService.show(
      PortalModalServiceComponent,
      initialState
    );
    this.bsModalRef.content.event.subscribe((res) => {
      if (res.data == "Confirm") {
        roleData.status = "Grant";
        roleData.canAccessFullCam2016 = res.access2016;
        roleData.canAccessFullCam2020 = res.access2020;
        roleData.canAccessFullCam2024 = res.access2024;
        roleData.canAccessRMT = res.accessRMT;
        this.dbService
          .grantUserRoleAccess(roleData)
          .pipe()
          .subscribe((users: any) => {
            this.response = this.BindResponseData(users.body);
            this.saveResponse = this.response;
            this.recordsExists = this.response.length > 0;
          });
      }
    });
  }
  OnReject(roleData: UnApprovedUsersData) {
    const initialState: ModalOptions = {
      initialState: {
        canAccessFullCam2016: false,
        canAccessRMT: false,
        canAccessFullCam2020: false,
        canAccessFullCam2024: false,
        title: "Reject User Role Access",
        name: roleData.userName,
        status: "Reject",
        grantedAccessFullCam2016: roleData.accessFullCam2016,
        grantedAccessRMT: roleData.accessRMT,
        grantedAccessFullCam2020: roleData.accessFullCam2020,
        grantedAccessFullCam2024: roleData.accessFullCam2024,
      },
    };
    this.bsModalRef = this.modalService.show(
      PortalModalServiceComponent,
      initialState
    );
    this.bsModalRef.content.event.subscribe((res) => {
      if (res.data == "Confirm") {
        let request = roleData;
        request.status = "Grant";
        request.canAccessFullCam2016 =
          res.access2016 == true ? false : roleData.canAccessFullCam2016;
        request.canAccessFullCam2024 =
          res.access2024 == true ? false : roleData.canAccessFullCam2024;
        request.canAccessRMT =
          res.accessRMT == true ? false : roleData.canAccessRMT;
        request.canAccessFullCam2020 =
          res.access2020 == true ? false : roleData.canAccessFullCam2020;
        this.dbService
          .rejectUserRoleAccess(request)
          .pipe()
          .subscribe((users: any) => {
            this.response = this.BindResponseData(users.body);
            this.saveResponse = this.response;
            this.recordsExists = this.response.length > 0;
          });
      }
    });
  }
  openModal(template: TemplateRef<any>, showPopUp: boolean, chk: boolean) {
    if (showPopUp == true && chk != showPopUp) {
      this.modalRef = this.modalService.show(template);
    }
  }
  helpTextModal(template: TemplateRef<any>) {
    this.modalHelpRef = this.modalService.show(template);
  }

  shouldDisableGrant(roleData: UnApprovedUsersData) {
    return !roleData.canAccessFullCam2024 && !roleData.canAccessFullCam2020;
  }

  BindResponseData(request: UnApprovedUsers[]): UnApprovedUsersData[] {
    let response: UnApprovedUsersData[] = [];
    request.forEach((obj) => {
      let dataObj: UnApprovedUsersData = {
        userGuid: obj.userGuid,
        userName: obj.userName,
        roleName: obj.roleName,
        canAccessRMT: obj.canAccessRMT,
        canAccessFullCam2016: obj.canAccessFullCam2016,
        canAccessFullCam2020: obj.canAccessFullCam2020,
        canAccessFullCam2024: obj.canAccessFullCam2024,
        accessRMT:
          obj.canAccessRMT == true &&
          (obj.status == "Grant" || obj.status == "Pending"),
        accessFullCam2016:
          obj.canAccessFullCam2016 == true &&
          (obj.status == "Grant" || obj.status == "Pending"),
        accessFullCam2020:
          obj.canAccessFullCam2020 == true &&
          (obj.status == "Grant" || obj.status == "Pending"),
        accessFullCam2024:
          obj.canAccessFullCam2024 == true &&
          (obj.status == "Grant" || obj.status == "Pending"),
        oldRMT: obj.oldRMT,
        old2016: obj.old2016,
        old2020: obj.old2020,
        old2024: obj.old2024,
        canAccessUserModule: obj.canAccessUserModule,
        canAccessStatistics: obj.canAccessStatistics,
        status: obj.status,
        userEmailId: obj.userEmailId,
      };
      response.push(dataObj);
    });
    return response;
  }
}
