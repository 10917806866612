import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ModalOptions } from "ngx-bootstrap/modal";
import { Subject, Observable, BehaviorSubject, interval } from "rxjs";
import {
  take,
  startWith,
  auditTime,
  debounce,
  mergeMap,
  map,
} from "rxjs/operators";
import { EventsService, RegimeA, RegimeF } from "src/app/shared/models";
import { ModalService } from "src/app/shared/services/modal.service";
import TimeUtilities from "src/app/shared/utilities/time";
import { EventStyleClass, RegimeFormData } from "../../models";
import { RegimeFormComponent } from "../regime-form/regime-form.component";
import { takeUntil, delay } from "rxjs/operators";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { CloneRegimeFormComponent } from "../clone-regime-form/clone-regime-form.component";
import { LayoutService } from "src/app/layout/layout.service";

@Component({
  selector: "fc-regime-editor",
  templateUrl: "./regime-editor.component.html",
  styleUrls: ["./regime-editor.component.scss"],
})
export class RegimeEditorComponent implements OnInit {
  @Input()
  startDate: Date;

  @Input()
  endDate: Date;

  @Input()
  availableRegimesMap$: (RegimeA | RegimeF)[] = [];

  @Input()
  formGroupInstance: UntypedFormGroup;

  @Input()
  eventsService: EventsService;

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;
  private readonly destroy$ = new Subject<void>();

  public regimes: UntypedFormGroup[] = [];
  public selectedRows: UntypedFormGroup[] = [];

  public isBulkActionLoading$ = new BehaviorSubject<boolean>(false);
  public selectedBulkAction: "delete" | "merge" = "delete";

  public isOutsideViewport: boolean = false;
  public isSlimRegimeExpanded: boolean = true;

  public highlightSelectionMap = {};
  protected isProccessingHighlightState: boolean = false;
  public modifierColumnWidth$: Observable<number>;

  constructor(
    private hostElement: ElementRef,
    private modalService: ModalService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    //delete below
    // let test: any = result;
    // const moment = TimeUtilities.getMoment();
    // test.startDate = moment("01/01/2010").toDate();
    // test.untilDate = moment("01/01/2020").toDate();
    // this.regimeSelected.emit(test);
    this.modifierColumnWidth$ = this.layoutService.modifierColumnWidth$;

    this.eventsService.selectedRegimeAndEventState$
      .pipe(
        takeUntil(this.destroy$),
        debounce(() => interval(500)),
        delay(0)
      )
      .subscribe((state) => {
        if (state && state.from == "event") {
          this.selectRows(state.regimeInstances);
        }
      });

    this.eventsService
      .getEventQ()
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(this.eventsService.getEventQ()["controls"]),
        auditTime(500)
      )
      .subscribe(() => {
        //note: this does not track individual item changes
        setTimeout(() => {
          this.setRegimes();
        }, 0);
      });

    this.layoutService.forceUpdateLayoutSizes();
  }

  ngAfterViewInit(): void {
    this.createAndObserve(this.hostElement)
      .pipe(debounce(() => interval(200)))
      .subscribe((isOutsideViewport) => {
        this.isOutsideViewport = isOutsideViewport;
      });
  }

  protected setRegimes(): void {
    this.regimes = [
      ...this.eventsService.getRegimesFromEvents(
        this.eventsService
          .getFilteredEventQByPlotType()
          ["controls"].sort(
            (a: AbstractControl, b: AbstractControl) =>
              this.getRegimeDate(a) - this.getRegimeDate(b)
          )
      ),
    ];
    this.eventsService.setNumberofRegimes(this.regimes.length);
    this.setNunmberOfUniqueRegimes(this.regimes);
  }

  //workaround to access this contenxt
  public getRowClass = (fg) => {
    return {
      highlighted: this.highlightSelectionMap[fg.get("regimeInstance").value],
    };
  };

  protected selectRows(regimeInstances): void {
    this.isProccessingHighlightState = true;
    this.highlightSelectionMap = {};
    this.deselectAll();
    regimeInstances.forEach((ri) => {
      this.highlightSelectionMap[ri] = true;
    });
    //force the view to update
    this.regimes = [...this.regimes];
    this.isProccessingHighlightState = false;
  }

  protected createAndObserve(element: ElementRef): Observable<boolean> {
    return new Observable((observer) => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        observer.next(entries);
      });

      intersectionObserver.observe(element.nativeElement);

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map((entry) => !entry.isIntersecting)
    );
  }

  protected setNunmberOfUniqueRegimes(regimes) {
    let unique = [];
    regimes.forEach((r) => {
      const regimeId = r.get("regimeId").value;
      if (!unique.includes(regimeId)) {
        unique.push(regimeId);
      }
    });
    this.eventsService.setNunmberOfUniqueRegimes(unique.length);
  }

  public getRegimeList(availableRegimesMap) {
    if (availableRegimesMap) {
      return Object.values(availableRegimesMap)
        .map((m: any) =>
          m.regime.map((r) => {
            return { ...r, type: m.type };
          })
        )
        .flat()
        .sort((a, b) => (a.$.nmRG > b.$.nmRG ? 1 : -1));
    }
    return [];
  }

  public async openRegimeForm(availableRegimesMap) {
    const moment = TimeUtilities.getMoment();
    let startDate;
    let endDate;
    //by default start date will be between sim start and end date in Timing tab,
    //start date will start after the selected regime date
    //and end date will be set before next regime event start date or sim end date
    //if any regime selected after bringing up this form
    if (this.selectedRows.length) {
      //start date will start after the latest event end date
      const regimeInstance = this.selectedRows
        .sort((a, b) => this.getRegimeDate(b) - this.getRegimeDate(a))[0]
        .get("regimeInstance").value;

      startDate = this.formGroupInstance
        .get("eventQ")
        .value.filter((e) => e.regimeInstance == regimeInstance)
        .sort((a, b) => b.dateEV - a.dateEV)[0].dateEV;

      startDate = moment(startDate).add(1, "day").toDate();

      const endDateIndex = this.regimes.findIndex(
        (r) => r.get("regimeInstance").value == regimeInstance
      );

      if (endDateIndex > -1 && this.regimes[endDateIndex + 1]) {
        const endDateRegimeInstance =
          this.regimes[endDateIndex + 1].get("regimeInstance").value;
        endDate = this.formGroupInstance
          .get("eventQ")
          .value.filter((e) => e.regimeInstance == endDateRegimeInstance)
          .sort((a, b) => b.dateEV - a.dateEV)[0].dateEV;

        endDate = moment(endDate).subtract(1, "day").toDate();
      } else {
        endDate = this.endDate;
      }
    } else {
      startDate = this.startDate;
      endDate = this.endDate;
    }

    //if no selected rows it will append to the end
    if (!this.selectedRows.length && this.regimes.length) {
      const startDateRegimeInstance =
        this.regimes[this.regimes.length - 1].get("regimeInstance").value;
      startDate = this.formGroupInstance
        .get("eventQ")
        .value.filter((e) => e.regimeInstance == startDateRegimeInstance)
        .sort((a, b) => b.dateEV - a.dateEV)[0].dateEV;

      startDate = moment(startDate).add(1, "day").toDate();
    }

    const initialState: ModalOptions = {
      initialState: {
        regimeList: this.getRegimeList(availableRegimesMap),
        startDate: startDate,
        endDate: endDate,
        eventsService: this.eventsService,
      },
    };

    this.modalService
      .openModal(RegimeFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.eventsService.createEventsByRegime(result);
        }
      });
  }
  // protected getFirstOrLastEventDateFromRegime(regimeInstance, option:'first'|'last'){

  // }

  public getEventType(fb: UntypedFormGroup): string {
    const eventName = fb.get("tEV")?.value;
    const isSimulate = fb.get("onEV")?.value;
    if (!eventName) {
      return;
    }
    if (!isSimulate) {
      return "Event disabled";
    }
    return this.eventsService.simulationService.eventFormsService.getAvailableEventTypes()[
      eventName
    ]?.label;
  }
  public getRegimeDate(fb: AbstractControl) {
    return this.eventsService.getEventDate(fb);
  }

  public getEventClass(fb: UntypedFormGroup): EventStyleClass {
    const isSimulate = fb.get("onEV")?.value;
    const eventName = fb.get("tEV")?.value;

    if (!eventName) {
      return;
    }
    if (!isSimulate) {
      return "disabled-event";
    }
    const eventType =
      this.eventsService.simulationService.eventFormsService.getAvailableEventTypes()[
        eventName
      ]?.type;
    return eventType == "tree"
      ? "forest-event"
      : eventType == "crop"
      ? "agricultural-event"
      : "mixed-event";
  }

  public cloneRegime(fb) {
    const initialState: ModalOptions = {
      initialState: {
        eventsService: this.eventsService,
      },
    };

    this.modalService
      .openModal(CloneRegimeFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result: { years: number; repeatTimes: number }) => {
        if (result) {
          this.eventsService.cloneRegime(
            fb.get("regimeInstance").value,
            result
          );
        }
      });
  }

  public editRegime(fb: UntypedFormGroup) {
    const initialState: ModalOptions = {
      initialState: {
        formData: fb.getRawValue(),
        startDate: this.startDate,
        eventsService: this.eventsService,
        simulationTimingType: this.eventsService.getSimulationTimingType(),
      },
    };

    this.modalService
      .openModal(RegimeFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const moment = TimeUtilities.getMoment();
          let resultWithModifiedDate = { ...result };
          //legacy Fullcam behaviour
          if (
            result.dateOriginEV == "Calendar" &&
            moment(result.dateEV).isAfter(fb.get("dateEV").value)
          ) {
            resultWithModifiedDate.dateEV = moment(result.dateEV)
              .subtract(1, "day")
              .toDate();
          }

          const daysAdded = moment(result.dateEV).diff(
            moment(fb.get("dateEV").value),
            "days"
          );

          this.eventsService.updateRegime(
            { ...fb.getRawValue(), ...resultWithModifiedDate },
            daysAdded
          );
        }
      });
  }

  public deleteRegimes(fbs: UntypedFormGroup[]) {
    fbs.forEach((fb) => {
      const regimeInstance = fb.get("regimeInstance").value;
      this.eventsService.deleteRegimes([regimeInstance]);
    });
    this.deselectAll();
  }

  public onSelect({ selected }) {
    if (this.isProccessingHighlightState) {
      return;
    }
    this.highlightSelectionMap = {};
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
    this.eventsService.setSelectedRegimeAndEventState({
      from: "regime",
      regimeInstances: this.selectedRows.map(
        (r) => r.get("regimeInstance").value
      ),
    });
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.selectedRows = [];
  }

  public bulkAction() {
    // const ids = this.selectedRows.map((row) => String(row.plotId));

    if (this.selectedBulkAction == "delete") {
      this.deleteRegimes(this.selectedRows);
    } else if (this.selectedBulkAction == "merge") {
      const selectedRow = this.selectedRows.sort(
        (a: any, b: any) => this.getRegimeDate(a) - this.getRegimeDate(b)
      )[0];
      const initialState: ModalOptions = {
        initialState: {
          formData: selectedRow.getRawValue(),
          isMerge: true,
        },
      };

      this.modalService
        .openModal(RegimeFormComponent, {
          ...initialState,
        })
        .pipe(take(1))
        .subscribe((result: RegimeFormData) => {
          if (result) {
            this.eventsService.mergeRegimes(
              selectedRow,
              this.selectedRows,
              result.nmRegime
            );
            setTimeout(() => {
              this.setRegimes();
            }, 0);
          }
          this.deselectAll();
        });
    }
  }

  public shouldDisableNewRegime(availableRegimesMap) {
    return !Object.keys(availableRegimesMap).length;
  }

  public getSlimRegimeLeftPostion(modifierColumnWidth: number) {
    if (this.isOutsideViewport && !this.isSlimRegimeExpanded) {
      return 0 - modifierColumnWidth + 77 + "px";
    } else if (this.isOutsideViewport && this.isSlimRegimeExpanded) {
      return "77px";
    } else {
      return 0 - modifierColumnWidth + "px";
    }
  }

  public getDateDisplayFormat(date) {
    const moment = TimeUtilities.getMoment();
    try {
      const momentObject = moment(date);
      const formattedDate =
        momentObject.format("d MMM") + " " + momentObject.year();
      return formattedDate;
    } catch (err) {
      return "Invalid Date";
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
