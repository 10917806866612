<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>
      New Estate plot
      <a
        aria-label="help link button"
        type="button"
        *ngIf="service?.baseHelpPageUrl"
        class="btn"
        [href]="service.baseHelpPageUrl + '/' + helpUrl"
        tooltip="View Estate help"
        target="_blank"
      >
        <i class="bi bi-question-circle"></i>
      </a>
    </h1>
    <form [formGroup]="formGroup">
      <div class="title-group"><h2>Start timing:</h2></div>
      <div class="input-container inline-element">
        <label class="label form-label" for="use-estate-timing"
          >Use Estate Timing</label
        >

        <div form-field class="form-check form-switch">
          <input
            id="use-estate-timing"
            class="form-check-input switch-input"
            type="checkbox"
            role="switch"
            autocomplete="off"
            formControlName="estateTimingEP"
          />
        </div>
      </div>

      <div
        class="input-container inline-timing-element"
        [class]="{ disabled: formGroup.get('estateTimingEP').value }"
      >
        <span class="plot-timing-label">Plot in the Estate:</span>
        <div class="timing-element">
          <div>
            <label class="label form-label" for="plot-year"> Year</label>
            <div class="messsage-container">
              <input
                [attr.disabled]="
                  formGroup.get('estateTimingEP').value == true || null
                "
                id="plot-year"
                class="plot-timing form-control form-control-sm"
                type="number"
                formControlName="startYrEP"
              />

              <fc-form-error-messages
                *ngIf="
                  formGroup.get('startYrEP')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('startYrEP')"
              >
              </fc-form-error-messages>
            </div>
          </div>
          <div>
            <label class="label form-label" for="plot-step"> Step </label>
            <div class="messsage-container">
              <input
                [attr.disabled]="
                  formGroup.get('estateTimingEP').value == true || null
                "
                id="plot-step"
                class="plot-timing form-control form-control-sm"
                type="number"
                formControlName="startStepEP"
              />

              <fc-form-error-messages
                *ngIf="
                  formGroup.get('startStepEP')?.errors &&
                  (formGroup.touched || formGroup.dirty)
                "
                [formControlInstance]="formGroup.get('startStepEP')"
              >
              </fc-form-error-messages>
            </div>
          </div>
        </div>
      </div>

      <div
        class="input-container inline-timing-element"
        [class]="{ disabled: !formGroup.get('estateTimingEP').value }"
      >
        <span class="plot-timing-label">Estate Timing:</span>
        <div class="inline-element">
          <div>
            <label class="label form-label" for="plot-year"> Year </label>
            <input
              readonly
              id="plot-year"
              class="plot-timing form-control form-control-sm"
              type="number"
              [value]="estateStartDate"
            />
          </div>
          <div>
            <label class="label form-label" for="plot-step"> Step </label>
            <input
              readonly
              id="plot-step"
              class="plot-timing form-control form-control-sm"
              type="number"
              [value]="estateStep"
            />
          </div>
        </div>
      </div>

      <div class="title-group"><h2>Area</h2></div>
      <div class="input-container inline-element">
        <label class="label form-label" for="use-plot-area"
          >Use area as defined in Plot file</label
        >
        <div form-field class="form-check form-switch">
          <div class="messsage-container">
            <input
              id="use-plot-area"
              class="form-check-input switch-input"
              type="checkbox"
              role="switch"
              autocomplete="off"
              formControlName="hasAreaEP"
            />
            <fc-form-error-messages
              *ngIf="
                formGroup.get('hasAreaEP')?.errors &&
                (formGroup.touched || formGroup.dirty)
              "
              [formControlInstance]="formGroup.get('hasAreaEP')"
            >
            </fc-form-error-messages>
          </div>
        </div>
      </div>
      <div class="input-container" *ngIf="!formGroup.get('hasAreaEP').value">
        <label class="label form-label" for="area"
          >Area (mass outputs in t)
        </label>
        <div class="area-input">
          <div class="messsage-container">
            <div class="inline-element">
              <input
                id="area"
                class="form-control form-control-sm"
                type="number"
                formControlName="areaEP"
              />
              <span class="input-group-addon addon-inline input-source-observer"
                >Area [ha]</span
              >
            </div>

            <fc-form-error-messages
              *ngIf="
                formGroup.get('areaEP')?.errors &&
                (formGroup.touched || formGroup.dirty)
              "
              [formControlInstance]="formGroup.get('areaEP')"
            >
            </fc-form-error-messages>
          </div>
        </div>
      </div>

      <div class="title-group"><h2>Plot file</h2></div>
      <div class="input-container">
        <label class="label form-label visually-hidden" for="plot-select">
          Plot file
        </label>
        <div class="messsage-container">
          <select
            id="plot-select"
            type="text"
            class="form-control form-select form-select-sm"
            formControlName="plotId"
          >
            <option
              *ngFor="let p of selectedPlotFiles"
              [value]="p.plotId"
              [class]="{ 'invalid-text': !p.plotId }"
            >
              {{ p.fileName }} {{ !p.plotId ? " - broken link" : "" }}
            </option>
          </select>
          <fc-form-error-messages
            *ngIf="
              formGroup.get('plotId')?.errors &&
              (formGroup.touched || formGroup.dirty)
            "
            [formControlInstance]="formGroup.get('plotId')"
          >
          </fc-form-error-messages>
        </div>
      </div>

      <div class="input-container inline-element is-simulate-container">
        <label class="label form-label" for="is-simulate">Simulate</label>

        <div form-field class="form-check form-switch">
          <input
            id="is-simulate"
            class="form-check-input switch-input"
            type="checkbox"
            role="switch"
            autocomplete="off"
            formControlName="simulateEP"
          />
        </div>
      </div>

      <div class="form-button-group">
        <button
          type="button"
          (click)="closeForm(null)"
          class="btn btn-outline-primary"
        >
          Cancel
        </button>
        <button
          type="button"
          (click)="closeForm(formGroup.getRawValue())"
          class="btn btn-outline-primary"
          [disabled]="formGroup.pristine"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
