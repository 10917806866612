import { About2024Service } from "src/app/plot/about/services/about-2024.service";
import { Simulation2020Service } from "./simulation-2020.service";
import { Injectable, Injector } from "@angular/core";
import { Configuration2024Service } from "src/app/plot/configuration/services/configuration-2024.service";
import { Timing2024Service } from "src/app/plot/timing/services/timing-2024.service";
import { DataBuilder2024Service } from "src/app/plot/data-builder/services/data-builder-2024.service";
import { Site2024Service } from "src/app/plot/site/services/site-2024.service";
import { Soil2024Service } from "src/app/plot/soil/services/soil-2024.service";
import { Trees2024Service } from "src/app/plot/trees/services/trees-2024.service";
import { Crops2024Service } from "src/app/plot/crops/services/crops-2024.service";
import { InitialConditions2024Service } from "src/app/plot/initial-conditions/services/initial-conditions-2024.service";
import { Events2024Service } from "src/app/plot/events/services/events-2024.service";
import { OutputWindows2024Service } from "src/app/plot/output-windows/service/output-windows-2024.service";
import { Explorer2024Service } from "src/app/plot/explorer/services/explorer-2024.service";
import { PlotDigest2024Service } from "src/app/plot/plot-digest/services/plot-digest-2024.service";
import { PlotFiles2024Service } from "src/app/plot/plot-files/services/plot-files-2024.service";
import { Estate2024Service } from "src/app/plot/estate/services/estate-2024.service";
import { EventForms2024Service } from "src/app/shared/components/event-forms/event-forms-2024.service";
import { InitialConditionsComponent } from "src/app/plot/initial-conditions/initial-conditions.component";
import { EventsComponent } from "src/app/plot/events/events.component";
import { OutputWindowsComponent } from "src/app/plot/output-windows/output-windows.component";
import { ExplorerComponent } from "src/app/plot/explorer/explorer.component";
import { PlotDigestComponent } from "src/app/plot/plot-digest/plot-digest.component";
import { Flows } from "../models";
import { AboutComponent } from "src/app/plot/about/about.component";
import { ConfigurationComponent } from "src/app/plot/configuration/configuration.component";
import { TimingComponent } from "src/app/plot/timing/timing.component";
import { PlotFilesComponent } from "src/app/plot/plot-files/plot-files.component";
import { EstateComponent } from "src/app/plot/estate/estate.component";
import { DataBuilderComponent } from "src/app/plot/data-builder/data-builder.component";
import { SiteComponent } from "src/app/plot/site/site.component";
import { TreesComponent } from "src/app/plot/trees/trees.component";
import { CropsComponent } from "src/app/plot/crops/crops.component";
import { SoilComponent } from "src/app/plot/soil/soil.component";
import { LogComponent } from "src/app/plot/log/log.component";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { MessageService } from "src/app/shared/services/message.service";
import { PlotReferenceDataService } from "./plot-reference-data.service";
import { Log2024Service } from "src/app/plot/log/services/log2024.service";
import { Router } from "@angular/router";
import { DbService } from "src/app/shared/services/db.service";
import { FlyOverPanelService } from "src/app/shared/components/fly-over-panel/services/fly-over-panel.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { GoogleAnalyticsService } from "src/app/shared/services/google-analytics.service";
import { AppInsightsService } from "src/app/shared/services/app-insights.service";
import { AppService } from "src/app/services/app.service";

@Injectable({
  providedIn: "root",
})
export class Simulation2024Service extends Simulation2020Service {
  public readonly allFlows: Flows = {
    about: {
      label: "About",
      service: this.aboutService,
      component: AboutComponent,
      isInvalid: () => this.aboutService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    configuration: {
      label: "Configuration",
      service: this.configurationService,
      component: ConfigurationComponent,
      isInvalid: () => this.configurationService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    timing: {
      label: "Timing",
      service: this.timingService,
      component: TimingComponent,
      isInvalid: () => this.timingService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    plotFiles: {
      label: "Plot files",
      service: this.plotFilesService,
      component: PlotFilesComponent,
      isInvalid: () => this.plotFilesService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    estate: {
      label: "Plots in estate",
      service: this.estateService,
      component: EstateComponent,
      isInvalid: () => this.estateService.isInvalid(),
      isShown: true,
      type: "baseFlow",
    },
    dataBuilder: {
      label: "Location info",
      service: this.dataBuilderService,
      component: DataBuilderComponent,
      isInvalid: () => this.dataBuilderService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompA" },
        { flow: "configuration", field: "tPlot", value: "CompF" },
        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
    },
    site: {
      label: "Site",
      service: this.siteService,
      component: SiteComponent,
      isInvalid: () => this.siteService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
    },

    trees: {
      label: "Trees",
      service: this.treesService,
      component: TreesComponent,
      isInvalid: () => this.treesService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompF" },
        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
    },
    crops: {
      label: "Crops",
      service: this.cropsService,
      component: CropsComponent,
      isShown: false,
      isInvalid: () => this.cropsService.isInvalid(),
      type: "mainFlow",
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompA" },
        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
    },
    soil: {
      label: "Soil",
      service: this.soilService,
      component: SoilComponent,
      isInvalid: () => this.soilService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
      showConditions: [
        [
          { flow: "configuration", field: "tPlot", value: "CompA" },
          { flow: "configuration", field: "userSoilMnrl", value: true },
        ],
        [
          { flow: "configuration", field: "tPlot", value: "CompF" },
          { flow: "configuration", field: "userSoilMnrl", value: true },
        ],
        [
          { flow: "configuration", field: "tPlot", value: "CompM" },
          { flow: "configuration", field: "userSoilMnrl", value: true },
        ],
        { flow: "configuration", field: "tPlot", value: "SoilF" },
        { flow: "configuration", field: "tPlot", value: "SoilA" },
      ],
    },
    // economic: {
    //   label: "Economic",
    //   service: this.economicService,
    //   component: EconomicComponent,
    //   isShown: false,
    //   isInvalid: () => this.economicService.isInvalid(),
    //   type: "mainFlow",
    //   isInMoreOptions: true,
    //   showConditions: [
    //     { flow: "configuration", field: "userEcon", value: true },
    //   ],
    // },

    initialConditions: {
      label: "Initial conditions",
      service: this.initialConditionsService,
      component: InitialConditionsComponent,
      isInvalid: () => this.initialConditionsService.isInvalid(),
      isShown: false,
      type: "mainFlow",
      isInMoreOptions: true,
    },
    events: {
      label: "Events",
      service: this.eventsService,
      component: EventsComponent,
      isInvalid: () => this.eventsService.isInvalid(),
      showConditions: [
        { flow: "configuration", field: "tPlot", value: "CompA" },

        { flow: "configuration", field: "tPlot", value: "CompF" },

        { flow: "configuration", field: "tPlot", value: "CompM" },
      ],
      isShown: false,
      type: "mainFlow",
    },
    outputWindows: {
      label: "Output windows",
      service: this.outputWindowsService,
      component: OutputWindowsComponent,
      isInvalid: () => false,
      isShown: true,
      type: "mainFlow",
    },
    explorer: {
      label: "Explorer",
      service: this.explorerService,
      component: ExplorerComponent,
      isInvalid: () => false,
      isShown: true,
      type: "mainFlow",
      isInMoreOptions: true,
    },
    plotDigest: {
      label: "Plot digest",
      service: this.plotDigestService,
      component: PlotDigestComponent,
      isInvalid: () => this.plotDigestService.isInvalid(),
      isShown: true,
      type: "mainFlow",
    },
    log: {
      label: "Log",
      service: this.logService,
      component: LogComponent,
      isInvalid: () => false,
      isShown: true,
      type: "mainFlow",
    },
  };
  public version = "2024";

  constructor(
    public injector: Injector,
    public aboutService: About2024Service,
    public configurationService: Configuration2024Service,
    public timingService: Timing2024Service,
    public dataBuilderService: DataBuilder2024Service,
    public siteService: Site2024Service,
    public soilService: Soil2024Service,
    public treesService: Trees2024Service,
    public cropsService: Crops2024Service,
    public initialConditionsService: InitialConditions2024Service,
    public eventsService: Events2024Service,
    public outputWindowsService: OutputWindows2024Service,
    public explorerService: Explorer2024Service,
    public logService: Log2024Service,
    public plotDigestService: PlotDigest2024Service,
    public plotFilesService: PlotFiles2024Service,
    public estateService: Estate2024Service,
    public eventFormsService: EventForms2024Service,
    public router: Router,
    public dbService: DbService,
    public myPlotsService: MyPlotsService,
    public flyOverPanelService: FlyOverPanelService,
    public helperService: HelperService,
    public messageService: MessageService,
    public modalService: ModalService,
    public plotRefDataService: PlotReferenceDataService,
    public gaService: GoogleAnalyticsService,
    public appInsights: AppInsightsService,
    public appService: AppService
  ) {
    super(
      injector,
      aboutService,
      configurationService,
      timingService,
      dataBuilderService,
      siteService,
      soilService,
      treesService,
      cropsService,
      initialConditionsService,
      eventsService,
      outputWindowsService,
      explorerService,
      logService,
      plotDigestService,
      plotFilesService,
      estateService,
      eventFormsService,
      router,
      dbService,
      myPlotsService,
      flyOverPanelService,
      helperService,
      messageService,
      modalService,
      plotRefDataService,
      gaService,
      appInsights,
      appService
    );
  }

  public setUp() {
    this.eventFormsService.setSimulationService(this);
    Object.values(this.allFlows).forEach((f) => {
      f.service.setSimulationService(this);
    });
  }

  public getNewInstance() {
    const childInjector = Injector.create({
      providers: [
        {
          provide: About2024Service,
          useClass: About2024Service,
          deps: [MyPlotsService],
        },
        {
          provide: Configuration2024Service,
          useClass: Configuration2024Service,
          deps: [],
        },
        { provide: Timing2024Service, useClass: Timing2024Service, deps: [] },
        {
          provide: DataBuilder2024Service,
          useClass: DataBuilder2024Service,
          deps: [],
        },
        { provide: Site2024Service, useClass: Site2024Service, deps: [] },
        { provide: Soil2024Service, useClass: Soil2024Service, deps: [] },
        { provide: Trees2024Service, useClass: Trees2024Service, deps: [] },
        { provide: Crops2024Service, useClass: Crops2024Service, deps: [] },
        {
          provide: InitialConditions2024Service,
          useClass: InitialConditions2024Service,
          deps: [],
        },
        { provide: Events2024Service, useClass: Events2024Service, deps: [] },
        {
          provide: Explorer2024Service,
          useClass: Explorer2024Service,
          deps: [],
        },
        {
          provide: OutputWindows2024Service,
          useClass: OutputWindows2024Service,
          deps: [],
        },
        {
          provide: PlotDigest2024Service,
          useClass: PlotDigest2024Service,
          deps: [],
        },
        {
          provide: PlotFiles2024Service,
          useClass: PlotFiles2024Service,
          deps: [],
        },
        { provide: Estate2024Service, useClass: Estate2024Service, deps: [] },
        {
          provide: EventForms2024Service,
          useClass: EventForms2024Service,
          deps: [],
        },
        { provide: HelperService, useClass: HelperService, deps: [] },
        { provide: MessageService, useClass: MessageService, deps: [] },
        {
          provide: PlotReferenceDataService,
          useClass: PlotReferenceDataService,
          deps: [],
        },
      ],
      parent: this.injector,
    });
    const injector = childInjector.get(Injector);
    const aboutService = childInjector.get(About2024Service);
    const configurationService = childInjector.get(Configuration2024Service);
    const timingService = childInjector.get(Timing2024Service);
    const dataBuilderService = childInjector.get(DataBuilder2024Service);
    const siteService = childInjector.get(Site2024Service);
    const soilService = childInjector.get(Soil2024Service);
    const treesService = childInjector.get(Trees2024Service);
    const cropsService = childInjector.get(Crops2024Service);
    const initialConditionsService = childInjector.get(
      InitialConditions2024Service
    );
    const eventsService = childInjector.get(Events2024Service);
    const explorerService = childInjector.get(Explorer2024Service);
    const outputWindowsService = childInjector.get(OutputWindows2024Service);
    const plotDigestService = childInjector.get(PlotDigest2024Service);
    const plotFilesService = childInjector.get(PlotFiles2024Service);
    const estateService = childInjector.get(Estate2024Service);
    const eventFormsService = childInjector.get(EventForms2024Service);
    const logService = childInjector.get(Log2024Service);

    const newInstance = new Simulation2024Service(
      injector,
      aboutService,
      configurationService,
      timingService,
      dataBuilderService,
      siteService,
      soilService,
      treesService,
      cropsService,
      initialConditionsService,
      eventsService,
      outputWindowsService,
      explorerService,
      logService,
      plotDigestService,
      plotFilesService,
      estateService,
      eventFormsService,
      this.router,
      this.dbService,
      this.myPlotsService,
      this.flyOverPanelService,
      this.helperService,
      this.messageService,
      this.modalService,
      this.plotRefDataService,
      this.gaService,
      this.appInsights,
      this.appService
    );
    newInstance.setUp();
    return newInstance;
  }
}
