<div class="title-group">
  <div class="title">
    <h1>Time series:</h1>
    <span>{{ title }}</span>
  </div>

  <button
    aria-label="back button"
    type="button"
    class="btn btn-close close-button"
    (click)="close()"
  ></button>
</div>

<div class="main-container">
  <form [formGroup]="formGroup">
    <fieldset>
      <legend class="d-none">Time series controls</legend>

      <div class="accordion-header is-expanded">
        <h3>Timing</h3>
        <div class="button-group">
          <button type="button" class="btn" aria-label="help button">
            <i class="bi bi-question-circle"></i>
          </button>
        </div>
      </div>
      <div class="accordion-body">
        <ul>
          <li class="list-item">
            <label class="label col-form-label" for="ts-start-year">
              Start Year
            </label>
            <div>
              <input
                id="ts-start-year"
                class="form-control form-control-sm"
                [class]="{ 'is-invalid': formGroup.get('startYear').errors }"
                type="number"
                formControlName="startYear"
                (blur)="buildVisualisationData()"
                (keydown.enter)="buildVisualisationData()"
              />
              <fc-form-error-messages
                *ngIf="formGroup.get('startYear').errors"
                [formControlInstance]="formGroup.get('startYear')"
                label="Start year"
              ></fc-form-error-messages>
            </div>
          </li>
          <li class="list-item">
            <label class="label col-form-label" for="ts-v"> Years are </label>
            <select
              id="ts-date-type"
              type="text"
              class="form-control form-select form-select-sm"
              formControlName="dateType"
            >
              <option
                *ngFor="let option of dateTypeOptions"
                [value]="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </li>
          <li class="list-item">
            <label class="label col-form-label" for="ts-extrapolation">
              Extrapolation
            </label>

            <select
              id="ts-extrapolation"
              type="text"
              class="form-control form-select form-select-sm"
              formControlName="extrapolation"
            >
              <option
                *ngFor="let option of extrapolationOptions"
                [value]="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </li>
        </ul>
      </div>

      <div class="accordion-header is-expanded">
        <h3>Amount of Data</h3>
        <div class="button-group">
          <button type="button" class="btn" aria-label="help button">
            <i class="bi bi-question-circle"></i>
          </button>
        </div>
      </div>
      <div class="accordion-body">
        <ul>
          <li class="list-item">
            <label class="label col-form-label" for="ts-year-of-data">
              Years of data
            </label>
            <div>
              <input
                id="ts-year-of-data"
                [class]="{ 'is-invalid': formGroup.get('yearsOfData').errors }"
                class="form-control form-control-sm"
                type="number"
                formControlName="yearsOfData"
                (blur)="buildVisualisationData()"
                (keydown.enter)="buildVisualisationData()"
              />
              <fc-form-error-messages
                *ngIf="formGroup.get('yearsOfData').errors"
                [formControlInstance]="formGroup.get('yearsOfData')"
                label="Years of data"
              ></fc-form-error-messages>
            </div>
          </li>
          <li class="list-item">
            <label class="label col-form-label" for="ts-data-points-per-year">
              Data points per year
            </label>
            <div>
              <input
                id="ts-data-points-per-year"
                class="form-control form-control-sm"
                [class]="{
                  'is-invalid': formGroup.get('dataPointsPerYear').errors
                }"
                type="number"
                formControlName="dataPointsPerYear"
                (blur)="buildVisualisationData()"
                (keydown.enter)="buildVisualisationData()"
              />
              <fc-form-error-messages
                *ngIf="formGroup.get('dataPointsPerYear').errors"
                [formControlInstance]="formGroup.get('dataPointsPerYear')"
                label="Data points per year"
              ></fc-form-error-messages>
            </div>
          </li>
        </ul>
      </div>
    </fieldset>
  </form>
  <div class="main-content" [formGroup]="formGroup">
    <div class="form-controls">
      <div class="left-controls">
        <label class="control" for="ts-show-graph">
          Show graph
          <div form-field class="form-check form-switch">
            <input
              id="ts-show-graph"
              class="form-check-input switch-input"
              type="checkbox"
              role="switch"
              formControlName="showGraph"
            />
          </div>
        </label>
      </div>
      <div class="right-controls">
        <div class="control">
          Decimal places
          <button
            aria-label="add decimal places"
            id="ts-add-decimal-places"
            class="btn btn-sm decimal-button"
            (click)="addDecimalPlaces()"
            [disabled]="
              formGroup.get('decimalPlaces').value >= maxDecimalPlaces
            "
          >
            <i class="bi bi-plus-lg"></i>
          </button>
          <button
            aria-label="reduce decimal places"
            id="ts-reduce-decimal-places"
            class="btn btn-sm decimal-button"
            (click)="reduceDecimalPlaces()"
            [disabled]="
              formGroup.get('decimalPlaces').value <= minDecimalPlaces
            "
          >
            <i class="bi bi-dash-lg"></i>
          </button>
        </div>
        <label class="control" for="ts-multiplier">
          Multiplier
          <input
            readonly
            id="ts-multiplier"
            aria-label="multiplier"
            class="form-control multiplier-input"
            formControlName="multiplier"
          />
        </label>

        <button class="btn btn-primary" (click)="save()">Save and close</button>
      </div>
    </div>
    <fc-chart
      [chartOption]="chartOption"
      class="chart"
      *ngIf="formGroup.get('showGraph').value"
    ></fc-chart>
    <ngx-datatable
      #ngxDatatable
      class="bootstrap"
      [headerHeight]="50"
      [limit]="10"
      [columnMode]="ColumnMode.force"
      [footerHeight]="50"
      rowHeight="auto"
      [rows]="rows"
    >
      <ng-container *ngFor="let header of headers; let headerIndex = index">
        <ngx-datatable-column
          *ngIf="header.editable"
          [name]="header.name"
          [width]="65 + formGroup.get('decimalPlaces').value * 5"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template ngx-datatable-header-template>
            {{ header.label }}<br />
            {{ unit }}
          </ng-template>

          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <input
              [attr.aria-label]="
                'time series input for ' + header.name + 'row ' + rowIndex
              "
              class="time-series-data"
              [class]="{ 'is-invalid': editing.isInvalid }"
              type="number"
              [id]="header.name + rowIndex"
              (focus)="editCell(header.name, rowIndex)"
              (blur)="updateValue($event, header.name, headerIndex, rowIndex)"
              (keyup.enter)="
                updateValue($event, header.name, headerIndex, rowIndex)
              "
              (keyup)="validateValue($event)"
              [value]="value"
              autocomplete="off"
            />
            <p
              class="error-message"
              *ngIf="editing.isInvalid && editing[rowIndex + '-' + header.name]"
            >
              {{ errorMessage }}
            </p>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          *ngIf="!header.editable"
          [name]="header.name"
          [width]="65 + formGroup.get('decimalPlaces').value * 5"
          [resizeable]="false"
          [sortable]="false"
          [draggable]="false"
          [canAutoResize]="false"
        >
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value }}
          </ng-template>
        </ngx-datatable-column>
      </ng-container>
    </ngx-datatable>
  </div>
</div>
