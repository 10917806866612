<div class="form" [formGroup]="formGroup">
  <div class="form-content">
    <div class="title">
      <button
        aria-label="back button"
        type="button"
        class="btn close-button"
        (click)="closeForm(null)"
      >
        <i class="bi bi-arrow-left-circle-fill"> </i>
      </button>

      <div class="title-link-container">
        <h2>
          {{
            !showPlotFileList
              ? "Select Project and Colection"
              : "Select plot files"
          }}
        </h2>
        <a
          href="#"
          *ngIf="showPlotFileList"
          (click)="$event.preventDefault(); backToFolderSelect()"
          >Back to Project Collection select</a
        >
      </div>
    </div>

    <ng-container *ngIf="!showPlotFileList">
      <fc-project-collection-list
        id="folder-select"
        [data]="folders$ | async"
        [subitemKey]="'collectionInfo'"
        [parentIdKey]="'projectId'"
        [subitemSubmitTooltip]="'select this collection for Estate simulation'"
        (subitemClicked)="selectFolder($event)"
      ></fc-project-collection-list>

      <div class="form-button-group bottom-controls">
        <div></div>

        <div class="plot-selection-details">
          <span *ngIf="!projectCollectionFilter"
            >Select a collection first to organise your plot files</span
          >
          <span *ngIf="projectCollectionFilter"
            ><i class="bi bi-folder-fill"></i> Project:
            {{ projectCollectionFilter.project }}
            <i class="bi bi-folder-fill"></i>Collection:
            {{ projectCollectionFilter.collection }}</span
          >
        </div>

        <div>
          <button
            [disabled]="!projectCollectionFilter"
            class="btn btn-sm btn-primary"
            (click)="showPlotFileList = true"
          >
            Next
          </button>
        </div>
      </div>
    </ng-container>

    <form [formGroup]="formGroup" *ngIf="showPlotFileList">
      <fc-my-plots
        class="plot-list"
        [selectPlotFilesOnly]="true"
        [selectedPlotFiles]="getSelectedPlotFiles()"
        [forcePlotTypeFilter]="forcePlotTypeFilter"
        [forceProjectCollectionFilter]="projectCollectionFilter"
        (plotFilesSeleceted)="addPlotFiles($event)"
      ></fc-my-plots>

      <div
        class="plot-selection-container"
        [class]="{ expanded: isPlotSelectionContainerExpanded }"
      >
        <div class="title">
          <button
            aria-label="back button"
            type="button"
            class="btn close-button"
            (click)="isPlotSelectionContainerExpanded = false"
          >
            <i class="bi bi-arrow-left-circle-fill"> </i>
          </button>
          <h3>Selected plot files</h3>
        </div>
        <a
          *ngIf="formGroup.get('plotFiles').value.length > 0"
          href="#"
          class="link-primary"
          (click)="$event.preventDefault(); removeAllSelectPlotFiles()"
          >Remove all</a
        >
        <div
          class="no-item"
          *ngIf="formGroup.get('plotFiles').value.length < 1"
        >
          No plot file selected
        </div>
        <div class="list-group list-group-flush plot-file-list">
          <li
            *ngFor="let plotFile of formGroup.get('plotFiles').value"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <span class="plot-file-name">{{ plotFile.fileName }}</span>
            <a
              tooltip="remove from this selection"
              container="body"
              [adaptivePosition]="true"
              placement="left"
              (click)="removeSelectedPlot(plotFile.plotId)"
            >
              <i class="bi bi-x text-danger"></i>
            </a>
          </li>
        </div>
      </div>

      <div class="form-button-group bottom-controls">
        <div>
          <button class="btn btn-sm btn-primary" (click)="backToFolderSelect()">
            Back to Project Collection select
          </button>
        </div>

        <div class="plot-selection-details">
          <span
            >Number of selected plot files :
            {{ formGroup.get("plotFiles").value.length }}</span
          >

          <a
            *ngIf="formGroup.get('plotFiles').value.length > 0"
            href="#"
            class="link-primary"
            (click)="
              $event.preventDefault();
              isPlotSelectionContainerExpanded =
                !isPlotSelectionContainerExpanded
            "
            >{{ isPlotSelectionContainerExpanded ? "hide" : "view" }}</a
          >
        </div>

        <div>
          <button
            [disabled]="formGroup.get('plotFiles').value.length < 1"
            class="btn btn-sm btn-primary"
            type="submit"
            (click)="closeForm(formGroup.getRawValue())"
          >
            Finish selecting plot file(s)
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
