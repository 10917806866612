import { Component, OnInit, Input } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
} from "@angular/forms";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ModalOptions } from "ngx-bootstrap/modal";
import { Subject, interval, auditTime, startWith, mergeWith } from "rxjs";

import { take, takeUntil, debounce, filter } from "rxjs/operators";
import { EventFormsComponent } from "src/app/shared/components/event-forms/event-forms.component";

import { ModalService } from "src/app/shared/services/modal.service";
import { EventStyleClass } from "../../models";
import { CloneEventFormComponent } from "../clone-event-form/clone-event-form.component";
import TimeUtilities from "src/app/shared/utilities/time";
import { EventsService } from "src/app/shared/models";

@Component({
  selector: "fc-event-editor",
  templateUrl: "./event-editor.component.html",
  styleUrls: ["./event-editor.component.scss"],
})
export class EventEditorComponent implements OnInit {
  @Input()
  formGroupInstance: UntypedFormGroup;

  @Input()
  eventsService: EventsService;

  private readonly destroy$ = new Subject<void>();

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  public highlightSelectionMap = {};

  public events: AbstractControl[] = [];

  public selectedRows: UntypedFormGroup[] = [];

  public selectedBulkAction: "delete" | "clone" = "delete";

  public currentSort: { prop: string; dir: "desc" | "asc" }[] = [
    { prop: null, dir: "asc" },
  ];

  private readonly sortingFieldNames = ["dateEV", "nmEV"];
  private readonly columnFieldNames = ["date", "name"];

  protected isProccessingHighlightState: boolean = false;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.eventsService.selectedRegimeAndEventState$
      .pipe(
        takeUntil(this.destroy$),
        debounce(() => interval(500))
      )
      .subscribe((state) => {
        if (state && state.from == "regime") {
          this.selectRows(state.regimeInstances);
        }
      });

    this.formGroupInstance
      .get("eventQ")
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(this.formGroupInstance.get("eventQ")["controls"]),
        mergeWith(
          this.formGroupInstance.get("sortBy1").valueChanges,
          this.formGroupInstance.get("sortBy2").valueChanges,
          this.formGroupInstance.get("showOnlyHS").valueChanges,
          this.formGroupInstance.get("sortIx").valueChanges,
          this.formGroupInstance.get("sortUp").valueChanges
        ),
        auditTime(500)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.events = [...this.applyEventFilters()];
        }, 0);

        this.eventsService.validateEvents();
      });
    this.events = [...this.applyEventFilters()];
    this.eventsService.validateEvents();
  }

  protected applyEventFilters(): AbstractControl[] {
    const sortBySystem = this.formGroupInstance.get("sortBy1").value;
    const sortByWhetherSimulating = this.formGroupInstance.get("sortBy2").value;
    const onlyShowSimulatingEvents =
      this.formGroupInstance.get("showOnlyHS").value;

    const sortIndex = this.formGroupInstance.get("sortIx").value;
    const isAsc = this.formGroupInstance.get("sortUp").value;
    const formGroups =
      this.eventsService.getFilteredEventQByPlotType().controls;

    const isSortingEventDate = sortIndex == 0;
    // this.sortingFieldNames = ["dateEV", "nmEV"];
    // this.columnFieldNames= ["date", "name"];
    this.currentSort = [
      {
        prop: this.columnFieldNames[sortIndex],
        dir: isAsc ? "asc" : "desc",
      },
    ];

    let allEvents = formGroups;

    if (onlyShowSimulatingEvents) {
      allEvents = allEvents.filter((fg) => fg.get("onEV").value);
    }

    return allEvents.sort((a, b) => {
      let columnSort;

      if (isAsc) {
        columnSort = !isSortingEventDate
          ? a
              .get(this.sortingFieldNames[sortIndex])
              .value.localeCompare(
                b.get(this.sortingFieldNames[sortIndex]).value
              )
          : this.getEventDate(a) - this.getEventDate(b);
      } else {
        columnSort = !isSortingEventDate
          ? b
              .get(this.sortingFieldNames[sortIndex])
              .value.localeCompare(
                a.get(this.sortingFieldNames[sortIndex]).value
              )
          : this.getEventDate(b) - this.getEventDate(a);
      }

      const systemSort = b
        .get("tEvent")
        .value.localeCompare(a.get("tEvent").value);
      const simSort = b.get("onEV").value - a.get("onEV").value;

      if (!sortBySystem && !sortByWhetherSimulating) {
        return columnSort;
      } else if (sortByWhetherSimulating && !sortBySystem) {
        return simSort || columnSort;
      } else if (!sortByWhetherSimulating && sortBySystem) {
        return systemSort || columnSort;
      } else {
        return systemSort || simSort || columnSort;
      }
    });
  }

  public getFormArrayControls(): AbstractControl[] {
    return (this.formGroupInstance.get("eventQ") as UntypedFormArray)[
      "controls"
    ];
  }

  public getSartSimDate(years, days): string {
    const moment = TimeUtilities.getMoment();
    let additionalYears = Math.floor(+days / 365);
    let remainingDays = +days - additionalYears * 365;
    //show 365days instead of 0
    if (remainingDays == 0) {
      remainingDays = 365;
      additionalYears -= 1;
    }

    const monthName = moment("01-01-0000", "DD-MM-YYYY")
      .add(remainingDays - 1, "days")
      .format("MMM");
    return (
      +years + additionalYears + "." + remainingDays + " (" + monthName + ")"
    );
  }

  public getEventType(fb: UntypedFormGroup): string {
    const isSimulate = fb.get("onEV")?.value;
    const eventName = fb.get("tEV")?.value;
    if (!eventName) {
      return;
    }
    if (!isSimulate) {
      return "Event disabled";
    }
    return this.eventsService.simulationService.eventFormsService.getAvailableEventTypes()[
      eventName
    ]?.label;
  }

  public getEventClass(fb: UntypedFormGroup): EventStyleClass {
    const isSimulate = fb.get("onEV")?.value;
    const eventName = fb.get("tEV")?.value;
    const eventDate = fb.get("dateEV")?.value;
    const dateOriginEV = fb.get("dateOriginEV")?.value;
    const nYrsFromStEV = fb.get("nYrsFromStEV")?.value;
    const nDaysFromStEV = fb.get("nDaysFromStEV")?.value;

    const isBetweenSimuationTime = this.eventsService.isBetweenSimuationTime({
      eventDate,
      dateOriginEV,
      nYrsFromStEV,
      nDaysFromStEV,
    });
    if (!eventName || !this.eventsService.simulationService.eventFormsService) {
      return;
    }
    if (!isSimulate || !isBetweenSimuationTime) {
      return "disabled-event";
    }
    const eventType =
      this.eventsService.simulationService.eventFormsService.getAvailableEventTypes()[
        eventName
      ]?.type;
    return eventType == "tree"
      ? "forest-event"
      : eventType == "crop"
      ? "agricultural-event"
      : "mixed-event";
  }

  public async createEvent() {
    const initialState: ModalOptions = {
      initialState: {
        simulationService: this.eventsService.simulationService,
        startDate: this.eventsService.getStartDate(),
        simulationTimingType: this.eventsService.getSimulationTimingType(),
        speciesType: null,
        formData: null,
        isEventPage: true,
      },
    };

    this.modalService
      .openModal(EventFormsComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((resultFormGroup) => {
        if (resultFormGroup) {
          this.eventsService.addEvent(resultFormGroup);
          this.eventsService.validateEvents();
        }
      });
  }

  public cloneEvents(fgs: UntypedFormGroup[]) {
    const initialState: ModalOptions = {
      initialState: {},
    };

    this.modalService
      .openModal(CloneEventFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe(
        (result: { years: number; days: number; repeatTimes: number }) => {
          if (result) {
            this.eventsService.cloneEvent(fgs, result);
            // fgs.forEach((fg) => {
            //   this.eventsService.cloneEvent(fg.get("eventId").value, result);
            // });
          }

          this.deselectAll();
          this.eventsService.validateEvents();
        }
      );
  }

  public async editEvent(fb: UntypedFormGroup) {
    const initialState: ModalOptions = {
      initialState: {
        simulationService: this.eventsService.simulationService,
        startDate: this.eventsService.getStartDate(),
        simulationTimingType: this.eventsService.getSimulationTimingType(),
        speciesType: null,
        formData: fb.getRawValue(),
        isEventPage: true,
      },
    };

    this.modalService
      .openModal(EventFormsComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((resultFb) => {
        if (resultFb) {
          this.eventsService.editEvent(fb, resultFb);
          this.eventsService.validateEvents();
        }
      });
  }

  public deleteEvents(events: UntypedFormGroup[]) {
    events.forEach((e: UntypedFormGroup) => this.eventsService.deleteEvent(e));
    this.deselectAll();
    this.eventsService.validateEvents();
  }

  public bulkAction() {
    if (this.selectedBulkAction == "delete") {
      this.deleteEvents(this.selectedRows);
    } else if (this.selectedBulkAction == "clone") {
      this.cloneEvents(this.selectedRows);
    }
  }

  protected selectRows(regimeInstances): void {
    this.isProccessingHighlightState = true;

    this.highlightSelectionMap = {};
    this.deselectAll();
    regimeInstances.forEach((ri) => {
      this.highlightSelectionMap[ri] = true;
    });
    //force the view to update
    this.events = [...this.events];
    this.isProccessingHighlightState = false;
  }

  public onSelect({ selected }) {
    if (this.isProccessingHighlightState) {
      return;
    }
    this.highlightSelectionMap = {};
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
    this.eventsService.setSelectedRegimeAndEventState({
      from: "event",
      regimeInstances: this.selectedRows.map(
        (r) => r.get("regimeInstance").value
      ),
    });
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.selectedRows = [];
  }

  //workaround to access this contenxt
  public getRowClass = (fg) => {
    // const eventType = fg.get("tEV").value;
    // const eventTypeObject =
    //   this.eventsService.simulationService.eventFormsService.getEventTypesByPlotType()[eventType];
    //any events don't belong to plot type will be filtered out,
    //e.g. agricultural and mixed events will be filtered out if plot type is forest system

    // if (!eventTypeObject) {
    //   return { hidden: true };
    // }

    const regimeInstance = fg.get("regimeInstance").value;
    const eventDate = fg.get("dateEV").value;
    const highlighted = this.highlightSelectionMap[regimeInstance];

    const hasNoSortingApplied =
      !this.formGroupInstance.get("sortBy1").value &&
      !this.formGroupInstance.get("sortBy2").value &&
      !this.formGroupInstance.get("showOnlyHS").value &&
      this.formGroupInstance.get("sortIx").value == 0;

    let isLastEvent = false;
    if (hasNoSortingApplied) {
      const filteredEvents = this.events
        .filter((e) => e.get("regimeInstance").value == regimeInstance)
        .sort((a, b) => this.getEventDate(a) - this.getEventDate(b));
      const lastEvent =
        filteredEvents[
          !this.formGroupInstance.get("sortUp").value
            ? 0
            : filteredEvents.length - 1
        ];

      isLastEvent = eventDate == lastEvent.get("dateEV").value;
    }

    ///debug
    // if (fg.invalid) {
    //   Object.entries(fg.controls).forEach((c) => {
    //     if (c[1]["invalid"]) {
    //     }
    //   });
    // }

    return {
      invalid: fg.invalid || fg.get("problemEV")?.value,
      ...(highlighted && { highlighted: true }),
      ...(isLastEvent && { "event-divider": true }),
    };
  };

  public onSort(event) {
    const sort = event.sorts[0];
    this.currentSort = [sort];
    this.formGroupInstance
      .get("sortIx")
      .setValue(this.columnFieldNames.findIndex((c) => c == sort.prop));
    this.formGroupInstance.get("sortUp").setValue(sort.dir == "asc");

    this.applyEventFilters();
  }

  public shouldDisableClone() {
    if (this.selectedRows.length < 1) {
      return true;
    }
    const firstValue = this.selectedRows[0].get("regimeInstance").value;
    const shouldDisable = this.selectedRows.some(
      (r) => r.get("regimeInstance").value !== firstValue
    );
    if (shouldDisable && this.selectedBulkAction == "clone") {
      this.selectedBulkAction = "delete";
    }

    return shouldDisable;
  }

  public getEventDate(fg) {
    return this.eventsService.getEventDate(fg);
  }

  public getDateDisplayFormat(date) {
    const moment = TimeUtilities.getMoment();
    try {
      const momentObject = moment(date);

      const formattedDate =
        momentObject.format("D MMM") + " " + momentObject.year();

      return formattedDate;
    } catch (err) {
      return "Invalid Date";
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
