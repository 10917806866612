import {
  AfterViewInit,
  Component,
  ComponentRef,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PlotsInEstateComponent } from "src/app/plot/estate/components/plots-in-estate/plots-in-estate.component";
import { EventEditorComponent } from "src/app/plot/events/components/event-editor/event-editor.component";
import { RegimeEditorComponent } from "src/app/plot/events/components/regime-editor/regime-editor.component";
import { ExplorerContainerComponent } from "src/app/plot/explorer/components/explorer-container/explorer-container.component";
import { FormItemElement, ItemInput } from "src/app/plot/models";
import { OutputWindowsContainerComponent } from "src/app/plot/output-windows/components/output-container/output-windows-container.component";
import { PlotDigestContainerComponent } from "src/app/plot/plot-digest/components/plot-digest-container/plot-digest-container.component";
import { PoltFileListComponent } from "src/app/plot/plot-files/components/polt-file-list/polt-file-list.component";
import { TyfCategoryTableComponent } from "src/app/plot/trees/components/tyf-category-table/tyf-category-table.component";
import { v4 as uuidv4 } from "uuid";
import { HashIdDirective } from "../../directives/hash-id.directive";
import { FlyOverPanelService } from "../fly-over-panel/services/fly-over-panel.service";
import { FolderSelectComponent } from "../folder-select/folder-select.component";
import { ButtonComponent } from "../form-elements/button/button.component";
import { FormItemTableComponent } from "../form-item-table/form-item-table.component";
import { FormulaDisplayComponent } from "../formula-display/formula-display.component";
import { LegendComponent } from "../legend/legend.component";
import { LocationMetadataComponent } from "../location-metadata/location-metadata.component";
import { MapComponent } from "../map/map.component";
import { SpeciesListComponent } from "../species-list/species-list.component";
import { SpeciesSelectorComponent } from "../species-selector/species-selector.component";
import { TableComponent } from "../table/table.component";
import { LogListComponent } from "src/app/plot/log/components/log-list/log-list.component";
import { RotationTableComponent } from "src/app/rmt/rmt-management/components/rotation-table/rotation-table.component";
import { SimulationResultComponent } from "src/app/rmt/rmt-results/components/simulation-result/simulation-result.component";

@Component({
  selector: "[fc-form-item-element]",
  templateUrl: "./form-item-element.component.html",
  styleUrls: ["./form-item-element.component.scss"],
  host: { class: "form-item-element" },
})
export class FormItemElementComponent implements OnInit, AfterViewInit {
  private readonly destroy$ = new Subject<void>();

  @Input() formGroupInstance: UntypedFormGroup;
  @Input() service: any;
  @Input() simulationService: any;

  @Input() item: FormItemElement;

  @Input() @HostBinding("class.modifiers") public isModifiersCol = false;

  private readonly componentMap = {
    MapComponent: MapComponent,
    SpeciesListComponent: SpeciesListComponent,
    SpeciesSelectorComponent: SpeciesSelectorComponent,
    LocationMetadataComponent: LocationMetadataComponent,
    ButtonComponent: ButtonComponent,
    FolderSelectComponent: FolderSelectComponent,
    TableComponent: TableComponent,
    FormItemTableComponent: FormItemTableComponent,
    FormulaDisplayComponent: FormulaDisplayComponent,
    RegimeEditorComponent: RegimeEditorComponent,
    EventEditorComponent: EventEditorComponent,
    TyfCategoryTableComponent: TyfCategoryTableComponent,
    PlotDigestContainerComponent: PlotDigestContainerComponent,
    OutputWindowsContainerComponent: OutputWindowsContainerComponent,
    ExplorerContainerComponent: ExplorerContainerComponent,
    PlotFileListComponent: PoltFileListComponent,
    LegendComponent: LegendComponent,
    PlotInEstateComponent: PlotsInEstateComponent,
    LogListComponent: LogListComponent,
    RotationTableComponent: RotationTableComponent,
    SimulationResultComponent: SimulationResultComponent,
  };

  private readonly dynamicComponentMap = {
    DataVisualiserComponent: import(
      "../data-visualiser/data-visualiser.component"
    ).then(({ DataVisualiserComponent }) => DataVisualiserComponent),
    DecayRateComponent: import("../decay-rate/decay-rate.component").then(
      ({ DecayRateComponent }) => DecayRateComponent
    ),
    SpeciesEventsComponent: import(
      "../species-events/species-events.component"
    ).then(({ SpeciesEventsComponent }) => SpeciesEventsComponent),
  };

  @ViewChildren(HashIdDirective)
  public componentHosts: QueryList<HashIdDirective>;

  public id: string = "_" + uuidv4();

  public standardInputs = ["number", "text"];

  constructor(private flyoverPanelService: FlyOverPanelService) {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    if (this.item.inputs) {
      setTimeout(() => {
        this.item.inputs.forEach((input, i) => {
          if (input.element == "component") {
            const id = this.id + "-component-" + i;
            this.loadFormComponent(input, id);
          }
        });
      }, 0);
    }
  }

  private async loadFormComponent(input, id): Promise<void> {
    const host = this.componentHosts.find((h) => h.hashId == id).vcRef;
    host.clear();

    const childComp: ComponentRef<any> = host.createComponent(
      this.componentMap[input.component]
    );

    // //child component inputs
    input.isComponentInvalid = () =>
      childComp.instance.isInvalid ? childComp.instance.isInvalid() : false;

    if (input.componentInputs) {
      input.componentInputs.forEach(async (ci) => {
        if (ci.formKey) {
          const formControl = this.formGroupInstance.get(ci.formKey);

          childComp.instance[ci.inputKey] = ci.isObservable
            ? formControl
            : formControl.value;
        } else if (ci.variable) {
          childComp.instance[ci.inputKey] = ci.bind
            ? this.service[ci.variable].bind(ci.bind)
            : this.service[ci.variable];
        } else if (ci.value) {
          childComp.instance[ci.inputKey] = ci.value;
        } else if (ci.method) {
          childComp.instance[ci.inputKey] = await this.service[ci.method](
            ci.payload || undefined
          );
        }
      });
    }

    if (input.componentOutputs) {
      input.componentOutputs.forEach((co) => {
        childComp.instance[co.outputKey]
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (val) => {
            await this.service[co.method](val);
          });
      });
    }
  }

  public async linkToComponent(input): Promise<void> {
    this.simulationService.flyOverPanelService.linkToComponent(
      input,
      this.formGroupInstance,
      this.service
    );
    //manually trigger validation
    this.formGroupInstance.markAsTouched();
  }

  public shouldShowDigestActions(input) {
    const isDigestSim =
      this.simulationService?.selectedPlotFormat$.getValue() == "pld";
    //remove this after pld get input info implemented
    if (!isDigestSim) {
      return false;
    }
    //events in trees and crops will not be included
    //the only way to detect is thurough flyover panel service as 'input' doesn't include parents
    const isExpanded = this.flyoverPanelService.isExpanded$.getValue();
    if (
      isExpanded &&
      this.flyoverPanelService.currentLoadedComponent ==
        "SpeciesEventsComponent"
    ) {
      return false;
    }

    const canThisPageAddedToDigest =
      this.simulationService.canThisPageAddedToDigest(
        this.service?.pageId,
        this.service
      );
    if (
      (input.component || !isDigestSim || !input.programmingName,
      !canThisPageAddedToDigest)
    ) {
      return false;
    }

    return this.service.getFormModel()[input.programmingName]?.isDigest;
  }

  public hasLabelMethod(labelMethod): string {
    if (typeof this.service[labelMethod] === "function") {
      return this.service[labelMethod]();
    }

    return;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
