import { Injectable } from "@angular/core";
import { RegimeEvent, RotationEvent, RotationForest } from "../../models";
import { RotationTreePlantingService } from "./rotation-tree-planting.service";
import { RotationChopperRollerService } from "./rotation-chopper-roller.service";
import { RotationFertilizationService } from "./rotation-fertilization.service";
import { RotationTreeLossService } from "./rotation-tree-loss.service";
import { RotationPestAndDiseaseService } from "./rotation-pest-and-disease.service";
import { RotationTermiteChangeService } from "./rotation-termite-change.service";
import { RotationForestFireService } from "./rotation-forest-fire.service";
import { RotationWeedControlService } from "./rotation-weed-control.service";
import { RotationTreeRegenerationService } from "./rotation-tree-regeneration.service";
import { RotationHarvestingThinService } from "./rotation-harvesting-thin.service";
import { RotationCoppiceHarvestingThinService } from "./rotation-coppice-harvesting-thin.service";
import { RotationLimbPruningService } from "./rotation-limb-pruning.service";
import { RotationClearingThinService } from "./rotation-clearing-thin.service";
import { AbstractControl, FormGroup } from "@angular/forms";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";

export type RotationEventService =
  | RotationTreePlantingService
  | RotationChopperRollerService
  | RotationFertilizationService
  | RotationTreeLossService
  | RotationPestAndDiseaseService
  | RotationTermiteChangeService
  | RotationForestFireService
  | RotationWeedControlService
  | RotationTreeRegenerationService
  | RotationHarvestingThinService
  | RotationCoppiceHarvestingThinService
  | RotationLimbPruningService
  | RotationClearingThinService;

@Injectable({
  providedIn: "root",
})
export class EventService {
  private readonly serviceMap = {
    RotationTreePlanting: RotationTreePlantingService,
    RotationChopperRoller: RotationChopperRollerService,
    RotationFertilization: RotationFertilizationService,
    RotationTreeLoss: RotationTreeLossService,
    RotationPestAndDisease: RotationPestAndDiseaseService,
    RotationTermiteChange: RotationTermiteChangeService,
    RotationForestFire: RotationForestFireService,
    RotationWeedControl: RotationWeedControlService,
    RotationTreeRegeneration: RotationTreeRegenerationService,
    RotationHarvestingThin: RotationHarvestingThinService,
    RotationCoppiceHarvestingThin: RotationCoppiceHarvestingThinService,
    RotationLimbPruning: RotationLimbPruningService,
    RotationClearingThin: RotationClearingThinService,
  };

  constructor() {}

  private isTreePlantingEvent(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.StockingLevelGroups?.length &&
      regimeEvent?.StockingLevelGroups[0].FullCAMFFrc !== undefined &&
      regimeEvent?.StockingLevelGroups[0].FullCAMPlnF !== undefined &&
      regimeEvent.Group == "Tree planting"
    );
  }

  private isChopperRoller(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMChop !== undefined &&
      regimeEvent.Group == "Slash Management"
    );
  }

  private isFertilization(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FertilizationLevelGroups !== undefined &&
      regimeEvent.Group == "Fertilisation"
    );
  }

  private isTreeLoss(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMThin !== undefined &&
      regimeEvent.Name.toLocaleLowerCase()?.indexOf("pest and disease") == -1 &&
      regimeEvent.Group == "Forest Disturbance"
    );
  }

  private isPestAndDisease(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMThin !== undefined &&
      regimeEvent.Group == "Forest Disturbance"
    );
  }

  private isTermiteChange(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.TermiteChangeStateGroups !== undefined &&
      regimeEvent.Group == "Forest Disturbance"
    );
  }

  private isForestFire(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMFirF !== undefined
      // && regimeEvent.Group == "Forest fire"
    );
  }

  private isWeedControl(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.WeedControlIntensityGroups !== undefined &&
      regimeEvent.Group == "Weed control"
    );
  }

  private isTreeRegeneration(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMFFrc !== undefined &&
      regimeEvent?.FullCAMPlnF !== undefined &&
      regimeEvent.Group == "Tree planting"
    );
  }

  private isHarvestingThin(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMThin !== undefined &&
      regimeEvent.Group == "Harvesting"
    );
  }

  private isCoppiceHarvestingThin(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMThin !== undefined &&
      regimeEvent?.FullCAMTmtF !== undefined &&
      regimeEvent.Group == "Tree Thinning"
    );
  }

  private isLimbPruning(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMThin !== undefined &&
      regimeEvent.Group == "Limb pruning"
    );
  }

  private isClearingThin(regimeEvent: RegimeEvent): boolean {
    return (
      regimeEvent?.FullCAMThin !== undefined &&
      regimeEvent.Group == "Harvesting"
    );
  }

  public getEventClassTypeByAttributes(
    rotationEvent: RotationEvent,
    rotationFormGroup: AbstractControl<RotationForest>,
    managementService: RmtManagementService
  ) {
    const regimeEvent = rotationEvent.RegimeEventReference.Value;

    if (rotationEvent.$type && this.serviceMap[rotationEvent.$type]) {
      return new this.serviceMap[rotationEvent.$type](
        rotationEvent,
        rotationFormGroup,
        managementService
      );
    }

    // if (this.isTreePlantingEvent(regimeEvent)) {
    //   return new this.serviceMap["TreePlanting"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isChopperRoller(regimeEvent)) {
    //   return new this.serviceMap["ChopperRoller"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isFertilization(regimeEvent)) {
    //   return new this.serviceMap["Fertilization"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isTreeLoss(regimeEvent)) {
    //   return new this.serviceMap["TreeLoss"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isPestAndDisease(regimeEvent)) {
    //   return new this.serviceMap["PestAndDisease"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isTermiteChange(regimeEvent)) {
    //   return new this.serviceMap["TermiteChange"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isForestFire(regimeEvent)) {
    //   return new this.serviceMap["ForestFire"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isWeedControl(regimeEvent)) {
    //   return new this.serviceMap["WeedControl"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isTreeRegeneration(regimeEvent)) {
    //   return new this.serviceMap["TreeRegeneration"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isHarvestingThin(regimeEvent)) {
    //   return new this.serviceMap["HarvestingThin"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isCoppiceHarvestingThin(regimeEvent)) {
    //   return new this.serviceMap["CoppiceHarvestingThin"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isLimbPruning(regimeEvent)) {
    //   return new this.serviceMap["LimbPruning"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    // if (this.isClearingThin(regimeEvent)) {
    //   return new this.serviceMap["ClearingThin"](
    //     rotationEvent,
    //     rotationFormGroup,
    //     managementService
    //   );
    // }

    return null;
  }
}
