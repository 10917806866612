import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { BaseModalFormComponent } from "../base-modal-form/base-modal-form.component";
import packageJson from "../../../../../package.json";

@Component({
  selector: "fc-about-fullcam",
  templateUrl: "./about-fullcam.component.html",
  styleUrls: ["./about-fullcam.component.scss"],
})
export class AboutFullcamComponent
  extends BaseModalFormComponent
  implements OnInit
{
  public version: string;
  public uiVersion: string;
  public rmtUiVersion: string;

  private simulationEngineVersionMap = {
    2016: "",
    2020: "v6.20.03.0827.02",
    2024: "v8.23.01.0614.02",
    rmt: "",
  };

  constructor(private appService: AppService) {
    super();
  }
  public getSimulationEngineVersion() {
    return this.simulationEngineVersionMap[this.version] || "";
  }

  ngOnInit(): void {
    this.version = this.appService.getVersion();
    this.uiVersion = packageJson.version;
    this.rmtUiVersion = packageJson["rmt-version"];
  }
}
