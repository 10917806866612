import { Component, OnInit } from "@angular/core";
import { AlertMessage, MessageService } from "../../services/message.service";
import { Observable } from "rxjs";

@Component({
  selector: "fc-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
})
export class MessagesComponent implements OnInit {
  public alerts: AlertMessage[] = [];
  public isFullScreenLoading$: Observable<boolean>;
  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.alerts = this.messageService.alerts;
    this.isFullScreenLoading$ = this.messageService.isFullScreenLoading$;
  }
}
