import { Injectable } from "@angular/core";
import { ObjectReference } from "../models";
import { Subject, takeUntil } from "rxjs";
import { RegimeEvent, RotationEvent, RotationForest } from "../../models";
import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import TimeUtilities from "src/app/shared/utilities/time";
import Utilities from "src/app/shared/utilities/utils";
import { RmtManagementService } from "../../rmt-management/services/rmt-management.service";
import XMLUtilities from "../../utils/xml-utiles";

@Injectable({
  providedIn: "root",
})
export class BaseRotationEventService {
  private destroySubject$ = new Subject<void>();
  private _isValid = true;
  private _isClearing = false;
  private _id: string = Utilities.uuid();
  private _day: number;
  private _year: number;
  private _offset: number;
  private _offsetSubsequentOnDateChange: boolean;
  private _regimeEventReference: ObjectReference<RegimeEvent, number>;
  public _rotationEvent: RotationEvent;
  // Observables to act like PropertyChanged in C#
  private propertyChanged = new Subject<string>();

  public _type = "";

  public managementFormGroup: FormGroup;

  public eventFormGroup: UntypedFormGroup = new UntypedFormGroup({
    eventDate: new UntypedFormControl(null, [Validators.required]),
    isOffset: new UntypedFormControl(false, [Validators.required]),
  });

  public isExpanded = false;
  public additionalFormControls = [];

  constructor(
    public rotationEvent: RotationEvent,
    public rotationFormGroup: AbstractControl<RotationForest>,
    public managementService: RmtManagementService
  ) {
    this._regimeEventReference = rotationEvent.RegimeEventReference;
    this._offsetSubsequentOnDateChange = false;
    this._rotationEvent = rotationEvent;
    this.managementFormGroup = this.managementService.getFormGroup();

    this.year = this.rotationEvent.Year;
    this.day = this.rotationEvent.Day;
    this.offset = this.rotationEvent.Offset;

    // this.calculateEventDate();
    //detect changes
    this.applyWatchers();
  }

  // Getter and setter with notification

  get id(): string {
    return this._id;
  }

  get day(): number {
    return this._day;
  }

  set day(value: number) {
    if (this._day !== value) {
      this._day = value;
      this.propertyChanged.next("Day");
    }
  }

  get year(): number {
    return this._year;
  }

  set year(value: number) {
    if (this._year !== value) {
      this._year = value;
      this.propertyChanged.next("Year");
    }
  }

  get offset(): number {
    return this._offset;
  }

  set offset(value: number) {
    if (this._offset !== value) {
      this._offset = value;
      this.calculateEventDate();
    }
  }

  get offsetSubsequentOnDateChange(): boolean {
    return this._offsetSubsequentOnDateChange;
  }

  set offsetSubsequentOnDateChange(value: boolean) {
    if (this._offsetSubsequentOnDateChange !== value) {
      this._offsetSubsequentOnDateChange = value;
      // this.propertyChanged.next("OffsetSubsequentOnDateChange");
    }
  }

  get regimeEventReference(): ObjectReference<RegimeEvent, number> {
    return this._regimeEventReference;
  }

  set regimeEventReference(value: ObjectReference<RegimeEvent, number>) {
    if (this._regimeEventReference !== value) {
      this._regimeEventReference = value;
      // this.propertyChanged.next("RegimeEventReference");
    }
  }

  // Utility method
  public toString(): string {
    if (!this._regimeEventReference || !this._regimeEventReference.Value) {
      return "";
    }
    return `${this._year}, ${this._day}, ${this._regimeEventReference.Value.Name}`;
  }

  public getEventDate() {
    return this.eventFormGroup.get("eventDate").value;
  }

  public getIntensity() {
    return null;
  }

  get isClearing() {
    return this._isClearing;
  }
  set isClearing(isClearing) {
    this._isClearing = isClearing;
  }

  get isValid() {
    return this._isValid;
  }

  set isValid(isValid) {
    this._isValid = isValid;
  }

  private calculateEventDate(): void {
    const moment = TimeUtilities.getMoment();
    const eventDate = moment(
      this.rotationFormGroup.get("RotationStartDate").value
    );

    eventDate.add(this.offset, "days");

    if (eventDate) {
      this.eventFormGroup.get("eventDate").setValue(eventDate.toDate());
    }
  }

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public setExpand(isExpanded) {
    this.isExpanded = isExpanded;
  }

  //watchers
  private applyWatchers(): void {
    this.rotationFormGroup
      .get("RotationStartDate")
      .valueChanges.pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.calculateEventDate();
      });
  }
  public readXmlObject(userRotationEvent) {
    const convertedUserRotationEvent =
      XMLUtilities.convertNilToNullValue(userRotationEvent);
    this.rotationEvent = convertedUserRotationEvent;
    this._regimeEventReference =
      convertedUserRotationEvent.RegimeEventReference;

    console.log(this.regimeEventReference.Value);
    return convertedUserRotationEvent;
  }

  public writeXmlObject() {
    let eventTemplate = {
      $: { "xsi:type": this._type },

      RegimeEventReferenceValue: {},
      RegimeEventReference: {},
      Year: this.year,
      Day: this.day,
      OffsetSubsequentOnDateChange: this.eventFormGroup.get("isOffset").value,
      Offset: this.offset,
    };

    eventTemplate.RegimeEventReference = [
      {
        Id: [this.regimeEventReference.Id],
        Name: [this.regimeEventReference.Name],
      },
    ];

    //value without $type
    const {
      $type,
      "xsi:type": _,
      ...referenceValue
    } = this.regimeEventReference.Value;
    eventTemplate.RegimeEventReferenceValue = {
      $: { "xsi:type": this._type.replace("Rotation", "Regime") },
      ...referenceValue,
    };

    const convertedJson = XMLUtilities.convertJsonNullValues(eventTemplate);

    console.log(convertedJson);
    return XMLUtilities.convertJsonNullValues(eventTemplate);
  }

  ngOnDestroy(): void {
    console.log("rotation Service destroyed");
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}

// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeTreePlanting))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeChopperRoller))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeFertilization))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeTreeLoss))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimePestAndDisease))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeTermiteChange))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeForestFire))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeWeedControl))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeTreeRegeneration))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeHarvestingThin))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeCoppiceHarvestingThin))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeLimbPruning))]
// [System.Runtime.Serialization.KnownTypeAttribute(typeof(NCAT.WebServices.NCATService.RegimeClearingThin))]
