<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <h1>Validation report</h1>
    <form>
      <ul
        class="list-group list-group-flush"
        *ngIf="plotFiles.length; else allValid"
      >
        <li class="list-group-item" *ngFor="let plotFile of plotFiles">
          {{ plotFile.fileName }} - {{ plotFile.validationMessage }}
        </li>
      </ul>
    </form>
  </div>
</div>

<ng-template #allValid> All plots have been verified as valid. </ng-template>
