import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { UserManagementComponent } from "./user-management/user-management.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { RoleAccessComponent } from "./role-access/role-access.component";

@NgModule({
  declarations: [
    UserManagementComponent,
    UserProfileComponent,
    RoleAccessComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class UserManagementModule {}
