import { EventEmitter, Injectable } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { take } from "rxjs/operators";
import { lastValueFrom } from "rxjs";
import { ConfirmModalComponent } from "../components/confirm-modal/confirm-modal.component";
import { ConfirmWithCommentModalComponent } from "../components/confirm-with-comment-modal/confirm-with-comment-modal.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modalConfig = {
    backdrop: false,
    ignoreBackdropClick: true,
  };

  constructor(private bsModalService: BsModalService) {}

  // public openModal(component: any, initialState): EventEmitter<any> {
  //   const modalRef: BsModalRef = this.bsModalService.show(component, {
  //     ...initialState,
  //   });

  //   modalRef.content.action.pipe(take(1)).subscribe(() => {
  //     modalRef.hide();
  //   });

  //   return modalRef.content.action;

  //   //cutom component must have action as @output to get the result.
  //   // if (modalRef.content?.action) {
  //   //   modalRef.content.action.pipe(take(1)).subscribe((r) => {
  //   //     result = r;
  //   //     modalRef.hide();
  //   //   });

  //   //   return result;
  //   // }

  //   // const closeEvent = await modalRef.onHidden.pipe(take(1)).toPromise();

  //   // return result;
  // }

  public openModal(component: any, initialState): EventEmitter<any> {
    const modalRef: BsModalRef = this.bsModalService.show(component, {
      ...initialState,
    });

    const modalCloseEmitter = new EventEmitter<{ type: string; data: any }>();

    // Subscribe to action from the modal content
    const actionSubscription = modalRef.content?.action
      ?.pipe(take(1))
      .subscribe((result) => {
        modalCloseEmitter.emit(result);
        modalRef.hide();
      });

    // Subscribe to the onHidden event to handle modal close/dismiss
    const hiddenSubscription = modalRef.onHidden.pipe(take(1)).subscribe(() => {
      // Check if action was not already emitted
      if (!actionSubscription?.closed) {
        modalCloseEmitter.emit(null);
      }
    });

    // Cleanup subscriptions when modal is closed or destroyed
    modalCloseEmitter.subscribe({
      complete: () => {
        actionSubscription?.unsubscribe();
        hiddenSubscription.unsubscribe();
      },
    });

    return modalCloseEmitter;
  }

  public async openConfirmModal(
    message?: string,
    noActions?: boolean,
    focusId?: string
  ) {
    const initialState: ModalOptions = {
      initialState: {
        message: message || "Are you sure?",
        noActions,
      },
    };
    const modalRef: BsModalRef = this.bsModalService.show(
      ConfirmModalComponent,
      {
        ...initialState,
      }
    );

    let result = null;
    if (focusId) {
      (document.querySelector("#" + focusId) as HTMLElement).focus();
    }

    modalRef.content.action.pipe(take(1)).subscribe((r) => {
      result = r;
      modalRef.hide();
    });

    const closeEvent = await lastValueFrom(modalRef.onHidden.pipe(take(1)));
    return result;
  }

  public async openConfirmModalWithComment(message?: string) {
    const initialState: ModalOptions = {
      initialState: {
        message: message || "Are you sure?",
      },
    };
    const modalRef: BsModalRef = this.bsModalService.show(
      ConfirmWithCommentModalComponent,
      {
        ...initialState,
      }
    );

    let result = null;

    modalRef.content.action.pipe(take(1)).subscribe((r) => {
      result = r;
      modalRef.hide();
    });

    const closeEvent = await lastValueFrom(modalRef.onHidden.pipe(take(1)));
    return result;
  }
}
