import { Directive, Input, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[hashId]",
})
export class HashIdDirective {
  @Input() hashId: string;

  constructor(public vcRef: ViewContainerRef) {}
}
