import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "fc-scenario-view",
  templateUrl: "./scenario-view.component.html",
  styleUrls: ["./scenario-view.component.scss"],
})
export class ScenarioViewComponent implements OnInit {
  @Input()
  public formGroupInstance: FormGroup;

  @Input()
  public selectedScenarioName: string;

  @Input()
  public viewType: "Scenario" | "Category" | "Input";

  @Input()
  public digestScenarios: FormArray;

  @Output()
  public scenarioItemClicked = new EventEmitter<{
    name: string;
    scenario: FormControl;
    itemLabel: "root" | string;
    path: string;
  }>();

  @Output()
  public scenarioLabelClicked = new EventEmitter<string>();

  @Output()
  public scenarioDeleted = new EventEmitter<FormGroup>();

  @Output()
  public scenarioCloned = new EventEmitter<FormGroup>();

  @Output()
  public editScenarioClicked = new EventEmitter<FormGroup>();

  public goupByInputList = [];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewType) {
      const currentValue: any = changes.viewType.currentValue;
      if (currentValue == "Input") {
        this.goupByInputList = [];

        this.digestScenarios.controls.forEach((scenario: FormGroup) => {
          const controls = scenario.value.controls;
          controls.forEach((c) => {
            if (!this.goupByInputList.includes(c.itemLabel)) {
              this.goupByInputList.push(c.itemLabel);
            }
          });
        });
      }
    }
  }

  public setScenarioItem(
    name,
    scenario: FormControl,
    itemLabel: "root" | string,
    path: string
  ) {
    this.scenarioItemClicked.emit({
      name,
      scenario,
      itemLabel,
      path,
    });
  }

  public checkValidation(item, scenario) {
    if (scenario.valid) {
      return false;
    }
    if (item.controls) {
      return item.controls.find((c) => c.control.invalid) !== undefined;
    } else if (item.subItems) {
      let isInvalid = false;
      item.subItems.forEach((i) => {
        isInvalid = this.checkValidation(i, scenario);
      });
      return isInvalid;
    }
  }

  public setScenarioInput(itemLabel) {
    this.scenarioLabelClicked.emit(itemLabel);
  }

  public deleteScenario(fg) {
    this.scenarioDeleted.emit(fg);
  }

  public cloneScenario(fg) {
    this.scenarioCloned.emit(fg);
  }
  public editScenario(fg) {
    this.editScenarioClicked.emit(fg);
  }
}
