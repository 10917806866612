export interface RoleAccessRequest {
  roleId: number;
  StudiesResearchValue?: boolean;
  accuValue?: boolean;
  dcceewValue?: boolean;
  otherValue?: boolean;
  reason: string;
  canAccessFullCam2016?: boolean;
  canAccessFullCam2020?: boolean;
  canAccessFullCam2024?: boolean;
  canAccessRMT?: boolean;
  requestVersion: string;
}

export type ProductType = "rmt" | "fullCAM2016" | "fullCAM2020" | "fullCAM2024";

export type UserRoleType =
  | "accuValue"
  | "dcceewValue"
  | "studiesResearchValue"
  | "otherValue";

export type ProductRequestStatus = "Pending" | "Grant" | null;

export interface RoleAccessModel {
  userGuidId: string;
  roleId: number;
  isInternalUser: boolean;
  studiesResearchValue: boolean;
  emissionReductionalue: boolean;
  canAccessFullCam2016: boolean;
  canAccessFullCam2020: boolean;
  canAccessFullCam2024: boolean;
  canAccessRMT: boolean;
  dcceewValue: boolean;
  otherValue: boolean;
  otherReason: string;
}

export interface RoleAccessResponse {
  userGuidId: string;
  roleId: number;
  isInternalUser: boolean;
  canAccessFullCam2016: boolean;
  canAccessFullCam2020: boolean;
  canAccessFullCam2024: boolean;
  canAccessRMT: boolean;
  canAccessUserModule: boolean;
  canAccessStatistics: boolean;
}

export interface UserRole {
  userGuid: string;
  roleId: number;
  isInternalUser: boolean;
  canAccessFullCam2016: boolean;
  canAccessFullCam2020: boolean;
  canAccessFullCam2024: boolean;
  canAccessRMT: boolean;
  canAccessUserModule: boolean;
  canAccessStatistics: boolean;
  otherReason: string;
  defaultSimulationQuota: number;
  quotaDays: number;
  roleName: string;
  plotSimulationCount: number;
  pldSimulationCount: number;
  estSimulationCount: number;
  privacyAccepted: boolean;
  status: string;
  fullCam2024Status: string;
  rmtStatus?: string;
}
