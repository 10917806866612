<ng-template #noSpecies>
  <div class="no-species">
    <button type="button" class="btn btn-sm btn-primary" (click)="addSpecies()">
      Add tree species
    </button>
  </div>
</ng-template>

<form (keydown.enter)="$event.preventDefault()" class="row g-0" *ngIf="layout">
  <div class="drawer-container col-xl-3">
    <fc-drawer>
      <fieldset
        fc-modifiers
        [data]="modifiers"
        [formGroupInstance]="species.controls[selectedSpeciesIndex]"
        [simulationService]="simulationService"
        class="modifiers-col"
      ></fieldset>
    </fc-drawer>
  </div>

  <fieldset class="main-form-col col-12 col-xl-9">
    <legend class="visually-hidden">main form</legend>
    <div class="parent-group">
      <h1 class="page-title">
        <div>
          <span>{{ getBasePageTitle() }}</span> > {{ layout.label }}
        </div>
        <a
          aria-label="help link button"
          type="button"
          *ngIf="service.pageHelpLink"
          class="btn"
          [href]="service.baseHelpPageUrl + '/' + service.pageHelpLink"
          [tooltip]="'View ' + layout.label + ' page help'"
          target="_blank"
        >
          <i class="bi bi-question-circle"></i>
        </a>
      </h1>
      <ng-container
        *ngIf="species.controls[selectedSpeciesIndex]; else noSpecies"
      >
        <div
          fc-form-group-element
          class="sub-groups"
          [formGroupInstance]="species.controls[selectedSpeciesIndex]"
          [service]="service"
          [simulationService]="simulationService"
          [group]="group"
          *ngFor="let group of layout.groups"
        ></div>
      </ng-container>
    </div>
    <fc-flow-bottom-navigation
      [canNavigatePreviousFlow$]="canNavigatePreviousFlow$"
      [canNavigateNextFlow$]="canNavigateNextFlow$"
      (nextFlowClicked)="nextFlowClicked.emit()"
      (previousFlowClicked)="previousFlowClicked.emit()"
    >
    </fc-flow-bottom-navigation>
  </fieldset>
</form>
