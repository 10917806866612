import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FolderList } from "src/app/my-plots/models";
import { Observable } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "fc-plot-files-select",
  templateUrl: "./plot-files-select.component.html",
  styleUrls: ["./plot-files-select.component.scss"],
})
export class PlotFilesSelectComponent implements OnInit {
  @Output() action = new EventEmitter();

  public formGroup: FormGroup = new FormGroup({
    plotFiles: new FormControl([], []),
  });

  public isPlotSelectionContainerExpanded: boolean = false;

  public forcePlotTypeFilter: "all" | "plo" | "pld" | "est" = null;

  public folders$: Observable<FolderList[]>;

  public projectCollectionFilter: {
    collection: string;
    collectionId: string;
    project: string;
    projectId: string;
  } = null;
  public showPlotFileList = false;

  public selectedPlotFiles = [];

  public simulationService = null;

  constructor(
    private appService: AppService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.simulationService = this.appService.getSimulationService();
    this.folders$ = this.simulationService.aboutService.getFolders();

    if (this.projectCollectionFilter && this.projectCollectionFilter.project) {
      setTimeout(() => {
        this.showPlotFileList = true;

        if (this.selectedPlotFiles.length) {
          this.formGroup.get("plotFiles").setValue(this.selectedPlotFiles);
        }
      }, 200);
    }
  }

  public addPlotFiles(plotFiles) {
    const plotFilesControl = this.formGroup.get("plotFiles");
    plotFilesControl.setValue([...plotFilesControl.value, ...plotFiles]);
  }
  public getSelectedPlotFiles() {
    return this.formGroup.get("plotFiles").value;
  }

  public removeSelectedPlot(id) {
    const plotFilesControl = this.formGroup.get("plotFiles");
    const deletingIndex = plotFilesControl.value.findIndex(
      (p) => p.plotId == id
    );
    const allPlotFiles = plotFilesControl.value;
    allPlotFiles.splice(deletingIndex, 1);

    plotFilesControl.setValue([...allPlotFiles]);
  }

  public removeAllSelectPlotFiles() {
    this.formGroup.get("plotFiles").setValue([]);
  }

  public selectFolder(collection) {
    this.projectCollectionFilter = {
      collection: collection.name,
      collectionId: collection.collectionId,
      project: collection.parent.name,
      projectId: collection.parent.projectId,
    };
  }

  public async backToFolderSelect() {
    //confirm before reselecting
    const message = `By changing the 'collection', all selected files will be cleared.`;
    const confirm = await this.modalService.openConfirmModal(message);

    if (confirm) {
      this.showPlotFileList = false;
      this.formGroup.get("plotFiles").setValue([]);
    }
  }

  public closeForm(formData): void {
    if (!formData) {
      this.action.emit(null);
    } else {
      this.action.emit({
        plotFiles: formData.plotFiles,
        projectCollectionFilter: this.projectCollectionFilter,
      });
    }
  }
}
