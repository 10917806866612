import { Injectable } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { CalendarSystemT, FieldWatcher } from "src/app/shared/models";
import TimeUtilities from "src/app/shared/utilities/time";

import { FormGroupElement, FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";

const otherTimingGroups: FormGroupElement[] = [
  {
    label: "Output Steps",
    isShown: true,
    isAccordion: true,
    isExpanded: true,
    helpLink: "27_Output%20Steps.htm",
    items: [
      {
        id: "stepsPerOutYTZ",
        label: "Simulation steps: Record the state of the simulation every",
        inputs: [
          {
            element: "input",
            type: "number",
            placeholder: "Step, e.g. 1",
            programmingName: "stepsPerOutYTZ",
          },
        ],
      },

      {
        id: "firstOutStepYTZ",
        label: "First simulation step recorded for output is step",
        inputs: [
          {
            element: "input",
            type: "number",
            placeholder: "Step, e.g. 1",
            programmingName: "firstOutStepYTZ",
          },
        ],
      },
      {
        id: "outputFreqDTZ",
        label: "Record the state of the simulation",
        inputs: [
          {
            element: "select",
            selectOptions: [
              { label: "Daily", value: "Daily" },
              { label: "Weekly", value: "Weekly" },
              { label: "Monthly", value: "Monthly" },
              { label: "Yearly", value: "Yearly" },
            ],

            programmingName: "outputFreqDTZ",
          },
        ],
      },
    ],
  },
];

const startEndSimulationGroup: FormGroupElement[] = [
  {
    label: "Start and End of Simulation",
    isShown: true,
    helpLink: "26_Start%20and%20End%20of%20Simulation.htm",
    items: [
      {
        id: "stYrYTZ",
        label: "Start date (end of)",
        preventLabelElement: true,
        inputs: [
          {
            element: "input",
            type: "number",
            floatingLabel: "Year",
            placeholder: "Year",
            programmingName: "stYrYTZ",
            customErrorMessages: {
              greater: "Start date can not be greater than end date.",
            },
          },
          {
            id: "stStepInStYrYTZ",
            element: "input",
            type: "number",
            floatingLabel: "Month",
            placeholder: "Month",
            programmingName: "stStepInStYrYTZ",
            customErrorMessages: {
              greater: "Start date can not be greater than end date.",
            },
          },
        ],
      },
      {
        id: "enYrYTZ",
        label: "End date (end of)",
        preventLabelElement: true,
        inputs: [
          {
            element: "input",
            type: "number",
            floatingLabel: "Year",
            placeholder: "Year",
            programmingName: "enYrYTZ",
            customErrorMessages: {
              less: "End date can not be less than start date.",
            },
          },
          {
            id: "enStepInEnYrYTZ",
            element: "input",
            type: "number",
            floatingLabel: "Month",
            placeholder: "Month",
            programmingName: "enStepInEnYrYTZ",
            customErrorMessages: {
              less: "End date can not be less than start date.",
            },
          },
        ],
      },
      {
        id: "stDateDTZ",
        label: "Start Date",
        inputs: [
          {
            customErrorMessages: {
              greater: "Start date can not be greater than end date.",
            },
            element: "input",
            type: "datePicker",
            placeholder: "DD/MM/YYYY",
            programmingName: "stDateDTZ",
          },
        ],
      },
      {
        id: "enDateDTZ",
        label: "End Date",
        inputs: [
          {
            customErrorMessages: {
              less: "End date can not be less than start date.",
            },
            element: "input",
            type: "datePicker",
            placeholder: "DD/MM/YYYY",
            programmingName: "enDateDTZ",
          },
        ],
      },
    ],
  },
];
@Injectable({
  providedIn: "root",
})
export class Timing2020Service extends BasePlotFormService {
  public readonly pageId = "timing";
  public readonly pageHelpLink = "199_Timing.htm";
  public readonly maxStepValueMap = {
    Yearly: 1,
    Monthly: 12,
    Weekly: 52,
    Daily: 365,
    Other: 8760,
  };

  readonly categoryIds = [3];
  public layout: FormLayout = {
    label: "Timing",
    groups: [
      {
        label: "Simulation timing",
        isShown: true,
        isRoot: true,
        helpLink: "267_Simulation%20Timing.htm",
        items: [
          {
            id: "dailyTimingTZ",
            label: "Do you want to use Calendar dates instead?",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "dailyTimingTZ",
              },
            ],
          },
          {
            id: "tStepsYTZ",
            label: "Simulation steps",
            helpLink: "5_Simulation%20Steps.htm",
            inputs: [
              {
                element: "select",
                selectOptions: [
                  { label: "Daily", value: "Daily", index: 3 },
                  { label: "Weekly", value: "Weekly", index: 2 },
                  { label: "Monthly", value: "Monthly", index: 1 },
                  { label: "Yearly", value: "Yearly", index: 0 },
                  { label: "Custom steps", value: "Other", index: 4 },
                ],
                programmingName: "tStepsYTZ",
              },
            ],
          },
          {
            id: "stepsPerYrYTZ",
            label: " Simulation steps per year",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "stepsPerYrYTZ",
              },
            ],
          },
          {
            groups: startEndSimulationGroup,
          },
        ],
      },
      {
        label: "Other Timing Options",
        isRoot: true,
        isShown: true,
        isAccordion: true,
        isExpanded: false,
        items: [
          {
            groups: otherTimingGroups,
          },
        ],
      },
    ],
  };

  public formModel = {
    dailyTimingTZ: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 5,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Document uses daily timing (as opposed to yearly timing)",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 60,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 2105,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    useDaysPerStepDTZ: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 5,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "Days per step (as opposed to steps per day)",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      columnWidth: 60,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 2111,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    daysPerStepDTZ: {
      high: 365,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 5,
      defaultValue: 1,
      validators: [],
      validatorConfig: [],
      label: "Length of simulation step in days per step",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2108,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    stepsPerDayDTZ: {
      high: 24,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 5,
      defaultValue: 1,
      validators: [],
      validatorConfig: [],
      label: "Length of simulation step in steps per day",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2112,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    outputFreqDTZ: {
      high: 5,
      low: 0,
      isOneMore: false,
      dataType: 1,
      helpId: 5,
      defaultValue: "Daily",
      validators: [],
      validatorConfig: [],
      label: "Output frequency",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2109,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    stDateDTZ: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 5,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Simulation start date (starts at beginning of this day)",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2106,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    enDateDTZ: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 5,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Simulation end date (ends at end of this day)",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2107,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    stepsPerOutDTZ: {
      high: 2100200300,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 5,
      defaultValue: 1,
      validators: [],
      validatorConfig: [],
      label: "Simulation steps per output",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2110,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    firstOutStepDTZ: {
      high: 2100200300,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 27,
      defaultValue: 1,
      validators: [],
      validatorConfig: [],
      label: "First output step",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 2113,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 175,
    },
    tStepsYTZ: {
      high: 4,
      low: 1,
      isOneMore: false,
      //dataType: 1,
      dataType: 5,
      helpId: 5,
      defaultValue: "Monthly",
      validators: [],
      validatorConfig: [],
      label: "Simulation steps per year",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1009,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    stepsPerYrYTZ: {
      high: 8760,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 5,
      defaultValue: 110,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 1,
        },
        {
          functionName: "max",
          input: 8760,
        },
      ],
      label: "Simulation steps per year (number typed by user)",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: false,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1010,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    stYrYTZ: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 26,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: -999,
        },
        {
          functionName: "max",
          input: 9999,
        },
      ],
      label: "Simulation start year",
      unit: "yr",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 593,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    stStepInStYrYTZ: {
      high: 8760,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 26,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],

      label: "Simulation start step within start `year`",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 594,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    enYrYTZ: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 26,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: -999,
        },
        {
          functionName: "max",
          input: 9999,
        },
      ],
      label: "Simulation end year",
      unit: "yr",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 595,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    enStepInEnYrYTZ: {
      high: 8760,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 26,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Simulation end step within end year",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 596,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    stepsPerOutYTZ: {
      high: 2100200300,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 27,
      defaultValue: 1,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 1,
        },
        {
          functionName: "max",
          input: 2100200300,
        },
      ],
      label: "Simulation steps per output",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 592,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
    firstOutStepYTZ: {
      high: 2100200300,
      low: 1,
      isOneMore: false,
      dataType: 1,
      helpId: 27,
      defaultValue: 1,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 1,
        },
        {
          functionName: "max",
          input: 2100200300,
        },
      ],
      label: "First output step",
      unit: "",
      categoryName: "Timing",
      categoryLabel: "Timing",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1106,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 176,
    },
  };

  public readonly fieldWatcher: FieldWatcher = {
    tStepsYTZ: (
      newValue: any,
      { formGroup, formModel, layout, fieldChangedEmitter }
    ) => {
      formModel["stStepInStYrYTZ"].isShown = newValue !== "Yearly";
      formModel["enStepInEnYrYTZ"].isShown = newValue !== "Yearly";
      formModel["stepsPerYrYTZ"].isShown = newValue == "Other";

      let placeholder = "";
      formGroup.get("stStepInStYrYTZ").clearValidators();
      formGroup.get("enStepInEnYrYTZ").clearValidators();

      if (newValue == "Monthly") {
        placeholder = "Month";
      } else if (newValue == "Weekly") {
        placeholder = "Week";
      } else if (newValue == "Daily") {
        placeholder = "Day";
      } else if (newValue == "Other") {
        placeholder = "Step";
      }

      let beginningFieldInput = this.getLayoutItem(layout.groups, "stYrYTZ");

      let endFieldInput = this.getLayoutItem(layout.groups, "enYrYTZ");
      beginningFieldInput.inputs[1].placeholder = placeholder;
      beginningFieldInput.inputs[1].floatingLabel = placeholder;
      endFieldInput.inputs[1].placeholder = placeholder;
      endFieldInput.inputs[1].floatingLabel = placeholder;

      formGroup
        .get("stepsPerYrYTZ")
        .setValidators([
          ...(newValue == "Other"
            ? [
                Validators.required,
                Validators.max(this.maxStepValueMap[newValue]),
              ]
            : []),
          Validators.min(1),
        ]);

      formGroup.get("stepsPerYrYTZ").updateValueAndValidity();
      this.updateStepDatesValidation(formGroup, newValue);
    },
    stepsPerOutYTZ: (
      newValue: any,
      { formGroup, formModel, layout, fieldChangedEmitter }
    ) => {
      const firstOutputStepControl = formGroup.get("firstOutStepYTZ");
      formModel["firstOutStepYTZ"].isShown = newValue > 1;
      if (newValue > 1) {
        firstOutputStepControl.setValidators([
          Validators.required,
          Validators.min(1),
          Validators.min(formModel["firstOutStepYTZ"].low),
          Validators.max(newValue),
        ]);
      } else {
        firstOutputStepControl.clearValidators();
      }

      firstOutputStepControl.updateValueAndValidity();
    },
    dailyTimingTZ: (
      newValue: any,
      { formGroup, formModel, layout, fieldChangedEmitter, simulationService }
    ) => {
      formModel["tStepsYTZ"].isShown = !newValue;
      formModel["stYrYTZ"].isShown = !newValue;
      formModel["enYrYTZ"].isShown = !newValue;
      formModel["stepsPerOutYTZ"].isShown = !newValue;
      formModel["firstOutStepYTZ"].isShown =
        !newValue && formGroup.get("stepsPerOutYTZ").value > 1;

      formModel["stDateDTZ"].isShown = newValue;
      formModel["enDateDTZ"].isShown = newValue;
      formModel["outputFreqDTZ"].isShown = newValue;

      if (newValue) {
        //reset values
        formGroup
          .get("tStepsYTZ")
          .setValue(formModel["tStepsYTZ"].defaultValue);
        formGroup.get("stYrYTZ").setValue(formModel["stYrYTZ"].defaultValue);
        formGroup.get("enYrYTZ").setValue(formModel["enYrYTZ"].defaultValue);
        formGroup
          .get("stStepInStYrYTZ")
          .setValue(formModel["stStepInStYrYTZ"].defaultValue);
        formGroup
          .get("enStepInEnYrYTZ")
          .setValue(formModel["enStepInEnYrYTZ"].defaultValue);
        formGroup
          .get("stepsPerOutYTZ")
          .setValue(formModel["stepsPerOutYTZ"].defaultValue);
        formGroup
          .get("firstOutStepYTZ")
          .setValue(formModel["firstOutStepYTZ"].defaultValue);

        formGroup
          .get("stepsPerYrYTZ")
          .setValue(formModel["stepsPerYrYTZ"].defaultValue);

        this.addDefaultValidatorsInBulk(
          ["stDateDTZ", "enDateDTZ"],
          this.getFormGroup(),
          formModel
        );

        this.removeDefaultValidatorsInBulk(
          [
            "stYrYTZ",
            "enYrYTZ",
            "stStepInStYrYTZ",
            "enStepInEnYrYTZ",
            "firstOutStepYTZ",
            "stepsPerYrYTZ",
          ],
          this.getFormGroup(),
          formModel
        );
      } else {
        //reset values
        formGroup
          .get("stDateDTZ")
          .setValue(formModel["stDateDTZ"].defaultValue);
        formGroup
          .get("enDateDTZ")
          .setValue(formModel["enDateDTZ"].defaultValue);
        formGroup
          .get("outputFreqDTZ")
          .setValue(formModel["outputFreqDTZ"].defaultValue);

        this.removeDefaultValidatorsInBulk(
          ["stDateDTZ", "enDateDTZ"],
          this.getFormGroup(),
          formModel
        );

        this.addDefaultValidatorsInBulk(
          [
            "stYrYTZ",
            "enYrYTZ",
            "stStepInStYrYTZ",
            "enStepInEnYrYTZ",
            "firstOutStepYTZ",
            "stepsPerYrYTZ",
          ],
          this.getFormGroup(),
          formModel
        );
      }
    },
    stepsPerYrYTZ: (newValue: any, { formModel, formGroup }) => {
      const stepType = formGroup.get("tStepsYTZ").value;
      const isCustomStep = stepType == "Other";
      const isOne = newValue == 1;
      if (isCustomStep) {
        formModel["stStepInStYrYTZ"].isShown = !isOne;
        formModel["enStepInEnYrYTZ"].isShown = !isOne;
      }
      this.updateStepDatesValidation(formGroup, stepType);
    },

    stYrYTZ: (newValue: any, { formGroup }) => {
      this.validateStepDates(formGroup);
    },
    enYrYTZ: (newValue: any, { formGroup }) => {
      this.validateStepDates(formGroup);
    },
    stStepInStYrYTZ: (newValue: any, { formGroup }) => {
      this.validateStepDates(formGroup);
    },
    enStepInEnYrYTZ: (newValue: any, { formGroup }) => {
      this.validateStepDates(formGroup);
    },
    stDateDTZ: (newValue: any, { formGroup }) => {
      this.validateClendarDates(formGroup);
    },
    enDateDTZ: (newValue: any, { formGroup }) => {
      this.validateClendarDates(formGroup);
    },
  };

  public updateStepDatesValidation(formGroup, stepType): void {
    //not to apply anything if dailyTimingTZ is set to use calendar (true)
    if (formGroup.get("dailyTimingTZ").value) {
      return;
    }

    const shouldRequrie =
      stepType !== "Yearly" &&
      !(stepType == "Other" && formGroup.get("stepsPerYrYTZ").value == 1);
    const validators = [
      Validators.required,
      Validators.min(1),
      Validators.max(
        stepType !== "Other"
          ? this.maxStepValueMap[stepType]
          : formGroup.get("stepsPerYrYTZ").value
      ),
    ];

    formGroup
      .get("stStepInStYrYTZ")
      .setValidators(shouldRequrie ? validators : null);

    formGroup
      .get("enStepInEnYrYTZ")
      .setValidators(shouldRequrie ? validators : null);

    formGroup.get("stStepInStYrYTZ").updateValueAndValidity();
    formGroup.get("enStepInEnYrYTZ").updateValueAndValidity();
  }

  validateClendarDates(formGroup: UntypedFormGroup): void {
    const startControl = formGroup.get("stDateDTZ");
    const endControl = formGroup.get("enDateDTZ");
    const isValidDate =
      startControl.value instanceof Date &&
      !isNaN(startControl.value.valueOf()) &&
      endControl.value instanceof Date &&
      !isNaN(endControl.value.valueOf());

    if (isValidDate) {
      const newStartValue = new Date(startControl.value.setHours(0, 0, 0, 0));
      const newEndValue = new Date(endControl.value.setHours(0, 0, 0, 0));

      const isInvalid = newStartValue > newEndValue;
      if (isInvalid) {
        this.addError(startControl, "greater");
        this.addError(endControl, "less");
      } else {
        this.removeError(startControl, "greater");
        this.removeError(endControl, "less");
      }
    }
  }

  validateStepDates(formGroup: UntypedFormGroup): void {
    const startControl = formGroup.get("stYrYTZ");
    const endControl = formGroup.get("enYrYTZ");
    const startSecondControl = this.formGroup.get("stStepInStYrYTZ");
    const endSecondControl = this.formGroup.get("enStepInEnYrYTZ");
    const isYearlyStep = this.formGroup.get("tStepsYTZ").value == "Yearly";
    if (
      !(+startControl.value > -1 && startControl.value) ||
      !(+endControl.value > -1 && endControl.value)
    ) {
      return;
    }

    const isInvalid = +startControl.value > +endControl.value;

    if (isInvalid) {
      this.addError(startControl, "greater");
      this.addError(endControl, "less");
      this.removeError(startSecondControl, "greater");
      this.removeError(endSecondControl, "less");
    } else if (
      startControl.value == endControl.value &&
      +startSecondControl.value > +endSecondControl.value &&
      !isYearlyStep
    ) {
      this.removeError(startControl, "greater");
      this.removeError(endControl, "less");
      this.addError(startSecondControl, "greater");
      this.addError(endSecondControl, "less");
    } else {
      this.removeError(startControl, "greater");
      this.removeError(endControl, "less");
      this.removeError(startSecondControl, "greater");
      this.removeError(endSecondControl, "less");
    }
  }

  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    return { xPath: "//Timing", id: null };
  }

  public readXmlObject(): void {
    const moment = TimeUtilities.getMoment();
    this.getFormGroup();
    const template = this.simulationService.getPlotFileJson()["Timing"][0];
    const timing = template.$;

    const enDateTM = template?.enDateTM && template?.enDateTM[0]?._;
    const stDateTM = template?.stDateTM && template?.stDateTM[0]?._;

    const timingWithCalendarDates = {
      ...timing,
      ...(enDateTM && { enDateDTZ: moment(enDateTM, "YYYYMMDD").toDate() }),
      ...(stDateTM && { stDateDTZ: moment(stDateTM, "YYYYMMDD").toDate() }),
    };

    this.applyXmlValuesToFormGroup(timingWithCalendarDates, this.formGroup);

    //manually trigger watchers to reapply validations.
    this.manuallyTriggerWatchingFields(this.fieldWatcher, {
      formGroup: this.formGroup,
      formModel: this.formModel,
      layout: this.layout,
      simulationService: this.simulationService,
    });
  }

  public getSimulationTimingType(): "step" | "calendar" {
    return this.getFormGroup().get("dailyTimingTZ").value ? "calendar" : "step";
  }

  public getStartTime(): number {
    const moment = TimeUtilities.getMoment();
    const date = this.getDate("start");
    return TimeUtilities.convertUnixToTimeInstant(moment(date).unix());
  }

  public getStartDate(): Date {
    return this.getDate("start");
  }

  public getEndTime(): number {
    const moment = TimeUtilities.getMoment();
    const date = this.getDate("end");
    return TimeUtilities.convertUnixToTimeInstant(moment(date).unix());
  }

  public getEndDate(): Date {
    return this.getDate("end");
  }

  getDate(d: "start" | "end"): Date {
    const moment = TimeUtilities.getMoment();
    const formGroup = this.getFormGroup();
    const isCalendarDate = formGroup.get("dailyTimingTZ").value;

    const dateField = d == "start" ? "stDateDTZ" : "enDateDTZ";

    if (isCalendarDate) {
      const dateValue = formGroup.get(dateField).value;

      return dateValue;
      //to do confirm ticks
      //  //  return TimeUtilities.convertUnixToTimeInstant(startDate.getTime());
    } else {
      const simulationType = formGroup.get("tStepsYTZ").value;

      const yearField = d == "start" ? "stYrYTZ" : "enYrYTZ";
      const stepField = d == "start" ? "stStepInStYrYTZ" : "enStepInEnYrYTZ";

      const yearValue = +formGroup.get(yearField).value;
      const stepValue = +formGroup.get(stepField).value;
      let numberOfDaysPerYear = 365;
      let momentDateObject = this.convertStepToMomentDate(yearValue);
      switch (simulationType) {
        case "Yearly":
          if (d == "end") {
            return momentDateObject.endOf("year").startOf("month").toDate();
          } else {
            return momentDateObject.toDate();
          }

        case "Monthly":
          const numberOfMonthsPerYear = 12;
          const monthValue = numberOfDaysPerYear / numberOfMonthsPerYear;

          if (d == "end") {
            return momentDateObject
              .add(Math.ceil(monthValue * stepValue) - 1, "d")
              .toDate();
          } else {
            return momentDateObject
              .add(
                Math.ceil(monthValue * (stepValue - 1)) -
                  (stepValue == 1 ? 0 : 1),
                "d"
              )
              .toDate();
          }

        case "Weekly":
          const numberOfWeeksPerYear = 52;
          const weeklyValue = numberOfDaysPerYear / numberOfWeeksPerYear;
          if (d == "end") {
            return momentDateObject
              .add(Math.ceil(weeklyValue * stepValue) - 1, "d")
              .toDate();
          } else {
            return momentDateObject
              .add(
                Math.ceil(weeklyValue * (stepValue - 1)) -
                  (stepValue == 1 ? 0 : 1),
                "d"
              )
              .toDate();
          }

        case "Daily":
          // double x;
          // flo lastStepLenInDaysDTM = modf(s_cast<flo>((enTimeTM - stTimeTM.AddDays(1)).GetDaysTimeFrac() + 1)
          //     * stepLenInDaysInvTM, &x);
          // //  - N steps
          // nSimStepsTM = s_cast<int>(x);
          // if (lastStepLenInDaysDTM > 0.0)
          //     ++nSimStepsTM;
          //let days;
          let days = stepValue;
          const endDateSpecialIncrementPattern = [
            3, 4, 5, 9, 10, 11, 15, 16, 17, 20, 21, 22, 26, 27, 28, 32, 33, 34,
            38, 39, 43, 44, 45, 49, 50, 51, 55, 56, 60, 61, 62, 66, 67, 68, 72,
            73, 74, 77, 78, 79, 83, 84, 85, 89, 90, 91, 95, 96, 100, 101, 102,
            106, 107, 108, 112, 113, 117, 118, 119, 123, 124, 125, 129, 130,
            131, 135, 136, 140, 141, 142, 146, 147, 148, 152, 153, 157, 158,
            159, 163, 164, 165, 196, 170, 171, 174, 175, 176, 180, 181, 182,
            186, 187, 188, 192, 193, 197, 198, 199, 203, 204, 205, 209, 210,
            214, 215, 216, 220, 221, 222, 226, 227, 228, 231, 232, 233, 237,
            238, 239, 243, 244, 245, 249, 250, 254, 255, 256, 260, 261, 262,
            266, 267, 271, 272, 273, 277, 278, 279, 283, 284, 285, 289, 290,
            294, 295, 296, 300, 301, 302, 306, 307, 311, 312, 313, 317, 318,
            319, 323, 324, 328, 329, 330, 334, 335, 336, 340, 341, 342, 346,
            347, 351, 352, 353, 357, 358, 359, 363, 364,
          ];

          if (d == "end") {
            if (endDateSpecialIncrementPattern.includes(stepValue)) {
              days += 1;
            }
            return momentDateObject.add(days - 1, "d").toDate();
          } else {
            if (endDateSpecialIncrementPattern.includes(stepValue - 1)) {
              days += 1;
            }

            return momentDateObject.add(days < 3 ? 0 : days - 2, "d").toDate();
          }

        case "Other":
          const stepsPerYear = formGroup.get("stepsPerYrYTZ").value;
          const portionPerDay = 365 / stepsPerYear;
          let value;

          if (d == "end") {
            value = stepValue * portionPerDay;
          } else {
            value =
              stepValue == 1 ? stepValue : (stepValue - 1) * portionPerDay;
          }
          return momentDateObject.add(Math.ceil(value) - 1, "day").toDate();
      }
      return d == "start"
        ? momentDateObject.toDate()
        : momentDateObject.subtract(1, "day").toDate();

      // return TimeUtilities.convertUnixToTimeInstant(momentDateObject.unix());
    }
  }

  private convertStepToMomentDate(step: number): moment.Moment {
    const moment = TimeUtilities.getMoment();
    const baseYear = moment("0000-01-01 00:00Z");
    baseYear.add(step, "year");
    return baseYear;
  }

  public isFixedCalendarDate(): boolean {
    return this.getFormGroup().get("dailyTimingTZ").value;
  }

  public writeXmlObject() {
    const moment = TimeUtilities.getMoment();
    let formData = this.getFormGroup().getRawValue();
    const { stDateDTZ, enDateDTZ, ...formValues } = formData;

    let dateObject;
    let startDate = "";
    let endDate = "";

    let timing = this.getFormValueObjectForXmlExport(null, formValues);

    if (formData.dailyTimingTZ) {
      if (stDateDTZ?.getDate) {
        startDate = moment(stDateDTZ).format("YYYYMMDD");
      }
      if (enDateDTZ?.getDate) {
        endDate = moment(enDateDTZ).format("YYYYMMDD");
      }

      dateObject = {
        stDateTM: [
          {
            _: startDate,
            $: {
              CalendarSystemT: "Gregorian",
            },
          },
        ],
        enDateTM: [
          {
            _: endDate,
            $: {
              CalendarSystemT: "Gregorian",
            },
          },
        ],
      };
    }

    return { Timing: [{ $: timing, ...(startDate && endDate && dateObject) }] };
  }

  public getCalendarSystem(): CalendarSystemT {
    return this.isFixedCalendarDate() ? "Gregorian" : "FixedLength";
  }

  public getSteps(): number {
    const stepType = this.getFormGroup().get("tStepsYTZ").value;
    return this.maxStepValueMap[stepType];
  }

  public updateFormModelUpateInitialised() {
    if (this.simulationService.isEstate()) {
      this.formModel["dailyTimingTZ"].isShown = false;
      this.formModel["dailyTimingTZ"].defaultValue = false;
    }
  }
}
