import {
  Component,
  ComponentRef,
  HostBinding,
  Injector,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { BehaviorSubject, Observable, Subject, Subscription, of } from "rxjs";
import { take, takeUntil, delay } from "rxjs/operators";
import { MyPlotsService } from "src/app/my-plots/services/my-plots.service";
import { FlyOverPanelService } from "src/app/shared/components/fly-over-panel/services/fly-over-panel.service";
import { Constants } from "src/app/shared/constants";
import { DbService } from "src/app/shared/services/db.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { MessageService } from "src/app/shared/services/message.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { PlotFlow } from "src/app/simulation/models";
import { PlotFormat, SimulationService } from "../../models";
import { FlyOverPanelComponent } from "../fly-over-panel/fly-over-panel.component";
import { BaseModalFormComponent } from "../base-modal-form/base-modal-form.component";
import { PlotReferenceDataService } from "src/app/core/services/plot-reference-data.service";

@Component({
  selector: "fc-plot-entry-modal",
  templateUrl: "./plot-entry-modal.component.html",
  styleUrls: ["./plot-entry-modal.component.scss"],
})
export class PlotEntryModalComponent
  extends BaseModalFormComponent
  implements OnInit
{
  @Output() action = new EventEmitter();

  @ViewChild(FlyOverPanelComponent) flyOverPanel: FlyOverPanelComponent;

  @ViewChildren("flowContentHost", { read: ViewContainerRef })
  private flowContentHost: QueryList<ViewContainerRef>;

  @HostBinding("class") className = this.getSelectedFlow()?.id;
  public readonly plotFormatMap = Constants.PLOT_FORMAT_MAP;

  private subscription: Subscription[] = [];

  private acceptedRoutes = Constants.PLOT_FORMATS;

  private readonly destroy$ = new Subject<void>();

  public masterFormGroup: UntypedFormGroup;
  public currentFlows$: Observable<PlotFlow[]>;
  public selectedPlotFormat$: Subject<PlotFormat>;

  public canNavigateNextFlow$ = new BehaviorSubject<boolean>(true);
  public canNavigatePreviousFlow$ = new BehaviorSubject<boolean>(true);

  public isFlowStepsExpanded: boolean = false;

  public flyOverPanelInjector: Injector;

  public isLoading: boolean = false;

  public backToPageText: String = "Back";

  public simulationService: SimulationService; //this is be an input

  constructor(
    public router: Router,
    public helperService: HelperService,
    public dbService: DbService,
    public flyOverPanelService: FlyOverPanelService,
    public plotRefDataService: PlotReferenceDataService,
    public messageService: MessageService,
    public myPlotsService: MyPlotsService,
    // public eventFormsService: EventFormsService,
    public modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedPlotFormat$ = this.simulationService.selectedPlotFormat$;

    this.initPlotFlow();

    if (!this.getSelectedFlow()) {
      this.currentFlows$.pipe(take(1)).subscribe((flows) => {
        this.setSelectedFlowPage(flows[0]);
      });
    }

    this.simulationService
      .getSelectedFlow$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((flow) => {
        setTimeout(() => {
          this.loadFormComponent(this.getSelectedFlow());
          this.checkNavigation();

          if (flow.service && flow.service.startLogging) {
            flow.service.startLogging();
          }
        }, 0);
      });
  }

  ngAfterViewInit() {}

  protected initPlotFlow() {
    this.currentFlows$ = this.simulationService.currentFlows$;

    this.simulationService.calculateFlowVisibility();

    this.currentFlows$.pipe(take(1)).subscribe((flows) => {
      flows
        .filter((f) => f.type == "baseFlow")
        .forEach((f) =>
          f.service
            .getFormGroup()
            .valueChanges.pipe(takeUntil(this.destroy$), delay(500))
            .subscribe(() => {
              this.simulationService.calculateFlowVisibility();
              this.checkNavigation();
            })
        );
    });
  }

  protected loadFormComponent(flow: PlotFlow) {
    //  this.className = this.getSelectedFlow()?.id + "-page";

    const flowHost = this.flowContentHost?.first;
    if (!flowHost) {
      return;
    }
    flowHost.clear();
    flowHost.element.nativeElement.className = "flow-host";
    const childComp: ComponentRef<any> = flowHost.createComponent(
      flow.component
    );

    //child component inputs
    childComp.instance["service"] = flow.service;
    childComp.instance["simulationService"] = this.simulationService;
    childComp.instance["canNavigatePreviousFlow$"] =
      this.canNavigatePreviousFlow$;
    childComp.instance["canNavigateNextFlow$"] = this.canNavigateNextFlow$;

    const nextFlowSub = childComp.instance["nextFlowClicked"]
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.nextFlow();
      });

    const previousFlowSub = childComp.instance["previousFlowClicked"]
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.previousFlow();
      });

    this.subscription.push(nextFlowSub, previousFlowSub);
  }

  public getSelectedFlow() {
    if (!this.simulationService) {
      return null;
    }
    return this.simulationService.getSelectedFlow();
  }

  public setSelectedFlowPage(flow): void {
    const currentFlow = this.getSelectedFlow();

    if (currentFlow && currentFlow.service.startLogging) {
      currentFlow.service.serviceDestroySubject$.next();
    }

    this.simulationService.setSelectedFlow(flow);
  }

  public checkNavigation(): void {
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const visibleFlows = allFlows.filter((f) => f.isShown);
      const selectedFlow = this.getSelectedFlow();
      const selectedFlowIndex = visibleFlows.findIndex(
        (f) => f.id == selectedFlow.id
      );

      this.canNavigatePreviousFlow$.next(selectedFlowIndex > 0);

      this.canNavigateNextFlow$.next(
        selectedFlowIndex < visibleFlows.length - 1
      );
    });
  }

  public previousFlow(): void {
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const visibleFlows = allFlows.filter((f) => f.isShown);
      const selectedFlow = this.getSelectedFlow();
      const index = visibleFlows.findIndex((f) => f.id == selectedFlow.id);
      this.setSelectedFlowPage(visibleFlows[index - 1]);
    });
  }

  public nextFlow(): void {
    this.currentFlows$.pipe(take(1)).subscribe((allFlows) => {
      const visibleFlows = allFlows.filter((f) => f.isShown);
      const selectedFlow = this.getSelectedFlow();
      const index = visibleFlows.findIndex((f) => f.id == selectedFlow.id);
      this.setSelectedFlowPage(visibleFlows[index + 1]);
    });
  }

  public downloadPlotFileLocally() {
    this.simulationService.downloadPlotFileLocally();
  }

  public savePlotFile() {
    this.simulationService.savePlotFile();
  }

  public runSimulation() {
    this.simulationService.runSimulation();
  }

  public collapseHelpPanel() {
    this.getSelectedFlow().service.collapseHelpPanel();
  }

  public isLoading$(): Observable<boolean> {
    return this.simulationService.isUploading$;
  }

  public isSimulating$(): Observable<boolean> {
    return this.simulationService.messageService.isSimulationRunning$;
  }

  public getPlotFormatName(): string {
    return this.simulationService.getPlotFormatName();
  }
  public shouldDisableSave(): boolean {
    const aboutFormGroup = this.simulationService.aboutService.getFormGroup();
    const project = aboutFormGroup.get("project").value;
    const collection = aboutFormGroup.get("collection").value;
    if (!project || !collection) {
      return true;
    }

    return false;
  }

  public isSimulationReady() {
    return this.simulationService.isSimulationReady();
  }

  public isFromServer(): boolean {
    return (
      this.simulationService.plotMetaData !== null &&
      this.simulationService.plotMetaData !== undefined &&
      !this.simulationService.isClonedPlotFile
    );
  }

  public async checkFormStatusBeforeSave() {
    if (this.simulationService.isDirtyForm()) {
      const message =
        "Are you sure, you want to leave this page with unsaved data?";
      const confirm = await this.modalService.openConfirmModal(message);

      if (confirm) {
        this.closeForm(null);
      }
    } else {
      this.closeForm(null);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.subscription.forEach((s) => {
      s.unsubscribe();
    });

    this.subscription = [];
    this.simulationService.reset();
  }
}
