import { v4 as uuidv4 } from "uuid";
import * as _ from "lodash";
import * as xml2js from "xml2js";
import Utilities from "src/app/shared/utilities/utils";

const rmtXmlBuilder = new xml2js.Builder({
  arrayAccessForm: "property",
  rootName: "root",
  xmldec: { version: "1.0", encoding: "UTF-8" },
});

export default class XMLUtilities {
  static uuid(): string {
    return uuidv4();
  }

  static readRMDFile(file): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const fileExtension: string = file.name.split(".").pop();

      if (fileExtension !== "rmd") {
        return reject({
          type: "danger",
          msg: `Only rmd file format accepted. `,
          dismissible: true,
        });
      }

      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = (event) => {
        const xmlDoc: string | ArrayBuffer = event.target.result;
        return resolve(xmlDoc);
      };

      fileReader.onerror = (err) => {
        return reject({
          type: "danger",
          msg: `Something went wrong while reading the file. `,
          dismissible: true,
        });
      };

      fileReader.readAsText(file);
    });
  }

  static convertJsonNullValues(obj): any {
    if (obj === null || obj === undefined) {
      return { $: { "xsi:nil": "true" } };
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertJsonNullValues(item));
    }

    if (typeof obj === "object") {
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
        if (value === null || value === undefined) {
          newObj[key] = { $: { "xsi:nil": "true" } };
        } else {
          newObj[key] = this.convertJsonNullValues(value);
        }
      }
      return newObj;
    }

    return obj;
  }

  static convertNilToNullValue(obj) {
    if (obj && typeof obj === "object" && obj["xsi:nil"]) {
      return null;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertNilToNullValue(item));
    }

    if (obj && typeof obj === "object") {
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
        newObj[key] = this.convertNilToNullValue(value);
      }
      return newObj;
    }

    return obj;
  }

  static convertXmlType(obj) {
    // Handle null or undefined
    if (obj == null) {
      return obj;
    }

    // Convert objects with "xsi:nil" to null
    if (typeof obj === "object" && obj["xsi:nil"]) {
      return null;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertXmlType(item));
    }

    // Handle objects
    if (typeof obj === "object") {
      const newObj = {};
      let hasXsiType = false;
      let xsiTypeValue;

      for (const [key, value] of Object.entries(obj)) {
        if (key === "xsi:type") {
          hasXsiType = true;
          xsiTypeValue = value;
        } else {
          newObj[key] = this.convertXmlType(value);
        }
      }

      // If the object had an "xsi:type" property, restructure it
      if (hasXsiType) {
        return {
          $: {
            "xsi:type": xsiTypeValue,
          },
          ...newObj,
        };
      }

      return newObj;
    }

    // Return primitives as is
    return obj;
  }

  static removeNullValues(obj: any): any {
    if (obj === null || obj === undefined) {
      return undefined;
    }

    if (Array.isArray(obj)) {
      return obj
        .map((item) => this.removeNullValues(item))
        .filter((item) => item !== undefined);
    }

    if (typeof obj === "object") {
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
        const processedValue = this.removeNullValues(value);
        if (processedValue !== undefined) {
          newObj[key] = processedValue;
        }
      }
      return Object.keys(newObj).length > 0 ? newObj : undefined;
    }

    return obj;
  }

  static convertRawTSValuesToDouble(obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertRawTSValuesToDouble(item));
    }

    if (typeof obj === "object" && obj !== null) {
      const newObj = {};
      for (const [key, value] of Object.entries(obj)) {
        if (key === "rawTS" && Array.isArray(value)) {
          newObj[key] = this.formatRawTS(value);
        } else if (typeof value === "object" && value !== null) {
          newObj[key] = this.convertRawTSValuesToDouble(value);
        } else {
          newObj[key] = value;
        }
      }
      return newObj;
    }

    return obj;
  }

  //For RMT

  static remapEventGroupArray(oldObj) {
    let obj = { ...oldObj };
    if (Array.isArray(obj.StockingLevelGroups)) {
      obj.StockingLevelGroups = {
        StockingLevelGroup: obj.StockingLevelGroups.map((g) => {
          return g;
        }),
      };
    } else if (Array.isArray(obj.FertilizationLevelGroups)) {
      obj.FertilizationLevelGroups = {
        FertilizationLevelGroup: obj.FertilizationLevelGroups.map((g) => {
          return g;
        }),
      };
    } else if (Array.isArray(obj.WeedControlIntensityGroups)) {
      obj.WeedControlIntensityGroups = {
        WeedControlIntensityGroup: obj.WeedControlIntensityGroups.map((g) => {
          return g;
        }),
      };
    }

    return obj;
  }

  static async convertXmlToJson(xml): Promise<Object> {
    const parser = new xml2js.Parser({
      explicitArray: false,
      mergeAttrs: true,
    });
    let json;
    await parser.parseString(xml, (err, result) => {
      json = result;
    });
    return json;
  }

  static async convertJsonToXmlString(jsonObject) {
    const xml = rmtXmlBuilder.buildObject(jsonObject);
    return xml;
  }

  static formatRawTS(rawTS): { double: number } {
    return {
      double: rawTS.map((value) => ({ _: Utilities.halfRoundUp(value, 12) })),
    };
  }
}
