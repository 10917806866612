import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { DbService } from "src/app/shared/services/db.service";
import {
  RoleAccessModel,
  RoleAccessRequest,
  RoleAccessResponse,
  UserRole,
} from "../model";
import { HttpResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class RoleAccessService {
  constructor(private dbService: DbService) {}

  public createUserRoleRequest(requestData: RoleAccessRequest) {
    return this.dbService.createUserRoleRequest(requestData);
  }
}
