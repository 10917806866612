import { EventEmitter } from "@angular/core";
import { UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { Subscription } from "rxjs";
import { Simulation2016Service } from "../core/services/simulation-2016.service";
import { Simulation2020Service } from "../core/services/simulation-2020.service";
import { Simulation2024Service } from "../core/services/simulation-2024.service";
import { About2020Service } from "../plot/about/services/about-2020.service";
import { About2024Service } from "../plot/about/services/about-2024.service";
import { Configuration2020Service } from "../plot/configuration/services/configuration-2020.service";
import { Configuration2024Service } from "../plot/configuration/services/configuration-2024.service";
import { Timing2020Service } from "../plot/timing/services/timing-2020.service";
import { Timing2024Service } from "../plot/timing/services/timing-2024.service";
import { Events2020Service } from "../plot/events/services/events-2020.service";
import { Events2024Service } from "../plot/events/services/events-2024.service";
import { Estate2020Service } from "../plot/estate/services/estate-2020.service";
import { Estate2024Service } from "../plot/estate/services/estate-2024.service";
import { Explorer2020Service } from "../plot/explorer/services/explorer-2020.service";
import { Explorer2024Service } from "../plot/explorer/services/explorer-2024.service";
import { OutputWindows2020Service } from "../plot/output-windows/service/output-windows-2020.service";
import { OutputWindows2024Service } from "../plot/output-windows/service/output-windows-2024.service";
import { PlotDigest2020Service } from "../plot/plot-digest/services/plot-digest-2020.service";
import { PlotDigest2024Service } from "../plot/plot-digest/services/plot-digest-2024.service";
import { PlotFiles2020Service } from "../plot/plot-files/services/plot-files-2020.service";
import { PlotFiles2024Service } from "../plot/plot-files/services/plot-files-2024.service";
import { EventForms2020Service } from "./components/event-forms/event-forms-2020.service";
import { EventForms2024Service } from "./components/event-forms/event-forms-2024.service";
import { RmtSimulationService } from "../rmt/simulation/services/rmt-simulation.service";

export type UserProfile = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

export type FormModel = {
  [key: string]: FormFieldModel;
};

export interface validatorModel {
  isCustomValidator: boolean;
  functionName: string;
  constantsInput?: string;
  input?: string | number | boolean;
}

export interface FormFieldModel {
  readonly label?: string;
  readonly defaultValue: any;
  readonly selectOptions?: any[];
  readonly unit?: string;
  readonly validatorConfig?: any[];
  validators?: ValidatorFn[];
  readonly programmingName?: string;
  isShown?: boolean;
  isDisabled?: boolean;
  readonly isExplorer?: boolean;
  readonly isFixedExplorerField?: boolean;
  readonly isDigest?: boolean;
  readonly excludeFromXml?: boolean;
  readonly high?: number;
  readonly low?: number;
  readonly isOneMore?: boolean;
  readonly dataType?: number;
  readonly helpId?: number;
  readonly categoryName?: string;
  readonly categoryLabel?: string;
  readonly columnWidth?: number;
  readonly errorId?: number;
  readonly isInverse?: boolean;
  readonly isSubSame?: boolean;
  readonly prefixId?: number;
  suffixId?: number;
  readonly isRisk?: boolean;
  readonly scale?: number;
  readonly sortId?: number;
  readonly isSpa?: boolean;
  readonly subSame?: boolean;
  readonly enumId?: string;
  readonly eventId?: number;
  readonly tIn?: number;
  readonly spec?: number;
  readonly std?: number;
  readonly isValidTricky?: boolean;
  readonly systemType?: number;
}

export type SimulationService =
  | Simulation2016Service
  | Simulation2020Service
  | Simulation2024Service
  | any;

export type AboutService = About2020Service | About2024Service;

export type ConfigurationService =
  | Configuration2020Service
  | Configuration2024Service;

export type TimingService = Timing2020Service | Timing2024Service;
export type PlotDigestService = PlotDigest2020Service | PlotDigest2024Service;

export type EventsService = Events2020Service | Events2024Service;

export type ExplorerService = Explorer2020Service | Explorer2024Service;
export type OutputWindowsService =
  | OutputWindows2020Service
  | OutputWindows2024Service;

export type PlotFilesService = PlotFiles2020Service | PlotFiles2024Service;

export type EstateService = Estate2020Service | Estate2024Service;

export type EventFormsService = EventForms2020Service | EventForms2024Service;

export interface FieldWatcherContext {
  formGroup: UntypedFormGroup;
  formModel: FormModel;
  layout: any;
  simulationService: SimulationService;
  fieldChangedEmitter?: EventEmitter<FieldChangedEmitter>;
  isEventPage?: boolean; //only available in event page/services
}
export interface FieldChangedEmitter {
  flow: string;
  [key: string]: any;
}

export type FieldWatcher = {
  [key: string]: (newValue: any, context: FieldWatcherContext) => void;
};

export interface ServerResponse {
  message: string;
  data: any;
}

export interface VisualiserHeader {
  name: string;
  label: string;
  editable: boolean;
}

export interface SpatialData {
  Version: String;
  InputElement: any[];
  ItemList: any[];
  LocnSoil: any[];
  SiteInfo: any[];
}

export interface InputItem {
  TimeSeries?: TimeSeries;
  option: string;
  tIn: string;
  value?: string;
}

export interface TimeSeries {
  dataPerYrTS: string;
  multTS: string;
  nDecPlacesTS: string;
  nYrsTS: string;
  rawTS: RawTs[];
  showGraphTS: boolean;
  tExtrapTS: string;
  tInTS: string;
  tOriginTS: string;
  yr0TS: string;
}

export interface RawTs {
  count: string;
  _: string;
}

export type PlotFormat = "plo" | "pld" | "est";
export type PlotDocumentType =
  | "DocumentPlot"
  | "DocumentPlotDigest"
  | "DocumentEstate";

export interface UploadPlotFileParams {
  file: File;
  type: PlotFormat;
  project: string;
  collection: string;
  fileName: string;
  latitude: string;
  longitude: string;
}
export interface ListPlotFilesParams {
  defaultSize?: string;
  pageNumber?: string;
}

export interface UploadEstatePlotFileParams {
  files: File[];
  project: string;
  collection: string;
}

export interface GetEstatePathParams {
  files: string[];
  project: string;
  collection: string;
}

export interface TreeViewItem {
  isExpanded?: boolean;
  isSearchMatch: boolean;
  id: string | number;
  name: string;
  description: string;
  subItems?: TreeViewItem[];
}

interface SpeciesMetaData {
  content: string;
  id: string;
  value: string;
}

export interface InputElement {
  TimeSeries: any[];
  option: string;
  tIn: string;
}

export interface TYFCategory {
  tTYFCat: string;
  tyf_G: string;
  tyf_r: string;
}

export interface TYFParameters {
  TYFCategory: TYFCategory[];
  count: string;
  idSP: string;
}

export interface TreeSpeciesMetadata extends SpeciesMetaData {
  TYFParameters: TYFParameters[];
}

export interface CropSpeciesMetaData extends SpeciesMetaData {
  InputElement: InputElement[];
}

export interface Species {
  $: { Version: string };
  RegimeList: RegimeA[] | RegimeF[];
  value: string;
  SpeciesForest: SpeciesForest[];
  SpeciesAgriculture: SpeciesAgriculture[];
}
export interface RegimeList {
  $: { idSP: string };
  RegimeF: [RegimeF];
  RegimeA: [RegimeA];
}

export interface RegimeA {
  $: {
    idRG: string;
    idSpecies: string;
    nmRG: string;
    tSuite: string;
  };
  RegimeEvent: RegimeEvent[];
}

export interface RegimeF {
  $: {
    cultRGF: string;
    idRG: string;
    idSpecies: string;
    nmRG: string;
    npiRGF: string;
    ppWeedCntRGF: string;
    prevLUseRGF: string;
    pruneRGF: string;
    rotLenRGF: string;
    sitePrepRGF: string;
    stateRGF: string;
    tSpecFrCat: string;
    tSuite: string;
    thinRGF: string;
    yrEnRGF: string;
    yrStRGF: string;
  };
  RegimeEvent: RegimeEvent[];
}

export interface RegimeCycle {
  eventDate: Date;
  simulate: boolean;
  event: RegimeEvent;
}

export interface RegimeEvent {
  $: {
    eventNmREV: string;
    idSP: string;
    patchiness: string;
    ruleSet: string;
    seq: string;
    tevREV: string;
  };
}

export interface SpeciesForest {
  $: {
    CFracBarkF: string;
    CFracBranF: string;
    CFracCortF: string;
    CFracFirtF: string;
    CFracLeafF: string;
    CFracStemF: string;
    atmsFracDBlitBkdnF: string;
    atmsFracDChwdBkdnF: string;
    atmsFracDCodrBkdnF: string;
    atmsFracDDdwdBkdnF: string;
    atmsFracDFidrBkdnF: string;
    atmsFracDLlitBkdnF: string;
    atmsFracRBlitBkdnF: string;
    atmsFracRChwdBkdnF: string;
    atmsFracRCodrBkdnF: string;
    atmsFracRDdwdBkdnF: string;
    atmsFracRFidrBkdnF: string;
    atmsFracRLlitBkdnF: string;
    atmsFracSBlitBkdnF: string;
    atmsFracSChwdBkdnF: string;
    atmsFracSCodrBkdnF: string;
    atmsFracSDdwdBkdnF: string;
    atmsFracSFidrBkdnF: string;
    atmsFracSLlitBkdnF: string;
    bkdnFracDBlitF: string;
    bkdnFracDChwdF: string;
    bkdnFracDCodrF: string;
    bkdnFracDDdwdF: string;
    bkdnFracDFidrF: string;
    bkdnFracDLlitF: string;
    bkdnFracRBlitF: string;
    bkdnFracRChwdF: string;
    bkdnFracRCodrF: string;
    bkdnFracRDdwdF: string;
    bkdnFracRFidrF: string;
    bkdnFracRLlitF: string;
    bkdnFracSBlitF: string;
    bkdnFracSChwdF: string;
    bkdnFracSCodrF: string;
    bkdnFracSDdwdF: string;
    bkdnFracSFidrF: string;
    bkdnFracSLlitF: string;
    bkdnSensMulcF: string;
    bkdnSensRainF: string;
    bkdnSensSoilF: string;
    bkdnSensTempF: string;
    curEdit: string;
    grthModeSP: string;
    idRegimeSP: string;
    idSP: string;
    mvgTreeId: string;
    nmSP: string;
    pltnType: string;
    rFracBarkF: string;
    rFracBranF: string;
    rFracCortF: string;
    rFracFirtF: string;
    rFracLeafF: string;
    rFracStemF: string;
    ratioBarkMortF: string;
    ratioBranMortF: string;
    ratioCortMortF: string;
    ratioFirtMortF: string;
    ratioLeafMortF: string;
    tAgeIxSP: string;
    tSpecFrCat: string;
    turnFracBarkF: string;
    turnFracBranF: string;
    turnFracCortF: string;
    turnFracFirtF: string;
    turnFracLeafF: string;
  };
  Allocations: any[];
  EventQ: any[];
  ForestDetails: any[];
  ForestInit: any[];
  EventstemFracInitFQ: any[];
  Growth: any[];
  Products: any[];
  TimeSeries: any[];
  nmGradeF: any[];
  notesSP: [string];
}

interface SpeciesAgriculture {
  $: { nmSP: string; idRegimeSP: string; idSP: string };
  AgDetails: any[];
  AgInit: any[];
  Allocations: any[];
  EventQ: any[];
  Growth: any[];
  Products: any[];
  nmGradeA: any[];
  notesSP: [string];
}
export interface TreeSpecies extends Species {
  SpeciesForest: SpeciesForest[];
}

export interface CropSpecies extends Species {
  SpeciesAgriculture: SpeciesAgriculture[];
}

export type SpeciesType = "tree" | "crop";

export interface SpeciesEvent {
  [key: string]: any;
  aqEnYrEV: string;
  aqStYrEV: string;
  categoryEV: string;
  clearEV: "true" | "false";
  dateOriginEV: string;
  idSP: string;
  nDaysFromStEV: "";
  nYrsFromStEV: "";
  nmEV: string;
  notesEV: [string];
  onEV: "true" | "false";
  tAaqEV: string;
  tEV: SpeciesEventType;
  tEvent: "SpecF" | "SpecA";
  tFaqEV: string;
}

export type SpeciesEventType =
  | "Thin"
  | "FirF"
  | "FirA"
  | "Harv"
  | "Plow"
  | "Herb"
  | "PlnF"
  | "PlnA"
  | "Graz"
  | "FFrc"
  | "NotE"
  | "AllE"
  | "TmtF"
  | "Chop"
  | "Term"
  | "IrrF"
  | "IrrA"
  | "NFeF"
  | "NFeA"
  | "ManF"
  | "ManA"
  | "XCst"
  | "XInc";

export interface SpeciesEventTypeMap {
  isAvaliable?: boolean; //used by event list view
  id?: string;
  label: string;
  service?: any;
  index: number;
  type?: "tree" | "crop" | "mixed";
  formGroup?: any; //to be removed
  categoryIds?: any; //to be removed
}

export interface ForestEvent {
  //   kThin = 0,  // Forest thinning
  //   kFirF = 1,  // Forest fire
  //   kFirA = 2,  // Agricultural fire
  //   kHarv = 3,  // Crop harvest
  //   kPlow = 4,  // Agricultural plowing
  //   kHerb = 5,  // Agricultural herbiciding
  //   kPlnF = 6,  // Tree planting
  //   kPlnA = 7,  // Crop planting
  //   kGraz = 8,  // Grazing change
  //   kFFrc = 9,  // Forest-Percentage change
  //   kNotE = 10,  // Not Event
  //   kAllE = 11,  // All Events
  //   kTmtF = 12,  // Forest treatment
  //   kChop = 13,  // Chopper roller
  //   kTerm = 14,  // Termite change
  //   kIrrF = 15,  // Forest irrigation change
  //   kIrrA = 16,  // Agricultural irrigation change
  //   kNFeF = 17,  // Forest N fertilizer application
  //   kNFeA = 18,  // Agricultural N fertilizer application
  //   kManF = 19,  // Forest manure from offsite
  //   kManA = 20,  // Agricultural manure from offsite
  //   kXCst = 21,  // External cost
  //   kXInc = 22,  // External income
}

export interface CropEvent {}

export type CalendarSystemT = "Gregorian" | "FixedLength";

export interface IFileUploadRequest {
  fileType: IPoltFileType | "rmd";
  file: File;
  project: string;
  collection: string;
  latitude?: string;
  longitude?: string;
  status?: "Valid" | "Invalid";
  plotId?: string;
}

export interface IFileUploadForm extends IFileUploadRequest {
  progress: string;
  subscription: Subscription;
}

export type IPoltFileType = "plo" | "pld" | "est";

export interface ErrorResponse {
  error: {
    title: string;
    status: number;
    detail: string;
  };
}

export interface UploadProgressCount {
  success: number;
  fail: number;
}

export interface FullcamVersionCheckResult {
  type: "revert" | "update";
  docVersion: number;
  version: string;
  previousDocVersion: number;
  previousVersion: string;
}

export class CustomApiResponse<T> {
  status: number;
  data: any;
  message: string;
}
