import { Injectable } from "@angular/core";
import { FieldWatcher, FormModel } from "src/app/shared/models";
import { FormGroupElement, FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
interface GetFormKeysfromCategoriesPayLoad {
  categoryIds: number[];
  baseValue: Object;
  formData: Object;
  removeLastCharacter?: boolean;
  excludeKeys?: string[];
  includeKeys?: string[];
  formName?: string;
}

/*******************xml fields*************************/
const forestSoilZapFields = [
  "fracHumfSopmToDpma",
  "fracHumfLrpmToRpma",
  "fracHumfMrpmToRpma",
  "fracHumfSommToDpma",
  "fracHumfLrmmToRpma",
  "fracHumfMrmmToRpma",
  "fracHumfMicrToXpma",
  "fracDLitBkdnToDpma",
  "fracRLitBkdnToRpma",
  "dToRRatioInPres",
  "fracManuCMToDpma",
  "fracManuCMToRpma",
  "fracManuCMToBiof",
  "fracManuCMToBios",
  "manuDpmaNCRatio",
  "manuRpmaNCRatio",
  "manuBiofNCRatio",
  "manuBiosNCRatio",
  "manuHumsNCRatio",
  "doManuFromOffs",
  "encpFracHums",
  "sampleDepth",
  "sdcmRateMultDpma",
  "sdcmRateMultRpma",
  "sdcmRateMultBiofV263",
  "sdcmRateMultBiosV263",
  "sdcmRateMultHums",
  "sdcmRateMultBiomCov",
  "fracPbioToBiofV263",
  "fracPbioToBiofV265",
  "fracPbioToBiosV265",
  "fracHumsToBiosV263",
  "fracHumsToBiofV265",
  "fracHumsToBiosV265",
  "evapoOpenRatio",
  "bToCMaxTSMDRatio",
  "maxBiofNCRatio",
  "maxBiosNCRatio",
  "maxHumsNCRatio",
  "minBiofNCRatio",
  "minBiosNCRatio",
  "minHumsNCRatio",
  "richNCRatio",
  "poorNCRatio",
  "fracDpmaToStorMyco",
  "fracRpmaToStorMyco",
  "fracBiofToStorMyco",
  "fracBiosToStorMyco",
];

const agricultureSoilZapFields = [
  "fracHumfSopmToDpma",
  "fracHumfLrpmToRpma",
  "fracHumfMrpmToRpma",
  "fracHumfSommToDpma",
  "fracHumfLrmmToRpma",
  "fracHumfMrmmToRpma",
  "fracHumfMicrToXpma",
  "fracDLitBkdnToDpma",
  "fracRLitBkdnToRpma",
  "dToRRatioInPres",
  "fracManuCMToDpma",
  "fracManuCMToRpma",
  "fracManuCMToBiof",
  "fracManuCMToBios",
  "manuDpmaNCRatio",
  "manuRpmaNCRatio",
  "manuBiofNCRatio",
  "manuBiosNCRatio",
  "manuHumsNCRatio",
  "doManuFromOffs",
  "pH",
  "encpFracHums",
  "sampleDepth",
  "sdcmRateMultDpma",
  "sdcmRateMultRpma",
  "sdcmRateMultBiofV263",
  "sdcmRateMultBiosV263",
  "sdcmRateMultHums",
  "sdcmRateMultBiomCov",
  "fracPbioToBiofV263",
  "fracPbioToBiofV265",
  "fracPbioToBiosV265",
  "fracHumsToBiosV263",
  "fracHumsToBiofV265",
  "fracHumsToBiosV265",
  "evapoOpenRatio",
  "bToCMaxTSMDRatio",
  "maxBiofNCRatio",
  "maxBiosNCRatio",
  "maxHumsNCRatio",
  "minBiofNCRatio",
  "minBiosNCRatio",
  "minHumsNCRatio",
  "richNCRatio",
  "poorNCRatio",
  "fracDpmaToStorMyco",
  "fracRpmaToStorMyco",
  "fracBiofToStorMyco",
  "fracBiosToStorMyco",
];

const soilOtherFields = ["clayFrac", "bulkDensity", "maxASW", "tSoil"];

const timeSeriesFields = [
  "soilCover",
  "presCMInput",
  "manuCMFromOffsF",
  "manuCMFromOffsA",
];

/*******************xml fields*************************/

const forestInputsGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    label: "Inputs",
    id: "input-group",
    showErrors: true,
    helpLink: "193_Soil%20Inputs.htm",
    items: [
      {
        label: "Manure from offsite is added to the soil",
        inputs: [
          {
            element: "input",
            type: "switch",
            programmingName: "doManuFromOffsF",
          },
        ],
      },
      {
        groups: [
          {
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            id: "forest-manure-group",
            label: "Manure from Offsite",
            items: [
              {
                label: "",
                inputs: [
                  {
                    element: "navigation",
                    linkTo: "flyOverPanel",
                    component: "DataVisualiserComponent",
                    programmingName: "manuCMFromOffsF",
                    componentInputs: [
                      {
                        inputKey: "timeSeries$",
                        isObservable: true,
                        formKey: "manuCMFromOffsF",
                      },
                      {
                        inputKey: "title",
                        value: "Manure from Offsite(Forest)",
                      },
                      {
                        inputKey: "formFieldModel",
                        method: "getFormFieldModel",
                        payload: "manuCMFromOffsF",
                      },
                    ],
                  },
                ],
              },
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Percentage of manure carbon that goes to",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [
                            "",
                            "Percentage of manure carbon that goes to",
                          ],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "DPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToDpmaF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "RPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToRpmaF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToBiofF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToBiosF",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: [
                                  "fracManuCMToDpmaF",
                                  "fracManuCMToRpmaF",
                                  "fracManuCMToBiofF",
                                  "fracManuCMToBiosF",
                                ],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            isShown: true,
            showErrors: true,
            isUnderlineHeader: true,
            id: "forest-broken-down-group",
            label: "Soil Input Percentages of Broken-Down Litter",
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Soil Input Percentages of Broken-Down Litter",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: ["", "To DPM or RPM", "To Hum"],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "Decomposable litter",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracDLitBkdnToDpmaF",
                                suffixText: "D",
                                width: "120px",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracDLitBkdnToDpmaF"],
                                width: "60px",
                              },
                            ],

                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "Resistant litter",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracRLitBkdnToRpmaF",
                                width: "120px",
                                suffixText: "R",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracRLitBkdnToRpmaF"],
                                width: "60px",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            isShown: true,
            showErrors: true,
            isUnderlineHeader: true,
            label: "Plant residues",
            id: "forest-plan-residues-group",
            items: [
              {
                label: "Plant residues",
                inputs: [
                  {
                    element: "navigation",
                    linkTo: "flyOverPanel",
                    component: "DataVisualiserComponent",
                    programmingName: "presCMInput",
                    componentInputs: [
                      {
                        inputKey: "timeSeries$",
                        isObservable: true,
                        formKey: "presCMInput",
                      },
                      {
                        inputKey: "title",
                        value: "Plant residues",
                      },
                      {
                        inputKey: "formFieldModel",
                        method: "getFormFieldModel",
                        payload: "presCMInput",
                      },
                    ],
                  },
                ],
              },
              {
                label: "Ratio of DPM to RPM",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "dToRRatioInPresF",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const forestPropertiesGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    showErrors: true,
    label: "Properties",
    id: "forest-properties-group",
    helpLink: "3_Soil%20Properties.htm",
    items: [
      {
        groups: [
          {
            isShown: true,
            label: "Soil properties",
            showErrors: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "HUM encapsulation percentage",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "encpFracHumsF",
                  },
                ],
              },
              {
                label: "Depth of soil sampled [cm]",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "sampleDepthF",
                  },
                ],
              },
            ],
          },
          {
            label: "Decomposition rate multipliers [1/yr]",
            showErrors: true,
            isUnderlineHeader: true,
            isShown: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Multipliers [1/yr]",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: ["Multipliers [1/yr]", ""],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "DPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultDpmaF",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "RPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultRpmaF",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultBiofV263F",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultBiosV263F",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultHumsF",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            label: "Plant Material and Biomass Destination Percentages",
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value:
                          "Plant Material and Biomass Destination Percentages",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [
                            "Percentages of decomposed DPM, RPM, BIO-F and BIO-S solids that go to",
                            "",
                          ],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracPbioToBiofV263F",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracPbioToBiofV263F"],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            isShown: true,
            label: "Humus Destination Percentages",
            showErrors: true,
            isUnderlineHeader: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Humus Destination Percentages",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [
                            "Percentages of decomposed HUM solids that go to",
                            "",
                          ],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracHumsToBiosV263F",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracHumsToBiosV263F"],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const forestWaterGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    showErrors: true,
    label: "Water",
    id: "Water-group",
    helpLink: "44_Soil%20Water.htm",
    items: [
      {
        groups: [
          {
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            label: "Top Soil Moisture Deficit (TSMD)",
            items: [
              {
                label: "Ratio of evapotranspiration to open-pan evaporation",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "evapoOpenRatioF",
                  },
                ],
              },
              {
                label: "Ratio of bare-to-covered maximum TSMD",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "bToCMaxTSMDRatioF",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const agriculturalInputsGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    showErrors: true,
    label: "Inputs",
    id: "input-group",
    helpLink: "193_Soil%20Inputs.htm",
    items: [
      {
        label: "Manure from offsite is added to the soil",
        inputs: [
          {
            width: "120px",
            element: "input",
            type: "switch",
            programmingName: "doManuFromOffsA",
          },
        ],
      },
      {
        groups: [
          {
            isShown: true,
            isUnderlineHeader: true,
            label: "Manure from Offsite",
            id: "agricultural-manure-group",
            items: [
              {
                label: "Manure From Offsite",
                inputs: [
                  {
                    element: "navigation",
                    linkTo: "flyOverPanel",
                    component: "DataVisualiserComponent",
                    programmingName: "manuCMFromOffsA",
                    componentInputs: [
                      {
                        inputKey: "timeSeries$",
                        isObservable: true,
                        formKey: "manuCMFromOffsA",
                      },
                      {
                        inputKey: "title",
                        value: "Manure From Offsite(Agriculture)",
                      },
                      {
                        inputKey: "formFieldModel",
                        method: "getFormFieldModel",
                        payload: "manuCMFromOffsA",
                      },
                    ],
                  },
                ],
              },

              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Percentage of manure carbon that goes to",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [
                            "",
                            "Percentage of manure carbon that goes to",
                          ],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "DPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToDpmaA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "RPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToRpmaA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToBiofA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracManuCMToBiosA",
                                width: "120px",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: [
                                  "fracManuCMToDpmaA",
                                  "fracManuCMToRpmaA",
                                  "fracManuCMToBiofA",
                                  "fracManuCMToBiosA",
                                ],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            label: "Soil input percentages of broken-down litter",
            id: "agricultural-broken-down-group",
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Soil Input Percentages of Broken-Down Litter",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: ["", "To DPM or RPM", "To Hum"],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "Decomposable litter",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracDLitBkdnToDpmaA",
                                suffixText: "D",
                                width: "120px",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracDLitBkdnToDpmaA"],
                                width: "60px",
                              },
                            ],

                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "Resistant litter",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracRLitBkdnToRpmaA",
                                width: "120px",
                                suffixText: "R",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracRLitBkdnToRpmaA"],
                                width: "60px",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            isShown: true,
            isUnderlineHeader: true,
            label: "Plant residues",
            id: "agricultural-plan-residues-group",
            items: [
              {
                label: "Plant residues",
                inputs: [
                  {
                    element: "navigation",
                    linkTo: "flyOverPanel",
                    component: "DataVisualiserComponent",
                    programmingName: "presCMInput",
                    componentInputs: [
                      {
                        inputKey: "timeSeries$",
                        isObservable: true,
                        formKey: "presCMInput",
                      },
                      {
                        inputKey: "title",
                        value: "Plant residues",
                      },
                      {
                        inputKey: "formFieldModel",
                        method: "getFormFieldModel",
                        payload: "presCMInput",
                      },
                    ],
                  },
                ],
              },
              {
                label: "Ratio of DPM to RPM",
                inputs: [
                  {
                    width: "120px",
                    element: "input",
                    type: "number",
                    programmingName: "dToRRatioInPresA",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const agriculturalPropertiesGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    showErrors: true,
    label: "Properties",
    id: "agricultural-properties-group",
    helpLink: "3_Soil%20Properties.htm",
    items: [
      {
        groups: [
          {
            isShown: true,
            label: "Soil properties",
            showErrors: true,
            isUnderlineHeader: true,
            items: [
              {
                label: "HUM encapsulation percentage",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "encpFracHumsA",
                  },
                ],
              },
              {
                label: "Depth of soil sampled [cm]",
                inputs: [
                  {
                    element: "input",
                    type: "number",
                    programmingName: "sampleDepthA",
                  },
                ],
              },
            ],
          },
          {
            label: "Decomposition rate multipliers [1/yr]",
            showErrors: true,
            isUnderlineHeader: true,
            isShown: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Multipliers [1/yr]",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: ["Multipliers [1/yr]", ""],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "DPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultDpmaA",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "RPM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultRpmaA",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultBiofV263A",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultBiosV263A",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "sdcmRateMultHumsA",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            label: "Plant Material and Biomass Destination Percentages",
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value:
                          "Plant Material and Biomass Destination Percentages",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [
                            "Percentages of decomposed DPM, RPM, BIO-F and BIO-S solids that go to",
                            "",
                          ],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-F",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracPbioToBiofV263A",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracPbioToBiofV263A"],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            isShown: true,
            showErrors: true,
            label: "Humus Destination Percentages",
            isUnderlineHeader: true,
            items: [
              {
                label: null,
                inputs: [
                  {
                    element: "component",
                    component: "FormItemTableComponent",
                    componentInputs: [
                      {
                        inputKey: "caption",
                        value: "Humus Destination Percentages",
                      },
                      {
                        inputKey: "service",
                        method: "getService",
                      },
                      {
                        inputKey: "data",
                        value: {
                          headers: [
                            "Percentages of decomposed HUM solids that go to",
                            "",
                          ],
                          rows: [
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "BIO-S",
                              },
                              {
                                element: "input",
                                type: "number",
                                programmingName: "fracHumsToBiosV263A",
                              },
                            ],
                            [
                              {
                                element: "text",
                                isHeader: "true",
                                value: "HUM",
                              },
                              {
                                element: "text",
                                method: "getRemaining",
                                payload: ["fracHumsToBiosV263A"],
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const agriculturalWaterGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    showErrors: true,
    label: "Water",
    id: "Water-group",
    helpLink: "44_Soil%20Water.htm",
    items: [
      {
        groups: [
          {
            isShown: true,
            isUnderlineHeader: true,
            showErrors: true,
            label: "Top Soil Moisture Deficit (TSMD)",
            items: [
              {
                label: "Ratio of evapotranspiration to open-pan evaporation",
                inputs: [
                  {
                    width: "120px",
                    element: "input",
                    type: "number",
                    programmingName: "evapoOpenRatioA",
                  },
                ],
              },
              {
                label: "Ratio of bare-to-covered maximum TSMD",
                inputs: [
                  {
                    width: "120px",
                    element: "input",
                    type: "number",
                    programmingName: "bToCMaxTSMDRatioA",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const soilCoverGroups: FormGroupElement[] = [
  {
    isShown: true,
    isAccordion: true,
    showErrors: true,
    label: "Soil cover",
    id: "soil-cover-group",
    helpLink: "102_Soil%20Cover.htm",
    items: [
      {
        label: "",

        inputs: [
          {
            element: "navigation",
            linkTo: "flyOverPanel",
            component: "DataVisualiserComponent",
            programmingName: "soilCover",
            componentInputs: [
              {
                inputKey: "timeSeries$",
                isObservable: true,
                formKey: "soilCover",
              },
              {
                inputKey: "title",
                value: "Soil cover",
              },
              {
                inputKey: "formFieldModel",
                method: "getFormFieldModel",
                payload: "soilCover",
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class Soil2020Service extends BasePlotFormService {
  public readonly pageId = "soil";
  public readonly pageHelpLink = "203_Soil.htm";

  public readonly categoryIds = [42, 43, 44, 45, 46, 47, 48, 49, 50, 51];

  public readonly layout: FormLayout = {
    label: "Soil",
    groups: [
      {
        label: "Forest",
        id: "forest-group",
        showErrors: true,
        isAccordion: true,
        isShown: true,
        isRoot: true,
        items: [
          {
            groups: forestInputsGroups,
          },
          {
            groups: forestPropertiesGroups,
          },
          {
            groups: forestWaterGroups,
          },
        ],
      },
      {
        label: "Agricultural",
        id: "agricultural-group",
        showErrors: true,
        isAccordion: true,
        isShown: true,
        isRoot: true,
        items: [
          {
            groups: agriculturalInputsGroups,
          },
          {
            groups: agriculturalPropertiesGroups,
          },
          {
            groups: agriculturalWaterGroups,
          },
          {
            groups: soilCoverGroups,
          },
        ],
      },
      {
        label: "Whole Plot",
        id: "whole-plot-group",
        showErrors: true,
        isShown: true,
        isRoot: true,
        helpLink: "46_Soil%20for%20the%20Whole%20Plot.htm",
        items: [
          {
            label: "% of the soil that is clay, by weight",
            inputs: [
              {
                width: "120px",
                element: "input",
                type: "number",
                programmingName: "clayFrac",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    fracHumfSopmToDpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 390,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 60,
    },
    fracHumfLrpmToRpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 392,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 59,
    },
    fracHumfMrpmToRpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 394,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 59,
    },
    fracHumfSommToDpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 396,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 60,
    },
    fracHumfLrmmToRpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 398,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 59,
    },
    fracHumfMrmmToRpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 400,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 59,
    },
    fracHumfMicrToXpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes to DPM and RPM",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 402,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracDLitBkdnToDpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "decomposable litter",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 63,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 418,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 60,
    },
    fracRLitBkdnToRpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "resistant litter",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 89,
      errorId: 59,
      isInverse: false,
      prefixId: 63,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 420,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    dToRRatioInPresF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of DPM to RPM in plant residues",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 72,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 426,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracManuCMToDpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 428,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracManuCMToRpmaF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 429,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracManuCMToBiofF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 79,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 430,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracManuCMToBiosF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 79,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 431,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    manuDpmaNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 438,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    manuRpmaNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 439,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    manuBiofNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 74,
      errorId: 12,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 440,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    manuBiosNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 74,
      errorId: 13,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 441,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    manuHumsNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      columnWidth: 65,
      errorId: 14,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 442,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    doManuFromOffsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 193,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "forest soil (yes/no)",
      unit: "",
      categoryName: "SoilFI",
      categoryLabel: "Soil : Forest : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 66,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 800,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracHumfSopmToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 404,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 62,
    },
    fracHumfLrpmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 406,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfMrpmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 408,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfSommToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 410,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 62,
    },
    fracHumfLrmmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 412,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfMrmmToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 78,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 414,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    fracHumfMicrToXpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes to DPM and RPM",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 57,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 416,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracDLitBkdnToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable litter",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 63,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 422,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 62,
    },
    fracRLitBkdnToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant litter",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 89,
      errorId: 0,
      isInverse: false,
      prefixId: 63,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 424,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 61,
    },
    dToRRatioInPresA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of DPM to RPM in plant residues",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 72,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 427,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToDpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "DPM",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 433,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToRpmaA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "RPM",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 434,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToBiofA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-F",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 435,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracManuCMToBiosA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-S",
      unit: "%",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 58,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 436,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuDpmaNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 443,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuRpmaNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 444,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuBiofNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 15,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 445,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuBiosNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 16,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 446,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    manuHumsNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      columnWidth: 70,
      errorId: 17,
      isInverse: true,
      prefixId: 55,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 447,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    doManuFromOffsA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 193,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "agricultural soil (yes/no)",
      unit: "",
      categoryName: "SoilAI",
      categoryLabel: "Soil : Agricultural : Inputs",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 66,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 801,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    encpFracHumsF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Forest HUM encapsulation %",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 87,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 449,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sampleDepthF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "30",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Depth of forest soil sampled",
      unit: "cm",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 451,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    sdcmRateMultDpmaF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "10.0000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "DPM",
      unit: "",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 455,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sdcmRateMultRpmaF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.3000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "RPM",
      unit: "",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 457,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sdcmRateMultBiofV263F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.6600",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 459,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sdcmRateMultBiosV263F: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.6600",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 461,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sdcmRateMultHumsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.0200",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "HUM",
      unit: "",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 463,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    sdcmRateMultBiomCovF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "BIO-F and BIO-S decomposition rate modifier for covered forest soil",
      unit: "",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 465,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracPbioToBiofV263F: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-F",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 98,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 467,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracPbioToBiofV265F: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F (RothC v26.5)",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      columnWidth: 104,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 469,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracPbioToBiosV265F: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S (RothC v26.5)",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      columnWidth: 104,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 470,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracHumsToBiosV263F: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-S",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 477,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracHumsToBiofV265F: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F (RothC v26.5)",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 479,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    fracHumsToBiosV265F: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S (RothC v26.5)",
      unit: "%",
      categoryName: "SoilFP",
      categoryLabel: "Soil : Forest : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 480,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    pHF: {
      high: 14,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pH of forest soil",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 134,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    pHA: {
      high: 14,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "pH of agricultural soil",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 138,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    encpFracHumsA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "Agricultural HUM encapsulation %",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 87,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 450,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sampleDepthA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "30.0000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Depth of agricultural soil sampled",
      unit: "cm",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 58,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 452,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    sdcmRateMultDpmaA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "10.0000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "DPM",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 456,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultRpmaA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.3000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "RPM",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 458,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultBiofV263A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.6600",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 460,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultBiosV263A: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.6600",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 462,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultHumsA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: "0.0200",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "HUM",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 77,
      errorId: 0,
      isInverse: false,
      prefixId: 67,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 464,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    sdcmRateMultBiomCovA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "BIO-F and BIO-S decomposition rate modifier for covered agricultural soil",
      unit: "",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 466,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracPbioToBiofV263A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-F",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 98,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 472,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracPbioToBiofV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 104,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 474,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracPbioToBiosV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 104,
      errorId: 0,
      isInverse: false,
      prefixId: 68,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 475,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumsToBiosV263A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-S",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 482,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumsToBiofV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 484,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    fracHumsToBiosV265A: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 3,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S (RothC v26.5)",
      unit: "%",
      categoryName: "SoilAP",
      categoryLabel: "Soil : Agricultural : Properties",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 69,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 485,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    tSoil: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 44,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Soil type (for computing ASW for 3PG)",
      unit: "",
      categoryName: "SoilFW",
      categoryLabel: "Soil : Forest : Water",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "SoilT",
      eventId: 10,
      tIn: 70,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    maxASW: {
      high: 1e36,
      low: 0.01,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Maximum available soil water (ASW) (for 3PG)",
      unit: "mm",
      categoryName: "SoilFW",
      categoryLabel: "Soil : Forest : Water",
      isShown: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 71,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    evapoOpenRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: "0.7500",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of evapotranspiration to open-pan evaporation",
      unit: "",
      categoryName: "SoilFW",
      categoryLabel: "Soil : Forest : Water",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 72,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    bToCMaxTSMDRatioF: {
      high: 10,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: "0.5560",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 10,
        },
      ],
      label: "Ratio of bare-to-covered maximum TSMD",
      unit: "",
      categoryName: "SoilFW",
      categoryLabel: "Soil : Forest : Water",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 79,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 453,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    evapoOpenRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: "0.7500",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 1e36,
        },
      ],
      label: "Ratio of evapotranspiration to open-pan evaporation",
      unit: "",
      categoryName: "SoilAW",
      categoryLabel: "Soil : Agricultural : Water",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 95,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 73,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    bToCMaxTSMDRatioA: {
      high: 10,
      low: 0.0001,
      isOneMore: false,
      dataType: 0,
      helpId: 44,
      defaultValue: "0.5560",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.0001,
        },
        {
          functionName: "max",
          input: 10,
        },
      ],
      label: "Ratio of bare-to-covered maximum TSMD",
      unit: "",
      categoryName: "SoilAW",
      categoryLabel: "Soil : Agricultural : Water",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 79,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 454,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxBiofNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 378,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    maxBiosNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 379,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    maxHumsNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 380,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    minBiofNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 384,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    minBiosNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 385,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    minHumsNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 386,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    richNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "C:N ratio above which the forest mineral pool is 'depleted'",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 501,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    poorNCRatioF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "C:N ratio below which the forest mineral pool is 'healthy'",
      unit: "",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 502,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    fracDpmaToStorMycoF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%/yr",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1119,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 71,
    },
    fracRpmaToStorMycoF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "%/yr",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1120,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 71,
    },
    fracBiofToStorMycoF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%/yr",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1121,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 71,
    },
    fracBiosToStorMycoF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%/yr",
      categoryName: "SoilFN",
      categoryLabel: "Soil : Forest : Nitrogen",
      isShown: true,
      columnWidth: 94,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1122,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 71,
    },
    maxBiofNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 381,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxBiosNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 382,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    maxHumsNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 24,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 383,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minBiofNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 387,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minBiosNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 388,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    minHumsNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "HUM",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 49,
      errorId: 0,
      isInverse: true,
      prefixId: 25,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 389,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    richNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "C:N ratio above which the agricultural mineral pool is 'depleted'",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 503,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    poorNCRatioA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 162,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "C:N ratio below which the agricultural mineral pool is 'healthy'",
      unit: "",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: true,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 504,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracDpmaToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "DPM",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1124,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    fracRpmaToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "RPM",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1125,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    fracBiofToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-F",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1126,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    fracBiosToStorMycoA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 104,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "BIO-S",
      unit: "%/yr",
      categoryName: "SoilAN",
      categoryLabel: "Soil : Agricultural : Nitrogen",
      isShown: true,
      columnWidth: 92,
      errorId: 0,
      isInverse: false,
      prefixId: 70,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 1127,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 72,
    },
    clayFrac: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 46,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "the soil that is clay, by weight",
      unit: "%",
      categoryName: "SoilWP",
      categoryLabel: "Soil : Whole Plot",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 414,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 69,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    bulkDensity: {
      high: 2.65,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 46,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Bulk density of the soil",
      unit: "t/m3",
      categoryName: "SoilWP",
      categoryLabel: "Soil : Whole Plot",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 139,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    presCMInput: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 99,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Inputs: Plant residues",
      unit: "tC/ha",
      categoryName: "SoilSp",
      categoryLabel: "Soil : Forest: Special",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 11,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    manuCMFromOffsF: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 101,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Manure from offsite",
      unit: "tC/ha",
      categoryName: "SoilSp",
      categoryLabel: "Soil : Forest : Special",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 12,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 64,
    },
    manuCMFromOffsA: {
      high: 1e36,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 101,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: "0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Manure from offsite",
      unit: "tC/ha",
      categoryName: "SoilSp",
      categoryLabel: "Soil : Agricultural : Special",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 13,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 65,
    },
    soilCover: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 6,
      helpId: 102,
      defaultValue: {
        $: {
          yr0TS: "2010",
          nYrsTS: "1",
          dataPerYrTS: "12",
        },
        rawTS: [
          {
            _: ",,,,,,,,,",
            $: {
              count: "10",
            },
          },
        ],
      },
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          isCustomValidator: true,
          functionName: "timeSeries",
        },
      ],
      label: "Agricultural soil cover",
      unit: "0...1",
      categoryName: "SoilSp",
      categoryLabel: "Soil : Agricultural : Special",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: true,
      enumId: null,
      eventId: 10,
      tIn: 14,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    pH: {
      label: "PH",
      defaultValue: null,
      suffixId: null,
    },
  };
  public updateFormDataFromSpatialData(locnSoil): void {
    if (!this.formGroup) {
      this.getFormGroup();
    }

    if (locnSoil.SoilBase) {
      const soilOtherInfo = locnSoil.SoilBase[0].SoilOther[0];
      const soilZap = locnSoil.SoilBase[0].SoilZap;
      const soilOther = locnSoil.SoilBase[0].SoilOther;

      if (soilZap) {
        soilZap.forEach((sz) => {
          const fieldSuffix = sz.id == "forest" ? "F" : "A";
          const obj = {};
          //convert keys before applying data
          Object.entries(sz).forEach((keyValue) => {
            const key = keyValue[0];
            const value = keyValue[1];
            if (key !== "id") {
              obj[key + fieldSuffix] = value;
            }
          });

          this.applyXmlValuesToFormGroup(obj, this.formGroup);
        });
      }

      if (soilOtherInfo) {
        this.applyXmlValuesToFormGroup(soilOther[0], this.formGroup);
      }

      if (locnSoil.soilCoverA) {
        const data = this.getDefaultTimeSeriesData("soilCover", {
          $: {
            yr0TS: "2010",
            nYrsTS: "1",
            dataPerYrTS: "1",
          },
          rawTS: [
            {
              _: String(locnSoil.soilCoverA),
              $: { count: "1" },
            },
          ],
        });
        this.formGroup.get("soilCover").setValue(data);
      }
    }
  }

  public remainingPercentage(fields: string[]) {
    let total = 0;
    let hasValues = true;
    fields.forEach((field) => {
      const control = this.formGroup.get(field);
      const value = control?.value;
      if (+value && control.valid) {
        total += +value;
      } else {
        hasValues = false;
      }
    });

    return hasValues ? 100 - total : null;
  }

  public readonly fieldWatcher: FieldWatcher = {
    "configuration:tPlot": (newValue: any, { formGroup }) => {
      const plotType = newValue;
      const forest = ["SoilF", "CompF", "CompM"];
      const agricultural = ["SoilA", "CompA", "CompM"];

      const forestGroup = this.getLayoutGroup(
        this.layout.groups,
        "forest-group"
      );
      const agriculturalGroup = this.getLayoutGroup(
        this.layout.groups,
        "agricultural-group"
      );

      const forestBrokenDownGroup = this.getLayoutGroup(
        this.layout.groups,
        "forest-broken-down-group"
      );

      const agriculturalBrokenDownGroup = this.getLayoutGroup(
        this.layout.groups,
        "agricultural-broken-down-group"
      );

      const forestPlantresiduesGroup = this.getLayoutGroup(
        this.layout.groups,
        "forest-plan-residues-group"
      );

      const agriculturalPlantresiduesGroup = this.getLayoutGroup(
        this.layout.groups,
        "agricultural-plan-residues-group"
      );

      const forestGroupFields = this.getForestFields();
      const agriculturalGroupFields = this.getAgricultureFields();
      forestGroup.isShown = forest.includes(plotType);
      agriculturalGroup.isShown = agricultural.includes(plotType);

      if (forestGroup.isShown) {
        this.addDefaultValidatorsInBulk(
          forestGroupFields,
          formGroup,
          this.formModel
        );
      } else {
        this.removeDefaultValidatorsInBulk(
          forestGroupFields,
          formGroup,
          this.formModel
        );
      }

      if (agriculturalGroup.isShown) {
        this.addDefaultValidatorsInBulk(
          agriculturalGroupFields,
          formGroup,
          this.formModel
        );
      } else {
        this.removeDefaultValidatorsInBulk(
          agriculturalGroupFields,
          formGroup,
          this.formModel
        );
      }

      forestBrokenDownGroup.isShown = ["CompF", "CompM"].includes(plotType);
      agriculturalBrokenDownGroup.isShown = ["CompA", "CompM"].includes(
        plotType
      );

      forestPlantresiduesGroup.isShown = plotType == "SoilF";
      agriculturalPlantresiduesGroup.isShown = plotType == "SoilA";

      //validation
      if (plotType == "SoilF" || plotType == "SoilA") {
        this.addDefaultValidatorsInBulk(
          ["presCMInput", "soilCover"],
          formGroup,
          this.formModel
        );
        // this.addValidatorsInBulk(
        //   ["presCMInput"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          ["presCMInput", "soilCover"],
          formGroup,
          this.formModel
        );
        // this.removeValidatorsInBulk(
        //   ["presCMInput"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (plotType == "SoilF") {
        this.addDefaultValidatorsInBulk(
          ["dToRRatioInPresF"],
          formGroup,
          this.formModel
        );
        // this.addValidatorsInBulk(
        //   ["dToRRatioInPresF"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          ["dToRRatioInPresF"],
          formGroup,
          this.formModel
        );
        // this.removeValidatorsInBulk(
        //   ["dToRRatioInPresF"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (plotType == "SoilA") {
        this.addDefaultValidatorsInBulk(
          ["dToRRatioInPresA"],
          formGroup,
          this.formModel
        );

        // this.addValidatorsInBulk(
        //   ["dToRRatioInPresA"],
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          ["dToRRatioInPresA"],
          formGroup,
          this.formModel
        );
        // this.removeValidatorsInBulk(
        //   ["dToRRatioInPresA"],
        //   [Validators.required],
        //   formGroup
        // );
      }

      const forestBrokenDownFields = [
        "fracDLitBkdnToDpmaF",
        "fracRLitBkdnToRpmaF",
      ];
      const agriculturalBrokenDownFields = [
        "fracDLitBkdnToDpmaA",
        "fracRLitBkdnToRpmaA",
      ];

      if (["CompF", "CompM"].includes(plotType)) {
        this.addDefaultValidatorsInBulk(
          forestBrokenDownFields,
          formGroup,
          this.formModel
        );
        // this.addValidatorsInBulk(
        //   forestBrokenDownFields,
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          forestBrokenDownFields,
          formGroup,
          this.formModel
        );
        // this.removeValidatorsInBulk(
        //   forestBrokenDownFields,
        //   [Validators.required],
        //   formGroup
        // );
      }

      if (["CompA", "CompM"].includes(plotType)) {
        this.addDefaultValidatorsInBulk(
          agriculturalBrokenDownFields,
          formGroup,
          this.formModel
        );

        // this.addValidatorsInBulk(
        //   agriculturalBrokenDownFields,
        //   [Validators.required],
        //   formGroup
        // );
      } else {
        this.removeDefaultValidatorsInBulk(
          agriculturalBrokenDownFields,
          formGroup,
          this.formModel
        );
        // this.removeValidatorsInBulk(
        //   agriculturalBrokenDownFields,
        //   [Validators.required],
        //   formGroup
        // );
      }
    },
    doManuFromOffsF: (newValue: any, { formGroup }) => {
      const manureGroup = this.getLayoutGroup(
        this.layout.groups,
        "forest-manure-group"
      );
      const fields = [
        "manuCMFromOffsF",
        "fracManuCMToDpmaF",
        "fracManuCMToRpmaF",
        "fracManuCMToBiofF",
        "fracManuCMToBiosF",
      ];
      manureGroup.isShown = newValue == true;
      if (newValue == true) {
        this.addDefaultValidatorsInBulk(fields, formGroup, this.formModel);
        // this.addValidatorsInBulk(fields, [Validators.required], formGroup);
      } else {
        this.removeDefaultValidatorsInBulk(fields, formGroup, this.formModel);
        // this.removeValidatorsInBulk(fields, [Validators.required], formGroup);
      }
    },
    doManuFromOffsA: (newValue: any, { formGroup }) => {
      const manureGroup = this.getLayoutGroup(
        this.layout.groups,
        "agricultural-manure-group"
      );
      const fields = [
        "manuCMFromOffsA",
        "fracManuCMToDpmaA",
        "fracManuCMToRpmaA",
        "fracManuCMToBiofA",
        "fracManuCMToBiosA",
      ];
      manureGroup.isShown = newValue == true;

      if (newValue == true) {
        this.addDefaultValidatorsInBulk(fields, formGroup, this.formModel);
        // this.addValidatorsInBulk(fields, [Validators.required], formGroup);
      } else {
        this.removeDefaultValidatorsInBulk(fields, formGroup, this.formModel);
        // this.removeValidatorsInBulk(fields, [Validators.required], formGroup);
      }
    },
  };

  private getForestFields(): string[] {
    return forestSoilZapFields.map((fF) => fF + "F");
  }

  private getAgricultureFields(): string[] {
    return agricultureSoilZapFields.map((fF) => fF + "A");
  }

  public readXmlObject(): void {
    super.readXmlObject();

    const template = this.simulationService.getPlotFileJson()["Soil"][0];
    const soilBase = template.SoilBase[0];

    const timeSeries = template.TimeSeries;
    if (soilBase) {
      const soilZap = soilBase.SoilZap;
      const forestSoilZap = soilZap[0].$;
      const agricultureSoilZap = soilZap[1].$;
      const soilOther = soilBase.SoilOther[0].$;

      [
        { suffix: "F", fields: forestSoilZap },
        { suffix: "A", fields: agricultureSoilZap },
        { suffix: null, fields: soilOther },
      ].forEach((soilObject) => {
        if (soilObject.suffix) {
          let fieldsObject = {};
          Object.entries(soilObject.fields).forEach((so) => {
            if (so[0] !== "id") {
              fieldsObject[so[0] + soilObject.suffix] = so[1];
            }
          });
          this.applyXmlValuesToFormGroup(fieldsObject, this.formGroup);
        } else {
          this.applyXmlValuesToFormGroup(soilObject.fields, this.formGroup);
        }
      });
      if (timeSeries) {
        timeSeries.forEach((ts) => {
          const objectKey = ts?.$?.tInTS;

          const formControl = this.formGroup.get(objectKey);
          if (formControl) {
            formControl.setValue(ts);
          } else {
            console.warn(`Soil -> ${objectKey} not found`);
          }
        });
      }
    }
    this.manuallyTriggerWatchingFields(this.fieldWatcher, {
      formGroup: this.formGroup,
      formModel: this.formModel,
      layout: this.layout,
      simulationService: this.simulationService,
    });
  }

  protected getFormKeysfromCategories(
    payload: GetFormKeysfromCategoriesPayLoad
  ): Object {
    const inputs = this.simulationService.plotRefDataService.getInputs(
      payload.categoryIds
    );

    let extraInputs = [];
    if (payload.includeKeys) {
      extraInputs = payload.includeKeys.map((k) => {
        return { progNmII: k };
      });
    }

    return Object.values([...inputs, ...extraInputs]).reduce((output, val) => {
      if (payload.excludeKeys && payload.excludeKeys.includes(val.progNmII)) {
        return output;
      }

      const formkey = payload.removeLastCharacter
        ? val.progNmII.slice(0, -1)
        : val.progNmII;
      if (payload.formData[formkey] === undefined) {
        console.warn(
          `Key: ${payload.formName}-> ${formkey} not found in form Data`
        );
      }

      output[formkey] = payload.formData[formkey] || "";
      return output;
    }, payload.baseValue);
  }

  public getXPathForCloneDigest(inputProgrammingName: string): {
    [xPath: string]: string;
    id: string;
  } {
    let programmingName = inputProgrammingName;

    let id = null;
    if (programmingName.charAt(programmingName.length - 1) == "F") {
      id == "forest";
      programmingName = programmingName.slice(0, -1);
      if (forestSoilZapFields.includes(programmingName)) {
        return { xPath: "//SoilZap", id, programmingName };
      }
    } else if (programmingName.charAt(programmingName.length - 1) == "A") {
      programmingName = programmingName.slice(0, -1);
      id = "agriculture";
      if (agricultureSoilZapFields.includes(programmingName)) {
        return { xPath: "//SoilZap", id, programmingName };
      }
    }

    if (soilOtherFields.includes(programmingName)) {
      id = "other";
      return { xPath: "//SoilOther", id, programmingName };
    }

    if (timeSeriesFields.includes(programmingName)) {
      return { xPath: "//TimeSeries", id: null, programmingName };
    }

    return null;
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();

    const xmlObject = {
      SoilBase: [{ SoilZap: [{ $: {} }, { $: {} }], SoilOther: [{ $: {} }] }],
      TimeSeries: [],
    };

    (xmlObject.SoilBase[0].SoilZap[0].$ = this.getFormValueObjectForXmlExport(
      forestSoilZapFields,
      formData,
      this.formModel,
      {
        position: "append",
        value: "F",
        id: "forest",
      }
    )),
      (xmlObject.SoilBase[0].SoilZap[1].$ = this.getFormValueObjectForXmlExport(
        agricultureSoilZapFields,
        formData,
        this.formModel,
        {
          position: "append",
          value: "A",
          id: "agriculture",
        }
      )),
      (xmlObject.SoilBase[0].SoilOther[0].$ = {
        id: "other",
        ...this.getFormValueObjectForXmlExport(
          soilOtherFields,
          formData,
          this.formModel
        ),
      });

    xmlObject.TimeSeries = timeSeriesFields.map((ts) => formData[ts]);

    return {
      Soil: [xmlObject],
    };
  }
}
