<div
  id="fc-drop-zone"
  (drop)="dropHandler($event)"
  (dragover)="dragoverHandler($event)"
  (dragleave)="dragleaveHandler($event)"
  (dragenter)="dragEnterHandler($event)"
  [class]="{ 'files-over': filesOver }"
>
  <div class="indicator">
    <div class="indicator-content">
      <i class="bi bi-cloud-upload"></i> <span>Drop files to upload</span>
    </div>
  </div>
</div>
