import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";

@Component({
  selector: "fc-formula-display",
  templateUrl: "./formula-display.component.html",
  styleUrls: ["./formula-display.component.scss"],
})
export class FormulaDisplayComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  public data: any;
  public formGroup: UntypedFormGroup;
  public formGroupPointer: string; //used for form array pointing to a slected formGroup
  public indexPointer: string; //same as above
  public service: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    //dynamic component will not update input values, need to watch changes manually
    // this.formGroup = this.getFormGroup();

    if (this.indexPointer) {
      this.service[this.indexPointer]
        .pipe(takeUntil(this.destroy$), delay(0))
        .subscribe(() => {
          this.formGroup = this.getFormGroup();
        });
    } else {
      setTimeout(() => {
        this.formGroup = this.getFormGroup();
      }, 0);
    }
  }

  private getFormGroup(): UntypedFormGroup {
    return this.formGroupPointer
      ? this.service[this.formGroupPointer]()
      : this.service.getFormGroup();
  }

  public isInvalid(): boolean {
    if (this.formGroup) {
      let allInputs = [];
      if (this.data?.rhs) {
        this.data?.rhs.forEach((items) => {
          const programmingName = items.find(
            (i) => i.programmingName
          )?.programmingName;
          if (programmingName) {
            allInputs.push(programmingName);
          }
        });
        return allInputs.some((input) => this.formGroup.get(input)?.invalid);
      }
    }
    return false;
  }

  public getInput(d) {
    return {
      element: "input",
      programmingName: d.programmingName,
      type: d.type,
    };
  }
  public getItem(d) {
    return {
      inputs: [this.getInput(d)],
      label: d.label,
    };
  }
}
