import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  forwardRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  ControlValueAccessor,
  NgModel,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Directive({
  selector: "[fcBeginningDay]",
  providers: [NgModel],
})
export class BeginningDayDirective {
  // @Input() public myValueChangeInterceptor: (value: Date) => Date;
  // @Output() public valueInput: EventEmitter<Date> = new EventEmitter();

  constructor(private model: NgModel, private element: ElementRef) {}

  @HostListener("ngModelChange", ["$event"])
  public onModelChange($event: Date): void {
    const isValidDate = $event instanceof Date && !isNaN($event.valueOf());

    if (isValidDate) {
      const newValue = new Date($event.setHours(0, 0, 0, 0));
      this.model.valueAccessor.writeValue(newValue);
    }
    // const cursorPosition: number = this.element.nativeElement.selectionStart;

    // if (this.myValueChangeInterceptor) {
    // 	const newValue: string = this.myValueChangeInterceptor($event);

    // 	this.model.valueAccessor.writeValue(newValue);
    // 	this.valueInput.emit(newValue);

    // 	if (newValue.length !== $event.length) {
    // 		this.element.nativeElement.selectionStart = this.element.nativeElement.selectionEnd = cursorPosition - 1;
    // 	}
    // } else {
    // 	this.valueInput.emit($event);
    // }
  }
}
