import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ColumnMode, DatatableComponent, id } from "@swimlane/ngx-datatable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormFieldModel, VisualiserHeader } from "../../models";
import { FlyOverPanelService } from "../fly-over-panel/services/fly-over-panel.service";

@Component({
  selector: "fc-data-visualiser",
  templateUrl: "./data-visualiser.component.html",
  styleUrls: ["./data-visualiser.component.scss"],
})
export class DataVisualiserComponent implements OnInit, OnDestroy {
  @ViewChild("ngxDatatable") ngxDatatable: DatatableComponent;

  public timeSeries$: UntypedFormControl;
  public formFieldModel: FormFieldModel;
  public title: string = "";
  public unit: string = "";
  public errorMessage: string = "Invalid field value";

  public isPercentage: boolean = false;
  public isKgdm: boolean = false; //values will have to x1000 when seeing this unit
  public high: number;
  public low: number;

  private readonly destroy$ = new Subject<void>();

  private readonly defaultOptions = {
    startYear: {
      defaultValue: 2010,
      validators: [],
      validatorConfig: [Validators.min(-999), Validators.max(9999)],
    },
    yearsOfData: {
      defaultValue: 1,
      validators: [],
      validatorConfig: [Validators.min(1), Validators.max(9999)],
    },
    dataPointsPerYear: {
      defaultValue: 12,
      validators: [],
      validatorConfig: [Validators.min(1), Validators.max(8760)],
    },
    multiplier: { defaultValue: 1.0, validators: [], validatorConfig: [] },
    decimalPlaces: {
      defaultValue: 1,
      validators: [],
      validatorConfig: [Validators.min(0), Validators.max(8)],
    },
    showGraph: { defaultValue: true, validators: [], validatorConfig: [] },
    extrapolation: {
      defaultValue: "AvgYr",
      validators: [],
      validatorConfig: [],
    },
    dateType: { defaultValue: "Calendar", validators: [], validatorConfig: [] },
  };

  public dateTypeOptions = [];
  public extrapolationOptions = [];

  private editing = { isInvalid: false };

  private rawData: any;

  public timeSeriesValues: string[] = [];
  public data: string[] = [];

  public ColumnMode = ColumnMode;

  public formGroup: UntypedFormGroup;

  public headers: VisualiserHeader[] = [];
  public rows: any[] = [];

  public chartOption;

  public readonly maxDecimalPlaces = 8;
  public readonly minDecimalPlaces = 0;

  private defaultChartOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      bottom: 90,
    },
    xAxis: {
      data: [],
      silent: false,
      splitLine: {
        isShown: false,
      },
      splitArea: {
        isShown: false,
      },
      name: "Year",
      nameLocation: "middle",
      nameGap: 50,
    },
    yAxis: {
      splitArea: {
        isShown: false,
      },
      name: "mm",
      nameLocation: "middle",
      nameGap: 50,
    },
    series: [
      {
        type: "bar",
        data: [],
        // Set `large` for large data amount
        large: true,
      },
    ],
  };

  public dataForSave: number[] = [];

  constructor(
    @Inject("componentData") public componentData: any,
    public flyOverPanelService: FlyOverPanelService
  ) {}

  ngOnInit(): void {
    this.timeSeries$ = this.componentData.timeSeries$;
    this.title = this.componentData.title;
    this.formFieldModel = this.componentData.formFieldModel;
    this.unit = this.formFieldModel.unit;
    this.initSelects(this.formFieldModel);

    this.isPercentage = String(this.unit).indexOf("%") > -1;
    this.isKgdm = String(this.unit).indexOf("kgdm/m3") > -1;

    this.high = this.convertHighLowBasedOnUnit(this.formFieldModel.high);
    this.low = this.convertHighLowBasedOnUnit(this.formFieldModel.low);

    if (this.high !== undefined && this.low !== undefined) {
      if (this.high == 1e36) {
        this.errorMessage = `Value must be greater than ${this.low}`;
      } else if (this.unit.indexOf("...") > -1) {
        const highLow = this.unit.split("...");
        const low = +highLow[0];
        const high = +highLow[1];
        this.errorMessage = `Value must be between ${low} and ${high}`;
      } else {
        this.errorMessage = `Value must be between ${this.low} and ${this.high}`;
      }
    }

    this.formGroup = new UntypedFormGroup(this.getDefaultFormGroup());

    this.applyData(this.timeSeries$.value);

    this.timeSeries$.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((ts) => {
        this.applyData(ts);
      });

    this.buildVisualisationData();

    // this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
    //   if (this.validateYearInputs()) {
    //     this.buildVisualisationData();
    //   }
    // });
  }

  protected initSelects(formFieldModel: FormFieldModel): void {
    //TSEdit.cpp 12644
    const calender = { value: "Calendar", label: "Calendar date" };
    const startSim = { value: "StartSim", label: "Since start of simulation" };
    const sprout = { value: "Sprout", label: "Years since plants sprouted" };
    const programmingName = this.timeSeries$.value?.$?.tInTS;

    const isSpecies = formFieldModel.spec !== 0;
    if (isSpecies) {
      this.dateTypeOptions = [calender, startSim, sprout];
    } else if (programmingName == "yieldTS") {
      this.dateTypeOptions = [sprout];
    } else {
      this.dateTypeOptions = [calender, startSim];
    }

    this.extrapolationOptions = [
      { value: "NearestYr", label: "Use nearest year in table" },
      { value: "CycleYrs", label: "Cycle table data across all time" },
      { value: "AvgYr", label: "Use average year of data" },
      { value: "AvgAllYrs", label: "Use average data across all time" },
    ];
  }

  private getDefaultFormGroup() {
    let data = {};
    const ofSpecies = this.formFieldModel.spec !== 0;
    const defaultOptions = { ...this.defaultOptions };

    defaultOptions.dateType.defaultValue = ofSpecies ? "Sprout" : "Calendar";
    defaultOptions.extrapolation.defaultValue = ofSpecies
      ? "NearestYr"
      : "AvgYr";
    defaultOptions.startYear.defaultValue = ofSpecies ? 0 : 2010;

    Object.entries(defaultOptions).forEach((entry) => {
      const f = entry[1];
      data[entry[0]] = new UntypedFormControl(
        f.defaultValue,
        f.validators.length ? f.validators : null
      );
    });

    return data;
  }

  private applyData(ts) {
    if (!ts) {
      this.formGroup.reset();
      this.rawData = undefined;
      this.formGroup.setValue(this.defaultOptions);
      return;
    }

    this.rawData = ts;
    if (ts.rawTS[0]._) {
      this.timeSeriesValues = ts.rawTS[0]._.split(",").map((d) => {
        if (d !== "" && !isNaN(+d.valueOf())) {
          return this.convertValueToViewBasedOnUnit(d);
        }
        return d;
      });
    } else {
      this.timeSeriesValues = [];
    }

    this.data = this.timeSeriesValues.map((d) => {
      if (d !== "" && !isNaN(+d.valueOf())) {
        return (+d).toFixed(+ts.$.nDecPlacesTS);
      }
      return d;
    });

    if (ts.$.yr0TS) {
      this.formGroup.get("startYear").setValue(+ts.$.yr0TS);
    }

    if (ts.$.nYrsTS) {
      this.formGroup.get("yearsOfData").setValue(+ts.$.nYrsTS);
    }

    if (ts.$.nDecPlacesTS) {
      this.formGroup.get("decimalPlaces").setValue(+ts.$.nDecPlacesTS);
    }
    if (ts.$.dataPerYrTS) {
      this.formGroup.get("dataPointsPerYear").setValue(+ts.$.dataPerYrTS);
    }
    if (ts.$.multTS) {
      this.formGroup.get("multiplier").setValue(+ts.$.multTS);
    }
    if (ts.$.tOriginTS) {
      this.formGroup.get("dateType").setValue(ts.$.tOriginTS);
    }
    if (ts.$.tExtrapTS) {
      this.formGroup.get("extrapolation").setValue(ts.$.tExtrapTS);
    }

    if (ts.$.showGraphTS !== null && ts.$.showGraphTS !== undefined) {
      this.formGroup
        .get("showGraph")
        .setValue(ts.$.showGraphTS == "true" || ts.$.showGraphTS == true);
    }

    if (this.validateYearInputs()) {
      this.updateDataDecimalPlaces(+this.formGroup.get("decimalPlaces").value);
      this.buildVisualisationData();
    }
  }

  private validateYearInputs(): boolean {
    return this.formGroup.valid;
  }

  private getMonthShortNames() {
    var format = new Intl.DateTimeFormat("au", { month: "short" });
    var months = [];
    for (let month = 0; month < 12; month++) {
      const d = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
      months.push({
        name: format.format(d),
        label: format.format(d),
        editable: true,
      });
    }
    return months;
  }

  private buildHeaders(dataPoints: number): VisualiserHeader[] {
    let headers = [];
    const defaultHeaders = [...new Array(dataPoints)].map((_, i) => {
      return {
        name: "Data" + (i + 1),
        label: "Data " + (i + 1),
        editable: true,
      };
    });
    switch (dataPoints) {
      case 1:
        headers = [{ name: "data", label: "Data", editable: true }];
        break;

      case 12:
        headers =
          this.formGroup.get("dateType").value == "Calendar"
            ? this.getMonthShortNames()
            : defaultHeaders;
        break;

      default:
        headers = defaultHeaders;
        break;
    }

    return [{ name: "Year", label: "Year", editable: false }, ...headers];
  }

  private buildRows({ data, dataPoints, yearsOfData, startYear, headers }) {
    //Divide array in equal parts
    // const equalPartIndex = Math.ceil(data.length / yearsOfData);
    this.dataForSave = [];

    return [...new Array(yearsOfData)].map((_, i) => {
      let rowData = {
        year: String(startYear + i),
      };

      let availableData = data.slice(
        i * dataPoints,
        i * dataPoints + dataPoints
      );
      if (availableData.length !== dataPoints) {
        [...new Array(dataPoints - availableData.length)].forEach(() => {
          availableData.push("");
        });
      }

      //to be improved, not a good place to do this
      this.dataForSave = [...this.dataForSave, ...availableData];

      [...new Array(dataPoints)].map((_, j) => {
        //skip Year header
        const d =
          availableData[j] || availableData[j] == 0 ? availableData[j] : "";
        rowData[headers[j + 1].name.toLowerCase()] = d;
      });

      return rowData;
    });
  }

  private buildChartData(rows, headers) {
    let chartData = {
      values: [],
      labels: [],
    };

    const headersWithoutYear = [...headers];
    headersWithoutYear.shift();
    // const rowsWithoutUnit = [...rows];
    // rowsWithoutUnit.shift();

    rows.forEach((row) => {
      const year = row[headers[0].name.toLowerCase()];
      headersWithoutYear.forEach((header) => {
        chartData.values.push(row[header.name.toLowerCase()]);
        chartData.labels.push(year + " " + header.label);
      });
    });
    return chartData;
  }

  public buildVisualisationData(): void {
    if (!this.validateYearInputs()) {
      return;
    }

    const dataPointsPerYear = this.formGroup.get("dataPointsPerYear").value;
    const yearsOfData = this.formGroup.get("yearsOfData").value;
    const startYear = this.formGroup.get("startYear").value;

    this.headers = this.buildHeaders(dataPointsPerYear);

    this.rows = this.buildRows({
      data: [...this.data],
      dataPoints: dataPointsPerYear,
      yearsOfData: yearsOfData,
      startYear: startYear,
      headers: this.headers,
    });

    const data = this.buildChartData(this.rows, this.headers);
    let chartOption = { ...this.defaultChartOption };
    chartOption.xAxis.data = data.labels;
    chartOption.series[0].data = data.values;
    chartOption.yAxis.name = this.unit;

    this.chartOption = chartOption;
  }

  public editCell(cell, rowIndex): void {
    this.editing[rowIndex + "-" + cell] = true;

    setTimeout(() => {
      const input: HTMLInputElement = document.querySelector(
        "#" + cell + rowIndex
      );
      if (input) {
        input.focus();
        input.select();
        this.editing.isInvalid = this.isInvalid(input.value);
      }
    }, 10);
  }

  public updateValue(event, cell, headerIndex, rowIndex): void {
    setTimeout(() => {
      const dataPointsPerYear = this.formGroup.get("dataPointsPerYear").value;
      const dataIndex: number = rowIndex * dataPointsPerYear + headerIndex - 1; //minors one for year column
      const updatedValue = +event.target.value;
      this.editing[rowIndex + "-" + cell] = false;

      if (
        event.target.value == "" ||
        this.isInvalid(updatedValue) ||
        isNaN(updatedValue.valueOf())
      ) {
        this.editing.isInvalid = false;
        //restore old value
        event.target.value = this.data[dataIndex];
        return;
      }
      //update timeSeries values and  data
      this.timeSeriesValues[dataIndex] = String(updatedValue);

      this.data[dataIndex] = updatedValue.toFixed(
        this.formGroup.get("decimalPlaces").value
      );
      this.buildVisualisationData();
    }, 1);
  }

  public validateValue(event): void {
    const updatedValue = +event.target.value;
    this.editing.isInvalid = this.isInvalid(updatedValue);
  }

  public save(): void {
    const defaultWinState = {
      $: {
        L: "10",
        T: "83",
        clientW: "702",
        clientH: "450",
        ws: "Normal",
      },
    };

    const editedData = {
      $: {
        multTS: this.formGroup.get("multiplier").value,
        showGraphTS: this.formGroup.get("showGraph").value,
        yr0TS: this.formGroup.get("startYear").value,
        nYrsTS: this.formGroup.get("yearsOfData").value,
        nDecPlacesTS: this.formGroup.get("decimalPlaces").value,
        dataPerYrTS: this.formGroup.get("dataPointsPerYear").value,
        tExtrapTS: this.formGroup.get("extrapolation").value,
        tOriginTS: this.formGroup.get("dateType").value,
      },
    };

    let data = {
      ...(this.rawData || {}),
      $: {
        ...(this.rawData?.$ || {}),
        ...editedData.$,
      },
      WinState: defaultWinState,
      rawTS: [
        {
          $: { count: this.dataForSave.length },
          _: this.convertValueToXmlBasedOnUnit(),
        },
      ],
    };

    this.timeSeries$.setValue(data);
    this.close();
  }

  public addDecimalPlaces(): void {
    const contaol = this.formGroup.get("decimalPlaces");
    const decimalPlaces = contaol.value;
    contaol.setValue(+decimalPlaces + 1);
    this.updateDataDecimalPlaces(+decimalPlaces + 1);
    this.buildVisualisationData();
  }

  public reduceDecimalPlaces(): void {
    const contaol = this.formGroup.get("decimalPlaces");
    const decimalPlaces = contaol.value;
    contaol.setValue(+decimalPlaces - 1);
    this.updateDataDecimalPlaces(+decimalPlaces - 1);
    this.buildVisualisationData();
  }

  protected updateDataDecimalPlaces(decimalPlaces: number): void {
    this.data = this.timeSeriesValues.map((d) =>
      d !== "" && d !== undefined && d !== null
        ? (+d).toFixed(decimalPlaces)
        : d
    );
  }

  public isInvalid(value): boolean {
    let high = this.high;
    let low = this.low;
    if (this.unit.indexOf("...") > -1) {
      const highLow = this.unit.split("...");
      low = +highLow[0];
      high = +highLow[1];
    }

    return +value > high || value < low;
  }

  protected convertValueToXmlBasedOnUnit(): string {
    if (!this.isPercentage && !this.isKgdm) {
      return this.timeSeriesValues.join(",");
    }

    return this.timeSeriesValues
      .map((d) =>
        String(d) !== "" && d !== undefined && d !== null
          ? String(+d / (this.isPercentage ? 100 : 1000))
          : String(d)
      )
      .join(",");
  }

  protected convertValueToViewBasedOnUnit(value) {
    if (this.isPercentage) {
      return +value * 100;
    }

    if (this.isKgdm) {
      return +value * 1000;
    }

    return +value;
  }

  protected convertHighLowBasedOnUnit(value) {
    if (this.isPercentage) {
      return +value * 100;
    }
    //legacy fullcam behaviour
    if (this.isKgdm) {
      return +value * 100;
    }

    return +value;
  }

  public close(): void {
    this.flyOverPanelService.closePanel();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
