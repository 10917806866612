import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LayoutService } from "src/app/layout/layout.service";
import { OutputInfo } from "src/app/plot/output-windows/outputInfo";
import { FlyOverPanelService } from "src/app/shared/components/fly-over-panel/services/fly-over-panel.service";
import { ExplorerService } from "src/app/shared/models";
import {
  ExplorerCatetory,
  ExplorerControl,
  SubcategoryMap,
} from "src/app/simulation/models";

@Component({
  selector: "fc-explorer-container",
  templateUrl: "./explorer-container.component.html",
  styleUrls: ["./explorer-container.component.scss"],
})
export class ExplorerContainerComponent implements OnInit, AfterViewInit {
  @Input() service: ExplorerService;
  public digestFormGroup: FormGroup = null;

  public categoryList: ExplorerCatetory[];
  public selectedControls: ExplorerControl[] = [];

  public prefixMap = {};

  constructor(
    private flyOverPanelService: FlyOverPanelService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.prefixMap = OutputInfo.getInputNameMapByIndex();

    if (this.service.simulationService.selectedPlotFormat$.value == "pld") {
      this.digestFormGroup =
        this.service.simulationService.plotDigestService.getFormGroup();
    }
    this.categoryList = this.service.getExplorerLayoutMap();
  }

  ngAfterViewInit(): void {
    this.layoutService.forceUpdateLayoutSizes();
  }

  public showControls(data: {
    subcategoryMap: SubcategoryMap;
    category: string;
    categoryKey: string;
    controlLabel: string;
  }) {
    this.selectedControls = this.getControlsFromMap(data.subcategoryMap).sort(
      (a: any, b: any) => a.model?.tIn - b.model?.tIn
    );
    this.service.setSelectedCategoryId(data.category);
    this.service.setSelectedCategoryKey({
      categoryKey: data.categoryKey,
      controlLabel: data.controlLabel,
    });
  }

  protected getControlsFromMap(
    subcategoryMap: SubcategoryMap
  ): ExplorerControl[] {
    let controls = [];
    Object.keys(subcategoryMap).forEach((key) => {
      if (key == "controls") {
        controls = [...controls, ...subcategoryMap[key]];
      } else {
        //as per current app
        // controls = [
        //   ...controls,
        //   ...this.getControlsFromMap(subcategoryMap[key]),
        // ];
        controls = [];
      }
    });
    return controls;
  }

  public openTimeSeriesPanel(control: ExplorerControl, service): void {
    const input = this.service.getTimeSeriesInputElement(
      control.service?.layout?.groups || [],
      control.programmingName
    );
    if (input) {
      this.flyOverPanelService.linkToComponent(
        input,
        control.formGroup,
        control.service
      );
    }
  }
}
