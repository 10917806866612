<table *ngIf="formGroup" [formGroup]="formGroup">
  <caption>
    {{
      caption
    }}
  </caption>
  <thead *ngIf="data.headers?.length">
    <tr>
      <ng-container *ngFor="let header of data.headers">
        <th scope="col" [id]="header" *ngIf="header">
          {{ header }}
        </th>
        <!--empty header accessiblity issue fix-->
        <td [id]="header" *ngIf="!header"></td>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data.rows; let rowIndex = i">
      <ng-container *ngFor="let d of row; let colIndex = i">
        <th
          scope="row"
          [id]="d.value"
          *ngIf="d.isHeader && d.element == 'text'; else formInput"
          [style]="d.width && { width: d.width, 'min-width': d.width }"
        >
          <ng-container *ngIf="d.value">
            {{ d.value }}
          </ng-container>
        </th>
        <ng-template #formInput>
          <td [headers]="data?.headers[colIndex] || ''">
            <span class="inline">
              <ng-container *ngIf="d.method && d.element == 'text'">
                <span
                  [style]="d.width && { width: d.width, 'min-width': d.width }"
                  class="d-block"
                  [class]="{
                    'is-invalid':
                      (d.method == 'getTotal' && isOverHundred(d.payload)) ||
                      (d.method == 'getRemaining' && isOverHundred(d.payload))
                  }"
                  >{{ dymamicMethod(d) }}</span
                >
              </ng-container>

              <ng-container *ngIf="d.element == 'checkbox'">
                <div
                  class="form-check"
                  [style]="d.width && { width: d.width, 'min-width': d.width }"
                >
                  <input
                    [formControlName]="d.programmingName"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    [id]="d.programmingName + '-checkbox'"
                  />
                  <label
                    class="form-check-label"
                    [for]="d.programmingName + '-checkbox'"
                  >
                    {{ d.label }}
                  </label>
                </div>
              </ng-container>

              <ng-container *ngIf="d.element == 'select'">
                <select
                  [id]="d.programmingName"
                  type="text"
                  [style]="d.width && { width: d.width, 'min-width': d.width }"
                  class="form-control form-select form-select-sm"
                  [formControlName]="d.programmingName"
                >
                  <option
                    *ngFor="let option of d.selectOptions"
                    [selected]="
                      formGroup.get(d.programmingName)?.value == option.value
                    "
                    [value]="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </ng-container>

              <!-- <span
              *ngIf="d.element == 'input'"
              [class]="{ inline: d.linkToComponent || d.suffixText }"
            > -->

              <ng-container *ngIf="d.element == 'input'">
                <label [for]="d.programmingName" class="visually-hidden"
                  >{{ row[0]?.element }}
                  {{ data?.headers ? data?.headers[colIndex] : "" }}</label
                >
                <input
                  [attr.disabled]="!isShown(d.programmingName) ? '' : null"
                  [id]="d.programmingName"
                  [style]="d.width && { width: d.width, 'min-width': d.width }"
                  class="form-control form-control-sm"
                  [type]="d.type"
                  [placeholder]="d.placeholder || ''"
                  [formControlName]="d.programmingName"
                />
              </ng-container>
              <button
                *ngIf="d.linkToComponent"
                [attr.aria-label]="
                  'link to component ' + d.linkToComponent.component
                "
                [id]="
                  'link-to-component-' +
                  colIndex +
                  rowIndex +
                  '-' +
                  d.linkToComponent.component
                "
                class="btn btn-outline-primary btn-sm link-component-data-visualiser-button"
                [class]="{
                  'link-component-data-visualiser-button':
                    d.linkToComponent.component == 'DataVisualiserComponent',
                  'link-component-data-line-chart-button':
                    d.linkToComponent.component == 'DecayRateComponent'
                }"
                (click)="linkToComponent(d.linkToComponent)"
              >
                <span
                  *ngIf="
                    d.linkToComponent.component == 'DataVisualiserComponent' ||
                    d.linkToComponent.component == 'SpeciesEventsComponent'
                  "
                >
                  View...</span
                >
                <span
                  *ngIf="d.linkToComponent.component == 'DecayRateComponent'"
                >
                  <i class="bi bi-graph-up"></i>
                </span>
              </button>
              <span *ngIf="d.suffixText">{{ d.suffixText }}</span>

              <fc-input-actions
                *ngIf="
                  simulationService?.selectedPlotFormat$.getValue() == 'pld'
                "
                [simulationService]="simulationService"
                [service]="service"
                [input]="getInput(d)"
                [item]="getItem(d)"
                [programmingName]="d.programmingName"
              ></fc-input-actions>
            </span>
            <fc-form-error-messages
              *ngIf="
                formGroup?.get(d.programmingName)?.errors &&
                (formGroup.get(d.programmingName).touched ||
                  formGroup.get(d.programmingName).dirty)
              "
              [formControlInstance]="formGroup.get(d.programmingName)"
              [label]="'This field'"
            ></fc-form-error-messages>
          </td>
        </ng-template>
      </ng-container>
    </tr>
  </tbody>
</table>
