<div class="logo-container">
  <img
    src="assets/images/DCCEEW_lores_inline_rev.png"
    aria-label="Department of Climate Change, Energy, the Environment and Water Government Crest"
    alt="Department of Climate Change, Energy, the Environment and Water Government Crest"
    class="logo"
  />
  <ng-container
    *ngIf="appService.getVersion() == 'rmt'; then rmt; else fullcam"
  ></ng-container>
  <span class="test-env">{{
    isTestEnv ? "TEST Environment" : "Beta Version"
  }}</span>
</div>

<div *ngIf="isPrivacyAccepted == true">
  <div class="user-details-container">
    <div class="user-details">
      <span>{{ userClaim?.given_name }} {{ userClaim?.family_name }}</span>
      <span>Standard User</span>
    </div>

    <button
      role="button"
      data-bs-toggle="dropdown"
      class="btn user-profile-dropdown"
      aria-label="user profile dropdown toggle"
      id="user-profile"
    >
      <fc-avatar
        [firstName]="userClaim?.given_name"
        [lastName]="userClaim?.family_name"
      ></fc-avatar>
    </button>
    <ul class="dropdown-menu">
      <li class="dropdown-item">
        <a aria-label="Sign out link" [routerLink]="" (click)="signOut()">
          <i class="bi bi-box-arrow-in-right"></i>Sign out
        </a>
      </li>

      <li class="dropdown-item">
        <a
          [routerLink]="['/view-privacy']"
          aria-label="view privacy link"
          routerLinkActive="router-link-active"
        >
          <i class="bi bi-eye-fill role-a"></i>Privacy Terms
        </a>
      </li>

      <li>
        <a
          [routerLink]="['/user-profile']"
          aria-label="User profile link"
          routerLinkActive="router-link-active"
        >
          <i class="bi bi-person-lines-fill role-a"></i>User profile
        </a>
      </li>
      <li class="dropdown-item">
        <a
          (click)="openAboutFullcamModel(); $event.preventDefault()"
          aria-label="about fullCAM link"
          routerLinkActive="router-link-active"
        >
          <i class="bi bi-info-circle role-a"></i>About FullCAM
        </a>
      </li>
    </ul>
  </div>
  <div class="help-container" *ngIf="isPrivacyAccepted == true">
    <a class="btn" [href]="baseHelpPageUrl + '/index.htm'" target="_blank">
      HELP<i class="bi bi-question-circle-fill"></i>
    </a>
  </div>
</div>

<ng-template #fullcam>
  <span class="app-title"
    >Full Carbon <br />
    Accounting Model
    {{
      appService.getVersion() ? "(" + appService.getVersion() + ")" : ""
    }}</span
  >
</ng-template>

<ng-template #rmt>
  <span class="app-title rmt-app-title">
    Reforestation Modelling Tool (RMT) <br />
    <span class="sub-title">Model your carbon estimation area (CEA)</span>
  </span>
</ng-template>
