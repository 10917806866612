import { Injectable } from "@angular/core";
import { FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FieldWatcher, FormModel } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class ExternalIncome2020Service extends BaseEventFormService {
  public readonly categoryIds = [92, 71];
  public readonly pageHelpLink = "";

  public layout: FormLayout = {
    label: "External income",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Description",
            inputs: [
              {
                element: "input",
                type: "text",
                programmingName: "descXInc",
              },
            ],
          },
          {
            label: "One-time income",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "oneTimeXInc",
              },
            ],
          },
          {
            id: "fixXInc",
            label: "Fixed [$]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fixXInc",
              },
            ],
          },

          {
            id: "phaXInc",
            label: "Per hectare [$/ha]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "phaXInc",
              },
            ],
          },

          {
            label: "On-going income (new level)",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "onGoingXInc",
              },
            ],
          },
          {
            id: "fixPyrXInc",
            label: "Fixed [$ per year]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fixPyrXInc",
              },
            ],
          },
          {
            id: "phaPyrXInc",
            label: "Per hectare [$/ha per year]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "phaPyrXInc",
              },
            ],
          },
          {
            id: "codeXInc",
            label: "Code (to identify the type of cost to future cost events)",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "codeXInc",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
    },
    tEvent: {
      defaultValue: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
    },
    descXInc: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 3,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Description of external income",
      unit: "",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    oneTimeXInc: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "External income has a one-time part",
      unit: "",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    onGoingXInc: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "External income has a on-going part",
      unit: "",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    fixXInc: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Fixed portion of one-time external income",
      unit: "$",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    phaXInc: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Per-hectare portion of one-time external income",
      unit: "$/ha",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    fixPyrXInc: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Fixed portion of on-going external income",
      unit: "$/yr",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    phaPyrXInc: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Per-hectare portion of on-going external income",
      unit: "$/ha/yr",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    codeXInc: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 3,
      helpId: 262,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Code for identifying an on-going external income",
      unit: "",
      categoryName: "EvXInc",
      categoryLabel: "External income event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
  };

  public fieldWatcher: FieldWatcher = {
    oneTimeXInc: (newValue: any, { formGroup, formModel }) => {
      formModel["fixXInc"].isShown = newValue;
      formModel["phaXInc"].isShown = newValue;
    },
    onGoingXInc: (newValue: any, { formGroup, formModel }) => {
      formModel["fixPyrXInc"].isShown = newValue;
      formModel["phaPyrXInc"].isShown = newValue;
      formModel["codeXInc"].isShown = newValue;
    },
  };
}
