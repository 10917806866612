import { Injectable } from "@angular/core";
import { FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FormModel } from "src/app/shared/models";
import { Constants } from "src/app/shared/constants";

@Injectable({
  providedIn: "root",
})
export class AgriculturalPlowing2020Service extends BaseEventFormService {
  public readonly categoryIds = [78, 71];
  public readonly pageHelpLink = "164_Plough.htm";

  public layout: FormLayout = {
    label: "Plow",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: "Percentage of agricultural system that is plowed",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctPlow",
              },
            ],
          },
        ],
      },

      // {
      //   label: "Cost",
      //   isShown: true,
      //   isRoot: true,
      //   id: "cost-group",
      //   showErrors:true,
      // items: [
      //     {
      //       label: "Fixed [$]",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "number",
      //           programmingName: "fixPlow",
      //         },
      //       ],
      //     },
      //     {
      //       label: "Per hectare [$/ha]",
      //       inputs: [
      //         {
      //           element: "input",
      //           type: "number",
      //           programmingName: "phaPlow",
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        label: "Destination percentages emitted to Atmosphere",
        isShown: true,
        isRoot: true,
        showErrors: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "FormItemTableComponent",
                componentInputs: [
                  {
                    inputKey: "caption",
                    value: "Destination percentages emitted to Atmosphere",
                  },
                  {
                    inputKey: "service",
                    method: "getService",
                  },
                  {
                    inputKey: "data",
                    value: {
                      headers: ["", "Decomposable"],
                      rows: [
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "DPM",
                          },

                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracDpmaToAtmsPlowA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "RPM",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracRpmaToAtmsPlowA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "BIO-F",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracBiofToAtmsPlowA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "BIO-S",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracBiosToAtmsPlowA",
                          },
                        ],
                        [
                          {
                            element: "text",
                            isHeader: "true",
                            value: "HUM",
                          },
                          {
                            element: "input",
                            type: "number",
                            programmingName: "fracHumToAtmsPlowA",
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecA",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    fracAfctPlow: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 164,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "agricultural system that is plowed",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 1191,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fixPlow: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 164,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "plowing event",
      unit: "$",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2062,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaPlow: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 164,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "plowing event",
      unit: "$/ha",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2063,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracDpmaToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "DPM",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2148,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracRpmaToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "RPM",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2149,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracBiofToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-F",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2150,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracBiosToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "BIO-S",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2151,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    fracHumToAtmsPlowA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "DPM",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2152,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 178,
    },
    recoveryFracA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 193,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Perennial Recovery Fraction",
      unit: "%",
      categoryName: "EvPlow",
      categoryLabel: "Agricultural plowing event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 4,
      tIn: 2153,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "Plow",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    impactMultiPlow: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    sPDecayMultiPlow: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    durationPlow: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    dTFracGbfrSoilA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    dTFracStlkSoilA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    dTFracLeafSoilA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    dTFracCortSoilA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
    dTFracFirtSoilA: {
      dataType: 0,
      defaultValue: null,
      suffixId: null,
    },
  };
  public getFormModel() {
    return this.formModel;
  }

  // public fieldWatcher: FieldWatcher = {
  //   fracAfctPlow: (newValue: any, { formGroup, formModel }) => {
  //     formGroup.get("clearEV").setValue(newValue == 100);
  //   },
  // };

  private isClearingEventPlow(formValue) {
    const k1Minus = Constants.k1Minus * 100;
    return +formValue.fracAfctPlow >= k1Minus;
  }

  public modifyBeforeSave(formGroup) {
    if (!formGroup) {
      return null;
    }

    let modifiedFormGroup = formGroup;
    if (modifiedFormGroup.get("clearEV")) {
      modifiedFormGroup
        .get("clearEV")
        .setValue(this.isClearingEventPlow(modifiedFormGroup.getRawValue()));
    }
    return modifiedFormGroup;
  }

  public writeXmlObject(formData): object {
    const basePlowFields = [
      "fracAfctPlow",
      "fixPlow",
      "phaPlow",
      "fracDpmaToAtmsPlowA",
      "fracRpmaToAtmsPlowA",
      "fracBiofToAtmsPlowA",
      "fracBiosToAtmsPlowA",
      "fracHumToAtmsPlowA",
      "impactMultiPlow",
      "sPDecayMultiPlow",
      "durationPlow",
      "dTFracGbfrSoilA",
      "dTFracStlkSoilA",
      "dTFracLeafSoilA",
      "dTFracCortSoilA",
      "dTFracFirtSoilA",
    ];
    const xmlObject = {
      $: {},
      notesEV: [],
      Plow: [{ $: {} }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.Plow[0].$ = this.getFormValueObjectForXmlExport(
      basePlowFields,
      formData
    );

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
