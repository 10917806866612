<ng-container *ngIf="spatialData$ | async as spatialData; else noData">
  <p>State = {{ spatialData.SiteInfo[0]?.state }}</p>
  <p>
    SA2 = {{ spatialData.SiteInfo[0]?.sa2Name }}({{
      spatialData.SiteInfo[0]?.SA2
    }})
  </p>
  <p>
    NPI Region =
    {{
      spatialData.SiteInfo[0]?.npi && spatialData.SiteInfo[0].npi !== "Blank"
        ? spatialData.SiteInfo[0].npi
        : "No Region"
    }}
  </p>
  <p>Growth Region = {{ spatialData.SiteInfo[0]?.growthRegion }}</p>

  <h3>Inputs from FullCAM server:</h3>
  <ol>
    <li *ngIf="spatialData?.LocnSoil[0]">Soil data</li>
    <li *ngFor="let data of spatialData?.InputElement || []">
      {{ descriptionMap[data.tIn] }}
    </li>

    <li *ngFor="let item of spatialData?.ItemList || []">
      {{ item.id == "FrSpecies" ? "Tree species" : "Crop species" }}
    </li>
  </ol>
</ng-container>

<ng-template #noData>No spatial data from FullCAM</ng-template>
