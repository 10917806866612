import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import { DataTablePage, FolderList, PlotTableColumns } from "./models";
import { ModalService } from "../shared/services/modal.service";
import { Constants } from "../shared/constants";
import { MyPlotsService } from "./services/my-plots.service";
import { take, takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject, lastValueFrom, Observable } from "rxjs";
import { MessageService } from "../shared/services/message.service";
import { AuthService } from "../auth/services/auth.service";
import { PlotUploadComponent } from "../shared/components/plot-upload/plot-upload.component";
import { ModalOptions } from "ngx-bootstrap/modal";
import { LayoutService } from "../layout/layout.service";
import { AppService } from "../services/app.service";
import { SimulationService } from "../shared/models";

@Component({
  selector: "fc-my-plots",
  templateUrl: "./my-plots.component.html",
  styleUrls: ["./my-plots.component.scss"],
})
export class MyPlotsComponent implements OnInit, AfterViewInit {
  private readonly roleSub$ = new Subject<void>();

  @Input()
  selectPlotFilesOnly: boolean = false;
  //work together with selectPlotFilesOnly
  @Input()
  selectedPlotFiles = [];

  @Input()
  forceStatusFilter;

  @Input()
  forcePlotTypeFilter;

  @Input()
  forceProjectCollectionFilter;

  @Output()
  public plotIdSeleceted = new EventEmitter<string>();

  //for multiple files select
  @Output()
  public plotFilesSeleceted = new EventEmitter<PlotTableColumns[]>();

  @ViewChild("plotTable") plotTable: DatatableComponent;

  public readonly defaultPage: DataTablePage = {
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    offset: 0,
  };
  public forceFilter = false;

  public isLoading: boolean = false;
  public isBulkActionLoading$ = new BehaviorSubject<boolean>(false);
  public selectedBulkAction: "delete" | "export" = "export";
  public selectedPlotTypeFilter: "all" | "plo" | "pld" | "est" = "all";

  public page = new DataTablePage();
  public selectedRows: PlotTableColumns[] = [];
  public rows: PlotTableColumns[] = [];

  public folders$: Observable<FolderList[]>;

  public statusFilter: "complete" | "pending" | null = null;

  public projectCollectionFilter: {
    collection: string;
    collectionId: string;
    project: string;
    projectId: string;
  } = null;

  public searchString: string = "";
  public searchFilter: string = "";

  readonly ColumnMode = ColumnMode;
  readonly SelectionType = SelectionType;

  public readonly acceptedFileTypes: string = Constants.PLOT_FORMATS.join(",");

  public simulationService: SimulationService = null;
  public appVersion: string = "";

  constructor(
    private myPlotsService: MyPlotsService,
    private modalService: ModalService,
    private messageService: MessageService,
    private authService: AuthService,
    public layoutService: LayoutService,
    public appService: AppService
  ) {
    this.page = { ...this.defaultPage };
  }

  async ngOnInit(): Promise<void> {
    this.simulationService = this.appService.getSimulationService();
    this.appVersion = this.simulationService.version;

    if (this.forceStatusFilter || this.forcePlotTypeFilter) {
      this.forceFilter = true;
      this.statusFilter = this.forceStatusFilter;
      this.selectedPlotTypeFilter = this.forcePlotTypeFilter || "all";
    }

    if (!this.forceProjectCollectionFilter) {
      this.folders$ = this.simulationService.aboutService.getFolders();
    } else {
      this.projectCollectionFilter = this.forceProjectCollectionFilter;
      //  this.filterPlotFiles();
    }

    this.authService.userRole$
      .pipe(takeUntil(this.roleSub$))
      .subscribe(async (role) => {
        if (role) {
          await this.getFolders();

          this.roleSub$.next();
          this.roleSub$.complete();
        }
      });
  }

  ngAfterViewInit(): void {
    this.layoutService.forceUpdateLayoutSizes();
  }

  private async getFolders(): Promise<void> {
    await this.simulationService.aboutService.loadFolders();
    this.filterPlotFiles();
    // this.myPlotsService
    //   .getList("folder")
    //   .pipe(take(1))
    //   .subscribe((f) => {
    //     if (f) {
    //       this.simulationService.aboutService.setFolders(f);
    //       this.folders = f;
    //       this.filterPlotFiles();
    //     }
    //   });
  }

  public setProjectCollectionFilter(event): void {
    if (
      event.parent?.projectId == this.projectCollectionFilter?.projectId &&
      event.collectionId == this.projectCollectionFilter?.collectionId
    ) {
      return;
    }

    this.projectCollectionFilter = {
      project: event.parent.name,
      projectId: event.parent.projectId,
      collection: event.name,
      collectionId: event.collectionId,
    };

    this.filterPlotFiles();
  }

  public removeProjectCollectionFilter(): void {
    this.projectCollectionFilter = null;
    this.filterPlotFiles();
  }

  public setSearchString(): void {
    if (this.searchString.length < 2) {
      return;
    }

    this.searchFilter = this.searchString;
    this.searchString = "";
    this.filterPlotFiles();
  }

  public removeSearchFilter(): void {
    this.searchFilter = "";
    this.filterPlotFiles();
  }

  public filterPlotFiles(pageInfo?) {
    //reset page
    this.page = { ...this.defaultPage, ...pageInfo };

    this.setPage(null, {
      ...(this.projectCollectionFilter && {
        projectId: this.projectCollectionFilter.projectId,
      }),
      ...(this.projectCollectionFilter && {
        collectionId: this.projectCollectionFilter.collectionId,
      }),

      ...(this.searchFilter?.length > 1 && { search: this.searchFilter }),
      ...(this.statusFilter && {
        status: this.statusFilter,
      }),
      ...(this.selectedPlotTypeFilter !== "all" && {
        plotType: this.selectedPlotTypeFilter,
      }),
    });
  }

  public async deleteFolder(row): Promise<void> {
    const type = row.collectionId ? "collection" : "project";
    const folderMessage =
      type == "collection"
        ? "collection and ALL the plot files under this folder"
        : "project and ALL the collections and plot files under this folder";
    const message = `Are you sure, you want to delete '${row.name}' ${folderMessage}?`;
    const confirm = await this.modalService.openConfirmModal(message);

    if (confirm) {
      this.myPlotsService
        .deleteFolders(type, [
          String(row[type == "collection" ? "collectionId" : "projectId"]),
        ])
        .pipe(take(1))
        .subscribe(async () => {
          await this.getFolders();
        });
    }
  }

  public setPage(pageInfo?, filters?) {
    if (pageInfo) {
      this.page = pageInfo;
    }

    this.myPlotsService
      .getList("plot", {
        pageSize: this.page.pageSize,
        pageNumber: this.page.offset + 1,
        ...filters,
      })
      .pipe(take(1))
      .subscribe((response) => {
        if (!response) {
          this.messageService.addAlert({
            type: "danger",
            msg: "Something went wrong while getting the plot files.",
            dismissible: true,
          });
          return;
        }
        this.rows = response.data;
        this.page = response.page;
      });
  }

  public onSelect({ selected }): void {
    this.selectedRows.splice(0, this.selectedRows.length);
    this.selectedRows.push(...selected);
  }

  public deselectAll(): void {
    this.onSelect({ selected: [] });
    this.plotTable.selected = [];
  }

  public onActivate(event) {}

  public async editPlotFile(row: PlotTableColumns): Promise<void> {
    this.messageService.setFullScreenLoading(true);
    const plotFileResults = await lastValueFrom(
      this.myPlotsService.downloadPlotFiles([row.plotId]).pipe(take(1))
    );
    const plotFile = plotFileResults[0].result;

    const plotFileJson =
      await this.simulationService.helperService.convertXmlToJson(plotFile);

    //intercept project and collection id because it cannot be modified after created at the moment 5/9/2022
    if (!this.simulationService.aboutService.formGroup) {
      this.simulationService.aboutService.getFormGroup();
    }

    this.simulationService.aboutService.formGroup
      .get("project")
      .setValue(row.project);
    this.simulationService.aboutService.formGroup
      .get("collection")
      .setValue(row.collection);

    this.simulationService.setPlotMetaData(row);
    this.simulationService.processImportedPlotFile(plotFileJson);
    this.messageService.setFullScreenLoading(false);
  }

  public async clonePlotFile(row: PlotTableColumns): Promise<void> {
    this.messageService.setFullScreenLoading(true);
    const plotfileResults = await lastValueFrom(
      this.myPlotsService.downloadPlotFiles([row.plotId]).pipe(take(1))
    );

    const plotFile = plotfileResults[0].result;

    let plotFileJson =
      await this.simulationService.helperService.convertXmlToJson(plotFile);

    //intercept file name to indicate cloned plot file
    const doctype = Object.keys(plotFileJson)[0];
    const fileName = plotFileJson[doctype]?.Meta[0].$?.nmME;

    plotFileJson[doctype].Meta[0].$.nmME =
      fileName +
      " cloned at " +
      this.simulationService.helperService.getTodayDateTimeForFileName();

    //intercept project and collection id because it cannot be modified after created at the moment 5/9/2022
    if (!this.simulationService.aboutService.formGroup) {
      this.simulationService.aboutService.getFormGroup();
    }

    this.simulationService.aboutService.formGroup
      .get("nmME")
      .setValue(row.fileName || null);

    this.simulationService.aboutService.formGroup
      .get("project")
      .setValue(row.project);
    this.simulationService.aboutService.formGroup
      .get("collection")
      .setValue(row.collection);

    this.simulationService.isClonedPlotFile = true;
    this.simulationService.setPlotMetaData(row);
    this.simulationService.processImportedPlotFile(plotFileJson);
    this.messageService.setFullScreenLoading(false);
  }

  public async deletePlotFiles(rows: PlotTableColumns[]): Promise<void> {
    const fileNames =
      rows.length == 1 ? rows[0].fileName : rows.length + " plot files";
    const ids = rows.map((r) => String(r.plotId));
    const message = `Are you sure, you want to delete ${fileNames}?`;
    const confirm = await this.modalService.openConfirmModal(message);

    if (confirm) {
      this.myPlotsService
        .deletePlotFiles(ids)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isBulkActionLoading$.next(false);
            this.filterPlotFiles();
            this.deselectAll();
          },
          error: (error) => {
            this.messageService.addAlert({
              type: "danger",
              msg: "Something went wrong while deleting files, please try again later.",
              dismissible: true,
            });
            this.isBulkActionLoading$.next(false);
          },
        });
    }
  }

  public bulkAction() {
    this.isBulkActionLoading$.next(true);
    const ids = this.selectedRows.map((row) => String(row.plotId));

    if (this.selectedBulkAction == "delete") {
      this.deletePlotFiles(this.selectedRows);
    } else if (this.selectedBulkAction == "export") {
      this.myPlotsService
        .downloadPlotFiles(ids)
        .pipe(take(1))
        .subscribe((xmlPlotsResults) => {
          this.isBulkActionLoading$.next(false);

          xmlPlotsResults.forEach((xmlPlotResult, index) => {
            const plotRow = this.selectedRows[index];
            this.simulationService.helperService.downloadFile(
              plotRow.fileName,
              xmlPlotResult.result,
              plotRow.fileType
            );
          });
          this.deselectAll();
        });
    }
  }

  public uploadPlotFile() {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        projectCollectionFilter: this.projectCollectionFilter,
        folders$: this.folders$,
      },
    };

    this.modalService
      .openModal(PlotUploadComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe(() => {
        if (!this.simulationService.isUploading$.getValue()) {
          this.simulationService.resetFileUpload();
        }
        this.filterPlotFiles();
      });
  }

  public async onFileSelected(event) {
    const file = event.target.files[0];

    if (file) {
      this.messageService.setFullScreenLoading(true);
      setTimeout(async () => {
        const plotFile = await this.simulationService.helperService
          .readFile(file)
          .catch((alertMessage) => {
            this.messageService.addAlert(alertMessage);
            this.messageService.setFullScreenLoading(false);
          });
        if (!plotFile) {
          return;
        }
        const plotFileJson =
          await this.simulationService.helperService.convertXmlToJson(plotFile);

        this.simulationService.isClonedPlotFile = true;
        this.simulationService.processImportedPlotFile(plotFileJson);
        this.messageService.setFullScreenLoading(false);
      }, 100);
    }
  }

  public async addToPlotFileList(all = false) {
    let selectedRows: PlotTableColumns[];

    if (all) {
      selectedRows = await this.getFullListFromCollection();
    } else {
      selectedRows = this.selectedRows;
    }

    this.plotFilesSeleceted.emit(selectedRows);
    setTimeout(() => {
      this.deselectAll();
    }, 0);
  }

  protected async getFullListFromCollection(): Promise<PlotTableColumns[]> {
    const response = await lastValueFrom(
      this.myPlotsService
        .getList("plot", {
          projectId: this.projectCollectionFilter.projectId,
          collectionId: this.projectCollectionFilter.collectionId,
          plotType: this.selectedPlotTypeFilter,
          size: 10000,
        })
        .pipe(take(1))
    );

    return response.data;
  }

  public isPlotInSelection(id) {
    if (!this.selectPlotFilesOnly) {
      return false;
    }
    const foundIndex = this.selectedPlotFiles.findIndex((p) => p.plotId == id);
    return foundIndex > -1;
  }

  //override selectFn
  public selectPlotFilesOnlySelectFn(event) {
    const isChecked = event.target.checked;
    if (isChecked) {
      const selectedPlotIds = this.selectedPlotFiles.map((p) => p.plotId);
      const rows = this.plotTable.rows;
      const filteredSelectedRows = rows.filter(
        (r) => !selectedPlotIds.includes(r.plotId)
      );
      this.selectedRows = [...filteredSelectedRows];
      this.plotTable.selected = [...filteredSelectedRows];
    } else {
      this.selectedRows = [];
      this.plotTable.selected = [];
    }
  }

  public selectPlotFilesOnlyAllRowsSelected(): boolean {
    //view is not ready
    if (!this.plotTable) {
      return false;
    }
    const rows = this.plotTable.rows;
    const selectedPlotIds = this.selectedPlotFiles.map((p) => p.plotId);
    const filteredSelectedRows = rows.filter(
      (r) => !selectedPlotIds.includes(r.plotId)
    );
    if (!filteredSelectedRows.length) {
      return false;
    }

    return filteredSelectedRows.length == this.selectedRows.length;
  }
}
