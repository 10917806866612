import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormArray } from "@angular/forms";
import { BehaviorSubject, lastValueFrom, Observable, Subject } from "rxjs";
import { pairwise, startWith, take, takeUntil, tap } from "rxjs/operators";
import { CropSpeciesMetaData, TreeSpeciesMetadata } from "../../models";
import { ModalService } from "../../services/modal.service";

@Component({
  selector: "fc-species-list",
  templateUrl: "./species-list.component.html",
  styleUrls: ["./species-list.component.scss"],
})
export class SpeciesListComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  @Input() selectedSpecies: UntypedFormArray = new UntypedFormArray([]);

  @Input() availableSpecies$: Observable<Array<any>>;

  @Input() initialSpeciesId$: BehaviorSubject<string>;

  @Input() type: "tree" | "crop";

  @Output()
  speciesSelected = new EventEmitter<{
    species: CropSpeciesMetaData | TreeSpeciesMetadata;
    type: "tree" | "crop";
  }>();

  @Output()
  speciesRemoved = new EventEmitter<{
    id: number;
    idRegimeSP: number;
    type: "tree" | "crop";
  }>();

  @Output()
  initialSpeciesSelected = new EventEmitter<{
    idRegimeSP: string;
    type: "tree" | "crop";
    speciesName: string;
  }>();

  // @Output()
  // initialSpeciesRemoved = new EventEmitter<"tree" | "crop">();

  public namespace: "SpeciesForest" | "SpeciesAgriculture";

  public selectedSpecies$: Observable<any>;
  public filteredSpecies$ = new BehaviorSubject<any[]>([]);

  public selectedTypeAheadModel: CropSpeciesMetaData | TreeSpeciesMetadata | "";
  public loadingSpeciesName: string = null;

  constructor(private modalService: ModalService) {}

  async ngOnInit(): Promise<void> {
    this.namespace =
      this.type == "tree" ? "SpeciesForest" : "SpeciesAgriculture";

    this.selectedSpecies.valueChanges
      .pipe(takeUntil(this.destroy$), startWith([]), pairwise())
      .subscribe(async ([oldSpecies, species]) => {
        //this.initialSpeciesId$.getValue()>-1 is to deal with when importing plot files and downlaod spatial data, it will update species idRegimeSP
        if (
          !oldSpecies.length &&
          species.length == 1 &&
          species[0][this.type + "ExistsInit"] &&
          !(+this.initialSpeciesId$.getValue() > -1)
        ) {
          const confirm = await this.confirmInitSpecies(
            this.type,
            species[0].nmSP
          );
          if (confirm) {
            this.setInitialSpecies(species[0].idRegimeSP, species[0].nmSP);
          }
        }

        this.loadingSpeciesName = null;

        this.filterSpecies(
          await lastValueFrom(this.availableSpecies$.pipe(take(1)))
        );
      });

    this.availableSpecies$
      .pipe(
        takeUntil(this.destroy$),
        tap((availableSpecies) => {
          this.filterSpecies(availableSpecies);
        })
      )
      .subscribe();
  }

  protected moveIndex(input, from, to): void {
    let numberOfDeletedElm = 1;

    const elm = input.splice(from, numberOfDeletedElm)[0];

    numberOfDeletedElm = 0;

    input.splice(to, numberOfDeletedElm, elm);
  }

  protected filterSpecies(availableSpecies): void {
    if (!availableSpecies) {
      return;
    }
    const selectedSpeciesList: string[] = this.selectedSpecies
      ? this.selectedSpecies.controls.map((c) => c.get("nmSP").value)
      : [];
    const filteredSpecies = availableSpecies
      .filter((as) => !selectedSpeciesList.includes(as.value))
      .sort((a, b) => a.value.localeCompare(b.value));
    this.filteredSpecies$.next(filteredSpecies);
  }

  public async addSpecies(species): Promise<void> {
    this.loadingSpeciesName = species.value;
    this.speciesSelected.emit({ species, type: this.type });
  }

  public removeSpecies(species): void {
    const idRegimeSP = species.get("idRegimeSP").value;
    // const initialId = this.initialSpeciesId$.getValue();
    // if (dbId == initialId) {
    //   this.initialSpeciesRemoved.emit(this.type);
    // }

    this.speciesRemoved.emit({
      id: species.get("idSP").value,
      idRegimeSP,
      type: this.type,
    });
  }

  public setInitialSpecies(idRegimeSP, speciesName) {
    this.initialSpeciesSelected.emit({
      idRegimeSP: idRegimeSP,
      type: this.type,
      speciesName: speciesName,
    });
  }

  public onAutoCompleteSelect(event): void {
    this.addSpecies(event.item);
    this.selectedTypeAheadModel = "";
  }

  private async confirmInitSpecies(
    type,
    speciesName: string
  ): Promise<boolean> {
    const content = `Make '${speciesName}' the initial ${type} species?\n\n
        That is, make it the species that was last growing in the ${
          type == "tree" ? "forest" : "agricultural"
        } system\n
        at the start of the simulation?\n\n
        Regardless of whether there ${
          type == "tree" ? "are trees" : "is a crop"
        } growing at the start of the simulation,\n
        making one of the downloaded ${type} species the initial species is\n
        highly recommended.\n`;

    return await this.modalService.openConfirmModal(
      content,
      null,
      "model-confirm-yes"
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
