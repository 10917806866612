import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";

@Injectable({
  providedIn: "root",
})
export class RotationPestAndDiseaseService extends BaseRotationEventService {
  private percentageTreesAffected = 0.0;

  public _type = "RotationPestAndDisease";

  public getIntensity() {
    if (this.regimeEventReference.Value?.FullCAMThin.fracAfctThin) {
      this.percentageTreesAffected =
        this.regimeEventReference.Value.FullCAMThin.fracAfctThin;
    }

    return `Percentage Trees Affected: ${(
      this.percentageTreesAffected * 100
    ).toFixed(2)}%`;
  }
}
