import { Component, OnInit, Input } from "@angular/core";
import { IOutputListCategory, IOutputWindow } from "../../models";
import { OutputWindowsService } from "src/app/shared/models";

@Component({
  selector: "fc-output-list",
  templateUrl: "./output-list.component.html",
  styleUrls: ["./output-list.component.scss"],
})
export class OutputListComponent implements OnInit {
  @Input() service: OutputWindowsService;

  public outputTree: IOutputListCategory[] = [];
  constructor() {}

  ngOnInit(): void {}

  public getActiveWindow(): IOutputListCategory[] {
    return this.service.getActiveWindow()?.get("outputTree").value;
  }

  public shouldDisplay(): boolean {
    const activeWindow = this.service.getActiveWindow();

    if (!activeWindow?.get("outputTree").value) {
      return false;
    }
    if (activeWindow?.get("isScenarioView")?.value) {
      return false;
    }
    return true;
  }
}
