import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { FlyOverPanelService } from "../fly-over-panel/services/fly-over-panel.service";

@Component({
  selector: "fc-decay-rate",
  templateUrl: "./decay-rate.component.html",
  styleUrls: ["./decay-rate.component.scss"],
})
export class DecayRateComponent implements OnInit {
  isViewOnly = false;
  incomingFieldControl: AbstractControl;
  incomingInput: any = null;

  public title: string = "";
  public inputVariables: {
    category: string;
    species: string;
    event: string;
    unit: string;
  };

  private readonly destroy$ = new Subject<void>();

  public formGroup = new UntypedFormGroup({
    decayYr: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(100),
    ]),
    decayMo: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(100),
    ]),
    decayDay: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(100),
    ]),
    remainYr: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(100),
    ]),
    remainMo: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(100),
    ]),
    remainDay: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(100),
    ]),
    halfYr: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(800000),
    ]),
    halfMo: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(9600000),
    ]),
    halfDay: new UntypedFormControl("", [
      Validators.min(0),
      Validators.max(292200000),
    ]),
  });

  readonly lnOfHalf = Math.log(0.5);

  readonly kfDaysPerYr = 365.25;
  readonly halfLifeHi = 800000.0;
  readonly halfLifeLo = 0.0;
  readonly rateHi = 1.0;
  readonly rateLo = 0.0;
  readonly defaultMaxRate = 0.999999998;
  readonly inputScale = 2;

  hi = 1;
  lo = 0;
  readonly ratePerYearType = 0;
  readonly halfLifeTpe = 1;
  readonly rateScale = 100.0;
  readonly halfLifeScale = 1.0;
  // ratePrecision = 9;
  // halfLifePrecision = 9;
  //inputTypes = 2;

  readonly k0Plus = 0.000000001; // 1.0e-9
  //k0PlusM = 0.00000000001; // k0Plus minus a bit! Definitely < k0Plus. 1.0e-11
  //kReadFlo = "%lg";
  kfBig = 1e36; // Huge floating point number (assumes flo >= f32)
  k0Minus = -this.k0Plus;
  k1Plus = 1.0 + this.k0Plus;
  k1Minus = 1.0 - this.k0Plus;
  kPi = 3.14159265359;

  isHalfLife = false;

  readonly kddDecayYr = 0;
  readonly kddDecayMo = 1;
  readonly kddDecayDay = 2;
  //   Amount remaining after a year, month, or day
  readonly kddRemainYr = 3;
  readonly kddRemainMo = 4;
  readonly kddRemainDay = 5;
  //   Half life in terms of years, months, or days
  readonly kddHalfYr = 6;
  readonly kddHalfMo = 7;
  readonly kddHalfDay = 8;

  public tDesc = -1;
  public inputType = -1;

  private readonly inputTypeMap = {
    decayYr: 0,
    decayMo: 1,
    decayDay: 2,
    remainYr: 3,
    remainMo: 4,
    remainDay: 5,
    halfYr: 6,
    halfMo: 7,
    halfDay: 8,
  };

  private readonly limitsTableMap = [
    [
      [0.000001, 0.99999999998, this.rateScale],
      [0.000000084, 0.871, this.rateScale],
      [0.00000000274, 0.065, this.rateScale],
      [0.00000000003, 0.999999, this.rateScale],
      [0.13, 0.99999916, this.rateScale],
      [0.935, 0.9999999972, this.rateScale],
      [0.028, 693146.0, this.halfLifeScale],
      [0.339, 8317762.0, this.halfLifeScale],
      [10.313, 253171000.0, this.halfLifeScale],
    ],

    [
      [this.rateLo, this.rateHi, this.rateScale],
      [this.rateLo, this.rateHi, this.rateScale],
      [this.rateLo, this.rateHi, this.rateScale],
      [this.rateLo, this.rateHi, this.rateScale],
      [this.rateLo, this.rateHi, this.rateScale],
      [this.rateLo, this.rateHi, this.rateScale],
      [this.halfLifeLo, this.halfLifeHi, this.halfLifeScale],
      [this.halfLifeLo, this.halfLifeHi * 12.0, this.halfLifeScale],
      [this.halfLifeLo, this.halfLifeHi * this.kfDaysPerYr, this.halfLifeScale],
    ],
  ];

  public readonly conversionFunctionsMap = [
    [
      this.ReturnItself.bind(this),
      this.RateFromMonthly.bind(this),
      this.RateFromDaily.bind(this),
      this.RateFromRemainingAfterYear.bind(this),
      this.RateFromRemainingAfterMonth.bind(this),
      this.RateFromRemainingAfterYear.bind(this),
      this.RateFromHalfLifeYear.bind(this),
      this.RateFromHalfLifeMonth.bind(this),
      this.RateFromHalfLifeDay.bind(this),
    ],
    [
      this.HalfLifeFromYearly.bind(this),
      this.HalfLifeFromMonthly.bind(this),
      this.HalfLifeFromDaily.bind(this),
      this.HalfLifeFromRemainingAfterYear.bind(this),
      this.HalfLifeFromRemainingAfterMonth.bind(this),
      this.HalfLifeFromRemainingAfterYear.bind(this),
      this.ReturnItself.bind(this),
      this.HalfLifeFromHalfLifeMonth.bind(this),
      this.HalfLifeFromHalfLifeDay.bind(this),
    ],
  ];

  //Chart
  xInYrs: boolean = false;
  nVertTicks: number = null;
  xScale: number = null;
  nYrsOnXAxis: number = null;
  nHoriTicks: number = null;

  public chartOption: any = {
    tooltip: {
      formatter: (params) => {
        return `
        <div>
        <span>${params.seriesName}</span>
          <span>${params.value[0] + (this.xInYrs ? " year" : " month")
          }</span><br/>
          ${params.marker}<span>${params.value[1].toFixed(2)}%</span>
        </div>`;
      },
    },
    legend: {},
    xAxis: {
      type: "value",
      nameLocation: "middle",
      nameTextStyle: {
        /* t-l-b-r */
        padding: [20, 0, 0, 0],
      },
    },
    yAxis: {
      axisLabel: {
        formatter: "{value}%",
      },
      name: "Percentage",
      nameLocation: "middle",
      nameTextStyle: {
        /* t-l-b-r */
        padding: [0, 0, 30, 0],
      },
    },
    series: [
      {
        name: "Decay rate",
        data: [],
        type: "line",
        smooth: true,
        itemStyle: {
          color: "red",
        },
      },
      {
        name: "Half life",
        type: "line",
        data: [],
        itemStyle: {
          color: "orange",
        },
      },
    ],
  };

  constructor(
    public flyOverPanelService: FlyOverPanelService,
    @Inject("componentData") public componentData: any
  ) { }

  ngOnInit(): void {
    const viewOnlyValue = this.componentData.viewOnlyValue;//this is for view only value used in Plants section in Tree page
    let incomingValue;
    if (viewOnlyValue !== null && viewOnlyValue !== undefined) {
      this.isViewOnly = true;
      incomingValue = +viewOnlyValue
    } else {
      this.incomingFieldControl = this.componentData.incomingFieldControl;
      incomingValue = +this.incomingFieldControl.value;
    }

    this.incomingInput = incomingValue

    this.title = this.componentData.title;
    this.inputVariables = this.componentData.inputVariables;

    this.isHalfLife = this.inputVariables?.unit !== "%/yr";

    this.tDesc = this.isHalfLife ? this.kddHalfYr : this.kddDecayYr;

    if (!this.isHalfLife) {
      this.incomingInput = this.incomingInput / 100;
    }

    this.applyFormValues();
  }

  public calculate(fieldName: string, isBlur?: boolean): void {
    const formControl = this.formGroup.get(fieldName);
    //do not calcuate if no value changes
    if (formControl.invalid || (formControl.pristine && isBlur)) {
      return;
    }

    let value = +formControl.value;

    if (value == null && value == undefined && typeof value !== "number") {
      return;
    }

    let inputType = -1;

    inputType = this.isHalfLife ? this.halfLifeTpe : this.ratePerYearType;

    this.tDesc = this.inputTypeMap[fieldName];

    const isPercentage = this.tDesc < 6;
    if (isPercentage) {
      value = value / 100;
    }

    this.incomingInput =
      this.conversionFunctionsMap[inputType][this.tDesc](value);

    this.applyFormValues();

    formControl.markAsPristine();
  }

  protected applyFormValues(): void {
    if (
      this.incomingInput == null &&
      this.incomingInput == undefined &&
      !isNaN(this.incomingInput.valueOf())
    ) {
      return;
    }

    let oneMinusRate, lnOneMinusRate, rate, halfLife;

    if (!this.isHalfLife) {
      if (this.incomingInput < this.k0Plus) {
        oneMinusRate = this.rateHi;
        halfLife = this.halfLifeHi;
      } else {
        //input is rate calculate the half life
        if (this.incomingInput == this.rateHi)
          this.incomingInput = this.defaultMaxRate;
        oneMinusRate = 1.0 - this.incomingInput;
        if (oneMinusRate > 0.0) {
          console.error("something went wrong with oneMinusRate.");
        }
        lnOneMinusRate = Math.log(oneMinusRate);
        halfLife = this.lnOfHalf / lnOneMinusRate;
      }
      rate = this.incomingInput;
    } else {
      if (this.incomingInput <= this.k0Plus) {
        rate = this.rateHi;
      } else if (this.incomingInput == this.halfLifeHi) {
        rate = this.rateLo;
      } else {
        rate = 1.0 - Math.exp(this.lnOfHalf / this.incomingInput);
      }

      oneMinusRate = 1.0 - rate;

      halfLife = this.incomingInput;
    }

    this.formGroup.get("decayYr").setValue(rate * 100);
    this.formGroup
      .get("decayMo")
      .setValue((1.0 - Math.pow(oneMinusRate, 1.0 / 12.0)) * 100);

    this.formGroup
      .get("decayDay")
      .setValue((1.0 - Math.pow(oneMinusRate, 1.0 / this.kfDaysPerYr)) * 100);

    this.formGroup.get("remainYr").setValue(oneMinusRate * 100);
    this.formGroup
      .get("remainMo")
      .setValue(Math.pow(oneMinusRate, 1.0 / 12.0) * 100);
    this.formGroup
      .get("remainDay")
      .setValue(Math.pow(oneMinusRate, 1.0 / this.kfDaysPerYr) * 100);

    this.formGroup.get("halfYr").setValue(halfLife);
    this.formGroup.get("halfMo").setValue(halfLife * 12.0);
    this.formGroup.get("halfDay").setValue(halfLife * this.kfDaysPerYr);

    this.drawChart(rate, halfLife);
  }

  public drawChart(rate, halfLife): void {
    this.getHorizontalScale(rate, halfLife);
    this.nYrsOnXAxis =
      (this.xScale * this.nVertTicks) / (this.xInYrs ? 1.0 : 12.0);

    const yearLabel = this.xInYrs ? "Years" : "Months";

    this.nHoriTicks =
      this.nYrsOnXAxis > 2 ? 11 : this.nYrsOnXAxis == 2 ? 25 : 13;
    const xAxisLabels = new Array(this.nHoriTicks).fill(0).map((d, index) => {
      if (yearLabel == "Years") {
        return (this.nYrsOnXAxis / (this.nHoriTicks - 1)) * index;
      } else {
        return index;
      }
    });

    const chartValues = this.getDecayCurveValues(rate, xAxisLabels);

    let chartOption = { ...this.chartOption };
    const halfLifeValue = this.formGroup.get(
      this.xInYrs ? "halfYr" : "halfMo"
    ).value;

    chartOption.xAxis.data = xAxisLabels;
    chartOption.xAxis.name = yearLabel;

    chartOption.series[0].data = chartValues.map((v, index) => [
      xAxisLabels[index],
      v,
    ]);

    chartOption.series[1].data = [
      [0, 50],
      [halfLifeValue, 50],
      [halfLifeValue, 0],
    ];
    this.chartOption = chartOption;
  }

  public convertToHalfLife(rate): number {
    if (rate == this.rateHi) return this.halfLifeLo;
    if (rate == this.rateLo) return this.halfLifeHi;
    const oneMinusRate = 1.0 - rate;
    const lnOneMinusRate = Math.log(oneMinusRate);
    return this.lnOfHalf / lnOneMinusRate;
  }

  public ReturnItself(x: number): number {
    return x;
  }

  public RateFromMonthly(x: number): number {
    return 1.0 - Math.pow(1.0 - x, 12.0);
  }

  public RateFromDaily(x: number): number {
    return 1.0 - Math.pow(1.0 - x, this.kfDaysPerYr);
  }

  public RateFromRemainingAfterYear(x: number): number {
    return 1.0 - x;
  }
  public RateFromRemainingAfterMonth(x: number): number {
    return 1.0 - Math.pow(x, 12.0);
  }
  public RateFromRemainingAfterDay(x: number): number {
    return 1.0 - Math.pow(x, this.kfDaysPerYr);
  }
  public RateFromHalfLifeYear(x: number): number {
    return 1.0 - Math.pow(0.5, 1.0 / x);
  }
  public RateFromHalfLifeMonth(x: number): number {
    return 1.0 - Math.pow(0.5, 12.0 / x);
  }
  public RateFromHalfLifeDay(x: number): number {
    return 1.0 - Math.pow(0.5, this.kfDaysPerYr / x);
  }
  public HalfLifeFromYearly(x: number): number {
    return this.convertToHalfLife(x);
  }
  public HalfLifeFromMonthly(x: number): number {
    return this.convertToHalfLife(1.0 - Math.pow(1.0 - x, 12.0));
  }
  public HalfLifeFromDaily(x: number): number {
    return this.convertToHalfLife(1.0 - Math.pow(1.0 - x, this.kfDaysPerYr));
  }
  public HalfLifeFromRemainingAfterYear(x: number) {
    return this.convertToHalfLife(1.0 - x);
  }
  public HalfLifeFromRemainingAfterMonth(x: number) {
    return this.convertToHalfLife(1.0 - Math.pow(x, 12.0));
  }
  public HalfLifeFromRemainingAfterDay(x: number) {
    return this.convertToHalfLife(1.0 - Math.pow(x, this.kfDaysPerYr));
  }
  public HalfLifeFromHalfLifeMonth(x: number): number {
    return x / 12.0;
  }
  public HalfLifeFromHalfLifeDay(x: number): number {
    return x / this.kfDaysPerYr;
  }

  getHorizontalScale(rate, halfLife): void {
    // Half-life too large
    if (rate <= this.k0Plus) {
      this.xInYrs = true;
      this.nVertTicks = 10;
      this.xScale = 1000000;
      return;
    }
    // Months scales
    this.xInYrs = false;
    if (halfLife <= 0.8) {
      this.nVertTicks = 12;
      this.xScale = 1;
      return;
    }
    if (halfLife <= 1.6) {
      this.nVertTicks = 8;
      this.xScale = 3;
      return;
    }
    // Years scales
    this.xInYrs = true;
    this.nVertTicks = 10;
    let en = 1;
    let ha = 0.75;
    while (true) {
      // Test a decade per loop
      if (halfLife <= ha * 5.0) {
        this.xScale = en / 2.0;
        return;
      }
      en *= 10;
      ha *= 10;
      if (halfLife <= ha) {
        this.xScale = en / 10;
        return;
      }
      if (halfLife <= ha * 2.0) {
        this.xScale = en / 5;
        return;
      }
    }
  }

  getDecayCurveValues(rate, xAxisLabels): number[] {
    let values = [];
    const oneMinusRate = 1.0 - rate;
    for (let i = 0; i < this.nHoriTicks; i++) {
      let y = 0.0;
      if (oneMinusRate > this.k0Plus) {
        y =
          Math.pow(oneMinusRate, xAxisLabels[i] / (this.xInYrs ? 1.0 : 12.0)) *
          100;
        values.push(y);
      } else {
        values.push(null);
      }
    }
    return values;
  }

  save() {
    if (!this.isViewOnly) {
      this.incomingFieldControl.setValue(
        this.isHalfLife ? this.incomingInput : this.incomingInput * 100
      );
    }
    this.flyOverPanelService.closePanel();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
