<div class="modal-body text-center confirm-modal-with-comment">
  <div class="message" [innerHTML]="message"></div>

  <div class="form-floating">
    <textarea
      [(ngModel)]="comment"
      #commentTextArea
      maxlength="500"
      class="form-control"
      id="comment"
      style="height: 100px"
    ></textarea>
    <small>{{ 500 - comment.length }} characters remaining</small>

    <label for="comment">Comment</label>
  </div>
  <div class="inline-button-group">
    <button
      id="model-confirm-no"
      type="button"
      class="btn btn-light"
      (click)="confirm(false, commentTextArea.value)"
    >
      Cancel
    </button>
    <button
      id="model-confirm-yes"
      type="button"
      class="btn btn-primary"
      (click)="confirm(true, commentTextArea.value)"
    >
      Confirm
    </button>
  </div>
</div>
