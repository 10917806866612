import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LayoutModule } from "./layout/layout.module";
import { FormsModule } from "@angular/forms";

import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { MyPlotsComponent } from "./my-plots/my-plots.component";
import { SimulationComponent } from "./simulation/simulation.component";
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from "./auth/auth-config";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { PlotEntryComponent } from "./simulation/plot-entry/plot-entry.component";
import { CanDeactivateGuardService } from "./shared/services/can-deactivate-guard.service";
import { SimulationModule } from "./simulation/simulation.module";
import { AlertModule } from "ngx-bootstrap/alert";
import { RmtModule } from "./rmt/rmt.module";
import { PortalAdminComponent } from "./user-role/portal-admin/portal-admin.component";
import { PortalAdminAllUsersComponent } from "./user-role/portal-admin-all-users/portal-admin-all-users.component";
import { PortalModalServiceComponent } from "./user-role/portal-admin/portal-modal-service/portal-modal-service.component";
import { PlotModule } from "./plot/plot.module";
import { ViewPrivacyComponent } from "./view-privacy/view-privacy.component";
import { PlotUploadDropZoneComponent } from "./shared/components/plot-upload-drop-zone/plot-upload-drop-zone.component";
import { FcHttpInterceptor } from "./shared/services/httpInterceptor.service";
import { MessagesComponent } from "./shared/components/messages/messages.component";
import { PolicyComponent } from "./view-privacy/policy/policy.component";
import { TermsConditionsComponent } from "./view-privacy/terms-conditions/terms-conditions.component";
import { AdminGuard } from "./shared/guards/admin.guard";
import { FullcamVersionGuard } from "./shared/guards/fullcam-verion.guard";
import { RmtEntryComponent } from "./rmt/simulation/rmt-entry/rmt-entry.component";
import { AppService } from "./services/app.service";
import { AuthService } from "./auth/services/auth.service";
import { UserManagementModule } from "./user-role/user-management.module";
import { UserManagementComponent } from "./user-role/user-management/user-management.component";
import { RoleAccessComponent } from "./user-role/role-access/role-access.component";
import { ResolvingAccessComponent } from "./shared/components/resolving-access/resolving-access.component";
import { NoAccessComponent } from "./shared/components/no-access/no-access.component";
import { MyRmdsComponent } from "./rmt/my-rmds/my-rmds/my-rmds.component";

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  declarations: [
    AppComponent,
    PortalAdminComponent,
    PortalAdminAllUsersComponent,
    PortalModalServiceComponent,
    ViewPrivacyComponent,
    PolicyComponent,
    PlotUploadDropZoneComponent,
    MessagesComponent,
    TermsConditionsComponent,
  ],
  imports: [
    CommonModule,
    UserManagementModule,
    BrowserModule,
    ReactiveFormsModule,
    LayoutModule,
    SimulationModule,
    RmtModule,
    FormsModule,
    AlertModule.forRoot(),
    PlotModule,
    RouterModule.forRoot(
      [
        {
          path: "",
          pathMatch: "full",
          redirectTo: "resolving-access",
        },
        {
          path: "state",
          component: DashboardComponent,
          /// canActivate: [MsalGuard],
        },
        {
          path: "code",
          component: DashboardComponent,
          // canActivate: [MsalGuard],
        },
        {
          path: "error",
          component: DashboardComponent,
        },
        {
          path: "plots-2020",
          component: MyPlotsComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          data: { version: "2020", title: "Plot (2020)" },
        },
        {
          path: "simulation-2020",
          component: SimulationComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          data: { version: "2020", title: "New Simulation (2020)" },
        },
        {
          path: "simulation-2020/:simType",
          component: PlotEntryComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          canDeactivate: [CanDeactivateGuardService],
          data: { version: "2020" },
        },
        {
          path: "plots-2024",
          component: MyPlotsComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          data: { version: "2024", title: "Plot (2024)" },
        },
        {
          path: "simulation-2024",
          component: SimulationComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          data: { version: "2024", title: "New Simulation (2024)" },
        },
        {
          path: "simulation-2024/:simType",
          component: PlotEntryComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          canDeactivate: [CanDeactivateGuardService],
          data: { version: "2024" },
        },
        {
          path: "user-profile",
          component: RoleAccessComponent,
          canActivate: [MsalGuard],
        },
        // {
        //   path: "user-management",
        //   component: UserManagementComponent,
        //   data: { title: "User Management" },
        //   canActivate: [MsalGuard, AdminGuard],
        // },
        {
          path: "portal-admin",
          component: PortalAdminComponent,
          data: { title: "Portal Admin" },
          canActivate: [MsalGuard, AdminGuard],
        },
        {
          path: "portal-admin-all",
          component: PortalAdminAllUsersComponent,
          data: { title: "Portal admin- Revoke Access" },
          canActivate: [MsalGuard, AdminGuard],
        },
        {
          path: "view-privacy",
          component: ViewPrivacyComponent,
          data: { title: "Privacy collection statement" },
        },
        {
          path: "rmt",
          component: RmtEntryComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          data: { version: "rmt" },
        },
        {
          path: "rmds",
          component: MyRmdsComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          data: { version: "rmt", title: "Rmd" },
        },
        {
          path: "rmt/:subPage",
          component: RmtEntryComponent,
          canActivate: [MsalGuard, FullcamVersionGuard],
          canDeactivate: [CanDeactivateGuardService],
          data: { version: "rmt" },
        },
        {
          path: "resolving-access",
          component: ResolvingAccessComponent,
          canActivate: [MsalGuard],
        },
        {
          path: "no-access",
          component: NoAccessComponent,
          canActivate: [MsalGuard],
        },
        {
          path: "**",
          redirectTo: "resolving-access",
        },
      ],
      {
        useHash: false,
        // Don't perform initial navigation in iframes
        initialNavigation: !isIframe ? "enabledBlocking" : "disabled",
      }
    ),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService, authService: AuthService) => () =>
        appService.initialiseApp(authService),
      multi: true,
      deps: [AppService, AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FcHttpInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
