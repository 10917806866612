<form class="row g-4" [formGroup]="formGroup">
  <div class="col-6">
    <div>
      <div class="card-header">
        <h2>Scenario & Input editing</h2>
        <div class="header-controls">
          <button class="btn btn-sm btn-primary-darken" (click)="addScenario()">
            Add Scenario
          </button>
          <button
            class="btn btn-sm btn-primary-darken"
            [disabled]="
              getSelectedDigestElementFormGroup()?.invalid ||
              !service.getSelectedScenarioName()
            "
            (click)="cloneFromPlot()"
          >
            Clone from plot
          </button>
        </div>
      </div>
      <div class="card-content">
        <fc-scenario-view
          [formGroupInstance]="formGroup"
          [digestScenarios]="digestScenarios"
          [viewType]="getViewType()"
          (scenarioItemClicked)="setScenarioItem($event)"
          (scenarioLabelClicked)="setScenarioInput($event)"
          (scenarioDeleted)="deleteScenario($event)"
          (scenarioCloned)="cloneScenario($event)"
          (editScenarioClicked)="editScenario($event)"
        ></fc-scenario-view>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card-header">
      <!-- <h3>{{ getCardHeader() }}</h3> -->
      <ng-container
        *ngIf="
          formGroup.get('viewType').value == 'Category' ||
          formGroup.get('viewType').value == 'Scenario'
        "
      >
        <h3>Scenario: {{ service.getSelectedScenarioName() }}</h3>
        <div class="notes"></div>
        <div
          class="category"
          *ngIf="formGroup.get('viewType').value == 'Category'"
        >
          <span
            *ngIf="
              service.selectedScenarioPath$ | async as selectedScenarioPath
            "
            >{{ selectedScenarioPath }}</span
          >
        </div>
        <div class="notes">
          <span>notes: </span>{{ service.getSelectedScenarioNotes() }}
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          formGroup.get('viewType').value == 'Input' &&
          service.digestElements[0]
        "
      >
        <h3>
          Input:
          {{
            service.digestElements[0]?.model?.prefixId
              ? prefixMap[service.digestElements[0].model.prefixId]?.description
              : ""
          }}
          {{ service.digestElements[0].itemLabel }}
        </h3>
      </ng-container>
    </div>
    <table
      class="table digest-elements-table"
      *ngIf="getSelectedDigestElementFormGroup()"
    >
      <thead>
        <tr>
          <th scope="col">
            {{
              formGroup.get("viewType").value == "Input"
                ? "Sceanrio"
                : "Element"
            }}
          </th>
          <th scope="col">Input value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of service.digestElements; let index=index">
          <td>
            <span *ngIf="getViewType() == 'Input'"
              >{{ item.scenarioName }}
              <ng-container *ngIf="item?.model?.unit"
                >({{ item.model.unit }})</ng-container
              >
            </span>
            <span *ngIf="getViewType() !== 'Input'">
              {{
                item?.model?.prefixId
                  ? prefixMap[item.model.prefixId]?.description
                  : ""
              }}
              {{ item.itemLabel }}
              {{
                item?.model?.suffixId
                  ? prefixMap[item.model.suffixId]?.description
                  : ""
              }}

              {{ item?.model?.unit }}</span
            >
          </td>
          <td>
            <div
              class="digest-elements"
              fc-form-item-element
              [formGroupInstance]="getViewType() !== 'Input'?getSelectedDigestElementFormGroup() :getDigestElementFormGroupByItemIndex(index) "
              [simulationService]="service.simulationService"
              [service]="item.service"
              [item]="item.inputItem"
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
