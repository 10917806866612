import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TransitionGroupItemDirective } from "../../directives/transition-group-item.directive";

@Component({
  selector: "[fc-transition-group]",
  template: "<ng-content></ng-content>",
})
export class TransitionGroupComponent {
  private readonly destroy$ = new Subject<void>();

  @Input("transition-group-class") class;

  @ContentChildren(TransitionGroupItemDirective)
  items: QueryList<TransitionGroupItemDirective>;

  ngAfterContentInit() {
    this.items.changes.pipe(takeUntil(this.destroy$)).subscribe((items) => {
      this.refreshPosition("prevPos");
      items.forEach((item) => {
        item.prevPos = item.newPos || item.prevPos;
      });

      items.forEach(this.runCallback);
      this.refreshPosition("newPos");
      items.forEach(this.applyTranslation);

      // force reflow to put everything in position
      const offSet = document.body.offsetHeight;
      this.items.forEach(this.runTransition.bind(this));
    });
  }

  runCallback(item: TransitionGroupItemDirective): void {
    if (item.moveCallback) {
      item.moveCallback();
    }
  }

  runTransition(item: TransitionGroupItemDirective): void {
    if (!item.moved) {
      return;
    }
    const cssClass = this.class + "-move";
    let el = item.el;
    let style: any = el.style;
    el.classList.add(cssClass);
    style.transform = style.WebkitTransform = style.transitionDuration = "";
    el.addEventListener(
      "transitionend",
      (item.moveCallback = (e: any) => {
        if (!e || /transform$/.test(e.propertyName)) {
          el.removeEventListener("transitionend", item.moveCallback);
          item.moveCallback = null;
          el.classList.remove(cssClass);
        }
      })
    );
  }

  refreshPosition(prop: string): void {
    this.items.forEach((item) => {
      item[prop] = item.el.getBoundingClientRect();
    });
  }

  applyTranslation(item: TransitionGroupItemDirective): void {
    item.moved = false;
    const dx = item.prevPos.left - item.newPos.left;
    const dy = item.prevPos.top - item.newPos.top;
    if (dx || dy) {
      item.moved = true;
      let style: any = item.el.style;
      style.transform = style.WebkitTransform =
        "translate(" + dx + "px," + dy + "px)";
      style.transitionDuration = "0s";
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
