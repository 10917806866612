import { Component, OnInit } from "@angular/core";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";
import { take, takeUntil } from "rxjs/operators";
import { Subject, lastValueFrom } from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { ProjectTableColumns } from "src/app/my-plots/models";

@Component({
  selector: "fc-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent extends BaseFlowComponent implements OnInit {
  // public folders: FolderList[] = [];
  // public projects: ProjectTableColumns[] = [];
  private readonly roleSub$ = new Subject<void>();

  constructor(private authService: AuthService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.authService.userRole$
      .pipe(takeUntil(this.roleSub$))
      .subscribe(async (role) => {
        if (role) {
          this.roleSub$.next();
          this.roleSub$.complete();
          //check if there are folders loaded already if not load folders
          const existingFolders: ProjectTableColumns[] = await lastValueFrom(
            this.service.getFolders().pipe(take(1))
          );

          if (!existingFolders?.length) {
            await this.service.loadFolders();
          }

          super.ngOnInit();
          const nameControl = this.formGroup.get("nmME");
          if (!nameControl.value) {
            const today =
              this.simulationService.helperService.getTodayDateTimeForFileName();
            const defaultName = "My_Plot_" + today;
            nameControl.setValue(defaultName);
          }
        }
      });
  }
}
