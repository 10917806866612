<div class="header">
  <div>
    <h1 *ngIf="!forceFilter && !selectPlotFilesOnly">
      Plots ({{ appVersion }})
    </h1>

    <div
      class="filters"
      [class]="{ 'force-filter': forceProjectCollectionFilter }"
    >
      <div
        *ngIf="projectCollectionFilter"
        class="chip project-collection-filter"
      >
        <i class="bi bi-folder-fill"></i
        ><span [title]="projectCollectionFilter?.project">{{
          projectCollectionFilter?.project
        }}</span>

        <i class="bi bi-chevron-right"></i>
        <i class="bi bi-folder-fill"></i
        ><span [title]="projectCollectionFilter?.collection">{{
          projectCollectionFilter?.collection
        }}</span>
        <a
          class="remove-button"
          tooltip="Remove filter"
          (click)="removeProjectCollectionFilter()"
        >
          <i class="bi bi-x text-danger"></i>
        </a>
      </div>
      <div *ngIf="searchFilter" class="chip">
        <span [title]="searchFilter">{{ searchFilter }}</span>

        <a
          class="remove-button"
          tooltip="Remove filter"
          (click)="removeSearchFilter()"
        >
          <i class="bi bi-x text-danger"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="header-button-group" *ngIf="selectPlotFilesOnly">
    <button
      tooltip="Upload plot files (.plo, .pld, .est) to FullCAM"
      (click)="uploadPlotFile()"
      type="button"
      class="btn btn-outline-primary-darken btn-sm"
    >
      Upload
    </button>
  </div>

  <div class="header-button-group" *ngIf="!forceFilter && !selectPlotFilesOnly">
    <button
      tooltip="Upload plot files (.plo, .pld, .est) to FullCAM"
      (click)="uploadPlotFile()"
      type="button"
      class="btn btn-outline-primary-darken btn-sm"
    >
      Upload
    </button>

    <input
      type="file"
      id="plot-import"
      (change)="onFileSelected($event)"
      [accept]="acceptedFileTypes"
      multiple
      hidden
    />
    <label
      tooltip="Open a plot file (.plo, .pld, .est) from own device"
      role="button"
      class="btn btn-outline-primary-darken btn-sm"
      for="plot-import"
      >Open</label
    >

    <button
      tooltip="Create a plot file from scratch"
      type="button"
      class="btn btn-primary-darken btn-sm"
      data-bs-toggle="dropdown"
    >
      New plot
    </button>

    <ul class="dropdown-menu">
      <li class="dropdown-item">
        <a [routerLink]="['/simulation-' + appVersion + '/plo']">Plot</a>
      </li>
      <li class="dropdown-item">
        <a [routerLink]="['/simulation-' + appVersion + '/pld']">Digest</a>
      </li>
      <li class="dropdown-item">
        <a [routerLink]="['/simulation-' + appVersion + '/est']">Estate</a>
      </li>
    </ul>
  </div>
</div>

<div class="row g-4">
  <div
    *ngIf="!forceProjectCollectionFilter"
    class="project-collection-container drawer-container col-xl-3"
    [style]="{
      height: (layoutService.mainHeight$ | async) - 148 + 'px'
    }"
  >
    <fc-drawer>
      <fc-project-collection-list
        [data]="folders$ | async"
        [subitemKey]="'collectionInfo'"
        [parentIdKey]="'projectId'"
        [subitemSubmitTooltip]="'Show plot files within this collection'"
        (deleteItem)="deleteFolder($event)"
        (subitemClicked)="setProjectCollectionFilter($event)"
      ></fc-project-collection-list>
    </fc-drawer>
  </div>
  <div
    [class]="forceProjectCollectionFilter ? 'col-lg-12' : 'col-xl-9 col-lg-12'"
  >
    <div class="content">
      <div class="table-controls">
        <div class="button-group-container">
          <ng-container *ngIf="!forceFilter && !selectPlotFilesOnly">
            <div class="button-group" *ngIf="selectedRows?.length">
              <select
                class="form-control form-select form-select-sm"
                [(ngModel)]="selectedBulkAction"
              >
                <option value="delete">Delete</option>
                <option value="export">Export</option>
              </select>
              <fc-button
                [isLoading$]="isBulkActionLoading$"
                [inputText]="'Apply'"
                (click)="bulkAction()"
              ></fc-button>
            </div>
          </ng-container>
          <ng-container *ngIf="selectPlotFilesOnly && selectedRows?.length">
            <button
              class="btn btn-sm btn-primary-darken"
              (click)="addToPlotFileList()"
            >
              Add to plot file list
            </button>

            <button
              (click)="addToPlotFileList(true)"
              type="button"
              class="btn btn-outline-primary-darken btn-sm"
            >
              Add all plot files from the collection
            </button>
          </ng-container>
        </div>
        <div class="button-group-container">
          <select
            class="form-control form-select form-select-sm plot-type-filter"
            [(ngModel)]="selectedPlotTypeFilter"
            (change)="filterPlotFiles()"
            [disabled]="forcePlotTypeFilter"
          >
            <option value="all">All plot type</option>
            <option value="plo">PLO (Plot)</option>
            <option value="pld">PLD (Plot Digest)</option>
            <option value="est">EST (Estate)</option>
          </select>

          <div class="button-group">
            <input
              id="plot-file-search"
              aria-label="Search plot file name"
              class="form-control form-control-sm"
              placeholder="Search plot file name"
              (keyup.enter)="setSearchString()"
              [(ngModel)]="searchString"
            />
            <button
              id="clear-plot-file-search"
              [disabled]="searchString?.length < 2"
              class="btn btn-primary-darken btn-sm"
              (click)="setSearchString()"
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <ngx-datatable
        #plotTable
        class="custom-table"
        [rows]="rows"
        [columnMode]="ColumnMode.force"
        [headerHeight]="56"
        [footerHeight]="56"
        rowHeight="56"
        [selected]="selectedRows"
        [selectionType]="SelectionType.checkbox"
        (select)="onSelect($event)"
        [externalPaging]="true"
        [count]="page?.totalElements || defaultPage.totalElements"
        [offset]="page?.pageNumber - 1 || defaultPage.pageNumber"
        [limit]="page?.pageSize || defaultPage.pageSize"
        (page)="filterPlotFiles($event)"
      >
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template
            ngx-datatable-header-template
            let-value="value"
            let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn"
          >
            <label class="check-container">
              <span class="visually-hidden">{{
                allRowsSelected ? "Uncheck all" : "Check all"
              }}</span>
              <input
                *ngIf="!selectPlotFilesOnly"
                type="checkbox"
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
              />
              <!--custom function to skip already selected rows -->
              <input
                *ngIf="selectPlotFilesOnly"
                type="checkbox"
                [checked]="selectPlotFilesOnlyAllRowsSelected()"
                (change)="selectPlotFilesOnlySelectFn($event)"
              />
              <span class="checkmark"></span>
            </label>
          </ng-template>

          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <label
              class="check-container"
              *ngIf="!isPlotInSelection(row.plotId)"
            >
              <span class="visually-hidden">{{
                isSelected ? "Deselect" : "Select"
              }}</span>

              <input
                type="checkbox"
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
              />
              <span class="checkmark"></span>
            </label>
            <span
              *ngIf="isPlotInSelection(row.plotId) && selectPlotFilesOnly"
              class="selected-checkbox"
            ></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="File name"
          [flexGrow]="3"
        ></ngx-datatable-column>

        <!-- <ngx-datatable-column name="Longitude, Latitude">
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            {{ row.longitude }}°S, {{ row.latitude }}°E
          </ng-template>
        </ngx-datatable-column> -->

        <ngx-datatable-column prop="fileType" [flexGrow]="1">
          <ng-template
            ngx-datatable-cell-template
            let-row="row"
            let-value="value"
          >
            {{ value }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Project"
          [flexGrow]="1"
        ></ngx-datatable-column>
        <ngx-datatable-column
          name="Collection"
          [flexGrow]="1"
        ></ngx-datatable-column>
        <ngx-datatable-column name="Created date" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value ? (value | date : "dd/MM/yyyy") : "Invalid Date" }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Modified date" [flexGrow]="1">
          <ng-template ngx-datatable-cell-template let-value="value">
            {{ value ? (value | date : "dd/MM/yyyy") : "Invalid Date" }}
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="Action"
          *ngIf="!selectPlotFilesOnly"
          [width]="130"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
        >
          <ng-template ngx-datatable-cell-template let-row="row">
            <div class="action-buttons">
              <button
                class="btn btn-sm btn-rounded"
                (click)="editPlotFile(row)"
                [adaptivePosition]="false"
                tooltip="Edit"
              >
                <span class="visually-hidden">edit</span>
                <i class="bi bi-pencil-fill"></i>
              </button>
              <button
                class="btn btn-sm btn-rounded"
                (click)="clonePlotFile(row)"
                [adaptivePosition]="false"
                tooltip="Clone"
              >
                <span class="visually-hidden">clone</span>
                <i class="bi bi-files"></i>
              </button>

              <button
                class="btn btn-sm btn-rounded"
                (click)="deletePlotFiles([row])"
                [adaptivePosition]="false"
                tooltip="Delete"
              >
                <span class="visually-hidden">delete</span>
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
