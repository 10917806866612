import { Injectable } from "@angular/core";
import { OutputInfo } from "src/app/plot/output-windows/outputInfo";
import { AppService } from "src/app/services/app.service";
import { PlotFormat } from "src/app/shared/models";

interface Category {
  [key: string]: {
    label: string;
    name: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class PlotReferenceDataService {
  public readonly dataTypeMap = {
    0: "Floating Point",
    1: "Integer",
    2: "Yes/No",
    3: "Static string",
    4: "Dynamic string",
    5: "Enumeration",
    6: "Time Series",
    7: "Wood",
    8: "Byte",
    9: "Date format",
  };

  public readonly eventIdMap = {
    0: "Forest thinning",
    1: "Forest fire",
    2: "Agricultural fire",
    3: "Crop harvest",
    4: "Agricultural plowing",
    5: "Agricultural herbiciding",
    6: "Tree planting",
    7: "Crop planting",
    8: "Grazing change",
    9: "Forest-Percentage change",
    10: "Not Event",
    11: "All Events",
    12: "Forest treatment",
    13: "Chopper roller",
    14: "Termite change",
    15: "Forest irrigation change",
    16: "Agricultural irrigation change",
    17: "Forest N fertilizer application",
    18: "Agricultural N fertilizer application",
    19: "Forest manure from offsite",
    20: "Agricultural manure from offsite",
    21: "External cost",
    22: "External income",
  };

  public speciesTypeMap = {
    0: "",
    1: "Tree",
    2: "Crop",
    3: "Tree or Crop",
  };

  public readonly categories: Category = {
    "0": {
      label: "Error!",
      name: "ErrorE",
    },
    "1": {
      label: "About the document",
      name: "MetaIn",
    },
    "2": {
      label: "Configuration",
      name: "Config",
    },
    "3": {
      label: "Timing",
      name: "Timing",
    },
    "4": {
      label: "Data builder",
      name: "Build",
    },
    "5": {
      label: "Site : Area",
      name: "SiteAr",
    },
    "6": {
      label: "Site : Water",
      name: "SiteWa",
    },
    "7": {
      label: "Site : Temperature",
      name: "SiteTe",
    },
    "8": {
      label: "Site : Light",
      name: "SiteLi",
    },
    "9": {
      label: "Site : Maximum biomass",
      name: "SiteMB",
    },
    "10": {
      label: "Site : Productivity",
      name: "SitePr",
    },
    "11": {
      label: "Site : Growth adjustments",
      name: "SiteGM",
    },
    "12": {
      label: "Site : Displacement",
      name: "SiteDp",
    },
    "13": {
      label: "Forest species set",
      name: "SpecFr",
    },
    "14": {
      label: "Agricultural species set",
      name: "SpecAg",
    },
    "15": {
      label: "Species",
      name: "SpecFA",
    },
    "16": {
      label: "Tree species : Growth",
      name: "TreeGr",
    },
    "17": {
      label: "Tree species : Growth (database only)",
      name: "TreeGD",
    },
    "18": {
      label: "Tree species : Plants",
      name: "TreePl",
    },
    "19": {
      label: "Tree species : Debris",
      name: "TreeDe",
    },
    "20": {
      label: "Tree species : Products",
      name: "TreePr",
    },
    "21": {
      label: "Tree species : Nitrogen",
      name: "TreeNi",
    },
    "22": {
      label: "Tree species : Thinning",
      name: "TreeLg",
    },
    "23": {
      label: "Tree species : Displacement due to bioenergy",
      name: "TreeDisE",
    },
    "24": {
      label: "Tree species : Displacement due to product use",
      name: "TreeDisP",
    },
    "25": {
      label: "Tree species : 3PG : Growth",
      name: "Tree3G",
    },
    "26": {
      label: "Tree species : 3PG : Allometry",
      name: "Tree3A",
    },
    "27": {
      label: "Tree species : 3PG : Leaves",
      name: "Tree3L",
    },
    "28": {
      label: "Tree species : 3PG : Other",
      name: "Tree3O",
    },
    "29": {
      label: "Crop species : Growth",
      name: "CropGr",
    },
    "30": {
      label: "Crop species : Plants",
      name: "CropPl",
    },
    "31": {
      label: "Crop species : Displacement due to bioenergy",
      name: "CropDe",
    },
    "32": {
      label: "Crop species : Products",
      name: "CropPr",
    },
    "33": {
      label: "Crop species : Nitrogen",
      name: "CropNi",
    },
    "34": {
      label: "Crop species : Harvest",
      name: "CropCg",
    },
    "35": {
      label: "Crop species : Debris",
      name: "CropDisE",
    },
    "36": {
      label: "Crop species : Displacement due to product use",
      name: "CropDisP",
    },
    "37": {
      label: "Mulch : Inputs",
      name: "MulcIn",
    },
    "38": {
      label: "Mulch : Forest : Properties",
      name: "MulcFP",
    },
    "39": {
      label: "Mulch : Agricultural : Properties",
      name: "MulcAP",
    },
    "40": {
      label: "Mulch : Forest : Nitrogen",
      name: "MulcFN",
    },
    "41": {
      label: "Mulch : Agricultural : Nitrogen",
      name: "MulcAN",
    },
    "42": {
      label: "Soil : Forest : Inputs",
      name: "SoilFI",
    },
    "43": {
      label: "Soil : Agricultural : Inputs",
      name: "SoilAI",
    },
    "44": {
      label: "Soil : Forest : Properties",
      name: "SoilFP",
    },
    "45": {
      label: "Soil : Agricultural : Properties",
      name: "SoilAP",
    },
    "46": {
      label: "Soil : Forest : Water",
      name: "SoilFW",
    },
    "47": {
      label: "Soil : Agricultural : Water",
      name: "SoilAW",
    },
    "48": {
      label: "Soil : Forest : Nitrogen",
      name: "SoilFN",
    },
    "49": {
      label: "Soil : Agricultural : Nitrogen",
      name: "SoilAN",
    },
    "50": {
      label: "Soil : Whole Plot",
      name: "SoilWP",
    },
    "51": {
      label: "Soil : Special",
      name: "SoilSp",
    },
    "52": {
      label: "Minerals: Forest nitrogen",
      name: "MnrlFN",
    },
    "53": {
      label: "Minerals: Agricultural nitrogen",
      name: "MnrlAN",
    },
    "54": {
      label: "Minerals: Forest : Boundary line emissions",
      name: "MnrlFB",
    },
    "55": {
      label: "Minerals: Agricultural : Boundary line emissions",
      name: "MnrlAB",
    },
    "56": {
      label: "Initial : Forest : Trees",
      name: "InitFT",
    },
    "57": {
      label: "Initial : Forest : Debris",
      name: "InitFD",
    },
    "58": {
      label: "Initial : Forest : Mulch",
      name: "InitFM",
    },
    "59": {
      label: "Initial : Forest : Soil",
      name: "InitFS",
    },
    "60": {
      label: "Initial : Forest : Minerals",
      name: "InitFN",
    },
    "61": {
      label: "Initial : Forest : Products",
      name: "InitFP",
    },
    "62": {
      label: "Initial : Agricultural : Crops",
      name: "InitAC",
    },
    "63": {
      label: "Initial : Agricultural : Debris",
      name: "InitAD",
    },
    "64": {
      label: "Initial : Agricultural : Mulch",
      name: "InitAM",
    },
    "65": {
      label: "Initial : Agricultural : Soil",
      name: "InitAS",
    },
    "66": {
      label: "Initial : Agricultural : Minerals",
      name: "InitAN",
    },
    "67": {
      label: "Initial : Agricultural : Products",
      name: "InitAP",
    },
    "68": {
      label: "Initial : Whole plot",
      name: "InitWP",
    },
    "69": {
      label: "Economic information",
      name: "EconIn",
    },
    "70": {
      label: "Events set",
      name: "EvSete",
    },
    "71": {
      label: "All events",
      name: "EvAlle",
    },
    "72": {
      label: "Tree planting event",
      name: "EvPlnF",
    },
    "73": {
      label: "Crop planting event",
      name: "EvPlnA",
    },
    "74": {
      label: "Forest thinning event",
      name: "EvThin",
    },
    "75": {
      label: "Crop harvest event",
      name: "EvHarv",
    },
    "76": {
      label: "Forest fire event",
      name: "EvFirF",
    },
    "77": {
      label: "Agricultural fire event",
      name: "EvFirA",
    },
    "78": {
      label: "Agricultural plowing event",
      name: "EvPlow",
    },
    "79": {
      label: "Agricultural herbiciding event",
      name: "EvHerb",
    },
    "80": {
      label: "Grazing change event",
      name: "EvGraz",
    },
    "81": {
      label: "Forest-Percentage change event",
      name: "EvFFrc",
    },
    "82": {
      label: "Forest treatment event",
      name: "EvTmtF",
    },
    "83": {
      label: "Chopper roller event",
      name: "EvChop",
    },
    "84": {
      label: "Termite change event",
      name: "EvTerm",
    },
    "85": {
      label: "Forest irrigation change event",
      name: "EvIrrF",
    },
    "86": {
      label: "Agricultural irrigation change event",
      name: "EvIrrA",
    },
    "87": {
      label: "Forest N fertilizer application event",
      name: "EvNFeF",
    },
    "88": {
      label: "Agricultural N fertilizer application event",
      name: "EvNFeA",
    },
    "89": {
      label: "Forest manure from offsite event",
      name: "EvManF",
    },
    "90": {
      label: "Agricultural manure from offsite event",
      name: "EvManA",
    },
    "91": {
      label: "External cost event",
      name: "EvXCst",
    },
    "92": {
      label: "External income event",
      name: "EvXInc",
    },
    "93": {
      label: "Sensitivity analysis",
      name: "SensAn",
    },
    "94": {
      label: "Spatial inputs",
      name: "SpaIn",
    },
    "95": {
      label: "Spatial-only inputs",
      name: "SpaOIn",
    },
    "96": {
      label: "Spatial simulation",
      name: "SpaSim",
    },
    "97": {
      label: "Spatial simulation outputs",
      name: "SpaOut",
    },
    "98": {
      label: "Location : Plot Data : Soils",
      name: "DbaLso",
    },
    "99": {
      label: "Initial : Forest : Standing Dead",
      name: "InitFSd",
    },
    "100": {
      label: "Tree species : Standing Dead",
      name: "TreeSd",
    },
    "101": {
      label: "Regime of the event",
      name: "Regime",
    },
    "102": {
      label: "Plots in the estate",
      name: "PlInEst",
    },
    "103": {
      label: "Digest element in the digest",
      name: "Scenario",
    },
    "104": {
      label: "Input element in the digestelement",
      name: "InputE",
    },
    "105": {
      label: "Digest in the document",
      name: "Digest",
    },
  };

  public readonly enums = [
    {
      enum: "AgAutoQT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 1,
      name: "IntensGrazOn",
      description: "Intensive grazing on",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 2,
      name: "IntensGrazOff",
      description: "Intensive grazing off",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 3,
      name: "SpotCult",
      description: "Spot Cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 4,
      name: "StripCult",
      description: "Strip cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 5,
      name: "BroadCult",
      description: "Broadcast cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 6,
      name: "SpotWC",
      description: "Spot weed control (Herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 7,
      name: "StripWC",
      description: "Strip weed control (Herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 8,
      name: "BlanketWC",
      description: "Blanket weed control (Herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 9,
      name: "BurnGrass",
      description: "Burning of grass cover",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 10,
      name: "PlantCrops",
      description: "Plant crops",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 11,
      name: "ManPPWCHarv",
      description: "Manual post planting weed control (Harvest)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 12,
      name: "ManPPWCHerb",
      description: "Manual post planting weed control (Herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 17,
      name: "GrazOff",
      description: "Grazing off",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 18,
      name: "PlowRI",
      description: "Run-In Plow",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 19,
      name: "PlantingRI",
      description: "Run-In Planting",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 20,
      name: "GrazNormRI",
      description: "Run-In grazing normal",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 21,
      name: "GrazOffRI",
      description: "Run-In grazing off",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 22,
      name: "GrazHeavyRI",
      description: "Run-In grazing",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 23,
      name: "AgFireRI",
      description: "Run-In fire",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 24,
      name: "GrazNM",
      description: "Grazing Non Managed",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "AgAutoQT",
      id: 25,
      name: "GrazNMOff",
      description: "Grazing Non Managed - Off",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "AgeIxT",
      id: 0,
      name: "aixBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AgeIxT",
      id: 1,
      name: "AvgAge",
      description: "Average age of the trees in the forest",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AgeIxT",
      id: 2,
      name: "MaxAge",
      description: "Maximum age of any tree in the forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AlignHT",
      id: 0,
      name: "Left",
      description: "Left",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AlignHT",
      id: 1,
      name: "CenterH",
      description: "Centre",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AlignHT",
      id: 2,
      name: "Right",
      description: "Right",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AlignVT",
      id: 0,
      name: "Top",
      description: "Top",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AlignVT",
      id: 1,
      name: "CenterV",
      description: "Centre",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AlignVT",
      id: 2,
      name: "Bottom",
      description: "Bottom",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 0,
      name: "Undefined",
      description: "Undefined",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 1,
      name: "Ignored",
      description: "Ignore",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 2,
      name: "Forest",
      description: "Forest Land",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 3,
      name: "CrC",
      description: "Cropland Remaining Cropland",
      code: "CrC",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 4,
      name: "GrG",
      description: "Grassland Remaining Grassland",
      code: "GrG",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 5,
      name: "DeforClassic",
      description: "Deforestation",
      code: "D4",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 6,
      name: "DMWet",
      description: "Deforestation Monitor - Wetland",
      code: "DMW",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 7,
      name: "AfforRefor",
      description: "Reforestation",
      code: "AR",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 8,
      name: "ALcF",
      description: "Additional Lands converted to Forest",
      code: "ALcF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 9,
      name: "MUF",
      description: "Multiple Use Forests",
      code: "MUF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 10,
      name: "SparseWoody",
      description: "Sparse Woody",
      code: "SW",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 11,
      name: "Plant",
      description: "Plantations",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 12,
      name: "FrF",
      description: "Forest Remaining Forest",
      code: "FrF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 17,
      name: "WLFL",
      description: "Wetland - flooded land",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 18,
      name: "WLNT",
      description: "Wetland - Non-tidal",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 19,
      name: "GMSparse",
      description: "Grazing Management - Sparse",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 20,
      name: "ERFCeaRvg",
      description: "ERF CEAs (Reveg)",
      code: "ERF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "AreaDefT",
      id: 21,
      name: "Pre90Plant",
      description: "Pre90 Plantations",
      code: "Pr90",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 0,
      name: "NA",
      description: "No events",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 1,
      name: "StartAg",
      description: "Initial Ag regimes",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 2,
      name: "ForestMVG",
      description: "Forest MVG",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 4,
      name: "ForestCPN",
      description: "Forest CPN",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 8,
      name: "AgGrass",
      description: "Grass Regimes",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 32,
      name: "Wildfire",
      description: "With spatial fire",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "AssembleRulesT",
      id: 64,
      name: "ForestPost89",
      description: "Forest Regimes Post 1989",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RootSlouT",
          source: "RootSlouT",
          enumTypeDescription: "tRootSlouGraz",
        },
      ],
    },
    {
      enum: "AvgAirTempT",
      id: 0,
      name: "Blank",
      description: "",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AvgAirTempT",
      id: 1,
      name: "TimeSeries",
      description: "Average Air Temperature time series (button below)",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "AvgAirTempT",
      id: 2,
      name: "Estimate",
      description: "Estimate from min. and range of air temperatures",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "AxisT",
      id: 0,
      name: "Horz",
      description: "Horizontal (X) axis",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "AxisT",
      id: 1,
      name: "Vert",
      description: "Vertical (Y) axis",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "BLAreaT",
      id: 0,
      name: "Cell",
      description: "No average",
      code: "1",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "BLAreaT",
      id: 1,
      name: "Hectare",
      description: "1 hectare",
      code: "4",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "BLAreaT",
      id: 2,
      name: "OneKm",
      description: "100 hectare",
      code: "40",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "BLAreaT",
      id: 3,
      name: "TwoKm",
      description: "400 hectare",
      code: "160",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "BLAreaT",
      id: 4,
      name: "ThreeKm",
      description: "900 hectare",
      code: "360",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "BLAreaT",
      id: 5,
      name: "FiveKm",
      description: "2500 hectare",
      code: "1000",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "ByteOrderT",
      id: 0,
      name: "LSB",
      description: "LSB",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "ByteOrderT",
      id: 1,
      name: "MSB",
      description: "MSB",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "CalendarSystemT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "CalendarSystemT",
      id: 1,
      name: "FixedLength",
      description: "Fixed Length",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "CalendarSystemT",
      id: 2,
      name: "Gregorian",
      description: "Gregorian",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "CollectionT",
      id: 0,
      name: "NotCollection",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "CollectionT",
      id: 1,
      name: "Collection",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "CollectionT",
      id: 2,
      name: "CollectionItem",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "CollectionT",
      id: 3,
      name: "CollectionNode",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 1,
      name: "BigInt",
      description: "xs:long",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 2,
      name: "binary",
      description: "xs:string",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 3,
      name: "bit",
      description: "xs:boolean",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 4,
      name: "char",
      description: "xs:string",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 5,
      name: "datetime",
      description: "xs:dateTime",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 6,
      name: "float",
      description: "xs:float",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 7,
      name: "float(53)",
      description: "xs:float",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 8,
      name: "Int",
      description: "xs:integer",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 9,
      name: "nvarchar",
      description: "xs:string",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 10,
      name: "SmallInt",
      description: "xs:integer",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 11,
      name: "tinyint",
      description: "xs:integer",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "ColXMLType",
      id: 12,
      name: "varchar",
      description: "xs:string",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "CultT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "CultT",
      id: 1,
      name: "None",
      description: "No cultivation",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "CultT",
      id: 2,
      name: "Spot",
      description: "Spot cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "CultT",
      id: 3,
      name: "Strip",
      description: "Strip cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "CultT",
      id: 4,
      name: "Broadcast",
      description: "Broadcast cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "CultT",
      id: 5,
      name: "StripBroad",
      description: "Strip and broadcast",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 0,
      name: "Flo",
      description: "Floating Point",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 1,
      name: "Int",
      description: "Integer",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DataT",
      id: 2,
      name: "Bool",
      description: "Yes/No",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DataT",
      id: 3,
      name: "CStr",
      description: "Static string",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 4,
      name: "Cot",
      description: "Dynamic string",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 5,
      name: "Enum",
      description: "Enumeration",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 6,
      name: "TS",
      description: "Time Series",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 7,
      name: "Wood",
      description: "Wood",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 8,
      name: "Byte",
      description: "Byte",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "DataT",
      id: 9,
      name: "Date",
      description: "Date format",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "DateOriginT",
      id: 0,
      name: "Calendar",
      description: "Calendar date",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DateOriginT",
      id: 1,
      name: "StartSim",
      description: "Since start of simulation",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DateOriginT",
      id: 2,
      name: "Sprout",
      description: "Years since plants sprouted",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DeforMonT",
      id: 0,
      name: "NoClear",
      description: "No Clearing",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DeforMonT",
      id: 1,
      name: "ClearC",
      description: "Clear Confirmed",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DeforMonT",
      id: 2,
      name: "ClearM",
      description: "Clear Monitoring",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DieOffT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DieOffT",
      id: 1,
      name: "GBF",
      description: "Grains, buds, fruit",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DieOffT",
      id: 2,
      name: "Stlk",
      description: "Stalks",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DieOffT",
      id: 3,
      name: "Leaf",
      description: "Leaves",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DieOffT",
      id: 4,
      name: "Cort",
      description: "Coarse roots",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DigestViewTypeT",
      id: 0,
      name: "Scenario",
      description: "Scenario",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DigestViewTypeT",
      id: 1,
      name: "Category",
      description: "Scenario by Category",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DigestViewTypeT",
      id: 2,
      name: "Input",
      description: "Input",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 0,
      name: "Meta",
      description: "Meta",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 1,
      name: "Config",
      description: "Config",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 2,
      name: "Timing",
      description: "Timing",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 3,
      name: "Build",
      description: "Build",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 4,
      name: "Log",
      description: "Log",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 5,
      name: "Site",
      description: "Site",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 6,
      name: "SpecGrpF",
      description: "SpecGrpF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 7,
      name: "SpecSetF",
      description: "SpecSetF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 8,
      name: "SpecSetA",
      description: "SpecSetA",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 9,
      name: "Mulch",
      description: "Mulch",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 10,
      name: "Soil",
      description: "Soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 11,
      name: "Mnrl",
      description: "Mnrl",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 12,
      name: "Init",
      description: "Init",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 17,
      name: "OptiPkg",
      description: "OptiPkg",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 18,
      name: "FileIdSet",
      description: "FileIdSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 19,
      name: "ePlotSet",
      description: "ePlotSet",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 20,
      name: "OutSet",
      description: "OutSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 21,
      name: "OutWinSet",
      description: "OutWinSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 22,
      name: "SpaOutWinSet",
      description: "SpaOutWinSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 23,
      name: "LocnSoil",
      description: "LocnSoil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 24,
      name: "RegimeFSet",
      description: "RegimeFSet",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 25,
      name: "RegimeASet",
      description: "RegimeASet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 26,
      name: "SuiteFSet",
      description: "SuiteFSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 27,
      name: "SuiteASet",
      description: "SuiteASet",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 28,
      name: "YieldASet",
      description: "YieldASet",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 29,
      name: "NpiTyfInputSet",
      description: "NpiTyfInputSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 30,
      name: "StateTyfInputSet",
      description: "StateTyfInputSet",

      enumTypes: [
        {
          cppPrefix: "kA",
          enumTypeName: "GrthModeAT",
          source: "GrthModeAT",
          enumTypeDescription: "GrowthMode",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 31,
      name: "SpeciesTyfInputSet",
      description: "SpeciesTyfInputSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblFT",
          source: "IncrTblFT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 32,
      name: "FireSeasonsSet",
      description: "FireSeasonsSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 33,
      name: "FidoProperties",
      description: "FidoProperties",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStemT",
          source: "InitStemT",
          enumTypeDescription: "StemMassType",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 34,
      name: "PointsOfInterestSet",
      description: "PointsOfInterestSet",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStem2T",
          source: "InitStemT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 35,
      name: "OASysProp",
      description: "OASys Properties",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "InputsListT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 36,
      name: "PerennialSet",
      description: "PerennialSet",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "LandTenureT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 37,
      name: "GrazingSet",
      description: "GrazingSet",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "LineStyleT",
          source: "LineStyleT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 38,
      name: "SimPlotFilesSet",
      description: "SimPlotFilesSet",
      enumTypes: [
        {
          cppPrefix: "klec",
          enumTypeName: "LogEntryClassT",
          source: "LogEntryClassT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 39,
      name: "InitCondFSet",
      description: "InitCondFSet",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "DocSectionT",
      id: 40,
      name: "FireFrequencySet",
      description: "FireFrequencySet",
      enumTypes: [
        {
          cppPrefix: "kmbr",
          enumTypeName: "MaxBMRngT",
          source: "MaxBMRngT",
          enumTypeDescription: "MaxBiomassRange",
        },
      ],
    },
    {
      enum: "DocT",
      id: 0,
      name: "None",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DocT",
      id: 1,
      name: "Plo",
      description: "Plot",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DocT",
      id: 2,
      name: "Est",
      description: "Estate",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DocT",
      id: 4,
      name: "Dba",
      description: "Dba",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "DocT",
      id: 8,
      name: "Sim",
      description: "Sim",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "DocT",
      id: 32,
      name: "Server",
      description: "Server",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "DocT",
      id: 64,
      name: "SpaPlot",
      description: "Header",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RootSlouT",
          source: "RootSlouT",
          enumTypeDescription: "tRootSlouGraz",
        },
      ],
    },
    {
      enum: "DsbnT",
      id: 0,
      name: "Uniform",
      description: "Uniform",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "DsbnT",
      id: 1,
      name: "Triang",
      description: "Trianglar",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "DsbnT",
      id: 2,
      name: "TNormal",
      description: "Truncated normal",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "DsbnT",
      id: 3,
      name: "TLogNorm",
      description: "Truncated Lognormal",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "EconT",
      id: 0,
      name: "econ0",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "EconT",
      id: 1,
      name: "econFlows",
      description: "Flows",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "EconT",
      id: 2,
      name: "econCPrice",
      description: "C Price",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "EconT",
      id: 3,
      name: "econCIncm",
      description: "C Income",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "EconT",
      id: 4,
      name: "econFlowsCIncm",
      description: "Flow C Income",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "EconT",
      id: 5,
      name: "econNPV",
      description: "NPV",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "EventQT",
      id: 0,
      name: "Doc",
      description: "Doc Queue",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "EventQT",
      id: 1,
      name: "SpecF",
      description: "Forest Species Events",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "EventQT",
      id: 2,
      name: "SpecA",
      description: "Ag Species Events",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ExtrapT",
      id: 0,
      name: "NearestYr",
      description: "Use nearest year in table",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "ExtrapT",
      id: 1,
      name: "CycleYrs",
      description: "Cycle table data across all time",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "ExtrapT",
      id: 2,
      name: "AvgYr",
      description: "Use average year of data",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ExtrapT",
      id: 3,
      name: "AvgAllYrs",
      description: "Use average data across all time",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 0,
      name: "Blank",
      description: "",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 1,
      name: "None",
      description: "None",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 2,
      name: "AtEstab",
      description: "At establishment",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 3,
      name: "1Late",
      description: "1 Late age fertilisation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 4,
      name: "2Late",
      description: "2 Late age fertilisations",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 5,
      name: "3Late",
      description: "3 Late age fertilisations",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 6,
      name: "4Late",
      description: "4 Late age fertilisations",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 7,
      name: "Est1Late",
      description: "At establishment and 1 late age fertilisation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 8,
      name: "Est2Late",
      description: "At establishment and 2 late age fertilisations",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 9,
      name: "Est3Late",
      description: "At establishment and 3 late age fertilisations",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "FertApplnT",
      id: 10,
      name: "Est4Late",
      description: "At establishment and 4 late age fertilisations",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 0,
      name: "Plo",
      description: ".plo",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 1,
      name: "Est",
      description: ".est",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FileT",
      id: 2,
      name: "Spa",
      description: ".spa",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FileT",
      id: 3,
      name: "Dba",
      description: ".dba",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 4,
      name: "Sim",
      description: ".sim",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 5,
      name: "Text",
      description: ".txt",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 6,
      name: "Rtf",
      description: ".rtf",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 7,
      name: "Excel",
      description: ".xlsx",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 8,
      name: "Bbl",
      description: ".bbl",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 9,
      name: "Bil",
      description: ".bil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 10,
      name: "Grd",
      description: ".grd",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 11,
      name: "CSV",
      description: ".csv",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 12,
      name: "Combined",
      description: ".cmb",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 17,
      name: "TIFF",
      description: ".tif",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "FileT",
      id: 18,
      name: "Fragment",
      description: "",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 19,
      name: "Pld",
      description: ".pld",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "FileT",
      id: 20,
      name: "Unknown",
      description: "Unknown",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "FireSeasonT",
      id: 0,
      name: "NA",
      description: "NA",
      code: "",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FireSeasonT",
      id: 1,
      name: "Early",
      description: "Early Season Wildfire",
      code: "EW",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FireSeasonT",
      id: 2,
      name: "Late",
      description: "Late Season Wildfire",
      code: "LW",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FireSeasonT",
      id: 3,
      name: "PrescribedBurn",
      description: "Prescribed Burn",
      code: "PB",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FireSeasonT",
      id: 4,
      name: "NaturalDisturbance",
      description: "Natural disturbance",
      code: "ND",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 0,
      name: "NA",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 1,
      name: "EvNotTS",
      description: "Use events (not time series) for",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 2,
      name: "PloConfig",
      description: "in plot configuration",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 3,
      name: "IrrigMin",
      description: "irrigation % (guaranteed min % of topsoil water capacity)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 4,
      name: "IrrigDef",
      description: "irrigation (applied regardless of conditions)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 5,
      name: "TreeGrMult",
      description: "Tree growth multiplier for",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 6,
      name: "CropGrMult",
      description: "Crop growth multiplier for",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 7,
      name: "TreAgeMaxGr",
      description: "Tree age of max growth in tree yield formula (G)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 8,
      name: "NonEndSpecMult",
      description: "Non-endemic species multiplier",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 9,
      name: "RelAllocs",
      description: "Relative allocations to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 10,
      name: "CPerc",
      description: "Carbon % of (dry)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 11,
      name: "TurnPerc",
      description: "Turnover % of",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 12,
      name: "LossToStemMort",
      description: "loss to stem loss) due to tree mortality",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 17,
      name: "BkdnSensTemp",
      description: "Breakdown sensitive to temperature and moisture)",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 18,
      name: "SensBkdnTo",
      description: "Sensitivity of breakdown to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 19,
      name: "DecompPerc",
      description: "Decomposition % of",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 20,
      name: "WhileInUse",
      description: "(while in use)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 21,
      name: "BurntBioenergy",
      description: "burnt for bioenergy",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 22,
      name: "ToLandfill",
      description: "to landfill",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 23,
      name: "InLandfill",
      description: "in landfill",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 24,
      name: "MinCN",
      description: "Minimum C:N ratio of",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 25,
      name: "MaxCN",
      description: "Maximum C:N ratio of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 26,
      name: "AtGermination",
      description: "at germination",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 27,
      name: "DuringMaturity",
      description: "during maturity",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 28,
      name: "StJointTiller",
      description: "at start of jointing/tillering",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 29,
      name: "StJointBoot",
      description: "at start of flowering/booting",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 30,
      name: "DaysEarlyGRForCN",
      description: "Days of early growth, for C:N ratio of",
      enumTypes: [
        {
          cppPrefix: "kA",
          enumTypeName: "GrthModeAT",
          source: "GrthModeAT",
          enumTypeDescription: "GrowthMode",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 31,
      name: "EffcEnergy",
      description: "Efficiency of using energy from",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblFT",
          source: "IncrTblFT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 32,
      name: "EffcEnergyFossil",
      description: "Efficiency of using energy from fossil fuel displaced by",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 33,
      name: "CEmissionRate",
      description: "C-emission rate of",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStemT",
          source: "InitStemT",
          enumTypeDescription: "StemMassType",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 34,
      name: "CEmissionRateDisp",
      description: "C-emission rate of fossil fuel displaced by",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStem2T",
          source: "InitStemT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 35,
      name: "CEmissionRateMake",
      description: "C-emission rate of fossil fuel used to make",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "InputsListT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 36,
      name: "CEmissionRateMakeD",
      description:
        "C-emission rate of fossil fuel used to make product displaced by",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "LandTenureT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 37,
      name: "CInFossil",
      description: "Carbon in fossil fuel",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "LineStyleT",
          source: "LineStyleT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 38,
      name: "EnergyFossil",
      description: "Energy in fossil fuel used to make",
      enumTypes: [
        {
          cppPrefix: "klec",
          enumTypeName: "LogEntryClassT",
          source: "LogEntryClassT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 39,
      name: "EnergyFossilDspl",
      description: "Energy in fossil fuel used to make product displaced by",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 40,
      name: "MDispProdDivMOf",
      description: "Mass of displaced product / Mass of",
      enumTypes: [
        {
          cppPrefix: "kmbr",
          enumTypeName: "MaxBMRngT",
          source: "MaxBMRngT",
          enumTypeDescription: "MaxBiomassRange",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 41,
      name: "LifetimeDispProd",
      description: "/ Mean lifetime of displaced product",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ModelT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 42,
      name: "LifeTimeOf",
      description: "Mean lifetime of",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "MonthT",
          source: "MonthT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 43,
      name: "MIncr",
      description: "mass increments",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "NFGroupT",
          source: "NFGroupT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 44,
      name: "StemLossCropMort",
      description: "loss to stem loss, due to crop mortality",
      enumTypes: [
        {
          cppPrefix: "knpi",
          enumTypeName: "NPIRegionT",
          source: "NPIRegionT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 45,
      name: "DaysJointTillCN",
      description: "Days to start of jointing/tillering, for C:N ratio of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "OnOffT",
          source: "OnOffT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 46,
      name: "DaysJointBootCN",
      description: "Days to start of flowering/booting, for C:N ratio of",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreqT",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 47,
      name: "DaysGrainfillCN",
      description: "Days to start of grainfill, for C:N ratio of",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreq2T",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 48,
      name: "PMCMInputs",
      description: "plant mulch carbon mass inputs to the mulch",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PixelDataT",
          source: "PixelDataT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 49,
      name: "HumfPerc",
      description: "Humification % of",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationTypeT",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 50,
      name: "MicrMulcF",
      description: "microbial mulch (forest)",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationType2T",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 51,
      name: "PlntMulcF",
      description: "plant mulch (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotT",
          source: "PlotT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 52,
      name: "MicrMulcA",
      description: "microbial mulch (agricultural)",
      enumTypes: [
        {
          enumTypeName: "Plot2T",
          source: "Plot2T",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 53,
      name: "PlntMulcA",
      description: "plant mulch (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotSortT",
          source: "PlotSortT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 57,
      name: "PercHumf",
      description: "% of humified",
      enumTypes: [
        {
          cppPrefix: "kppwc",
          enumTypeName: "PPWeedCntT",
          source: "PPWeedCntT",
          enumTypeDescription: "PostPlantingWeedControl",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 58,
      name: "ManureCTo",
      description: "% of manure carbon that goes to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PrevLUseT",
          source: "PrevLUseT",
          enumTypeDescription: "PreviousUse",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 59,
      name: "ToRPMF",
      description: "to RPM (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PriceT",
          source: "PriceT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 60,
      name: "ToDPMF",
      description: "to DPM (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PruneT",
          source: "PruneT",
          enumTypeDescription: "Prune",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 61,
      name: "ToRPMA",
      description: "to RPM (agricultural)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "RegimeT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 62,
      name: "ToDPMA",
      description: "to DPM (agricultural)",
      enumTypes: [
        {
          cppPrefix: "krga",
          enumTypeName: "RgaCategoryT",
          source: "RgaCategoryT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 63,
      name: "PercBroken",
      description: "% of broken-down",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RgaConfigT",
          source: "RgaConfigT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 64,
      name: "F",
      description: "(forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RootSlouT",
          source: "RootSlouT",
          enumTypeDescription: "tRootSlouGraz",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 65,
      name: "A",
      description: "(agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVersT",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 66,
      name: "ManureOffsAdd",
      description: "Manure from offsite applied to the",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVers2T",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 67,
      name: "DcmpRateMult",
      description: "Decomposition rate multiplier for",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SampleT",
          source: "SampleT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 68,
      name: "PercDcmpSolids",
      description: "% of decomposed DPM, RPM, BIO-F, BIO-S solids -&gt;",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SfStatusT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 69,
      name: "PercDcmpHUMSoilds",
      description: "% of decomposed HUM solids -&gt;",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SimStepsT",
          source: "SimStepsT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 70,
      name: "PercN",
      description: "% of N in",
      enumTypes: [
        {
          cppPrefix: "kspt",
          enumTypeName: "SitePrepT",
          source: "SitePrepT",
          enumTypeDescription: "SitePreparation",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 71,
      name: "ToTreeMychF",
      description: "that moves to trees by mycorrhizal uptake (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SoilT",
          source: "SoilT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 72,
      name: "ToTreeMychA",
      description: "that moves to crop by mycorrhizal uptake (agricultural)",
      enumTypes: [
        {
          cppPrefix: "ksand",
          enumTypeName: "SoilSandyT",
          source: "SoilSandyT",
          enumTypeDescription: "SoilSandiness",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 73,
      name: "AtmosDepo",
      description: "Atmospheric deposition,",
      enumTypes: [
        {
          cppPrefix: "ksp",
          enumTypeName: "SoilTempT",
          source: "SoilTempT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 74,
      name: "NSymbioFix",
      description: "Non-symbiotic fixation,",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaHdrT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 75,
      name: "NitrEachDayF",
      description: "nitrified each day (forest)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaRepT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 76,
      name: "NitrEachDayA",
      description: "nitrified each day (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecT",
          source: "SpecT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 77,
      name: "InNitrTempFnF",
      description: "in the nitrification temperature function (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecAgCatT",
          source: "SpecAgCatT",
          enumTypeDescription: "SpecAgCat",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 78,
      name: "InNitrTempFnA",
      description: "in the nitrification temperature function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpecFrCatT",
          source: "SpecFrCatT",
          enumTypeDescription: "ForestCategory",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 79,
      name: "InNitrPHFnF",
      description: "in the nitrification pH function (forest)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateT",
          source: "StateT",
          enumTypeDescription: "States",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 80,
      name: "InNitrPHFnA",
      description: "in the nitrification pH function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateForT",
          source: "StateT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 81,
      name: "InNitrWaterFnF",
      description: ", in the nitrification soil water function (forest)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateIbraSoilT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 82,
      name: "InNitrWaterFnA",
      description: ", in the nitrification soil water function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StatusT",
          source: "StatusT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 83,
      name: "0NitrWaterFnF",
      description:
        "for zero clay, in the nitrification soil water function (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StdT",
          source: "StdT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 84,
      name: "0NitrWaterFnA",
      description:
        "for zero clay, in the nitrification soil water function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "kstock",
          enumTypeName: "StockingT",
          source: "StockingT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 85,
      name: "CutoffNitrWaterFnF",
      description:
        "at the cutoff clay percentage, in the nitrification soil water function (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SystemT",
          source: "SystemT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 86,
      name: "CutoffNitrWaterFnA",
      description:
        "at the cutoff clay percentage, in the nitrification soil water function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ThinT",
          source: "ThinT",
          enumTypeDescription: "Thin",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 87,
      name: "InDeniCO2FnF",
      description: "in the denitrification CO2 function (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TickT",
          source: "TickT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 88,
      name: "InDeniCO2FnA",
      description: "in the denitrification CO2 function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOriginT",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 89,
      name: "InDeniNO3FnF",
      description: "in the denitrification NO3 function (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin2T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 90,
      name: "InDeniNO3FnA",
      description: "in the denitrification NO3 function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin3T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 91,
      name: "InDeniWaterFnF",
      description: "in the denitrification soil water function (forest)",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "TitleT",
          source: "TitleT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 92,
      name: "InDeniWaterFnA",
      description: "in the denitrification soil water function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProdT",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 93,
      name: "InDeniRCO2FnF",
      description: "in the denitrification CO2 ratio function (forest)",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProd2T",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 94,
      name: "InDeniRCO2FnA",
      description: "in the denitrification CO2 ratio function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TreeProd3T",
          source: "TreeProd3T",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 95,
      name: "InDeniRNO3FnF",
      description: "in the denitrification NO3 ratio function (forest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "VPDTempT",
          source: "VPDTempT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 96,
      name: "InDeniRNO3FnA",
      description: "in the denitrification NO3 ratio function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "YesNoT",
          source: "YesNoT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 97,
      name: "InDeniRWaterFnF",
      description: "in the denitrification soil water ratio function (forest)",
      enumTypes: [
        {
          cppPrefix: "kyt",
          enumTypeName: "YieldT",
          source: "YieldT",
          enumTypeDescription: "YieldType",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 98,
      name: "InDeniRWaterFnA",
      description:
        "in the denitrification soil water ratio function (agricultural)",
      enumTypes: [
        {
          cppPrefix: "kSpfl",
          enumTypeName: "SpflErrT",
          source: "SpflErrT",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 99,
      name: "NBondEmitF",
      description: "nitrogen boundary-line emissions (forest)",
      enumTypes: [
        {
          cppPrefix: "krl",
          enumTypeName: "RotLenT",
          source: "RotLenT",
          enumTypeDescription: "RotationLength",
        },
      ],
    },
    {
      enum: "FixiT",
      id: 100,
      name: "NBondEmitA",
      description: "nitrogen boundary-line emissions (agricultural)",
      enumTypes: [
        {
          cppPrefix: "kF",
          enumTypeName: "GrthModeFT",
          source: "GrthModeFT",
        },
      ],
    },

    {
      enum: "FixoT",
      id: 0,
      name: "NA",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 1,
      name: "RateProd",
      description: "Production rate of",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 2,
      name: "RateProdL",
      description: "Rate of production of new plant material &gt;",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 3,
      name: "RateTurn",
      description: "Turnover rate of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 4,
      name: "RateTurnL",
      description: "Rate of turnover (shedding) of plant material &gt;",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 5,
      name: "RateYield",
      description: "Yield rate of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 6,
      name: "RateYieldL",
      description: "Net rate of production and turnover of plant material &gt;",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 7,
      name: "In",
      description: "(is an input)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 8,
      name: "M",
      description: "Mass of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 9,
      name: "ExclBB",
      description: "(excluding branches and bark)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 10,
      name: "Perc",
      description: "Percentage of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 11,
      name: "Fr",
      description: ", forest",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 12,
      name: "Ag",
      description: ", agricultural",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 17,
      name: "CMOfAg",
      description: "C mass of agricultural",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 18,
      name: "NMOf",
      description: "N mass of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 19,
      name: "NMOfFr",
      description: "N mass of forest",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 20,
      name: "NMOfAg",
      description: "N mass of agricultural",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 21,
      name: "CMDispEner",
      description: "C mass of fossil fuel displaced by bioenergy from",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 22,
      name: "CMDispUse",
      description: "C mass of fossil fuel displaced due to use of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 23,
      name: "CMDispBy",
      description: "C mass of fossil fuel displaced by",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 24,
      name: "CMSeq",
      description: "C mass sequestered due to",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 25,
      name: "CMSeqL",
      description: "C mass removed from atmosphere due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 26,
      name: "CMEmitNet",
      description: "Net C mass emitted",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 27,
      name: "CMEmitNetL",
      description: "Net C mass emitted to atmosphere",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 28,
      name: "CMSeqNet",
      description: "Net C mass sequestered",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 29,
      name: "CMSeqNetL",
      description: "Net C mass removed from atmosphere",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 30,
      name: "NMSeq",
      description: "N mass sequestered due to",
      enumTypes: [
        {
          cppPrefix: "kA",
          enumTypeName: "GrthModeAT",
          source: "GrthModeAT",
          enumTypeDescription: "GrowthMode",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 31,
      name: "NMSeqL",
      description: "N mass removed from atmosphere due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblFT",
          source: "IncrTblFT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 32,
      name: "NMEmitNet",
      description: "Net N mass emitted",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 33,
      name: "NMEmitNetL",
      description: "Net N mass emitted to atmosphere",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStemT",
          source: "InitStemT",
          enumTypeDescription: "StemMassType",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 34,
      name: "NMSeqNet",
      description: "Net N mass sequestered",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStem2T",
          source: "InitStemT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 35,
      name: "NMSeqNetL",
      description: "Net N mass removed from atmosphere",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "InputsListT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 36,
      name: "CEmitDue",
      description: "C mass emitted due to",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "LandTenureT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 37,
      name: "CEmitDueL",
      description: "C mass emitted to atmosphere due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "LineStyleT",
          source: "LineStyleT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 38,
      name: "CEmit",
      description: "C mass emitted",
      enumTypes: [
        {
          cppPrefix: "klec",
          enumTypeName: "LogEntryClassT",
          source: "LogEntryClassT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 39,
      name: "CEmitL",
      description: "C mass emitted to atmosphere",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 40,
      name: "NEmitDue",
      description: "N mass emitted due to",
      enumTypes: [
        {
          cppPrefix: "kmbr",
          enumTypeName: "MaxBMRngT",
          source: "MaxBMRngT",
          enumTypeDescription: "MaxBiomassRange",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 41,
      name: "NEmitDueL",
      description: "N mass emitted to atmosphere due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ModelT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 42,
      name: "NInN2EmitDue",
      description: "N mass in N2 emitted due to",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "MonthT",
          source: "MonthT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 43,
      name: "NInN2EmitDueL",
      description: "N mass in N2 emitted to atmosphere due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "NFGroupT",
          source: "NFGroupT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 44,
      name: "NInN2OEmitDue",
      description: "N mass in N20 emitted due to",
      enumTypes: [
        {
          cppPrefix: "knpi",
          enumTypeName: "NPIRegionT",
          source: "NPIRegionT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 45,
      name: "NInN2OEmitDueL",
      description: "N mass in N20 emitted to atmosphere due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "OnOffT",
          source: "OnOffT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 46,
      name: "CMFrom",
      description: "C mass moved from",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreqT",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 47,
      name: "CMFromFr",
      description: "C mass moved from forest",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreq2T",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 48,
      name: "CMFromAg",
      description: "C mass moved from agricultural",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PixelDataT",
          source: "PixelDataT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 49,
      name: "NMFrom",
      description: "N mass moved from",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationTypeT",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 50,
      name: "NMFromFr",
      description: "N mass moved from forest",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationType2T",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 51,
      name: "NMFromAg",
      description: "N mass moved from agricultural",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotT",
          source: "PlotT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 52,
      name: "CN",
      description: "C:N ratio of",
      enumTypes: [
        {
          enumTypeName: "Plot2T",
          source: "Plot2T",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 53,
      name: "CNAg",
      description: "C:N ratio of agricultural",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotSortT",
          source: "PlotSortT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 57,
      name: "PMInSoilPlow",
      description: "plant matter in the soil due to plowing",
      enumTypes: [
        {
          cppPrefix: "kppwc",
          enumTypeName: "PPWeedCntT",
          source: "PPWeedCntT",
          enumTypeDescription: "PostPlantingWeedControl",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 58,
      name: "PMInSoil",
      description: "plant matter in the soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PrevLUseT",
          source: "PrevLUseT",
          enumTypeDescription: "PreviousUse",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 59,
      name: "XBioInSoil",
      description: "decomposing biomass in the soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PriceT",
          source: "PriceT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 60,
      name: "BioInSoil",
      description: "biomass (fast decomposing + slow decomposing) in the soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PruneT",
          source: "PruneT",
          enumTypeDescription: "Prune",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 61,
      name: "HUMInsoil",
      description: "humified organic matter in the soil",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "RegimeT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 62,
      name: "DueBkdn",
      description: "due to breakdown",
      enumTypes: [
        {
          cppPrefix: "krga",
          enumTypeName: "RgaCategoryT",
          source: "RgaCategoryT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 63,
      name: "DueTurn",
      description: "due to turnover",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RgaConfigT",
          source: "RgaConfigT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 64,
      name: "DueFire",
      description: "due to fire",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RootSlouT",
          source: "RootSlouT",
          enumTypeDescription: "tRootSlouGraz",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 65,
      name: "DueDcmp",
      description: "due to decomposition",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVersT",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 66,
      name: "DueHumf",
      description: "due to humification",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVers2T",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 67,
      name: "DueEncp",
      description: "due to encapsulation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SampleT",
          source: "SampleT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 68,
      name: "DueHarv",
      description: "due to harvest",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SfStatusT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 69,
      name: "DueThin",
      description: "due to thinning",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SimStepsT",
          source: "SimStepsT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 70,
      name: "BalAgToFr",
      description: "rebalanced from agricultural to forest",
      enumTypes: [
        {
          cppPrefix: "kspt",
          enumTypeName: "SitePrepT",
          source: "SitePrepT",
          enumTypeDescription: "SitePreparation",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 71,
      name: "BalAgToFrL",
      description:
        "moved from agricultural to forest due to those mineral pools effectively being one common pool",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SoilT",
          source: "SoilT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 72,
      name: "NMAmmIn",
      description: "N mass in ammonium in",
      enumTypes: [
        {
          cppPrefix: "ksand",
          enumTypeName: "SoilSandyT",
          source: "SoilSandyT",
          enumTypeDescription: "SoilSandiness",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 73,
      name: "NMNtrIn",
      description: "N mass in nitrate in",
      enumTypes: [
        {
          cppPrefix: "ksp",
          enumTypeName: "SoilTempT",
          source: "SoilTempT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 74,
      name: "SoilShallowL",
      description: "soil, available to the organic soil",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaHdrT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 75,
      name: "SoilDeepL",
      description: "soil, available to the plants but not to the organic soil",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaRepT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 76,
      name: "NRatio",
      description: "N2/N20 N mass ratio in denitrification emissions,",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecT",
          source: "SpecT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 77,
      name: "NRatioL",
      description:
        "Ratio of N mass in N2 to N mass in N20 in N mass emitted by minerals due to denitrification,",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecAgCatT",
          source: "SpecAgCatT",
          enumTypeDescription: "SpecAgCat",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 78,
      name: "DEachStep",
      description: ", during each output step",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpecFrCatT",
          source: "SpecFrCatT",
          enumTypeDescription: "ForestCategory",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 79,
      name: "DFromSt",
      description: ", cumulatively from start of simulation",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateT",
          source: "StateT",
          enumTypeDescription: "States",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 80,
      name: "ToDate",
      description: ", cumulative to date",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateForT",
          source: "StateT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 81,
      name: "Transported",
      description: ", transported to the mill",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateIbraSoilT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 82,
      name: "Wood",
      description: "Stems and branches, log grade",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StatusT",
          source: "StatusT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 83,
      name: "Bark",
      description: "Bark, log grade",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StdT",
          source: "StdT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 84,
      name: "Fuel",
      description: "Biofuel, log grade",
      enumTypes: [
        {
          cppPrefix: "kstock",
          enumTypeName: "StockingT",
          source: "StockingT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 85,
      name: "Prod",
      description: "Produce, crop grade",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SystemT",
          source: "SystemT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 86,
      name: "Hssi",
      description: "Hay, straw, and silage, crop grade",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ThinT",
          source: "ThinT",
          enumTypeDescription: "Thin",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 87,
      name: "Fual",
      description: "Biofuel, crop grade",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TickT",
          source: "TickT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 88,
      name: "CIncmFrom",
      description: "Carbon income from",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOriginT",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 89,
      name: "CIncmFromChngsIn",
      description: "Income or expense due to changes in the carbon mass of",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin2T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 90,
      name: "NValOf",
      description: "Value of the nitrogen",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin3T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 91,
      name: "InNitrF",
      description: ", in forest nitrification",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "TitleT",
          source: "TitleT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 92,
      name: "InDeniF",
      description: ", in forest denitrification",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProdT",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 93,
      name: "InNitrA",
      description: ", in agricultural nitrification",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProd2T",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 94,
      name: "InDeniA",
      description: ", in agricultural denitrification",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TreeProd3T",
          source: "TreeProd3T",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 95,
      name: "NValProduct",
      description: "Combined value of the three nitrogen functions:",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "VPDTempT",
          source: "VPDTempT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 96,
      name: "NMNitrDueTo",
      description: "Nitrified N mass due to",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "YesNoT",
          source: "YesNoT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 97,
      name: "NMNitrDueToL",
      description:
        "N mass moved from shallow ammonium to shallow nitrate by nitrification due to",
      enumTypes: [
        {
          cppPrefix: "kyt",
          enumTypeName: "YieldT",
          source: "YieldT",
          enumTypeDescription: "YieldType",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 98,
      name: "EndOfStep",
      description: "(at end of step)",
      enumTypes: [
        {
          cppPrefix: "kSpfl",
          enumTypeName: "SpflErrT",
          source: "SpflErrT",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 99,
      name: "DueDieO",
      description: "due to die-off",
      enumTypes: [
        {
          cppPrefix: "krl",
          enumTypeName: "RotLenT",
          source: "RotLenT",
          enumTypeDescription: "RotationLength",
        },
      ],
    },
    {
      enum: "FixoT",
      id: 100,
      name: "DueGrth",
      description: "due to growth",
      enumTypes: [
        {
          cppPrefix: "kF",
          enumTypeName: "GrthModeFT",
          source: "GrthModeFT",
        },
      ],
    },

    {
      enum: "FontPitchT",
      id: 0,
      name: "Default",
      description: "Default",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FontPitchT",
      id: 1,
      name: "Variable",
      description: "Variable",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FontPitchT",
      id: 2,
      name: "Fixed",
      description: "Fixed",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FontStyleT",
      id: 0,
      name: "Plain",
      description: "Plain",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FontStyleT",
      id: 1,
      name: "Bold",
      description: "Bold",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FontStyleT",
      id: 2,
      name: "Italic",
      description: "Italic",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FontStyleT",
      id: 3,
      name: "Underline",
      description: "Underline",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FontStyleT",
      id: 4,
      name: "Strikeout",
      description: "Strikeout",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 1,
      name: "IntensGrazOn",
      description: "Intensive grazing on",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 2,
      name: "IntensGrazOff",
      description: "Intensive grazing off",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 3,
      name: "Chop",
      description: "Chopper rolling",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 4,
      name: "BroadBurn",
      description: "Broadcast burn",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 5,
      name: "WindrowBurn",
      description: "Windrow and burn",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 6,
      name: "SpotCult",
      description: "Spot Cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 7,
      name: "StripCult",
      description: "Strip cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 8,
      name: "BroadCult",
      description: "Broadcast cultivation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 9,
      name: "SpotWC",
      description: "Spot weed control",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 10,
      name: "StripWC",
      description: "Strip weed control",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 11,
      name: "BlanketWC",
      description: "Blanket weed control",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 12,
      name: "BurnGrass",
      description: "Burning of grass cover",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 17,
      name: "PlantTreesNat",
      description: "Plant trees - initial stocking natural regeneration",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 18,
      name: "Type2FertMod",
      description: "Type 2 fertility modifier",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 19,
      name: "Type1Response",
      description: "Type 1 response to planting method",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 20,
      name: "ManPPWCHarv",
      description: "Manual post planting weed control (Harvest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 21,
      name: "ManPPWCHerb",
      description: "Manual post planting weed control (Herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 22,
      name: "StripPPWCHarv",
      description: "Strip post planting weed control (Harvest)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 23,
      name: "StripPPWCHerb",
      description: "Strip post planting weed control (Herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 24,
      name: "BlanketPPWC",
      description: "Blanket post planting weed control",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 25,
      name: "GrazReIntro",
      description: "Grazing re-introduced",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 26,
      name: "GrazOff",
      description: "Grazing off",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 27,
      name: "WCheavy",
      description: "Weed control heavy",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 28,
      name: "WCNormal",
      description: "Weed control normal",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 29,
      name: "WCLight",
      description: "Weed control light",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 30,
      name: "FertEstablish",
      description: "Fertiliser application at establishment",
      enumTypes: [
        {
          cppPrefix: "kA",
          enumTypeName: "GrthModeAT",
          source: "GrthModeAT",
          enumTypeDescription: "GrowthMode",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 31,
      name: "FertMidRot1",
      description: "Fertiliser application mid-rotation 1",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblFT",
          source: "IncrTblFT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 32,
      name: "FertMidRot2",
      description: "Fertiliser application mid-rotation 2",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 33,
      name: "FertMidRot3",
      description: "Fertiliser application mid rotation 3",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStemT",
          source: "InitStemT",
          enumTypeDescription: "StemMassType",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 34,
      name: "WholPClear",
      description: "Forest percentage change (100%-0%)",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStem2T",
          source: "InitStemT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 35,
      name: "Thin1",
      description: "Thin 1",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "InputsListT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 36,
      name: "Thin2",
      description: "Thin 2",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "LandTenureT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 37,
      name: "Thin3",
      description: "Thin 3",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "LineStyleT",
          source: "LineStyleT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 38,
      name: "Thin4",
      description: "Thin 4",
      enumTypes: [
        {
          cppPrefix: "klec",
          enumTypeName: "LogEntryClassT",
          source: "LogEntryClassT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 39,
      name: "Thin5",
      description: "Thin 5",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 40,
      name: "Thin6",
      description: "Thin Clearing",
      enumTypes: [
        {
          cppPrefix: "kmbr",
          enumTypeName: "MaxBMRngT",
          source: "MaxBMRngT",
          enumTypeDescription: "MaxBiomassRange",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 41,
      name: "Prune1",
      description: "Prune 1",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ModelT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 42,
      name: "Prune2",
      description: "Prune 2",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "MonthT",
          source: "MonthT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 43,
      name: "Prune3",
      description: "Prune 3",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "NFGroupT",
          source: "NFGroupT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 44,
      name: "Prune4",
      description: "Prune 4",
      enumTypes: [
        {
          cppPrefix: "knpi",
          enumTypeName: "NPIRegionT",
          source: "NPIRegionT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 45,
      name: "Burn1",
      description: "Prescribed burning 1",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "OnOffT",
          source: "OnOffT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 46,
      name: "Burn2",
      description: "Prescribed burning 2",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreqT",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 47,
      name: "Burn3",
      description: "Prescribed burning 3",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreq2T",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 48,
      name: "WFireKilled",
      description: "Wildfire - trees killed",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PixelDataT",
          source: "PixelDataT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 49,
      name: "WFireNotKilled",
      description: "Wildfire - trees not killed",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationTypeT",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 50,
      name: "InitClrNoProd",
      description: "Initial clearing : no products",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationType2T",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 51,
      name: "InitClrProdRcvy",
      description: "Initial clearing : product recovery",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotT",
          source: "PlotT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 52,
      name: "Windthrow",
      description: "Windthrow",
      enumTypes: [
        {
          enumTypeName: "Plot2T",
          source: "Plot2T",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 53,
      name: "PAndDKilled",
      description: "Pest and disease - Trees killed",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotSortT",
          source: "PlotSortT",
        },
      ],
    },
    {
      enum: "FrAutoQT",
      id: 57,
      name: "Coppice",
      description: "Coppice",
      enumTypes: [
        {
          cppPrefix: "kppwc",
          enumTypeName: "PPWeedCntT",
          source: "PPWeedCntT",
          enumTypeDescription: "PostPlantingWeedControl",
        },
      ],
    },
    {
      enum: "FrFracT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FrFracT",
      id: 1,
      name: "FracConst",
      description: "Equal to constant",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FrFracT",
      id: 2,
      name: "Frac3PG",
      description: "Equal to 3PG canopyFrac",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FrFracT",
      id: 3,
      name: "FracTYF",
      description: "By tree yield formula",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FrFracT",
      id: 4,
      name: "FracAge",
      description: "By age",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 0,
      name: "Unknown",
      description: "Unknown",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 1,
      name: "BBL",
      description: "BBL",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 2,
      name: "BIL",
      description: "BIL",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 3,
      name: "GRD",
      description: "GRD",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 4,
      name: "CMB",
      description: "CMB",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 5,
      name: "INT",
      description: "INT",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 6,
      name: "BLU",
      description: "BLU",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "FullCAMLayoutT",
      id: 7,
      name: "MMB",
      description: "MMB",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 0,
      name: "NoPlot",
      description: "No Plot File",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 1,
      name: "POIName",
      description: "Use POI Name",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 2,
      name: "LatLong",
      description: "Use Lat/Long",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 3,
      name: "LatLongWoodi",
      description: "Use Lat/Long/Woodi",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 4,
      name: "NameLatLong",
      description: "Use POI Name/Lat/Long",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 5,
      name: "SimNameLatLong",
      description: "Use Sim Name/Lat/Long",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 6,
      name: "TileNameLatLong",
      description: "Use Tile/POI Name/Lat/Long",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "GeneratePloT",
      id: 7,
      name: "TileSimNameLatLong",
      description: "Use Tile/Sim Name/Lat/Long",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "GrazerT",
      id: 0,
      name: "Managed",
      description: "Managed",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "GrazerT",
      id: 1,
      name: "NonManaged",
      description: "Non Managed",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "GrazT",
      id: 0,
      name: "GrazByM",
      description: "Crop masses",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "GrazT",
      id: 1,
      name: "GrazByFrac",
      description: "Fractions of current crop",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "GrazT",
      id: 2,
      name: "GrazByFracNPP",
      description: "Fractions of NPP",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "GrazT",
      id: 3,
      name: "GrazOff",
      description: "No grazing",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "GrthModeAT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "GrthModeAT",
      id: 1,
      name: "Yield",
      description: "Yields (Annual)",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "GrthModeAT",
      id: 2,
      name: "NPP",
      description: "Net Primary Production (NPP)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "GrthModeAT",
      id: 3,
      name: "Perennial",
      description: "Yields (Perennial)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "GrthModeAT",
      id: 4,
      name: "Linear",
      description: "Yields (Linear)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "GrthModeFT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "GrthModeFT",
      id: 1,
      name: "Yield",
      description: "Yields",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "GrthModeFT",
      id: 2,
      name: "NPP",
      description: "Net Primary Production (NPP)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 0,
      name: "",
      description: "",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 1,
      name: "MDD",
      description: "Murray-Darling Depression",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 2,
      name: "NCP",
      description: "Naracoorte Coastal Plain",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 3,
      name: "VVP",
      description: "Victorian Volcanic Plain",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 4,
      name: "SCP",
      description: "South East Coastal Plain",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 5,
      name: "SEH",
      description: "South Eastern Highlands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 6,
      name: "AA",
      description: "Australian Alps",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 7,
      name: "NSS",
      description: "NSW SouthWestern Slopes",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 8,
      name: "RIV",
      description: "Riverina",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 9,
      name: "FUR",
      description: "Furneaux",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 10,
      name: "SEC",
      description: "South East Corner",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 11,
      name: "BEN",
      description: "Ben Lomond",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 12,
      name: "TM",
      description: "Tasmanian Midlands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 17,
      name: "DRP",
      description: "Darling Riverine Plains",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 18,
      name: "ML",
      description: "Mulga Lands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 19,
      name: "SSD",
      description: "Simpson-Strzelecki Dunefields",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 20,
      name: "SB",
      description: "Sydney Basin",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 21,
      name: "CHC",
      description: "Channel Country",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 22,
      name: "BBN",
      description: "Brigalow Belt North",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 23,
      name: "NAN",
      description: "Nandewar",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 24,
      name: "CP",
      description: "Cobar Peneplain",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 25,
      name: "BHC",
      description: "Broken Hill Complex",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 26,
      name: "NET",
      description: "New England Tableland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 27,
      name: "NNC",
      description: "NSW North Coast",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 28,
      name: "CR",
      description: "Central Ranges",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 29,
      name: "FIN",
      description: "Finke",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 30,
      name: "STP",
      description: "Stony Plains",
      enumTypes: [
        {
          cppPrefix: "kA",
          enumTypeName: "GrthModeAT",
          source: "GrthModeAT",
          enumTypeDescription: "GrowthMode",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 31,
      name: "GAW",
      description: "Gawler",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblFT",
          source: "IncrTblFT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 32,
      name: "GVD",
      description: "Great Victoria Desert",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 33,
      name: "NUL",
      description: "Nullarbor",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStemT",
          source: "InitStemT",
          enumTypeDescription: "StemMassType",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 34,
      name: "HAM",
      description: "Hampton",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStem2T",
          source: "InitStemT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 35,
      name: "EYB",
      description: "Eyre and Yorke Blocks",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "InputsListT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 36,
      name: "LB",
      description: "Lofty Block",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "LandTenureT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 37,
      name: "FOR",
      description: "Flinders and Olary Ranges",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "LineStyleT",
          source: "LineStyleT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 38,
      name: "MII",
      description: "Mount Isa Inlier",
      enumTypes: [
        {
          cppPrefix: "klec",
          enumTypeName: "LogEntryClassT",
          source: "LogEntryClassT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 39,
      name: "GUP",
      description: "Gulf Plains",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 40,
      name: "CYP",
      description: "Cape York Peninsula",
      enumTypes: [
        {
          cppPrefix: "kmbr",
          enumTypeName: "MaxBMRngT",
          source: "MaxBMRngT",
          enumTypeDescription: "MaxBiomassRange",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 41,
      name: "MGD",
      description: "Mitchell Grass Downs",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ModelT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 42,
      name: "WT",
      description: "Wet Tropics",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "MonthT",
          source: "MonthT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 43,
      name: "CMC",
      description: "Central Mackay Coast",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "NFGroupT",
          source: "NFGroupT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 44,
      name: "EIU",
      description: "Einasleigh Uplands",
      enumTypes: [
        {
          cppPrefix: "knpi",
          enumTypeName: "NPIRegionT",
          source: "NPIRegionT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 45,
      name: "DEU",
      description: "Desert Uplands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "OnOffT",
          source: "OnOffT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 46,
      name: "GFU",
      description: "Gulf Fall and Uplands",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreqT",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 47,
      name: "MAC",
      description: "MacDonnell Ranges",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreq2T",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 48,
      name: "BRT",
      description: "Burt Plain",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PixelDataT",
          source: "PixelDataT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 49,
      name: "TAN",
      description: "Tanami",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationTypeT",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 50,
      name: "STU",
      description: "Sturt Plateau",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationType2T",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 51,
      name: "OVP",
      description: "Ord-Victoria Plains",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotT",
          source: "PlotT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 52,
      name: "VB",
      description: "Victoria Bonaparte",
      enumTypes: [
        {
          enumTypeName: "Plot2T",
          source: "Plot2T",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 53,
      name: "GAS",
      description: "Gascoyne",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotSortT",
          source: "PlotSortT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 57,
      name: "ESP",
      description: "Esperance Plains",
      enumTypes: [
        {
          cppPrefix: "kppwc",
          enumTypeName: "PPWeedCntT",
          source: "PPWeedCntT",
          enumTypeDescription: "PostPlantingWeedControl",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 58,
      name: "DL",
      description: "Dampierland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PrevLUseT",
          source: "PrevLUseT",
          enumTypeDescription: "PreviousUse",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 59,
      name: "GD",
      description: "Gibson Desert",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PriceT",
          source: "PriceT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 60,
      name: "GSD",
      description: "Great Sandy Desert",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PruneT",
          source: "PruneT",
          enumTypeDescription: "Prune",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 61,
      name: "JF",
      description: "Jarrah Forest",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "RegimeT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 62,
      name: "WAR",
      description: "Warren",
      enumTypes: [
        {
          cppPrefix: "krga",
          enumTypeName: "RgaCategoryT",
          source: "RgaCategoryT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 63,
      name: "LSD",
      description: "Little Sandy Desert",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RgaConfigT",
          source: "RgaConfigT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 64,
      name: "MAL",
      description: "Mallee",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RootSlouT",
          source: "RootSlouT",
          enumTypeDescription: "tRootSlouGraz",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 65,
      name: "MUR",
      description: "Murchison",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVersT",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 66,
      name: "NK",
      description: "Northern Kimberley",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVers2T",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 67,
      name: "GS",
      description: "Geraldton Sandplains",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SampleT",
          source: "SampleT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 68,
      name: "PIL",
      description: "Pilbara",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SfStatusT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 69,
      name: "SWA",
      description: "Swan Coastal Plain",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SimStepsT",
          source: "SimStepsT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 70,
      name: "AW",
      description: "Avon Wheatbelt",
      enumTypes: [
        {
          cppPrefix: "kspt",
          enumTypeName: "SitePrepT",
          source: "SitePrepT",
          enumTypeDescription: "SitePreparation",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 71,
      name: "YAL",
      description: "Yalgoo",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SoilT",
          source: "SoilT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 72,
      name: "GUC",
      description: "Gulf Coastal",
      enumTypes: [
        {
          cppPrefix: "ksand",
          enumTypeName: "SoilSandyT",
          source: "SoilSandyT",
          enumTypeDescription: "SoilSandiness",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 73,
      name: "DAB",
      description: "Daly Basin",
      enumTypes: [
        {
          cppPrefix: "ksp",
          enumTypeName: "SoilTempT",
          source: "SoilTempT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 74,
      name: "SEQ",
      description: "South Eastern Queensland",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaHdrT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 75,
      name: "PCA",
      description: "Pine-Creek Arnhem",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaRepT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 76,
      name: "BBS",
      description: "South Brigalow",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecT",
          source: "SpecT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 77,
      name: "CA",
      description: "Central Arnhem",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecAgCatT",
          source: "SpecAgCatT",
          enumTypeDescription: "SpecAgCat",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 78,
      name: "VM",
      description: "Victorian Midlands",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpecFrCatT",
          source: "SpecFrCatT",
          enumTypeDescription: "ForestCategory",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 79,
      name: "TEC",
      description: "Top End Coast",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateT",
          source: "StateT",
          enumTypeDescription: "States",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 80,
      name: "WOO",
      description: "Woolnorth",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateForT",
          source: "StateT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 81,
      name: "S01",
      description: "Recherche",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateIbraSoilT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 82,
      name: "S02",
      description: "Fitzgerald",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StatusT",
          source: "StatusT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 83,
      name: "S03",
      description: "Eastern Mallee",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StdT",
          source: "StdT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 84,
      name: "S04",
      description: "Western Mallee",
      enumTypes: [
        {
          cppPrefix: "kstock",
          enumTypeName: "StockingT",
          source: "StockingT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 85,
      name: "S05",
      description: "Geraldton Hills",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SystemT",
          source: "SystemT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 86,
      name: "S06",
      description: "Leseur Sandplain",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ThinT",
          source: "ThinT",
          enumTypeDescription: "Thin",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 87,
      name: "S07",
      description: "Perth North",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TickT",
          source: "TickT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 88,
      name: "S08",
      description: "Perth South",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOriginT",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 89,
      name: "S09",
      description: "Dandarragan Plateau",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin2T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 90,
      name: "S10",
      description: "Avon Wheatbelt P1 North",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin3T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 92,
      name: "S11",
      description: "Avon Wheatbelt P1 South",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProdT",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 93,
      name: "S12",
      description: "Avon Wheatbelt P2",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProd2T",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 94,
      name: "S13",
      description: "Southern Cross",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TreeProd3T",
          source: "TreeProd3T",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 95,
      name: "S14",
      description: "Northern Jarrah Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "VPDTempT",
          source: "VPDTempT",
        },
      ],
    },
    {
      enum: "IbraT",
      id: 96,
      name: "S15",
      description: "Southern Jarrah Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "YesNoT",
          source: "YesNoT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 0,
      name: "ErrorE",
      description: "Error!",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 1,
      name: "MetaIn",
      description: "About the document",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 2,
      name: "Config",
      description: "Configuration",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 3,
      name: "Timing",
      description: "Timing",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 4,
      name: "Build",
      description: "Data builder",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 5,
      name: "SiteAr",
      description: "Site : Area",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 6,
      name: "SiteWa",
      description: "Site : Water",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 7,
      name: "SiteTe",
      description: "Site : Temperature",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 8,
      name: "SiteLi",
      description: "Site : Light",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 9,
      name: "SiteMB",
      description: "Site : Maximum biomass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 10,
      name: "SitePr",
      description: "Site : Productivity",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 11,
      name: "SiteGM",
      description: "Site : Growth adjustments",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 12,
      name: "SiteDp",
      description: "Site : Displacement",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 17,
      name: "TreeGD",
      description: "Tree species : Growth (database only)",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 18,
      name: "TreePl",
      description: "Tree species : Plants",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 19,
      name: "TreeDe",
      description: "Tree species : Debris",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 20,
      name: "TreePr",
      description: "Tree species : Products",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 21,
      name: "TreeNi",
      description: "Tree species : Nitrogen",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 22,
      name: "TreeLg",
      description: "Tree species : Thinning",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 23,
      name: "TreeDisE",
      description: "Tree species : Displacement due to bioenergy",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 24,
      name: "TreeDisP",
      description: "Tree species : Displacement due to product use",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 25,
      name: "Tree3G",
      description: "Tree species : 3PG : Growth",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 26,
      name: "Tree3A",
      description: "Tree species : 3PG : Allometry",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 27,
      name: "Tree3L",
      description: "Tree species : 3PG : Leaves",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 28,
      name: "Tree3O",
      description: "Tree species : 3PG : Other",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 29,
      name: "CropGr",
      description: "Crop species : Growth",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 30,
      name: "CropPl",
      description: "Crop species : Plants",
      enumTypes: [
        {
          cppPrefix: "kA",
          enumTypeName: "GrthModeAT",
          source: "GrthModeAT",
          enumTypeDescription: "GrowthMode",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 31,
      name: "CropDe",
      description: "Crop species : Displacement due to bioenergy",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblFT",
          source: "IncrTblFT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 32,
      name: "CropPr",
      description: "Crop species : Products",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 33,
      name: "CropNi",
      description: "Crop species : Nitrogen",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStemT",
          source: "InitStemT",
          enumTypeDescription: "StemMassType",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 34,
      name: "CropCg",
      description: "Crop species : Harvest",
      enumTypes: [
        {
          cppPrefix: "kint",
          enumTypeName: "InitStem2T",
          source: "InitStemT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 35,
      name: "CropDisE",
      description: "Crop species : Debris",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "InputsListT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 36,
      name: "CropDisP",
      description: "Crop species : Displacement due to product use",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "LandTenureT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 37,
      name: "MulcIn",
      description: "Mulch : Inputs",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "LineStyleT",
          source: "LineStyleT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 38,
      name: "MulcFP",
      description: "Mulch : Forest : Properties",
      enumTypes: [
        {
          cppPrefix: "klec",
          enumTypeName: "LogEntryClassT",
          source: "LogEntryClassT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 39,
      name: "MulcAP",
      description: "Mulch : Agricultural : Properties",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 40,
      name: "MulcFN",
      description: "Mulch : Forest : Nitrogen",
      enumTypes: [
        {
          cppPrefix: "kmbr",
          enumTypeName: "MaxBMRngT",
          source: "MaxBMRngT",
          enumTypeDescription: "MaxBiomassRange",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 41,
      name: "MulcAN",
      description: "Mulch : Agricultural : Nitrogen",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ModelT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 42,
      name: "SoilFI",
      description: "Soil : Forest : Inputs",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "MonthT",
          source: "MonthT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 43,
      name: "SoilAI",
      description: "Soil : Agricultural : Inputs",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "NFGroupT",
          source: "NFGroupT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 44,
      name: "SoilFP",
      description: "Soil : Forest : Properties",
      enumTypes: [
        {
          cppPrefix: "knpi",
          enumTypeName: "NPIRegionT",
          source: "NPIRegionT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 45,
      name: "SoilAP",
      description: "Soil : Agricultural : Properties",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "OnOffT",
          source: "OnOffT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 46,
      name: "SoilFW",
      description: "Soil : Forest : Water",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreqT",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 47,
      name: "SoilAW",
      description: "Soil : Agricultural : Water",
      enumTypes: [
        {
          cppPrefix: "kof",
          enumTypeName: "OutFreq2T",
          source: "OutFreqT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 48,
      name: "SoilFN",
      description: "Soil : Forest : Nitrogen",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PixelDataT",
          source: "PixelDataT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 49,
      name: "SoilAN",
      description: "Soil : Agricultural : Nitrogen",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationTypeT",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 50,
      name: "SoilWP",
      description: "Soil : Whole Plot",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationType2T",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 51,
      name: "SoilSp",
      description: "Soil : Special",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotT",
          source: "PlotT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 52,
      name: "MnrlFN",
      description: "Minerals: Forest nitrogen",
      enumTypes: [
        {
          enumTypeName: "Plot2T",
          source: "Plot2T",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 53,
      name: "MnrlAN",
      description: "Minerals: Agricultural nitrogen",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PlotSortT",
          source: "PlotSortT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 57,
      name: "InitFD",
      description: "Initial : Forest : Debris",
      enumTypes: [
        {
          cppPrefix: "kppwc",
          enumTypeName: "PPWeedCntT",
          source: "PPWeedCntT",
          enumTypeDescription: "PostPlantingWeedControl",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 58,
      name: "InitFM",
      description: "Initial : Forest : Mulch",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PrevLUseT",
          source: "PrevLUseT",
          enumTypeDescription: "PreviousUse",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 59,
      name: "InitFS",
      description: "Initial : Forest : Soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PriceT",
          source: "PriceT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 60,
      name: "InitFN",
      description: "Initial : Forest : Minerals",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PruneT",
          source: "PruneT",
          enumTypeDescription: "Prune",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 61,
      name: "InitFP",
      description: "Initial : Forest : Products",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "RegimeT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 62,
      name: "InitAC",
      description: "Initial : Agricultural : Crops",
      enumTypes: [
        {
          cppPrefix: "krga",
          enumTypeName: "RgaCategoryT",
          source: "RgaCategoryT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 63,
      name: "InitAD",
      description: "Initial : Agricultural : Debris",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RgaConfigT",
          source: "RgaConfigT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 64,
      name: "InitAM",
      description: "Initial : Agricultural : Mulch",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RootSlouT",
          source: "RootSlouT",
          enumTypeDescription: "tRootSlouGraz",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 65,
      name: "InitAS",
      description: "Initial : Agricultural : Soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVersT",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 66,
      name: "InitAN",
      description: "Initial : Agricultural : Minerals",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "RothCVers2T",
          source: "RothCVersT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 67,
      name: "InitAP",
      description: "Initial : Agricultural : Products",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SampleT",
          source: "SampleT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 68,
      name: "InitWP",
      description: "Initial : Whole plot",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SfStatusT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 69,
      name: "EconIn",
      description: "Economic information",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SimStepsT",
          source: "SimStepsT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 70,
      name: "EvSete",
      description: "Events set",
      enumTypes: [
        {
          cppPrefix: "kspt",
          enumTypeName: "SitePrepT",
          source: "SitePrepT",
          enumTypeDescription: "SitePreparation",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 71,
      name: "EvAlle",
      description: "All events",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SoilT",
          source: "SoilT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 72,
      name: "EvPlnF",
      description: "Tree planting event",
      enumTypes: [
        {
          cppPrefix: "ksand",
          enumTypeName: "SoilSandyT",
          source: "SoilSandyT",
          enumTypeDescription: "SoilSandiness",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 73,
      name: "EvPlnA",
      description: "Crop planting event",
      enumTypes: [
        {
          cppPrefix: "ksp",
          enumTypeName: "SoilTempT",
          source: "SoilTempT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 74,
      name: "EvThin",
      description: "Forest thinning event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaHdrT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 75,
      name: "EvHarv",
      description: "Crop harvest event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpaRepT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 76,
      name: "EvFirF",
      description: "Forest fire event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecT",
          source: "SpecT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 77,
      name: "EvFirA",
      description: "Agricultural fire event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SpecAgCatT",
          source: "SpecAgCatT",
          enumTypeDescription: "SpecAgCat",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 78,
      name: "EvPlow",
      description: "Agricultural plowing event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "SpecFrCatT",
          source: "SpecFrCatT",
          enumTypeDescription: "ForestCategory",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 79,
      name: "EvHerb",
      description: "Agricultural herbiciding event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateT",
          source: "StateT",
          enumTypeDescription: "States",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 80,
      name: "EvGraz",
      description: "Grazing change event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateForT",
          source: "StateT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 81,
      name: "EvFFrc",
      description: "Forest-Percentage change event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateIbraSoilT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 82,
      name: "EvTmtF",
      description: "Forest treatment event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StatusT",
          source: "StatusT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 83,
      name: "EvChop",
      description: "Chopper roller event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "StdT",
          source: "StdT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 84,
      name: "EvTerm",
      description: "Termite change event",
      enumTypes: [
        {
          cppPrefix: "kstock",
          enumTypeName: "StockingT",
          source: "StockingT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 85,
      name: "EvIrrF",
      description: "Forest irrigation change event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SystemT",
          source: "SystemT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 86,
      name: "EvIrrA",
      description: "Agricultural irrigation change event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ThinT",
          source: "ThinT",
          enumTypeDescription: "Thin",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 87,
      name: "EvNFeF",
      description: "Forest N fertilizer application event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TickT",
          source: "TickT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 88,
      name: "EvNFeA",
      description: "Agricultural N fertilizer application event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOriginT",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 89,
      name: "EvManF",
      description: "Forest manure from offsite event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin2T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 90,
      name: "EvManA",
      description: "Agricultural manure from offsite event",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin3T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 91,
      name: "EvXCst",
      description: "External cost event",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "TitleT",
          source: "TitleT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 92,
      name: "EvXInc",
      description: "External income event",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProdT",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 93,
      name: "SensAn",
      description: "Sensitivity analysis",
      enumTypes: [
        {
          cppPrefix: "ktp",
          enumTypeName: "TreeProd2T",
          source: "TreeProdT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 94,
      name: "SpaIn",
      description: "Spatial inputs",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TreeProd3T",
          source: "TreeProd3T",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 95,
      name: "SpaOIn",
      description: "Spatial-only inputs",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "VPDTempT",
          source: "VPDTempT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 96,
      name: "SpaSim",
      description: "Spatial simulation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "YesNoT",
          source: "YesNoT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 97,
      name: "SpaOut",
      description: "Spatial simulation outputs",
      enumTypes: [
        {
          cppPrefix: "kyt",
          enumTypeName: "YieldT",
          source: "YieldT",
          enumTypeDescription: "YieldType",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 98,
      name: "DbaLso",
      description: "Location : Plot Data : Soils",
      enumTypes: [
        {
          cppPrefix: "kSpfl",
          enumTypeName: "SpflErrT",
          source: "SpflErrT",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 99,
      name: "InitFSd",
      description: "Initial : Forest : Standing Dead",
      enumTypes: [
        {
          cppPrefix: "krl",
          enumTypeName: "RotLenT",
          source: "RotLenT",
          enumTypeDescription: "RotationLength",
        },
      ],
    },
    {
      enum: "InCatT",
      id: 100,
      name: "TreeSd",
      description: "Tree species : Standing Dead",
      enumTypes: [
        {
          cppPrefix: "kF",
          enumTypeName: "GrthModeFT",
          source: "GrthModeFT",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 0,
      name: "iaBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 1,
      name: "IncrGbfrMA",
      description: "GBF mass",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 2,
      name: "IncrStlkMA",
      description: "Stalk mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 3,
      name: "IncrLeafMA",
      description: "Leaf mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 4,
      name: "IncrCortMA",
      description: "Coarse root mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 5,
      name: "IncrStLfMA",
      description: "Stalk and leaf mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "IncrTblAT",
      id: 6,
      name: "IncrAbgMA",
      description: "Aboveground mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "IncrTblFT",
      id: 0,
      name: "ifBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "IncrTblFT",
      id: 1,
      name: "IncrStemVolF",
      description: "Stem volume",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "IncrTblFT",
      id: 2,
      name: "IncrStemMF",
      description: "Stem mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "IncrTblFT",
      id: 3,
      name: "IncrAbgMF",
      description: "Aboveground mass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "InitStemT",
      id: 0,
      name: "Mass",
      description: "Masses",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "InitStemT",
      id: 1,
      name: "Vol",
      description: "Volume for stems, masses for other components",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "InitStemT",
      id: 2,
      name: "Frac",
      description: "Percentage of maximum tree biomass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "InitStemT",
      id: 3,
      name: "FracAge",
      description: "Percentage of tree age biomass",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 0,
      name: "Blank",
      description: "",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 1,
      name: "BaseLandUse",
      description: "BaseLandUse",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 2,
      name: "Mask",
      description: "Masks;Regions",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 3,
      name: "Transitional",
      description: "Transitions",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 4,
      name: "Region",
      description: "Regions;SimCell Map",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 5,
      name: "Climate",
      description: "Climate",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 6,
      name: "Input",
      description: "Inputs;Soils;Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "LayerTypeT",
      id: 7,
      name: "ReportingRegion",
      description: "Regions;Masks;SimCell Map",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "LeachingNT",
      id: 0,
      name: "LeachN_NCyc",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "LeachingNT",
      id: 1,
      name: "LeachN_frac",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "LeachingNT",
      id: 2,
      name: "LeachN_TS",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "LineStyleT",
      id: 0,
      name: "Solid",
      description: "Solid",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "LineStyleT",
      id: 1,
      name: "Dash",
      description: "Dash",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "LineStyleT",
      id: 2,
      name: "Dot",
      description: "Dot",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "LineStyleT",
      id: 3,
      name: "DashDot",
      description: "Dash dot",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LineStyleT",
      id: 4,
      name: "DashDotDot",
      description: "Dash dot dot",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LogEntryClassT",
      id: 0,
      name: "Debug",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "LogEntryClassT",
      id: 1,
      name: "Info",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "LogEntryClassT",
      id: 2,
      name: "Warn",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "LogEntryClassT",
      id: 3,
      name: "Error",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LogEntryClassT",
      id: 4,
      name: "Critical",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 0,
      name: "NoMessage",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 1,
      name: "FormatStr",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 2,
      name: "Literal",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 3,
      name: "CliRcvInput",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 4,
      name: "UserAction",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 5,
      name: "SpaLocnErr",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 6,
      name: "SpaUsingLocn",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 7,
      name: "SpaStateIBRAsoilErr",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 8,
      name: "SpaLocnDecodeErr",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 9,
      name: "SpaUsingStateIBRAsoil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 10,
      name: "SpaUsingRemap",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 11,
      name: "SpaReceivesNPI",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 12,
      name: "ConfigChangeOldNew",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 17,
      name: "ExplDropInput",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 18,
      name: "DocSave",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 19,
      name: "DocCreated",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 20,
      name: "DocConverted",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DsbnT",
          source: "DsbnT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 21,
      name: "SpaReceivesRegion",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EnumT",
          source: "EnumT",
          enumTypeDescription: "EnumTypes",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 22,
      name: "DataBuilderVersAndURL",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 23,
      name: "FIDOProject",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 24,
      name: "FIDOBaseline",
      enumTypes: [
        {
          cppPrefix: "kfa",
          enumTypeName: "FertApplnT",
          source: "FertApplnT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 25,
      name: "FIDOScenario",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileT",
          source: "FileT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 26,
      name: "Temp26",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 27,
      name: "Temp27",
      enumTypes: [
        {
          cppPrefix: "kfaq",
          enumTypeName: "FrAutoQT",
          source: "FrAutoQT",
          enumTypeDescription: "FrAutoQ",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 28,
      name: "Temp28",
      enumTypes: [
        {
          cppPrefix: "kFr",
          enumTypeName: "FrFracT",
          source: "FrFracT",
          enumTypeDescription: "stdInitTreeF_tFrFracInit",
        },
      ],
    },
    {
      enum: "LogEntryMsgT",
      id: 29,
      name: "Temp29",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "MaxBMRngT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 1,
      name: "BigInt",
      description: "BigInt",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 2,
      name: "binary",
      description: "WChar",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 3,
      name: "bit",
      description: "Boolean",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 4,
      name: "char",
      description: "WChar",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 5,
      name: "datetime",
      description: "Date",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 6,
      name: "float",
      description: "Double",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 7,
      name: "float(53)",
      description: "Double",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 8,
      name: "Int",
      description: "Integer",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 9,
      name: "nvarchar",
      description: "WChar",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 10,
      name: "smallint",
      description: "Integer",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 11,
      name: "tinyint",
      description: "Integer",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "MeasureType",
      id: 12,
      name: "varchar",
      description: "WChar",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "ModelT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "ModelT",
      id: 1,
      name: "CAMFor",
      description: "CAMFor",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "ModelT",
      id: 2,
      name: "CAMAg",
      description: "CAMAg",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ModelT",
      id: 3,
      name: "3PG",
      description: "3PG",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ModelT",
      id: 4,
      name: "Gendec",
      description: "Gendec",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ModelT",
      id: 5,
      name: "RothC",
      description: "RothC",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 0,
      name: "MoX",
      description: "MoX",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 1,
      name: "Jan",
      description: "Jan",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 2,
      name: "Feb",
      description: "Feb",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 3,
      name: "Mar",
      description: "Mar",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 4,
      name: "Apr",
      description: "Apr",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 5,
      name: "May",
      description: "May",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 6,
      name: "Jun",
      description: "Jun",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 7,
      name: "Jul",
      description: "Jul",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 8,
      name: "Aug",
      description: "Aug",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 9,
      name: "Sep",
      description: "Sep",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 10,
      name: "Oct",
      description: "Oct",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 11,
      name: "Nov",
      description: "Nov",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "MonthT",
      id: 12,
      name: "Dec",
      description: "Dec",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 0,
      name: "MVG_0",
      description: "Local species",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 1,
      name: "MVG_1",
      description: "Rainforest and vine thickets",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "MVG",
      id: 2,
      name: "MVG_2",
      description: "Eucalyptus Tall Open Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "MVG",
      id: 3,
      name: "MVG_3",
      description: "Eucalyptus Open Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 4,
      name: "MVG_4",
      description: "Eucalyptus Low Open Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 5,
      name: "MVG_5",
      description: "Eucalyptus Woodland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 6,
      name: "MVG_6",
      description: "Acacia Forest and Woodlands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 7,
      name: "MVG_7",
      description: "Callitris Forest and Woodlands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 8,
      name: "MVG_8",
      description: "Casuarina Forest and Woodland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 9,
      name: "MVG_9",
      description: "Melaleuca Forest and Woodland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 10,
      name: "MVG_10",
      description: "Other Forests and Woodlands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 11,
      name: "MVG_11",
      description: "Eucalyptus Open Woodland",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 12,
      name: "MVG_12",
      description: "Tropical Eucalyptus woodlands/grasslands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 17,
      name: "MVG_17",
      description: "Other Shrublands",
      enumTypes: [
        {
          cppPrefix: "kcu",
          enumTypeName: "CultT",
          source: "CultT",
          enumTypeDescription: "Cultivation",
        },
      ],
    },
    {
      enum: "MVG",
      id: 18,
      name: "MVG_18",
      description: "Heath",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 22,
      name: "MVG_22",
      description: "Chenopod Shrub; Samphire Shrub and Forbland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "EventT",
          source: "EventT",
          enumTypeDescription: "EventT",
        },
      ],
    },
    {
      enum: "MVG",
      id: 23,
      name: "MVG_23",
      description:
        "Mangrove; tidal mudflat; samphire and bare areas; claypan; sand; rock; salt lakes; lagoons; freshwater lakes",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "ExtrapT",
          source: "ExtrapT",
          enumTypeDescription: "SeriesExtrapolation",
        },
      ],
    },
    {
      enum: "MVG",
      id: 26,
      name: "MVG_26",
      description: "Unclassified Native vegetation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "FileConvertT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 0,
      name: "Blank",
      description: "No Region",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 1,
      name: "WA",
      description: "Western Australia",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 2,
      name: "Tas",
      description: "Tasmania",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 3,
      name: "GreenTri",
      description: "Green Triangle",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 4,
      name: "Lofty",
      description: "Lofty Block",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 5,
      name: "CentVic",
      description: "Central Victoria",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 6,
      name: "Murray",
      description: "Murray Valley",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 7,
      name: "CentGipp",
      description: "Central Gippsland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 8,
      name: "Bombala",
      description: "Bombala/East Gippsland",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 9,
      name: "SouthTbl",
      description: "Southern Tablelands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 10,
      name: "CentTbl",
      description: "Central Tablelands",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 11,
      name: "NthTbl",
      description: "Northern Tablelands",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 12,
      name: "NthCoast",
      description: "North Coast",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "NPIRegionT",
      id: 100,
      name: "BlankACT",
      description: "Aust. Capital Territory",
      enumTypes: [
        {
          cppPrefix: "kF",
          enumTypeName: "GrthModeFT",
          source: "GrthModeFT",
        },
      ],
    },
    {
      enum: "OASysStepT",
      id: 1,
      name: "Step",
      description: "Step",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OASysStepT",
      id: 3,
      name: "Quarter",
      description: "Quarter",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OASysStepT",
      id: 6,
      name: "HalfYear",
      description: "Half Year",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "OASysStepT",
      id: 12,
      name: "CalYear",
      description: "Calendar Year",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "OASysStepT",
      id: 18,
      name: "FinYear",
      description: "Financial Year",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "DataT",
          source: "DataT",
        },
      ],
    },
    {
      enum: "OASysStepT",
      id: 19,
      name: "Custom",
      description: "Custom",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "OnOffT",
      id: 0,
      name: "Off",
      description: "Off",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OnOffT",
      id: 1,
      name: "On",
      description: "On",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutCat0T",
      id: 0,
      name: "C",
      description: "Carbon",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OutCat0T",
      id: 1,
      name: "N",
      description: "Nitrogen",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutCat0T",
      id: 2,
      name: "Dollars",
      description: "Dollars",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "OutCat0T",
      id: 3,
      name: "Indept",
      description: "Other",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutCat0T",
      id: 4,
      name: "OASys",
      description: "Spatial",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 0,
      name: "Time",
      description: "Timing",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 1,
      name: "FrAg",
      description: "Whole",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 2,
      name: "Fr",
      description: "Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 3,
      name: "Ag",
      description: "Agricultural",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 4,
      name: "Leak",
      description: "Leaks",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 5,
      name: "Ledg",
      description: "Ledger",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 6,
      name: "Cumu",
      description: "Cumulative",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 7,
      name: "DB",
      description: "OASys",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 8,
      name: "CStock",
      description: "Carbon Stock",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 9,
      name: "NA1",
      description: "-",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "OutCat1T",
      id: 10,
      name: "CProjects",
      description: "Carbon Projects",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 0,
      name: "NA2",
      description: "-",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 1,
      name: "Emit",
      description: "Emissions",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 2,
      name: "Plnt",
      description: "Plants",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 3,
      name: "SDwd",
      description: "Standing dead",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 4,
      name: "Debr",
      description: "Debris",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 5,
      name: "Mulc",
      description: "Mulch",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 6,
      name: "Soil",
      description: "Soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 7,
      name: "Mnrl",
      description: "Minerals",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 8,
      name: "Prod",
      description: "Products",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 9,
      name: "Disp",
      description: "Displacements",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 10,
      name: "Water",
      description: "Water",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 11,
      name: "Rate",
      description: "Rates",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "ByteOrderT",
          source: "ByteOrderT",
        },
      ],
    },
    {
      enum: "OutCat2T",
      id: 12,
      name: "Logs",
      description: "Log Production",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PoolT",
          source: "PoolT",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 0,
      name: "Daily",
      description: "Daily",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 1,
      name: "Weekly",
      description: "Weekly",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 2,
      name: "Monthly",
      description: "Monthly",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 3,
      name: "Yearly",
      description: "Yearly",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 4,
      name: "MoWeek",
      description: "Monthly and Weekly",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 5,
      name: "YrWeek",
      description: "Yearly and Weekly",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "OutFreqT",
      id: 6,
      name: "Other",
      description: "Other",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "OutputT",
      id: 0,
      name: "0",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OutputT",
      id: 1,
      name: "total",
      description: "Total",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutputT",
      id: 2,
      name: "baseFlow",
      description: "Base Flow",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "OutputT",
      id: 3,
      name: "leak",
      description: "Leak",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutputT",
      id: 4,
      name: "flowTotal",
      description: "flow Total",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutWinOptionT",
      id: 0,
      name: "Combined",
      description: "combine all Plots",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "OutWinOptionT",
      id: 1,
      name: "PerScenario",
      description: "per Scenario",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutWinOptionT",
      id: 2,
      name: "PerOutput",
      description: "per Output",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "OutWinOptionT",
      id: 3,
      name: "PerDoc",
      description: "per Document",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "OutWinViewT",
      id: 1,
      name: "Graph",
      description: "Graph",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "OutWinViewT",
      id: 2,
      name: "Table",
      description: "Table",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PixelDataT",
      id: 0,
      name: "Unknown",
      description: "",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PixelDataT",
      id: 1,
      name: "Bit",
      description: "BIT",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PixelDataT",
      id: 2,
      name: "UInt",
      description: "UNSIGNEDINT",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PixelDataT",
      id: 3,
      name: "Int",
      description: "SIGNEDINT",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PixelDataT",
      id: 4,
      name: "Float",
      description: "FLOAT",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PlantationT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PlantationT",
      id: 1,
      name: "Other",
      description: "Other tree",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PlantationT",
      id: 2,
      name: "Softwood",
      description: "Softwood",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PlantationT",
      id: 3,
      name: "Hardwood",
      description: "Hardwood",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 0,
      name: "pctBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 1,
      name: "TreeF",
      description: "Trees",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 2,
      name: "MulcF",
      description: "Forest mulch",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 3,
      name: "SoilF",
      description: "Forest soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 4,
      name: "CompF",
      description: "Forest system",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 5,
      name: "MulcA",
      description: "Agricultural mulch",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 6,
      name: "SoilA",
      description: "Agricultural soil",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 7,
      name: "CompA",
      description: "Agricultural system",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "PlotT",
      id: 8,
      name: "CompM",
      description: "Mixed (forest and agricultural) system",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 0,
      name: "StemF",
      description: "Stems",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 1,
      name: "BranF",
      description: "branches",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 2,
      name: "BarkF",
      description: "Bark",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 3,
      name: "LeafF",
      description: "Leaves",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 4,
      name: "CortF",
      description: "Coarse Roots",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 5,
      name: "FirtF",
      description: "Fine Roots",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 6,
      name: "GbfrA",
      description: "Grains, Buds, and Fruit",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 7,
      name: "StlkA",
      description: "Stalks",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 8,
      name: "LeafA",
      description: "Leaves",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 9,
      name: "CortA",
      description: "Coarse Roots",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "PoolT",
      id: 10,
      name: "FirtA",
      description: "Fine Roots",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 1,
      name: "None",
      description: "No post planting weed control",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 2,
      name: "ManStrip",
      description: "Manual and strip weed control",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 3,
      name: "ManBlanket",
      description: "Manual and blanket weed control",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 4,
      name: "ManHand",
      description: "Manual weed control (hand tools)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 5,
      name: "ManHerb",
      description: "Manual weed control (herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 6,
      name: "StripMow",
      description: "Strip weed control (mowing)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 7,
      name: "StripHerb",
      description: "Strip weed control (herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 8,
      name: "BlanketHerb",
      description: "Blanket weed control (herbicide)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 9,
      name: "GrazStrip",
      description: "Grazing and strip",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "PPWeedCntT",
      id: 10,
      name: "StripBlanket",
      description: "Strip and blanket",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "PrevLUseT",
      id: 0,
      name: "pluBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PrevLUseT",
      id: 1,
      name: "Pasture",
      description: "Pasture",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PrevLUseT",
      id: 2,
      name: "Plantation",
      description: "Plantation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PrevLUseT",
      id: 3,
      name: "NativeForest",
      description: "Native Forest",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PrevLUseT",
      id: 4,
      name: "InitPlantation",
      description: "Initial Plantation",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PrevLUseT",
      id: 5,
      name: "LocalSpecies",
      description: "Local Species",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "PriceT",
      id: 0,
      name: "PriceBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PriceT",
      id: 1,
      name: "PricePerGmt",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PriceT",
      id: 2,
      name: "PricePerDmt",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PriceT",
      id: 3,
      name: "PricePerM3",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 0,
      name: "Fuel",
      description: "Fuel",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 1,
      name: "Paper",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 2,
      name: "Packing",
      description: "Packing",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 3,
      name: "Furniture",
      description: "Furniture",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 4,
      name: "Fibre",
      description: "Fibre",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 5,
      name: "Construction",
      description: "Construction",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "ProdT",
      id: 6,
      name: "Residue",
      description: "Residue",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 0,
      name: "PS_0",
      description: "Unprotected",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 1,
      name: "PS_1",
      description: "IUCN Category 1a",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 2,
      name: "PS_2",
      description: "IUCN Category 1b",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 3,
      name: "PS_3",
      description: "IUCN Category 2",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 4,
      name: "PS_4",
      description: "IUCN Category 3",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 5,
      name: "PS_5",
      description: "IUCN Category 4",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 6,
      name: "PS_6",
      description: "IUCN Category 5",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 7,
      name: "PS_7",
      description: "IUCN Category 6",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 8,
      name: "PS_8",
      description: "IUCN Category N/A",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 9,
      name: "PS_9",
      description: "QLD high value regrowth areas",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 19,
      name: "PS_19",
      description: "Regions falling in IUCN category 1a and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "kds",
          enumTypeName: "DocSectionT",
          source: "DocSectionT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 29,
      name: "PS_29",
      description: "Regions falling in IUCN category 1b and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "GrazT",
          source: "GrazT",
          enumTypeDescription: "tGraz",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 39,
      name: "PS_39",
      description: "Regions falling in IUCN category 2 and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "klem",
          enumTypeName: "LogEntryMsgT",
          source: "LogEntryMsgT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 49,
      name: "PS_49",
      description: "Regions falling in IUCN category 3 and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "kpt",
          enumTypeName: "PlantationTypeT",
          source: "PlantationT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 59,
      name: "PS_59",
      description: "Regions falling in IUCN category 4 and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "PriceT",
          source: "PriceT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 69,
      name: "PS_69",
      description: "Regions falling in IUCN category 5 and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "SimStepsT",
          source: "SimStepsT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 79,
      name: "PS_79",
      description: "Regions falling in IUCN category 6 and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "StateT",
          source: "StateT",
          enumTypeDescription: "States",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 89,
      name: "PS_89",
      description: "Regions falling in IUCN category n/a and QLD high growth",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin2T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "ProtectedStatus",
      id: 90,
      name: "PS_90",
      description: "Protected Lands (All Lands)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "TimeOrigin3T",
          source: "DateOriginT",
        },
      ],
    },
    {
      enum: "PruneT",
      id: 0,
      name: "PruneBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "PruneT",
      id: 1,
      name: "Prune0",
      description: "No prunes",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "PruneT",
      id: 2,
      name: "Prune1",
      description: "1 Prune",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "PruneT",
      id: 3,
      name: "Prune2",
      description: "2 Prunes",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PruneT",
      id: 4,
      name: "Prune3",
      description: "3 Prunes",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "PruneT",
      id: 5,
      name: "Prune4",
      description: "4 Prunes",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "QueueRulesT",
      id: 0,
      name: "NA",
      description: "None",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "QueueRulesT",
      id: 1,
      name: "AlignPrev",
      description: "Align Previous",
      code: "AP",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "QueueRulesT",
      id: 2,
      name: "AlignNext",
      description: "Align Next",
      code: "AN",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "QueueRulesT",
      id: 4,
      name: "DayOfYear",
      description: "Day of Year",
      code: "=",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "QueueRulesT",
      id: 8,
      name: "DisableSpeciesPrev",
      description: "Disable Species (previous)",
      code: "DP",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "QueueRulesT",
      id: 32,
      name: "GroupPersistence",
      description: "Group Persistence",
      code: "GP",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "IncrTblAT",
          source: "IncrTblAT",
        },
      ],
    },
    {
      enum: "RationT",
      id: 0,
      name: "RationProd",
      description: "Production",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "RationT",
      id: 1,
      name: "RationAbsn",
      description: "Absorption",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "RationT",
      id: 2,
      name: "RationMdcm",
      description: "Mulch decomposition",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "RationT",
      id: 3,
      name: "RationSoen",
      description: "Soil entry",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "RationT",
      id: 4,
      name: "RationSdcm",
      description: "Soil decomposition",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "RationT",
      id: 5,
      name: "RationNita",
      description: "Nitrification (NH4 part)",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "RationT",
      id: 6,
      name: "RationBond",
      description: "Boundary line emissions",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "RationT",
      id: 7,
      name: "RationDeni",
      description: "Denitrification",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 0,
      name: "CatUndef",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 1,
      name: "Cat01",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 2,
      name: "Cat02",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 3,
      name: "Cat03",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 4,
      name: "Cat04",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 5,
      name: "Cat05",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 6,
      name: "Cat06",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 7,
      name: "Cat07",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignVT",
          source: "AlignVT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 8,
      name: "Cat08",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AnnualOutputT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 9,
      name: "Cat09",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AvgAirTempT",
          source: "AvgAirTempT",
        },
      ],
    },
    {
      enum: "RgaCategoryT",
      id: 10,
      name: "Cat10",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AxisT",
          source: "AxisT",
        },
      ],
    },
    {
      enum: "RgaConfigT",
      id: 0,
      name: "RgaMixed",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "RgaConfigT",
      id: 1,
      name: "RgaAgOnly",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "RgaConfigT",
      id: 2,
      name: "RgaAll",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "RootSlouT",
      id: 0,
      name: "rsgBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "RootSlouT",
      id: 1,
      name: "SlouOn",
      description: "Sloughing on",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "RootSlouT",
      id: 2,
      name: "SlouOff",
      description: "Sloughing off",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "RothCVersT",
      id: 0,
      name: "rvBlank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "RothCVersT",
      id: 1,
      name: "Vers263",
      description: "26.3",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "RothCVersT",
      id: 2,
      name: "Vers265",
      description: "26.5",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "RotLenT",
      id: 0,
      name: "Blank",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "RotLenT",
      id: 1,
      name: "Short",
      description: "Short",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "RotLenT",
      id: 2,
      name: "Long",
      description: "Long",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "RotLenT",
      id: 3,
      name: "NoHarvest",
      description: "Not Harvested",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "SampleT",
      id: 0,
      name: "MonteCarlo",
      description: "Monte Carlo",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "SampleT",
      id: 1,
      name: "LatinHyperCube",
      description: "Latin HyperCube",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "SampleT",
      id: 2,
      name: "DsbnMeans",
      description: "Dsbn Means",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ScalingT",
      id: 0,
      name: "Adds",
      description: "Adds",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "ScalingT",
      id: 1,
      name: "Avgs",
      description: "Averages",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "ScalingT",
      id: 2,
      name: "Same",
      description: "Same",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "ScalingT",
      id: 3,
      name: "Econ",
      description: "Economy",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "ScalingT",
      id: 4,
      name: "Diff",
      description: "Difference",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 0,
      name: "AgActivity",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 1,
      name: "AgPersistence",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 2,
      name: "Transitions",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 3,
      name: "ForestActivity",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 4,
      name: "SpatialFire",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 5,
      name: "Patchiness",
      description: "Randomiser used for Patchiness",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "SeedUsageT",
      id: 6,
      name: "PrescribedBurn",
      description: "Randomiser used for PrescribedBurn",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 0,
      name: "NA",
      description: "SilvSys Not Available",
      code: "NA",
      enumTypes: [
        {
          cppPrefix: "",
          enumTypeName: "NoEnumT",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 1,
      name: "FirewoodFallen",
      description: "Firewood Fallen",
      code: "FF",
      enumTypes: [
        {
          cppPrefix: "kaaq",
          enumTypeName: "AgAutoQT",
          source: "AgAutoQT",
          enumTypeDescription: "AgAutoQ",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 2,
      name: "ClearFell",
      description: "Clear Fell",
      code: "CL",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxT",
          source: "AgeIxT",
          enumTypeDescription: "TreeAgeIndex",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 3,
      name: "ClearFellSalvage",
      description: "Clear Fell Salvage",
      code: "SL",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxAT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 4,
      name: "GroupSelection",
      description: "Group (or Gap) selection",
      code: "GS",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgeIxFT",
          source: "AgeIxT",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 5,
      name: "Reforestation",
      description: "Reforestation",
      code: "RF",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AgLandUseT",
        },
      ],
    },
    {
      enum: "SilvSysT",
      id: 6,
      name: "RegrowthRetention",
      description: "Regrowth retention harvesting",
      code: "RR",
      enumTypes: [
        {
          cppPrefix: "k",
          enumTypeName: "AlignHT",
          source: "AlignHT",
        },
      ],
    },
  ];

  allInputs = {
    "0": [
      {
        progNmII: "NA",
        descII: "Not an input of note",
        tInII: 0,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "yrREV",
        descII:
          "Year part of time since start of first calendar year of regime cycle",
        tInII: 582,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -100,
        hiII: 1000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dayREV",
        descII:
          "Day part of time since start of first calendar year of regime cycle",
        tInII: 583,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "day",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 364,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "specNmREV",
        descII: "Species of regime event",
        tInII: 584,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "eventNmREV",
        descII: "Standard event of species of regime event",
        tInII: 585,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 160,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "yieldTS",
        descII: "Crop yields",
        tInII: 587,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "regimeIdACT",
        descII: "Regime (of activity)",
        tInII: 588,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "NoEnumT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 300,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1153",
        descII: "?",
        tInII: 1153,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1166",
        descII: "Replacement state",
        tInII: 1166,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 84,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StateT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 67,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1167",
        descII: "Replacement IBRA",
        tInII: 1167,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 84,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1168",
        descII: "Replace soil ID in remap",
        tInII: 1168,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 84,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1169",
        descII: "Replacement soil ID",
        tInII: 1169,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 84,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 67,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "yrClearLoSUA",
        descII: "Year cleared: Start of range",
        tInII: 1170,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1500,
        hiII: 4000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1171",
        descII: "State",
        tInII: 1171,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StateT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 50,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1172",
        descII: "IBRA",
        tInII: 1172,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1173",
        descII: "Soil ID",
        tInII: 1173,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1174",
        descII: "State",
        tInII: 1174,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StateForT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 50,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1175",
        descII: "National plantation inventory (NPI) region",
        tInII: 1175,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "NPIRegionT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 122,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "revqIdACT",
        descII: "Regime event queue (of activity)",
        tInII: 1176,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 160,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "freqACT",
        descII: "Relative frequency (of activity)",
        tInII: 1177,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 51,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "yrEstabLoSUF",
        descII: "Year plantation established: Start of range",
        tInII: 1178,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1500,
        hiII: 4000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 60,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "yrEstabHiSUF",
        descII: "Year plantation established: End of range",
        tInII: 1179,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1500,
        hiII: 4000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 60,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nmRGA",
        descII: "Agricultural regime name",
        tInII: 1180,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 300,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nmREVQ",
        descII: "Name of regime event queue",
        tInII: 1181,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 160,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1182",
        descII: "State",
        tInII: 1182,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StateT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 50,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1183",
        descII: "IBRA",
        tInII: 1183,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1184",
        descII: "Soil ID",
        tInII: 1184,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nmRGF",
        descII: "Forest regime name",
        tInII: 1185,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 325,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "specNmYDA",
        descII: "Crop",
        tInII: 1187,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 130,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tYield",
        descII: "Yield type",
        tInII: 1188,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "YieldT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 90,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "yrClearHiSUA",
        descII: "Year cleared: End of range",
        tInII: 1190,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1500,
        hiII: 4000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "regimeIdYDA",
        descII: "Regime",
        tInII: 1202,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "NoEnumT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 282,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "soilSandySUF",
        descII: "Soil sandiness",
        tInII: 1215,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SoilSandyT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 54,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "pltnTypeSUF",
        descII: "Plantation type (identifier for spatial sim.s)",
        tInII: 1216,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PlantationTypeT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 57,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "prevLUseSUF",
        descII: "Previous land use",
        tInII: 1217,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PrevLUseT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 67,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxBMRngSUF",
        descII: "Maximum biomass range",
        tInII: 1218,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 24,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "MaxBMRngT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "commentRGF",
        descII: "Forest regime comments",
        tInII: 1219,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "specNmRGF",
        descII: "Forest regime tree species",
        tInII: 1220,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 235,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "statusRGF",
        descII: "Forest regime status",
        tInII: 1253,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StatusT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tConfigRGA",
        descII: "Config type Agricultural regime applies to",
        tInII: 1264,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "RgaConfigT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initClearREVQ",
        descII:
          "Agricultural regime: event queue must start with a cleared plot",
        tInII: 1265,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 84,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "statusRGA",
        descII: "Agricultural regime status",
        tInII: 1267,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StatusT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused3",
        descII: "?",
        tInII: 1405,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "ununsed2115",
        descII: "Category regime event queue",
        tInII: 2115,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 111,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "RgaCategoryT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "grazingTS",
        descII: "Grazing Pressure",
        tInII: 2145,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "t/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 0,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "1": [
      {
        progNmII: "savedByResearch",
        descII: "Document was saved by research",
        tInII: 591,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 0,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nmME",
        descII: "Name",
        tInII: 796,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "notesME",
        descII: "Notes",
        tInII: 797,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lockOnME",
        descII: "Document locked",
        tInII: 1012,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 0,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lockTime",
        descII: "Time that document was locked",
        tInII: 1072,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lockId",
        descII: "Security id of locked document",
        tInII: 1123,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "savedByVersion",
        descII: "Saved by Fullcam version",
        tInII: 2202,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 11,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 1,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "2": [
      {
        progNmII: "userN",
        descII: "Nitrogen processing (on/off)",
        tInII: 505,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 128,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tPlot",
        descII: "Type of plot",
        tInII: 576,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 6,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PlotT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tTreeProd",
        descII: "Type of tree production model",
        tInII: 577,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 6,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "TreeProdT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userMulchF",
        descII: "Include forest mulch",
        tInII: 578,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 2,
        riskII: 0,
        spaII: 0,
        helpIdII: 6,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 0,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userSoilMnrl",
        descII: "Include soil and minerals",
        tInII: 579,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 2,
        riskII: 0,
        spaII: 0,
        helpIdII: 128,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userMulchA",
        descII: "Include agricultural mulch",
        tInII: 580,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 2,
        riskII: 0,
        spaII: 0,
        helpIdII: 6,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 0,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rothCVers",
        descII: "RothC version",
        tInII: 581,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 138,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "RothCVersT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userCalcModFrost",
        descII: "Calculate the frost modifier (modFrost) from scratch",
        tInII: 590,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userDispEner",
        descII: "Displacement of fossil fuels due to bioenergy (on/off)",
        tInII: 969,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 128,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userDispProd",
        descII: "Displacement of fossil fuels due to product use (on/off)",
        tInII: 970,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 128,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEventIrrF",
        descII: "forest irrigation (yes/no)",
        tInII: 1022,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 1,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 195,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEventIrrA",
        descII: "agricultural irrigation (yes/no)",
        tInII: 1023,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 1,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 195,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEventNFeF",
        descII: "forest N fertilizer (yes/no)",
        tInII: 1024,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 1,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 195,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEventNFeA",
        descII: "agricultural N fertilizer (yes/no)",
        tInII: 1025,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 1,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 195,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEventManF",
        descII: "forest manure from offsite (yes/no)",
        tInII: 1026,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 1,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 195,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEventManA",
        descII: "agricultural manure from offsite (yes/no)",
        tInII: 1027,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 1,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 195,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userEcon",
        descII: "Economic Modelling on (yes/no)",
        tInII: 1102,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userSens",
        descII: "Sensitivity analysis possible (yes/no)",
        tInII: 1135,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userOpti",
        descII: "Optimization analysis possible (yes/no)",
        tInII: 1136,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userCalcFPI",
        descII:
          "Calculate the forest productivity index (FPI) input from scatch",
        tInII: 1203,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 138,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 0,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userCalcModTemp",
        descII: "Calculate the temperature modifier (modTemp) from scratch",
        tInII: 1206,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userCalcModASW",
        descII: "Calculate the soil water modifier (modASW) from scratch",
        tInII: 1207,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userLogGrade",
        descII: "Allow thin specification by log grade (yes/no)",
        tInII: 1493,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "userCropGrade",
        descII: "Allow harvest specification by crop grade (yes/no)",
        tInII: 1734,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 8,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simulateSpatialFire",
        descII: "Simulate Spatial Fire",
        tInII: 2133,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 128,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 2,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "3": [
      {
        progNmII: "stepsPerOutYTZ",
        descII: "Simulation steps per output",
        tInII: 592,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 27,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stYrYTZ",
        descII: "Simulation start year",
        tInII: 593,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 26,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stStepInStYrYTZ",
        descII: "Simulation start step within start year",
        tInII: 594,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 26,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 8760,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "enYrYTZ",
        descII: "Simulation end year",
        tInII: 595,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 26,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "enStepInEnYrYTZ",
        descII: "Simulation end step within end year",
        tInII: 596,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 26,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 8760,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tStepsYTZ",
        descII: "Simulation steps per year (radio button choice)",
        tInII: 1009,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 4,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stepsPerYrYTZ",
        descII: "Simulation steps per year (number typed by user)",
        tInII: 1010,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 8760,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firstOutStepYTZ",
        descII: "First output step",
        tInII: 1106,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 176,
        riskII: 0,
        spaII: 0,
        helpIdII: 27,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dailyTimingTZ",
        descII: "Document uses daily timing (as opposed to yearly timing)",
        tInII: 2105,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 60,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stDateDTZ",
        descII: "Simulation start date (starts at beginning of this day)",
        tInII: 2106,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 9,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "enDateDTZ",
        descII: "Simulation end date (ends at end of this day)",
        tInII: 2107,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 9,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "daysPerStepDTZ",
        descII: "Length of simulation step in days per step",
        tInII: 2108,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 365,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "outputFreqDTZ",
        descII: "Output frequency",
        tInII: 2109,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 5,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stepsPerOutDTZ",
        descII: "Simulation steps per output",
        tInII: 2110,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "useDaysPerStepDTZ",
        descII: "Days per step (as opposed to steps per day)",
        tInII: 2111,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 60,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stepsPerDayDTZ",
        descII: "Length of simulation step in steps per day",
        tInII: 2112,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 5,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 24,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firstOutStepDTZ",
        descII: "First output step",
        tInII: 2113,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 175,
        riskII: 0,
        spaII: 0,
        helpIdII: 27,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 3,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "4": [
      {
        progNmII: "areaBL",
        descII: "area for DataBuilder",
        tInII: 92,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 132,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 5,
        tEnumII: "BLAreaT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 4,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lonBL",
        descII: "Longitude of plot",
        tInII: 376,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "Deg E",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 132,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -180,
        hiII: 180,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 4,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "latBL",
        descII: "Latitude of plot",
        tInII: 377,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "Deg N",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 132,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -90,
        hiII: 90,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 4,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "frCat",
        descII: "Forest Category",
        tInII: 1252,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 132,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: "SpecFrCatT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 4,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "5": [
      {
        progNmII: "hasArea",
        descII: "Site has area (yes/no)",
        tInII: 1213,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 157,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 5,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteArea",
        descII: "Site area",
        tInII: 1214,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 157,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.01,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 5,
        tSpecII: 0,
        tEventII: 10,
      },
    ],

    "6": [
      {
        progNmII: "conditIrrigF",
        descII: "Conditional forest",
        tInII: 3,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 3,
        riskII: 0,
        spaII: 1,
        helpIdII: 91,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "defnitIrrigF",
        descII: "Definite forest",
        tInII: 4,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 92,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "VPD",
        descII: "Vapor pressure deficit (VPD)",
        tInII: 9,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kPa",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 97,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "openPanEvap",
        descII: "Open-pan evaporation",
        tInII: 10,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 98,
        sortNII: 416,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rainfall",
        descII: "Rainfall",
        tInII: 575,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 88,
        sortNII: 419,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tVPD",
        descII: "Source of VPD data",
        tInII: 598,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 12,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "VPDTempT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "conditIrrigOnF",
        descII: "Forest conditional irrigation (on/off)",
        tInII: 1011,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 12,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "conditIrrigOnA",
        descII: "Agricultural conditional irrigation (on/off)",
        tInII: 1013,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 12,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "defnitIrrigA",
        descII: "Definite agricultural",
        tInII: 1014,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 4,
        riskII: 1,
        spaII: 1,
        helpIdII: 92,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "conditIrrigA",
        descII: "Conditional agricultural",
        tInII: 1015,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 3,
        riskII: 0,
        spaII: 1,
        helpIdII: 91,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "modASWTS",
        descII: "Soil water modifier (modASW) in 3PG-lite",
        tInII: 1210,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "0...1",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 183,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "modVPDTS",
        descII: "Vapor pressure deficit (VPD) modifier in 3PG-lite",
        tInII: 1211,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "0...1",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 194,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "eqmEvap",
        descII: "Equilibrium evaporation",
        tInII: 1212,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 156,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 6,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "7": [
      {
        progNmII: "avgAirTemp",
        descII: "Average air temperature",
        tInII: 1,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 89,
        sortNII: 415,
        subSameII: 1,
        tDataII: 6,
        loII: -270,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "frostNights",
        descII: "Frost nights",
        tInII: 6,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "days",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 94,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 365.25,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rangeAirTemp",
        descII: "Air temperature daily range",
        tInII: 7,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 95,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 120,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minAirTemp",
        descII: "Minimum air temperature",
        tInII: 8,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 96,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: -60,
        hiII: 60,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "modFrostTS",
        descII: "Frost modifier (modFrost) in 3PG-lite",
        tInII: 589,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "0...1",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 178,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tAirTemp",
        descII: "Source of air temperature data",
        tInII: 597,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 13,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "AvgAirTempT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tSoilTemp",
        descII: "Source of soil temperature data",
        tInII: 1056,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 13,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SoilTempT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "soilTemp",
        descII: "Soil temperature",
        tInII: 1057,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 66,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: -270,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 7,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "8": [
      {
        progNmII: "solarRad",
        descII: "Solar radiation",
        tInII: 5,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/m2",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 93,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 8,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "latitude3PG",
        descII: "Latitude",
        tInII: 195,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Deg N",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 35,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -180,
        hiII: 180,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 8,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "molPARPerMJRad",
        descII: "Photosynthetically active photons per megajoule of radiation",
        tInII: 196,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mol/MJ",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 35,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 8,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "plantMPerMolC",
        descII: "Plant mass produced per mole of GPP carbon",
        tInII: 197,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "g",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 35,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 8,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "APAR",
        descII: "APAR (absorbed photosynthetically active radiation)",
        tInII: 1205,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/m2",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 38,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 8,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "9": [
      {
        progNmII: "maxAbgMF",
        descII: "Maximum aboveground forest biomass",
        tInII: 131,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 36,
        sortNII: 420,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 10000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 1000,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 9,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxAbgMA",
        descII: "Maximum aboveground agricultural biomass",
        tInII: 132,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 36,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 9,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "10": [
      {
        progNmII: "fertility",
        descII: "Soil fertility rating (0 to 1)",
        tInII: 2,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "0...1",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 90,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 10,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "forestProdIx",
        descII: "Forest productivity index (annual rate)",
        tInII: 48,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "1...60",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 188,
        sortNII: 418,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 10,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "modNutrTS",
        descII: "Soil nutrition modifier (modNutr) in 3PG-lite",
        tInII: 1209,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "0...2",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 181,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 2,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 10,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fpiAvgLT",
        descII: "FPI long term average",
        tInII: 2201,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 36,
        sortNII: 420,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 10,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "11": [
      {
        progNmII: "siteMultStemF",
        descII: "stems",
        tInII: 184,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 5,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultBranF",
        descII: "branches",
        tInII: 185,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 5,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultBarkF",
        descII: "bark",
        tInII: 186,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 5,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultLeafF",
        descII: "leaves",
        tInII: 187,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 5,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultCortF",
        descII: "coarse roots",
        tInII: 188,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 5,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultFirtF",
        descII: "fine roots",
        tInII: 189,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 5,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultGbfrA",
        descII: "grains-buds-fruit",
        tInII: 190,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 6,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultStlkA",
        descII: "bark",
        tInII: 191,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 6,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultLeafA",
        descII: "leaves",
        tInII: 192,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 6,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultCortA",
        descII: "coarse roots",
        tInII: 193,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 6,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "siteMultFirtA",
        descII: "fine roots",
        tInII: 194,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 6,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 39,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 11,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "12": [
      {
        progNmII: "upstreamCRatio",
        descII:
          "Upstream C emissions in making fossil fuel / C in that fossil fuel",
        tInII: 968,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 17,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 12,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "13": [
      {
        progNmII: "nSpecsF",
        descII: "Number of tree species",
        tInII: 604,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 13,
        tSpecII: 0,
        tEventII: 10,
      },
      // {
      //   progNmII: "unused1287",
      //   descII: "Tree Category",
      //   tInII: 1287,
      //   tSysII: 0,
      //   tStdII: 0,
      //   unitNmII: "",
      //   prefixII: 0,
      //   suffixII: 0,
      //   riskII: 0,
      //   spaII: 0,
      //   helpIdII: 148,
      //   sortNII: 0,
      //   subSameII: 0,
      //   tDataII: 5,
      //   loII: 0,
      //   hiII: 0,
      //   tEnumII: "SpecFrCatT",
      //   validTrickyII: 0,
      //   errNII: 0,
      //   inverseII: 0,
      //   scaleII: 1,
      //   oneMoreII: 0,
      //   colWidthII: 70,
      //   catIxII: 13,
      //   tSpecII: 1,
      //   tEventII: 10,
      // },
    ],
    "14": [
      {
        progNmII: "nSpecsA",
        descII: "Number of crop species",
        tInII: 1130,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 14,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "categoryA",
        descII: "Crop Category",
        tInII: 1286,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SpecAgCatT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 14,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "15": [
      {
        progNmII: "tSP",
        descII: "Type of plants (trees or crop)",
        tInII: 599,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 56,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SpecT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 15,
        tSpecII: 3,
        tEventII: 10,
      },
      {
        progNmII: "tAgeIxSP",
        descII:
          "Type of plant age to use in growth tables (average or maximum)",
        tInII: 601,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "AgeIxT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 15,
        tSpecII: 3,
        tEventII: 10,
      },
      {
        progNmII: "nmSP",
        descII: "Species name",
        tInII: 798,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 145,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 15,
        tSpecII: 3,
        tEventII: 10,
      },
      {
        progNmII: "notesSP",
        descII: "Species notes",
        tInII: 799,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 145,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 15,
        tSpecII: 3,
        tEventII: 10,
      },
      {
        progNmII: "inUseSP",
        descII: "Species in use",
        tInII: 1269,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 15,
        tSpecII: 3,
        tEventII: 10,
      },
      {
        progNmII: "statusSP",
        descII: "Species status",
        tInII: 1284,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StatusT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 15,
        tSpecII: 3,
        tEventII: 10,
      },
    ],
    "16": [
      {
        progNmII: "incrStemVolF",
        descII: "Stem volume increments",
        tInII: 22,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "m3/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 110,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrAbgMF",
        descII: "Aboveground",
        tInII: 23,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 110,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "allocStemF",
        descII: "stems",
        tInII: 24,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 112,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "allocBranF",
        descII: "branches",
        tInII: 25,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 112,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "allocBarkF",
        descII: "bark",
        tInII: 26,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 112,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "allocLeafF",
        descII: "leaves",
        tInII: 27,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 112,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "allocCortF",
        descII: "coarse roots",
        tInII: 28,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 112,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "allocFirtF",
        descII: "fine roots",
        tInII: 29,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 112,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pltnType",
        descII: "Plantation Type (identifier for spatial sim.s)",
        tInII: 312,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 145,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PlantationTypeT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "grthModeF",
        descII: "Types of growth tables (yields or NPP)",
        tInII: 313,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "GrthModeFT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tIncrTblF",
        descII: "Type of increments to use for tree growth",
        tInII: 602,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 110,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "IncrTblFT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "mvgTreeId",
        descII: "MVG number (native vegetation identifier for spatial sim.s)",
        tInII: 607,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 145,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tyf_r",
        descII: "of max aboveground biomass in tyf (r)",
        tInII: 1147,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 8,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrStemMF",
        descII: "Stem",
        tInII: 1204,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 110,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tyf_G",
        descII: "",
        tInII: 1257,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 7,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 16,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "17": [
      {
        progNmII: "unused198",
        descII: ": ag",
        tInII: 198,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 7,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 17,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "unused1146",
        descII: ": bg",
        tInII: 1146,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr/(t/ha)",
        prefixII: 7,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 17,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "unused1258",
        descII: "in tree yield formula (r): ar",
        tInII: 1258,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 8,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 17,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "unused1259",
        descII: "in tree yield formula (r): br",
        tInII: 1259,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 8,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 17,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "unused1260",
        descII: "unused",
        tInII: 1260,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 17,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "unused1261",
        descII: "unused",
        tInII: 1261,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 17,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "18": [
      {
        progNmII: "stemDensity",
        descII: "Stem density",
        tInII: 74,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgdm/m3",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 9,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracStemF",
        descII: "stems",
        tInII: 75,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracBranF",
        descII: "branches",
        tInII: 76,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracBarkF",
        descII: "bark",
        tInII: 77,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracLeafF",
        descII: "leaves",
        tInII: 78,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracCortF",
        descII: "coarse roots",
        tInII: 79,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracFirtF",
        descII: "fine roots",
        tInII: 80,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF01",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF02",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF03",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF04",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF05",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF06",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF07",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF08",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF09",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF10",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF11",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBranF12",
        descII: "branches",
        tInII: 81,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF01",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF02",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF03",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF04",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF05",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF06",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF07",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF08",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF09",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF10",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF11",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracBarkF12",
        descII: "bark",
        tInII: 82,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF01",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF02",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF03",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF04",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF05",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF06",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF07",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF08",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF09",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF10",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF11",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafF12",
        descII: "leaves",
        tInII: 83,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracCortF",
        descII: "coarse roots",
        tInII: 84,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "turnFracFirtF",
        descII: "fine roots",
        tInII: 85,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useBioAgeAdjMortF",
        descII: "Use biomass age adjustment (yes/no)",
        tInII: 498,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ratioBranMortF",
        descII: "Ratio of branch",
        tInII: 977,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 12,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ratioBarkMortF",
        descII: "Ratio of bark",
        tInII: 978,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 12,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ratioLeafMortF",
        descII: "Ratio of leaf",
        tInII: 979,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 12,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ratioCortMortF",
        descII: "Ratio of coarse root",
        tInII: 980,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 12,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ratioFirtMortF",
        descII: "Ratio of fine root",
        tInII: 981,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 12,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemMortF",
        descII: "Annual % of stems lost due to mortality",
        tInII: 986,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 19,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 0.999999999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "treeMortOn",
        descII: "Tree mortality (on/off)",
        tInII: 988,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "replaceStemsMortF",
        descII: "Replace dying trees with new trees (of age 0) (yes/no)",
        tInII: 1001,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "remvAvgAgeMultMortF",
        descII: "Multiplier of average age",
        tInII: 1002,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 13,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "remvMaxAgeMultMortF",
        descII: "Multiplier of age of oldest trees",
        tInII: 1003,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 13,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "remvOffsetMortF",
        descII: "Added constant",
        tInII: 1004,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 13,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 18,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "19": [
      {
        progNmII: "rFracStemF",
        descII: "stems",
        tInII: 86,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "rFracBranF",
        descII: "branches",
        tInII: 87,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "rFracBarkF",
        descII: "bark",
        tInII: 88,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "rFracLeafF",
        descII: "leaves",
        tInII: 89,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "rFracCortF",
        descII: "coarse roots",
        tInII: 90,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "rFracFirtF",
        descII: "fine roots",
        tInII: 91,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "mrFracOfRLittF",
        descII:
          "resistant litter that is 'more-resistant' (not 'less-resistant')",
        tInII: 93,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDDdwdF",
        descII: "decomposable deadwood",
        tInII: 200,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRDdwdF",
        descII: "resistant deadwood",
        tInII: 201,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDBlitF",
        descII: "decomposable bark litter",
        tInII: 202,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRBlitF",
        descII: "resistant bark litter",
        tInII: 203,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDLlitF",
        descII: "decomposable leaf litter",
        tInII: 204,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRLlitF",
        descII: "resistant leaf litter",
        tInII: 205,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDCodrF",
        descII: "decomposable coarse dead roots",
        tInII: 206,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRCodrF",
        descII: "resistant coarse dead roots",
        tInII: 207,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDFidrF",
        descII: "decomposable fine dead roots",
        tInII: 208,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRFidrF",
        descII: "resistant fine dead roots",
        tInII: 209,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDDdwdBkdnF",
        descII: "decomposable deadwood breakdown",
        tInII: 210,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRDdwdBkdnF",
        descII: "resistant deadwood breakdown",
        tInII: 211,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDBlitBkdnF",
        descII: "decomposable bark litter breakdown",
        tInII: 212,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRBlitBkdnF",
        descII: "resistant bark litter breakdown",
        tInII: 213,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDLlitBkdnF",
        descII: "decomposable leaf litter breakdown",
        tInII: 214,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRLlitBkdnF",
        descII: "resistant leaf litter breakdown",
        tInII: 215,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDCodrBkdnF",
        descII: "decomposable coarse dead root breakdown",
        tInII: 216,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRCodrBkdnF",
        descII: "resistant coarse dead root breakdown",
        tInII: 217,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDFidrBkdnF",
        descII: "decomposable fine dead root breakdown",
        tInII: 218,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRFidrBkdnF",
        descII: "resistant fine dead root breakdown",
        tInII: 219,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensSoilF",
        descII: "soil-style (on/off)",
        tInII: 1016,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 17,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensTempF",
        descII: "temperature",
        tInII: 1017,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 18,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensRainF",
        descII: "rainfall",
        tInII: 1018,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 18,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDChwdF",
        descII: "decomposable chopped wood",
        tInII: 1040,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRChwdF",
        descII: "resistant chopped wood",
        tInII: 1041,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDChwdBkdnF",
        descII: "decomposable chopped wood breakdown",
        tInII: 1042,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRChwdBkdnF",
        descII: "resistant chopped wood breakdown",
        tInII: 1043,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensMulcF",
        descII: "mulch-style (on/off)",
        tInII: 1117,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 17,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 19,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "20": [
      {
        progNmII: "pdcmFracFuelF",
        descII: "forest biofuel",
        tInII: 220,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracPaprF",
        descII: "pulp and paper",
        tInII: 221,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracPackF",
        descII: "packing wood",
        tInII: 222,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracFurnF",
        descII: "furniture,poles,preservatives",
        tInII: 223,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracFibrF",
        descII: "fiberboard",
        tInII: 224,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracConsF",
        descII: "construction timber",
        tInII: 225,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracResiF",
        descII: "mill residue",
        tInII: 226,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracPaprF",
        descII: "pulp and paper",
        tInII: 391,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracPackF",
        descII: "packing wood",
        tInII: 393,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracFurnF",
        descII: "furniture,poles,preservatives",
        tInII: 395,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracFibrF",
        descII: "fiberboard",
        tInII: 397,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracConsF",
        descII: "construction timber",
        tInII: 399,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracResiF",
        descII: "mill residue",
        tInII: 401,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracFuelF",
        descII: "forest biofuel",
        tInII: 403,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracPaprF",
        descII: "pulp and paper",
        tInII: 405,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracPackF",
        descII: "packing wood",
        tInII: 407,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracFurnF",
        descII: "furniture,poles,preservatives",
        tInII: 409,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracFibrF",
        descII: "fiberboard",
        tInII: 411,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracConsF",
        descII: "construction timber",
        tInII: 413,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fillFracResiF",
        descII: "mill residue",
        tInII: 415,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 22,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracFuelF",
        descII: "forest biofuel",
        tInII: 468,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracFibrF",
        descII: "fiberboard",
        tInII: 471,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracPackF",
        descII: "packing wood",
        tInII: 473,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracResiF",
        descII: "mill residue",
        tInII: 476,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracPaprF",
        descII: "pulp and paper",
        tInII: 478,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracConsF",
        descII: "construction timber",
        tInII: 481,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ldcmFracFurnF",
        descII: "furniture,poles,preservatives",
        tInII: 483,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 23,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "enerFracFuelF",
        descII: "forest biofuel burnt for bioenergy",
        tInII: 486,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 20,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "21": [
      {
        progNmII: "maxSFixNCRatioF",
        descII:
          "Maximum ratio of symbiotic N fixation (kgN) to C production (tC)",
        tInII: 239,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "/1000",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "storFracLeafTurnNF",
        descII: "Stored % of leaf nitrogen during turnover",
        tInII: 240,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "userRDebrNCRatioF",
        descII: "resistant debris pools",
        tInII: 241,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "absnNCRatioF",
        descII:
          "Ratio of absorbed N to the C in the decomposable fine breakdown",
        tInII: 242,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxBkdnNCRatioF",
        descII: "the decomposable fine breakdown",
        tInII: 243,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemMaxNC1F",
        descII: "tree stems",
        tInII: 1294,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 101,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemMaxNC2F",
        descII: "tree stems",
        tInII: 1295,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 27,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 101,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "branMaxNC1F",
        descII: "tree branches",
        tInII: 1296,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 102,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "branMaxNC2F",
        descII: "tree branches",
        tInII: 1297,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 27,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 102,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "barkMaxNC1F",
        descII: "tree bark",
        tInII: 1298,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 103,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "barkMaxNC2F",
        descII: "tree bark",
        tInII: 1299,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 27,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 103,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "leafMaxNC1F",
        descII: "tree leaves",
        tInII: 1300,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 104,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "leafMaxNC2F",
        descII: "tree leaves",
        tInII: 1301,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 27,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 104,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cortMaxNC1F",
        descII: "tree coarse roots",
        tInII: 1302,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 105,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cortMaxNC2F",
        descII: "tree coarse roots",
        tInII: 1303,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 27,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 105,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "firtMaxNC1F",
        descII: "tree fine roots",
        tInII: 1304,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 106,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "firtMaxNC2F",
        descII: "tree fine roots",
        tInII: 1305,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 27,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 106,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemMinNC1F",
        descII: "tree stems",
        tInII: 1306,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 101,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "branMinNC1F",
        descII: "tree branches",
        tInII: 1307,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 102,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "barkMinNC1F",
        descII: "tree bark",
        tInII: 1308,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 103,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "leafMinNC1F",
        descII: "tree leaves",
        tInII: 1309,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 104,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cortMinNC1F",
        descII: "tree coarse roots",
        tInII: 1310,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 105,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "firtMinNC1F",
        descII: "tree fine roots",
        tInII: 1311,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 106,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemDays2F",
        descII: "stems",
        tInII: 1312,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 30,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "branDays2F",
        descII: "branches",
        tInII: 1313,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 30,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "barkDays2F",
        descII: "bark",
        tInII: 1314,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 30,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "leafDays2F",
        descII: "leaves",
        tInII: 1315,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 30,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cortDays2F",
        descII: "coarse roots",
        tInII: 1316,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 30,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "firtDays2F",
        descII: "fine roots",
        tInII: 1317,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 30,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 21,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "22": [
      {
        progNmII: "fracStemToDdwdThin1",
        descII: "stems to deadwood",
        tInII: 1494,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFuelThin1",
        descII: "stems to biofuel",
        tInII: 1495,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPaprThin1",
        descII: "stems to pulp and paper",
        tInII: 1496,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPackThin1",
        descII: "stems to packing wood",
        tInII: 1497,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFurnThin1",
        descII: "stems to furniture",
        tInII: 1498,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFibrThin1",
        descII: "stems to fiberboard",
        tInII: 1499,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToConsThin1",
        descII: "stems to construction timber",
        tInII: 1500,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToResiThin1",
        descII: "stems to mill residues",
        tInII: 1501,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToDdwdThin1",
        descII: "branches to deadwood",
        tInII: 1502,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFuelThin1",
        descII: "branches to biofuel",
        tInII: 1503,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPaprThin1",
        descII: "branches to pulp and paper",
        tInII: 1504,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPackThin1",
        descII: "branches to packing wood",
        tInII: 1505,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFurnThin1",
        descII: "branches to furniture",
        tInII: 1506,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFibrThin1",
        descII: "branches to fiberboard",
        tInII: 1507,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToConsThin1",
        descII: "branches to construction wood",
        tInII: 1508,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToResiThin1",
        descII: "branches to mill residue",
        tInII: 1509,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToBlitThin1",
        descII: "bark to litter",
        tInII: 1510,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToFuelThin1",
        descII: "bark to biofuel",
        tInII: 1511,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToPaprThin1",
        descII: "bark to pulp and paper",
        tInII: 1512,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToResiThin1",
        descII: "bark to mill residue",
        tInII: 1513,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitThin1",
        descII: "leaves to litter",
        tInII: 1514,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFuelThin1",
        descII: "leaves to biofuel",
        tInII: 1515,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrThin1",
        descII: "coarse roots to dead roots",
        tInII: 1516,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFuelThin1",
        descII: "coarse roots to biofuel",
        tInII: 1517,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrThin1",
        descII: "fine roots to dead roots",
        tInII: 1518,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracDdwdToFuelThin1",
        descII: "deadwood to biofuel",
        tInII: 1519,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracChwdToFuelThin1",
        descII: "chopped wood to biofuel",
        tInII: 1520,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBlitToFuelThin1",
        descII: "bark litter to biofuel",
        tInII: 1521,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFuelThin1",
        descII: "leaf litter to biofuel",
        tInII: 1522,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 122,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToDdwdThin2",
        descII: "stems to deadwood",
        tInII: 1523,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFuelThin2",
        descII: "stems to biofuel",
        tInII: 1524,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPaprThin2",
        descII: "stems to pulp and paper",
        tInII: 1525,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPackThin2",
        descII: "stems to packing wood",
        tInII: 1526,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFurnThin2",
        descII: "stems to furniture",
        tInII: 1527,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFibrThin2",
        descII: "stems to fiberboard",
        tInII: 1528,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToConsThin2",
        descII: "stems to construction timber",
        tInII: 1529,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToResiThin2",
        descII: "stems to mill residues",
        tInII: 1530,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToDdwdThin2",
        descII: "branches to deadwood",
        tInII: 1531,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFuelThin2",
        descII: "branches to biofuel",
        tInII: 1532,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPaprThin2",
        descII: "branches to pulp and paper",
        tInII: 1533,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPackThin2",
        descII: "branches to packing wood",
        tInII: 1534,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFurnThin2",
        descII: "branches to furniture",
        tInII: 1535,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFibrThin2",
        descII: "branches to fiberboard",
        tInII: 1536,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToConsThin2",
        descII: "branches to construction wood",
        tInII: 1537,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToResiThin2",
        descII: "branches to mill residue",
        tInII: 1538,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToBlitThin2",
        descII: "bark to litter",
        tInII: 1539,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToFuelThin2",
        descII: "bark to biofuel",
        tInII: 1540,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToPaprThin2",
        descII: "bark to pulp and paper",
        tInII: 1541,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToResiThin2",
        descII: "bark to mill residue",
        tInII: 1542,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitThin2",
        descII: "leaves to litter",
        tInII: 1543,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFuelThin2",
        descII: "leaves to biofuel",
        tInII: 1544,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrThin2",
        descII: "coarse roots to dead roots",
        tInII: 1545,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFuelThin2",
        descII: "coarse roots to biofuel",
        tInII: 1546,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrThin2",
        descII: "fine roots to dead roots",
        tInII: 1547,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracDdwdToFuelThin2",
        descII: "deadwood to biofuel",
        tInII: 1548,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracChwdToFuelThin2",
        descII: "chopped wood to biofuel",
        tInII: 1549,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBlitToFuelThin2",
        descII: "bark litter to biofuel",
        tInII: 1550,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFuelThin2",
        descII: "leaf litter to biofuel",
        tInII: 1551,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 123,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToDdwdThin3",
        descII: "stems to deadwood",
        tInII: 1552,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFuelThin3",
        descII: "stems to biofuel",
        tInII: 1553,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPaprThin3",
        descII: "stems to pulp and paper",
        tInII: 1554,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPackThin3",
        descII: "stems to packing wood",
        tInII: 1555,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFurnThin3",
        descII: "stems to furniture",
        tInII: 1556,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFibrThin3",
        descII: "stems to fiberboard",
        tInII: 1557,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToConsThin3",
        descII: "stems to construction timber",
        tInII: 1558,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToResiThin3",
        descII: "stems to mill residues",
        tInII: 1559,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToDdwdThin3",
        descII: "branches to deadwood",
        tInII: 1560,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFuelThin3",
        descII: "branches to biofuel",
        tInII: 1561,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPaprThin3",
        descII: "branches to pulp and paper",
        tInII: 1562,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPackThin3",
        descII: "branches to packing wood",
        tInII: 1563,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFurnThin3",
        descII: "branches to furniture",
        tInII: 1564,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFibrThin3",
        descII: "branches to fiberboard",
        tInII: 1565,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToConsThin3",
        descII: "branches to construction wood",
        tInII: 1566,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToResiThin3",
        descII: "branches to mill residue",
        tInII: 1567,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToBlitThin3",
        descII: "bark to litter",
        tInII: 1568,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToFuelThin3",
        descII: "bark to biofuel",
        tInII: 1569,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToPaprThin3",
        descII: "bark to pulp and paper",
        tInII: 1570,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToResiThin3",
        descII: "bark to mill residue",
        tInII: 1571,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitThin3",
        descII: "leaves to litter",
        tInII: 1572,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFuelThin3",
        descII: "leaves to biofuel",
        tInII: 1573,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrThin3",
        descII: "coarse roots to dead roots",
        tInII: 1574,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFuelThin3",
        descII: "coarse roots to biofuel",
        tInII: 1575,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrThin3",
        descII: "fine roots to dead roots",
        tInII: 1576,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracDdwdToFuelThin3",
        descII: "deadwood to biofuel",
        tInII: 1577,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracChwdToFuelThin3",
        descII: "chopped wood to biofuel",
        tInII: 1578,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBlitToFuelThin3",
        descII: "bark litter to biofuel",
        tInII: 1579,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFuelThin3",
        descII: "leaf litter to biofuel",
        tInII: 1580,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 124,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToDdwdThin4",
        descII: "stems to deadwood",
        tInII: 1581,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFuelThin4",
        descII: "stems to biofuel",
        tInII: 1582,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPaprThin4",
        descII: "stems to pulp and paper",
        tInII: 1583,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPackThin4",
        descII: "stems to packing wood",
        tInII: 1584,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFurnThin4",
        descII: "stems to furniture",
        tInII: 1585,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFibrThin4",
        descII: "stems to fiberboard",
        tInII: 1586,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToConsThin4",
        descII: "stems to construction timber",
        tInII: 1587,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToResiThin4",
        descII: "stems to mill residues",
        tInII: 1588,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToDdwdThin4",
        descII: "branches to deadwood",
        tInII: 1589,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFuelThin4",
        descII: "branches to biofuel",
        tInII: 1590,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPaprThin4",
        descII: "branches to pulp and paper",
        tInII: 1591,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPackThin4",
        descII: "branches to packing wood",
        tInII: 1592,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFurnThin4",
        descII: "branches to furniture",
        tInII: 1593,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFibrThin4",
        descII: "branches to fiberboard",
        tInII: 1594,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToConsThin4",
        descII: "branches to construction wood",
        tInII: 1595,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToResiThin4",
        descII: "branches to mill residue",
        tInII: 1596,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToBlitThin4",
        descII: "bark to litter",
        tInII: 1597,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToFuelThin4",
        descII: "bark to biofuel",
        tInII: 1598,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToPaprThin4",
        descII: "bark to pulp and paper",
        tInII: 1599,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToResiThin4",
        descII: "bark to mill residue",
        tInII: 1600,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitThin4",
        descII: "leaves to litter",
        tInII: 1601,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFuelThin4",
        descII: "leaves to biofuel",
        tInII: 1602,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrThin4",
        descII: "coarse roots to dead roots",
        tInII: 1603,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFuelThin4",
        descII: "coarse roots to biofuel",
        tInII: 1604,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrThin4",
        descII: "fine roots to dead roots",
        tInII: 1605,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracDdwdToFuelThin4",
        descII: "deadwood to biofuel",
        tInII: 1606,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracChwdToFuelThin4",
        descII: "chopped wood to biofuel",
        tInII: 1607,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBlitToFuelThin4",
        descII: "bark litter to biofuel",
        tInII: 1608,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFuelThin4",
        descII: "leaf litter to biofuel",
        tInII: 1609,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 125,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToDdwdThin5",
        descII: "stems to deadwood",
        tInII: 1610,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFuelThin5",
        descII: "stems to biofuel",
        tInII: 1611,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPaprThin5",
        descII: "stems to pulp and paper",
        tInII: 1612,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToPackThin5",
        descII: "stems to packing wood",
        tInII: 1613,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFurnThin5",
        descII: "stems to furniture",
        tInII: 1614,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToFibrThin5",
        descII: "stems to fiberboard",
        tInII: 1615,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToConsThin5",
        descII: "stems to construction timber",
        tInII: 1616,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracStemToResiThin5",
        descII: "stems to mill residues",
        tInII: 1617,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToDdwdThin5",
        descII: "branches to deadwood",
        tInII: 1618,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFuelThin5",
        descII: "branches to biofuel",
        tInII: 1619,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPaprThin5",
        descII: "branches to pulp and paper",
        tInII: 1620,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToPackThin5",
        descII: "branches to packing wood",
        tInII: 1621,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFurnThin5",
        descII: "branches to furniture",
        tInII: 1622,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToFibrThin5",
        descII: "branches to fiberboard",
        tInII: 1623,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToConsThin5",
        descII: "branches to construction wood",
        tInII: 1624,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBranToResiThin5",
        descII: "branches to mill residue",
        tInII: 1625,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToBlitThin5",
        descII: "bark to litter",
        tInII: 1626,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToFuelThin5",
        descII: "bark to biofuel",
        tInII: 1627,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToPaprThin5",
        descII: "bark to pulp and paper",
        tInII: 1628,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBarkToResiThin5",
        descII: "bark to mill residue",
        tInII: 1629,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitThin5",
        descII: "leaves to litter",
        tInII: 1630,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFuelThin5",
        descII: "leaves to biofuel",
        tInII: 1631,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrThin5",
        descII: "coarse roots to dead roots",
        tInII: 1632,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFuelThin5",
        descII: "coarse roots to biofuel",
        tInII: 1633,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrThin5",
        descII: "fine roots to dead roots",
        tInII: 1634,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracDdwdToFuelThin5",
        descII: "deadwood to biofuel",
        tInII: 1635,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracChwdToFuelThin5",
        descII: "chopped wood to biofuel",
        tInII: 1636,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracBlitToFuelThin5",
        descII: "bark litter to biofuel",
        tInII: 1637,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFuelThin5",
        descII: "leaf litter to biofuel",
        tInII: 1638,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 126,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeF1",
        descII: "Name of log grade 1",
        tInII: 1735,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeF2",
        descII: "Name of log grade 2",
        tInII: 1736,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeF3",
        descII: "Name of log grade 3",
        tInII: 1737,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeF4",
        descII: "Name of log grade 4",
        tInII: 1738,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeF5",
        descII: "Name of log grade 5",
        tInII: 1739,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRWood1",
        descII: "",
        tInII: 1745,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRWood1",
        descII: "",
        tInII: 1746,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRWood1",
        descII: "",
        tInII: 1747,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRWood1",
        descII: "",
        tInII: 1748,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRWood1",
        descII: "",
        tInII: 1749,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRWood1",
        descII: "",
        tInII: 1750,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRWood1",
        descII: "Price at the mill gate",
        tInII: 1751,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRWood1",
        descII: "",
        tInII: 1752,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRWood1",
        descII: "harvest costs",
        tInII: 1753,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRWood1",
        descII: "transport costs",
        tInII: 1754,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRWood1",
        descII: "mill-gate prices",
        tInII: 1755,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 132,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRBark1",
        descII: "",
        tInII: 1756,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRBark1",
        descII: "",
        tInII: 1757,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRBark1",
        descII: "",
        tInII: 1758,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRBark1",
        descII: "",
        tInII: 1759,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRBark1",
        descII: "",
        tInII: 1760,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRBark1",
        descII: "",
        tInII: 1761,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRBark1",
        descII: "",
        tInII: 1762,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRBark1",
        descII: "",
        tInII: 1763,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRBark1",
        descII: "harvest costs",
        tInII: 1764,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRBark1",
        descII: "transport costs",
        tInII: 1765,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRBark1",
        descII: "mill-gate prices",
        tInII: 1766,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 137,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRFuel1",
        descII: "",
        tInII: 1767,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRFuel1",
        descII: "",
        tInII: 1768,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFuel1",
        descII: "",
        tInII: 1769,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFuel1",
        descII: "",
        tInII: 1770,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFuel1",
        descII: "",
        tInII: 1771,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFuel1",
        descII: "",
        tInII: 1772,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFuel1",
        descII: "",
        tInII: 1773,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFuel1",
        descII: "",
        tInII: 1774,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFuel1",
        descII: "harvest costs",
        tInII: 1775,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFuel1",
        descII: "transport costs",
        tInII: 1776,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFuel1",
        descII: "mill-gate prices",
        tInII: 1777,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 142,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRWood2",
        descII: "",
        tInII: 1778,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRWood2",
        descII: "",
        tInII: 1779,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRWood2",
        descII: "",
        tInII: 1780,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRWood2",
        descII: "",
        tInII: 1781,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRWood2",
        descII: "",
        tInII: 1782,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRWood2",
        descII: "",
        tInII: 1783,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRWood2",
        descII: "",
        tInII: 1784,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRWood2",
        descII: "",
        tInII: 1785,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRWood2",
        descII: "harvest costs",
        tInII: 1786,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRWood2",
        descII: "transport costs",
        tInII: 1787,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRWood2",
        descII: "mill-gate prices",
        tInII: 1788,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 133,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRBark2",
        descII: "",
        tInII: 1789,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRBark2",
        descII: "",
        tInII: 1790,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRBark2",
        descII: "",
        tInII: 1791,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRBark2",
        descII: "",
        tInII: 1792,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRBark2",
        descII: "",
        tInII: 1793,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRBark2",
        descII: "",
        tInII: 1794,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRBark2",
        descII: "",
        tInII: 1795,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRBark2",
        descII: "",
        tInII: 1796,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRBark2",
        descII: "harvest costs",
        tInII: 1797,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRBark2",
        descII: "transport costs",
        tInII: 1798,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRBark2",
        descII: "mill-gate prices",
        tInII: 1799,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 138,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRFuel2",
        descII: "",
        tInII: 1800,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRFuel2",
        descII: "",
        tInII: 1801,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFuel2",
        descII: "",
        tInII: 1802,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFuel2",
        descII: "",
        tInII: 1803,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFuel2",
        descII: "",
        tInII: 1804,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFuel2",
        descII: "",
        tInII: 1805,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFuel2",
        descII: "",
        tInII: 1806,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFuel2",
        descII: "",
        tInII: 1807,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFuel2",
        descII: "harvest costs",
        tInII: 1808,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFuel2",
        descII: "transport costs",
        tInII: 1809,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFuel2",
        descII: "mill-gate prices",
        tInII: 1810,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 143,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRWood3",
        descII: "",
        tInII: 1811,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRWood3",
        descII: "",
        tInII: 1812,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRWood3",
        descII: "",
        tInII: 1813,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRWood3",
        descII: "",
        tInII: 1814,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRWood3",
        descII: "",
        tInII: 1815,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRWood3",
        descII: "",
        tInII: 1816,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRWood3",
        descII: "",
        tInII: 1817,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRWood3",
        descII: "",
        tInII: 1818,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRWood3",
        descII: "harvest costs",
        tInII: 1819,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRWood3",
        descII: "transport costs",
        tInII: 1820,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRWood3",
        descII: "mill-gate prices",
        tInII: 1821,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 134,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRBark3",
        descII: "",
        tInII: 1822,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRBark3",
        descII: "",
        tInII: 1823,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRBark3",
        descII: "",
        tInII: 1824,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRBark3",
        descII: "",
        tInII: 1825,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRBark3",
        descII: "",
        tInII: 1826,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRBark3",
        descII: "",
        tInII: 1827,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRBark3",
        descII: "",
        tInII: 1828,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRBark3",
        descII: "",
        tInII: 1829,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRBark3",
        descII: "harvest costs",
        tInII: 1830,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRBark3",
        descII: "transport costs",
        tInII: 1831,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRBark3",
        descII: "mill-gate prices",
        tInII: 1832,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 139,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRFuel3",
        descII: "",
        tInII: 1833,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRFuel3",
        descII: "",
        tInII: 1834,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFuel3",
        descII: "",
        tInII: 1835,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFuel3",
        descII: "",
        tInII: 1836,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFuel3",
        descII: "",
        tInII: 1837,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFuel3",
        descII: "",
        tInII: 1838,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFuel3",
        descII: "",
        tInII: 1839,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFuel3",
        descII: "",
        tInII: 1840,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFuel3",
        descII: "harvest costs",
        tInII: 1841,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFuel3",
        descII: "transport costs",
        tInII: 1842,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFuel3",
        descII: "mill-gate prices",
        tInII: 1843,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 144,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRWood4",
        descII: "",
        tInII: 1844,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRWood4",
        descII: "",
        tInII: 1845,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRWood4",
        descII: "",
        tInII: 1846,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRWood4",
        descII: "",
        tInII: 1847,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRWood4",
        descII: "",
        tInII: 1848,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRWood4",
        descII: "",
        tInII: 1849,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRWood4",
        descII: "",
        tInII: 1850,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRWood4",
        descII: "",
        tInII: 1851,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRWood4",
        descII: "harvest costs",
        tInII: 1852,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRWood4",
        descII: "transport costs",
        tInII: 1853,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRWood4",
        descII: "mill-gate prices",
        tInII: 1854,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 135,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRBark4",
        descII: "",
        tInII: 1855,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRBark4",
        descII: "",
        tInII: 1856,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRBark4",
        descII: "",
        tInII: 1857,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRBark4",
        descII: "",
        tInII: 1858,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRBark4",
        descII: "",
        tInII: 1859,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRBark4",
        descII: "",
        tInII: 1860,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRBark4",
        descII: "",
        tInII: 1861,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRBark4",
        descII: "",
        tInII: 1862,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRBark4",
        descII: "harvest costs",
        tInII: 1863,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRBark4",
        descII: "transport costs",
        tInII: 1864,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRBark4",
        descII: "mill-gate prices",
        tInII: 1865,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 140,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRFuel4",
        descII: "",
        tInII: 1866,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRFuel4",
        descII: "",
        tInII: 1867,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFuel4",
        descII: "",
        tInII: 1868,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFuel4",
        descII: "",
        tInII: 1869,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFuel4",
        descII: "",
        tInII: 1870,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFuel4",
        descII: "",
        tInII: 1871,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFuel4",
        descII: "",
        tInII: 1872,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFuel4",
        descII: "",
        tInII: 1873,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFuel4",
        descII: "harvest costs",
        tInII: 1874,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFuel4",
        descII: "transport costs",
        tInII: 1875,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFuel4",
        descII: "mill-gate prices",
        tInII: 1876,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 145,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRWood5",
        descII: "",
        tInII: 1877,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRWood5",
        descII: "",
        tInII: 1878,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRWood5",
        descII: "",
        tInII: 1879,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRWood5",
        descII: "",
        tInII: 1880,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRWood5",
        descII: "",
        tInII: 1881,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRWood5",
        descII: "",
        tInII: 1882,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRWood5",
        descII: "",
        tInII: 1883,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRWood5",
        descII: "",
        tInII: 1884,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRWood5",
        descII: "harvest costs",
        tInII: 1885,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRWood5",
        descII: "transport costs",
        tInII: 1886,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRWood5",
        descII: "mill-gate prices",
        tInII: 1887,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 136,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRBark5",
        descII: "",
        tInII: 1888,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRBark5",
        descII: "",
        tInII: 1889,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRBark5",
        descII: "",
        tInII: 1890,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRBark5",
        descII: "",
        tInII: 1891,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRBark5",
        descII: "",
        tInII: 1892,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRBark5",
        descII: "",
        tInII: 1893,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRBark5",
        descII: "",
        tInII: 1894,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRBark5",
        descII: "",
        tInII: 1895,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRBark5",
        descII: "harvest costs",
        tInII: 1896,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRBark5",
        descII: "transport costs",
        tInII: 1897,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRBark5",
        descII: "mill-gate prices",
        tInII: 1898,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 141,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "tPRFuel5",
        descII: "",
        tInII: 1899,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "useStemDensityPRFuel5",
        descII: "",
        tInII: 1900,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 148,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFuel5",
        descII: "",
        tInII: 1901,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 149,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFuel5",
        descII: "",
        tInII: 1902,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFuel5",
        descII: "",
        tInII: 1903,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFuel5",
        descII: "",
        tInII: 1904,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFuel5",
        descII: "",
        tInII: 1905,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFuel5",
        descII: "",
        tInII: 1906,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFuel5",
        descII: "harvest costs",
        tInII: 1907,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFuel5",
        descII: "transport costs",
        tInII: 1908,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFuel5",
        descII: "mill-gate prices",
        tInII: 1909,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 146,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 22,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "23": [
      {
        progNmII: "effcEnerFuelF",
        descII: "forest biofuel",
        tInII: 820,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerPaprF",
        descII: "pulp and paper",
        tInII: 821,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerPackF",
        descII: "packing wood",
        tInII: 822,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerFurnF",
        descII: "furniture and poles",
        tInII: 823,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerFibrF",
        descII: "fiberboard",
        tInII: 824,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerConsF",
        descII: "construction wood",
        tInII: 825,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerResiF",
        descII: "mill residue",
        tInII: 826,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossFuelF",
        descII: "forest biofuel",
        tInII: 827,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossPaprF",
        descII: "pulp and paper",
        tInII: 828,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossPackF",
        descII: "packing wood",
        tInII: 829,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossFurnF",
        descII: "furniture and poles",
        tInII: 830,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossFibrF",
        descII: "fiberboard",
        tInII: 831,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossConsF",
        descII: "construction wood",
        tInII: 832,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "effcFossResiF",
        descII: "mill residue",
        tInII: 833,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerFuelF",
        descII: "forest biofuel",
        tInII: 834,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerPaprF",
        descII: "pulp and paper",
        tInII: 835,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerPackF",
        descII: "packing wood",
        tInII: 836,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerFurnF",
        descII: "furniture and poles",
        tInII: 837,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerFibrF",
        descII: "fiberboard",
        tInII: 838,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerConsF",
        descII: "construction wood",
        tInII: 839,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerResiF",
        descII: "mill residue",
        tInII: 840,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossFuelF",
        descII: "forest biofuel",
        tInII: 841,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossPaprF",
        descII: "pulp and paper",
        tInII: 842,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossPackF",
        descII: "packing wood",
        tInII: 843,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossFurnF",
        descII: "furniture and poles",
        tInII: 844,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossFibrF",
        descII: "fiberboard",
        tInII: 845,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossConsF",
        descII: "construction wood",
        tInII: 846,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossResiF",
        descII: "mill residue",
        tInII: 847,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cultivationCF",
        descII: "spent cultivating tree species on plot",
        tInII: 960,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha/yr",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "thinCF",
        descII: "used in thinning / C in thinned material",
        tInII: 961,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "biofuelBurnCF",
        descII: "used to burn forest biofuel / C in forest biofuel",
        tInII: 962,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "wasteBurnCF",
        descII:
          "used in burning non-biofuel forest products / C in those products",
        tInII: 963,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 23,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "24": [
      {
        progNmII: "makeProdPaprF",
        descII: "pulp and paper",
        tInII: 848,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeProdPackF",
        descII: "packing wood",
        tInII: 849,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeProdFurnF",
        descII: "furniture and poles",
        tInII: 850,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeProdFibrF",
        descII: "fiberboard",
        tInII: 851,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeProdConsF",
        descII: "construction wood",
        tInII: 852,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeProdResiF",
        descII: "mill residue",
        tInII: 853,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdPaprF",
        descII: "pulp and paper",
        tInII: 854,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdPackF",
        descII: "packing wood",
        tInII: 855,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdFurnF",
        descII: "furniture and poles",
        tInII: 856,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdFibrF",
        descII: "fiberboard",
        tInII: 857,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdConsF",
        descII: "construction wood",
        tInII: 858,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdResiF",
        descII: "mill residue",
        tInII: 859,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeDispPaprF",
        descII: "product displaced by pulp and paper",
        tInII: 860,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeDispPackF",
        descII: "packing wood",
        tInII: 861,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeDispFurnF",
        descII: "furniture and poles",
        tInII: 862,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeDispFibrF",
        descII: "fiberboard",
        tInII: 863,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeDispConsF",
        descII: "construction wood",
        tInII: 864,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "makeDispResiF",
        descII: "mill residue",
        tInII: 865,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispPaprF",
        descII: "pulp and paper",
        tInII: 866,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispPackF",
        descII: "packing wood",
        tInII: 867,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispFurnF",
        descII: "furniture and poles",
        tInII: 868,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispFibrF",
        descII: "fiberboard",
        tInII: 869,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispConsF",
        descII: "construction wood",
        tInII: 870,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispResiF",
        descII: "mill residue",
        tInII: 871,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdPaprF",
        descII: "pulp and paper",
        tInII: 872,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdPackF",
        descII: "packing wood",
        tInII: 873,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdFurnF",
        descII: "furniture and poles",
        tInII: 874,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdFibrF",
        descII: "fiberboard",
        tInII: 875,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdConsF",
        descII: "construction wood",
        tInII: 876,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdResiF",
        descII: "mill residue",
        tInII: 877,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispPaprF",
        descII: "pulp and paper",
        tInII: 878,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispPackF",
        descII: "packing wood",
        tInII: 879,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispFurnF",
        descII: "furniture and poles",
        tInII: 880,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispFibrF",
        descII: "fiberboard",
        tInII: 881,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispConsF",
        descII: "construction wood",
        tInII: 882,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispResiF",
        descII: "mill residue",
        tInII: 883,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdPaprF",
        descII: "pulp and paper",
        tInII: 885,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdPackF",
        descII: "packing wood",
        tInII: 886,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdFurnF",
        descII: "furniture and poles",
        tInII: 887,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdFibrF",
        descII: "fiberboard",
        tInII: 888,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdConsF",
        descII: "construction wood",
        tInII: 889,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdResiF",
        descII: "mill residue",
        tInII: 890,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 24,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "25": [
      {
        progNmII: "ratioNPPToGPP",
        descII: "Ratio of NPP to GPP",
        tInII: 244,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "nDaysLostPerFrost",
        descII: "Days of growth lost for each frost night",
        tInII: 245,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "day",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 365.25,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxStemMAt1000tph",
        descII: "Maximum stem mass per tree in mature forests of 1000 trees/ha",
        tInII: 246,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kg/tree",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "minTempForGrth",
        descII: "Minimum temperature for growth",
        tInII: 253,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -273,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "optTempForGrth",
        descII: "Optimal temperature for growth",
        tInII: 254,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -273,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxTempForGrth",
        descII: "Maximum temperature for growth",
        tInII: 255,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -273,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxAge",
        descII: "Maximum tree age",
        tInII: 266,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.01,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "agePower",
        descII: "The power in the age modifier formula",
        tInII: 267,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.01,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ageMult",
        descII: "The multiplier in the age modifier formula",
        tInII: 268,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 4,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.01,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "modTempTS",
        descII: "Temperature modifier in 3PG-lite",
        tInII: 1208,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "0...1",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 61,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 25,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "26": [
      {
        progNmII: "ratioFoliStem2cm",
        descII: "Ratio of foliage NPP to stem NPP,at 2 cm stem diameter",
        tInII: 247,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ratioFoliStem20cm",
        descII: "Ratio of foliage NPP to stem NPP,at 20 cm stem diameter",
        tInII: 248,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemMDBHMult",
        descII: "The power in the stem-mass-to-diameter formula",
        tInII: 249,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "stemMDBHPower",
        descII: "The multiplier in the stem-mass-to-diameter formula",
        tInII: 250,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxFracNPPToRoot",
        descII: "Minimum % of NPP that goes to the roots",
        tInII: 251,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "minFracNPPToRoot",
        descII: "Maximum % of NPP that goes to the roots",
        tInII: 252,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "age0BBFrac",
        descII: "stem that is branch and bark,for seedlings",
        tInII: 276,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "matureAgeBBFrac",
        descII: "stem that is branch and bark,for mature-age trees",
        tInII: 277,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ageOfHalfwayBBFrac",
        descII:
          "Age at which bark-and-bark frac. halfway between seedling and mature",
        tInII: 278,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 22,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 26,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "27": [
      {
        progNmII: "maxLitterfall",
        descII: "Maximum monthly leaf turnover fraction",
        tInII: 256,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "1/month",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "age0Litterfall",
        descII: "Monthly leaf turnover fraction for a seedling",
        tInII: 257,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "1/month",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ageMedianLitterfall",
        descII: "Age at which leaf turnover fraction has median value",
        tInII: 258,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxCanopyCond",
        descII: "Maximum canopy conductance",
        tInII: 260,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "m/s",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "maxStomatalCond",
        descII: "Maximum stomatal conductance",
        tInII: 261,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "m/s",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "condCoefft",
        descII: "VPD response coefficient for canopy conductance",
        tInII: 262,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kPa",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "canopyBoundaryCond",
        descII: "Canopy boundary layer conductance",
        tInII: 263,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "m/s",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "age0SLA",
        descII: "Specific leaf area (SLA) for a seedling",
        tInII: 269,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "m2/kg",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "matureAgeSLA",
        descII: "Specific leaf area (SLA) for a mature-age forest",
        tInII: 270,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "m2/kg",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ageOfHalfwaySLA",
        descII: "Age at which SLA is halfway between seedling and mature SLA's",
        tInII: 271,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "PARAbsorbCoefft",
        descII:
          "Radiation extinction coefft for absorption of photosyn. active rad.",
        tInII: 272,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "ageMatureCanopy",
        descII:
          "Age at which the tree canopy reaches its mature level of cover",
        tInII: 273,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fracRainIntercept",
        descII: "Percentage of precipitation intercepted by the tree canopy",
        tInII: 274,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 155,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "quantumEfficiency",
        descII: "Quantum efficiency",
        tInII: 275,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mol C / mol PAR",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 21,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 27,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "28": [
      {
        progNmII: "turnFracRoot3PG",
        descII: "roots that turnover",
        tInII: 259,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 41,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 28,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "fertFracNPPToRoot",
        descII:
          "Fertility factor for the fraction of NPP that goes to the roots",
        tInII: 264,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 41,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 28,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "modNutrFor0Fert",
        descII:
          "Soil nutrition modifier when the soil fertility rating is zero",
        tInII: 265,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 41,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 28,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
    "29": [
      {
        progNmII: "incrGbfrMA",
        descII: "Grains,buds,and fruit",
        tInII: 30,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrStlkMA",
        descII: "Stalk",
        tInII: 31,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrLeafMA",
        descII: "Leaf",
        tInII: 32,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrCortMA",
        descII: "Coarse root",
        tInII: 33,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrStLfMA",
        descII: "Stalk and leaf",
        tInII: 34,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrAbgMA",
        descII: "Aboveground",
        tInII: 35,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 43,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "allocGbfrA",
        descII: "grains-buds-fruit",
        tInII: 36,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 124,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "allocStlkA",
        descII: "stalks",
        tInII: 37,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 124,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "allocLeafA",
        descII: "leaves",
        tInII: 38,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 124,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "allocCortA",
        descII: "coarse roots",
        tInII: 39,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 124,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "allocFirtA",
        descII: "fine roots",
        tInII: 40,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 9,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 124,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "grthModeA",
        descII: "Types of growth tables (yields or NPP)",
        tInII: 600,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "GrthModeAT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tIncrTblA",
        descII: "Type of increments to use for crop growth",
        tInII: 603,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "IncrTblAT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "sigmoidalGrowthA",
        descII: "Sigmoidal Growth Factor A",
        tInII: 2137,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "sigmoidalGrowthB",
        descII: "Sigmoidal Growth Factor B",
        tInII: 2138,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 42,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "turnFracDieOA",
        descII: "crop",
        tInII: 2143,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 177,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "perennialDieOffTS",
        descII: "Die Off biomass",
        tInII: 2146,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 177,
        riskII: 1,
        spaII: 0,
        helpIdII: 118,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "perennialSDMTS",
        descII: "Perennial SDM",
        tInII: 2147,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 113,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 29,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "30": [
      {
        progNmII: "CFracGbfrA",
        descII: "grains-buds-fruit",
        tInII: 94,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracStlkA",
        descII: "stalks",
        tInII: 95,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracLeafA",
        descII: "leaves",
        tInII: 96,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracCortA",
        descII: "coarse roots",
        tInII: 97,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracFirtA",
        descII: "fine roots",
        tInII: 98,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "turnFracGbfrA",
        descII: "grains-buds-fruit",
        tInII: 99,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "turnFracStlkA",
        descII: "stalks",
        tInII: 100,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "turnFracLeafA",
        descII: "leaves",
        tInII: 101,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "turnFracCortA",
        descII: "coarse roots",
        tInII: 102,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "turnFracFirtA",
        descII: "fine roots",
        tInII: 103,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 11,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 43,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "ratioGbfrMortA",
        descII: "Ratio of grains-buds-fruit",
        tInII: 982,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 44,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "ratioLeafMortA",
        descII: "Ratio of leaf",
        tInII: 983,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 44,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "ratioCortMortA",
        descII: "Ratio of coarse root",
        tInII: 984,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 44,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "ratioFirtMortA",
        descII: "Ratio of fine root",
        tInII: 985,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 44,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMortA",
        descII: "Annual % of stalks lost due to mortality",
        tInII: 987,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 19,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 0.999999999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cropMortOn",
        descII: "Crop mortality (on/off)",
        tInII: 989,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "replaceStlksMortA",
        descII: "Replace dying plants with new plants (of age 0) (yes/no)",
        tInII: 1005,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "remvAvgAgeMultMortA",
        descII: "Multiplier of average age in formula for age of dying plants",
        tInII: 1006,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "remvMaxAgeMultMortA",
        descII:
          "Multiplier of age of oldest plants in formula for age of dying plants",
        tInII: 1007,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "remvOffsetMortA",
        descII: "Added constant in formula for age of dying plants",
        tInII: 1008,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 121,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 30,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "31": [
      {
        progNmII: "rFracGbfrA",
        descII: "grains-buds-fruit",
        tInII: 104,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "rFracStlkA",
        descII: "stalks",
        tInII: 105,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "rFracLeafA",
        descII: "leaves",
        tInII: 106,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "rFracCortA",
        descII: "coarse roots",
        tInII: 107,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "rFracFirtA",
        descII: "fine roots",
        tInII: 108,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 14,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "mrFracOfRLittA",
        descII:
          "resistant litter that is 'more-resistant' (not 'less-resistant')",
        tInII: 109,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDGlitA",
        descII: "decomposable grains-buds-fruit",
        tInII: 279,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRGlitA",
        descII: "resistant grains-buds-fruit",
        tInII: 280,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDSlitA",
        descII: "decomposable stalk litter",
        tInII: 281,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRSlitA",
        descII: "resistant stalk litter",
        tInII: 282,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDLlitA",
        descII: "decomposable leaf litter",
        tInII: 283,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRLlitA",
        descII: "resistant leaf litter",
        tInII: 284,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDCodrA",
        descII: "decomposable coarse dead roots",
        tInII: 285,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRCodrA",
        descII: "resistant coarse dead roots",
        tInII: 286,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracDFidrA",
        descII: "decomposable fine dead roots",
        tInII: 287,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracRFidrA",
        descII: "resistant fine dead roots",
        tInII: 288,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDGlitBkdnA",
        descII: "decomposable grains-buds-fruit",
        tInII: 289,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRGlitBkdnA",
        descII: "resistant grains-buds-fruit",
        tInII: 290,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDSlitBkdnA",
        descII: "decomposable stalk litter breakdown",
        tInII: 291,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRSlitBkdnA",
        descII: "resistant stalk litter breakdown",
        tInII: 292,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDLlitBkdnA",
        descII: "decomposable leaf litter breakdown",
        tInII: 293,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRLlitBkdnA",
        descII: "resistant leaf litter breakdown",
        tInII: 294,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDCodrBkdnA",
        descII: "decomposable coarse dead root breakdown",
        tInII: 295,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRCodrBkdnA",
        descII: "resistant coarse dead root breakdown",
        tInII: 296,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracDFidrBkdnA",
        descII: "decomposable fine dead root breakdown",
        tInII: 297,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracRFidrBkdnA",
        descII: "resistant fine dead root breakdown",
        tInII: 298,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensMulcA",
        descII: "mulch-style (yes/no)",
        tInII: 1019,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 17,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensTempA",
        descII: "temperature",
        tInII: 1020,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 18,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensRainA",
        descII: "rainfall",
        tInII: 1021,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 18,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "bkdnSensSoilA",
        descII: "soil-style (yes/no)",
        tInII: 1118,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 17,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 122,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 31,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "32": [
      {
        progNmII: "pdcmFracGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 299,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracCaneA",
        descII: "canes",
        tInII: 300,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracLfprA",
        descII: "leaf products",
        tInII: 301,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracRtprA",
        descII: "root products",
        tInII: 302,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracHssiA",
        descII: "hay-straw-silage",
        tInII: 303,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracAnprA",
        descII: "animal products",
        tInII: 304,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 20,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "pdcmFracFualA",
        descII: "agricultural biofuel",
        tInII: 417,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracFualA",
        descII: "agricultural biofuel",
        tInII: 419,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 421,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracCaneA",
        descII: "canes",
        tInII: 423,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracLfprA",
        descII: "leaf products",
        tInII: 425,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracRtprA",
        descII: "root products",
        tInII: 432,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracHssiA",
        descII: "hay-straw-silage",
        tInII: 437,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "enerFracAnprA",
        descII: "animal products",
        tInII: 788,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 21,
        riskII: 1,
        spaII: 0,
        helpIdII: 47,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 32,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "33": [
      {
        progNmII: "maxSFixNCRatioA",
        descII:
          "Maximum ratio of symbiotic N fixation (kgN) to C production (tC)",
        tInII: 315,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "/1000",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "storFracLeafTurnNA",
        descII: "Stored % of leaf nitrogen during turnover",
        tInII: 316,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "userRDebrNCRatioA",
        descII: "resistant debris pools",
        tInII: 317,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "absnNCRatioA",
        descII:
          "Ratio of absorbed N to the C in the decomposable fine breakdown",
        tInII: 318,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "maxBkdnNCRatioA",
        descII: "the decomposable fine breakdown",
        tInII: 319,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMaxNC1A",
        descII: "grains-buds-fruit",
        tInII: 1318,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMaxNC2A",
        descII: "grains-buds-fruit",
        tInII: 1319,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMaxNC3A",
        descII: "grains-buds-fruit",
        tInII: 1320,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMaxNC4A",
        descII: "grains-buds-fruit during grainfill",
        tInII: 1321,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMaxNC1A",
        descII: "stalks",
        tInII: 1322,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMaxNC2A",
        descII: "stalks",
        tInII: 1323,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMaxNC3A",
        descII: "stalks",
        tInII: 1324,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMaxNC4A",
        descII: "stalks during grainfill",
        tInII: 1325,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMaxNC1A",
        descII: "leaves",
        tInII: 1326,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMaxNC2A",
        descII: "leaves",
        tInII: 1327,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMaxNC3A",
        descII: "leaves",
        tInII: 1328,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMaxNC4A",
        descII: "leaves during grainfill",
        tInII: 1329,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMaxNC1A",
        descII: "coarse roots",
        tInII: 1330,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMaxNC2A",
        descII: "coarse roots",
        tInII: 1331,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMaxNC3A",
        descII: "coarse roots",
        tInII: 1332,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMaxNC4A",
        descII: "coarse roots during grainfill",
        tInII: 1333,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMaxNC1A",
        descII: "fine roots",
        tInII: 1334,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMaxNC2A",
        descII: "fine roots",
        tInII: 1335,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMaxNC3A",
        descII: "fine roots",
        tInII: 1336,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMaxNC4A",
        descII: "fine roots during grainfill",
        tInII: 1337,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMinNC1A",
        descII: "grains-buds-fruit",
        tInII: 1338,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMinNC2A",
        descII: "grains-buds-fruit",
        tInII: 1339,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMinNC3A",
        descII: "grains-buds-fruit",
        tInII: 1340,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMinNC4A",
        descII: "grains-buds-fruit during grainfill",
        tInII: 1341,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 107,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMinNC1A",
        descII: "stalks",
        tInII: 1342,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMinNC2A",
        descII: "stalks",
        tInII: 1343,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMinNC3A",
        descII: "stalks",
        tInII: 1344,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkMinNC4A",
        descII: "stalks during grainfill",
        tInII: 1345,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 108,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMinNC1A",
        descII: "leaves",
        tInII: 1346,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMinNC2A",
        descII: "leaves",
        tInII: 1347,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMinNC3A",
        descII: "leaves",
        tInII: 1348,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafMinNC4A",
        descII: "leaves during grainfill",
        tInII: 1349,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 109,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMinNC1A",
        descII: "coarse roots",
        tInII: 1350,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMinNC2A",
        descII: "coarse roots",
        tInII: 1351,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMinNC3A",
        descII: "coarse roots",
        tInII: 1352,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortMinNC4A",
        descII: "coarse roots during grainfill",
        tInII: 1353,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 110,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMinNC1A",
        descII: "fine roots",
        tInII: 1354,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 26,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMinNC2A",
        descII: "fine roots",
        tInII: 1355,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 28,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMinNC3A",
        descII: "fine roots",
        tInII: 1356,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 29,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtMinNC4A",
        descII: "fine roots during grainfill",
        tInII: 1357,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 111,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrDays2A",
        descII: "grains-buds-fruit",
        tInII: 1358,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 45,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrDays3A",
        descII: "grains-buds-fruit",
        tInII: 1359,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 46,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "gbfrDays4A",
        descII: "grains-buds-fruit",
        tInII: 1360,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 47,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkDays2A",
        descII: "stalks",
        tInII: 1361,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 45,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkDays3A",
        descII: "stalks",
        tInII: 1362,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 46,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stlkDays4A",
        descII: "stalks",
        tInII: 1363,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 47,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafDays2A",
        descII: "leaves",
        tInII: 1364,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 45,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafDays3A",
        descII: "leaves",
        tInII: 1365,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 46,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "leafDays4A",
        descII: "leaves",
        tInII: 1366,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 47,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortDays2A",
        descII: "coarse roots",
        tInII: 1367,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 45,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortDays3A",
        descII: "coarse roots",
        tInII: 1368,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 46,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cortDays4A",
        descII: "coarse roots",
        tInII: 1369,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 47,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtDays2A",
        descII: "fine roots",
        tInII: 1370,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 45,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtDays3A",
        descII: "fine roots",
        tInII: 1371,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 46,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "firtDays4A",
        descII: "fine roots",
        tInII: 1372,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 47,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 74,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 33,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "34": [
      {
        progNmII: "fracGbfrToFualHarv1",
        descII: "grains-buds-fruit to biofuel",
        tInII: 1654,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGbfpHarv1",
        descII: "grains-buds-fruit to products",
        tInII: 1655,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToHssiHarv1",
        descII: "grains-buds-fruit to hay-straw-silage",
        tInII: 1656,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGlitHarv1",
        descII: "grains-buds-fruit to litter",
        tInII: 1657,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToFualHarv1",
        descII: "stalks to biofuel",
        tInII: 1658,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToCaneHarv1",
        descII: "stalks to canes",
        tInII: 1659,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToHssiHarv1",
        descII: "stalks to hay-straw-silage",
        tInII: 1660,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToSlitHarv1",
        descII: "stalks to litter",
        tInII: 1661,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFualHarv1",
        descII: "leaves to biofuel",
        tInII: 1662,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLfprHarv1",
        descII: "leaves to products",
        tInII: 1663,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToHssiHarv1",
        descII: "leaves to hay-straw-silage",
        tInII: 1664,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitHarv1",
        descII: "leaves to litter",
        tInII: 1665,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFualHarv1",
        descII: "coarse roots to biofuel",
        tInII: 1666,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToRtprHarv1",
        descII: "coarse roots to products",
        tInII: 1667,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToHssiHarv1",
        descII: "coarse roots to hay-straw-silage",
        tInII: 1668,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrHarv1",
        descII: "coarse roots to dead roots",
        tInII: 1669,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrHarv1",
        descII: "fine roots to dead roots",
        tInII: 1670,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGlitToFualHarv1",
        descII: "grains-buds-fruit litter to biofuel",
        tInII: 1671,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracSlitToFualHarv1",
        descII: "stalk litter to biofuel",
        tInII: 1672,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFualHarv1",
        descII: "leaf litter to biofuel",
        tInII: 1673,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 128,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToFualHarv2",
        descII: "grains-buds-fruit to biofuel",
        tInII: 1674,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGbfpHarv2",
        descII: "grains-buds-fruit to products",
        tInII: 1675,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToHssiHarv2",
        descII: "grains-buds-fruit to hay-straw-silage",
        tInII: 1676,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGlitHarv2",
        descII: "grains-buds-fruit to litter",
        tInII: 1677,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToFualHarv2",
        descII: "stalks to biofuel",
        tInII: 1678,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToCaneHarv2",
        descII: "stalks to canes",
        tInII: 1679,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToHssiHarv2",
        descII: "stalks to hay-straw-silage",
        tInII: 1680,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToSlitHarv2",
        descII: "stalks to litter",
        tInII: 1681,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFualHarv2",
        descII: "leaves to biofuel",
        tInII: 1682,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLfprHarv2",
        descII: "leaves to products",
        tInII: 1683,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToHssiHarv2",
        descII: "leaves to hay-straw-silage",
        tInII: 1684,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitHarv2",
        descII: "leaves to litter",
        tInII: 1685,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFualHarv2",
        descII: "coarse roots to biofuel",
        tInII: 1686,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToRtprHarv2",
        descII: "coarse roots to products",
        tInII: 1687,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToHssiHarv2",
        descII: "coarse roots to hay-straw-silage",
        tInII: 1688,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrHarv2",
        descII: "coarse roots to dead roots",
        tInII: 1689,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrHarv2",
        descII: "fine roots to dead roots",
        tInII: 1690,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGlitToFualHarv2",
        descII: "grains-buds-fruit litter to biofuel",
        tInII: 1691,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracSlitToFualHarv2",
        descII: "stalk litter to biofuel",
        tInII: 1692,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFualHarv2",
        descII: "leaf litter to biofuel",
        tInII: 1693,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 129,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToFualHarv3",
        descII: "grains-buds-fruit to biofuel",
        tInII: 1694,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGbfpHarv3",
        descII: "grains-buds-fruit to products",
        tInII: 1695,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToHssiHarv3",
        descII: "grains-buds-fruit to hay-straw-silage",
        tInII: 1696,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGlitHarv3",
        descII: "grains-buds-fruit to litter",
        tInII: 1697,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToFualHarv3",
        descII: "stalks to biofuel",
        tInII: 1698,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToCaneHarv3",
        descII: "stalks to canes",
        tInII: 1699,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToHssiHarv3",
        descII: "stalks to hay-straw-silage",
        tInII: 1700,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToSlitHarv3",
        descII: "stalks to litter",
        tInII: 1701,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFualHarv3",
        descII: "leaves to biofuel",
        tInII: 1702,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLfprHarv3",
        descII: "leaves to products",
        tInII: 1703,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToHssiHarv3",
        descII: "leaves to hay-straw-silage",
        tInII: 1704,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitHarv3",
        descII: "leaves to litter",
        tInII: 1705,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFualHarv3",
        descII: "coarse roots to biofuel",
        tInII: 1706,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToRtprHarv3",
        descII: "coarse roots to products",
        tInII: 1707,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToHssiHarv3",
        descII: "coarse roots to hay-straw-silage",
        tInII: 1708,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrHarv3",
        descII: "coarse roots to dead roots",
        tInII: 1709,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrHarv3",
        descII: "fine roots to dead roots",
        tInII: 1710,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGlitToFualHarv3",
        descII: "grains-buds-fruit litter to biofuel",
        tInII: 1711,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracSlitToFualHarv3",
        descII: "stalk litter to biofuel",
        tInII: 1712,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFualHarv3",
        descII: "leaf litter to biofuel",
        tInII: 1713,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 130,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToFualHarv4",
        descII: "grains-buds-fruit to biofuel",
        tInII: 1714,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGbfpHarv4",
        descII: "grains-buds-fruit to products",
        tInII: 1715,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToHssiHarv4",
        descII: "grains-buds-fruit to hay-straw-silage",
        tInII: 1716,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGbfrToGlitHarv4",
        descII: "grains-buds-fruit to litter",
        tInII: 1717,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToFualHarv4",
        descII: "stalks to biofuel",
        tInII: 1718,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToCaneHarv4",
        descII: "stalks to canes",
        tInII: 1719,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToHssiHarv4",
        descII: "stalks to hay-straw-silage",
        tInII: 1720,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracStlkToSlitHarv4",
        descII: "stalks to litter",
        tInII: 1721,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToFualHarv4",
        descII: "leaves to biofuel",
        tInII: 1722,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLfprHarv4",
        descII: "leaves to products",
        tInII: 1723,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToHssiHarv4",
        descII: "leaves to hay-straw-silage",
        tInII: 1724,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLeafToLlitHarv4",
        descII: "leaves to litter",
        tInII: 1725,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToFualHarv4",
        descII: "coarse roots to biofuel",
        tInII: 1726,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToRtprHarv4",
        descII: "coarse roots to products",
        tInII: 1727,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToHssiHarv4",
        descII: "coarse roots to hay-straw-silage",
        tInII: 1728,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracCortToCodrHarv4",
        descII: "coarse roots to dead roots",
        tInII: 1729,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracFirtToFidrHarv4",
        descII: "fine roots to dead roots",
        tInII: 1730,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracGlitToFualHarv4",
        descII: "grains-buds-fruit litter to biofuel",
        tInII: 1731,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracSlitToFualHarv4",
        descII: "stalk litter to biofuel",
        tInII: 1732,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracLlitToFualHarv4",
        descII: "leaf litter to biofuel",
        tInII: 1733,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 131,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeA1",
        descII: "Name of crop grade 1",
        tInII: 1740,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeA2",
        descII: "Name of crop grade 2",
        tInII: 1741,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeA3",
        descII: "Name of crop grade 3",
        tInII: 1742,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "nmGradeA4",
        descII: "Name of crop grade 4",
        tInII: 1743,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 200,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRProd1",
        descII: "",
        tInII: 1910,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRProd1",
        descII: "",
        tInII: 1911,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRProd1",
        descII: "",
        tInII: 1912,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRProd1",
        descII: "",
        tInII: 1913,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRProd1",
        descII: "",
        tInII: 1914,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRProd1",
        descII: "",
        tInII: 1915,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRProd1",
        descII: "",
        tInII: 1916,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRProd1",
        descII: "harvest costs",
        tInII: 1917,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRProd1",
        descII: "transport costs",
        tInII: 1918,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRProd1",
        descII: "mill-gate prices",
        tInII: 1919,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 157,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRHssi1",
        descII: "",
        tInII: 1920,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRHssi1",
        descII: "",
        tInII: 1921,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRHssi1",
        descII: "",
        tInII: 1922,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRHssi1",
        descII: "",
        tInII: 1923,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRHssi1",
        descII: "",
        tInII: 1924,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRHssi1",
        descII: "",
        tInII: 1925,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRHssi1",
        descII: "",
        tInII: 1926,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRHssi1",
        descII: "harvest costs",
        tInII: 1927,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRHssi1",
        descII: "transport costs",
        tInII: 1928,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRHssi1",
        descII: "mill-gate prices",
        tInII: 1929,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 161,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRFual1",
        descII: "",
        tInII: 1930,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFual1",
        descII: "",
        tInII: 1931,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFual1",
        descII: "",
        tInII: 1932,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFual1",
        descII: "",
        tInII: 1933,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFual1",
        descII: "",
        tInII: 1934,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFual1",
        descII: "",
        tInII: 1935,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFual1",
        descII: "",
        tInII: 1936,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFual1",
        descII: "harvest costs",
        tInII: 1937,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFual1",
        descII: "transport costs",
        tInII: 1938,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFual1",
        descII: "mill-gate prices",
        tInII: 1939,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 165,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRProd2",
        descII: "",
        tInII: 1940,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRProd2",
        descII: "",
        tInII: 1941,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRProd2",
        descII: "",
        tInII: 1942,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRProd2",
        descII: "",
        tInII: 1943,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRProd2",
        descII: "",
        tInII: 1944,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRProd2",
        descII: "",
        tInII: 1945,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRProd2",
        descII: "",
        tInII: 1946,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRProd2",
        descII: "harvest costs",
        tInII: 1947,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRProd2",
        descII: "transport costs",
        tInII: 1948,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRProd2",
        descII: "mill-gate prices",
        tInII: 1949,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 158,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRHssi2",
        descII: "",
        tInII: 1950,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRHssi2",
        descII: "",
        tInII: 1951,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRHssi2",
        descII: "",
        tInII: 1952,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRHssi2",
        descII: "",
        tInII: 1953,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRHssi2",
        descII: "",
        tInII: 1954,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRHssi2",
        descII: "",
        tInII: 1955,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRHssi2",
        descII: "",
        tInII: 1956,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRHssi2",
        descII: "harvest costs",
        tInII: 1957,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRHssi2",
        descII: "transport costs",
        tInII: 1958,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRHssi2",
        descII: "mill-gate prices",
        tInII: 1959,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 162,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRFual2",
        descII: "",
        tInII: 1960,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFual2",
        descII: "",
        tInII: 1961,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFual2",
        descII: "",
        tInII: 1962,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFual2",
        descII: "",
        tInII: 1963,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFual2",
        descII: "",
        tInII: 1964,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFual2",
        descII: "",
        tInII: 1965,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFual2",
        descII: "",
        tInII: 1966,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFual2",
        descII: "harvest costs",
        tInII: 1967,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFual2",
        descII: "transport costs",
        tInII: 1968,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFual2",
        descII: "mill-gate prices",
        tInII: 1969,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 166,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRProd3",
        descII: "",
        tInII: 1970,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRProd3",
        descII: "",
        tInII: 1971,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRProd3",
        descII: "",
        tInII: 1972,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRProd3",
        descII: "",
        tInII: 1973,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRProd3",
        descII: "",
        tInII: 1974,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRProd3",
        descII: "",
        tInII: 1975,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRProd3",
        descII: "",
        tInII: 1976,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRProd3",
        descII: "harvest costs",
        tInII: 1977,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRProd3",
        descII: "transport costs",
        tInII: 1978,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRProd3",
        descII: "mill-gate prices",
        tInII: 1979,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 159,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRHssi3",
        descII: "",
        tInII: 1980,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRHssi3",
        descII: "",
        tInII: 1981,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRHssi3",
        descII: "",
        tInII: 1982,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRHssi3",
        descII: "",
        tInII: 1983,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRHssi3",
        descII: "",
        tInII: 1984,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRHssi3",
        descII: "",
        tInII: 1985,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRHssi3",
        descII: "",
        tInII: 1986,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRHssi3",
        descII: "harvest costs",
        tInII: 1987,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRHssi3",
        descII: "transport costs",
        tInII: 1988,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRHssi3",
        descII: "mill-gate prices",
        tInII: 1989,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 163,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRFual3",
        descII: "",
        tInII: 1990,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFual3",
        descII: "",
        tInII: 1991,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFual3",
        descII: "",
        tInII: 1992,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFual3",
        descII: "",
        tInII: 1993,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFual3",
        descII: "",
        tInII: 1994,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFual3",
        descII: "",
        tInII: 1995,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFual3",
        descII: "",
        tInII: 1996,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFual3",
        descII: "harvest costs",
        tInII: 1997,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFual3",
        descII: "transport costs",
        tInII: 1998,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFual3",
        descII: "mill-gate prices",
        tInII: 1999,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 167,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRProd4",
        descII: "",
        tInII: 2000,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRProd4",
        descII: "",
        tInII: 2001,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRProd4",
        descII: "",
        tInII: 2002,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRProd4",
        descII: "",
        tInII: 2003,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRProd4",
        descII: "",
        tInII: 2004,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRProd4",
        descII: "",
        tInII: 2005,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRProd4",
        descII: "",
        tInII: 2006,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRProd4",
        descII: "harvest costs",
        tInII: 2007,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRProd4",
        descII: "transport costs",
        tInII: 2008,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRProd4",
        descII: "mill-gate prices",
        tInII: 2009,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 160,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRHssi4",
        descII: "",
        tInII: 2010,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRHssi4",
        descII: "",
        tInII: 2011,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRHssi4",
        descII: "",
        tInII: 2012,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRHssi4",
        descII: "",
        tInII: 2013,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRHssi4",
        descII: "",
        tInII: 2014,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRHssi4",
        descII: "",
        tInII: 2015,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRHssi4",
        descII: "",
        tInII: 2016,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRHssi4",
        descII: "harvest costs",
        tInII: 2017,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRHssi4",
        descII: "transport costs",
        tInII: 2018,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRHssi4",
        descII: "mill-gate prices",
        tInII: 2019,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 164,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "tPRFual4",
        descII: "",
        tInII: 2020,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 147,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "PriceT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "densityPRFual4",
        descII: "",
        tInII: 2021,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "dmt/m3",
        prefixII: 150,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "fracWaterPRFual4",
        descII: "",
        tInII: 2022,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 151,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 0.95,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCostPRFual4",
        descII: "",
        tInII: 2024,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 152,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "trnsCostPRFual4",
        descII: "",
        tInII: 2024,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 153,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "millPricePRFual4",
        descII: "",
        tInII: 2025,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "$/dmt-e",
        prefixII: 154,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "stYearPRFual4",
        descII: "",
        tInII: 2026,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 155,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1800,
        hiII: 2200,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaHarvPRFual4",
        descII: "harvest costs",
        tInII: 2027,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaTrnsPRFual4",
        descII: "transport costs",
        tInII: 2028,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "incrPaMillPRFual4",
        descII: "mill-gate prices",
        tInII: 2029,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "% pa",
        prefixII: 156,
        suffixII: 168,
        riskII: 1,
        spaII: 0,
        helpIdII: 263,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 34,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "35": [
      {
        progNmII: "effcEnerFualA",
        descII: "agricultural biofuel",
        tInII: 891,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 892,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerCaneA",
        descII: "canes",
        tInII: 893,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerLfprA",
        descII: "leaf products",
        tInII: 894,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerRtprA",
        descII: "root products",
        tInII: 895,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerHssiA",
        descII: "hay-straw-silage",
        tInII: 896,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcEnerAnprA",
        descII: "animal products",
        tInII: 897,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 31,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossFualA",
        descII: "agricultural biofuel",
        tInII: 898,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 899,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossCaneA",
        descII: "canes",
        tInII: 900,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossLfprA",
        descII: "leaf products",
        tInII: 901,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossRtprA",
        descII: "root products",
        tInII: 902,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossHssiA",
        descII: "hay-straw-silage",
        tInII: 903,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "effcFossAnprA",
        descII: "animal products",
        tInII: 904,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 32,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerFualA",
        descII: "agricultural biofuel",
        tInII: 905,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 906,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerCaneA",
        descII: "canes",
        tInII: 907,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerLfprA",
        descII: "leaf products",
        tInII: 908,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerRtprA",
        descII: "root products",
        tInII: 909,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerHssiA",
        descII: "hay-straw-silage",
        tInII: 910,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrEnerAnprA",
        descII: "animal products",
        tInII: 911,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossFualA",
        descII: "agricultural biofuel",
        tInII: 912,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 913,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossCaneA",
        descII: "canes",
        tInII: 914,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossLfprA",
        descII: "leaf products",
        tInII: 915,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossRtprA",
        descII: "root products",
        tInII: 916,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossHssiA",
        descII: "hay-straw-silage",
        tInII: 917,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrFossAnprA",
        descII: "animal products",
        tInII: 918,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 34,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cultivationCA",
        descII: "spent cultivating crop species on plot",
        tInII: 964,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha/yr",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "harvCA",
        descII: "used in harvesting / C in harvested material",
        tInII: 965,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "biofuelBurnCA",
        descII:
          "used in burning agricultural biofuel / C in agricultural biofuel",
        tInII: 966,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "wasteBurnCA",
        descII:
          "used in burning non-biofuel agricultural products / C in those products",
        tInII: 967,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 37,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 16,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 35,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "36": [
      {
        progNmII: "CFracProdAnprA",
        descII: "animal products",
        tInII: 884,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeProdGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 919,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeProdCaneA",
        descII: "canes",
        tInII: 920,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeProdLfprA",
        descII: "leaf products",
        tInII: 921,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeProdRtprA",
        descII: "root products",
        tInII: 922,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeProdHssiA",
        descII: "hay-straw-silage",
        tInII: 923,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeProdAnprA",
        descII: "animal products",
        tInII: 924,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 38,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 925,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdCaneA",
        descII: "fossil fuel used to canes",
        tInII: 926,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 33,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdLfprA",
        descII: "leaf products",
        tInII: 927,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdRtprA",
        descII: "root products",
        tInII: 928,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdHssiA",
        descII: "hay-straw-silage",
        tInII: 929,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrProdAnprA",
        descII: "animal products",
        tInII: 930,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 35,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeDispGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 931,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeDispCaneA",
        descII: "canes",
        tInII: 932,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeDispLfprA",
        descII: "leaf products",
        tInII: 933,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeDispRtprA",
        descII: "root products",
        tInII: 934,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeDispHssiA",
        descII: "hay-straw-silage",
        tInII: 935,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "makeDispAnprA",
        descII: "animal products",
        tInII: 936,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "MJ/t",
        prefixII: 39,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 937,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispCaneA",
        descII: "canes",
        tInII: 938,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispLfprA",
        descII: "leaf products",
        tInII: 939,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispRtprA",
        descII: "root products",
        tInII: 940,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispHssiA",
        descII: "hay-straw-silage",
        tInII: 941,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "cemrDispAnprA",
        descII: "animal products",
        tInII: 942,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "t/MJ",
        prefixII: 36,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 943,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdCaneA",
        descII: "canes",
        tInII: 944,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdLfprA",
        descII: "leaf products",
        tInII: 945,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdRtprA",
        descII: "root products",
        tInII: 946,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdHssiA",
        descII: "hay-straw-silage",
        tInII: 947,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "MDispPerProdAnprA",
        descII: "animal products",
        tInII: 948,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 40,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 949,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispCaneA",
        descII: "canes",
        tInII: 950,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispLfprA",
        descII: "leaf products",
        tInII: 951,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispRtprA",
        descII: "root products",
        tInII: 952,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispHssiA",
        descII: "hay-straw-silage",
        tInII: 953,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "LProdPerDispAnprA",
        descII: "animal products",
        tInII: 954,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 42,
        suffixII: 41,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdGbfpA",
        descII: "grains-buds-fruit products",
        tInII: 955,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdCaneA",
        descII: "canes",
        tInII: 956,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdLfprA",
        descII: "leaf products",
        tInII: 957,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdRtprA",
        descII: "root products",
        tInII: 958,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
      {
        progNmII: "CFracProdHssiA",
        descII: "hay-straw-silage",
        tInII: 959,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 10,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 18,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 36,
        tSpecII: 2,
        tEventII: 10,
      },
    ],
    "37": [
      {
        progNmII: "sopmCMInput",
        descII: "Soluble",
        tInII: 17,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 48,
        riskII: 1,
        spaII: 1,
        helpIdII: 105,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 37,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrpmCMInput",
        descII: "Less-resistant",
        tInII: 18,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 48,
        riskII: 1,
        spaII: 1,
        helpIdII: 106,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 37,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrpmCMInput",
        descII: "More-resistant",
        tInII: 19,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 48,
        riskII: 1,
        spaII: 1,
        helpIdII: 107,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 37,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "38": [
      {
        progNmII: "fracMdcmToMicrF",
        descII: "eaten carbon that is assimilated",
        tInII: 320,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracMicrCMIsWallF",
        descII: "microbial carbon in cell walls",
        tInII: 322,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracWallCMIsLrF",
        descII: "cell wall carbon in less-resistant material",
        tInII: 324,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "turnFracMicrF",
        descII: "live mulch microbes that die each year",
        tInII: 326,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracMdcmMicrExcrF",
        descII: "assimilated carbon that is excreted",
        tInII: 328,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracSopmF",
        descII: "soluble",
        tInII: 346,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracLrpmF",
        descII: "less-resistant",
        tInII: 347,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracMrpmF",
        descII: "more-resistant",
        tInII: 348,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracSommF",
        descII: "soluble",
        tInII: 349,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracLrmmF",
        descII: "less-resistant",
        tInII: 350,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracMrmmF",
        descII: "more-resistant",
        tInII: 351,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmSensTempF",
        descII: "temperature",
        tInII: 358,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "1/(deg C)",
        prefixII: 54,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmSensRainF",
        descII: "rainfall",
        tInII: 360,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "1/mm",
        prefixII: 54,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracSopmF",
        descII: "soluble",
        tInII: 362,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracLrpmF",
        descII: "less-resistant",
        tInII: 363,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracMrpmF",
        descII: "more-resistant",
        tInII: 364,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracSommF",
        descII: "soluble",
        tInII: 365,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracLrmmF",
        descII: "less-resistant",
        tInII: 366,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracMrmmF",
        descII: "more-resistant",
        tInII: 367,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracMicrF",
        descII: "live microbes",
        tInII: 368,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 38,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "39": [
      {
        progNmII: "fracMdcmToMicrA",
        descII: "eaten carbon that is assimilated",
        tInII: 321,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracMicrCMIsWallA",
        descII: "microbial carbon in cell walls",
        tInII: 323,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracWallCMIsLrA",
        descII: "cell wall carbon in less-resistant material",
        tInII: 325,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "turnFracMicrA",
        descII: "live mulch microbes that die each year",
        tInII: 327,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracMdcmMicrExcrA",
        descII: "assimilated carbon that is excreted",
        tInII: 329,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracSopmA",
        descII: "soluble",
        tInII: 352,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracLrpmA",
        descII: "less-resistant",
        tInII: 353,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracMrpmA",
        descII: "more-resistant",
        tInII: 354,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracSommA",
        descII: "soluble",
        tInII: 355,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracLrmmA",
        descII: "less-resistant",
        tInII: 356,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmFracMrmmA",
        descII: "more-resistant",
        tInII: 357,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 19,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmSensTempA",
        descII: "temperature",
        tInII: 359,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "1/(deg C)",
        prefixII: 54,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mdcmSensRainA",
        descII: "rainfall",
        tInII: 361,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "1/mm",
        prefixII: 54,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracSopmA",
        descII: "soluble",
        tInII: 369,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracLrpmA",
        descII: "less-resistant",
        tInII: 370,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracMrpmA",
        descII: "more-resistant",
        tInII: 371,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracSommA",
        descII: "soluble",
        tInII: 372,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracLrmmA",
        descII: "less-resistant",
        tInII: 373,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracMrmmA",
        descII: "more-resistant",
        tInII: 374,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humfFracMicrA",
        descII: "live microbes",
        tInII: 375,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 49,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 29,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 39,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "40": [
      {
        progNmII: "micrNCRatioF",
        descII: "live microbes",
        tInII: 330,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sommNCRatioF",
        descII: "soluble",
        tInII: 331,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrmmNCRatioF",
        descII: "less-resistant",
        tInII: 334,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrmmNCRatioF",
        descII: "more-resistant",
        tInII: 335,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrpmNCRatioF",
        descII: "more-resistant",
        tInII: 338,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrpmNCRatioF",
        descII: "less-resistant",
        tInII: 339,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxMicrNCRatioF",
        descII: "live microbes",
        tInII: 340,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minMicrNCRatioF",
        descII: "live microbes",
        tInII: 341,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 40,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "41": [
      {
        progNmII: "micrNCRatioA",
        descII: "live microbes",
        tInII: 332,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sommNCRatioA",
        descII: "soluble",
        tInII: 333,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrmmNCRatioA",
        descII: "less-resistant",
        tInII: 336,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrmmNCRatioA",
        descII: "more-resistant",
        tInII: 337,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrpmNCRatioA",
        descII: "more-resistant",
        tInII: 342,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrpmNCRatioA",
        descII: "less-resistant",
        tInII: 343,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxMicrNCRatioA",
        descII: "live microbes",
        tInII: 344,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minMicrNCRatioA",
        descII: "live microbes",
        tInII: 345,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 76,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 41,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "42": [
      {
        progNmII: "fracHumfSopmToDpmaF",
        descII: "soluble plant mulch",
        tInII: 390,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 60,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfLrpmToRpmaF",
        descII: "less-resistant plant mulch",
        tInII: 392,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 59,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfMrpmToRpmaF",
        descII: "more-resistant plant mulch",
        tInII: 394,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 59,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfSommToDpmaF",
        descII: "soluble microbial mulch",
        tInII: 396,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 60,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 78,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfLrmmToRpmaF",
        descII: "less-resistant microbial mulch",
        tInII: 398,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 59,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 78,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfMrmmToRpmaF",
        descII: "more-resistant microbial mulch",
        tInII: 400,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 59,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 78,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfMicrToXpmaF",
        descII: "live microbes to DPM and RPM",
        tInII: 402,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracDLitBkdnToDpmaF",
        descII: "decomposable litter",
        tInII: 418,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 63,
        suffixII: 60,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 94,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracRLitBkdnToRpmaF",
        descII: "resistant litter",
        tInII: 420,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 63,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 59,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 89,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dToRRatioInPresF",
        descII: "Ratio of DPM to RPM in plant residues",
        tInII: 426,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 72,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToDpmaF",
        descII: "DPM",
        tInII: 428,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToRpmaF",
        descII: "RPM",
        tInII: 429,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToBiofF",
        descII: "BIO-F",
        tInII: 430,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 79,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToBiosF",
        descII: "BIO-S",
        tInII: 431,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 79,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuDpmaNCRatioF",
        descII: "DPM",
        tInII: 438,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuRpmaNCRatioF",
        descII: "RPM",
        tInII: 439,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuBiofNCRatioF",
        descII: "BIO-F",
        tInII: 440,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 12,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 74,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuBiosNCRatioF",
        descII: "BIO-S",
        tInII: 441,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 13,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 74,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuHumsNCRatioF",
        descII: "HUM",
        tInII: 442,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 14,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "doManuFromOffsF",
        descII: "forest soil (yes/no)",
        tInII: 800,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 66,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 42,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "43": [
      {
        progNmII: "fracHumfSopmToDpmaA",
        descII: "soluble plant mulch",
        tInII: 404,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 62,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfLrpmToRpmaA",
        descII: "less-resistant plant mulch",
        tInII: 406,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 61,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfMrpmToRpmaA",
        descII: "more-resistant plant mulch",
        tInII: 408,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 61,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfSommToDpmaA",
        descII: "soluble microbial mulch",
        tInII: 410,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 62,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 78,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfLrmmToRpmaA",
        descII: "less-resistant microbial mulch",
        tInII: 412,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 61,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 78,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfMrmmToRpmaA",
        descII: "more-resistant microbial mulch",
        tInII: 414,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 61,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 78,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumfMicrToXpmaA",
        descII: "live microbes to DPM and RPM",
        tInII: 416,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 57,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracDLitBkdnToDpmaA",
        descII: "decomposable litter",
        tInII: 422,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 63,
        suffixII: 62,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 94,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracRLitBkdnToRpmaA",
        descII: "resistant litter",
        tInII: 424,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 63,
        suffixII: 61,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 89,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dToRRatioInPresA",
        descII: "Ratio of DPM to RPM in plant residues",
        tInII: 427,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 72,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToDpmaA",
        descII: "DPM",
        tInII: 433,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToRpmaA",
        descII: "RPM",
        tInII: 434,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToBiofA",
        descII: "BIO-F",
        tInII: 435,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracManuCMToBiosA",
        descII: "BIO-S",
        tInII: 436,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 58,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuDpmaNCRatioA",
        descII: "DPM",
        tInII: 443,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuRpmaNCRatioA",
        descII: "RPM",
        tInII: 444,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuBiofNCRatioA",
        descII: "BIO-F",
        tInII: 445,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 15,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuBiosNCRatioA",
        descII: "BIO-S",
        tInII: 446,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 16,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuHumsNCRatioA",
        descII: "HUM",
        tInII: 447,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 55,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 17,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "doManuFromOffsA",
        descII: "agricultural soil (yes/no)",
        tInII: 801,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 66,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 43,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "44": [
      {
        progNmII: "encpFracHumsF",
        descII: "Forest HUM encapsulation %",
        tInII: 449,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 87,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sampleDepthF",
        descII: "Depth of forest soil sampled",
        tInII: 451,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "cm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 58,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultDpmaF",
        descII: "DPM",
        tInII: 455,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultRpmaF",
        descII: "RPM",
        tInII: 457,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultBiofV263F",
        descII: "BIO-F",
        tInII: 459,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultBiosV263F",
        descII: "BIO-S",
        tInII: 461,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultHumsF",
        descII: "HUM",
        tInII: 463,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultBiomCovF",
        descII:
          "BIO-F and BIO-S decomposition rate modifier for covered forest soil",
        tInII: 465,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 95,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracPbioToBiofV263F",
        descII: "BIO-F",
        tInII: 467,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 68,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 98,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracPbioToBiofV265F",
        descII: "BIO-F (RothC v26.5)",
        tInII: 469,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 68,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 104,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracPbioToBiosV265F",
        descII: "BIO-S (RothC v26.5)",
        tInII: 470,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 68,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 104,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumsToBiosV263F",
        descII: "BIO-S",
        tInII: 477,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 69,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumsToBiofV265F",
        descII: "BIO-F (RothC v26.5)",
        tInII: 479,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 69,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumsToBiosV265F",
        descII: "BIO-S (RothC v26.5)",
        tInII: 480,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 69,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 44,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "45": [
      {
        progNmII: "pHF",
        descII: "pH of forest soil",
        tInII: 134,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 14,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 58,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "pHA",
        descII: "pH of agricultural soil",
        tInII: 138,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 14,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 58,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "encpFracHumsA",
        descII: "Agricultural HUM encapsulation %",
        tInII: 450,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 87,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sampleDepthA",
        descII: "Depth of agricultural soil sampled",
        tInII: 452,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "cm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 58,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultDpmaA",
        descII: "DPM",
        tInII: 456,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultRpmaA",
        descII: "RPM",
        tInII: 458,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultBiofV263A",
        descII: "BIO-F",
        tInII: 460,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultBiosV263A",
        descII: "BIO-S",
        tInII: 462,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultHumsA",
        descII: "HUM",
        tInII: 464,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 67,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 77,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sdcmRateMultBiomCovA",
        descII:
          "BIO-F and BIO-S decomposition rate modifier for covered agricultural soil",
        tInII: 466,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 95,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracPbioToBiofV263A",
        descII: "BIO-F",
        tInII: 472,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 68,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 98,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracPbioToBiofV265A",
        descII: "BIO-F (RothC v26.5)",
        tInII: 474,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 68,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 104,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracPbioToBiosV265A",
        descII: "BIO-S (RothC v26.5)",
        tInII: 475,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 68,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 104,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumsToBiosV263A",
        descII: "BIO-S",
        tInII: 482,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 69,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumsToBiofV265A",
        descII: "BIO-F (RothC v26.5)",
        tInII: 484,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 69,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracHumsToBiosV265A",
        descII: "BIO-S (RothC v26.5)",
        tInII: 485,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 69,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 3,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 45,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "46": [
      {
        progNmII: "tSoil",
        descII: "Soil type (for computing ASW for 3PG)",
        tInII: 70,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 44,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SoilT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 46,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxASW",
        descII: "Maximum available soil water (ASW) (for 3PG)",
        tInII: 71,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 44,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.01,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 95,
        catIxII: 46,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "evapoOpenRatioF",
        descII: "Ratio of evapotranspiration to open-pan evaporation",
        tInII: 72,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 44,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 95,
        catIxII: 46,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bToCMaxTSMDRatioF",
        descII: "Ratio of bare-to-covered maximum TSMD",
        tInII: 453,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 44,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 10,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 79,
        catIxII: 46,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "47": [
      {
        progNmII: "evapoOpenRatioA",
        descII: "Ratio of evapotranspiration to open-pan evaporation",
        tInII: 73,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 44,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 95,
        catIxII: 47,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bToCMaxTSMDRatioA",
        descII: "Ratio of bare-to-covered maximum TSMD",
        tInII: 454,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 44,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.0001,
        hiII: 10,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 79,
        catIxII: 47,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "48": [
      {
        progNmII: "maxBiofNCRatioF",
        descII: "BIO-F",
        tInII: 378,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxBiosNCRatioF",
        descII: "BIO-S",
        tInII: 379,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxHumsNCRatioF",
        descII: "HUM",
        tInII: 380,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minBiofNCRatioF",
        descII: "BIO-F",
        tInII: 384,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minBiosNCRatioF",
        descII: "BIO-S",
        tInII: 385,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minHumsNCRatioF",
        descII: "HUM",
        tInII: 386,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "richNCRatioF",
        descII: "C:N ratio above which the forest mineral pool is 'depleted'",
        tInII: 501,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "poorNCRatioF",
        descII: "C:N ratio below which the forest mineral pool is 'healthy'",
        tInII: 502,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracDpmaToStorMycoF",
        descII: "DPM",
        tInII: 1119,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 71,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 94,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracRpmaToStorMycoF",
        descII: "RPM",
        tInII: 1120,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 71,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 94,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracBiofToStorMycoF",
        descII: "BIO-F",
        tInII: 1121,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 71,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 94,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracBiosToStorMycoF",
        descII: "BIO-S",
        tInII: 1122,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 71,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 94,
        catIxII: 48,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "49": [
      {
        progNmII: "maxBiofNCRatioA",
        descII: "BIO-F",
        tInII: 381,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxBiosNCRatioA",
        descII: "BIO-S",
        tInII: 382,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxHumsNCRatioA",
        descII: "HUM",
        tInII: 383,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 24,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minBiofNCRatioA",
        descII: "BIO-F",
        tInII: 387,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minBiosNCRatioA",
        descII: "BIO-S",
        tInII: 388,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "minHumsNCRatioA",
        descII: "HUM",
        tInII: 389,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 25,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 49,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "richNCRatioA",
        descII:
          "C:N ratio above which the agricultural mineral pool is 'depleted'",
        tInII: 503,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "poorNCRatioA",
        descII:
          "C:N ratio below which the agricultural mineral pool is 'healthy'",
        tInII: 504,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracDpmaToStorMycoA",
        descII: "DPM",
        tInII: 1124,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 72,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracRpmaToStorMycoA",
        descII: "RPM",
        tInII: 1125,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 72,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracBiofToStorMycoA",
        descII: "BIO-F",
        tInII: 1126,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 72,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracBiosToStorMycoA",
        descII: "BIO-S",
        tInII: 1127,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 70,
        suffixII: 72,
        riskII: 1,
        spaII: 1,
        helpIdII: 104,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 92,
        catIxII: 49,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "50": [
      {
        progNmII: "clayFrac",
        descII: "the soil that is clay, by weight",
        tInII: 69,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 46,
        sortNII: 414,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 50,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bulkDensity",
        descII: "Bulk density of the soil",
        tInII: 139,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "t/m3",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 46,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 2.65,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 50,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "51": [
      {
        progNmII: "presCMInput",
        descII: "Inputs: Plant residues",
        tInII: 11,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 99,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 51,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuCMFromOffsF",
        descII: "Manure from offsite",
        tInII: 12,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 101,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 51,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "manuCMFromOffsA",
        descII: "Manure from offsite",
        tInII: 13,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 101,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 51,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "soilCover",
        descII: "Agricultural soil cover",
        tInII: 14,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "0...1",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 102,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 51,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "52": [
      {
        progNmII: "mnrlNMFromOffsF",
        descII: "Nitrogen from offsite",
        tInII: 15,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 103,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniMaxF",
        descII: "Maximum denitrification rate",
        tInII: 110,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha per day",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNRMaxF",
        descII: "Maximum N2:N2O ratio during denitrification",
        tInII: 111,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha per day",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniCO2_aF",
        descII: "'a'",
        tInII: 112,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 87,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniCO2_bF",
        descII: "'b'",
        tInII: 113,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 87,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniCO2_cF",
        descII: "'c'",
        tInII: 114,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 87,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNO3_aF",
        descII: "'a'",
        tInII: 115,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 89,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNO3_bF",
        descII: "'b'",
        tInII: 116,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 89,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNO3_cF",
        descII: "'c'",
        tInII: 117,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 89,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniWater_aF",
        descII: "'a'",
        tInII: 118,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 91,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniWater_bF",
        descII: "'b'",
        tInII: 119,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 91,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniWater_cF",
        descII: "'c'",
        tInII: 120,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 91,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRCO2_aF",
        descII: "'a'",
        tInII: 121,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 93,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRCO2_bF",
        descII: "'b'",
        tInII: 122,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 93,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRCO2_cF",
        descII: "'c'",
        tInII: 123,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 93,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRNO3_aF",
        descII: "'a'",
        tInII: 124,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 95,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRNO3_bF",
        descII: "'b'",
        tInII: 125,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 95,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRNO3_cF",
        descII: "'c'",
        tInII: 126,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 95,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "depoSlopeF",
        descII: "slope",
        tInII: 487,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha per mm of rain",
        prefixII: 73,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "depoOffsetF",
        descII: "offset",
        tInII: 488,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha per year",
        prefixII: 73,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nfixSlopeF",
        descII: "slope",
        tInII: 491,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha per mm of rain",
        prefixII: 74,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nfixOffsetF",
        descII: "offset",
        tInII: 492,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha per year",
        prefixII: 74,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracBiteArrF",
        descII: "Ration bite size of forest process",
        tInII: 495,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 87,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracBiteArrA",
        descII: "Ration bite size of agricultural process",
        tInII: 496,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 87,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracMnrlNToDeepLeacF",
        descII: "mineral N lost to the depths due to leaching",
        tInII: 499,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "proRataNRationingF",
        descII: "Ration N between processes on a pro-rata basis (yes/no)",
        tInII: 972,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 64,
        riskII: 0,
        spaII: 0,
        helpIdII: 87,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rationNArrF",
        descII: "Order of process in competing for rationed N",
        tInII: 974,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 64,
        riskII: 0,
        spaII: 0,
        helpIdII: 87,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 1000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rationNArrA",
        descII: "Order of process in competing for rationed N",
        tInII: 975,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 0,
        spaII: 0,
        helpIdII: 87,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 1000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "useTSDeniF",
        descII: "Specify forest denitrification by time series (yes/no)",
        tInII: 1107,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tLeachingNF",
        descII:
          "Forest leaching specified by N cycling,fraction,or time series",
        tInII: 1109,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mnrlNMToAtmsDeniF",
        descII: "Denitrification",
        tInII: 1111,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 117,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mnrlNMToAtmsLeacF",
        descII: "Leaching",
        tInII: 1113,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 119,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrK1F",
        descII: "Percentage of net mineralized N that is",
        tInII: 1407,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 75,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrK2F",
        descII: "Percentage of nitrified N that is lost as N2O",
        tInII: 1408,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrKMaxF",
        descII: "Maximum percentage of shallow ammonium that can be",
        tInII: 1409,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "per day",
        prefixII: 0,
        suffixII: 75,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrTemp_aF",
        descII: "'a'",
        tInII: 1410,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 77,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrTemp_bF",
        descII: "'b'",
        tInII: 1411,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 77,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -20,
        hiII: 50,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrTemp_cF",
        descII: "'c'",
        tInII: 1412,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 77,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrPH_aF",
        descII: "'a'",
        tInII: 1413,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 79,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrPH_bF",
        descII: "'b'",
        tInII: 1414,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 79,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 14,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrPH_cF",
        descII: "'c'",
        tInII: 1415,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 79,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_aLF",
        descII: "'a'",
        tInII: 1416,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 83,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_aHF",
        descII: "'a'",
        tInII: 1417,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 85,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_aXF",
        descII: "Cutoff clay percentage for 'a'",
        tInII: 1418,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 81,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_bLF",
        descII: "'b'",
        tInII: 1419,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 83,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_bHF",
        descII: "'b'",
        tInII: 1420,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 85,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_bXF",
        descII: "Cutoff clay percentage for 'b'",
        tInII: 1421,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 81,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_cLF",
        descII: "'c'",
        tInII: 1422,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 83,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_cHF",
        descII: "'c'",
        tInII: 1423,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 85,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_cXF",
        descII: "Cutoff clay percentage for 'c'",
        tInII: 1424,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 81,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRWater_aF",
        descII: "'a'",
        tInII: 1425,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 97,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRWater_bF",
        descII: "'b'",
        tInII: 1426,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 97,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRWater_cF",
        descII: "'c'",
        tInII: 1427,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 97,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 52,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "53": [
      {
        progNmII: "mnrlNMFromOffsA",
        descII: "Nitrogen from offsite",
        tInII: 16,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 103,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "depoSlopeA",
        descII: "slope",
        tInII: 489,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha per mm of rain",
        prefixII: 73,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "depoOffsetA",
        descII: "offset",
        tInII: 490,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha per year",
        prefixII: 73,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nfixSlopeA",
        descII: "slope",
        tInII: 493,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha per mm of rain",
        prefixII: 74,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nfixOffsetA",
        descII: "offset",
        tInII: 494,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha per year",
        prefixII: 74,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fracMnrlNToDeepLeacA",
        descII: "mineral N lost to the depths due to leaching",
        tInII: 500,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "proRataNRationingA",
        descII: "Ration N between processes on a pro-rata basis (yes/no)",
        tInII: 973,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 0,
        spaII: 0,
        helpIdII: 87,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "useTSDeniA",
        descII: "Specify agricultural denitrification by time series (yes/no)",
        tInII: 1108,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tLeachingNA",
        descII: "Ag. leaching specified by N cycling,fraction,or time series",
        tInII: 1110,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mnrlNMToAtmsDeniA",
        descII: "Denitrification",
        tInII: 1112,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 117,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mnrlNMToAtmsLeacA",
        descII: "Leaching",
        tInII: 1114,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 119,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrK1A",
        descII: "Percentage of net mineralized N that is",
        tInII: 1428,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 76,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrK2A",
        descII: "Percentage of nitrified N that is lost as N2O",
        tInII: 1429,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrKMaxA",
        descII: "Maximum percentage of shallow ammonium that can be",
        tInII: 1430,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "per day",
        prefixII: 0,
        suffixII: 76,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrTemp_aA",
        descII: "'a'",
        tInII: 1431,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 78,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrTemp_bA",
        descII: "'b'",
        tInII: 1432,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 78,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -20,
        hiII: 50,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrTemp_cA",
        descII: "'c'",
        tInII: 1433,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 78,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrPH_aA",
        descII: "'a'",
        tInII: 1434,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 80,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrPH_bA",
        descII: "'b'",
        tInII: 1435,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 80,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 14,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrPH_cA",
        descII: "'c'",
        tInII: 1436,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 80,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_aLA",
        descII: "'a'",
        tInII: 1437,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 84,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_aHA",
        descII: "'a'",
        tInII: 1438,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 86,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_aXA",
        descII: "Cutoff clay percentage for 'a'",
        tInII: 1439,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 82,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_bLA",
        descII: "'b'",
        tInII: 1440,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 84,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_bHA",
        descII: "'b'",
        tInII: 1441,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 86,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_bXA",
        descII: "Cutoff clay percentage for 'b'",
        tInII: 1442,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 82,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_cLA",
        descII: "'c'",
        tInII: 1443,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 84,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_cHA",
        descII: "'c'",
        tInII: 1444,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 86,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nitrWater_cXA",
        descII: "Cutoff clay percentage for 'c'",
        tInII: 1445,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 82,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniMaxA",
        descII: "Maximum denitrification rate",
        tInII: 1446,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha per day",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNRMaxA",
        descII: "Maximum N2:N2O ratio during denitrification",
        tInII: 1447,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha per day",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniCO2_aA",
        descII: "'a'",
        tInII: 1448,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 88,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniCO2_bA",
        descII: "'b'",
        tInII: 1449,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 88,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniCO2_cA",
        descII: "'c'",
        tInII: 1450,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 88,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNO3_aA",
        descII: "'a'",
        tInII: 1451,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 90,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNO3_bA",
        descII: "'b'",
        tInII: 1452,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 90,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniNO3_cA",
        descII: "'c'",
        tInII: 1453,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 90,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniWater_aA",
        descII: "'a'",
        tInII: 1454,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 92,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniWater_bA",
        descII: "'b'",
        tInII: 1455,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 92,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniWater_cA",
        descII: "'c'",
        tInII: 1456,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 92,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRCO2_aA",
        descII: "'a'",
        tInII: 1457,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 94,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRCO2_bA",
        descII: "'b'",
        tInII: 1458,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 94,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRCO2_cA",
        descII: "'c'",
        tInII: 1459,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgC/ha per day",
        prefixII: 0,
        suffixII: 94,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRNO3_aA",
        descII: "'a'",
        tInII: 1460,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 96,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRNO3_bA",
        descII: "'b'",
        tInII: 1461,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 96,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRNO3_cA",
        descII: "'c'",
        tInII: 1462,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 96,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRWater_aA",
        descII: "'a'",
        tInII: 1463,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 98,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 9.9,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRWater_bA",
        descII: "'b'",
        tInII: 1464,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 98,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "deniRWater_cA",
        descII: "'c'",
        tInII: 1465,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 98,
        riskII: 1,
        spaII: 1,
        helpIdII: 162,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 53,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "54": [
      {
        progNmII: "bondOnF",
        descII: "Forest nitrogen boundary-line emissions (on/off)",
        tInII: 1065,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondEmitLoF",
        descII: "Low level of",
        tInII: 1066,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 99,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondEmitMedF",
        descII: "Medium level of",
        tInII: 1067,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 99,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondEmitHiF",
        descII: "High level of",
        tInII: 1068,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 99,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondMnrlNMF",
        descII: "Mineral nitrogen threshold for",
        tInII: 1069,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 99,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondSoilTempF",
        descII: "Soil temperature threshold for ",
        tInII: 1070,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 99,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondSpreadF",
        descII:
          "Forest boundary-line spread (for time steps longer than 1 day)",
        tInII: 1071,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 54,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "55": [
      {
        progNmII: "bondOnA",
        descII: "Agricultural nitrogen boundary-line emissions (on/off)",
        tInII: 1058,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondEmitLoA",
        descII: "Low level of",
        tInII: 1059,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 100,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondEmitMedA",
        descII: "Medium level of",
        tInII: 1060,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 100,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondEmitHiA",
        descII: "High level of",
        tInII: 1061,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 100,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondMnrlNMA",
        descII: "Mineral nitrogen threshold for",
        tInII: 1062,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 100,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondSoilTempA",
        descII: "Soil temperature threshold for",
        tInII: 1063,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "deg C",
        prefixII: 0,
        suffixII: 100,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "bondSpreadA",
        descII:
          "Agricultural boundary-line spread (for time steps longer than 1 day)",
        tInII: 1064,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 67,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 55,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "56": [
      {
        progNmII: "avgTreeAgeInit",
        descII: "Average age of trees",
        tInII: 142,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxTreeAgeInit",
        descII: "Age of oldest trees",
        tInII: 143,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stemVolInitF",
        descII: "Stem volume",
        tInII: 144,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "m3/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "branMInitF",
        descII: "Branch mass",
        tInII: 145,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "barkMInitF",
        descII: "Bark mass",
        tInII: 146,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "leafMInitF",
        descII: "Leaf mass",
        tInII: 147,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cortMInitF",
        descII: "Coarse root mass",
        tInII: 148,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firtMInitF",
        descII: "Fine root mass",
        tInII: 149,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stemM3PGInit",
        descII: "Stem mass (3PG)",
        tInII: 151,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "foliM3PGInit",
        descII: "Foliage mass (3PG)",
        tInII: 152,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rootM3PGInit",
        descII: "Root mass (3PG)",
        tInII: 153,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "nStemsInit",
        descII: "Stems per hectare",
        tInII: 154,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "1/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stemNCRatioInitF",
        descII: "stems",
        tInII: 506,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 1,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "branNCRatioInitF",
        descII: "branches",
        tInII: 507,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 2,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "barkNCRatioInitF",
        descII: "bark",
        tInII: 508,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 3,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "leafNCRatioInitF",
        descII: "leaves",
        tInII: 509,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 4,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cortNCRatioInitF",
        descII: "coarse roots",
        tInII: 510,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 5,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firtNCRatioInitF",
        descII: "fine roots",
        tInII: 511,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 6,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "storNMInitF",
        descII: "Nitrogen mass in tree store",
        tInII: 512,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tInitStem",
        descII: "Method of specifying initial sizes of tree components",
        tInII: 628,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "InitStemT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "treeExistsInit",
        descII: "Trees initially growing in the forest (yes/no)",
        tInII: 1079,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "treeNmInit",
        descII: "Name of initial tree species",
        tInII: 1131,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stemMInitF",
        descII: "Stem mass",
        tInII: 1137,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stemFracInitF",
        descII: "Stem",
        tInII: 1138,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 101,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "branFracInitF",
        descII: "Branch",
        tInII: 1139,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 101,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "barkFracInitF",
        descII: "Bark",
        tInII: 1140,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 101,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "leafFracInitF",
        descII: "Leaf",
        tInII: 1141,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 101,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cortFracInitF",
        descII: "Coarse root",
        tInII: 1142,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 101,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firtFracInitF",
        descII: "Fine root",
        tInII: 1143,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 101,
        riskII: 1,
        spaII: 1,
        helpIdII: 185,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 56,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "57": [
      {
        progNmII: "dFidrCMInitF",
        descII: "decomposable fine dead roots",
        tInII: 21,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dDdwdCMInitF",
        descII: "decomposable deadwood",
        tInII: 155,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rDdwdCMInitF",
        descII: "resistant deadwood",
        tInII: 156,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dBlitCMInitF",
        descII: "decomposable bark litter",
        tInII: 157,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rBlitCMInitF",
        descII: "resistant bark litter",
        tInII: 158,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dLlitCMInitF",
        descII: "decomposable leaf litter",
        tInII: 159,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rLlitCMInitF",
        descII: "resistant leaf litter",
        tInII: 160,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dCodrCMInitF",
        descII: "decomposable coarse dead roots",
        tInII: 161,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rCodrCMInitF",
        descII: "resistant coarse dead roots",
        tInII: 162,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rFidrCMInitF",
        descII: "resistant fine dead roots",
        tInII: 163,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dDdwdNCRatioInitF",
        descII: "decomposable deadwood",
        tInII: 513,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dBlitNCRatioInitF",
        descII: "decomposable bark litter",
        tInII: 514,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dLlitNCRatioInitF",
        descII: "decomposable leaf litter",
        tInII: 515,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dCodrNCRatioInitF",
        descII: "decomposable coarse dead roots",
        tInII: 516,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dFidrNCRatioInitF",
        descII: "decomposable fine dead roots",
        tInII: 517,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dChwdCMInitF",
        descII: "decomposable chopped wood",
        tInII: 1044,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rChwdCMInitF",
        descII: "resistant chopped wood",
        tInII: 1045,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dChwdNCRatioInitF",
        descII: "decomposable chopped wood",
        tInII: 1046,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 57,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "58": [
      {
        progNmII: "sopmCMInitF",
        descII: "soluble",
        tInII: 164,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrpmCMInitF",
        descII: "less-resistant",
        tInII: 165,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrpmCMInitF",
        descII: "more-resistant",
        tInII: 166,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sommCMInitF",
        descII: "soluble",
        tInII: 167,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrmmCMInitF",
        descII: "less-resistant",
        tInII: 168,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrmmCMInitF",
        descII: "more-resistant",
        tInII: 169,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "micrCMInitF",
        descII: "live microbes",
        tInII: 170,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sopmNCRatioInitF",
        descII: "soluble",
        tInII: 518,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 51,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sommNCRatioInitF",
        descII: "soluble",
        tInII: 519,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 50,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "micrNCRatioInitF",
        descII: "live microbes",
        tInII: 520,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 18,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 58,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "59": [
      {
        progNmII: "ASWInitF",
        descII: "Available soil water (ASW)",
        tInII: 150,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dpmaCMInitF",
        descII: "DPM",
        tInII: 172,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rpmaCMInitF",
        descII: "RPM",
        tInII: 173,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biofCMInitF",
        descII: "BIO-F",
        tInII: 174,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biosCMInitF",
        descII: "BIO-S",
        tInII: 175,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humsCMInitF",
        descII: "HUM",
        tInII: 176,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "TSMDInitF",
        descII: "Forest topsoil moisture deficit (TSMD)",
        tInII: 177,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "inrtCMInitF",
        descII: "inert soil",
        tInII: 178,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dpmaNCRatioInitF",
        descII: "DPM",
        tInII: 523,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rpmaNCRatioInitF",
        descII: "RPM",
        tInII: 524,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biofNCRatioInitF",
        descII: "BIO-F",
        tInII: 525,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 12,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biosNCRatioInitF",
        descII: "BIO-S",
        tInII: 526,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 13,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humsNCRatioInitF",
        descII: "HUM",
        tInII: 527,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 14,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "inrtNCRatioInitF",
        descII: "inert soil",
        tInII: 528,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 59,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "60": [
      {
        progNmII: "mulcNMInitF",
        descII: "available nitrogen",
        tInII: 171,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 172,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 60,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sAmmNMInitF",
        descII: "shallow ammonium (with forest",
        tInII: 237,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 60,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sNtrNMInitF",
        descII: "shallow nitrate (with forest",
        tInII: 238,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 60,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dAmmNMInitF",
        descII: "deep ammonium (below forest",
        tInII: 1083,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 60,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dNtrNMInitF",
        descII: "deep nitrate (below forest",
        tInII: 1378,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 60,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "61": [
      {
        progNmII: "fuelCMInitF",
        descII: "biofuel stocks",
        tInII: 529,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "paprCMInitF",
        descII: "pulp and paper",
        tInII: 530,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 20,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "packCMInitF",
        descII: "packing wood",
        tInII: 531,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 20,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "furnCMInitF",
        descII: "furniture,poles,and preservatives",
        tInII: 532,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 20,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fibrCMInitF",
        descII: "fiberboard",
        tInII: 533,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 20,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "consCMInitF",
        descII: "construction timber",
        tInII: 534,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 20,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "resiCMInitF",
        descII: "mill residue",
        tInII: 535,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 20,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fuelNCRatioInitF",
        descII: "biofuel",
        tInII: 536,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 64,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "paprNCRatioInitF",
        descII: "pulp and paper",
        tInII: 537,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "packNCRatioInitF",
        descII: "packing wood",
        tInII: 538,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "furnNCRatioInitF",
        descII: "furniture,poles,and preservatives",
        tInII: 539,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fibrNCRatioInitF",
        descII: "fiberboard",
        tInII: 540,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "consNCRatioInitF",
        descII: "construction timber",
        tInII: 541,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "resiNCRatioInitF",
        descII: "mill residue",
        tInII: 542,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fuelLfCMInitF",
        descII: "forest biofuel",
        tInII: 802,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "paprLfCMInitF",
        descII: "pulp and paper",
        tInII: 803,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "packLfCMInitF",
        descII: "packing wood",
        tInII: 804,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "furnLfCMInitF",
        descII: "furniture,poles,and preservatives",
        tInII: 805,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fibrLfCMInitF",
        descII: "fiberboard",
        tInII: 806,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "consLfCMInitF",
        descII: "construction timber",
        tInII: 807,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "resiLfCMInitF",
        descII: "mill residue",
        tInII: 808,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 23,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 61,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "62": [
      {
        progNmII: "leafMInitA",
        descII: "Leaf mass",
        tInII: 41,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cortMInitA",
        descII: "Coarse root mass",
        tInII: 42,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firtMInitA",
        descII: "Fine root mass",
        tInII: 43,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxCropAgeInit",
        descII: "Age of oldest plants",
        tInII: 137,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "avgCropAgeInit",
        descII: "Average age of plants",
        tInII: 179,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "gbfrMInitA",
        descII: "Grains,buds,and fruit mass",
        tInII: 180,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stlkMInitA",
        descII: "Stalk mass",
        tInII: 181,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "gbfrNCRatioInitA",
        descII: "grains-buds-fruit",
        tInII: 543,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 7,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "stlkNCRatioInitA",
        descII: "stalks",
        tInII: 544,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 8,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "leafNCRatioInitA",
        descII: "leaves",
        tInII: 545,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 9,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cortNCRatioInitA",
        descII: "coarse roots",
        tInII: 546,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 10,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "firtNCRatioInitA",
        descII: "fine roots",
        tInII: 547,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 11,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "storNMInitA",
        descII: "Nitrogen mass in plant store",
        tInII: 548,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cropExistsInit",
        descII: "Plants initially growing in the agricultural system (yes/no)",
        tInII: 1080,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cropNmInit",
        descII: "Name of initial crop species",
        tInII: 1132,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 184,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 62,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "63": [
      {
        progNmII: "dGlitCMInitA",
        descII: "decomposable grains-buds-fruit litter",
        tInII: 44,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rGlitCMInitA",
        descII: "resistant grains-buds-fruit litter",
        tInII: 45,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dSlitCMInitA",
        descII: "decomposable stalk litter",
        tInII: 46,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rLlitCMInitA",
        descII: "resistant leaf litter",
        tInII: 49,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dCodrCMInitA",
        descII: "decomposable coarse dead roots",
        tInII: 50,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rCodrCMInitA",
        descII: "resistant coarse dead roots",
        tInII: 51,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dFidrCMInitA",
        descII: "decomposable fine dead roots",
        tInII: 52,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rFidrCMInitA",
        descII: "resistant fine dead roots",
        tInII: 53,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rSlitCMInitA",
        descII: "resistant stalk litter",
        tInII: 182,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dLlitCMInitA",
        descII: "decomposable leaf litter",
        tInII: 183,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dGlitNCRatioInitA",
        descII: "decomposable grains-buds-fruit litter",
        tInII: 549,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dSlitNCRatioInitA",
        descII: "decomposable stalk litter",
        tInII: 550,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dLlitNCRatioInitA",
        descII: "decomposable leaf litter",
        tInII: 551,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dCodrNCRatioInitA",
        descII: "decomposable coarse dead roots",
        tInII: 552,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dFidrNCRatioInitA",
        descII: "decomposable fine dead roots",
        tInII: 553,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 63,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "64": [
      {
        progNmII: "sopmCMInitA",
        descII: "soluble",
        tInII: 54,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrpmCMInitA",
        descII: "less-resistant",
        tInII: 55,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrpmCMInitA",
        descII: "more-resistant",
        tInII: 56,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sommCMInitA",
        descII: "soluble",
        tInII: 57,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lrmmCMInitA",
        descII: "less-resistant",
        tInII: 58,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "mrmmCMInitA",
        descII: "more-resistant",
        tInII: 59,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "micrCMInitA",
        descII: "live microbes",
        tInII: 60,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sopmNCRatioInitA",
        descII: "soluble",
        tInII: 554,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 53,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sommNCRatioInitA",
        descII: "soluble",
        tInII: 555,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 52,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "micrNCRatioInitA",
        descII: "live microbes",
        tInII: 556,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 37,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 19,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 64,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "65": [
      {
        progNmII: "dpmaCMInitA",
        descII: "DPM",
        tInII: 62,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rpmaCMInitA",
        descII: "RPM",
        tInII: 63,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biofCMInitA",
        descII: "BIO-F",
        tInII: 64,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biosCMInitA",
        descII: "BIO-S",
        tInII: 65,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humsCMInitA",
        descII: "HUM",
        tInII: 66,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "TSMDInitA",
        descII: "Agricultural topsoil moisture deficit (TSMD)",
        tInII: 67,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "inrtCMInitA",
        descII: "inert soil",
        tInII: 68,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dpmaNCRatioInitA",
        descII: "DPM",
        tInII: 557,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rpmaNCRatioInitA",
        descII: "RPM",
        tInII: 558,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biofNCRatioInitA",
        descII: "BIO-F",
        tInII: 559,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 15,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "biosNCRatioInitA",
        descII: "BIO-S",
        tInII: 560,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 16,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "humsNCRatioInitA",
        descII: "HUM",
        tInII: 561,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 17,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "inrtNCRatioInitA",
        descII: "inert soil",
        tInII: 562,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 32,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 65,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "66": [
      {
        progNmII: "mulcNMInitA",
        descII: "agricultural mineralized nitrogen",
        tInII: 61,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 66,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sAmmNMInitA",
        descII: "shallow ammonium (with agricultural",
        tInII: 1379,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 66,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sNtrNMInitA",
        descII: "shallow nitrate (with agricultural",
        tInII: 1380,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 66,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dAmmNMInitA",
        descII: "deep ammonium (below agricultural",
        tInII: 1381,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 66,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dNtrNMInitA",
        descII: "deep nitrate (below agricultural",
        tInII: 1382,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "kgN/ha",
        prefixII: 103,
        suffixII: 104,
        riskII: 1,
        spaII: 1,
        helpIdII: 100,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 66,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "67": [
      {
        progNmII: "gbfpCMInitA",
        descII: "grains-buds-fruit products",
        tInII: 563,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "caneCMInitA",
        descII: "canes",
        tInII: 564,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lfprCMInitA",
        descII: "leaf products",
        tInII: 565,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rtprCMInitA",
        descII: "root products",
        tInII: 566,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "hssiCMInitA",
        descII: "hay-straw-silage",
        tInII: 567,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "anprCMInitA",
        descII: "animal products",
        tInII: 568,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "gbfpNCRatioInitA",
        descII: "grains-buds-fruit products",
        tInII: 569,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "caneNCRatioInitA",
        descII: "canes",
        tInII: 570,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lfprNCRatioInitA",
        descII: "leaf products",
        tInII: 571,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rtprNCRatioInitA",
        descII: "root products",
        tInII: 572,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "hssiNCRatioInitA",
        descII: "hay-straw-silage",
        tInII: 573,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "anprNCRatioInitA",
        descII: "animal products",
        tInII: 574,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fualCMInitA",
        descII: "biofuel stocks",
        tInII: 809,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha",
        prefixII: 172,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fualNCRatioInitA",
        descII: "biofuel stocks",
        tInII: 810,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 65,
        riskII: 1,
        spaII: 1,
        helpIdII: 33,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 67,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "68": [
      {
        progNmII: "frFracInit",
        descII: "Forest % (manually entered)",
        tInII: 133,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 197,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 68,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tFrFracInit",
        descII: "Type of initial forest %",
        tInII: 606,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 197,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "FrFracT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 68,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "69": [
      {
        progNmII: "incmStYr",
        descII: "Carbon income period - first year",
        tInII: 2030,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -900,
        hiII: 9000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmEnYr",
        descII: "Carbon income period - last year",
        tInII: 2031,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -900,
        hiII: 9000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmTreeF",
        descII: "trees",
        tInII: 2032,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmDebrF",
        descII: "forest debris",
        tInII: 2033,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmMulcF",
        descII: "forest mulch",
        tInII: 2034,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmSoilF",
        descII: "forest soil",
        tInII: 2035,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmProdF",
        descII: "forest products",
        tInII: 2036,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmCropA",
        descII: "crops",
        tInII: 2037,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmDebrA",
        descII: "agricultural debris",
        tInII: 2038,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmMulcA",
        descII: "agricultural mulch",
        tInII: 2039,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmSoilA",
        descII: "agricultural soil",
        tInII: 2040,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "incmProdA",
        descII: "agricultural products",
        tInII: 2041,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cPriceUseTS",
        descII: "Price of carbon specified by time series",
        tInII: 2042,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 169,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cPriceBaseYr",
        descII: "Base year for carbon price trajectory",
        tInII: 2043,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -900,
        hiII: 9000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cPriceBase",
        descII: "Base price in carbon price trajectory",
        tInII: 2044,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "$/t CO2-e",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cPriceIncr",
        descII: "Annual real increase in carbon price trajectory",
        tInII: 2045,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cPriceTS",
        descII: "Carbon price time series",
        tInII: 2046,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "$/t CO2-e",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 1,
        tDataII: 6,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "discountRateEC",
        descII: "Annual real discount rate (the price of money)",
        tInII: 2047,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "refDiscountYr",
        descII: "Reference year for discounting of cashflows",
        tInII: 2048,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -900,
        hiII: 9000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 75,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lastDiscountYr",
        descII: "Last year for discounting of cashflows",
        tInII: 2049,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 264,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -900,
        hiII: 9000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 75,
        catIxII: 69,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "70": [
      {
        progNmII: "nEvents",
        descII: "Number of events",
        tInII: 976,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 136,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 70,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "71": [
      {
        progNmII: "tAaqEV",
        descII: "Type of agricultural autoqueue event",
        tInII: 522,
        tSysII: 2,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "AgAutoQT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "aqEnYrEV",
        descII: "End year for applicability in autoqueue",
        tInII: 586,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "onEV",
        descII: "Event on/off",
        tInII: 608,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "tEV",
        descII: "Type of event",
        tInII: 609,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "EventT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "dateOriginEV",
        descII:
          "Type of event date specification (calendar or since start of simulation)",
        tInII: 610,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "TimeOriginT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "dateEV",
        descII: "Date of event,specified as a calendar date",
        tInII: 611,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 9,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "nDaysFromStEV",
        descII: "Date of event,specified in days since start of simulation",
        tInII: 612,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -364635,
        hiII: 3649635,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 90,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "nmEV",
        descII: "Event name",
        tInII: 613,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "labelEV",
        descII: "Event label",
        tInII: 1148,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 3,
        loII: 1,
        hiII: 31,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "tFaqEV",
        descII: "Type of forest autoqueue event",
        tInII: 1186,
        tSysII: 2,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "FrAutoQT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "aqStYrEV",
        descII: "Start year for applicability in autoqueue",
        tInII: 1189,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "notesEV",
        descII: "Event notes",
        tInII: 1288,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "nYrsFromStEV",
        descII:
          "Date of event,specified in calendar years since start of simulation",
        tInII: 2104,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 143,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 90,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
      {
        progNmII: "categoryEV",
        descII: "Event regime category",
        tInII: 2114,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 137,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "RgaCategoryT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 65,
        catIxII: 71,
        tSpecII: 0,
        tEventII: 11,
      },
    ],
    "72": [
      {
        progNmII: "agePlnF",
        descII: "Age of trees when planted",
        tInII: 614,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "stemVolPlnF",
        descII: "Volume of stems",
        tInII: 615,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "m3/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "branMPlnF",
        descII: "branches",
        tInII: 616,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "barkMPlnF",
        descII: "bark",
        tInII: 617,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "leafMPlnF",
        descII: "leaves",
        tInII: 618,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "cortMPlnF",
        descII: "coarse roots",
        tInII: 619,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "firtMPlnF",
        descII: "fine roots",
        tInII: 620,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "stemNCRatioPlnF",
        descII: "stems",
        tInII: 621,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 1,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "branNCRatioPlnF",
        descII: "branches",
        tInII: 622,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 2,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "barkNCRatioPlnF",
        descII: "bark",
        tInII: 623,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 3,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "leafNCRatioPlnF",
        descII: "leaves",
        tInII: 624,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 4,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "cortNCRatioPlnF",
        descII: "coarse roots",
        tInII: 625,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 5,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "firtNCRatioPlnF",
        descII: "fine roots",
        tInII: 626,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 6,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "storNMPlnF",
        descII: "Nitrogen mass in plant store",
        tInII: 627,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "treeNmPlnF",
        descII: "Name of tree species",
        tInII: 1133,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "tStemPlnF",
        descII: "Method of specifying sizes of components of planted trees",
        tInII: 1144,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "InitStemT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "stemMPlnF",
        descII: "stems",
        tInII: 1145,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "fixPlnF",
        descII: "tree planting event",
        tInII: 2050,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "phaPlnF",
        descII: "tree planting event",
        tInII: 2051,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
      {
        progNmII: "tTYFCatInitF",
        descII: "Initial Growth Calibration ",
        tInII: 2120,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "TYFCatT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tTYFCat",
        descII: "Growth Calibration",
        tInII: 2121,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 158,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "TYFCatT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 72,
        tSpecII: 0,
        tEventII: 6,
      },
    ],
    "73": [
      {
        progNmII: "agePlnA",
        descII: "Age of crops when planted",
        tInII: 629,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "gbfrMPlnA",
        descII: "grains-buds-fruit",
        tInII: 630,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "stlkMPlnA",
        descII: "stalks",
        tInII: 631,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "leafMPlnA",
        descII: "leaves",
        tInII: 632,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "cortMPlnA",
        descII: "coarse roots",
        tInII: 633,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "firtMPlnA",
        descII: "fine roots",
        tInII: 634,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha",
        prefixII: 172,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "gbfrNCRatioPlnA",
        descII: "grains-buds-fruit",
        tInII: 635,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 7,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "stlkNCRatioPlnA",
        descII: "stalks",
        tInII: 636,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 8,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "leafNCRatioPlnA",
        descII: "leaves",
        tInII: 637,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 9,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "cortNCRatioPlnA",
        descII: "coarse roots",
        tInII: 638,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 10,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "firtNCRatioPlnA",
        descII: "fine roots",
        tInII: 639,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 56,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 11,
        inverseII: 1,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "storNMPlnA",
        descII: "Nitrogen mass in plant store",
        tInII: 640,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "cropNmPlnA",
        descII: "Name of crop species",
        tInII: 1134,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "fixPlnA",
        descII: "crop planting event",
        tInII: 2052,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "phaPlnA",
        descII: "crop planting event",
        tInII: 2053,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
      {
        progNmII: "grthPeriod",
        descII: "Age of crop maturity",
        tInII: 2144,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 73,
        tSpecII: 0,
        tEventII: 7,
      },
    ],
    "74": [
      {
        progNmII: "useBioAgeAdjThin",
        descII: "Use biomass age adjustment (yes/no)",
        tInII: 314,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracAfctThin",
        descII: "forest affected by thin",
        tInII: 641,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToDdwdThin",
        descII: "stems to deadwood",
        tInII: 642,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToFuelThin",
        descII: "stems to biofuel",
        tInII: 643,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToPaprThin",
        descII: "stems to pulp and paper",
        tInII: 644,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToPackThin",
        descII: "stems to packing wood",
        tInII: 645,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToFurnThin",
        descII: "stems to furniture",
        tInII: 646,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToFibrThin",
        descII: "stems to fiberboard",
        tInII: 647,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToConsThin",
        descII: "stems to construction timber",
        tInII: 648,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToResiThin",
        descII: "stems to mill residues",
        tInII: 649,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToDdwdThin",
        descII: "branches to deadwood",
        tInII: 650,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToFuelThin",
        descII: "branches to biofuel",
        tInII: 651,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToPaprThin",
        descII: "branches to pulp and paper",
        tInII: 652,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToPackThin",
        descII: "branches to packing wood",
        tInII: 653,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToFurnThin",
        descII: "branches to furniture",
        tInII: 654,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToFibrThin",
        descII: "branches to fiberboard",
        tInII: 655,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToConsThin",
        descII: "branches to construction wood",
        tInII: 656,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToResiThin",
        descII: "branches to mill residue",
        tInII: 657,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBarkToBlitThin",
        descII: "bark to litter",
        tInII: 658,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBarkToFuelThin",
        descII: "bark to biofuel",
        tInII: 659,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBarkToPaprThin",
        descII: "bark to pulp and paper",
        tInII: 660,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBarkToResiThin",
        descII: "bark to mill residue",
        tInII: 661,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracLeafToLlitThin",
        descII: "leaves to litter",
        tInII: 662,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracCortToCodrThin",
        descII: "coarse roots to dead roots",
        tInII: 663,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracFirtToFidrThin",
        descII: "fine roots to dead roots",
        tInII: 664,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracDdwdToFuelThin",
        descII: "deadwood to biofuel",
        tInII: 814,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBlitToFuelThin",
        descII: "bark litter to biofuel",
        tInII: 815,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracLlitToFuelThin",
        descII: "leaf litter to biofuel",
        tInII: 816,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "replaceStemsThin",
        descII:
          "Replace thinned trees (stems) with new trees (of age 0) (yes/no)",
        tInII: 993,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "remvAvgAgeMultThin",
        descII: "Multiplier of average age",
        tInII: 995,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 105,
        riskII: 1,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "remvMaxAgeMultThin",
        descII: "Multiplier of age of oldest trees",
        tInII: 996,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 105,
        riskII: 1,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "remvOffsetThin",
        descII: "Added constant",
        tInII: 997,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 105,
        riskII: 1,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracChwdToFuelThin",
        descII: "chopped wood to biofuel",
        tInII: 1047,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracLeafToFuelThin",
        descII: "leaves to biofuel",
        tInII: 1115,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracCortToFuelThin",
        descII: "coarse roots to biofuel",
        tInII: 1116,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "multStemThin",
        descII: "stems",
        tInII: 1193,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 106,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "multBranThin",
        descII: "branches",
        tInII: 1194,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 106,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "multBarkThin",
        descII: "bark",
        tInII: 1195,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 106,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "multLeafThin",
        descII: "leaves",
        tInII: 1196,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 106,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "multFirtThin",
        descII: "fine roots",
        tInII: 1197,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 106,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "multCortThin",
        descII: "coarse roots",
        tInII: 1198,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 106,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "boostYrsThin",
        descII: "Length of post-thin period",
        tInII: 1199,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "useLogGrades",
        descII:
          "User's choice of whether use log grades to specify the thin (yes/no)",
        tInII: 1639,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracGradeThin1",
        descII: "thin that is of grade 1 logs",
        tInII: 1640,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracGradeThin2",
        descII: "thin that is of grade 2 logs",
        tInII: 1641,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracGradeThin3",
        descII: "thin that is of grade 3 logs",
        tInII: 1642,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracGradeThin4",
        descII: "thin that is of grade 4 logs",
        tInII: 1643,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracGradeThin5",
        descII: "thin that is of grade 5 logs",
        tInII: 1644,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "treeNmThin",
        descII: "Name of tree species expected to be thinned",
        tInII: 1645,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 120,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "clearAllRemainsF",
        descII: "Clear all remaining tree mass to debris (yes/no)",
        tInII: 1646,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fixThin",
        descII: "thinning event",
        tInII: 2054,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "phaThin",
        descII: "thinning event",
        tInII: 2055,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 154,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToDdwdExtraF",
        descII: "stems to deadwood",
        tInII: 2093,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 173,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToDdwdExtraF",
        descII: "branches to deadwood",
        tInII: 2094,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 173,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBarkToBlitExtraF",
        descII: "bark to bark litter",
        tInII: 2095,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 173,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracLeafToLlitExtraF",
        descII: "leaves to leaf litter",
        tInII: 2096,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 173,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracCortToCodrExtraF",
        descII: "coarse roots to dead coarse roots",
        tInII: 2097,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 173,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracFirtToFidrExtraF",
        descII: "fine roots to dead fine roots",
        tInII: 2098,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 173,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracStemToSDdwdThin",
        descII: "stems to standing dead stems",
        tInII: 2176,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBranToSDdwdThin",
        descII: "branches to standing dead branches",
        tInII: 2177,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracBarkToSBlitThin",
        descII: "bark to standing dead bark",
        tInII: 2178,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracLeafToSLlitThin",
        descII: "leaves to standing dead leaves",
        tInII: 2179,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracCortToSCodrThin",
        descII: "coarse roots to standing dead course roots",
        tInII: 2180,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracFirtToSFidrThin",
        descII: "fine roots to standing dead fine roots",
        tInII: 2181,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracSDdwdToFuelThin",
        descII: "Standing dead stem to biofuel",
        tInII: 2182,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracSChwdToFuelThin",
        descII: "Standing dead branch to biofuel",
        tInII: 2183,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracSBlitToFuelThin",
        descII: "Standing dead bark to biofuel",
        tInII: 2184,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
      {
        progNmII: "fracSLlitToFuelThin",
        descII: "Standing dead leaf to biofuel",
        tInII: 2185,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 121,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 74,
        tSpecII: 0,
        tEventII: 0,
      },
    ],
    "75": [
      {
        progNmII: "fracAfctHarv",
        descII: "agricultural system affected by harvesting",
        tInII: 665,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGbfrToGbfpHarv",
        descII: "grains-buds-fruit to products",
        tInII: 666,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGbfrToHssiHarv",
        descII: "grains-buds-fruit to hay-straw-silage",
        tInII: 667,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGbfrToGlitHarv",
        descII: "grains-buds-fruit to litter",
        tInII: 668,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracStlkToCaneHarv",
        descII: "stalks to canes",
        tInII: 669,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracStlkToHssiHarv",
        descII: "stalks to hay-straw-silage",
        tInII: 670,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracStlkToSlitHarv",
        descII: "stalks to litter",
        tInII: 671,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracLeafToLfprHarv",
        descII: "leaves to products",
        tInII: 672,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracLeafToHssiHarv",
        descII: "leaves to hay-straw-silage",
        tInII: 673,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracLeafToLlitHarv",
        descII: "leaves to litter",
        tInII: 674,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracCortToRtprHarv",
        descII: "coarse roots to products",
        tInII: 675,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracCortToHssiHarv",
        descII: "coarse roots to hay-straw-silage",
        tInII: 676,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracCortToCodrHarv",
        descII: "coarse roots to dead roots",
        tInII: 677,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracFirtToFidrHarv",
        descII: "fine roots to dead roots",
        tInII: 678,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "volFracGbfrNMHarv",
        descII: "nitrogen in (grains-buds-fruit to products) to atmosphere",
        tInII: 679,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "volFracStlkNMHarv",
        descII: "nitrogen in (stalks to products) to atmosphere",
        tInII: 680,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "volFracLeafNMHarv",
        descII: "nitrogen in (leaves to products) to atmosphere",
        tInII: 681,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "volFracCortNMHarv",
        descII: "nitrogen in (coarse roots to products) to atmosphere",
        tInII: 682,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracCortToFualHarv",
        descII: "coarse roots to biofuel",
        tInII: 793,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGbfrToFualHarv",
        descII: "grains-buds-fruit to biofuel",
        tInII: 811,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracStlkToFualHarv",
        descII: "stalks to biofuel",
        tInII: 812,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracLeafToFualHarv",
        descII: "leaves to biofuel",
        tInII: 813,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGlitToFualHarv",
        descII: "grains-buds-fruit litter to biofuel",
        tInII: 817,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracSlitToFualHarv",
        descII: "stalk litter to biofuel",
        tInII: 818,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracLlitToFualHarv",
        descII: "leaf litter to biofuel",
        tInII: 819,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 127,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "replaceStlksHarv",
        descII:
          "Replace harvested plants (stalks) with new plants (of age 0) (yes/no)",
        tInII: 994,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "remvAvgAgeMultHarv",
        descII: "Multiplier of average age",
        tInII: 998,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 107,
        riskII: 1,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "remvMaxAgeMultHarv",
        descII: "Multiplier of age of oldest plants",
        tInII: 999,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 107,
        riskII: 1,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "remvOffsetHarv",
        descII: "Added constant",
        tInII: 1000,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 107,
        riskII: 1,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "useCropGrades",
        descII:
          "User's choice of whether use crop grades to specify the harvest (yes/no)",
        tInII: 1647,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "clearAllRemainsA",
        descII: "Clear all remaining crop mass to debris (yes/no)",
        tInII: 1648,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "cropNmHarv",
        descII: "Name of crop species expected to be harvested",
        tInII: 1649,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 161,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 120,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGradeHarv1",
        descII: "harvest that is of grade 1",
        tInII: 1650,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGradeHarv2",
        descII: "harvest that is of grade 2",
        tInII: 1651,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGradeHarv3",
        descII: "harvest that is of grade 3",
        tInII: 1652,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGradeHarv4",
        descII: "harvest that is of grade 4",
        tInII: 1653,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 140,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 80,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fixHarv",
        descII: "harvest event",
        tInII: 2056,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "phaHarv",
        descII: "harvest event",
        tInII: 2057,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracGbfrToGlitExtraA",
        descII: "grains-buds-fruit to gbf litter",
        tInII: 2099,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 174,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracStlkToSlitExtraA",
        descII: "stalks to stalk litter",
        tInII: 2100,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 174,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracLeafToLlitExtraA",
        descII: "leaves to leaf litter",
        tInII: 2101,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 174,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracCortToCodrExtraA",
        descII: "coarse roots to dead coarse roots",
        tInII: 2102,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 174,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
      {
        progNmII: "fracFirtToFidrExtraA",
        descII: "fine roots to dead fine roots",
        tInII: 2103,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 174,
        riskII: 1,
        spaII: 1,
        helpIdII: 153,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 75,
        tSpecII: 0,
        tEventII: 3,
      },
    ],
    "76": [
      {
        progNmII: "fracSAmmNToAtmsFirF",
        descII: "nitrogen in ammonium in the shallow soil",
        tInII: 229,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSNtrNToAtmsFirF",
        descII: "nitrogen in nitrate in the shallow soil",
        tInII: 230,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDAmmNToAtmsFirF",
        descII: "nitrogen in ammonium in the deep soil",
        tInII: 231,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDNtrNToAtmsFirF",
        descII: "nitrogen in nitrate in the deep soil",
        tInII: 232,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracAfctFirF",
        descII: "forest affected by fire",
        tInII: 683,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracStemToAtmsFirF",
        descII: "stems",
        tInII: 684,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracBranToAtmsFirF",
        descII: "branches",
        tInII: 685,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracBarkToAtmsFirF",
        descII: "bark",
        tInII: 686,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLeafToAtmsFirF",
        descII: "leaves",
        tInII: 687,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracStemToDdwdFirF",
        descII: "stems to deadwood",
        tInII: 688,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracBranToDdwdFirF",
        descII: "branches to deadwood",
        tInII: 689,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracBarkToBlitFirF",
        descII: "bark to litter",
        tInII: 690,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLeafToLlitFirF",
        descII: "leaves to litter",
        tInII: 691,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracCortToCodrFirF",
        descII: "coarse roots to dead roots",
        tInII: 692,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracFirtToFidrFirF",
        descII: "fine roots to dead roots",
        tInII: 693,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDDdwdToAtmsFirF",
        descII: "decomposable deadwood",
        tInII: 694,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRDdwdToAtmsFirF",
        descII: "resistant deadwood",
        tInII: 695,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDBlitToAtmsFirF",
        descII: "decomposable bark litter",
        tInII: 696,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRBlitToAtmsFirF",
        descII: "resistant bark litter",
        tInII: 697,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDLlitToAtmsFirF",
        descII: "decomposable leaf litter",
        tInII: 698,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRLlitToAtmsFirF",
        descII: "resistant leaf litter",
        tInII: 699,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDCodrToAtmsFirF",
        descII: "decomposable coarse dead roots",
        tInII: 700,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRCodrToAtmsFirF",
        descII: "resistant coarse dead roots",
        tInII: 701,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDFidrToAtmsFirF",
        descII: "decomposable fine roots",
        tInII: 702,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRFidrToAtmsFirF",
        descII: "resistant fine roots",
        tInII: 703,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDDdwdToInrtFirF",
        descII: "decomposable deadwood",
        tInII: 704,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRDdwdToInrtFirF",
        descII: "resistant deadwood",
        tInII: 705,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDBlitToInrtFirF",
        descII: "decomposable bark litter",
        tInII: 706,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRBlitToInrtFirF",
        descII: "resistant bark litter",
        tInII: 707,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDLlitToInrtFirF",
        descII: "decomposable leaf litter",
        tInII: 708,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRLlitToInrtFirF",
        descII: "resistant leaf litter",
        tInII: 709,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDCodrToInrtFirF",
        descII: "decomposable coarse dead roots",
        tInII: 710,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRCodrToInrtFirF",
        descII: "resistant coarse dead roots",
        tInII: 711,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDFidrToInrtFirF",
        descII: "decomposable fine roots",
        tInII: 712,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRFidrToInrtFirF",
        descII: "resistant fine roots",
        tInII: 713,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSopmToAtmsFirF",
        descII: "soluble plant mulch",
        tInII: 714,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLrpmToAtmsFirF",
        descII: "less-resistant plant mulch",
        tInII: 715,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMrpmToAtmsFirF",
        descII: "more-resistant plant mulch",
        tInII: 716,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSommToAtmsFirF",
        descII: "soluble microbial mulch",
        tInII: 717,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLrmmToAtmsFirF",
        descII: "less-resistant microbial mulch",
        tInII: 718,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMrmmToAtmsFirF",
        descII: "more-resistant microbial mulch",
        tInII: 719,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMicrToAtmsFirF",
        descII: "live microbes",
        tInII: 720,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMnamNToAtmsFirF",
        descII: "mineral nitrogen",
        tInII: 721,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSopmToInrtFirF",
        descII: "soluble plant mulch",
        tInII: 722,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLrpmToInrtFirF",
        descII: "less-resistant plant mulch",
        tInII: 723,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMrpmToInrtFirF",
        descII: "more-resistant plant mulch",
        tInII: 724,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSommToInrtFirF",
        descII: "soluble microbial mulch",
        tInII: 725,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLrmmToInrtFirF",
        descII: "less-resistant microbial mulch",
        tInII: 726,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMrmmToInrtFirF",
        descII: "more-resistant microbial mulch",
        tInII: 727,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracMicrToInrtFirF",
        descII: "live microbes",
        tInII: 728,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLeafRegrowFirF",
        descII: "burnt leaves that regrow (in addition to other growth)",
        tInII: 729,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDChwdToAtmsFirF",
        descII: "decomposable chopped wood",
        tInII: 1048,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRChwdToAtmsFirF",
        descII: "resistant chopped wood",
        tInII: 1049,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracDChwdToInrtFirF",
        descII: "decomposable chopped wood",
        tInII: 1050,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracRChwdToInrtFirF",
        descII: "resistant chopped wood",
        tInII: 1051,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracCortToAtmsFirF",
        descII: "coarse roots",
        tInII: 1158,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracFirtToAtmsFirF",
        descII: "fine roots",
        tInII: 1159,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "yrsStemRegrowFirF",
        descII: "Years to fully regrow stems",
        tInII: 1160,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 1,
        tDataII: 0,
        loII: 0.083,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "yrsBarkRegrowFirF",
        descII: "Years to fully regrow bark",
        tInII: 1161,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 1,
        tDataII: 0,
        loII: 0.083,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "yrsBranRegrowFirF",
        descII: "Years to fully regrow branches",
        tInII: 1162,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 1,
        tDataII: 0,
        loII: 0.083,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "yrsLeafRegrowFirF",
        descII: "Years to fully regrow leaves",
        tInII: 1163,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 1,
        tDataII: 0,
        loII: 0.083,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "yrsCortRegrowFirF",
        descII: "Years to fully regrow coarse roots",
        tInII: 1164,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 1,
        tDataII: 0,
        loII: 0.083,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "yrsFirtRegrowFirF",
        descII: "Years to fully regrow fine roots",
        tInII: 1165,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 1,
        tDataII: 0,
        loII: 0.083,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "useBioAgeAdjFirF",
        descII: "Use biomass age adjustment (yes/no)",
        tInII: 1470,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fixFirF",
        descII: "forest fire",
        tInII: 2058,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "phaFirF",
        descII: "forest fire",
        tInII: 2059,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 120,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracStemToSDdwdFirF",
        descII: "stems to standing dead stems",
        tInII: 2186,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracBranToSChwdFirF",
        descII: "branches to standing dead branches",
        tInII: 2187,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracBarkToSBlitFirF",
        descII: "bark to standing dead bark",
        tInII: 2188,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracLeafToSLlitFirF",
        descII: "leaves to standing dead leaves",
        tInII: 2189,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracCortToSCodrFirF",
        descII: "coarse roots to standing dead course roots",
        tInII: 2190,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracFirtToSFidrFirF",
        descII: "fine roots to standing dead fine roots",
        tInII: 2191,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSDdwdToAtmsFirF",
        descII: "Standing dead stem",
        tInII: 2192,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSChwdToAtmsFirF",
        descII: "Standing dead branch",
        tInII: 2193,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSBlitToAtmsFirF",
        descII: "Standing dead bark",
        tInII: 2194,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSLlitToAtmsFirF",
        descII: "Standing dead leaf",
        tInII: 2195,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSDdwdToDebrFirF",
        descII: "Standing dead stem to debris",
        tInII: 2196,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSChwdToDebrFirF",
        descII: "Standing dead branch to debris",
        tInII: 2197,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSBlitToDebrFirF",
        descII: "Standing dead bark to debris",
        tInII: 2198,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
      {
        progNmII: "fracSLlitToDebrFirF",
        descII: "Standing dead leaf to debris",
        tInII: 2199,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 144,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 76,
        tSpecII: 0,
        tEventII: 1,
      },
    ],
    "77": [
      {
        progNmII: "fracDAmmNToAtmsFirA",
        descII: "nitrogen in ammonium in the deep soil",
        tInII: 227,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDNtrNToAtmsFirA",
        descII: "nitrogen in nitrate in the deep soil",
        tInII: 228,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracAfctFirA",
        descII: "agricultural system affected by fire",
        tInII: 730,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracGbfrToAtmsFirA",
        descII: "grains-buds-fruit",
        tInII: 731,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracStlkToAtmsFirA",
        descII: "stalks",
        tInII: 732,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracLeafToAtmsFirA",
        descII: "leaves",
        tInII: 733,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracGbfrToGlitFirA",
        descII: "grains-buds-fruit to litter",
        tInII: 734,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracStlkToSlitFirA",
        descII: "stalks to litter",
        tInII: 735,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracLeafToLlitFirA",
        descII: "leaves to litter",
        tInII: 736,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracCortToCodrFirA",
        descII: "coarse roots to dead roots",
        tInII: 737,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracFirtToFidrFirA",
        descII: "fine roots to dead roots",
        tInII: 738,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 109,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDGlitToAtmsFirA",
        descII: "decomposable grains-buds-fruit litter",
        tInII: 739,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRGlitToAtmsFirA",
        descII: "resistant grains-buds-fruit litter",
        tInII: 740,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDSlitToAtmsFirA",
        descII: "decomposable stalk litter",
        tInII: 741,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRSlitToAtmsFirA",
        descII: "resistant stalk litter",
        tInII: 742,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDLlitToAtmsFirA",
        descII: "decomposable leaf litter",
        tInII: 743,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRLlitToAtmsFirA",
        descII: "resistant leaf litter",
        tInII: 744,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDCodrToAtmsFirA",
        descII: "decompos coarse dead roots",
        tInII: 745,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRCodrToAtmsFirA",
        descII: "resist coarse dead roots",
        tInII: 746,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDFidrToAtmsFirA",
        descII: "decompos. fine dead roots",
        tInII: 747,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRFidrToAtmsFirA",
        descII: "resist. fine dead roots",
        tInII: 748,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDGlitToInrtFirA",
        descII: "decomposable grains-buds-fruit litter",
        tInII: 749,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRGlitToInrtFirA",
        descII: "resistant grains-buds-fruit litter",
        tInII: 750,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDSlitToInrtFirA",
        descII: "decomposable stalk litter",
        tInII: 751,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRSlitToInrtFirA",
        descII: "resistant stalk litter",
        tInII: 752,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDLlitToInrtFirA",
        descII: "decomposable leaf litter",
        tInII: 753,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRLlitToInrtFirA",
        descII: "resistant leaf litter",
        tInII: 754,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDCodrToInrtFirA",
        descII: "decompos coarse dead roots",
        tInII: 755,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRCodrToInrtFirA",
        descII: "resist coarse dead roots",
        tInII: 756,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracDFidrToInrtFirA",
        descII: "decomposable fine dead roots",
        tInII: 757,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracRFidrToInrtFirA",
        descII: "resistant fine dead roots",
        tInII: 758,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 110,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracSopmToAtmsFirA",
        descII: "soluble plant mulch",
        tInII: 759,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracLrpmToAtmsFirA",
        descII: "less-resistant plant mulch",
        tInII: 760,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMrpmToAtmsFirA",
        descII: "more-resistant plant mulch",
        tInII: 761,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracSommToAtmsFirA",
        descII: "soluble microbial mulch",
        tInII: 762,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracLrmmToAtmsFirA",
        descII: "less-resistant microbial mulch",
        tInII: 763,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMrmmToAtmsFirA",
        descII: "more-resistant microbial mulch",
        tInII: 764,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMicrToAtmsFirA",
        descII: "live microbes",
        tInII: 765,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMnamNToAtmsFirA",
        descII: "mineral nitrogen",
        tInII: 766,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracSopmToInrtFirA",
        descII: "soluble plant mulch",
        tInII: 767,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracLrpmToInrtFirA",
        descII: "less-resistant plant mulch",
        tInII: 768,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMrpmToInrtFirA",
        descII: "more-resistant plant mulch",
        tInII: 769,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracSommToInrtFirA",
        descII: "soluble microbial mulch",
        tInII: 770,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracLrmmToInrtFirA",
        descII: "less-resistant microbial mulch",
        tInII: 771,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMrmmToInrtFirA",
        descII: "more-resistant microbial mulch",
        tInII: 772,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracMicrToInrtFirA",
        descII: "live microbes",
        tInII: 773,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracSNtrNToAtmsFirA",
        descII: "nitrogen in nitrate in the shallow soil",
        tInII: 1099,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fracSAmmNToAtmsFirA",
        descII: "nitrogen in ammonium in the shallow soil",
        tInII: 1100,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 108,
        riskII: 1,
        spaII: 1,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "fixFirA",
        descII: "agricultural fire",
        tInII: 2060,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
      {
        progNmII: "phaFirA",
        descII: "agricultural fire",
        tInII: 2061,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 149,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 77,
        tSpecII: 0,
        tEventII: 2,
      },
    ],
    "78": [
      {
        progNmII: "fracAfctPlow",
        descII: "agricultural system that is plowed",
        tInII: 1191,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 164,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "fixPlow",
        descII: "plowing event",
        tInII: 2062,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 164,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "phaPlow",
        descII: "plowing event",
        tInII: 2063,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 164,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "fracDpmaToAtmsPlowA",
        descII: "DPM",
        tInII: 2148,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 178,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "fracRpmaToAtmsPlowA",
        descII: "RPM",
        tInII: 2149,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 178,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "fracBiofToAtmsPlowA",
        descII: "BIO-F",
        tInII: 2150,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 178,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "fracBiosToAtmsPlowA",
        descII: "BIO-S",
        tInII: 2151,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 178,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "fracHumToAtmsPlowA",
        descII: "DPM",
        tInII: 2152,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 178,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      /*2024 plowing fields it meant to be in a 2024 plot reference */
      {
        progNmII: "durationPlow",
        descII: "Plow duration",
        tInII: 2202,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "impactMultiPlow",
        descII: "Plow impact multiplier",
        tInII: 2203,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "sPDecayMultiPlow",
        descII: "Soil pool decay multiplier",
        tInII: 2204,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "dTFracGbfrSoilA",
        descII: "direct transfer of grains, buds and fruits to soil",
        tInII: 2205,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "dTFracStlkSoilA",
        descII: "direct transfer of stalk to soil",
        tInII: 2206,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "dTFracLeafSoilA",
        descII: "direct transfer of leaf to soil",
        tInII: 2207,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "dTFracCortSoilA",
        descII: "direct transfer of coarse root to soil",
        tInII: 2208,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      {
        progNmII: "dTFracFirtSoilA",
        descII: "direct transfer of fine root to soil",
        tInII: 2209,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        tDataII: 0,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
      /*  2024 plowing fields*/
      {
        progNmII: "recoveryFracA",
        descII: "Perennial Recovery Fraction",
        tInII: 2153,
        tSysII: 1,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 193,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 78,
        tSpecII: 0,
        tEventII: 4,
      },
    ],
    "79": [
      {
        progNmII: "fracAfctHerb",
        descII: "agricultural system affected by herbicide",
        tInII: 1192,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 163,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 79,
        tSpecII: 0,
        tEventII: 5,
      },
      {
        progNmII: "fixHerb",
        descII: "herbicide event",
        tInII: 2064,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 163,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 79,
        tSpecII: 0,
        tEventII: 5,
      },
      {
        progNmII: "phaHerb",
        descII: "herbicide event",
        tInII: 2065,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 163,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 79,
        tSpecII: 0,
        tEventII: 5,
      },
    ],
    "80": [
      {
        progNmII: "fracFoddNMToSNtrGraz",
        descII: "nitrogen to shallow nitrate",
        tInII: 127,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddNMToDAmmGraz",
        descII: "nitrogen to deep ammonium",
        tInII: 128,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddNMToDNtrGraz",
        descII: "nitrogen to deep nitrate",
        tInII: 129,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "tGraz",
        descII: "Type of grazing regime",
        tInII: 774,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "GrazT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "tRootSlouGraz",
        descII: "Root sloughing (on/off)",
        tInII: 775,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 1,
        tEnumII: "RootSlouT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "gbfrMGraz",
        descII: "grains-buds-fruit",
        tInII: 776,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha per day",
        prefixII: 172,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "stlkMGraz",
        descII: "stalks",
        tInII: 777,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha per day",
        prefixII: 172,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "leafMGraz",
        descII: "leaves",
        tInII: 778,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "dmt/ha per day",
        prefixII: 172,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracGbfrGraz",
        descII: "grains-buds-fruit",
        tInII: 779,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 120,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracStlkGraz",
        descII: "stalks",
        tInII: 780,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 120,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracLeafGraz",
        descII: "leaves",
        tInII: 781,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 120,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracGbfrNPPGraz",
        descII: "grains-buds-fruit NPP",
        tInII: 782,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 120,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracStlkNPPGraz",
        descII: "stalk NPP",
        tInII: 783,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 120,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracLeafNPPGraz",
        descII: "leaf NPP",
        tInII: 784,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 120,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddCMToAtmsGraz",
        descII: "to atmosphere",
        tInII: 785,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddCMToDpmaGraz",
        descII: "to DPM",
        tInII: 786,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddCMToRpmaGraz",
        descII: "to RPM",
        tInII: 787,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddNMToAtmsGraz",
        descII: "nitrogen to atmosphere (via manure and urine)",
        tInII: 789,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddNMToDpmaGraz",
        descII: "nitrogen to DPM (via manure)",
        tInII: 790,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddNMToRpmaGraz",
        descII: "nitrogen to RPM (via manure)",
        tInII: 791,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracFoddNMToSAmmGraz",
        descII: "nitrogen to shallow ammonium",
        tInII: 792,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 112,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "maxRootSlouFrac",
        descII: "Maximum % of roots sloughed off in grazing",
        tInII: 971,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fixGraz",
        descII: "grazing change",
        tInII: 2066,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "phaGraz",
        descII: "grazing change",
        tInII: 2067,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracGlitGraz",
        descII: "grains-buds-fruit litter",
        tInII: 2088,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 172,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracSlitGraz",
        descII: "stalk litter",
        tInII: 2089,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 172,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "fracLlitGraz",
        descII: "leaf litter",
        tInII: 2090,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%/day",
        prefixII: 172,
        suffixII: 111,
        riskII: 1,
        spaII: 1,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "cropGrazOn",
        descII: "Crop grazing on (on/off)",
        tInII: 2091,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "debrGrazOn",
        descII: "Debris grazing on (on/off)",
        tInII: 2092,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
      {
        progNmII: "grazerType",
        descII: "Type of Grazer",
        tInII: 2118,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 196,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 80,
        tSpecII: 0,
        tEventII: 8,
      },
    ],
    "81": [
      {
        progNmII: "tFFrc",
        descII: "Type of forest percentage scheme",
        tInII: 794,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 116,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "FrFracT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 81,
        tSpecII: 0,
        tEventII: 9,
      },
      {
        progNmII: "constFFrc",
        descII: "Forest percentage when the forest percentage is a constant",
        tInII: 795,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 116,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 81,
        tSpecII: 0,
        tEventII: 9,
      },
      {
        progNmII: "fracFFrc",
        descII:
          "Forest percentage at given time,when the forest percentage rises",
        tInII: 1200,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 116,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 81,
        tSpecII: 0,
        tEventII: 9,
      },
      {
        progNmII: "yearFFrc",
        descII:
          "Age of tree in years for given forest percentage,when the forest percentage rises",
        tInII: 1201,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 116,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.001,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 81,
        tSpecII: 0,
        tEventII: 9,
      },
      {
        progNmII: "fixFFrc",
        descII: "forest-fraction change",
        tInII: 2068,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 116,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 81,
        tSpecII: 0,
        tEventII: 9,
      },
      {
        progNmII: "phaFFrc",
        descII: "forest-fraction change",
        tInII: 2069,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 116,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 81,
        tSpecII: 0,
        tEventII: 9,
      },
    ],
    "82": [
      {
        progNmII: "yieldMultTmtF",
        descII: "New tree yield multiplier due to forest treatment (type 2)",
        tInII: 990,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
      {
        progNmII: "ageAdvTmtF",
        descII: "Age advance due to forest treatment (type 1)",
        tInII: 991,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
      {
        progNmII: "advPeriodTmtF",
        descII: "Advancement period for forest treatment (type 1)",
        tInII: 992,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
      {
        progNmII: "type1On",
        descII: "Type 1 forest treatment (on/off)",
        tInII: 1128,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
      {
        progNmII: "type2On",
        descII: "Type 2 forest treatment (on/off)",
        tInII: 1129,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
      {
        progNmII: "fixTmtF",
        descII: "forest treatment",
        tInII: 2070,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
      {
        progNmII: "phaTmtF",
        descII: "forest treatment",
        tInII: 2071,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 51,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 82,
        tSpecII: 0,
        tEventII: 12,
      },
    ],
    "83": [
      {
        progNmII: "fracDDdwdFChop",
        descII: "decomposable deadwood that becomes chopped wood",
        tInII: 1052,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 52,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 83,
        tSpecII: 0,
        tEventII: 13,
      },
      {
        progNmII: "fracRDdwdFChop",
        descII: "resistant deadwood that becomes chopped wood",
        tInII: 1053,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 52,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 83,
        tSpecII: 0,
        tEventII: 13,
      },
      {
        progNmII: "fracDBarkFChop",
        descII: "decomposable bark that becomes chopped wood",
        tInII: 1054,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 52,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 83,
        tSpecII: 0,
        tEventII: 13,
      },
      {
        progNmII: "fracRBarkFChop",
        descII: "resistant bark that becomes chopped wood",
        tInII: 1055,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 52,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 83,
        tSpecII: 0,
        tEventII: 13,
      },
      {
        progNmII: "fixChop",
        descII: "chopper-roller event",
        tInII: 2072,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 52,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 83,
        tSpecII: 0,
        tEventII: 13,
      },
      {
        progNmII: "phaChop",
        descII: "chopper-roller event",
        tInII: 2073,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 52,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 83,
        tSpecII: 0,
        tEventII: 13,
      },
    ],
    "84": [
      {
        progNmII: "fracDDdwdFTerm",
        descII: "decomposable deadwood",
        tInII: 1028,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 113,
        riskII: 1,
        spaII: 1,
        helpIdII: 53,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 84,
        tSpecII: 0,
        tEventII: 14,
      },
      {
        progNmII: "fracRDdwdFTerm",
        descII: "resistant deadwood",
        tInII: 1029,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 113,
        riskII: 1,
        spaII: 1,
        helpIdII: 53,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 84,
        tSpecII: 0,
        tEventII: 14,
      },
      {
        progNmII: "fracDCodrFTerm",
        descII: "decomposable coarse dead roots",
        tInII: 1030,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 113,
        riskII: 1,
        spaII: 1,
        helpIdII: 53,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 84,
        tSpecII: 0,
        tEventII: 14,
      },
      {
        progNmII: "fracRCodrFTerm",
        descII: "resistant coarse dead roots",
        tInII: 1031,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%/yr",
        prefixII: 120,
        suffixII: 113,
        riskII: 1,
        spaII: 1,
        helpIdII: 53,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 84,
        tSpecII: 0,
        tEventII: 14,
      },
      {
        progNmII: "fixTerm",
        descII: "termite change",
        tInII: 2074,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 53,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 84,
        tSpecII: 0,
        tEventII: 14,
      },
      {
        progNmII: "phaTerm",
        descII: "termite change",
        tInII: 2075,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 53,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 84,
        tSpecII: 0,
        tEventII: 14,
      },
    ],
    "85": [
      {
        progNmII: "defnitIrrF",
        descII: "Definite forest irrigation",
        tInII: 1032,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "mm/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 85,
        tSpecII: 0,
        tEventII: 15,
      },
      {
        progNmII: "conditIrrF",
        descII:
          "maximum forest soil water guaranteed by conditional irrigation",
        tInII: 1033,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 85,
        tSpecII: 0,
        tEventII: 15,
      },
      {
        progNmII: "fixIrrF",
        descII: "forest irrigation change",
        tInII: 2076,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 85,
        tSpecII: 0,
        tEventII: 15,
      },
      {
        progNmII: "phaIrrF",
        descII: "forest irrigation change",
        tInII: 2077,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 85,
        tSpecII: 0,
        tEventII: 15,
      },
    ],
    "86": [
      {
        progNmII: "defnitIrrA",
        descII: "Definite agricultural irrigation",
        tInII: 1034,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "mm/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 86,
        tSpecII: 0,
        tEventII: 16,
      },
      {
        progNmII: "conditIrrA",
        descII:
          "maximum agricultural soil water guaranteed by conditional irrigation",
        tInII: 1035,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "%",
        prefixII: 120,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 86,
        tSpecII: 0,
        tEventII: 16,
      },
      {
        progNmII: "fixIrrA",
        descII: "agricultural irrigation change",
        tInII: 2078,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 86,
        tSpecII: 0,
        tEventII: 16,
      },
      {
        progNmII: "phaIrrA",
        descII: "agricultural irrigation change",
        tInII: 2079,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 54,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 86,
        tSpecII: 0,
        tEventII: 16,
      },
    ],
    "87": [
      {
        progNmII: "sAmmNMNFeF",
        descII: "ammonium in the shallow forest soil (event)",
        tInII: 199,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
      {
        progNmII: "mnamNMNFeF",
        descII: "minerals available to the forest mulch (event)",
        tInII: 1036,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
      {
        progNmII: "sNtrNMNFeF",
        descII: "nitrate in the shallow forest soil (event)",
        tInII: 1091,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
      {
        progNmII: "dNtrNMNFeF",
        descII: "nitrate in the deep forest soil (event)",
        tInII: 1101,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
      {
        progNmII: "dAmmNMNFeF",
        descII: "ammonium in the deep forest soil (event)",
        tInII: 1103,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
      {
        progNmII: "fixNFeF",
        descII: "forest nitrogen fertilizer application",
        tInII: 2080,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
      {
        progNmII: "phaNFeF",
        descII: "forest nitrogen fertilizer application",
        tInII: 2081,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 87,
        tSpecII: 0,
        tEventII: 17,
      },
    ],
    "88": [
      {
        progNmII: "sAmmNMNFeA",
        descII: "ammonium in the shallow agricultural soil (event)",
        tInII: 140,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
      {
        progNmII: "sNtrNMNFeA",
        descII: "nitrate in the shallow agricultural soil (event)",
        tInII: 141,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
      {
        progNmII: "mnamNMNFeA",
        descII: "minerals available to the agricultural mulch (event)",
        tInII: 1037,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
      {
        progNmII: "dAmmNMNFeA",
        descII: "ammonium in the deep agricultural soil (event)",
        tInII: 1081,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
      {
        progNmII: "dNtrNMNFeA",
        descII: "nitrate in the deep agricultural soil (event)",
        tInII: 1093,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "kgN/ha",
        prefixII: 114,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1000,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
      {
        progNmII: "fixNFeA",
        descII: "agricultural nitrogen fertilizer application",
        tInII: 2082,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
      {
        progNmII: "phaNFeA",
        descII: "agricultural nitrogen fertilizer application",
        tInII: 2083,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 60,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 88,
        tSpecII: 0,
        tEventII: 18,
      },
    ],
    "89": [
      {
        progNmII: "manuCMManF",
        descII: "forest soil",
        tInII: 1038,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha/yr",
        prefixII: 66,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 62,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 89,
        tSpecII: 0,
        tEventII: 19,
      },
      {
        progNmII: "fixManF",
        descII: "forest manure change",
        tInII: 2084,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 62,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 89,
        tSpecII: 0,
        tEventII: 19,
      },
      {
        progNmII: "phaManF",
        descII: "forest manure change",
        tInII: 2085,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 62,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 89,
        tSpecII: 0,
        tEventII: 19,
      },
    ],
    "90": [
      {
        progNmII: "manuCMManA",
        descII: "agricultural soil",
        tInII: 1039,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "tC/ha/yr",
        prefixII: 66,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 62,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 90,
        tSpecII: 0,
        tEventII: 20,
      },
      {
        progNmII: "fixManA",
        descII: "agricultural manure change",
        tInII: 2086,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$",
        prefixII: 170,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 62,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 90,
        tSpecII: 0,
        tEventII: 20,
      },
      {
        progNmII: "phaManA",
        descII: "agricultural manure change",
        tInII: 2087,
        tSysII: 1,
        tStdII: 2,
        unitNmII: "$/ha",
        prefixII: 171,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 62,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 90,
        tSpecII: 0,
        tEventII: 20,
      },
    ],
    "91": [
      {
        progNmII: "descXCst",
        descII: "Description of external cost",
        tInII: 1477,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 3,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 100,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "oneTimeXCst",
        descII: "External cost has a one-time part",
        tInII: 1478,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "onGoingXCst",
        descII: "External cost has a on-going part",
        tInII: 1479,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "fixXCst",
        descII: "Fixed portion of one-time external cost",
        tInII: 1480,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "phaXCst",
        descII: "Per-hectare portion of one-time external cost",
        tInII: 1481,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "fixPyrXCst",
        descII: "Fixed portion of on-going external cost",
        tInII: 1482,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "phaPyrXCst",
        descII: "Per-hectare portion of on-going external cost",
        tInII: 1483,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$/ha/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
      {
        progNmII: "codeXCst",
        descII: "Code for identifying an on-going external cost",
        tInII: 1484,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 259,
        sortNII: 0,
        subSameII: 0,
        tDataII: 3,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 100,
        catIxII: 91,
        tSpecII: 0,
        tEventII: 21,
      },
    ],
    "92": [
      {
        progNmII: "descXInc",
        descII: "Description of external income",
        tInII: 1485,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 3,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 100,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "oneTimeXInc",
        descII: "External income has a one-time part",
        tInII: 1486,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "onGoingXInc",
        descII: "External income has a on-going part",
        tInII: 1487,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "fixXInc",
        descII: "Fixed portion of one-time external income",
        tInII: 1488,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "phaXInc",
        descII: "Per-hectare portion of one-time external income",
        tInII: 1489,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "fixPyrXInc",
        descII: "Fixed portion of on-going external income",
        tInII: 1490,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "phaPyrXInc",
        descII: "Per-hectare portion of on-going external income",
        tInII: 1491,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "$/ha/yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
      {
        progNmII: "codeXInc",
        descII: "Code for identifying an on-going external income",
        tInII: 1492,
        tSysII: 2,
        tStdII: 3,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 262,
        sortNII: 0,
        subSameII: 0,
        tDataII: 3,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 100,
        catIxII: 92,
        tSpecII: 0,
        tEventII: 22,
      },
    ],
    "93": [
      {
        progNmII: "tSimSteps",
        descII:
          "Simulation step type,to use for the sensitivity analysis outputs",
        tInII: 1073,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 160,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SimStepsT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 93,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxNIterations",
        descII: "Maximum number of iterations during a sensitivity analysis",
        tInII: 1074,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 160,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 93,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "useCnvg",
        descII:
          "Stop before max number of iterations when sens. converge (yes/no)",
        tInII: 1075,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 160,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 93,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cnvgPercentChng",
        descII:
          "Sensitivity convergence when each output changes by less than this percentage",
        tInII: 1076,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 160,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 1e-9,
        hiII: 100,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 93,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cnvgCheckNSteps",
        descII: "Check for sensitivity convergence every this many iterations",
        tInII: 1077,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 160,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 1,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 93,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "tSample",
        descII: "Sampling type in sensitivity analysis",
        tInII: 1078,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 160,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "SampleT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 93,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "94": [
      {
        progNmII: "onSIN",
        descII: "Spatial input is On/Off",
        tInII: 1467,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 192,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 94,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dfltValSpaOSIN",
        descII: "Default spatial value",
        tInII: 1468,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 192,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 94,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "dfltTsSpaOSIN",
        descII: "Default spatial time-series",
        tInII: 1469,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 192,
        sortNII: 0,
        subSameII: 0,
        tDataII: 6,
        loII: -1e36,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 94,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fBodySIN",
        descII: "Spatial File for input",
        tInII: 1472,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 192,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 94,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "pathSINS",
        descII: "Path for all spatial files",
        tInII: 1473,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 192,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 94,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "95": [
      {
        progNmII: "initTSMD",
        descII: "Initial TSMD (topsoil moisture deficit)",
        tInII: 47,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "mm",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 187,
        sortNII: 417,
        subSameII: 1,
        tDataII: 6,
        loII: 0,
        hiII: 10000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "treeMvg",
        descII: "Tree mvg number",
        tInII: 130,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 192,
        sortNII: 407,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 50,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initTotalSoilC",
        descII: "Initial total soil carbon mass",
        tInII: 135,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "tC/ha",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 191,
        sortNII: 410,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "reportingRegion1",
        descII: "Reporting Region 1",
        tInII: 136,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 192,
        sortNII: 570,
        subSameII: 0,
        tDataII: 1,
        loII: -2147483647,
        hiII: 2147483648,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fireRegion",
        descII: "Fire Region",
        tInII: 1082,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 408,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 10000,
        tEnumII: "FireRegionT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fireTimeSeries",
        descII: "Fire Timeseries",
        tInII: 1090,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 404,
        subSameII: 0,
        tDataII: 7,
        loII: 0,
        hiII: 3,
        tEnumII: "SpatialFireT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "reportPerHa",
        descII:
          "Report results in tonnes per hectare rather than tonnes,etc (yes/no)",
        tInII: 1092,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "fireFrequency",
        descII: "Fire Frequency",
        tInII: 1104,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 405,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 10000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initFracRpma",
        descII: "RPM fraction in soil",
        tInII: 1154,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 115,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 412,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 1,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initFracHums",
        descII: "HUM fraction in soil",
        tInII: 1157,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 115,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 411,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 1,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "npi",
        descII: "National plantation inventory (NPI) region",
        tInII: 1270,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 192,
        sortNII: 402,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 15,
        tEnumII: "NPIRegionT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "woodi",
        descII: "Woodiness (history of woody/non-woody status)",
        tInII: 1271,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 191,
        sortNII: 301,
        subSameII: 0,
        tDataII: 7,
        loII: 0,
        hiII: 1,
        tEnumII: "WoodinessT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "plantationType",
        descII: "Plantation type",
        tInII: 1293,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 406,
        subSameII: 0,
        tDataII: 8,
        loII: 1,
        hiII: 3,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "baseLandUse",
        descII: "Base land use",
        tInII: 1373,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 201,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 4294967295,
        tEnumII: "BaseLandUseT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1374",
        descII: "unused",
        tInII: 1374,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 8,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "harvest",
        descII: "Harvest",
        tInII: 1375,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1376",
        descII: "unused",
        tInII: 1376,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused",
        descII: "unused",
        tInII: 1401,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "irrigation",
        descII: "Irrigation",
        tInII: 1402,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "drought",
        descII: "Drought and dieback",
        tInII: 1403,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "OnOffT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "rndSeedFromSurface",
        descII: "Random seed value",
        tInII: 1404,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -1,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "growthRegion",
        descII: "Growth calibration region",
        tInII: 2122,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 403,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 255,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "protectedArea",
        descII: "Protected Land layer",
        tInII: 2123,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 148,
        sortNII: 569,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 255,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "statisticalArea",
        descII: "SA2 Region layer",
        tInII: 2127,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 187,
        sortNII: 401,
        subSameII: 1,
        tDataII: 1,
        loII: 0,
        hiII: 99999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "reportingRegion2",
        descII: "Reporting Region 2",
        tInII: 2129,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 192,
        sortNII: 571,
        subSameII: 0,
        tDataII: 1,
        loII: -2147483647,
        hiII: 2147483648,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "reportingRegion3",
        descII: "Reporting Region 3",
        tInII: 2130,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 192,
        sortNII: 572,
        subSameII: 0,
        tDataII: 1,
        loII: -2147483647,
        hiII: 2147483648,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "reportingRegion4",
        descII: "Reporting Region 4",
        tInII: 2131,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 192,
        sortNII: 573,
        subSameII: 0,
        tDataII: 1,
        loII: -2147483647,
        hiII: 2147483648,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initFracInrt",
        descII: "IOM fraction in soil",
        tInII: 2135,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 115,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 413,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 1,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "wildfire",
        descII: "Spatial Fire",
        tInII: 2136,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 88,
        sortNII: 422,
        subSameII: 0,
        tDataII: 6,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask1",
        descII: "Mask 1",
        tInII: 2139,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 101,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask2",
        descII: "Mask 2",
        tInII: 2140,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 102,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask3",
        descII: "Mask 3",
        tInII: 2141,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 103,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask4",
        descII: "Mask 4",
        tInII: 2142,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 104,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask5",
        descII: "Mask 5",
        tInII: 2154,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 105,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask6",
        descII: "Mask 6",
        tInII: 2155,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 106,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask7",
        descII: "Mask 7",
        tInII: 2156,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 107,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "simMask8",
        descII: "Mask 8",
        tInII: 2157,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 0,
        sortNII: 108,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "silvSysTS",
        descII: "SilvSys HNS",
        tInII: 2200,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 1,
        helpIdII: 191,
        sortNII: 301,
        subSameII: 0,
        tDataII: 7,
        loII: 0,
        hiII: 1,
        tEnumII: "SilvSysT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 95,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "96": [
      {
        progNmII: "post1990Yr",
        descII: "Consider only 'year' land use change",
        tInII: 497,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1084",
        descII: "Contribution type for plot cells with no biomass",
        tInII: 1084,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1085",
        descII:
          "Contribution type for plot cells with no transitions of interest",
        tInII: 1085,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1086",
        descII: "Contribution type for plot cells that are masked out",
        tInII: 1086,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 80,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1087",
        descII: "Contribution type for plot cells with bad spatial data",
        tInII: 1087,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 80,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1088",
        descII: "Contribution type for plot cells with bad location data",
        tInII: 1088,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 80,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1089",
        descII: "Contribution type for plot cells with bad event queues",
        tInII: 1089,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 80,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "lonTL",
        descII: "Longitude for created spatial simulation plot",
        tInII: 1094,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "Deg E",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 132,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -180,
        hiII: 180,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "intDegRectN",
        descII: "Area of interest - Northern edge",
        tInII: 1095,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "Deg N",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -90,
        hiII: 90,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "intDegRectS",
        descII: "Area of interest - Southern edge",
        tInII: 1096,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "Deg N",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -90,
        hiII: 90,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "intDegRectE",
        descII: "Area of interest - Eastern edge",
        tInII: 1097,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "Deg E",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -180,
        hiII: 180,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "intDegRectW",
        descII: "Area of interest - Western edge",
        tInII: 1098,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "Deg E",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -180,
        hiII: 180,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "latTL",
        descII: "Latitude for created spatial simulation plot",
        tInII: 1105,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "Deg N",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 132,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: -180,
        hiII: 180,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "areaName",
        descII: "Name for the selected area",
        tInII: 1275,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1276",
        descII: "unused",
        tInII: 1276,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1277",
        descII: "unused",
        tInII: 1277,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1278",
        descII: "unused",
        tInII: 1278,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1279",
        descII: "unused",
        tInII: 1279,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1280",
        descII:
          "Contribution type for output steps before first transition of interest",
        tInII: 1280,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "startAtFirstTrans",
        descII:
          "Start spatial simulation of cells at first clear or reveg transition",
        tInII: 1281,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "limitSpaErrs",
        descII:
          "Limit the number of errors reported during a spatial simulation",
        tInII: 1282,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "maxSpaErrs",
        descII:
          "Maximum number of errors reported (of each type) during a spatial simulation",
        tInII: 1283,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1285",
        descII: "Contribution type for plot cells with no activity",
        tInII: 1285,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 80,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1290",
        descII: "unused",
        tInII: 1290,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1291",
        descII: "unused",
        tInII: 1291,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "createSimCellsGrid",
        descII: "Create simulated cells grid when running spatial simulation",
        tInII: 1292,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1377",
        descII: "Policy used for Spatial Simulations",
        tInII: 1377,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "forToFor",
        descII: "Forest to forest",
        tInII: 1391,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "forToGrass",
        descII: "Forest to grassland",
        tInII: 1392,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "forToCrop",
        descII: "Forest to cropland",
        tInII: 1393,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "grassToFor",
        descII: "Grassland to forest",
        tInII: 1394,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "grassToGrass",
        descII: "Grassland to grassland",
        tInII: 1395,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "grassToCrop",
        descII: "Grassland to cropland",
        tInII: 1396,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cropToFor",
        descII: "Cropland to forest",
        tInII: 1397,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cropToGrass",
        descII: "Cropland to grassland",
        tInII: 1398,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "cropToCrop",
        descII: "Cropland to cropland",
        tInII: 1399,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "post1990",
        descII: "Consider only 'post1990' land use change",
        tInII: 1400,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "spaOutCellSize",
        descII: "Selected cell size of spatial outputs",
        tInII: 1406,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0.00025,
        hiII: 0.001,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1466",
        descII: "unused",
        tInII: 1466,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1471",
        descII:
          "Policy defined method for building plots used for spatial simulations",
        tInII: 1471,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "outputPath",
        descII: "Spatial results location",
        tInII: 1474,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1475",
        descII: "unused",
        tInII: 1475,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 81,
        sortNII: 0,
        subSameII: 0,
        tDataII: 4,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1476",
        descII:
          "Policy defined method for handling transitions in spatial simulations",
        tInII: 1476,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1744",
        descII: "unused1744",
        tInII: 1744,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "pre2009",
        descII: "Consider only 'pre2009' land use change",
        tInII: 2116,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "pre2009Yr",
        descII: "Consider only 'year' land use change",
        tInII: 2117,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "createAgRunIn",
        descII: "Create agricultural activity run in for initially clear plots",
        tInII: 2124,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "createAgRunInStYr",
        descII:
          "Start year for agricultural activity run in for initially clear plots",
        tInII: 2125,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "createAgRunInEnYr",
        descII:
          "End year for agricultural activity run in for initially clear plots",
        tInII: 2126,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "changeMgmtPractices",
        descII: "Change management practices to allow dynamic crop allocation",
        tInII: 2128,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "hold1990values",
        descII: "hold 1990 values",
        tInII: 2132,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 2,
        loII: 0,
        hiII: 1,
        tEnumII: "YesNoT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "commencePrescribedBurnYr",
        descII: "Commence prescribed burn simulation",
        tInII: 2134,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "yr",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 79,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: -999,
        hiII: 9999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 96,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "97": [
      {
        progNmII: "spaOutStartYr",
        descII: "Start year of Spatial output grids",
        tInII: 1272,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 97,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "spaOutNYrs",
        descII: "Number of years spanned by spatial output grids",
        tInII: 1273,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 97,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "spaOutSteps",
        descII: "Spacing in timing steps of spatial output grids",
        tInII: 1274,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 97,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "spaOutStepSelection",
        descII: "Selected step of spatial output grid",
        tInII: 1289,
        tSysII: 3,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 82,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 2100200300,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 1,
        colWidthII: 70,
        catIxII: 97,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "98": [
      {
        progNmII: "unused20",
        descII: "unused",
        tInII: 20,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused233",
        descII: "unused",
        tInII: 233,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused234",
        descII: "unused",
        tInII: 234,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused235",
        descII: "unused",
        tInII: 235,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused236",
        descII: "unused",
        tInII: 236,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused305",
        descII: "unused",
        tInII: 305,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused306",
        descII: "unused",
        tInII: 306,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused307",
        descII: "unused",
        tInII: 307,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused308",
        descII: "unused",
        tInII: 308,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused309",
        descII: "unused",
        tInII: 309,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused310",
        descII: "unused",
        tInII: 310,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused311",
        descII: "unused",
        tInII: 311,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused448",
        descII: "unused",
        tInII: 448,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused521",
        descII: "unused",
        tInII: 521,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused605",
        descII: "unused",
        tInII: 605,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1149",
        descII: "unused",
        tInII: 1149,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 5,
        loII: 0,
        hiII: 0,
        tEnumII: "StateT",
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 50,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1150",
        descII: "unused",
        tInII: 1150,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 99,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1151",
        descII: "unused",
        tInII: 1151,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 1,
        loII: 0,
        hiII: 999,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 40,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "unused1152",
        descII: "unused",
        tInII: 1152,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "",
        prefixII: 0,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 3,
        loII: 1,
        hiII: 55,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 194,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initFracBiof",
        descII: "BIO-F",
        tInII: 1155,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 115,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 1,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 99,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "initFracBios",
        descII: "BIO-S",
        tInII: 1156,
        tSysII: 2,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 115,
        suffixII: 0,
        riskII: 0,
        spaII: 0,
        helpIdII: 83,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 1,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 99,
        catIxII: 98,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "99": [
      {
        progNmII: "sDStemCMInitF",
        descII: "initial standing dead stem ",
        tInII: 2158,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 99,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sDBranCMInitF",
        descII: "initial standing dead branch",
        tInII: 2159,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 99,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sDBlitCMInitF",
        descII: "initial standing dead bark",
        tInII: 2160,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 99,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sDLlitCMInitF",
        descII: "initial standing dead leaf",
        tInII: 2161,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 99,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sDCodrCMInitF",
        descII: "initial standing dead coarse roots",
        tInII: 2162,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 99,
        tSpecII: 0,
        tEventII: 10,
      },
      {
        progNmII: "sDFidrCMInitF",
        descII: "initial standing dead fine roots",
        tInII: 2163,
        tSysII: 0,
        tStdII: 1,
        unitNmII: "tC/ha",
        prefixII: 102,
        suffixII: 0,
        riskII: 1,
        spaII: 1,
        helpIdII: 31,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1e36,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 99,
        tSpecII: 0,
        tEventII: 10,
      },
    ],
    "100": [
      {
        progNmII: "bkdnFracSDdwdF",
        descII: "standing dead stem",
        tInII: 2164,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracSChwdF",
        descII: "standing dead branch",
        tInII: 2165,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracSBlitF",
        descII: "standing dead bark",
        tInII: 2166,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracSLlitF",
        descII: "standing dead leaf",
        tInII: 2167,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracSCodrF",
        descII: "standing dead coarse roots",
        tInII: 2168,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "bkdnFracSFidrF",
        descII: "standing dead fine roots",
        tInII: 2169,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "Half life yrs",
        prefixII: 15,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 800000,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 1,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracSDdwdBkdnF",
        descII: "standing dead stem breakdown",
        tInII: 2170,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracSChwdBkdnF",
        descII: "standing dead branch breakdown",
        tInII: 2171,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracSBlitBkdnF",
        descII: "standing dead bark breakdown",
        tInII: 2172,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracSLlitBkdnF",
        descII: "standing dead leaf breakdown",
        tInII: 2173,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracSCodrBkdnF",
        descII: "standing dead coarse roots breakdown",
        tInII: 2174,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
      {
        progNmII: "atmsFracSFidrBkdnF",
        descII: "standing dead fine roots breakdown",
        tInII: 2175,
        tSysII: 0,
        tStdII: 0,
        unitNmII: "%",
        prefixII: 16,
        suffixII: 0,
        riskII: 1,
        spaII: 0,
        helpIdII: 45,
        sortNII: 0,
        subSameII: 0,
        tDataII: 0,
        loII: 0,
        hiII: 1,
        tEnumII: null,
        validTrickyII: 0,
        errNII: 0,
        inverseII: 0,
        scaleII: 100,
        oneMoreII: 0,
        colWidthII: 70,
        catIxII: 100,
        tSpecII: 1,
        tEventII: 10,
      },
    ],
  };

  public descriptionMap = null;
  public prefixMap;
  public suffixEnumsMap;
  constructor(private appService: AppService) {
    this.prefixMap = OutputInfo.getInputNameMapByIndex();

    this.suffixEnumsMap = this.getSuffixEnumsMap();
  }

  public getInputs(categoryIds: number[]) {
    let allInputs = [];
    categoryIds.forEach((id) => {
      allInputs = [...allInputs, ...this.allInputs[id]];
    });
    return allInputs;
  }

  public getDescriptionMap() {
    if (!this.descriptionMap) {
      let dMap = {};
      Object.values(this.allInputs).forEach((arr) => {
        arr.forEach((input) => {
          dMap[input.progNmII] = input.descII;
        });
      });
      this.descriptionMap = dMap;
    }
    return this.descriptionMap;
  }

  public getSuffixEnumsMap() {
    return this.enums.reduce((acc, item) => {
      if (item.enum == "FixiT") {
        acc[item.id] = item;
      }
      return acc;
    }, {});
  }

  public getRequiredInputsNamesBycategory(): {
    categories: { [key: string]: string };
    numberOfTimeSeries: number;
    numberOfinputs: number;
    numberOfCategories: number;
  } {
    let numberOfinputs = 0;
    let numberOfTimeSeries = 0;
    let numberOfCategories = 0;

    let catMap = {};
    Object.entries(this.allInputs).forEach((catInputs: any) => {
      const inputs = catInputs[1];
      const category = catInputs[0];
      inputs.forEach((input) => {
        if (this.isInputRequired(input)) {
          const catLabel = this.categories[category].label;
          if (!catMap[catLabel]) {
            catMap[catLabel] = [];
          }

          let prefix = this.prefixMap[input.prefixII]?.description || "";
          let suffix = this.suffixEnumsMap[input.suffixII]?.description || "";
          const timeSeries = input.tDataII == 6 ? " {time series}" : "";
          if (!suffix) {
            suffix = this.prefixMap[input.suffixII]?.description || "";
          }

          if (prefix) {
            prefix = prefix + " ";
          }

          if (suffix) {
            suffix = " " + suffix;
          }

          catMap[catLabel].push(prefix + input.descII + suffix + timeSeries);

          if (input.tDataII == 6) {
            numberOfTimeSeries += 1;
          } else {
            numberOfinputs += 1;
          }
        }
      });
    });
    numberOfCategories = Object.keys(catMap)?.length || 0;
    return {
      categories: catMap,
      numberOfTimeSeries,
      numberOfinputs,
      numberOfCategories,
    };
  }

  private isInputRequired(input) {
    const errorCategory = 0;
    const metaInCategory = 1;
    const configCategory = 2;
    const EvAlleCategory = 71;
    const notRequiredCategories = [
      errorCategory,
      metaInCategory,
      configCategory,
      EvAlleCategory,
    ];

    if (!notRequiredCategories.includes(input.catIxII)) {
      //kil_allPgs <--default value from PUBLIC RELEASE version
      return this.isRequiredFromInT(input);
      //return this.isRequired(input);
      //case kil_allPgs:	required[i] = dL->isRequiredFromInT(ii.tInII);			break;
    }
  }

  private isRequiredFromInT(input) {
    const notEvent = 10;
    const notSpecies = 0;
    // Not an event input
    if (input.tEventII == notEvent) {
      switch (input.tSpecII) {
        case notSpecies: {
          if (this.isRequired(input)) {
            return true;
          }
          // Could still be required if tIn is a standard param of a species
          // (on the "Std Info" panel of the Trees or Crops pages).
          // That is, an inputs for the initial plants, debris, products.
          // return this.isRequiredAsStdInput(input);
        }
        case 1: //kTree:
          return this.isRequired(input);
        case 2: //kCrop:
          return this.isRequired(input);
        case 3: //kAllSpecs:
          return this.isRequired(input);
        default:
          return false;
      }
    } else {
      // Event input

      if (this.isPldPloSim() && this.isRequiredByEventQ(input)) {
        return true;
      }
      // Could still be required if tIn is a standard param of a species
      // o(on the "Std Info" panel of the Trees or Crops pages).
      // That is, any event param input for the appropriate system.
      //return this.isRequiredAsStdInput(input);
    }
  }

  private isRequired(input) {
    //- Call InitIsRequired first, to set coOnly. specNm and eventNm may be 0's.
    //- If coOnly is true (ie only considering config page settings) then specNm and eventNm
    //  are ignored.
    //- This procedure not fast, so cache results where possible.

    //ConfigOnly is NOT available in PUBLIC RELEASE version
    const coOnly = true;
    //#define SPECFUSED	(coOnly || SpecFUsedAndExists(specNm))   // Slow!
    // const SPECAUSED = false;
    const speciesUsed = true;

    //INTREEUSED	(pldPloSim && (coOnly || InitTreeExists(specNm)))
    // const INTREEUSED = false;
    const programmingName = input.progNmII;

    const kAllE = 11; // All Events

    const is2020 = this.appService.getVersion() == "2020";

    const is2024 = this.appService.getVersion() == "2024";

    switch (programmingName) {
      // ----- Meta -----
      case "nmME":
      case "notesME":
      case "lockOnME":
        return true;
      case "lockTime":
      case "lockId":
        return false;

      // ----- Config -----
      case "tPlot":
      case "tTreeProd":
      case "userMulchF":
      case "userMulchA":
        return this.isPldPloSim();
      case "userN":
      case "userDispEner":
      case "userDispProd":
        return this.isPldPloSim() && this.hasConfig("CAMX");
      case "userSoilMnrl":
        return this.isPldPloSim();
      case "rothCVers":
        return this.isPldPloSim() && this.hasConfig("RothC");
      case "userCalcFPI":
        return this.isPldPloSim() && this.hasConfig("tyfOn");
      case "userCalcModTemp":
      case "userCalcModASW":
      case "userCalcModFrost":
        return this.isPldPloSim() && this.hasConfig("pppgL");
      case "userEventIrrF":
        return this.isPldPlo() && this.waterUsedF(coOnly);
      case "userEventIrrA":
        return this.isPldPlo() && this.waterUsedA(coOnly);
      case "userEventNFeF":
        return this.isPldPlo() && this.hasConfig("simMnrlNF");
      case "userEventNFeA":
        return this.isPldPlo() && this.hasConfig("simMnrlNA");
      case "userEventManF":
        return this.isPldPlo() && this.hasConfig("RothCF");
      case "userEventManA":
        return this.isPldPlo() && this.hasConfig("RothCA");
      case "userSens":
      case "userOpti":
      case "userEcon":
        return this.isPlo();
      case "userLogGrade":
      case "userCropGrade":
        return this.isPldPloSim();

      // ----- Timing -----
      case "dailyTimingTZ":
        return this.isPldPloEst();

      case "useDaysPerStepDTZ":
        return this.isPldPloEst() && this.getTimingField("dailyTimingTZ");
      case "daysPerStepDTZ":
        return (
          this.isPldPloEst() &&
          this.getTimingField("dailyTimingTZ") &&
          this.getTimingField("useDaysPerStepDTZ")
        );
      case "stepsPerDayDTZ":
        return (
          this.isPldPloEst() &&
          this.getTimingField("dailyTimingTZ") &&
          this.getTimingField("stepsPerDayDTZ")
        );
      case "stDateDTZ":
      case "enDateDTZ":
      case "outputFreqDTZ":
        return this.isPldPloEst() && this.getTimingField("dailyTimingTZ");
      case "stepsPerOutDTZ":
        return (
          this.isPldPloEst() &&
          this.getTimingField("dailyTimingTZ") &&
          this.getTimingField("outputFreqDTZ") == "Other"
        );
      case "firstOutStepDTZ":
        return (
          this.isPldPloEst() &&
          this.getTimingField("dailyTimingTZ") &&
          this.getTimingField("outputFreqDTZ") == "Other" &&
          this.getTimingField("stepsPerOutDTZ") > 1
        );

      case "tStepsYTZ":
        return this.isPldPloEst() && !this.getTimingField("dailyTimingTZ");
      case "stepsPerYrYTZ":
        return (
          this.isPldPloEst() &&
          !this.getTimingField("dailyTimingTZ") &&
          this.getTimingField("tStepsYTZ") == "Other"
        );
      case "stYrYTZ":
      case "stStepInStYrYTZ":
      case "enYrYTZ":
      case "enStepInEnYrYTZ":
      case "stepsPerOutYTZ":
        return this.isPldPloEst() && !this.getTimingField("dailyTimingTZ");
      case "firstOutStepYTZ":
        return (
          this.isPldPloEst() &&
          !this.getTimingField("dailyTimingTZ") &&
          this.getTimingField("stepsPerOutYTZ") > 1
        );

      // ----- SpaSim -----
      case "pathSINS":
      case "onSIN":
      case "fBodySIN":
      case "dfltTsSpaOSIN":
      case "dfltValSpaOSIN":
        return false; //sim is not used

      // ----- Build -----
      case "frCat":
      case "areaBL":
      case "lonBL":
      case "latBL":
        return this.isPldPlo();
      // ----- SiteZap -----
      case "tAirTemp":
        return this.isPldPlo() && this.isAirTempUsed(coOnly);
      case "tVPD":
        return this.isPldPlo() && this.hasConfig("PPPG");
      case "tSoilTemp":
        return this.isPldPlo() && this.isSoilTempUsed();
      case "hasArea":
        return this.isPldPlo();
      case "siteArea":
        return this.isPldPlo() && !this.getSiteField("hasArea");
      case "conditIrrigOnF":
        return this.isPldPloSim() && this.hasConfig("RothCF");
      case "conditIrrigOnA":
        return this.isPldPloSim() && this.hasConfig("RothCA");
      case "siteMultStemF":
      case "siteMultBranF":
      case "siteMultBarkF":
      case "siteMultLeafF":
      case "siteMultCortF":
      case "siteMultFirtF":
        return this.isPldPloSim() && this.hasConfig("CAMFor");
      case "siteMultGbfrA":
      case "siteMultStlkA":
      case "siteMultLeafA":
      case "siteMultCortA":
      case "siteMultFirtA":
        return this.isPldPloSim() && this.hasConfig("CAMAg");
      case "maxAbgMF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("CAMFor") &&
          !this.hasConfig("incrFOn")
        );
      case "maxAbgMA":
        return this.isPldPloSim() && this.hasConfig("CAMAg");
      case "latitude3PG":
      case "molPARPerMJRad":
      case "plantMPerMolC":
        return this.isPldPloSim() && this.hasConfig("PPPG");
      case "upstreamCRatio":
        return (
          this.isPldPloSim() &&
          this.hasConfig("dispEner") &&
          this.hasConfig("CAMX")
        );

      // ----- Site time series -----
      case "rainfall":
        return this.isPldPloSim() && this.waterUsed(coOnly);
      case "openPanEvap":
        return this.isPldPloSim() && this.hasConfig("RothC");
      case "defnitIrrigF":
        return (
          this.isPldPloSim() &&
          !this.getConfigField("userEventIrrF") &&
          this.waterUsedF(coOnly)
        );
      case "defnitIrrigA":
        return (
          this.isPldPloSim() &&
          !this.hasConfig("eventIrrA") &&
          this.waterUsedA(coOnly)
        );
      case "conditIrrigF":
        return (
          this.isPldPloSim() &&
          (this.hasConfig("RothCF") || this.hasConfig("PPPG")) &&
          !this.getConfigField("userEventIrrF") &&
          (coOnly || this.getSiteField("conditIrrigOnF")) &&
          this.waterUsedF(coOnly)
        );
      case "conditIrrigA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          !this.hasConfig("eventIrrA") &&
          (coOnly || this.getSiteField("conditIrrigOnA")) &&
          this.waterUsedA(coOnly)
        );
      case "VPD":
        return (
          this.isPldPloSim() &&
          this.hasConfig("PPPG") &&
          (coOnly || this.getSiteField("tVPD") == "Direct")
        );
      case "modVPDTS":
      case "eqmEvap":
        return this.isPldPloSim() && this.hasConfig("calcModASW");
      case "modASWTS":
        return (
          this.isPldPloSim() &&
          this.hasConfig("pppgL") &&
          !this.hasConfig("calcModASW")
        );
      case "avgAirTemp":
        return (
          this.isPldPloSim() &&
          this.isAirTempUsed(coOnly) &&
          (coOnly || this.getSiteField("tAirTemp") == "Direct")
        );
      case "rangeAirTemp":
      case "minAirTemp":
        return (
          this.isPldPloSim() &&
          ((this.isAirTempUsed(coOnly) &&
            (coOnly || this.getSiteField("tAirTemp") == "RangeMin")) ||
            this.hasConfig("PPPG"))
          //&& (coOnly || this.getSiteField("tVPD") == "RangeMin")))
        );
      case "soilTemp":
        return (
          this.isPldPloSim() &&
          this.isSoilTempUsed() &&
          (coOnly || this.isSim() || this.getSiteField("tSoilTemp") == "Direct")
        );
      case "frostNights":
        return (
          this.isPldPloSim() &&
          (this.hasConfig("PPPG") || this.hasConfig("calcModFrost"))
        );
      case "modFrostTS":
        return (
          this.isPldPloSim() &&
          this.hasConfig("pppgL") &&
          !this.hasConfig("calcModFrost")
        );
      case "solarRad":
        return this.isPldPloSim() && this.hasConfig("PPPG");
      case "APAR":
        return this.isPldPloSim() && this.hasConfig("pppgL");
      case "fpiAvgLT":
      case "forestProdIx":
        return (
          this.isPldPloSim() &&
          this.hasConfig("tyfOn") &&
          !this.hasConfig("pppgL")
        );
      case "fertility":
        return this.isPldPloSim() && this.hasConfig("PPPG");
      case "modNutrTS":
        return this.isPldPloSim() && this.hasConfig("pppgL");
      // ----- Spec -----
      // Not really applicable; need F vs A!
      case "tSP":
      case "tAgeIxSP":
      case "nmSP":
      case "notesSP":
      case "inUseSP":
      case "statusSP":
      case "categoryA":
        return (
          this.isPldPloSim() &&
          (this.hasConfig("PPPG") || this.hasConfig("CAMX"))
        );
      // Case 345
      case "sigmoidalGrowthA":
      case "sigmoidalGrowthB":
      case "grthModeA":
        return (
          this.isPldPloSim() &&
          (this.hasConfig("PPPG") || this.hasConfig("CAMAg"))
        );
      case "grthModeF":
        return (
          this.isPldPloSim() &&
          (this.hasConfig("PPPG") ||
            (this.hasConfig("CAMFor") && !this.hasConfig("tyfOn")))
        );

      // ----- Species set -----
      case "nSpecsF":
        return this.isPldPloSim() && this.hasConfig("CAMFor");
      case "nSpecsA":
        return this.isPldPloSim() && this.hasConfig("CAMAg");

      // ----- SpecZapF -----
      case "mvgTreeId":
      case "pltnType":
        return this.hasConfig("CAMFor") && speciesUsed; // case 1509
      case "tyf_G":
      case "tyf_r":
        return this.hasConfig("tyfOn") && speciesUsed;

      case "turnFracBranF":
      case "turnFracBarkF":
      case "turnFracLeafF":
        return this.hasConfig("CAMFor") && speciesUsed && is2020;
      case "turnFracBranF01":
      case "turnFracBranF02":
      case "turnFracBranF03":
      case "turnFracBranF04":
      case "turnFracBranF05":
      case "turnFracBranF06":
      case "turnFracBranF07":
      case "turnFracBranF08":
      case "turnFracBranF09":
      case "turnFracBranF10":
      case "turnFracBranF11":
      case "turnFracBranF12":
      case "turnFracBarkF01":
      case "turnFracBarkF02":
      case "turnFracBarkF03":
      case "turnFracBarkF04":
      case "turnFracBarkF05":
      case "turnFracBarkF06":
      case "turnFracBarkF07":
      case "turnFracBarkF08":
      case "turnFracBarkF09":
      case "turnFracBarkF10":
      case "turnFracBarkF11":
      case "turnFracBarkF12":
      case "turnFracLeafF01":
      case "turnFracLeafF02":
      case "turnFracLeafF03":
      case "turnFracLeafF04":
      case "turnFracLeafF05":
      case "turnFracLeafF06":
      case "turnFracLeafF07":
      case "turnFracLeafF08":
      case "turnFracLeafF09":
      case "turnFracLeafF10":
      case "turnFracLeafF11":
      case "turnFracLeafF12":
        return this.hasConfig("CAMFor") && speciesUsed && is2024;
      case "CFracStemF":
      case "CFracBranF":
      case "CFracBarkF":
      case "CFracLeafF":
      case "CFracCortF":
      case "CFracFirtF":
      case "turnFracCortF":
      case "turnFracFirtF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "ratioBranMortF":
      case "ratioBarkMortF":
      case "ratioLeafMortF":
      case "ratioCortMortF":
      case "ratioFirtMortF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "rFracStemF":
      case "rFracBranF":
      case "rFracBarkF":
      case "rFracLeafF":
      case "rFracCortF":
      case "rFracFirtF":
      case "bkdnFracDDdwdF":
      case "bkdnFracRDdwdF":
      case "bkdnFracDChwdF":
      case "bkdnFracRChwdF":
      case "bkdnFracDBlitF":
      case "bkdnFracRBlitF":
      case "bkdnFracDLlitF":
      case "bkdnFracRLlitF":
      case "bkdnFracDCodrF":
      case "bkdnFracRCodrF":
      case "bkdnFracDFidrF":
      case "bkdnFracRFidrF":
      case "atmsFracDDdwdBkdnF":
      case "atmsFracRDdwdBkdnF":
      case "atmsFracDChwdBkdnF":
      case "atmsFracRChwdBkdnF":
      case "atmsFracDBlitBkdnF":
      case "atmsFracRBlitBkdnF":
      case "atmsFracDLlitBkdnF":
      case "atmsFracRLlitBkdnF":
      case "atmsFracDCodrBkdnF":
      case "atmsFracRCodrBkdnF":
      case "atmsFracDFidrBkdnF":
      case "atmsFracRFidrBkdnF":
      case "bkdnSensMulcF":
      case "bkdnSensSoilF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "bkdnSensTempF":
      case "bkdnSensRainF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "bkdnFracSDdwdF":
      case "bkdnFracSChwdF":
      case "bkdnFracSBlitF":
      case "bkdnFracSLlitF":
      case "bkdnFracSCodrF":
      case "bkdnFracSFidrF":
      case "atmsFracSDdwdBkdnF":
      case "atmsFracSChwdBkdnF":
      case "atmsFracSBlitBkdnF":
      case "atmsFracSLlitBkdnF":
      case "atmsFracSCodrBkdnF":
      case "atmsFracSFidrBkdnF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "pdcmFracFuelF":
      case "pdcmFracPaprF":
      case "pdcmFracPackF":
      case "pdcmFracFurnF":
      case "pdcmFracFibrF":
      case "pdcmFracConsF":
      case "pdcmFracResiF":
      case "enerFracFuelF":
      case "enerFracPaprF":
      case "enerFracPackF":
      case "enerFracFurnF":
      case "enerFracFibrF":
      case "enerFracConsF":
      case "enerFracResiF":
      case "fillFracFuelF":
      case "fillFracPaprF":
      case "fillFracPackF":
      case "fillFracFurnF":
      case "fillFracFibrF":
      case "fillFracConsF":
      case "fillFracResiF":
      case "ldcmFracFuelF":
      case "ldcmFracPaprF":
      case "ldcmFracPackF":
      case "ldcmFracFurnF":
      case "ldcmFracFibrF":
      case "ldcmFracConsF":
      case "ldcmFracResiF":
        return this.hasConfig("CAMFor") && speciesUsed;

      case "effcEnerFuelF":
      case "effcEnerPaprF":
      case "effcEnerPackF":
      case "effcEnerFurnF":
      case "effcEnerFibrF":
      case "effcEnerConsF":
      case "effcEnerResiF":
      case "effcFossFuelF":
      case "effcFossPaprF":
      case "effcFossPackF":
      case "effcFossFurnF":
      case "effcFossFibrF":
      case "effcFossConsF":
      case "effcFossResiF":
      case "cemrEnerFuelF":
      case "cemrEnerPaprF":
      case "cemrEnerPackF":
      case "cemrEnerFurnF":
      case "cemrEnerFibrF":
      case "cemrEnerConsF":
      case "cemrEnerResiF":
      case "cemrFossFuelF":
      case "cemrFossPaprF":
      case "cemrFossPackF":
      case "cemrFossFurnF":
      case "cemrFossFibrF":
      case "cemrFossConsF":
      case "cemrFossResiF":

      case "cultivationCF":
      case "thinCF":
      case "biofuelBurnCF":
      case "wasteBurnCF":
        return (
          this.hasConfig("dispEner") && this.hasConfig("CAMFor") && speciesUsed
        );

      case "makeProdPaprF":
      case "makeProdPackF":
      case "makeProdFurnF":
      case "makeProdFibrF":
      case "makeProdConsF":
      case "makeProdResiF":
      case "cemrProdPaprF":
      case "cemrProdPackF":
      case "cemrProdFurnF":
      case "cemrProdFibrF":
      case "cemrProdConsF":
      case "cemrProdResiF":
      case "makeDispPaprF":
      case "makeDispPackF":
      case "makeDispFurnF":
      case "makeDispFibrF":
      case "makeDispConsF":
      case "makeDispResiF":
      case "cemrDispPaprF":
      case "cemrDispPackF":
      case "cemrDispFurnF":
      case "cemrDispFibrF":
      case "cemrDispConsF":
      case "cemrDispResiF":
      case "MDispPerProdPaprF":
      case "MDispPerProdPackF":
      case "MDispPerProdFurnF":
      case "MDispPerProdFibrF":
      case "MDispPerProdConsF":
      case "MDispPerProdResiF":
      case "LProdPerDispPaprF":
      case "LProdPerDispPackF":
      case "LProdPerDispFurnF":
      case "LProdPerDispFibrF":
      case "LProdPerDispConsF":
      case "LProdPerDispResiF":
      case "CFracProdPaprF":
      case "CFracProdPackF":
      case "CFracProdFurnF":
      case "CFracProdFibrF":
      case "CFracProdConsF":
      case "CFracProdResiF":
        return (
          this.hasConfig("dispProd") && this.hasConfig("CAMFor") && speciesUsed
        );

      case "tIncrTblF":
        return (
          this.hasConfig("CAMFor") && !this.hasConfig("PPPG") && speciesUsed
        );
      case "treeMortOn":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "replaceStemsMortF":
      case "useBioAgeAdjMortF":
      case "remvAvgAgeMultMortF":
      case "remvMaxAgeMultMortF":
      case "remvOffsetMortF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "stemMaxNC1F":
      case "branMaxNC1F":
      case "barkMaxNC1F":
      case "leafMaxNC1F":
      case "cortMaxNC1F":
      case "firtMaxNC1F":
      case "stemMaxNC2F":
      case "branMaxNC2F":
      case "barkMaxNC2F":
      case "leafMaxNC2F":
      case "cortMaxNC2F":
      case "firtMaxNC2F":
      case "stemMinNC1F":
      case "branMinNC1F":
      case "barkMinNC1F":
      case "leafMinNC1F":
      case "cortMinNC1F":
      case "firtMinNC1F":
      case "stemDays2F":
      case "branDays2F":
      case "barkDays2F":
      case "leafDays2F":
      case "cortDays2F":
      case "firtDays2F":

      case "maxSFixNCRatioF":
      case "storFracLeafTurnNF":
        return this.hasConfig("simNF") && speciesUsed;
      case "userRDebrNCRatioF":
        return (
          this.hasConfig("simNF") && !this.hasConfig("GendecF") && speciesUsed
        );
      case "absnNCRatioF":
      case "maxBkdnNCRatioF":
        return this.hasConfig("simNF") && speciesUsed;

      case "nmGradeF1":
      case "nmGradeF2":
      case "nmGradeF3":
      case "nmGradeF4":
      case "nmGradeF5":
        return false;

      case "ratioNPPToGPP":
      case "nDaysLostPerFrost":
        return (
          (this.hasConfig("PPPG") || this.hasConfig("pppgL")) && speciesUsed
        );
      case "maxStemMAt1000tph":
        return (
          this.hasConfig("PPPG") && !this.hasConfig("CAMFor") && speciesUsed
        );
      case "ratioFoliStem2cm":
      case "ratioFoliStem20cm":
      case "stemMDBHMult":
      case "stemMDBHPower":
      case "maxFracNPPToRoot":
      case "minFracNPPToRoot":
      case "minTempForGrth":
      case "optTempForGrth":
      case "maxTempForGrth":
        return this.hasConfig("PPPG") && speciesUsed;
      case "maxLitterfall":
      case "age0Litterfall":
      case "ageMedianLitterfall":
      case "turnFracRoot3PG":
        return (
          this.hasConfig("PPPG") && !this.hasConfig("CAMFor") && speciesUsed
        );
      case "maxCanopyCond":
      case "maxStomatalCond":
      case "condCoefft":
      case "canopyBoundaryCond":
        return this.hasConfig("PPPG") && speciesUsed;
      case "fertFracNPPToRoot":
        return (
          this.hasConfig("PPPG") && !this.hasConfig("CAMFor") && speciesUsed
        );
      case "modNutrFor0Fert":
      case "maxAge":
      case "agePower":
      case "ageMult":
      case "age0SLA":
      case "matureAgeSLA":
      case "ageOfHalfwaySLA":
      case "PARAbsorbCoefft":
      case "ageMatureCanopy":
      case "quantumEfficiency":
      case "age0BBFrac":
        return this.hasConfig("PPPG") && speciesUsed;
      case "matureAgeBBFrac":
      case "ageOfHalfwayBBFrac":
        return (
          this.hasConfig("PPPG") && !this.hasConfig("CAMFor") && speciesUsed
        );
      case "mrFracOfRLittF":
        return (
          this.hasConfig("CAMFor") && this.hasConfig("GendecF") && speciesUsed
        );

      case "fracStemToDdwdThin1":
      case "fracStemToFuelThin1":
      case "fracStemToPaprThin1":
      case "fracStemToPackThin1":
      case "fracStemToFurnThin1":
      case "fracStemToFibrThin1":
      case "fracStemToConsThin1":
      case "fracStemToResiThin1":

      case "fracBranToDdwdThin1":
      case "fracBranToFuelThin1":
      case "fracBranToPaprThin1":
      case "fracBranToPackThin1":
      case "fracBranToFurnThin1":
      case "fracBranToFibrThin1":
      case "fracBranToConsThin1":
      case "fracBranToResiThin1":

      case "fracBarkToBlitThin1":
      case "fracBarkToFuelThin1":
      case "fracBarkToPaprThin1":
      case "fracBarkToResiThin1":

      case "fracLeafToLlitThin1":
      case "fracLeafToFuelThin1":

      case "fracCortToCodrThin1":
      case "fracCortToFuelThin1":
      case "fracFirtToFidrThin1":

      case "fracDdwdToFuelThin1":
      case "fracChwdToFuelThin1":
      case "fracBlitToFuelThin1":
      case "fracLlitToFuelThin1":

      case "tPRWood1":
      case "useStemDensityPRWood1":
      case "densityPRWood1":
      case "fracWaterPRWood1":
      case "harvCostPRWood1":
      case "trnsCostPRWood1":
      case "millPricePRWood1":
      case "stYearPRWood1":
      case "incrPaHarvPRWood1":
      case "incrPaTrnsPRWood1":
      case "incrPaMillPRWood1":
      case "tPRBark1":
      case "useStemDensityPRBark1":
      case "densityPRBark1":
      case "fracWaterPRBark1":
      case "harvCostPRBark1":
      case "trnsCostPRBark1":
      case "millPricePRBark1":
      case "stYearPRBark1":
      case "incrPaHarvPRBark1":
      case "incrPaTrnsPRBark1":
      case "incrPaMillPRBark1":
      case "tPRFuel1":
      case "useStemDensityPRFuel1":
      case "densityPRFuel1":
      case "fracWaterPRFuel1":
      case "harvCostPRFuel1":
      case "trnsCostPRFuel1":
      case "millPricePRFuel1":
      case "stYearPRFuel1":
      case "incrPaHarvPRFuel1":
      case "incrPaTrnsPRFuel1":
      case "incrPaMillPRFuel1":

      case "fracStemToDdwdThin2":
      case "fracStemToFuelThin2":
      case "fracStemToPaprThin2":
      case "fracStemToPackThin2":
      case "fracStemToFurnThin2":
      case "fracStemToFibrThin2":
      case "fracStemToConsThin2":
      case "fracStemToResiThin2":
      case "fracBranToDdwdThin2":
      case "fracBranToFuelThin2":
      case "fracBranToPaprThin2":
      case "fracBranToPackThin2":
      case "fracBranToFurnThin2":
      case "fracBranToFibrThin2":
      case "fracBranToConsThin2":
      case "fracBranToResiThin2":
      case "fracBarkToBlitThin2":
      case "fracBarkToFuelThin2":
      case "fracBarkToPaprThin2":
      case "fracBarkToResiThin2":
      case "fracLeafToLlitThin2":
      case "fracLeafToFuelThin2":
      case "fracCortToCodrThin2":
      case "fracCortToFuelThin2":
      case "fracFirtToFidrThin2":
      case "fracDdwdToFuelThin2":
      case "fracChwdToFuelThin2":
      case "fracBlitToFuelThin2":
      case "fracLlitToFuelThin2":

      case "tPRWood2":
      case "useStemDensityPRWood2":
      case "densityPRWood2":
      case "fracWaterPRWood2":
      case "harvCostPRWood2":
      case "trnsCostPRWood2":
      case "millPricePRWood2":
      case "stYearPRWood2":
      case "incrPaHarvPRWood2":
      case "incrPaTrnsPRWood2":
      case "incrPaMillPRWood2":
      case "tPRBark2":
      case "useStemDensityPRBark2":
      case "densityPRBark2":
      case "fracWaterPRBark2":
      case "harvCostPRBark2":
      case "trnsCostPRBark2":
      case "millPricePRBark2":
      case "stYearPRBark2":
      case "incrPaHarvPRBark2":
      case "incrPaTrnsPRBark2":
      case "incrPaMillPRBark2":
      case "tPRFuel2":
      case "useStemDensityPRFuel2":
      case "densityPRFuel2":
      case "fracWaterPRFuel2":
      case "harvCostPRFuel2":
      case "trnsCostPRFuel2":
      case "millPricePRFuel2":
      case "stYearPRFuel2":
      case "incrPaHarvPRFuel2":
      case "incrPaTrnsPRFuel2":
      case "incrPaMillPRFuel2":

      case "fracStemToDdwdThin3":
      case "fracStemToFuelThin3":
      case "fracStemToPaprThin3":
      case "fracStemToPackThin3":
      case "fracStemToFurnThin3":
      case "fracStemToFibrThin3":
      case "fracStemToConsThin3":
      case "fracStemToResiThin3":
      case "fracBranToDdwdThin3":
      case "fracBranToFuelThin3":
      case "fracBranToPaprThin3":
      case "fracBranToPackThin3":
      case "fracBranToFurnThin3":
      case "fracBranToFibrThin3":
      case "fracBranToConsThin3":
      case "fracBranToResiThin3":
      case "fracBarkToBlitThin3":
      case "fracBarkToFuelThin3":
      case "fracBarkToPaprThin3":
      case "fracBarkToResiThin3":
      case "fracLeafToLlitThin3":
      case "fracLeafToFuelThin3":
      case "fracCortToCodrThin3":
      case "fracCortToFuelThin3":
      case "fracFirtToFidrThin3":
      case "fracDdwdToFuelThin3":
      case "fracChwdToFuelThin3":
      case "fracBlitToFuelThin3":
      case "fracLlitToFuelThin3":

      case "tPRWood3":
      case "useStemDensityPRWood3":
      case "densityPRWood3":
      case "fracWaterPRWood3":
      case "harvCostPRWood3":
      case "trnsCostPRWood3":
      case "millPricePRWood3":
      case "stYearPRWood3":
      case "incrPaHarvPRWood3":
      case "incrPaTrnsPRWood3":
      case "incrPaMillPRWood3":
      case "tPRBark3":
      case "useStemDensityPRBark3":
      case "densityPRBark3":
      case "fracWaterPRBark3":
      case "harvCostPRBark3":
      case "trnsCostPRBark3":
      case "millPricePRBark3":
      case "stYearPRBark3":
      case "incrPaHarvPRBark3":
      case "incrPaTrnsPRBark3":
      case "incrPaMillPRBark3":
      case "tPRFuel3":
      case "useStemDensityPRFuel3":
      case "densityPRFuel3":
      case "fracWaterPRFuel3":
      case "harvCostPRFuel3":
      case "trnsCostPRFuel3":
      case "millPricePRFuel3":
      case "stYearPRFuel3":
      case "incrPaHarvPRFuel3":
      case "incrPaTrnsPRFuel3":
      case "incrPaMillPRFuel3":

      case "fracStemToDdwdThin4":
      case "fracStemToFuelThin4":
      case "fracStemToPaprThin4":
      case "fracStemToPackThin4":
      case "fracStemToFurnThin4":
      case "fracStemToFibrThin4":
      case "fracStemToConsThin4":
      case "fracStemToResiThin4":
      case "fracBranToDdwdThin4":
      case "fracBranToFuelThin4":
      case "fracBranToPaprThin4":
      case "fracBranToPackThin4":
      case "fracBranToFurnThin4":
      case "fracBranToFibrThin4":
      case "fracBranToConsThin4":
      case "fracBranToResiThin4":
      case "fracBarkToBlitThin4":
      case "fracBarkToFuelThin4":
      case "fracBarkToPaprThin4":
      case "fracBarkToResiThin4":
      case "fracLeafToLlitThin4":
      case "fracLeafToFuelThin4":
      case "fracCortToCodrThin4":
      case "fracCortToFuelThin4":
      case "fracFirtToFidrThin4":
      case "fracDdwdToFuelThin4":
      case "fracChwdToFuelThin4":
      case "fracBlitToFuelThin4":
      case "fracLlitToFuelThin4":

      case "tPRWood4":
      case "useStemDensityPRWood4":
      case "densityPRWood4":
      case "fracWaterPRWood4":
      case "harvCostPRWood4":
      case "trnsCostPRWood4":
      case "millPricePRWood4":
      case "stYearPRWood4":
      case "incrPaHarvPRWood4":
      case "incrPaTrnsPRWood4":
      case "incrPaMillPRWood4":
      case "tPRBark4":
      case "useStemDensityPRBark4":
      case "densityPRBark4":
      case "fracWaterPRBark4":
      case "harvCostPRBark4":
      case "trnsCostPRBark4":
      case "millPricePRBark4":
      case "stYearPRBark4":
      case "incrPaHarvPRBark4":
      case "incrPaTrnsPRBark4":
      case "incrPaMillPRBark4":
      case "tPRFuel4":
      case "useStemDensityPRFuel4":
      case "densityPRFuel4":
      case "fracWaterPRFuel4":
      case "harvCostPRFuel4":
      case "trnsCostPRFuel4":
      case "millPricePRFuel4":
      case "stYearPRFuel4":
      case "incrPaHarvPRFuel4":
      case "incrPaTrnsPRFuel4":
      case "incrPaMillPRFuel4":

      case "fracStemToDdwdThin5":
      case "fracStemToFuelThin5":
      case "fracStemToPaprThin5":
      case "fracStemToPackThin5":
      case "fracStemToFurnThin5":
      case "fracStemToFibrThin5":
      case "fracStemToConsThin5":
      case "fracStemToResiThin5":
      case "fracBranToDdwdThin5":
      case "fracBranToFuelThin5":
      case "fracBranToPaprThin5":
      case "fracBranToPackThin5":
      case "fracBranToFurnThin5":
      case "fracBranToFibrThin5":
      case "fracBranToConsThin5":
      case "fracBranToResiThin5":
      case "fracBarkToBlitThin5":
      case "fracBarkToFuelThin5":
      case "fracBarkToPaprThin5":
      case "fracBarkToResiThin5":
      case "fracLeafToLlitThin5":
      case "fracLeafToFuelThin5":
      case "fracCortToCodrThin5":
      case "fracCortToFuelThin5":
      case "fracFirtToFidrThin5":
      case "fracDdwdToFuelThin5":
      case "fracChwdToFuelThin5":
      case "fracBlitToFuelThin5":
      case "fracLlitToFuelThin5":

      case "tPRWood5":
      case "useStemDensityPRWood5":
      case "densityPRWood5":
      case "fracWaterPRWood5":
      case "harvCostPRWood5":
      case "trnsCostPRWood5":
      case "millPricePRWood5":
      case "stYearPRWood5":
      case "incrPaHarvPRWood5":
      case "incrPaTrnsPRWood5":
      case "incrPaMillPRWood5":
      case "tPRBark5":
      case "useStemDensityPRBark5":
      case "densityPRBark5":
      case "fracWaterPRBark5":
      case "harvCostPRBark5":
      case "trnsCostPRBark5":
      case "millPricePRBark5":
      case "stYearPRBark5":
      case "incrPaHarvPRBark5":
      case "incrPaTrnsPRBark5":
      case "incrPaMillPRBark5":
      case "tPRFuel5":
      case "useStemDensityPRFuel5":
      case "densityPRFuel5":
      case "fracWaterPRFuel5":
      case "harvCostPRFuel5":
      case "trnsCostPRFuel5":
      case "millPricePRFuel5":
      case "stYearPRFuel5":
      case "incrPaHarvPRFuel5":
      case "incrPaTrnsPRFuel5":
      case "incrPaMillPRFuel5":
        return this.hasConfig("logGrade") && speciesUsed;

      // ----- SpecF time series -----
      case "incrStemVolF":
        return this.hasConfig("incrFOn") && speciesUsed;
      case "incrStemMF":
        return this.hasConfig("incrFOn") && speciesUsed;
      case "incrAbgMF":
        return this.hasConfig("incrFOn") && speciesUsed;
      case "allocStemF":
      case "allocBranF":
      case "allocBarkF":
      case "allocLeafF":
      case "allocCortF":
      case "allocFirtF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "stemDensity":
        return (
          (this.hasConfig("CAMFor") || this.hasConfig("PPPG")) && speciesUsed
        );
      case "stemMortF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "modTempTS":
        return (
          this.hasConfig("pppgL") &&
          !this.hasConfig("calcModTemp") &&
          speciesUsed
        );
      case "fracRainIntercept":
        return (
          (this.hasConfig("PPPG") || this.hasConfig("calcModASW")) &&
          speciesUsed
        );

      // ----- SpecZapA -----
      case "CFracGbfrA":
      case "CFracStlkA":
      case "CFracLeafA":
      case "CFracCortA":
      case "CFracFirtA":
      case "turnFracGbfrA":
      case "turnFracStlkA":
      case "turnFracLeafA":
      case "turnFracCortA":
      case "turnFracFirtA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "ratioGbfrMortA":
      case "ratioLeafMortA":
      case "ratioCortMortA":
      case "ratioFirtMortA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "rFracGbfrA":
      case "rFracStlkA":
      case "rFracLeafA":
      case "rFracCortA":
      case "rFracFirtA":
      case "bkdnFracDGlitA":
      case "bkdnFracRGlitA":
      case "bkdnFracDSlitA":
      case "bkdnFracRSlitA":
      case "bkdnFracDLlitA":
      case "bkdnFracRLlitA":
      case "bkdnFracDCodrA":
      case "bkdnFracRCodrA":
      case "bkdnFracDFidrA":
      case "bkdnFracRFidrA":
      case "atmsFracDGlitBkdnA":
      case "atmsFracRGlitBkdnA":
      case "atmsFracDSlitBkdnA":
      case "atmsFracRSlitBkdnA":
      case "atmsFracDLlitBkdnA":
      case "atmsFracRLlitBkdnA":
      case "atmsFracDCodrBkdnA":
      case "atmsFracRCodrBkdnA":
      case "atmsFracDFidrBkdnA":
      case "atmsFracRFidrBkdnA":
      case "bkdnSensMulcA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "bkdnSensSoilA":
        return (
          this.hasConfig("CAMAg") && this.hasConfig("RothCA") && speciesUsed
        );
      case "bkdnSensTempA":
      case "bkdnSensRainA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "pdcmFracFualA":
      case "pdcmFracGbfpA":
      case "pdcmFracCaneA":
      case "pdcmFracLfprA":
      case "pdcmFracRtprA":
      case "pdcmFracHssiA":
      case "pdcmFracAnprA":
      case "enerFracFualA":
      case "enerFracGbfpA":
      case "enerFracCaneA":
      case "enerFracLfprA":
      case "enerFracRtprA":
      case "enerFracHssiA":
      case "enerFracAnprA":
        return this.hasConfig("CAMAg") && speciesUsed;

      case "effcEnerFualA":
      case "effcEnerGbfpA":
      case "effcEnerCaneA":
      case "effcEnerLfprA":
      case "effcEnerRtprA":
      case "effcEnerHssiA":
      case "effcEnerAnprA":
      case "effcFossFualA":
      case "effcFossGbfpA":
      case "effcFossCaneA":
      case "effcFossLfprA":
      case "effcFossRtprA":
      case "effcFossHssiA":
      case "effcFossAnprA":
      case "cemrEnerFualA":
      case "cemrEnerGbfpA":
      case "cemrEnerCaneA":
      case "cemrEnerLfprA":
      case "cemrEnerRtprA":
      case "cemrEnerHssiA":
      case "cemrEnerAnprA":
      case "cemrFossFualA":
      case "cemrFossGbfpA":
      case "cemrFossCaneA":
      case "cemrFossLfprA":
      case "cemrFossRtprA":
      case "cemrFossHssiA":
      case "cemrFossAnprA":

      case "cultivationCA":
      case "harvCA":
      case "biofuelBurnCA":
      case "wasteBurnCA":
        return (
          this.hasConfig("dispEner") && this.hasConfig("CAMAg") && speciesUsed
        );

      case "makeProdGbfpA":
      case "makeProdCaneA":
      case "makeProdLfprA":
      case "makeProdRtprA":
      case "makeProdHssiA":
      case "makeProdAnprA":
      case "cemrProdGbfpA":
      case "cemrProdCaneA":
      case "cemrProdLfprA":
      case "cemrProdRtprA":
      case "cemrProdHssiA":
      case "cemrProdAnprA":
      case "makeDispGbfpA":
      case "makeDispCaneA":
      case "makeDispLfprA":
      case "makeDispRtprA":
      case "makeDispHssiA":
      case "makeDispAnprA":
      case "cemrDispGbfpA":
      case "cemrDispCaneA":
      case "cemrDispLfprA":
      case "cemrDispRtprA":
      case "cemrDispHssiA":
      case "cemrDispAnprA":
      case "MDispPerProdGbfpA":
      case "MDispPerProdCaneA":
      case "MDispPerProdLfprA":
      case "MDispPerProdRtprA":
      case "MDispPerProdHssiA":
      case "MDispPerProdAnprA":
      case "LProdPerDispGbfpA":
      case "LProdPerDispCaneA":
      case "LProdPerDispLfprA":
      case "LProdPerDispRtprA":
      case "LProdPerDispHssiA":
      case "LProdPerDispAnprA":
      case "CFracProdGbfpA":
      case "CFracProdCaneA":
      case "CFracProdLfprA":
      case "CFracProdRtprA":
      case "CFracProdHssiA":
      case "CFracProdAnprA":
        return (
          this.hasConfig("dispProd") && this.hasConfig("CAMAg") && speciesUsed
        );

      case "tIncrTblA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "cropMortOn":
      case "replaceStlksMortA":
      case "remvAvgAgeMultMortA":
      case "remvMaxAgeMultMortA":
      case "remvOffsetMortA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "gbfrMaxNC1A":
      case "gbfrMaxNC2A":
      case "gbfrMaxNC3A":
      case "gbfrMaxNC4A":
      case "stlkMaxNC1A":
      case "stlkMaxNC2A":
      case "stlkMaxNC3A":
      case "stlkMaxNC4A":
      case "leafMaxNC1A":
      case "leafMaxNC2A":
      case "leafMaxNC3A":
      case "leafMaxNC4A":
      case "cortMaxNC1A":
      case "cortMaxNC2A":
      case "cortMaxNC3A":
      case "cortMaxNC4A":
      case "firtMaxNC1A":
      case "firtMaxNC2A":
      case "firtMaxNC3A":
      case "firtMaxNC4A":
      case "gbfrMinNC1A":
      case "gbfrMinNC2A":
      case "gbfrMinNC3A":
      case "gbfrMinNC4A":
      case "stlkMinNC1A":
      case "stlkMinNC2A":
      case "stlkMinNC3A":
      case "stlkMinNC4A":
      case "leafMinNC1A":
      case "leafMinNC2A":
      case "leafMinNC3A":
      case "leafMinNC4A":
      case "cortMinNC1A":
      case "cortMinNC2A":
      case "cortMinNC3A":
      case "cortMinNC4A":
      case "firtMinNC1A":
      case "firtMinNC2A":
      case "firtMinNC3A":
      case "firtMinNC4A":
      case "gbfrDays2A":
      case "gbfrDays3A":
      case "gbfrDays4A":
      case "stlkDays2A":
      case "stlkDays3A":
      case "stlkDays4A":
      case "leafDays2A":
      case "leafDays3A":
      case "leafDays4A":
      case "cortDays2A":
      case "cortDays3A":
      case "cortDays4A":
      case "firtDays2A":
      case "firtDays3A":
      case "firtDays4A":

      case "maxSFixNCRatioA":
      case "storFracLeafTurnNA":
        return this.hasConfig("simNA") && speciesUsed;
      case "userRDebrNCRatioA":
        return (
          this.hasConfig("simNA") && !this.hasConfig("GendecA") && speciesUsed
        );
      case "absnNCRatioA":
      case "maxBkdnNCRatioA":
        return this.hasConfig("simNA") && speciesUsed;
      case "mrFracOfRLittA":
        return this.hasConfig("CAMAg") && speciesUsed;

      case "nmGradeA1":
      case "nmGradeA2":
      case "nmGradeA3":
      case "nmGradeA4":
        return false;

      case "fracGbfrToFualHarv1":
      case "fracGbfrToGbfpHarv1":
      case "fracGbfrToHssiHarv1":
      case "fracGbfrToGlitHarv1":
      case "fracStlkToFualHarv1":
      case "fracStlkToCaneHarv1":
      case "fracStlkToHssiHarv1":
      case "fracStlkToSlitHarv1":
      case "fracLeafToFualHarv1":
      case "fracLeafToLfprHarv1":
      case "fracLeafToHssiHarv1":
      case "fracLeafToLlitHarv1":
      case "fracCortToFualHarv1":
      case "fracCortToRtprHarv1":
      case "fracCortToHssiHarv1":
      case "fracCortToCodrHarv1":
      case "fracFirtToFidrHarv1":
      case "fracGlitToFualHarv1":
      case "fracSlitToFualHarv1":
      case "fracLlitToFualHarv1":

      case "tPRProd1":
      case "densityPRProd1":
      case "fracWaterPRProd1":
      case "harvCostPRProd1":
      case "trnsCostPRProd1":
      case "millPricePRProd1":
      case "stYearPRProd1":
      case "incrPaHarvPRProd1":
      case "incrPaTrnsPRProd1":
      case "incrPaMillPRProd1":
      case "tPRHssi1":
      case "densityPRHssi1":
      case "fracWaterPRHssi1":
      case "harvCostPRHssi1":
      case "trnsCostPRHssi1":
      case "millPricePRHssi1":
      case "stYearPRHssi1":
      case "incrPaHarvPRHssi1":
      case "incrPaTrnsPRHssi1":
      case "incrPaMillPRHssi1":
      case "tPRFual1":
      case "densityPRFual1":
      case "fracWaterPRFual1":
      case "harvCostPRFual1":
      case "trnsCostPRFual1":
      case "millPricePRFual1":
      case "stYearPRFual1":
      case "incrPaHarvPRFual1":
      case "incrPaTrnsPRFual1":
      case "incrPaMillPRFual1":

      case "fracGbfrToFualHarv2":
      case "fracGbfrToGbfpHarv2":
      case "fracGbfrToHssiHarv2":
      case "fracGbfrToGlitHarv2":
      case "fracStlkToFualHarv2":
      case "fracStlkToCaneHarv2":
      case "fracStlkToHssiHarv2":
      case "fracStlkToSlitHarv2":
      case "fracLeafToFualHarv2":
      case "fracLeafToLfprHarv2":
      case "fracLeafToHssiHarv2":
      case "fracLeafToLlitHarv2":
      case "fracCortToFualHarv2":
      case "fracCortToRtprHarv2":
      case "fracCortToHssiHarv2":
      case "fracCortToCodrHarv2":
      case "fracFirtToFidrHarv2":
      case "fracGlitToFualHarv2":
      case "fracSlitToFualHarv2":
      case "fracLlitToFualHarv2":

      case "tPRProd2":
      case "densityPRProd2":
      case "fracWaterPRProd2":
      case "harvCostPRProd2":
      case "trnsCostPRProd2":
      case "millPricePRProd2":
      case "stYearPRProd2":
      case "incrPaHarvPRProd2":
      case "incrPaTrnsPRProd2":
      case "incrPaMillPRProd2":
      case "tPRHssi2":
      case "densityPRHssi2":
      case "fracWaterPRHssi2":
      case "harvCostPRHssi2":
      case "trnsCostPRHssi2":
      case "millPricePRHssi2":
      case "stYearPRHssi2":
      case "incrPaHarvPRHssi2":
      case "incrPaTrnsPRHssi2":
      case "incrPaMillPRHssi2":
      case "tPRFual2":
      case "densityPRFual2":
      case "fracWaterPRFual2":
      case "harvCostPRFual2":
      case "trnsCostPRFual2":
      case "millPricePRFual2":
      case "stYearPRFual2":
      case "incrPaHarvPRFual2":
      case "incrPaTrnsPRFual2":
      case "incrPaMillPRFual2":

      case "fracGbfrToFualHarv3":
      case "fracGbfrToGbfpHarv3":
      case "fracGbfrToHssiHarv3":
      case "fracGbfrToGlitHarv3":
      case "fracStlkToFualHarv3":
      case "fracStlkToCaneHarv3":
      case "fracStlkToHssiHarv3":
      case "fracStlkToSlitHarv3":
      case "fracLeafToFualHarv3":
      case "fracLeafToLfprHarv3":
      case "fracLeafToHssiHarv3":
      case "fracLeafToLlitHarv3":
      case "fracCortToFualHarv3":
      case "fracCortToRtprHarv3":
      case "fracCortToHssiHarv3":
      case "fracCortToCodrHarv3":
      case "fracFirtToFidrHarv3":
      case "fracGlitToFualHarv3":
      case "fracSlitToFualHarv3":
      case "fracLlitToFualHarv3":

      case "tPRProd3":
      case "densityPRProd3":
      case "fracWaterPRProd3":
      case "harvCostPRProd3":
      case "trnsCostPRProd3":
      case "millPricePRProd3":
      case "stYearPRProd3":
      case "incrPaHarvPRProd3":
      case "incrPaTrnsPRProd3":
      case "incrPaMillPRProd3":
      case "tPRHssi3":
      case "densityPRHssi3":
      case "fracWaterPRHssi3":
      case "harvCostPRHssi3":
      case "trnsCostPRHssi3":
      case "millPricePRHssi3":
      case "stYearPRHssi3":
      case "incrPaHarvPRHssi3":
      case "incrPaTrnsPRHssi3":
      case "incrPaMillPRHssi3":
      case "tPRFual3":
      case "densityPRFual3":
      case "fracWaterPRFual3":
      case "harvCostPRFual3":
      case "trnsCostPRFual3":
      case "millPricePRFual3":
      case "stYearPRFual3":
      case "incrPaHarvPRFual3":
      case "incrPaTrnsPRFual3":
      case "incrPaMillPRFual3":

      case "fracGbfrToFualHarv4":
      case "fracGbfrToGbfpHarv4":
      case "fracGbfrToHssiHarv4":
      case "fracGbfrToGlitHarv4":
      case "fracStlkToFualHarv4":
      case "fracStlkToCaneHarv4":
      case "fracStlkToHssiHarv4":
      case "fracStlkToSlitHarv4":
      case "fracLeafToFualHarv4":
      case "fracLeafToLfprHarv4":
      case "fracLeafToHssiHarv4":
      case "fracLeafToLlitHarv4":
      case "fracCortToFualHarv4":
      case "fracCortToRtprHarv4":
      case "fracCortToHssiHarv4":
      case "fracCortToCodrHarv4":
      case "fracFirtToFidrHarv4":
      case "fracGlitToFualHarv4":
      case "fracSlitToFualHarv4":
      case "fracLlitToFualHarv4":

      case "tPRProd4":
      case "densityPRProd4":
      case "fracWaterPRProd4":
      case "harvCostPRProd4":
      case "trnsCostPRProd4":
      case "millPricePRProd4":
      case "stYearPRProd4":
      case "incrPaHarvPRProd4":
      case "incrPaTrnsPRProd4":
      case "incrPaMillPRProd4":
      case "tPRHssi4":
      case "densityPRHssi4":
      case "fracWaterPRHssi4":
      case "harvCostPRHssi4":
      case "trnsCostPRHssi4":
      case "millPricePRHssi4":
      case "stYearPRHssi4":
      case "incrPaHarvPRHssi4":
      case "incrPaTrnsPRHssi4":
      case "incrPaMillPRHssi4":
      case "tPRFual4":
      case "densityPRFual4":
      case "fracWaterPRFual4":
      case "harvCostPRFual4":
      case "trnsCostPRFual4":
      case "millPricePRFual4":
      case "stYearPRFual4":
      case "incrPaHarvPRFual4":
      case "incrPaTrnsPRFual4":
      case "incrPaMillPRFual4":
        return this.hasConfig("cropGrade") && speciesUsed;

      // ----- SpecA time series -----
      case "incrGbfrMA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "incrStlkMA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "incrLeafMA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "incrCortMA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "incrStLfMA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "incrAbgMA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "turnFracDieOA":
      case "allocGbfrA":
      case "allocStlkA":
      case "allocLeafA":
      case "allocCortA":
      case "allocFirtA":
        return this.hasConfig("CAMAg") && speciesUsed;
      case "stlkMortA":
        return this.hasConfig("CAMAg") && speciesUsed;

      // ----- MulchZap -----
      case "fracMdcmToMicrF":
      case "fracMicrCMIsWallF":
      case "fracWallCMIsLrF":
      case "turnFracMicrF":
      case "fracMdcmMicrExcrF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "fracMdcmToMicrA":
      case "fracMicrCMIsWallA":
      case "fracWallCMIsLrA":
      case "turnFracMicrA":
      case "fracMdcmMicrExcrA":
        return this.isPldPloSim() && this.hasConfig("GendecA");
      case "micrNCRatioF":
      case "sommNCRatioF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("GendecF") &&
          !this.hasConfig("simNF")
        );
      case "micrNCRatioA":
      case "sommNCRatioA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("GendecA") &&
          !this.hasConfig("simNA")
        );
      case "lrmmNCRatioF":
      case "mrmmNCRatioF":
      case "mrpmNCRatioF":
      case "lrpmNCRatioF":
      case "maxMicrNCRatioF":
      case "minMicrNCRatioF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "lrmmNCRatioA":
      case "mrmmNCRatioA":
      case "mrpmNCRatioA":
      case "lrpmNCRatioA":
      case "maxMicrNCRatioA":
      case "minMicrNCRatioA":
        return this.isPldPloSim() && this.hasConfig("GendecA");
      case "mdcmFracSopmF":
      case "mdcmFracLrpmF":
      case "mdcmFracMrpmF":
      case "mdcmFracSommF":
      case "mdcmFracLrmmF":
      case "mdcmFracMrmmF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "mdcmFracSopmA":
      case "mdcmFracLrpmA":
      case "mdcmFracMrpmA":
      case "mdcmFracSommA":
      case "mdcmFracLrmmA":
      case "mdcmFracMrmmA":
        return this.isPldPloSim() && this.hasConfig("GendecA");
      case "mdcmSensTempF":
      case "mdcmSensRainF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "mdcmSensTempA":
      case "mdcmSensRainA":
        return this.isPldPloSim() && this.hasConfig("GendecA");
      case "humfFracSopmF":
      case "humfFracLrpmF":
      case "humfFracMrpmF":
      case "humfFracSommF":
      case "humfFracLrmmF":
      case "humfFracMrmmF":
      case "humfFracMicrF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "humfFracSopmA":
      case "humfFracLrpmA":
      case "humfFracMrpmA":
      case "humfFracSommA":
      case "humfFracLrmmA":
      case "humfFracMrmmA":
      case "humfFracMicrA":
        return this.isPldPloSim() && this.hasConfig("GendecA");

      // ----- Mulch time series -----
      case "sopmCMInput":
      case "lrpmCMInput":
      case "mrpmCMInput":
        return (
          this.isPldPloSim() &&
          ((this.hasConfig("GendecF") && !this.hasConfig("CAMFor")) ||
            (this.hasConfig("GendecA") && !this.hasConfig("CAMAg")))
        );

      // ----- SoilZap -----
      // A sim has a locnSoilSet which has a SoilZap
      case "clayFrac":
        return this.isPldPloSim() && this.hasConfig("RothC");
      case "bulkDensity":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothC") &&
          this.hasConfig("simN")
        );
      case "tSoil":
      case "maxASW":
        return (
          this.isPldPloSim() &&
          (this.hasConfig("PPPG") || this.hasConfig("calcModASW"))
        );
      case "maxBiofNCRatioF":
      case "maxBiosNCRatioF":
      case "maxHumsNCRatioF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "maxBiofNCRatioA":
      case "maxBiosNCRatioA":
      case "maxHumsNCRatioA":
        return this.isPldPloSim() && this.hasConfig("simNA");
      case "minBiofNCRatioF":
      case "minBiosNCRatioF":
      case "minHumsNCRatioF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "minBiofNCRatioA":
      case "minBiosNCRatioA":
      case "minHumsNCRatioA":
        return this.isPldPloSim() && this.hasConfig("simNA");
      case "fracHumfSopmToDpmaF":
      case "fracHumfLrpmToRpmaF":
      case "fracHumfMrpmToRpmaF":
      case "fracHumfSommToDpmaF":
      case "fracHumfLrmmToRpmaF":
      case "fracHumfMrmmToRpmaF":
      case "fracHumfMicrToXpmaF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("CAMFor") &&
          this.hasConfig("GendecF")
        );
      case "fracHumfSopmToDpmaA":
      case "fracHumfLrpmToRpmaA":
      case "fracHumfMrpmToRpmaA":
      case "fracHumfSommToDpmaA":
      case "fracHumfLrmmToRpmaA":
      case "fracHumfMrmmToRpmaA":
      case "fracHumfMicrToXpmaA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("CAMAg") &&
          this.hasConfig("GendecA")
        );
      case "fracDLitBkdnToDpmaF":
      case "fracRLitBkdnToRpmaF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          !this.hasConfig("GendecF")
        );
      case "fracDLitBkdnToDpmaA":
      case "fracRLitBkdnToRpmaA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          !this.hasConfig("GendecA")
        );
      case "dToRRatioInPresF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          !this.hasConfig("CAMFor")
        );
      case "dToRRatioInPresA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          !this.hasConfig("CAMAg")
        );
      case "doManuFromOffsF":
      case "fracManuCMToDpmaF":
      case "fracManuCMToRpmaF":
      case "fracManuCMToBiofF":
      case "fracManuCMToBiosF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          (coOnly || this.getSoilField("doManuFromOffsF"))
        );
      case "doManuFromOffsA":
      case "fracManuCMToDpmaA":
      case "fracManuCMToRpmaA":
      case "fracManuCMToBiofA":
      case "fracManuCMToBiosA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          (coOnly || this.getSoilField("doManuFromOffsA"))
        );
      case "manuDpmaNCRatioF":
      case "manuRpmaNCRatioF":
      case "manuBiofNCRatioF":
      case "manuBiosNCRatioF":
      case "manuHumsNCRatioF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("simNF") &&
          (coOnly || this.getSoilField("doManuFromOffsF"))
        );
      case "manuDpmaNCRatioA":
      case "manuRpmaNCRatioA":
      case "manuBiofNCRatioA":
      case "manuBiosNCRatioA":
      case "manuHumsNCRatioA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("simNA") &&
          (coOnly || this.getSoilField("doManuFromOffsA"))
        );
      case "encpFracHumsF":
      case "sampleDepthF":
      case "evapoOpenRatioF":
      case "bToCMaxTSMDRatioF":
      case "sdcmRateMultDpmaF":
      case "sdcmRateMultRpmaF":
        return this.isPldPloSim() && this.hasConfig("RothCF");
      case "pHF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("simNF") &&
          this.hasConfig("RothCF")
        );
      case "encpFracHumsA":
      case "sampleDepthA":
      case "evapoOpenRatioA":
      case "bToCMaxTSMDRatioA":
      case "sdcmRateMultDpmaA":
      case "sdcmRateMultRpmaA":
        return this.isPldPloSim() && this.hasConfig("RothCA");
      case "pHA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("simNA") &&
          this.hasConfig("RothCA")
        );
      case "sdcmRateMultBiofV263F":
      case "sdcmRateMultBiosV263F":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          this.hasConfig("RothC263")
        );
      case "sdcmRateMultBiofV263A":
      case "sdcmRateMultBiosV263A":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          this.hasConfig("RothC263")
        );
      case "sdcmRateMultHumsF":
        return this.isPldPloSim() && this.hasConfig("RothCF");
      case "sdcmRateMultHumsA":
        return this.isPldPloSim() && this.hasConfig("RothCA");
      case "fracPbioToBiofV263F":
      case "fracHumsToBiosV263F":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          this.hasConfig("RothC263")
        );
      case "fracPbioToBiofV263A":
      case "fracHumsToBiosV263A":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          this.hasConfig("RothC263")
        );
      case "sdcmRateMultBiomCovF":
      case "fracPbioToBiofV265F":
      case "fracPbioToBiosV265F":
      case "fracHumsToBiofV265F":
      case "fracHumsToBiosV265F":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          this.hasConfig("RothC265")
        );
      case "sdcmRateMultBiomCovA":
      case "fracPbioToBiofV265A":
      case "fracPbioToBiosV265A":
      case "fracHumsToBiofV265A":
      case "fracHumsToBiosV265A":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          this.hasConfig("RothC265")
        );
      case "richNCRatioF":
      case "poorNCRatioF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "richNCRatioA":
      case "poorNCRatioA":
        return this.isPldPloSim() && this.hasConfig("simNA");
      case "fracDpmaToStorMycoF":
      case "fracRpmaToStorMycoF":
      case "fracBiofToStorMycoF":
      case "fracBiosToStorMycoF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "fracDpmaToStorMycoA":
      case "fracRpmaToStorMycoA":
      case "fracBiofToStorMycoA":
      case "fracBiosToStorMycoA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      // ----- Soil time series -----
      case "soilCover":
        return this.isPldPloSim() && this.hasConfig("RothCA"); // && !this.hasConfig('CAMFor');
      case "presCMInput":
        return (
          this.isPldPloSim() &&
          ((this.hasConfig("RothCF") && !this.hasConfig("CAMFor")) ||
            (this.hasConfig("RothCA") && !this.hasConfig("CAMAg")))
        );
      case "manuCMFromOffsF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCF") &&
          !this.hasConfig("eventManF")
        );
      case "manuCMFromOffsA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          !this.hasConfig("eventManA")
        );

      // ----- MnrlZap -----
      case "depoSlopeF":
      case "depoOffsetF":
      case "nfixSlopeF":
      case "nfixOffsetF":

      case "nitrK1F":
      case "nitrK2F":
      case "nitrKMaxF":
      case "nitrTemp_aF":
      case "nitrTemp_bF":
      case "nitrTemp_cF":
      case "nitrPH_aF":
      case "nitrPH_bF":
      case "nitrPH_cF":
      case "nitrWater_aLF":
      case "nitrWater_aHF":
      case "nitrWater_aXF":
      case "nitrWater_bLF":
      case "nitrWater_bHF":
      case "nitrWater_bXF":
      case "nitrWater_cLF":
      case "nitrWater_cHF":
      case "nitrWater_cXF":

      case "deniMaxF":
      case "deniNRMaxF":
      case "deniCO2_aF":
      case "deniCO2_bF":
      case "deniCO2_cF":
      case "deniNO3_aF":
      case "deniNO3_bF":
      case "deniNO3_cF":
      case "deniWater_aF":
      case "deniWater_bF":
      case "deniWater_cF":
      case "deniRCO2_aF":
      case "deniRCO2_bF":
      case "deniRCO2_cF":
      case "deniRNO3_aF":
      case "deniRNO3_bF":
      case "deniRNO3_cF":
      case "deniRWater_aF":
      case "deniRWater_bF":
      case "deniRWater_cF":

      case "useTSDeniF":
      case "fracMnrlNToDeepLeacF":
      case "tLeachingNF":
      case "proRataNRationingF":
      case "rationNArrF":
      case "fracBiteArrF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "depoSlopeA":
      case "depoOffsetA":
      case "nfixSlopeA":
      case "nfixOffsetA":

      case "nitrK1A":
      case "nitrK2A":
      case "nitrKMaxA":
      case "nitrTemp_aA":
      case "nitrTemp_bA":
      case "nitrTemp_cA":
      case "nitrPH_aA":
      case "nitrPH_bA":
      case "nitrPH_cA":
      case "nitrWater_aLA":
      case "nitrWater_aHA":
      case "nitrWater_aXA":
      case "nitrWater_bLA":
      case "nitrWater_bHA":
      case "nitrWater_bXA":
      case "nitrWater_cLA":
      case "nitrWater_cHA":
      case "nitrWater_cXA":

      case "deniMaxA":
      case "deniNRMaxA":
      case "deniCO2_aA":
      case "deniCO2_bA":
      case "deniCO2_cA":
      case "deniNO3_aA":
      case "deniNO3_bA":
      case "deniNO3_cA":
      case "deniWater_aA":
      case "deniWater_bA":
      case "deniWater_cA":
      case "deniRCO2_aA":
      case "deniRCO2_bA":
      case "deniRCO2_cA":
      case "deniRNO3_aA":
      case "deniRNO3_bA":
      case "deniRNO3_cA":
      case "deniRWater_aA":
      case "deniRWater_bA":
      case "deniRWater_cA":

      case "useTSDeniA":
      case "fracMnrlNToDeepLeacA":
      case "tLeachingNA":
      case "proRataNRationingA":
      case "rationNArrA":
      case "fracBiteArrA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      case "bondOnF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "bondEmitLoF":
      case "bondEmitMedF":
      case "bondEmitHiF":
      case "bondMnrlNMF":
      case "bondSoilTempF":
      case "bondSpreadF":
        return false; // this.isPldPloSim() && this.hasConfig('simNF') && (coOnly || mnrl->f.bondOn);
      case "bondOnA":
        return false; // this.isPldPloSim() && this.hasConfig('simNA');
      case "bondEmitLoA":
      case "bondEmitMedA":
      case "bondEmitHiA":
      case "bondMnrlNMA":
      case "bondSoilTempA":
      case "bondSpreadA":
        return false; //this.isPldPloSim() && this.hasConfig('simNA') && (coOnly || mnrl->a.bondOn);

      // ----- Mnrl time series -----
      case "mnrlNMFromOffsF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("simMnrlNF") &&
          !this.hasConfig("eventNFeF")
        );
      case "mnrlNMFromOffsA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("simMnrlNA") &&
          !this.hasConfig("eventNFeA")
        );
      case "mnrlNMToAtmsDeniF":
      case "mnrlNMToAtmsLeacF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "mnrlNMToAtmsDeniA":
      case "mnrlNMToAtmsLeacA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      // ----- InitTreeF -----
      case "treeNmInit":
      case "treeExistsInit":
        return speciesUsed && this.hasConfig("CAMFor");
      case "maxTreeAgeInit":
      case "avgTreeAgeInit":
        return speciesUsed && this.hasConfig("CAMFor");

      case "tInitStem":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "stemMInitF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "branMInitF":
      case "barkMInitF":
      case "leafMInitF":
      case "cortMInitF":
      case "firtMInitF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "stemVolInitF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "stemFracInitF":
      case "branFracInitF":
      case "barkFracInitF":
      case "leafFracInitF":
      case "cortFracInitF":
      case "firtFracInitF":
        return this.hasConfig("CAMFor") && speciesUsed;
      case "stemNCRatioInitF":
      case "branNCRatioInitF":
      case "barkNCRatioInitF":
      case "leafNCRatioInitF":
      case "cortNCRatioInitF":
      case "firtNCRatioInitF":
      case "storNMInitF":
        return this.hasConfig("simNF") && speciesUsed;
      case "stemM3PGInit":
      case "foliM3PGInit":
      case "rootM3PGInit":
      case "nStemsInit":
        return (
          speciesUsed && this.hasConfig("PPPG") && !this.hasConfig("CAMFor")
        );

      // ----- InitDebrF -----
      case "dDdwdCMInitF":
      case "rDdwdCMInitF":
      case "dChwdCMInitF":
      case "rChwdCMInitF":
      case "dBlitCMInitF":
      case "rBlitCMInitF":
      case "dLlitCMInitF":
      case "rLlitCMInitF":
      case "dCodrCMInitF":
      case "rCodrCMInitF":
      case "dFidrCMInitF":
      case "rFidrCMInitF":
        return this.isPldPloSim() && this.hasConfig("CAMFor");
      // ----- InitStdeF ----
      case "sDStemCMInitF":
      case "sDBranCMInitF":
      case "sDBlitCMInitF":
      case "sDLlitCMInitF":
      case "sDCodrCMInitF":
      case "sDFidrCMInitF":
        return this.isPldPloSim() && this.hasConfig("CAMFor");

      case "dDdwdNCRatioInitF":
      case "dChwdNCRatioInitF":
      case "dBlitNCRatioInitF":
      case "dLlitNCRatioInitF":
      case "dCodrNCRatioInitF":
      case "dFidrNCRatioInitF":
        return this.isPldPloSim() && this.hasConfig("simNF");

      // ----- InitMulcF -----
      case "sopmCMInitF":
      case "lrpmCMInitF":
      case "mrpmCMInitF":
      case "sommCMInitF":
      case "lrmmCMInitF":
      case "mrmmCMInitF":
      case "micrCMInitF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "sopmNCRatioInitF":
      case "sommNCRatioInitF":
      case "micrNCRatioInitF":
        return (
          this.isPldPloSim() &&
          this.hasConfig("GendecF") &&
          this.hasConfig("simNF")
        );

      // ----- InitSoilF -----
      case "dpmaCMInitF":
      case "rpmaCMInitF":
      case "biofCMInitF":
      case "biosCMInitF":
      case "humsCMInitF":
        return this.isPldPloSim() && this.hasConfig("RothCF");

      case "dpmaNCRatioInitF":
      case "rpmaNCRatioInitF":
      case "biofNCRatioInitF":
      case "biosNCRatioInitF":
      case "humsNCRatioInitF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "inrtCMInitF":
        return this.isPldPloSim() && this.hasConfig("CAMFor");
      case "inrtNCRatioInitF":
        return this.isPldPloSim() && this.hasConfig("simNF");
      case "ASWInitF":
        return this.isPldPloSim() && this.hasConfig("PPPG");
      case "TSMDInitF":
        return this.isPldPloSim() && this.hasConfig("RothCF");

      // ----- InitMnrlF -----
      case "mulcNMInitF":
        return this.isPldPloSim() && this.hasConfig("GendecF");
      case "sAmmNMInitF":
      case "sNtrNMInitF":
      case "dAmmNMInitF":
      case "dNtrNMInitF":
        return this.isPldPloSim() && this.hasConfig("simNF");

      // ----- InitProdF -----
      case "fuelCMInitF":
      case "paprCMInitF":
      case "packCMInitF":
      case "furnCMInitF":
      case "fibrCMInitF":
      case "consCMInitF":
      case "resiCMInitF":
      case "fuelLfCMInitF":
      case "paprLfCMInitF":
      case "packLfCMInitF":
      case "furnLfCMInitF":
      case "fibrLfCMInitF":
      case "consLfCMInitF":
      case "resiLfCMInitF":
        return this.isPldPloSim() && this.hasConfig("CAMFor");
      case "fuelNCRatioInitF":
      case "paprNCRatioInitF":
      case "packNCRatioInitF":
      case "furnNCRatioInitF":
      case "fibrNCRatioInitF":
      case "consNCRatioInitF":
      case "resiNCRatioInitF":
        return this.isPldPloSim() && this.hasConfig("simNF");

      // ----- InitCropA -----
      case "cropNmInit":
      case "cropExistsInit":
      case "maxCropAgeInit":
      case "avgCropAgeInit":
      case "gbfrMInitA":
      case "stlkMInitA":
      case "leafMInitA":
      case "cortMInitA":
      case "firtMInitA":
        return this.isPldPloSim() && this.hasConfig("CAMAg");
      case "gbfrNCRatioInitA":
      case "stlkNCRatioInitA":
      case "leafNCRatioInitA":
      case "cortNCRatioInitA":
      case "firtNCRatioInitA":
      case "storNMInitA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      // ----- InitDebrA -----
      case "dGlitCMInitA":
      case "rGlitCMInitA":
      case "dSlitCMInitA":
      case "rSlitCMInitA":
      case "dLlitCMInitA":
      case "rLlitCMInitA":
      case "dCodrCMInitA":
      case "rCodrCMInitA":
      case "dFidrCMInitA":
      case "rFidrCMInitA":
        return this.isPldPloSim() && this.hasConfig("CAMAg");
      case "dGlitNCRatioInitA":
      case "dSlitNCRatioInitA":
      case "dLlitNCRatioInitA":
      case "dCodrNCRatioInitA":
      case "dFidrNCRatioInitA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      // ----- InitMulcA -----
      case "sopmCMInitA":
      case "lrpmCMInitA":
      case "mrpmCMInitA":
      case "sommCMInitA":
      case "lrmmCMInitA":
      case "mrmmCMInitA":
      case "micrCMInitA":
        return this.isPldPloSim() && this.hasConfig("GendecA");
      case "sopmNCRatioInitA":
      case "sommNCRatioInitA":
      case "micrNCRatioInitA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("GendecA") &&
          this.hasConfig("simNA")
        );

      // ----- InitSoilA -----
      case "dpmaCMInitA":
      case "rpmaCMInitA":
      case "humsCMInitA":
      case "biofCMInitA":
      case "biosCMInitA":
        return this.isPldPloSim() && this.hasConfig("RothCA");

      case "dpmaNCRatioInitA":
      case "rpmaNCRatioInitA":
      case "biofNCRatioInitA":
      case "biosNCRatioInitA":
      case "humsNCRatioInitA":
        return (
          this.isPldPloSim() &&
          this.hasConfig("RothCA") &&
          this.hasConfig("simNA")
        );
      case "inrtNCRatioInitA":
        return this.isPldPloSim() && this.hasConfig("simNA");
      case "TSMDInitA":
        return this.isPldPloSim() && this.hasConfig("RothCA");

      // ----- InitMnrlA -----
      case "mulcNMInitA":
        return this.isPldPloSim() && this.hasConfig("GendecA");
      case "sAmmNMInitA":
      case "sNtrNMInitA":
      case "dAmmNMInitA":
      case "dNtrNMInitA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      // ----- InitProdA -----
      case "fualCMInitA":
      case "gbfpCMInitA":
      case "caneCMInitA":
      case "lfprCMInitA":
      case "rtprCMInitA":
      case "hssiCMInitA":
      case "anprCMInitA":
        return this.isPldPloSim() && this.hasConfig("CAMAg");
      case "fualNCRatioInitA":
      case "gbfpNCRatioInitA":
      case "caneNCRatioInitA":
      case "lfprNCRatioInitA":
      case "rtprNCRatioInitA":
      case "hssiNCRatioInitA":
      case "anprNCRatioInitA":
        return this.isPldPloSim() && this.hasConfig("simNA");

      // ----- InitWholP -----
      case "tFrFracInit":
        return (
          this.isPldPloSim() &&
          this.hasConfig("CAMFor") &&
          this.hasConfig("CAMFor")
        );
      case "frFracInit":
        return (
          this.isPldPloSim() &&
          this.hasConfig("CAMFor") &&
          this.hasConfig("CAMAg")
        ); //&& (coOnly || init->wholP.z.tFrFracInit == kFrFracConst);
      case "initTSMD":
        return this.isSim() && this.hasConfig("RothC");

      // ------ Economic information ------
      case "incmStYr":
      case "incmEnYr":
      case "incmTreeF":
      case "incmDebrF":
      case "incmMulcF":
      case "incmSoilF":
      case "incmProdF":
      case "incmCropA":
      case "incmDebrA":
      case "incmMulcA":
      case "incmSoilA":
      case "incmProdA":
        return false; //this.isPldPlo() && this.hasConfig('econ');
      case "cPriceUseTS":
      case "cPriceBaseYr":
      case "cPriceBase":
      case "cPriceIncr":
        return false; //this.isPldPlo() && this.hasConfig('econ') && (coOnly || !econInfo->z.cPriceUseTS);
      case "cPriceTS":
        return false; //this.isPldPlo() && this.hasConfig('econ') && (coOnly || econInfo->z.cPriceUseTS);
      case "discountRateEC":
      case "refDiscountYr":
      case "lastDiscountYr":
        return false; //this.isPldPlo() && this.hasConfig('econ');

      // ----- All events -----
      case "tEV":
      case "onEV":
      case "tOriginEV":
      case "dateEV":
      case "nYrsFromStEV":
      case "nDaysFromStEV":
      case "nmEV":
      case "notesEV":
      case "categoryEV":
      case "labelEV":
        return this.isEvUsed(kAllE);
      case "tFaqEV":
      case "tAaqEV":
      case "aqStYrEV":
      case "aqEnYrEV":
        return this.isSim() && this.hasConfig("CAMFor") && this.isEvUsed(kAllE);
      case "nEvents":
        return this.isPldPloSim() && this.hasConfig("CAMX"); //  && EVUSED(kAllE);

      // ----- Tree planting event -----
      case "treeNmPlnF":
      case "tStemPlnF":
        return this.hasConfig("CAMFor");
      case "stemVolPlnF":
        return this.hasConfig("CAMFor");
      case "stemMPlnF":
        return this.hasConfig("CAMFor");
      case "branMPlnF":
      case "barkMPlnF":
      case "leafMPlnF":
      case "cortMPlnF":
      case "firtMPlnF":
        return this.hasConfig("CAMFor");
      case "agePlnF":
        return this.hasConfig("CAMFor");
      case "stemNCRatioPlnF":
      case "branNCRatioPlnF":
      case "barkNCRatioPlnF":
      case "leafNCRatioPlnF":
      case "cortNCRatioPlnF":
      case "firtNCRatioPlnF":
      case "storNMPlnF":
        return this.hasConfig("CAMFor"); // this.hasConfig("simNA");
      case "fixPlnF":
      case "phaPlnF":
        return true;

      // ----- Crop planting event -----
      case "cropNmPlnA":
        return this.hasConfig("CAMAg");
      case "tTYFCat":
      case "agePlnA":
        return this.hasConfig("CAMAg");
      case "gbfrMPlnA":
      case "stlkMPlnA":
      case "leafMPlnA":
      case "cortMPlnA":
      case "firtMPlnA":
        return this.hasConfig("CAMAg"); // && EVPLNA(IsUsed_MPlnA());
      case "gbfrNCRatioPlnA":
      case "stlkNCRatioPlnA":
      case "leafNCRatioPlnA":
      case "cortNCRatioPlnA":
      case "firtNCRatioPlnA":
      case "storNMPlnA":
        return this.hasConfig("CAMAg"); // && EVPLNA(IsUsed_NPlnA(this));
      case "fixPlnA":
      case "phaPlnA":
        return this.hasConfig("CAMAg"); // && EVPLNA(IsUsed_econPlnA(this));

      // ----- Forest thinning event -----
      case "fracAfctThin":
        return this.hasConfig("CAMFor"); // && EVTHIN(IsUsed_fracAfctThin());
      case "useLogGrades":
        return this.hasConfig("CAMFor"); // && EVTHIN(IsUsed_canChooseLG(this));

      case "clearAllRemainsF":
      case "treeNmThin":
      case "fracGradeThin1":
      case "fracGradeThin2":
      case "fracGradeThin3":
      case "fracGradeThin4":
      case "fracGradeThin5":
      case "fracStemToDdwdExtraF":
      case "fracBranToDdwdExtraF":
      case "fracBarkToBlitExtraF":
      case "fracLeafToLlitExtraF":
      case "fracCortToCodrExtraF":
      case "fracFirtToFidrExtraF":
        return this.hasConfig("CAMFor"); //&& EVTHIN(IsUsed_useLogGrades(this));
      case "fracStemToDdwdThin":
      case "fracStemToFuelThin":
      case "fracStemToPaprThin":
      case "fracStemToPackThin":
      case "fracStemToFurnThin":
      case "fracStemToFibrThin":
      case "fracStemToConsThin":
      case "fracStemToResiThin":
      case "fracStemToSDdwdThin":
      case "fracBranToDdwdThin":
      case "fracBranToFuelThin":
      case "fracBranToPaprThin":
      case "fracBranToPackThin":
      case "fracBranToFurnThin":
      case "fracBranToFibrThin":
      case "fracBranToConsThin":
      case "fracBranToResiThin":
      case "fracBranToSDdwdThin":
      case "fracBarkToBlitThin":
      case "fracBarkToFuelThin":
      case "fracBarkToPaprThin":
      case "fracBarkToResiThin":
      case "fracBarkToSBlitThin":
      case "fracLeafToLlitThin":
      case "fracLeafToFuelThin":
      case "fracCortToCodrThin":
      case "fracCortToFuelThin":
      case "fracCortToSCodrThin":
      case "fracFirtToFidrThin":
      case "fracFirtToSFidrThin":
      case "fracDdwdToFuelThin":
      case "fracChwdToFuelThin":
      case "fracBlitToFuelThin":
      case "fracLlitToFuelThin":
      case "fracSDdwdToFuelThin":
      case "fracSChwdToFuelThin":
      case "fracSBlitToFuelThin":
      case "fracSLlitToFuelThin":
      case "replaceStemsThin":
        return this.hasConfig("CAMFor"); // && EVTHIN(IsUsed_manualThin(this));

      case "useBioAgeAdjThin":
        return this.hasConfig("CAMFor"); //&& EVTHIN(IsUsed_ageAdjThin(this));
      case "remvAvgAgeMultThin":
      case "remvMaxAgeMultThin":
      case "remvOffsetThin":
        return this.hasConfig("CAMFor"); //  && EVTHIN(IsUsed_replaceThin(this));
      case "multStemThin":
      case "multBranThin":
      case "multBarkThin":
      case "multLeafThin":
      case "multFirtThin":
      case "multCortThin":
      case "boostYrsThin":
        return this.hasConfig("CAMFor"); // && EVTHIN(IsUsed_postThin(this));
      case "fixThin":
      case "phaThin":
        return this.hasConfig("CAMFor"); //   && EVTHIN(IsUsed_econThin(this));

      // ----- Agricultural harvest event -----
      case "fracAfctHarv":
        return this.hasConfig("CAMAg"); //&& EVHARV(IsUsed_fracAfctHarv());
      case "useCropGrades":
        return this.hasConfig("CAMAg"); // && EVHARV(IsUsed_canChooseCG(this));
      case "clearAllRemainsA":
      case "cropNmHarv":
      case "fracGradeHarv1":
      case "fracGradeHarv2":
      case "fracGradeHarv3":
      case "fracGradeHarv4":
      case "fracGbfrToGlitExtraA":
      case "fracStlkToSlitExtraA":
      case "fracLeafToLlitExtraA":
      case "fracCortToCodrExtraA":
      case "fracFirtToFidrExtraA":
        return this.hasConfig("CAMAg"); //  && EVHARV(IsUsed_useCropGrades(this));
      case "fracGbfrToFualHarv":
      case "fracGbfrToGbfpHarv":
      case "fracGbfrToHssiHarv":
      case "fracGbfrToGlitHarv":
      case "fracStlkToFualHarv":
      case "fracStlkToCaneHarv":
      case "fracStlkToHssiHarv":
      case "fracStlkToSlitHarv":
      case "fracLeafToFualHarv":
      case "fracLeafToLfprHarv":
      case "fracLeafToHssiHarv":
      case "fracLeafToLlitHarv":
      case "fracCortToFualHarv":
      case "fracCortToRtprHarv":
      case "fracCortToHssiHarv":
      case "fracCortToCodrHarv":
      case "fracFirtToFidrHarv":
      case "fracGlitToFualHarv":
      case "fracSlitToFualHarv":
      case "fracLlitToFualHarv":
        return this.hasConfig("CAMAg"); // && EVHARV(IsUsed_manualHarv(this));
      case "replaceStlksHarv":
      case "remvAvgAgeMultHarv":
      case "remvMaxAgeMultHarv":
      case "remvOffsetHarv":
        return this.hasConfig("CAMAg"); // && EVHARV(IsUsed_replaceHarv(this));
      case "volFracGbfrNMHarv":
      case "volFracStlkNMHarv":
      case "volFracLeafNMHarv":
      case "volFracCortNMHarv":
        return this.hasConfig("CAMAg"); // && EVHARV(IsUsed_volHarv(this));
      case "fixHarv":
      case "phaHarv":
        return this.hasConfig("CAMAg"); //  && EVHARV(IsUsed_econHarv(this));

      // ----- Forest fire -----
      case "fracAfctFirF":
        return this.hasConfig("CAMFor"); //&& EVFIRF(IsUsed_fracFirF());

      case "fracStemToAtmsFirF":
      case "fracBranToAtmsFirF":
      case "fracBarkToAtmsFirF":
      case "fracLeafToAtmsFirF":
      case "fracStemToDdwdFirF":
      case "fracBranToDdwdFirF":
      case "fracBarkToBlitFirF":
      case "fracLeafToLlitFirF":
      case "fracCortToAtmsFirF":
      case "fracCortToCodrFirF":
      case "fracFirtToAtmsFirF":
      case "fracFirtToFidrFirF":
      case "fracStemToSDdwdFirF":
      case "fracBranToSChwdFirF":
      case "fracBarkToSBlitFirF":
      case "fracLeafToSLlitFirF":
      case "fracCortToSCodrFirF":
      case "fracFirtToSFidrFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_treeFirF());

      case "fracDDdwdToAtmsFirF":
      case "fracRDdwdToAtmsFirF":
      case "fracDChwdToAtmsFirF":
      case "fracRChwdToAtmsFirF":
      case "fracDBlitToAtmsFirF":
      case "fracRBlitToAtmsFirF":
      case "fracDLlitToAtmsFirF":
      case "fracRLlitToAtmsFirF":
      case "fracDCodrToAtmsFirF":
      case "fracRCodrToAtmsFirF":
      case "fracDFidrToAtmsFirF":
      case "fracRFidrToAtmsFirF":
      case "fracDDdwdToInrtFirF":
      case "fracRDdwdToInrtFirF":
      case "fracDChwdToInrtFirF":
      case "fracRChwdToInrtFirF":
      case "fracDBlitToInrtFirF":
      case "fracRBlitToInrtFirF":
      case "fracDLlitToInrtFirF":
      case "fracRLlitToInrtFirF":
      case "fracDCodrToInrtFirF":
      case "fracRCodrToInrtFirF":
      case "fracDFidrToInrtFirF":
      case "fracRFidrToInrtFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_debrFirF());
      case "fracSDdwdToAtmsFirF":
      case "fracSChwdToAtmsFirF":
      case "fracSBlitToAtmsFirF":
      case "fracSLlitToAtmsFirF":
      case "fracSDdwdToDebrFirF":
      case "fracSChwdToDebrFirF":
      case "fracSBlitToDebrFirF":
      case "fracSLlitToDebrFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_SdwdFirF());
      case "fracSopmToAtmsFirF":
      case "fracLrpmToAtmsFirF":
      case "fracMrpmToAtmsFirF":
      case "fracSommToAtmsFirF":
      case "fracLrmmToAtmsFirF":
      case "fracMrmmToAtmsFirF":
      case "fracMicrToAtmsFirF":
      case "fracSopmToInrtFirF":
      case "fracLrpmToInrtFirF":
      case "fracMrpmToInrtFirF":
      case "fracSommToInrtFirF":
      case "fracLrmmToInrtFirF":
      case "fracMrmmToInrtFirF":
      case "fracMicrToInrtFirF":
        return this.hasConfig("CAMFor"); //   && EVFIRF(IsUsed_mulcFirF(this));
      case "fracMnamNToAtmsFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_fracMToAtmsFirF(this));
      case "fracSAmmNToAtmsFirF":
      case "fracSNtrNToAtmsFirF":
      case "fracDAmmNToAtmsFirF":
      case "fracDNtrNToAtmsFirF":
        return this.hasConfig("CAMFor"); //  && EVFIRF(IsUsed_fracNToAtmsFirF(this));
      case "yrsStemRegrowFirF":
        return this.hasConfig("CAMFor"); //&& EVFIRF(IsUsed_stemRegrowFirF());
      case "yrsBranRegrowFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_branRegrowFirF());
      case "yrsBarkRegrowFirF":
        return this.hasConfig("CAMFor"); //&& EVFIRF(IsUsed_barkRegrowFirF());
      case "yrsLeafRegrowFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_leafRegrowFirF());
      case "yrsCortRegrowFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_cortRegrowFirF());
      case "yrsFirtRegrowFirF":
        return this.hasConfig("CAMFor"); //&& EVFIRF(IsUsed_firtRegrowFirF());

      case "useBioAgeAdjFirF":
        return this.hasConfig("CAMFor"); // && EVFIRF(IsUsed_ageAdjFirF(this));
      case "fixFirF":
      case "phaFirF":
        return this.hasConfig("CAMFor"); //&& EVFIRF(IsUsed_econFirF(this));

      // ----- Agricultural fire -----
      case "fracAfctFirA":
        return this.hasConfig("CAMAg"); //&& EVFIRA(IsUsed_fracFirA());
      case "fracGbfrToAtmsFirA":
      case "fracStlkToAtmsFirA":
      case "fracLeafToAtmsFirA":
      case "fracGbfrToGlitFirA":
      case "fracStlkToSlitFirA":
      case "fracLeafToLlitFirA":
      case "fracCortToCodrFirA":
      case "fracFirtToFidrFirA":
        return this.hasConfig("CAMAg"); // && EVFIRA(IsUsed_cropFirA());
      case "fracDGlitToAtmsFirA":
      case "fracRGlitToAtmsFirA":
      case "fracDSlitToAtmsFirA":
      case "fracRSlitToAtmsFirA":
      case "fracDLlitToAtmsFirA":
      case "fracRLlitToAtmsFirA":
      case "fracDCodrToAtmsFirA":
      case "fracRCodrToAtmsFirA":
      case "fracDFidrToAtmsFirA":
      case "fracRFidrToAtmsFirA":
      case "fracDGlitToInrtFirA":
      case "fracRGlitToInrtFirA":
      case "fracDSlitToInrtFirA":
      case "fracRSlitToInrtFirA":
      case "fracDLlitToInrtFirA":
      case "fracRLlitToInrtFirA":
      case "fracDCodrToInrtFirA":
      case "fracRCodrToInrtFirA":
      case "fracDFidrToInrtFirA":
      case "fracRFidrToInrtFirA":
        return this.hasConfig("CAMAg"); //&& EVFIRA(IsUsed_debrFirA());
      case "fracSopmToAtmsFirA":
      case "fracLrpmToAtmsFirA":
      case "fracMrpmToAtmsFirA":
      case "fracSommToAtmsFirA":
      case "fracLrmmToAtmsFirA":
      case "fracMrmmToAtmsFirA":
      case "fracMicrToAtmsFirA":
      case "fracSopmToInrtFirA":
      case "fracLrpmToInrtFirA":
      case "fracMrpmToInrtFirA":
      case "fracSommToInrtFirA":
      case "fracLrmmToInrtFirA":
      case "fracMrmmToInrtFirA":
      case "fracMicrToInrtFirA":
        return this.hasConfig("CAMAg"); //   && EVFIRA(IsUsed_mulchFirA(this));
      case "fracMnamNToAtmsFirA":
        return this.hasConfig("CAMAg"); // && EVFIRA(IsUsed_mulcNFirA(this));
      case "fracSAmmNToAtmsFirA":
      case "fracSNtrNToAtmsFirA":
      case "fracDAmmNToAtmsFirA":
      case "fracDNtrNToAtmsFirA":
        return this.hasConfig("CAMAg"); //&& EVFIRA(IsUsed_fracNToAtmsFirA(this));
      case "fixFirA":
      case "phaFirA":
        return this.hasConfig("CAMAg"); //&& EVFIRA(IsUsed_econFirA(this));

      // ----- Plow -----
      case "impactMultiPlow":
      case "sPDecayMultiPlow":
      case "durationPlow":
      case "dTFracGbfrSoilA":
      case "dTFracStlkSoilA":
      case "dTFracLeafSoilA":
      case "dTFracCortSoilA":
      case "dTFracFirtSoilA":
      case "fracAfctPlow":
        return this.hasConfig("CAMAg"); //  && EVPLOW(IsUsed_fracAfctPlow());
      case "fixPlow":
      case "phaPlow":
        return this.hasConfig("CAMAg"); // && EVPLOW(IsUsed_econPlow(this));

      // ----- Herbicide -----
      case "fracAfctHerb":
        return this.hasConfig("CAMAg"); // && EVHERB(IsUsed_fracAfctHerb());
      case "fixHerb":
      case "phaHerb":
        return this.hasConfig("CAMAg"); // && EVHERB(IsUsed_econHerb(this));

      // ----- Grazing change -----
      case "cropGrazOn":
      case "debrGrazOn":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_graz());
      case "tGraz":
        return this.hasConfig("CAMAg"); //  && EVGRAZ(IsUsed_tGraz());
      case "gbfrMGraz":
      case "stlkMGraz":
      case "leafMGraz":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_MGraz());
      case "fracGbfrGraz":
      case "fracStlkGraz":
      case "fracLeafGraz":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_fracCropGraz());
      case "fracGbfrNPPGraz":
      case "fracStlkNPPGraz":
      case "fracLeafNPPGraz":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_fracNPPGraz());
      case "fracGlitGraz":
      case "fracSlitGraz":
      case "fracLlitGraz":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_fracDebrGraz());
      case "tRootSlouGraz":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_tRootSlouGraz());
      case "maxRootSlouFrac":
        return this.hasConfig("CAMAg"); //&& EVGRAZ(IsUsed_maxRootSlouFrac());
      case "fracFoddCMToAtmsGraz":
      case "fracFoddCMToDpmaGraz":
      case "fracFoddCMToRpmaGraz":
        return this.hasConfig("CAMAg"); //&& EVGRAZ(IsUsed_fracFoddCMGraz());
      case "fracFoddNMToAtmsGraz":
      case "fracFoddNMToDpmaGraz":
      case "fracFoddNMToRpmaGraz":
      case "fracFoddNMToSAmmGraz":
      case "fracFoddNMToSNtrGraz":
      case "fracFoddNMToDAmmGraz":
      case "fracFoddNMToDNtrGraz":
        return this.hasConfig("simNA"); // && EVGRAZ(IsUsed_fracFoddCMGraz());
      case "fixGraz":
      case "phaGraz":
        return this.hasConfig("CAMAg"); // && EVGRAZ(IsUsed_econGraz(this));

      // ----- Forest fraction change -----
      case "tFFrc":
        return this.hasConfig("CAMFor") && this.hasConfig("CAMAg"); //   && EVFFRC(IsUsed_tFFrac());

      case "constFFrc":
        return this.hasConfig("CAMFor") && this.hasConfig("CAMAg"); //  && EVFFRC(IsUsed_constFFrc());
      case "fracFFrc":
      case "yearFFrc":
        return this.hasConfig("CAMFor") && this.hasConfig("CAMAg"); //  && EVFFRC(IsUsed_frFracAge(this));
      case "fixFFrc":
      case "phaFFrc":
        return this.hasConfig("CAMFor") && this.hasConfig("CAMAg"); // && EVFFRC(IsUsed_econFFrc(this));

      // ----- Forest treatment -----
      case "ageAdvTmtF":
      case "advPeriodTmtF":
        return this.hasConfig("tyfOn"); // && EVTMTF(IsUsed_advTmtF());
      case "yieldMultTmtF":
        return this.hasConfig("tyfOn"); //&& EVTMTF(IsUsed_yieldTmtF());
      case "type1On":
      case "type2On":
        return this.hasConfig("tyfOn"); // && EVTMTF(IsUsed_tTmtF());
      case "fixTmtF":
      case "phaTmtF":
        return this.hasConfig("tyfOn"); // && EVTMTF(IsUsed_econTmtF(this));

      // ----- Chopper roller -----
      case "fracDDdwdFChop":
      case "fracRDdwdFChop":
      case "fracDBarkFChop":
      case "fracRBarkFChop":
        return this.hasConfig("CAMFor"); // && EVCHOP(IsUsed_fracChop());
      case "fixChop":
      case "phaChop":
        return this.hasConfig("CAMFor"); //  && EVCHOP(IsUsed_econChop(this));

      // ----- Termite change -----
      case "fracDDdwdFTerm":
      case "fracRDdwdFTerm":
      case "fracDCodrFTerm":
      case "fracRCodrFTerm":
        return this.hasConfig("CAMFor"); //  && EVTERM(IsUsed_fracTerm());
      case "fixTerm":
      case "phaTerm":
        return this.hasConfig("CAMFor"); // && EVTERM(IsUsed_econTerm(this));
      //TODO 19329 Add the parameters for EXCL to KIN
      // ----- Forest irrigation change -----
      case "defnitIrrF":
        return this.getConfigField("userEventIrrF") && this.waterUsedF(coOnly); //&& EVIRRF(IsUsed_defnIrrF());
      case "conditIrrF":
        return this.getConfigField("userEventIrrF") && this.waterUsedF(coOnly); // && EVIRRF(IsUsed_condIrrF(this, coOnly));
      case "fixIrrF":
      case "phaIrrF":
        return this.getConfigField("userEventIrrF") && this.waterUsedF(coOnly); //    && EVIRRF(IsUsed_econIrrF(this));

      // ----- Agricultural irrigation change -----
      case "defnitIrrA":
        return this.hasConfig("eventIrrA") && this.waterUsedA(coOnly); //   && EVIRRA(IsUsed_defnIrrA());
      case "conditIrrA":
        return this.hasConfig("eventIrrA") && this.waterUsedA(coOnly); //  && EVIRRA(IsUsed_condIrrA(this, coOnly));
      case "fixIrrA":
      case "phaIrrA":
        return this.hasConfig("eventIrrA") && this.waterUsedF(coOnly); //  && EVIRRA(IsUsed_econIrrA(this));

      // ----- Forest nitrogen fertilizer application -----
      case "mnamNMNFeF":
        return false; // (this.hasConfig('simMnrlNF') && this.hasConfig('eventNFeF')) && EVNFEF(IsUsed_mulcNFeF(this));
      case "sAmmNMNFeF":
      case "sNtrNMNFeF":
      case "dAmmNMNFeF":
      case "dNtrNMNFeF":
        return false; //(this.hasConfig('simMnrlNF') && this.hasConfig('eventNFeF')) && EVNFEF(IsUsed_NNFeF(this));
      case "fixNFeF":
      case "phaNFeF":
        return false; //  (this.hasConfig('simMnrlNF') && this.hasConfig('eventNFeF')) && EVNFEF(IsUsed_econNFeF(this));

      // ----- Agricultural nitrogen fertilizer application -----
      case "mnamNMNFeA":
        return false; // (this.hasConfig('simMnrlNA') && this.hasConfig('eventNFeA'))  && EVNFEA(IsUsed_mulcNFeA(this));
      case "sAmmNMNFeA":
      case "sNtrNMNFeA":
      case "dAmmNMNFeA":
      case "dNtrNMNFeA":
        return false; // (this.hasConfig('simMnrlNA') && this.hasConfig('eventNFeA')) && EVNFEA(IsUsed_NNFeA(this));
      case "fixNFeA":
      case "phaNFeA":
        return false; //  (this.hasConfig('simMnrlNA') && this.hasConfig('eventNFeA'))  && EVNFEA(IsUsed_econNFeA(this));

      // ----- Forest manure from offsite change -----
      case "manuCMManF":
        return this.hasConfig("RothCF") && this.hasConfig("eventManF"); //  && EVMANF(IsUsed_manuCMManF());
      case "fixManF":
      case "phaManF":
        return this.hasConfig("RothCF") && this.hasConfig("eventManF"); // && EVMANF(IsUsed_econManF(this));

      // ----- Agricultural manure from offsite change -----
      case "manuCMManA":
        return this.hasConfig("RothCA") && this.hasConfig("eventManA"); // && EVMANA(IsUsed_manuCMManA());
      case "fixManA":
      case "phaManA":
        return this.hasConfig("RothCA") && this.hasConfig("eventManA"); //  && EVMANA(IsUsed_econManA(this));

      // ----- External costs -----
      case "descXCst":
      case "oneTimeXCst":
      case "onGoingXCst":
        return false; //this.hasConfig('econ') && EVXCST(IsUsed_XCst());
      case "fixXCst":
      case "phaXCst":
        return false; //this.hasConfig('econ') && EVXCST(IsUsed_oneTimeXCst());
      case "fixPyrXCst":
      case "phaPyrXCst":
      case "codeXCst":
        return false; //this.hasConfig('econ') && EVXCST(IsUsed_onGoingXCst());

      // ----- External income -----
      case "descXInc":
      case "oneTimeXInc":
      case "onGoingXInc":
        return false; // this.hasConfig('econ') && EVXINC(IsUsed_XInc());
      case "fixXInc":
      case "phaXInc":
        return false; // this.hasConfig('econ') && EVXINC(IsUsed_oneTimeXInc());
      case "fixPyrXInc":
      case "phaPyrXInc":
      case "codeXInc":
        return false; //   this.hasConfig('econ') && EVXINC(IsUsed_onGoingXInc());

      // ----- Sensitivity analysis -----
      case "tSimSteps":
      case "maxNIterations":
      case "useCnvg":
      case "cnvgPercentChng":
      case "cnvgCheckNSteps":
      case "tSample":
        return this.isPlo() && this.hasConfig("sens");

      // ----- Spatial inputs
      // ----- Spatial-only inputs -----
      case "initFracRpma":
      case "initFracHums":
        return this.isSim() && this.hasConfig("RothCA");
      case "initFracInrt":
        return this.isSim() && this.hasConfig("CAMFor");
      case "reportingRegion1":
      case "reportingRegion2":
      case "reportingRegion3":
      case "reportingRegion4":
      case "npi":
      case "initTotalSoilC":
      case "treeMvg":
      case "plantationType":
      case "baseLandUse":
      case "woodi":
      case "harvest":
      case "irrigation":
      case "drought":
      case "growthRegion":
      case "protectedArea":
      case "statisticalArea":
      case "wildfire":
        return this.isSim();

      // ----- Spatial analysis -----
      case "outputPath":
      case "areaName":

      case "lonTL":
      case "latTL":

      case "reportPerHa":
      case "createSimCellsGrid":

      case "intDegRectN":
      case "intDegRectS":
      case "intDegRectE":
      case "intDegRectW":
      case "spaOutStartYr":
      case "spaOutNYrs":
      case "spaOutSteps":
      case "spaOutStepSelection":
      case "spaOutCellSize":
      case "limitSpaErrs":
      case "maxSpaErrs":
      case "forToFor":
      case "forToGrass":
      case "forToCrop":
      case "grassToFor":
      case "grassToGrass":
      case "grassToCrop":
      case "cropToFor":
      case "cropToGrass":
      case "cropToCrop":
      case "post1990":
      case "post1990Yr":
      case "pre2009":
      case "pre2009Yr":
      case "createAgRunIn":
      case "createAgRunInStYr":
      case "createAgRunInEnYr":
      case "changeMgmtPractices":
      case "simulateSpatialFire":
      case "commencePrescribedBurnYr":
      case "hold1990values":
        return this.isSim();
      case "fracDpmaToAtmsPlowA":
      case "fracRpmaToAtmsPlowA":
      case "fracBiofToAtmsPlowA":
      case "fracBiosToAtmsPlowA":
      case "fracHumToAtmsPlowA":
        return this.isPlo() && this.hasConfig("CAMFor");
      case "durationPlow":
      case "impactMultiPlow":
      case "sPDecayMultiPlow":
      case "dTFracGbfrSoilA":
      case "dTFracStlkSoilA":
      case "dTFracLeafSoilA":
      case "durationPlow":
      case "dTFracCortSoilA":
        return this.isPlo() && this.hasConfig("CAMFor") && is2024;

      // ----- Default -----
      default:
        if (input.catIxII == 0) return false;
        //        EXIT;
        return false;
    }
  }

  private isRequiredAsStdInput(input): boolean {
    const tStd = input.tStdII;
    const stdF = 1;
    const stdFA = 3;
    const stdA = 2;
    if (tStd == stdF || tStd == stdFA) {
      return true;
    }

    if (tStd == stdA || tStd == stdFA) {
      return true;
    }

    return false;
  }

  private getEventQ(): any[] {
    return this.getSimulationService()
      .eventsService.getFormGroup()
      .get("eventQ")?.value;
  }

  private isRequiredByEventQ(input): boolean {
    // Slow!
    // specNm == 0 for the main event queue
    const tev = input.tEventII;
    const notE = 10;
    const allE = 11;
    if (tev == notE) {
      return false;
    }

    return this.isRequired(input);
  }

  private getSimulationService() {
    return this.appService.getSimulationService();
  }

  private getTimingField(field: string): any {
    return this.getSimulationService().timingService.getFormGroup().get(field)
      ?.value;
  }

  private getSiteField(field: string): any {
    return this.getSimulationService().siteService.getFormGroup().get(field)
      ?.value;
  }

  private getSoilField(field: string): any {
    return this.getSimulationService().soilService.getFormGroup().get(field)
      ?.value;
  }

  private getConfigField(field: string): any {
    return this.getSimulationService()
      .configurationService.getFormGroup()
      .get(field)?.value;
  }

  private getInitialConditionsField(field: string): any {
    return this.getSimulationService()
      .initialConditionsService.getFormGroup()
      .get(field)?.value;
  }
  private hasConfig(arg) {
    const simulationService = this.appService.getSimulationService();
    const configService = simulationService.configurationService;
    //check variable
    if (configService[arg] !== undefined) {
      return configService[arg];
    } else if (configService.getFormGroup().get(arg)?.value !== undefined) {
      return configService.getFormGroup().get(arg)?.value;
    }

    return false;
  }

  private isPldPloSim(): boolean {
    //SIM is not available in PUBLIC RELEASE version
    return this.isPldPlo();
  }

  private isPldPloEst(): boolean {
    return this.isPldPlo() || this.getPlotFormat() == "est";
  }

  private isPldPlo(): boolean {
    const plotFormat =
      this.getSimulationService().selectedPlotFormat$.getValue();
    return plotFormat == "pld" || plotFormat == "plo";
  }

  private isPlo(): boolean {
    return this.getPlotFormat() == "plo";
  }

  private isSim(): boolean {
    return false;
  }
  private getPlotFormat(): PlotFormat {
    return this.getSimulationService().selectedPlotFormat$.getValue();
  }

  private waterUsedF(considerConfigOnly = true) {
    //considerConfigOnly is NOT available in PUBLIC RELEASE version
    return (
      this.isPldPloSim() &&
      (this.hasConfig("RothCF") ||
        this.hasConfig("GendecF") ||
        this.hasConfig("PPPG") ||
        this.hasConfig("calcModASW") ||
        this.hasConfig("CAMFor"))
    );
  }

  //---------------------------------------------------------------------------
  private waterUsedA(considerConfigOnly = true) {
    //considerConfigOnly is NOT available in PUBLIC RELEASE version
    return (
      this.isPldPloSim() &&
      (this.hasConfig("RothCA") ||
        this.hasConfig("GendecA") ||
        this.hasConfig("CAMAg"))
    );
  }

  //---------------------------------------------------------------------------
  private waterUsed(considerConfigOnly = true) {
    return (
      this.waterUsedF(considerConfigOnly) || this.waterUsedA(considerConfigOnly)
    );
  }

  private isAirTempUsed(considerConfigOnly = true): boolean {
    if (!this.isPldPloSim()) {
      return false;
    }

    let b;
    if (considerConfigOnly) {
      b =
        this.hasConfig("RothC") ||
        this.hasConfig("Gendec") ||
        this.hasConfig("PPPG") ||
        this.hasConfig("pppgL") ||
        this.isSoilTempUsed();
    } else {
      b =
        this.hasConfig("RothC") ||
        this.hasConfig("Gendec") ||
        this.hasConfig("PPPG") ||
        this.hasConfig("calcModTemp") ||
        (this.isSoilTempUsed() &&
          this.getSiteField("tSoilTemp") == "AirTemp") ||
        this.hasSensitiveToRainAndTempF() ||
        this.hasSensitiveToRainAndTempA();
    }
    return b;
  }

  private isSoilTempUsed(): boolean {
    // SoilTemp is only used for:
    //  - Boundary-line emissions {simN}
    //  - NCycle nitrification temperature function {simN}
    if (!this.isPldPloSim()) {
      return false;
    }
    return this.hasConfig("simN"); //Not available in PUBLIC RELEASE version
  }

  private hasSensitiveToRainAndTempF() {
    if (this.hasConfig("CAMFor")) {
      return this.hasBreakDownSensMulchF();
    }

    return false;
  }

  private hasSensitiveToRainAndTempA() {
    if (this.hasConfig("CAMAg")) {
      return this.hasBreakDownSensMulchA();
    }

    return false;
  }

  private getSpecies(speciesType: "tree" | "crop") {
    return this.getSimulationService()[
      speciesType == "tree" ? "treesService" : "cropsService"
    ].getSpecies()?.value;
  }

  private hasBreakDownSensMulchF(): boolean {
    const species =
      this.getSimulationService().treesService.getSpecies()?.value;
    if (!species?.length) {
      return false;
    }
    for (const sp of species) {
      if (sp.bkdnSensMulcF) {
        return true;
      }
    }

    return false;
  }

  private hasBreakDownSensMulchA(): boolean {
    const species =
      this.getSimulationService().cropsService.getSpecies()?.value;
    if (!species?.length) {
      return false;
    }
    for (const sp of species) {
      if (sp.bkdnSensMulcA) {
        return true;
      }
    }

    return false;
  }

  private isEvUsed(tev, eventAttribute?, attributeValue?) {
    const coOnly = true;
    return (
      this.isPldPloSim() &&
      (coOnly || this.isEventExists(tev, eventAttribute, attributeValue))
    );
  }

  private isEventExists(
    tevId: string,
    eventAttribute?: string,
    attributeValue?: any
  ): boolean {
    for (const eq of this.getEventQ()) {
      if (eq.eventId == this.eventIdMap[tevId]) {
        // Check if both eventAttribute and attributeValue are provided before comparing
        if (
          eventAttribute !== undefined &&
          attributeValue !== undefined &&
          eq[eventAttribute] == attributeValue
        ) {
          return true;
        } else if (eventAttribute) {
          return (
            eq[eventAttribute] !== null && eq[eventAttribute] !== undefined
          );
        }

        return true;
      }
    }

    ["tree", "crop"].forEach((speciesType: "tree" | "crop") => {
      for (const eq of this.getSpecies(speciesType)) {
        if (eq.tEV == this.eventIdMap[tevId]) {
          // Check if both eventAttribute and attributeValue are provided before comparing
          if (
            eventAttribute !== undefined &&
            attributeValue !== undefined &&
            eq[eventAttribute] === attributeValue
          ) {
            return true;
          } else if (eventAttribute) {
            return (
              eq[eventAttribute] !== null && eq[eventAttribute] !== undefined
            );
          }
          return true;
        }
      }
    });

    return false;
  }
}
