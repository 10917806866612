<nav aria-label="breadcrumb" class="breadcrumb-container">
  <button class="btn btn-sm">
    <i class="bi bi-chevron-left" (click)="navigateToHome()"></i>
  </button>
  <ol class="breadcrumb">
    <li
      class="breadcrumb-item"
      *ngFor="let navItem of nav; let i = index"
      [class]="{ active: nav.length - 1 == i }"
    >
      <button
        class="btn btn-sm"
        (click)="navigateTo(i)"
        *ngIf="nav.length - 1 !== i; else currentNavItem"
      >
        {{ navItem.label }}
      </button>
      <ng-template #currentNavItem>
        <span>{{ navItem.label }}</span>
      </ng-template>
    </li>
  </ol>
</nav>
