import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BasePlotFormService } from "src/app/plot/services/base-plot-form.service";
import { FormLayout } from "src/app/plot/models";
import { FormModel } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class RmtResultsService extends BasePlotFormService {
  public readonly pageId = "result";

  public readonly viewType$ = new BehaviorSubject<"table" | "chart">("chart");

  public readonly simulationResult$ = new BehaviorSubject<string>(null);

  public layout: FormLayout = {
    label: "Result",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "SimulationResultComponent",
                componentInputs: [
                  {
                    inputKey: "simulationService",
                    variable: "simulationService",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {};

  public setViewType(type): void {
    this.viewType$.next(type);
  }

  public setResult(result): void {
    this.simulationResult$.next(result);
  }

  public exportTableAsCsv() {
    const result = this.simulationResult$.getValue();
    if (this.simulationResult$.getValue()) {
      this.simulationService.helperService.downloadFile(
        "RMT_simulation_result_" +
          this.simulationService.getFileName() +
          ".csv",
        result,
        "text/csv"
      );
    } else {
      this.simulationService.messageService.addAlert({
        type: "danger",
        msg: `Simulation result not found.`,
        dismissible: true,
      });
    }
  }

  override reset() {
    this.viewType$.next("chart");
    this.simulationResult$.next(null);
  }
}
