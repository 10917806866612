import { Injectable } from "@angular/core";
import { UntypedFormArray, Validators } from "@angular/forms";
import { BehaviorSubject, of } from "rxjs";
import { take } from "rxjs/operators";
import { Constants } from "src/app/shared/constants";
import {
  CropSpecies,
  CropSpeciesMetaData,
  FieldWatcher,
  FormModel,
  SpatialData,
  TreeSpecies,
  TreeSpeciesMetadata,
} from "src/app/shared/models";
import { FormGroupElement, FormLayout } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import Utilities from "src/app/shared/utilities/utils";

@Injectable({
  providedIn: "root",
})
export class DataBuilder2020Service extends BasePlotFormService {
  public readonly pageId = "dataBuilder";
  public readonly pageHelpLink = "132_Data%20Builder.htm";

  public readonly categoryIds = [4];

  public readonly spatialDataSource$ = new BehaviorSubject<SpatialData>(null);
  public readonly spatialData$ = this.spatialDataSource$.asObservable();

  readonly selectedTreeSpeciesSource$ = new BehaviorSubject<TreeSpecies[]>([]);

  readonly selectedCropSpeciesSource$ = new BehaviorSubject<CropSpecies[]>([]);

  readonly availableTreeSpecies$ = new BehaviorSubject<TreeSpeciesMetadata[]>(
    []
  );

  readonly availableCropSpecies$ = new BehaviorSubject<CropSpeciesMetaData[]>(
    []
  );

  public modifiers: FormGroupElement[] = [
    {
      label: "Location",
      isShown: true,
      isRoot: true,
      items: [
        {
          label: null,
          preventLabelElement: true,
          inputs: [
            {
              label: "Longitude",
              element: "input",
              type: "number",
              programmingName: "lonBL",
            },
            {
              label: "Latitude",
              element: "input",
              type: "number",
              programmingName: "latBL",
            },
          ],
        },
      ],
    },
    {
      label: "Spatial data",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: "Spatial data averaged over",
          inputs: [
            {
              element: "select",
              selectOptions: [
                { label: "No average", value: "Cell" },
                { label: "1 hectare", value: "Hectare" },
                { label: "100 hectare", value: "OneKm" },
                { label: "400 hectare", value: "TwoKm" },
                { label: "900 hectare", value: "ThreeKm" },
                { label: "2500 hectare", value: "FiveKm" },
              ],
              programmingName: "areaBL",
            },
          ],
        },
        {
          id: "frCat",
          label: "Forest category",
          inputs: [
            {
              element: "select",
              selectOptions: [
                { label: "All", value: null },
                { label: "Native Vegetation Groups", value: "MVG" },
                { label: "Plantation Species", value: "Plantation" },
                { label: "Environmental and Mallees", value: "EnvMallee" },
                { label: "ERF Methods", value: "ERF" },
                {
                  label: "ERF Methods-EMP specific calibrations",
                  value: "ERFH",
                },
              ],
              programmingName: "frCat",
            },
          ],
        },
        {
          id: "frFracBL",
          label: "Forest % download",
          inputs: [
            {
              element: "input",
              type: "number",
              programmingName: "frFracBL",
            },
          ],
        },

        {
          label: "Apply queried data",
          inputs: [
            {
              element: "input",
              type: "switch",
              programmingName: "applyDownloadedData",
            },
          ],
        },

        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "ButtonComponent",
              componentInputs: [
                {
                  inputKey: "disabled",
                  variable: "disableQuery",
                  bind: this,
                },
                {
                  inputKey: "disabledTooltip",
                  value:
                    "Latitude, Longitude, Plot type in Configureation page and Forest % download inputs must be valid",
                  bind: this,
                },
                {
                  inputKey: "inputText",
                  value: "Query FullCAM spatial data",
                },
                {
                  inputKey: "isLoading$",
                  variable: "isLoading$",
                },
                {
                  inputKey: "successText",
                  value: "Done",
                },
              ],
              componentOutputs: [
                {
                  outputKey: "onClick",
                  method: "getSpatialData",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: "Location metadata",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: null,
          inputs: [
            {
              element: "component",
              component: "LocationMetadataComponent",
              componentInputs: [
                {
                  inputKey: "spatialData$",
                  variable: "spatialData$",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Location info",
    groups: [
      // {
      //   isShown: true,
      //   isRoot: true,
      //   items: [
      //     {
      //       label: null,
      //       inputs: [
      //         // {
      //         //   element: "component",
      //         //   component: "MapComponent",
      //         //   componentInputs: [
      //         //     {
      //         //       inputKey: "lat$",
      //         //       formKey: "latBL",
      //         //       isObservable: true,
      //         //     },
      //         //     {
      //         //       inputKey: "lng$",
      //         //       formKey: "lonBL",
      //         //       isObservable: true,
      //         //     },
      //         //   ],
      //         //   componentOutputs: [
      //         //     {
      //         //       outputKey: "mapClickEvent",
      //         //       method: "setLatlng",
      //         //     },
      //         //   ],
      //         // },
      //       ],
      //     },
      //   ],
      // },

      {
        label: "Select species",
        isShown: true,
        isRoot: true,
        items: [
          {
            label: null,
            groups: [
              {
                label: "Search or scroll to select trees for your simulation",
                isShown: true,
                id: "tree-species-list",
                items: [
                  {
                    label: null,
                    inputs: [
                      {
                        element: "component",
                        component: "SpeciesListComponent",
                        componentInputs: [
                          {
                            inputKey: "selectedSpecies",
                            method: "getSelectedSpecies",
                            payload: "tree",
                          },
                          {
                            inputKey: "initialSpeciesId$",
                            method: "getInitialSpeciesId",
                            payload: "tree",
                          },
                          {
                            inputKey: "availableSpecies$",
                            variable: "availableTreeSpecies$",
                          },
                          {
                            inputKey: "type",
                            value: "tree",
                          },
                        ],
                        componentOutputs: [
                          {
                            outputKey: "speciesSelected",
                            method: "getSpeciesDetails",
                          },
                          {
                            outputKey: "speciesRemoved",
                            method: "removeSelectedSpecies",
                          },
                          {
                            outputKey: "initialSpeciesSelected",
                            method: "setInitialSpecies",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            label: null,
            groups: [
              {
                label: "Search or scroll to select crops for your simulation",
                isShown: true,
                id: "crop-species-list",
                items: [
                  {
                    label: null,
                    inputs: [
                      {
                        element: "component",
                        component: "SpeciesListComponent",
                        componentInputs: [
                          {
                            inputKey: "selectedSpecies",
                            method: "getSelectedSpecies",
                            payload: "crop",
                          },
                          {
                            inputKey: "initialSpeciesId$",
                            method: "getInitialSpeciesId",
                            payload: "crop",
                          },
                          {
                            inputKey: "availableSpecies$",
                            variable: "availableCropSpecies$",
                          },
                          {
                            inputKey: "type",
                            value: "crop",
                          },
                        ],
                        componentOutputs: [
                          {
                            outputKey: "speciesSelected",
                            method: "getSpeciesDetails",
                          },
                          {
                            outputKey: "speciesRemoved",
                            method: "removeSelectedSpecies",
                          },
                          {
                            outputKey: "initialSpeciesSelected",
                            method: "setInitialSpecies",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    lonBL: {
      high: 180,
      low: -180,
      isOneMore: false,
      dataType: 0,
      helpId: 132,
      defaultValue: "131.03000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: -180,
        },
        {
          functionName: "max",
          input: 180,
        },
      ],
      label: "Longitude of plot",
      unit: "Deg E",
      categoryName: "Build",
      categoryLabel: "Location info",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 376,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    latBL: {
      high: 90,
      low: -90,
      isOneMore: false,
      dataType: 0,
      helpId: 132,
      defaultValue: "-25.34000",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: -90,
        },
        {
          functionName: "max",
          input: 90,
        },
      ],
      label: "Latitude of plot",
      unit: "Deg N",
      categoryName: "Build",
      categoryLabel: "Location info",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 10,
      tIn: 377,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    frCat: {
      high: 6,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 132,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Forest Category",
      unit: "",
      categoryName: "Build",
      categoryLabel: "Location info",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 100,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "SpecFrCatT",
      eventId: 10,
      tIn: 1252,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    applyDownloadedData: {
      label: "Apply queried data",
      defaultValue: true,
      isShown: true,
      isExplorer: false,
      excludeFromXml: true,
      suffixId: null,
    },
    areaBL: {
      high: 5,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 132,
      defaultValue: "Cell",
      validators: [],
      validatorConfig: [],
      label: "area for DataBuilder",
      unit: "",
      categoryName: "Build",
      categoryLabel: "Location info",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "BLAreaT",
      eventId: 10,
      tIn: 92,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    frFracBL: {
      label: "Forest % download",
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      isShown: false,
      isExplorer: false,
      isDigest: true,
      categoryLabel: "Location info",
      unit: "%",
      suffixId: null,
      isOneMore: false,
      dataType: 0,
      high: 0,
      low: 1,
      helpId: 132,
      scale: 1,
      isInverse: false,
      eventId: 10,
      isRisk: false,
      isSpa: false,
    },
  };

  public fieldWatcher: FieldWatcher = {
    "configuration:tPlot": (newValue: any, { formGroup, formModel }) => {
      const plotType = newValue;
      const isMixed = plotType == "CompM";

      const treeListGroup = this.getLayoutGroup(
        this.layout.groups,
        "tree-species-list"
      );
      const cropListGroup = this.getLayoutGroup(
        this.layout.groups,
        "crop-species-list"
      );

      treeListGroup.isShown = plotType == "CompF" || plotType == "CompM";
      cropListGroup.isShown = plotType == "CompA" || plotType == "CompM";

      formModel["frCat"].isShown =
        Constants.ALL_FOREST_PLOT_TYPES.includes(plotType);
      formModel["frFracBL"].isShown = plotType == "CompM";

      //location form

      if (isMixed) {
        this.addValidatorsInBulk(
          ["frFracBL"],
          [Validators.required],
          formGroup
        );
      } else {
        formGroup.get("frFracBL").setValue(formModel["frFracBL"].defaultValue);
        this.removeValidatorsInBulk(
          ["frFracBL"],
          [Validators.required],
          formGroup
        );
      }
    },
  };

  public setLatlng(mapClickEvent): void {
    const latlng = mapClickEvent.lngLat;
    this.formGroup.get("latBL").setValue(latlng.lat);
    this.formGroup.get("lonBL").setValue(latlng.lng);
  }

  public disableQuery(): boolean {
    const lat = this.formGroup.get("latBL").invalid;
    const long = this.formGroup.get("lonBL").invalid;
    const plotType = this.getPlotType() == null;
    const percentForest = this.formGroup.get("frFracBL").invalid;
    return lat || long || plotType || percentForest;
  }

  public getPlotType() {
    return this.simulationService.configurationService
      .getFormGroup()
      .get("tPlot")?.value;
  }

  public getSpatialData() {
    this.isLoading$.next(true);

    const plotType = this.getPlotType();

    const params = {
      latitude: this.formGroup.get("latBL").value,
      longitude: this.formGroup.get("lonBL").value,
      area: this.formGroup.get("areaBL").value,
      plotT: plotType,
      frCat:
        !this.formGroup.get("frCat").value ||
        this.formGroup.get("frCat").value == "null"
          ? "All"
          : this.formGroup.get("frCat").value,
      //DocEdit.cpp 2351
      incGrowth: true,
    };

    this.simulationService.dbService
      .getSpatialData(params)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.isLoading$.next(false);

          if (!response || response?.error) {
            this.simulationService.messageService.addAlert({
              type: "danger",
              msg: "Something went wrong while getting the spatial data.",
              dismissible: true,
            });
            return;
          }

          if (response.ItemList[0].id == "Messages") {
            const errorMessage = response.ItemList[0].ItemInfo[0].value;
            this.simulationService.messageService.addAlert({
              type: "danger",
              msg: errorMessage,
              dismissible: true,
            });
            return;
          }
          //check if it is imported plot file, then redownload the species again
          let isImportedPlotFile = false;
          const selectedCropsSepcies =
            this.selectedCropSpeciesSource$.getValue();
          const selectedTreeSpecies =
            this.selectedTreeSpeciesSource$.getValue();
          if (
            (selectedCropsSepcies || selectedTreeSpecies) &&
            !this.spatialDataSource$.getValue()
          ) {
            isImportedPlotFile = true;
          }

          this.spatialDataSource$.next(response);
          this.setSpatialData(response);

          const logArguments = [
            this.formGroup.get("latBL").value,
            this.formGroup.get("lonBL").value,
          ];

          this.simulationService.logService.setLog(
            logArguments,
            "SpaUsingLocn"
          );

          if (isImportedPlotFile) {
            this.reassignRegimesAfterImport(
              selectedTreeSpecies,
              selectedCropsSepcies
            );
          }

          //manually validate pages that have data updated
          //manually trigger watchers to reapply validations
          const siteService = this.simulationService.siteService;
          const soilService = this.simulationService.soilService;
          const initialConditionsService =
            this.simulationService.initialConditionsService;

          [siteService, soilService, initialConditionsService].forEach(
            (service) => {
              service.manuallyTriggerWatchingFields(service.fieldWatcher, {
                formGroup: service.getFormGroup(),
                formModel: service.formModel,
                layout: service.layout,
                simulationService: this.simulationService,
              });
            }
          );
        },
        (error) => {
          this.isLoading$.next(false);
        }
      );
  }

  public setSpatialData(data): void {
    //DocDB.cpp:835
    const siteInfo = data?.SiteInfo[0];
    const initialConditionsFormGroup =
      this.simulationService.initialConditionsService.getFormGroup();

    if (siteInfo) {
      this.formGroup.get("latBL").setValue(siteInfo.latitude);
      this.formGroup.get("lonBL").setValue(siteInfo.longitude);
      this.formGroup.get("areaBL").setValue(siteInfo.areaBL);
      if (this.formGroup.get("frFracBL").value > -1) {
        this.applyXmlValuesToFormGroup(
          {
            frFracInit: this.formGroup.get("frFracBL").value,
            tFrFracInit: "FracConst",
          },
          initialConditionsFormGroup
        );
      }
    }

    if (data.ItemList) {
      this.availableTreeSpecies$.next([]);
      this.availableCropSpecies$.next([]);
      //should clear all relevant data from the other tabs?
      data.ItemList.forEach((il) => {
        if (il.id == "FrSpecies") {
          this.availableTreeSpecies$.next(il.ItemInfo);
        } else if (il.id == "AgSpecies") {
          this.availableCropSpecies$.next(il.ItemInfo);
        }
      });
    }

    const isAplyData = this.formGroup.get("applyDownloadedData").value;

    this.simulationService.messageService.addAlert({
      type: "success",
      msg: isAplyData
        ? "Spatial data has been applied to the form successfully!"
        : "Speice list has been updated successfully!",
      timeout: 5000,
      dismissible: true,
    });

    if (!this.formGroup.get("applyDownloadedData").value) {
      return;
    }

    if (data?.InputElement) {
      this.simulationService.siteService.updateFormDataFromSpatialData(
        data.InputElement
      );
    }

    if (data.LocnSoil?.length) {
      this.simulationService.soilService.updateFormDataFromSpatialData(
        data.LocnSoil[0]
      );

      this.simulationService.initialConditionsService.setInitialSoilData(
        data.LocnSoil[0]
      );
    }
  }

  reassignRegimesAfterImport(selectedTreeSpecies, selectedCropsSepcies): void {
    selectedTreeSpecies.forEach((ts) => {
      if (ts.SpeciesForest) {
        //Existing Fullcam uses species name to update the regimeList after imported
        //species name changed will be treated as custom species

        const name = ts.SpeciesForest[0]?.$.nmSP;
        const foundSpecies = this.availableTreeSpecies$
          .getValue()
          ?.find((at) => at.value == name);

        if (foundSpecies) {
          this.getSpeciesDetails({ species: foundSpecies, type: "tree" }, true);
        } else {
          this.simulationService.messageService.addAlert({
            type: "danger",
            msg: `Species - ${ts.SpeciesForest[0]?.$.nmSP} not found from the current latitute and longitude.`,
            dismissible: true,
          });
        }
      }
    });

    selectedCropsSepcies.forEach((cs) => {
      if (cs.SpeciesAgriculture) {
        //this is the only unique value can link the idSP from a plot file to available species
        const name = cs.SpeciesAgriculture[0]?.$.nmSP;
        const foundSpecies = this.availableCropSpecies$
          .getValue()
          ?.find((at) => at.value == name);
        if (foundSpecies) {
          this.getSpeciesDetails({ species: foundSpecies, type: "crop" }, true);
        } else {
          this.simulationService.messageService.addAlert({
            type: "danger",
            msg: `Species - ${cs.SpeciesAgriculture[0]?.$.nmSP} not found from the current latitute and longitude.`,
            dismissible: true,
          });
        }
      }
    });
  }

  public getSpeciesDetails(data, isRegimeOnly?: boolean) {
    const params = {
      specId: data.species.id,
      latitude: this.formGroup.get("latBL").value,
      longitude: this.formGroup.get("lonBL").value,
      area: this.formGroup.get("areaBL").value,
      frCat:
        !this.formGroup.get("frCat").value ||
        this.formGroup.get("frCat").value == "null"
          ? "All"
          : this.formGroup.get("frCat").value,
    };

    this.simulationService.dbService
      .getSpeciesDetails(params)
      .pipe(take(1))
      .subscribe(
        (responseFromServer) => {
          let response = responseFromServer;

          let speciesNameForLog = "";

          this.isLoading$.next(false);
          if (!response || response?.error) {
            this.simulationService.messageService.addAlert({
              type: "danger",
              msg: "Something went wrong while getting the Species data.",
              dismissible: true,
            });
            return;
          }

          if (response.ItemList && response.ItemList[0].id == "Messages") {
            const errorMessage = response.ItemList[0].ItemInfo[0].value;
            this.simulationService.messageService.addAlert({
              type: "danger",
              msg: errorMessage,
              dismissible: true,
            });
            return;
          }

          if (response.RegimeList) {
            this.simulationService.eventsService.addAvailableRegime(
              response.RegimeList[0]
            );
          }
          //for imported plot file
          if (isRegimeOnly) {
            this.simulationService[
              data.type == "tree" ? "treesService" : "cropsService"
            ].replaceIdRegimeSPFromDownloadedSpecies(response);

            this.replaceSpeciesDetails(response, data.type);
            return;
          }

          if (data.type == "tree") {
            //idSP from server is actually idRegimeSP
            //assign idSP back to idRegimeSP, so app can use idRegimeSP throughout
            response.SpeciesForest[0].$.idRegimeSP =
              response.SpeciesForest[0].$.idSP;
            this.simulationService.treesService.applySpeciesFormData(
              response.SpeciesForest[0],
              true
            );
            speciesNameForLog = response.SpeciesForest[0].$.nmSP;
          } else {
            //idSP from server is actually idRegimeSP
            //assign idSP back to idRegimeSP, so app can use idRegimeSP throughout

            response.SpeciesAgriculture[0].$.idRegimeSP =
              response.SpeciesAgriculture[0].$.idSP;
            this.simulationService.cropsService.applySpeciesFormData(
              response.SpeciesAgriculture[0],
              true
            );

            speciesNameForLog = response.SpeciesAgriculture[0].$.nmSP;
          }

          this.setSpeciesDetails(response, data.type);

          const logArguments = [
            "Client received a %s species: '%s'",
            Utilities.capitaliseFirstLetter(data.type),
            speciesNameForLog,
          ];

          this.simulationService.logService.setLog(logArguments, "FormatStr");
        },
        (error) => {
          this.isLoading$.next(false);
        }
      );
  }

  public removeSelectedSpecies({ type, idRegimeSP, id }) {
    const service =
      type == "tree"
        ? this.simulationService.treesService
        : this.simulationService.cropsService;

    service.speciesButtonClicked({ event: "delete", idRegimeSP, id });
  }

  public setInitialSpecies(data: {
    type: "tree" | "crop";
    idRegimeSP: string;
    speciesName: string;
  }) {
    const speciesType: "tree" | "crop" = data.type;

    this.simulationService.initialConditionsService.setInitialConditionsData(
      data.type,
      data.idRegimeSP
    );

    const service =
      speciesType == "tree"
        ? this.simulationService.treesService
        : this.simulationService.cropsService;
    service.setSpeciesInUse();

    this.simulationService.logService.setLog(
      [
        `${Utilities.capitaliseFirstLetter(speciesType)} '${
          data.speciesName
        }' set as initial species`,
      ],
      "UserAction"
    );
  }

  public setSpeciesDetails(item, type) {
    const source =
      type == "tree"
        ? this.selectedTreeSpeciesSource$
        : this.selectedCropSpeciesSource$;

    source.next([...source.getValue(), item]);
  }

  // public getSpeciesDetailsByName(speceisName, type){
  //   const source =
  //   type == "tree"
  //     ? this.selectedTreeSpeciesSource$
  //     : this.selectedCropSpeciesSource$;

  //     const allSelectedSpecies =source.getValue();
  //     let speciesDetails: Species = allSelectedSpecies.find((s:Species)=>{
  //       const speciesType=type== "tree" ? "SpeciesForest" : "SpeciesAgriculture";
  //       return  s[speciesType][0].$.nmSP == speceisName
  //     })
  //    return speciesDetails
  // }

  public replaceSpeciesDetails(responseFromServer, type) {
    let item = responseFromServer;
    if (type == "tree") {
      item.SpeciesForest[0].$.idRegimeSP = item.SpeciesForest[0].$.idSP;
    } else {
      item.SpeciesAgriculture[0].$.idRegimeSP =
        item.SpeciesAgriculture[0].$.idSP;
    }

    const source =
      type == "tree"
        ? this.selectedTreeSpeciesSource$
        : this.selectedCropSpeciesSource$;

    let spieces: any[] = source.getValue().map((s: any) => {
      if (s.nmSP == item.nmSP) {
        return item;
      }
      return s;
    });
    source.next(spieces);
  }

  public getAllSelectedSpecies(type: "tree" | "crop") {
    return type == "tree"
      ? this.selectedTreeSpeciesSource$.getValue()
      : this.selectedCropSpeciesSource$.getValue();
  }

  public getSelectedSpecies(type): UntypedFormArray {
    const service =
      type == "tree"
        ? this.simulationService.treesService
        : this.simulationService.cropsService;
    return service.getSpecies();
  }

  public getInitialSpeciesId(type): BehaviorSubject<string> {
    return this.simulationService.initialConditionsService.getInitialSpeciesId(
      type
    );
  }

  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    return { xPath: "//Build", id: null };
  }

  public readXmlObject(): void {
    this.getFormGroup();
    if (!this.simulationService.getPlotFileJson()) {
      return;
    }

    const template = this.simulationService.getPlotFileJson()["Build"][0];
    let dataBuilder = template.$;
    const treesService = this.simulationService.treesService;
    const cropsService = this.simulationService.cropsService;
    const trees = this.simulationService.getPlotFileJson()?.SpeciesForestSet[0];
    const crops =
      this.simulationService.getPlotFileJson()?.SpeciesAgricultureSet[0];

    if (trees.SpeciesForest?.length) {
      trees.SpeciesForest.forEach((sfFromPlot) => {
        let sf = sfFromPlot;
        //when a custom spices created the idRegimeSP is set to zero,
        //some plot files are corrupted that sets all idRegimeSP= 0;
        //in this case all idRegimeSP= 0 will be assigned to a random id
        if (sf.$.idRegimeSP == 0) {
          sf.$.idRegimeSP = Utilities.uuid();
        }

        treesService.applySpeciesFormData(sf);

        //after reading plot file idSP will be replace by the app, speciesDetails is variable from server and kept temporarily.
        //for speciesDetails, when downloaded from server idSP = idRegimeSP, after saved by the app, idRegimeSP = idSP
        //we need to reassign the idRegimeSP back to idSP to mock it as downloaded from Server when importing

        sf.$.idSP = sf.$.idRegimeSP;

        this.setSpeciesDetails({ SpeciesForest: [sf] }, "tree");
      });
      treesService
        .getFormGroup()
        .get("showOnlyInUse")
        .setValue(trees?.$?.showOnlyInUse === "true");
    }

    if (crops.SpeciesAgriculture?.length) {
      crops.SpeciesAgriculture.forEach((saFromPlot) => {
        let sa = saFromPlot;
        //when a custom spices created the idRegimeSP is set to zero,
        //some plot files are corrupted that sets all idRegimeSP= 0;
        //in this case all idRegimeSP= 0 will be assigned to a random id

        if (sa.$.idRegimeSP == 0) {
          sa.$.idRegimeSP = Utilities.uuid();
        }

        cropsService.applySpeciesFormData(sa);

        //after reading plot file idSP will be replace by the app, speciesDetails is variable from server and kept temporarily.
        //for speciesDetails, when downloaded from server idSP = idRegimeSP, after saved by the app, idRegimeSP = idSP
        //we need to reassign the idRegimeSP back to idSP to mock it as downloaded from Server when importing
        sa.$.idSP = sa.$.idRegimeSP;
        this.setSpeciesDetails({ SpeciesAgriculture: [sa] }, "crop");
      });
      cropsService
        .getFormGroup()
        .get("showOnlyInUse")
        .setValue(crops?.$?.showOnlyInUse === "true");
    }

    //backend code converts frCat from "" to "All", this is something we need to address
    //workaround to convert All back to "";
    if (dataBuilder?.frCat == "All") {
      dataBuilder.frCat = "";
    }

    this.applyXmlValuesToFormGroup(dataBuilder, this.formGroup);

    //apply TYF value
    treesService.getTYFCategory();
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();

    let dataBuilder = this.getFormValueObjectForXmlExport(null, formData);

    return { Build: [{ $: dataBuilder }] };
  }

  public reset(): void {
    this.spatialDataSource$.next(null);
    this.availableTreeSpecies$.next([]);
    this.availableCropSpecies$.next([]);
    this.selectedTreeSpeciesSource$.next([]);
    this.selectedCropSpeciesSource$.next([]);
    super.reset();
  }
}
