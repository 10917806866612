import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  UntypedFormArray,
  UntypedFormGroup,
} from "@angular/forms";
import {} from "lodash";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil, startWith } from "rxjs/operators";
import { Species } from "../../models";

interface ButtonEventEmitter {
  event: "new" | "clone" | "delete" | "usage";
  data?: any;
  id?: number;
  idRegimeSP?: number;
}

@Component({
  selector: "fc-species-selector",
  templateUrl: "./species-selector.component.html",
  styleUrls: ["./species-selector.component.scss"],
})
export class SpeciesSelectorComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  @Input() selectOnly: boolean = false; // show the select element only, a "select one" placeholder will be added too

  @Input() formGroupInstance: UntypedFormGroup;

  @Input() selectedId$: BehaviorSubject<number>;

  @Input() speciesInUse$: BehaviorSubject<string[]>;

  @Input() type: "tree" | "crop";

  @Output() buttonClicked = new EventEmitter<ButtonEventEmitter>();

  @Output() speciesSelected = new EventEmitter<{
    id: number | string;
    type: "tree" | "crop";
    name: string;
  }>();

  public emptyFormGroupPlaceholder: UntypedFormGroup = new UntypedFormGroup({}); //used by initial condidtions when no species selected

  public filteredSpecies: AbstractControl[]; //todo

  ngOnInit(): void {
    this.getFilteredSpecies();
  }

  protected getFilteredSpecies() {
    const showOnlyInUseControl = this.formGroupInstance.get("showOnlyInUse");

    //only tree and crop services will not have selectOnly
    if (showOnlyInUseControl !== null && !this.selectOnly) {
      //disable if there is species in use
      this.speciesInUse$.getValue().length
        ? showOnlyInUseControl.enable()
        : showOnlyInUseControl.disable();
      if (!this.speciesInUse$.getValue().length) {
        showOnlyInUseControl.setValue(false);
        this.filteredSpecies = (
          this.formGroupInstance.get("species") as FormArray
        ).controls;
        return;
      }

      showOnlyInUseControl.valueChanges
        .pipe(takeUntil(this.destroy$), startWith(showOnlyInUseControl.value))
        .subscribe((showOnlyInUse) => {
          if (showOnlyInUse) {
            this.filteredSpecies = (
              this.formGroupInstance.get("species") as FormArray
            ).controls.filter((sp) =>
              this.speciesInUse$.getValue().includes(sp.get("idRegimeSP").value)
            );
            this.reapplyFilteredSpecies();
          } else {
            this.filteredSpecies = (
              this.formGroupInstance.get("species") as FormArray
            ).controls;
          }
        });
    } else {
      this.filteredSpecies = (
        this.formGroupInstance.get("species") as FormArray
      ).controls;
    }
  }

  protected reapplyFilteredSpecies() {
    const currentSelectedId = this.selectedId$.getValue();

    const foundSpeciesIndex = this.filteredSpecies.findIndex(
      (s: any) => s.idRegimeSP == currentSelectedId
    );

    if (foundSpeciesIndex < 0) {
      setTimeout(() => {
        this.selectSpecies({ target: { selectedIndex: 0 } });
      }, 0);
    } else {
      const speciesFormGroup = this.findSpeciesByIdRegimeSP(currentSelectedId);

      if (speciesFormGroup) {
        const name = speciesFormGroup.get("nmSP")?.value;
        const idRegimeSP = speciesFormGroup.get("idRegimeSP")?.value;
        this.speciesSelected.emit({ id: idRegimeSP, type: this.type, name });
      }
    }
  }

  public selectSpecies(event): void {
    let selectedIndex = event.target["selectedIndex"];

    //placeholder will present when theres no species selected, therefore selected index will have to minors 1
    if (this.selectOnly && this.selectedId$.getValue() == -1) {
      //need to miners one as placeholder option added
      selectedIndex = selectedIndex - 1;
    }

    const selectedSpecies = this.filteredSpecies[selectedIndex];

    if (selectedSpecies) {
      const name = selectedSpecies.get("nmSP").value;
      const idRegimeSP = selectedSpecies.get("idRegimeSP")?.value;
      this.speciesSelected.emit({ id: idRegimeSP, type: this.type, name });
    }
  }

  public buttonClickedEvent(type: "clone" | "delete" | "new") {
    if (type == "new") {
      this.buttonClicked.emit({ event: "new" });
      this.formGroupInstance.get("showOnlyInUse").setValue(false);
      return;
    }

    const speciesFormGroup = this.findSpeciesByIdRegimeSP(
      this.selectedId$.getValue()
    ) as UntypedFormGroup;
    if (!speciesFormGroup) {
      return;
    }

    if (type == "clone") {
      this.buttonClicked.emit({
        event: type,
        data: speciesFormGroup.getRawValue(),
      });
    } else if (type == "delete") {
      this.buttonClicked.emit({
        event: type,
        id: this.selectedId$.getValue(),
        idRegimeSP: speciesFormGroup.get("idRegimeSP")?.value,
      });
    }
    if (!this.speciesInUse$.getValue().length) {
      this.formGroupInstance.get("showOnlyInUse").setValue(false);
      this.formGroupInstance.get("showOnlyInUse").disable();
    }
  }

  public getSelectedSpeciesFormGroup(
    selectedId
  ): UntypedFormGroup | AbstractControl {
    if (
      selectedId == null ||
      typeof selectedId == "undefined" ||
      selectedId == -1
    ) {
      return this.emptyFormGroupPlaceholder;
    }
    return this.findSpeciesByIdRegimeSP(selectedId);

    // if (!this.selectOnly && selectedId !== null && selectedId > -1) {
    //   return this.findSpeciesByDbId(selectedId);
    // } else if (this.selectOnly && selectedId > 0) {
    //   return this.findSpeciesByDbId(selectedId);
    // } else {
    //   return this.emptyFormGroupPlaceholder;
    // }
  }

  //use for validation in the service
  public isInvalid(): boolean {
    const speciesFormGroup = this.findSpeciesByIdRegimeSP(
      this.selectedId$.getValue()
    );

    return speciesFormGroup ? speciesFormGroup.invalid : false;
  }

  protected findSpeciesByIdRegimeSP(id): AbstractControl {
    const speciesFormArray = this.formGroupInstance.controls
      .species as UntypedFormArray;

    return speciesFormArray.controls.find(
      (s) => s.get("idRegimeSP").value == id
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
