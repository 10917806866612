import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { FormLayout, subGroupsEmitterObject } from "src/app/plot/models";
import { FormModel, SimulationService } from "src/app/shared/models";

@Component({
  selector: "fc-base-flow-component",
  templateUrl: "./base-flow.component.html",
  styleUrls: ["./base-flow.component.scss"],
})
export class BaseFlowComponent implements OnInit, OnDestroy {
  @Output() fieldChanged = new EventEmitter<any>();
  @Output() nextFlowClicked = new EventEmitter<any>();
  @Output() previousFlowClicked = new EventEmitter<any>();

  @Input() service: any;
  @Input() simulationService: SimulationService;

  @Input()
  public canNavigatePreviousFlow$: Observable<boolean>;

  @Input()
  public canNavigateNextFlow$: Observable<boolean>;

  public destroySubject$ = new Subject<void>();
  public formGroup: UntypedFormGroup;
  public formModel: FormModel;
  public layout: FormLayout;
  public modifiers: FormLayout;
  public selectedSubGroups: subGroupsEmitterObject = null;
  public nav: subGroupsEmitterObject[] = [];
  constructor() {}

  ngOnInit(): void {
    this.formGroup = this.service.getFormGroup();
    this.formModel = this.service.getFormModel();
    this.layout = this.service.layout;
    this.modifiers = this.service.modifiers;

    setTimeout(() => {
      this.service.watchFields(this.destroySubject$, {
        formGroup: this.formGroup,
        formModel: this.formModel,
        layout: this.layout,
        fieldChangedEmitter: this.fieldChanged,
      });
    }, 1);
  }

  public getBasePageTitle() {
    return this.simulationService.getBasePageTitle();
  }

  ngOnDestroy(): void {
    if (this.formGroup) {
      Object.keys(this.formGroup.controls).forEach((key) => {
        this.formGroup.get(key).markAsTouched();
      });
    }
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
