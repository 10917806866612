<button
  [id]="id"
  class="btn btn-sm"
  type="button"
  [ngClass]="getClassObject()"
  [disabled]="(isLoading$ | async) || isDisabled()"
  (click)="onClick.emit()"
>
  <i class="bi bi-check icon" aria-label="success" *ngIf="isSuccess"></i>
  <ng-container *ngIf="isLoading$ | async; else inputTextTemplate">
    <span
      *ngIf="isLoading$ | async"
      class="spinner spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <span> Loading...</span>
  </ng-container>
  <ng-template #inputTextTemplate>
    <ng-container *ngIf="isSuccess && successText; else label">
      {{ successText }}
    </ng-container>
    <ng-template #label> {{ inputText }} </ng-template>
  </ng-template>
</button>
