import { Injectable } from "@angular/core";
import { FormLayout } from "src/app/plot/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

import { FieldWatcher, FormModel } from "src/app/shared/models";

@Injectable({
  providedIn: "root",
})
export class ExternalCost2020Service extends BaseEventFormService {
  public readonly categoryIds = [91, 71];
  public readonly pageHelpLink = "";

  public layout: FormLayout = {
    label: "External cost",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Description",
            inputs: [
              {
                element: "input",
                type: "text",
                programmingName: "descXCst",
              },
            ],
          },
          {
            label: "One-time cost",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "oneTimeXCst",
              },
            ],
          },
          {
            label: "Fixed [$]",
            id: "fixXCst",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fixXCst",
              },
            ],
          },

          {
            id: "phaXCst",
            label: "Per hectare [$/ha]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "phaXCst",
              },
            ],
          },

          {
            label: "On-going cost (new level)",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "onGoingXCst",
              },
            ],
          },
          {
            id: "fixPyrXCst",
            label: "Fixed [$ per year]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fixPyrXCst",
              },
            ],
          },
          {
            id: "phaPyrXCst",
            label: "Per hectare [$/ha per year]",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "phaPyrXCst",
              },
            ],
          },
          {
            id: "codeXCst",
            label: "Code (to identify the type of cost to future cost events)",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "codeXCst",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
    },
    tEvent: {
      defaultValue: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
    },
    descXCst: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 3,
      helpId: 259,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Description of external cost",
      unit: "",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    oneTimeXCst: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 259,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "External cost has a one-time part",
      unit: "",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    onGoingXCst: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 259,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "External cost has a on-going part",
      unit: "",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    fixXCst: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 259,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Fixed portion of one-time external cost",
      unit: "$",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    phaXCst: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 259,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Per-hectare portion of one-time external cost",
      unit: "$/ha",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    fixPyrXCst: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 259,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Fixed portion of on-going external cost",
      unit: "$/yr",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    phaPyrXCst: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 259,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Per-hectare portion of on-going external cost",
      unit: "$/ha/yr",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
    codeXCst: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 3,
      helpId: 259,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Code for identifying an on-going external cost",
      unit: "",
      categoryName: "EvXCst",
      categoryLabel: "External cost event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
    },
  };

  public fieldWatcher: FieldWatcher = {
    oneTimeXCst: (newValue: any, { formGroup, formModel }) => {
      formModel["fixXCst"].isShown = newValue;
      formModel["phaXCst"].isShown = newValue;
    },
    onGoingXCst: (newValue: any, { formGroup, formModel }) => {
      formModel["fixPyrXCst"].isShown = newValue;
      formModel["phaPyrXCst"].isShown = newValue;
      formModel["codeXCst"].isShown = newValue;
    },
  };
}
