<legend>Instructions:</legend>
<ul>
  <li>
    <div class="label">Step 1:</div>
    <div class="modifier-value">Type in the project name</div>
  </li>
  <li>
    <div class="label">Step 2:</div>
    <div class="modifier-value">Type in the CEA name</div>
  </li>
  <li>
    <div class="label">Step 3:</div>
    <div class="modifier-value">
      Type in the latitude and longitude of your modelling point, then press
      'Download Site Information'
    </div>
  </li>
  <li>
    <div class="label">Step 4:</div>
    <div class="modifier-value">Type in CEA area in hectares</div>
  </li>

  <li>
    <div class="label">Step 5:</div>
    <div class="modifier-value">
      Go to the 'Management' tab to define the management regime
    </div>
  </li>
</ul>
<!--base info-->
<div
  fc-form-group-element
  class="sub-groups"
  [formGroupInstance]="simulationService.projectService.getFormGroup()"
  [service]="simulationService.projectService"
  [simulationService]="simulationService"
  [group]="group"
  [isModifiersCol]="true"
  *ngFor="let group of simulationService.projectService.modifiers"
></div>

<ng-container *ngIf="service.pageId == 'management'">
  <div
    fc-form-group-element
    class="sub-groups"
    [formGroupInstance]="simulationService.managementService.getFormGroup()"
    [service]="simulationService.managementService"
    [simulationService]="simulationService"
    [group]="group"
    [isModifiersCol]="true"
    *ngFor="let group of simulationService.managementService.modifiers"
  ></div>
</ng-container>
