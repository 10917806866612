<div class="modal-body text-center">
  <div class="message" [innerHTML]="message"></div>
  <div class="inline-button-group">
    <button
      *ngIf="noActions; else actionButtons"
      type="button"
      class="btn btn-primary"
      (click)="confirm(null)"
    >
      OK
    </button>
  </div>
</div>

<ng-template #actionButtons>
  <button
    id="model-confirm-yes"
    type="button"
    class="btn btn-light"
    (click)="confirm(true)"
  >
    Yes
  </button>
  <button
    id="model-confirm-no"
    type="button"
    class="btn btn-primary"
    (click)="confirm(false)"
  >
    No
  </button>
</ng-template>
