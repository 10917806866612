import { Injectable } from "@angular/core";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { Constants } from "src/app/shared/constants";
import { FormModel } from "src/app/shared/models";
import { BaseEventFormService } from "src/app/shared/services/base-event-form.service";

const percentageCropGroups: FormGroupElement[] = [
  {
    label: "Crop",
    isShown: true,
    isAccordion: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",

            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Destination percentages in the affected portion - Crop",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To Debris"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGbfrToAtmsFirA",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracGbfrToGlitFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalks",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStlkToAtmsFirA",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracStlkToSlitFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaves",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToAtmsFirA",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracLeafToLlitFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse roots",
                      },
                      {
                        element: "text",
                        value: "",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracCortToCodrFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine roots",
                      },
                      {
                        element: "text",
                        value: "",
                      },

                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracFirtToFidrFirA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const decomposableDebrisGroups: FormGroupElement[] = [
  {
    label: "Decomposable debris",
    isAccordion: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Decomposable debris",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To inert soil"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDGlitToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDGlitToInrtFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDSlitToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDSlitToInrtFirA",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDLlitToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDLlitToInrtFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDCodrToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDCodrToInrtFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDFidrToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDFidrToInrtFirA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

const resistantDebrisGroups: FormGroupElement[] = [
  {
    label: "Resistant debris",
    isAccordion: true,
    isShown: true,
    items: [
      {
        label: null,
        inputs: [
          {
            element: "component",
            programmingName: "project",
            component: "FormItemTableComponent",
            componentInputs: [
              {
                inputKey: "caption",
                value: "Decomposable debris",
              },
              {
                inputKey: "formGroupInstance",
                method: "getFormGroup",
              },
              {
                inputKey: "service",
                method: "getService",
              },
              {
                inputKey: "data",
                value: {
                  headers: ["", "To Atmos.", "To inert soil"],
                  rows: [
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "GBF litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRGlitToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRGlitToInrtFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Stalk litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRSlitToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRSlitToInrtFirA",
                      },
                    ],

                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Leaf litter",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRLlitToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRLlitToInrtFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Coarse dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRCodrToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracRCodrToInrtFirA",
                      },
                    ],
                    [
                      {
                        element: "text",
                        isHeader: "true",
                        value: "Fine dead roots",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDFidrToAtmsFirA",
                      },
                      {
                        element: "input",
                        type: "number",
                        programmingName: "fracDFidrToInrtFirA",
                      },
                    ],
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class AgriculturalFire2020Service extends BaseEventFormService {
  public readonly categoryIds = [77, 71];
  public readonly pageHelpLink = "149_Agricultural%20Fire.htm";

  public layout: FormLayout = {
    label: "Agricultural fire",
    groups: [
      {
        label: "Affected portion",
        isShown: true,
        isRoot: true,
        items: [
          {
            label: "Percentage of forest affected by fire",
            inputs: [
              {
                element: "input",
                type: "number",
                programmingName: "fracAfctFirA",
              },
            ],
          },
        ],
      },

      {
        label: "Destination percentages in the affected portion",
        isShown: true,
        isAccordion: true,
        isRoot: true,
        items: [
          {
            groups: [
              ...percentageCropGroups,
              ...decomposableDebrisGroups,
              ...resistantDebrisGroups,
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    idSP: {
      defaultValue: null,
      suffixId: null,
    },
    tEvent: {
      defaultValue: "SpecA",
      suffixId: null,
    },
    clearEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      defaultValue: false,
      suffixId: null,
    },
    eventId: {
      defaultValue: null,
      suffixId: null,
    },
    fracDAmmNToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in ammonium in the deep soil",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 227,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracDNtrNToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in nitrate in the deep soil",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 228,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracAfctFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      label: "agricultural system affected by fire",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 730,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    fracGbfrToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 731,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracStlkToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 732,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracLeafToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 733,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracGbfrToGlitFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "grains-buds-fruit to litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 734,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 109,
    },
    fracStlkToSlitFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "stalks to litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 735,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 109,
    },
    fracLeafToLlitFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "leaves to litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 736,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 109,
    },
    fracCortToCodrFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "coarse roots to dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 737,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 109,
    },
    fracFirtToFidrFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "fine roots to dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 738,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 109,
    },
    fracDGlitToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable grains-buds-fruit litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 739,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracRGlitToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant grains-buds-fruit litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 740,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracDSlitToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable stalk litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 741,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracRSlitToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant stalk litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 742,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracDLlitToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable leaf litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 743,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracRLlitToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant leaf litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 744,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracDCodrToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decompos coarse dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 745,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracRCodrToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resist coarse dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 746,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracDFidrToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decompos. fine dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 747,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracRFidrToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resist. fine dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 748,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracDGlitToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable grains-buds-fruit litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 749,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracRGlitToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant grains-buds-fruit litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 750,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracDSlitToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable stalk litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 751,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracRSlitToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant stalk litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 752,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracDLlitToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable leaf litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 753,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracRLlitToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant leaf litter",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 754,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracDCodrToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decompos coarse dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 755,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracRCodrToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resist coarse dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 756,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracDFidrToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "decomposable fine dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 757,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracRFidrToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0,
        },
        {
          functionName: "max",
          input: 100,
        },
      ],
      label: "resistant fine dead roots",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 758,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 110,
    },
    fracSopmToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 759,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracLrpmToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 760,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMrpmToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 761,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracSommToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 762,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracLrmmToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 763,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMrmmToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 764,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMicrToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 765,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMnamNToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "mineral nitrogen",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 766,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracSopmToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble plant mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 767,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracLrpmToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 768,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMrpmToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant plant mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 769,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracSommToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "soluble microbial mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 770,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracLrmmToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "less-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 771,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMrmmToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "more-resistant microbial mulch",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 772,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracMicrToInrtFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "live microbes",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 773,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracSNtrNToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in nitrate in the shallow soil",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 1099,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fracSAmmNToAtmsFirA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "nitrogen in ammonium in the shallow soil",
      unit: "%",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 120,
      isRisk: true,
      scale: 100,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 1100,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 108,
    },
    fixFirA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural fire",
      unit: "$",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 170,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 2060,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    phaFirA: {
      high: 1e36,
      low: -1e36,
      isOneMore: false,
      dataType: 0,
      helpId: 149,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "agricultural fire",
      unit: "$/ha",
      categoryName: "EvFirA",
      categoryLabel: "Agricultural fire event",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 171,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 2,
      tIn: 2061,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    tAaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of agricultural autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "AgAutoQT",
      eventId: 11,
      tIn: 522,
      spec: 0,
      std: 2,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqEnYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "End year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 586,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    onEV: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event on/off",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 11,
      tIn: 608,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: "FirA",
      validators: [],
      validatorConfig: [],
      label: "Type of event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "EventT",
      eventId: 11,
      tIn: 609,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateOriginEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 143,
      defaultValue: "Calendar",
      validators: [],
      validatorConfig: [],
      label:
        "Type of event date specification (calendar or since start of simulation)",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TimeOriginT",
      eventId: 11,
      tIn: 610,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    dateEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 9,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified as a calendar date",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 80,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 611,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nDaysFromStEV: {
      high: 3649635,
      low: -364635,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Date of event,specified in days since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 612,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nmEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Event name",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 613,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    labelEV: {
      high: 31,
      low: 1,
      isOneMore: false,
      dataType: 3,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event label",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1148,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    tFaqEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Type of forest autoqueue event",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "FrAutoQT",
      eventId: 11,
      tIn: 1186,
      spec: 0,
      std: 1,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    aqStYrEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Start year for applicability in autoqueue",
      unit: "yr",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1189,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    notesEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 4,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event notes",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 1288,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    nYrsFromStEV: {
      high: 9999,
      low: -999,
      isOneMore: false,
      dataType: 1,
      helpId: 143,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label:
        "Date of event,specified in calendar years since start of simulation",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 90,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: true,
      scale: 1,
      sortId: 0,
      isSpa: true,
      isSubSame: false,
      enumId: null,
      eventId: 11,
      tIn: 2104,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    categoryEV: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 137,
      defaultValue: null,
      validators: [],
      validatorConfig: [],
      label: "Event regime category",
      unit: "",
      categoryName: "EvAlle",
      categoryLabel: "All events",
      isShown: true,
      isExplorer: true,
      isFixedExplorerField: true,
      columnWidth: 65,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RgaCategoryT",
      eventId: 11,
      tIn: 2114,
      spec: 0,
      std: 3,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
  };

  // public fieldWatcher: FieldWatcher = {
  //   fracAfctFirA: (newValue: any, { formGroup, formModel }) => {
  //     formGroup.get("clearEV").setValue(newValue >= 100);
  //   },
  // };

  private isClearingEventFirA(formValue) {
    const k1Minus = Constants.k1Minus * 100;
    return (
      +formValue.fracAfctFirA >= k1Minus &&
      +formValue.fracGbfrToAtmsFirA + +formValue.fracGbfrToGlitFirA >=
        k1Minus &&
      +formValue.fracStlkToAtmsFirA + +formValue.fracStlkToSlitFirA >=
        k1Minus &&
      +formValue.fracLeafToAtmsFirA + +formValue.fracLeafToLlitFirA >=
        k1Minus &&
      +formValue.fracCortToCodrFirA >= k1Minus &&
      +formValue.fracFirtToFidrFirA >= k1Minus
    );
  }

  public modifyBeforeSave(formGroup) {
    if (!formGroup) {
      return null;
    }

    let modifiedFormGroup = formGroup;
    if (modifiedFormGroup.get("clearEV")) {
      modifiedFormGroup
        .get("clearEV")
        .setValue(this.isClearingEventFirA(modifiedFormGroup.getRawValue()));
    }
    return modifiedFormGroup;
  }

  public writeXmlObject(formData): object {
    const baseFirFFields = [
      "fracAfctFirA",
      "fracGbfrToAtmsFirA",
      "fracStlkToAtmsFirA",
      "fracLeafToAtmsFirA",
      "fracGbfrToGlitFirA",
      "fracStlkToSlitFirA",
      "fracLeafToLlitFirA",
      "fracCortToCodrFirA",
      "fracFirtToFidrFirA",
      "fracDGlitToAtmsFirA",
      "fracRGlitToAtmsFirA",
      "fracDSlitToAtmsFirA",
      "fracRSlitToAtmsFirA",
      "fracDLlitToAtmsFirA",
      "fracRLlitToAtmsFirA",
      "fracDCodrToAtmsFirA",
      "fracRCodrToAtmsFirA",
      "fracDFidrToAtmsFirA",
      "fracRFidrToAtmsFirA",
      "fracDGlitToInrtFirA",
      "fracRGlitToInrtFirA",
      "fracDSlitToInrtFirA",
      "fracRSlitToInrtFirA",
      "fracDLlitToInrtFirA",
      "fracRLlitToInrtFirA",
      "fracDCodrToInrtFirA",
      "fracRCodrToInrtFirA",
      "fracDFidrToInrtFirA",
      "fracRFidrToInrtFirA",
      "fracSopmToAtmsFirA",
      "fracLrpmToAtmsFirA",
      "fracMrpmToAtmsFirA",
      "fracSommToAtmsFirA",
      "fracLrmmToAtmsFirA",
      "fracMrmmToAtmsFirA",
      "fracMicrToAtmsFirA",
      "fracSopmToInrtFirA",
      "fracLrpmToInrtFirA",
      "fracMrpmToInrtFirA",
      "fracSommToInrtFirA",
      "fracLrmmToInrtFirA",
      "fracMrmmToInrtFirA",
      "fracMicrToInrtFirA",
      "fracMnamNToAtmsFirA",
      "fracSAmmNToAtmsFirA",
      "fracSNtrNToAtmsFirA",
      "fracDAmmNToAtmsFirA",
      "fracDNtrNToAtmsFirA",
      "fixFirA",
      "phaFirA",
    ];
    const xmlObject = {
      $: {},
      notesEV: [],
      FirA: [{ $: {} }],
    };

    const baseEventFields = this.getBaseEventFieldsForXmlExport();
    xmlObject.$ = this.getFormValueObjectForXmlExport(
      baseEventFields,
      formData
    );

    xmlObject.FirA[0].$ = this.getFormValueObjectForXmlExport(
      baseFirFFields,
      formData
    );

    xmlObject.notesEV = [formData.notesEV];
    return xmlObject;
  }
}
