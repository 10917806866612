import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { Constants } from "../../constants";
import {
  EventsService,
  SimulationService,
  SpeciesEvent,
  SpeciesEventType,
  SpeciesEventTypeMap,
  SpeciesType,
} from "../../models";
import { AgriculturalFire2020Service } from "./agricultural-fire/agricultural-fire-2020.service";
import { AgriculturalHerbiciding2020Service } from "./agricultural-herbiciding/agricultural-herbiciding-2020.service";
import { AgriculturalIrrigationChange2020Service } from "./agricultural-irrigation-change/agricultural-irrigation-change-2020.service";
import { AgriculturalManureFromOffsite2020Service } from "./agricultural-manure-from-offsite/agricultural-manure-from-offsite-2020.service";
import { AgriculturalPlowing2020Service } from "./agricultural-plowing/agricultural-plowing-2020.service";
import { ChopperRoller2020Service } from "./chopper-roller/chopper-roller-2020.service";
import { CropHarvest2020Service } from "./crop-harvest/crop-harvest-2020.service";
import { CropPlanting2020Service } from "./crop-planting/crop-planting-2020.service";
import { ExternalCost2020Service } from "./external-cost/external-cost-2020.service";
import { ExternalIncome2020Service } from "./external-income/external-income-2020.service";
import { ForestFire2020Service } from "./forest-fire/forest-fire-2020.service";
import { ForestIrrigationChange2020Service } from "./forest-irrigation-change/forest-irrigation-change-2020.service";
import { ForestManureFromOffsite2020Service } from "./forest-manure-from-offsite/forest-manure-from-offsite-2020.service";
import { ForestPercentageChange2020Service } from "./forest-percentage-change/forest-percentage-change-2020.service";
import { ForestTreatment2020Service } from "./forest-treatment/forest-treatment-2020.service";
import { GrazingChange2020Service } from "./grazing-change/grazing-change-2020.service";
import { TermiteChange2020Service } from "./termite-change/termite-change-2020.service";
import { TreePlanting2020Service } from "./tree-planting/tree-planting-2020.service";
import { ForestThinning2020Service } from "./forest-thinning/forest-thinning-2020.service";

@Injectable({
  providedIn: "root",
})
export class EventForms2020Service {
  public version = "2020";

  // constructor() {
  //   console.log(this.simulationService, "IN EVENT FORMS SERVICE");
  //   // Object.values(this.getAvailableEventTypes()).forEach((eventType) => {
  //   //   eventType.service.setSimulationService(this.simulationService);
  //   //   console.log(eventType.label, "setSimulationService");
  //   // });
  // }

  public eventsService: EventsService;
  public simulationService: SimulationService;

  public forestEventTypeMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    PlnF: {
      label: "Tree planting",
      service: TreePlanting2020Service,
      index: 6,
      type: "tree",
    },
    Thin: {
      label: "Forest thinning",
      service: ForestThinning2020Service,
      index: 0,
      type: "tree",
    },
    FirF: {
      label: "Forest fire",
      service: ForestFire2020Service,
      index: 1,
      type: "tree",
    },
    TmtF: {
      label: "Forest treatment",
      service: ForestTreatment2020Service,
      index: 12,
      type: "tree",
    },
    Chop: {
      label: "Chopper roller",
      service: ChopperRoller2020Service,
      index: 13,
      type: "tree",
    },
    Term: {
      label: "Termite change",
      service: TermiteChange2020Service,
      index: 14,
      type: "tree",
    },

    // NFeF: {
    //   label: "Forest N fertilizer application",
    //   formGroup: this.forestNFertilizerApplication,
    //   categoryIds: [87],
    //index:17
    // },
  };

  public readonly agriculturalEventTypeMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    PlnA: {
      label: "Crop planting",
      service: CropPlanting2020Service,

      index: 7,
      type: "crop",
    },
    Harv: {
      label: "Crop harvest",
      service: CropHarvest2020Service,

      index: 3,
      type: "crop",
    },
    FirA: {
      label: "Agricultural Fire",
      service: AgriculturalFire2020Service,
      index: 2,
      type: "crop",
    },

    Plow: {
      label: "Agricultural plowing",
      service: AgriculturalPlowing2020Service,

      index: 4,
      type: "crop",
    },
    Herb: {
      label: "Agricultural herbiciding",
      service: AgriculturalHerbiciding2020Service,

      index: 5,
      type: "crop",
    },

    Graz: {
      label: "Grazing change",
      service: GrazingChange2020Service,

      index: 8,
      type: "crop",
    },

    // NFeA: {
    //   label: "Agricultural N fertilizer application",
    //   formGroup: null,
    //   categoryIds: [88],
    //index:18
    // },
  };

  public readonly mixedEventTypeMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    // NotE: {
    //   label: "Not Event",
    //   formGroup: null,
    //   categoryIds: [],
    //index:10
    // },
    IrrF: {
      label: "Forest irrigation change",
      service: ForestIrrigationChange2020Service,
      index: 15,
      type: "mixed",
    },
    ManF: {
      label: "Forest manure from offsite",
      service: ForestManureFromOffsite2020Service,
      index: 19,
      type: "mixed",
    },
    IrrA: {
      label: "Agricultural irrigation change",
      service: AgriculturalIrrigationChange2020Service,
      index: 16,
      type: "mixed",
    },
    ManA: {
      label: "Agricultural manure from offsite",
      service: AgriculturalManureFromOffsite2020Service,
      index: 20,
      type: "mixed",
    },
    FFrc: {
      label: "Forest-Percentage change",
      service: ForestPercentageChange2020Service,
      index: 9,
      type: "mixed",
    },
    XCst: {
      label: "External cost",
      service: ExternalCost2020Service,
      index: 21,
      type: "mixed",
    },
    XInc: {
      label: "External income",
      service: ExternalIncome2020Service,
      index: 22,
      type: "mixed",
    },
  };

  public speciesEventMap: {
    [key: string]: SpeciesEventTypeMap;
  } = {
    ...this.forestEventTypeMap,
    ...this.agriculturalEventTypeMap,
    ...this.mixedEventTypeMap,
  };

  public getEventService(eventType: string): any {
    if (this.speciesEventMap[eventType]) {
      return new this.speciesEventMap[eventType].service();
    }
    return null;
  }

  public getAvailableEventTypes(type?: "tree" | "crop"): {
    [key: string]: SpeciesEventTypeMap;
  } {
    if (!type) {
      return {
        ...this.getForestEventTypes(),
        ...this.getAgriculturalEventTypes(),
      };
    }

    if (type == "tree") {
      return this.getForestEventTypes();
    } else {
      return this.getAgriculturalEventTypes();
    }
  }

  public getAgriculturalEventTypes(): {
    [key: string]: SpeciesEventTypeMap;
  } {
    const configurationFormGroup = this.getConfigurationFormGroup();
    const isEcon = configurationFormGroup.get("userEcon")?.value;
    const useEventIrrigation =
      configurationFormGroup.get("userEventIrrF")?.value;
    const useEventManure = configurationFormGroup.get("userEventManF")?.value;
    let agriculturalEventTypeMap = this.agriculturalEventTypeMap;

    agriculturalEventTypeMap = {
      ...agriculturalEventTypeMap,
      ...(useEventIrrigation && { IrrA: this.mixedEventTypeMap.IrrA }),
      ...(useEventManure && { ManA: this.mixedEventTypeMap.ManA }),
      ...(isEcon && {
        XCst: this.mixedEventTypeMap.XCst,
        XInc: this.mixedEventTypeMap.XInc,
      }),
    };
    return agriculturalEventTypeMap;
  }

  public getForestEventTypes(): {
    [key: string]: SpeciesEventTypeMap;
  } {
    const configurationFormGroup = this.getConfigurationFormGroup();
    const plotType = configurationFormGroup.get("tPlot")?.value;
    const isEcon = configurationFormGroup.get("userEcon")?.value;
    const useEventIrrigation =
      configurationFormGroup.get("userEventIrrF")?.value;
    const useEventManure = configurationFormGroup.get("userEventManF")?.value;
    let forestEventTypeMap = this.forestEventTypeMap;

    forestEventTypeMap = {
      ...forestEventTypeMap,
      ...(useEventIrrigation && { IrrF: this.mixedEventTypeMap.IrrF }),
      ...(useEventManure && { ManF: this.mixedEventTypeMap.ManF }),
      ...(plotType == "CompM" && { FFrc: this.mixedEventTypeMap.FFrc }),
      ...(isEcon && {
        XCst: this.mixedEventTypeMap.XCst,
        XInc: this.mixedEventTypeMap.XInc,
      }),
    };
    return forestEventTypeMap;
  }

  public getEventTypesByPlotType() {
    const configurationFormGroup = this.getConfigurationFormGroup();
    const plotType = configurationFormGroup.get("tPlot")?.value;

    if (plotType == "CompM") {
      return this.getAvailableEventTypes();
    }
    if (plotType == "CompF") {
      return this.getAvailableEventTypes("tree");
    }

    if (plotType == "CompA") {
      return this.getAvailableEventTypes("crop");
    }

    return {};
  }

  public getConfigurationFormGroup(): UntypedFormGroup {
    if (!this.simulationService) {
      return;
    }
    const configurationService = this.simulationService.configurationService;
    return configurationService.getFormGroup();
  }

  public setSimulationService(simulationService) {
    this.simulationService = simulationService;
  }

  public setEventsService(eventsService) {
    this.eventsService = eventsService;
  }

  public getEventFormGroup(eventType: string): UntypedFormGroup {
    const service: any = this.getEventService(eventType);
    if (service) {
      return service.getFormGroup() as UntypedFormGroup;
    }

    return null;
  }

  public getSpeciesTypeByEventType(eventType: SpeciesEventType): SpeciesType {
    if (this.getAgriculturalEventTypes()[eventType]) {
      return "crop";
    } else if (this.getForestEventTypes()[eventType]) {
      return "tree";
    }

    return null;
  }

  public getEventDescription(event: any): string {
    const eventName = event.tEV;
    if (this["desc" + eventName]) {
      return this["desc" + eventName](event);
    } else {
      return "N/A";
    }
  }

  protected descManF(event): string {
    const kin_manuCMManF = "1038";
    return this.descChangeLevel(
      "Forest manure from offsite",
      event.manuCMManF,
      kin_manuCMManF
    );
  }

  protected descIrrF(event): string {
    const kin_defnitIrrF = "1032";
    return this.descChangeLevel(
      "Forest irrigation",
      event.defnitIrrF,
      kin_defnitIrrF
    );
  }

  protected descFFrc(event): string {
    let desc = "Forest percentage -> ";
    switch (event.tFFrc) {
      case "FracConst":
        if (event.constFFrc == null) {
          desc += "constant (?)";
        } else {
          desc += this.formatNumberToFourDecimalPlaces(+event.constFFrc) + "%";
        }
        break;
      case "Frac3PG":
        desc += "3PG canopy percentage";
        break;
      case "FracTYF":
        desc += "determined by tree yield formula";
        break;
      case "FracAge":
        if (event.fracFFrc == null || event.yearFFrc == null) {
          desc += "rising (?)";
        } else {
          const value = this.formatNumberToFourDecimalPlaces(+event.fracFFrc);
          desc += `rises to ${value}% after ${event.yearFFrc} years`;
        }
        break;
      case "":
        desc += "?";
        break;
    }

    return desc;
  }

  protected descTerm(event): string {
    if (
      event.fracDDdwdFTerm == null ||
      event.fracDCodrFTerm == null ||
      event.fracRDdwdFTerm == null ||
      event.fracRCodrFTerm == null
    ) {
      return "Termites -> ?";
    } else {
      const value = this.formatNumberToFourDecimalPlaces(
        ((+event.fracDDdwdFTerm +
          +event.fracRDdwdFTerm +
          +event.fracDCodrFTerm +
          +event.fracRCodrFTerm) *
          25.0) /
          100
      );

      return `Termites -> ${value}% (avg)`;
    }
  }

  protected descChop(event): string {
    if (
      event.fracDDdwdFChop == null ||
      event.fracDBarkFChop == null ||
      event.fracRDdwdFChop == null ||
      event.fracRBarkFChop == null
    ) {
      return "Chopper roller -> ?";
    } else {
      return `Chopper roller -> ${this.formatNumberToFourDecimalPlaces(
        (+event.fracDDdwdFChop +
          +event.fracRDdwdFChop +
          +event.fracDBarkFChop +
          +event.fracRBarkFChop) *
          25.0
      )}% (avg)`;
    }
  }

  protected descTmtF(event): string {
    let desc = "Forest treatment";
    if (
      event.type1On &&
      event.ageAdvTmtF !== null &&
      event.advPeriodTmtF !== null
    ) {
      desc += `: +${this.formatNumberToFourDecimalPlaces(
        +event.ageAdvTmtF
      )} yrs over ${this.formatNumberToFourDecimalPlaces(
        +event.advPeriodTmtF
      )} yrs`;
    }

    if (event.type2On && event.yieldMultTmtF !== null) {
      desc += `: times ${this.formatNumberToFourDecimalPlaces(
        +event.yieldMultTmtF
      )} `;
    }

    return desc;
  }

  protected descFirF(event): string {
    return this.descAffectedPortion(
      "Forest fire",
      event.clearEV == true,
      event.fracAfctFirF
    );
  }

  protected descPlnF(event): string {
    return `Plant trees: ${event.treeNmPlnF}`;
  }

  /**Event.cpp - 1726**/
  protected descThin(event): string {
    let desc = "Thin";

    if (event.clearEV == true) {
      desc = desc + " (clearing)";
    } else if (event.fracAfctThin) {
      desc = `${desc} on ${this.formatNumberToFourDecimalPlaces(
        +event.fracAfctThin
      )}%`;
      let fracStemsLost = this.fracStemsLost(event);
      if (fracStemsLost !== null) {
        desc = `${desc} (removes ${this.formatNumberToFourDecimalPlaces(
          +fracStemsLost
        )}% of stems)`;
      }
    }
    return desc;
  }

  protected descNFeF(event): string {
    // - Only describes the simNF case (ie may give wrong number if Gendec only. Would need config.
    const configFormGroup = this.getConfigurationFormGroup();
    const simNF = configFormGroup.get("simNF").value;

    const totalNM = simNF
      ? +event.sAmmNMNFeF +
        +event.sNtrNMNFeF +
        +event.dAmmNMNFeF +
        +event.dNtrNMNFeF
      : +event.mnamNMNFeF;
    return `Forest N-fertilizer, ${1000.0 * +totalNM} kgN/ha`;
  }

  /****crop description****/
  protected descPlnA(event): string {
    return "Plant crop: " + event.cropNmPlnA;
  }

  protected descHarv(event): string {
    let desc = "Harvest ";
    if (event.clearEV == true) {
      desc += "(clearing)";
    } else if (event.fracAfctHarv !== null) {
      desc += `on ${this.formatNumberToFourDecimalPlaces(event.fracAfctHarv)}%`;
      const fracStlksLost = this.fracStlksLost(event);
      if (fracStlksLost) {
        desc += ` (removes ${this.formatNumberToFourDecimalPlaces(
          +fracStlksLost / 100
        )}% of stems)`;
      }
    }
    return desc;
  }

  protected descFirA(event): string {
    return this.descAffectedPortion(
      "Agricultural fire",
      event.clearEV == true,
      event.fracAfctFirA
    );
  }

  protected descPlow(event): string {
    return this.descAffectedPortion(
      "Plow",
      event.clearEV == true,
      event.fracAfctPlow
    );
  }

  protected descHerb(event): string {
    return this.descAffectedPortion(
      "Herbicide",
      event.clearEV == true,
      event.fracAfctHerb
    );
  }

  protected descGraz(event): string {
    let desc = "";
    if (event.grazerType == "NonManaged") {
      desc = "Non managed Grazing -> ";
    } else {
      desc = "Grazing -> ";
    }

    if (event.cropGrazOn) {
      if (event.tGraz == "GrazByM") {
        const sum =
          (+event.gbfrMGraz || 0) +
          (+event.stlkMGraz || 0) +
          (+event.leafMGraz || 0);
        desc += `${sum.toFixed(3)} dmt/ha/day ${this.grazingComponents(
          event.gbfrMGraz,
          event.stlkMGraz,
          event.leafMGraz,
          false
        )}`;
      } else if (event.tGraz == "GrazByFrac") {
        desc += `%crop/day ${this.grazingComponents(
          String(+event.fracGbfrGraz / 100),
          String(+event.fracStlkGraz / 100),
          String(+event.fracLeafGraz / 100),
          true
        )}`;
      } else if (event.tGraz == "GrazByFracNPP") {
        desc += `%NPP/day ${this.grazingComponents(
          String(+event.fracGbfrNPPGraz / 100),
          String(+event.fracStlkNPPGraz / 100),
          String(+event.fracLeafNPPGraz / 100),
          true
        )}`;
      }
    }
    if (event.debrGrazOn) {
      if (event.cropGrazOn) {
        desc += ", ";
      }

      desc += `%debris/day ${this.grazingComponents(
        String(+event.fracGlitGraz / 100),
        String(+event.fracSlitGraz / 100),
        String(+event.fracLlitGraz / 100),
        true
      )}`;
    }
    if (!event.cropGrazOn && !event.debrGrazOn) {
      desc += "Off";
    }
    return desc;
  }

  protected descIrrA(event): string {
    const kin_defnitIrrA = "1034";

    return this.descChangeLevel(
      "Agricultural irrigation",
      event.defnitIrrA,
      kin_defnitIrrA
    );
  }

  protected descManA(event): string {
    const kin_manuCMManA = "1039";
    return this.descChangeLevel(
      "Agricultural manure from offsite",
      event.manuCMManA,
      kin_manuCMManA
    );
  }

  protected descNFeA(event): string {
    // - Only describes the simNF case (ie may give wrong number if Gendec only. Would need config.
    const configFormGroup = this.getConfigurationFormGroup();
    const simNA = configFormGroup.get("simNA").value;

    const totalNM = simNA
      ? +event.sAmmNMNFeA +
        +event.sNtrNMNFeA +
        +event.dAmmNMNFeA +
        +event.dNtrNMNFeA
      : +event.mnamNMNFeA;
    return `Agricultural N-fertilizer ${1000.0 * +totalNM} kgN/ha`;
  }

  /******other events******/

  protected descXCst(event): string {
    return "External cost: " + event.descXCst;
  }

  protected grazingComponents(
    gbf: string,
    stalks: string,
    leaves: string,
    pct: boolean
  ) {
    const pctStr = pct ? "%" : "";

    const pctMult = pct ? 100 : 1;

    const gbfStr = gbf == null ? 0 : +gbf * pctMult + pctStr;
    const stalksStr = stalks == null ? 0 : +stalks * pctMult + pctStr;
    const leavesStr = leaves == null ? 0 : +leaves * pctMult + pctStr;
    return `(${gbfStr} gbf, ${stalksStr} stalks, ${leavesStr} leaves)`;
  }

  protected descAffectedPortion(
    type: string,
    clearing: boolean,
    efct: string
  ): string {
    if (clearing) {
      return type + " (clearing)";
    } else if (efct == null) {
      return type;
    } else {
      //
      return `${type} on ${this.formatNumberToFourDecimalPlaces(+efct)}%`;
    }
  }

  protected fracStemsLost(event): number {
    if (!event.fracAfctThin) {
      return null;
    }

    let fracAfctStemsLost;
    //useLogGrades is only available in research edition
    if (this.useLogGrades(event)) {
      if (event.clearAllRemainsF == true) {
        fracAfctStemsLost = 1.0;
      } else {
        fracAfctStemsLost = 0.0;
        const kNLogGrades = 5;
        const fracGradeThin = event.fracGradeThin
          ? event.fracGradeThin.split(",")
          : [];
        for (let i = kNLogGrades - 1; i >= 0; --i)
          if (fracGradeThin && +fracGradeThin[i] > 0.0) {
            const x = this.fracAfctStemsLost(event);
            if (x === null) {
              return null;
            }

            fracAfctStemsLost += x * +fracGradeThin[i];
          }
      }
    } else {
      fracAfctStemsLost = this.fracAfctStemsLost(event);
      if (fracAfctStemsLost === null) {
        return null;
      }
    }
    return (+event.fracAfctThin * fracAfctStemsLost) / 100;
  }

  protected fracAfctStemsLost(thinDest) {
    //Fraction of stems in the affected portion of the forest that are lost during the thin
    if (
      thinDest.fracStemToDdwdThin == null ||
      thinDest.fracStemToFuelThin == null ||
      thinDest.fracStemToPaprThin == null ||
      thinDest.fracStemToPackThin == null ||
      thinDest.fracStemToFurnThin == null ||
      thinDest.fracStemToFibrThin == null ||
      thinDest.fracStemToConsThin == null ||
      thinDest.fracStemToResiThin == null ||
      thinDest.fracStemToSDdwdThin == null
    )
      return null;
    return (
      +thinDest.fracStemToDdwdThin +
      +thinDest.fracStemToFuelThin +
      +thinDest.fracStemToPaprThin +
      +thinDest.fracStemToPackThin +
      +thinDest.fracStemToFurnThin +
      +thinDest.fracStemToFibrThin +
      +thinDest.fracStemToConsThin +
      +thinDest.fracStemToResiThin +
      +thinDest.fracStemToSDdwdThin
    );
  }

  protected descChangeLevel(type: string, f: string, tIn: string): string {
    //to be improved
    let desc = "";

    if (f == null) {
      desc += type + " -> ?";
    } else {
      const allInputs = this.simulationService.plotRefDataService.allInputs;
      let isFloToUser = true;
      const info: any = Object.values(allInputs).find((inputs: any) =>
        inputs.find((i) => String(i.tInII) == tIn)
      );

      if (info) {
        isFloToUser = this.floToUser(f, info.scaleII, info.inverseII);
        desc = `${type} -> ${isFloToUser ? f : 0.0} ->${info.unitNmII}`;
      }
    }

    return desc;
  }

  protected floToUser(f: string, scale: string, inverse: boolean): boolean {
    // Returns true iff non-blank

    //to be improved
    if (f == null) {
      return false;
    }
    if (inverse) {
      if (+f < Constants.k0Plus) {
        +f == Constants.kfBig;
        return true;
      }

      return false;
      //    +f = 1.0 / +f;
    }
    // *f *= scale; <- not sure what it's going to do
    return true;
  }

  protected useLogGrades(event): boolean {
    const configFormGroup = this.getConfigurationFormGroup();
    const logGrade = configFormGroup.get("userLogGrade")?.value;
    const econ = configFormGroup.get("userEcon")?.value;

    return (logGrade && event.userThisThinByLG) || econ;
  }

  protected fracStlksLost(harvestEvent) {
    if (harvestEvent.fracAfctHarv == null) {
      return;
    }

    let fracAfctStlksLost;
    if (this.useCropGrades(harvestEvent)) {
      if (harvestEvent.clearAllRemainsA) fracAfctStlksLost = 1.0;
      else {
        //to be improved
        // const Spec* sp = d->specSetA->SpeciesFromNm(cropNmHarv.s);
        // if (sp == 0)
        //     return kfBlank;
        // const SpecZapA* spAz = &(sp->AsType<const SpecA>()->z);
        // fracAfctStlksLost = 0.0;
        // for (int i = kNCropGrades - 1; i >= 0; --i)
        //     if (event.fracGradeHarv[i] > 0.0)
        //     {
        //         flo x = spAz->harvDestSpA[i].FracAfctStlksLost();
        //         if (x == kfBlank)
        //             return kfBlank;
        //         fracAfctStlksLost += x * event.fracGradeHarv[i];
        //     }
      }
    } else {
      fracAfctStlksLost = this.fracAfctStlksLost(harvestEvent);
      if (!fracAfctStlksLost) {
        return;
      }
    }
    return harvestEvent.fracAfctHarv * fracAfctStlksLost;
  }
  protected fracAfctStlksLost(harvestEvent): number {
    // Fraction of stalks in the affected portion of the crop that are lost during the harvest
    if (
      harvestEvent.fracStlkToFualHarv == null ||
      harvestEvent.fracStlkToCaneHarv == null ||
      harvestEvent.fracStlkToHssiHarv == null ||
      harvestEvent.fracStlkToSlitHarv == null
    )
      return;
    return (
      +harvestEvent.fracStlkToFualHarv +
      +harvestEvent.fracStlkToCaneHarv +
      +harvestEvent.fracStlkToHssiHarv +
      +harvestEvent.fracStlkToSlitHarv
    );
  }

  useCropGrades(harvestEvent) {
    const configFormGroup =
      this.simulationService.configurationService.getFormGroup();
    return (
      (configFormGroup.get("userCropGrade").value &&
        harvestEvent.userThisHarvByCG) ||
      configFormGroup.get("userEcon").value
    );
  }

  speciesFromNm(specNm) {
    !specNm;
    return 0;
    // SpecKey srch;
    // srch.nmSP.WrapCT(specNm, strlen(specNm));

    // return find(srch);
  }

  public getEventTypes(type?: "tree" | "crop"): {
    [key: string]: SpeciesEventTypeMap;
  } {
    if (!type) {
      return {
        ...this.getForestEventTypes(),
        ...this.getAgriculturalEventTypes(),
      };
    }

    if (!type) {
      return {
        ...this.getForestEventTypes(),
        ...this.getAgriculturalEventTypes(),
      };
    }

    if (type == "tree") {
      return this.getForestEventTypes();
    } else {
      return this.getAgriculturalEventTypes();
    }
  }

  protected formatNumberToFourDecimalPlaces(n) {
    const formattedNumber = (+n).toFixed(4);
    return parseFloat(formattedNumber).toString();
  }
}
