export const environment = {
  production: false,
  baseUrl: "https://dev.fullcam.gov.au/api",
  plotApiUrl: "https://wa-asp-aue-npdsi-fullcam-portal.azurewebsites.net/api/fullcam2020-simulator",
  // baseUrl: "https://localhost:7207/api",
  // plotApiUrl: "https://localhost:7207/api/fullcam2020-simulator",
  rmtApiUrl: "http://localhost:25958/api/v1",
  fullcam2020HelpUrl:
    "https://dcceew.gov.au/themes/custom/awe/fullcam/Help-FullCAM2020",

  b2cPolicies: {
    clientId: "ee367aff-d63b-4771-b9bd-5f6adcb6daa6",
    names: {
      signUpSignIn: "B2C_1A_APO_SIGNUP_SIGNIN",
    },
    authorities: {
      signUpSignIn: {
        authority:
          "https://auth-dev.dcceew.gov.au/auth-dev.dcceew.gov.au/B2C_1A_APO_SIGNUP_SIGNIN",
      },
    },
    authorityDomain: "auth-dev.dcceew.gov.au",
    postLogoutRedirectUri: "https://dev.fullcam.gov.au",
    scopes: [
      //"https://auth-dev.dcceew.gov.au/45261b6f-f22a-444f-9321-1b4012a134e3/fullcam-external/authorised.access",
      "https://auth-dev.dcceew.gov.au/54991242-a609-4128-9730-aa0a0e487758/authorised.access",
    ],
    fullCAM_scope:
      // "https://authdev.awe.gov.au/45261b6f-f22a-444f-9321-1b4012a134e3/fullcam-external/authorised.access",
      "https://auth-dev.dcceew.gov.au/54991242-a609-4128-9730-aa0a0e487758/authorised.access",
  },
  gaTrackingId: "G-4TX4T69MMQ",
  appInsightsKey:
    // "InstrumentationKey=bc2b8850-979f-4290-a4a2-acfc66e1c1dd;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/",
    "InstrumentationKey=c8829fc5-64a7-4e9c-8ec6-fc32deee93f6;IngestionEndpoint=https://australiaeast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/;ApplicationId=2aad68b2-bd19-4ac4-b59f-ffc6af3ddfb5",
    //dataApiUrl: "https://localhost:7207/api",
  //plotApiUrl: "https://localhost:7207/api/fullcam2020-simulator",
};
