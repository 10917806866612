import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError, take, filter } from "rxjs/operators";
import { AuthService } from "src/app/auth/services/auth.service";
import { UserRole } from "src/app/user-role/role-access/model";

@Injectable({
  providedIn: "root",
})
export class FullcamVersionGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let fullcamVersion = route.data["version"];

    if (!fullcamVersion) {
      return of(true);
    }

    return this.authService.userRole$.pipe(
      filter((role: UserRole) => role !== null),
      map((role) => {
        switch (fullcamVersion) {
          case "2016":
            return role?.canAccessFullCam2016 === true;
          case "2020":
            return role?.canAccessFullCam2020 === true;
          case "2024":
            return role?.canAccessFullCam2024 === true;
          case "rmt":
            return true;
            return role?.canAccessRMT === true;
          default:
            this.router.navigate([""]);
            return false;
        }
      }),
      catchError((error) => {
        this.router.navigate([""]);
        return of(false);
      })
    );
  }
}
