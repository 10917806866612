import { Component, OnInit, AfterViewInit, TemplateRef } from "@angular/core";
import { DbService } from "./shared/services/db.service";
import { filter, take } from "rxjs/operators";
import { MessageService } from "./shared/services/message.service";
import { AuthService } from "./auth/services/auth.service";
import { LayoutService } from "./layout/layout.service";

import { interval, Observable, Subscription } from "rxjs";
import { RoleAccessResponse, UserRole } from "./user-role/role-access/model";
import { BsModalService, BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppService } from "./services/app.service";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";
import { AppInsightsService } from "./shared/services/app-insights.service";

@Component({
  selector: "fc-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  public isIframe = false;
  public userRole$: Observable<UserRole>;
  public userClaim: any;

  public showPrivacy = true;
  public isPrivacyAccepted = false;
  public isPrivacyRejected = false;
  public isUserBlocked = false;
  public showHeader = false;
  public chkPolicyTerms = false;
  privacyAccept = "false";
  public loginFirstTime: boolean = false;
  public countDownSec: number = 15;
  private subscription: Subscription;
  public activeAccount: any;
  modalRef?: BsModalRef;
  public errCheck = false;

  constructor(
    private dbService: DbService,
    public messageService: MessageService,
    private authService: AuthService,
    public layoutService: LayoutService,
    private modalService: BsModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private gaService: GoogleAnalyticsService,
    private appInsights: AppInsightsService
  ) {}
  ngOnInit() {
    this.gaService.initialise();
    //
    //feed the version back to app service,
    //so other pages can get the ringht simulation version
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let currentRoute = this.activatedRoute.root;
        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }
        const routeData = currentRoute.snapshot.data;

        const version = routeData?.version || null;
        const title = routeData?.title || null;
        if (version) {
          this.appService.setAppVersion(version);
        }

        try {
          if (title) {
            const url: string = event.urlAfterRedirects;
            const urlWithoutSlash = url.startsWith("/")
              ? url.substring(1)
              : url;
            this.appService.setPageTitle(
              this.appService.getPageTitlePrefix() + title || urlWithoutSlash
            );

            this.gaService.sendEvent({
              name: "page_view",
              category: "fcm-page-title",
              label: this.appService.getPageTitlePrefix() + title,
            });

            this.appInsights.logPageView({
              name:
                this.appService.getPageTitlePrefix() + title || urlWithoutSlash,
              url: url,
            });
          }
        } catch (error) {
          console.error("Unable to track sub-pages with Google Analytics.");
        }
      });

    this.userRole$ = this.authService.userRole$;
    this.isIframe = window !== window.parent && !window.opener;

    this.loginFirstTime = false;
    this.showPrivacy = false;
    this.isUserBlocked = false;

    this.activeAccount = this.authService.getActiveAccount();

    if (this.activeAccount !== null) {
      this.userClaim = this.activeAccount.idTokenClaims;
      this.authService.setUserClaim(this.userClaim);
    }

    this.userRole$.pipe(take(1)).subscribe((role) => {
      if (role) {
        this.assignUserRole(role);
      } else {
        this.FetchUserRoles();
      }
    });
  }

  ngAfterViewInit() {
    this.layoutService.watchLayout();
  }

  FetchUserRoles() {
    if (this.activeAccount != null) {
      this.authService
        .getUserRoles()
        .pipe(take(1))
        .subscribe((role: UserRole) => {
          this.assignUserRole(role);
        });
    } else {
      sessionStorage.clear();
      if (this.activeAccount == null) {
        this.reloadPage();
      }
    }
  }

  private assignUserRole(role: UserRole): void {
    if (role != null && role.privacyAccepted != null) {
      //this.userRole$ = role;
      if (role.status == "Blocked") {
        this.isUserBlocked = true;
        this.isPrivacyAccepted = true;
        this.loginFirstTime = false;
        this.showHeader = true;
        this.chkPolicyTerms = true;
        return;
      }
      this.showPrivacy = role.privacyAccepted == true ? false : true;
      if (role.privacyAccepted) {
        this.authService.setUserRoles(role);
        sessionStorage.setItem("userGuidId", role.userGuid);
        this.isPrivacyAccepted = true;
        this.chkPolicyTerms = true;
        this.loginFirstTime = false;
        this.showHeader = true;
      }
    } else {
      this.isPrivacyAccepted = false;
      this.chkPolicyTerms = true;
      this.loginFirstTime = false;
      this.showHeader = false;
    }
  }

  Accept() {
    if (this.chkPolicyTerms == false) {
      this.errCheck = true;
      return false;
    } else this.errCheck = false;
    this.dbService
      .addUser()
      .pipe()
      .subscribe((obj: RoleAccessResponse) => {
        if (obj != null) {
          this.FetchUserRoles();
        }
      });
  }

  Cancel() {
    this.isPrivacyAccepted = false;
    /* //DCCEEW Graph API access disabled on DCCEEW
    this.dbService
      .deleteUser(
        this.activeAccount.username,
        this.activeAccount.localAccountId
      )
      .pipe()
      .subscribe((obj: boolean) => {
        if (obj != null) {
        }
      });
      */
    this.showHeader = true;
    this.showPrivacy = false;
    this.loginFirstTime = false;
    this.isPrivacyAccepted = false;
    this.isPrivacyRejected = true;

    this.subscription = interval(1000).subscribe((x) => {
      this.countDownSec = this.countDownSec - 1;
    });
    //Signout automatically after 15 secs
    setTimeout(() => {
      this.authService.singOut();
    }, 15000);
  }

  openPolicyTerms(template: TemplateRef<any>) {
    const config: ModalOptions = { class: "modal-lg" };
    this.modalRef = this.modalService.show(template, config);
  }
  chkSelect() {
    if (this.chkPolicyTerms == true) this.errCheck = false;
    else this.errCheck = true;
  }
  reloadPage() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
