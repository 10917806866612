import { Injectable } from "@angular/core";
import { Constants } from "src/app/shared/constants";
import { FieldWatcher, FormModel } from "src/app/shared/models";
import { FormGroupElement, FormLayout, LogField } from "../../models";
import { BasePlotFormService } from "../../services/base-plot-form.service";
import {
  distinctUntilChanged,
  pairwise,
  startWith,
  take,
  takeUntil,
} from "rxjs";
import { ModalOptions } from "ngx-bootstrap/modal";
import { ModelsAndInputComponent } from "../components/models-and-input/models-and-input.component";
import { DiagramsComponent } from "../components/diagrans/diagrams.component";

const additionalGroups: FormGroupElement[] = [
  // {
  //   label: "Economic modelling",
  //   isShown: true,
  //   isAccordion: true,
  //   isExpanded: true,
  //   helpLink: "#agriculture",
  //   items: [
  //     {
  //       id: "userEcon",
  //       label: "Economic modelling",
  //       inputs: [
  //         {
  //           element: "input",
  //           type: "switch",
  //           programmingName: "userEcon",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    label: "Irrigation",
    isShown: true,
    isAccordion: true,
    isExpanded: false,
    items: [
      {
        id: "userEventIrrF",
        label: "Forest",
        inputs: [
          {
            element: "input",
            type: "radioSwitch",
            selectOptions: [
              { label: "Time series", value: false },
              { label: "Events", value: true },
            ],
            programmingName: "userEventIrrF",
          },
        ],
      },
      {
        id: "userEventIrrA",
        label: "Agricultural",
        inputs: [
          {
            element: "input",
            type: "radioSwitch",
            selectOptions: [
              { label: "Time series", value: false },
              { label: "Events", value: true },
            ],
            programmingName: "userEventIrrA",
          },
        ],
      },
    ],
  },
  {
    label: "Manure-From-Offsite Added to the Soil",
    isShown: true,
    isAccordion: true,
    isExpanded: false,
    items: [
      {
        id: "userEventManF",
        label: "Forest",
        inputs: [
          {
            element: "input",
            type: "radioSwitch",
            selectOptions: [
              { label: "Time series", value: false },
              { label: "Events", value: true },
            ],
            programmingName: "userEventManF",
          },
        ],
      },
      {
        id: "userEventManA",
        label: "Agricultural",
        inputs: [
          {
            element: "input",
            type: "radioSwitch",
            selectOptions: [
              { label: "Time series", value: false },
              { label: "Events", value: true },
            ],
            programmingName: "userEventManA",
          },
        ],
      },
    ],
  },
  {
    label: "RothC",
    isShown: true,
    isAccordion: true,
    isExpanded: false,
    items: [
      {
        id: "rothCVers",
        label: "Version",
        inputs: [
          {
            element: "select",
            selectOptions: [{ label: "26.3", value: "Vers263" }],
            programmingName: "rothCVers",
          },
        ],
      },
    ],
  },
  {
    label: "Tree production",
    isShown: true,
    isAccordion: true,
    isExpanded: false,
    helpLink: "108_Configure%20Tree%20Production.htm",
    items: [
      {
        id: "tTreeProd",
        label: "Method",
        inputs: [
          {
            element: "select",
            selectOptions: [
              { label: "Increments", value: "Incr" },
              { label: "Tree yield formula", value: "TYF" },
            ],
            programmingName: "tTreeProd",
          },
        ],
      },
    ],
  },

  {
    label: "About your configuration",
    isShown: true,
    isAccordion: true,
    isExpanded: false,
    items: [
      {
        id: "modelsAndInputs",
        label: "",
        inputs: [
          {
            showButtonMethod: "shouldEnableModelsAndDiagramsButton",
            buttonLabel: "View Models and Inputs",
            element: "button",
            submitMethod: "openModelsAndInputs",
          },
        ],
      },
      {
        id: "diagrams",
        label: "",
        inputs: [
          {
            showButtonMethod: "shouldEnableModelsAndDiagramsButton",
            buttonLabel: "View Diagrams",
            element: "button",
            submitMethod: "openDiagrams",
          },
        ],
      },
    ],
  },
];

@Injectable({
  providedIn: "root",
})
export class Configuration2020Service extends BasePlotFormService {
  public readonly pageId = "configuration";

  public readonly pageHelpLink = "150_Configuration.htm";

  public readonly categoryIds = [2];
  public CAMFor: boolean = false; //     CAMFor
  public PPPG: boolean = false; //     3PG (variable names can't start with a digit)
  public GendecF: boolean = false; //     Forest GENDEC
  public RothCF: boolean = false; //     Forest RothC
  public CAMAg: boolean = false; //     CAMAg
  public GendecA: boolean = false; //     Agricultural GENDEC
  public RothCA: boolean = false; //     Agricultural RothC
  public CAMX: boolean = false; //     CAMFor  || CAMAg
  public Gendec: boolean = false; //     GendecF || GendecA
  public RothC: boolean = false;

  public incrFOn: boolean = false;
  public tyfOn: boolean = false;
  public pppgL: boolean = false;
  public calcModTemp: boolean = false;
  public calcModASW: boolean = false;
  public calcModFrost: boolean = false;

  public RothC263: boolean = true;
  public RothC265: boolean = false;
  public simNA: boolean = false;

  public readonly noItemMessage =
    "There is no items with the selected plot type.";

  public layout: FormLayout = {
    label: "Configuration",
    groups: [
      {
        label: "Plot",
        isShown: true,
        isRoot: true,
        showErrors: true,
        helpLink: "6_Configure%20the%20Plot.htm",
        items: [
          {
            id: "tPlot",
            label: "Type",
            inputs: [
              {
                element: "select",
                selectOptions: [
                  { label: "Forest soil", value: "SoilF" },
                  { label: "Forest system", value: "CompF" },
                  { label: "Agricultural soil", value: "SoilA" },
                  { label: "Agricultural system", value: "CompA" },
                  {
                    label: "Mixed (forest and agricultural) system",
                    value: "CompM",
                  },
                ],
                programmingName: "tPlot",
              },
            ],
          },
          {
            id: "userSoilMnrl",
            label: "Include Soil and minerals",
            inputs: [
              {
                element: "input",
                type: "switch",
                programmingName: "userSoilMnrl",
              },
            ],
          },
        ],
      },
      {
        label: "Additional options",
        isRoot: true,
        isShown: true,
        isAccordion: true,
        isExpanded: false,
        helpLink: "138_Configure%20Other%20Options.htm",
        items: [
          {
            groups: additionalGroups,
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    tPlot: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 6,
      defaultValue: null,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
      ],
      label: "Type of plot",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "PlotT",
      eventId: 10,
      tIn: 576,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userSoilMnrl: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 128,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "Include soil and minerals",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: false,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 579,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 2,
    },
    userMulchF: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 6,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Include forest mulch",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 578,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 2,
    },
    userMulchA: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 6,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Include agricultural mulch",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 580,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 2,
    },
    tTreeProd: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 6,
      defaultValue: "TYF",
      validators: [],
      validatorConfig: [],
      label: "Type of tree production model",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isExplorer: true,
      isDigest: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "TreeProdT",
      eventId: 10,
      tIn: 577,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userCalcFPI: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 138,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Calculate the forest productivity index (FPI) input from scatch",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1203,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userCalcModTemp: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Calculate the temperature modifier (modTemp) from scratch",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1206,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userCalcModASW: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Calculate the soil water modifier (modASW) from scratch",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1207,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userCalcModFrost: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Calculate the frost modifier (modFrost) from scratch",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 590,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userN: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 128,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Nitrogen processing (on/off)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 505,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userDispEner: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 128,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Displacement of fossil fuels due to bioenergy (on/off)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 969,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userDispProd: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 128,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Displacement of fossil fuels due to product use (on/off)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "OnOffT",
      eventId: 10,
      tIn: 970,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userEventIrrF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 195,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "forest irrigation (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 1,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1022,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userEventIrrA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 195,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "agricultural irrigation (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 1,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1023,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    userEventNFeF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 195,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "forest N fertilizer (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 1,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1024,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userEventNFeA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 195,
      defaultValue: true,
      validators: [],
      validatorConfig: [],
      label: "agricultural N fertilizer (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 1,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1025,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    userEventManF: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 195,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "forest manure from offsite (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 1,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1026,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userEventManA: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 195,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "agricultural manure from offsite (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: false,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 1,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1027,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    rothCVers: {
      high: 0,
      low: 0,
      isOneMore: false,
      dataType: 5,
      helpId: 138,
      defaultValue: "Vers263",
      validators: [],
      validatorConfig: [],
      label: "RothC version",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "RothCVersT",
      eventId: 10,
      tIn: 581,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userSens: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Sensitivity analysis possible (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1135,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userOpti: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Optimization analysis possible (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1136,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userEcon: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Economic Modelling on (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: false,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1102,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 2,
      suffixId: 0,
    },
    userLogGrade: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Allow thin specification by log grade (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1493,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 0,
      suffixId: 0,
    },
    userCropGrade: {
      high: 1,
      low: 0,
      isOneMore: false,
      dataType: 2,
      helpId: 8,
      defaultValue: false,
      validators: [],
      validatorConfig: [],
      label: "Allow harvest specification by crop grade (yes/no)",
      unit: "",
      categoryName: "Config",
      categoryLabel: "Configuration",
      isShown: true,
      isDigest: true,
      isExplorer: true,
      columnWidth: 70,
      errorId: 0,
      isInverse: false,
      prefixId: 0,
      isRisk: false,
      scale: 1,
      sortId: 0,
      isSpa: false,
      isSubSame: false,
      enumId: "YesNoT",
      eventId: 10,
      tIn: 1734,
      spec: 0,
      std: 0,
      isValidTricky: false,
      systemType: 1,
      suffixId: 0,
    },
    modelsAndInputs: {
      //place holder to get rid of no items message
      isShown: true,
      defaultValue: null,
    },
    diagrams: {
      //place holder to get rid of no items message
      isShown: true,
      defaultValue: null,
    },
  };

  private readonly logFields: { [key: string]: LogField } = {
    tPlot: {
      logType: "ConfigChangeOldNew",
      getLogArguments: (oldValue, newValue, model, service) => {
        const programmingName = "tPlot";
        const oldDisplayValue =
          service.getInputDisplayValueByProgrammingNameAndValue(
            programmingName,
            oldValue
          );
        const newDisplayValue =
          service.getInputDisplayValueByProgrammingNameAndValue(
            programmingName,
            newValue
          );

        return [
          model.label,
          programmingName,
          oldDisplayValue || "",
          newDisplayValue || "",
        ];
      },
    },
    userSoilMnrl: {
      logType: "ConfigChangeInput",
      getLogArguments: (oldValue, newValue, model, service) => {
        const programmingName = "userSoilMnrl";
        const label = model.label + " in plot configuration";

        const newDisplayValue =
          service.getInputDisplayValueByProgrammingNameAndValue(
            programmingName,
            newValue
          );

        return [label, programmingName, newDisplayValue];
      },
    },
    tTreeProd: {
      logType: "ConfigChangeOldNew",
      getLogArguments: (oldValue, newValue, model, service) => {
        const programmingName = "tTreeProd";
        const oldDisplayValue =
          service.getInputDisplayValueByProgrammingNameAndValue(
            programmingName,
            oldValue
          );
        const newDisplayValue =
          service.getInputDisplayValueByProgrammingNameAndValue(
            programmingName,
            newValue
          );

        return [
          model.label,
          programmingName,
          oldDisplayValue || "",
          newDisplayValue || "",
        ];
      },
    },
  };

  public readonly fieldWatcher: FieldWatcher = {
    tPlot: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter, simulationService }
    ) => {
      const useSoilMineralFieldName = "userSoilMnrl";
      const plotType = formGroup.get("tPlot").value;
      const includeSoilAndMineralControl = formGroup.get(
        useSoilMineralFieldName
      );

      const showMixedSystemGroup =
        Constants.ALL_SYSTEM_PLOT_TYPES.includes(plotType);
      const showForestMixedSystemGroup =
        Constants.FOREST_SYSTEM_PLOT_TYPES.includes(plotType);
      const showAgriculturalMixedSystemGroup =
        Constants.AGRICULTURAL_SYSTEM_PLOT_TYPES.includes(plotType);

      const showSoilMineralControl = showMixedSystemGroup;

      formModel[useSoilMineralFieldName].isShown = showSoilMineralControl;
      const showForestControls =
        showForestMixedSystemGroup && showSoilMineralControl;
      const showAgriculturalControls =
        showAgriculturalMixedSystemGroup && showSoilMineralControl;
      const showTreeProductionControl = showForestMixedSystemGroup;
      const showRothC =
        ["SoilF", "SoilA"].includes(plotType) ||
        (showMixedSystemGroup && includeSoilAndMineralControl.value);

      formModel["rothCVers"].isShown = showRothC;
      formModel["userEventIrrF"].isShown = showForestControls;
      formModel["userEventIrrA"].isShown = showAgriculturalControls;
      formModel["userEventManF"].isShown = showForestControls;
      formModel["userEventManA"].isShown = showAgriculturalControls;
      formModel["tTreeProd"].isShown = showTreeProductionControl;
      fieldChangedEmitter.emit({
        flow: "configuration",
        tPlot: newValue,
      });
      //for outputs
      this.setVariables(newValue);
    },
    // userEcon: async (
    //   newValue: any,
    //   { formGroup, formModel, fieldChangedEmitter }
    // ) => {
    //   fieldChangedEmitter.emit({
    //     flow: "configuration",
    //     userEcon: newValue,
    //   });
    // },
    userSoilMnrl: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter }
    ) => {
      const plotType = formGroup.get("tPlot").value;
      const showForestControls =
        ["CompF", "CompM"].includes(plotType) && newValue;
      const showAgriculturalControls =
        ["CompA", "CompM"].includes(plotType) && newValue;

      formModel["userEventIrrF"].isShown = showForestControls;
      formModel["userEventIrrA"].isShown = showAgriculturalControls;
      formModel["userEventManF"].isShown = showForestControls;
      formModel["userEventManA"].isShown = showAgriculturalControls;
      formModel["rothCVers"].isShown = newValue;
      this.setVariables(plotType);
    },
    tTreeProd: (
      newValue: any,
      { formGroup, formModel, fieldChangedEmitter }
    ) => {
      const plotType = formGroup.get("tPlot").value;
      this.setVariables(plotType);
    },
  };

  public setVariables(plotType) {
    const treeProduction = this.getFormGroup().get("tTreeProd").value;
    const userSoilMnrl = this.getFormGroup().get("userSoilMnrl").value;

    //not existing in Standard versoin
    const userCalcFPI = this.getFormGroup().get("userCalcFPI")?.value
      ? true
      : false;
    const userCalcModASW = this.getFormGroup().get("userCalcModASW")?.value
      ? true
      : false;
    const userCalcModFrost = this.getFormGroup().get("userCalcModFrost")?.value
      ? true
      : false;

    const userMulchF = this.getFormGroup().get("userMulchF")?.value
      ? true
      : false;

    const userMulchA = this.getFormGroup().get("userMulchA")?.value
      ? true
      : false;

    switch (plotType) {
      case null:
        this.CAMFor = false;
        this.CAMAg = false;
        this.PPPG = false;
        this.GendecF = false;
        this.GendecA = false;
        this.RothCF = false;
        this.RothCA = false;
        break;
      case "TreeF":
        this.CAMFor = false;
        this.CAMAg = false;
        this.PPPG = true;
        this.GendecF = false;
        this.GendecA = false;
        this.RothCF = false;
        this.RothCA = false;
        break;
      case "MulcF":
        this.CAMFor = false;
        this.CAMAg = false;
        this.PPPG = false;
        this.GendecF = true;
        this.GendecA = false;
        this.RothCF = false;
        this.RothCA = false;
        break;
      case "SoilF":
        this.CAMFor = false;
        this.CAMAg = false;
        this.PPPG = false;
        this.GendecF = false;
        this.GendecA = false;
        this.RothCF = true;
        this.RothCA = false;
        break;
      case "CompF":
        this.CAMFor = true;
        this.CAMAg = false;
        this.PPPG = treeProduction == "3PG";
        this.GendecF = userMulchF;
        this.GendecA = false;
        this.RothCF = true;
        this.RothCA = false;
        break;
      case "MulcA":
        this.CAMFor = false;
        this.CAMAg = false;
        this.PPPG = false;
        this.GendecF = false;
        this.GendecA = true;
        this.RothCF = false;
        this.RothCA = false;
        break;
      case "SoilA":
        this.CAMFor = false;
        this.CAMAg = false;
        this.PPPG = false;
        this.GendecF = false;
        this.GendecA = false;
        this.RothCF = false;
        this.RothCA = true;
        break;
      case "CompA":
        this.CAMFor = false;
        this.CAMAg = true;
        this.PPPG = false;
        this.GendecF = false;
        this.GendecA = userMulchA;
        this.RothCF = false;
        this.RothCA = true;
        break;
      case "CompM":
        this.CAMFor = true;
        this.CAMAg = true;
        this.PPPG = treeProduction == "3PG";
        this.GendecF = userMulchF;
        this.GendecA = userMulchA;
        this.RothCF = true;
        this.RothCA = true;
        break;
    }
    // Omit soil
    if (
      !userSoilMnrl &&
      (plotType == "CompF" || plotType == "CompA" || plotType == "CompM")
    ) {
      this.PPPG = false;
      this.GendecF = false;
      this.GendecA = false;
      this.RothCF = false; // Thus this.CAMFor does not imply this.RothCF
      this.RothCA = false; // Thus this.CAMAg  does not imply this.RothCA
    }
    // Multi-models
    this.CAMX = this.CAMFor || this.CAMAg;
    this.Gendec = this.GendecF || this.GendecA;
    this.RothC = this.RothCF || this.RothCA;
    // Tree production
    this.incrFOn = this.CAMFor && !this.PPPG && treeProduction == "Incr";
    this.tyfOn = this.CAMFor && !this.PPPG && treeProduction == "TYF";
    this.pppgL = this.tyfOn && userCalcFPI;
    this.calcModTemp = plotType && userCalcFPI;
    this.calcModASW = this.pppgL && userCalcModASW;
    this.calcModFrost = this.pppgL && userCalcModFrost;

    this.simNA =
      this.getFormGroup().get("userN").value &&
      this.getFormGroup().get("userSoilMnrl").value &&
      this.CAMAg;
    //     // Simulate
    //     const simNF = z.userN && z.userSoilMnrl && this.CAMFor;
    //     simNA = z.userN && z.userSoilMnrl && this.CAMAg;
    //     simN = simNF || simNA;
    //     simMnrlNF = simNF || this.GendecF;
    //     simMnrlNA = simNA || this.GendecA;
    //     simMnrlN = simMnrlNF || simMnrlNA;
    //     dispEner = z.userDispEner && this.CAMX;
    //     dispProd = z.userDispProd && this.CAMX;
    //     // Events or TS
    //     ComputeEventOrTSCO();
    //     // this.RothC version
    this.RothC263 =
      this.RothC && this.getFormGroup().get("rothCVers").value == "Vers263";
    this.RothC265 =
      this.RothC && this.getFormGroup().get("rothCVers").value == "Vers265";
    //     // Analyses
    //     sens = z.userSens;
    // #ifdef developer_
    //     opti = z.userOpti;
    // #else
    //     opti = false;
    // #endif
    //     econ = this.CAMX   && z.userEcon;
    //     logGrade = this.CAMFor && (z.userLogGrade || econ);
    //     cropGrade = this.CAMAg && (z.userCropGrade || econ);
  }

  public getSelectedPlotTypeName(): string {
    const type = this.getFormGroup().get("tPlot")?.value;

    return type ? Constants.PLOT_TYPE_MAP[type] : "";
  }

  public getXPathForCloneDigest(programmingName): {
    [xPath: string]: string;
    id: string;
  } {
    return { xPath: "//Config", id: null };
  }

  public hasConfig(arg): boolean {
    return this[arg];
  }

  public openDiagrams() {
    const initialState: ModalOptions = {
      initialState: {
        tPlot: this.getFormGroup().get("tPlot").value,
        userSoilMnrl: this.getFormGroup().get("userSoilMnrl").value,
      },
    };

    this.simulationService.modalService
      .openModal(DiagramsComponent, {
        ...initialState,
      })
      .pipe(take(1));
  }

  public openModelsAndInputs() {
    const initialState: ModalOptions = {
      initialState: {
        inputData: this.getConfigModels(),
      },
    };

    this.simulationService.modalService
      .openModal(ModelsAndInputComponent, {
        ...initialState,
      })
      .pipe(take(1));
  }

  private getConfigModels(): string[] {
    let output = ["Models", "======", ""];

    if (this.CAMFor || this.PPPG || this.GendecF || this.RothCF) {
      output.push("Forest");
      if (this.CAMFor) output.push("  Forest framework: CAMFor");
      if (this.CAMFor || this.PPPG) {
        const treeProductionValue = this.getFormGroup().get("tTreeProd").value;
        const treeProductionDisplayValue =
          this.getInputDisplayValueByProgrammingNameAndValue(
            "tTreeProd",
            treeProductionValue
          );
        output.push("  Tree production: " + treeProductionDisplayValue);
        output.push(`  Trees (ex production): ${this.PPPG ? "3PG" : "CAMFor"}`);
      }
      if (this.CAMFor) {
        output.push("  Forest debris: CAMFor");
      }
      if (this.GendecF) {
        output.push("  Forest mulch: GENDEC");
      }
      if (this.RothCF) {
        output.push("  Active forest soil: RothC");
      }
      if (this.CAMFor && this.RothCF) {
        output.push("  Inert forest soil: CAMFor");
      }
      if (this.CAMFor) {
        output.push("  Forest products: CAMFor");
      }
    }
    output.push("");

    // Agricultural models
    if (this.CAMAg || this.GendecA || this.RothCA) {
      output.push("Agricultural");
      if (this.CAMAg) {
        output.push("  Agricultural framework: CAMAg");
        output.push("  Crop production: Increments");
        output.push("  Crops (ex production): CAMAg");
        output.push("  Agricultural debris: CAMAg");
      }
      if (this.GendecA) output.push("  Agricultural mulch: GENDEC");
      if (this.RothCA) output.push("  Active agricultural soil: RothC");
      if (this.CAMAg && this.RothCA)
        output.push("  Inert agricultural soil: CAMAg");
      if (this.CAMAg) output.push("  Agricultural products: CAMAg");
    }
    // Finish
    output.push("");
    output.push(...this.getInputsUsed());

    return output;
  }

  public getInputsUsed(): string[] {
    //No Forms for output optinos in PUBLIC RELEASE version
    const inputData =
      this.simulationService.plotRefDataService.getRequiredInputsNamesBycategory();

    let output = ["Required Inputs", "===============", ""];
    output.push("Show values: Off");
    output.push("Show programming names: Off");
    output.push("Display: Inputs required by the current configuration");
    output.push("");

    output.push(
      `# inputs: ${inputData.numberOfinputs + inputData.numberOfTimeSeries}  (${
        inputData.numberOfTimeSeries
      } time series and ${inputData.numberOfinputs} single numbers)`
    );
    output.push(`# categories: ${inputData.numberOfCategories}`);

    Object.entries(inputData.categories).forEach((ic) => {
      const key = ic[0];
      const inputs = ic[1] as string[];

      output.push(`${key} (${inputs?.length})`);
      output.push("---------------------");
      inputs.forEach((i) => {
        output.push(i);
      });

      output.push("");
    });

    return output;
  }

  public shouldEnableModelsAndDiagramsButton() {
    return this.getFormGroup().get("tPlot")?.value;
  }

  public readXmlObject(): void {
    super.readXmlObject();

    const template = this.simulationService.getPlotFileJson()["Config"][0];
    const config = template.$;

    this.applyXmlValuesToFormGroup(config, this.formGroup);
    //trigger output windows menu
    this.setVariables(this.getFormGroup().get("tPlot")?.value);
  }

  public writeXmlObject() {
    let formData = this.getFormGroup().getRawValue();
    const { modelsAndInputs, diagrams, ...allOtherFormData } = formData;
    let config = this.getFormValueObjectForXmlExport(null, allOtherFormData);

    return { Config: [{ $: config }] };
  }

  public startLogging(): void {
    this.getFormGroup()
      .valueChanges.pipe(
        startWith(this.getFormGroup().value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.serviceDestroySubject$)
      )
      .subscribe(([oldFormValues, newFormValues]) => {
        for (const field of Object.keys(this.logFields)) {
          if (oldFormValues[field] !== newFormValues[field]) {
            const logArguments = this.logFields[field].getLogArguments(
              oldFormValues[field],
              newFormValues[field],
              this.formModel[field],
              this
            );
            this.simulationService.logService.setLog(
              logArguments,
              this.logFields[field].logType
            );
            break;
          }
        }
      });
  }
}
