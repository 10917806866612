import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

declare var gtag: Function;

interface GaEvent {
  name: string;
  category: string;
  label: string;
}

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor() {}

  public initialise() {
    if (!environment.production) {
      return;
    }
    // dynamically add analytics scripts to document head
    try {
      const url = "https://www.googletagmanager.com/gtag/js?id=";
      const gTagScript = document.createElement("script");
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.gaTrackingId}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement("script");
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.gaTrackingId}');`;
      document.head.appendChild(dataLayerScript);

      document.addEventListener("click", this.handleClickEvent.bind(this));
    } catch (e) {
      console.error("Error adding Google Analytics", e);
    }
  }

  private handleClickEvent(event: MouseEvent) {
    let element = event.target as HTMLElement;

    element = this.findRelevantParent(element) || element;

    let category = "";
    let label = "";

    if (this.isButton(element)) {
      category = "Button";
      label = this.getButtonLabel(element);
    } else if (this.isFormElement(element)) {
      category = "Form Element";
      label = this.getFormElementLabel(element);
    } else if (this.isAnchor(element)) {
      category = "Anchor";
      label = this.getAnchorLabel(element);
    }

    if (category && label) {
      this.sendEvent({
        name: "click",
        category: category,
        label: label + " | Page: " + document.title,
      });
    }
  }

  private findRelevantParent(element: HTMLElement): HTMLElement | null {
    while (element && element !== document.body) {
      if (
        this.isButton(element) ||
        this.isFormElement(element) ||
        this.isAnchor(element)
      ) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }

  private isButton(element: HTMLElement): boolean {
    return (
      element.tagName === "BUTTON" ||
      element.matches('[role="button"]') ||
      element.classList.contains("btn")
    );
  }

  private getButtonLabel(element: HTMLElement): string {
    // Check for child elements that might contain the label text
    const childText = element.querySelector(".visually-hidden");
    if (childText) {
      return childText.textContent || "";
    } else if (element.getAttribute("id")) {
      return element.getAttribute("id") || "";
    }

    // Fallback to the button's own text or value
    return element.innerText || element.getAttribute("value") || "";
  }

  private isAnchor(element: HTMLElement): boolean {
    return element.tagName === "A";
  }

  private getAnchorLabel(element: HTMLElement): string {
    return element.textContent || element.getAttribute("href") || "";
  }

  private isFormElement(element: HTMLElement): boolean {
    return (
      ["INPUT", "SELECT", "TEXTAREA", "OPTION"].includes(element.tagName) ||
      element.matches('[type="checkbox"]') ||
      element.matches('[type="radio"]')
    );
  }

  private getFormElementLabel(element: HTMLElement): string {
    return element.getAttribute("name") || element.getAttribute("id") || "";
  }

  public sendEvent(event: GaEvent) {
    if (!environment.production) {
      return;
    }
    if (typeof gtag !== "undefined") {
      gtag("event", event.name, {
        event_category: event.category,
        event_label: event.label,
      });
    }
  }
}
