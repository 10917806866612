import { v4 as uuidv4 } from "uuid";
import Papa from "papaparse";
import * as _ from "lodash";
import * as xml2js from "xml2js";

const rmtXmlBuilder = new xml2js.Builder({
  arrayAccessForm: "property",
  rootName: "root",
  xmldec: { version: "1.0", encoding: "UTF-8" },
});

export default class Utilities {
  static async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  static uuid(): string {
    return uuidv4();
  }

  static convertCsvToArray(csv: string | File, config?: object): any {
    return Papa.parse(csv, config);
  }

  static convertArrayToCsv(array) {
    return Papa.unparse(array);
  }

  static setValueToMap(object, path, value, replaceValue: boolean = true) {
    const last = path.pop();
    const obj = path.reduce((o, k) => (o[k] = o[k] || {}), object);

    let outputValue = value;
    if (!replaceValue) {
      if (Array.isArray(outputValue)) {
        outputValue = value;
      } else {
        outputValue = { ...obj[last], ...value };
      }
    }
    obj[last] = outputValue;
  }

  static getValueFromMap(object, path) {
    return path.reduce((acc, key) => acc && acc[key], object);
  }

  static cloneDeep(obj) {
    return _.cloneDeep(obj);
  }

  static formatNumberToFourDecimalPlaces(n) {
    const formattedNumber = (+n).toFixed(4);
    return parseFloat(formattedNumber).toString();
  }

  static capitaliseFirstLetter(input: string): string {
    if (input.length === 0) {
      return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  static halfRoundUp(number, decimals = 2) {
    const multiplier = Math.pow(10, decimals);
    return Math.round(number * multiplier) / multiplier;
  }

  static simulationRounding(value) {
    return (Math.round(+value * 1000) / 1000).toFixed(2);
  }

  //For RMT
  static async convertXmlToJson(xml): Promise<Object> {
    const parser = new xml2js.Parser({
      explicitArray: false,
      mergeAttrs: true,
    });
    let json;
    await parser.parseString(xml, (err, result) => {
      json = result;
    });
    return json;
  }

  static async convertJsonToXmlString(jsonObject) {
    const xml = rmtXmlBuilder.buildObject(jsonObject);
    return xml;
  }

  static formatRawTS(rawTS): { double: number } {
    return {
      double: rawTS.map((value) => ({ _: value })),
    };
  }
}
