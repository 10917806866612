import { Component, Input } from "@angular/core";
import { TableComponent } from "src/app/shared/components/table/table.component";
import { Observable } from "rxjs";
import { takeUntil, delay } from "rxjs/operators";
import { TableComponentData } from "src/app/shared/components/table/table.component";

@Component({
  selector: "fc-tyf-category-table",
  templateUrl: "./tyf-category-table.component.html",
  styleUrls: ["./tyf-category-table.component.scss"],
})
export class TyfCategoryTableComponent extends TableComponent {
  @Input() selectedSpeciesId$: Observable<string>;
  @Input() service: any;
  public tableData: TableComponentData;

  ngOnInit() {
    this.selectedSpeciesId$
      .pipe(takeUntil(this.destroy$), delay(0))
      .subscribe(() => {
        this.tableData = this.service.getTYFCategory();
      });
  }
}
