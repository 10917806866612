import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as echarts from "echarts/core";
import {
  BarChart,
  // The series types are defined with the SeriesOption suffix
  BarSeriesOption,
  LineSeriesOption,
} from "echarts/charts";

import { LineChart } from "echarts/charts";

import {
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  // The component types are defined with the suffix ComponentOption
  TitleComponentOption,
  GridComponent,
  GridComponentOption,
  // Dataset
  DatasetComponent,
  DatasetComponentOption,
  // Built-in transform (filter, sort)
  TransformComponent,
  LegendComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { v4 as uuidv4 } from "uuid";

// Combine an Option type with only required components and charts via ComposeOption
type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | DatasetComponentOption
>;

@Component({
  selector: "fc-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.chart.resize();
  }

  @ViewChild("eChart") chartElementRef: ElementRef;

  @Input("chartOption")
  set chartOption(d) {
    this._chartOption = d;
    if (this.chart) {
      this.chart.setOption(d);
    }
  }

  @Input("height") chartHeight = "350px";

  public id: string = "_" + uuidv4();

  public _chartOption;

  private chart;
  private defaultOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      bottom: 90,
    },
    xAxis: {
      data: [],
      silent: false,
      splitLine: {
        isShown: false,
      },
      splitArea: {
        isShown: false,
      },
    },
    yAxis: {
      splitArea: {
        isShown: false,
      },
    },
    series: [
      {
        type: "bar",
        data: [],
        // Set `large` for large data amount
        large: true,
      },
    ],
  };

  constructor() {
    // Register the required components
    echarts.use([
      TitleComponent,
      ToolboxComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      LegendComponent,
      BarChart,
      LineChart,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
    ]);

    // const option: ECOption = {
    //   // ...
    // };
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.chart) {
      this.chart = echarts.init(document.querySelector("#" + this.id));
    }

    this.chart.setOption(this._chartOption || this.defaultOption);
  }

  ngOnDestroy(): void {
    echarts.dispose(this.chart);
  }
}
