import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "fc-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @HostBinding("attr.title") public tooltip = null;

  @Input() color: string = "primary";

  @Input() isLoading$: Observable<boolean>;

  @Input() disabled: () => boolean | boolean;

  @Input() invalid: () => boolean | boolean;

  public set disabledTooltip(value: () => string | string) {
    this.tooltip = value instanceof Function ? value() : value;
  }

  @Input() isSuccess: boolean = false;
  @Input() successText: string = "";

  @Input() inputText: string = "";

  @Output() onClick = new EventEmitter();
  public id: string = null;

  constructor() {}

  ngOnInit(): void {
    this.id = this.convertTextToElementId(this.inputText);
  }
  private convertTextToElementId(text: string) {
    if (!text.trim()) {
      return "default_id"; // Default ID for empty or whitespace-only strings
    }

    const id = text
      .toLowerCase() // Convert to lowercase
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .replace(/[^a-z0-9\-_]/g, "") // Remove invalid characters
      .substring(0, 99); // Limit the length of the ID

    // Ensure the ID starts with a letter
    if (!id.charAt(0).match(/[a-z]/)) {
      return "a" + id;
    }

    return id;
  }

  public isDisabled(): boolean {
    return this.disabled instanceof Function ? this.disabled() : this.disabled;
  }

  public isInvalid(): boolean {
    return this.invalid instanceof Function ? this.invalid() : this.invalid;
  }

  public getClassObject() {
    return {
      [`btn-${this.color}`]: true,
      "btn-success": this.isSuccess,
      "is-invalid": this.isInvalid(),
    };
  }

  public resetState(): void {
    this.isSuccess = false;
  }
}
