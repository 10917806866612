<div class="form">
  <div class="form-content">
    <div class="title">
      <span
        [tooltip]="
          !projectCollectionFilter
            ? 'Project and Collection must be selected.'
            : 'Click the select button to confirm.'
        "
      >
        <button
          aria-label="back button"
          type="button"
          class="btn close-button"
          disabled
        >
          <i class="bi bi-arrow-left-circle-fill"> </i>
        </button>
      </span>
      <h2>Select Project and Collection</h2>
    </div>

    <fc-project-collection-list
      id="folder-select"
      [data]="folders$ | async"
      [subitemKey]="'collectionInfo'"
      [parentIdKey]="'projectId'"
      [subitemSubmitTooltip]="'select this collection for Estate simulation'"
      (subitemClicked)="selectFolder($event)"
    ></fc-project-collection-list>

    <div class="form-button-group bottom-controls">
      <div></div>

      <div class="plot-selection-details">
        <span *ngIf="!projectCollectionFilter"
          >Select a collection first to organise your plot files</span
        >
        <span *ngIf="projectCollectionFilter"
          ><i class="bi bi-folder-fill"></i> Project:
          {{ projectCollectionFilter.project }}
          <i class="bi bi-folder-fill"></i>Collection:
          {{ projectCollectionFilter.collection }}</span
        >
      </div>

      <span
        [tooltip]="
          !projectCollectionFilter
            ? 'Project and Collection must be selected.'
            : ''
        "
      >
        <button
          [disabled]="!projectCollectionFilter"
          class="btn btn-sm btn-primary"
          (click)="closeForm(projectCollectionFilter)"
        >
          Select
        </button>
      </span>
    </div>
  </div>
</div>
