<h1>New simulation ({{ appVersion }})</h1>

<h2>Create from scratch</h2>
<div class="card-container">
  <div class="card">
    <div class="header">.plo</div>
    <div class="text">Create a single plot</div>

    <div class="footer">
      <button
        class="btn btn-sm"
        [routerLink]="['/simulation-' + appVersion + '/plo']"
      >
        <span class="visually-hidden">Create a single plot</span>
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>
  <div class="card">
    <div class="header">.est</div>
    <div class="text">Create an estate</div>

    <div class="footer">
      <button
        class="btn btn-sm"
        [routerLink]="['/simulation-' + appVersion + '/est']"
      >
        <span class="visually-hidden">Create a estate</span>
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>
  <div class="card">
    <div class="header">.pld</div>
    <div class="text">Create a digest</div>

    <div class="footer">
      <button
        class="btn btn-sm"
        [routerLink]="['/simulation-' + appVersion + '/pld']"
      >
        <span class="visually-hidden">Create a digest</span>
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>
  <div class="card">
    <div class="header">Import</div>
    <div class="text">Import and modify a file from your computer</div>

    <div class="footer">
      <label for="plot-file-select" id="plot-file-select-label">
        <span class="visually-hidden">Select a plot file</span>
        <i class="bi bi-chevron-right"></i>
      </label>
      <input
        class="form-control"
        type="file"
        accept="acceptedFileTypes"
        id="plot-file-select"
        (change)="onFileSelected($event)"
      />
    </div>
  </div>
</div>

<h2>Create from templates</h2>

<div class="card-container">
  <div class="card" *ngFor="let template of plotTemplates$ | async">
    <div class="header">{{ template?.$?.header }}</div>
    <div class="text">
      {{ template?.Meta[0]?.$?.nmME }}
    </div>

    <div class="footer">
      <button class="btn btn-sm" (click)="setPlotTemplate(template?.$?.value)">
        <span class="visually-hidden"
          >Create a plot base on template - {{ template?.$?.header }}</span
        >
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>
</div>

<h2>Continue with incomplete files</h2>
<fc-my-plots class="plot-list" [forceStatusFilter]="'Invalid'"></fc-my-plots>
<h2>Create from complete files</h2>
<fc-my-plots class="plot-list" [forceStatusFilter]="'Valid'"></fc-my-plots>
