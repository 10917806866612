import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, lastValueFrom, take } from "rxjs";
import { FolderList } from "src/app/my-plots/models";
import { FormGroupElement, FormLayout } from "src/app/plot/models";
import { BasePlotFormService } from "src/app/plot/services/base-plot-form.service";
import { FormModel } from "src/app/shared/models";
import { SiteInfoResponse, XMLRawTS } from "../../models";
import Utilities from "src/app/shared/utilities/utils";
import { MyRmdService } from "../../my-rmds/services/my-rmd.service";

const initialSpeciesRoundingProperties = [
  "CFracStemF",
  "CFracBranF",
  "CFracBarkF",
  "CFracLeafF",
  "CFracCortF",
  "CFracFirtF",
  "turnFracBranF",
  "turnFracBarkF",
  "turnFracLeafF",
  "turnFracCortF",
  "turnFracFirtF",
  "rFracStemF",
  "rFracBranF",
  "rFracBarkF",
  "rFracLeafF",
  "rFracCortF",
  "rFracFirtF",
  "bkdnFracDDdwdF",
  "bkdnFracRDdwdF",
  "bkdnFracDChwdF",
  "bkdnFracRChwdF",
  "bkdnFracDBlitF",
  "bkdnFracRBlitF",
  "bkdnFracDLlitF",
  "bkdnFracRLlitF",
  "bkdnFracDCodrF",
  "bkdnFracRCodrF",
  "bkdnFracDFidrF",
  "bkdnFracRFidrF",
  "atmsFracDDdwdBkdnF",
  "atmsFracRDdwdBkdnF",
  "atmsFracDChwdBkdnF",
  "atmsFracRChwdBkdnF",
  "atmsFracDBlitBkdnF",
  "atmsFracRBlitBkdnF",
  "atmsFracDLlitBkdnF",
  "atmsFracRLlitBkdnF",
  "atmsFracDCodrBkdnF",
  "atmsFracRCodrBkdnF",
  "atmsFracDFidrBkdnF",
  "atmsFracRFidrBkdnF",
  "bkdnSensTempF",
  "bkdnSensRainF",
];
@Injectable({
  providedIn: "root",
})
export class RmtProjectService extends BasePlotFormService {
  public readonly pageId = "about";

  private readonly stateMap = {
    Blank: "No State",
    ACT: "Australian Capital Territory",
    NSW: "New South Wales",
    NT: "Northern Territory",
    Qld: "Queensland",
    SA: "South Australia",
    Tas: "Tasmania",
    Vic: "Victoria",
    WA: "Western Australia",
  };

  private readonly npiRegionMap = {
    Blank: "No Region",
    WA: "Western Australia",
    Tas: "Tasmania",
    GreenTri: "Green Triangle",
    Lofty: "Lofty Block",
    CentVic: "Central Victoria",
    Murray: "Murray Valley",
    CentGipp: "Central Gippsland",
    Bombala: "Bombala/East Gippsland",
    SouthTbl: "Southern Tablelands",
    CentTbl: "Central Tablelands",
    NthTbl: "Northern Tablelands",
    NthCoast: "North Coast",
    SEQld: "South East Queensland",
    NthQld: "Northern Queensland",
    NT: "Northern Territory",
  };

  public folders$ = new BehaviorSubject<FolderList[]>([]);

  public modifiers: FormGroupElement[] = [
    {
      label: "Site Information",
      isShown: true,
      isRoot: true,
      isAccordion: true,
      isExpanded: true,
      items: [
        {
          label: "Latitude",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getSiteInfoDisplay",
              payload: "Latitude",
            },
          ],
        },
        {
          label: "Longitude",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getSiteInfoDisplay",
              payload: "Longitude",
            },
          ],
        },
        {
          label: "State",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getSiteInfoDisplay",
              payload: "State",
            },
          ],
        },
        {
          label: "NPI Region",
          preventLabelElement: true,
          inputs: [
            {
              element: "text",
              method: "getSiteInfoDisplay",
              payload: "NpiRegion",
            },
          ],
        },
      ],
    },
  ];

  public layout: FormLayout = {
    label: "Project",
    groups: [
      {
        label: null,
        isShown: true,
        isRoot: true,
        items: [
          {
            label: null,
            inputs: [
              {
                label: "Project Name",
                element: "input",
                type: "text",
                programmingName: "ProjectName",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Project",
                element: "component",
                programmingName: "project",
                component: "FolderSelectComponent",
                componentInputs: [
                  {
                    inputKey: "selectedProjectName",
                    isObservable: true,
                    formKey: "project",
                  },
                  {
                    inputKey: "selectedCollectionName",
                    isObservable: true,
                    formKey: "collection",
                  },
                  {
                    inputKey: "folders$",
                    method: "getFolders",
                  },
                  {
                    inputKey: "type",
                    value: "project",
                  },
                  {
                    inputKey: "isLocked",
                    method: "isEditRMD",
                  },
                  {
                    inputKey: "isRMT",
                    value: true,
                  },
                  {
                    inputKey: "isRMT",
                    value: true,
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "onRefreshFolders",
                    method: "loadFolders",
                  },
                ],
              },
            ],
          },

          {
            label: null,
            inputs: [
              {
                label: "Collection",
                element: "component",
                programmingName: "collection",
                component: "FolderSelectComponent",
                componentInputs: [
                  {
                    inputKey: "selectedProjectName",
                    isObservable: true,
                    formKey: "project",
                  },
                  {
                    inputKey: "selectedCollectionName",
                    isObservable: true,
                    formKey: "collection",
                  },
                  {
                    inputKey: "folders$",
                    method: "getFolders",
                  },
                  {
                    inputKey: "type",
                    value: "collection",
                  },
                  {
                    inputKey: "isRMT",
                    value: true,
                  },
                  {
                    inputKey: "isLocked",
                    method: "isEditRMD",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "onRefreshFolders",
                    method: "loadFolders",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Carbon Estimation Area (CEA) name",
                element: "input",
                type: "text",
                programmingName: "StandName",
              },
            ],
          },

          {
            label: null,
            inputs: [
              {
                label: "Longitude",
                element: "input",
                type: "number",
                programmingName: "Longitude",
              },
              {
                label: "Latitude",
                element: "input",
                type: "number",
                programmingName: "Latitude",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                element: "component",
                component: "ButtonComponent",
                componentInputs: [
                  {
                    inputKey: "disabled",
                    variable: "disableQuery",
                    bind: this,
                  },
                  {
                    inputKey: "invalid",
                    variable: "isSiteInfoButtonInvalid",
                    bind: this,
                  },
                  {
                    inputKey: "disabledTooltip",
                    value: "Latitude and Longitude inputs must be valid",
                    bind: this,
                  },
                  {
                    inputKey: "inputText",
                    value: "Download Site Infomation",
                  },
                  {
                    inputKey: "isLoading$",
                    variable: "isLoading$",
                  },
                  {
                    inputKey: "successText",
                    value: "Done",
                  },
                ],
                componentOutputs: [
                  {
                    outputKey: "onClick",
                    method: "getSiteInfo",
                  },
                ],
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Planted area (ha)",
                element: "input",
                type: "number",
                programmingName: "Area",
              },
            ],
          },
          {
            label: null,
            inputs: [
              {
                label: "Notes",
                element: "textarea",
                placeholder: "Description here...",
                programmingName: "Notes",
              },
            ],
          },
        ],
      },
    ],
  };

  public formModel: FormModel = {
    ProjectName: {
      dataType: 4,
      helpId: 11,
      defaultValue: "TEST Project",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      label: "Project Name",
      isShown: true,
    },
    project: {
      label: "Project",
      defaultValue: "",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      isShown: true,
    },
    collection: {
      label: "Collection",
      defaultValue: "",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      isShown: true,
    },
    StandName: {
      dataType: 4,
      helpId: 11,
      defaultValue: "TEST Stand",
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "pattern",
          constantsInput: "STRING_INPUT_VALIDATION_PATTERN",
        },
        {
          functionName: "maxLength",
          input: 510,
        },
      ],
      label: "Carbon Estimation Area (CEA) name",
      isShown: true,
    },
    Latitude: {
      label: "Latitude",
      defaultValue: -16,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: -44,
        },
        {
          functionName: "max",
          input: -9,
        },
      ],

      isShown: true,
    },
    Longitude: {
      label: "Longitude",
      defaultValue: 144,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 112,
        },
        {
          functionName: "max",
          input: 154,
        },
      ],
      isShown: true,
    },
    Area: {
      label: "Area",
      defaultValue: 100,
      validators: [],
      validatorConfig: [
        {
          functionName: "required",
        },
        {
          functionName: "min",
          input: 0.001,
        },
        {
          functionName: "max",
          input: 1.7976931348623157e308,
        },
      ],
      isShown: true,
    },
    Notes: {
      label: "Notes",
      defaultValue: "",
      validators: [],
      validatorConfig: [
        {
          functionName: "maxLength",
          input: 1000,
        },
      ],
      isShown: true,
    },
  };

  public siteInfoData$ = new BehaviorSubject<SiteInfoResponse>(null);

  constructor(public myRmdService: MyRmdService) {
    super();
  }

  public isEditRMD(): boolean {
    return this.simulationService.isEditRMD();
  }

  public setFolders(folders: FolderList[]): void {
    this.folders$.next(folders);
  }
  public getFolders(): Observable<FolderList[]> {
    return this.folders$.asObservable();
  }

  public getFileName(): string {
    return this.getFormGroup().get("StandName").value || null;
  }

  public async loadFolders(): Promise<void> {
    const folders = await lastValueFrom(
      this.myRmdService.getList("folder").pipe(take(1))
    );

    this.folders$.next(folders);
  }

  public disableQuery(): boolean {
    const lat = this.formGroup.get("Latitude").invalid;
    const long = this.formGroup.get("Longitude").invalid;
    return lat || long;
  }

  public isSiteInfoButtonInvalid(): boolean {
    return this.siteInfoData$.getValue() == null;
  }

  public getSiteInfoDisplay(key: string) {
    const siteInfo = this.siteInfoData$.getValue();

    const location = siteInfo?.Location;
    if (!siteInfo) {
      if (key == "Latitude" || key == "Longitude") {
        return "Not set";
      }
      return "Unknown";
    }

    if (key == "NpiRegion") {
      return this.npiRegionMap[location[key]] || "Unknown";
    }
    if (key == "State") {
      return this.stateMap[location[key]] || "Unknown";
    }
    return location[key];
  }

  public async getSiteInfo() {
    try {
      const managementService = this.simulationService.managementService;
      const rotations = managementService.getRotations();
      if (managementService.getRotations()?.length) {
        const message = `<p>Downloading this data will delete previously selected information for: User Rotations and Available Species.</p>
      <p>Do you wish to continue?</p>`;
        const confirm =
          await this.simulationService.modalService.openConfirmModal(message);

        if (!confirm) {
          return;
        }
      }

      managementService.regimeList$.next({});
      managementService.speciesList$.next({});
      managementService.treeList$.next({});
      rotations.clear();
      this.isLoading$.next(true);
      const lat = this.getFormGroup().get("Latitude").value;
      const lon = this.getFormGroup().get("Longitude").value;

      this.simulationService.dbService
        .getRMTSiteInfo(lon, lat)
        .pipe(take(1))
        .subscribe((response) => {
          let siteInfo = response.data;
          if (siteInfo.Location) {
            siteInfo.Location.Latitude = lat;
            siteInfo.Location.Longitude = lon;
          }

          this.siteInfoData$.next(siteInfo);
          this.simulationService.messageService.addAlert({
            type: "success",
            msg: response?.message,
            timeout: 2000,
          });
        });
    } finally {
      this.isLoading$.next(false);
    }
  }

  public writeSiteInfoXmlObject() {
    const siteInfo = Utilities.cloneDeep(this.siteInfoData$.getValue());
    //convert rawTS to match backend requirement
    let InitialSpecies = siteInfo.InitialSpecies;
    let ForestProdIx = siteInfo.ForestProdIx;
    let Rainfall = siteInfo.Rainfall;
    let AvgAirTemp = siteInfo.AvgAirTemp;
    let allocStemF = InitialSpecies.allocStemF;
    let allocBranF = InitialSpecies.allocBranF;
    let allocBarkF = InitialSpecies.allocBarkF;
    let allocLeafF = InitialSpecies.allocLeafF;
    let allocCortF = InitialSpecies.allocCortF;
    let allocFirtF = InitialSpecies.allocFirtF;
    let stemDensity = InitialSpecies.stemDensity;

    [
      ForestProdIx,
      Rainfall,
      AvgAirTemp,
      allocStemF,
      allocBranF,
      allocBarkF,
      allocLeafF,
      allocCortF,
      allocFirtF,
      stemDensity,
    ].forEach((ts) => {
      if (ts.rawTS && !ts.rawTS.double) {
        ts.rawTS = Utilities.formatRawTS(ts.rawTS) as XMLRawTS;
      }
    });

    const availableSpecies = siteInfo.AvailableSpecies
      ?.ObjectReferenceOfSpeciesString?.length
      ? siteInfo.AvailableSpecies
      : { ObjectReferenceOfSpeciesString: siteInfo.AvailableSpecies };

    const template = {
      Location: siteInfo.Location,
      MaxAbgMf: siteInfo.MaxAbgMf,
      ForestProdIx,
      Rainfall,
      AvgAirTemp,
      InitialSpecies,
      AvailableSpecies: availableSpecies,
    };
    //round values
    // Object.
    // InitialSpecies["$"]
    initialSpeciesRoundingProperties.forEach((prop) => {
      if (
        template.InitialSpecies[prop] &&
        !isNaN(template.InitialSpecies[prop])
      ) {
        template.InitialSpecies[prop] = Utilities.halfRoundUp(
          +template.InitialSpecies[prop]
        );
      }
    });
    return template;
  }

  public readXmlObject(): void {
    const formGroup = this.getFormGroup();
    if (!this.simulationService.importedRMDFileJson) {
      return;
    }
    const ncatStand = this.simulationService.importedRMDFileJson["NCATStand"];
    const template = ncatStand?.ProjectInformation;

    formGroup.patchValue(template);
    if (
      ncatStand.SiteInfo.AvailableSpecies?.ObjectReferenceOfSpeciesString
        ?.length
    ) {
      ncatStand.SiteInfo.AvailableSpecies =
        ncatStand.SiteInfo.AvailableSpecies.ObjectReferenceOfSpeciesString;
    }
    if (ncatStand.SiteInfo) {
      this.siteInfoData$.next(ncatStand.SiteInfo);
    }
  }

  public writeXmlObject() {
    const formData = this.getFormGroup().value;
    const siteInfo = this.siteInfoData$.getValue();
    const location = siteInfo?.Location;
    let baseTemplate = {
      StandName: formData.StandName,
      ProjectName: formData.ProjectName,
      Area: formData.Area,
      Longitude: formData.Longitude,
      IsValidLongitude: this.getFormGroup().get("Longitude").valid,
      Latitude: formData.Latitude,
      IsValidLatitude: this.getFormGroup().get("Latitude").valid,
      Notes: formData.Notes,
      Location: location,
    };

    return baseTemplate;
  }
  override isInvalid(): boolean {
    const isFormValid = super.isInvalid();
    const hasNoSiteInfo = !this.siteInfoData$.getValue();
    return isFormValid || hasNoSiteInfo;
  }

  override reset() {
    this.folders$.next([]);
    super.reset();
  }
}
