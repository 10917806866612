<div
  *ngIf="!isLocked || (isLocked && !selectedFolder); else labelOnly"
  class="folder-select"
  role="select"
  [class]="{
    'is-open': isDropdownOpen,
    'is-invalid':
      type == 'project'
        ? selectedProjectName.invalid && selectedProjectName.touched
        : selectedCollectionName.invalid && selectedCollectionName.touched
  }"
>
  <span (click)="isDropdownOpen = !isDropdownOpen">{{
    selectedFolder?.name || "Select a " + type
  }}</span>
  <button
    type="button"
    class="btn expand-button btn-sm"
    [attr.aria-label]="
      isDropdownOpen ? 'collapse section button' : 'expand section button'
    "
    [attr.aria-expanded]="isDropdownOpen"
    [attr.aria-controls]="type + '-select'"
    (click)="isDropdownOpen = !isDropdownOpen"
  >
    <i class="bi bi-chevron-down arrow"></i>
  </button>
</div>

<div
  class="folder-select-dropdown"
  [class]="{ 'is-open': isDropdownOpen }"
  [id]="type + '-select'"
>
  <input
    [attr.aria-label]="'search ' + type + ' input'"
    [(ngModel)]="typeaHeadModel"
    [typeahead]="folders"
    typeaheadOptionField="name"
    (typeaheadOnSelect)="onAutoCompleteSelect($event)"
    [isAnimated]="true"
    [placeholder]="'Search ' + type"
    class="form-control form-control-sm autocomplete"
  />
  <a
    href="#"
    class="edit-link"
    (click)="openProjectForm(); $event.preventDefault()"
    >Create new {{ type }}</a
  >

  <div class="list-group">
    <span
      class="list-group-item"
      *ngFor="let folder of folders"
      [class]="{
        'is-disabled': shouldDisableFolder(folder)
      }"
    >
      <i class="bi bi-folder-fill"></i>
      <a
        href="#"
        class="text-body"
        (click)="selectFolder(folder); $event.preventDefault()"
        [title]="folder.name"
      >
        {{ folder.name }}</a
      >
    </span>

    <span class="list-group-item" *ngIf="!folders?.length">No {{ type }}s</span>
  </div>
</div>

<ng-template #labelOnly
  ><span class="label-only">{{ selectedFolder?.name }}</span></ng-template
>
