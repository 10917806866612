import { Injectable } from "@angular/core";
import {
  Regime,
  RegimeForest,
  RotationEvent,
  RotationForest,
  SpecF,
  Tree,
  UserRotationsChangedAction,
} from "../models";
import { RmtSimulationService } from "../simulation/services/rmt-simulation.service";
import TimeUtilities from "src/app/shared/utilities/time";
import { FormModel } from "src/app/shared/models";
import { AbstractControl, FormGroup } from "@angular/forms";
import { EventService, RotationEventService } from "./events/event.service";
import Utilities from "src/app/shared/utilities/utils";
import { RotationTreePlantingService } from "./events/rotation-tree-planting.service";
import { RotationTreeRegenerationService } from "./events/rotation-tree-regeneration.service";
import { RotationClearingThinService } from "./events/rotation-clearing-thin.service";
import { RotationTreeLossService } from "./events/rotation-tree-loss.service";
import { RotationCoppiceHarvestingThinService } from "./events/rotation-coppice-harvesting-thin.service";
import { RotationPestAndDiseaseService } from "./events/rotation-pest-and-disease.service";
import { RotationHarvestingThinService } from "./events/rotation-harvesting-thin.service";
import { RotationLimbPruningService } from "./events/rotation-limb-pruning.service";
import { RotationForestFireService } from "./events/rotation-forest-fire.service";
import { RotationFertilizationService } from "./events/rotation-fertilization.service";
import { RotationWeedControlService } from "./events/rotation-weed-control.service";
import { RotationChopperRollerService } from "./events/rotation-chopper-roller.service";
import { RotationTermiteChangeService } from "./events/rotation-termite-change.service";
import { BehaviorSubject } from "rxjs";

interface SelectedState {
  rotationExpandedState: { [key: string]: boolean };
  rotationSort: { prop: string; dir: "asc" | "desc" }[];
  selectedTab: "rotationPropertiesTab" | "eventsTab";
  eventSort: { prop: string; dir: "asc" | "desc" }[];
  eventExpandedState: { [key: string]: boolean };
}

@Injectable({
  providedIn: "root",
})
export class RotationService {
  private readonly rotationEventCollectionErrors = {
    "0": "Planting of trees invalid because there are already trees",
    "1": "Thin invalid because there are no trees",
    "2": "Treatment invalid because there are no trees",
    "3": "Chopper-roller invalid because there are trees standing",
    "4": "Weed control invalid because there are no trees",
    "5": "Fertilization invalid because there are no trees",
    "6": "Invalid date used for Event",
    "7": "Error within event queue",
    // unused
    "8": "Event invalid because there are no tress",
    "9": "Treatment invalid because the tree yield formula not in use",
    "10": "Thinning invalid because expecting a different species",
    "11": "Harvest invalid because expecting a different species",
  };

  private readonly defaultSelectedState: SelectedState = {
    rotationExpandedState: {},
    rotationSort: [{ prop: "startDate", dir: "asc" }],
    selectedTab: "rotationPropertiesTab" as const, //default selected
    eventSort: [{ prop: "date", dir: "asc" }],
    eventExpandedState: {},
  };

  private simulationService: RmtSimulationService;

  public isMovingEvent = false; //to prevent ui triggers moveEvent

  public selectedState$ = new BehaviorSubject<SelectedState>(
    this.defaultSelectedState
  );

  public eventErrorMessage$ = new BehaviorSubject<string>("");
  public erorrRotationId: string = null;
  public errorEventId: string = null;

  public rotationModel: FormModel = {
    Id: {
      defaultValue: null,
      isShown: true,
    },
    RegimeReference: {
      defaultValue: null,
      isShown: true,
    },
    UserSpeciesReference: {
      label: "User Species Reference",
      defaultValue: null,
      isShown: true,
    },
    SpeciesTYFInputs: {
      label: "Species TYF Inputs",
      defaultValue: null,
      isShown: true,
    },
    RotationEventCollection: {
      label: "Rotation Event Collection",
      defaultValue: [],
      isShown: true,
    },
    Offset: {
      label: "Offset",
      defaultValue: 0,
      isShown: true,
    },
    RotationStartDate: {
      label: "Rotation Start Date",
      defaultValue: null,
      isShown: true,
    },
    IsValid: {
      label: "",
      defaultValue: true,
      isShown: false,
    },
  };

  constructor(private eventService: EventService) {}

  public getRotationSort(): { prop: string; dir: "asc" | "desc" }[] {
    return this.selectedState$.getValue().rotationSort;
  }
  public setRotationSort(rotationSort): void {
    this.selectedState$.next({
      ...this.selectedState$.getValue(),
      rotationSort,
    });
  }

  public getEventSort(): { prop: string; dir: "asc" | "desc" }[] {
    return this.selectedState$.getValue().eventSort;
  }
  public setEventSort(eventSort): void {
    this.selectedState$.next({
      ...this.selectedState$.getValue(),
      eventSort,
    });
  }

  public getSelectedState(): SelectedState {
    return this.selectedState$.getValue();
  }

  public setSelectedState(selectedState): void {
    this.selectedState$.next({
      ...this.selectedState$.getValue(),
      ...selectedState,
    });
  }

  public setSimulationService(simulationService): void {
    this.simulationService = simulationService;
  }

  public createRotationFormGroup(rotationData: RotationForest): FormGroup {
    const managementService = this.simulationService.managementService;
    const rotationFormGroup = managementService.createFormGroup(
      this.rotationModel
    );
    const { RotationEventCollection, ...restData } = rotationData;
    rotationFormGroup.patchValue(restData);

    return rotationFormGroup;
  }

  public buildTree(from): Tree | null {
    const speciesForest = from as SpecF;
    if (!speciesForest) {
      return null;
    }

    const returnTree: Tree = {
      SpeciesReference: {
        Id: from.Id,
        Name: from.Name,
        Value: from,
      },
      Name: speciesForest.Name,
      Carbon: {
        Bark: speciesForest.CFracBarkF,
        Branch: speciesForest.CFracBranF,
        CoarseRoots: speciesForest.CFracCortF,
        FineRoots: speciesForest.CFracFirtF,
        Leaf: speciesForest.CFracLeafF,
        Stem: speciesForest.CFracStemF,
      },
      Turnover: {
        Bark: speciesForest.turnFracBarkF,
        Branch: speciesForest.turnFracBranF,
        CoarseRoots: speciesForest.turnFracCortF,
        FineRoots: speciesForest.turnFracFirtF,
        Leaf: speciesForest.turnFracLeafF,
      },
      Resistant: {
        Bark: speciesForest.rFracBarkF,
        Branch: speciesForest.rFracBranF,
        CoarseRoots: speciesForest.rFracCortF,
        FineRoots: speciesForest.rFracFirtF,
        Leaf: speciesForest.rFracLeafF,
        Stem: speciesForest.rFracStemF,
      },
      DecomposableBreakdown: {
        BarkLitter: speciesForest.bkdnFracDBlitF,
        Deadwood: speciesForest.bkdnFracDDdwdF,
        ChoppedWood: speciesForest.bkdnFracDChwdF,
        LeafLitter: speciesForest.bkdnFracDLlitF,
        CoarseDeadRoots: speciesForest.bkdnFracDCodrF,
        FineDeadRoots: speciesForest.bkdnFracDFidrF,
      },
      ResistantBreakdown: {
        BarkLitter: speciesForest.bkdnFracRBlitF,
        Deadwood: speciesForest.bkdnFracRDdwdF,
        ChoppedWood: speciesForest.bkdnFracRChwdF,
        LeafLitter: speciesForest.bkdnFracRLlitF,
        CoarseDeadRoots: speciesForest.bkdnFracRCodrF,
        FineDeadRoots: speciesForest.bkdnFracRFidrF,
      },
    };

    return returnTree;
  }

  public buildRotationEventQueue(
    rotationFormGroup: AbstractControl<RotationForest>,
    regime: Regime
  ): void {
    const moment = TimeUtilities.getMoment();
    // Assume rotationStartDate is already adjusted to include rotation.Offset
    // Getting the simulation start date as a Moment object
    const rotationStartDate = moment(this.simulationService.getStartDate()).add(
      rotationFormGroup.get("Offset").value,
      "days"
    );
    rotationFormGroup
      .get("RotationStartDate")
      .setValue(rotationStartDate.toDate());

    regime.DefaultRotation.forEach((rotationEvent) => {
      const regimeEventId = rotationEvent.RegimeEventReference.Id;
      const regimeEvent = regime.AvaliableEvents.find(
        (e) => e.Id === regimeEventId
      );

      if (!regimeEvent) {
        console.error("Regime event not found");
        return;
      }

      rotationEvent.RegimeEventReference = {
        Id: regimeEvent.Id,
        Name: regimeEvent.Name,
        Value: regimeEvent,
      };

      const newRotationEventService = this.cloneRotationEvent(
        rotationEvent,
        rotationFormGroup
      );

      newRotationEventService.year = rotationEvent.Year;
      newRotationEventService.day = rotationEvent.Day;
      // newRotationEvent.OffsetSubsequentOnDateChange = false;
      const eventDate = moment(rotationStartDate)
        .add(newRotationEventService.year, "years")
        .add(newRotationEventService.day, "days");

      newRotationEventService.offset = eventDate.diff(
        rotationStartDate,
        "days"
      );

      const rotationEventCollection = rotationFormGroup.get(
        "RotationEventCollection"
      );

      rotationEventCollection.setValue([
        ...rotationEventCollection.value,
        newRotationEventService,
      ]);

      // //Calculating the event date using the rotation start date
      // const eventDate = rotationStartDate
      //   .clone()
      //   .add(newRotationEvent.Year, "years")
      //   .add(newRotationEvent.Day, "days");

      // //Calculating the offset as the difference in days between the event date and the rotation start date
      // newRotationEvent.Offset = eventDate.diff(rotationStartDate, "days");
      // rotation.RotationEventCollection.push(newRotationEvent);
    });
  }

  // // Copy Default Rotation from the selected Regime
  // DateTime rotationStartDate = _ncatStand.Management.StartDate.AddDays(rotation.Offset).Date;
  // foreach (RotationEvent rotationEvent in regime.DefaultRotation)
  // {
  //     int regimeEventId = rotationEvent.RegimeEventReference.Id;
  //     var regimeEvent = regime.AvailableEvents.FirstOrDefault<RegimeEvent>(e => e.Id == regimeEventId);
  //     rotationEvent.RegimeEventReference = new ObjectReference<RegimeEvent, int>
  //                                              {
  //                                                  Id = regimeEvent.Id,
  //                                                  Name = regimeEvent.Name,
  //                                                  Value = regimeEvent
  //                                              };

  //     RotationEvent newRotationEvent = CloneRotationEvent(rotationEvent);
  //     newRotationEvent.OffsetSubsequentOnDateChange = false;
  //     newRotationEvent.Year = rotationEvent.Year;
  //     newRotationEvent.Day = rotationEvent.Day;

  //     DateTime eventDate =
  //         rotationStartDate.AddYears(newRotationEvent.Year).AddDays(newRotationEvent.Day).Date;
  //     newRotationEvent.Offset = (eventDate - rotationStartDate).Days;
  //     rotation.RotationEventCollection.Add(newRotationEvent);
  //}

  public cloneRotationEvent(
    originalRotationEvent: RotationEvent,
    rotationFormGroup: AbstractControl<RotationForest>
  ): any {
    let rotationService;
    const managementService = this.simulationService.managementService;

    rotationService = this.eventService.getEventClassTypeByAttributes(
      originalRotationEvent,
      rotationFormGroup,
      managementService
    );
    return rotationService;
  }

  public moveEventWithOffset(
    rotationFormGroup: FormGroup,
    rotationEventService: RotationEventService,
    dateNew: Date,
    dateOld: Date
  ) {
    const moment = TimeUtilities.getMoment();
    const offsetAdjustment = moment(dateNew)
      .startOf("day")
      .diff(moment(dateOld).startOf("day"), "days");
    const managementService = this.simulationService.managementService;

    const simStartDate = managementService.getStartDate();
    const simEndDate = managementService.getEndDate();
    const rotationsFormArray = managementService.getRotations();

    // Adjust Management Start & End dates
    if (offsetAdjustment < 0) {
      managementService.setStartDate(
        moment(simStartDate).add(offsetAdjustment, "days").toDate()
      );
      managementService.setEndDate(
        moment(simEndDate).add(offsetAdjustment, "days").toDate()
      );
    }

    // Adjust Rotations
    const rotationsGreaterThan = rotationsFormArray.controls.filter((r) => {
      const offset = r.get("Offset").value;
      return offset > rotationFormGroup.get("Offset").value;
    });

    rotationsGreaterThan.forEach((rotationGreaterThan) => {
      const offsetControl = rotationGreaterThan.get("Offset");
      let offset = offsetControl.value;

      if (offsetAdjustment < 0) {
        offsetControl.setValue((offset += -offsetAdjustment));
      } else {
        offsetControl.setValue((offset += offsetAdjustment));
      }

      //adjust rotationStart date to trigger its events date change
      const rotationStartDate = moment(
        this.simulationService.getStartDate()
      ).add(rotationGreaterThan.get("Offset").value, "days");
      rotationGreaterThan
        .get("RotationStartDate")
        .setValue(rotationStartDate.toDate());
    });

    // Adjust Events in rotation modified

    const oldEventOffset = rotationEventService.offset;
    const rotationEventServices = rotationFormGroup.get(
      "RotationEventCollection"
    ).value;
    let found = false;

    for (const r of rotationEventServices) {
      if (r === rotationEventService) {
        found = true;
        if (offsetAdjustment < 0) {
          continue;
        }
      }

      if (found && r.offset >= oldEventOffset) {
        r.offset += offsetAdjustment < 0 ? -offsetAdjustment : offsetAdjustment;
      }
    }

    this.isMovingEvent = false;
  }

  public getRotationLength(
    rotationEventCollection: RotationEventService[]
  ): number {
    if (!rotationEventCollection || rotationEventCollection.length === 0) {
      return 0;
    }

    const maxEventOffset = Math.max(
      ...rotationEventCollection.map((rev) => rev.offset)
    );
    return maxEventOffset;
  }

  private userRotationsChanged(
    newRotations: FormGroup[],
    action: UserRotationsChangedAction
  ): void {
    const managementService = this.simulationService.managementService;
    const rotations = managementService.getRotations();
    const moment = TimeUtilities.getMoment();

    switch (action) {
      case "Add": {
        newRotations.forEach((newRotation) => {
          let rotationStartDate: Date = null;
          if (rotations.length - 1 > 0) {
            const lastRot = rotations.controls[rotations.length - 2].value;

            rotationStartDate = moment(managementService.getStartDate())
              .add(
                lastRot.Offset +
                  this.getRotationLength(lastRot.RotationEventCollection) +
                  1,
                "days"
              )
              .toDate();
          } else {
            rotationStartDate = managementService.getStartDate();
          }
          newRotation.get("RotationStartDate").setValue(rotationStartDate);
          newRotation
            .get("Offset")
            .setValue(
              moment(rotationStartDate).diff(
                managementService.getStartDate(),
                "days"
              )
            );
        });

        break;
      }
      // case NotifyCollectionChangedAction.Reset:
      //     {
      //         _managementViewModel.RotationViewModels.Clear();
      //         // Collection Clear doesn't work the way expected. Collection is already cleared when it gets here
      //         // and old items is empty. So use one of our existing dictionaries to find rotations
      //         // and clear all required with those.
      //         // old - foreach (Rotation rotation in _standController.Stand.Management.UserRotations)
      //         foreach (Rotation rotation in _rotationViewModels.Keys)
      //         {
      //             _standController.ViewModelList.Remove(_rotationViewModels[rotation]);
      //             _standController.ViewModelList.Remove(_rotationPropertiesViewModels[rotation]);
      //             _standController.ViewModelList.Remove(_rotationEventListViewModels[rotation]);
      //             _rotationControllers[rotation].Detach();
      //         }
      //         _rotationViewModels.Clear();
      //         _rotationPropertiesViewModels.Clear();
      //         _rotationEventListViewModels.Clear();
      //         _rotationControllers.Clear();
      //     }
      //     break;
      // default:
      //     throw new NotSupportedException("This kind of rotation collection change is not supported.");
    }
    // SetRotationEventQueueErrors();
  }

  public addUserRotation(
    regime: RegimeForest,
    buildEventQueue: boolean
  ): FormGroup {
    if (regime == null) {
      return null;
    }

    const userSpecies = this.buildTree(regime.SpeciesReference.Value);

    //   // add to user tress collection
    //   if (!_ncatStand.Trees.Contains(userSpecies))
    //       _ncatStand.Trees.Add(userSpecies);

    //   // Set up new Rotation
    const rotation: RotationForest = {
      Id: Utilities.uuid(),
      RegimeReference: {
        Id: regime.RegimeID,
        Name: regime.Name,
        Value: regime,
      },
      UserSpeciesReference: {
        Id: userSpecies.SpeciesReference.Id,
        Name: userSpecies.Name,
        Value: userSpecies,
      },
      SpeciesTYFInputs: {
        G: +regime.TreeGrowthProperties.G,
        r: +regime.TreeGrowthProperties.r,
        Error: "", // Default or computed value for Error
      },
      RotationEventCollection: [],
      Offset: 0,
      RotationStartDate: null,
      IsValid: true,
    };

    const rotationFormGroup = this.createRotationFormGroup(rotation);

    this.simulationService.managementService
      .getRotations()
      .push(rotationFormGroup);

    //   _ncatStand.Management.UserRota tions.Add(rotation);

    if (buildEventQueue) {
      this.buildRotationEventQueue(rotationFormGroup, regime);
      this.applyEventSort(rotationFormGroup);
      this.userRotationsChanged([rotationFormGroup], "Add");
    }

    this.simulationService.managementService.setSpecies({
      [regime.SpeciesReference.Id]: regime.SpeciesReference.Value,
    });

    this.simulationService.managementService.setRegime(regime);

    this.simulationService.managementService.setTree({
      [userSpecies.SpeciesReference.Id]: userSpecies,
    });
    //   // add to global lists of species and regimes
    //   if (_ncatStand.SpeciesList.FirstOrDefault(s => s.Id == regime.SpeciesReference.Id) == null)
    //       _ncatStand.SpeciesList.Add(regime.SpeciesReference.Value);
    //   if (_ncatStand.RegimesList.FirstOrDefault(r => r.RegimeID == regime.RegimeID) == null)
    //       _ncatStand.RegimesList.Add(regime);
    return rotationFormGroup;
  }

  public cloneRotation(rotationForest: RotationForest) {
    let rotationFormGroup = this.addUserRotation(
      rotationForest.RegimeReference.Value as RegimeForest,
      false
    );

    rotationForest.RotationEventCollection.forEach((originalEvent) => {
      const newRotationEventService = this.cloneRotationEvent(
        originalEvent.rotationEvent,
        rotationFormGroup
      );
      newRotationEventService.offsetSubsequentOnDateChange =
        originalEvent.offsetSubsequentOnDateChange;
      newRotationEventService.offset = originalEvent.offset;
      newRotationEventService.year = originalEvent.year;
      newRotationEventService.day = originalEvent.day;

      const rotationEventCollection = rotationFormGroup.get(
        "RotationEventCollection"
      );

      rotationEventCollection.setValue([
        ...rotationEventCollection.value,
        newRotationEventService,
      ]);
    });

    this.userRotationsChanged([rotationFormGroup], "Add");

    // Rotation rotation = AddUserRotation(originalRotation.RegimeReference.Value as RegimeForest, false);
    // rotation.RotationEventCollection.Clear();

    // // Copy value across

    // RotationForest rotationForest = rotation as RotationForest;

    // rotationForest.SpeciesTYFInputs.G = ((RotationForest)originalRotation).SpeciesTYFInputs.G;

    // rotationForest.SpeciesTYFInputs.r = ((RotationForest)originalRotation).SpeciesTYFInputs.r;

    // // Copy User Rotation events from the original Rotation

    // DateTime rotationStartDate = _ncatStand.Management.StartDate.AddDays(rotation.Offset).Date;
    // foreach (RotationEvent originalRotationEvent in originalRotation.RotationEventCollection)
    // {
    //     // TODO: Check if I need to clone the Regime stuff as well - or is there only one copy of each regime kept
    //     RotationEvent newRotationEvent = CloneRotationEvent(originalRotationEvent);
    //     newRotationEvent.OffsetSubsequentOnDateChange = originalRotationEvent.OffsetSubsequentOnDateChange;
    //     newRotationEvent.Offset = originalRotationEvent.Offset;
    //     newRotationEvent.Year = originalRotationEvent.Year;
    //     newRotationEvent.Day = originalRotationEvent.Day;
    //     rotation.RotationEventCollection.Add(newRotationEvent);
    // }
  }

  public deleteRotations(rotationForests: RotationForest[]) {
    const managementService = this.simulationService.managementService;

    if (!managementService.getRotations().length) {
      return;
    }

    rotationForests.forEach((rotationForest) => {
      // const rotationFormGroup = this.getRotationFormGroup(
      //   rotationForest
      // ) as AbstractControl<RotationForest>;

      // // calculate the offset adjsutment for every subsequent user rotation
      const offsetAdjustment =
        this.getRotationLength(rotationForest.RotationEventCollection) + 1;

      const subRotations = managementService
        .getRotations()
        .controls.filter((r) => r.get("Offset").value > offsetAdjustment);
      subRotations.forEach((subRotation) => {
        const offsetContorl = subRotation.get("Offset");
        offsetContorl.setValue(offsetContorl.value - offsetAdjustment);
      });

      // Tree tree = rotation.UserSpeciesReference.Value;

      // // remove from user tress collection if only single reference to this tree
      // if (_ncatStand.Management.UserRotations.Count(s => s.UserSpeciesReference.Id == regime.SpeciesReference.Id) == 1)
      //     _ncatStand.Trees.Remove(tree);

      // // remove from server cache data for regime and species if only single reference to this species
      // if (_ncatStand.Management.UserRotations.Count(s => s.UserSpeciesReference.Id == regime.SpeciesReference.Id) == 1)
      //     _ncatStand.SpeciesList.Remove(regime.SpeciesReference.Value);
      // if (_ncatStand.Management.UserRotations.Count(s => s.RegimeReference.Id == regime.RegimeID) == 1)
      //     _ncatStand.RegimesList.Remove(regime);

      // _ncatStand.Management.UserRotations.Remove(rotation);
      managementService
        .getRotations()
        .removeAt(
          managementService
            .getRotations()
            .controls.findIndex((r) => r.get("Id").value == rotationForest.Id)
        );

      // now adjust every subsequent user rotation
    });
  }

  public resetRotation(rotationForest: RotationForest) {
    const rotationFormGroup = this.getRotationFormGroup(
      rotationForest
    ) as AbstractControl<RotationForest>;
    //the inputs below is not editable anyway
    // // Reset G & r
    // if (row.RegimeReference.Value !== null) {
    //   const rotationForest = row as RotationForest;
    //   const regimeReference = row.RegimeReference.Value as RegimeForest;
    //   rotationForest.SpeciesTYFInputs.G = regimeReference.TreeGrowth.G;
    //   rotationForest.SpeciesTYFInputs.r = regimeReference.TreeGrowth.r;
    // }
    // Clear & rebuild event lists
    rotationFormGroup.get("RotationEventCollection").setValue([]);
    this.buildRotationEventQueue(
      rotationFormGroup,
      rotationForest.RegimeReference.Value
    );
  }

  private getRotationFormGroup(
    rotationForest: RotationForest
  ): AbstractControl<RotationForest> {
    const managementService = this.simulationService.managementService;

    return managementService
      .getRotations()
      .controls.find((r) => r.get("Id").value == rotationForest.Id);
  }

  private setRotationEventQueueErrors() {
    let errorMessage = "";
    // _managementViewModel.IsValidEvents = CheckRotationEvents(ref ErrorMessage);
    // _managementViewModel.EventErrorMessage = ErrorMessage;
  }

  public checkRotationEvents(): { isValid: boolean; errorMessage: string } {
    const managementService = this.simulationService.managementService;
    const moment = TimeUtilities.getMoment();

    let response = { isValid: true, errorMessage: "" };
    let rotationErrorSet = false;
    let treeExists = false; // initial conditions
    let rotationEventInError = null;
    let rotationInError = null;

    let eventEventOk = true;
    const rotations = this.getSortRotationAndEventsByDate(
      Utilities.cloneDeep(managementService.getRotations().value)
    );
    rotations.forEach((rotation: RotationForest) => {
      const eventCollections = rotation.RotationEventCollection;

      for (const rotationEventService of eventCollections) {
        // if an event error has been found already we dont need to check anything else
        if (eventEventOk == false) {
          rotationEventService.isValid = true;
          continue;
        }

        // ****** Handle planting events ******
        if (
          rotationEventService instanceof RotationTreePlantingService ||
          rotationEventService instanceof RotationTreeRegenerationService
        ) {
          if (treeExists) {
            response.errorMessage = this.rotationEventCollectionErrors["0"];
            rotationEventInError = rotationEventService;
            rotationInError = rotation;
            eventEventOk = false;
          } else {
            treeExists = true;
          }
        }
        // ****** Handle thin events ******
        else if (
          rotationEventService instanceof RotationClearingThinService ||
          rotationEventService instanceof RotationTreeLossService ||
          rotationEventService instanceof
            RotationCoppiceHarvestingThinService ||
          rotationEventService instanceof RotationPestAndDiseaseService ||
          rotationEventService instanceof RotationHarvestingThinService ||
          rotationEventService instanceof RotationLimbPruningService
        ) {
          if (!treeExists) {
            rotationEventInError = rotationEventService;
            rotationInError = rotation;
            response.errorMessage = this.rotationEventCollectionErrors["1"];
            eventEventOk = false;
          }
          if (rotationEventService.isClearing) {
            treeExists = false;
          }
        }

        // ****** Handle other events ******
        else if (rotationEventService instanceof RotationForestFireService) {
          // TODO: does this require trees to exist?
          if (rotationEventService.isClearing) {
            treeExists = false;
          }
        } else if (
          rotationEventService instanceof RotationFertilizationService ||
          rotationEventService instanceof RotationWeedControlService
        ) {
          if (!treeExists) {
            rotationEventInError = rotationEventService;
            rotationInError = rotation;
            if (rotationEventService instanceof RotationWeedControlService)
              response.errorMessage = this.rotationEventCollectionErrors["4"];
            else if (
              rotationEventService instanceof RotationFertilizationService
            )
              response.errorMessage = this.rotationEventCollectionErrors["5"];
            else
              response.errorMessage = this.rotationEventCollectionErrors["2"];
            eventEventOk = false;
          }
        } else if (
          rotationEventService instanceof RotationChopperRollerService
        ) {
          if (treeExists) {
            rotationEventInError = rotationEventService;
            rotationInError = rotation;
            response.errorMessage = this.rotationEventCollectionErrors["3"];
            eventEventOk = false;
          }
        } else if (
          rotationEventService instanceof RotationTermiteChangeService
        ) {
          // TODO: should this need a tree?
        } // RotationEventDefaultViewModel
        else {
          // nothing to do here
        }
        // rotationEventDefaultViewModel.IsValidEvents = eventEventOk; // set current valid status to true

        rotationEventService.isValid = rotationEventInError ? false : true;
      }

      if (rotationEventInError && !eventEventOk) {
        this.erorrRotationId = rotation.Id;
        //  rotation.get("IsValid").setValue(false);
        //rotationViewModel.IsValidEvents = false;
      } else {
        this.erorrRotationId = null;
        //   rotation.get("IsValid").setValue(true);
        // rotationViewModel.IsValidEvents = true;
      }
    });

    if (rotationEventInError !== null && !eventEventOk) {
      let eventDetails = "";
      if (rotationEventInError.rotationEvent?.RegimeEventReference?.Value) {
        const regimeEventReference =
          rotationEventInError.rotationEvent?.RegimeEventReference?.Value;
        const regimeGroup = regimeEventReference.Group;
        const regimeName = regimeEventReference.Name;
        eventDetails = `Group:${regimeGroup} \n Name: ${regimeName} `;
      }

      const errorDate = moment(rotationEventInError.getEventDate());
      response.errorMessage = `${
        response.errorMessage
      } \n on ${errorDate.format("DD/MM/YYYY")}\n ${eventDetails}`;

      this.eventErrorMessage$.next(response.errorMessage);
      this.errorEventId = rotationEventInError.id;
    } else {
      this.eventErrorMessage$.next(null);
      this.errorEventId = null;
    }

    response.isValid = eventEventOk;

    return response;
  }

  public getLastRotation(): AbstractControl {
    const managementService = this.simulationService.managementService;
    const rotationsFormArray = managementService.getRotations();
    return rotationsFormArray.controls[rotationsFormArray.length - 1];
  }
  public isLastRotation(id): boolean {
    const lastRotation = this.getLastRotation();
    const rId = lastRotation.get("Id").value;
    return rId == id;
  }

  public deleteRotationEvent(id, rotationFormGroup) {
    let eventCollection = rotationFormGroup.get(
      "RotationEventCollection"
    ).value;
    const deletingEventIndex = eventCollection.findIndex((e) => e.id == id);
    if (deletingEventIndex > -1) {
      eventCollection.splice(deletingEventIndex, 1);
      rotationFormGroup
        .get("RotationEventCollection")
        .setValue(eventCollection);
    }
  }

  public applyEventSort(rotationFormGroup) {
    const prop = this.getEventSort()[0].prop;
    const dir = this.getEventSort()[0].dir;

    const getPropValue = (re: RotationEventService) => {
      //map prop to formGorup property
      switch (prop) {
        case "date":
          return re.getEventDate();
        case "group":
          return re.regimeEventReference?.Value?.Group;
        case "name":
          return re.regimeEventReference?.Value?.Name;
        case "intensity":
          return re.getIntensity();
      }
    };
    const getName = (re: RotationEventService) =>
      re.regimeEventReference?.Value?.Name || "";

    const sortedEventCollections = rotationFormGroup
      .get("RotationEventCollection")
      .value.sort((a, b) => {
        const valueA = getPropValue(a);
        const valueB = getPropValue(b);

        if (valueA < valueB) return dir === "asc" ? -1 : 1;
        if (valueA > valueB) return dir === "asc" ? 1 : -1;

        const nameA = getName(a);
        const nameB = getName(b);
        if (nameA < nameB) return dir === "asc" ? -1 : 1;
        if (nameA > nameB) return dir === "asc" ? 1 : -1;
        return 0;
      });

    rotationFormGroup
      .get("RotationEventCollection")
      .setValue([...sortedEventCollections]);
  }

  public applyRotationSort() {
    const prop = this.getRotationSort()[0].prop;
    const dir = this.getRotationSort()[0].dir;

    const getPropValue = (fg: AbstractControl) => {
      //map prop to formGorup property
      switch (prop) {
        case "startDate":
          return fg.get("RotationStartDate").value;

        case "rotationName":
          return fg.get("RegimeReference").value?.Name || "";
        default:
          break;
      }
    };

    const rotations = this.simulationService.managementService.getRotations();
    const sortedArray = rotations.controls.slice().sort((a, b) => {
      const valueA = getPropValue(a);
      const valueB = getPropValue(b);

      if (valueA < valueB) return dir === "asc" ? -1 : 1;
      if (valueA > valueB) return dir === "asc" ? 1 : -1;
      return 0;
    });

    rotations.clear();
    sortedArray.forEach((r) => rotations.push(r));
  }

  public getSortRotationAndEventsByDate(
    rotations: RotationForest[]
  ): RotationForest[] {
    const dir = "asc";
    const sortedRotations = rotations.sort((a, b) => {
      const valueA = a.RotationStartDate;
      const valueB = b.RotationStartDate;

      if (valueA < valueB) return dir === "asc" ? -1 : 1;
      if (valueA > valueB) return dir === "asc" ? 1 : -1;

      const nameA = a.RegimeReference?.Name || "";
      const nameB = b.RegimeReference?.Name || "";
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    sortedRotations.forEach((rotation) => {
      if (rotation.RotationEventCollection) {
        rotation.RotationEventCollection.sort((a, b) => {
          const valueA = a.getEventDate();
          const valueB = b.getEventDate();

          if (valueA < valueB) return dir === "asc" ? -1 : 1;
          if (valueA > valueB) return dir === "asc" ? 1 : -1;

          const nameA = a.regimeEventReference?.Value?.Name || "";
          const nameB = b.regimeEventReference?.Value?.Name || "";
          return nameA.localeCompare(nameB);
        });
      }
    });

    return sortedRotations;
  }

  public writeXmlObject() {
    const rotations =
      this.simulationService.managementService.getRotations().value;

    const xmlTemplate = {
      Rotation: rotations.map((r) => {
        let rotationTemplate = {
          $: { "xsi:type": "RotationForest" },
          RegimeReference: {},
          RotationEventCollection: { RotationEvent: [] },
          UserSpeciesReference: {},
          SpeciesTYFInputs: {},
        };

        //regime reference
        rotationTemplate.RegimeReference = {
          Id: r.RegimeReference.Id,
          Name: r.RegimeReference.Name,
        };

        //SpeciesTYFInputs
        rotationTemplate.SpeciesTYFInputs = {
          G: Utilities.halfRoundUp(r.SpeciesTYFInputs.G, 5),
          r: Utilities.halfRoundUp(r.SpeciesTYFInputs.r, 5),
        };
        //UserSpeciesReference
        rotationTemplate.UserSpeciesReference = {
          Id: r.RegimeReference.Value.SpeciesReference.Id,
          Name: r.RegimeReference.Value.SpeciesReference.Name,
        };

        rotationTemplate.RotationEventCollection.RotationEvent =
          r.RotationEventCollection.map((e) => {
            return e.writeXmlObject();
          });

        return rotationTemplate;
      }),
    };

    return xmlTemplate;
  }

  public reset() {
    this.isMovingEvent = false;

    this.selectedState$.next(this.defaultSelectedState);

    this.eventErrorMessage$.next("");
    this.erorrRotationId = null;
    this.errorEventId = null;
  }
}
