<div class="row">
  <div class="header">
    <div></div>
    <div class="header-button-group">
      <button
        *ngIf="hasBrokenLinkPlotFiles()"
        (click)="relinkPlotFiles()"
        type="button"
        class="btn btn-outline-primary-darken btn-sm"
      >
        Relink plot files
      </button>
      <button
        *ngIf="hasPlotFiles()"
        (click)="validateEstatePlots()"
        type="button"
        class="btn btn-outline-primary-darken btn-sm"
      >
        Validate Estate Plots
      </button>
      <button
        (click)="createEstatePlot()"
        type="button"
        class="btn btn-primary-darken btn-sm"
      >
        New estate plot
      </button>
    </div>
  </div>

  <div class="content">
    <div class="table-controls">
      <div class="button-group-container">
        <div class="button-group" *ngIf="selectedRows?.length">
          <select
            class="form-control form-select form-select-sm"
            [(ngModel)]="selectedBulkAction"
          >
            <option value="clone">Clone</option>
            <option value="delete">Delete</option>

            <option value="simTrue">Simulate plots</option>
            <option value="simFalse">Do not simulate plots</option>
            <option value="estTrue">Use Estate timing</option>
            <option value="estFalse">Use Plot timing</option>
          </select>
          <fc-button
            [isLoading$]="isBulkActionLoading$"
            [inputText]="'Apply'"
            (click)="bulkAction()"
          ></fc-button>
        </div>
      </div>
      <div class="button-group-container">
        <div class="button-group-container">
          <div class="button-group">
            <input
              id="plot-file-search"
              aria-label="Search plot file name"
              class="form-control form-control-sm"
              placeholder="Search plot file name"
              [(ngModel)]="searchString"
              (keyup.enter)="updateFilter()"
            />
            <button
              id="clear-plot-file-search"
              class="btn btn-primary-darken btn-sm"
              (keyup.enter)="updateFilter()"
              (click)="updateFilter()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <ngx-datatable
      #plotTable
      class="custom-table"
      [rows]="tempEstatePlots"
      [columnMode]="ColumnMode.force"
      [headerHeight]="56"
      [footerHeight]="56"
      rowHeight="56"
      [selected]="selectedRows"
      [selectionType]="SelectionType.checkbox"
      (select)="onSelect($event)"
      [limit]="10"
    >
      <ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              allRowsSelected ? "Uncheck all" : "Check all"
            }}</span>
            <input
              type="checkbox"
              [checked]="allRowsSelected"
              (change)="selectFn(!allRowsSelected)"
            />
            <!--custom function to skip already selected rows -->
            <!-- <input
                *ngIf="selectPlotFilesOnly"
                type="checkbox"
                [checked]="selectPlotFilesOnlyAllRowsSelected()"
                (change)="selectPlotFilesOnlySelectFn($event)"
              /> -->
            <span class="checkmark"></span>
          </label>
        </ng-template>

        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
        >
          <label class="check-container">
            <span class="visually-hidden">{{
              isSelected ? "Deselect" : "Select"
            }}</span>

            <input
              type="checkbox"
              [checked]="isSelected"
              (change)="onCheckboxChangeFn($event)"
            />
            <span class="checkmark"></span>
          </label>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="validationStatus"
        [width]="50"
        [canAutoResize]="false"
        [draggable]="false"
        [sortable]="false"
        [resizeable]="false"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
        </ng-template>

        <ng-template ngx-datatable-cell-template let-value="value">
          <span
            class="status"
            [style]="{ 'background-color': getStatusLegend(value)?.color }"
            [tooltip]="getStatusLegend(value)?.label"
          ></span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="startYrEP" [draggable]="false" [width]="30">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>Start year</span>
        </ng-template>

        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.estateTimingEP ? service.getStartYear() : value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="startStepEP" [draggable]="false" [width]="30">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>Start step</span>
        </ng-template>

        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.estateTimingEP ? service.getStartStep() : value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="estateTimingEP"
        [draggable]="false"
        [width]="30"
      >
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>Timing</span>
        </ng-template>

        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.estateTimingEP ? "Estate" : "Plot" }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="areaEP" [draggable]="false" [width]="30">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>Area [ha]</span>
        </ng-template>

        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          let-value="value"
        >
          {{ row.hasAreaEP ? row?.plotAreaEP : value }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="plotIxEP" prop="plotIxEP" [draggable]="false">
        <ng-template let-column="column" ngx-datatable-header-template>
          <span>File name</span>
        </ng-template>

        <ng-template ngx-datatable-cell-template let-row="row">
          {{ row.fileName }} {{ !row.plotId ? " - broken link" : "" }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Action"
        [width]="130"
        [canAutoResize]="false"
        [draggable]="false"
      >
        <ng-template ngx-datatable-cell-template let-row="row">
          <div class="action-buttons">
            <button
              class="btn btn-sm btn-rounded"
              (click)="editEstateForm(row)"
              [adaptivePosition]="false"
              tooltip="Edit"
              container="body"
            >
              <span class="visually-hidden">edit</span>
              <i class="bi bi-pencil-fill"></i>
            </button>
            <button
              class="btn btn-sm btn-rounded"
              (click)="cloneEstatePlots([row])"
              [adaptivePosition]="false"
              tooltip="Clone"
              container="body"
            >
              <span class="visually-hidden">clone</span>
              <i class="bi bi-files"></i>
            </button>

            <button
              class="btn btn-sm btn-rounded"
              (click)="deletePlotFiles([row])"
              [adaptivePosition]="false"
              tooltip="Delete"
              container="body"
            >
              <span class="visually-hidden">delete</span>
              <i class="bi bi-trash"></i>
            </button>

            <button
              class="btn btn-sm btn-rounded"
              [adaptivePosition]="false"
              tooltip="More actions"
              container="body"
              data-bs-toggle="dropdown"
            >
              <span class="visually-hidden">more actions</span>
              <i class="bi bi-three-dots-vertical"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  (click)="editPlotFile(row.plotId); $event.preventDefault()"
                  [class.disabled]="
                    row.plotId === null || row.plotId === undefined
                  "
                  >View
                </a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  (click)="
                    service.setSimulationFalgForPlots(true, [row.id]);
                    $event.preventDefault()
                  "
                  >Simulate plots</a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  (click)="
                    service.setSimulationFalgForPlots(false, [row.id]);
                    $event.preventDefault()
                  "
                  >Do not simulate plots</a
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  (click)="
                    service.setEstateTimingUsedForPlots(true, [row.id]);
                    $event.preventDefault()
                  "
                  >Use Estate timing</a
                >
              </li>

              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  (click)="
                    service.setEstateTimingUsedForPlots(false, [row.id]);
                    $event.preventDefault()
                  "
                  >Use Plot timing</a
                >
              </li>
            </ul>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
