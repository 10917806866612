import { Injectable } from "@angular/core";
import { BaseRotationEventService } from "./base-rotation-event.service";

@Injectable({
  providedIn: "root",
})
export class RotationTreeLossService extends BaseRotationEventService {
  private percentTreesKilled = 0.0;

  public _type = "RotationTreeLoss";

  public getIntensity() {
    if (this.regimeEventReference.Value?.FullCAMThin.fracAfctThin) {
      this.percentTreesKilled =
        this.regimeEventReference.Value.FullCAMThin.fracAfctThin;
    }

    return `Percentage Trees Affected: ${(
      this.percentTreesKilled * 100
    ).toFixed(2)}%`;
  }
}
