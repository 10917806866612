import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import * as xml2js from "xml2js";
import { Constants } from "../constants";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public async convertXmlToJson(xml): Promise<Object> {
    const parser = new xml2js.Parser({
      trim: true,
      explicitArray: true,
    });
    let json;
    await parser.parseString(xml, (err, result) => {
      json = result;
    });
    return json;
  }

  public async readFile(file): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const fileExtension = file.name.split(".").pop();
      const plotFormat = Constants.PLOT_FORMATS;

      if (!plotFormat.includes(fileExtension)) {
        return reject({
          type: "danger",
          msg: `Only ${Constants.PLOT_FORMATS.join(
            ", "
          )} file formats accepted. `,
          dismissible: true,
        });
      }

      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = (event) => {
        const xmlDoc: string | ArrayBuffer = event.target.result;
        // this.decodeBinary(xmlDoc);
        const isBinaryFile = this.isBinaryFile(String(xmlDoc));
        if (isBinaryFile) {
          return reject({
            type: "danger",
            msg: `The imported file might be a 2016 plot file, which is not currently supported.`,
            dismissible: true,
          });
        }
        return resolve(xmlDoc);
      };

      fileReader.onerror = (err) => {
        return reject({
          type: "danger",
          msg: `Something went wrong while reading the file. `,
          dismissible: true,
        });
      };

      fileReader.readAsBinaryString(file);
    });
  }

  public isBinaryFile(fileReaderResult: string): boolean {
    const nonPrintableASCIIRegex = /[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x9F]/g;
    return fileReaderResult.match(nonPrintableASCIIRegex)?.length > 0;
  }

  public decodeBinary(data) {
    // console.log(data);
    const binaryData = new Uint8Array(data);
    // console.log(binaryData);
    // const encodings = ["utf-8"];

    // let xmlString = "";

    // for (const encoding of encodings) {
    //   try {
    //     const decoder = new TextDecoder(encoding);
    //     xmlString = decoder.decode(binaryData);
    //     console.log(`Decoded with ${encoding} :`, xmlString);
    //   } catch (error) {
    //     console.log("Failed to decode:", error);
    //   }
    // }
    const nonTextCharacters = [];
    let text = "";
    for (let i = 0; i < binaryData.length; i++) {
      const byte = binaryData[i];

      if (byte >= 32 && byte < 127) {
        text += String.fromCharCode(byte);
      } else {
        const last20Characters = String(text).substr(text.length - 20);
        nonTextCharacters.push({ position: i, value: byte });
      }
    }
  }

  public downloadFile(
    fileName: string,
    content: string,
    type: string = "text/plain"
  ): void {
    const pseudoelement = document.createElement("a");
    const blob = new Blob([content], { type });

    pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
    pseudoelement.setAttribute("download", fileName);

    pseudoelement.dataset.downloadurl = [
      type,
      pseudoelement.download,
      pseudoelement.href,
    ].join(":");
    pseudoelement.draggable = true;
    pseudoelement.classList.add("dragout");

    pseudoelement.click();

    setTimeout(() => {
      pseudoelement.remove();
    }, 1);
  }

  public createFile(
    fileName: string,
    content: string,
    type: string = "text/plain"
  ): File {
    const blob = new Blob([content], { type });
    return new File([blob], fileName, { type });
  }

  public convertJsonToXmlString(jsonObject) {
    const builder = new xml2js.Builder();

    const xml = builder.buildObject(jsonObject);
    return xml;
  }

  public convertXmlStringToFileObject(
    xmlString,
    fileNameIncludingExtension
  ): File {
    let blob = new Blob([xmlString], { type: "text/plain" });

    return new File([blob], fileNameIncludingExtension, {
      type: "text/plain'",
    });
  }

  public getTodayDateTimeForFileName() {
    return moment().format("DD_MM_YYYY_h_mm_ss");
  }

  public dynamicLoadComponent(
    viewContainerRef: ViewContainerRef,
    component: any,
    inputs?: { key: string; value: any }[]
  ): ComponentRef<any> {
    viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<any>(component);
    inputs.forEach((i) => {
      componentRef.instance[i.key] = i.value;
    });
    return componentRef;
  }
}
