<ng-container *ngIf="category && !isScenarioOW()"
  ><h2 class="accordion-header">
    <div class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        (click)="toggleSelectedCategories()"
        class="btn select-button"
        [class]="{ selected: category.isSelected && !category.isIndeterminate }"
        aria-label="select"
      >
        <i
          class="bi"
          [class]="
            category.isIndeterminate
              ? 'bi-dash'
              : category.isSelected
              ? 'bi-check'
              : 'bi-plus'
          "
          role="img"
          aria-label="add"
        ></i>
      </button>

      <button
        class="btn accordion-button"
        (click)="toggleTreeExpansion(category)"
        [class]="{
          collapsed: !category.isExpanded
        }"
        type="button"
        [attr.aria-expanded]="category.isExpanded"
      >
        {{ category.label }}
      </button>
    </div>
  </h2>

  <div
    class="accordion-collapse collapse"
    [class]="{
      show: category.isExpanded
    }"
  >
    <div class="accordion-body">
      <ng-container *ngIf="category.items">
        <div class="output-item" *ngFor="let item of category.items">
          <button
            (click)="toggleSelectedItem(item)"
            class="btn select-button"
            aria-label="select"
            [class]="{ selected: item.isSelected }"
          >
            <i
              class="bi"
              [class]="item.isSelected ? 'bi-check' : 'bi-plus'"
              role="img"
              aria-label="add"
            ></i></button
          ><span
            >{{
              outputNameMap[item.prefixOI]
                ? outputNameMap[item.prefixOI]
                : outputNameMap[item.tOutputOI]
            }}
            {{ item.nmOI }}
            {{ outputNameMap[item.suffixOI] }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="category.subcategories?.length">
        <div class="accordion">
          <div
            class="accordion-item"
            fc-output-list-item
            *ngFor="let subCategory of category.subcategories"
            [category]="subCategory"
            [service]="service"
            [isParentSelected]="category.isSelected"
            [parent]="category"
            (selectedItemUpdated)="setSelectedCategory()"
          ></div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
