import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener,
  HostBinding,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ModalOptions } from "ngx-bootstrap/modal";
import { Observable, Subject, lastValueFrom } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import {
  CollectionTableColumns,
  FolderList,
  ProjectTableColumns,
} from "src/app/my-plots/models";
import { MessageService } from "../../services/message.service";
import { ModalService } from "../../services/modal.service";
import { ProjectFormComponent } from "../project-form/project-form.component";

@Component({
  selector: "fc-folder-select",
  templateUrl: "./folder-select.component.html",
  styleUrls: ["./folder-select.component.scss"],
})
export class FolderSelectComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  @HostBinding("class.is-disabled")
  isDisabled: boolean = false;

  @Input() selectedProjectName: UntypedFormControl = null;

  @Input() selectedCollectionName: UntypedFormControl = null;

  @Input() folders$: Observable<FolderList[]>;

  @Input() type: "project" | "collection";

  @Input() isLocked: boolean = false;

  @Input() isRMT: boolean = false;

  @Output() onRefreshFolders = new EventEmitter<void>();

  public selectedFolder: ProjectTableColumns | CollectionTableColumns = null;

  public folders: FolderList[] | CollectionTableColumns[] = [];

  public isDropdownOpen: boolean = false;

  public typeaHeadModel: string = "";

  @HostListener("document:click", ["$event"])
  handleClick(e: Event) {
    const clickedInside = this.hostElement.nativeElement.contains(e.target);
    if (!clickedInside) {
      this.isDropdownOpen = false;
      this.typeaHeadModel = "";
    }

    if (clickedInside) {
      if (this.type == "project") {
        this.selectedProjectName.markAsTouched();
      } else if (this.type == "collection" && !this.isDisabled) {
        this.selectedCollectionName.markAsTouched();
      }
    }
  }

  constructor(
    private hostElement: ElementRef,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  async ngOnInit(): Promise<void> {
    this.folders$.pipe(takeUntil(this.destroy$)).subscribe(async (f) => {
      if (this.type == "project" && Array.isArray(f)) {
        await this.applyInitProjectSettings(f);
      } else if (this.type == "collection") {
        await this.applyInitCollectionSettings();
      }
    });

    //if (this.type == "collection") {
    this.selectedProjectName.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (projectName) => {
        this.isDisabled = projectName == undefined || projectName == null;
        this.selectedFolder = null;
        this.selectedProjectName.markAsDirty();

        if (this.type == "collection") {
          this.selectedCollectionName.setValue(null);
          if (projectName) {
            const project = await this.getSelectedPorjectFolder();
            if (project) {
              this.folders = project.collectionInfo || [];
            }
          }
        }
      });
    //}
  }

  protected async applyInitProjectSettings(
    folders: FolderList[]
  ): Promise<void> {
    const projectName = this.selectedProjectName.value;
    this.folders = folders;
    //selectedProjectName.value
    if (projectName) {
      this.selectedFolder = await this.getSelectedPorjectFolder();

      if (this.isLocked && !this.selectFolder) {
        this.messageService.addAlert({
          type: "danger",
          msg: "Project no longer exists, please select again",
          dismissible: true,
        });
      }
    } else if (this.selectedFolder) {
      this.selectedProjectName.setValue(this.selectedFolder[this.getIdKey()]);
    }
  }

  protected async applyInitCollectionSettings(): Promise<void> {
    const projectName = this.selectedProjectName.value;
    const collectionName = this.selectedCollectionName.value;

    if (!projectName) {
      this.isDisabled = true;
    }

    if (projectName) {
      const project = await this.getSelectedPorjectFolder();
      if (project) {
        this.folders = project.collectionInfo;
      }
    }
    //collectionName takes precedence over selectedFolder
    if (collectionName) {
      this.selectedFolder = this.folders.find((f) => f.name == collectionName);
      if (this.isLocked && !this.selectedFolder) {
        this.messageService.addAlert({
          type: "danger",
          msg: "Collection no longer exists, please select again.",
          dismissible: true,
        });
      }
    } else if (this.selectedFolder) {
      this.selectedCollectionName.setValue(
        this.selectedFolder[this.getIdKey()]
      );
    }
  }

  protected getIdKey(): "name" {
    return "name";
    //return this.type == "collection" ? "collectionName" : "projectName";
  }

  /*** for collection type to get the project folder */
  protected async getSelectedPorjectFolder(): Promise<FolderList> {
    const folders = await lastValueFrom(this.folders$.pipe(take(1)));
    if (folders?.length) {
      return folders.find((p) => p.name == this.selectedProjectName.value);
    } else {
      //show error
      this.messageService.addAlert({
        type: "danger",
        msg: "no projects or collections found.",
        dismissible: true,
      });
      return;
    }
  }

  public onAutoCompleteSelect(event) {
    const id = event.item[this.getIdKey()];
    if (id !== this.selectedFolder[this.getIdKey()]) {
      this.selectFolder(event.item);
    } else {
      this.isDropdownOpen = false;
      this.typeaHeadModel = "";
    }
  }

  public selectFolder(folder) {
    if (this.type == "project") {
      this.selectedProjectName.setValue(folder.name);
      this.selectedProjectName.markAsDirty();
    } else if (this.type == "collection") {
      this.selectedCollectionName.setValue(folder.name);
      this.selectedCollectionName.markAsDirty();
    }
    this.selectedFolder = folder;
    this.isDropdownOpen = false;
    this.typeaHeadModel = "";
  }

  public async openProjectForm() {
    let associatedProject = null;
    if (this.type == "collection") {
      associatedProject = await this.getSelectedPorjectFolder();
    }

    const initialState: ModalOptions = {
      initialState: {
        isRMT: this.isRMT,
        type: this.type,
        formData: null,
        folders$: this.folders$,
        ...(this.type == "collection" && {
          associatedProject: associatedProject,
          lockProject: true,
        }),
      },
    };

    this.modalService
      .openModal(ProjectFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result && result[this.getIdKey()]) {
          this.selectedFolder = result[this.getIdKey()];

          this.type == "project"
            ? this.selectedProjectName.setValue(result.name)
            : this.selectedCollectionName.setValue(result.name);
          this.onRefreshFolders.emit();
        }
      });
  }

  public shouldDisableFolder(folder): boolean {
    if (!folder.name || !this.selectedFolder) {
      return false;
    }

    if (folder.name == this.selectedFolder?.name) {
      return true;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
