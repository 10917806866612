import { Component, OnInit } from "@angular/core";
import { FormArray } from "@angular/forms";
import { BaseFlowComponent } from "src/app/simulation/components/base-flow/base-flow.component";

@Component({
  selector: "fc-output-windows",
  templateUrl: "./output-windows.component.html",
  styleUrls: ["./output-windows.component.scss"],
})
export class OutputWindowsComponent extends BaseFlowComponent {
  ngOnInit(): void {
    super.ngOnInit();

    const outputWindows = this.formGroup.get("outputWindows") as FormArray;

    if (!outputWindows.controls.length) {
      this.service.addOutputWindow(true);
    } else {
      outputWindows.controls.forEach((ow) => this.service.buildTree(ow));
    }
  }
}
