import { Component, OnInit, Input } from "@angular/core";
import { AbstractControl, FormArray, FormGroup, UntypedFormGroup } from "@angular/forms";

import { take } from "rxjs/operators";
import { Subject } from "rxjs";

import { ModalOptions } from "ngx-bootstrap/modal";
import { ModalService } from "src/app/shared/services/modal.service";
import { AddScenarioFormComponent } from "../add-scenario-form/add-scenario-form.component";
import {
  ISortedScenarioArrayItem,
  PlotDigestScenario,
} from "src/app/plot/models";
import { CloneScenarioFormComponent } from "../clone-scenario-form/clone-scenario-form.component";
import { PlotFilesSelectComponent } from "src/app/shared/components/plot-files-select/plot-files-select.component";
import { OutputInfo } from "src/app/plot/output-windows/outputInfo";
import { PlotDigestService } from "src/app/shared/models";

@Component({
  selector: "fc-plot-digest-container",
  templateUrl: "./plot-digest-container.component.html",
  styleUrls: ["./plot-digest-container.component.scss"],
})
export class PlotDigestContainerComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  @Input()
  service: PlotDigestService;

  public formGroup: UntypedFormGroup;

  public sortedScenarioArray: ISortedScenarioArrayItem[] = [];
  public prefixMap = {};

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.prefixMap = OutputInfo.getInputNameMapByIndex();
    this.formGroup = this.service.getFormGroup();
  }

  get digestScenarios() {
    return this.formGroup.controls["digestScenarios"] as FormArray;
  }

  public addScenario() {
    const initialState: ModalOptions = {
      initialState: {},
    };

    this.modalService
      .openModal(AddScenarioFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result: PlotDigestScenario) => {
        if (result) {
          this.service.addScenario(result);
        }
      });
  }

  public setScenarioItem({ name, scenario, itemLabel, path }) {
    this.service.setScenarioItem({ name, scenario, itemLabel, path });
  }

  public setScenarioInput(itemLabel) {
    this.service.setDigestElementsByLabel(itemLabel);
  }

  // protected setDigestElementsByLabel(itemLabel) {}

  public getSortedSenarioMap(scenarioMap) {
    return this.service.getSortedSenarioMap(scenarioMap);
  }

  public getSelectedDigestElementFormGroup(): FormGroup {
    return this.service.getSelectedDigestElementFormGroup();
  }

  public getDigestElementFormGroupByItemIndex(index): AbstractControl {
    const formArray = this.service.getDigestScenariosFormArray();
    const formArrayItem = formArray.controls[index];

    if (formArrayItem) {
      return formArrayItem.get('digestElementFormGroup');
    }
  }

  public getCardHeader() {
    const viewType = this.service.getFormGroup().get("viewType").value;
    if (viewType == "Category") {
      return `Scenario: ${this.service.getSelectedScenarioName()}`;
    }
  }

  public cloneFromPlot(): void {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        forcePlotTypeFilter: "plo",
        forceProjectAndCollectionSelectFirst: true,
      },
    };

    this.modalService
      .openModal(PlotFilesSelectComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result?.plotFiles) {
          this.service.cloneScenarioFromPlotFiles(result.plotFiles);
        }
      });
  }

  public cloneScenario(fg) {
    const initialState: ModalOptions = {
      ignoreBackdropClick: true,
      initialState: {
        scenarioName: fg.value.name,
      },
    };
    this.modalService
      .openModal(CloneScenarioFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result?.numberOfClones) {
          this.service.cloneScenario(result.numberOfClones, fg);
        }
      });
  }

  public editScenario(fg) {
    const initialState: ModalOptions = {
      initialState: {
        scenarioName: fg.value.name,
        notes: fg.value.notes,
      },
    };

    this.modalService
      .openModal(AddScenarioFormComponent, {
        ...initialState,
      })
      .pipe(take(1))
      .subscribe((result: PlotDigestScenario) => {
        if (result) {
          fg.get("name").setValue(result.name);
          fg.get("notes").setValue(result.notes);
          //reclick scenario as name is the id used
          this.setScenarioItem({
            name: fg.get("name").value,
            scenario: fg,
            itemLabel: "root",
            path: fg.get("name").value,
          });
        }
      });
  }

  public deleteScenario(fg) {
    this.service.deleteScenario(fg.get("name").value);
  }

  public getViewType(): "Scenario" | "Category" | "Input" {
    return this.service.getFormGroup().get("viewType").value;
  }

  public getSelectedScenarioName(): string {
    return this.service.getSelectedScenarioName();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
