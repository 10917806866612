import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import {
  FolderType,
  ListType,
  PlotTableColumns,
} from "src/app/my-plots/models";
import { ClonedPlotFile, ClonePlotFilesResponse } from "src/app/plot/models";
import { environment } from "src/environments/environment";
import * as xml2js from "xml2js";
import {
  RoleAccessModel,
  RoleAccessRequest,
  RoleAccessResponse,
  UserRole,
} from "../../user-role/role-access/model";
import {
  IFileUploadRequest,
  UploadEstatePlotFileParams,
  CustomApiResponse,
} from "../models";
import { AppService } from "src/app/services/app.service";
import {
  UnApprovedAllUsersData,
  UnApprovedUsers,
} from "src/app/user-role/models/UnApprovedUsers copy";
import { User, UserRequest } from "src/app/user-role/models/user-management";
import {
  RegimeForest,
  SiteInfoResponse,
  SpeciesRegimesResponse,
} from "src/app/rmt/models";

const BASE_URL = environment.baseUrl;
// const RMT_URL = environment.rmtApiUrl;
@Injectable({
  providedIn: "root",
})
export class DbService {
  constructor(private http: HttpClient, private appService: AppService) {}

  private getBasePlotApiUrl() {
    let year = this.appService.getSimulationService()?.version;

    return `${BASE_URL}/${year}/fullcam-simulator`;
  }

  private getBaseDataApiUrl() {
    let year = this.appService.getSimulationService()?.version;

    return `${BASE_URL}/${year}`;
  }

  private remapXmlData(data) {
    let obj = {};
    if (!data || !Object.keys(data).length || typeof data === "string") {
      return data;
    }

    Object.entries(data).forEach((d) => {
      const key = d[0];
      const value: any = d[1];
      if (data[key] && Array.isArray(data[key])) {
        obj[key] = value.map((v) => this.remapXmlData(v));
        return obj;
      } else if (key == "$") {
        obj = { ...obj, ...value };
        return obj;
      } else if (key == "_") {
        obj[key] = value;
        return obj;
      } else if (!Array.isArray(data[key])) {
        obj[key] = this.remapXmlData(value);
        return obj;
      } else {
        obj[key] = value;
      }
    });

    return obj;
  }

  public addUser(): Observable<any> {
    return this.http.get(`${BASE_URL}/user-managment/user-guid`);
  }

  public deleteUser(userEmailId: string): Observable<any> {
    return this.http.delete<boolean>(
      `${BASE_URL}/user-managment/soft-delete-user`,
      {
        headers: { emailId: userEmailId },
      }
    );
  }

  public saveRoleAccessData(roleData: RoleAccessModel) {
    const userGuid = sessionStorage.getItem("userGuidId");
    const headers = {
      "content-type": "application/json",
      userGuidId: userGuid,
    };
    let response: RoleAccessResponse;
    const body = JSON.stringify(roleData);
    var resultData = this.http
      .post<RoleAccessResponse>(
        `${BASE_URL}/user-managment/save-role-access`,
        body,
        { headers: headers, observe: "response" }
      )
      .subscribe((res) => {
        response = res.body;
      });
  }

  public createUserRoleRequest(userRequest: RoleAccessRequest) {
    const headers = { "Content-Type": "application/json" };

    const body = JSON.stringify(userRequest);
    return this.http.post<UserRole>(
      `${BASE_URL}/user-managment/save-role-access`,
      body,
      {
        headers: headers,
        observe: "response",
      }
    );
  }

  public getMasterInputs() {
    return this.http.get(
      `${this.getBaseDataApiUrl()}/data-builder/generated-inputs?`
    );
  }

  public getEnumTypes() {
    return this.http.get(
      `${this.getBaseDataApiUrl()}/data-builder/enum-types?`
    );
  }

  public getPlotTemplateList(): Observable<string> {
    return this.http.get(
      `${this.getBaseDataApiUrl()}/data-builder/templates?`,
      {
        responseType: "text",
      }
    );
  }
  /****************** user management ********************/
  public getUserRoles(): Observable<any> {
    return this.http.get(`${BASE_URL}/user-managment/user-roles`);
  }

  public getUnApprovedUsers(): Observable<any> {
    return this.http.get(`${BASE_URL}/user-managment/unapproved-users`);
  }

  public getUnApprovedAllUsers(): Observable<any> {
    return this.http.get(`${BASE_URL}/user-managment/unapproved-all-users`);
  }

  public grantUserRoleAccess(roleData: UnApprovedUsers): Observable<any> {
    const headers = { "content-type": "application/json" };
    const body = JSON.stringify(roleData);
    return this.http.post<any>(
      `${BASE_URL}/user-managment/grant-access`,
      body,
      { headers: headers, observe: "response" }
    );
  }
  public rejectUserRoleAccess(roleData: UnApprovedUsers): Observable<any> {
    const headers = { "content-type": "application/json" };
    const body = JSON.stringify(roleData);
    return this.http.post<any>(
      `${BASE_URL}/user-managment/reject-access`,
      body,
      { headers: headers, observe: "response" }
    );
  }
  public ManageUserAccess(data: UnApprovedAllUsersData): Observable<any> {
    const headers = { "content-type": "application/json" };
    const body = JSON.stringify(data);
    return this.http.post<any>(
      `${BASE_URL}/user-managment/manage-user-access`,
      body,
      { headers: headers, observe: "response" }
    );
  }

  //updated
  public getAllUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(
      `${BASE_URL}/user-managment/unapproved-all-users`
    );
  }

  public getUserRequests(): Observable<UserRequest[]> {
    return this.http.get<UserRequest[]>(`${BASE_URL}/user-managment/requests`);
  }

  public getUserProfile(userEmailId): Observable<UserRequest[]> {
    return this.http.get<UserRequest[]>(`${BASE_URL}/user-managment/profile?`, {
      params: { email: userEmailId },
    });
  }

  /******************end user management ********************/

  public getPlotTemplate(name: string): Observable<string> {
    return this.http.get(`${this.getBaseDataApiUrl()}/data-builder/template?`, {
      params: { templateName: name },
      responseType: "text",
    });
  }

  public runSimulation(
    xmlString,
    plotFormat: "plo" | "pld" | "est",
    fileName: string,
    digestSimOption?: "Combined" | "PerDoc" | "PerOutput",
    plotIds?: string[]
  ) {
    const formData: FormData = new FormData();
    const userGuid = sessionStorage.getItem("userGuidId");
    const blob = new Blob([xmlString], { type: "text/plain" });
    let file = new File([blob], fileName, {
      type: "text/plain",
    });

    /***********for debugging purpose**********/
    // const testFileblob = new Blob([testPlotFile], { type: "text/plain" });
    // file = new File([testFileblob], fileName, {
    //   type: "text/plain",
    // });

    /***********for debugging purpose**********/
    if (plotFormat !== "est") {
      formData.append("file", file);
    }

    if (digestSimOption && plotFormat == "pld") {
      formData.append("simOption", digestSimOption);
    }
    if (plotIds && plotFormat == "est") {
      formData.append("estfile", file);
      plotIds.forEach((id) => {
        formData.append("plotIds", id);
      });
    }

    let simType;
    let responseType;
    switch (plotFormat) {
      case "plo":
        simType = "run-plotsimulation";
        responseType = "text/plain";
        break;
      case "pld":
        simType = "run-pld-filesimulation";
        responseType = "blob";
        break;
      case "est":
        simType = "run-est-filesimulation";
        responseType = "text/plain";
        break;
    }

    return this.http.post(`${this.getBasePlotApiUrl()}/${simType}?`, formData, {
      responseType: responseType,
      headers: { userGuidId: userGuid },
    });
  }

  public getSpatialData(params): Observable<any> {
    const url = `${this.getBaseDataApiUrl()}/data-builder/siteinfo?`;
    return this.http
      .get(url, {
        params,
        responseType: "text",
      })
      .pipe(
        switchMap(async (xml) => {
          const parser = new xml2js.Parser({
            trim: true,
            explicitArray: true,
          });
          let convertedData;
          await parser.parseString(xml, (err, result) => {
            convertedData = this.remapXmlData(result);
          });

          return convertedData?.DocFragment;
        })
      );
  }

  public getSpeciesDetails(params): Observable<any> {
    const url = `${this.getBaseDataApiUrl()}/data-builder/species?`;
    return this.http
      .get(url, {
        params,
        responseType: "text",
      })
      .pipe(
        switchMap(async (xml) => {
          const parser = new xml2js.Parser({
            trim: true,
            explicitArray: true,
          });
          let data;
          await parser.parseString(xml, (err, result) => {
            data = result?.DocFragment;
          });

          return data;
        })
      );
  }

  public getRegimes(params): Observable<any> {
    return this.http
      .get(`${this.getBaseDataApiUrl()}/data-builder/regimes?`, {
        params,
        responseType: "text",
      })
      .pipe(
        switchMap(async (xml) => {
          const parser = new xml2js.Parser({
            trim: true,
            explicitArray: true,
          });
          let data;
          await parser.parseString(xml, (err, result) => {
            data = result?.DocFragment;
          });

          return data;
        })
      );
  }

  public loadLocalFile(path, responseType) {
    return this.http.get(path, {
      headers: new HttpHeaders()
        .set("Content-Type", "text/xml")
        .append("Access-Control-Allow-Methods", "GET")
        .append("Access-Control-Allow-Origin", "*")
        .append(
          "Access-Control-Allow-Headers",
          "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
        ),
      responseType,
    });
  }

  public createFolder(type: FolderType, params, isRMT?: boolean) {
    const userGuid = sessionStorage.getItem("userGuidId");
    const headers = { userGuidId: userGuid };

    const baseUrl = isRMT ? `${BASE_URL}/rmt` : `${this.getBasePlotApiUrl()}`;

    return this.http.post(
      `${baseUrl}/save-${type}?`,
      {
        ...params,
      },
      { headers }
    );
  }

  public updateFolder(type: FolderType, params, isRMT?: boolean) {
    const userGuid = sessionStorage.getItem("userGuidId");
    const headers = { userGuidId: userGuid };

    const baseUrl = isRMT ? `${BASE_URL}/rmt` : `${this.getBasePlotApiUrl()}`;

    return this.http.put(
      `${baseUrl}/${type}?`,
      {
        ...params,
      },
      { headers }
    );
  }

  public deleteFolders(type: FolderType, params: string[], isRMT?: boolean) {
    const userGuid = sessionStorage.getItem("userGuidId");

    const baseUrl = isRMT ? `${BASE_URL}/rmt` : `${this.getBasePlotApiUrl()}`;

    return this.http.delete(`${baseUrl}/${type}s?`, {
      body: params,
      headers: { userGuidId: userGuid },
    });
  }

  public getList(listType: ListType, inputParams?, isRMT?: boolean) {
    let listPath = "";
    switch (listType) {
      case "folder":
        listPath = "project-collections";
        break;
      case "project":
        listPath = "projects";
        break;
      case "collection":
        listPath = "collections";
        break;
      default:
        listPath = isRMT ? "rmd-files" : "plotfiles";
        break;
    }

    const userGuid = sessionStorage.getItem("userGuidId");

    const params = new HttpParams({
      fromObject: inputParams,
    });

    const baseUrl = isRMT ? `${BASE_URL}/rmt` : `${this.getBasePlotApiUrl()}`;
    return this.http.get(`${baseUrl}/${listPath}?`, {
      params,
      headers: { userGuidId: userGuid },
    });
  }

  public filterPlotFileList(params) {
    return this.http.post<PlotTableColumns>(
      `${this.getBasePlotApiUrl()}/filter-plotfiles`,
      params,
      {
        headers: { "content-type": "application/json" },
      }
    );
  }

  public clonePlotFilesForDigest(
    params: ClonedPlotFile
  ): Observable<ClonePlotFilesResponse> {
    return this.http.post<ClonePlotFilesResponse>(
      `${this.getBasePlotApiUrl()}/clone-plotfiles`,
      params,
      {
        headers: { "content-type": "application/json" },
      }
    );
  }

  public updatePlotFile(
    inputParams: IFileUploadRequest,
    isRMT: boolean = false
  ) {
    let formData = new FormData();
    formData.append("fileType", inputParams.fileType);
    formData.append("project", inputParams.project);
    formData.append("collection", inputParams.collection);
    formData.append("file", inputParams.file);
    formData.append("latitude", inputParams.latitude);
    formData.append("longitude", inputParams.longitude);
    formData.append("plotId", inputParams.plotId);
    formData.append("status", inputParams.status);

    const url = isRMT
      ? `${BASE_URL}/rmt/update-rmd-file`
      : `${this.getBasePlotApiUrl()}/update-plotfile`;

    return this.http.put(url, formData);
  }

  public uploadPlotFile(
    inputParams: IFileUploadRequest,
    includeProgress: boolean = true,
    isRMT: boolean = false
  ) {
    const userGuid = sessionStorage.getItem("userGuidId");
    let formData = new FormData();
    formData.append("fileType", inputParams.fileType);
    formData.append("project", inputParams.project);
    formData.append("collection", inputParams.collection);
    formData.append("file", inputParams.file);
    formData.append("status", inputParams.status);

    if (inputParams.fileType !== "est") {
      formData.append("latitude", inputParams.latitude);
      formData.append("longitude", inputParams.longitude);
    }

    const url = isRMT
      ? `${BASE_URL}/rmt/upload-rmd-file`
      : `${this.getBasePlotApiUrl()}/upload-plotfile`;

    return this.http.post(`${url}`, formData, {
      headers: { userGuidId: userGuid },
      ...(includeProgress && { reportProgress: true }),
      ...(includeProgress && { observe: "events" }),
    });
  }

  public uploadEstatePlotFile(params: UploadEstatePlotFileParams) {
    return this.http.post(
      `${this.getBasePlotApiUrl()}/upload-est-plotfiles?`,
      params
    );
  }

  public updatePlotFileVersion(file) {
    let formData = new FormData();
    formData.append("file", file);

    return this.http.post(
      `${this.getBasePlotApiUrl()}/update-old-plotfile-newversion?`,
      formData
    );
  }

  public downloadPlotFile(id: string, isRMT: boolean = false) {
    const url = isRMT
      ? `${BASE_URL}/rmt/download-rmd-file`
      : `${this.getBasePlotApiUrl()}/download-plotfile`;

    return this.http.get(`${url}?`, {
      params: { plotId: id },
      responseType: "text",
    });
  }

  public deletePlotFiles(ids: string[], isRMT: boolean = false) {
    const url = isRMT
      ? `${BASE_URL}/rmt/rmd-files`
      : `${this.getBasePlotApiUrl()}/plotfiles`;

    return this.http.delete(`${url}?`, {
      body: ids,
    });
  }

  public verifyPlotFiles(params) {
    const formData: FormData = new FormData();
    if (params.plotIds?.length) {
      params.plotIds.forEach((id) => {
        formData.append("plotId", id);
      });
    }

    if (params.plotFiles?.length) {
      params.plotFiles.forEach((f) => {
        formData.append("plotFile", f);
      });
    }

    if (params.siteAreaData !== undefined) {
      formData.append("siteAreaData", params.siteAreaData);
    }

    formData.append("fileType", params.fileType);

    return this.http.post(
      `${this.getBasePlotApiUrl()}/verify-plotfiles?`,
      formData
    );
  }

  public downloadAsset(fileName: string, filePath: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.http
        .get(filePath, { responseType: "blob" })
        .pipe(
          catchError((error) => {
            observer.error("Error downloading the file");
            return throwError(() => new Error(error));
          })
        )
        .subscribe((response) => {
          let dataType = response.type;
          let binaryData = [response];
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          downloadLink.setAttribute("download", fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          observer.next();
          observer.complete();
        });
    });
  }

  /************************* RMT ***************************/

  public getRMTSiteInfo(
    lon: number,
    lat: number
  ): Observable<CustomApiResponse<SiteInfoResponse>> {
    return this.http.get<CustomApiResponse<SiteInfoResponse>>(
      `${BASE_URL}/rmt/site-info`,
      {
        params: { lat, lon },
      }
    );
  }

  public getRMTSpeciesRegimes(params: {
    lat: number;
    lon: number;
    speciesId: string;
  }): Observable<CustomApiResponse<SpeciesRegimesResponse>> {
    return this.http.get<CustomApiResponse<SpeciesRegimesResponse>>(
      `${BASE_URL}/rmt/location-species-regimes`,
      {
        params,
      }
    );
  }

  public getRMTRegimeDetails(params: {
    lat: number;
    lon: number;
    regimeId: string;
  }): Observable<CustomApiResponse<RegimeForest>> {
    return this.http.get<CustomApiResponse<RegimeForest>>(
      `${BASE_URL}/rmt/regime-details`,
      {
        params,
      }
    );
  }

  public runRMTSimulation(file: File): Observable<CustomApiResponse<string>> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return this.http.post<CustomApiResponse<string>>(
      `${BASE_URL}/rmt/simulate`,
      formData
    );
  }

  public decryptRMDFile(file): Observable<CustomApiResponse<string>> {
    const formData: FormData = new FormData();
    formData.append("file", file);
    return this.http.post<CustomApiResponse<string>>(
      `${BASE_URL}/rmt/decrypt-file?`,
      formData
    );
  }
}
