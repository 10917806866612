import { Component, Input, OnInit } from "@angular/core";
import { result } from "lodash";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "fc-output-windows-container",
  templateUrl: "./output-windows-container.component.html",
  styleUrls: ["./output-windows-container.component.scss"],
})
export class OutputWindowsContainerComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  @Input() service: any;

  ngOnInit(): void {
    // this.service.simulationService.simulationResult$.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe(result=>{
    // });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
