<div class="form">
  <button type="button" class="btn back-button" (click)="closeForm(null)">
    <span class="visually-hidden">Close form</span>
    <i class="bi bi-arrow-left-circle-fill"> </i>
  </button>
  <div class="form-content">
    <select
      class="form-control form-select form-select-sm"
      [(ngModel)]="seletedOption"
    >
      <option *ngFor="let do of diagramOptions" [value]="do">{{ do }}</option>
    </select>

    <div class="forest" *ngIf="shouldShowForest()">
      <h2>
        {{
          seletedOption == "System Overview" ? "Forest System" : seletedOption
        }}
      </h2>

      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Forest System - Tree')"
          src="assets/images/diagrams/Forest System - Tree.png"
          alt="Forest System - Tree"
        />
        <img
          *ngIf="shouldShowImage('Forest System - Products')"
          src="assets/images/diagrams/Forest System - Products.png"
          alt="Forest System - Products"
        />
      </div>
      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Forest System - Debris')"
          src="assets/images/diagrams/Forest System - Debris.png"
          alt="Forest System - Debris"
        />
      </div>
      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Forest System - Soil') && !userSoilMnrl"
          src="assets/images/diagrams/Forest System - Soil.png"
          alt="Forest System - Soil"
        />

        <img
          *ngIf="shouldShowImage('Forest System - Soil') && userSoilMnrl"
          src="assets/images/diagrams/Forest System - Soil - Active.png"
          alt="Forest System - Soil"
        />
      </div>
    </div>

    <div class="agricultural" *ngIf="shouldShowAgricultural()">
      <h2>
        {{
          seletedOption == "System Overview"
            ? "Agricultural System"
            : seletedOption
        }}
      </h2>
      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Agricultural System - Crop')"
          src="assets/images/diagrams/Agricultural System - Crop.png"
          alt="Agricultural System - Crop"
        />
        <img
          *ngIf="shouldShowImage('Agricultural System - Products')"
          src="assets/images/diagrams/Agricultural System - Products.png"
          alt="Agricultural System - Products"
        />
      </div>
      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Agricultural System - Debris')"
          src="assets/images/diagrams/Agricultural System - Debris.png"
          alt="Agricultural System - Debris"
        />
      </div>
      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Agricultural System - Mulch')"
          src="assets/images/diagrams/Agricultural System - Mulch.png"
          alt="Agricultural System - Mulch"
        />
      </div>
      <div class="img-row">
        <img
          *ngIf="shouldShowImage('Agricultural System - Soil') && !userSoilMnrl"
          src="assets/images/diagrams/Agricultural System - Soil.png"
          alt="Agricultural System - Soil"
        />
        <img
          *ngIf="shouldShowImage('Agricultural System - Soil') && userSoilMnrl"
          src="assets/images/diagrams/Agricultural System - Soil - Active.png"
          alt="Agricultural System - Soil"
        />
      </div>
    </div>
  </div>
</div>
