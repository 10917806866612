import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { RoleAccessService } from "./service/role-access.service";
import {
  Observable,
  Subject,
  concatMap,
  delay,
  finalize,
  from,
  map,
  of,
  take,
  takeUntil,
} from "rxjs";
import {
  ProductType,
  RoleAccessRequest,
  UserRole,
  UserRoleType,
} from "./model";
import { AuthService } from "src/app/auth/services/auth.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { MessageService } from "src/app/shared/services/message.service";

@Component({
  selector: "fc-role-access",
  templateUrl: "./role-access.component.html",
  styleUrls: ["./role-access.component.scss"],
})
export class RoleAccessComponent implements OnInit {
  private destroy$ = new Subject<void>();

  public userClaim$: Observable<any>;
  public userRole: UserRole;

  public isLoading = false;

  public readonly versions = [
    {
      value: "canAccessFullCam2024",
      label: "FullCAM 2024",
      statusProp: "fullCam2024Status",
      requestVersion: "2024",
    },
    {
      value: "canAccessFullCam2020",
      label: "FullCAM 2020",
      warning: true,
      statusProp: "status",
      requestVersion: "2020",
    },
    //
    // {
    //   value: "canAccessFullCam2016",
    //   label: "FullCAM 2016",
    //   warning: true,
    //  requestVersion:"2016"
    // },
    {
      value: "rmt",
      label: "Reforestation Modelling Tool (RMT)",
      requestVersion: "RMT",
    },
  ];
  public readonly roles = [
    { value: "accuValue", label: "ACCU scheme user" },
    { value: "dcceewValue", label: "Collaboration with DCCEEW" },
    { value: "studiesResearchValue", label: "Research and/or study" },
    { value: "otherValue", label: "Other" },
  ];
  public formGroup = new FormGroup({
    selectedVersions: new FormArray([]),
  });

  constructor(
    private roleAccessService: RoleAccessService,
    private authService: AuthService,
    private modalService: ModalService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.authService.userRole$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userRole) => {
        this.userRole = userRole;
      });

    this.userClaim$ = this.authService.userClaim$;
  }

  get selectedVersions() {
    return this.formGroup.get("selectedVersions") as FormArray;
  }

  public getRoleFormGroup(versionValue: string) {
    const fb = this.selectedVersions.controls.find(
      (fb) => fb.get("product").value == versionValue
    );
    return fb;
  }

  public onVersionChange(event: any, versionObject) {
    const selectedVersionsArray = this.selectedVersions;
    if (event.target.checked) {
      selectedVersionsArray.push(this.createVersionRoleGroup(versionObject));
    } else {
      const index = selectedVersionsArray.controls.findIndex(
        (control) => control.get("product").value === versionObject.value
      );
      selectedVersionsArray.removeAt(index);
    }
  }

  public onRoleChange(
    event: any,
    role: UserRoleType,
    roleWarning: boolean | undefined
  ) {
    if (event.target.checked && role == "accuValue" && roleWarning) {
      this.showVersionWarning();
    }
  }

  public createVersionRoleGroup(versionObject) {
    return new UntypedFormGroup({
      product: new UntypedFormControl(versionObject.value as ProductType),
      role: new UntypedFormControl(null as UserRoleType, Validators.required),
      reason: new UntypedFormControl("" as string),
      requestVersion: new UntypedFormControl(
        versionObject.requestVersion as string
      ),
    });
  }

  public isLoading$(): Observable<boolean> {
    return of(this.isLoading);
  }

  public shouldDisable(versionObject): boolean {
    if (!this.userRole) {
      return false;
    }
    //workaround
    if (
      this.userRole[versionObject.value] ||
      this.userRole[versionObject.statusProp] == "Pending"
    ) {
      return true;
    }

    return false;
  }

  public isPending(statusProp: string): boolean {
    if (!this.userRole) {
      return false;
    }

    if (this.userRole[statusProp] == "Pending") {
      return true;
    }

    return false;
  }

  private showVersionWarning() {
    const message = `<p>Warning: This verion is no longer the default version for most schemes. Please refer to the guidelines for the latest version and this version should only be used for schemes that specify in guidelines.</p>`;
    this.modalService.openConfirmModal(message, true);
  }

  public submitForm() {
    this.isLoading = true;
    const mappedRequestObject: RoleAccessRequest[] = this.formGroup
      .getRawValue()
      .selectedVersions.map((sv) => {
        return {
          [sv.product]: true,
          [sv.role]: true,
          reason: sv.reason,
          roleId: this.userRole.roleId,
          requestVersion: sv.requestVersion,
        };
      });

    from(mappedRequestObject)
      .pipe(
        concatMap((userRequest, index) =>
          this.roleAccessService
            .createUserRoleRequest(userRequest)
            .pipe(delay(index * 1000))
        ),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (response) => {
          const newUserRole = response.body;
          this.authService.setUserRoles({ ...this.userRole, ...newUserRole });

          this.selectedVersions.clear();
          this.messageService.addAlert({
            type: "success",
            msg: "Your request has been submitted successfully!",
          });
        },
        error: (error) => {
          console.error("Request error:", error);
        },
      });
  }
}
