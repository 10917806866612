import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { UserRole } from "src/app/user-role/role-access/model";

@Component({
  selector: "fc-resolving-access",
  templateUrl: "./resolving-access.component.html",
  styleUrls: ["./resolving-access.component.scss"],
})
export class ResolvingAccessComponent implements OnInit {
  private readonly roleSub$ = new Subject<void>();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.redirectBasedOnRole();
  }

  private redirectBasedOnRole(): void {
    this.authService.userRole$
      .pipe(takeUntil(this.roleSub$))
      .subscribe((userRole: UserRole) => {
        const currentUrl = this.router.url;

        const isRMT = currentUrl.indexOf("/rmt") > -1;
        if (
          isRMT &&
          userRole.canAccessRMT &&
          userRole?.rmtStatus &&
          userRole.rmtStatus !== "Pending"
        ) {
          this.router.navigate(["rmt/rmds"]);
          return;
        }

        switch (true) {
          case userRole.canAccessFullCam2024 &&
            userRole.fullCam2024Status !== "Pending":
            this.router.navigate(["/plots-2024"]);
            break;

          case userRole.canAccessFullCam2020 &&
            userRole.fullCam2024Status !== "Pending":
            this.router.navigate(["/plots-2020"]);
            break;

          case userRole.canAccessRMT &&
            userRole?.rmtStatus &&
            userRole.rmtStatus !== "Pending":
            this.router.navigate(["rmt/rmds"]);
            break;

          default:
            this.router.navigate(["/no-access"]);
            break;
        }

        // if (
        //   userRole.canAccessFullCam2024 &&
        //   userRole.fullCam2024Status !== "Pending"
        // ) {
        //   this.router.navigate(["/plots-2024"]);
        // } else if (
        //   userRole.canAccessFullCam2020 &&
        //   userRole.fullCam2024Status !== "Pending"
        // ) {
        //   this.router.navigate(["/plots-2020"]);
        // } else {
        //   this.router.navigate(["/no-access"]);
        // }
      });
  }

  ngOnDestroy() {
    this.roleSub$.next();
    this.roleSub$.complete();
  }
}
